import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../../../assets/arrow-new.svg";
import EditIcon from "../../../../assets/edit-project.svg";
import { Tooltip } from "react-tooltip";
import PlanProjectPhaseSectionIndividualTable from "./PlanProjectPhaseSectionIndividualTable";
import { useDispatch } from "react-redux";
import { updateProjectPhaseAction } from "../../../../actions/projectPhaseActions";
import Delete from "../../../../assets/delete.svg";

import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ConfirmDeleteProjectPhase from "./ConfirmDeleteProjectPhase";
import { isPermitted } from "../../../../helper/isPermitted";
import { isAdmin } from "../../../../helper/isUserAdmin";
import { isOwnerInProjectOne } from "../../../../helper/projectOwner";

const PlanProjectPhaseDetailSectionTable = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState([]);
  const inputRef = useRef(null);
  const [showPhaseDeleteModal, setShowPhaseDeleteModal] = useState(false);
  const [showPhaseDeleteId, setShowPhaseDeleteId] = useState(null);
  const [projectPhaseName, setProjectPhaseName] = useState(
    props?.data?.phaseName
  );
  const { loading: updateProjectPhaseLoading } = useSelector(
    (state) => state.updateProjectPhaseData
  );
  const { loading: projectsPhasesLoading } = useSelector(
    (state) => state.projectPhasesListingData
  );
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  // project phases tasks data state
  // original  project phases task list
  const [projectPhasesTasks, setProjectPhasesTasks] = useState([]);
  // after applying filters project phases task list
  const [projectPhasesTasksList, setProjectPhasesTasksList] = useState([]);
  const [isLoadingPhaseTasks, setIsLoadingPhaseTasks] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const [filters, setFilters] = useState({
    pinnedState: false,
  });

  useEffect(() => {
    if ((props.phaseId, props.projectId)) {
      getAllTasksByPhaseId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.phaseId, props.projectId, props.projectAllPhasesTasks]);

  // Function to toggle ID in the state array
  const toggleOpen = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id)); // Remove ID if it exists
    } else {
      setOpen([...open, id]); // Add ID if it doesn't exist
    }
  };

  // Manage filter count section (tasks, completed, overdue)
  const getStatusCounts = async (type) => {};

  const handleLoseFocus = async (e) => {
    const newProjectPhaseName = e.target.value;
    if (
      newProjectPhaseName &&
      newProjectPhaseName.length > 0 &&
      props?.data?.phaseName !== newProjectPhaseName
    ) {
      await dispatch(
        updateProjectPhaseAction({
          phaseId: props?.phaseId,
          fieldName: "phaseName",
          fieldValues: newProjectPhaseName,
        })
      );
      await props.getAllProjectPhases();
    } else {
      setProjectPhaseName(null);
    }
    props.setEdit(null);
  };

  const getAllTasksByPhaseId = async () => {
    try {
      setIsLoadingPhaseTasks(true);
      if (props.data) {
        const response = props.projectAllPhasesTasks?.find(
          (item) => item.id === props?.phaseId
        );
        const allTasks = response.tasks;
        setProjectPhasesTasks(allTasks);
      }
    } catch (error) {
    } finally {
      setIsLoadingPhaseTasks(false);
    }
  };

  const handleChange = (e) => {
    // props.setEdit(null);
    setProjectPhaseName(e.target.value);
  };

  // function for update count in filters

  const filteredListing = async () => {
    let filteredProjects = [...projectPhasesTasks]; // Copy the original list

    // Sort by pin
    filteredProjects.sort((a, b) => {
      if (a.isPinned && !b.isPinned) {
        return -1;
      }
      if (!a.isPinned && b.isPinned) {
        return 1;
      }
      return 0;
    });

    setProjectPhasesTasksList(filteredProjects);
  };

  useEffect(() => {
    filteredListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, projectPhasesTasks]);

  useEffect(() => {
    if (userProfile?.phaseListingTour && props.index === 0) {
      toggleOpen(props?.data?.id);
      props.setShowTour(true);
    }
  }, [userProfile]);

  return (
    <div
      key={props.index}
      className="project-timeline plan-project-phase-section-wraper position-relative"
    >
      <Tooltip id="my-tooltip" />
      <div className="w-100 project-detail-modal-header">
        <h2
          className="d-flex align-items-center"
          onClick={() => toggleOpen(props?.data?.id)}
          onMouseEnter={() => setShowDeleteIcon(true)}
          onMouseLeave={() => setShowDeleteIcon(false)}
        >
          {" "}
          <img
            src={ArrowIcon}
            className={`${
              open.includes(props?.data?.id) ? "flip-arrow" : "flip-not-arrow"
            }`}
            alt="arrow-icon"
            onClick={(e) => {
              toggleOpen(props?.data?.id);
            }}
          />{" "}
          {props.edit === props?.data?.id ? (
            <>
              <input
                ref={inputRef}
                autoFocus
                disabled={updateProjectPhaseLoading || projectsPhasesLoading}
                className="form-control project-detail-form-control"
                value={projectPhaseName}
                defaultValue={props?.data?.phaseName}
                onBlur={(e) => handleLoseFocus(e)}
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={(e) => {
                  // when press enter call handle lose focus
                  if (e.key === "Enter") {
                    handleLoseFocus(e);
                  }
                }}
              />
              {(updateProjectPhaseLoading || projectsPhasesLoading) && (
                <Spinner
                  className="global-spinner ms-2"
                  isDark={props.isDark}
                  size="small"
                />
              )}
            </>
          ) : (
            <>{`${props?.data?.phaseName}`}</>
          )}
          {((showDeleteIcon &&
            isPermitted("project/phase-delete") &&
            isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
            (showDeleteIcon && isAdmin(userProfile))) && (
            <button className="ms-1">
              {" "}
              <img
                src={Delete}
                alt="delete-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPhaseDeleteId(props?.data?.id);
                  setShowPhaseDeleteModal(true);
                }}
              />{" "}
            </button>
          )}
          {((isPermitted("project/pm-update-project-phase") &&
            isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
            isAdmin(userProfile)) && (
            <button className="phase-edit-button phase-edit-btn-step">
              {" "}
              <img
                src={EditIcon}
                alt="edit-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  props.setEdit(props?.data?.id);
                }}
              />{" "}
            </button>
          )}
          <span
            className={`${
              (isPermitted("project/pm-update-project-phase") &&
                isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
              isAdmin(userProfile)
                ? ""
                : "ms-2"
            }`}
          >
            {" "}
            Tasks ({projectPhasesTasks.length}){" "}
          </span>
        </h2>
      </div>
      {open.includes(props?.data?.id) && (
        <>
          {isLoadingPhaseTasks ? (
            <div className="w-100 d-flex justify-content-center">
              <Spinner animation="border" className="global-spinner" />
            </div>
          ) : (
            <>
              <PlanProjectPhaseSectionIndividualTable
                props={props}
                rowData={projectPhasesTasksList}
                setSelectedTask={setSelectedTask}
                selectedTask={selectedTask}
                projectId={props.projectId}
                phaseId={props?.data?.id}
                taskList={projectPhasesTasks}
                projectAllPhasesTasks={props.projectAllPhasesTasks}
                filters={filters}
                setFilters={setFilters}
                setTaskList={setProjectPhasesTasksList}
                setListToFilter={setProjectPhasesTasksList}
                getAllTasksByPhaseId={() => {
                  props.getAllProjectPhases();
                }}
                boardColumnData={[]}
                updateCountFilters={() => {}}
                getStatusCounts={getStatusCounts}
                isDark={props.isDark}
                updateTaskData={props.updatePhaseTaskData}
                updateTaskMemberData={props.updatePhaseTaskMemberData}
                getTaskList={() => props.getTaskList(false)}
                setReadLoad={props.setReadLoad}
                setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
              />
            </>
          )}
        </>
      )}
      <ConfirmDeleteProjectPhase
        showDeleteModal={showPhaseDeleteModal}
        handleCloseDeleteModal={() => setShowPhaseDeleteModal(false)}
        isDark={props.isDark}
        phaseId={showPhaseDeleteId}
        getProjectList={props.getAllProjectPhases}
        listToFilter={props.projectAllPhasesTasks}
        setListToFilter={props.setProjectAllPhasesTasks}
        phaseName={props?.data?.phaseName}
        type="projectPhase"
        setSelectedPhaseIdChange={props.setSelectedPhaseIdChange}
        // modalContent={projectsDeleteModalContent}
      />
    </div>
  );
};

export default PlanProjectPhaseDetailSectionTable;
