import React, { useState } from "react";
import userMemberImage from "../../../assets/user-member.svg";
import NewTeam from "../../Team/components/NewTeam";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { dateFormatter } from "../../../helper/dateFormatter";
import { useNavigate } from "react-router-dom";
import progressImage from "../../../assets/progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.png";
import criticalImage from "../../../assets/critical.svg";

function TeamDetailProjectGrid(props) {
  const [show, setShow] = useState(false);
  const [create, setCreate] = useState(0);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setCreate(0);
  };
  const handleCreate = (value) => {
    setCreate(value);
  };

  const getStatusProps = (statusName) => {
    const lowercasedStatus = statusName?.toLowerCase();

    switch (lowercasedStatus) {
      case "in progress":
        return progressImage;
      case "completed":
        return doneImage;
      case "on hold":
        return reviewImage;
      case "submit for review":
        return reviewIcon;
      case "not started":
        return criticalImage;
      default:
        return {
          className: "status-icon-wrap",
          image: null,
        };
    }
  };

  return (
    <div className="ms-4 team-left-right-wraper">
      <div className="row">
        <div className="col-lg-12 mt-3">
          <div className="w-100 user-profile-worked-wrap">
            <h5> Projects</h5>
            <div className="table-responsive scroll-smooth custom-horizental-scrollbar">
              <table
                className="table"
                border="1"
                style={{
                  width: "100%",
                  minWidth: "850px",
                }}
              >
                {props.projects?.map((p, id) => (
                  <tr>
                    <td width="32%">
                      <div
                        className="table-heading"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            "/" +
                              localStorage.getItem("company") +
                              "/project?pid=" +
                              p?.id +
                              "&pname=" +
                              p?.projectName
                          )
                        }
                      >
                        <img
                          className="project-listing-icon-img"
                          src={
                            getProjectIconById(p?.projectIcon)
                              ? getProjectIconById(p?.projectIcon)
                              : props?.data?.teamIcon
                          }
                          alt="project-icon"
                        />
                        {p?.data?.projectName.length > 20
                          ? p?.projectName.substring(0, 20) + "..."
                          : p?.projectName}
                      </div>
                    </td>
                    <td width="25%">
                      <div className="user-member-wrap">
                        {" "}
                        <img
                          src={userMemberImage}
                          alt="User Member"
                        /> Member {p?.projectMembers?.length}{" "}
                      </div>
                    </td>
                    <td width="22%">
                      <div className="status-icon-wrap  status-in-progress">
                        {" "}
                        <img
                          src={getStatusProps(p?.projectStatus?.name)}
                          alt="Status Icon"
                        />{" "}
                        {p?.projectStatus?.name}{" "}
                      </div>
                    </td>
                    <td width="21%" className="user-member-last-active">
                      Last active : {dateFormatter(p?.projectUpdatedDate)}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewTeam
        create={create}
        show={show}
        handleClose={handleClose}
        isDark={props.isDark}
        handleCreate={handleCreate}
      />
    </div>
  );
}

export default TeamDetailProjectGrid;
