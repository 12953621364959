import {
  BILLING_FAIL,
  BILLING_REQUEST,
  BILLING_SUCCESS,
  PAYMENT_HISTORY_FAIL,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  SUBSCRIPTION_FAIL,
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_SUCCESS,
} from "../constants/paymentConstants";

export const paymentReducers = (state = [], action) => {
  switch (action.type) {
    case PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        payLoading: true,
      };

    case PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        payloading: false,
        transactionHistory: action.payload,
      };

    case PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        payloading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// User billing reducers
export const billingReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_REQUEST:
      return {
        ...state,
        bloading: true,
      };

    case BILLING_SUCCESS:
      return {
        ...state,
        bloading: false,
        billingDetail: action.payload,
      };

    case BILLING_FAIL:
      return {
        ...state,
        bloading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// User billing reducers
export const subscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subloading: false,
        subscriptionDetail: action.payload,
      };

    case SUBSCRIPTION_FAIL:
      return {
        ...state,
        subloading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
