import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  calculateDurationAgo,
  formatTimeAgo,
} from "../../../helper/timeAgoFormatter";

function UserChatProfile({ selectedChat, socket, lastSeen, online, away }) {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    setTyping(false);
  }, [selectedChat]);

  useEffect(() => {
    socket.on("userTyping", (data) => {
      if (selectedChat?.room_id === data?.roomId) {
        setTyping(true);
      }
    });
    socket.on("userStoppedTyping", (data) => {
      setTyping(false);
    });
    return () => {
      socket.off("userTyping");
      socket.off("userStoppedTyping");
    };
  }, [selectedChat]);

  return (
    <div className="user-chat-profile-wrapper">
      <div className="d-flex align-items-center gap-3">
        {/* image */}
        {/* image */}
        {selectedChat?.user_profile_image ? (
          <img
            className="user-list-card-user-image"
            src={selectedChat?.user_profile_image}
            alt="User Profile"
          />
        ) : (
          <div className={"user-list-card-user-image-name"}>
            {selectedChat?.user_name[0]?.toUpperCase()}
            {selectedChat?.user_name?.split(" ")[1]
              ? selectedChat?.user_name?.split(" ")[1][0]?.toUpperCase()
              : selectedChat?.user_name[1]?.toUpperCase()}
          </div>
        )}

        {/* name and last message */}
        <div className="user-list-card-user-name">
          <h3>{selectedChat?.user_name}</h3>
          {typing ? (
            <p className="user-list-card-user-name-p">
              {`${selectedChat?.user_name} is typing...`}
            </p>
          ) : away ? (
            <p className="mb-0 d-flex align-items-center">
              <span className="me-1 away-status-chat-profile"></span>Away
            </p>
          ) : online ? (
            <p className="mb-0 d-flex align-items-center">
              <span className="me-1 online-status-chat-profile"></span>Online
            </p>
          ) : selectedChat?.user_lastSeen ? (
            <p>Last seen {calculateDurationAgo(selectedChat.user_lastSeen)}</p>
          ) : null}
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        {showSearchBar && (
          <motion.div
            style={{ minWidth: "300px" }}
            initial={{ opacity: 0 }} // Start with opacity 0
            animate={{ opacity: showSearchBar ? 1 : 0 }} // Animate opacity based on showSearchBar state
            transition={{ duration: 0.5 }}
          >
            <div
              style={{ border: "1px solid #f2f5ff", flex: 1 }}
              className="users-list-search-input-wrapper"
            >
              <input
                autoFocus
                className="users-list-search-input"
                type="text"
                style={{ flex: 1 }}
                placeholder="Search message in chat"
              />
              <Icon
                className="user-list-search-icon"
                width={18}
                height={18}
                icon={"majesticons:search-line"}
              />
            </div>
          </motion.div>
        )}

        {/* <button
          onClick={() => {
            setShowSearchBar(!showSearchBar);
          }}
          className="user-chat-profile-btns "
        >
          {showSearchBar ? (
            <motion.div
              initial={{ opacity: showSearchBar ? 0 : 1 }} // Start with opacity 0
              animate={{ opacity: showSearchBar ? 1 : 0 }} // Animate opacity based on showSearchBar state
              transition={{ duration: 0.5 }} // Duration of the animation (in seconds)
            >
              <Icon width={15} height={15} icon={"ic:round-close"} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: showSearchBar ? 1 : 0 }} // Start with opacity 0
              animate={{ opacity: showSearchBar ? 0 : 1 }} // Animate opacity based on showSearchBar state
              transition={{ duration: 0.5 }} // Duration of the animation (in seconds)
            >
              <Icon width={15} height={15} icon={"basil:search-outline"} />
            </motion.div>
          )}
        </button> */}
      </div>
    </div>
  );
}

export default UserChatProfile;
