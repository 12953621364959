import React, { useState } from "react";
import NoInternetIcon from "../../assets/info.svg";
import { Modal, Spinner } from "react-bootstrap";
import "./retry.css"
const RetryModal = ({ show, onRetry, onClose,loading }) => {
  if (!show) return null;

  return (
    <>
      <Modal
        show={show && localStorage.getItem("access_token")}
        className="retry-modal p-0"
        data-theme={"default"}
        centered
      >
        <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column p-0 m-0 position-relative">
          <div className="del-modal-img-container">
            <img
              src={NoInternetIcon}
              alt="no-internet-icon"
              width={150}
              height={150}
            />
          </div>
          <h5 className="day-time-start-modal-heading  mb-2 ps-3 pe-3">
            Request Failed, <br></br> Do you want to retry?
          </h5>

          <div className="w-100 mt-3 delete-modal-btn-wrapper  d-flex">
            <button disabled={loading} className="delete-btn-2 flex-1" onClick={onClose}>
              Cancel
            </button>
            <button disabled={loading} onClick={onRetry} className="delete-btn-1  flex-1">
              Retry {loading&&<Spinner size="sm" className="ms-2" />}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RetryModal;
