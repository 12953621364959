// Calculate discount price
export const calculateDiscountedPrice = (
  perSeatCharged,
  yearlyDiscountedPercentage,
  seat
) => {
  // Convert string values to numbers
  const originalPrice = Math.round(parseInt(perSeatCharged));
  const discountPercentage = Math.round(parseInt(yearlyDiscountedPercentage));

  // Calculate the discounted price
  const discountedPrice =
    originalPrice - originalPrice * (discountPercentage / 100);

  return discountedPrice; // Round to two decimal places
};
