import Delete from "../../../../assets/delete.svg";
import Edit from "../../../../assets/edit.svg";
import Eyes from "../../../../assets/eyes.svg";
const color = [
  "#ece1d3",
  "#fffff0",
  "#def2ef",
  "#fff7c2",
  "#ede7de",
  "#fffce6",
  "#fcebeb",
  "#d8def0",
];
const data = [
  {
    id: 1,
    member_name: "Abdus Salam",
    email: "info@yourmail.com",
    status: "Contacted",
    title: "Manager",
    salary: "50,000",
    performance: 25,
    action: "",
  },
  {
    id: 2,
    member_name: "Badr al-din",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 3,
    member_name: "Fatahallah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 4,
    member_name: "Brahim",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 5,
    member_name: "Dhikrullah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 6,
    member_name: "Abdus Salam",
    email: "info@yourmail.com",
    status: "Contacted",
    title: "Manager",
    salary: "50,000",
    performance: 25,
    action: "",
  },
  {
    id: 7,
    member_name: "Badr al-din",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 8,
    member_name: "Fatahallah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 9,
    member_name: "Brahim",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 10,
    member_name: "Dhikrullah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 11,
    member_name: "Abdus Salam",
    email: "info@yourmail.com",
    status: "Contacted",
    title: "Manager",
    salary: "50,000",
    performance: 25,
    action: "",
  },
  {
    id: 12,
    member_name: "Badr al-din",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 13,
    member_name: "Fatahallah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 14,
    member_name: "Brahim",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 15,
    member_name: "Dhikrullah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 16,
    member_name: "Abdus Salam",
    email: "info@yourmail.com",
    status: "Contacted",
    title: "Manager",
    salary: "50,000",
    performance: 25,
    action: "",
  },
  {
    id: 17,
    member_name: "Badr al-din",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 18,
    member_name: "Fatahallah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 19,
    member_name: "Brahim",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 20,
    member_name: "Dhikrullah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 21,
    member_name: "Abdus Salam",
    email: "info@yourmail.com",
    status: "Contacted",
    title: "Manager",
    salary: "50,000",
    performance: 25,
    action: "",
  },
  {
    id: 22,
    member_name: "Badr al-din",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 23,
    member_name: "Fatahallah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 24,
    member_name: "Brahim",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
  {
    id: 25,
    member_name: "Dhikrullah",
    email: "info@yourmail.com",
    status: "New Leads",
    title: "Manager",
    salary: "13,122",
    performance: 25,
    action: "",
  },
];

const PerformanceCellRenderer = (params) => {
  const { performance } = params.data;

  const progressPercentage = `${performance}%`;
  const progressBarStyle = { width: progressPercentage };

  return (
    <div className="progress">
      <div
        className="progress-bar-custom"
        role="progressbar"
        style={progressBarStyle}
      >
        {progressPercentage}
      </div>
    </div>
  );
};
const ActionCellRenderer = (params) => {
  return (
    <div class="btn-action">
      <a onClick={() => console.log(params)}>
        {" "}
        <img src={Eyes} />{" "}
      </a>{" "}
      <a onClick={() => console.log(params)}>
        {" "}
        <img src={Edit} />{" "}
      </a>{" "}
      <a onClick={() => console.log(params)}>
        {" "}
        <img src={Delete} />{" "}
      </a>
    </div>
  );
};

export const mockTransactionData = () => {
  return data.map((res, i) => ({
    id: res.id,
    member_name: res.member_name,
    email: res.email,
    status: res.status,
    title: res.title,
    salary: "AED " + res.salary,
    performance: res.performance, // Updated line
    action: res.action,
  }));
};

export const mockTransactionDataColumns = () => {
  let colorIndex = 0;
  return [
    { field: "id", headerName: "ID", width: 100, hide: true }, // Sort by ID in ascending order
    { field: "member_name", headerName: "Member Name", width: 100 },
    {
      field: "email",
      headerName: "Email Address",
      width: 200,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        caseSensitive: true,
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      cellStyle: (params) => {
        const backgroundColor = color[colorIndex++ % color.length];
        return {
          backgroundColor: backgroundColor,
        };
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        cellHeight: 30,
        values: ["Contacted", "New Leads"],
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        cellHeight: 30,
        values: ["Manager", "Admin"],
      },
    },
    { field: "salary", headerName: "Salary", width: 200 },
    {
      field: "performance",
      headerName: "Perfromance",
      width: 200,
      cellRenderer: PerformanceCellRenderer,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      cellRenderer: ActionCellRenderer,
      editable: false,
    },
  ];
};
