import React from "react";

const CircularProgressTasks = ({ completed, total, isDark }) => {
  const radius = 18; // Radius of the circle
  const stroke = 3; // Stroke width
  const normalizedRadius = radius - stroke / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  
  // Handle edge case when total is 0
  const progress = total === 0 ? 0 : (completed / total) * 100;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div data-theme={isDark} style={{ display: "flex", alignItems: "center" }}>
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ marginRight: "8px" }}
      >
        <circle
          stroke="var(--divider-color)" // Background circle color
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          fill="transparent"
          className="circular-task-stroke"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="14px"
          className="circle-progress-text"
        >
          {completed}
        </text>
      </svg>
      <div className="d-flex flex-column gap-3">
        <p className="mb-0">{`Task completed`}</p>
        <p className="mb-0">{`out of ${total}`}</p>
      </div>
    </div>
  );
};

export default CircularProgressTasks;
