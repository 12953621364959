import { v4 as uuidv4 } from "uuid";

export const dndData = [
  {
    type_id: uuidv4(),
    type: "Todo",
    type_count: 3,
    is_completed: false,
    tasks: [
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
    ],
  },
  {
    type_id: uuidv4(),
    type: "In Progress",
    type_count: 2,
    is_completed: false,
    tasks: [
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
    ],
  },
  {
    type_id: uuidv4(),
    type: "Done",
    type_count: 3,
    is_completed: true,
    tasks: [
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
      {
        task_id: uuidv4(),
        task_description: "Attend meeting with lorem lipsum dollar dummy",
        users: [
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
          {
            user_id: uuidv4(),
            user_name: "Muhammad Bilal",
            avatar: null,
          },
        ],
      },
    ],
  },
];
