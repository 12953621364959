import React from "react";
import BalanceSheet from "./components/BalanceSheet";

const BalanceSheetMain = (props) => {
  return (
    <BalanceSheet
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default BalanceSheetMain;
