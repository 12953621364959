// Constants for task priorities list
export const TASK_PRIORITIES_LIST_REQUEST = "TASK_PRIORITIES_LIST_REQUEST";
export const TASK_PRIORITIES_LIST_SUCCESS = "TASK_PRIORITIES_LIST_SUCCESS";
export const TASK_PRIORITIES_LIST_FAIL = "TASK_PRIORITIES_LIST_FAIL";

export const TASK_TYPES_LIST_REQUEST = "TASK_TYPES_LIST_REQUEST";
export const TASK_TYPES_LIST_SUCCESS = "TASK_TYPES_LIST_SUCCESS";
export const TASK_TYPES_LIST_FAIL = "TASK_TYPES_LIST_FAIL";

export const TASK_LIST_REQUEST = "TASK_LIST_REQUEST";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAIL = "TASK_LIST_FAIL";

export const TASK_UPDATE_REQUEST = "TASK_UPDATE_REQUEST";
export const TASK_UPDATE_SUCCESS = "TASK_UPDATE_SUCCESS";
export const TASK_UPDATE_FAIL = "TASK_UPDATE_FAIL";

// Add item to array
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
