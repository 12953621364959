export const getIconCode = (moduleName) => {
  switch (moduleName) {
    case "crm-leads":
      return "lets-icons:user-light";
    case "crm-contacts":
      return "system-uicons:contacts";
    case "crm-deals":
      return "solar:ticket-sale-linear";
    case "crm-accounts":
      return "teenyicons:users-outline";
    case "crm-marketing":
      return "circum:microphone-on";
    case "crm-live-chat":
      return "ph:chats-thin";
    case "crm-integrations":
      return "fluent:plug-connected-20-regular";
    case "task-management-porojects":
      return "octicon:project-24";
    case "task-management-tasks":
      return "fluent:tasks-app-28-regular";
    case "task-management-timeline":
      return "material-symbols-light:timeline";
    case "task-management-teams":
      return "ph:users-four-thin";
    case "accounts-expenses":
      return "arcticons:day-to-day-expenses";
    case "accounts-invoices":
      return "iconamoon:invoice-thin";
    case "accounts-chart-of-accounts":
      return "material-symbols-light:pie-chart-outline";
    case "accounts-balance-sheet":
      return "material-symbols-light:flowsheet-outline";
    case "accounts-pl":
      return "arcticons:score-sheets";
    case "accounts-general-ledger":
      return "emojione-monotone:ledger";
    case "goals-my-goal":
      return "ph:target-thin";
    case "goals-setting":
      return "lets-icons:setting-line-light";
    case "supply-chain-inventory":
      return "material-symbols-light:inventory-2-outline-rounded";
    case "hr-recruiter":
      return "fluent-mdl2:account-management";
    case "company-employees":
      return "ph:users-light";
    case "company-roles":
      return "carbon:user-role";
    case "company-subscriptions":
      return "material-symbols-light:unsubscribe-outline-sharp";
    case "booking":
      return "healthicons:book-outline";
    case "plugins":
      return "clarity:plugin-outline-badged";
    case "team-performance":
      return "clarity:employee-group-line";
    default:
      // Default to a generic icon code if the moduleName doesn't match any case
      return "default-icon-code";
  }
};
