import React from "react";
import VerifyInviteEmailMain from "./components/VerifyInviteEmailMain";

const VerifyInviteEmail = (props) => {
  return (
    <VerifyInviteEmailMain
      isDark={props.isDark}
      setCompanySlug={props?.setCompanySlug}
      companySlug={props?.companySlug}
    />
  );
};

export default VerifyInviteEmail;
