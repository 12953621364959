import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import { Modal, ListGroup, InputGroup, Spinner } from "react-bootstrap";

const MessageForwardModal = ({
  show,
  onClose,
  userList,
  isDark,
  handleForwardMessage,
  forwardLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSelectUser = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleSend = async () => {
    // Implement the send message functionality
    const close = () => onClose();
    handleForwardMessage(selectedUsers, close);
  };

  const filteredUsers = userList.filter((user) =>
    user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      show={show}
      //   onHide={onClose}
      centered
      data-theme={isDark}
      className="forward-user-list-modal"
    >
      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          Forward <span>Message</span>
        </p>
        <button className="custom-close-button" onClick={onClose}>
          <Icon icon="maki:cross" />
        </button>
      </div>
      <Modal.Body>
        <InputGroup className="mb-3">
          <div className="w-100 form-group">
            <div className="w-100 forward-input-search position-relative">
              <input
                placeholder="Search"
                className="w-100"
                type="text"
                name="searchText"
                value={searchTerm}
                onChange={handleSearch}
                autoComplete="off"
              />
              <div className="position-absolute advanced-input-search-icon">
                <Icon icon="mdi:search" fontSize={20} />
              </div>
            </div>
          </div>
        </InputGroup>
        <ListGroup className="list-group-forward-users custom-horizental-scrollbar">
          {filteredUsers && filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <ListGroup.Item
                key={user.user_id}
                className="d-flex align-items-center justify-content-between forward-message-user"
                onClick={() => toggleSelectUser(user.user_id)}
              >
                <div className="d-flex align-items-center">
                  {user?.user_profile_image ? (
                    <img
                      className="user-list-forward-user-image me-2"
                      src={user?.user_profile_image}
                      alt="User Profile"
                    />
                  ) : (
                    <div className="user-list-forward-user-image-name me-2">
                      {user?.user_name[0]?.toUpperCase()}
                      {user?.user_name?.split(" ")[1]
                        ? user?.user_name?.split(" ")[1][0]?.toUpperCase()
                        : user?.user_name[1]?.toUpperCase()}
                    </div>
                  )}

                  <span>{user.user_name}</span>
                </div>

                <label className="control-task control-task-checkbox control-select-checkbox ps-1">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.user_id)} // Optional: for pre-selection
                    onChange={() => toggleSelectUser(user.user_id)}
                  />
                  <div className="control_task_indicator control-select-indicator"></div>
                </label>
              </ListGroup.Item>
            ))
          ) : (
            <p className="forward-no-user">No users found.</p>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer className="forward-user-modal-footer">
        <button
          className="w-100 d-flex align-items-center justify-content-center gap-2 forward-message-button"
          onClick={handleSend}
          disabled={!selectedUsers.length || forwardLoading}
        >
          <Icon icon="bi:send-fill" /> Send Message{" "}
          {forwardLoading && <Spinner size="sm" />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageForwardModal;
