import Logo from "../assets/iffra-logo.png";
import { dateFormatter } from "./dateFormatter";
import html2pdf from 'html2pdf.js/dist/html2pdf.min.js';

export const generateHTML = (rowData, applicationName) => {
  // Replace placeholders in the HTML with dynamic data from the table row
  const dynamicHTML = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Payment</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
          rel="stylesheet"
        />
      </head>
      <body style="background-color: #e9eeff; padding: 0; margin: 0">
        <div
          style="
            margin: 0 auto;
            border: none;
            background: #fff;
            font-family: 'Poppins', sans-serif;
            width: 690px;
          "
        >
          <div style="text-align: center; border-bottom: 1px solid #e9eeff">
            <img style="padding: 20px; width: 170px" src=${Logo} />
          </div>
          <h2
            style="
              font-size: 36px;
              color: #000;
              text-align: center;
              padding: 20px 0 0px 0;
              margin: 0;
            "
          >
            Payment Receipt
          </h2>
          <p
            style="text-align: center; font-size: 16px; padding: 0 25px; margin: 0"
          >
            We would like to express our gratitude for your recent payment. Your
            transaction has been processed successfully.
          </p>
          <span style="display: block; text-align: left; padding-left: 25px;">
            Here are the details of your payment receipt:</span
          >
          <h6 style="padding-left: 25px; display: block; text-align: left; margin: 0; font-size: 16px; margin-top: 10px;margin-bottom:5px">
              Billing Details:
            </h6>
          <div style="width: 50%; float: left">
            <ul
              style="
                list-style: none;
                padding: 0;
                margin: 0;
                // margin-top: 44px;
                padding-left: 25px;
              "
            >
              <li style="padding: 5px 0; font-size: 12px">
                Receipt Number: <strong> ${rowData?.receiptNumber} </strong>
              </li>
              <li style="padding: 5px 0; font-size: 12px">
                Transaction Date: <strong> ${dateFormatter(
                  rowData?.transactionDate,
                  2
                )} </strong>
              </li>
            </ul>
          </div>
          <div
            style="width: 45%; float: left; text-align: right; padding-right: 25px"
          >
            
            <ul style="list-style: none; margin: 0; padding: 0">
              <li style="padding: 5px 0; font-size: 12px">
                Customer Name: <strong> ${rowData?.customerName} </strong>
              </li>
              <li style="padding: 5px 0; font-size: 12px">
                Email: <strong> ${rowData?.customerEmail} </strong>
              </li>
            </ul>
          </div>
          <div style="clear: both"></div>
          <div style="padding: 15px">
            <table width="100%" border="1" style="border: 1px solid #94abff">
              <tr style="border: 1px solid #94abff">
                <td
                  height="40"
                  colspan="2"
                  style="
                    background: #94abff;
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: center;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                  Details of Your Purchase:
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Package Description
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                  ${rowData?.packageDescription}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Seats
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.seatCount}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Payment Cycle
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.paymentCycle}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-right: 1px solid #94abff;
                    color: #94abff;
                  "
                >
                  Total Amount
                </td>
                <td
                  style="
                    padding-right: 15px;
                    font-size: 20px;
                    font-weight: bold;
                    text-align: right;
                    border: none;
                    color: #94abff;
                  "
                >
                  $${rowData?.totalAmount}
                </td>
              </tr>
            </table>
          </div>
          <div style="padding: 15px">
            <table width="100%" border="1" style="border: 1px solid #94abff">
              <tr style="border: 1px solid #94abff">
                <td
                  height="40"
                  colspan="2"
                  style="
                    background: #94abff;
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: center;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                  Payment Information
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Payment Method
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                  Stripe
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Cardholder Name:
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.customerName}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Card Type:
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.paymentMethod}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Last Four Digits
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.paymentCardNumber}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Expiry Date:
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.paymentCardExpiry}
                </td>
              </tr>
            </table>
          </div>
          <div style="padding: 15px">
            <table width="100%" border="1" style="border: 1px solid #94abff">
              <tr style="border: 1px solid #94abff">
                <td
                  height="40"
                  colspan="2"
                  style="
                    background: #94abff;
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: center;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                  Transaction Summary
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Payment Status
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.paymentStatus}
                </td>
              </tr>
              <tr style="border: none">
                <td
                  height="40"
                  style="
                    padding-left: 15px;
                    border: none;
                    border-bottom: 1px solid #94abff;
                    border-right: 1px solid #94abff;
                  "
                >
                  Transaction ID:
                </td>
                <td
                  style="
                    padding-right: 15px;
                    text-align: right;
                    border: none;
                    border-bottom: 1px solid #94abff;
                  "
                >
                ${rowData?.transactionId}
                </td>
              </tr>
            </table>
          </div>
         
          <p
            style="
              padding: 0 25px;
              text-align: left;
              font-size: 14px;
              padding-bottom: 40px;
              line-height: 22px;
              font-weight: bold;
            "
          >
            Thank you for choosing ${applicationName}. We appreciate your business.
          </p>
        </div>
      </body>
    </html>
    `;

  // Now, convert the HTML to PDF using html2pdf
  const pdfLink = document.createElement("a");
  html2pdf(dynamicHTML, {
    margin: 10,
    filename: "receipt.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  }).then((pdf) => {
    pdf.output("blob").then((pdfBlob) => {
      pdfLink.href = window.URL.createObjectURL(pdfBlob);
      pdfLink.download = "receipt.pdf";
      document.body.appendChild(pdfLink);
      pdfLink.click();
      document.body.removeChild(pdfLink);
    });
  });
};
