import axios from "axios";
import { getRetryModal } from "../components/RetryModal/RetryContext";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,

  headers: {
    responseType: "json", // default
    Accept: "application/json",
  },
});
API.interceptors.response.use(
  (response) => response,
  (error) => {
    const showRetryModal = getRetryModal(); // Use context access function

    if (error.response && error.response.status >= 400) {
      if (!error.config.url.includes("users/device-token/update")) {
        showRetryModal(() => API.request(error.config)); // Pass the retry logic
      }
    }

    return Promise.reject(error);
  }
);
// Interceptor for request headers
API.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
});

// Interceptor for successful responses
API.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response;
    }
    return response;
  },
  function (error) {
    const statusCode = error.response ? error.response.status : null;
    // const navigate = useNavigate();
    if (statusCode === 401) {
      localStorage.removeItem("access_token");
    }
  }
);

//-----------------------------------------------------------------------

// Project module API
export const ProjectAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_PROJECT_MODULE_API, // Change this to your desired base URL
  headers: {
    responseType: "json", // default
    Accept: "application/json",
  },
});

// Interceptor for request headers
ProjectAPI.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

// Interceptor for request headers for ProjectAPI
ProjectAPI.interceptors.request.use((config) => {
  // Add any additional headers or modifications specific to ProjectAPI
  return config;
});

// Interceptor for successful responses for ProjectAPI
ProjectAPI.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response;
    }
    return response;
  },
  function (error) {
    // Handle errors specific to ProjectAPI
    return Promise.reject(error);
  }
);

//-----------------------------------------------------------------------

// Project module API
export const ProjectFormDataAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_PROJECT_MODULE_API, // Change this to your desired base URL
  headers: {
    responseType: "json", // default
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

// Interceptor for request headers
ProjectFormDataAPI.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

// Interceptor for request headers for ProjectFormDataAPI
ProjectFormDataAPI.interceptors.request.use((config) => {
  // Add any additional headers or modifications specific to ProjectFormDataAPI
  return config;
});

// Interceptor for successful responses for ProjectFormDataAPI
ProjectFormDataAPI.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response;
    }
    return response;
  },
  function (error) {
    // Handle errors specific to ProjectFormDataAPI
    return Promise.reject(error);
  }
);

//-----------------------------------------------------------------------

export default API;
