import { useState, useEffect, useCallback } from "react";
import "./style.css";

// const APP_DEEP_LINK = "myapp://home"; // Replace with your actual app deep link
const APP_STORE_LINK =
  "https://apps.apple.com/pk/app/iffra-task-pro/id6740398175"; // iOS App Store link
const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.softlinks.iffra"; // Android Play Store link

const MobileAppBanner = () => {
  const [deviceType, setDeviceType] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Detect mobile device type
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setDeviceType("ios");
    } else if (/android/.test(userAgent)) {
      setDeviceType("android");
    }

    // Check screen size on resize
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const dashboardWrapper = document.querySelector(".dashboard-area-wraper");

    if (isMobileView && dashboardWrapper) {
      const currentMargin = parseInt(
        window.getComputedStyle(dashboardWrapper).marginTop
      );
      dashboardWrapper.style.marginTop = `${currentMargin + 60}px`;
    } else if (dashboardWrapper) {
      dashboardWrapper.style.marginTop = ""; // Reset to default
    }
  }, [isMobileView]);

  const handleOpenApp = useCallback(() => {
    if (!deviceType) return;

    // const startTime = new Date().getTime();
    // let timeout;

    // // Open the app
    // window.location.href = APP_DEEP_LINK;

    // Set timeout to check if the app is installed
    // timeout = setTimeout(() => {
    //   const elapsedTime = new Date().getTime() - startTime;

    //   // If the app did not open (i.e., user is still on browser), redirect to store
    //   if (elapsedTime < 1500) {
    if (deviceType === "ios") {
      window.location.href = APP_STORE_LINK;
    } else if (deviceType === "android") {
      window.location.href = PLAY_STORE_LINK;
    }
    //   }
    // }, 1500);

    // Cancel fallback if the user interacts with the page
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // clearTimeout(timeout);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [deviceType]);

  if (!isMobileView) return null; // Hide banner if not on mobile

  return (
    <div className="mobile-app-banner">
      <span>Get our mobile app for a better experience!</span>
      <button onClick={handleOpenApp}>Open App</button>
    </div>
  );
};

export default MobileAppBanner;
