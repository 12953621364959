import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/Bell_fill.svg";

const DeleteUnSubscribeModel = (props) => {
  const [dload, setDLoad] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleSubmit = async () => {
    try {
      setDLoad(true);
      await props.handleSubmit(props.billingId);
      setConfirm(false);
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };
  return !confirm ? (
    <Modal
      show={props.showDeleteModal}
      onHide={() => {
        props.handleCloseDeleteModal();
        setConfirm(false);
      }}
      className="main-delete-modal delete-confirm-modal delete-sub-modal-main"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="" closeButton>
        <div className="payment-method-wrap module-modal-wrap">
          <h3 className="module-select-heading">
            {" "}
            <span>Unsubscribe</span> From Package{" "}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div className="popup-delete-card-wraper">
          <h3>{props?.unsubOne?.short_description}</h3>
          <h3 className="unsub-pc">{props?.unsubOne?.long_description}</h3>
          <div className="choose-continue-btn choose-continue-btn-margin">
            <div className="next-button-wraper module-btn-wrapper">
              <button
                className="w-25"
                disabled={dload}
                onClick={() => setConfirm(true)}
              >
                {dload ? (
                  <Spinner
                    style={{ width: "1rem", height: "1rem" }}
                    animation="border"
                  />
                ) : (
                  props.buttonTitle
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      show={props.showDeleteModal}
      onHide={() => {
        props.handleCloseDeleteModal();
        setConfirm(false);
      }}
      className="main-delete-modal unsubscribe-modal unsub-main-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div
          className="del-modal-img-container d-flex align-items-center justify-content-center mb-3"
          style={{
            width: "82px",
            height: "82px",
            backgroundColor: "#EFF2FF",
            borderRadius: "50%",
          }}
        >
          <img
            src={DeleteIcon}
            style={{ width: "30px", height: "30px", margin: 0 }}
            alt="bell-icon"
          />
        </div>

        <h6 style={{ fontSize: "16px !important" }} className="text-center">
          {props?.unsub?.long_description}
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            disabled={dload}
            className="w-50 delete-btn-1"
            onClick={handleSubmit}
          >
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Ok"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={() => {
              props.handleCloseDeleteModal();
              setConfirm(false);
            }}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUnSubscribeModel;
