import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { ProjectAPI } from "./../../../services/ClientApi";
import { toast } from "react-toastify";

const DeleteCommentModal = (props) => {
  const [dload, setDLoad] = useState(false);

  const handleSubmit = async () => {
    try {
      setDLoad(true);
      const formData = new FormData();
      formData.append("taskId", props.taskId);
      formData.append("commentId", props.commentId);

      const response = await ProjectAPI.post("task/delete-comment", {
        taskId: props.taskId,
        commentId: props.commentId,
      });

      if (response?.data?.status === "success") {
        // First, filter out the comment with a matching comId directly in the comments array
        let updatedComments = props.comments.filter(
          (comment) => comment.comId !== props.commentId
        );

        // If no comment was removed, look for the comId in the replies of each comment
        if (updatedComments.length === props.comments.length) {
          updatedComments = props.comments.map((comment) => {
            // Filter out the matching reply in the replies array
            const updatedReplies = comment.replies.filter(
              (reply) => reply.comId !== props.commentId
            );

            // Return the updated comment with filtered replies
            return {
              ...comment,
              replies: updatedReplies,
            };
          });
        }

        // Update the state with the filtered comments
        props.setComments(updatedComments);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
      toast.error("Error deleting comment", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        // hideProgressBar: true,
        // closeButton: false,
      });
    } finally {
      setDLoad(false);
      props.handleCloseDeleteModal();
    }
  };
  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete this</h5>

        <p className="del-modal-name">comment</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={handleSubmit}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCommentModal;
