import axios from "axios";
import {
  PROJECT_LISTING_SUCCESS,
  PROJECT_LISTING_REQUEST,
  PROJECT_LISTING_NOLOAD_REQUEST,
  PROJECT_LISTING_FAIL,
  PROJECT_DETAIL_REQUEST,
  PROJECT_DETAIL_SUCCESS,
  PROJECT_DETAIL_FAIL,
  CREATE_PROJECT_FAIL,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_FAIL,
  SHARE_PROJECT_SUCCESS,
  SHARE_PROJECT_REQUEST,
  SHARE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_FAIL,
  CREATE_PROJECT_TYPES_SUCCESS,
  CREATE_PROJECT_TYPES_REQUEST,
  CREATE_PROJECT_TYPES_FAIL,
  CREATE_PROJECT_USERS_SUCCESS,
  CREATE_PROJECT_USERS_REQUEST,
  CREATE_PROJECT_USERS_FAIL,
  PROJECT_LISTING_STATUS_REQUEST,
  PROJECT_LISTING_STATUS_SUCCESS,
  PROJECT_LISTING_STATUS_FAIL,
  PROJECT_FORM_LISTING_FAIL,
  PROJECT_FORM_LISTING_REQUEST,
  PROJECT_FORM_LISTING_SUCCESS,
  CLEAR_PROJECT_ERROR,
  PROJECT_UPDATE_REQUEST,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAIL,
  ADD_PROJECT_SUCCESS,
  PROJECT_LISTING_ALL_STATUS_FAIL,
  PROJECT_LISTING_ALL_STATUS_REQUEST,
  PROJECT_LISTING_ALL_STATUS_SUCCESS,
} from "../constants/projectConstants";

import API, { ProjectAPI, ProjectFormDataAPI } from "../services/ClientApi";
import {
  projectListingCacheAddRecord,
  projectListingCacheDeleteRecord,
  projectListingCacheFetchFailure,
  projectListingCacheFetchRequest,
  projectListingCacheFetchSuccess,
  projectListingCacheUpdateRecord,
} from "./projectListingCacheActions";
import {
  deleteProjectCompleted,
  editProjectCompleted,
} from "./projectCompletedListingActions";

// Action to get project detail
export const projectDetailAction = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_DETAIL_REQUEST });
    const { data } = await API.get("");
    dispatch({
      type: PROJECT_DETAIL_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: PROJECT_DETAIL_FAIL,
      payload: error,
    });
  }
};

// Action to get project listing
export const projectListingAction = (params) => async (dispatch, getState) => {
  try {
    const state = getState();
    const cachedProjects =
      state?.projectCachedList?.projectListingCacheData[
        `${params.pageSize}_${params.currentPage}`
      ];
    if (cachedProjects) {
      return;
    }
    dispatch(projectListingCacheFetchRequest());
    if (params?.noLoad) {
      dispatch({ type: PROJECT_LISTING_NOLOAD_REQUEST });
    } else {
      dispatch({ type: PROJECT_LISTING_REQUEST });
    }
    const { data } = await ProjectAPI.get(
      `project/pm-projects?page=${params.currentPage}&limit=${params.pageSize}`
    );
    dispatch({
      type: PROJECT_LISTING_SUCCESS,
      payload: data,
    });
    dispatch(
      projectListingCacheFetchSuccess(data, params.pageSize, params.currentPage)
    );

    return data;
  } catch (error) {
    dispatch(projectListingCacheFetchFailure(error));
    dispatch({
      type: PROJECT_LISTING_FAIL,
      payload: error,
      meta: {
        retryAction: projectListingAction(params), // Retry action
      },
    });
  }
};

// Action to get project listing
export const projectFormListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FORM_LISTING_REQUEST });

    const { data } = await ProjectAPI.post("project/assignment");
    dispatch({
      type: PROJECT_FORM_LISTING_SUCCESS,
      payload: data?.data,
    });
    return data?.pdata;
  } catch (error) {
    dispatch({
      type: PROJECT_FORM_LISTING_FAIL,
      payload: error,
      meta: {
        retryAction: projectFormListingAction(), // Retry action
      },
    });
  }
};

// Action to get project listing
export const emptyProjectFormListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FORM_LISTING_REQUEST });

    dispatch({
      type: PROJECT_FORM_LISTING_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_FORM_LISTING_FAIL,
      payload: error,
      meta: {
        retryAction: emptyProjectFormListingAction(), // Retry action
      },
    });
  }
};

// Action to create project
export const createProjectAction = (projectData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await ProjectFormDataAPI.post(
      `project/pm-create-project`,
      projectData
    );
    if (data.success === true) {
      // dispatch(projectListingCacheAddRecord())
      dispatch({ type: CREATE_PROJECT_SUCCESS, payload: data });
      return data;
    }
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_FAIL,
      payload: error,
      meta: {
        retryAction: createProjectAction(projectData), // Retry action
      },
    });
    return error;
  }
};

// Action to edit project
export const editProjectAction = (projectData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_PROJECT_REQUEST });

    const response = await ProjectAPI.post(
      `project/pm-update-project`,
      projectData
    );

    dispatch({ type: EDIT_PROJECT_SUCCESS, payload: response.data });
    dispatch(
      projectListingCacheUpdateRecord(
        projectData?.projectId,
        response?.data?.project
      )
    );
    dispatch(
      editProjectCompleted(projectData?.projectId, response?.data?.project)
    );
  } catch (error) {
    dispatch({
      type: EDIT_PROJECT_FAIL,
      payload: error,
      meta: {
        retryAction: editProjectAction(projectData), // Retry action
      },
    });
    return error;
  }
};

// Action to share project
export const shareProjectAction = (projectData) => async (dispatch) => {
  try {
    dispatch({ type: SHARE_PROJECT_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}`,
      projectData,
      config
    );
    if (data.status === "success") {
      dispatch({ type: SHARE_PROJECT_SUCCESS, payload: data });
      return data;
    }
  } catch (error) {
    dispatch({
      type: SHARE_PROJECT_FAIL,
      payload: error,
      meta: {
        retryAction: shareProjectAction(projectData), // Retry action
      },
    });
    return error;
  }
};

// // Action to delete project
// export const deleteProjectAction = (projectData) => async (dispatch) => {
//   try {
//     dispatch({ type: DELETE_PROJECT_REQUEST });

//     const config = {
//       headers: {
//         "Content-type": "application/json",
//       },
//     };

//     const { data } = await axios.post(
//       `${process.env.REACT_APP_BACKEND_API}`,
//       projectData,
//       config
//     );
//     if (data.status === "success") {
//       dispatch({ type: DELETE_PROJECT_SUCCESS, payload: data });
//       return data;
//     }
//   } catch (error) {
//     dispatch({
//       type: DELETE_PROJECT_FAIL,
//       payload: error,
//     });
//     return error;
//   }
// };

// Action to get project user listing
export const projectUserListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_USERS_REQUEST });
    const data = await ProjectAPI.get("teams/pm-users");
    dispatch({
      type: CREATE_PROJECT_USERS_SUCCESS,
      payload: data.data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_USERS_FAIL,
      payload: error,
      meta: {
        retryAction: projectUserListingAction(), // Retry action
      },
    });
  }
};

// Action to empty project user listing
export const emptyProjectUserListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_USERS_REQUEST });
    dispatch({
      type: CREATE_PROJECT_USERS_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_USERS_FAIL,
      payload: error,
      meta: {
        retryAction: emptyProjectUserListingAction(), // Retry action
      },
    });
  }
};

// Action to get project type listing
export const projectTypeListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_TYPES_REQUEST });
    const data = await ProjectAPI.get("general/pm-members-types");
    dispatch({
      type: CREATE_PROJECT_TYPES_SUCCESS,
      payload: data?.data?.data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_TYPES_FAIL,
      payload: error,
      meta: {
        retryAction: projectTypeListingAction(), // Retry action
      },
    });
  }
};

// Action to empty project type listing
export const emptyProjectTypeListingAction = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_TYPES_REQUEST });
    dispatch({
      type: CREATE_PROJECT_TYPES_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_TYPES_FAIL,
      payload: error,
      meta: {
        retryAction: emptyProjectTypeListingAction(), // Retry action
      },
    });
  }
};

// Action for project statuses
export const projectListingStatusAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LISTING_STATUS_REQUEST });

    const { data } = await ProjectAPI.get("general/pm-statues");
    dispatch({
      type: PROJECT_LISTING_STATUS_SUCCESS,
      payload: data?.data,
    });

    return data?.data;
  } catch (error) {
    dispatch({
      type: PROJECT_LISTING_STATUS_FAIL,
      payload: error,
      meta: {
        retryAction: projectListingStatusAction(), // Retry action
      },
    });
  }
};

// Action for project statuses
export const projectAllListingStatusAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LISTING_ALL_STATUS_REQUEST });

    const { data } = await ProjectAPI.get("general/pm-all-statues");
    dispatch({
      type: PROJECT_LISTING_ALL_STATUS_SUCCESS,
      payload: data?.data,
    });

    return data?.data;
  } catch (error) {
    dispatch({
      type: PROJECT_LISTING_ALL_STATUS_FAIL,
      payload: error,
      meta: {
        retryAction: projectAllListingStatusAction(params), // Retry action
      },
    });
  }
};

// Action for project statuses
export const emptyProjectListingStatusAction = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LISTING_STATUS_REQUEST });
    dispatch({
      type: PROJECT_LISTING_STATUS_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_LISTING_STATUS_FAIL,
      payload: error,
      meta: {
        retryAction: emptyProjectListingStatusAction(), // Retry action
      },
    });
  }
};

export const emptyProjectListingAllStatusAction = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LISTING_ALL_STATUS_REQUEST });
    dispatch({
      type: PROJECT_LISTING_ALL_STATUS_SUCCESS,
      payload: null, // Reset payload
    });
  } catch (error) {
    dispatch({
      type: PROJECT_LISTING_ALL_STATUS_FAIL,
      payload: error, // Dispatch the error in case of failure
      meta: {
        retryAction: emptyProjectListingAllStatusAction(), // Retry action
      },
    });
  }
};

// action to update a project
export const updateProjectAction =
  (updatedProject) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROJECT_UPDATE_REQUEST });

      // Simulating the updated project response from the API
      const data = updatedProject;

      // Update the project list in the state
      let { projectList } = getState().projectListingData;

      projectList = {
        ...projectList,
        data: projectList?.data?.map((project) =>
          project.projectId === data.projectId ? data : project
        ),
      };

      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: projectList,
      });

      dispatch(projectListingCacheUpdateRecord(data.projectId, data));
      dispatch(editProjectCompleted(data.projectId, data));

      return data;
    } catch (error) {
      dispatch({
        type: PROJECT_UPDATE_FAIL,
        payload: error,
        meta: {
          retryAction: updateProjectAction(updatedProject), // Retry action
        },
      });
    }
  };

// action to delete multiple projects by an array of IDs
export const deleteProjectAction =
  (projectIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROJECT_LISTING_REQUEST }); // Start loading state

      // Assuming successful deletion, update the local state
      const state = getState();

      if (
        state &&
        state.projectListingData &&
        state.projectListingData.projectList
      ) {
        const updatedProjectList = state.projectListingData.projectList.filter(
          (project) => !projectIds.includes(project.projectId) // Keep projects NOT in projectIds
        );

        dispatch({
          type: PROJECT_LISTING_SUCCESS,
          payload: updatedProjectList,
        });

        // Update the project list in the cache
        projectIds.forEach((projectId) => {
          dispatch(projectListingCacheDeleteRecord(projectId));
          dispatch(deleteProjectCompleted(projectId));
        });
      } else {
        // Handle the case where projectList is undefined
        dispatch({
          type: PROJECT_LISTING_FAIL,
          payload: "Project list is undefined",
        });
      }
    } catch (error) {
      // Handle errors
      dispatch({
        type: PROJECT_LISTING_FAIL,
        payload: error.message,
        meta: {
          retryAction: deleteProjectAction(projectIds), // Retry action
        },
      });
    }
  };

// Add project to list
export const addProjectAction = (projectData) => (dispatch) => {
  dispatch({
    type: ADD_PROJECT_SUCCESS,
    payload: projectData,
  });
};

// action to clear the entire task list
export const clearProjectListAction = () => (dispatch) => {
  dispatch({
    type: PROJECT_LISTING_SUCCESS,
    payload: null, // Set the taskList to an empty array
  });
};
