/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/dashboard.scss";
import EditWhite from "../../../assets/edit-white.svg";
import TMImage from "../../../assets/dashboard-create-task.png";
// import TMImage from "../../../assets/team-member-image.png";
import CreateIcon from "../../../assets/create-task-icon.png";
import ProfileImage from "../../../assets/profile-image.png";
import assignedmeimage from "../../../assets/assigned-me-icon-1.svg";
import assignedmeimage1 from "../../../assets/assigned-me-icon-2.svg";
import assignedmeimage2 from "../../../assets/assigned-me-icon-3.svg";
import assignedmeimage3 from "../../../assets/assigned-me-icon-4.svg";
import arrowtop from "../../../assets/arrow-top.svg";

import IterpolationChart from "./InterpolationChart";
import API from "../../../services/ClientApi";
import userProfileBlank from "../../../assets/blank_profile.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/infoMain.svg";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import { useGetDashboardDataQuery } from "../../../slices/dashboard/dashboardSlice";
import DashboardTableListing from "./DashboardTableListing";
import {
  projectFormListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import {
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";
import NoTaskData from "../../../assets/no-dashboard-tasks.png";
import DashboardNotifications from "./DashboardNotifications";
import { ShimmerTable } from "react-shimmer-effects";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import CreateTaskModal from "../../Tasks/components/CreateTaskModal";
import { isPermitted } from "../../../helper/isPermitted";
import { color } from "../../Tasks/components/utils/data";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const DashboardMain = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: dashboardData, isLoading } = useGetDashboardDataQuery(
    {},
    { skip: !localStorage.getItem("access_token") }
  );
  const [user, setUser] = useState(null);
  const [showTask, setShowTask] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [load, setLoad] = useState(false);
  const { applicationName } = useSelector((state) => state.text);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState({
    label: "This Week",
    value: "this_week",
  });
  const { modalContents } = useSelector((state) => state?.modalData);
  const verifyModalContent = findObjectByContentTitle(
    "Verify Email",
    modalContents?.data
  );
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );

  const handleCloseTaskModal = () => {
    setShowTask(false);
  };

  const getBoardingData = async () => {
    try {
      if (!projectUserList) {
        dispatch(projectUserListingAction());
      }
      if (!taskPriorities) {
        dispatch(taskPrioritiesListAction());
      }
      if (!taskTypes) {
        dispatch(taskTypesListAction());
      }
      if (!projectTypeList) {
        dispatch(projectTypeListingAction());
      }
      if (!projectFormList) {
        dispatch(projectFormListingAction());
      }
      if (!projectStatusList) {
        dispatch(projectListingStatusAction());
      }
    } catch (error) {
    } finally {
      // setLoad(false);
      // setMainLoad(false);
    }
  };

  useEffect(() => {
    getBoardingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!subscriptionDetail) {
  //     getPlanData();
  //   }
  // }, [billingDetail]);

  // useEffect(() => {
  //   if (subscriptionDetail) {
  //     setPlanData(subscriptionDetail?.packageDetails);
  //   }
  // }, [subscriptionDetail]);

  const resendEmail = async () => {
    try {
      setLoad(true);
      const response = await API.post("users/resend-email-verification");
      if (response?.data?.status === "success") {
        toast.success("Verification mail sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setShowVerifyEmailModal(false);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const handleVerifyEmailModalClose = () => {
    setShowVerifyEmailModal(false);
  };

  useEffect(() => {
    setUser(userProfile);
  }, [userProfile]);

  const handleImageError = (event) => {
    // Handle broken image scenario
    event.target.src = ProfileImage; // Use the imported ProfileNew image
  };

  // Week selection
  // Custom styles
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected
        ? "#deebff"
        : "var(--select-dropdown-option-color)",
      color: state.isSelected
        ? "#333"
        : "var(--select-dropdown-option-font-color)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      width: "120px", // Set the desired width
      backgroundColor: "var(--search-bar-bg)",
    }),
  };

  const graphselectoptions = [
    {
      label: "This Week",
      value: "this_week",
    },
    {
      label: "Last Week",
      value: "last_week",
    },
    // {
    //   label: "This Month",
    //   value: "this_month",
    // },
  ];

  // Priority selection
  const CustomSelectionControl = ({ children, menuIsOpen,...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {children}
          </div>
        ) : (
          children
        )}
        <div className="select-icon me-1"  style={{
          transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease",
        }}>
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const Graphselectdate = () => {
    // Filter out the selected option from the graphselectoptions list
    const filteredGraphOptions = graphselectoptions?.filter(
      (option) => option.value !== selectedWeek?.value
    );

    return (
      <div
        className={`task-situation-filter-top-wrap-select selected-items-priority-section`}
      >
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-situation-filter-top-wrap-select-div`}
            classNamePrefix="select"
            placeholder="Priority"
            value={selectedWeek}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            
            name="color"
            options={filteredGraphOptions} // Use the filtered options
            components={{ Control: CustomSelectionControl }}
            onChange={(e) => {
              setSelectedWeek(e);
            }}
          />
        </div>
      </div>
    );
  };

  // Navigate to task listing on stat click
  const handleStatNavigation = async (
    memberType,
    memberType2,
    memberType3,
    status,
    memberFilter
  ) => {
    if (userProfile?.emailVerified !== 0) {
      const statFilters = {
        [memberType]: [userProfile?.userId],
        status: status,
        memberFilter: memberFilter,
        [memberType2]: [],
        [memberType3]: [],
        priority: "",
        overdue: false,
        multiStatus: [],
        multiPriority: [],
        teamsMembers: [],
        teamsMembersTypes: [],
        taskType: [],
        taskStartDate: "",
        taskEndDate: "",
        selectedDate: "",
        searchText: "",
        taskName: "",
        createdAt: "",
        updatedAt: "",
        projectsIds: [],
        isDeleted: false,
        hasApproval: false,
      };

      // Stringify the object before saving it to sessionStorage
      sessionStorage?.setItem("statFilters", JSON.stringify(statFilters));
      navigate(`/${localStorage.getItem("company")}/task-management-tasks`);
    } else {
      setShowVerifyEmailModal(true);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dasboard-{applicationName}</title>
      </Helmet>
      <div className="row">
        <div className="col-md-9 dash-mobile">
          <div className="left-content-wraper">
            <div className="assgined-boxes-main-wrp">
              <div className="row">
                <div className="col-md-6 assign-me-box-col">
                  <div className="assigned-me-box">
                    <h5>
                      {" "}
                      Assigned <span>to me</span>{" "}
                    </h5>
                    <ul className="row">
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "memberIds",
                              "ownerIds",
                              "observerIds",
                              "not-started",
                              "member"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>Not Started</span>
                            <strong>
                              {dashboardData?.taskStats?.assignedTasksStats
                                ?.notStarted || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "memberIds",
                              "ownerIds",
                              "observerIds",
                              "in-progress",
                              "member"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage1} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>In Progress</span>
                            <strong>
                              {dashboardData?.taskStats?.assignedTasksStats
                                ?.inProgress || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "memberIds",
                              "ownerIds",
                              "observerIds",
                              "on-hold",
                              "member"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage2} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>On Hold</span>
                            <strong>
                              {dashboardData?.taskStats?.assignedTasksStats
                                ?.onHold || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "memberIds",
                              "ownerIds",
                              "observerIds",
                              "review",
                              "member"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage3} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>In Review </span>
                            <strong>
                              {dashboardData?.taskStats?.assignedTasksStats
                                ?.inReview || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="assigned-me-box">
                    <h5>
                      {" "}
                      Assigned <span> by me</span>{" "}
                    </h5>
                    <ul className="row">
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "ownerIds",
                              "memberIds",
                              "observerIds",
                              "not-started",
                              "owner"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>Not Started</span>
                            <strong>
                              {dashboardData?.taskStats?.ownerTasksStats
                                ?.notStarted || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "ownerIds",
                              "memberIds",
                              "observerIds",
                              "in-progress",
                              "owner"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage1} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>In Progress</span>
                            <strong>
                              {dashboardData?.taskStats?.ownerTasksStats
                                ?.inProgress || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "ownerIds",
                              "memberIds",
                              "observerIds",
                              "on-hold",
                              "owner"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage2} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>On Hold</span>
                            <strong>
                              {dashboardData?.taskStats?.ownerTasksStats
                                ?.onHold || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li className="col-md-6">
                        <div
                          className="assgine-me-list"
                          onClick={() => {
                            handleStatNavigation(
                              "ownerIds",
                              "memberIds",
                              "observerIds",
                              "review",
                              "owner"
                            );
                          }}
                        >
                          <div className="assgine-me-icon">
                            <img src={assignedmeimage3} alt="no-data" />
                          </div>
                          <div className="assgine-me-text">
                            <span>In Review </span>
                            <strong>
                              {dashboardData?.taskStats?.ownerTasksStats
                                ?.inReview || 0}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="observer-main-wrap">
              <h4>
                As <span>Observer</span>{" "}
              </h4>
              <ul className="row">
                <li className="col-md-3">
                  <div
                    className="listing-observer-wrap"
                    onClick={() => {
                      handleStatNavigation(
                        "observerIds",
                        "memberIds",
                        "ownerIds",
                        "not-started",
                        "observer"
                      );
                    }}
                  >
                    <div className="listing-observer-image">
                      <img src={assignedmeimage} alt="no-data" />
                    </div>
                    <div className="listing-observer-text">
                      <span>Not Started</span>
                      <strong>
                        {dashboardData?.taskStats?.observerTasksStats
                          ?.notStarted || 0}
                      </strong>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </li>
                <li className="col-md-3">
                  <div
                    className="listing-observer-wrap"
                    onClick={() => {
                      handleStatNavigation(
                        "observerIds",
                        "memberIds",
                        "ownerIds",
                        "in-progress",
                        "observer"
                      );
                    }}
                  >
                    <div className="listing-observer-image">
                      <img src={assignedmeimage1} alt="no-data" />
                    </div>
                    <div className="listing-observer-text">
                      <span>In Progress</span>
                      <strong>
                        {dashboardData?.taskStats?.observerTasksStats
                          ?.inProgress || 0}
                      </strong>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </li>
                <li className="col-md-3">
                  <div
                    className="listing-observer-wrap"
                    onClick={() => {
                      handleStatNavigation(
                        "observerIds",
                        "memberIds",
                        "ownerIds",
                        "on-hold",
                        "observer"
                      );
                    }}
                  >
                    <div className="listing-observer-image">
                      <img src={assignedmeimage2} alt="no-data" />
                    </div>
                    <div className="listing-observer-text">
                      <span>On Hold</span>
                      <strong>
                        {dashboardData?.taskStats?.observerTasksStats?.onHold ||
                          0}
                      </strong>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </li>
                <li className="col-md-3">
                  <div
                    className="listing-observer-wrap"
                    onClick={() => {
                      handleStatNavigation(
                        "observerIds",
                        "memberIds",
                        "ownerIds",
                        "review",
                        "observer"
                      );
                    }}
                  >
                    <div className="listing-observer-image">
                      <img src={assignedmeimage3} alt="no-data" />
                    </div>
                    <div className="listing-observer-text">
                      <span>In Review </span>
                      <strong>
                        {dashboardData?.taskStats?.observerTasksStats
                          ?.inReview || 0}
                      </strong>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="performance-main-wraper">
              <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap mb-2">
                <h2>
                  My <span>Performance</span>
                  {/* <strong>80%</strong> */}
                  {/* <img src={arrowtop} alt="no-data" /> */}
                </h2>

                <div className="dropdown-date">
                  <Graphselectdate />
                </div>
              </div>
              <div className="graph-chart-wrap">
                <IterpolationChart
                  data={dashboardData?.performanceChartData}
                  selectedWeek={selectedWeek}
                />
              </div>
            </div>
            <div className="team-member-table-wrap">
              <h3>
                {" "}
                My <span>Tasks</span>{" "}
              </h3>
              {isLoading ? (
                <div className="bg-white dashboard-table-shimmer">
                  <ShimmerTable row={5} />
                </div>
              ) : (
                <>
                  {dashboardData?.myAllTasks &&
                  dashboardData?.myAllTasks?.length > 0 ? (
                    <div className="table-responsive custom-horizental-scrollbar">
                      <DashboardTableListing
                        rowData={dashboardData?.myAllTasks}
                        selectedTask={selectedTask}
                        setSelectedTask={setSelectedTask}
                        setShowTaskDetails={setShowTaskDetails}
                        showTaskDetails={showTaskDetails}
                        disableAction={false}
                        isDark={props.isDark}
                        setNewId={() => {}}
                        listingType="ALL_TASKS"
                      />
                    </div>
                  ) : (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center no-dashboard-task-data">
                      <img src={NoTaskData} alt="no-data" />
                      <p className="mb-0">No Tasks Yet</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3 no-left-paddding">
          <div className="right-main-wraper">
            <div className="profile-main-wraper">
              <img
                className="profile-image-wraper"
                src={user?.profileImage ? user?.profileImage : userProfileBlank}
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                }}
                onError={handleImageError}
                alt="dashboard-profile"
              ></img>
              <div className="profiel-text-wrap">
                <h4> {userProfile?.userName} </h4>
                <span>{userProfile?.roleName}</span>
                <div className="btn-profile-wrap">
                  {" "}
                  <button
                    className="profile-dash-btn"
                    onClick={() => {
                      if (userProfile?.emailVerified !== 0) {
                        // navigate(`/${localStorage.getItem("company")}/profile`);
                        saveLocalStorageToCookies("/profile");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                    disabled={props.runTour}
                  >
                    {" "}
                    Go To Profile{" "}
                  </button>{" "}
                  <a
                    className="edit-btn"
                    onClick={() => {
                      if (userProfile?.emailVerified !== 0) {
                        // navigate(`/${localStorage.getItem("company")}/profile`);
                        saveLocalStorageToCookies("/profile");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                  >
                    {" "}
                    <img src={EditWhite} alt="dashbord-icons" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            {isPermitted("task/create-new") && (
              <div className="invite-team--member-wraper">
                <div className="invite-team--member-image mb-4">
                  <img src={TMImage} alt="team" />
                </div>
                <h4 className="mb-4"> Plan it. Own it. Get it done! </h4>

                <div className="invite-btn-wrap">
                  {" "}
                  <a
                    onClick={() => {
                      if (userProfile?.emailVerified !== 0) {
                        if (!isTrialActive(userProfile)) {
                          props.setOpenSubscriptionsModal(true);
                        } else {
                          setShowTask(true);
                        }
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                  >
                    {" "}
                    <img
                      src={CreateIcon}
                      alt="dashbord-icons"
                      className="me-1"
                    />{" "}
                    Create Task{" "}
                  </a>{" "}
                </div>
              </div>
            )}
            <div className="recent-task-wrap">
              <h5 className="recent-task-head"> Notifications</h5>
              <DashboardNotifications />
            </div>
          </div>
        </div>
        {showTask && (
          <CreateTaskModal
            show={showTask}
            handleCloseModal={handleCloseTaskModal}
            isDark={props.isDark}
            getBoardData={() => {}}
            setShowTaskDetails={() => {}}
            setNewId={() => {}}
            listing="PROJECT"
          />
        )}
        {showVerifyEmailModal && (
          <Modal
            show={showVerifyEmailModal}
            onHide={handleVerifyEmailModalClose}
            centered
            size="md"
            data-theme={props?.isDark}
            backdrop="static"
            keyboard={false}
            className="main-delete-modal"
          >
            <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
              <div className="del-modal-img-container">
                <img src={DeleteIcon} alt="delete-icon" />
              </div>
              <h5>{verifyModalContent?.model_title}</h5>
              <p>
                {verifyModalContent?.short_description}{" "}
                <a onClick={resendEmail} className="email-verify-anchor">
                  {load ? (
                    <Spinner
                      border="animation"
                      style={{ width: "1rem", height: "1rem" }}
                    />
                  ) : (
                    "Resend"
                  )}
                </a>{" "}
                {verifyModalContent?.long_description}
              </p>
            </Modal.Body>

            <Modal.Footer>
              <div className="w-100 delete-modal-btn-wrapper">
                <button
                  className="w-100 delete-btn-1"
                  onClick={handleVerifyEmailModalClose}
                  disabled={load}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
};

export default DashboardMain;
