/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetStaffSummaryReportQuery } from "../../../slices/projects/projectSlice";
import {
  formatDateToYYYYMMDD,
  formatDeadlineDate,
} from "../../../helper/dateFormatter";
import { toast } from "react-toastify";
import { downloadStaffSummaryAsExcel } from "../../../helper/downloadFileHelpers";
import StaffSummaryFilter from "./StaffSummaryFilter";
import userProfileBlank from "../../../assets/blank_profile.svg";
import { ShimmerTable } from "react-shimmer-effects";
import PerformanceDownloadModal from "./PerformanceDownloadModal";
import dueiconbtn from "../../../assets/due-btn-icon.svg";
import taskinfoicon from "../../../assets/total-task-icon-1.svg";
import taskinfoicon1 from "../../../assets/total-task-icon-2.svg";
import upIcon from "../../../assets/performance_up_icon.png";
import downIcon from "../../../assets/performance_down_icon.png";
import NoTaskData from "../../../assets/no-dashboard-tasks.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import HowPerformanceWorksModal from "../../Dailyreport/modals/HowPerformanceWorksModal";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import StaffSummaryListView from "./StaffSummaryListView";

const StaffSummaryMain = memo(({ props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [view, setView] = useState(1); // 1: tabular, 2: grid
  const { userProfile } = useSelector((state) => state?.profile);
  const [filterType, setFilterType] = useState("this_month");
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [excelLoad, setExcelLoad] = useState(false);
  const [sortOrder, setSortOrder] = useState("normal");
  const [showPerformanceDownloadModal, setShowPerformanceDownloadModal] =
    useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    data: staffSummaryData,
    isLoading,
    isFetching,
    refetch: staffSummaryDataRefetch,
  } = useGetStaffSummaryReportQuery(
    {
      filterType: filterType,
      selectedDateFilter:
        filterType !== "date_range"
          ? formatDateToYYYYMMDD(selectedDateFilter)
          : null,
      startDate: formatDateToYYYYMMDD(startDate),
      endDate: formatDateToYYYYMMDD(endDate),
      sendEmail: false,
    },
    {
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }
  );

  // Filter staff summary data based on search text
  let filteredStaffData = staffSummaryData?.data?.filter((item) =>
    item?.userName.toLowerCase().includes(searchText.toLowerCase())
  );

  // Sort filtered staff data based on taskScore
  const sortFilteredData = (data) => {
    // Only sort if sortOrder is "ascending" or "descending"
    if (sortOrder !== "ascending" && sortOrder !== "descending") {
      return data;
    }

    // Define groups:
    // Group 0: totalTasks === 0
    // Group 1: totalTasks > 0 && performance === 0
    // Group 2: totalTasks > 0 && performance > 0
    const getGroup = (item) => {
      if (item.totalTask === 0) return 0;
      if (item.performance === 0) return 1;
      return 2;
    };

    return [...data].sort((a, b) => {
      const groupA = getGroup(a);
      const groupB = getGroup(b);

      // If records belong to different groups, order based on sortOrder.
      if (groupA !== groupB) {
        if (sortOrder === "ascending") {
          // Ascending: group 0, then group 1, then group 2.
          return groupA - groupB;
        } else {
          // Descending: reverse the group order: group 2, then group 1, then group 0.
          return groupB - groupA;
        }
      }

      // Both records are in the same group.
      // For group 2, sort by performance.
      if (groupA === 2) {
        if (sortOrder === "ascending") {
          return a.performance - b.performance;
        } else {
          return b.performance - a.performance;
        }
      }

      // For groups 0 and 1, the order can remain unchanged (or you can add additional tie-breakers if needed).
      return 0;
    });
  };

  const sortedStaffData = sortFilteredData(filteredStaffData);

  const refreshFilters = () => {
    setSearchText("");
    setSelectedDateFilter(null);
    setStartDate(null);
    setEndDate(null);
    setFilterType("all");
  };

  const handleStatNavigation = async (userId) => {
    const statFilters = {
      memberIds: [userId],
      status: "",
      memberFilter: "member",
      priority: "",
      overdue: false,
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate:
        selectedDateFilter && filterType !== "date_range"
          ? formatDateToYYYYMMDD(selectedDateFilter)
          : "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    };

    // Stringify the object before saving it to sessionStorage
    sessionStorage?.setItem("statFilters", JSON.stringify(statFilters));
    sessionStorage?.setItem(
      "filterType",
      filterType === "date_range" ? "all" : filterType
    );
    navigate(`/${localStorage.getItem("company")}/task-management-tasks`, {
      state: { from: location.pathname },
    });
  };

  const handleDownloadExcel = async () => {
    try {
      setExcelLoad(true);
      const tasks = staffSummaryData?.data || [];

      if (tasks?.length === 0) {
        toast.warn("No members available to download.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }
      const typeTask =
        filterType === "date_range"
          ? `${formatDeadlineDate(startDate)} - ${formatDeadlineDate(endDate)}`
          : selectedDateFilter
          ? formatDeadlineDate(selectedDateFilter)
          : filterType;
      downloadStaffSummaryAsExcel(tasks, typeTask);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to download tasks.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      setExcelLoad(false);
    }
  };

  useEffect(() => {
    if (!isTrialActive(userProfile) && !props.openSubscriptionModal) {
      props.setOpenSubscriptionsModal(true);
    }
  }, [userProfile, props.openSubscriptionModal]);

  return (
    <div className="project-plan-main-wraper p-0" data-theme={props.isDark}>
      <div className="staff-summary-main-wraper">
        <div className="d-flex align-items-center gap-2 mb-3">
          <h5 className="m-0 "> Team Performance </h5>
          <button
            disabled={isLoading}
            onClick={async () => {
              setOpenModal(true);
            }}
            className="download-excel-btn d-flex align-items-center"
            data-tooltip-id={`team-performance-hiw-btn`}
          >
            <i className="fas fa-circle-info m-0"></i>
          </button>
          <ReactTooltip
            id={`team-performance-hiw-btn`}
            place="right"
            content={"How it Works?"}
          />
        </div>
        <StaffSummaryFilter
          props={props}
          filterType={filterType}
          setFilterType={setFilterType}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          refreshFilters={refreshFilters}
          searchText={searchText}
          setSearchText={setSearchText}
          handleDownloadExcel={handleDownloadExcel}
          excelLoad={excelLoad}
          staffSummaryDataRefetch={staffSummaryDataRefetch}
          isFetching={isFetching}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setShowPerformanceDownloadModal={setShowPerformanceDownloadModal}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          bottomSheetOpen={bottomSheetOpen}
          setBottomSheetOpen={setBottomSheetOpen}
          setView={setView}
          view={view}
        />
        {view === 1 ? (
          (sortedStaffData || staffSummaryData?.data) && (
            <StaffSummaryListView
              rowData={sortedStaffData || staffSummaryData?.data}
              handleStatNavigation={handleStatNavigation}
              totalTaskHeaderText={
                filterType === "date_range"
                  ? `${formatDeadlineDate(startDate)} - ${formatDeadlineDate(
                      endDate
                    )}`
                  : selectedDateFilter
                  ? formatDeadlineDate(selectedDateFilter)
                  : filterType
              }
              startDate={startDate}
              endDate={endDate}
              filterType={filterType}
              selectedDateFilter={selectedDateFilter}
            />
          )
        ) : (
          <ul className="row">
            {(sortedStaffData || staffSummaryData?.data)?.map(
              (staff, index) => (
                <li
                  className="col-sm-12 col-md-6 col-lg-4 staff-summery-card"
                  key={index}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (filterType === "date_range" && startDate && endDate) {
                        sessionStorage?.setItem(
                          "dailyReportStartDate",
                          startDate
                        );
                        sessionStorage?.setItem("dailyReportEndDate", endDate);
                      }
                      sessionStorage?.setItem(
                        "dailyReportDate",
                        filterType === "today"
                          ? new Date()
                          : filterType === "date_range"
                          ? JSON.stringify([
                              new Date(startDate).toISOString(),
                              new Date(endDate).toISOString(),
                            ])
                          : selectedDateFilter
                      );
                      console.log("dailyReportFilterType", filterType);
                      sessionStorage?.setItem(
                        "dailyReportFilterType",
                        filterType
                      );
                      navigate(
                        `/${localStorage.getItem(
                          "company"
                        )}/performance-report?userId=${staff?.userId}`,
                        {
                          state: { from: location.pathname },
                        }
                      );
                    }}
                    className="listing-staff-summary-wrap"
                  >
                    <div className="listing-staff-summary-top">
                      <div className="d-flex align-items-center gap-1 listing-staff-profile-data">
                        <div className="listing-staff-profile-image">
                          <img
                            src={
                              staff?.profileImage
                                ? staff?.profileImage
                                : userProfileBlank
                            }
                            alt="no-data"
                          />
                        </div>
                        <p className="listing-staff-profile-name">
                          {staff?.userName}
                        </p>
                      </div>
                      {/* <div className="listing-staff-profile-text">
                    {index + 1 < 10 ? `0${index + 1}` : index + 1}
                  </div> */}
                    </div>
                    <div className="total-staff-summary-task">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap total-staff-summary-task-total-count-section">
                        <h2>
                          Total Task <span>{staff?.totalTask}</span>
                        </h2>
                        <a
                          href=""
                          className="total-staff-summary-task-list-url"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleStatNavigation(staff?.userId);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={dueiconbtn} alt="no-data" /> See Task List
                        </a>
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                        <div className="d-flex task-info-list-wrap flex-grow-1">
                          <div className="task-info-list-icon">
                            <img src={taskinfoicon} alt="no-data" />
                          </div>
                          <div className="task-info-list-text">
                            <span>Performance</span>
                            <strong
                              style={{
                                color:
                                  staff?.performanceStatus === 1
                                    ? "#2098D1"
                                    : "#FF6363",
                              }}
                            >
                              {staff?.totalTask && staff?.totalTask > 0
                                ? `${staff?.performance}%`
                                : "---"}
                              {staff?.totalTask && staff?.totalTask > 0 ? (
                                <img
                                  height={20}
                                  width={20}
                                  src={
                                    staff?.performanceStatus === 1
                                      ? upIcon
                                      : downIcon
                                  }
                                  alt="no-data"
                                />
                              ) : (
                                <></>
                              )}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div className="task-info-list-wrap flex-grow-1">
                          <div className="task-info-list-icon">
                            <img src={taskinfoicon1} alt="no-data" />
                          </div>
                          <div className="task-info-list-text">
                            <span>Obtained Points</span>
                            <strong className="second-color">
                              {staff?.totalTask && staff?.totalTask > 0
                                ? staff?.obtainedScore
                                : "---"}
                            </strong>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        )}

        {!isLoading &&
          (staffSummaryData?.data?.length === 0 || !staffSummaryData?.data) && (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center no-dashboard-task-data">
              <img src={NoTaskData} alt="no-data" />
              <p className="mb-0">No Staff Summary Found</p>
            </div>
          )}
        {isLoading && (
          <div className="position-relative">
            <div className="">
              <ShimmerTable row={5} />
            </div>
          </div>
        )}
      </div>
      <PerformanceDownloadModal
        show={showPerformanceDownloadModal}
        isDark={props.isDark}
        handleCloseModal={() => setShowPerformanceDownloadModal(false)}
        typeTask={
          filterType === "date_range"
            ? `${formatDeadlineDate(startDate)} - ${formatDeadlineDate(
                endDate
              )}`
            : selectedDateFilter
            ? formatDeadlineDate(selectedDateFilter)
            : filterType
        }
      />
      <HowPerformanceWorksModal
        show={openModal}
        handleCloseModal={() => setOpenModal(false)}
        isDark={props.isDark}
      />
    </div>
  );
});

export default StaffSummaryMain;
