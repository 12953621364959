import React, { useEffect, useMemo, useState } from "react";
import AddBtn from "../../../assets/add-btn.svg";
import Filter from "../../../assets/filter-btn.svg";
import LeadImg from "../../../assets/lead-drop.svg";
import "../styles/projects.scss";
import { AgGridReact } from "ag-grid-react";
import { mockTransactionData, mockTransactionDataColumns } from "./utils/data";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomPagination from "../../../components/CustomPagination";

const Inventory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(50); // Default page size
  const [rowData, setRowData] = useState(
    localStorage.getItem("gridApi")
      ? JSON.parse(localStorage.getItem("gridApi"))
      : mockTransactionData()
  );
  const [user, setUser] = useState("");

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const validateInput = (event) => {
    const value = parseInt(event.target.value);

    // Check if the value is not a number or is less than or equal to 0
    if (isNaN(value) || value <= 0) {
      event.preventDefault(); // Prevent invalid input from being set
      return;
    }

    // Update the page size state with the valid value
    setPageSize(value);
  };

  // Persist column swap state for table

  const swapColumnState = async (e) => {
    var columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem("myColumnState", columnState);
  };

  // Row drag persistence
  const onRowDragEnd = (params) => {
    // Perform any additional actions after the swap operation
    const rows = [];
    params.api.forEachNodeAfterFilterAndSort((node) => rows.push(node.data));
    // setRowData(rows);
    localStorage.setItem("gridApi", JSON.stringify(rows));
  };

  // On ready table state
  const gridReady = (params) => {
    var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };

  // Keyboard enter
  const handleKeyDown = async (event) => {
    if (event.keyCode === 13) {
      setRowData([
        ...rowData,
        {
          id: rowData[rowData.length - 1].id + 1,
          member_name: user,
          email: "info@yourmail.com",
          status: "New Leads",
          title: "Manager",
          salary: "13,122",
          performance: 25,
          action: "",
        },
      ]);
      setUser("");
    }
  };

  useEffect(() => {}, [currentPage]);

  return (
    <div className="leads-table-main-wraper">
      <div className="lead-heading-btn">
        <div className="row">
          <div className="col-md-6">
            <h2>
              {" "}
              Inventory{" "}
              <a href="#">
                {" "}
                <img src={LeadImg} />{" "}
              </a>
            </h2>
          </div>
          <div className="col-md-6">
            <div className="btn-leads-cta-wrap">
              {" "}
              <a href="#">
                {" "}
                <img src={Filter} /> Filters{" "}
              </a>{" "}
              <a href="#">
                {" "}
                <img src={AddBtn} /> Add New{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="leads-table-wrap">
        <div className="table-responsive">
          <div
            className="ag-theme-alpine"
            style={{
              width: "100%",
              minWidth: "900px",
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={mockTransactionDataColumns()}
              defaultColDef={defaultColDef}
              onColumnMoved={swapColumnState}
              // pagination={true}
              rowDragManaged={window.innerWidth > 1000 ? true : false}
              rowDragEntireRow={window.innerWidth > 1000 ? true : false}
              animateRows={true}
              domLayout={"autoHeight"}
              onRowDragEnd={onRowDragEnd}
              singleClickEdit={true}
              suppressHorizontalScroll={true}
              overlayLoadingTemplate={
                '<object style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) scale(2)" type="image/svg+xml" data="https://ag-grid.com/images/ag-grid-loading-spinner.svg" aria-label="loading"></object>'
              }
              overlayNoRowsTemplate={
                "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
              }
              onGridReady={gridReady}
              // rowDataUpdated={rowDataUpdated} // Call the function on row data change
              // onGridReady={onGridReady} // Call the function on grid ready
            ></AgGridReact>
            <div className="w-100 add-lead-new">
              <input
                value={user}
                placeholder="+ Add user"
                onKeyDown={handleKeyDown}
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="record-pagination-wraper">
        <div className="row">
          <div className="col-md-6 lead-pagi-col">
            <div className="record-wrap">
              Records Per Page{" "}
              <input
                // type="number"
                disabled
                value={pageSize}
                onChange={validateInput}
              />
            </div>
          </div>

          <div className="col-md-6 lead-pgi-right lead-pagi-col">
            {/* <div className="pagination-main-wrap"> */}
            <CustomPagination
              totalRows={totalRows}
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
