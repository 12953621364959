import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Modal } from "react-bootstrap";
import P1 from "../../../assets/performance-work.png";
import "./style.css";

const HowPerformanceWorksModal = ({ show, handleCloseModal, isDark }) => {
  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="performance-working-modal-main"
    >
      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          How your performance percentage and points are calculated
        </p>
        <button className="custom-close-button" onClick={handleCloseModal}>
          <Icon icon="maki:cross" />
        </button>
      </div>

      <Modal.Body>
        <div className="performance-image-container">
          <img src={P1} alt="performance-icon-1" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowPerformanceWorksModal;
