import React, { useEffect, useState } from "react";
import API from "../../../services/ClientApi";
import Icon1 from "../../../assets/notify-icons/comment-task.png";
import Icon2 from "../../../assets/notify-icons/complete-task.png";
import Icon3 from "../../../assets/notify-icons/due-task.png";
import Icon4 from "../../../assets/notify-icons/new-project.png";
import Icon5 from "../../../assets/notify-icons/new-task.png";
import Icon6 from "../../../assets/notify-icons/status-task.png";
import Icon7 from "../../../assets/notify-icons/update-project.png";
import NoData from "../../../assets/no-notifications.png";

import { dateFormatter } from "../../../helper/dateFormatter";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { notificationUnReadCount } from "../../../actions/notificationsActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    try {
      setLoad(true);
      const response = await API.get(`users/notification?page=1&type=unread`);

      if (response?.data?.status === "success") {
        setNotifications(response?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const navigateByType = (data) => {
    if (data?.notificationType === "subscription") {
      // props?.handleClose();
      navigate(`/${localStorage.getItem("company")}/company-subscriptions`);
    }
    if (data?.notificationType?.includes("project-")) {
      // props?.handleClose();
      navigate(
        `/${localStorage.getItem("company")}/project?pid=${data?.target_id}`
      );
    }
    if (data?.notificationType?.includes("task-")) {
      // props.setShowTaskDetail(true);
      // props.setSelectedTask(data?.target_id);
      navigate(
        `/${localStorage.getItem("company")}/task-management-tasks?taskId=${
          data?.target_id
        }`
      );
    }
    // props?.handleClose();
  };

  const handleNotificationClick = async (notificationKey, data) => {
    try {
      navigateByType(data);
      if (!data?.readStatus) {
        await API.post("users/notification/update-status", {
          notificationKey: notificationKey,
        });
        await getNotifications();
        await dispatch(notificationUnReadCount());
      }
    } catch (error) {
    } finally {
    }
  };

  function getNotificationMainText(notification) {
    const { notificationType } = notification;

    switch (notificationType) {
      case "task-created":
        return "New Task Assigned";
      case "task-deadline":
        return "Task Deadline Change";
      case "task-status":
        return "Task Status Change";
      case "task-comment":
        return "Task Comment Alert";
      case "task-completed":
        return "Task Completion Acknowledgment";
      case "project-crated": // Assuming this is a typo and should be 'project-created'
        return "New Project Created";
      case "project-deadline":
        return "Project Deadline Reminder";
      case "project-update":
        return "Project Update Notification";
      default:
        return "Notification";
    }
  }

  function getNotificationIcon(notificationType) {
    switch (notificationType) {
      case "task-created":
        return Icon5; // New Task Assigned
      case "task-deadline":
        return Icon3; // Task Due Soon
      case "task-status":
        return Icon6; // Task Status Change
      case "task-comment":
        return Icon1; // Task Comment Alert
      case "task-completed":
        return Icon2; // Task Completion Acknowledgment
      case "project-crated": // Assuming typo, should be 'project-created'
        return Icon4; // New Project Created
      case "project-deadline":
        return Icon3; // Project Deadline Reminder
      case "project-update":
        return Icon7; // Project Update Notification
      default:
        return Icon5; // Fallback to no icon
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="listing-notification-wraper dashboard-notification-list">
      <ul className="mb-0">
        {!load && notifications.length > 0
          ? notifications?.slice(0, 5)?.map((n, id) => (
              <li
                key={id}
                className="mt-0"
                onClick={() => {
                  handleNotificationClick(n?.notificationKey, n);
                }}
                style={{
                  cursor: "pointer !important",
                }}
                // className={`${
                //   n.readStatus ? "read-notification" : ""
                // }`}
              >
                <div className="w-100 lisitng-noftt-wrap">
                  <div className="w-100 d-flex align-items-center">
                    <div className="lisitng-noftt-icon">
                      <img
                        src={getNotificationIcon(n?.notificationType)}
                        alt={`notification icon`}
                      />
                    </div>
                    <div
                      className={`w-100 listing-notify-wrap ${
                        n?.readStatus === false
                          ? "unread-notification-container"
                          : ""
                      }`}
                    >
                      <div className="w-100 d-flex align-items-center justify-content-between gap-1 flex-wrap-reverse">
                        <h5> {getNotificationMainText(n)} </h5>
                        <div className="notification-date">
                          {dateFormatter(n?.createdAt, 4)}
                        </div>
                      </div>
                      <p>{n?.content}</p>
                    </div>
                  </div>
                  {/* <div className="notification-date">
                {dateFormatter(n?.createdAt, 3)}
              </div> */}
                </div>
              </li>
            ))
          : !load && (
              <div className="d-flex align-items-center flex-column justify-content-center dashboard-no-notification">
                <img src={NoData} alt="no-notify" />
                <p className="mb-0">No Notifications Yet</p>
              </div>
            )}

        {load && (
          <div className="w-100 d-flex justify-content-center global-spinner">
            <ShimmerCategoryList title items={5} categoryStyle="STYLE_SEVEN" />
          </div>
        )}
      </ul>
    </div>
  );
};

export default DashboardNotifications;
