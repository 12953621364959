import React, { useEffect, useState } from "react";
import { isPermitted } from "../../../helper/isPermitted";
import avatar from "../../../assets/avatar.svg";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const ProjectUserListItemForFilter = ({
  setData,
  data,
  res,
  serverFilters,
  setServerFilters,
  userListRef,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      setIsChecked(!isChecked);

      // Determine which ID array to update based on the memberFilter
      const filterKey =
        serverFilters.memberFilter === "member"
          ? "memberIds"
          : serverFilters.memberFilter === "observer"
          ? "observerIds"
          : "ownerIds";

      const currentIds = serverFilters[filterKey];

      // Check if the userId is already in the selected filter array
      if (currentIds.includes(res?.userId)) {
        // Remove the userId if it exists in the array
        const updatedIds = currentIds.filter(
          (userId) => userId !== res?.userId
        );
        setServerFilters({
          ...serverFilters,
          [filterKey]: updatedIds,
        });
      } else {
        // Add the userId if it doesn't exist in the array
        setServerFilters({
          ...serverFilters,
          [filterKey]: [...currentIds, res?.userId],
        });
      }
      setIsClicked(false);
    }
  }, [isClicked]);
  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      className={`w-100 border-bottom-1 task-user-filter-dropdown-menu-item task-user-filter-dropdown-menu-item-mobile ${
        serverFilters[
          serverFilters.memberFilter === "member"
            ? "memberIds"
            : serverFilters.memberFilter === "observer"
            ? "observerIds"
            : "ownerIds"
        ]?.includes(res?.userId)
          ? "task-user-filter-dropdown-menu-item-mobile-select"
          : ""
      }`}
    >
      <div
        className="checkbox create-task-checkbox-mobile"
        onClick={() => {
          setIsChecked(!isChecked);

          // Determine which ID array to update based on the memberFilter
          const filterKey =
            serverFilters.memberFilter === "member"
              ? "memberIds"
              : serverFilters.memberFilter === "observer"
              ? "observerIds"
              : "ownerIds";

          const currentIds = serverFilters[filterKey];

          // Check if the userId is already in the selected filter array
          if (currentIds.includes(res?.userId)) {
            // Remove the userId if it exists in the array
            const updatedIds = currentIds.filter(
              (userId) => userId !== res?.userId
            );
            setServerFilters({
              ...serverFilters,
              [filterKey]: updatedIds,
            });
          } else {
            // Add the userId if it doesn't exist in the array
            setServerFilters({
              ...serverFilters,
              [filterKey]: [...currentIds, res?.userId],
            });
          }
        }}
      >
        <input
          type="checkbox"
          checked={serverFilters[
            serverFilters.memberFilter === "member"
              ? "memberIds"
              : serverFilters.memberFilter === "observer"
              ? "observerIds"
              : "ownerIds"
          ]?.includes(res?.userId)}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span
            className="ps-1"
            onClick={(e) => {
              if (isPermitted("user-profile")) {
                e.stopPropagation();
                // window.open(
                //   `/${localStorage.getItem("company")}/user-profile?id=${
                //     res?.userId
                //   }`,
                //   "_blank" // Opens in a new tab or window
                // );
                saveLocalStorageToCookies(
                  `/user-profile?id=${res?.userId}`
                );
              }
            }}
          >
            {res?.userName}
          </span>
        </label>
      </div>
    </div>
  );
};

export default ProjectUserListItemForFilter;
