/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, memo, useMemo, useEffect } from "react";
import "../styles/projects.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ButtonGroup, Dropdown, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import ProjectTeamMemberFilterSection from "./ProjectTeamMemberFilterSection";
import { Tooltip } from "react-tooltip";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import {
  areObjectsEqual,
  getDefaultMarkAsById,
  getIdByDefaultMarkAs,
  getStatusNameById,
} from "../../../helper/taskHelpers";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userProfilePage } from "../../../actions/userActions";

function ProjectFilterSection({
  props,
  overdueCount,
  projectUserList,
  allProjects,
  refreshFilters,
  showRefresh,
  projectStatusList,
  isShowOnlyCompletedProjects,
  projectTypeList,
  setServerFilters,
  serverFilters,
  setShowAdvanceFilterModal,
  userProfile,
}) {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const [saveLoad, setSaveLoad] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [projectPublicityList, setProjectPublicityList] = useState([
    "Public",
    "Private",
  ]);
  const [selectedProjectPublicity, setSelectedProjectPublicity] =
    useState(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);

  useMemo(() => {
    setSelectedProjectPublicity(serverFilters.privacy);
  }, [serverFilters]);

  useEffect(() => {
    setSelectedTaskStatus(
      serverFilters?.status
        ? getIdByDefaultMarkAs(projectStatusList, serverFilters?.status)
        : null
    );
  }, [projectStatusList]);

  const CustomTasksStatusEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectStatusList?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "not-started"
                        ? NotStarted
                        : res?.defaultMarkAs === "in-progress"
                        ? progressImage
                        : res?.defaultMarkAs === "on-hold"
                        ? reviewImage
                        : res?.defaultMarkAs === "review"
                        ? reviewIcon
                        : CompletedIcon
                    }
                    alt={res?.statusName}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 4,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div className="">{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? "#333"
        : "var(--select-dropdown-option-font-color)",
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected
        ? "#deebff"
        : "var(--select-dropdown-option-color)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      minWidth: "max-content", // Set the desired width
      backgroundColor: "var(--search-bar-bg)",
    }),
  };

  const handleType = (value) => {
    setServerFilters((prev) => ({
      ...prev,
      overdue: true,
    }));

    localStorage.removeItem("projectFilter");
  };

  const PublicSelectSection = () => {
    // Filter out the selected privacy value from the projectPublicityList
    const filteredPublicityOptions = projectPublicityList?.filter(
      (privacy) => privacy.toLowerCase() !== selectedProjectPublicity
    );

    return (
      <li className={`task-situation-filter-top-wrap-select`}>
        {serverFilters?.privacy && (
          <div className="selected-filter-text-container selected-filter-text-status">
            <Icon
              icon="mdi:cross-circle"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProjectPublicity(null);
                setServerFilters((prev) => ({
                  ...prev,
                  privacy: "",
                }));
              }}
            />
          </div>
        )}
        <div data-theme={props?.isDark} className="form-group">
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status  ${
              selectedProjectPublicity
                ? "filter-dropdown-selected-project-value"
                : "filter-dropdown-not-selected-project-value"
            }`}
            classNamePrefix="select"
            data-theme={props?.isDark}
            value={
              selectedProjectPublicity
                ? {
                    label:
                      selectedProjectPublicity.charAt(0).toUpperCase() +
                      selectedProjectPublicity.slice(1),
                    value: selectedProjectPublicity,
                  }
                : null
            }
            placeholder="Privacy"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            menuPlacement="auto" // Adjust placement based on available space
            menuPosition="fixed" // Use fixed positioning to ensure visibility
            // menuPortalTarget={document.body}
            name="color"
            // Use filtered options
            options={filteredPublicityOptions?.map((res, index) => ({
              label: res,
              value: res,
            }))}
            onChange={(e) => {
              setSelectedProjectPublicity(e.value);
              setServerFilters({
                ...serverFilters,
                privacy: e.value.toLowerCase(),
              });
            }}
            components={{
              Control: ({ children, menuIsOpen, ...props }) => {
                return (
                  <components.Control {...props}>
                    {props.hasValue ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ color: "blue !important" }}>
                          {children}
                        </div>
                      </div>
                    ) : (
                      children
                    )}
                    <div
                      className="select-icon me-1"
                      style={{
                        transform: menuIsOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <IoIosArrowDown />
                    </div>
                  </components.Control>
                );
              },
            }}
          />
        </div>
      </li>
    );
  };

  const StatusSelectSection = () => {
    // Filter out the selected status from the projectStatusList
    const filteredStatusOptions = projectStatusList?.filter(
      (status) => status.id !== selectedTaskStatus
    );

    return (
      <li className={`task-situation-filter-top-wrap-select`}>
        {serverFilters?.status && (
          <div className="selected-filter-text-container selected-filter-text-status">
            <Icon
              icon="mdi:cross-circle"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTaskStatus(null);
                setServerFilters((prev) => ({
                  ...prev,
                  status: "",
                }));
              }}
            />
          </div>
        )}
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status filter-item-status ${
              selectedTaskStatus
                ? "filter-dropdown-selected-value"
                : "filter-dropdown-not-selected-value"
            }`}
            classNamePrefix="select"
            placeholder="Status"
            value={
              selectedTaskStatus
                ? {
                    label: getStatusNameById(
                      projectStatusList,
                      selectedTaskStatus
                    ),
                    value: selectedTaskStatus,
                  }
                : null
            }
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            menuPlacement="auto" // Adjust placement based on available space
            menuPosition="fixed" // Use fixed positioning to ensure visibility
            // menuPortalTarget={document.body}
            name="color"
            options={filteredStatusOptions?.map((res, index) => ({
              label: (
                <div className="select-option-list">
                  {" "}
                  {res?.defaultMarkAs === "not-started" && (
                    <img
                      src={NotStarted}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "in-progress" && (
                    <img
                      src={progressImage}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "on-hold" && (
                    <img
                      src={reviewImage}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "review" && (
                    <img
                      src={reviewIcon}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "completed" && (
                    <img
                      src={CompletedIcon}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.statusName}
                </div>
              ),
              value: res?.id,
            }))}
            components={{ Control: CustomTasksStatusEditorControl }}
            onChange={(e) => {
              setSelectedTaskStatus(e.value);
              setServerFilters((prev) => ({
                ...prev,
                status: getDefaultMarkAsById(projectStatusList, e.value),
              }));
              localStorage.removeItem("taskFilter");
            }}
          />
        </div>
      </li>
    );
  };

  const OverdueCountSection = () => {
    return (
      <div
        className={`team-filter-option-overdue
          ${
            serverFilters?.overdue === true
              ? "team-selected-filter-options"
              : ""
          }`}
      >
        <a className={`team-filter-option `} onClick={() => handleType(1)}>
          {" "}
          Overdue <span>({overdueCount})</span>
        </a>
      </div>
    );
  };

  // Save filter
  const handleFilterSave = async () => {
    try {
      const data = areObjectsEqual(
        serverFilters,
        userProfile?.userFilters?.project?.[0]?.filterOptions
      )
        ? {
            status: "",
            overdue: false,
            memberFilter: "member",
            privacy: "",
            memberIds: [],
            observerIds: [],
            ownerIds: [],
            multiStatus: [],
            teamsMembers: [],
            teamsMembersTypes: [],
            projectStartDate: "",
            projectEndDate: "",
            selectedDate: "",
            searchText: "",
            projectName: "",
            createdAt: "",
            updatedAt: "",
            isDeleted: false,
          }
        : serverFilters;
      setSaveLoad(true);
      const response = await API.post("users/filters", {
        filterName: "project List Filter",
        filterType: "project",
        filterOptions: data,
      });
      if (response?.data?.status === "success") {
        await dispatch(userProfilePage());
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
    } finally {
      setSaveLoad(false);
      if (
        areObjectsEqual(
          serverFilters,
          userProfile?.userFilters?.project?.[0]?.filterOptions
        )
      ) {
        refreshFilters();
        setSelectedTaskStatus(null);
      }
    }
  };

  // Detect if filter combination is already saved
  useEffect(() => {
    if (
      areObjectsEqual(
        serverFilters,
        userProfile?.userFilters?.project?.[0]?.filterOptions
      )
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverFilters, userProfile]);

  return (
    <>
      <Tooltip id="my-tooltip" />
      {/* filters for laptop screen */}
      <div
        className={`task-main-info-list project-main-info-laptop justify-content-between ${
          isShowOnlyCompletedProjects && "task-main-info-list-completed"
        }`}
      >
        <ul className="project-list-filter-container">
          <li>
            <ProjectTeamMemberFilterSection
              props={props}
              projectUserList={projectUserList}
              allProjects={allProjects}
              projectTypeList={projectTypeList}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
            />
          </li>
          <PublicSelectSection />
          <StatusSelectSection />
          {!isShowOnlyCompletedProjects && (
            <li>
              <OverdueCountSection />{" "}
            </li>
          )}
          <li className="task-list-filters-btn-container">
            <div
              className="d-flex align-items-center task-filter-container"
              onClick={() => setShowAdvanceFilterModal(true)}
            >
              <Icon icon="solar:filter-outline" />
              <span>Filters</span>
            </div>
          </li>

          {showRefresh() && (
            <>
              <li
                className={`filter-save-icon ${isSaved ? "saved-filter" : ""}`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${
                  isSaved ? "Clear your filter settings" : "Save filters"
                }`}
                onClick={handleFilterSave}
              >
                {saveLoad ? (
                  <Spinner size="sm" className="me-1 global-spinner" />
                ) : (
                  ""
                )}
                <Icon icon="fluent:save-20-regular" />{" "}
                <span className="refresh-text">
                  {isSaved ? "Saved" : "Save"}
                </span>
              </li>
              <li
                className="filter-refresh-icon"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Reset filters"
              >
                <Icon
                  icon="bx:refresh"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                />{" "}
                <span
                  className="refresh-text"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                >
                  Clear
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* filters for tablet screen */}
      <div className="ms-4 task-main-info-list project-main-info-tablet justify-content-between">
        <ul className="project-list-filter-container">
          <li>
            <ProjectTeamMemberFilterSection
              props={props}
              projectUserList={projectUserList}
              allProjects={allProjects}
              projectTypeList={projectTypeList}
            />
          </li>
          <PublicSelectSection />
          <div className="project-main-info-tablet">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="secondary"
                id="color-dropdown"
                className="no-arrow-dd "
              >
                <div className="menu-btn">
                  <Icon icon="mi:options-horizontal" />
                </div>
              </Dropdown.Toggle>{" "}
              <Dropdown.Menu>
                {!isShowOnlyCompletedProjects && (
                  <Dropdown.Item
                    className="project-filter-dropdown-list"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <OverdueCountSection />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <li className="task-list-filters-btn-container">
            <div
              className="d-flex align-items-center task-filter-container"
              onClick={() => setShowAdvanceFilterModal(true)}
            >
              <Icon icon="solar:filter-outline" />
              <span>Filters</span>
            </div>
          </li>

          {showRefresh() && (
            <>
              <li
                className={`filter-save-icon ${isSaved ? "saved-filter" : ""}`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${
                  isSaved ? "Clear your filter settings" : "Save filters"
                }`}
                onClick={handleFilterSave}
              >
                {saveLoad ? (
                  <Spinner size="sm" className="me-1 global-spinner" />
                ) : (
                  ""
                )}
                <Icon icon="fluent:save-20-regular" />{" "}
                <span className="refresh-text">
                  {isSaved ? "Saved" : "Save"}
                </span>
              </li>
              <li
                className="filter-refresh-icon"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Reset filters"
              >
                <Icon
                  icon="bx:refresh"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                />{" "}
                <span
                  className="refresh-text"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                >
                  Clear
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* filters for mobile screen */}
      <div className="project-main-info-mobile ms-4">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            variant="secondary"
            id="color-dropdown"
            className="no-arrow-dd "
          >
            <div className="menu-btn">
              <Icon icon="mi:options-horizontal" />
            </div>
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ProjectTeamMemberFilterSection
                props={props}
                projectUserList={projectUserList}
                allProjects={allProjects}
                projectTypeList={projectTypeList}
              />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <PublicSelectSection />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="project-filter-dropdown-list"
            >
              <OverdueCountSection />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="project-filter-dropdown-list"
            >
              <li className="task-list-filters-btn-container">
                <div
                  className="d-flex align-items-center task-filter-container"
                  onClick={() => setShowAdvanceFilterModal(true)}
                >
                  <Icon icon="solar:filter-outline" />
                  <span>Filters</span>
                </div>
              </li>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <ul className="mobileview-filter-ul project-list-filter-container">
          {showRefresh() && (
            <>
              <li
                className={`filter-save-icon ${isSaved ? "saved-filter" : ""}`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${
                  isSaved ? "Clear your filter settings" : "Save filters"
                }`}
                onClick={handleFilterSave}
              >
                {saveLoad ? (
                  <Spinner size="sm" className="me-1 global-spinner" />
                ) : (
                  ""
                )}
                <Icon icon="fluent:save-20-regular" />{" "}
                <span className="refresh-text">
                  {isSaved ? "Saved" : "Save"}
                </span>
              </li>
              <li
                className="filter-refresh-icon"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Reset filters"
              >
                <Icon
                  icon="bx:refresh"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                />{" "}
                <span
                  className="refresh-text"
                  onClick={() => {
                    setSelectedTaskStatus(null);
                    refreshFilters();
                  }}
                >
                  Clear
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default memo(ProjectFilterSection);
