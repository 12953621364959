import {
  TASK_PRIORITIES_LIST_FAIL,
  TASK_PRIORITIES_LIST_REQUEST,
  TASK_PRIORITIES_LIST_SUCCESS,
  TASK_TYPES_LIST_FAIL,
  TASK_TYPES_LIST_REQUEST,
  TASK_TYPES_LIST_SUCCESS,
  TASK_LIST_FAIL,
  TASK_LIST_REQUEST,
  TASK_LIST_SUCCESS,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_FAIL,
  ADD_TASK_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/tasksConstants";

// Task priorities list reducer
export const taskPrioritiesListReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_PRIORITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TASK_PRIORITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskPriorities: action.payload,
      };
    case TASK_PRIORITIES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Task types list reducers
export const taskTypesListReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TASK_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTypes: action.payload,
      };
    case TASK_TYPES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Task types list reducers
export const taskListReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskList: action.payload,
      };
    case TASK_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TASK_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TASK_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        taskList: state.taskList.map((task) =>
          task.id === action.payload.id ? action.payload : task
        ),
      };
    case TASK_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        taskList: [action.payload, ...state.taskList],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
