// Import action type constants from actionTypes.js
import { ProjectAPI } from "../services/ClientApi";
import {
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  UPDATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  CLEAR_CACHED_TASKS,
} from "../constants/taskCachedConstants";

// Action creator for initiating the task fetch process
// This will set the loading state to true
export const fetchTasksRequest = () => ({
  type: FETCH_TASKS_REQUEST,
});

// Action creator for handling successful task fetch
// This takes the fetched tasks, limit, and page as payload
export const fetchTasksSuccess = (tasks, limit, page) => ({
  type: FETCH_TASKS_SUCCESS,
  payload: { tasks, limit, page },
});

// Action creator for handling task fetch failure
// This will pass the error message as payload
export const fetchTasksFailure = (error) => ({
  type: FETCH_TASKS_FAILURE,
  payload: error,
});

// Thunk action to fetch tasks based on the provided limit and page
export const fetchTasks = (limit, page) => {
  return async (dispatch, getState) => {
    // Get the current state of tasks from Redux store
    const state = getState();
    // Check if tasks for the given limit and page are already cached in state
    const cachedTasks = state?.taskCachedList?.taskList[`${limit}_${page}`];

    // If tasks are cached, return early without making an API call
    if (cachedTasks) {
      return;
    }

    // Dispatch action to indicate task fetch process has started
    dispatch(fetchTasksRequest());

    try {
      // Fetch tasks from the API with the specified limit and page
      const { data } = await ProjectAPI.post(
        `task/listing?page=${page}&limit=${limit}`
      );

      // Dispatch action for successful task fetch, passing the fetched data
      dispatch(fetchTasksSuccess(data, limit, page));
    } catch (error) {
      // If there is an error during fetch, dispatch failure action
      // dispatch(fetchTasksFailure(error.message));
      dispatch({
        type: FETCH_TASKS_FAILURE,
        payload: error.message,
        meta: {
          retryAction: fetchTasks(limit, page), // Retry action
        },
      });
    }
  };
};

// Action creator for updating a task
// This will update the task with the specified taskId with the new task data
export const updateCachedTask =
  (taskId, updatedTask) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TASK_SUCCESS,
      payload: { taskId, updatedTask },
    });
  };

// Action creator for deleting a task
// This will delete the task with the specified taskId, limit, and page
export const deleteCachedTask = (taskIds) => ({
  type: DELETE_TASK_SUCCESS,
  payload: { taskIds },
});

export const clearCachedTasks = () => ({
  type: CLEAR_CACHED_TASKS,
});
