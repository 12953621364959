// Output: "Monday-Friday"
export function convertDaysToRange(dayString) {
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Split the input string into an array of days
  const days = dayString.split(",");

  // Sort the input days based on their order in the weekDays array
  const sortedDays = days.sort(
    (a, b) => weekDays.indexOf(a) - weekDays.indexOf(b)
  );

  // If there's only one day, return it as is
  if (sortedDays.length === 1) return sortedDays[0];

  // Return the first and last day of the sorted array as a range
  return `${sortedDays[0]}-${sortedDays[sortedDays.length - 1]}`;
}
