/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import { sortCheckedMembersOnTop } from "../../../helper/sortCheckedMembersOnTop";
import { toast } from "react-toastify";
import SearchIcon from "../../../assets/search-icon.png";
import { isPermitted } from "../../../helper/isPermitted";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

function TasksListingMembersEditor(props) {
  const [currentTab, setCurrentTab] = useState(3);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const userListRef = useRef(null);
  const inputRef = useRef();
  // Scroll the focused item into view
  useEffect(() => {
    console.log("useEffect", "focusedIndex", focusedIndex);
    console.log("userListRef", userListRef.current);

    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  useEffect(() => {
    // Registering event listeners
    const handleTabClick = (tabIndex) => {
      setCurrentTab(tabIndex);
    };

    document
      .querySelector(".task-user-filter-dropdown-tab-bar")
      .addEventListener("click", (event) => {
        const target = event.target;
        const tabIndex = parseInt(target.getAttribute("data-tab-index"), 10);
        if (!isNaN(tabIndex)) {
          handleTabClick(tabIndex);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  // Prevent AG Grid from intercepting Enter key
  const stopEditingOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Stop AG Grid from handling Enter
      event.stopPropagation();
      // Find the currently focused option in react-select
      const focusedOption = document.querySelector(
        ".task-user-filter-dropdown-menu-item-focused"
      );

      if (focusedOption) {
        focusedOption.click(); // Simulate a click on the focused option
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", stopEditingOnEnter, true);
    return () =>
      document.removeEventListener("keydown", stopEditingOnEnter, true);
  }, [props.api]);

  // Type id based on stypeName
  const getTypeID = (typeName) => {
    const typeObj =
      props?.projectTypes?.length > 0 &&
      props?.projectTypes?.find((type) => type?.typeName === typeName);
    return typeObj ? typeObj?.id : null;
  };

  // Modal search functionality
  const [search, setSearch] = useState("");
  const [userSearchList, setUserSearchList] = useState(props?.userList);

  const handleSearch = (event) => {
    const filteredList = props.userList.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  // Handle clearing the search input
  const clearSearch = () => {
    const filteredList = props.userList.filter((user) =>
      user.userName.toLowerCase().includes("".toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch("");
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < userSearchList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : userSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item

      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown

      event.preventDefault();
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      data-theme={props.isDark}
      className="position-absolute rounded-3 top-0 shadow-lg task-table-user-filter task-user-filter-dropdown"
    >
      <div className="p-0 w-100 h-100 d-flex custom-user-selection-main-container">
        <div className="task-user-filter-dropdown-tab-bar">
          <ul className="d-flex flex-column align-items-start custom-horizental-scrollbar p-0 m-0 custom-user-type-list">
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 pt-0 ${
                  currentTab === 3 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(3)}
              >
                Members
              </a>
              <div></div>
            </li>
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                  currentTab === 2 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(2)}
              >
                {" "}
                Project Owner{" "}
              </a>
              <div></div>
            </li>
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                  currentTab === 1 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(1)}
              >
                Project Observer
              </a>
              <div></div>
            </li>
            <div className="w-100 "></div>
          </ul>
        </div>
        <div className="d-flex flex-column custom-user-selection-main-container">
          <div className="listing-team-modal-search-input-wrap position-relative">
            <input
              ref={inputRef}
              type="text"
              name="search"
              value={search}
              onChange={handleSearch}
              placeholder="Search members"
              autoComplete="off"
              autoFocus={true}
              onBlur={() => inputRef.current.focus()}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                inputRef.current.focus();
              }}
            />
            {search ? (
              <span className="custom-user-search-icon" onClick={clearSearch}>
                &#x2715;
              </span>
            ) : (
              <img
                src={SearchIcon}
                className="custom-user-search-icon"
                alt="search-icon"
                width={20}
                height={20}
              />
            )}
          </div>
          <div className="user-list-main-container custom-vertical-scrollbar">
            {currentTab === 1 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                setCurrentTab={setCurrentTab}
                members={userSearchList}
                selectedUsers={props?.params?.data?.projectMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                pageParams={props?.pageParams || {}}
                isCompletedProjectListing={props?.isCompletedProjectListing}
                postUpdateProjectMembers={props.postUpdateProjectMembers}
                getProjectList={props.getProjectList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
            {currentTab === 2 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                members={userSearchList}
                selectedUsers={props?.params?.data?.projectMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                pageParams={props.pageParams}
                isCompletedProjectListing={props.isCompletedProjectListing}
                postUpdateProjectMembers={props.postUpdateProjectMembers}
                getProjectList={props.getProjectList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
            {currentTab === 3 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                members={userSearchList}
                selectedUsers={props?.params?.data?.projectMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                pageParams={props.pageParams}
                isCompletedProjectListing={props.isCompletedProjectListing}
                postUpdateProjectMembers={props.postUpdateProjectMembers}
                getProjectList={props.getProjectList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const TaskUsersListForFilter = ({
  currentTab,
  isDark,
  members,
  selectedUsers,
  params,
  getTypeID,
  isOwner,
  pageParams,
  isCompletedProjectListing,
  postUpdateProjectMembers,
  getProjectList,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const newMembers = sortCheckedMembersOnTop(
    members,
    selectedUsers,
    currentTab
  );
  let ownerCount = selectedUsers?.filter(
    (member) => member.memberType === "owner"
  ).length;

  return newMembers?.length > 0 ? (
    <div
      ref={userListRef}
      className="pe-2 task-user-filter-dropdown-menu custom-vertical-scrollbar my-2"
    >
      {newMembers?.map((res, id) => {
        return (
          <TaskUsersListItemForFilter
            index={id}
            member={res}
            selectedUsers={selectedUsers}
            params={params}
            getTypeID={getTypeID}
            isOwner={isOwner}
            ownerCount={ownerCount}
            type={
              currentTab === 1
                ? "observer"
                : currentTab === 2
                ? "owner"
                : "member"
            }
            pageParams={pageParams}
            isCompletedProjectListing={isCompletedProjectListing}
            postUpdateProjectMembers={postUpdateProjectMembers}
            getProjectList={getProjectList}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
            setIsClicked={setIsClicked}
            isClicked={isClicked}
          />
        );
      })}
    </div>
  ) : (
    <div className="task-user-filter-dropdown-menu custom-vertical-scrollbar my-2">
      <p>No members found</p>
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  member,
  selectedUsers,
  type,
  params,
  getTypeID,
  isOwner,
  ownerCount,
  pageParams,
  isCompletedProjectListing,
  postUpdateProjectMembers,
  getProjectList,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const updateProjectData = async (project) => {
    // await dispatch(updateProjectAction(project));
    // await dispatch(updateActiveProjectAction(project));
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(
      selectedUsers?.some(
        (user) => user.userId === member?.userId && user.memberType === type
      )
    );
  }, [selectedUsers, member, type]);

  const convertToObject = (data) => {
    return data.map((item) => {
      return {
        [getTypeID(item.memberType)]: item.userId,
      };
    });
  };

  const updateMembers = async (data) => {
    try {
      // Convert projectMembersData into an array of strings
      let combinedArray = data.map((member) => {
        const [key, value] = Object.entries(member)[0];
        return `${key} : ${value}`;
      });
      const formData = new FormData(); // Create a new FormData object

      // Append each key-value pair from data to the FormData object
      formData.append("projectId", params.data.projectId);

      // For members
      combinedArray.forEach((memberItem, index) => {
        formData.append(`projectMembers[]`, memberItem);
      });

      let combinedArrayNew = {
        projectId: params.data.projectId,
        projectMembers: combinedArray || [],
      };
      const response = await postUpdateProjectMembers({
        ...pageParams,
        ...combinedArrayNew,
        isCompletedProjectListing: isCompletedProjectListing,
      });

      const { id, ...rest } = response?.data?.data;
      const updatedProjectsDetail = {
        projectId: id,
        ...rest,
      };
      // toast.success("Members updated successfully", {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 300,
      //   hideProgressBar: true,
      //   closeButton: false,
      // });
      updateProjectData(updatedProjectsDetail);
      await getProjectList();
    } catch (error) {
    } finally {
    }
  };

  const handleCheckboxClick = async () => {
    if (ownerCount < 2 && type === "owner" && isChecked) {
      toast.info(
        "There will always be atleast one owner associated with projects.",
        { position: toast.POSITION.TOP_CENTER }
      );
    } else {
      setIsChecked(!isChecked);
      if (!isChecked) {
        // setOwnerCount((prev) => prev + 1);
        // Clone the original projectMembers array
        const updatedProjectMembers = [...params.data.projectMembers];

        // Add the new member with the memberType
        updatedProjectMembers.push({ ...member, memberType: type });

        params.api.applyTransaction({
          update: [{ ...params.data, projectMembers: updatedProjectMembers }],
        });

        params.node.setDataValue("users", updatedProjectMembers);

        let updatedMembers = convertToObject(params.data.projectMembers);

        updatedMembers = [
          ...updatedMembers,
          { [getTypeID(type)]: member?.userId },
        ];
        params.api.stopEditing();
        updateMembers(updatedMembers);
      } else {
        // setOwnerCount((prev) => prev - 1);
        // Clone the original projectMembers array
        let updatedProjectMembers = [...params.data.projectMembers];

        // Remove the member from the projectMembers array
        updatedProjectMembers = updatedProjectMembers.filter(
          (m) => !(m.userId === member.userId && m.memberType === type)
        );
        let updatedMembers = convertToObject(updatedProjectMembers);

        // Update the grid data with the updated projectMembers array
        params.api.applyTransaction({
          update: [{ ...params.data, projectMembers: updatedProjectMembers }],
        });
        // Refresh the grid to reflect the changes
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
          suppressFlash: true,
        });
        params.api.stopEditing();
        // start editing

        updateMembers(updatedMembers);
      }
    }
  };

  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      console.log("checked clicked");

      setIsChecked(!isChecked);
      handleCheckboxClick();

      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px !important",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className="w-100 border-bottom-1 task-user-filter-dropdown-menu-item"
    >
      <div
        className={`checkbox create-task-checkbox ${
          focusedIndex === index
            ? "task-user-filter-dropdown-menu-item-focused"
            : ""
        }`}
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onClick={handleCheckboxClick}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={member?.profileImage ? member?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span className="ps-1">{member?.userName}</span>
        </label>
      </div>
    </div>
  );
};

export default TasksListingMembersEditor;
