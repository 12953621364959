import React, { useRef, useState } from 'react';

const ScrollableDiv = () => {
  const scrollableRef = useRef(null);
  const [startX, setStartX] = useState(0); // Initial mouse position (X-coordinate)

  const handleMouseDown = (event) => {
    setStartX(event.clientX); // Capture initial mouse position on mousedown
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    const deltaX = event.clientX - startX; // Calculate movement since mousedown
    scrollableRef.current.scrollLeft -= deltaX; // Update scroll position based on movement
  };

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="w-100 outer-div">
      <div
        ref={scrollableRef}
        className="scrollable-div"
        style={{background: "gray", height: "20px", width: "20px"}}
        onMouseDown={handleMouseDown}
      >
      </div>
    </div>
  );
};

export default ScrollableDiv;