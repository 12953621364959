import { PMBaseApi } from "../../services/PMBaseApi";
import { dashboardSlice } from "../dashboard/dashboardSlice";
import { projectSlice } from "../projects/projectSlice";
import { timelineSlice } from "../timeline/timelineSilce";
import { completedTasksSlice } from "./completedTasks.Slice";
import { taskSlice } from "./tasksSlice";

export const phaseTasksSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting task listings with pagination
    getPhaseTaskListing: builder.query({
      query: (body) => ({
        url: `project/phases-tasks`, // API endpoint
        method: "POST",
        body: body,
      }),
      providesTags: ["phaseTasksListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // getPhaseTaskListing: builder.query({
    //   query: ({
    //     projectId,
    //     projectPhaseId,
    //     page,
    //     limit,
    //     type,
    //     timeZone,
    //     status,
    //     priority,
    //     overdue,
    //     selectedDate,
    //     membersFilter,
    //     observersFilter,
    //     ownersFilter,
    //   }) => ({
    //     url: `project/phase-tasks?projectId=${projectId}&projectPhaseId=${projectPhaseId}&page=${page}&limit=${limit}&type=${type}&status=${status}&priority=${priority}&overdue=${overdue}&selectedDate=${selectedDate}&membersFilter=${membersFilter}&observersFilter=${observersFilter}&ownersFilter=${ownersFilter}`, // API endpoint and query parameters
    //     method: "GET",
    //   }),
    //   providesTags: ["phaseTasksListing"], // Tags used for cache management
    //   keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    // }),

    // Define the query for getting task listings with pagination
    getProjectTaskListing: builder.mutation({
      query: (data) => ({
        url: `project/detail`, // API endpoint and query parameters
        method: "POST",
        body: data,
      }),
      providesTags: ["projectTasksListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to get all tasks of a project associated with phases
    getProjectPhaseTaskListing: builder.mutation({
      query: (data) => ({
        url: `project/pm-project-phases-tasks`, // API endpoint and query parameters
        method: "POST",
        body: data,
      }),
      providesTags: ["projectPhaseTasksListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to update task data
    updatePhaseTaskData: builder.mutation({
      query: (data) => ({
        url: `task/update`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectTasksListing"])
          );
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
          if (params.fieldName === "taskStatusId") {
            dispatch(projectSlice.util.invalidateTags(["projectListing"]));
          }
        } catch (error) {
          console.error("Error updating task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update task members
    updatePhaseTaskMemberData: builder.mutation({
      query: (data) => ({
        url: `task/member-update`, // API endpoint to update task members
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectTasksListing"])
          );
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
        } catch (error) {
          console.error("Error updating task member data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to move task among phases
    phaseChange: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),
  }),
});

// Export the hooks generated by `taskSlice`
export const {
  // useGetPhaseTaskListingMutation,
  useGetPhaseTaskListingQuery,
  useUpdatePhaseTaskDataMutation,
  useUpdatePhaseTaskMemberDataMutation,
  usePhaseChangeMutation,
  useGetProjectTaskListingMutation,
  useGetProjectPhaseTaskListingMutation,
} = phaseTasksSlice;
