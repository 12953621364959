import React from "react";
import { v4 as uuidv4 } from "uuid"; // Import from uuid
import SprintBoard from "./SprintBoard";

const SprintList = ({ data }) => {
  return (
    <div className="sprint-main">
      {data &&
        data?.map(
          (res, id) =>
            res?.boardType === "sprint" && <SprintBoard data={res} index={id} />
        )}
      <div className="create-new-sprint">
        <div className="d-flex align-items-center justify-content-center create-new-sprint-text-wrap">
          <p>Start a new sprint</p>
        </div>
      </div>
    </div>
  );
};

export default SprintList;
