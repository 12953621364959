import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { getIndividualFileType } from "./getFileType";

// Add this function outside to download doc type files
export const handleDownloadDocFiles = async (selectedFile) => {
  try {
    saveAs(selectedFile, getIndividualFileType(selectedFile));
  } catch (error) {
    toast.error(error?.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

// if (
//   fileType === "doc" ||
//   fileType === "xls" ||
//   fileType === "ppt" ||
//   fileType === "pptx" ||
//   fileType === "docx" ||
//   fileType === "xlsx" ||
//   fileType === "rtf"
// ) {
//   const downloadLink = document.createElement("a");
//   downloadLink.href = selectedFile; // Replace with your download logic
//   // downloadLink.download = "downloaded_file.ext"; // Set desired filename (optional)
//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   document.body.removeChild(downloadLink);
// }
