import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { set } from "lodash";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const TwoLineInterpolationChart = ({ data, selectedWeek }) => {
  const labels = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];

  const [tasksData, setTaskData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [projectsData, setProjectData] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (data) {
      if (selectedWeek?.value === "this_week") {
        let taskDataCount = data?.currentWeekTasksCount;
        setTaskData([
          taskDataCount?.Sat,
          taskDataCount?.Sun,
          taskDataCount?.Mon,
          taskDataCount?.Tue,
          taskDataCount?.Wed,
          taskDataCount?.Thu,
          taskDataCount?.Fri,
        ]);
        setProjectData([
          data?.currentWeekProjectsCount?.Sat,
          data?.currentWeekProjectsCount?.Sun,
          data?.currentWeekProjectsCount?.Mon,
          data?.currentWeekProjectsCount?.Tue,
          data?.currentWeekProjectsCount?.Wed,
          data?.currentWeekProjectsCount?.Thu,
          data?.currentWeekProjectsCount?.Fri,
        ]);

        // console.log("tasksData", data?.currentWeekTasksCount);
      } else {
        setTaskData([
          data?.lastWeekTasksCount?.Sat,
          data?.lastWeekTasksCount?.Sun,
          data?.lastWeekTasksCount?.Mon,
          data?.lastWeekTasksCount?.Tue,
          data?.lastWeekTasksCount?.Wed,
          data?.lastWeekTasksCount?.Thu,
          data?.lastWeekTasksCount?.Fri,
        ]);
        setProjectData([
          data?.lastWeekProjectCount?.Sat,
          data?.lastWeekProjectCount?.Sun,
          data?.lastWeekProjectCount?.Mon,
          data?.lastWeekProjectCount?.Tue,
          data?.lastWeekProjectCount?.Wed,
          data?.lastWeekProjectCount?.Thu,
          data?.lastWeekProjectCount?.Fri,
        ]);
      }
    }
  }, [selectedWeek, data]);
  console.log("tasksData", selectedWeek?.value, tasksData.toString(), projectsData.toString());

  // Example Data: Includes both positive and negative values
  const defaultDayData = { tasks: 0, projects: 0 };
  const datasetConfig = (label, data, color, borderColor, tension) => ({
    label,
    data,
    backgroundColor: color,
    borderColor: borderColor,
    pointBackgroundColor: color,
    pointRadius: 4,
    pointHoverRadius: 6,
    borderWidth: 2,
    tension, // Cubic interpolation for smooth lines
  });

  // const tasksData = labels.map((day, index) =>
  //   Math.max(
  //     (data?.[day.toLowerCase()] || defaultDayData).tasks ||
  //       (index % 2 === 0 ? index * 2 : -index * 2),
  //     0
  //   )
  // );
  // const projectsData = labels.map((day, index) =>
  //   Math.max(
  //     (data?.[day.toLowerCase()] || defaultDayData).projects ||
  //       (index % 2 === 0 ? -index * 3 : index * 3),
  //     0
  //   )
  // );

  const maxAbsValue = Math.max(
    ...tasksData.map(Math.abs),
    ...projectsData.map(Math.abs)
  );

  const stepSize = Math.ceil(maxAbsValue / 5);
  const yAxisMax = Math.ceil(maxAbsValue / stepSize) * stepSize;

  return (
    <Line
      data={{
        labels,
        datasets: [
          datasetConfig("Tasks", tasksData, "#94ABFF", "#94ABFF", 0.4), // Cubic interpolation
          datasetConfig("Projects", projectsData, "#394B87", "#394B87", 0.4),
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              boxWidth: 6,
              boxHeight: 6,
              usePointStyle: true,
              pointStyle: "circle",
              color: "#333333",
              font: {
                family: "Poppins",
                size: 14,
                weight: 300,
              },
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const label = tooltipItem.dataset.label || "";
                const value = tooltipItem.raw || 0;
                return `${label}: ${value} tasks`;
              },
            },
            bodyFont: {
              family: "Poppins",
              size: 12,
            },
          },
        },
        layout: {
          padding: {
            right: 20,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              padding: 10,
              color: "#a6abbd",
              font: {
                family: "Poppins",
                size: 12,
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: false,
            },
            ticks: {
              beginAtZero: true, // Ensure ticks start from 0
              stepSize: stepSize,
              max: yAxisMax,
              min: 0, // Force y-axis to start at 0
              color: "#a6abbd",
              padding: 10,
              font: {
                family: "Poppins",
                size: 12,
              },
              callback: (value) => `${value}`,
            },
            grid: {
              drawBorder: false,
            },
          },
        },
      }}
      height={320} // Adjusted height
    />
  );
};

export default TwoLineInterpolationChart;
