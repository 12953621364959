import { useState, useRef } from "react";
import { default as ReactSelect, components } from "react-select";

const MultiSelect = (props) => {
  const noDelete = props?.noDelete;
  const noAdd = props?.noAdd;
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label?.toLowerCase().includes(input?.toLowerCase())
    );

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={(e) => {
          if (
            (noDelete &&
              filteredSelectedOptions.some(
                (option) => option.id === props?.data?.id
              )) ||
            (noAdd &&
              filteredSelectedOptions.some(
                (option) => option.id !== props?.data?.id
              ))
          ) {
            e.stopPropagation();
          }
        }}
      >
        {props.value === "*" &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > 0 ? (
          <input
            key={props.value}
            disabled={
              (noDelete &&
                filteredSelectedOptions.some(
                  (option) => option.id === props?.data?.id
                )) ||
              (noAdd &&
                filteredSelectedOptions.some(
                  (option) => option.id !== props?.data?.id
                ))
            }
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = true;
            }}
          />
        ) : (
          <input
            key={props.value}
            disabled={
              (noDelete &&
                filteredSelectedOptions.some(
                  (option) => option.id === props?.data?.id
                )) ||
              (noAdd &&
                filteredSelectedOptions.some(
                  (option) => option.id !== props?.data?.id
                ))
            }
            type="checkbox"
            checked={props.isSelected || isAllSelected.current}
          />
        )}
        <label
          style={{ marginLeft: "5px", marginBottom: "0px" }}
          onClick={(e) => {
            if (
              (noDelete &&
                filteredSelectedOptions.some(
                  (option) => option.id === props?.data?.id
                )) ||
              (noAdd &&
                filteredSelectedOptions.some(
                  (option) => option.id !== props?.data?.id
                ))
            ) {
              e.stopPropagation();
            }
          }}
        >
          {props.label}
        </label>
      </div>
    </components.Option>
  );

  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label?.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" && selectInput) {
      const focusedOption = props.options.find(
        (option) => option.label?.toLowerCase() === selectInput?.toLowerCase()
      );

      if (focusedOption) {
        e.preventDefault();
        handleChange([...props.value, focusedOption]);
      }
    }
  };

  const handleChange = (selected) => {
    setSelectInput("");
    // Check if the "Select All" option was selected
    if (selected.some((option) => option.value === allOption.value)) {
      // If "Select All" was selected, select all options
      if (
        selected.length === 1 || // Only "Select All" selected
        selected.length - 1 === filteredOptions.length // All options selected
      ) {
        // Select all options
        props.onChange(filteredOptions);
      } else {
        // Deselect all options
        props.onChange([]);
      }
    } else {
      props.onChange(selected);
    }
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (props.isSelectAll && props.options?.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        {...props}
        inputValue={selectInput}
        placeholder={props?.placehold ? props.placehold : "Select department"}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={
          noDelete || noAdd
            ? [...props?.options]
            : [allOption, ...props?.options]
        }
        onChange={handleChange}
        onBlur={props?.handleOnBlur}
        components={{
          Option: Option,
          Input: Input,
          ...props?.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props?.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        isDisabled={props?.disabled === false ? true : false}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
    />
  );
};

export default MultiSelect;
