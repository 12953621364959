import {
  CLEAR_ERROR,
  NOTIFICATION_UNREAD_COUNT_FAIL,
  NOTIFICATION_UNREAD_COUNT_REQUEST,
  NOTIFICATION_UNREAD_COUNT_SUCCESS,
} from "../constants/notificationConstants";

export const notificationUnReadCountReducer = (state = null, action) => {
  switch (action.type) {
    case NOTIFICATION_UNREAD_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        unReadNotificationCount: 0,
      };

    case NOTIFICATION_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unReadNotificationCount: action.payload,
      };

    case NOTIFICATION_UNREAD_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        unReadNotificationCount: 0,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        unReadNotificationCount: 0,
      };

    default:
      return state;
  }
};
