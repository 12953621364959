export const isAdmin = (userObject) => {
  // Check if the "roles" property exists and is an array
  if (!userObject?.roles || !Array.isArray(userObject?.roles)) {
    return false; // Early return if roles is missing or not an array
  }

  // Loop through each role in the "roles" array
  for (const role of userObject?.roles) {
    // Check if the role has "roleType" as "admin"
    if (role?.roleType === "admin") {
      return true; // Return true if an admin role is found
    }
  }

  // If no admin role is found, return false
  return false;
};
