import moduleNameImage from "../../../assets/modules-name.svg";
import React, { useEffect, useRef, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { createPortal } from "react-dom";
import { ProjectNameTooltip } from "../../Tasks/components/utils/data";
import getProjectIconById from "../../../helper/projectIconGenerator";
const Portal = (props) => {
  return createPortal(props.children, document.body);
};
export const MemberListingTablePerformanceCellRenderer = (props) => {
  const { memberProgress: progressbarValue } = props.data;
  const progressPercentage = `${progressbarValue}%`;
  const [showProgress, setShowProgress] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const buttonRef = useRef();

  const progressBarStyle = { width: progressPercentage };
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const uniqueId = uuidv4();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    const parentRect = buttonRef.current.getBoundingClientRect();
    setXValue(parentRect.x);
    setYValue(parentRect.y);
    setShowProgress(true);
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      // Click occurred outside the DropButton component
      setShowProgress(false);
    }
  };

  const handleWindowResize = () => {
    if (props.showMore && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setXValue(parentRect.x);
      setYValue(parentRect.y);
    }
  };

  const handleScroll = () => {
    if (showProgress && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setYValue(parentRect.y + window.scrollY);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleScroll); // Remove scroll event listener on cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Re-run effect when showMore changes

  return (
    <div className="position-relative d-flex align-items-center justify-content-between gap-3">
      <div
        className="progress-timeline"
        onMouseEnter={(e) => buttonClickHandler(e)}
        ref={buttonRef}
      >
        <div
          className="progress-bar-timeline"
          role="progressbar"
          aria-valuenow="70"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${progressbarValue}%` }}
        >
          <span className="sr-only">{progressPercentage}% Complete</span>{" "}
        </div>
      </div>
      {/* {showProgress && (
        <Portal>
          <div
            style={{
              position: "absolute",
              left: `${xValue}px`,
              top: `${yValue + 20}px`,
              listStyle: "none",
              background: "#fff", // Set background color
              border: "1px solid #ccc",
              fontSize: "14px",
              fontWeight: 300,
            }}
            className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
          >
            {props.dropdownRenderer(props?.data)}
          </div>
        </Portal>
      )} */}
      {!props.noName && (
        <div
          class="module-task-image position-relative"
          onMouseEnter={(event) => {
            setShowTooltip(true);
            const rect = event.target.getBoundingClientRect();
            setTooltipPosition({ x: rect.x, y: rect.y });
          }}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {" "}
          {props?.data?.project?.projectIcon && (
            <img
              src={
                getProjectIconById(props?.data?.project?.projectIcon)
                  ? getProjectIconById(props?.data?.project?.projectIcon)
                  : props?.data?.project?.projectIcon
              }
              alt="Module Name"
            />
          )}{" "}
          {showTooltip && (
            <Portal>
              <ProjectNameTooltip
                projectName={props?.data?.projectName}
                position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
              />
            </Portal>
          )}
        </div>
      )}
    </div>
  );
};
