import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles/advanceSearchModal.css";
import close from "../../../../assets/close.svg";
import closeFilter from "../../../../assets/close-filter.svg";
import { capitalizeFirstLetter } from "../../../../helper/capitalizeFirstLetter";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { formatDateToYYYYMMDD } from "../../../../helper/dateFormatter";

function TasksAdvanceSearchModalMain({
  showModal,
  setShowModal,
  isDark,
  data,
  setFilters,
  projectUserList,
  projectStatusList,
  projectTypeList,
  taskPriorities,
  taskTypes,
  refreshFilters,
  showOnlyCompletedTasks = false,
  initialFormData,
  formData,
  setFormData,
}) {
  const datePickerRef = useRef(null);
  const timelinePickerRef = useRef(null);
  const timelineToPickerRef = useRef(null);
  const timelineFromPickerRef = useRef(null);
  const createdDatePickerRef = useRef(null);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const [openMore, setOpenMore] = useState(false);

  // Check if formData is the same as initialFormData
  const showClearIcon =
    JSON.stringify(formData) !== JSON.stringify(initialFormData);

  // Dynamic handleChange function
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (type === "checkbox") {
      e.target.blur();
    }
  };

  const [allProjectMembers, setAllProjectMembers] = useState(
    projectUserList?.map((item) => {
      return item.userId;
    })
  );
  // state for all member types list
  const [allMemberTypes] = useState(
    projectTypeList && projectTypeList.length > 0
      ? projectTypeList?.map((item) => {
          return {
            label: capitalizeFirstLetter(item.typeName),
            value: item.id,
          };
        })
      : []
  );

  const [allProjectStatus, setAllProjectStatus] = useState(
    projectStatusList && projectStatusList.length > 0
      ? projectStatusList?.map((item) => {
          return { label: item.statusName, value: item.id };
        })
      : []
  );

  // state for all task priorities
  const [allTaskPriorities] = useState(
    taskPriorities && taskPriorities.length > 0
      ? taskPriorities?.map((item) => {
          return { label: item.priorityName, value: item.id };
        })
      : []
  );

  // state for all task types list options
  const [allTaskTypes] = useState(
    taskTypes && taskTypes.length > 0
      ? taskTypes?.map((item) => {
          return { label: item.typeName, value: item.id };
        })
      : []
  );

  const getUserNameById = (userId) => {
    const user = projectUserList?.find((user) => user.userId === userId);
    return user ? user.userName : ""; // Return userName if found, otherwise return an empty string
  };

  // Modify formData before applying filter
  const prepareSearchData = (formData) => {
    const statusMap = Object.fromEntries(
      projectStatusList?.map((status) => [status.id, status.defaultMarkAs])
    );
    const priorityMap = Object.fromEntries(
      taskPriorities?.map((priority) => [priority.id, priority.defaultMarkAs])
    );
    const typeMap = Object.fromEntries(
      taskTypes?.map((type) => [type.id, type.typeName])
    );
    const memberTypeMap = Object.fromEntries(
      projectTypeList?.map((type) => [type.id, type.typeName])
    );

    return {
      ...formData,
      multiStatus: formData.multiStatus
        ? formData.multiStatus
            .map((status) => statusMap[status.value] || "")
            .filter(Boolean)
            .join(",")
        : "", // Map status IDs to defaultMarkAs
      multiPriority: formData.multiPriority
        ? formData.multiPriority
            .map((priority) => priorityMap[priority.value] || "")
            .filter(Boolean)
            .join(",")
        : "", // Map priority IDs to defaultMarkAs
      taskType: formData.taskType
        ? formData.taskType
            .map((type) => typeMap[type.value] || "")
            .filter(Boolean)
            .join(",")
        : "", // Map type IDs to typeName
      teamsMembers: formData.teamsMembers
        ? formData.teamsMembers.join(",")
        : "", // Keep as is
      teamsMembersTypes: formData.teamsMembersTypes
        ? formData.teamsMembersTypes
            .map((type) => memberTypeMap[type.value] || "")
            .filter(Boolean)
            .join(",")
        : "", // Map team member types to typeName
      projectsIds: formData.projectsIds
        ? formData.projectsIds.map((project) => project.value).join(",")
        : "", // Keep as is
      taskStartDate: formData.taskStartDate
        ? formatDateToYYYYMMDD(formData.taskStartDate)
        : "", // Format start date
      taskEndDate: formData.taskEndDate
        ? formatDateToYYYYMMDD(formData.taskEndDate)
        : "", // Format end date
      createdAt: formData.createdAt
        ? formatDateToYYYYMMDD(formData.createdAt)
        : "", // Format start date
      updatedAt: formData.updatedAt
        ? formatDateToYYYYMMDD(formData.updatedAt)
        : "", // Format end date
      selectedDate: formData.selectedDate
        ? formatDateToYYYYMMDD(formData.selectedDate)
        : "", // Format end date
    };
  };

  // Get some initial form data
  useEffect(() => {
    if (data) {
      setAllProjectMembers(
        projectUserList?.map((item) => {
          return item.userId;
        })
      );

      // Filter out duplicates
      setAllProjectStatus(
        projectStatusList && projectStatusList.length > 0
          ? projectStatusList?.map((item) => {
              return { label: item.statusName, value: item.id };
            })
          : []
      );
      if (
        localStorage.getItem("taskFilter") &&
        localStorage.getItem("taskFilter") === "Critical"
      ) {
        const label = localStorage.getItem("taskFilter");
        const matchedPriority = taskPriorities?.find(
          (item) => item.priorityName === label
        );

        const result = matchedPriority
          ? { label: label, value: matchedPriority.id }
          : null;
        if (result) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            manyPriorities: [result],
          }));
        }
      }
      localStorage.removeItem("taskFilter");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handler to manage multi select
  const handleMultiSelectChange = (e, field) => {
    // const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    if (formData !== null && formData.hasOwnProperty(field)) {
      setFormData((prevFilters) => ({
        ...prevFilters,
        [field]: e,
      }));
    } else {
      setFormData((prevFilters) => ({
        ...prevFilters,
        [field]: e,
      }));
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      size="xl"
      data-theme={isDark}
      keyboard={false}
      className="main-advance-search-modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column p-4 advance-search-modal-body">
        <div className="popup-advance-search-wrap w-100">
          <div className="popup-heading-quick-result">
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-start gap-3 flex-wrap">
                <h3 className="mb-0">
                  {" "}
                  Advance <span>Search</span>{" "}
                </h3>
                {showClearIcon && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <div
                    className="filter-refresh-icon"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Reset filters"
                    onClick={() => {
                      refreshFilters();
                    }}
                  >
                    <Icon icon="humbleicons:refresh" />{" "}
                    <span className="refresh-text">Clear</span>
                  </div>
                )}
              </div>

              <div className="col-md-8  d-flex align-items-center justify-content-end w-max">
                <div className="quick-search-list-wrap">
                  <ul className="gap-md-0 gap-2 mb-0">
                    {/* <li>
                      {" "}
                      <a onClick={() => handleStatusClick("Public", "privacy")}>
                        {" "}
                        Public{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={() => handleStatusClick("Private", "privacy")}
                      >
                        {" "}
                        Private{" "}
                      </a>{" "}
                    </li> */}
                    <li className="flex-1 form-group ">
                      <div
                        onClick={() => datePickerRef.current.flatpickr.open()}
                        data-theme={isDark}
                        className="form-control project-details-main-date-filter d-flex align-items-center justify-content-between advance-search-history-date"
                      >
                        <Flatpickr
                          data-theme={isDark}
                          className="project-details-task-table-flatpickr-container"
                          options={{
                            altInput: true,
                            altFormat: "M d, Y",
                            dateFormat: "Y-m-d",
                            static: true,
                            disableMobile: true,
                          }}
                          placeholder="MMM DD, YYYY"
                          ref={datePickerRef}
                          value={formData?.selectedDate || null}
                          onChange={([date]) => {
                            date &&
                              handleMultiSelectChange(date, "selectedDate");
                            // setSelectedDateFilter(date);
                          }}
                        />
                        <span>
                          <Icon
                            onClick={() =>
                              datePickerRef.current.flatpickr.open()
                            }
                            fontSize={16}
                            icon="lets-icons:date-range"
                          />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="form-filed-advance-seach-wrap">
            {/* <h4 className="text-start"> Tasks </h4> */}
            <form className="row">
              {/* Search */}
              <div className="col-md-12">
                <div className="form-group">
                  <div className="w-100 advanced-input-search position-relative">
                    <input
                      placeholder="Search here"
                      className="w-100"
                      type="text"
                      name="searchText"
                      value={formData.searchText}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="position-absolute advanced-input-search-icon">
                      <Icon icon="wpf:search" />
                    </div>
                  </div>
                </div>
              </div>

              {/* task name */}
              <div className="col-md-6">
                <div className="form-group">
                  <div className="w-100 advanced-input-search position-relative">
                    <input
                      type="text"
                      placeholder="Add Task Name"
                      className="w-100"
                      name="taskName"
                      value={formData.taskName}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              {/* select users */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allProjectMembers?.map((option) => {
                      return {
                        value: option,
                        label: getUserNameById(option),
                      };
                    })}
                    value={formData?.teamsMembers?.map((option) => {
                      return {
                        value: option,
                        label: getUserNameById(option),
                      };
                    })}
                    onChange={(selected) => {
                      handleMultiSelectChange(
                        selected.map((item) => item.value),
                        "teamsMembers"
                      );
                    }}
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions.map(
                                        (item) => item.value
                                      ),
                                      "teamsMembers"
                                    );
                                  }}
                                  alt="filter-icon"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Team Members
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              {/* member list types */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allMemberTypes || []}
                    value={formData?.teamsMembersTypes || []}
                    onChange={(selected) => {
                      handleMultiSelectChange(selected, "teamsMembersTypes");
                    }}
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "teamsMembersTypes"
                                    );
                                  }}
                                  alt="filter-icon"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Member Types
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              {/* project status */}
              {!showOnlyCompletedTasks && (
                <div className="col-md-6">
                  <div className="form-group">
                    <MultiSelect
                      options={allProjectStatus}
                      value={formData?.multiStatus || []}
                      onChange={(selected) =>
                        handleMultiSelectChange(selected, "multiStatus")
                      }
                      labelledBy="Select"
                      className="multi-select"
                      valueRenderer={(selected) => (
                        <>
                          {selected.length > 0 ? (
                            <div className="selected-options-list-wrap">
                              {selected?.map((item, index) => (
                                <div key={index}>
                                  {item.label}
                                  <img
                                    src={closeFilter}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      const remainingOptions = selected.filter(
                                        (option) => option.value !== item.value
                                      );
                                      handleMultiSelectChange(
                                        remainingOptions,
                                        "multiStatus"
                                      );
                                    }}
                                    alt="filter-icon"
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p className="multiselect-placeholder">
                              Select Status
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              )}
              {/* project priorities */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allTaskPriorities}
                    value={formData?.multiPriority || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "multiPriority")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "multiPriority"
                                    );
                                  }}
                                  alt="filter-icon"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Priorities
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* task types  */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allTaskTypes}
                    value={formData?.taskType || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "taskType")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "taskType"
                                    );
                                  }}
                                  alt="filter-icon"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Task Types
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </form>
          </div>

          {/* More options */}
          <div className="form-filed-advance-seach-wrap">
            <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
              <h4 className="text-start mb-0"> More Options </h4>
              <div
                className="more-option-open-icon"
                onClick={() => setOpenMore(!openMore)}
              >
                <Icon
                  icon="icon-park-outline:down"
                  className={
                    openMore ? "flipped-icon-more" : "flipped-icon-no-more"
                  }
                />
              </div>
            </div>
            {openMore && (
              <div className="advanced-more-options mt-3">
                <div className="row">
                  {/** To from input */}
                  <div className="col-md-6 ">
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                      <p className="mb-0 task-to-from-text">Tasks</p>
                      <div className="row flex-1">
                        {/* to date input */}
                        <div className="col-md-6 ">
                          <div className=" form-group cursor-pointer mb-0">
                            <div
                              data-theme={isDark}
                              className=" project-advance-filter-timeline-date-picker project-details-task-table-flatpickr-container  d-flex align-items-center justify-content-between "
                            >
                              <Flatpickr
                                ref={timelineFromPickerRef}
                                data-theme={isDark}
                                className="project-details-task-table-flatpickr-container"
                                options={{
                                  // mode: "range",
                                  altInput: true,
                                  altFormat: "M d, Y",
                                  dateFormat: "Y-m-d",
                                  static: true,
                                  disableMobile: true,
                                  maxDate: formData?.taskEndDate || null, // Prevent selecting after the task end date
                                }}
                                placeholder="From"
                                value={formData?.taskStartDate || null}
                                onChange={([date]) => {
                                  date &&
                                    handleMultiSelectChange(
                                      date,
                                      "taskStartDate"
                                    );
                                }}
                              />
                              <span className="project-advance-filter-timeline-date-pickr-icon">
                                <Icon
                                  onClick={() =>
                                    timelineFromPickerRef.current.flatpickr.open()
                                  }
                                  icon="lets-icons:date-range"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* from date input */}
                        <div className="col-md-6 ">
                          <div className=" form-group cursor-pointer mb-0">
                            <div
                              data-theme={isDark}
                              className=" project-advance-filter-timeline-date-picker project-details-task-table-flatpickr-container  d-flex align-items-center justify-content-between "
                            >
                              <Flatpickr
                                ref={timelineToPickerRef}
                                data-theme={isDark}
                                className="project-details-task-table-flatpickr-container"
                                options={{
                                  // mode: "range",
                                  altInput: true,
                                  altFormat: "M d, Y",
                                  dateFormat: "Y-m-d",
                                  static: true,
                                  disableMobile: true,
                                  minDate: formData?.taskStartDate || null, // Prevent selecting before the task start date
                                }}
                                placeholder="To"
                                value={formData?.taskEndDate || null}
                                onChange={([date]) => {
                                  date &&
                                    handleMultiSelectChange(
                                      date,
                                      "taskEndDate"
                                    );
                                }}
                              />
                              <span className="project-advance-filter-timeline-date-pickr-icon">
                                <Icon
                                  onClick={() =>
                                    timelineToPickerRef.current.flatpickr.open()
                                  }
                                  icon="lets-icons:date-range"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* created date input */}
                  <div className="col-md-6 ">
                    <div className=" form-group cursor-pointer">
                      <div
                        data-theme={isDark}
                        className=" project-advance-filter-timeline-date-picker project-details-task-table-flatpickr-container  d-flex align-items-center justify-content-between "
                      >
                        <Flatpickr
                          ref={createdDatePickerRef}
                          data-theme={isDark}
                          className="project-details-task-table-flatpickr-container"
                          options={{
                            // mode: "range",
                            altInput: true,
                            altFormat: "M d, Y",
                            dateFormat: "Y-m-d",
                            static: true,
                            disableMobile: true,
                          }}
                          placeholder="Created Date"
                          value={formData?.createdAt || null}
                          onChange={([date]) => {
                            date && handleMultiSelectChange(date, "createdAt");
                          }}
                        />
                        <span className="project-advance-filter-timeline-date-pickr-icon">
                          <Icon
                            onClick={() =>
                              createdDatePickerRef.current.flatpickr.open()
                            }
                            icon="lets-icons:date-range"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* updated date input */}
                  <div className="col-md-6 ">
                    <div className=" form-group cursor-pointer">
                      <div
                        data-theme={isDark}
                        className=" project-advance-filter-timeline-date-picker project-details-task-table-flatpickr-container  d-flex align-items-center justify-content-between "
                      >
                        <Flatpickr
                          ref={timelinePickerRef}
                          data-theme={isDark}
                          className="project-details-task-table-flatpickr-container"
                          options={{
                            // mode: "range",
                            altInput: true,
                            altFormat: "M d, Y",
                            dateFormat: "Y-m-d",
                            static: true,
                            disableMobile: true,
                          }}
                          placeholder="Updated Date"
                          value={formData?.updatedAt || null}
                          onChange={([date]) => {
                            date && handleMultiSelectChange(date, "updatedAt");
                          }}
                        />
                        <span className="project-advance-filter-timeline-date-pickr-icon">
                          <Icon
                            onClick={() =>
                              timelinePickerRef.current.flatpickr.open()
                            }
                            icon="lets-icons:date-range"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/** Projects */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <MultiSelect
                        hideSelectAll={true}
                        options={projectFormList?.map((option, index) => {
                          return {
                            label: option?.projectName,
                            value: option?.id,
                          };
                        })}
                        value={formData?.projectsIds || []}
                        onChange={(selected) =>
                          handleMultiSelectChange(selected, "projectsIds")
                        }
                        labelledBy="Select"
                        className="multi-select"
                        valueRenderer={(selected) => (
                          <>
                            {selected.length > 0 ? (
                              <div className="selected-options-list-wrap">
                                {selected?.map((item, index) => (
                                  <div key={index}>
                                    {item.label}
                                    <img
                                      src={closeFilter}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const remainingOptions =
                                          selected.filter(
                                            (option) =>
                                              option.value !== item.value
                                          );
                                        handleMultiSelectChange(
                                          remainingOptions,
                                          "projectsIds"
                                        );
                                      }}
                                      alt="filter-icon"
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="multiselect-placeholder">
                                Select Projects
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="d-flex align-items-center mb-0 control-task control-task-checkbox control-select-checkbox advanced-check-options">
                      <input
                        type="checkbox"
                        name="isDeleted"
                        checked={formData.isDeleted}
                        onChange={handleChange}
                      />
                      <div className="control_task_indicator control-select-indicator"></div>
                      <p>Deleted Tasks</p>
                    </label>
                  </div>
                  <div>
                    <label className="mt-3 d-flex align-items-center mb-0 control-task control-task-checkbox control-select-checkbox advanced-check-options">
                      <input
                        type="checkbox"
                        name="hasApproval"
                        checked={formData.hasApproval}
                        onChange={handleChange}
                      />
                      <div className="control_task_indicator control-select-indicator"></div>
                      <p>Review for Approval</p>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="btn-searc-result-wraper">
            <div className="row">
              <div className="col-md-6">
                <div className="more-filter-btn d-flex"></div>
              </div>
              <div className="col-md-6">
                <div className="main-search-btn">
                  <button
                    onClick={async () => {
                      // setShowModal(false);
                      const modifiedData = prepareSearchData(formData);
                      setFilters((prevServerFilters) => ({
                        ...prevServerFilters,
                        ...modifiedData,
                      }));
                      setShowModal(false);
                    }}
                  >
                    {" "}
                    Search Advance Filters{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TasksAdvanceSearchModalMain;
