import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginUser } from "../../actions/userActions";
import { toast } from "react-toastify";
import API from "../../services/ClientApi";
import { requestForToken } from "../../firebase-config";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import saveSwitchCompanyCookies from "../../services/saveSwitchCompanyCookies";
// import {
//   clearTaskListAction,
//   emptyTaskPrioritiesListAction,
//   emptyTaskTypesListAction,
// } from "../../actions/tasksActions";
// import {
//   clearProjectListAction,
//   emptyProjectFormListingAction,
//   emptyProjectListingStatusAction,
//   emptyProjectTypeListingAction,
//   emptyProjectUserListingAction,
// } from "../../actions/projectActions";
// import {
//   clearActiveProjectListAction,
//   clearOngoingTasksListAction,
//   clearTimelineStats,
// } from "../../actions/timelineActions";
// import { emptyTeamsListAction } from "../../actions/teamsActions";

const CompanyListModal = (props) => {
  const dispatch = useDispatch();
  const [rload, setRload] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [error, setError] = useState(null);
  const [showPasswordInput, setShowPasswordInput] = React.useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])/, // Alphanumeric validation
        "Password must be alphanumeric"
      )
      .min(6, "Password must be at least 6 characters"),
  });

  const handleCompanySelect = (companyId) => {
    setSelectedCompany(companyId);
    setShowPasswordInput(true);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setRload(true);
        const response = await dispatch(
          loginUser(props.user?.emailAddress, values.password, selectedCompany)
        );
        if (response.status === "success") {
          //   dispatch(clearTaskListAction());
          //   dispatch(clearProjectListAction);
          //   dispatch(clearActiveProjectListAction());
          //   dispatch(clearOngoingTasksListAction());
          //   dispatch(clearTimelineStats());
          //   dispatch(emptyTaskTypesListAction());
          //   dispatch(emptyProjectFormListingAction());
          //   dispatch(emptyProjectListingStatusAction());
          //   dispatch(emptyProjectTypeListingAction());
          //   dispatch(emptyProjectUserListingAction());
          //   dispatch(emptyTaskPrioritiesListAction());
          //   dispatch(emptyTeamsListAction());
          const permissions = await API.get("users/permissions-urls");
          if (!permissions) {
            toast.error("An unexpected error occured.", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            localStorage.setItem(
              "permissions",
              JSON.stringify(permissions?.data?.data)
            );
            localStorage.setItem("company", response?.data?.companySlug);
            // props?.setCompanySlug(response?.data?.companySlug);
            if (
              permissions?.data?.status === "success" &&
              localStorage.getItem("company") &&
              localStorage.getItem("permissions")
            ) {
              saveSwitchCompanyCookies();
              window.location.href = `/${localStorage.getItem(
                "company"
              )}/dashboard`;

              toast.success("Logged in successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              requestForToken();
            }
          }
        } else {
          setError(response.response.data.message);
        } // Other logic after successful login
      } catch (error) {
        console.log(error);
      } finally {
        setRload(false);
      }
    },
  });

  useEffect(() => {
    const getCompanies = async () => {
      const filteredCompanies = props?.userCompanies.filter(
        (company) =>
          company.companySlug.toLowerCase() ===
          props.user?.companySlug.toLowerCase()
      );
      setCompanies(props?.userCompanies);
      setSelectedCompany(filteredCompanies[0]?.companyId);
    };

    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={props.showCompanyModal}
      onHide={props.handleCloseCompanyModal}
      className="main-update-modal choose-company-modal-main"
      backdrop="static"
      keyboard={false}
      data-theme={props.isDark}
      centered
    >
      <Modal.Header
        className="update-modal-header choose-company-modal position-relative"
        
      >
        <h6>
          Choose <span style={{ fontWeight: 300 }}>a company</span>
        </h6>
        <button
          className="custom-modal-close-btn position-absolute top-0 end-0" 
          onClick={props.handleCloseCompanyModal}
        >
          <Icon icon="maki:cross" />
        </button>
      </Modal.Header>

      <Modal.Body className="update-modal-body company-modal-body">
        <p className="role-page-description mb-2">
          Multiple accounts associated, please choose one to proceed with
        </p>
        <div className="row company-modal-row">
          {companies?.length > 0 ? (
            companies?.map((res, id) => (
              <div className="col-md-4 modules-opt-wrap" key={id}>
                <div
                  className={`d-flex flex-column company-select ${
                    selectedCompany === res?.companyId
                      ? "company-select-modal"
                      : ""
                  }`}
                  onClick={() => handleCompanySelect(res?.companyId)}
                >
                  <p>{res.companyName}</p>
                  <p>{res.companySlug}</p>
                </div>
              </div>
            ))
          ) : (
            <option value="" disabled>
              No companies available
            </option>
          )}
        </div>
        {showPasswordInput && (
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="password" style={{ fontSize: "14px" }}>
                Password*
              </label>
              <input
                type="password"
                className={`form-control ${
                  (formik.touched.password && formik.errors.password) || error
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Your Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={async (e) => {
                  formik.setErrors({
                    password: "",
                  });
                  setError("");
                  formik.handleChange(e);
                }}
                value={formik.values.password}
                style={{ fontSize: "14px" }}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback right-error">
                  {formik.errors.password}
                </div>
              )}
              {error && <p className="auth-err">{error}</p>}
            </div>
            <div className="mt-3 w-100 d-flex justify-content-end align-items-center company-btn-wrapper">
              <button type="submit" disabled={rload}>
                {rload ? (
                  <Spinner className="spinner-role" border="animation" />
                ) : (
                  "Switch Account"
                )}
              </button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CompanyListModal;
