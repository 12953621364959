import React, { useMemo } from "react";
import UsersListSearch from "./UsersListSearch";
import UsersListCard from "./UsersListCard";

function WrapperUsersList({
  setSelectedChat,
  selectedChat,
  setSearchData,
  searchData,
  chats,
  socket,
  roomChangeLoad,
  setRoomChangeLoad,
  setRoomId,
  setDays,
  setOnline,
  setAway,
  setDayState,
  setUnreadCount,
}) {
  return (
    <div className="user-lists-main-wrapper">
      <UsersListSearch setSearchData={setSearchData} chats={chats} />
      <div className="user-lists-card-wrapper custom-horizental-scrollbar">
        {searchData && searchData?.length > 0 ? (
          <>
            {searchData?.map((item) => {
              const isRoom = item.hasOwnProperty("room_id");
              const isUser = item.hasOwnProperty("user_id");

              return (
                <UsersListCard
                  key={isRoom ? item.room_id : item.user_id} // Use room_id for room or user_id for user
                  setSelectedChat={setSelectedChat}
                  selectedChat={selectedChat}
                  data={item}
                  isSelected={
                    (isRoom && item?.room_id === selectedChat?.room_id) ||
                    (isUser && item?.user_id === selectedChat?.user_id)
                  }
                  typing={false}
                  roomChangeLoad={roomChangeLoad}
                  setRoomChangeLoad={setRoomChangeLoad}
                  setRoomId={setRoomId}
                  setDays={setDays}
                  setOnline={setOnline}
                  setAway={setAway}
                  setDayState={setDayState}
                  setUnreadCount={setUnreadCount}
                />
              );
            })}
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <p className="text-size-weight">No user found.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default WrapperUsersList;
