const initialState = {
  applicationName: "IFFRA-Task Pro",
};

export const textReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TEXT":
      return {
        ...state,
        applicationName: action.payload,
      };
    default:
      return state;
  }
};
