/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import "../../Leads/styles/leads.scss";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../styles/roles.css";
import Caret from "../../../assets/caret-table-icon.svg";
import Role from "../../../assets/role-arrow.svg";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import RoleNameModal from "./RoleNameModal";
import Dropdown from "react-bootstrap/Dropdown";
import Delete from "../../../assets/delete.svg";
import DeleteRoleModal from "./DeleteRoleModal";
import Offcanvas from "react-bootstrap/Offcanvas";
import CloseBtn from "../../../assets/icon-close.svg";
import { Helmet } from "react-helmet";
import Button from "../../../components/Button";
import { dateFormatter } from "../../../helper/dateFormatter";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import { ShimmerCategoryItem, ShimmerTable } from "react-shimmer-effects";

const RolesMain = (props) => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [idR, setIdR] = useState([]);
  const [checkedModules, setCheckedModules] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [add, setAdd] = useState(false);
  const [sload, setSload] = useState(false);
  const [modalRole, setModalRole] = useState(false);
  const [roleName, setRoleName] = useState({ name: "", id: "", members: 0 });
  const [roleType, setRoleType] = useState(null);
  const [roleCaetgories, setRoleCategories] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [privateId, setPrivate] = useState([]);
  const [publicId, setPublic] = useState([]);
  const [rErr, setRErr] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [pload, setPLoad] = useState(false);
  const initialValues = {
    role: "",
  };
  const [show, setShow] = useState(false);
  const [added, setAdded] = useState(false);
  const { applicationName } = useSelector((state) => state.text);
  const [seeMore, setSeeMore] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);

  const verifyModalContent = findObjectByContentTitle(
    "roles-and-permissions",
    modalContents?.data
  );

  const handleCloseModal = () => {
    setShow(false);
  };
  const handleShowModal = () => {
    setShow(true);
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Role is required"),
  });

  const handleClose = () => {
    setModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleModalRoleClose = () => {
    setModalRole(false);
  };

  const handlePrivatePublic = async (pId, pType, iid, mid) => {
    if (pType === "PUBLIC") {
      const updatedIds = privateId.includes(iid)
        ? privateId.filter((id) => id !== iid) // Remove iid if it exists
        : [...privateId, iid];
      setPrivate(updatedIds);
      const updatedPrivate = [...publicId, iid];
      setPublic(updatedPrivate);
    } else {
      const updatedIds = publicId.includes(iid)
        ? publicId.filter((id) => id !== iid) // Remove iid if it exists
        : [...publicId, iid];
      setPublic(updatedIds);
      const updatedPrivate = [...privateId, iid];
      setPrivate(updatedPrivate);
    }

    const updatedRoleCategories = roleCaetgories.map((roleCategory) => {
      // Find the target module in the modules array
      const updatedModules = roleCategory.modules.map((module) => {
        if (module.moduleId === mid) {
          // Update the isChecked property based on the action
          module.accessType = pType;
        }
        return module;
      });

      // Return the updated roleCategory object
      return {
        ...roleCategory,
        modules: updatedModules,
      };
    });

    // Update the state with the modified roleCategories array
    setRoleCategories(updatedRoleCategories);

    try {
      const responseU = await API.post("company/roles-access-update", {
        permissionId: pId,
        permissionsType: pType,
      });
      props?.getOptions();
      const permissions = await API.get("users/permissions-urls");
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions?.data?.data)
      );
      // getPermissions(roleId, false);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const getRolesAndPermissions = async () => {
      try {
        setLoad(true);
        let response = await API.get("company/roles");
        setRoles(response?.data?.data);
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getRolesAndPermissions();
  }, []);

  const handleArrowClick = (roleName) => {
    setIdR((prevRoleNames) => {
      if (prevRoleNames?.includes(roleName)) {
        // RoleName exists, remove it
        return prevRoleNames?.filter((name) => name !== roleName);
      } else {
        // RoleName does not exist, add it
        return [...prevRoleNames, roleName];
      }
    });
  };

  const handleUpdateRole = async (rId, mId, action, mid, iid) => {
    const updatedIds = checkedModules.includes(iid)
      ? checkedModules.filter((id) => id !== iid) // Remove iid if it exists
      : [...checkedModules, iid];
    setCheckedModules(updatedIds);
    if (action === "add") {
      const newPublic = [...publicId, iid];
      setPublic(newPublic);
    } else {
      const updatedIds = publicId.includes(iid)
        ? publicId.filter((id) => id !== iid) // Remove iid if it exists
        : [...publicId, iid];
      setPublic(updatedIds);
    }

    // Clone the roleCategories array to avoid direct mutation
    const updatedRoleCategories = roleCaetgories.map((roleCategory) => {
      // Find the target module in the modules array
      const updatedModules = roleCategory.modules.map((module) => {
        if (module.moduleId === mId) {
          // Update the isChecked property based on the action
          module.isChecked = action === "add";
        }
        return module;
      });

      // Return the updated roleCategory object
      return {
        ...roleCategory,
        modules: updatedModules,
      };
    });
    // Update the state with the modified roleCategories array
    setRoleCategories(updatedRoleCategories);

    try {
      const responseU = await API.post("company/update-role-permissions", {
        roleId: rId,
        moduleId: mId,
        actionType: action,
      });
      props?.getOptions();
      const permissions = await API.get("users/permissions-urls");
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions?.data?.data)
      );
      // getPermissions(roleId, false);
    } catch (error) {
    } finally {
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // You can perform any additional submission logic here
    // If there are no validation errors
    setAdded(true);
    if (values.role) {
      setSload(true);
      try {
        const response = await API.post("company/role-create", {
          roleName: values.role,
        });

        if (response?.data?.status === "success") {
          let responsec = await API.get("company/roles");
          setRoles(responsec?.data?.data);
          handleClose();
          toast.success("Role added successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setSload(false);
          setAdd(true);
          handleShowModal();
          setRoleId(response?.data?.data[0]?.roleId);
          getPermissions(response?.data?.data[0]?.roleId, true);
        } else {
          setRErr("Role name already exists");
        }
      } catch (error) {
      } finally {
        setSload(false);
      }
    }

    // Set submitting to false to allow the form to be submitted again
    setSubmitting(false);
  };

  // Keyboard enter
  const getRolesAfterUpdate = async () => {
    try {
      let response = await API.get("company/roles");
      setRoles(response?.data?.data);
    } catch (error) {}
  };

  const getPermissions = async (id, load) => {
    try {
      if (load) {
        setPLoad(true);
      }
      const response = await API.post("company/roles-permissions", {
        roleId: id,
      });
      setRoleType(response?.data?.data[0]?.roleType);
      const rolesCategoriesNew = response?.data?.data[0]?.rolesCategories?.map(
        (category) => ({
          ...category,
          modules: category?.modules.map((module) => {
            const uid = uuidv4();
            if (module?.isChecked) {
              // If isChecked is true, store the uid in the state
              setCheckedModules((prevCheckedModules) => [
                ...prevCheckedModules,
                uid,
              ]);
            }
            if (module?.isChecked && module?.accessType === "PUBLIC") {
              setPublic((prevCheckedModules) => [...prevCheckedModules, uid]);
            } else if (module?.isChecked && module?.accessType === "PRIVATE") {
              setPrivate((prevCheckedModules) => [...prevCheckedModules, uid]);
            }
            return {
              ...module,
              uid: uid,
            };
          }),
        })
      );
      setRoleCategories(rolesCategoriesNew);
    } catch (error) {
    } finally {
      setPLoad(false);
    }
  };

  // Used to stop animation after it ran for 2.8 sec, afetr adding new role
  useEffect(() => {
    if (!added) {
      return; // No need to continue if animation has ended
    }

    // Set a timeout to trigger setAdded(false) after the animation
    const timeoutId = setTimeout(() => {
      setAdded(false);
    }, 2800); // Adjust the timeout to match your animation duration

    // Clear the timeout on component unmount or when added state changes
    return () => clearTimeout(timeoutId);
  }, [added]);

  // AG-Grid library
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const columns = [
    {
      field: "roleName",
      headerName: "Role",
      editable: (params) => params.data.roleType !== "Default",
      cellStyle: (params) => {
        return {
          cursor: "pointer",
        };
      },
      onCellValueChanged: async (params) => {
        try {
          if (params.newValue === "" || !params.newValue) {
            await getRolesAfterUpdate();
            return 0;
          }
          const response = await API.post("company/role-update", {
            roleId: params?.data?.roleId,
            roleName: params.newValue,
          });
          if (response?.data?.status === "success") {
            toast.success("Role updated successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            getRolesAfterUpdate();
          }
          if (response === undefined) {
            toast.error("Name already exists", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            getRolesAfterUpdate();
          }
        } catch (error) {
        } finally {
        }
      },
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.roleName
              ? params.data.roleName
                  .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
                  .replace(/^(.)/, (_, char) => char.toUpperCase())
              : ""}
          </div>
        );
      },
    },
    {
      field: "userRoleCount",
      headerName: "Members",
      cellRenderer: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>{params.data.userRoleCount}</div>
        );
      },
      editable: false,
    },
    {
      field: "roleType",
      headerName: "Type",
      editable: false,
      maxWidth: 150,
      cellClassName: "ellipsis-cell",
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.roleType.length > 12
              ? params.data.roleType.split("").splice(0, 12).join("") + "..."
              : params.data.roleType}
          </div>
        );
      },
      cellStyle: (params) => {
        const backgroundColor = "#ece1d3";
        return {
          backgroundColor: backgroundColor,
          textAlign: "center",
          whiteSpace: "nowrap", // Prevents text from wrapping
          overflow: "hidden !important", // Hides any content that overflows the container
          textOverflow: "ellipsis",
        };
      },
    },
    {
      field: "createdDate",
      headerName: "Created",
      editable: false,
      cellRenderer: (params) => {
        return dateFormatter(params?.data?.createdAt);
      },
    },
    {
      field: "updatedDate",
      headerName: "Last Updated",
      editable: false,

      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => handleRowClick(params)}>
              {dateFormatter(params?.data?.updatedAt)}
            </p>
            {params?.data?.roleType !== "Default" && (
              <Dropdown className="roles-drop-down roles-drop-role">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <button className="action-btn">
                    <img src={Caret} alt="cart-icon" />
                  </button>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setAdd(false);
                      setRoleName({
                        ...roleName,
                        name: params?.data?.roleName,
                        id: params?.data?.roleId,
                        members: params?.data?.userRoleCount,
                      });
                      setShowDeleteModal(true);
                    }}
                  >
                    <img
                      src={Delete}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      alt="delete-icon"
                    />
                    Delete role
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        );
      },
    },
  ];

  let clickTimer;

  const handleRowClick = (params) => {
    handleShowModal();
    setRoleId(params?.data?.roleId);
    getPermissions(params?.data?.roleId, true);
  };

  const handleCellClick = (params) => {
    // Check if the clicked cell is part of the "roleName" column
    if (params.colDef.field === "roleName") {
      // Use a timer to differentiate between single click and double click
      if (!clickTimer) {
        // Single click
        clickTimer = setTimeout(() => {
          clearTimeout(clickTimer);
          clickTimer = null;

          // Execute single-click action (if needed)
          handleRowClick(params);
          // For example: handleRowClick(params);
        }, 200); // Adjust the time interval as needed
      } else {
        // Double click
        clearTimeout(clickTimer);
        clickTimer = null;
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.colDef.field,
        });
        // Execute double-click action
      }
    } else if (params.colDef.field !== "updatedDate") {
      handleRowClick(params);
    }

    if (params.colDef.field === "userRoleCount") {
      if (params.data.userRoleCount > 0) {
        navigate(`/${localStorage.getItem("company")}/company-employees`);
        const userManagementFilters = JSON.parse(
          localStorage.getItem("userManagementFilters")
        )
          ? JSON.parse(localStorage.getItem("userManagementFilters"))
          : null;
        if (userManagementFilters) {
          localStorage.setItem(
            "userManagementFilters",
            JSON.stringify({
              ...userManagementFilters,
              roles: userManagementFilters?.roles
                ? [
                    ...userManagementFilters?.roles,
                    {
                      label: capitalizeFirstLetter(params.data.roleName),
                      value: capitalizeFirstLetter(params.data.roleName),
                    },
                  ]
                : [
                    {
                      label: capitalizeFirstLetter(params.data.roleName),
                      value: capitalizeFirstLetter(params.data.roleName),
                    },
                  ],
            })
          );
        } else {
          localStorage.setItem(
            "userManagementFilters",
            JSON.stringify({
              roles: [
                {
                  label: capitalizeFirstLetter(params.data.roleName),
                  value: capitalizeFirstLetter(params.data.roleName),
                },
              ],
            })
          );
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roles & Permissions-{applicationName}</title>
      </Helmet>
      <div className="user-dashboard-main-wrap roles-dash-main-wrap">
        <div className="permission-heading-role-wrap">
          <div className="row">
            <div className="col-md-12">
              <div className="role-heading-list-wrap">
                <ul className="w-100 d-flex justify-content-between role-main-ui">
                  <li>
                    <div className="role-heading-wrap">
                      {" "}
                      Roles & <span>Permissions </span>{" "}
                    </div>
                  </li>

                  <li>
                    <div className="btn-leads-cta-wrap role-main-ui-btn">
                      {" "}
                      <Button
                        icon="fa fa-add"
                        text="Add New Role"
                        handleClick={() => setModal(true)}
                        width="180px"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <p className="role-page-description">
                {verifyModalContent?.short_description}{" "}
                <span className="see-more-txt" onClick={() => setSeeMore(true)}>
                  See More
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="table-role-permission-wraper">
          <div className="table-responsive croll-smooth custom-horizental-scrollbar">
            {/* {load ? (
              <div className="w-100 d-flex justify-content-center align-items-center global-spinner">
                <Spinner animation="border" />
              </div>
            ) : ( */}
            <div
              className="ag-theme-alpine"
              style={{
                width: "100%",
                minWidth: "900px",
                maxWidth: "900px",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {load ? (
                <div className="position-relative">
                  <div className="ms-1">
                    <ShimmerTable row={4} />
                  </div>
                </div>
              ) : (
                <AgGridReact
                  rowData={roles}
                  columnDefs={columns}
                  defaultColDef={defaultColDef}
                  rowDragManaged={false}
                  rowDragEntireRow={false}
                  animateRows={true}
                  suppressRowTransform={true}
                  domLayout={"autoHeight"}
                  stopEditingWhenCellsLoseFocus={true}
                  getRowNodeId={(data) => data.id}
                  deltaRowDataMode={true}
                  getRowStyle={(params) => {
                    if (added && params.node.rowIndex === roles.length - 1) {
                      // Apply animation only to the last row
                      return { animation: "bottomToTop 1s" };
                    }
                    return {};
                  }}
                  onCellClicked={(params) => handleCellClick(params)}
                ></AgGridReact>
              )}
            </div>
            {/* )} */}
          </div>
        </div>
        <Offcanvas
          className="off-concave-role"
          show={show}
          // onHide={handleCloseModal}
          placement="end"
          data-theme={props.isDark}
        >
          {/* <Offcanvas.Header closeButton>
      <Offcanvas.Title>Notifications</Offcanvas.Title>
    </Offcanvas.Header> */}
          <Offcanvas.Body>
            <div className="subscription-main-new-wraper">
              <div className="subrcip-top-heading-field-wrap">
                <div className="d-flex justify-content-between role-offconcave-header-wrap">
                  <div className="heading-permission-wrap">
                    {" "}
                    Set <span>Permissions</span>{" "}
                  </div>

                  <div className="form-group form-role">
                    <select
                      className="form-control"
                      value={roleId}
                      onChange={(e) => {
                        setRoleId(e.target.value);
                        getPermissions(e.target.value, true);
                      }}
                    >
                      {roles?.map((r, id) => (
                        <option key={id} value={r?.roleId}>
                          {capitalizeFirstLetter(r?.roleName)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {!pload && roleType === "admin" && (
                  <div className="col-md-12">
                    <p className="role-page-description role-page-description-admin">
                      <span>Note:</span> Admin permissions cannot be updated.
                    </p>
                  </div>
                )}
              </div>
              {pload ? (
                <div className="position-relative">
                  <div className="ms-1">
                    <ShimmerTable row={1} />
                    <ShimmerCategoryItem
                      hasImage
                      imageType="thumbnail"
                      imageWidth={200}
                      imageHeight={200}
                      title
                    />
                    <ShimmerCategoryItem
                      hasImage
                      imageType="thumbnail"
                      imageWidth={200}
                      imageHeight={200}
                      title
                    />
                    <ShimmerCategoryItem
                      hasImage
                      imageType="thumbnail"
                      imageWidth={200}
                      imageHeight={200}
                      title
                    />
                  </div>
                </div>
              ) : (
                <div className="table-main-wraper-permission">
                  <div className="table-responsive">
                    <table
                      width="100%"
                      border="1"
                      className="table table-role-permission "
                    >
                      <tr className="heading-permission">
                        <td>Modules</td>
                        <td className="d-flex align-items-center px-4 w-full justify-content-between flex-row">
                          Details{" "}
                          <span w className="pull-right ">
                            {" "}
                            Permissions/Access{" "}
                          </span>
                        </td>
                      </tr>
                      {roleCaetgories?.map(
                        (res, id) =>
                          res?.modules?.length > 0 && (
                            <tr key={id}>
                              <td className="d-flex align-items-center table-permission-modules-td pt-4">
                                {res?.categoryName}{" "}
                                <a
                                  className={`arrow-btn-role ${
                                    idR.includes(res?.categoryName)
                                      ? "flip-arrow"
                                      : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleArrowClick(res?.categoryName)
                                  }
                                >
                                  <img src={Role} alt="role-icon" />
                                </a>
                              </td>

                              <td>
                                <div className="modules-permission-listing">
                                  {/* <div className="modules-permission-heading">
                  {mod?.permissionName}
                </div> */}

                                  <div className="listing-edit-permission">
                                    <ul>
                                      {res?.modules
                                        ?.slice(
                                          0,
                                          idR.includes(res?.categoryName)
                                            ? res?.modules?.length
                                            : 2
                                        )
                                        .map((mod, id) => (
                                          <li key={mod?.uid}>
                                            <div className="list-edit-module-wrap">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <span className="heading-lead">
                                                    {" "}
                                                    {mod?.permissionName}{" "}
                                                  </span>
                                                </div>

                                                <div className="col-md-8">
                                                  <div className="lising-radio-permission-wrap">
                                                    <ul>
                                                      <li>
                                                        {" "}
                                                        <div className="onoffswitch2">
                                                          <input
                                                            type="checkbox"
                                                            name={`myonoffswitch${mod?.uid}`}
                                                            className="onoffswitch2-checkbox"
                                                            id={`myonoffswitch${mod?.uid}`}
                                                            checked={checkedModules?.includes(
                                                              mod?.uid
                                                            )}
                                                            disabled={
                                                              roleType ===
                                                              "admin"
                                                            }
                                                            onClick={() => {
                                                              handleUpdateRole(
                                                                roleId,
                                                                mod?.moduleId,
                                                                mod?.isChecked
                                                                  ? "del"
                                                                  : "add",
                                                                mod?.moduleId,
                                                                mod?.uid
                                                              );
                                                            }}
                                                          />
                                                          <label
                                                            className="onoffswitch2-label"
                                                            for={`myonoffswitch${mod?.uid}`}
                                                          >
                                                            <span className="onoffswitch2-inner"></span>
                                                            <span className="onoffswitch2-switch"></span>
                                                          </label>
                                                        </div>{" "}
                                                      </li>
                                                      <li>
                                                        {" "}
                                                        <div className="d-flex form-check-inline">
                                                          <label className="customradio">
                                                            <label className="customradio-role">
                                                              <input
                                                                type="radio"
                                                                name={`radio${mod.uid}`}
                                                                className="role-permission-radio"
                                                                checked={publicId.includes(
                                                                  mod.uid
                                                                )}
                                                                disabled={
                                                                  !checkedModules.includes(
                                                                    mod.uid
                                                                  ) ||
                                                                  roleType ===
                                                                    "admin"
                                                                }
                                                                onClick={() =>
                                                                  handlePrivatePublic(
                                                                    mod?.permissionId,
                                                                    publicId.includes(
                                                                      mod.uid
                                                                    )
                                                                      ? "PRIVATE"
                                                                      : "PUBLIC",
                                                                    mod?.uid,
                                                                    mod?.moduleId
                                                                  )
                                                                }
                                                              />
                                                              <span className="radio-mark" />
                                                            </label>
                                                            <span className="radiotextsty">
                                                              Public
                                                            </span>
                                                          </label>
                                                          <label className="customradio">
                                                            <label className="customradio-role">
                                                              <input
                                                                type="radio"
                                                                name={`radio${mod.uid}`}
                                                                className="role-permission-radio"
                                                                checked={privateId.includes(
                                                                  mod.uid
                                                                )}
                                                                disabled={
                                                                  !checkedModules.includes(
                                                                    mod.uid
                                                                  ) ||
                                                                  roleType ===
                                                                    "admin"
                                                                }
                                                                onClick={() =>
                                                                  handlePrivatePublic(
                                                                    mod?.permissionId,
                                                                    privateId.includes(
                                                                      mod.uid
                                                                    )
                                                                      ? "PUBLIC"
                                                                      : "PRIVATE",
                                                                    mod?.uid,
                                                                    mod?.moduleId
                                                                  )
                                                                }
                                                              />
                                                              <span className="radio-mark" />
                                                            </label>
                                                            <span className="radiotextsty">
                                                              Private
                                                            </span>
                                                          </label>
                                                        </div>{" "}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                      )}
                    </table>
                  </div>
                </div>
              )}
              <button className="btn-role-concave" onClick={handleCloseModal}>
                <img src={CloseBtn} alt="close-icon" />
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          show={modal}
          onHide={handleClose}
          className="main-update-modal"
          data-theme={props.isDark}
          centered
        >
          <Modal.Header
            className="update-modal-header"
            closeButton
          ></Modal.Header>
          <Modal.Body className="update-modal-body">
            <div className="col-md-12 choose-industry-columns ">
              <div>
                <div className="form-section-wrap form-modal-wrap role-modal">
                  <h4>
                    {" "}
                    Add <span>Role</span>{" "}
                  </h4>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <div className="form-group">
                          <label>Role*</label>
                          <Field
                            type="role"
                            name="role"
                            className={`form-control ${
                              (touched.role && errors.role) || rErr
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Add new role"
                            onChange={(e) => {
                              setFieldValue("role", e.target.value); // This will update the form value
                              setRErr(""); // Reset the error
                            }}
                          />
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="invalid-feedback"
                          />
                          {rErr && (
                            <p
                              className="text-end"
                              style={{ fontSize: "14px", color: "#e93131" }}
                            >
                              {rErr}
                            </p>
                          )}
                        </div>

                        <div className="btn-group-signup">
                          <button
                            type="submit"
                            className="d-flex align-items-center justify-content-center"
                          >
                            {sload ? (
                              <Spinner animation="border" />
                            ) : (
                              "Add Role"
                            )}
                          </button>
                        </div>

                        {/* <div className="or-text-signup">
              <span>OR</span>
            </div> */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <RoleNameModal
          modal={modalRole}
          handleClose={handleModalRoleClose}
          role={roleName}
          setRoleName={setRoleName}
          isDark={props.isDark}
          getRolesAfterUpdate={getRolesAfterUpdate}
        />
        <DeleteRoleModal
          roleName={roleName}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDark={props.isDark}
          getRolesAfterUpdate={getRolesAfterUpdate}
        />
        <SeeMoreModal
          seeMore={seeMore}
          setSeeMore={setSeeMore}
          headTextOne="Roles"
          headTextTwo="& Permissions"
          isDark={props.isDark}
          modalContent={verifyModalContent}
        />
      </div>
    </>
  );
};

export default RolesMain;
