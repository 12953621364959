import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
import ClickAwayListener from "react-click-away-listener";
import moment from "moment-timezone";
import { Icon } from "@iconify/react/dist/iconify.js";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useIsMobile } from "../../helper/windowHelper";

const DateTimePicker = ({
  isOpen,
  date,
  setDate,
  time,
  setTime,
  setIsOpen,
  handleDateTimeChange = () => {},
  isCloseIcon = false,
  isDateSelectOnSlotChange = false,
  enableClear = false,
  clearDateHandler = () => {},
}) => {
  const { isMobile } = useIsMobile();
  const [isDark, setIsDark] = useState(
    localStorage.getItem("isDark")
      ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
        ? "default"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
        ? "color1"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
        ? "color2"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
        ? "color3"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
        ? "color4"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
        ? "color5"
        : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
        ? "color6"
        : "default"
      : "default"
  );
  const pickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState("bottom"); // Default: show dropdown below
  const [alignment, setAlignment] = useState("left");
  const timeSlotsRef = useRef(null);
  const timeSlotsMobileRef = useRef(null);

  const CustomHeader = ({ date }) => {
    let newDate = date ? new Date(date) : new Date();

    const month = newDate
      .toLocaleString("default", { month: "long" })
      .slice(0, 3);
    const year = newDate.getFullYear();
    const day = newDate.getDate();

    return (
      <div className="custom-header">
        <span>{`${day} ${month} ${year}`}</span>
      </div>
    );
  };

  const calculatePosition = () => {
    if (pickerRef.current && dropdownRef.current) {
      const pickerRect = pickerRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const dropdownWidth = dropdownRef.current.offsetWidth;
      const { innerHeight, innerWidth } = window;

      let newPosition = "bottom"; // Default position
      let newAlignment = "left"; // Default alignment

      // Check vertical positioning
      if (pickerRect.bottom + dropdownHeight <= innerHeight) {
        newPosition = "bottom";
      } else if (pickerRect.top - dropdownHeight >= 0) {
        newPosition = "top";
      } else {
        newPosition = "center"; // Fallback if no space above or below
      }

      // Check horizontal positioning
      if (pickerRect.left + dropdownWidth <= innerWidth) {
        newAlignment = "left";
      } else if (pickerRect.right - dropdownWidth >= 0) {
        newAlignment = "right";
      } else {
        newAlignment = "center"; // Center the dropdown if no space on left/right
      }

      setPosition(newPosition);
      setAlignment(newAlignment);
    }
  };

  // 🔹 Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) && // If clicked outside dropdown
        pickerRef.current &&
        !pickerRef.current.contains(event.target) && // If clicked outside picker
        !isMobile
      ) {
        setIsOpen(false); // Close dropdown
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    if (isOpen) calculatePosition();
    window.addEventListener("resize", calculatePosition);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [isOpen]);

  const handleDateChange = (newDate) => {
    const combinedDateTime = `${moment(newDate).format(
      "YYYY-MM-DD"
    )}T00:00:00.000Z`;
    console.log("date", combinedDateTime);
    setDate(new Date(combinedDateTime));
    handleDateTimeChange();
  };

  const handleTimeChange = (newTime) => {
    if (date) {
      const combinedDateTime = `${moment(date).format(
        "YYYY-MM-DD"
      )}T00:00:00.000Z`;

      setDate(new Date(combinedDateTime));
    } else {
      if (isDateSelectOnSlotChange) {
        const combinedDateTime = `${moment(new Date()).format(
          "YYYY-MM-DD"
        )}T00:00:00.000Z`;

        setDate(new Date(combinedDateTime));
      }
    }
    setTime(newTime);
    handleDateTimeChange();
  };

  const smoothScrollTo = (ref, targetPosition, direction = "y") => {
    const startPosition =
      direction === "x" ? ref.current.scrollLeft : ref.current.scrollTop;
    const distance = targetPosition - startPosition;
    const duration = 800; // Duration for the scroll
    let startTime = null;

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1); // Ensure progress does not exceed 1

      // Easing function for smooth scrolling
      const ease = (t) => {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
      };

      if (ref && ref.current) {
        if (direction === "x") {
          ref.current.scrollLeft = startPosition + distance * ease(progress);
        } else {
          ref.current.scrollTop = startPosition + distance * ease(progress);
        }

        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        }
      }
    };

    requestAnimationFrame(animation);
  };

  useEffect(() => {
    const scrollToActiveSlot = () => {
      if (timeSlotsRef.current && isOpen) {
        const activeSlot = isMobile
          ? timeSlotsRef.current.querySelector(".time-slot-mobile.active")
          : timeSlotsRef.current.querySelector(".time-slot.active");
        if (activeSlot) {
          const slotRect = activeSlot.getBoundingClientRect();
          const containerRect = timeSlotsRef.current.getBoundingClientRect();

          // Calculate the scroll position with an offset of 100px
          const offset = 100; // Offset in pixels
          const scrollPosition =
            slotRect.top -
            containerRect.top +
            timeSlotsRef.current.scrollTop -
            offset;

          if (isMobile) {
            // Scroll horizontally for mobile devices

            const horizontalScrollPosition =
              slotRect.left -
              containerRect.left +
              timeSlotsRef.current.scrollLeft -
              offset -
              73;
            smoothScrollTo(timeSlotsRef, horizontalScrollPosition, "x"); // Specify horizontal scrolling
          } else {
            // Use the smooth scroll function for vertical scrolling
            smoothScrollTo(timeSlotsRef, scrollPosition);
          }
        }
      }
      if (timeSlotsMobileRef.current && isOpen && isMobile) {
        const activeSlot = isMobile
          ? timeSlotsMobileRef.current.querySelector(".time-slot-mobile.active")
          : timeSlotsMobileRef.current.querySelector(".time-slot.active");
        if (activeSlot) {
          const slotRect = activeSlot.getBoundingClientRect();
          const containerRect =
            timeSlotsMobileRef.current.getBoundingClientRect();

          // Calculate the scroll position with an offset of 100px
          const offset = 100; // Offset in pixels
          const scrollPosition =
            slotRect.top -
            containerRect.top +
            timeSlotsMobileRef.current.scrollTop -
            offset;

          if (isMobile) {
            // Scroll horizontally for mobile devices

            const horizontalScrollPosition =
              slotRect.left -
              containerRect.left +
              timeSlotsMobileRef.current.scrollLeft -
              offset -
              73;
            smoothScrollTo(timeSlotsMobileRef, horizontalScrollPosition, "x"); // Specify horizontal scrolling
          } else {
            // Use the smooth scroll function for vertical scrolling
            smoothScrollTo(timeSlotsMobileRef, scrollPosition);
          }
        }
      }
    };

    if (isOpen) {
      // Delay execution until the BottomSheet has fully rendered
      setTimeout(scrollToActiveSlot, 0); // Use a slight delay to ensure DOM is updated
    }
  }, [time, isOpen, isMobile]); // Scroll to active time slot when time changes

  const renderContent = () => {
    return (
      <div data-theme={isDark} className="picker-content">
        {isMobile && (
          <>
            <h3 className="mt-3 text-center ms-2">Choose Date and Time</h3>
          </>
        )}
        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={date ? new Date(date) : null}
            tileDisabled={({ date }) => date < new Date().setHours(0, 0, 0, 0)}
            className="react-calendar react-calender-mobile"
            // Custom header
            formatMonthYear={() => {
              return (
                <CustomHeader
                  date={
                    date
                      ? new Date(moment(date).toISOString().split("T")[0])
                      : new Date()
                  }
                />
              );
            }}
          />
        </div>

        <div
          style={{
            marginTop: isMobile ? "9px" : "19px",
          }}
          className="time-container"
        >
          {date && !isMobile ? (
            <div className="selected-date mb-3 pe-1 text-center">{time}</div>
          ) : null}
          {date && isMobile ? (
            <div className="selected-date mb-3 pe-1 text-center">{time}</div>
          ) : null}
          <div
            style={{
              marginTop: !isMobile ? (date ? "26px" : "55px") : "10px",
            }}
            className={`${isMobile ? "time-slots-mobile" : "time-slots"}`}
            ref={isMobile ? timeSlotsMobileRef : timeSlotsRef}
          >
            {Array.from({ length: 48 }, (_, i) => {
              const hour = Math.floor(i / 2);
              const minute = i % 2 === 0 ? "00" : "30";
              const slot = `${hour.toString().padStart(2, "0")}:${minute}`;
              return (
                <button
                  key={slot}
                  className={`${isMobile ? "time-slot-mobile" : "time-slot"} ${
                    time === slot ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleTimeChange(slot);
                  }}
                  style={{
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                  }}
                >
                  {slot}
                </button>
              );
            })}
          </div>
        </div>
        {isCloseIcon && (
          <div
            style={{
              right: "4px",
              top: "8px",
            }}
            className="position-absolute"
          >
            <button
              style={{
                border: !isMobile ? "none" : "1px solid rgba(0, 0, 0, 0.07)",
                borderRadius: "4px",
                padding: "1px 3px",
              }}
              className="select-icon me-1 select-icon-date bg-white"
              onClick={() => setIsOpen(false)}
            >
              <Icon icon="maki:cross" fontSize={18} />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isOpen && !isMobile && (
        <>
          {/* <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
            }}
          > */}
          <div className="date-time-picker" ref={pickerRef}>
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                zIndex: 1000,
                ...(position === "bottom" && { top: "100%" }),
                ...(position === "top" && { bottom: "100%" }),
                ...(position === "center" && {
                  top: "0",
                  left: "50%",
                  transform: "translate(-50%, -3%)",
                }),
                ...(alignment === "right" && { left: "auto", right: "0" }),
                ...(alignment === "center" && {
                  left: "50%",
                  transform: "translateX(-50%)",
                }),
                overflow: "hidden",
              }}
              className={`picker-dropdown picker-${position}`}
            >
              {renderContent()}
              {enableClear && (
                <div
                  className="d-flex align-items-center justify-content-center my-2"
                  onClick={async () => {
                    setDate(null);
                    setTime("19:00");
                    clearDateHandler();
                  }}
                >
                  <button className="clear-date-modal-btn">Clear date</button>
                </div>
              )}
            </div>
          </div>
          {/* </ClickAwayListener> */}
        </>
      )}
      {isMobile && (
        <BottomSheet
          onDismiss={() => setIsOpen(false)}
          open={isOpen}
          blocking={false}
        >
          {renderContent()}
          {enableClear && (
            <div
              className="d-flex align-items-center justify-content-center my-2"
              onClick={async () => {
                setDate(null);
                setTime("19:00");
                clearDateHandler();
              }}
            >
              <button className="clear-date-modal-btn">Clear date</button>
            </div>
          )}
        </BottomSheet>
      )}
    </>
  );
};

export default DateTimePicker;
