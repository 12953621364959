import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { toast } from "react-toastify";
import { useDeleteTeamMutation } from "../../../slices/teams/teamsSlice";

const ConfirmDeleteTeamModal = (props) => {
  const [dload, setDLoad] = useState(false);
  const [deleteTeam, { isLoading, isError, error }] = useDeleteTeamMutation();

  // Delete project
  const deleteProject = async () => {
    try {
      setDLoad(true);
      await deleteTeam(props.id).unwrap();
      props.handleCloseDeleteModal();
      toast.success("Team deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      // props.setListToFilter((prevList) =>
      //   prevList.filter((team) => team.id !== props.id)
      // );
    } catch (error) {
      console.error("Failed to delete team:", error);
      toast.error("Failed to delete team", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>
        <p className="del-modal-name">{props.getTeamName(props.id)}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={deleteProject}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteTeamModal;
