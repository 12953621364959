/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  ShimmerBadge,
  ShimmerButton,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";

const ProfileShimmer = () => {
  return (
    <div className="profile-info-wraper">
      <div className="profil-image-name-wrap">
        <div className="d-flex align-items-center gap-4 flex-md-row flex-column prof-main-wrap">
          <div className="profile-shimmer-thumbnail">
            <ShimmerThumbnail className="m-0" rounded />
          </div>
          <div className="w-100 d-flex align-items-center justify-content-md-between justify-content-center flex-wrap profile-main-mod-btn-wrap">
            {/* <div className="profile-dash-text">
                      {" "}
                      <div className="w-100 d-flex align-items-center profile-role-wrapper">
                        <div className="d-flex profile-roles-span-wrap">
                          {user?.userInfo?.roles?.map((res, id) => (
                            <span className="user-profile-role-span" key={id}>
                              {" "}
                              {res?.roleName.charAt(0).toUpperCase() +
                                res?.roleName.slice(1)}
                            </span>
                          ))}
                        </div>
                        <div className="teams-listing-wap team-listing-profile">
                          <UserDepartmentList user={user} colors={colors} />
                        </div>
                      </div>
                      <h4> {user?.userInfo?.userName} </h4>
                      <p> {user?.userInfo?.companyName} </p>
                    </div> */}
            <div className="profile-dash-text">
              {" "}
              <div className="w-100 d-flex align-items-center profile-role-wrapper">
                <div className="d-flex profile-roles-span-wrap">
                  <ShimmerBadge width={100} />
                </div>
                <div className="teams-listing-wap team-listing-profile">
                  <ShimmerCircularImage size={30} />
                </div>
              </div>
              <h4 style={{ width: "250px" }}>
                {" "}
                <ShimmerTitle line={1} />
              </h4>
              <p style={{ width: "250px" }}>
                {" "}
                <ShimmerTitle line={1} />
              </p>
            </div>
          </div>
        </div>
        <div className="profile-nav-wrap">
          <div className="nav-wrap-inner">
            <ul>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-4 w-100 d-flex flex-column nav-item-shimmer-wrap">
        <ShimmerBadge width={200} />

        <div className="w-75 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
        <div className="w-75 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
        <div className="w-75 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
        <div className="w-75 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
      </div>
    </div>
  );
};

export default ProfileShimmer;
