// Import action type constants from actionTypes.js
import { ProjectAPI } from "../services/ClientApi";
import {
  FETCH_COMPLETED_TASKS_REQUEST,
  FETCH_COMPLETED_TASKS_SUCCESS,
  FETCH_COMPLETED_TASKS_FAILURE,
  UPDATE_COMPLETED_TASK,
  DELETE_COMPLETED_TASK,
  CLEAR_COMPLETED_TASKS,
} from "../constants/completedTasksConstants";

// Action creator for initiating the task fetch process
// This will set the loading state to true
export const fetchCompletedTasksRequest = () => ({
  type: FETCH_COMPLETED_TASKS_REQUEST,
});

// Action creator for handling successful task fetch
// This takes the fetched tasks, limit, and page as payload
export const fetchCompletedTasksSuccess = (tasks, limit, page) => ({
  type: FETCH_COMPLETED_TASKS_SUCCESS,
  payload: { tasks, limit, page },
});

// Action creator for handling task fetch failure
// This will pass the error message as payload
export const fetchCompletedTasksFailure = (error) => ({
  type: FETCH_COMPLETED_TASKS_FAILURE,
  payload: error,
});

// Thunk action to fetch tasks based on the provided limit and page
export const fetchCompletedTasks = (limit, page) => {
  return async (dispatch, getState) => {
    // Get the current state of tasks from Redux store
    const state = getState();
    // Check if tasks for the given limit and page are already cached in state
    const cachedTasks = state?.taskCachedCompletedList?.taskCompletedList[`${limit}_${page}`];

    // If tasks are cached, return early without making an API call
    if (cachedTasks) {
      return;
    }

    // Dispatch action to indicate task fetch process has started
    dispatch(fetchCompletedTasksRequest());

    try {
      // Fetch tasks from the API with the specified limit and page
      const { data } = await ProjectAPI.post(
        `task/completed-listing?page=${page}&limit=${limit}`
      );

      // Dispatch action for successful task fetch, passing the fetched data
      dispatch(fetchCompletedTasksSuccess(data, limit, page));
    } catch (error) {
      // If there is an error during fetch, dispatch failure action
      // dispatch(fetchCompletedTasksFailure(error.message));
      dispatch({
        type: FETCH_COMPLETED_TASKS_FAILURE,
        payload: error.message,
        meta: {
          retryAction: fetchCompletedTasks(limit, page), // Retry action
        },
      })
    }
  };
};

// Action creator for updating a task
// This will update the task with the specified taskId with the new task data
export const updateCompletedTask = (taskId, updatedTask) => ({
  type: UPDATE_COMPLETED_TASK,
  payload: { taskId, updatedTask },
});

// Action creator for deleting a task
// This will delete the task with the specified taskId, limit, and page
export const deleteCompletedTask = (taskId, limit, page) => ({
  type: DELETE_COMPLETED_TASK,
  payload: { taskId, limit, page },
});

// This will remove all completed tasks
export const clearCompletedTask = () => ({
  type: CLEAR_COMPLETED_TASKS
});
