import { PMBaseApi } from "../../services/PMBaseApi";

export const timelineSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // endpoint for getting all timeline state
    postTimelineStats: builder.mutation({
      query: () => ({
        url: `/timeline/stats`,
        method: "POST",
      }),
      keepUnusedDataFor: 12 * 60 * 60 * 1000,

      //   providesTags: ["projectListing"],
    }),

   

    // endpoint for timeline ongoing tasks
    getTimelineOngoingTasks: builder.query({
      query: () => ({
        url: `/timeline/ongoing-tasks`,
        method: "GET",
      }),
      keepUnusedDataFor: 12 * 60 * 60 * 1000,
      providesTags: ["timelineOngoingTasks"],
    }),
  }),
});

export const {
  usePostTimelineStatsMutation,
  useGetTimelineOngoingTasksQuery,
} = timelineSlice;
