import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteProjectAction,
  projectFormListingAction,
} from "../../../actions/projectActions";
import { deleteActiveProjectAction } from "../../../actions/timelineActions";
import { useDeleteProjectsMutation } from "../../../slices/projects/projectSlice";

const ConfirmSureDeleteProjectModal = (props) => {
  const [dload, setDLoad] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteProjects] = useDeleteProjectsMutation();

  // Delete project
  const deleteProject = async () => {
    try {
      setDLoad(true);
      const response = await deleteProjects({
        projectsIds: [props.projectId],
      });
      if (response?.data?.status === "success") {
        props.handleCloseDeleteModal();
       
        // dispatch(deleteProjectAction([props.projectId]));
        // dispatch(deleteActiveProjectAction([props.projectId]));
        toast.success("Project deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        dispatch(projectFormListingAction());
        if (props.projectDetailRoute) {
          navigate(
            `/${localStorage.getItem("company")}/task-management-porojects`
          );
        }
        props.setListToFilter((prevList) =>
          prevList.filter((project) => project.projectId !== props.projectId)
        );
        props.getProjectList();
      }
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>
          This action will remove all associated tasks, do you want to proceed ?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteProject}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmSureDeleteProjectModal;
