import React from "react";
import ProjectDetailMain from "./components/ProjectDetailMain";
import "./styles/projectDetail.scss"

const ProjectDetail = (props) => {
  return (
    <ProjectDetailMain
      isDark={props.isDark}
      setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
    />
  );
};

export default ProjectDetail;
