/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import ClickAwayListener from "react-click-away-listener";
import SearchIcon from "../../../assets/search-icon.png";

function TaskSelectedUserSection({
  props,
  projectUserList,
  taskListToFilters,
  projectTypeList,
  isDark,
  handleBulkChange,
  selectedTasks,
  data,
  setData,
}) {
  const [showFilterDropDown, setshowFilterDropDown] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);

  const userListRef = useRef(null);
  const handleKeyDown = (event) => {
    if (!showFilterDropDown) return;

    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < projectUserList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : projectUserList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item
      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown
      setshowFilterDropDown(false);
      event.preventDefault();
    }
  };

  // Scroll the focused item into view
  useEffect(() => {
    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  // Helper function to get userName based on userId
  const getUserNameById = (userId) => {
    const user = projectUserList?.find((user) => user.userId === userId);
    return user ? user : ""; // Return userName if found, otherwise return an empty string
  };

  return (
    <section
      onKeyDown={handleKeyDown}
      className={`position-relative selected-task-user-filter me-0 ${
        data ? "filter-top-user-section" : ""
      }`}
    >
      {data ? (
        <div
          className="d-flex align-items-center task-selector-filter tasks-main-info-list-font-weight cursor-pointer selected-items-assigned-section"
          onClick={() => setshowFilterDropDown(!showFilterDropDown)}
        >
          <img
            src={getUserNameById(data)?.profileImage || avatar}
            alt="avatar-icon"
            width={24}
            height={24}
            className="rounded-circle"
          />{" "}
          {getUserNameById(data)?.userName}{" "}
          <div
            class="select-icon"
            style={{
              transform: showFilterDropDown ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
            </svg>
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center task-selector-filter tasks-main-info-list-font-weight cursor-pointer selected-items-assigned-section"
          onClick={() => setshowFilterDropDown(!showFilterDropDown)}
        >
          <img src={avatar} alt="avatar-icon" width={24} height={24} /> Assigned
          to{" "}
          <div
            class="select-icon"
            style={{
              transform: showFilterDropDown ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
            </svg>
          </div>
        </div>
      )}
      {showFilterDropDown && (
        <ClickAwayListener onClickAway={() => setshowFilterDropDown(false)}>
          <div className="position-absolute rounded-3 shadow-lg task-user-filter-dropdown">
            <TaskUsersListForFilter
              isDark={isDark}
              projectUserList={projectUserList}
              setData={setData}
              data={data}
              projectTypeList={projectTypeList}
              handleBulkChange={handleBulkChange}
              selectedTasks={selectedTasks}
              userListRef={userListRef}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              isClicked={isClicked}
              setIsClicked={setIsClicked}
            />
          </div>
        </ClickAwayListener>
      )}
    </section>
  );
}

const TaskUsersListForFilter = ({
  projectUserList,
  setData,
  data,
  projectTypeList,
  handleBulkChange,
  selectedTasks,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  isClicked,
  setIsClicked,
}) => {
  const [search, setSearch] = useState("");
  const [userSearchList, setUserSearchList] = useState(projectUserList);

  const handleSearch = (event) => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setUserSearchList(projectUserList);
    setSearch("");
  };

  return (
    <div className="d-flex task-user-filter-dropdown-menu custom-vertical-scrollbar">
      <div className="w-100 d-flex flex-column custom-user-selection-main-container">
        <div className="listing-team-modal-search-input-wrap position-relative">
          <input
            type="text"
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            autoFocus={true}
            autoComplete="off"
            className="custom-user-search"
          />
          {search ? (
            <span className="custom-user-search-icon" onClick={clearSearch}>
              &#x2715;
            </span>
          ) : (
            <img
              src={SearchIcon}
              className="custom-user-search-icon"
              alt="search-icon"
              width={20}
              height={20}
            />
          )}
        </div>
        {userSearchList?.length === 0 && <p className="p-0">No member found</p>}
        <div
          ref={userListRef}
          className="user-list-main-container custom-vertical-scrollbar"
        >
          {userSearchList?.map((res, id) => (
            <TaskUsersListItemForFilter
              setData={setData}
              data={data}
              res={res}
              index={id}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              handleBulkChange={handleBulkChange}
              selectedTasks={selectedTasks}
              isClicked={isClicked}
              setIsClicked={setIsClicked}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  setData,
  data,
  res,
  handleBulkChange,
  selectedTasks,
  focusedIndex,
  setFocusedIndex,
  index,
  isClicked,
  setIsClicked,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      setIsChecked(!isChecked);
      if (data === res?.userId) {
        // If the userId exists in the teamMembers array, remove it
        setData(null);
      } else {
        setData(res?.userId);
        const data = {
          taskIds: selectedTasks,
          actionType: "assignedTo",
          actionValue: res?.userId,
        };
        handleBulkChange(data);
      }
      setIsClicked(false);
    }
  }, [isClicked]);
  return (
    <div
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className="w-100 border-bottom-1 task-user-filter-dropdown-menu-item"
    >
      <div
        className="checkbox create-task-checkbox"
        onClick={() => {
          setIsChecked(!isChecked);
          if (data === res?.userId) {
            // If the userId exists in the teamMembers array, remove it
            setData(null);
          } else {
            setData(res?.userId);
            const data = {
              taskIds: selectedTasks,
              actionType: "assignedTo",
              actionValue: res?.userId,
            };
            handleBulkChange(data);
          }
        }}
      >
        <input type="checkbox" checked={data === res?.userId} />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          {/* <span className="user-live-status"></span> */}
          <span className="ps-1">{res?.userName}</span>
        </label>
      </div>
    </div>
  );
};

export default TaskSelectedUserSection;
