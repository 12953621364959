import React from "react";
import CloseBtn from "../../../assets/icon-close.svg";
import DueTaskList from "./DueTaskList";
import { Offcanvas } from "react-bootstrap";

function DueTaskOffCanvas({ show, isDark, handleCloseModal,props,dailyReportData,allTasks,showTasksTypeName }) {
  return (
    <Offcanvas
      className={`${"off-concave-proj-modal"}`}
      show={show}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body className="pt-0">
        <DueTaskList
          props={props}
          dailyReportData={dailyReportData}
          taskData={allTasks}
          showTasksTypeName={showTasksTypeName}
        />
        {/* close icon */}
        <button
          className="btn-role-concave"
          onClick={() => {
            handleCloseModal();
          }}
        >
          <img src={CloseBtn} alt="Close" />
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default DueTaskOffCanvas;
