import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import InfoIcon from "../../../assets/info.svg";
import Select, { components } from "react-select";
import { customStyles } from "../../Projects/components/ProjectNewModal";
import { IoIosArrowDown } from "react-icons/io";
import { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateCachedTask } from "../../../actions/taskCachedActions";
import {
  removeTaskAndPhases,
  updateCachedProjectDetailTask,
} from "../../../actions/projectDetailTasksActions";
import { updateOngoingTaskAction } from "../../../actions/timelineActions";
import { usePhaseChangeMutation } from "../../../slices/tasks/projectDetailSectionSlice";

function MoveTaskToAnotherPhaseModal(props) {
  // create the state for the selectedTaskPhase
  const dispatch = useDispatch();
  const [phaseChange] = usePhaseChangeMutation();
  const [selectedTaskPhase, setSelectedTaskPhase] = useState(null);
  const [initLoad, setInitLoad] = useState(false);
  const [taskDetails, setTaskDetails] = useState(null);
  const [load, setLoad] = useState(null);

  const handleSubmit = async () => {
    setLoad(true);

    if (!selectedTaskPhase) {
      setLoad(false);
      if (!selectedTaskPhase) {
        toast.error("Please add at least one team member", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return; // Prevent further execution
      } else {
        return;
      }
    } else {
      try {
        const data = {
          taskId: props.taskId,
          fieldName: "projectPhaseId",
          fieldValues: selectedTaskPhase.value,
        };
        const response = await ProjectAPI.post("task/update", data);
        if (response) {
          await phaseChange();
          toast.success("Task updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false,
          });
          const taskData = response?.data?.taskDetail;
          await dispatch(updateCachedTask(taskData?.id, taskData));
          await dispatch(updateCachedProjectDetailTask(taskData?.id, taskData));
          await dispatch(updateOngoingTaskAction(taskData));
          await dispatch(
            removeTaskAndPhases(
              taskData?.project?.id,
              props?.phaseId,
              taskData?.id
            )
          );
        }
      } catch (error) {
      } finally {
        props.handleCloseModal();
        setLoad(false);
      }
    }
  };

  const getTaskDetails = async (taskId) => {
    try {
      setSelectedTaskPhase(null);
      setInitLoad(true);
      setTaskDetails([]);
      const response = await ProjectAPI.post("task/detail", { taskId: taskId });
      if (response?.status === 201) {
        setTaskDetails(response.data.task);
        const fetchedTaskDetails = response.data.task;
        fetchedTaskDetails?.projectPhase?.length !== 0 &&
          setSelectedTaskPhase({
            id: fetchedTaskDetails.projectPhase?.id,
            label: fetchedTaskDetails.projectPhase?.phaseName,
            value: fetchedTaskDetails.projectPhase?.phaseName,
          });
      }
    } catch (error) {
    } finally {
      setInitLoad(false);
    }
  };
  useEffect(() => {
    if (props.taskId && props.showModal) {
      getTaskDetails(props.taskId);
    }
  }, [props.taskId, props.showModal]);

  return (
    <Modal
      show={props.showModal}
      onHide={props.handleCloseModal}
      className="main-update-modal"
      centered
      data-theme={props.isDark}
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div>
          <h4>
            {" "}
            Change <span className="invite-modal-span">Phase</span>
          </h4>
          {initLoad ? (
            <div className="w-100 d-flex justify-content-center global-spinner">
              <Spinner animation="border" />
            </div>
          ) : (
            <div>
              {/* project phases */}
              <div className="col-12 mt-4">
                <div className="form-group multi-select-wrapper">
                  <label>
                    {" "}
                    Project Phases{" "}
                    <img
                      src={InfoIcon}
                      alt="info-icon"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Selected Project Phases"
                    />{" "}
                  </label>
                  <div className="team-offconcave-select-wrapper">
                    <Select
                      isSelectAll={false}
                      placeholder="Select Project Phase"
                      value={selectedTaskPhase}
                      className={`create-task-priority-select`}
                      styles={customStyles}
                      isSearchable={false}
                      menuPlacement="bottom"
                      onChange={(e) => {
                        setSelectedTaskPhase(e);
                      }}
                      getOptionValue={(option) => option.value}
                      options={
                        props.projectAllPhasesTasks
                          ? props.projectAllPhasesTasks?.map((res, id) => ({
                              id: res.id,
                              label: res.phaseName,
                              value: res.id,
                            }))
                          : []
                      }
                      components={{
                        Control: ({ children, ...props }) => {
                          return (
                            <components.Control {...props}>
                              {children}
                              <div className="select-icon me-1">
                                <IoIosArrowDown />
                              </div>
                            </components.Control>
                          );
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* project phases submit button */}
              <div className="choose-continue-btn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="next-button-wraper invite-btn-margin">
                      {" "}
                      <button
                        onClick={handleSubmit}
                        disabled={load}
                        className="team-invite-button"
                      >
                        {" "}
                        {load ? <Spinner animation="border" /> : "Update"}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MoveTaskToAnotherPhaseModal;
