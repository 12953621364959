/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CloseBtn from "../../assets/icon-close.svg";
import LiveChatWrapper from "../LiveChat/components/LiveChatWrapper";
import socket from "../../services/socket";
import {
  useClearMessagesMutation,
  useStartChatMutation,
} from "../../slices/chat/chatSlice";
import { useSelector } from "react-redux";
import "./style/chat.css";
import notificationSound from "../../assets/message-notification-new.mp3";
import {
  calculateWeeksAgo,
  getDaysSinceOldestMessage,
  groupMessagesByDate,
} from "../../helper/chatHelper";

function ChatMain({
  isDark,
  show,
  handleCloseModal,
  roomData,
  setRoomData,
  initialLoad,
  setInitialLoad,
  setShowChat,
}) {
  // const [room, setRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [days, setDays] = useState(0);
  const [maxMessages, setMaxMessages] = useState(0);
  const [roomId, setRoomId] = useState(null);
  const { userProfile } = useSelector((state) => state?.profile);
  const [roomChangeLoad, setRoomChangeLoad] = useState(false);
  const [startChat] = useStartChatMutation();
  const [roomLastSeen, setRoomLastSeen] = useState(null);
  const [unreadCount, setUnreadCount] = useState(false);
  const [prevDays, setPrevDays] = useState(0); // Ref to store the previous days value
  const [online, setOnline] = useState(null);
  const [away, setAway] = useState(null);
  const [dayState, setDayState] = useState(0);
  const [initialEarliestMessageDayCount, setInitialEarliestMessageDayCount] =
    useState(null);

  const [messages, setMessages] = useState([]);

  const handleClose = async () => {
    // Reset local states
    // setRoom(null);
    setRooms([]);
    setMessages([]);
    setRoomData(null);
    localStorage.removeItem("rid");
    // You can also reset any other states you may have here
    handleCloseModal(); // Call the parent function if you need to hide the modal
  };

  const handleByWeekLoad = async () => {
    setDays((prev) => prev + 7);
    // await getChatMessages(roomData?.room_id);
  };

  useEffect(() => {
    if (prevDays !== days) {
      // setPrevDays(days);
      getChatMessages(roomData?.room_id);
    }
  }, [days]);

  useEffect(() => {
    socket.emit("fetchModalAllUsers", {
      userId: userProfile.userId,
      companyId: userProfile.companyId,
    });
  }, [show]);

  const handleOnlineStatus = async (users) => {
    const rid = localStorage.getItem("rid") || roomData?.room_id;

    // Find the room whose room_id matches rid
    const matchedRoom = users?.find((user) => user.room_id === rid);

    if (matchedRoom) {
      // Check the user_chat_status of the matched room
      if (matchedRoom.user_chat_status === "online") {
        setOnline(true);
        setAway(false);
      } else if (matchedRoom.user_chat_status === "away") {
        setOnline(false);
        setAway(true);
      } else {
        setOnline(false);
        setAway(false);
      }
    } else {
      // If no matched room, handle fallback logic
      setOnline(false);
      setAway(false);
    }
  };

  useEffect(() => {
    socket.on("modalAllUsers", (data) => {
      if (data?.users) {
        // Sort users based on 'unseenMessageCount', if available
        const sortedUsers = [...data.users].sort((a, b) => {
          if (a?.unseenMessageCount > 0 && b?.unseenMessageCount === 0) {
            return -1; // 'a' comes before 'b'
          }
          if (a?.unseenMessageCount === 0 && b?.unseenMessageCount > 0) {
            return 1; // 'b' comes before 'a'
          }
          return 0; // No change in order
        });

        setRooms(sortedUsers);

        // Determine the selected room based on roomData's room_id or user_id
        const selectedRoom = sortedUsers?.find((chat) =>
          roomData?.room_id
            ? chat?.room_id === roomData?.room_id
            : chat?.user_id === roomData?.user_id
        );

        if (!roomData) {
          // Set the selected room or default to the first room if no match is found
          setRoomData(selectedRoom || sortedUsers[0]);
        }

        handleOnlineStatus(sortedUsers);
      }
    });

    return () => {
      socket.off("modalAllUsers");
    };
  }, []);

  const handleStartChat = async () => {
    if (roomData?.user_id && !roomData?.room_id) {
      const response = await startChat({
        chatId: roomData?.user_id,
        body: {
          content: "",
          message_type: "",
        },
      }).unwrap();
      // socket.emit("joinRoom", response?.data?.room_id);
      localStorage?.setItem("rid", response?.data?.room_id);

      setRoomData(response?.data);
    }
  };

  const handleChatStart = async () => {
    if (roomData?.room_id) {
      socket.emit("joinRoom", roomData?.room_id);
    } else {
      handleStartChat();
    }

    setDays(0);
    setPrevDays(0);
    setRoomLastSeen(roomData?.user_lastSeen || null);
    await getChatMessages(roomData?.room_id, dayState);
  };

  useEffect(() => {
    handleChatStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomData]);

  // Get chat messages
  const getChatMessages = async (id, day = null) => {
    const localId = localStorage.getItem("rid");

    const calculatedDays =
      day !== null && day !== undefined
        ? day
        : initialEarliestMessageDayCount + days;

    socket.emit("fetchMessagesByRoom", {
      roomId: localId && localId !== "undefined" ? localId : id,
      days: calculatedDays,
    });
  };
  // // Handeling unread count
  const handleUnreadCount = async (data) => {
    const localId = localStorage.getItem("rid");

    socket.emit("updateLastMessageSeen", {
      roomId: localId ? localId : roomData?.room_id,
      lastMessageSeenId: data[data.length - 1]?.messageId,
      userId: userProfile?.userId,
    });
    localStorage.removeItem("uc");
  };

  // GetMessages
  const getRoomMessages = async () => {
    socket.on("messagesByRoom", (data) => {
      if (data?.messages?.messages) {
        setMessages(groupMessagesByDate(data?.messages?.messages));
        if (dayState === 0) {
          setInitialEarliestMessageDayCount(
            getDaysSinceOldestMessage(
              groupMessagesByDate(data?.messages?.messages)
            )
          );
        }
      } else {
        setMessages({});
      }
      setMaxMessages(data?.messages?.meta?.total);
      let UC = localStorage.getItem("uc") || roomData?.unseenMessageCount > 0;

      if (UC) {
        handleUnreadCount(data?.messages?.messages || []);
      }
      setInitialLoad(false);
      setRoomChangeLoad(false);
    });

    return () => {
      socket.off("messagesByRoom");
    };
  };

  useEffect(() => {
    getRoomMessages();
  }, []);

  // Define a function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  useEffect(() => {
    socket.on("receiveMessage", (data) => {
      // Clear messages
      getChatMessages(roomData?.room_id);
      // Play notification sound if the message is from another user
      if (data && userProfile?.userId === data?.savedMessage?.receiver?.id) {
        playNotificationSound();
      }

      // // Update the state with the modified rooms array
      // setRooms(updatedRooms);
      setRoomLastSeen(data?.savedMessage?.sentAt);
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, []);

  useEffect(() => {
    socket.on("forwardSuccess", (data) => {
      getChatMessages(roomData?.room_id);
    });
    return () => {
      socket.off("forwardSuccess");
    };
  }, []);

  useEffect(() => {
    // Listen for the availability status change event
    socket.on("lastMessageSeenChange", (data) => {
      socket.emit("fetchAllUsers", {
        userId: userProfile.userId,
        companyId: userProfile.companyId,
      });
      socket.emit("fetchModalAllUsers", {
        userId: userProfile.userId,
        companyId: userProfile.companyId,
      });
    }); // Cleanup on component unmount
    return () => {
      socket.off("lastMessageSeenChange");
    };
  }, []);

  return (
    <Offcanvas
      className={`off-concave-chat`}
      show={show}
      // onHide={handleCloseModal}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body>
        <LiveChatWrapper
          chats={rooms}
          room={roomData}
          setRoom={setRoomData}
          user={userProfile}
          roomChats={messages}
          socket={socket}
          setMessages={setMessages}
          handleByWeekLoad={handleByWeekLoad}
          prevDays={prevDays}
          days={days}
          setDays={setDays}
          setPrevDays={setPrevDays}
          isDark={isDark}
          maxMessages={maxMessages}
          lastSeen={roomLastSeen}
          initialLoad={initialLoad}
          roomChangeLoad={roomChangeLoad}
          setRoomChangeLoad={setRoomChangeLoad}
          setRoomId={setRoomId}
          online={online}
          setOnline={setOnline}
          away={away}
          setAway={setAway}
          setDayState={setDayState}
          setUnreadCount={setUnreadCount}
        />

        {/** close icon */}
        <button
          className="btn-role-concave"
          onClick={async () => {
            handleClose();
            handleUnreadCount(messages);
            setInitialLoad(true);
            // socket.emit("fetchAllUsers", {
            //   userId: userProfile.userId,
            //   companyId: userProfile.companyId,
            // });
            const url = new URL(window.location.href);
            if (url.searchParams.has("chats")) {
              url.searchParams.delete("chats");
              window.history.replaceState(null, "", url.toString());
            }
          }}
        >
          <img src={CloseBtn} alt="Close" />
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default memo(ChatMain);
