import React from "react";
import Inventory from "./components/Inventory";

const InventoryMain = (props) => {
  return (
    <Inventory
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default InventoryMain;
