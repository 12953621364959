// Constants for project listing
export const PROJECT_LISTING_REQUEST = "PROJECT_LISTING_REQUEST";
export const PROJECT_LISTING_NOLOAD_REQUEST = "PROJECT_LISTING_NOLOAD_REQUEST";
export const PROJECT_LISTING_SUCCESS = "PROJECT_LISTING_SUCCESS";
export const PROJECT_LISTING_FAIL = "PROJECT_LISTING_FAIL";

// Constants for project detail
export const PROJECT_DETAIL_REQUEST = "PROJECT_LISTING_REQUEST";
export const PROJECT_DETAIL_SUCCESS = "PROJECT_LISTING_SUCCESS";
export const PROJECT_DETAIL_FAIL = "PROJECT_LISTING_FAIL";

// Constants for creating project
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

// Constants for deleting project
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

// Constants for editing project
export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_FAIL = "EDIT_PROJECT_FAIL";

// Constants for sharing project
export const SHARE_PROJECT_REQUEST = "SHARE_PROJECT_REQUEST";
export const SHARE_PROJECT_SUCCESS = "SHARE_PROJECT_SUCCESS";
export const SHARE_PROJECT_FAIL = "SHARE_PROJECT_FAIL";

// Constants for users during project creation
export const CREATE_PROJECT_USERS_REQUEST = "CREATE_PROJECT_USERS_REQUEST";
export const CREATE_PROJECT_USERS_SUCCESS = "CREATE_PROJECT_USERS_SUCCESS";
export const CREATE_PROJECT_USERS_FAIL = "CREATE_PROJECT_USERS_FAIL";

// Constants for types during project creation
export const CREATE_PROJECT_TYPES_REQUEST = "CREATE_PROJECT_TYPES_REQUEST";
export const CREATE_PROJECT_TYPES_SUCCESS = "CREATE_PROJECT_TYPES_SUCCESS";
export const CREATE_PROJECT_TYPES_FAIL = "CREATE_PROJECT_TYPES_FAIL";

// Constants for all status types in project listing
export const PROJECT_LISTING_STATUS_REQUEST = "PROJECT_LISTING_STATUS_REQUEST";
export const PROJECT_LISTING_STATUS_SUCCESS = "PROJECT_LISTING_STATUS_SUCCESS";
export const PROJECT_LISTING_STATUS_FAIL = "PROJECT_LISTING_STATUS_FAIL";

// Constants for all status types in project listing
export const PROJECT_LISTING_ALL_STATUS_REQUEST =
  "PROJECT_LISTING_ALL_STATUS_REQUEST";
export const PROJECT_LISTING_ALL_STATUS_SUCCESS =
  "PROJECT_LISTING_ALL_STATUS_SUCCESS";
export const PROJECT_LISTING_ALL_STATUS_FAIL =
  "PROJECT_LISTING_ALL_STATUS_FAIL";

// Project form listing constants
export const PROJECT_FORM_LISTING_REQUEST = "PROJECT_FORM_LISTING_REQUEST";
export const PROJECT_FORM_LISTING_SUCCESS = "PROJECT_FORM_LISTING_SUCCESS";
export const PROJECT_FORM_LISTING_FAIL = "PROJECT_FORM_LISTING_FAIL";

// Update project
export const PROJECT_UPDATE_REQUEST = "PROJECT_UPDATE_REQUEST";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAIL = "PROJECT_UPDATE_FAIL";

// Add item to array
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";

// Clear project errors
export const CLEAR_PROJECT_ERROR = "CLEAR_PROJECT_ERROR";
