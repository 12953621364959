import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import userProfileBlank from "../../../assets/blank_profile.svg";
import Cropper from "cropperjs";
import {
  useGetIndustriesQuery,
  usePostUpdateCompanyProfileMutation,
} from "../../../slices/user/userSlice";
import { toast } from "react-toastify";

function useEditCompanyInfoModal({ handleCloseModal, companyProfile }) {
  const { data: industryList } = useGetIndustriesQuery();
  const [showCropper, setShowCropper] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = useRef();

  const [
    postUpdateCompanyProfile,
    { isLoading: isUpdateCompanyProfileLoading },
  ] = usePostUpdateCompanyProfileMutation();

  const formik = useFormik({
    initialValues: {
      logo: companyProfile?.companyLogo || userProfileBlank,
      companyName: companyProfile?.companyName || "",
      phoneNumber: companyProfile?.companyPhone || "",
      email: companyProfile?.companyEmail || "",
      industry: companyProfile?.companyIndustry?.id || "",
      about: companyProfile?.companyDescription || "",
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //   companyName: Yup.string().required("Company Name is required"),
    //   phoneNumber: Yup.string()
    //     .matches(/^\+?\d+$/, "Phone number is invalid") // Ensure the phone number has only digits and an optional "+" at the start
    //     .max(15, "Phone number must be at most 15 characters") // Enforce a maximum length of 15 characters
    //     .required("Phone Number is required"), // Make the phone number field required
    //   email: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email is required"),
    //   industry: Yup.string().required("Industry is required"),
    //   about: Yup.string().required("About company is required"),
    // }),
    onSubmit: async (values) => {
      // Handle form submission
      try {
        // Create FormData object
        const formData = new FormData();
        formData.append("companyName", values.companyName);
        formData.append("companyPhone", values.phoneNumber);
        formData.append("companyEmail", values.email);
        formData.append("companyDescription", values.about);
        formData.append("companyIndustry", values.industry);

        // Append logo only if it exists
        if (values.logo) {
          formData.append("logo", imageFile); // Use imageFile if it's the cropped image
        }

        const response = await postUpdateCompanyProfile(formData).unwrap();

        if (response?.status === "success") {
          toast.success(response?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 300,
            hideProgressBar: true,
            closeButton: false,
          });
        } else {
          toast.error(response?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 300,
          });
        }
      } catch (e) {
        toast.error(e.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
        });
      } finally {
        handleCloseModal();
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setShowCropper(true);

      // Reset the file input value
      e.target.value = null; // Allows selecting the same file again
    }
  };

  const handleCrop = async () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();

      // Create a new canvas to add a white background
      const newCanvas = document.createElement("canvas");
      const context = newCanvas.getContext("2d");

      // Set the dimensions of the new canvas to match the cropped canvas
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;

      // Draw a white rectangle as the background
      context.fillStyle = "white";
      context.fillRect(0, 0, newCanvas.width, newCanvas.height);

      // Draw the cropped image over the white background
      context.drawImage(canvas, 0, 0);

      // Convert the new canvas to a blob
      newCanvas.toBlob((blob) => {
        const file = new File([blob], `${selectedImage?.name}`, {
          type: blob.type,
        });
        setImageFile(file);
        const croppedImageUrl = URL.createObjectURL(blob);
        formik.setFieldValue("logo", croppedImageUrl);
        setSelectedImage(croppedImageUrl);

        setShowCropper(false);
      }, "image/jpeg");
    }
  };

  useEffect(() => {
    if (showCropper && selectedImage && imageRef.current) {
      const image = imageRef.current;
      const imageUrl = URL.createObjectURL(selectedImage);
      image.src = imageUrl;

      setCropper(
        new Cropper(image, {
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCropper, selectedImage]);

  return {
    formik,
    showCropper,
    setShowCropper,
    cropper,
    setCropper,
    selectedImage,
    setSelectedImage,
    imageFile,
    setImageFile,
    imageRef,
    handleFileChange,
    handleCrop,
    isUpdateCompanyProfileLoading,
    industryList,
  };
}

export default useEditCompanyInfoModal;
