/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from "react";
import ProfileNew from "../../../assets/profile-new-image.jpg";
import "../styles/profile.css";
import "react-responsive-tabs/styles.css";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import Password from "./Password";
import Language from "./Language";
import SessionHistory from "./SessionHistory";
import Notifications from "./Notifications";
import PersonalInfo from "./PersonalInfo";
import { Tooltip as ReactTooltip } from "react-tooltip";
import userProfileBlank from "../../../assets/blank_profile.svg";
import { v4 as uuidv4 } from "uuid";
import { userProfilePage } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap";
import EditWhite from "../../../assets/edit-white.svg";
import { Helmet } from "react-helmet";
import Timezone from "./Timezone";
import ProfileShimmer from "../../UserProfile/components/ProfileShimmer";
import Button from "../../../components/Button";
import ProfileEditModal from "../../UserProfile/components/ProfileEditModal";
import { useNavigate } from "react-router-dom";
import CompanyProfileMain from "../../CompanyProfile/CompanyProfileMain";
import UserSettings from "../../UserProfile/components/UserSettings";

const ProfileMain = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navWrapRef = useRef(null);
  const [openModal, setOpenMoadl] = useState(false);
  const { userProfile } = useSelector((state) => state.profile);
  const [isOverflowing, setOverflowing] = useState(false);
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState(0);
  const [load, setLoad] = useState(true);
  const [uniqueHeader, setUniqueHeader] = useState([]);
  const [checkedModules, setCheckedModules] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [state, setState] = useState(
    sessionStorage.getItem("tab")
      ? parseInt(sessionStorage.getItem("tab")) === 2 &&
        userProfile?.userLoginWith === "password"
        ? parseInt(sessionStorage.getItem("tab"))
        : 0
      : 0
  );
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  // ... your existing state variables
  const [showModal, setShowModal] = useState(false);
  const [cropper, setCropper] = useState(null);
  const imageRef = useRef(null);
  const { applicationName } = useSelector((state) => state.text);

  // ... your existing handleFileChange function

  const handleModalClose = () => {
    setShowModal(false);
    setCropper(null); // Clean up Cropper instance
  };

  // ... your existing handleImageUpload function

  useEffect(() => {
    // Initialize Cropper when the modal opens and an image is selected
    if (showModal && selectedImage && imageRef.current) {
      const image = imageRef.current;
      image.src = URL.createObjectURL(selectedImage);
      setCropper(
        new Cropper(image, {
          // Optional cropping options
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    // Cleanup Cropper when the modal closes or component unmounts
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [showModal, selectedImage, imageRef, setCropper]);

  const handleChange = (value) => {
    getUserMain();
    sessionStorage.setItem("tab", value);
    setState(value);
  };

  useEffect(() => {
    const checkOverflow = () => {
      const navWrap = navWrapRef.current;
      if (navWrap && navWrap.scrollWidth > navWrap.clientWidth) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    };

    window.addEventListener("resize", checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const getUser = async () => {
    try {
      const user = await API.get("users/basic-info");
      const perm = await API.post("users/permissions", {
        userId: userProfile?.userId,
        roleId: user?.data?.data?.roles[0]?.roleId,
      });
      if (user) {
        setUserId(user?.data?.data?.userId);
        const usermain = await API.post("users/user-profile", {
          userId: userProfile?.userId,
        });
        setUser(usermain?.data?.data);
      }
      if (perm) {
        const uniqueCategoryNames = Array.from(
          new Set(
            perm?.data?.data
              .flatMap((role) =>
                role.rolesCategories.filter(
                  (category) => category.modules.length > 0
                )
              )
              .map((category) => category.categoryName)
          )
        );
        setUniqueHeader(uniqueCategoryNames);
        const modifiedData = perm?.data?.data.map((role) => ({
          ...role,
          rolesCategories: role?.rolesCategories.map((category) => ({
            ...category,
            modules: category?.modules.map((module) => {
              const uid = uuidv4();
              if (module?.isChecked) {
                // If isChecked is true, store the uid in the state
                setCheckedModules((prevCheckedModules) => [
                  ...prevCheckedModules,
                  uid,
                ]);
              }
              return {
                ...module,
                uid: uid,
              };
            }),
          })),
        }));
        setPermissions(modifiedData);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const getMainProfileData = async (load = true) => {
    load && setLoad(true);
    await dispatch(userProfilePage());
  };

  useEffect(() => {
    if (!userProfile) {
      getMainProfileData(true);
    }
  }, []);

  useEffect(() => {
    getUser();
  }, [userProfile]);

  const getUserMain = async () => {
    try {
      const usermain = await API.post("users/user-profile", {
        userId: userId,
      });
      setUser(usermain?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.slice(
        ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      // Check if the file extension is allowed
      if (allowedExtensions.includes(fileExtension?.toLowerCase())) {
        setSelectedImage(selectedFile);
        setShowModal(true);
        // handleImageUpload(selectedFile);
      } else {
        // Show an error message for disallowed file types
        toast.error("Invalid file type. Allowed extensions: jpg, jpeg, png", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        // You can display an error message to the user or perform other actions.
      }
    }
    event.target.value = null;
  };

  const handleImageUpload = async (image) => {
    const croppedCanvas = cropper.getCroppedCanvas();
    await croppedCanvas.toBlob(async (blob) => {
      const file = new File([blob], "my_image.jpg", { type: blob.type });

      // Use the blob here, e.g., for upload, display, or download
      setIsUploading(true);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
        },
      };
      try {
        const response = await API.post(
          `users/upload-profile`,
          {
            picture: file,
          },
          config
        );
        if (response?.status === 200) {
          await dispatch(userProfilePage());
          toast.success(response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setSelectedImage(null);
        } else {
          setSelectedImage(null);
          throw new Error("File upload failed.");
        }
        setShowModal(false);
        // Handle API response (e.g., update profile image URL)
      } catch (error) {
        toast.error("File upload failed.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } finally {
        setIsUploading(false);
      }
    });
  };

  const handleImageError = (event) => {
    // Handle broken image scenario
    event.target.src = ProfileNew; // Use the imported ProfileNew image
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile-{applicationName}</title>
      </Helmet>
      <div className="user-profile-main-wraper">
        <h3>
          {" "}
          User <span>Profile</span>{" "}
        </h3>
        {load ? (
          <ProfileShimmer />
        ) : (
          <>
            <div className="profile-info-wraper">
              <div className="profil-image-name-wrap">
                <div className="d-flex align-items-center gap-4 flex-md-row flex-column">
                  <div className="">
                    <div className="profile-edit-btn">
                      <a
                        className=""
                        onClick={() =>
                          document.getElementById("profileImage").click()
                        }
                      >
                        {" "}
                        <img src={EditWhite} alt="edit-icon" />{" "}
                      </a>
                      <img
                        className="profile-dash-image"
                        src={
                          user?.userInfo?.profileImage
                            ? user?.userInfo?.profileImage
                            : userProfileBlank
                        }
                        alt="User Profile"
                        onClick={() =>
                          document.getElementById("profileImage").click()
                        }
                        onError={handleImageError}
                      />
                    </div>
                    <input
                      type="file"
                      id="profileImage"
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between gap-2 w-100 flex-wrap">
                    <div className="profile-dash-text">
                      {" "}
                      <div className="w-100 d-flex align-items-center profile-role-wrapper">
                        <div className="d-flex profile-roles-span-wrap">
                          {user?.userInfo?.roles?.map((res, id) => (
                            <span className="user-profile-role-span" key={id}>
                              {" "}
                              {res?.roleName.charAt(0).toUpperCase() +
                                res?.roleName.slice(1)}
                            </span>
                          ))}
                        </div>
                        <div className="teams-listing-wap team-listing-profile">
                          <UserDepartmentList user={user} colors={colors} />
                        </div>
                      </div>
                      <h4> {user?.userInfo?.userName} </h4>
                      <p> {user?.userInfo?.companyName} </p>
                      {/* {state === 1 &&
                        user?.userInfo?.roles?.some(
                          (role) => role.roleType === "admin"
                        ) && <a className="edit-profile-btn">Add New Role </a>} */}
                    </div>
                    <div>
                      <Button
                        // icon="fa fa-edit"
                        text="Edit Profile"
                        handleClick={() => setOpenMoadl(true)}
                        width="150px"
                        iconify="mdi:edit"
                      />
                    </div>
                  </div>
                </div>
                <div className="profile-nav-wrap">
                  <div className="nav-wrap-inner" ref={navWrapRef}>
                    <ul>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 0 ? "selected-profile-tab-one" : ""
                          }`}
                          onClick={() => handleChange(0)}
                        >
                          {" "}
                          Personal Info{" "}
                        </a>{" "}
                      </li>
                      {user?.userInfo?.roles?.some(
                        (role) => role.roleType === "admin"
                      ) && (
                        <li>
                          {" "}
                          <a
                            className={`${
                              state === 1 ? "selected-profile-tab" : ""
                            }`}
                            onClick={() => handleChange(1)}
                          >
                            {" "}
                            Company Info{" "}
                          </a>{" "}
                        </li>
                      )}
                      {userProfile?.userLoginWith === "password" && (
                        <li>
                          {" "}
                          <a
                            className={`${
                              state === 2 ? "selected-profile-tab" : ""
                            }`}
                            onClick={() => handleChange(2)}
                          >
                            {" "}
                            Password{" "}
                          </a>{" "}
                        </li>
                      )}

                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 3 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(3)}
                        >
                          {" "}
                          Language & Region{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          onClick={() => {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/performance-report?userId=${
                                user?.userInfo?.userId
                              }`
                            );
                          }}
                        >
                          Performance Report
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 4 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(4)}
                        >
                          {" "}
                          Notification{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 5 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(5)}
                        >
                          {" "}
                          Session History{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 6 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(6)}
                        >
                          {" "}
                          Settings{" "}
                        </a>{" "}
                      </li>
                      {/*<li>
                        {" "}
                        <a
                          className={`${
                            state === 6 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(6)}
                        >
                          {" "}
                          Timezone{" "}
                        </a>{" "}
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {state === 0 && (
              <PersonalInfo
                user={user}
                getMainProfileData={() => getMainProfileData(false)}
              />
            )}
            {state === 1 &&
              user?.userInfo?.roles?.some(
                (role) => role.roleType === "admin"
              ) && (
                <div className="listing-info-profile-wrap">
                  <CompanyProfileMain
                    isDark={props.isDark}
                    placement="profile"
                  />
                </div>
              )}
            {state === 2 && userProfile?.userLoginWith === "password" && (
              <Password />
            )}
            {state === 3 && <Language user={user} />}
            {state === 4 && <Notifications user={user} />}
            {state === 5 && <SessionHistory isDark={props.isDark} />}
            {state === 6 && (
              <UserSettings
                user={user}
                isAdmin={user?.userInfo?.roles?.some(
                  (role) => role.roleType === "admin"
                )}
              />
            )}
          </>
        )}
        <Modal
          show={showModal}
          onHide={handleModalClose}
          centered
          size="lg"
          data-theme={props?.isDark}
        >
          <Modal.Header closeButton>
            <div className="modal-heading-wrap">
              <h3>
                {" "}
                {/* <span>Choose</span> Your Plan{" "} */}
                <span>Crop</span> Image{" "}
              </h3>
            </div>
          </Modal.Header>
          <Modal.Body style={{ height: "400px" }}>
            <div className="w-100 h-100">
              <img ref={imageRef} alt="crop-item" />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="crop-btn"
              onClick={handleImageUpload}
              disabled={isUploading}
            >
              {isUploading ? (
                <Spinner border="animation" size="1rem" />
              ) : (
                "Upload photo"
              )}
            </button>
          </Modal.Footer>
        </Modal>
        {openModal && (
          <ProfileEditModal
            show={openModal}
            handleCloseModal={() => setOpenMoadl(false)}
            isDark={props.isDark}
            userProfile={user}
            getUser={getUser}
          />
        )}
      </div>
    </>
  );
};

const UserDepartmentList = ({ user, colors }) => {
  const [showMore, setShowMore] = useState(false);

  const [moduleCategories, setModuleCategories] = useState([]);

  useMemo(() => {
    if (user.userRolesAndModules) {
      setModuleCategories(
        user?.userRolesAndModules.flatMap((role) => role?.moduleCategories)
      );
    }
  }, [user]);

  const toggleShowAll = () => {
    setShowMore(!showMore);
  };

  const firstFourCategories = moduleCategories.slice(0, 4);

  return (
    <ul>
      {showMore
        ? moduleCategories.map((moduleCategory, id) => {
            const uniqueId = uuidv4();
            return (
              <li
                key={uniqueId}
                style={{
                  backgroundColor: colors[id % colors.length],
                }}
                data-tooltip-id={`my-tooltip-${uniqueId}`}
              >
                {moduleCategory?.abbreviatedName}
                <ReactTooltip
                  id={`my-tooltip-${uniqueId}`}
                  place="top"
                  content={() => (
                    <div>
                      {moduleCategory?.abbreviatedName} <br />
                      {moduleCategory?.roleName.charAt(0).toUpperCase() +
                        moduleCategory?.roleName.slice(1)}
                    </div>
                  )}
                />
              </li>
            );
          })
        : firstFourCategories.map((moduleCategory, id) => {
            const uniqueId = uuidv4();
            return (
              <li
                key={uniqueId}
                style={{
                  backgroundColor: colors[id % colors.length],
                }}
                data-tooltip-id={`my-tooltip-${uniqueId}`}
              >
                {moduleCategory?.abbreviatedName}
                <ReactTooltip
                  id={`my-tooltip-${uniqueId}`}
                  place="top"
                  content={() => (
                    <div>
                      {moduleCategory?.abbreviatedName} <br />
                      {moduleCategory?.roleName.charAt(0).toUpperCase() +
                        moduleCategory?.roleName.slice(1)}
                    </div>
                  )}
                />
              </li>
            );
          })}
      {moduleCategories.length > 4 && (
        <li onClick={toggleShowAll} className="last-module-count">
          {showMore
            ? `-${moduleCategories.length - 4}`
            : `+${moduleCategories.length - 4}`}
        </li>
      )}
    </ul>
  );
};

export default ProfileMain;
