// export default Agtable;
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import AddBtn from "../../assets/add-btn.svg";
import Filter from "../../assets/filter-btn.svg";
import LeadImg from "../../assets/lead-drop.svg";
import Spinner from "react-bootstrap/Spinner";
import { FileIcon, defaultStyles } from "react-file-icon";
import "../Leads/styles/leads.scss";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import OffconcaveTable from "../../components/OffconcaveTable";
import "./agtable.css";
import { toast } from "react-toastify";
import flatpickr from "flatpickr";
import ScrollableDiv from "./ScrollableDiv";

const Agtable = () => {
  const color = ["#00c875", "#fdab3d", "#e2445c", "#c4c4c4"];
  const [api, setApi] = useState({});
  const [leftApi, setLeftApi] = useState(null);
  const [rightApi, setRightApi] = useState(null);
  const [user, setUser] = useState("");
  const [currentId, setCurrentId] = useState(0);
  const [og, setOg] = useState([
    {
      type_Id: uuidv4(),
      type: "todo",
      tasks: [
        {
          id: uuidv4(),
          project: "Project1",
        },
        {
          id: uuidv4(),
          project: "Project2",
        },
      ],
    },
    {
      type_Id: uuidv4(),
      type: "completed",
      tasks: [
        {
          id: uuidv4(),
          project: "Project3",
        },
        {
          id: uuidv4(),
          project: "Project4",
        },
      ],
    },
  ]);
  const [data, setData] = useState({});
  // const [completedData, setCompletedData] = useState([]);
  const [radioChecked, setRadioChecked] = useState(0);

  const handleScroll = (direction) => {
    if (direction === 0) {
      document.querySelector(".table-responsive").scrollLeft -= 50; // Adjust scroll speed as needed
    } else {
      document.querySelector(".table-responsive").scrollLeft += 50; // Adjust scroll speed as needed
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: (params) => params.data.id !== "aggrigate-data",
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  // On ready table state
  const gridReady = (params, side) => {
    if (side === 0) {
      setLeftApi(params.api);
    }

    if (side === 1) {
      setRightApi(params.api);
    }
    // var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    // if (columnState) {
    //   params.columnApi.applyColumnState({
    //     state: columnState,
    //     applyOrder: true,
    //   });
    // }
  };

  // Function to get all items from the 'todo' type
  const getAllItems = async () => {
    const todoItems = og.filter((item) => item.type === "todo");
    const allTodoTasks = todoItems.flatMap((item) => item.tasks);
    return allTodoTasks;
  };

  // Function to get all items from the 'completed' type
  const getCompletedAllItems = async () => {
    return og
      .filter((item) => item.type === "completed")
      .flatMap((item) => item.tasks);
  };

  // Row drag persistence
  const onRowDragEnd = async (params, id) => {
    // Perform any additional actions after the swap operation
    if (currentId !== id) {
      const rows = [];
      params.api.forEachNodeAfterFilterAndSort((node) => rows.push(node.data));
    } else {
      const rows = [];
      params.api.forEachNodeAfterFilterAndSort((node) => rows.push(node.data));
      // const newData = rows.filter((row) => row.id !== "aggrigate-data");
      localStorage.setItem("rowData", JSON.stringify(rows));
      console.log(
        "Rows : ",
        rows.filter((row) => row.id !== "aggrigate-data")
      );
    }
  };

  // Persist column swap state for table

  const swapColumnState = async (e) => {
    var columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem("myColumnState", columnState);
  };

  //--------------------------------------

  // Value edit
  const handleCellValueChanged = async (params, id) => {
    // if (id === 0) {
    //   await updateItem(params.data.id, params.data);
    // } else if (id === 1) {
    //   await updateCompletedItem(params.data.id, params.data);
    // }
    // getTableData();
  };
  //---------------------------------------------------

  // Column defs
  const leftColumns = [
    {
      field: "id",
      headerName: "id",
      editable: false,
      sortable: false,
    }, // Sort by ID in ascending order
    {
      field: "project",
      headerName: "Project",
      width: 200,
    },
  ];

  const rigthColumns = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      sortable: false,
    }, // Sort by ID in ascending order
    {
      field: "project",
      headerName: "Project",
      width: 200,
    },
  ];

  //------------------------------------------------

  // useEffect(() => {
  //   const getData = async () => {
  //     const response = await getAllItems();
  //     const responseC = await getCompletedAllItems();

  //     console.log(response);
  //     const newData = response.map((res, i) => ({
  //       id: res.id,
  //       project: res.project,
  //     }));
  //     const newDataC = responseC.map((res, i) => ({
  //       id: res.id,
  //       project: res.project,
  //     }));
  //     setData([...newData]);
  //     setCompletedData([...newDataC]);
  //     // setData(
  //     //   localStorage.getItem("rowData")
  //     //     ? JSON.parse(localStorage.getItem("rowData"))
  //     //     : [...newData, aggregatedDataRow]
  //     // );
  //   };
  //   getData();
  // }, []);

  useEffect(() => {
    const newData = {};
    og.forEach(({ type_Id, tasks }) => {
      newData[type_Id] = tasks;
    });
    setData(newData);
  }, []);

  const onDragStop = useCallback(
    (params) => {
      var nodes = params.nodes;

      if (radioChecked === 0) {
        leftApi.applyTransaction({
          remove: nodes.map(function (node) {
            return node.data;
          }),
        });
      } else if (radioChecked === 1) {
        leftApi.setNodesSelected({ nodes, newValue: false });
      }
    },
    [leftApi, radioChecked]
  );

  const onDragRStop = useCallback(
    (params) => {
      var nodes = params.nodes;

      if (radioChecked === 1) {
        rightApi.applyTransaction({
          remove: nodes.map(function (node) {
            return node.data;
          }),
        });
      } else if (radioChecked === 0) {
        rightApi.setNodesSelected({ nodes, newValue: false });
      }
    },
    [rightApi, radioChecked]
  );

  useEffect(() => {
    if (!leftApi || !rightApi) {
      return;
    }
    const dropZoneParams = rightApi.getRowDropZoneParams({ onDragStop });
    const dropZoneRParams = leftApi.getRowDropZoneParams({ onDragRStop });

    leftApi.removeRowDropZone(dropZoneParams);
    leftApi.addRowDropZone(dropZoneParams);
    rightApi.removeRowDropZone(dropZoneRParams);
    rightApi.addRowDropZone(dropZoneRParams);
  }, [leftApi, rightApi, onDragStop, onDragRStop]);

  const getGridWrapper = (id) => (
    <>
      <div className={`lead-heading-btn ${id > 0 ? "leading-grid-margin" : ""}`}>
        <div className="row">
          <div className="col-md-6">
            <h2>
              {" "}
              {id === 0 ? "To-do" : "Completed"}{" "}
              <a href="#">
                {" "}
                <img src={LeadImg} />{" "}
              </a>
            </h2>
          </div>
          <div className="col-md-6">
            <div className="btn-leads-cta-wrap">
              {" "}
              <a href="#">
                {" "}
                <img src={Filter} /> Filters{" "}
              </a>{" "}
              <a href="#">
                {" "}
                <img src={AddBtn} /> Add New{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="leads-table-wrap">
        <div className="table-responsive">
          <div
            className="ag-theme-alpine"
            style={{
              width: "100%",
              minWidth: "900px",
            }}
          >
            <AgGridReact
              gridId={id}
              rowData={data[id]}
              columnDefs={leftColumns}
              defaultColDef={defaultColDef}
              // getRowId={getRowId}
              onColumnMoved={swapColumnState}
              onDragStarted={(params) => {
                setCurrentId(params.api.gos.gridOptions.gridId);
              }}
              rowSelection={"multiple"}
              // suppressMoveWhenRowDragging={id === 0}
              // suppressRowClickSelection={id === 0}
              rowDragManaged={window.innerWidth > 1000 ? true : false}
              rowDragEntireRow={window.innerWidth > 1000 ? true : false}
              animateRows={true}
              domLayout={"autoHeight"}
              onRowDragEnd={(params) => onRowDragEnd(params, id)}
              singleClickEdit={true}
              suppressHorizontalScroll={true}
              overlayLoadingTemplate={
                '<object style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) scale(2)" type="image/svg+xml" data="https://ag-grid.com/images/ag-grid-loading-spinner.svg" aria-label="loading"></object>'
              }
              overlayNoRowsTemplate={
                "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
              }
              onGridReady={(params) => gridReady(params, id)}
              onCellValueChanged={(params) =>
                handleCellValueChanged(params, id)
              }
            ></AgGridReact>
          </div>
        </div>
        <ScrollableDiv/>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button onClick={() => handleScroll(0)}>Left</button>
          <button onClick={() => handleScroll(1)}>Right</button>
        </div>
      </div>
    </>
  );

  return (
    <div className={"leads-table-main-wraper"}>
      {Object.keys(data).map((key) => (
        <React.Fragment key={key}>{getGridWrapper(key)}</React.Fragment>
      ))}
      <div className="w-100"></div>
    </div>
  );
};

export default Agtable;
