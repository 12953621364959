// Payment history
export const PAYMENT_HISTORY_REQUEST = "PAYMENT_HISTORY_REQUEST";
export const PAYMENT_HISTORY_SUCCESS = "PAYMENT_HISTORY_SUCCESS";
export const PAYMENT_HISTORY_FAIL = "PAYMENT_HISTORY_FAIL";

// Billing detail
export const BILLING_REQUEST = "BILLING_REQUEST";
export const BILLING_SUCCESS = "BILLING_SUCCESS";
export const BILLING_FAIL = "BILLING_FAIL";

// Subscription detail
export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAIL = "SUBSCRIPTION_FAIL";
