import React from "react";
import Invoice from "./components/Invoice";

const InvoicesMain = (props) => {
  return (
    <Invoice
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default InvoicesMain;
