/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Password = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newError, setNewError] = useState("");
  const [oldError, setOldError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [load, setLoad] = useState(false);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    setOldError("");
    setPasswordError("");
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmError("");
  };

  const handleUpdatePassword = async (e) => {
    // Validate old password
    e.preventDefault();
    e.stopPropagation();
    // Validate alphanumeric new password
    if (
      !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(newPassword) ||
      !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(oldPassword)
    ) {
      if (!/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(newPassword)) {
        setNewError("New password must be alphanumeric");
      }
      if (!/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(oldPassword)) {
        setOldError("Old password must be alphanumeric");
      }
      return;
    }

    // Validate new and confirm password match
    if (newPassword !== confirmPassword) {
      setConfirmError("New and confirm passwords do not match");
      return;
    }

    try {
      setLoad(true);
      const response = await API.post("users/update-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      if (response?.data?.status === "success") {
        toast.success("Password updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        setPasswordError("Invalid password");
      }
    } catch (error) {
      setPasswordError("Your password does not match");
    } finally {
      setLoad(false);
    }
  };

  return (
    <form className="listing-info-profile-wrap" autoComplete="off">
      <h4>Password</h4>
      <ul className="row password-row-info-main">
        <li className="col-md-12">
          <div className="overview-profile overview-password">
            <a
              style={{
                border: (passwordError || oldError) && "1px solid #e93131",
              }}
            >
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.32143 3.20831C2.69416 3.20831 1.375 4.52747 1.375 6.15474C1.375 8.32443 3.13388 10.0833 5.30357 10.0833H5.69643C7.86612 10.0833 9.625 8.32443 9.625 6.15474C9.625 4.52747 8.30584 3.20831 6.67857 3.20831H4.32143ZM5.5 6.79165C5.70711 6.79165 5.875 6.62375 5.875 6.41665C5.875 6.20954 5.70711 6.04165 5.5 6.04165C5.29289 6.04165 5.125 6.20954 5.125 6.41665C5.125 6.62375 5.29289 6.79165 5.5 6.79165ZM6.875 6.41665C6.875 6.99964 6.51217 7.49789 6 7.69791V8.70831H5V7.69791C4.48783 7.49789 4.125 6.99964 4.125 6.41665C4.125 5.65725 4.74061 5.04165 5.5 5.04165C6.25939 5.04165 6.875 5.65725 6.875 6.41665Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.56274 3.66656V3.66656C7.70224 2.82954 7.33851 1.98842 6.6331 1.51677L6.4618 1.40223C5.53388 0.781798 4.30569 0.858269 3.4619 1.58902L3.05729 1.93941"
                    stroke="currentColor"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap pass-info-num-wrap">
                <strong>Old Password:</strong>
                <input
                  className="password-input-fields"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  type="password"
                  placeholder="Enter Old Password"
                  autoComplete="new-password"
                />
              </div>
              <div className="clearfix"></div>
            </a>
            {passwordError && (
              <p
                style={{ color: "#e93131", textAlign: "end", fontSize: "14px" }}
              >
                {passwordError}
              </p>
            )}
            {oldError && (
              <p
                style={{ color: "#e93131", textAlign: "end", fontSize: "14px" }}
              >
                {oldError}
              </p>
            )}
          </div>
        </li>
        <li className="col-md-12">
          <div className="overview-profile overview-password">
            <a style={{ border: newError && "1px solid #e93131" }}>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.32143 3.20831C2.69416 3.20831 1.375 4.52747 1.375 6.15474C1.375 8.32443 3.13388 10.0833 5.30357 10.0833H5.69643C7.86612 10.0833 9.625 8.32443 9.625 6.15474C9.625 4.52747 8.30584 3.20831 6.67857 3.20831H4.32143ZM5.5 6.79165C5.70711 6.79165 5.875 6.62375 5.875 6.41665C5.875 6.20954 5.70711 6.04165 5.5 6.04165C5.29289 6.04165 5.125 6.20954 5.125 6.41665C5.125 6.62375 5.29289 6.79165 5.5 6.79165ZM6.875 6.41665C6.875 6.99964 6.51217 7.49789 6 7.69791V8.70831H5V7.69791C4.48783 7.49789 4.125 6.99964 4.125 6.41665C4.125 5.65725 4.74061 5.04165 5.5 5.04165C6.25939 5.04165 6.875 5.65725 6.875 6.41665Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.56274 3.66656V3.66656C7.70224 2.82954 7.33851 1.98842 6.6331 1.51677L6.4618 1.40223C5.53388 0.781798 4.30569 0.858269 3.4619 1.58902L3.05729 1.93941"
                    stroke="currentColor"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap pass-info-num-wrap">
                <strong>New Password:</strong>
                <input
                  className="password-input-fields"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  type="password"
                  placeholder="Enter New Password"
                  autoComplete="new-password"
                />
              </div>
              <div className="clearfix"></div>
            </a>
            {newError && (
              <p
                style={{ color: "#e93131", textAlign: "end", fontSize: "14px" }}
              >
                {newError}
              </p>
            )}
          </div>
        </li>
        <li className="col-md-12">
          <div className="overview-profile overview-password">
            <a style={{ border: confirmError && "1px solid #e93131" }}>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.32143 3.20831C2.69416 3.20831 1.375 4.52747 1.375 6.15474C1.375 8.32443 3.13388 10.0833 5.30357 10.0833H5.69643C7.86612 10.0833 9.625 8.32443 9.625 6.15474C9.625 4.52747 8.30584 3.20831 6.67857 3.20831H4.32143ZM5.5 6.79165C5.70711 6.79165 5.875 6.62375 5.875 6.41665C5.875 6.20954 5.70711 6.04165 5.5 6.04165C5.29289 6.04165 5.125 6.20954 5.125 6.41665C5.125 6.62375 5.29289 6.79165 5.5 6.79165ZM6.875 6.41665C6.875 6.99964 6.51217 7.49789 6 7.69791V8.70831H5V7.69791C4.48783 7.49789 4.125 6.99964 4.125 6.41665C4.125 5.65725 4.74061 5.04165 5.5 5.04165C6.25939 5.04165 6.875 5.65725 6.875 6.41665Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.56274 3.66656V3.66656C7.70224 2.82954 7.33851 1.98842 6.6331 1.51677L6.4618 1.40223C5.53388 0.781798 4.30569 0.858269 3.4619 1.58902L3.05729 1.93941"
                    stroke="currentColor"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap pass-info-num-wrap">
                <strong>Confirm Password:</strong>
                <input
                  className="password-input-fields"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  type="password"
                  placeholder="Enter Confirm Password"
                  autoComplete="new-password"
                />
              </div>
              <div className="clearfix"></div>
            </a>
            {confirmError && (
              <p
                style={{ color: "#e93131", textAlign: "end", fontSize: "14px" }}
              >
                {confirmError}
              </p>
            )}
          </div>
        </li>
      </ul>
      <input type="hidden" value="something" />
      <div className="w-100 overview-password-btn">
        <button
          onClick={handleUpdatePassword}
          className="d-flex justify-content-center align-items-center"
        >
          {load ? <Spinner animation="border" /> : "Update"}
        </button>
      </div>
    </form>
  );
};

export default Password;
