import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./customToast.css";

const CustomToast = ({ isVisible, onClose, updateLoad }) => {
  const [progress, setProgress] = useState(25);
  const [statusText, setStatusText] = useState("Updating Task...");
  const [isDone, setIsDone] = useState(false); // To track when the task is done

  useEffect(() => {
    setProgress(25);
    setStatusText("Updating Task...");
    setIsDone(false); // Reset when the task is updated
  }, [isVisible]);

  useEffect(() => {
    let interval;

    if (updateLoad) {
      // Gradually increase progress to 75%
      interval = setInterval(() => {
        setProgress((prev) => (prev < 75 ? prev + 10 : prev));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [updateLoad]);

  useEffect(() => {
    if (!updateLoad) {
      setProgress(100);
      setStatusText("Done!");
      setIsDone(true); // Mark as done

      // Close the toast after 3 seconds
      const timeout = setTimeout(() => {
        onClose();
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [updateLoad, onClose]);

  // Add a CSS class for visibility based on `isVisible`
  return (
    <div
      className={`custom-toast ${
        isVisible ? "visible" : "hidden"
      } on-update-loader ${isDone ? "is-uppdate-done" : ""}`}
    >
      <span>{statusText}</span>
      <ProgressBar
        now={progress}
        variant="info"
        className="w-100 mt-2"
        style={{ height: "2px", borderRadius: "4px" }}
      />
    </div>
  );
};

export default CustomToast;
