import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { useDeleteTaskDataMutation } from "../../../slices/tasks/tasksSlice";
import { useDeleteCompletedTaskDataMutation } from "../../../slices/tasks/completedTasks.Slice";

const DeleteSelectedTasksModal = ({
  showDeleteModal = false,
  handleCloseDeleteModal = () => {},
  isDark = false,
  listingType = "ALL_TASKS",
  selectedTasks = [],
  getTaskList = () => {},
  getGrid = () => {},
  setListToFilter = () => {},
  setTaskList = () => {},
  setSelectedTasks = () => {},
  isActive = false,
}) => {
  const [dload, setDLoad] = useState(false);

  // Initialize the delete mutation hooks
  const [deleteTaskData, { isLoading }] = useDeleteTaskDataMutation();
  const [deleteCompletedTaskData] = useDeleteCompletedTaskDataMutation();

  // Delete tasks
  const deleteTasks = async () => {
    try {
      setDLoad(true);
      // Call the delete mutation function with selected task IDs
      if (listingType === "COMPLETED_TASKS") {
        await deleteCompletedTaskData(selectedTasks).unwrap();
      } else {
        await deleteTaskData(selectedTasks).unwrap();
      }

      // Handle UI updates after successful deletion
      handleCloseDeleteModal();
      getTaskList();
      if (isActive) {
        getGrid();
      }
      toast.success(
        `${selectedTasks.length === 1 ? "Task" : "Tasks"} deleted successfully`,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        }
      );

      // Update task lists in the parent component
      setListToFilter((prevList) =>
        prevList.filter((task) => !selectedTasks.includes(task.id))
      );
      setTaskList((prevList) =>
        prevList.filter((task) => !selectedTasks.includes(task.id))
      );
      setSelectedTasks([]);
    } catch (error) {
      toast.error(error?.message || "Error deleting tasks.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>

        <p className="del-modal-name">
          selected {selectedTasks.length === 1 ? "task" : "tasks"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={deleteTasks}
            disabled={dload || isLoading}
          >
            {dload || isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSelectedTasksModal;
