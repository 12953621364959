import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const AddStageModal = (props) => {
  const [rload, setRLoad] = useState(false);
  const [err, setErr] = useState("");
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Stage name is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    // You can perform any additional submission logic here
    // If there are no validation errors
    try {
      if (values.name) {
        const newColumn = {
          type_id: uuidv4(),
          type: values.name,
          type_count: 0,
          tasks: [],
        };

        props.setTasksData([...props.tasksData, newColumn]);
        // Set submitting to false to allow the form to be submitted again
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
      props.handleClose();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="col-md-12 choose-industry-columns ">
          <div>
            <div className="form-section-wrap form-modal-wrap role-modal">
              <h4>
                {" "}
                Stage <span>name</span>{" "}
              </h4>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="form-group">
                      <label>Name*</label>
                      <Field
                        type="text"
                        name="name"
                        className={`form-control ${
                          (touched.name && errors.name) || err
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Add stage name"
                        onChange={(e) => {
                          setFieldValue("name", e.target.value); // This will update the form value
                          setErr(""); // Reset the error
                        }}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                      {err && (
                        <p
                          className="text-end"
                          style={{ fontSize: "14px", color: "#e93131" }}
                        >
                          {err}
                        </p>
                      )}
                    </div>

                    <div className="btn-group-signup">
                      <button
                        type="submit"
                        className="d-flex align-items-center justify-content-center"
                      >
                        {rload ? <Spinner animation="border" /> : "Add"}
                      </button>
                    </div>

                    {/* <div className="or-text-signup">
                        <span>OR</span>
                      </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStageModal;
