import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreatePerformanceTemplateMutation,
  useGetPerformanceTemplateDetailsQuery,
  useUpdatePerformanceTemplateScoreMutation,
} from "../../../slices/performance/performanceSlice";
import { toast } from "react-toastify";
import _ from "lodash";

function useAddApiOffCanvas({ show, handleCloseModal, updateKPIId }) {
  const [isAssigneeCardExpanded, setAssigneeCardExpanded] = useState(true);
  const [isOwnerCardExpanded, setOwnerCardExpanded] = useState(true);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [updatePerformanceTemplateScore, { isLoading: isCPTSubmitLoading }] =
    useUpdatePerformanceTemplateScoreMutation();

  const {
    data: templateDetails,
    isLoading: isLoadingDetails,
    error: detailsError,
  } = useGetPerformanceTemplateDetailsQuery(updateKPIId, {
    skip: !show || !updateKPIId,
  });

  const formatMinMaxErrorMessage = (value, isMin = false) => {
    if (typeof value !== "number" || isNaN(value)) return "Invalid value";

    const formattedValue = value > 0 && isMin ? `-${value}` : value;
    return `Cannot be ${isMin ? "less" : "greater"} than ${formattedValue}`;
  };

  const initialKPIValues = {
    kpiTitle: "",
    allowPoints: false,
    taskAssigneePoints: {
      maxPoints: 0,
      taskPoints: 0,
      onTimeCompletion: 0,
      lateCompletion: 0,
      dailyAttendancePoints: 0,
      onTimeAttendance: 0,
      lateCheckIn: 0,
      earlyCheckOut: 0,
      leave: 0,
      absent: 0,
    },
    taskOwnerPoints: {
      maxPoints: 0,
      taskPoints: 0,
      onTimeCompletion: 0,
      lateCompletion: 0,
      dailyAttendancePoints: 0,
      onTimeAttendance: 0,
      lateCheckIn: 0,
      earlyCheckOut: 0,
      leave: 0,
      absent: 0,
    },
  };

  const validationSchema = Yup.object({
    kpiTitle: Yup.string().required("KPI Title is required"),

    taskAssigneePoints: Yup.object({
      maxPoints: Yup.number()
        .required("Maximum points are required")
        .positive("Must be positive"),

      taskPoints: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      onTimeCompletion: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      lateCompletion: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      dailyAttendancePoints: Yup.number()
        .required("Attendance points are required")
        .positive("Must be positive"),

      onTimeAttendance: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      lateCheckIn: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      earlyCheckOut: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      leave: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      absent: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
    }),

    taskOwnerPoints: Yup.object({
      maxPoints: Yup.number()
        .notRequired() // Makes it optional
        .moreThan(-1, "Must be 0 or a positive number"),

      taskPoints: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      onTimeCompletion: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      lateCompletion: Yup.number().when("maxPoints", (maxPoints, schema) =>
        schema
          .min(-maxPoints, formatMinMaxErrorMessage(Number(maxPoints), true)) // Pass `true` for min
          .max(maxPoints, formatMinMaxErrorMessage(Number(maxPoints), false))
      ),

      dailyAttendancePoints: Yup.number()
        .notRequired() // Makes it optional
        .moreThan(-1, "Must be 0 or a positive number"),

      onTimeAttendance: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      lateCheckIn: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      earlyCheckOut: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      leave: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
      absent: Yup.number().when(
        "dailyAttendancePoints",
        (dailyAttendancePoints, schema) =>
          schema
            .min(
              -dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), true)
            ) // Pass `true` for min
            .max(
              dailyAttendancePoints,
              formatMinMaxErrorMessage(Number(dailyAttendancePoints), false)
            )
      ),
    }),
  });

  useEffect(() => {
    if (show) {
      formik?.resetForm();
      handleUpdateIsShowUpdateModal(false);
    }
  }, [show]);

  const formik = useFormik({
    initialValues: initialKPIValues,
    validationSchema,
    validateOnChange: false, // Prevent validation on change
    validateOnBlur: false, // Prevent validation on blur
    onSubmit: async (values) => {
      handleUpdateIsShowUpdateModal(true);
    },
  });
  const removeError = async (fieldPath) => {
    // Set a new timeout to validate after user stops typing
    setTimeout(() => {
      formik.validateField(fieldPath);
    }, 100); // Adjust delay as needed
  };

  useEffect(() => {
    if (templateDetails && templateDetails?.data) {
      const template = templateDetails?.data;
      console.log("template", template);

      formik.setValues({
        kpiTitle: template.performanceTemplate.templateName,
        allowPoints: template.taskApplyRules === 1 ? true : false,
        taskAssigneePoints: {
          maxPoints: template.taskAssigneeMaxPoints,
          taskPoints: 0, // This field is not present in the API response
          onTimeCompletion: template.taskAssigneeCompletionPoints,
          lateCompletion: template.taskAssigneeDeductedPerDay,
          dailyAttendancePoints: template.assigneeDailyAttendancePoints,
          onTimeAttendance: template.assigneeOnTimePoints,
          lateCheckIn: template.assigneeLateCheckinPoints,
          earlyCheckOut: template.assigneeEarlyCheckoutPoints,
          leave: template.assigneeLeavePoints,
          absent: template.assigneeAbsentPoints,
        },
        taskOwnerPoints: {
          maxPoints: template.taskOwnerMaxPoints,
          taskPoints: 0, // This field is not present in the API response
          onTimeCompletion: template.taskOwnerCompletionPoints,
          lateCompletion: template.taskOwnerPointsDeductedPerDay,
          dailyAttendancePoints: template.ownerDailyAttendancePoints,
          onTimeAttendance: template.ownerOnTimePoints,
          lateCheckIn: template.ownerLateCheckinPoints,
          earlyCheckOut: template.ownerEarlyCheckoutPoints,
          leave: template.ownerLeavePoints,
          absent: template.ownerAbsentPoints,
        },
      });
    }
  }, [templateDetails, formik.setValues]);

  const toggleAssigneeCard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAssigneeCardExpanded(!isAssigneeCardExpanded);
  };

  const toggleOwnerCard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOwnerCardExpanded(!isOwnerCardExpanded);
  };
  const handleUpdateIsShowUpdateModal = (value) => {
    setIsShowUpdateModal(value);
  };

  const handleOnProceed = async () => {
    try {
      let values = formik?.values;
      const apiBody = {
        templateName: values.kpiTitle,
        templateId: updateKPIId || undefined, // Include templateId if it exists

        assignee: {
          taskApplyRules: values?.allowPoints ? 1 : 0,
          taskMaxPoints: values.taskAssigneePoints.maxPoints,
          taskCompletionPoints: values.taskAssigneePoints.onTimeCompletion,
          pointsDeductedPerDay: values.taskAssigneePoints.lateCompletion,
          attendanceRuleEnabled: true,
          dailyAttendancePoints:
            values.taskAssigneePoints.dailyAttendancePoints,
          onTimePoints: values.taskAssigneePoints.onTimeAttendance,
          lateCheckinPoints: values.taskAssigneePoints.lateCheckIn,
          earlyCheckoutPoints: values.taskAssigneePoints.earlyCheckOut,
          leavePoints: values.taskAssigneePoints.leave,
          absentPoints: values.taskAssigneePoints.absent,
        },
        owner: {
          taskMaxPoints: values.taskOwnerPoints.maxPoints,
          taskCompletionPoints: values.taskOwnerPoints.onTimeCompletion,
          pointsDeductedPerDay: values.taskOwnerPoints.lateCompletion,
          attendanceRuleEnabled: true,
          dailyAttendancePoints: values.taskOwnerPoints.dailyAttendancePoints,
          onTimePoints: values.taskOwnerPoints.onTimeAttendance,
          lateCheckinPoints: values.taskOwnerPoints.lateCheckIn,
          earlyCheckoutPoints: values.taskOwnerPoints.earlyCheckOut,
          leavePoints: values.taskOwnerPoints.leave,
          absentPoints: values.taskOwnerPoints.absent,
        },
      };

      const response = await updatePerformanceTemplateScore(apiBody).unwrap();
      toast.success("Performance template created successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
      formik?.resetForm();
      handleUpdateIsShowUpdateModal(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error creating performance template:", error);
      toast.error(
        error.data?.message ||
          "An error occurred while creating the performance template"
      );
    }
  };

  return {
    formik,
    isCPTSubmitLoading,
    isAssigneeCardExpanded,
    isOwnerCardExpanded,
    toggleAssigneeCard,
    toggleOwnerCard,
    handleUpdateIsShowUpdateModal,
    setIsShowUpdateModal,
    isShowUpdateModal,
    handleOnProceed,
    removeError,
  };
}

export default useAddApiOffCanvas;
