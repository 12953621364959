// constants/projectCompletedListingConstants.js

export const PROJECT_COMPLETED_LISTING_REQUEST = 'PROJECT_COMPLETED_LISTING_REQUEST';
export const PROJECT_COMPLETED_LISTING_SUCCESS = 'PROJECT_COMPLETED_LISTING_SUCCESS';
export const PROJECT_COMPLETED_LISTING_FAIL = 'PROJECT_COMPLETED_LISTING_FAIL';

export const PROJECT_COMPLETED_EDIT_REQUEST = 'PROJECT_COMPLETED_EDIT_REQUEST';
export const PROJECT_COMPLETED_EDIT_SUCCESS = 'PROJECT_COMPLETED_EDIT_SUCCESS';
export const PROJECT_COMPLETED_EDIT_FAIL = 'PROJECT_COMPLETED_EDIT_FAIL';

export const PROJECT_COMPLETED_DELETE_REQUEST = 'PROJECT_COMPLETED_DELETE_REQUEST';
export const PROJECT_COMPLETED_DELETE_SUCCESS = 'PROJECT_COMPLETED_DELETE_SUCCESS';
export const PROJECT_COMPLETED_DELETE_FAIL = 'PROJECT_COMPLETED_DELETE_FAIL';

export const PROJECT_COMPLETED_CLEAR_STATE = 'PROJECT_COMPLETED_CLEAR_STATE';
