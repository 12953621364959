import { toast } from "react-toastify";
import { ProjectAPI } from "../../../services/ClientApi";

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export const getProjectPhases = async (projectId) => {
  try {
    const response = await ProjectAPI.post("project/pm-project-phases", {
      projectId: projectId,
    });
    return response;
  } catch (error) {
  } finally {
  }
};

// create a function that will get all tasks by phase id and project id
export const getTasksByPhaseIdAndProjectId = async (phaseId, projectId) => {
  try {
    const response = await ProjectAPI.post("project/phase-tasks", {
      projectId: projectId,
      projectPhaseId: phaseId,
    });
    return response;
  } catch (error) {
  } finally {
  }
};

// create a function that will get all tasks by project
export const getTasksAllPhases = async (projectId) => {
  try {
    const response = await ProjectAPI.post("project/pm-project-phases-tasks", {
      projectId: projectId,
    });
    return response;
  } catch (error) {
  } finally {
  }
};

export const toCamelCase = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

// update project
export const updateProject = async (data, isShowToast) => {
  try {
    const response = await ProjectAPI.post(`project/pm-update-project`, data);
    if (response) {
      isShowToast &&
        toast.success("Project timeline date updated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
    }
  } catch (error) {
    isShowToast && toast.error(error, { position: toast.POSITION.TOP_CENTER });
  }
};
