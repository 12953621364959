import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/Bell_fill.svg";

const DeleteReSubscribeModel = (props) => {
  const [dload, setDLoad] = useState(false);

  const handleSubmit = async () => {
    try {
      setDLoad(true);
      await props.handleSubmit(
        props?.packageId,
        props?.stripeProductId,
        props?.totalAmount,
        props?.seatCount,
        props?.interval
      );
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };
  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal unsubscribe-modal unsub-main-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div
          className="del-modal-img-container d-flex align-items-center justify-content-center mb-3"
          style={{
            width: "82px",
            height: "82px",
            backgroundColor: "#EFF2FF",
            borderRadius: "50%",
          }}
        >
          <img
            src={DeleteIcon}
            style={{ width: "30px", height: "30px", margin: 0 }}
          />
        </div>

        <h6 style={{ fontSize: "16px !important" }} className="text-center">
          {props.title}
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            disabled={dload}
            className="w-50 delete-btn-1"
            onClick={handleSubmit}
          >
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              props.buttonTitle
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReSubscribeModel;
