// Helper to get statusName by ID
export const getStatusNameById = (statuses, id) => {
  if (id === "9faa1123-d3f6-45a0-9ea3-a238dba20307") {
    return "Deleted";
  }

  const status = statuses?.find((status) => status.id === id);
  return status ? status.statusName : null;
};

// Helper to get defaultMarkAs by ID
export const getDefaultMarkAsById = (statuses, id) => {
  if (id === "9faa1123-d3f6-45a0-9ea3-a238dba20307") {
    return "deleted";
  }
  const status = statuses?.find((status) => status.id === id);
  return status ? status?.defaultMarkAs : null;
};

// Helper to get ID by defaultMarkAs value
export const getIdByDefaultMarkAs = (statuses, defaultMarkAs) => {
  const status = statuses?.find(
    (status) => status.defaultMarkAs === defaultMarkAs
  );
  return status ? status?.id : null;
};

// Helper to get statusName by ID
export const getPriorityNameById = (priorities, id) => {
  const priority = priorities?.find((priority) => priority.id === id);
  return priority ? priority?.priorityName : null;
};

// Helper to get name of priority by default mark as
export const getPriorityNameByDefaultMarkAs = (priorities, defaultMarkAs) => {
  const priority = priorities?.find(
    (priority) => priority.defaultMarkAs === defaultMarkAs
  );
  return priority ? priority?.priorityName : null;
};

// Type label helper
export const formatLabel = (label) => {
  if (label === "last_month") {
    return "Last month";
  }
  if (label === "this_week") {
    return "This week";
  }
  if (label === "this_month") {
    return "This month";
  }
  if (label === "all_time") {
    return "All Time";
  }
  if (label === "date_range") {
    return "Date Range";
  }
  return label.charAt(0).toUpperCase() + label.slice(1);
};

// Utility function to format file size
export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

// Helper function to truncate text
export const truncateText = (text, maxLength = 8) => {
  return text?.length > maxLength
    ? text?.substring(0, maxLength) + "..."
    : text;
};

// Compare 2 objects with an exception for 'filterType'
export const areObjectsEqual = (obj1, obj2) => {
  // Check if both are objects
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  // Get keys excluding 'filterType'
  const keys1 = Object.keys(obj1).filter((key) => key !== "filterType");
  const keys2 = Object.keys(obj2).filter((key) => key !== "filterType");

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively compare all keys and values, ignoring 'filterType'
  for (const key of keys1) {
    if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

// Utility function to check if an element is in the viewport
export const isElementInViewport = (el) => {
  if (!el || typeof el.getBoundingClientRect !== "function") return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// Calculate overall progress
export function calculateAverageProgress(taskList) {
  // Mapping of status names to progress values
  const statusProgressMap = {
    "Not Started": 0,
    "In Progress": 33,
    "On Hold": 66,
    "Submit For Review": 66,
    Completed: 100,
  };

  // Initialize an array to store individual progress ratios
  const progressRatios = [];

  // Iterate over the task list to calculate progress ratios
  taskList?.forEach((task) => {
    const statusName = task?.taskStatus?.statusName;
    const progress = statusProgressMap[statusName]; // Retrieve progress from the map

    if (progress !== undefined) {
      // Convert progress to a ratio (progress / 100)
      progressRatios.push(progress / 100);
    }
  });

  // Calculate the average progress ratio
  const totalProgress = progressRatios.reduce(
    (total, ratio) => total + ratio,
    0
  );

  const averageProgress = totalProgress / progressRatios.length;
  // Return the average progress percentage
  return averageProgress * 100 || 0; // Return 0 if the array is empty
  // return statusAverageProgress;
}
