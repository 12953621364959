import React from "react";
import Expenses from "./components/Expenses";

const ExpensesMain = (props) => {
  return (
    <Expenses
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default ExpensesMain;
