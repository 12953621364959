import React, { useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector } from "react-redux";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

import TaskUserFilter from "./TaskUserFilter";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import bugIcon from "../../../assets/bug-icon.png";
import newIcon from "../../../assets/new-task.png";
import errorIcon from "../../../assets/issue.png";
import { formatCustomDate } from "../../../helper/dateFormatter";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import moment from "moment-timezone";
import { createPortal } from "react-dom";
import ClickAwayListener from "react-click-away-listener";
import { useIsMobile } from "../../../helper/windowHelper";
const Portal = (props) => {
  return createPortal(props.children, document.body);
};
function TaskDetailFilterSection({
  props,
  task,
  setTask,
  taskList,
  setTaskList,
  isDark,
  getStatusCounts,
  projectTypes,
  priorities,
  types,
  kanban,
  getTaskDataAfterUpdate,
  canEdit,
  userProfile,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
}) {
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    task?.taskDeadline ? new Date(task?.taskDeadline?.split("T")[0]) : null
  );
  const [selectedPrevDateFilter, setSelectedPrevDateFilter] = useState(
    task?.taskDeadline ? moment(task?.taskDeadline)?.toISOString() : null
  );

  const [selectedTime, setSelectedTime] = useState(() => {
    if (task?.taskDeadline) {
      // Format the time as HH:mm
      return `${
        moment(task?.taskDeadline)?.toISOString()?.split("T")[1]?.split(":")[0]
      }:${
        moment(task?.taskDeadline)?.toISOString()?.split("T")[1]?.split(":")[1]
      }`;
    }
    return "19:00"; // Default time
  });

  const [taskPriorityList] = useState(["Critical", "Highest", "Medium", "Low"]);
  const [taskTypesList] = useState(["New Task", "Bug", "Issue"]);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const [selectedTaskType, setSelectedTaskType] = useState(
    !task?.taskType ? "New task" : task?.taskType?.typeName
  );
  const [selectedTaskPriority, setSelectedTaskPriority] = useState(
    task?.taskPriority?.length === 0 ? null : task?.taskPriority?.priorityName
  );
  const updateRowParameterById = (id, parameterName, parameterValue) => {
    setTaskList((prevRowData) => {
      // Find the index of the object with the given id
      const rowIndex = prevRowData.findIndex((row) => row.id === id);

      // If the object with the given id is found
      if (rowIndex !== -1) {
        // Create a new array to avoid mutating the state directly
        const newRowData = [...prevRowData];
        // Update the parameter of the object at the found index
        newRowData[rowIndex] = {
          ...newRowData[rowIndex],
          [parameterName]: parameterValue,
        };
        return newRowData;
      }

      // If the object with the given id is not found, return the previous state
      return prevRowData;
    });
  };

  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorityList.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "critical"
                        ? criticalImage
                        : res?.toLowerCase() === "highest"
                        ? highestImage
                        : res?.toLowerCase() === "medium"
                        ? mediumImage
                        : res?.toLowerCase() === "low"
                        ? lowImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomTasksTypeEditorControl = ({ children, menuIsOpen, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskTypesList?.map((res, index) => {
              if (
                res?.toLowerCase() === props.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "bug"
                        ? bugIcon
                        : res?.toLowerCase() === "error"
                        ? criticalImage
                        : res?.toLowerCase() === "new task"
                        ? newIcon
                        : res?.toLowerCase() === "issue"
                        ? errorIcon
                        : mediumImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 4,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected ? "#deebff" : "#fff",
      // backgroundColor: state.isSelected ? "#e9eeff" : "inherit",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      width: "120px", // Set the desired width
    }),
  };

  // Task update function
  const updateTask = async (data) => {
    await updateTaskData(data);
  };

  // Get priority id
  const getIdByPriorityName = (priorityName) => {
    const priority = priorities?.find(
      (item) => item.priorityName === priorityName
    );
    return priority ? priority.id : null;
  };

  // Get type id
  const getIdByTypeName = (typeName) => {
    const type = types?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const options = taskPriorityList
    ?.filter((res) => res !== selectedTaskPriority)
    .map((res) => ({
      label: res,
      value: res,
    }));

  const handleClearDate = () => {
    setSelectedDateFilter(null);
    const data = {
      taskId: task?.id,
      fieldName: "taskDeadline",
      fieldValues: null,
    };
    updateTask(data);
  };

  return (
    <>
      {/* filters for laptop screen */}
      <div className="mb-2 task-detail-filter-section task-main-info-list project-main-info-laptop">
        <ul
          className={`${
            !canEdit ? "cannot-edit-li" : ""
          } project-list-filter-container`}
        >
          <li className="h-100">
            <TaskUserFilter
              data={task}
              setData={setTask}
              props={props}
              projectUserList={
                projectUserList?.data?.map((user) => ({ ...user })) || []
              }
              projectTypes={projectTypes}
              updateRowParameterById={updateRowParameterById}
              kanban={kanban}
              getTaskDataAfterUpdate={getTaskDataAfterUpdate}
              canEdit={canEdit}
              userId={userProfile?.userId}
              userProfile={userProfile}
              updateTaskData={updateTaskData}
              pageParams={pageParams}
              listingType={listingType}
              setUpdateLoad={setUpdateLoad}
              updateTaskMemberData={updateTaskMemberData}
              updatePhaseTaskMemberData={updatePhaseTaskMemberData}
              isCompletedTaskChanged={isCompletedTaskChanged}
              setIsCompletedTaskChanged={setIsCompletedTaskChanged}
              allowAssigntoText={true}
            />
          </li>
          <li className="h-100">
            <DatePickerSection
              canEdit={canEdit}
              isDark={isDark}
              updateTask={updateTask}
              task={task}
              time={selectedTime}
              setTime={(newTime) => setSelectedTime(newTime)}
              date={selectedDateFilter}
              setDate={(newDate) => setSelectedDateFilter(newDate)}
              selectedPrevDateFilter={selectedPrevDateFilter}
              setSelectedPrevDateFilter={setSelectedPrevDateFilter}
              apiCallToSetDate={handleClearDate}
              heightFlex={true}
            />
          </li>

          {canEdit ? (
            <li className="h-100 task-situation-filter-top-wrap-select">
              <div className="h-100 form-group task-detail-form-group">
                <Select
                  className={`h-100 td-basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                    selectedTaskPriority
                      ? "filter-dropdown-task-detail-selected-value"
                      : ""
                  }`}
                  classNamePrefix="select"
                  value={
                    selectedTaskPriority
                      ? {
                          label: selectedTaskPriority,
                          value: selectedTaskPriority,
                        }
                      : { label: "Set Priority", value: "" }
                  }
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={false}
                  styles={customStyles}
                  menuPlacement="auto" // Adjust placement based on available space
                  menuPosition="fixed" // Use fixed positioning to ensure visibility
                  // menuPortalTarget={document.body}
                  data-theme={isDark}
                  placeholder="Select Task Priority"
                  name="taskPriority"
                  options={options?.map((res, index) => ({
                    label: (
                      <div>
                        {res.label === "Critical" && (
                          <img
                            src={criticalImage}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res.label === "Highest" && (
                          <img
                            src={highestImage}
                            alt="Highest"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res.label === "Medium" && (
                          <img
                            src={mediumImage}
                            alt="Medium"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res.label === "Low" && (
                          <img
                            src={lowImage}
                            alt="Low"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res.label}
                      </div>
                    ),
                    value: res.value,
                  }))}
                  // formatOptionLabel={formatOptionLabel}
                  components={{ Control: CustomTasksPriorityEditorControl }}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setSelectedTaskPriority(selectedOption.value);
                      const data = {
                        taskId: task?.id,
                        fieldName: "taskPriorityId",
                        fieldValues: getIdByPriorityName(selectedOption.value),
                      };
                      updateTask(data);
                      updateRowParameterById(task?.id, "taskPriority", {
                        id: getIdByPriorityName(selectedOption.value),
                        priorityName: selectedOption.value,
                      });
                    }
                  }}
                />
              </div>
            </li>
          ) : (
            <>
              <div
                data-tooltip-id="pri-lap-perm-tlt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                {taskPriorityList.map((res, index) => {
                  if (
                    res?.toLowerCase() ===
                    task?.taskPriority?.priorityName?.toLowerCase()
                  ) {
                    return (
                      <img
                        key={index}
                        src={
                          res?.toLowerCase() === "critical"
                            ? criticalImage
                            : res?.toLowerCase() === "highest"
                            ? highestImage
                            : res?.toLowerCase() === "medium"
                            ? mediumImage
                            : res?.toLowerCase() === "low"
                            ? lowImage
                            : lowImage
                        }
                        alt={res}
                        style={{
                          width: 20,
                          marginLeft: 5,
                          marginRight: 0,
                          marginTop: 1,
                        }}
                      />
                    );
                  }
                  return null;
                })}
                <div
                  className={`${
                    task?.taskPriority?.priorityName ? "ms-1" : ""
                  }`}
                  style={{
                    color: "blue !important",
                    fontSize: "14px",
                    fontWeight: "300",
                  }}
                >
                  {task?.taskPriority?.priorityName
                    ? task?.taskPriority?.priorityName
                    : "Priority Not Set"}
                </div>
                <ReactTooltip
                  id={`pri-lap-perm-tlt`}
                  place="top"
                  content={() => (
                    <div>You don't have permission to update this field.</div>
                  )}
                />
              </div>
            </>
          )}
          <li className="h-100 task-situation-filter-top-wrap-select">
            {canEdit ? (
              <div className="h-100 form-group task-detail-form-group">
                <Select
                  className={`h-100 td-basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                    selectedTaskType
                      ? "filter-dropdown-task-detail-selected-value"
                      : "filter-dropdown-not-selected-value"
                  }`}
                  classNamePrefix="select"
                  value={{
                    label: selectedTaskType,
                    value: selectedTaskType,
                  }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={false}
                  styles={customStyles}
                  menuPlacement="auto" // Adjust placement based on available space
                  menuPosition="fixed" // Use fixed positioning to ensure visibility
                  // menuPortalTarget={document.body}
                  data-theme={isDark}
                  placeholder="Select Task Type"
                  name="color"
                  options={taskTypesList
                    ?.filter(
                      (res) =>
                        res.toLocaleLowerCase() !==
                        selectedTaskType.toLocaleLowerCase()
                    )
                    .map((res, index) => ({
                      label: (
                        <div>
                          {res === "New Task" && (
                            <img
                              src={newIcon}
                              alt="New Task"
                              width={20}
                              height={20}
                              className="me-1"
                            />
                          )}
                          {res === "Bug" && (
                            <img
                              src={bugIcon}
                              alt="Bug"
                              width={20}
                              height={20}
                              className="me-1"
                            />
                          )}
                          {res === "Issue" && (
                            <img
                              src={errorIcon}
                              alt="Issue"
                              width={20}
                              height={20}
                              className="me-1"
                            />
                          )}
                          {res}
                        </div>
                      ),
                      value: res,
                    }))}
                  components={{ Control: CustomTasksTypeEditorControl }}
                  onChange={(e) => {
                    setSelectedTaskType(e.value);
                    const data = {
                      taskId: task?.id,
                      fieldName: "taskTypeId",
                      fieldValues: getIdByTypeName(e.value),
                    };
                    updateRowParameterById(task?.id, "taskType", {
                      id: getIdByTypeName(e.value),
                      typeName: e.value,
                    });
                    updateTask(data);
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id="situation-lap-perm-tlt"
                style={{ height: "100%", display: "flex", alignItems: "center" }}
              >
                {task?.taskType?.length === 0 ? (
                  <img
                    key={"new"}
                    src={mediumImage}
                    alt={"new task"}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 0,
                      marginTop: 1,
                    }}
                  />
                ) : (
                  taskTypesList?.map((res, index) => {
                    if (
                      res?.toLowerCase() ===
                      task?.taskType?.typeName?.toLowerCase()
                    ) {
                      return res?.toLowerCase() !== "issue" ? (
                        <img
                          key={index}
                          src={
                            res?.toLowerCase() === "bug"
                              ? bugIcon
                              : res?.toLowerCase() === "error"
                              ? criticalImage
                              : res?.toLowerCase() === "new task"
                              ? mediumImage
                              : mediumImage
                          }
                          alt={res}
                          style={{
                            width: 20,
                            marginLeft: 5,
                            marginRight: 0,
                            marginTop: 1,
                          }}
                        />
                      ) : (
                        <Icon
                          icon="pajamas:issue-close"
                          width={20}
                          height={20}
                          style={{ marginLeft: "5px" }}
                        />
                      );
                    }
                    return null;
                  })
                )}
                <div
                  className="ms-1"
                  style={{
                    color: "blue !important",
                    fontSize: "14px",
                    fontWeight: "300",
                  }}
                >
                  {!task?.taskType ? "Type Not Set" : task?.taskType?.typeName}
                </div>
                <ReactTooltip
                  id={`situation-lap-perm-tlt`}
                  place="top"
                  content={() => (
                    <div>You don't have permission to update this field.</div>
                  )}
                />
              </div>
            )}
          </li>
        </ul>
      </div>
      {/* filters for tablet screen */}
      <div className="mb-2 task-main-info-list project-main-info-tablet">
        <ul className="project-list-filter-container">
          <li className="h-100">
            <TaskUserFilter
              data={task}
              setData={setTask}
              props={props}
              projectUserList={
                projectUserList?.data?.map((user) => ({ ...user })) || []
              }
              projectTypes={projectTypes}
              updateRowParameterById={updateRowParameterById}
              kanban={kanban}
              getTaskDataAfterUpdate={getTaskDataAfterUpdate}
              canEdit={canEdit}
              userId={userProfile?.userId}
              userProfile={userProfile}
              updateTaskData={updateTaskData}
              pageParams={pageParams}
              listingType={listingType}
              setUpdateLoad={setUpdateLoad}
              updateTaskMemberData={updateTaskMemberData}
              updatePhaseTaskMemberData={updatePhaseTaskMemberData}
              isCompletedTaskChanged={isCompletedTaskChanged}
              setIsCompletedTaskChanged={setIsCompletedTaskChanged}
              allowAssigntoText={true}
            />
          </li>
          <li className="h-100">
            <DatePickerSection
              canEdit={canEdit}
              isDark={isDark}
              updateTask={updateTask}
              task={task}
              time={selectedTime}
              setTime={(newTime) => setSelectedTime(newTime)}
              date={selectedDateFilter}
              setDate={(newDate) => setSelectedDateFilter(newDate)}
              selectedPrevDateFilter={selectedPrevDateFilter}
              setSelectedPrevDateFilter={setSelectedPrevDateFilter}
              apiCallToSetDate={handleClearDate}
            />
          </li>

          {canEdit ? (
            <li className="h-100 task-situation-filter-top-wrap-select">
              <div className="form-group task-detail-form-group">
                <Select
                  className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                    selectedTaskPriority
                      ? "filter-dropdown-task-detail-selected-value"
                      : ""
                  }`}
                  classNamePrefix="select"
                  value={
                    selectedTaskPriority
                      ? {
                          label: selectedTaskPriority,
                          value: selectedTaskPriority,
                        }
                      : { label: "Set Priority", value: "" }
                  }
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={false}
                  styles={customStyles}
                  data-theme={isDark}
                  menuPlacement="auto" // Adjust placement based on available space
                  menuPosition="fixed" // Use fixed positioning to ensure visibility
                  menuPortalTarget={document.body}
                  placeholder="Select Task Priority"
                  name="taskPriority"
                  options={options?.map((res, index) => ({
                    label: (
                      <div>
                        {res.label === "Critical" && (
                          <img
                            src={criticalImage}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-2"
                          />
                        )}
                        {res.label === "Highest" && (
                          <img
                            src={highestImage}
                            alt="Highest"
                            width={20}
                            height={20}
                            className="me-2"
                          />
                        )}
                        {res.label === "Medium" && (
                          <img
                            src={mediumImage}
                            alt="Medium"
                            width={20}
                            height={20}
                            className="me-2"
                          />
                        )}
                        {res.label === "Low" && (
                          <img
                            src={lowImage}
                            alt="Low"
                            width={20}
                            height={20}
                            className="me-2"
                          />
                        )}
                        {res.label}
                      </div>
                    ),
                    value: res.value,
                  }))}
                  // formatOptionLabel={formatOptionLabel}
                  components={{ Control: CustomTasksPriorityEditorControl }}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setSelectedTaskPriority(selectedOption.value);
                      const data = {
                        taskId: task?.id,
                        fieldName: "taskPriorityId",
                        fieldValues: getIdByPriorityName(selectedOption.value),
                      };
                      updateTask(data);
                      updateRowParameterById(task?.id, "taskPriority", {
                        id: getIdByPriorityName(selectedOption.value),
                        priorityName: selectedOption.value,
                      });
                    }
                  }}
                />
              </div>
            </li>
          ) : (
            <div
              data-tooltip-id="pri-tab-perm-tlt"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "15px",
              }}
            >
              {taskPriorityList.map((res, index) => {
                if (
                  res?.toLowerCase() ===
                  task?.taskPriority?.priorityName?.toLowerCase()
                ) {
                  return (
                    <img
                      key={index}
                      src={
                        res?.toLowerCase() === "critical"
                          ? criticalImage
                          : res?.toLowerCase() === "highest"
                          ? highestImage
                          : res?.toLowerCase() === "medium"
                          ? mediumImage
                          : ""
                      }
                      alt={res}
                      style={{
                        width: 20,
                        marginLeft: 5,
                        marginRight: 0,
                        marginTop: 1,
                      }}
                    />
                  );
                }
                return null;
              })}
              <div
                className={`${task?.taskPriority?.priorityName ? "ms-1" : ""}`}
                style={{
                  color: "blue !important",
                  fontSize: "14px",
                  fontWeight: "300",
                }}
              >
                {task?.taskPriority?.priorityName
                  ? task?.taskPriority?.priorityName
                  : "Priority Not Set"}
              </div>
              <ReactTooltip
                id={`pri-tab-perm-tlt`}
                place="top"
                content={() => (
                  <div>You don't have permission to update this field.</div>
                )}
              />
            </div>
          )}
          <div className="project-main-info-tablet">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="secondary"
                id="color-dropdown"
                className="no-arrow-dd "
              >
                <div className="menu-btn">
                  <Icon icon="mi:options-horizontal" />
                </div>
              </Dropdown.Toggle>{" "}
              <Dropdown.Menu>
                <Dropdown.Item
                  className="project-filter-dropdown-list"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <li className="h-100 task-situation-filter-top-wrap-select">
                    {canEdit ? (
                      <div className="form-group task-detail-form-group">
                        <Select
                          className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                            selectedTaskType
                              ? "filter-dropdown-task-detail-selected-value"
                              : "filter-dropdown-not-selected-value"
                          }`}
                          classNamePrefix="select"
                          value={{
                            label: selectedTaskType,
                            value: selectedTaskType,
                          }}
                          isDisabled={false}
                          isLoading={false}
                          menuPlacement="auto" // Adjust placement based on available space
                          menuPosition="fixed" // Use fixed positioning to ensure visibility
                          menuPortalTarget={document.body}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          styles={customStyles}
                          data-theme={isDark}
                          placeholder="Select Task Type"
                          name="color"
                          options={taskTypesList
                            ?.filter((res) => res !== selectedTaskType)
                            .map((res, index) => ({
                              label: (
                                <div>
                                  {res === "New Task" && (
                                    <img
                                      src={newIcon}
                                      alt="New Task"
                                      width={20}
                                      height={20}
                                      className="me-2"
                                    />
                                  )}
                                  {res === "Bug" && (
                                    <img
                                      src={bugIcon}
                                      alt="Bug"
                                      width={20}
                                      height={20}
                                      className="me-2"
                                    />
                                  )}
                                  {res === "Issue" && (
                                    <img
                                      src={errorIcon}
                                      alt="Issue"
                                      width={20}
                                      height={20}
                                      className="me-2"
                                    />
                                  )}
                                  {res}
                                </div>
                              ),
                              value: res,
                            }))}
                          components={{ Control: CustomTasksTypeEditorControl }}
                          onChange={(e) => {
                            setSelectedTaskType(e.value);
                            const data = {
                              taskId: task?.id,
                              fieldName: "taskTypeId",
                              fieldValues: getIdByTypeName(e.value),
                            };
                            updateRowParameterById(task?.id, "taskType", {
                              id: getIdByTypeName(e.value),
                              typeName: e.value,
                            });
                            updateTask(data);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id="situation-tab-perm-tlt"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {task?.taskType?.length === 0 ? (
                          <img
                            key={"new"}
                            src={mediumImage}
                            alt={"new task"}
                            style={{
                              width: 20,
                              marginLeft: 5,
                              marginRight: 0,
                              marginTop: 1,
                            }}
                          />
                        ) : (
                          taskTypesList?.map((res, index) => {
                            if (
                              res?.toLowerCase() ===
                              task?.taskType?.typeName?.toLowerCase()
                            ) {
                              return res?.toLowerCase() !== "issue" ? (
                                <img
                                  key={index}
                                  src={
                                    res?.toLowerCase() === "bug"
                                      ? bugIcon
                                      : res?.toLowerCase() === "error"
                                      ? criticalImage
                                      : res?.toLowerCase() === "new task"
                                      ? mediumImage
                                      : mediumImage
                                  }
                                  alt={res}
                                  style={{
                                    width: 20,
                                    marginLeft: 5,
                                    marginRight: 0,
                                    marginTop: 1,
                                  }}
                                />
                              ) : (
                                <Icon
                                  icon="pajamas:issue-close"
                                  width={20}
                                  height={20}
                                  style={{ marginLeft: "5px" }}
                                />
                              );
                            }
                            return null;
                          })
                        )}
                        <div
                          className="ms-1"
                          style={{
                            color: "blue !important",
                            fontSize: "14px",
                            fontWeight: "300",
                          }}
                        >
                          {task?.taskType?.length === 0
                            ? "New Task"
                            : task?.taskType?.typeName}
                        </div>
                        <ReactTooltip
                          id={`situation-tab-perm-tlt`}
                          place="top"
                          content={() => (
                            <div>
                              You don't have permission to update this field.
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </li>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </ul>
      </div>
      {/* filters for mobile screen */}
      <div className="mb-2 project-main-info-mobile">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            variant="secondary"
            id="color-dropdown"
            className="no-arrow-dd "
          >
            <div className="menu-btn">
              <Icon icon="mi:options-horizontal" />
            </div>
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <TaskUserFilter
                data={task}
                setData={setTask}
                props={props}
                projectUserList={
                  projectUserList?.data?.map((user) => ({ ...user })) || []
                }
                projectTypes={projectTypes}
                updateRowParameterById={updateRowParameterById}
                kanban={kanban}
                getTaskDataAfterUpdate={getTaskDataAfterUpdate}
                canEdit={canEdit}
                userId={userProfile?.userId}
                userProfile={userProfile}
                updateTaskData={updateTaskData}
                pageParams={pageParams}
                listingType={listingType}
                setUpdateLoad={setUpdateLoad}
                updateTaskMemberData={updateTaskMemberData}
                updatePhaseTaskMemberData={updatePhaseTaskMemberData}
                isCompletedTaskChanged={isCompletedTaskChanged}
                setIsCompletedTaskChanged={setIsCompletedTaskChanged}
                allowAssigntoText={true}
              />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="project-filter-dropdown-list"
            >
              <DatePickerSection
                canEdit={canEdit}
                isDark={isDark}
                updateTask={updateTask}
                task={task}
                time={selectedTime}
                setTime={(newTime) => setSelectedTime(newTime)}
                date={selectedDateFilter}
                setDate={(newDate) => setSelectedDateFilter(newDate)}
                selectedPrevDateFilter={selectedPrevDateFilter}
                setSelectedPrevDateFilter={setSelectedPrevDateFilter}
                apiCallToSetDate={handleClearDate}
              />
            </Dropdown.Item>

            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {canEdit ? (
                <li className="h-100 task-situation-filter-top-wrap-select">
                  <div className="form-group task-detail-form-group">
                    <Select
                      className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                        selectedTaskPriority
                          ? "filter-dropdown-task-detail-selected-value"
                          : ""
                      }`}
                      classNamePrefix="select"
                      value={
                        selectedTaskPriority
                          ? {
                              label: selectedTaskPriority,
                              value: selectedTaskPriority,
                            }
                          : { label: "Set Priority", value: "" }
                      }
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      menuPlacement="auto" // Adjust placement based on available space
                      menuPosition="fixed" // Use fixed positioning to ensure visibility
                      menuPortalTarget={document.body}
                      isSearchable={false}
                      styles={customStyles}
                      data-theme={isDark}
                      placeholder="Select Task Priority"
                      name="taskPriority"
                      options={options?.map((res, index) => ({
                        label: (
                          <div>
                            {res.label === "Critical" && (
                              <img
                                src={criticalImage}
                                alt="Critical"
                                width={20}
                                height={20}
                                className="me-2"
                              />
                            )}
                            {res.label === "Highest" && (
                              <img
                                src={highestImage}
                                alt="Highest"
                                width={20}
                                height={20}
                                className="me-2"
                              />
                            )}
                            {res.label === "Medium" && (
                              <img
                                src={mediumImage}
                                alt="Medium"
                                width={20}
                                height={20}
                                className="me-2"
                              />
                            )}
                            {res.label === "Low" && (
                              <img
                                src={lowImage}
                                alt="Low"
                                width={20}
                                height={20}
                                className="me-2"
                              />
                            )}
                            {res.label}
                          </div>
                        ),
                        value: res.value,
                      }))}
                      components={{ Control: CustomTasksPriorityEditorControl }}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setSelectedTaskPriority(selectedOption.value);
                          const data = {
                            taskId: task?.id,
                            fieldName: "taskPriorityId",
                            fieldValues: getIdByPriorityName(
                              selectedOption.value
                            ),
                          };
                          updateTask(data);
                          updateRowParameterById(task?.id, "taskPriority", {
                            id: getIdByPriorityName(selectedOption.value),
                            priorityName: selectedOption.value,
                          });
                        }
                      }}
                    />
                  </div>
                </li>
              ) : (
                <div
                  data-tooltip-id="sit-mob-perm-tlt"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  {taskPriorityList.map((res, index) => {
                    if (
                      res?.toLowerCase() ===
                      task?.taskPriority?.priorityName?.toLowerCase()
                    ) {
                      return (
                        <img
                          key={index}
                          src={
                            res?.toLowerCase() === "critical"
                              ? criticalImage
                              : res?.toLowerCase() === "highest"
                              ? highestImage
                              : res?.toLowerCase() === "medium"
                              ? mediumImage
                              : ""
                          }
                          alt={res}
                          style={{
                            width: 20,
                            marginLeft: 5,
                            marginRight: 0,
                            marginTop: 1,
                          }}
                        />
                      );
                    }
                    return null;
                  })}
                  <div
                    className={`${
                      task?.taskPriority?.priorityName ? "ms-1" : ""
                    }`}
                    style={{
                      color: "blue !important",
                      fontSize: "14px",
                      fontWeight: "300",
                    }}
                  >
                    {task?.taskPriority?.priorityName
                      ? task?.taskPriority?.priorityName
                      : "Priority Not Set"}
                  </div>
                  <ReactTooltip
                    id={`sit-mob-perm-tlt`}
                    place="top"
                    content={() => (
                      <div>You don't have permission to update this field.</div>
                    )}
                  />
                </div>
              )}
            </Dropdown.Item>
            <Dropdown.Item
              className="project-filter-dropdown-list"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <li className="h-100 task-situation-filter-top-wrap-select">
                {canEdit ? (
                  <div className="form-group task-detail-form-group">
                    <Select
                      className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status ${
                        selectedTaskType
                          ? "filter-dropdown-task-detail-selected-value"
                          : "filter-dropdown-not-selected-value"
                      }`}
                      classNamePrefix="select"
                      value={{
                        label: selectedTaskType,
                        value: selectedTaskType,
                      }}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      menuPlacement="auto" // Adjust placement based on available space
                      menuPosition="fixed" // Use fixed positioning to ensure visibility
                      menuPortalTarget={document.body}
                      styles={customStyles}
                      data-theme={isDark}
                      placeholder="Select Task Type"
                      name="color"
                      options={taskTypesList
                        ?.filter((res) => res !== selectedTaskType)
                        .map((res, index) => ({
                          label: (
                            <div>
                              {res === "New Task" && (
                                <img
                                  src={newIcon}
                                  alt="New Task"
                                  width={20}
                                  height={20}
                                  className="me-2"
                                />
                              )}
                              {res === "Bug" && (
                                <img
                                  src={bugIcon}
                                  alt="Bug"
                                  width={20}
                                  height={20}
                                  className="me-2"
                                />
                              )}
                              {res === "Issue" && (
                                <img
                                  src={errorIcon}
                                  alt="Issue"
                                  width={20}
                                  height={20}
                                  className="me-2"
                                />
                              )}
                              {res}
                            </div>
                          ),
                          value: res,
                        }))}
                      components={{ Control: CustomTasksTypeEditorControl }}
                      onChange={(e) => {
                        setSelectedTaskType(e.value);
                        const data = {
                          taskId: task?.id,
                          fieldName: "taskTypeId",
                          fieldValues: getIdByTypeName(e.value),
                        };
                        updateRowParameterById(task?.id, "taskType", {
                          id: getIdByTypeName(e.value),
                          typeName: e.value,
                        });
                        updateTask(data);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id="situ-mob-perm-tlt"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {task?.taskType?.length === 0 ? (
                      <img
                        key={"new"}
                        src={mediumImage}
                        alt={"new task"}
                        style={{
                          width: 20,
                          marginLeft: 5,
                          marginRight: 0,
                          marginTop: 1,
                        }}
                      />
                    ) : (
                      taskTypesList?.map((res, index) => {
                        if (
                          res?.toLowerCase() ===
                          task?.taskType?.typeName?.toLowerCase()
                        ) {
                          return res?.toLowerCase() !== "issue" ? (
                            <img
                              key={index}
                              src={
                                res?.toLowerCase() === "bug"
                                  ? bugIcon
                                  : res?.toLowerCase() === "error"
                                  ? criticalImage
                                  : res?.toLowerCase() === "new task"
                                  ? mediumImage
                                  : mediumImage
                              }
                              alt={res}
                              style={{
                                width: 20,
                                marginLeft: 5,
                                marginRight: 0,
                                marginTop: 1,
                              }}
                            />
                          ) : (
                            <Icon
                              icon="pajamas:issue-close"
                              width={20}
                              height={20}
                              style={{ marginLeft: "5px" }}
                            />
                          );
                        }
                        return null;
                      })
                    )}
                    <div
                      className="ms-1"
                      style={{
                        color: "blue !important",
                        fontSize: "14px",
                        fontWeight: "300",
                      }}
                    >
                      {task?.taskType?.length === 0
                        ? "New Task"
                        : task?.taskType?.typeName}
                    </div>
                    <ReactTooltip
                      id={`situ-mob-perm-tlt`}
                      place="top"
                      content={() => (
                        <div>
                          You don't have permission to update this field.
                        </div>
                      )}
                    />
                  </div>
                )}
              </li>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

export default TaskDetailFilterSection;

const DatePickerSection = ({
  canEdit,
  isDark,
  task,
  updateTask,
  date,
  time,
  setDate,
  setTime,
  selectedPrevDateFilter,
  setSelectedPrevDateFilter,
  apiCallToSetDate,
  heightFlex
}) => {
  const { isMobile } = useIsMobile();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const sectionRef = useRef();
  const { userProfile } = useSelector((state) => state?.profile);

  const iconRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleIconClick = () => {
    if (canEdit) {
      // setShowDropdown(!showDropdown);
      setIsClicked(true);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    if (isMobile) return; // Don't apply the listener on mobile

    const handleClickOutsideUpdate = async (event) => {
      console.log("event", event);

      if (event) {
        console.log("event", "if event", event);

        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        } else if (
          sectionRef.current &&
          sectionRef.current.contains(event.target) &&
          canEdit
        ) {
          setShowDropdown((prev) => !prev);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutsideUpdate);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideUpdate);
  }, [isMobile, isClicked]);

  const handleUpdateDeadline = async (date, time) => {
    console.log("date", "1489", date, time);
    if (date && time) {
      if (new Date(date)) {
        const combinedDateTime = `${moment(date).format(
          "YYYY-MM-DD"
        )}T${time}:00.000Z`;

        const data = {
          taskId: task?.id,
          fieldName: "taskDeadline",
          fieldValues: moment(combinedDateTime, "YYYY-MM-DD HH:mm")
            .clone() // Clone to avoid mutation of the original moment object
            .tz(userProfile?.timeZone, true) // true ensures we keep the exact same time, not adjusting it to the timezone offset
            .utc()
            .toISOString(),
        };
        console.log(
          "date update and call the api",
          selectedPrevDateFilter !== combinedDateTime,
          selectedPrevDateFilter,
          combinedDateTime
        );

        if (selectedPrevDateFilter !== combinedDateTime) {
          await updateTask(data);
          setSelectedPrevDateFilter(combinedDateTime);
        }

        setDate(date);
        setTime(time);
      }
    }
  };

  const handleClickOutside = async () => {
    console.log("clicked outside");
    await handleUpdateDeadline(date, time);
  };
  useEffect(() => {
    if (!showDropdown) {
      handleUpdateDeadline(date, time); // ✅ Update date when dropdown closes
    }
  }, [showDropdown]);

  const [dropdownPosition, setDropdownPosition] = useState(null);

  useEffect(() => {
    if (showDropdown) {
      const rect = sectionRef?.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
  }, [showDropdown]);

  return (
    <section ref={sectionRef} className="h-100">
      {canEdit ? (
        <div
          data-theme={isDark}
          onClick={handleIconClick}
          className="h-100 project-details-main-date-filter d-flex align-items-center justify-content-between position-relative"
        >
          {/* Icon */}
          <div
            className={`h-100 d-flex align-items-center td-selected-item-date-detail`}
          >
            <span>
              <Icon icon="lets-icons:date-range" />
            </span>
            <p
              className="p-0 m-0"
              style={{ fontWeight: 300, fontSize: "13px" }}
            >
              {date ? formatCustomDate(date, time) : "Due Date"}
            </p>
            {canEdit && (
              <>
                {date && (
                  <div
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                      padding: "0px 3px",
                    }}
                    ref={iconRef}
                    className="select-icon me-1 select-icon-date"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      apiCallToSetDate();
                    }}
                  >
                    <IoMdClose
                      style={{ cursor: "pointer", color: "#343131" }}
                    />
                  </div>
                )}
                <div
                  className="select-icon me-1 select-icon-date"
                  style={{
                    transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  <IoIosArrowDown />
                </div>
              </>
            )}
          </div>
          {/* Dropdown */}
          {showDropdown && dropdownPosition && (
            <Portal>
              <div
                ref={dropdownRef}
                className="custom-date-input-select"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowDropdown(true);
                }}
                style={{
                  position: "absolute",
                  zIndex: 1050,
                  top: dropdownPosition.top + 8 + "px",
                  left: dropdownPosition.left + "px",
                }}
              >
                <DateTimePicker
                  isOpen={showDropdown}
                  setIsOpen={setShowDropdown}
                  time={time}
                  setTime={(newTime) => setTime(newTime)}
                  date={date}
                  setDate={(newDate) => setDate(newDate)}
                  isClickAway={false}
                />
              </div>
            </Portal>
          )}
        </div>
      ) : (
        <div
          className={`d-flex align-items-center selected-item-date-detail-no-edit`}
        >
          <span>
            <Icon icon="lets-icons:date-range" />
          </span>
          <p className="p-0 m-0" style={{ fontWeight: 300, fontSize: "13px" }}>
            {date ? formatCustomDate(date, time) : "Due Date"}
          </p>
          {canEdit && (
            <div className="select-icon me-1 select-icon-date">
              <IoIosArrowDown />
            </div>
          )}
        </div>
      )}
    </section>
  );
};
