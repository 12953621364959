import "../styles/style.css";
import ConfirmUpdatePSModal from "../modals/ConfirmUpdatePSModal";
import usePSMainService from "../services/usePSMainService";
import PSMainHeader from "./PSMainHeader";
import PSKPIMainCard from "./PSKPIMainCard";
import AddKPIOffCanvas from "../canvas/AddKPIOffCanvas";
import { ShimmerBadge, ShimmerTitle } from "react-shimmer-effects";

export default function PerformanceSystemMain({ isDark }) {
  const {
    isShowUpdateModal,
    companyPerformanceTemplates,
    isLoading,

    MotionWrapper,
    isShowAddNewKPI,
    handleUpdateIsShowAddNewKPI,
    updateKPIId,
    handleSetUpdateKPIId,
    animationVariants,
  } = usePSMainService();

  return (
    <div className="pb-2 ">
      {/* heading and button */}
      <PSMainHeader
        isDark={isDark}
        handleUpdateIsShowAddNewKPI={handleUpdateIsShowAddNewKPI}
      />

      {/* kpi card list */}
      {isLoading ? (
        <div>
          <ShimmerBadge width={200} />
          <ShimmerTitle line={3} variant="secondary" />
        </div>
      ) : (
        companyPerformanceTemplates?.data?.map((res, id) => (
          <PSKPIMainCard
            data={res}
            isDark={isDark}
            handleSetUpdateKPIId={handleSetUpdateKPIId}
            handleUpdateIsShowAddNewKPI={handleUpdateIsShowAddNewKPI}
          />
        ))
      )}

      <AddKPIOffCanvas
        show={isShowAddNewKPI}
        handleCloseModal={() => {
          handleUpdateIsShowAddNewKPI(false);
          handleSetUpdateKPIId(null);
        }}
        isDark={isDark}
        MotionWrapper={MotionWrapper}
        animationVariants={animationVariants}
        updateKPIId={updateKPIId}
      />
    </div>
  );
}
