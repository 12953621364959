import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Modal } from "react-bootstrap";
import { defaultStyles, FileIcon } from "react-file-icon";
import { getFileType } from "../../../helper/getFileType";

const AttachmentsModal = ({
  show,
  isDark,
  handleCloseModal,
  files,
  canEdit,
  handleThumbnailClick,
  handleDeleteFile,
  handleDownloadDocFiles,
  handleFileClick,
}) => {
  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="attachment-task-modal-main"
    >
      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          Attachment <span>({files?.length})</span>
        </p>
        <button className="custom-close-button" onClick={handleCloseModal}>
          <Icon icon="maki:cross" />
        </button>
      </div>
      <Modal.Body>
        <div className="attachment-files-main-wrap-list">
          {files?.map((attachment, index) => (
            <div
              className="file-attachment-wrap"
              // onClick={() => handleThumbnailClick(attachment)}
            >
              <div className="file-attachment-icon">
                {attachment &&
                  getFileType(attachment.attachmentName) === "image" && (
                    <img
                      key={attachment?.id}
                      className="thumbnail"
                      src={attachment?.attachmentPath}
                      alt={attachment?.attachmentName}
                      onClick={() => handleThumbnailClick(attachment)}
                    />
                  )}{" "}
                {attachment &&
                  getFileType(attachment?.attachmentName) !== "image" && (
                    <FileIcon
                      extension={getFileType(attachment?.attachmentName)}
                      {...defaultStyles[
                        getFileType(attachment?.attachmentName)
                      ]}
                    />
                  )}
              </div>
              {canEdit && (
                <button className="delete-file-button">
                  <Icon
                    icon="basil:cross-outline"
                    size={24}
                    onClick={() => handleDeleteFile(attachment)}
                  />
                </button>
              )}
              <div className="d-flex flex-column justify-content-center">
                <h6>
                  {attachment?.attachmentName &&
                  attachment.attachmentName.length > 15
                    ? attachment.attachmentName.substring(0, 15) + "..."
                    : attachment.attachmentName}
                </h6>
                <div className="d-flex align-items-center gap-2 view-download-container">
                  <p onClick={() => handleFileClick(attachment)}>View</p>
                  <span></span>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDownloadDocFiles(
                        attachment?.attachmentPath,
                        getFileType(attachment?.attachmentPath)
                      );
                    }}
                  >
                    Download
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AttachmentsModal;
