import React, { useEffect, useRef, useState } from "react";
import CloseBtn from "../../../assets/icon-close.svg";
import { Modal, Offcanvas, Spinner } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import MultiSelect from "../../../components/MultiSelect";
import { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import P1Icon from "../../../assets/project1.svg";
import P2Icon from "../../../assets/project2.svg";
import P3Icon from "../../../assets/project3.svg";
import P4Icon from "../../../assets/project4.svg";
import P5Icon from "../../../assets/project5.svg";
import InfoIcon from "../../../assets/info.svg";
import PhotoIcon from "../../../assets/photo.svg";
import { isPermitted } from "../../../helper/isPermitted";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { v4 as uuidv4 } from "uuid";
import { ShimmerButton } from "react-shimmer-effects";
import {
  useUpdateTeamDataMutation,
  useUpdateTeamIconMutation,
  useAddTeamMemberMutation,
  useRemoveTeamMemberMutation,
} from "../../../slices/teams/teamsSlice";

const EditTeam = (props) => {
  const [updateTeamData] = useUpdateTeamDataMutation();
  const [updateTeamIcon] = useUpdateTeamIconMutation();
  const [addTeamMember] = useAddTeamMemberMutation();
  const [removeTeamMember] = useRemoveTeamMemberMutation();
  const [check, setCheck] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [projProfile, setProjProfile] = useState(0);
  const [projProfileImg, setProjProfileImg] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [fileLoad, setFileLoad] = useState(false);
  const [teamLoad, setTeamLoad] = useState(false);

  const handleModalClose = () => {
    setShowCropModal(false);
    setCropper(null); // Clean up Cropper instance
  };

  const imageOptions = [
    {
      id: 0,
      url: P1Icon,
    },
    {
      id: 1,
      url: P2Icon,
    },
    {
      id: 2,
      url: P3Icon,
    },
    {
      id: 3,
      url: P4Icon,
    },
    {
      id: 4,
      url: P5Icon,
    },
  ];

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the first selected file
    // Check if a file is selected
    if (file) {
      setShowCropModal(true);
      // Check if the file type is an image
      if (!file.type.startsWith("image/")) {
        // Display an error message
        toast.error("You can only select image files.", {
          position: toast.POSITION.TOP_CENTER,
        });

        // Optionally clear the selected file input
        event.target.value = null;
        return;
      }

      // Assuming file is an image
      setFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setProjProfileImg(reader.result); // Set the selected image as projProfile
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Initialize Cropper when the modal opens and an image is selected
    if (showCropModal && file && imageRef.current) {
      const image = imageRef.current;
      image.src = URL.createObjectURL(file);
      setCropper(
        new Cropper(image, {
          // Optional cropping options
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    // Cleanup Cropper when the modal closes or component unmounts
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCropModal, file, imageRef, setCropper]);

  const handleImageUpload = async (image) => {
    setFileLoad(true);
    const croppedCanvas = cropper.getCroppedCanvas();
    await croppedCanvas.toBlob(async (blob) => {
      const fileNew = new File([blob], `team-icon-${uuidv4()}.jpg`, {
        type: blob.type,
      });
      // Use the blob here, e.g., for upload, display, or download
      if (fileNew) {
        // Assuming file is an image
        setFile(fileNew);
        const data = {
          logo: fileNew,
          teamId: props.id,
        };

        await updateImageData(data);
        const reader = new FileReader();
        reader.onload = () => {
          setProjProfileImg(reader.result); // Set the selected image as projProfile
        };
        reader.readAsDataURL(fileNew);
      }
      // Handle API response (e.g., update profile image URL)
    });
  };

  const validationSchema = Yup.object().shape({
    teamName: Yup.string().required("Team name is required"),
    teamLink: Yup.string()
      .nullable() // Allow null value
      .test("is-url", "Team link must be a valid URL", function (value) {
        if (
          value &&
          value.trim() !== "" &&
          !Yup.string().url().isValidSync(value)
        ) {
          return false;
        }
        return true;
      }),
    teamMembers: Yup.array()
      .required("At least one team member is required")
      .min(1, "At least one person must be invited"),
  });

  const getTeamData = async () => {
    setTeamLoad(true);
    try {
      const response = await ProjectAPI.post("teams/detail", {
        teamId: props.id,
      });
      if (response.status === 201) {
        setTeamData(response?.data?.data[0]);
        setCheck(response?.data?.data[0]?.teamInfo?.joinPrivacy);
        setPrevSelectedProjects(
          response?.data?.data[0]?.projects?.map((proj) => ({
            id: proj.id,
            value: proj.projectName,
            label: proj.projectName,
          }))
        );
        setPrevSelectedTeams(
          response?.data?.data[0]?.teamMembersDetails?.users?.map((member) => ({
            id: member.userId,
            value: member.userName,
            label: member.userName,
          }))
        );
        if (
          !["0", "1", "2", "3", "4"].includes(
            response?.data?.data[0]?.teamInfo?.teamIcon
          )
        ) {
          setProjProfileImg(response?.data?.data[0]?.teamInfo?.teamIcon);
          setProjProfile(5);
        } else {
          setProjProfile(parseInt(response?.data?.data[0]?.teamInfo?.teamIcon));
        }
      }
    } catch (error) {
    } finally {
      setTeamLoad(false);
    }
  };

  useEffect(() => {
    getTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const teamMembers =
    teamData &&
    teamData?.teamMembersDetails?.users?.map((member) => ({
      id: member.userId,
      value: member.userName,
      label: member.userName,
    }));

  const projectData =
    teamData &&
    teamData?.projects?.map((proj) => ({
      id: proj.id,
      value: proj.projectName,
      label: proj.projectName,
    }));

  const initialValues = {
    teamName: teamData?.teamInfo?.teamName || "",
    teamLink: teamData?.teamInfo?.teamLink || "",
    teamMembers: teamMembers,
    projects: projectData,
  };

  // Update data other then members
  const updateData = async (data) => {
    try {
      await updateTeamData(data).unwrap();
      toast.success("Team updated successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 200,
        closeButton: false,
      });
    } finally {
      setShowCropModal(false);
      setFileLoad(false);
    }
    // try {
    //   const response = await ProjectAPI.post("teams/update", data);
    //   if (response?.status === 201) {
    //     const updatedProject = response?.data?.team;
    //     // Extract teamInfo contents
    //     const { teamInfo, ...rest } = updatedProject[0];
    //     // Rename teamMembersDetails to teamMembers
    //     const {
    //       teamMembersDetails: teamMembers,
    //       ...restWithoutTeamMembersDetails
    //     } = rest;
    //     // Construct the modified object
    //     const modifiedObject = {
    //       ...restWithoutTeamMembersDetails,
    //       ...teamInfo,
    //       teamMembers: teamMembers.users,
    //     };
    //     const updatedListToFilter = props.teamToFilterList.map((item) =>
    //       item.id === modifiedObject.id ? modifiedObject : item
    //     );
    //     toast.success("Team updated successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     props.setTeamToFilterList(updatedListToFilter);
    //   }
    // } catch (error) {
    //   // Handle error if needed
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 200,
    //     closeButton: false,
    //   });
    // } finally {
    //   // Any cleanup code can go here
    //   setShowCropModal(false);
    //   setFileLoad(false);
    // }
  };

  // Update image data
  const updateImageData = async (data) => {
    try {
      await updateTeamIcon(data).unwrap();
      toast.success("Team icon updated successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowCropModal(false);
      setFileLoad(false);
    }
    // try {
    //   const formData = new FormData();
    //   formData.append("logo", data.logo);
    //   formData.append("teamId", data.teamId);
    //   const response = await ProjectAPI.post(
    //     "teams/update-team-icon",
    //     formData
    //   );
    //   if (response?.status === 201) {
    //     const updatedProject = response?.data?.team;
    //     // Extract teamInfo contents
    //     const { teamInfo, ...rest } = updatedProject[0];
    //     // Rename teamMembersDetails to teamMembers
    //     const {
    //       teamMembersDetails: teamMembers,
    //       ...restWithoutTeamMembersDetails
    //     } = rest;
    //     // Construct the modified object
    //     const modifiedObject = {
    //       ...restWithoutTeamMembersDetails,
    //       ...teamInfo,
    //       teamMembers: teamMembers.users,
    //     };
    //     const updatedListToFilter = props.teamToFilterList.map((item) =>
    //       item.id === modifiedObject.id ? modifiedObject : item
    //     );
    //     toast.success("Team updated successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     props.setTeamToFilterList(updatedListToFilter);
    //   }
    // } catch (error) {
    //   // Handle error if needed
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // } finally {
    //   // Any cleanup code can go here
    //   setShowCropModal(false);
    //   setFileLoad(false);
    // }
  };

  // Project add and delete
  const [prevSelectedProjects, setPrevSelectedProjects] = useState([]);

  // Team add and delete
  const [prevSelectedTeams, setPrevSelectedTeams] = useState([]);

  const handleTeamChange = async (selectedOptions) => {
    const currentSelectedTeams = selectedOptions.map((option) => option);

    let added = false;
    let removed = false;
    let addedTeamId = null;
    let removedTeamId = null;

    // Get the IDs of the current and previous selected teams
    const currentTeamIds = currentSelectedTeams.map((team) => team.id);
    const prevTeamIds = prevSelectedTeams.map((team) => team.id);

    // Check for added teams
    for (let team of currentSelectedTeams) {
      if (!prevTeamIds.includes(team.id)) {
        added = true;
        addedTeamId = team.id;
        break;
      }
    }

    // Check for removed teams
    for (let team of prevSelectedTeams) {
      if (!currentTeamIds.includes(team.id)) {
        removed = true;
        removedTeamId = team.id;
        break;
      }
    }

    // Execute mutation for removed teams if removed is true
    if (removed) {
      try {
        await removeTeamMember({
          teamId: props.id,
          teamMember: removedTeamId,
        }).unwrap();
        toast.success("Team member removed successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (error) {
        toast.error("Failed to remove team member", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    // Execute mutation for added teams if added is true
    if (added) {
      try {
        await addTeamMember({
          teamId: props.id,
          teamMember: addedTeamId,
        }).unwrap();
        toast.success("Team member added successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (error) {
        toast.error("Failed to add team member", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    // Update previous selected teams
    setPrevSelectedTeams(currentSelectedTeams);
  };

  return (
    <Offcanvas
      className={`off-concave-role`}
      show={props.show}
      placement="end"
      data-theme={props.isDark}
    >
      {/* <Offcanvas.Header closeButton>
    <Offcanvas.Title>Notifications</Offcanvas.Title>
  </Offcanvas.Header> */}
      <Offcanvas.Body>
        <div className="add-team-member-wraper">
          <div className="d-flex justify-content-between mb-3 create-team-modal-header-wrap">
            {" "}
            <h3>
              Edit <span>team</span>
            </h3>{" "}
          </div>
          <p>
            Make teams to work on specific project or task or even assign them a
            stage of a project.
          </p>
          {!teamLoad ? (
            <div className="listing-form-team-member">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Team name{" "}
                          <span className="ms-1 required-fields-span">*</span>
                        </label>
                        <input
                          type="text"
                          name="teamName"
                          placeholder="Team name"
                          value={values.teamName}
                          onChange={handleChange}
                          isInvalid={touched.teamName && !!errors.teamName}
                          className={`form-control ${
                            touched.teamName && errors.teamName
                              ? "is-invalid"
                              : ""
                          }`}
                          onKeyDown={async (e) => {
                            if (e.key === "Enter") {
                              if (e.target.value.trim() === "") {
                                // If the text is an empty string, revert to previous text
                                setFieldValue("teamName", teamData?.teamName);
                              }
                              e.target.blur(); // Blur the input field
                            }
                          }}
                          onBlur={async (e) => {
                            if (e.target.value.trim() === "") {
                              // If the text is an empty string, revert to previous text
                              // If the text is an empty string, revert to previous text
                              setFieldValue("teamName", teamData?.teamName);
                            } else {
                              const data = {
                                fieldName: "teamName",
                                fieldValues: e.target.value,
                                teamId: props.id,
                              };
                              updateData(data);
                            }
                          }}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="teamName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Add people to your team{" "}
                          <span className="ms-1 required-fields-span">*</span>
                        </label>
                        <div
                          className={`team-offconcave-select-wrapper ${
                            !isPermitted("teams/delete-member")
                              ? "no-multi-cross"
                              : ""
                          }`}
                        >
                          <MultiSelect
                            options={props.projectUserList}
                            isSelectAll={true}
                            placehold="Invite people"
                            noDelete={!isPermitted("teams/delete-member")}
                            noAdd={!isPermitted("teams/add-member")}
                            value={values.teamMembers}
                            isInvalid={
                              touched.teamMembers && !!errors.teamMembers
                            }
                            className={`${
                              touched.teamMembers && errors.teamMembers
                                ? "dept-err"
                                : ""
                            }`}
                            name="teamMembers"
                            menuPlacement="bottom"
                            onChange={(e) => {
                              setFieldValue("teamMembers", e);
                              handleTeamChange(e);
                            }}
                          />

                          {touched.teamMembers && errors.teamMembers && (
                            <div className="text-error-department">
                              Please select atleast one team member
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Team link to share </label>
                        <input
                          type="url"
                          name="teamLink"
                          value={values.teamLink}
                          disabled
                          onChange={handleChange}
                          isInvalid={touched.teamLink && !!errors.teamLink}
                          className={`form-control ${
                            touched.teamLink && errors.teamLink
                              ? "is-invalid"
                              : ""
                          }`}
                          onKeyDown={async (e) => {
                            if (e.key === "Enter") {
                              if (e.target.value.trim() === "") {
                                // If the text is an empty string, revert to previous text
                                setFieldValue("teamLink", teamData?.teamLink);
                              }
                              e.target.blur(); // Blur the input field
                            }
                          }}
                          onBlur={async (e) => {
                            if (e.target.value.trim() === "") {
                              // If the text is an empty string, revert to previous text
                              // If the text is an empty string, revert to previous text
                              setFieldValue("teamLink", teamData?.teamLink);
                            } else {
                              const data = {
                                fieldName: "teamLink",
                                fieldValues: e.target.value,
                                teamId: props.id,
                              };
                              updateData(data);
                            }
                          }}
                          placeholder="https://www.figma.com/file/7SV7vkt3LZ9ujGZovePKVW/Free"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="teamLink"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-center">
                      <div className="form-group">
                        <div className="rounded-2 ">
                          <div
                            className="checkbox create-task-checkbox"
                            onClick={async () => {
                              setCheck(!check);
                              const data = {
                                fieldName: "joinPrivacy",
                                fieldValues: check ? 0 : 1,
                                teamId: props.id,
                              };
                              await updateData(data);
                            }}
                          >
                            <input type="checkbox" checked={check} />
                            <label className="d-flex align-items-center">
                              <span className="ps-2 create-team-modal-check-text">
                                Anyone can join the team
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Assign Project </label>
                        <div className="team-offconcave-select-wrapper">
                          <MultiSelect
                            options={props.projectList}
                            isSelectAll={true}
                            placehold="Assign project"
                            value={values.projects}
                            isInvalid={touched.projects && !!errors.projects}
                            className={`${
                              touched.projects && errors.projects
                                ? "dept-err"
                                : ""
                            }`}
                            name="projects"
                            menuPlacement="bottom"
                            onChange={async (e) => {
                              setFieldValue("projects", e);
                              const data = {
                                fieldName: "projects",
                                fieldValues: e
                                  ?.map((item) => item.id)
                                  .join(","),
                                teamId: props.id,
                              };
                              await updateData(data);
                            }}
                          />

                          {touched.projects && errors.projects && (
                            <div className="text-error-department">
                              Please select atleast one project
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-icon-wrap">
                        <label>
                          {" "}
                          Team Icon{" "}
                          <img
                            src={InfoIcon}
                            alt="info-icon"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Team Icon"
                          />{" "}
                        </label>
                        <ul>
                          {imageOptions?.map((res, id) => (
                            <li>
                              {" "}
                              <label
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  setProjProfile(res?.id);
                                  const data = {
                                    fieldName: "teamIcon",
                                    fieldValues: res?.id.toString(),
                                    teamId: props.id,
                                  };
                                  await updateData(data);
                                }}
                              >
                                {" "}
                                <img
                                  className={`${
                                    projProfile === res?.id
                                      ? "selected-project-profile-img"
                                      : "unselected-project-profile-img"
                                  }`}
                                  src={res?.url}
                                  alt="project-icon"
                                />{" "}
                              </label>
                            </li>
                          ))}

                          <li>
                            {" "}
                            <label htmlFor="file-upload">
                              {" "}
                              <img
                                src={
                                  projProfileImg ? projProfileImg : PhotoIcon
                                }
                                alt="avatar-icon"
                              />{" "}
                            </label>
                            <input
                              type="file"
                              id="file-upload"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form-full">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
            </div>
          )}
        </div>

        <button
          className="btn-role-concave"
          onClick={() => {
            props.handleClose();
          }}
        >
          <img src={CloseBtn} alt="close-btn" />
        </button>
        {showCropModal && (
          <Modal
            show={showCropModal}
            onHide={handleModalClose}
            centered
            size="lg"
            data-theme={props?.isDark}
          >
            <Modal.Header closeButton>
              <div className="modal-heading-wrap">
                <h3>
                  {" "}
                  {/* <span>Choose</span> Your Plan{" "} */}
                  <span>Crop</span> Image{" "}
                </h3>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: "400px" }}>
              <div className="w-100 h-100">
                <img ref={imageRef} alt="crop-item" />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button className="crop-btn" onClick={handleImageUpload}>
                {fileLoad ? (
                  <Spinner border="animation" size="1rem" />
                ) : (
                  "Select"
                )}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditTeam;
