import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../../assets/info.svg";
function ConfirmChangeCompletedTaskModal({
  isDark,
  isShow,
  taskData,
  handleOnYes,
  handleOnNo,
}) {
  return (
    <Modal
      show={isShow}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" width={100} height={100} />
        </div>
        <h5>Do you want to change status of completed marked task?</h5>

        <p className="del-modal-name">{taskData?.taskName}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={handleOnYes}>
            Yes
          </button>
          <button className="w-50 delete-btn-2" onClick={handleOnNo}>
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmChangeCompletedTaskModal;
