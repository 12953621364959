import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { findObjectByContentTitle } from "../../../helper/getContentObject";

const InstallModuleModal = (props) => {
  const { modalContents } = useSelector((state) => state?.modalData);

  const verifyModalContent = findObjectByContentTitle(
    "install-module",
    modalContents?.data
  );

  return (
    <Modal
      show={props.installModal}
      onHide={() => props.setInstallModal(false)}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div className="install-modal-img">
          {/* <img src={DeleteIcon} /> */}
          <Icon icon="icon-park-solid:install"></Icon>
        </div>
        <h5>{verifyModalContent?.short_description}</h5>
        <h5>{verifyModalContent?.long_description}</h5>
        <p className="del-modal-name">
          {props?.moduleName ? props?.moduleName : "this module"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={props.handleSubmit}>
            {props.loadbtn ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Install"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={() => props.setInstallModal(false)}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InstallModuleModal;
