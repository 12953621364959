import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import NotIcon from "../../assets/404.png";
import "../EmailVerify/styles/verify.css";
import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {
  const navigate = useNavigate();
  // Now you have the token and can use it as needed
  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      const authUrl = `${process.env.REACT_APP_AUTH_URL}/login`;

      // Try opening in the same tab first
      const newWindow = window.open(authUrl);

      // If pop-up blocked, fall back to same-page redirect
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        window.location.href = authUrl;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  return (
    <div className="verify-email-main" data-theme={props.isDark}>
      <Card
        style={{ width: "100%", height: "100%", background: "transparent" }}
      >
        {/* <Card.Img src={NotIcon}></Card.Img> */}
        <Card.Body>
          <Card.Text>
            <div className="d-flex flex-column justify-content-center align-items-center page-not-wrapper">
              <img src={NotIcon} style={{ width: "60%" }} alt="404-icon" />
              <div className="btn-group-not-found">
                <button
                  onClick={() => {
                    if (!localStorage.getItem("access_token")) {
                      const authUrl = `${process.env.REACT_APP_AUTH_URL}/login`;

                      // Try opening in the same tab first
                      const newWindow = window.open(authUrl);

                      // If pop-up blocked, fall back to same-page redirect
                      if (
                        !newWindow ||
                        newWindow.closed ||
                        typeof newWindow.closed === "undefined"
                      ) {
                        window.location.href = authUrl;
                      }
                    } else {
                      navigate(`/${localStorage.getItem("company")}/profile`);
                    }
                  }}
                >
                  BACK TO HOME
                </button>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      {/* Other components or logic related to the token */}
    </div>
  );
};

export default PageNotFound;
