// src/redux/reducers.js
import {
  PROJECT_LISTING_CACHE_FETCH_REQUEST,
  PROJECT_LISTING_CACHE_FETCH_SUCCESS,
  PROJECT_LISTING_CACHE_FETCH_FAILURE,
  PROJECT_LISTING_CACHE_UPDATE_RECORD,
  PROJECT_LISTING_CACHE_DELETE_RECORD,
  PROJECT_LISTING_CACHE_ADD_RECORD,
  CLEAR_PROJECT_LISTING_CACHE,
} from "../constants/ProjectListingCacheConstants";

const initialState = {
  projectListingCacheData: {}, // Cached data will be stored as {`${limit}_${page}`: data}
  loading: false,
  error: null,
};

// Reducer to manage the state of project listing cache
const projectListingCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LISTING_CACHE_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_LISTING_CACHE_FETCH_SUCCESS:
      // Cache data based on limit and page
      const { data, limit, page } = action.payload;
      return {
        ...state,
        loading: false,
        projectListingCacheData: {
          ...state.projectListingCacheData,
          [`${limit}_${page}`]: data,
        },
      };
    case PROJECT_LISTING_CACHE_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PROJECT_LISTING_CACHE_UPDATE_RECORD:
      // Update a record in the cached data
      let updatedData = { ...state.projectListingCacheData };
      Object.keys(updatedData).forEach((key) => {
        let newUpdatedData = updatedData[key]?.data?.map((record) =>
          record?.id === action.payload.id
            ? { ...record, ...action.payload.newData }
            : record
        );
        return {
          ...updatedData[key],
          data: newUpdatedData,
        };
      });

      return {
        ...state,
        projectListingCacheData: updatedData,
      };
    case PROJECT_LISTING_CACHE_DELETE_RECORD:
      // Delete a record from the cached data
      let filteredData = { ...state.projectListingCacheData };
      Object.keys(filteredData).forEach((key) => {
        let newUpdatedData = filteredData[key]?.data?.filter(
          (record) => record.id !== action.payload
        );
        return {
          ...updatedData[key],
          data: newUpdatedData,
        };
      });

      return {
        ...state,
        projectListingCacheData: filteredData,
      };
    case PROJECT_LISTING_CACHE_ADD_RECORD:
      // Add a record to the cached data
      const cacheKey = `${action.payload.limit}_${action.payload.page}`;
      const newData = [
        action.payload.data,
        ...(state.projectListingCacheData[cacheKey] || []),
      ];
      return {
        ...state,
        projectListingCacheData: {
          ...state.projectListingCacheData,
          [cacheKey]: newData,
        },
      };

    case CLEAR_PROJECT_LISTING_CACHE:
      return {
        ...state,
        projectListingCacheData: {}, // Cached data will be stored as {`${limit}_${page}`: data}
        loading: false,
      };
    default:
      return state;
  }
};

export default projectListingCacheReducer;
