import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import {
  ListofTaskMemberCellRenderer,
  ProjectInfoRenderer,
  ProjectNameTooltip,
  SituationCellRenderer,
  TaskStatusInfoRenderer,
} from "./utils/data";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import progressImage from "../../../assets/in-progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import criticalImage from "../../../assets/critical.svg";
import RepeatIcon from "../../../assets/repeat-task-icon.png";
import deleteIcon from "../../../assets/circle-delete.png";
import NotStarted from "../../../assets/not-started.svg";
import { IoIosArrowDown } from "react-icons/io";
import DropButton from "../../Team/components/DropButton";
import { createPortal } from "react-dom";
import Delete from "../../../assets/delete.svg";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import EditIcon from "../../../assets/edit-project.svg";
import {
  convertToISO,
  dateFormatter,
  formatCustomDate,
  formatDateToYYYYMMDD,
} from "../../../helper/dateFormatter";
import ConfirmDeleteTaskModal from "./ConfirmDeleteTaskModal";
import { useSelector } from "react-redux";
import TasksListingMainMembersEditor from "./TaskListingMainMemberEditor";
import bugIcon from "../../../assets/bug-icon.png";
import newIcon from "../../../assets/new-task.png";
import errorIcon from "../../../assets/issue.png";
import { isPermitted } from "../../../helper/isPermitted";
import {
  isMemberOfTask,
  isObserver,
  isOwnerInTaskOne,
  isOwnerInTasks,
} from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import NoStatusAllowedModal from "../../../components/NoStatusAllowedModal/NoStatusAllowedModal";
import { useUpdateCompletedTaskDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import { toast } from "react-toastify";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { useSearchParams } from "react-router-dom";
import { getDefaultMarkAsById } from "../../../helper/taskHelpers";
import TimerIcon from "../../../assets/overdue-timer.png";
import TimerGreenIcon from "../../../assets/overdue-green.png";
import TimelyIcon from "../../../assets/timely-completed.png";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import moment from "moment-timezone";
import { useIsMobile } from "../../../helper/windowHelper";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};
function TasksListingTable({
  props,
  boardColumnData,
  rowData,
  totalTasks,
  setRowData,
  setSelectedTask,
  selectedTask,
  filters,
  setFilters,
  setListToFilter,
  taskList,
  setTaskList,
  getStatusCounts,
  updateCountFilters,
  selectedTasks,
  setSelectedTasks,
  pageParams,
  listingType,
  setUpdating,
  userList,
  order,
  updateOrder,
  sortOrder,
  setSortOrder,
  updateTaskData,
  updateTaskMemberData,
  disableAction,
  newId,
  showTaskDetails,
  setShowTaskDetails,
  setNewId,
  getTaskList,
  setReadLoad,
  readLoad,
  setWasUnread,
}) {
  const { isMobile } = useIsMobile();
  const [updateCompletedTaskData] = useUpdateCompletedTaskDataMutation();
  const [updateTaskId, setUpdateTaskId] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const gridRef = useRef();
  const [edit, setEdit] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const optionsDropdownRef = useRef(null);
  const [paramApi, setParamApi] = useState(null);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [taskProgressOptionsList] = useState(() => {
    const baseOptions = [
      "Not Started",
      "In Progress",
      "On Hold",
      "Completed",
      "Submit For Review",
    ];

    // Conditionally add "Deleted" if listingType is COMPLETED_TASKS
    // return baseOptions;
    return listingType === "COMPLETED_TASKS"
      ? [...baseOptions, "Deleted"]
      : baseOptions;
  });

  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  // const { projectAllStatusList } = useSelector(
  //   (state) => state.projectAllStatusListMain
  // );
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );

  const { userProfile } = useSelector((state) => state?.profile);

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { projectFormList } = useSelector((state) => state.projectFormListData);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);

  const handleClose = () => {
    setShowStatusModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onRowClicked = (event) => {
    const index = selectedTasks.indexOf(event?.data?.id);
    if (index === -1) {
      // Project ID not found, add it to the array
      setSelectedTasks([...selectedTasks, event?.data?.id]);
    } else {
      setChecked(false);
      // Project ID found, remove it from the array
      const updatedProjects = [...selectedTasks];
      updatedProjects.splice(index, 1);
      setSelectedTasks(updatedProjects);
    }
  };

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });

  // Update pin
  // Update name
  const updateProject = async (data) => {
    try {
      let response;
      if (listingType === "COMPLETED_TASKS") {
        response = await updateCompletedTaskData({
          ...pageParams,
          ...data,
        }).unwrap();
      } else {
        response = await updateTaskData({
          ...pageParams,
          ...data,
        }).unwrap();
      }
      if (response?.status === "success") {
        editingCellsRef.current.clear();
        setReadLoad(false);
        // await getTaskList();
        // toast.success("Task updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
      }
    } catch (error) {
      console.log("==========error==========================");
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [checked, setChecked] = useState(false);

  // Function to check if the task can be selected
  const canBeSelected = (task) => {
    return (
      (isOwnerInTasks(rowData, task.id, userProfile?.userId) ||
        isAdmin(userProfile)) &&
      isPermitted("task/delete")
    );
  };

  const handleHeaderCheckboxChange = () => {
    const newSelectedTasks = [];
    if (!checked) {
      // Iterate through each task in the array
      taskList.forEach((task) => {
        // Check if the task can be selected
        if (canBeSelected(task)) {
          // If yes, add its ID to the selectable tasks array
          const index = selectedTasks.indexOf(task.id);
          if (index === -1) {
            newSelectedTasks.push(task.id);
          }
        }
      });
      // Merge newSelectedTasks with existing selectedTasks
      setSelectedTasks((prevSelectedTasks) => [
        ...prevSelectedTasks,
        ...newSelectedTasks,
      ]);
    } else {
      // Clear selected tasks
      setSelectedTasks([]);
    }
    // Toggle the checked state
    setChecked((prevChecked) => !prevChecked);
  };

  // Custom header component for checkboxes
  const SelectAllHeaderComponent = (props) => {
    return (
      <label className="control-task control-task-checkbox control-select-checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleHeaderCheckboxChange}
        />
        <div className="control_task_indicator control-select-indicator"></div>
      </label>
    );
  };

  // Date formating
  class DatePicker {
    constructor() {
      // Initialize necessary variables
      this.eInput = null;
      this.params = null;
      this.data = null;
    }

    init(params, data) {
      this.params = params;
      this.data = data; // Assuming data is directly accessible from params

      this.eInput = document.createElement("input");
      this.eInput.value = params.value || "";
      this.eInput.classList.add("ag-input");
      this.eInput.style.height = "var(--ag-row-height)";
      this.eInput.style.fontSize = "calc(var(--ag-font-size) + 1px)";

      $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: this.onSelect.bind(this),
      });
    }

    onSelect(dateText) {
      const { data } = this.params;
      if (data) {
        const updatedData = { ...data, due_date: dateText };
        this.params.api.applyTransaction({ update: [updatedData] });
      }
    }

    getGui() {
      return this.eInput;
    }

    afterGuiAttached() {
      this.eInput.focus();
      this.eInput.select();
    }

    getValue() {
      return this.eInput.value;
    }

    destroy() {}

    isPopup() {
      return false;
    }
  }

  // Date formater
  // Date formatter
  const formatDate = (dateStr) => {
    if (!dateStr) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }
    const dateString = convertToISO(dateStr);
    const [day, month, year] = dateString?.split("/").map(Number);
    const formattedDate = new Date(year, month - 1, day); // Month is 0-indexed

    const monthNames = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    // Check if formattedDate is valid
    if (isNaN(formattedDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const dateParts = formattedDate.toLocaleDateString("en-US").split("/");
    return `${dateParts[1]} ${monthNames[formattedDate.getMonth() + 1]}, ${
      dateParts[2]
    }`;
  };

  const TaskListingDueDateCellEditorHandler = (params) => {
    const datePickerRef = useRef(null);
    let isTimeModalOpen = true;
    // Use the taskDeadline directly from the API without altering it.
    const [selectedDateFilter, setSelectedDateFilter] = useState(
      params?.data?.taskDeadline
        ? new Date(params?.data?.taskDeadline?.split("T")[0])
        : null
    );
    const [selectedTime, setSelectedTime] = useState(() => {
      if (params?.data?.taskDeadline && new Date(params?.data?.taskDeadline)) {
        const hours = params?.data?.taskDeadline?.split("T")[1]?.split(":")[0];
        const minutes = params?.data?.taskDeadline
          ?.split("T")[1]
          ?.split(":")[1];
        return `${hours}:${minutes}`;
      }
      return "19:00"; // Default time
    });

    const handleDateTimeChange = () => {
      setIsDateChanged(true);
    };

    useEffect(() => {
      if (selectedTime && selectedDateFilter) {
        let updatedDate = `${formatDateToYYYYMMDD(
          selectedDateFilter
        )}T${selectedTime}:00.000Z`;

        const updatedProjectData = {
          ...params.data,
          taskDeadline: updatedDate,
        };

        const index = taskList?.findIndex(
          (project) => project.id === updatedProjectData.id
        );

        if (index !== -1) {
          const updatedListToFilter = [...taskList];
          updatedListToFilter[index] = updatedProjectData;
          setTaskList(updatedListToFilter);
        }

        params.api.applyTransaction({
          update: [updatedProjectData],
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTime, selectedDateFilter]);

    const formatDateTime = (date, time) => {
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      return `${formattedDate} - ${time}`;
    };

    const handleClick = (event) => {
      const popupEditor = document.querySelector(
        ".task-table-deadline-date-input"
      );

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    // Clear due date from item
    const handleClearDate = async () => {
      setIsDateChanged(true);
      let updatedDate = null;

      const updatedProjectData = {
        ...params.data,
        taskDeadline: updatedDate,
      };

      const index = taskList?.findIndex(
        (project) => project.id === updatedProjectData.id
      );

      if (index !== -1) {
        const updatedListToFilter = [...taskList];
        updatedListToFilter[index] = updatedProjectData;
        setTaskList(updatedListToFilter);
      }

      params.api.applyTransaction({
        update: [updatedProjectData],
      });
    };

    return (
      <div
        data-theme={props.isDark}
        className="project-details-task-table-flatpickr-container task-list-date-container position-relative"
        style={{ width: "180px", background: "red", zIndex: 9999 }}
      >
        <input
          value={
            selectedDateFilter
              ? formatDateTime(selectedDateFilter, selectedTime)
              : null
          }
          altFormat="M d, Y H:i"
          dateFormat="Y-m-d H:i"
          className="form-control input active task-table-deadline-date-input"
          placeholder="Select Due Date"
          tabIndex="0"
          autoFocus
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }} // Prevents the grid from losing focus
          readOnly
        ></input>
        <DateTimePicker
          isOpen={true}
          setIsOpen={(open) => {
            isTimeModalOpen = open;
          }}
          time={selectedTime}
          setTime={setSelectedTime}
          date={selectedDateFilter}
          setDate={setSelectedDateFilter}
          ref={datePickerRef}
          handleDateTimeChange={handleDateTimeChange}
          enableClear={params?.data?.taskDeadline}
          clearDateHandler={handleClearDate}
        />
      </div>
    );
  };

  // Function to add/remove the editing-active class
  const editingCellsRef = useRef(new Set());

  // Column definitions for task listing
  const columnData = [
    {
      field: "select", // Field name for selection
      headerName: "",
      maxWidth: 34, // Adjust width as needed
      editable: false,
      headerComponent: SelectAllHeaderComponent, // Key addition
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <label className="control-task control-task-checkbox control-select-checkbox">
            <input
              type="checkbox"
              checked={selectedTasks?.includes(params.data.id)} // Optional: for pre-selection
              onChange={() => onRowClicked(params)}
              disabled={
                !(
                  (isOwnerInTasks(
                    rowData,
                    params.data.id,
                    userProfile?.userId
                  ) ||
                    isAdmin(userProfile)) &&
                  isPermitted("task/delete")
                ) || disableAction
              }
            />
            <div className="control_task_indicator control-select-indicator"></div>
          </label>
        );
      },
    },
    {
      field: "taskName",
      headerName: "Tasks",
      // pinned: "left",
      editable: false,
      suppressMenu: true,
      width: 405,
      minWidth: 405,
      headerComponent: () => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            Tasks
            <span className="ms-1 task-count-text">{totalTasks || 0}</span>
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "custom-task-name-renderer";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += " selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      onCellClicked: (params) => {
        if (!disableAction) {
          // Check if the clicked element has the "pined-icon" class
          if (params.event.target.closest(".tasks-listing-pin-icon")) {
            return; // Prevent execution if the clicked element is the pinned icon
          }
          setSelectedTask(params.data.id);
          handleTaskDetailsShowModal();
          if (!params?.data?.isRead) {
            params.data.isRead = true;
            // Refresh the grid to reflect the changes
            params.api.refreshCells({
              rowNodes: [params.node],
              force: true,
              suppressFlash: true,
            });
            // Delay clearing cache by 2 seconds
            // setTimeout(() => {
            //   clearAllTasksCache();
            // }, 2000);
            setWasUnread(true);
          }
        }
      },
      cellRenderer: (params) => {
        return (
          <TaskNameCellRenderer
            data={params.data}
            api={params.api}
            handleEdit={handleEdit}
            row={params.rowIndex}
            showPin={hoveredRow === params.data.id}
            setEdit={setEdit}
            params={params}
            canEdit={
              (isOwnerInTasks(rowData, hoveredRow, userProfile?.userId) ||
                isAdmin(userProfile)) &&
              !disableAction
            }
            updateProject={updateProject}
            setReadLoad={setReadLoad}
          />
        );
      },
    },
    {
      field: "taskPriority",
      headerName: "Priority",
      maxWidth: 120,
      minWidth: 120,
      // headerComponent: (params) =>
      //   SortableHeaderComponent({
      //     ...params,
      //     updateOrder: updateOrder, // Pass updateOrder from props
      //     order: order,
      //     sortOrder: sortOrder,
      //     setSortOrder: setSortOrder,
      //     list: "TASK",
      //   }),
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: disableAction
        ? false
        : !isMobile &&
          ((isPermitted("task/update") &&
            isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
            isAdmin(userProfile)),

      cellEditorSelector: (params) => {
        return {
          component: TasksSelectPriorityEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: SituationCellRenderer,
    },
    {
      field: "taskMembers",
      headerName: "Assigned to",
      minWidth: 250,
      maxWidth: 250,
      suppressMenu: true,
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: disableAction
        ? false
        : !isMobile &&
          ((isPermitted("task/update") &&
            isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
            isAdmin(userProfile)),
      width: 145,
      cellEditorParams: {
        popupParent: document.querySelector("body"),
      },
      cellEditorSelector: (params) => {
        const gridElement = document.querySelector(".dashboard-main-wraper"); // Replace with your grid's root element if different
        const cellRect = params.eGridCell.getBoundingClientRect();
        const gridRect = gridElement.getBoundingClientRect();
        const spaceBelow = gridRect.bottom - cellRect.bottom;

        return {
          component: () => {
            return (
              <TasksListingMainMembersEditor
                params={params}
                isDark={props.isDark}
                userList={userList}
                projectTypes={projectTypeList}
                listingType={listingType}
                pageParams={pageParams}
                updateTaskMemberData={updateTaskMemberData}
                isOwner={isOwnerInTasks(
                  rowData,
                  hoveredRow,
                  userProfile?.userId
                )}
                getTaskList={getTaskList}
              />
            );
          },
          popup: true,
          popupPosition: spaceBelow > 314 ? "under" : "over",
        };
      },
      cellRenderer: ListofTaskMemberCellRenderer,
      onCellValueChanged: (params) => {
        setTimeout(() => {
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
            suppressFlash: true,
          });
          params.node.__editing = false;
        }, 150); // Smooth transition on value change
      },
    },
    {
      field: "taskDeadline",
      headerName: "Due Date",
      minWidth: 190,
      maxWidth: 190,
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: disableAction
        ? false
        : !isMobile &&
          ((isPermitted("task/update") &&
            isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
            isAdmin(userProfile)),
      cellRenderer: (params) => {
        const taskDeadline = params.data.taskDeadline
          ? new Date(params.data.taskDeadline)
          : ""; // Parse taskDeadline into a Date object
        const currentDate = new Date();

        return (
          <div
            className="project-date"
            style={{
              color:
                taskDeadline < currentDate &&
                params.data.taskDeadline &&
                params?.data?.taskStatus?.defaultMarkAs !== "completed"
                  ? "#ff0000"
                  : "",
            }}
          >
            {" "}
            {/* <img src={CheckIcon} alt="check-icon" />{" "}
            {params?.data?.taskDeadline ? "Due: " : ""} */}
            {params?.data?.taskDeadline
              ? formatCustomDate(
                  new Date(params?.data?.taskDeadline),
                  `${
                    moment(params?.data?.taskDeadline)
                      .toISOString()
                      ?.split("T")[1]
                      ?.split(":")[0]
                  }:${
                    moment(params?.data?.taskDeadline)
                      .toISOString()
                      ?.split("T")[1]
                      ?.split(":")[1]
                  }`
                )
              : "No deadline"}{" "}
          </div>
        );
      },
      cellEditorSelector: (params) => {
        return {
          component: (params) => {
            return TaskListingDueDateCellEditorHandler(params);
          },
          popup: true,
          popupPosition: "over",
        };
      },
      cellEditor: DatePicker,
      valueFormatter: (params) => formatDate(params.data.taskDeadline),
    },
    {
      field: "taskStatus",
      headerName: "Status",
      maxWidth: 170,
      minWidth: 170,
      // headerComponent: (params) =>
      //   SortableHeaderComponent({
      //     ...params,
      //     updateOrder: updateOrder, // Pass updateOrder from props
      //     order: order,
      //     sortOrder: sortOrder,
      //     setSortOrder: setSortOrder,
      //     list: "TASK",
      //   }),
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: (params) =>
        disableAction
          ? false
          : !isMobile &&
            ((isPermitted("task/update") &&
              (isMemberOfTask(params?.data, userProfile?.userId) ||
                isObserver(params?.data, userProfile?.userId))) ||
              isAdmin(userProfile)),
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProgressEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: (params) => {
        return TaskStatusInfoRenderer(params);
      },
    },
    {
      field: "",
      headerName: "Project",
      width: 190,
      minWidth: 190,
      editable: (params) =>
        disableAction
          ? false
          : !isMobile &&
            ((isPermitted("task/update") &&
              isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
              isAdmin(userProfile)),
      cellClass: (params) => {
        let classes = "task-project-col ";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += " selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: ProjectInfoRenderer,
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProjectEditor,
          popup: true,
          popupPosition: "over",
        };
      },
    },

    {
      field: "createdAt",
      headerName: "Created",
      editable: false,
      minWidth: 130,
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.createdAt, 3)}
            </p>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      editable: false,
      minWidth: 130,
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          selectedTasks.includes(params.data.id) ||
          hoveredRow === params.data.id ||
          !params?.data?.isRead
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.updatedAt, 3)}
            </p>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedTasks?.length === 0) {
      setChecked(false);
    }
    if (selectedTasks?.length === rowData?.length) {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTasks]);

  useEffect(() => {
    getAllProjects().catch((error) => {
      console.error("Error fetching projects:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFormList]);

  useEffect(() => {
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const handleMouseOut = (event) => {
    // Get the bounding box of the grid and options dropdown
    const gridRect = gridRef?.current?.getBoundingClientRect();
    const optionsDropdownRect =
      optionsDropdownRef?.current?.getBoundingClientRect();

    // Check if the mouse has left the grid area
    if (
      event.clientX > gridRect.right ||
      event.clientY < gridRect.top ||
      event.clientY > gridRect.bottom + 30
    ) {
      if (!showMore) {
        setHoveredMenu(null);
        setHoveredRow(null);
        setShowMore(false);
      }
      // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

      if (
        showMenu === false ||
        (optionsDropdownRect &&
          (event.clientX < optionsDropdownRect.left ||
            event.clientX > optionsDropdownRect.right ||
            event.clientY < optionsDropdownRect.top ||
            event.clientY > optionsDropdownRect.bottom))
      ) {
        setHoveredMenu(null);
        setShowMenu(false);
      }
    }
  };

  const gridReady = (params) => {
    setGridApi(params.api);
    var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color:
      // state.data.value.toString().toLowerCase() === "critical"
      //   ? "#FB3F3F"
      //   : state.data.value.toString().toLowerCase() === "highest"
      //   ? "#56BAEC"
      //   : state.data.value.toString().toLowerCase() === "medium"
      //   ? "#394B84"
      //   : state.data.value.toString().toLowerCase() === "low"
      //   ? "#2C2D2F"
      //   : state.data.value.toString().toLowerCase() === "in progress"
      //   ? "#2098D1"
      //   : state.data.value.toString().toLowerCase() === "not started"
      //   ? "black"
      //   : state.data.value.toString().toLowerCase() === "completed"
      //   ? "#2DBF64"
      //   : state.data.value.toString().toLowerCase() === "on hold"
      //   ? "#E8910D"
      //   : state.data.value.toString().toLowerCase() === "submit for review"
      //   ? "#EDA900"
      //   : state.data.value.toString().toLowerCase() === "deleted"
      //   ? "#ff0000"
      //   : " black",
      fontSize: "14px",
      fontWeight: 300,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      minWidth: "120px", // Set the desired width
    }),
  };
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("-");
    return new Date(`${month}/${year}/${day}`);
  };

  // const handleTaskDetailsCloseModal = () => {
  //   setSelectedTask(null);
  //   setNewId(null);
  //   setShowTaskDetails(false);
  // };

  const handleTaskDetailsShowModal = () => {
    setShowTaskDetails(true);
  };

  const OpenProjectCellRenderer = ({ showModal, id, setEdit, handleOpen }) => {
    return (
      <div
        className="open-target-link d-flex align-items-center justify-content-between "
        onClick={async (e) => {
          if (!disableAction) {
            e.stopPropagation();
            setSelectedTask(id);
            showModal();
            handleOpen();
          }
        }}
        onMouseOver={() => setEdit(false)}
      >
        <div>
          {" "}
          <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
        </div>
        {/* <img src={openTask} alt="Open Task" width={100} height={100} /> */}
      </div>
    );
  };

  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorities.map((res, index) => {
              if (
                res?.priorityName?.toLowerCase() ===
                props.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.priorityName?.toLowerCase() === "critical"
                        ? criticalImage
                        : res?.priorityName?.toLowerCase() === "highest"
                        ? highestImage
                        : res?.priorityName?.toLowerCase() === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectPriorityEditor = (params) => {
    const [selected, setSelected] = useState(
      params?.data?.taskPriority?.priorityName
    );

    // Icons mapping based on `defaultMarkAs`
    const priorityIcons = {
      critical: criticalImage,
      highest: highestImage,
      medium: mediumImage,
      low: lowImage,
    };

    // Filter out the selected option from the options list
    const filteredOptions = taskPriorities?.filter(
      (res) => res.priorityName !== selected // Exclude the selected option
    );

    // Handling the selection change
    const handleChange = async (e) => {
      console.log("Selected option: ", e.value);
      setSelected(e.value.priorityName); // Update local state with the selected value
      params.api.stopEditing();

      // Update the data object in AG-Grid
      params.data.taskPriority = e.value; // Update the selected priority in the params object

      const fieldStartData = {
        taskId: params?.data?.id,
        fieldName: "taskPriorityId",
        fieldValues: e.value.id,
      };

      // Call the update function (e.g., to persist the change)
      await updateProject(fieldStartData);

      // After selection, notify AG-Grid that editing is done
      params.api.stopEditing();
    };

    // Ensure the selected value is correctly reflected in the dropdown
    useEffect(() => {
      setSelected(params?.data?.taskPriority?.priorityName);
    }, [params?.data?.taskPriority?.priorityName]); // Ensure it updates when `taskPriority` changes

    // Prevent AG Grid from intercepting Enter key
    const stopEditingOnEnter = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Stop AG Grid from handling Enter
        event.stopPropagation();
        // Find the currently focused option in react-select
        const focusedOption = document.querySelector(
          ".select__menu-list .select__option--is-focused"
        );

        if (focusedOption) {
          focusedOption.click(); // Simulate a click on the focused option
        }
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", stopEditingOnEnter, true);
      return () =>
        document.removeEventListener("keydown", stopEditingOnEnter, true);
    }, [selected, props.api]);

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", width: "120px" }}
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          className={`basic-single tasks-select-status-editor task-situation-filter-top-wrap-select-div task-${selected?.toLowerCase()}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          autoFocus={true}
          menuPlacement="auto"
          name="color"
          options={filteredOptions?.map((res) => ({
            label: (
              <div className="d-flex align-items-center">
                {/* Dynamically add icons based on defaultMarkAs */}
                <img
                  src={priorityIcons[res.defaultMarkAs]} // Get the correct icon using `defaultMarkAs`
                  alt={res.priorityName}
                  width={20}
                  height={20}
                  className="me-1"
                />
                {res.priorityName}
              </div>
            ),
            value: res,
          }))}
          components={{ Control: CustomTasksPriorityEditorControl }}
          onChange={handleChange} // Update the onChange handler to handle selection
          // onKeyDown={handleKeyDown} // Detect key down (Enter) and trigger onChange
        />
      </div>
    );
  };

  const CustomTasksProgressEditorControl = ({
    children,
    taskDeadline,
    menuIsOpen,
    taskCompletedDate,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskProgressOptionsList?.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "deleted"
                        ? deleteIcon
                        : res?.toLowerCase() === "in progress"
                        ? progressImage
                        : res?.toLowerCase() === "not started"
                        ? NotStarted
                        : res?.toLowerCase() === "completed"
                        ? taskDeadline
                          ? taskDeadline < taskCompletedDate
                            ? TimerGreenIcon
                            : TimelyIcon
                          : doneImage
                        : res?.toLowerCase() === "on hold"
                        ? reviewImage
                        : res?.toLowerCase() === "submit for review" &&
                          reviewIcon
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomTasksProjectEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectOptions?.map((res, index) => {
              if (
                res?.label.toLowerCase() ===
                props?.getValue()[0]?.label?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={res?.icon?.props?.src}
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectProgressEditor = (params) => {
    const [selected, setSelected] = useState(
      params.data?.taskStatus?.statusName
    );

    const taskDeadline = params.data.taskDeadline
      ? new Date(params.data.taskDeadline)
      : ""; // Parse taskDeadline into a Date object
    const taskCompletedDate = params.data.taskCompletedDate
      ? new Date(params.data.taskCompletedDate)
      : new Date();

    // Icons mapping based on statusName
    const statusIcons = {
      "not started": NotStarted,
      "in progress": progressImage,
      "on hold": reviewImage,
      "submit for review": reviewIcon,
      completed: doneImage,
    };

    // Filter out the selected option from the options list
    const filteredOptions = projectStatusList?.filter(
      (res) => res?.statusName !== selected
    );

    // Prevent AG Grid from intercepting Enter key
    const stopEditingOnEnter = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Stop AG Grid from handling Enter
        event.stopPropagation();
        // Find the currently focused option in react-select
        const focusedOption = document.querySelector(
          ".select__menu-list .select__option--is-focused"
        );

        if (focusedOption) {
          focusedOption.click(); // Simulate a click on the focused option
        }
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", stopEditingOnEnter, true);
      return () =>
        document.removeEventListener("keydown", stopEditingOnEnter, true);
    }, [props.api]);

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", minWidth: "170px" }}
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          className={`basic-single tasks-select-status-editor task-listing-situation-filter-top-wrap-select-div select-item-status task-${selected
            ?.toLowerCase()
            .split(" ")
            .join("-")}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          menuPlacement="auto"
          autoFocus={true}
          name="color"
          options={filteredOptions?.map((res) => ({
            label: (
              <div className="d-flex align-items-center">
                {/* Dynamically add icons based on statusName */}
                <img
                  src={statusIcons[res.statusName?.toLowerCase()]} // Get the correct icon using statusName
                  alt={res.statusName}
                  width={20}
                  height={20}
                  className="me-1"
                />
                {res.statusName}
              </div>
            ),
            value: res,
          }))}
          components={{
            Control: (props) => (
              <CustomTasksProgressEditorControl
                {...props}
                taskDeadline={taskDeadline}
                taskCompletedDate={taskCompletedDate}
              />
            ),
          }}
          onChange={async (e) => {
            if (
              params?.data?.hasApproval &&
              !isOwnerInTaskOne(params?.data, userProfile?.userId) &&
              getDefaultMarkAsById(projectStatusList, e.value.id) ===
                "completed"
            ) {
              setShowStatusModal(true);
              return; // Prevent the Select component's value from updating
            } else {
              let endDate = new Date(parseDate(params.data.taskDeadline));
              let currentDate = new Date();
              currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              );
              endDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate()
              );
              const currentStatus =
                params?.data?.taskStatus?.statusName?.toLowerCase() ||
                params?.data?.taskStatus?.name?.toLowerCase();
              const newStatus = e.value.statusName.toLowerCase();
              if (currentStatus !== "completed" && newStatus === "completed") {
                getStatusCounts("INCREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("DECREMENT_OVERDUE");
                }
              } else if (
                currentStatus === "completed" &&
                newStatus !== "completed"
              ) {
                getStatusCounts("DECREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("INCREMENT_OVERDUE");
                }
              }
              params.data.taskStatus = {
                ...e.value,
                name: e.value.statusName,
              };
              setSelected(e.value.statusName);
              params.api.stopEditing();
              const fieldStartData = {
                taskId: params?.data?.id,
                fieldName: "taskStatusId",
                fieldValues: e.value.id,
              };
              await updateProject(fieldStartData);
            }
          }}
        />
      </div>
    );
  };

  // Project helper
  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.id,
        }))
      );
    }
  };

  const getProjectOptionById = (value) => {
    return projectFormList?.find((option) => option.id === value);
  };

  // Main Project Selector Component
  // Project selector
  const TasksSelectProjectEditor = (params) => {
    const [selected, setSelected] = useState(params.data?.project);

    // Custom filter function for searching
    const filterOption = (option, inputValue) => {
      const label = option.label.props.children[1].toLowerCase(); // Assuming the second child is the label text
      return label.includes(inputValue.toLowerCase());
    };

    // Filter out the selected project from the options list
    const filteredOptions = projectOptions?.filter(
      (res) => res?.value !== selected?.id
    );
    // Prevent AG Grid from intercepting Enter key
    const stopEditingOnEnter = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Stop AG Grid from handling Enter
        event.stopPropagation();
        // Find the currently focused option in react-select
        const focusedOption = document.querySelector(
          ".select__menu-list .select__option--is-focused"
        );

        if (focusedOption) {
          focusedOption.click(); // Simulate a click on the focused option
        }
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", stopEditingOnEnter, true);
      return () =>
        document.removeEventListener("keydown", stopEditingOnEnter, true);
    }, [props.api]);

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", minWidth: "195px" }}
        className="project-tasklist-select"
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          menuPlacement="auto"
          className="select-item-project"
          classNamePrefix="select"
          placeholder="Select project"
          data-theme={props.isDark}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          autoFocus={true}
          value={{
            label: selected?.projectName,
            value: selected?.id,
          }}
          options={filteredOptions?.map((res, index) => ({
            label: (
              <div
                className="d-flex align-items-center gap-1 custom-project-select-item"
                key={index}
              >
                {res?.icon}
                {res?.label}
              </div>
            ),
            value: res.value,
          }))}
          components={{ Control: CustomTasksProjectEditorControl }}
          onChange={async (e) => {
            const selectedProject = getProjectOptionById(e.value);

            params.data.project = selectedProject;
            params.api.stopEditing();
            // // setSelected(e.value);
            const fieldStartData = {
              taskId: params?.data?.id,
              fieldName: "projectId",
              fieldValues: e.value,
            };
            await updateProject(fieldStartData);
          }}
          filterOption={filterOption} // Add the custom filter function
        />
      </div>
    );
  };

  const TaskNameCellRenderer = (props) => {
    const canEdit = props.canEdit && isPermitted("task/update");
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const { taskName } = props.data;

    const currentDate = new Date();
    const taskDeadline = new Date(props.data.taskDeadline); // Parse taskDeadline into a Date object

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="d-flex align-items-center task-title m-0">
            <div
              className="module-task-image position-relative"
              onMouseEnter={(event) => {
                setShowTooltip(true);
                const rect = event.target.getBoundingClientRect();
                setTooltipPosition({ x: rect.x, y: rect.y });
              }}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <img
                src={
                  props?.data?.taskType?.typeName?.toLowerCase() === "bug"
                    ? bugIcon
                    : props?.data?.taskType?.typeName?.toLowerCase() === "error"
                    ? criticalImage
                    : props?.data?.taskType?.typeName?.toLowerCase() ===
                      "new task"
                    ? newIcon
                    : props?.data?.taskType?.typeName?.toLowerCase() === "issue"
                    ? errorIcon
                    : newIcon
                }
                alt={props?.data?.taskType?.typeName}
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                }}
              />

              {showTooltip && (
                <Portal>
                  <ProjectNameTooltip
                    projectName={
                      props?.data?.taskType?.typeName
                        ? props?.data?.taskType?.typeName
                        : "New Task"
                    }
                    position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
                  />
                </Portal>
              )}
            </div>
            <p
              className="mb-0"
              onMouseOver={() => {
                if (canEdit) {
                  props.setEdit(true);
                }
              }}
              onMouseLeave={() => props.setEdit(false)}
              onClick={() => {
                if (canEdit) {
                  props.handleEdit(props.api, props.row);
                }
              }}
              style={{ cursor: canEdit ? "pointer" : "" }}
            >
              {" "}
              {taskName?.length > 30
                ? taskName?.substring(0, 30) + "..."
                : taskName}
              {taskDeadline < currentDate &&
                props.data.taskDeadline &&
                props?.data?.taskStatus?.defaultMarkAs !== "completed" && (
                  <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                    <img src={TimerIcon} alt="timer" width={14} height={14} />
                    <div
                      className="custom-task-tooltip"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents click event from bubbling to parent
                      }}
                    >
                      Due date is exceeded
                    </div>
                  </div>
                )}
              {props.data.isRecurred === 1 && (
                <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                  <img src={RepeatIcon} alt="timer" width={14} height={14} />
                  <div
                    className="custom-task-tooltip"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents click event from bubbling to parent
                    }}
                  >
                    Repeated Task
                  </div>
                </div>
              )}
            </p>
          </h5>
        </div>
        <div className="d-flex align-items-center gap-2">
          {hoveredRow === props.params.data.id &&
            !props.params.data.isPinned && (
              <div
                onClick={async (e) => {
                  e.stopPropagation();
                }}
              >
                <Icon
                  className="tasks-listing-pin-icon"
                  icon="icon-park-solid:pin"
                  onMouseOver={() => props.setEdit(false)}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (!disableAction) {
                      props.params.data.isPinned = true;
                      // Refresh the grid to reflect the changes
                      props.params.api.refreshCells({
                        rowNodes: [props.params.node],
                        force: true,
                        suppressFlash: true,
                      });
                      const fieldEndData = {
                        taskId: props.params?.data?.id,
                        fieldName: "isPined",
                        fieldValues: true,
                      };
                      // Refresh the entire grid
                      setFilters({
                        ...filters,
                        pinnedState: !filters.pinnedState,
                      });
                      await updateProject(fieldEndData);
                    }
                  }}
                />
              </div>
            )}
          {props.params.data.isPinned && (
            <div
              onClick={async (e) => {
                e.stopPropagation();
              }}
            >
              <Icon
                className="tasks-listing-pin-icon pined-icon"
                icon="icon-park-solid:pin"
                onMouseOver={() => props.setEdit(false)}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!disableAction) {
                    props.params.data.isPinned = false;
                    // Refresh the grid to reflect the changes
                    props.params.api.refreshCells({
                      rowNodes: [props.params.node],
                      force: true,
                      suppressFlash: true,
                    });
                    const fieldEndData = {
                      taskId: props.params?.data?.id,
                      fieldName: "isPined",
                      fieldValues: false,
                    };
                    // Refresh the entire grid
                    setFilters({
                      ...filters,
                      pinnedState: !filters.pinnedState,
                    });
                    await updateProject(fieldEndData);
                  }
                }}
              />
            </div>
          )}
          <OpenProjectCellRenderer
            showModal={handleTaskDetailsShowModal}
            id={props.params.data.id}
            setEdit={props.setEdit}
            handleOpen={() => {}}
          />
        </div>
      </div>
    );
  };

  const handleEdit = async (api, row) => {
    setEdit(true);

    api.startEditingCell({
      rowIndex: row,
      colKey: "taskName",
    });
    if (!edit) {
      setEdit(true);
      api.startEditingCell({
        rowIndex: row,
        colKey: "taskName",
      });
    }
  };

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null && !disableAction) {
      const dropdownHeight = 70; // Adjust this value based on your dropdown height
      const availableSpaceBelow =
        window.innerHeight - (yValue + dropdownHeight);

      const dropdownPosition =
        availableSpaceBelow < 0
          ? {
              top: `${yValue - dropdownHeight - 26}px`,
              left: `${xValue - 20}px`,
            }
          : { top: `${yValue}px`, left: `${xValue - 20}px` };

      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top,
            left: optionsColumnPosition.left,
            zIndex: 1000,
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}

          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div className="position-relative">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
            {showMenu && (
              <Portal>
                <div
                  ref={optionsDropdownRef}
                  data-theme={props.isDark}
                  style={{
                    position: "absolute",
                    left: dropdownPosition.left,
                    top: dropdownPosition.top,
                    listStyle: "none",
                    background: "#fff", // Set background color
                    fontSize: "14px",
                    fontWeight: 300,
                    minWidth: "150px",
                  }}
                  onMouseEnter={(e) => setShowMenu(true)}
                  onMouseLeave={() => setShowMore(false)}
                  className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1"
                >
                  {((isPermitted("task/update") &&
                    isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        setSelectedTask(hoveredRow);
                        handleTaskDetailsShowModal();
                        // const taskEdit = rowData?.find(
                        //   (task) => task?.id === hoveredRow
                        // );
                        if (!paramApi?.data?.isRead) {
                          paramApi.data.isRead = true;
                          // Refresh the grid to reflect the changes
                          paramApi.api.refreshCells({
                            rowNodes: [paramApi.node],
                            force: true,
                            suppressFlash: true,
                          });
                        }
                      }}
                    >
                      <img
                        src={EditIcon}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="edit-icon"
                      />
                      Edit Task
                    </div>
                  )}
                  {((isPermitted("task/delete") &&
                    isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <img
                        src={Delete}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="delete-icon"
                      />
                      Delete Task
                    </div>
                  )}
                </div>
              </Portal>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: false,
      filter: false,
      flex: 1,
      minWidth: 100,
      width: 1300,
    };
  }, []);

  const changeTheOptionPosition = async (r, i) => {
    const hoveredRowElement = document?.querySelector(`.ag-row[row-id="${r}"]`);
    if (hoveredRowElement) {
      const topPosition = 50 + i * 42; // Adjust top dynamically

      setOptionsColumnPosition({
        top: topPosition,
        left: -20, // Adjust the left position as needed
      });
    }
  };

  // Search param from email redirect
  // Access URL search parameters
  const [searchParams] = useSearchParams(); // React Router v6 hook

  useEffect(() => {
    // Get taskId from the URL query parameters
    const taskId = searchParams.get("taskId");

    // If taskId exists, set it as the selected task and show task details
    if (taskId) {
      setSelectedTask(taskId);
      setShowTaskDetails(true);
    }
  }, [searchParams]); // Re-run if searchParams changes

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rowData]);

  useEffect(() => {
    if (rowData?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [rowData]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  const taskOptionPermission =
    (isPermitted("task/delete") &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    (isPermitted("task/update") &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    isAdmin(userProfile);

  const handleCellEditingStopped = async (event) => {
    if (event.colDef.field === "taskDeadline" && isDateChanged) {
      const updatedTaskDeadline = event.data.taskDeadline;
      const taskId = event.data.id;
      console.log("Event : ", updatedTaskDeadline, event.colDef.field);
      // Call the API to update the task deadline
      const fieldEndData = {
        taskId,
        fieldName: "taskDeadline",
        fieldValues: moment(updatedTaskDeadline, "YYYY-MM-DD HH:mm")
          .clone() // Clone to avoid mutation of the original moment object
          .tz(userProfile?.timeZone, true) // true ensures we keep the exact same time, not adjusting it to the timezone offset
          .utc()
          .toISOString(),
      };

      await updateTaskData(fieldEndData); // Replace with your actual API call
      setIsDateChanged(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!gridRef.current?.contains(event.target)) {
        editingCellsRef.current.clear(); // Clear editing state
        // gridRef.current?.api.stopEditing();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="position-relative project-timeline mt-3"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      <div className="position-relative">
        {gridApi && taskOptionPermission && renderCustomOptionsColumn()}
      </div>
      <div
        className="table-responsive ms-4 scroll-smooth custom-horizental-scrollbar"
        ref={tableRef}
      >
        <Tooltip id="my-tooltip" />

        <div
          className="ag-theme-alpine "
          style={{
            width: "100%",
            minWidth: "1740px",
          }}
          ref={gridRef}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                rowData?.length === 1 ? "left-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={rowData}
            columnDefs={columnData}
            defaultColDef={defaultColDef}
            getRowId={(params) => {
              return params.data.id;
            }}
            headerHeight={41}
            rowDragManaged={false}
            rowDragEntireRow={false}
            animateRows={true}
            domLayout={"autoHeight"}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            suppressRowHoverHighlight={true}
            popupParent={popupParent}
            onCellEditingStarted={(params) => {
              const cellKey = `${params.node.id}-${params.column.colId}`;
              // If the same cell is already editing, stop it and remove from ref
              if (editingCellsRef.current.has(cellKey)) {
                params.api.stopEditing(); // 🚫 Stop editing
                editingCellsRef.current.delete(cellKey); // ❌ Remove from ref
                return; // Exit early
              }
              // If another cell is being edited, remove it first
              if (editingCellsRef.current.size > 0) {
                const [existingKey] = editingCellsRef.current;

                editingCellsRef.current.delete(existingKey); // ❌ Remove previous key
              }
              // Add the new cellKey to ref
              editingCellsRef.current.add(cellKey);
            }}
            onCellEditingStopped={async (event) => {
              // Check if editing stopped due to clicking outside of the cell
              await handleCellEditingStopped(event);
              setEdit(false); // Set edit to false when editing is stopped
              // const cellKey = `${event.node.id}-${event.column.colId}`;
              // editingCellsRef.current.delete(cellKey);
            }}
            onGridReady={gridReady}
            onCellMouseOver={(params) => {
              if (params?.data?.id !== hoveredRow) {
                setShowMore(false);
                setHoveredRow(params?.data?.id);
                setParamApi(params);
                // changeTheOptionPosition(params?.data?.id);
                !updateTaskCanvas && setUpdateTaskId(params?.data?.id);
              }
              if (params?.rowIndex !== hoveredMenu) {
                setShowMenu(false);
                setHoveredMenu(params?.rowIndex);
                changeTheOptionPosition(params?.data?.id, params?.rowIndex);
              }
            }}
          ></AgGridReact>
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                rowData?.length === 1 ? "right-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
      <ConfirmDeleteTaskModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDark={props.isDark}
        getTaskName={() => {
          // eslint-disable-next-line array-callback-return
          const task = taskList?.find((task) => {
            if (updateTaskId === task.id) {
              return task;
            }
          });
          return task?.taskName;
        }}
        taskId={updateTaskId}
        setListToFilter={setListToFilter}
        setTaskList={setTaskList}
        type="task"
        updateCountFilters={updateCountFilters}
        listingType={listingType}
        getTaskList={getTaskList}
      />
      {showStatusModal && (
        <NoStatusAllowedModal
          show={showStatusModal}
          handleClose={handleClose}
          isDark={props.isDark}
        />
      )}
    </div>
  );
}

export default TasksListingTable;
