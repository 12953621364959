import React, { useState } from "react";
import DashboardTableListing from "../../Dashboard/components/DashboardTableListing";
import NoTaskData from "../../../assets/no-dashboard-tasks.png";

const DueTaskList = ({ props, taskData, showTasksTypeName }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  return (
    <div className="due-task-list-main-wrap">
      <div className="due-task-list-wrap p-0 border-0 mt-4">
        <h5>
          {" "}
          <strong>{showTasksTypeName}</strong> Task List{" "}
        </h5>
        <>
          {taskData && taskData?.length > 0 ? (
            <div className="table-responsive custom-horizental-scrollbar">
              <DashboardTableListing
                rowData={taskData}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                setShowTaskDetails={setShowTaskDetails}
                showTaskDetails={showTaskDetails}
                disableAction={false}
                isDark={props.isDark}
                setNewId={() => {}}
                listingType="ALL_TASKS"
              />
            </div>
          ) : (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center no-dashboard-task-data">
              <img src={NoTaskData} alt="no-data" />
              <p className="mb-0">No Tasks Yet</p>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default DueTaskList;
