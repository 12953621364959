import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../../assets/delete-circle.svg";
import { ProjectAPI } from "../../../../services/ClientApi";
import { toast } from "react-toastify";

const ConfirmDeleteProjectPhase = (props) => {
  const [dload, setDLoad] = useState(false);

  // Delete project
  const deleteProject = async () => {
    try {
      setDLoad(true);
      const response = await ProjectAPI.post("project/phase-delete", {
        projectId: props.projectId,
        phaseId: props.phaseId,
      });
      props.setSelectedPhaseIdChange();
      if (response?.status === 200) {
        props.handleCloseDeleteModal();
        toast.success("Project deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        props.setListToFilter((prevList) =>
          prevList.filter((project) => project.id !== props.phaseId)
        );
        await props.getProjectList();
      }
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };
  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      size="lg"
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        {/*<h5>{props?.modalContent?.short_description}</h5>
  <h5>{props?.modalContent?.long_description}</h5>*/}
        <h5>
          This action will remove all associated tasks, do you want to proceed ?
        </h5>

        <p className="del-modal-name">{props.phaseName}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteProject}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteProjectPhase;
