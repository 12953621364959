import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { useDeleteMessageMutation } from "../../../slices/chat/chatSlice";

const ConfirmDeleteMessage = ({
  showDeleteModal = false,
  handleCloseDeleteModal = () => {},
  isDark = false,
  message,
  setMessages,
  setIsMessageDeleted,
}) => {
  // Initialize the delete mutation hooks
  const [deleteMessage, { isLoading }] = useDeleteMessageMutation();

  // Delete Message
  const deleteMessages = async () => {
    try {
      const response = await deleteMessage({ messageId: message?.messageId });

      if (response?.data?.status === "success") {
        setIsMessageDeleted(true);

        // Update the messages state by filtering out the deleted message
        setMessages((prevMessages) => {
          const updatedMessages = { ...prevMessages };

          Object.keys(updatedMessages).forEach((day) => {
            updatedMessages[day] = updatedMessages[day].filter(
              (msg) => msg.messageId !== message.messageId
            );

            // Remove empty days
            if (updatedMessages[day].length === 0) {
              delete updatedMessages[day];
            }
          });

          return updatedMessages;
        });

        toast.success(`Message deleted successfully`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
      toast.error(error?.message || "Error deleting message.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  return (
    <Modal
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>

        <p className="del-modal-name">this message</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={deleteMessages}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteMessage;
