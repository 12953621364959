import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import bugIcon from "../../../assets/bug.svg";
import criticalImage from "../../../assets/critical.svg";
import mediumImage from "../../../assets/medium.svg";

const SprintTask = ({ task, taskIndex }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedEpic, setSelectedEpic] = useState("Epic");
  const [selectedStatus, setSelectedStatus] = useState(
    task?.taskStatus?.statusName
  );

  const handleCheckboxClick = async (e) => {
    e.stopPropagation(); // Stop the event from bubbling up
    setIsChecked(!isChecked);
  };

  // Epic list
  let epicList = ["Epic", "Epic 1", "Epic 2"];
  const statusList = ["Not Started", "In Progress", "On Hold", "Completed"];

  return (
    <Draggable
      key={task?.taskId}
      draggableId={task?.taskId?.toString()}
      index={taskIndex}
    >
      {(provided) => (
        <div
          className="d-flex justify-content-between backlog-task"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            className="checkbox create-task-checkbox"
            onClick={handleCheckboxClick}
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxClick}
            />
            <label className="ms-2 scrum-taskName-label">
              <span>
                <img
                  src={
                    task?.taskType?.typeName?.toLowerCase() === "bug"
                      ? bugIcon
                      : task?.taskType?.typeName?.toLowerCase() === "error"
                      ? criticalImage
                      : task?.taskType?.typeName?.toLowerCase() === "new task"
                      ? mediumImage
                      : mediumImage
                  }
                  alt={task?.taskType?.typeName}
                  style={{
                    width: 20,
                    marginRight: 5,
                  }}
                />
              </span>
              {task?.taskName?.length > 15
                ? task?.taskName?.substring(0, 15) + "..."
                : task?.taskName}
            </label>
          </div>
          <div className="form-group">
            <select
              className="basic-single project-filter-select task-situation-filter-top-wrap-select-div select-scrum-style"
              name="color"
              value={selectedEpic}
              onChange={(e) => setSelectedEpic(e.target.value)}
            >
              <option value="" disabled hidden>
                Select an option
              </option>
              {epicList.map((res, index) => (
                <option value={res} key={index}>
                  {res}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <select
              className="basic-single project-filter-select task-situation-filter-top-wrap-select-div select-status-scrum-style"
              name="color"
              value={selectedStatus}
              style={{
                color:
                  selectedStatus?.toLowerCase() === "completed"
                    ? "#2DBF64"
                    : selectedStatus?.toLowerCase() === "on hold"
                    ? "#E8910D"
                    : selectedStatus?.toLowerCase() === "in progress"
                    ? "#2098D1"
                    : selectedStatus?.toLowerCase() === "not started"
                    ? "#fd1b1b"
                    : "black",
              }}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="" disabled hidden>
                Select an option
              </option>
              {statusList.map((res, index) => (
                <option value={res} key={index}>
                  {res}
                </option>
              ))}
            </select>
          </div>
          <div className="task-scrum-circle-wrap">
            <div>
              <p>JS</p>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SprintTask;
