import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import NoInternetIcon from "../assets/info.svg"; // Add this icon to your assets

const InternetConnectionCheck = ({ isDark }) => {
  const [showModal, setShowModal] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("fast"); // 'fast', 'slow', 'very-slow', 'offline'
  const [downTime, setDownTime] = useState(0); // Track down time in seconds

  useEffect(() => {
    // Check connection when component mounts
    checkConnection();

    // Add event listeners for online/offline status
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Periodic connection check
    const intervalId = setInterval(checkConnection, 5000);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(intervalId);
    };
  }, []);

  const handleOnline = () => {
    setDownTime(0); // Reset down time when back online
    checkConnection();
  };

  const handleOffline = () => {
    // Start tracking down time
    const downInterval = setInterval(() => {
      setDownTime((prev) => {
        if (prev >= 30) {
          setShowModal(true); // Show modal after 30 seconds
          clearInterval(downInterval); // Stop the interval
          return prev; // Keep the down time at 30
        }
        return prev + 1; // Increment down time
      });
    }, 1000); // Check every second

    return () => clearInterval(downInterval); // Cleanup on unmount
  };

  const checkConnection = () => {
    const startTime = performance.now();

    fetch("/ping", {
      method: "GET",
      cache: "no-cache",
    })
      .then((response) => {
        const endTime = performance.now();
        const duration = endTime - startTime;

        // Reset down time if connection is restored
        if (duration < 10000) {
          setDownTime(0);
        }

        // // Categorize connection speed
        // if (duration > 10000) {
        //   setConnectionStatus("slow");
        //   setShowModal(true);
        // }
      })
      .catch(() => {
        setConnectionStatus("offline");
        // Increment down time if offline
        setDownTime((prev) => prev + 1);
      });
  };

  const handleRetry = () => {
    setShowModal(false);
    window.location.reload();
  };

  return (
    <Modal
      show={showModal}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img
            src={NoInternetIcon}
            alt="no-internet-icon"
            width={100}
            height={100}
          />
        </div>
        <h5>
          {connectionStatus === "offline"
            ? "No Internet Connection"
            : "Slow Internet Connection"}
        </h5>
        <p className="del-modal-name">
          {connectionStatus === "offline"
            ? "Please check your internet connection and try again"
            : "Your internet connection is unstable"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={handleRetry}>
            Retry
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InternetConnectionCheck;
