import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup"; // To create validation schema
import { usePostAddCompanyWorkingHoursMutation } from "../../../slices/user/userSlice";
function useSetWorkingHoursModal({ handleCloseModal }) {
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);

  const [
    postAddCompanyWorkingHours,
    { isLoading: isAddCompanyWorkingHoursLoading },
  ] = usePostAddCompanyWorkingHoursMutation();

  const initialValues = {
    shiftTitle: "",
    startTime: "",
    endTime: "",
    days: [], // Default as empty array
  };

  const daysList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const validationSchema = Yup.object({
    shiftTitle: Yup.string().required("Shift title is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    days: Yup.array()
      .min(1, "At least one day must be selected")
      .required("Select at least one day"),
  });

  const handleOnSubmit = async (values) => {
    // Handle form submission
    try {
      // Create payload
      const payload = {
        shiftTitle: values.shiftTitle,
        dayOfWeek: values.days.join(","), // Assuming days need to be a comma-separated string
        startTime: values.startTime,
        endTime: values.endTime,
      };

      const response = await postAddCompanyWorkingHours(payload).unwrap();
      if (response?.status === "success") {
        toast.success(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
        });
      }
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
      });
    } finally {
      handleCloseModal();
    }
  };

  return {
    validationSchema,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    initialValues,
    setEndTimeValue,
    handleOnSubmit,
    daysList,
    isAddCompanyWorkingHoursLoading,
  };
}

export default useSetWorkingHoursModal;
