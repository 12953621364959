import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import CloseBtn from "../../../assets/icon-close.svg";
import "../styles/tasks.scss";
import { Tooltip } from "react-tooltip";
import infoIcon from "../../../assets/info.svg";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import plusIcon from "../../../assets/plus.svg";
import Delete from "../../../assets/delete.svg";
import MultiSelect from "../../../components/MultiSelect";
import InfoIcon from "../../../assets/info.svg";
import { useDispatch } from "react-redux";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import bugIcon from "../../../assets/bug.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from "react-select";
import { ProjectAPI } from "../../../services/ClientApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { teamsListAction } from "../../../actions/teamsActions";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getFileType } from "../../../components/getFIleType";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import NewTeam from "../../Team/components/NewTeam";
import { isPermitted } from "../../../helper/isPermitted";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import { getIconByStatusId } from "../../../helper/getIconByStatusId";
import { useCreateTaskDataMutation } from "../../../slices/tasks/tasksSlice";

function CreateTaskOffcanvas({
  show,
  handleCloseModal,
  isDark,
  getBoardData,
  getGrid,
  isOutgoing,
  pageSize,
  currentPage,
}) {
  const dispatch = useDispatch();
  const [createTaskData, { isLoading }] = useCreateTaskDataMutation();
  const [showNew, setShowNew] = useState(false);
  const [initLoad, setInitLoad] = useState(false);
  const navigate = useNavigate();
  const [reviewForApproval, setReviewForApproval] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [typeOfTasksOptions, setTypeOfTasksOptions] = useState([]);

  const [projectOptions, setProjectOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [list, setList] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const flatpickrRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [customFieldsErrors, setCustomFieldsErrors] = useState([]);
  const [projectPhasesOptions, setProjectPhasesOptions] = useState({
    data: [],
    isLoading: false,
  });
  const { userProfile } = useSelector((state) => state?.profile);
  const allowedExtensions = [
    "pdf",
    "doc",
    "docx",
    "png",
    "jpg",
    "jpeg",
    "gif",
    "csv",
    "xls",
    "ppt",
    "pptx",
    "xlsx",
    "msg",
  ];
  const { getRootProps, getInputProps } = useDropzone({
    accept: "*", // Define accepted file types
    multiple: true,
    onDrop: (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => {
        const extension = `${file.name.split(".").pop()}`;
        return allowedExtensions.includes(extension.toLowerCase());
      });

      if (filteredFiles.length === 0) {
        // Handle case where no valid files were dropped
        toast.error("Please enter a valid file.", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              file.type.startsWith("image/") &&
              /\.(jpg|jpeg|png|webp|gif)$/i.test(file.name)
                ? URL.createObjectURL(file)
                : null, // Generate preview URL for images
          })
        ),
      ]);
    },
  });

  const [observerOptions, setObserverOptions] = useState([]);
  const [createTaskLoading, setCreateTaskLoading] = useState(false);

  // all apis listing data
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);

  const { teamsOptionsList } = useSelector((state) => state.teamsListData);

  const validateForm = () => {
    const newErrors = customFields.map((item) => {
      const error = {};
      if (!item.field || !item.value) {
        error.id = item.id;
        error.field = !item.field ? "Field is required" : "";
        error.value = !item.value ? "Value is required" : "";
      }
      return error;
    });
    setCustomFieldsErrors(newErrors);
    return newErrors.every((error) => !error.field && !error.value);
  };

  const updateState = (typeName, users) => {
    // Ensure users is always an array, even for single user updates
    const userList = Array.isArray(users) ? users : [users];

    setObserverOptions(
      userList.map((user) => ({
        id: user.userId,
        value: user.userName,
        label: user.userName,
      }))
    );
  };

  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.projectName,
        }))
      );
    }
  };

  const getProjectPhasesAPI = async (projectId) => {
    try {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
      const response = await ProjectAPI.post("project/pm-project-phases", {
        projectId: projectId,
      });

      if (response.status === 201) {
        setProjectPhasesOptions({
          data: response?.data?.data?.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.phaseName,
          })),
          isLoading: false,
        });
      }
    } catch (error) {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
    }
  };

  const getAllStatuses = async () => {
    if (projectStatusList) {
      setStatusOptions(
        projectStatusList?.map((status) => ({
          id: status.id,
          label: status.statusName,
          value: status.statusName,
        }))
      );
    }
  };

  const getAllTaskTypes = async () => {
    if (taskTypes) {
      setTypeOfTasksOptions(
        taskTypes?.map((task) => ({
          id: task.id,
          label: task.typeName,
          value: task.typeName,
        }))
      );
    }
  };

  const getUsersAndTypes = async () => {
    try {
      setInitLoad(true);
      if (projectUserList?.data?.length > 0) {
        updateState("observer", projectUserList.data);
      }
      if (taskPriorities) {
        setPriorityOptions(
          taskPriorities?.map((priority) => ({
            id: priority.id,
            value: priority.priorityName,
            label: priority.priorityName,
          }))
        );
        // Execute each asynchronous operation sequentially
        await Promise.all([
          getAllProjects().catch((error) => {
            console.error("Error fetching projects:", error);
          }),
          getAllStatuses().catch((error) => {
            console.error("Error fetching statuses:", error);
          }),
          getAllTaskTypes().catch((error) => {
            console.error("Error fetching task types:", error);
          }),
          dispatch(teamsListAction()).catch((error) => {
            console.error("Error dispatching teamsListAction:", error);
          }),
        ]);
      }
    } catch (error) {
    } finally {
      setInitLoad(false);
    }
  };

  useEffect(() => {
    getUsersAndTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // custom styles for priority
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.data.value.toString().toLowerCase() === "critical"
          ? "#FB3F3F"
          : state.data.value.toString().toLowerCase() === "highest"
          ? "#56BAEC"
          : state.data.value.toString().toLowerCase() === "medium"
          ? "#394B84"
          : state.data.value.toString().toLowerCase() === "low"
          ? "#2C2D2F"
          : state.data.value.toString().toLowerCase() === "in progress"
          ? "#2098D1"
          : state.data.value.toString().toLowerCase() === "not started"
          ? "#fd1b1b"
          : state.data.value.toString().toLowerCase() === "completed"
          ? "#2DBF64"
          : state.data.value.toString().toLowerCase() === "on hold"
          ? "#E8910D"
          : state.data.value.toString().toLowerCase() === "submit for review"
          ? "#EBA900"
          : " black",
      fontSize: "13px",
      backgroundColor: state.isSelected ? "#e9eeff" : "transparent",
      "&:hover": {
        backgroundColor: "#e9eeff", // Set background color when hovering over the option
      },
      fontWeight: 300,
      zIndex: 99999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      // Set the desired width
    }),
  };

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const createTaskSubmit = async (data) => {
    try {
      setCreateTaskLoading(true);
      let formData = new FormData();

      formData.append("taskName", data.taskName);
      data.taskPriorityId &&
        formData.append("taskPriorityId", data.taskPriorityId);
      data.taskTypeId && formData.append("taskTypeId", data.taskTypeId);
      data.taskStatusId && formData.append("taskStatusId", data.taskStatusId);
      data.taskDescription &&
        formData.append("taskDescription", data.taskDescription);
      formData.append("hasApproval", data.hasApproval ? true : false);
      data.projectId && formData.append("projectId", data.projectId);
      data.taskTeamId && formData.append("taskTeamId", data.taskTeamId);
      data.projectPhaseId &&
        formData.append("projectPhaseId", data.projectPhaseId);
      const taskDeadline =
        selectedDateFilter !== null
          ? convertToTimezone(selectedDateFilter, userProfile?.timeZone)
          : null;
      taskDeadline && formData.append("taskDeadline", taskDeadline);
      // formData.append(`taskCheckList[]`, data.taskCheckList);
      // formData.append(`files[]`, data.files);
      // formData.append(`taskMembers[]`, data.taskMembers);

      // For taskCheckList
      data.taskCheckList.forEach((checkListItem, index) => {
        formData.append(`taskCheckList[]`, checkListItem);
      });

      // for customFields
      data.customFields.forEach((customField, index) => {
        formData.append(`customFields[]`, customField);
      });

      // For files
      data.files.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      // For taskMembers
      data.taskMembers.forEach((memberId, index) => {
        formData.append(`taskMembers[]`, memberId);
      });

      // const response = await createTaskData(formData).unwrap();
      // if (response.status === "success") {
      //   // await getBoardData();
      //   toast.success("Task Created Successfully.", {
      //     position: toast.POSITION.TOP_CENTER,
      //     autoClose: 3000,
      //   });
      //   handleCloseModal();
      // }
      // Now formData contains all the data in a format suitable for sending in a form request
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setSelectedDateFilter(null);
      setCreateTaskLoading(false);
      getGrid();
      if (!teamsOptionsList) {
        dispatch(teamsListAction());
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      taskName: "",
      deadline: "",
      assignedTo: null,
      priority: null,
      taskType: null,
      project: null,
      observer: null,
      status: "",
      reviewForApproval: false,
      taskDescription: "",
      attachment: null,
      taskTeamId: null,
      projectPhases: null,
    },
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission here
      // validateForm();

      let data = {
        taskName: values.taskName,
        taskPriorityId: values?.priority?.value?.id,
        taskTypeId: values?.taskType?.value?.id,
        taskStatusId: values?.status?.value?.id,
        taskDescription: values?.taskDescription,
        hasApproval: reviewForApproval,
        projectId: values?.project?.id,
        projectPhaseId: values?.projectPhases?.id,
        taskDeadline: convertToUTC(selectedDateFilter),
        taskCheckList: [],
        files: files,
        taskMembers: [],
        customFields: [],
        taskTeamId: values?.taskTeamId?.flatMap((item) => item?.id).join(","),
      };

      if (list && list.length > 0) {
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
          item.text &&
            item.text.length > 0 &&
            data.taskCheckList.push(item.text);
        });
      }
      if (customFields && customFields.length > 0) {
        // eslint-disable-next-line array-callback-return
        customFields.map((item) => {
          item.field &&
            item.field.length > 0 &&
            data.customFields.push(`${item.field}: ${item.value}`);
        });
      }
      let taskObserver = [];
      let taskAssignTo = [];
      if (values.observer && values.observer.length > 0) {
        const newTaskMembers = values.observer.map((item) => {
          return `${getTypeIDByName("observer")}:${item.id}`;
        });
        taskObserver = newTaskMembers;
      }

      if (values.assignedTo) {
        const newTaskMember = `${getTypeIDByName("member")}:${
          values.assignedTo.id
        }`;
        taskAssignTo = [newTaskMember];
      }

      if (taskObserver.length > 0 || taskAssignTo.length > 0) {
        data.taskMembers = [
          ...taskObserver,
          ...taskAssignTo,
          `${getTypeIDByName("owner")}:${userProfile?.userId}`,
        ];
      }
      await createTaskSubmit(data);
      resetForm();
      setList([]);
      setReviewForApproval(false);
      setFiles([]);
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
      setCustomFields([]);
      navigate(`/${localStorage.getItem("company")}/task-management-tasks`);
    },
    validate: (values) => {
      const errors = {};

      // Validate each field here
      if (!values.taskName) {
        errors.taskName = "Task name is required.";
      }
      if (!values.assignedTo) {
        errors.assignedTo = "Assigned to is required.";
      }
      // if (
      //   !values.assignedTo &&
      //   (!values?.taskTeamId || values?.taskTeamId?.length === 0)
      // ) {
      //   errors.assignedTo = "Assigned to is required.";
      // }
      return errors;
    },
  });

  const handleDeleteFile = (fileToDelete) => {
    const updatedFiles = files.filter((file) => file !== fileToDelete);
    setFiles(updatedFiles);
  };

  // Get Team List
  const getTeamList = async () => {
    dispatch(teamsListAction());
  };

  return (
    <Offcanvas
      className="off-concave-role"
      show={show}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body>
        <div className="create-task-new-wraper">
          <div className="create-task-main-wraper">
            <h4>
              {" "}
              Create <span>Task</span>{" "}
            </h4>
            <p>
              {" "}
              Quickly manage task details if you want to change timeline,
              priority or assign task to new members
            </p>
            <Tooltip id="my-tooltip" />
            {initLoad ? (
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between tri-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between tri-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-end single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
              </div>
            ) : (
              <form noValidate className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-9">
                  <div className="form-group">
                    <label>
                      {" "}
                      Task name{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Name"
                      />{" "}
                    </label>
                    <input
                      type="text"
                      name="taskName"
                      placeholder="Task Name"
                      isInvalid={
                        formik?.touched.taskName && !!formik?.errors.taskName
                      }
                      className={`form-control ${
                        formik?.touched.taskName && formik?.errors.taskName
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.taskName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    {formik.touched.taskName && formik.errors.taskName ? (
                      <div className="invalid-feedback">
                        {formik.errors.taskName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group create-task-deadline">
                    <label>
                      Deadline{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Deadline"
                      />
                    </label>
                    <Flatpickr
                      // data-theme={props.isDark}
                      className={`project-details-task-table-flatpickr-container `}
                      name="deadline"
                      placeholder="Set Date"
                      options={{
                        altInput: true,
                        altFormat: "M d, Y H:i",
                        dateFormat: "Y-m-d H:i",
                        static: true,
                        enableTime: true,
                        disableMobile: true,
                        defaultHour: 18, // Set to 18 for 6:00 PM
                        // minDate: new Date()
                      }}
                      ref={flatpickrRef}
                      value={selectedDateFilter}
                      onChange={([date]) => {
                        const formattedDate = date
                          ? new Date(date)
                          : new Date();
                        setSelectedDateFilter(formattedDate);
                      }}
                    />
                    <span
                      className="create-task-deadline-icon"
                      onClick={() => flatpickrRef.current.flatpickr.open()}
                    >
                      <Icon icon="lets-icons:date-range" />
                    </span>
                    {selectedDateFilter ? null : (
                      <div className="invalid-feedback">
                        Task Deadline is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Assigned To{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Assigned To"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        options={observerOptions ? observerOptions : []}
                        isSelectAll={false}
                        placeholder="Select Assignee"
                        value={formik.values.assignedTo}
                        isInvalid={
                          formik.touched.assignedTo &&
                          !!formik.errors.assignedTo
                        }
                        className={`${
                          formik.touched.assignedTo && formik.errors.assignedTo
                            ? "is-invalid invalid-error-border"
                            : ""
                        }`}
                        name="assignedTo"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("assignedTo", e);
                        }}
                      />

                      {formik.touched.assignedTo && formik.errors.assignedTo ? (
                        <div className="invalid-feedback">
                          {formik.errors.assignedTo}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Priority{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Assigned To"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={true}
                        placeholder="Set Priority"
                        value={formik.values.priority}
                        isInvalid={
                          formik.touched.priority && !!formik.errors.priority
                        }
                        className={`${
                          formik.touched.priority && formik.errors.priority
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="priority"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("priority", e);
                        }}
                        options={
                          priorityOptions
                            ? priorityOptions.map((res, index) => ({
                                label: (
                                  <div>
                                    {" "}
                                    {index === 0 && (
                                      <img
                                        src={criticalImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 1 && (
                                      <img
                                        src={highestImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 2 && (
                                      <img
                                        src={mediumImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 3 && (
                                      <img
                                        src={lowImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                      />
                                    )}{" "}
                                    {res.label}
                                  </div>
                                ),
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.priority && formik.errors.priority ? (
                        <div className="invalid-feedback">
                          {formik.errors.priority}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* type of tasks */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Type of task
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Type of task"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select Task Type"
                        value={formik.values.taskType}
                        isInvalid={
                          formik.touched.taskType && !!formik.errors.taskType
                        }
                        className={`${
                          formik.touched.taskType && formik.errors.taskType
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="taskType"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("taskType", e);
                        }}
                        getOptionLabel={(option) => option.label} // Update this line
                        getOptionValue={(option) => option.value}
                        options={
                          typeOfTasksOptions
                            ? typeOfTasksOptions?.map((res, index) => ({
                                label: (
                                  <div className="d-flex align-items-center gap-1">
                                    {" "}
                                    {index === 0 && (
                                      <img
                                        src={criticalImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 1 && (
                                      <img
                                        src={mediumImage}
                                        alt="Progress"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 2 && (
                                      <img
                                        src={bugIcon}
                                        alt="Review"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {index === 3 && (
                                      <Icon
                                        icon="pajamas:issue-close"
                                        width={20}
                                        height={20}
                                      />
                                    )}
                                    {res?.value}
                                  </div>
                                ),
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.taskType && formik.errors.taskType ? (
                        <div className="invalid-feedback">
                          {formik.errors.taskType}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* projects */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Project{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Project"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={true}
                        placeholder="Select Project"
                        value={formik.values.project}
                        isInvalid={
                          formik.touched.project && !!formik.errors.project
                        }
                        className={`${
                          formik.touched.project && formik.errors.project
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={true}
                        name="project"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("project", e);
                          formik.setFieldValue("projectPhases", null);
                          getProjectPhasesAPI(e.id);
                        }}
                        getOptionLabel={(option) => (
                          <div className="d-flex align-items-center gap-1 selection-project-img">
                            {option.icon} {/* Render the icon */}
                            {option.label} {/* Render the typeName */}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        options={projectOptions ? projectOptions : []}
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.project && formik.errors.project ? (
                        <div className="invalid-feedback">
                          {formik.errors.project}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* project phases */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Project Phases{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Selected Project Phases"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select Project Phase"
                        value={formik.values.projectPhases}
                        isInvalid={
                          formik.touched.projectPhases &&
                          !!formik.errors.projectPhases
                        }
                        className={`${
                          formik.touched.projectPhases &&
                          formik.errors.projectPhases
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isLoading={projectPhasesOptions?.isLoading}
                        isDisabled={projectPhasesOptions?.isLoading}
                        isSearchable={false}
                        name="projectPhases"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("projectPhases", e);
                        }}
                        getOptionValue={(option) => option.value}
                        options={
                          projectPhasesOptions.data
                            ? projectPhasesOptions.data
                            : []
                        }
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.status && formik.errors.status ? (
                        <div className="invalid-feedback">
                          {formik.errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* statuses */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Status{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Status"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select Status"
                        value={formik.values.status}
                        isInvalid={
                          formik.touched.status && !!formik.errors.status
                        }
                        className={`${
                          formik.touched.status && formik.errors.status
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="status"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("status", e);
                        }}
                        getOptionValue={(option) => option.value}
                        options={
                          statusOptions
                            ? statusOptions?.map((res, index) => ({
                                label: (
                                  <div className="d-flex align-items-center gap-1">
                                    <img
                                      src={getIconByStatusId(
                                        statusOptions,
                                        res?.id
                                      )}
                                      alt="status-icon"
                                      width={20}
                                      height={20}
                                    />
                                    {res?.value}
                                  </div>
                                ),
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.status && formik.errors.status ? (
                        <div className="invalid-feedback">
                          {formik.errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* task team */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Task Teams{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Teams"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <MultiSelect
                        options={teamsOptionsList ? teamsOptionsList : []}
                        isSelectAll={true}
                        placehold="Select Task Teams"
                        value={formik.values.taskTeamId}
                        name="taskTeamId"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("taskTeamId", e);
                        }}
                        disabled={isPermitted("teams/listing")}
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1">
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {isPermitted("teams/create") && (
                  <div className="d-flex align-items-center col-md-4 add-team-project">
                    <span onClick={() => setShowNew(true)}>+ Add Team</span>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Observer{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Observer"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <MultiSelect
                        options={observerOptions ? observerOptions : []}
                        isSelectAll={true}
                        placehold="Select Observer"
                        value={formik.values.observer}
                        isInvalid={
                          formik.touched.observer && !!formik.errors.observer
                        }
                        className={`${
                          formik.touched.observer && formik.errors.observer
                            ? "is-invalid invalid-error-border"
                            : ""
                        }`}
                        name="observer"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("observer", e);
                        }}
                      />

                      {formik.touched.observer && formik.errors.observer ? (
                        <div className="invalid-feedback">
                          {formik.errors.observer}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="form-group">
                    <label>
                      Review for Approval{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Review For Approval"
                      />
                    </label>
                    <div className="mt-1 rounded-0 ">
                      <div
                        className="checkbox  create-task-checkbox"
                        onClick={() => setReviewForApproval(!reviewForApproval)}
                      >
                        <input type="checkbox" checked={reviewForApproval} />
                        <label>
                          <span className="ps-2">Check me out</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="textarea-group">
                    <label> Task Description </label>
                    <Editor
                      tinymceScriptSrc="/tinymce/tinymce.min.js"
                      apiKey="gpl" // Your TinyMCE API key
                      value={formik.values.taskDescription}
                      init={{
                        height: 200,
                        menubar: false,
                        selector: "textarea", // Not required if directly replacing <textarea>
                        plugins: "lists textcolor colorpicker",
                        toolbar:
                          "undo redo | blocks | forecolor | bold italic underline | checklist numlist bullist",
                        content_style:
                          "body { font-family: 'Poppins', sans-serif; font-size: 14px }",
                      }}
                      onEditorChange={(newContent, editor) => {
                        // Update your formik state if needed:
                        formik.setFieldValue("taskDescription", newContent);
                      }}
                    />
                  </div>
                </div>
                <div className="todo-listn-wrap col-md-12 mb-0 mt-3">
                  <h5>
                    {" "}
                    Todo List{" "}
                    <span
                      className="todo-list-add-item"
                      onClick={() => {
                        setList([
                          ...list,
                          { id: uuidv4(), checked: false, text: null },
                        ]);
                      }}
                    >
                      {" "}
                      <img src={plusIcon} alt="Plus" /> Add Item{" "}
                    </span>{" "}
                  </h5>
                  <TaskDetailsTodoList
                    list={list}
                    setList={setList}
                    isDark={isDark}
                    addItemInList={() => {
                      setList([
                        ...list,
                        { id: uuidv4(), checked: false, text: null },
                      ]);
                    }}
                  />
                </div>

                {/* custom Fields*/}
                <div className="todo-listn-wrap col-md-12 mb-0 mt-3">
                  <h5>
                    Custom Fields
                    <span
                      className="todo-list-add-item"
                      onClick={() => {
                        setCustomFields([
                          ...customFields,
                          { id: uuidv4(), field: null, value: null },
                        ]);
                      }}
                    >
                      <img src={plusIcon} alt="Plus" /> Add Field
                    </span>
                  </h5>
                  <TaskDetailsCustomFieldsList
                    list={customFields}
                    setList={setCustomFields}
                    isDark={isDark}
                    errors={customFieldsErrors}
                    validateForm={validateForm}
                  />
                </div>

                <div className="col-md-12">
                  <div className="drag-drop-task-file">
                    <label>Attachment</label>
                    <div
                      {...getRootProps()}
                      className="drage-file-upload dropzone"
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3 flex-wrap d-flex w-100 align-items-center justify-content-center gap-2">
                        {files.map((file) => (
                          <div
                            key={file.name}
                            className={`file-preview-container`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="file-preview">
                              {file.preview ? (
                                <img
                                  src={file.preview}
                                  alt={file.name}
                                  className="file-image"
                                />
                              ) : (
                                <div>
                                  <FileIcon
                                    extension={getFileType(file?.name)}
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                    {...defaultStyles[getFileType(file?.name)]}
                                  />
                                </div>
                              )}
                              <div className="file-info">
                                <span className="file-size">
                                  {(file.size / 1024).toFixed(2)} KB
                                </span>
                                <span className="file-name">{file.name}</span>
                              </div>
                              <span
                                className="delete-button"
                                onClick={() => handleDeleteFile(file)}
                              >
                                <Icon icon="fluent:delete-12-filled" />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      Drag and drop or browse
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="create-task-button-wrap">
                    <button
                      type="submit"
                      disabled={createTaskLoading}
                      className="custom-button-main"
                      style={{ minWidth: "150px" }}
                    >
                      {createTaskLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Continue"
                      )}{" "}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        {/** close icon */}
        <button className="btn-role-concave" onClick={handleCloseModal}>
          <img src={CloseBtn} alt="Close" />
        </button>
        {showNew && (
          <NewTeam
            create={0}
            show={showNew}
            handleClose={() => setShowNew(false)}
            isDark={isDark}
            handleCreate={() => {}}
            projectList={projectOptions}
            projectUserList={observerOptions ? observerOptions : []}
            teamToFilterList={[]}
            setTeamToFilterList={() => {}}
            teamList={[]}
            setTeamList={() => {}}
            teamDataAfterUpdate={() => {}}
            fromProjectForm={true}
            getTeamList={getTeamList}
          />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreateTaskOffcanvas;

const TaskDetailsTodoList = ({ list, setList, isDark, addItemInList }) => {
  const onCheckedChange = (id, checked) => {
    setList(
      list.map((res, index) =>
        res.id === id ? { ...res, checked: checked } : res
      )
    );
  };
  const onTextChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, text: text } : res))
    );
  };

  const onItemDelete = (id) => {
    setList(list.filter((res, index) => res.id !== id));
  };
  return (
    <div className="d-flex flex-column gap-2">
      {list.map((res, index) => {
        return (
          <TaskDetailsTodoListItem
            text={res.text}
            checked={res.checked}
            id={res.id}
            key={index}
            isDark={isDark}
            addItemInList={addItemInList}
            onCheckedChange={onCheckedChange}
            onTextChange={onTextChange}
            onItemDelete={onItemDelete}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsTodoListItem = ({
  text,
  checked,
  id,
  key,
  isDark,
  onCheckedChange,
  onTextChange,
  onItemDelete,
  addItemInList,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isEditable, setIsEditable] = useState(text ? false : true);
  const [inputValue, setInputValue] = useState(text);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      inputValue && inputValue.length > 0 && setIsEditable(false);
      onTextChange(id, inputValue);
      if (inputValue && inputValue.length > 0) {
        addItemInList();
      } else {
        onItemDelete(id);
      }
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
    onTextChange(id, event.target.value);
  };
  const handleBlur = (e) => {
    e.stopPropagation();
    e.preventDefault();
    inputValue && inputValue.length > 0 && setIsEditable(false);
    onTextChange(id, inputValue);
    if (inputValue && inputValue.length > 0) {
    } else {
      onItemDelete(id);
    }
  };
  return (
    <div
      data-theme={isDark}
      key={key}
      className="position-relative d-flex align-item-center gap-2 task-details-todo-list-item"
    >
      <div
        className={`${
          !isEditable
            ? "task-details-check-list-radio-button"
            : "task-details-check-list-radio-button-input"
        }`}
        onClick={() => {
          setIsChecked(!isChecked);
          if (!isChecked) {
            onCheckedChange(id, isChecked);
          }
        }}
      >
        <input
          type="checkbox"
          name="optionsRadios"
          id="optionsRadios2"
          value="option2"
          checked={isChecked}
        />
        <label></label>
      </div>
      {!isEditable ? (
        <div className="d-flex align-items-center w-100 task-details-todo-list-item-text-wrap">
          <p
            onClick={() => setIsEditable(true)}
            className={`ps-4 mb-0 pb-0 task-details-todo-list-item-text w-100 ${
              isChecked ? "text-decoration-line-through" : ""
            }`}
          >
            {text}
          </p>

          <img
            onClick={() => onItemDelete(id)}
            src={Delete}
            alt="Delete"
            className="task-details-todo-list-item-delete-icon"
          />
        </div>
      ) : (
        <div className="ps-3 ms-2 task-details-todo-list-item-input-container w-100">
          <input
            type="text"
            placeholder="Add a task"
            className="form-control w-100"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={inputValue}
            onBlur={handleBlur}
            autoFocus
            autoComplete="off"
          />
        </div>
      )}
    </div>
  );
};

const TaskDetailsCustomFieldsList = ({
  list,
  setList,
  isDark,
  errors,
  validateForm,
}) => {
  const onFieldChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, field: text } : res))
    );
  };
  const onValueChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, value: text } : res))
    );
  };

  const onItemDelete = (id) => {
    setList(list.filter((res, index) => res.id !== id));
  };

  return (
    <div className="d-flex flex-column gap-2">
      {list.map((res, index) => {
        return (
          <TaskDetailsCustomFieldsListItem
            field={res.field}
            value={res.value}
            id={res.id}
            key={index}
            isDark={isDark}
            onFieldChange={onFieldChange}
            onValueChange={onValueChange}
            onItemDelete={onItemDelete}
            error={errors.find((error) => error.id === res.id)}
            validateForm={validateForm}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsCustomFieldsListItem = ({
  field,
  value,
  id,
  key,
  isDark,
  onFieldChange,
  onValueChange,
  onItemDelete,
  error,
  validateForm,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleFieldChange = (event) => {
    const newField = event.target.value;
    onFieldChange(id, newField);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    onValueChange(id, newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <div
      className={`position-relative d-flex align-item-center gap-2 task-details-custom-fields-list-item ${
        isHovered ? "hovered" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-50 mb-0 form-group">
        <input
          type="text"
          placeholder="Field"
          className={`form-control`}
          value={field}
          onChange={handleFieldChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
      </div>
      <div className="w-50 mb-0 form-group">
        <input
          type="text"
          placeholder="Value"
          className={`form-control`}
          value={value}
          onChange={handleValueChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
      </div>
      {isHovered ? (
        <div className="delete-icon-container">
          <img
            onClick={() => onItemDelete(id)}
            src={Delete}
            alt="Delete"
            height={20}
            width={20}
            className="mt-2 cursor-pointer"
            style={{ cursor: "pointer" }}
          />
        </div>
      ) : (
        <div style={{ width: "20px", height: "20px" }}></div>
      )}
    </div>
  );
};

export const convertToUTC = (dateTimeStr) => {
  const currentDate = new Date();
  const originalTimezoneOffset = currentDate.getTimezoneOffset();
  const date = new Date(dateTimeStr);
  const utcOffsetMinutes = date.getTimezoneOffset();
  const targetOffsetMinutes = originalTimezoneOffset;

  const offsetDifferenceMinutes = targetOffsetMinutes - utcOffsetMinutes;
  date.setMinutes(date.getMinutes() + offsetDifferenceMinutes);

  return date.toISOString();
};

export const convertToTimezone = (deadline, timeZone) => {
  if (!deadline) {
    return "No Deadline"; // Return this if the deadline is null
  }

  // Parse the deadline if it's a string
  const dateObj = typeof deadline === "string" ? new Date(deadline) : deadline;

  if (isNaN(dateObj.getTime())) {
    console.error("Invalid date provided:", deadline);
    return "Invalid Date";
  }

  // Get the target timezone's offset in minutes
  const targetDate = new Date(dateObj.toLocaleString("en-US", { timeZone }));
  const offsetMinutes = (targetDate.getTime() - dateObj.getTime()) / 60000; // Calculate the offset difference in minutes

  // Adjust the original date by the offset to convert it to the target timezone
  dateObj.setMinutes(dateObj.getMinutes() + offsetMinutes);

  // Now `dateObj` is in the target timezone; convert it to ISO string (UTC)
  const isoString = dateObj.toISOString();
  
  return isoString;
};

