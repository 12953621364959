import { PMBaseApi } from "../../services/PMBaseApi";
import { timelineSlice } from "../timeline/timelineSilce";
import { phaseTasksSlice } from "./projectDetailSectionSlice";
import { taskSlice } from "./tasksSlice";

// // Helper function to construct cache keys based on query parameters
// const constructCacheKey = (params) =>
//   `getTaskListing(${JSON.stringify(params)})`;

// // Function to update a specific task in the cached task listing
// const updateCachedTask = (
//   cachedData,
//   updatedTask,
//   dispatch,
//   taskSlice,
//   params
// ) => {
//   // Find the index of the task to update in the cached data
//   const taskIndex = cachedData.findIndex((task) => task.id === updatedTask.id);
//   if (taskIndex !== -1) {
//     // If the task exists, update it in the cache using `updateQueryData`
//     dispatch(
//       taskSlice.util.updateQueryData(
//         "getTaskListing", // The query endpoint to update
//         params, // The parameters used to fetch the query data
//         (draft) => {
//           // Directly modify the draft to update the task
//           draft.data[taskIndex] = updatedTask;
//         }
//       )
//     );
//   }
// };

export const kanbanSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting task listings with pagination
    getTaskListing: builder.query({
      //   query: ({ page, limit }) => ({
      //     url: `task/listing?page=${page}&limit=${limit}`, // API endpoint and query parameters
      //     method: "POST",
      //   }),
      //   providesTags: ["taskListing"], // Tags used for cache management
      //   keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to clear all cached data
    updateKanbanAllTaskListings: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),
  }),
});

// Export the hooks generated by `taskSlice`
export const { useGetTaskListingQuery, useUpdateKanbanAllTaskListingsMutation } = kanbanSlice;
