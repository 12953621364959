export function formatTimeAgo(timestamp) {
  const now = new Date();
  const date = new Date(timestamp);

  // Ensure the time difference is in positive values
  const timeDifference = now - date;

  // If the time difference is negative (this should be rare), return now.
  if (timeDifference < 0) return "Just now";

  const secondsAgo = Math.floor(timeDifference / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);
  const weeksAgo = Math.floor(daysAgo / 7);
  const monthsAgo = Math.floor(daysAgo / 30);
  const yearsAgo = Math.floor(daysAgo / 365);

  if (daysAgo === 0) {
    // Today
    return `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;
  } else if (daysAgo === 1) {
    // Yesterday
    return "Yesterday";
  } else if (daysAgo < 7) {
    // Days ago (within the past week)
    return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
  } else if (weeksAgo < 5) {
    // Weeks ago
    return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ago`;
  } else if (monthsAgo < 12) {
    // Months ago
    return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
  } else {
    // Years ago
    return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
  }
}

// Function to calculate duration ago
export function calculateDurationAgo(dateString) {
  const currentDate = new Date();
  const pastDate = new Date(dateString);

  // Calculate the difference in milliseconds
  const differenceMs = currentDate - pastDate;

  // Convert milliseconds to seconds, minutes, hours, days, weeks, months, and years
  const seconds = Math.floor(differenceMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  // Helper to add 's' if needed
  const pluralize = (value, unit) =>
    `${value} ${unit}${value > 1 ? "s" : ""} ago`;

  // Determine which unit to display
  if (years > 0) {
    return pluralize(years, "year");
  } else if (months > 0) {
    return pluralize(months, "month");
  } else if (weeks > 0) {
    return pluralize(weeks, "week");
  } else if (days > 0) {
    return pluralize(days, "day");
  } else if (hours > 0) {
    return pluralize(hours, "hour");
  } else if (minutes > 0) {
    return pluralize(minutes, "minute");
  } else if (seconds >= 1) {
    return pluralize(seconds, "second");
  } else {
    return "Just now";
  }
}

// Function to calculate duration ago
export function calculateShortDurationAgo(dateString) {
  const currentDate = new Date();
  const pastDate = new Date(dateString);

  // Calculate the difference in milliseconds
  const differenceMs = currentDate - pastDate;

  // Convert milliseconds to seconds, minutes, hours, days, weeks, months, and years
  const seconds = Math.floor(differenceMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  // Helper to add 's' if needed, with short unit versions
  const pluralizeShort = (value, longUnit, shortUnit) =>
    `${value} ${value > 1 ? longUnit + "s" : shortUnit} ago`;

  // Determine which unit to display
  if (years > 0) {
    return pluralizeShort(years, "year", "yr");
  } else if (months > 0) {
    return pluralizeShort(months, "month", "mo");
  } else if (weeks > 0) {
    return pluralizeShort(weeks, "week", "wk");
  } else if (days > 0) {
    return pluralizeShort(days, "day", "day");
  } else if (hours > 0) {
    return pluralizeShort(hours, "hour", "hr");
  } else if (minutes > 0) {
    return `${minutes} min ago`; // Special case for "min"
  } else if (seconds >= 1) {
    return `${seconds} sec ago`; // Special case for "sec"
  } else {
    return "Just now";
  }
}

// Function to format time in "hh:mm AM/PM" format
export function formatTime(dateString) {
  const date = new Date(dateString);

  // Extract hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours || 12; // Adjust for 0 (midnight)

  // Pad minutes with leading zero if needed
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return formatted time
  return `${hours}:${formattedMinutes} ${ampm}`;
}

// Format "19:00" to "7:00 PM"
export const formatTimeToAMPM = (time) => {
  if (!time) return "";

  const [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 -> 12, 13 -> 1, etc.
  const formattedMinutes = String(minutes).padStart(2, "0"); // Ensure two digits

  return `${String(formattedHours).padStart(2, "0")}:${formattedMinutes} ${period}`;
};

