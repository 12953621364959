import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import API from "../../../services/ClientApi";
import { calculateDiscountedPrice } from "./../../../helper/getDiscountedPrice";
import { calculateMonthDifference } from "./../../../helper/getMonthDifference";
import { dateFormatter } from "./../../../helper/dateFormatter";
import { useDispatch } from "react-redux";
import { billingData } from "../../../actions/paymentActions";

const AddOnSummaryModal = (props) => {
  const dispatch = useDispatch();
  const [loadbtn, setLoadBtn] = useState(false);

  const handleSubmit = async (price) => {
    setLoadBtn(true);
    try {
      const response = await API.post("company/seats-addons", {
        seatCount: parseInt(props.addonSeat),
        paymentBy: 1,
        chargedPrice: price.price,
      });
      if (response?.data?.status === "success") {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        await props.getPlanData();
        await dispatch(billingData());
        props.handleClose();
        props.setAddOnSeat(1);
      }

      if (response === undefined) {
        toast.error("Payment not successful", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoadBtn(false);
    }
  };

  return (
    <Modal
      show={props.modal}
      onHide={async () => {
        props.handleClose();
        props.setAddOnSeat(1);
      }}
      className={`main-update-modal main-module-modal add-on-modal add-on-summery-modal`}
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="" closeButton>
        <div className="d-flex align-items-center add-on-popup-wraper">
          <h2>
            {" "}
            Add-on <span>Payment Summary</span>{" "}
          </h2>
        </div>
      </Modal.Header>

      <Modal.Body className="update-modal-body">
        <hr />

        <div className="add-on-popup-wraper">
          <div className="w-100 d-flex justify-content-between align-items-center summery-wrapper">
            <div className="w-100 package-info-data">
              <div className="w-100 d-flex justify-content-between plan-wrap">
                <p>Package</p>
                <p>{props?.planData?.packageName}</p>
              </div>
              <div className="w-100 d-flex justify-content-between plan-wrap">
                <p>Additional {`${props.addonSeat === 1 ? "Seat" : "Seats"}`}</p>
                <p>{props.addonSeat}</p>
              </div>
              <div className="w-100 d-flex justify-content-between plan-wrap">
                <p>New Seat Count</p>
                <p>{`${
                  parseInt(props.addonSeat) +
                  parseInt(props?.planData?.seatCount)
                } = ${props?.planData?.seatCount} + ${props.addonSeat}`}</p>
              </div>
              <div className="w-100 d-flex justify-content-between plan-wrap">
                <p>Duration</p>
                <p>{props?.planData?.subscriptionPeriod}</p>
              </div>
              <div className="w-100 d-flex justify-content-between plan-wrap">
                <p>Effective Date</p>
                <p>{dateFormatter(new Date())}</p>
              </div>
            </div>
          </div>
          {props?.planData?.subscriptionPeriod === "year" && (
            <p className="add-on-modal-info">
              <span style={{ fontWeight: "500" }}>Note: </span>You will be
              charged only for remaining subscription period.
            </p>
          )}
          <hr />
          <div className="w-100 d-flex justify-content-between align-items-center package-info-data-main">
            <div className="package-info-data">
              <p>Total</p>
            </div>
            <p>
              {props?.planData?.subscriptionPeriod === "year" ? (
                <p>
                  {props?.planData?.subscriptionPeriod === "year" ? (
                    <span
                      style={{ fontSize: "15px" }}
                    >{`(${calculateMonthDifference(
                      props?.planData?.trialEndDate
                    )} months X $${Math.round(
                      Math.floor(
                        calculateDiscountedPrice(
                          props?.planData?.perSeatCharged,
                          props?.planData?.yearlyDiscountedPercentage
                        ) *
                          props.addonSeat *
                          calculateMonthDifference(
                            props?.planData?.trialEndDate
                          )
                      ) /
                        calculateMonthDifference(props?.planData?.trialEndDate)
                    )})`}</span>
                  ) : (
                    ""
                  )}{" "}
                  ${" "}
                  {Math.floor(
                    calculateDiscountedPrice(
                      props?.planData?.perSeatCharged,
                      props?.planData?.yearlyDiscountedPercentage
                    ) *
                      props.addonSeat *
                      calculateMonthDifference(props?.planData?.trialEndDate)
                  )}
                </p>
              ) : (
                <p>
                  $
                  {Math.floor(
                    props?.planData?.perSeatCharged * props.addonSeat
                  )}
                </p>
              )}
            </p>
          </div>
          <div className="choose-continue-btn choose-continue-btn-margin">
            <div className="next-button-wraper module-btn-wrapper">
              <button
                onClick={() => {
                  handleSubmit({
                    price:
                      props?.planData?.subscriptionPeriod === "year"
                        ? Math.floor(
                            calculateDiscountedPrice(
                              props?.planData?.perSeatCharged,
                              props?.planData?.yearlyDiscountedPercentage
                            ) *
                              props.addonSeat *
                              calculateMonthDifference(
                                props?.planData?.trialEndDate
                              )
                          )
                        : Math.floor(
                            props?.planData?.perSeatCharged * props.addonSeat
                          ),
                  });
                }}
                disabled={loadbtn}
                className="module-modal-btn module-main-btn remove-package-modal-btn"
              >
                {loadbtn ? <Spinner animation="border" /> : "Buy Now"}{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddOnSummaryModal;
