import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "../../../components/PaymentModal/paymentmodal.css";
import API from "../../../services/ClientApi";
import { useDispatch } from "react-redux";
import Stripe from "../../../assets/stripelogo.png";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { userProfilePage } from "../../../actions/userActions";
import { billingData, paymentListing } from "../../../actions/paymentActions";

const options = {
  style: {
    base: {
      color: "#333333",
      margin: "10px 0 20px 0",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const UpdateCreditCardModal = ({
  isOpen,
  onClose,
  user,
  transaction,
  amountTask,
  packageData,
  getPackageName = null,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    amount: amountTask ? amountTask : "",
  });
  const [errors, setErrors] = useState({
    // amount: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });

  if (!isOpen) {
    return null;
  }

  const setField = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    // Clear the error for the field when its value changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));

    setField(field, value);
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const { token, error } = await stripe.createToken(cardElement);

    const emptyFields = {
      cardNumber: cardElement._empty,
      cardExpiry: cardExpiryElement._empty,
      cardCvc: cardCvcElement._empty,
    };

    const invalidFields = {
      cardNumber: cardElement._invalid,
      cardExpiry: cardExpiryElement._invalid,
      cardCvc: cardCvcElement._invalid,
    };

    const newErrors = {};
    // if (data?.amount === "") {
    //   newErrors["amount"] = "Field is required";
    // }
    // if (data?.amount < 25) {
    //   newErrors["amount"] = "Minimum transaction amount is $25";
    // }

    // Check for empty and invalid fields
    Object.keys(emptyFields).forEach((field) => {
      if (emptyFields[field]) {
        newErrors[field] = "Field is required";
      }
    });

    Object.keys(invalidFields).forEach((field) => {
      if (invalidFields[field]) {
        newErrors[field] = "Field is invalid";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (Object.keys(newErrors).length <= 0) {
      if (token === undefined) {
        onClose();
        toast.error("Error occured", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        try {
          setLoad(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const res = await API.post(
            `company/stripe-update-card-info`,
            { token: token?.id },
            config
          );

          // const clientSecret = res?.data?.client_secret;

          if (!stripe || !elements) {
            return;
          }
          if (res.error) {
            setLoad(false);
            onClose();
            toast.error("Error occured", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            getPackageName !== null && (await getPackageName());
            setLoad(false);
            setData({ ...data, amount: "" });
            onClose();
            toast.success("Credit card information updated successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            dispatch(userProfilePage());
            dispatch(paymentListing());
            dispatch(billingData());
            localStorage.setItem("show", true);
            transaction();
          }
        } catch (error) {
          onClose();
          // toast.error("Error occured", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 3000,
          // });
          setLoad(false);
        }
      }
    }
  };

  return (
    <div
      className="modal-overlay-funds"
      onClick={() => {
        setErrors({
          //   amount: "",
          cardNumber: "",
          cardExpiry: "",
          cardCvc: "",
        });
        onClose();
      }}
    >
      <div className="modal-funds" onClick={(e) => e.stopPropagation()}>
        <div className="stripe-logo">
          <img src={Stripe} alt="stripe-logo" />
        </div>
        <h1 className="funds-heading">Update Card Information</h1>

        <form className="w-100" onSubmit={!load && paymentHandler}>
          {/* <div className="d-flex flex-column form-group fund-info-relative">
            <label>Amount</label>
            <input
              type="number"
              name="amount"
              className="card-info-amount"
              placeholder="25"
              value={data?.amount}
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
                setErrors({ ...errors, amount: "" });
              }}
            />
            {errors.amount && (
              <p className="fund-info-amount">{errors.amount}</p>
            )}
          </div> */}

          <div className="fund-group form-group fund-info-relative">
            <label htmlFor="card_num_field">Card Number</label>
            <div
              className={`cn-wrapper ${
                errors?.cardNumber ? "payment-modal-field-err" : ""
              }`}
            >
              <CardNumberElement
                type="text"
                id="card_num_field"
                options={options}
                onChange={(e) =>
                  handleInputChange("cardNumber", e.complete ? e.error : "")
                }
              />
            </div>
            {errors.cardNumber && (
              <p className="fund-info">{errors.cardNumber}</p>
            )}
          </div>

          <div className="fund-group form-group fund-info-relative">
            <label htmlFor="card_exp_field">Card Expiry</label>
            <div
              className={`cn-wrapper ${
                errors?.cardExpiry ? "payment-modal-field-err" : ""
              }`}
            >
              <CardExpiryElement
                type="text"
                id="card_exp_field"
                options={options}
                onChange={(e) =>
                  handleInputChange("cardExpiry", e.complete ? e.error : "")
                }
              />
            </div>
            {errors.cardExpiry && (
              <p className="fund-info">{errors.cardExpiry}</p>
            )}
          </div>

          <div className="fund-group form-group fund-info-relative">
            <label htmlFor="card_cvc_field">Card CVC</label>
            <div
              className={`cn-wrapper ${
                errors?.cardCvc ? "payment-modal-field-err" : ""
              }`}
            >
              <CardCvcElement
                type="text"
                id="card_cvc_field"
                options={options}
                onChange={(e) =>
                  handleInputChange("cardCvc", e.complete ? e.error : "")
                }
              />
            </div>
            {errors.cardCvc && <p className="fund-info">{errors.cardCvc}</p>}
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button type="submit" className="btn-fund" disabled={load}>
              {load ? (
                <Spinner
                  border="animation"
                  style={{
                    width: "1rem !important",
                    height: "1rem !important",
                  }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateCreditCardModal;
