import React, { useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { isPermitted } from "../../../helper/isPermitted";
import DeleteCommentModal from "./DeleteCommentModal";
import { ProjectFormDataAPI } from "../../../services/ClientApi";
import TiptapEditor from "./TiptapEditor";
import BulbIcon from "../../../assets/bulb.png";
import { FileIcon, defaultStyles } from "react-file-icon";
import { handleDownloadDocFiles } from "../../../helper/downloadFile";
import { getFileType } from "../../../helper/getFileType";
import { toast } from "react-toastify";
import { calculateTotalSize } from "../../Tasks/components/CreateTaskModal";
import { Icon } from "@iconify/react/dist/iconify.js";

const CommentRepliesSection = ({
  comment,
  getInitials,
  calculateDurationAgo,
  comments,
  setComments,
  taskId,
  isDark,
  userId,
  sanitizedCommentText,
  handleReplyTask,
  parentId,
  setMentionedUserIds,
  mentionedUserIds,
  highlightText,
  commentContent,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updatedText, setUpdatedText] = useState(comment.text);
  const [replyMode, setReplyMode] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [load, setLoad] = useState(false);
  const [repliesSelectedFiles, setRepliesSelectedFiles] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setUpdatedText(comment.text);
    setEditMode(false);
    setMentionedUserIds([]);
  };

  const handleSaveEdit = async () => {
    if (updatedText.trim() === "" && !load) {
      setUpdatedText(comment.text);
      setEditMode(false);
    } else {
      handleEditTask(comment.comId, updatedText);
    }
  };

  const handleReply = () => {
    setReplyMode(true);
  };

  const handleCancelReply = () => {
    setReplyText("");
    setReplyMode(false);
    setMentionedUserIds([]);
    setRepliesSelectedFiles([]);
  };

  const handleSaveReply = async () => {
    if ((replyText.trim() === "" && repliesSelectedFiles?.length === 0) || load)
      return;
    setLoad(true);
    await handleReplyTask(
      {
        text: replyText,
        repliedToCommentId: comment.comId,
        attachments: repliesSelectedFiles,
      },
      parentId,
      "NESTED_REPLY"
    );
    setReplyText("");
    setReplyMode(false);
    setLoad(false);
    setRepliesSelectedFiles([]);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const totalSize = calculateTotalSize(files);

    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const validFiles = files.filter((file) => validateFile(file));
    if (validFiles.length > 0) {
      setRepliesSelectedFiles((prev) => [...prev, ...validFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const validateFile = (file) => {
    const allowedExtensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "doc",
      "docx",
      "txt",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "gif",
      "bmp",
      "svg",
      "zip",
      "rar",
      "mp3",
      "mp4",
    ];
    const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      alert(`Invalid file type: .${fileExtension}.`);
      return false;
    }
    if (file.size > maxFileSize) {
      alert("File size exceeds the limit of 8 MB.");
      return false;
    }
    return true;
  };

  const handleEditTask = async (id, text) => {
    try {
      setLoad(true);
      let formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("commentId", id);
      formData.append("comments", text);

      // Transform mentionedUserIds array into a comma-separated string
      const mentionIdsString = mentionedUserIds.join(",");
      formData.append("mentionedUsersIds", mentionIdsString);

      // Append each file in repliesSelectedFiles to the formData
      repliesSelectedFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });

      // eslint-disable-next-line no-unused-vars
      const resp = await ProjectFormDataAPI.post(
        "task/update-comment",
        formData
      );

      // Helper function to recursively find and update a reply
      const updateCommentOrReply = (commentsList) =>
        commentsList.map((comment) => {
          if (comment.comId === id) {
            // If the comment is found, update its text
            return {
              ...comment,
              text,
              isEdited: resp?.data?.data?.isEdited,
            };
          } else if (comment.replies) {
            // If not found, check replies recursively
            return {
              ...comment,
              replies: updateCommentOrReply(comment.replies),
            };
          }
          return comment;
        });

      // Update the comments array using the helper function
      const updatedComments = updateCommentOrReply(comments);

      // Update the state with the updated comments array
      setComments(updatedComments);
    } catch (error) {
      console.error("Error updating comment:", error);
    } finally {
      setLoad(false);
      setEditMode(false);
      setMentionedUserIds([]);
    }
  };

  const fileViewers = {
    image: (url) => url,
    video: (url) => url,
    pdf: (url) => url,
    doc: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    docx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xls: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xlsx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    ppt: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    pptx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    default: (url) => url, // Fallback for other file types
  };

  const handleThumbnailClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowModal(true);
  };

  const getFileViewerUrl = (fileType, fileUrl) => {
    return fileViewers[fileType]
      ? fileViewers[fileType](fileUrl)
      : fileViewers.default(fileUrl);
  };

  const handleFileClick = (attachment) => {
    const fileType = getFileType(attachment.attachmentName);
    const fileViewerUrl = getFileViewerUrl(fileType, attachment.attachmentPath);
    window.open(fileViewerUrl, "_blank", "noopener,noreferrer");
  };

  const handleFileChange = (files) => {
    const acceptedFiles = Array.from(files);

    const totalSize = calculateTotalSize(acceptedFiles);

    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

    if (filteredFiles.length > 0) {
      setRepliesSelectedFiles((prev) => [...prev, ...filteredFiles]);
    }
  };

  return (
    <div
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
      className="d-flex gap-2 mt-4 comment-box "
    >
      <div className="comment-user-image-container">
        {comment?.avatarUrl ? (
          <img src={comment?.avatarUrl} alt="comment-user-profile-name" />
        ) : (
          <div className="user-comment-name">
            {getInitials(comment?.fullName)}
          </div>
        )}
      </div>
      <div className="w-100 d-flex flex-column comment-box-text">
        <p className="comment-user-name">
          {comment?.fullName}
          {comment?.isEdited && (
            <span className="ms-2 is-comment-edited-text">Edited</span>
          )}
          <span className="ms-2 comment-date">
            {calculateDurationAgo(comment.createdAt)}
          </span>
        </p>
        {editMode ? (
          <div className="comment-editor-mce">
            <TiptapEditor
              value={comment?.text}
              disabled={load}
              onChange={(content) => {
                setUpdatedText(content);
              }}
              onKeyDown={() => {
                handleSaveEdit();
              }}
              setMentionedUserIds={setMentionedUserIds}
              onFileChange={handleFileChange}
              isFileUpload={true}
            />
            <div className="editor-toolbar">
              <label htmlFor="file-upload-reply" className="file-upload-label">
                <Icon icon="mdi:file-upload" />
              </label>
              <input
                id="file-upload-reply"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>
        ) : (
          <div
            className="comment-user-comment"
            // dangerouslySetInnerHTML={{ __html: sanitizedCommentText }}
          >
            {highlightText(sanitizedCommentText, commentContent)}
          </div>
        )}
        {/* Attachments Section */}
        {comment?.attachments && comment.attachments.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "4px",
            }}
            className="attachment-files-main-wrap mt-2"
          >
            {comment.attachments.map((attachment, index) => (
              <div
                style={{ width: "max-content", cursor: "pointer" }}
                key={index}
                className="file-attachment-wrap bg-white"
                onClick={() => handleFileClick(attachment)}
              >
                <div className="file-attachment-icon">
                  {getFileType(attachment.attachmentName) === "image" ? (
                    <img
                      className="thumbnail"
                      src={attachment.attachmentPath}
                      alt={attachment.attachmentName}
                      onClick={() => handleThumbnailClick(attachment)}
                    />
                  ) : (
                    <FileIcon
                      extension={getFileType(attachment.attachmentName)}
                      {...defaultStyles[getFileType(attachment.attachmentName)]}
                    />
                  )}
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h6>
                    {attachment.attachmentName.length > 15
                      ? attachment.attachmentName.substring(0, 15) + "..."
                      : attachment.attachmentName}
                  </h6>
                  <div className="d-flex align-items-center gap-2 view-download-container">
                    <p onClick={() => handleFileClick(attachment)}>View</p>
                    <span></span>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDownloadDocFiles(
                          attachment.attachmentPath,
                          getFileType(attachment.attachmentPath)
                        );
                      }}
                    >
                      Download
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!replyMode && (
          <div
            className={`d-flex align-items-center gap-2 w-100 edit-comment-section mt-2 ${
              editMode ? "mt-2" : ""
            }`}
          >
            {!editMode && (
              <span onClick={handleReply} className="reply-comment-btn">
                Reply
              </span>
            )}
            {!editMode &&
              isPermitted("task/update-comment") &&
              userId === comment?.userId && (
                <>
                  <span>.</span>
                  <span onClick={handleEdit}>Edit</span>
                </>
              )}
            {!editMode && (
              <>
                {isPermitted("task/update-comment") &&
                  isPermitted("task/delete-comment") &&
                  userId === comment?.userId && <span>.</span>}
                {isPermitted("task/delete-comment") &&
                  userId === comment?.userId && (
                    <span
                      className="delete-comment-btn"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </span>
                  )}
              </>
            )}
            {editMode && (
              <div className="w-100 flex-md-row flex-column d-flex align-items-md-center align-items-end justify-content-between py-2">
                <p className="align-self-start mb-0 ctrl-enter-text">
                  <img src={BulbIcon} alt="bulb-icon" width={24} height={24} />{" "}
                  Ctrl+Enter to "add comment"
                </p>
                <div className="d-flex align-items-center gap-2">
                  <button
                    onClick={handleSaveEdit}
                    className="comment-field-submit-btn comment-field-post-button"
                  >
                    Save{" "}
                    {load && (
                      <span>
                        <Spinner size="sm" className="ms-2" />
                      </span>
                    )}
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="comment-field-cancel-btn comment-field-submit-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {replyMode && (
          <div className="reply-editor mt-3">
            <TiptapEditor
              value={replyText}
              disabled={load}
              onChange={(content) => setReplyText(content)}
              onKeyDown={handleSaveReply}
              setMentionedUserIds={setMentionedUserIds}
              onFileChange={handleFileChange}
              isFileUpload={true}
            />
            <div className="w-100 flex-md-row flex-column d-flex align-items-md-center align-items-end justify-content-between py-2">
              <p className="align-self-start mb-0 ctrl-enter-text">
                <img src={BulbIcon} alt="bulb-icon" width={24} height={24} />{" "}
                Ctrl+Enter to "add comment"
              </p>
              <div className="d-flex align-items-center gap-2">
                <button
                  onClick={handleSaveReply}
                  className="comment-field-submit-btn comment-field-post-button"
                >
                  Post {load && <Spinner size="sm" />}
                </button>
                <button
                  onClick={handleCancelReply}
                  className="comment-field-cancel-btn comment-field-submit-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {repliesSelectedFiles.length > 0 && (
          <div className="create-task-modal-uploaded-files">
            <h6>Attached Replies Files with comments</h6>
            {repliesSelectedFiles.map((file, index) => (
              <div key={index} className="file-item-one">
                <span>
                  {file.name} ({(file.size / 1024).toFixed(2)} KB)
                </span>
                <button
                  className="remove-uploaded-file-button"
                  onClick={() => {
                    setRepliesSelectedFiles((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                  }}
                >
                  <Icon icon="maki:cross" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {showDeleteModal && (
        <DeleteCommentModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          comments={comments}
          setComments={setComments}
          taskId={taskId}
          commentId={comment.comId}
          isDark={isDark}
        />
      )}
      <Modal
        className="file-viewer-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
        data-theme={isDark}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAttachment?.attachmentName &&
            selectedAttachment?.attachmentName.length > 15
              ? selectedAttachment?.attachmentName.substring(0, 15) + "..."
              : selectedAttachment?.attachmentName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAttachment &&
          getFileType(selectedAttachment?.attachmentName) === "image" ? (
            <img
              src={selectedAttachment?.attachmentPath}
              alt="Attachment"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          ) : (
            <iframe
              src={selectedAttachment?.attachmentPath}
              title="Attachment Viewer"
              width="100%"
              height="500px"
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="file-viewer-btn"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CommentRepliesSection;
