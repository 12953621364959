import {
  TIMELINE_STATS_REQUEST,
  TIMELINE_STATS_SUCCESS,
  TIMELINE_STATS_FAIL,
  TIMELINE_ACTIVE_PROJECTS_FAIL,
  TIMELINE_ACTIVE_PROJECTS_REQUEST,
  TIMELINE_ACTIVE_PROJECTS_SUCCESS,
  TIMELINE_ONGOING_TASK_REQUEST,
  TIMELINE_ONGOING_TASK_SUCCESS,
  TIMELINE_ONGOING_TASK_FAIL,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL,
  TIMELINE_ONGOING_TASK_UPDATE_FAIL,
  TIMELINE_ONGOING_TASK_UPDATE_REQUEST,
  TIMELINE_ONGOING_TASK_UPDATE_SUCCESS,
} from "../constants/timelineConstants";
import { ProjectAPI } from "../services/ClientApi";

// Action to get timelineStat
export const timelineStatsAction = (noLoad) => async (dispatch) => {
  try {
    if (!noLoad) {
      dispatch({ type: TIMELINE_STATS_REQUEST });
    }
    const data = await ProjectAPI.post("timeline/stats");
    dispatch({
      type: TIMELINE_STATS_SUCCESS,
      payload: data?.data?.data,
    });

    return data?.data?.data;
  } catch (error) {
    dispatch({
      type: TIMELINE_STATS_FAIL,
      payload: error,
      meta: {
        retryAction: timelineStatsAction(noLoad), // Retry action
      },
    });
  }
};

// Action to clear timeline stats
export const clearTimelineStats = () => async (dispatch) => {
  dispatch({
    type: TIMELINE_STATS_SUCCESS,
    payload: null,
  });
};

// Action to get timeline active projects
export const timelineActiveProjectAction = () => async (dispatch) => {
  try {
    dispatch({ type: TIMELINE_ACTIVE_PROJECTS_REQUEST });
    const data = await ProjectAPI.post("timeline/active-projects");
    dispatch({
      type: TIMELINE_ACTIVE_PROJECTS_SUCCESS,
      payload: data?.data?.data,
    });

    return data?.data?.data;
  } catch (error) {
    // dispatch({
    //   type: TIMELINE_ACTIVE_PROJECTS_FAIL,
    //   payload: error,
    //   meta: {
    //     // retryAction: timelineActiveProjectAction(), // Retry action
    //   },
    // });
  }
};

// Action to get timeline ongoing tasks
export const timelineOngoingTaskAction = () => async (dispatch) => {
  try {
    dispatch({ type: TIMELINE_ONGOING_TASK_REQUEST });
    const data = await ProjectAPI.post("timeline/ongoing-tasks");
    dispatch({
      type: TIMELINE_ONGOING_TASK_SUCCESS,
      payload: data?.data?.data,
    });

    return data?.data?.data;
  } catch (error) {
    dispatch({
      type: TIMELINE_ONGOING_TASK_FAIL,
      payload: error,
      meta: {
        retryAction: timelineOngoingTaskAction(), // Retry action
      },
    });
  }
};

// action to update a project
export const updateActiveProjectAction =
  (updatedProject) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST });

      // Simulating the updated project response from the API
      const data = updatedProject;

      dispatch({
        type: TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS,
        payload: data,
      });

      // Update the project list in the state
      const { timelineActiveProjects } = getState().timelineActiveProjectsData;
      const updatedProjectList = timelineActiveProjects.map((project) =>
        project.projectId === data.projectId ? data : project
      );

      dispatch({
        type: TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS,
        payload: updatedProjectList,
      });

      return data;
    } catch (error) {
      dispatch({
        type: TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL,
        payload: error,
        meta: {
          retryAction: updateActiveProjectAction(updatedProject), // Retry action
        },
      });
    }
  };

// action to delete multiple projects by an array of IDs
export const deleteActiveProjectAction =
  (projectIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMELINE_ACTIVE_PROJECTS_REQUEST }); // Start loading state

      // Assuming successful deletion, update the local state
      const state = getState();

      if (
        state &&
        state.timelineActiveProjectsData &&
        state.timelineActiveProjectsData.timelineActiveProjects
      ) {
        const updatedProjectList =
          state.timelineActiveProjectsData.timelineActiveProjects.filter(
            (project) => !projectIds.includes(project.projectId) // Keep projects NOT in projectIds
          );

        dispatch({
          type: TIMELINE_ACTIVE_PROJECTS_SUCCESS,
          payload: updatedProjectList,
        });
      } else {
        // Handle the case where projectList is undefined
        // dispatch({
        //   type: TIMELINE_ACTIVE_PROJECTS_FAIL,
        //   payload: "Project list is undefined",
        // });
      }
    } catch (error) {
      // Handle errors
      // dispatch({
      //   type: TIMELINE_ACTIVE_PROJECTS_FAIL,
      //   payload: error.message,
      //   meta: {
      //     retryAction: deleteActiveProjectAction(projectIds), // Retry action
      //   },
      // });
    }
  };

// action to clear the entire task list
export const clearActiveProjectListAction = () => (dispatch) => {
  dispatch({
    type: TIMELINE_ACTIVE_PROJECTS_SUCCESS,
    payload: null, // Set the taskList to an empty array
  });
};

// action to update a ongoing task
export const updateOngoingTaskAction =
  (updatedTask) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMELINE_ONGOING_TASK_UPDATE_REQUEST });

      // Simulating the updated task response from the API
      const data = updatedTask;

      dispatch({
        type: TIMELINE_ONGOING_TASK_UPDATE_SUCCESS,
        payload: data,
      });

      // Update the task list in the state
      const { timelineOngoingTasks } = getState()?.timelineOngoingTaskData;
      const updatedTaskList = timelineOngoingTasks?.map((task) =>
        task.id === data.id ? data : task
      );

      dispatch({
        type: TIMELINE_ONGOING_TASK_UPDATE_SUCCESS,
        payload: updatedTaskList,
      });

      return data;
    } catch (error) {
      dispatch({
        type: TIMELINE_ONGOING_TASK_UPDATE_FAIL,
        payload: error,
        meta: {
          retryAction: updateOngoingTaskAction(updatedTask), // Retry action
        },
      });
    }
  };

// action to delete multiple tasks by an array of IDs
export const deleteOngoingTasksAction =
  (taskIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMELINE_ONGOING_TASK_REQUEST }); // Start loading state

      // Assuming successful deletion, update the local state
      const state = getState();

      if (
        state &&
        state.timelineOngoingTaskData &&
        state.timelineOngoingTaskData.timelineOngoingTasks
      ) {
        const updatedtaskList =
          state.timelineOngoingTaskData.timelineOngoingTasks.filter(
            (task) => !taskIds.includes(task.id) // Keep projects NOT in task
          );

        dispatch({
          type: TIMELINE_ONGOING_TASK_SUCCESS,
          payload: updatedtaskList,
        });
      } else {
        // Handle the case where taskList is undefined
        dispatch({
          type: TIMELINE_ONGOING_TASK_FAIL,
          payload: "task list is undefined",
        });
      }
    } catch (error) {
      // Handle errors
      dispatch({
        type: TIMELINE_ONGOING_TASK_FAIL,
        payload: error.message,
        meta: {
          retryAction: deleteOngoingTasksAction(taskIds), // Retry action
        },
      });
    }
  };

// action to clear the entire task list
export const clearOngoingTasksListAction = () => (dispatch) => {
  dispatch({
    type: TIMELINE_ONGOING_TASK_SUCCESS,
    payload: null, // Set the taskList to an empty array
  });
};
