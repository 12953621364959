/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import ProfileNew from "../../../assets/profile-new-image.jpg";
import "react-responsive-tabs/styles.css";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import UserSessionHistory from "./UserSessionHistory";
import UserPermissions from "./UserPermissions";
import UserPersonalInfo from "./UserPersonalInfo";

import { Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import userProfileBlank from "../../../assets/blank_profile.svg";
import "../styles/userProfile.css";
import { Helmet } from "react-helmet";
import Button from "../../../components/Button";
import AddUserRole from "./AddUserRole";
import ChangeUserRole from "./ChangeUserRole";
import { useSelector } from "react-redux";
import ProfileShimmer from "./ProfileShimmer";
import {
  useGetCompanyRolesQuery,
  useGetUserPermissionsMutation,
} from "../../../slices/user/userSlice";
import UserSettings from "./UserSettings";

const UserProfileMain = (props) => {
  const navWrapRef = useRef(null);
  const { userProfile } = useSelector((state) => state?.profile);
  const [getUserPermissions, { data: userPermissios }] =
    useGetUserPermissionsMutation();
  const { data: companyRoles } = useGetCompanyRolesQuery();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  // eslint-disable-next-line no-unused-vars
  const [isOverflowing, setOverflowing] = useState(false);
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState(
    sessionStorage.getItem("userTab")
      ? parseInt(sessionStorage.getItem("userTab"))
      : 0
  );
  const [checkedModules, setCheckedModules] = useState([]);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const { applicationName } = useSelector((state) => state.text);

  const handleCloseAddRoleModal = () => {
    setShowAddRoleModal(false);
  };
  const handleCloseChangeRoleModal = () => {
    setShowChangeRoleModal(false);
  };

  const handleChange = (value) => {
    sessionStorage.setItem("userTab", value);
    setState(value);
  };

  useEffect(() => {
    const getAllRoles = async () => {
      setLoad(true);
      try {
        if (companyRoles) {
          setRoles(companyRoles?.data);
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };
    if (userProfile) {
      getAllRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, companyRoles]);

  // useEffect(() => {
  //   getUserRoles
  // },[])

  useEffect(() => {
    const checkOverflow = () => {
      const navWrap = navWrapRef.current;
      if (navWrap && navWrap.scrollWidth > navWrap.clientWidth) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    };

    window.addEventListener("resize", checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const getUser = async (load = true) => {
    load && setLoad(true);
    try {
      const user = await API.post("users/user-profile", { userId: id });
      if (user) {
        setUser(user?.data?.data);
      }
    } catch (error) {
    } finally {
      load && setLoad(false);
    }
  };

  const handleImageError = (event) => {
    // Handle broken image scenario
    event.target.src = ProfileNew; // Use the imported ProfileNew image
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Profile-{applicationName}</title>
      </Helmet>
      <div className="user-profile-main-wraper">
        <h3>
          {" "}
          User <span>Profile</span>{" "}
        </h3>
        {load ? (
          <ProfileShimmer />
        ) : (
          <>
            <div className="profile-info-wraper">
              <div className="profil-image-name-wrap">
                <div className="d-flex align-items-center gap-4 flex-md-row flex-column prof-main-wrap">
                  <div className="">
                    {user?.userInfo?.profileImage ? (
                      <img
                        className="profile-dash-image"
                        src={user?.userInfo?.profileImage}
                        onError={handleImageError}
                        alt="profile-icon"
                      />
                    ) : (
                      <img
                        className="profile-dash-image"
                        src={userProfileBlank}
                        onError={handleImageError}
                        alt="profile-icon"
                      />
                    )}
                  </div>
                  <div className="w-100 d-flex align-items-center justify-content-md-between justify-content-center flex-wrap profile-main-mod-btn-wrap">
                    <div className="profile-dash-text">
                      {" "}
                      <div className="w-100 d-flex align-items-center profile-role-wrapper">
                        <div className="d-flex profile-roles-span-wrap">
                          {user?.userInfo?.roles?.map((res, id) => (
                            <span className="user-profile-role-span" key={id}>
                              {" "}
                              {res?.roleName.charAt(0).toUpperCase() +
                                res?.roleName.slice(1)}
                            </span>
                          ))}
                        </div>
                        <div className="teams-listing-wap team-listing-profile">
                          <UserDepartmentList user={user} colors={colors} />
                        </div>
                      </div>
                      <h4> {user?.userInfo?.userName} </h4>
                      <p> {user?.userInfo?.companyName} </p>
                    </div>

                    {user?.userInfo?.adminUserCount > 1 &&
                      user?.userInfo?.roles?.some(
                        (role) => role.roleType === "admin"
                      ) && (
                        <Button
                          icon="fa fa-edit"
                          text="Change Role"
                          handleClick={() => setShowChangeRoleModal(true)}
                          width="162px"
                        />
                      )}
                    {user?.userInfo?.roles?.some(
                      (role) => role.roleType !== "admin"
                    ) && (
                      <div className="d-flex flex-row flex-wrap align-items-center justify-content-center user-profile-dual-btn-container">
                        <Button
                          icon="fa fa-edit"
                          text="Change Role"
                          handleClick={() => setShowChangeRoleModal(true)}
                          width="162px"
                        />

                        <Button
                          icon="fa fa-add"
                          text="Assign New Role"
                          handleClick={() => setShowAddRoleModal(true)}
                          width="184px"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-nav-wrap">
                  <div className="nav-wrap-inner" ref={navWrapRef}>
                    <ul>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 0 ? "selected-profile-tab-one" : ""
                          }`}
                          onClick={() => handleChange(0)}
                        >
                          {" "}
                          Personal Info{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 1 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(1)}
                        >
                          {" "}
                          Roles & Permissions{" "}
                        </a>{" "}
                      </li>

                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 2 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(2)}
                        >
                          {" "}
                          Session History{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className={`${
                            state === 3 ? "selected-profile-tab" : ""
                          }`}
                          onClick={() => handleChange(3)}
                        >
                          {" "}
                          Settings{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {state === 0 && <UserPersonalInfo user={user} getUser={getUser} />}
            {state === 1 && (
              <UserPermissions
                user={user}
                checkedModules={checkedModules}
                setCheckedModules={setCheckedModules}
                userId={id}
                isDark={props.isDark}
                getUser={getUser}
                showChangeRoleModal={showChangeRoleModal}
                setShowChangeRoleModal={setShowChangeRoleModal}
                handleCloseChangeRoleModal={handleCloseChangeRoleModal}
                showAddRoleModal={showAddRoleModal}
                setShowAddRoleModal={setShowAddRoleModal}
                handleCloseAddRoleModal={handleCloseAddRoleModal}
                getOptions={props.getOptions}
                show={show}
                setShow={setShow}
              />
            )}
            {state === 2 && <UserSessionHistory user={user} />}
            {state === 3 && (
              <UserSettings user={user} isAdmin={true} getUser={getUser} />
            )}
          </>
        )}
        {showAddRoleModal && (
          <AddUserRole
            isDark={props.isDark}
            showAddRoleModal={showAddRoleModal}
            handleCloseAddRoleModal={handleCloseAddRoleModal}
            roles={roles}
            user={user}
            getUser={getUser}
          />
        )}
        {showChangeRoleModal && (
          <ChangeUserRole
            isDark={props.isDark}
            showAddRoleModal={showChangeRoleModal}
            handleCloseAddRoleModal={handleCloseChangeRoleModal}
            roles={roles}
            user={user}
            getUser={getUser}
          />
        )}
      </div>
    </>
  );
};

const UserDepartmentList = ({ user, colors }) => {
  const [showMore, setShowMore] = useState(false);

  const [moduleCategories, setModuleCategories] = useState([]);

  useMemo(() => {
    if (user.userRolesAndModules) {
      setModuleCategories(
        user?.userRolesAndModules.flatMap((role) => role?.moduleCategories)
      );
    }
  }, [user]);

  const toggleShowAll = () => {
    setShowMore(!showMore);
  };

  const firstFourCategories = moduleCategories.slice(0, 4);

  return (
    <ul>
      {showMore
        ? moduleCategories.map((moduleCategory, id) => {
            const uniqueId = uuidv4();
            return (
              <li
                key={uniqueId}
                style={{
                  backgroundColor: colors[id % colors.length],
                }}
                data-tooltip-id={`my-tooltip-${uniqueId}`}
              >
                {moduleCategory?.abbreviatedName}
                <ReactTooltip
                  id={`my-tooltip-${uniqueId}`}
                  place="top"
                  content={() => (
                    <div>
                      {moduleCategory?.abbreviatedName} <br />
                      {moduleCategory?.roleName.charAt(0).toUpperCase() +
                        moduleCategory?.roleName.slice(1)}
                    </div>
                  )}
                />
              </li>
            );
          })
        : firstFourCategories.map((moduleCategory, id) => {
            const uniqueId = uuidv4();
            return (
              <li
                key={uniqueId}
                style={{
                  backgroundColor: colors[id % colors.length],
                }}
                data-tooltip-id={`my-tooltip-${uniqueId}`}
              >
                {moduleCategory?.abbreviatedName}
                <ReactTooltip
                  id={`my-tooltip-${uniqueId}`}
                  place="top"
                  content={() => (
                    <div>
                      {moduleCategory?.abbreviatedName} <br />
                      {moduleCategory?.roleName.charAt(0).toUpperCase() +
                        moduleCategory?.roleName.slice(1)}
                    </div>
                  )}
                />
              </li>
            );
          })}
      {moduleCategories.length > 4 && (
        <li onClick={toggleShowAll} className="last-module-count">
          {showMore
            ? `-${moduleCategories.length - 4}`
            : `+${moduleCategories.length - 4}`}
        </li>
      )}
    </ul>
  );
};

export default UserProfileMain;
