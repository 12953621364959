import { ProjectFormDataAPI } from "../../services/ClientApi";
import { PMBaseApi } from "../../services/PMBaseApi";
import { timelineSlice } from "../timeline/timelineSilce";
import { phaseTasksSlice } from "./projectDetailSectionSlice";
import { taskSlice } from "./tasksSlice";

// Helper function to construct cache keys based on query parameters
const constructCacheKey = (params) =>
  `getCompletedTaskListing(${JSON.stringify(params)})`;

// Function to update a specific task in the cached completed task listing
const updateCachedCompletedTask = (
  cachedData,
  updatedTask,
  dispatch,
  completedTasksSlice,
  params
) => {
  // Find the index of the task to update in the cached data
  const taskIndex = cachedData.findIndex((task) => task.id === updatedTask.id);
  if (taskIndex !== -1) {
    // If the task exists, update it in the cache using `updateQueryData`
    dispatch(
      completedTasksSlice.util.updateQueryData(
        "getCompletedTaskListing", // The query endpoint to update
        params, // The parameters used to fetch the query data
        (draft) => {
          // Directly modify the draft to update the task
          draft.data[taskIndex] = updatedTask;
        }
      )
    );
  }
};

export const completedTasksSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting completed task listings with pagination
    getCompletedTaskListing: builder.query({
      query: ({ page, limit, orderBy = "", orderDirection = "" }) => ({
        url: `task/completed-listing?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDirection=${orderDirection}`, // API endpoint and query parameters
        method: "POST",
      }),
      providesTags: ["completedTaskListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to create a new completed task (if applicable)
    createCompletedTaskData: builder.mutation({
      // Custom query function to handle the API request
      queryFn: async (formData) => {
        try {
          // Use your original API call to post form data
          const response = await ProjectFormDataAPI.post(
            "task/create-completed",
            formData
          );
          return { data: response.data };
        } catch (error) {
          return {
            error: { status: error.response?.status, data: error.message },
          };
        }
      },
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          // Automatically re-fetch the completed task listing after a task is created
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
        } catch (error) {
          console.error("Error creating completed task:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update completed task data
    updateCompletedTaskData: builder.mutation({
      query: (data) => ({
        url: `task/update`, // API endpoint to update completed task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          if (
            params?.fieldName === "taskStatusId" &&
            params?.fieldValues !== "c8ae646a-5561-4517-bae6-a14c5e2e7345"
          ) {
            dispatch(taskSlice.util.invalidateTags(["taskListing"]));
            dispatch(
              completedTasksSlice.util.invalidateTags(["completedTaskListing"])
            );
          } else {
            const response = await queryFulfilled; // Await the response after mutation
            const updatedTask = response?.data?.data; // Extract updated task data
            const cache = getState()[completedTasksSlice.reducerPath].queries; // Access the Redux cache

            // Construct the cache key using predefined parameters
            const cachedKey = constructCacheKey({
              limit: params?.limit,
              page: params?.page,
            });
            const cachedData = cache[cachedKey]?.data?.data; // Get the cached data if available

            if (cachedData) {
              // Update the cached completed task if found
              updateCachedCompletedTask(
                cachedData,
                updatedTask,
                dispatch,
                completedTasksSlice,
                {
                  limit: params?.limit,
                  page: params?.page,
                }
              );
            }
          }
        } catch (error) {
          console.error("Error updating completed task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update completed task members
    updateCompletedTaskMemberData: builder.mutation({
      query: (data) => ({
        url: `task/member-update`, // API endpoint to update completed task members
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          const response = await queryFulfilled; // Await the response after mutation
          const updatedTask = response?.data?.taskDetail?.task; // Extract updated task data
          const cache = getState()[completedTasksSlice.reducerPath].queries; // Access the Redux cache

          // Construct the cache key using predefined parameters
          const cachedKey = constructCacheKey({
            limit: params?.limit,
            page: params?.page,
          });
          const cachedData = cache[cachedKey]?.data?.data; // Get the cached data if available

          if (cachedData) {
            // Update the cached completed task if found
            updateCachedCompletedTask(
              cachedData,
              updatedTask,
              dispatch,
              completedTasksSlice,
              {
                limit: params?.limit,
                page: params?.page,
              }
            );
          }
        } catch (error) {
          console.error("Error updating completed task member data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to delete completed tasks
    deleteCompletedTaskData: builder.mutation({
      query: (taskIds) => ({
        url: `task/bulk-delete`, // API endpoint for bulk delete of completed tasks
        method: "POST",
        body: { taskIds }, // Send the array of IDs in the request body
      }),
      // Hook to handle cache updates and re-fetching after the mutation is successful
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation
          // Invalidate the completedTaskListing query to trigger re-fetching
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
        } catch (error) {
          console.error("Error deleting completed task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to clear all cached data related to completed tasks
    clearAllCompletedTaskCache: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(completedTasksSlice.util.resetApiState());
        } catch (error) {
          console.error("Error clearing all completed task cache:", error);
        }
      },
    }),
  }),
});

// Export the hooks generated by `completedTasksSlice`
export const {
  useGetCompletedTaskListingQuery,
  useUpdateCompletedTaskDataMutation,
  useUpdateCompletedTaskMemberDataMutation,
  useDeleteCompletedTaskDataMutation,
  useCreateCompletedTaskDataMutation,
  useClearAllCompletedTaskCacheMutation,
} = completedTasksSlice;
