import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Role from "../../../assets/role-arrow.svg";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const DropdownRoles = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const buttonRef = useRef();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    const parentRect = buttonRef.current.getBoundingClientRect();
    setXValue(parentRect.x);
    setYValue(parentRect.y);
    setShowProgress(true);
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      // Click occurred outside the DropButton component
      setShowProgress(false);
    }
  };

  const handleWindowResize = () => {
    if (props.showMore && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setXValue(parentRect.x);
      setYValue(parentRect.y);
    }
  };

  const handleScroll = () => {
    if (buttonRef?.current) {
      const parentRect = buttonRef?.current?.getBoundingClientRect();
      setXValue(parentRect.x);
      setYValue(parentRect.y);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleScroll, { capture: true }); // Add scroll event listener with capture

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleScroll, { capture: true }); // Remove scroll event listener with capture on cleanup
    };
  }, []);

  return (
    <>
      <div
        className="w-100"
        onClick={(e) => buttonClickHandler(e)}
        ref={buttonRef}
      >
        View roles{" "}
        <span style={{ cursor: "pointer" }}>
          <img
            src={Role}
            width={15}
            height={15}
            style={{ transform: "rotate(90deg)" }}
          />
        </span>
      </div>
      {showProgress && (
        <Portal>
          <div
            style={{
              position: "absolute",
              left: `${xValue}px`,
              top: `${yValue + 20}px`,
              listStyle: "none",
              background: "#fff", // Set background color
              border: "1px solid #ccc",
              fontSize: "14px",
              fontWeight: 300,
            }}
            className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
          >
            {props.roles.map((res, id) => (
              <p style={{ marginBottom: "0px", padding: "0.25rem" }}>
                {capitalizeFirstLetter(res)}
              </p>
            ))}
          </div>
        </Portal>
      )}
    </>
  );
};

export default DropdownRoles;
