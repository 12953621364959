import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import Delete from "../../../assets/delete-icon.png";
import PSKPIDeleteModal from "../modals/PSKPIDeleteModal";

function PSKPIMainCard({
  data,
  isDark,
  handleSetUpdateKPIId,
  handleUpdateIsShowAddNewKPI,
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(null);

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="card main-card mb-4">
        <Tooltip id="pskpi-my-tooltip" />
        <div className="card-body ">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <h4 className="kpi-main-card-section-title mb-0 flex-grow-1">
                {data?.performanceTemplate?.templateName
                  ?.split(" ")
                  .map((word, index) =>
                    index === 0 ? word : <span key={index}> {word}</span>
                  )}
              </h4>
              {data?.performanceTemplate?.isDefault ? (
                <div
                  className="ms-2 performance-default-tag"
                  data-tooltip-id="pskpi-my-tooltip"
                  data-tooltip-content={
                    "System default appraisal module to evaluate performance of your team. You may create custom model if this does not suite your company policy"
                  }
                >
                  <IoIosInformationCircleOutline size={20} /> Default
                </div>
              ) : (
                <button
                  className="info-icon ms-2"
                  data-tooltip-id="pskpi-my-tooltip"
                  data-tooltip-content={
                    data?.performanceTemplate?.isDefault
                      ? "System default appraisal module to evaluate performance of your team. You may create custom model if this does not suite your company policy"
                      : "Evaluate performance of your team"
                  }
                >
                  <IoIosInformationCircleOutline size={20} />
                </button>
              )}
            </div>
            {/* <button className="performance-card-menu-btn">
            <Icon icon="carbon:overflow-menu-vertical" />
          </button> */}
            <Dropdown className="roles-drop-down roles-drop-role pt-menu-dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <button className="performance-card-menu-btn">
                  <Icon
                    icon="iconamoon:menu-kebab-vertical-bold"
                    fontSize={24}
                  />
                </button>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleSetUpdateKPIId(data?.performanceTemplate?.id);
                    handleUpdateIsShowAddNewKPI(true);
                  }}
                >
                  <Icon icon="carbon:view" fontSize={20} className="me-2" />
                  View Model
                </Dropdown.Item>
                {/* </Dropdown.Menu>
            <Dropdown.Menu> */}
                {!data?.performanceTemplate?.isDefault && (
                  <Dropdown.Item
                    onClick={() => {
                      setDeleteTemplate(data?.performanceTemplate);
                      setOpenDeleteModal(true);
                    }}
                  >
                    <img
                      src={Delete}
                      alt="Delete"
                      width={20}
                      height={20}
                      className="me-2"
                    />
                    Delete Model
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* checkbox and label  */}
          <div className="mb-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input md-3 mt-0"
                style={{
                  width: "19px",
                  height: "19px",
                }}
                id="allowPoints"
                checked={data?.taskApplyRules}
                // onChange={(e) => setAllowPoints(e.target.checked)}
              />
              <label
                className="d-flex align-items-center form-check-label text-secondary ms-1 gap-2"
                htmlFor="allowPoints"
              >
                Allow points for tasks with the same owner and member.{" "}
                <IoIosInformationCircleOutline
                  size={20}
                  data-tooltip-id="pskpi-my-tooltip"
                  data-tooltip-content="This will allow user to get points even when he is creating and self assigning a task"
                />
              </label>
            </div>
          </div>
          <div className="kpi-card-points-section pt-2 pb-2 mt-3">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex align-items-center">
                <h4 className="kpi-main-card-section-title2 mb-0 flex-grow-1">
                  Points <span>for Task Assignee</span>
                </h4>
                <button
                  className="info-icon ms-2"
                  data-tooltip-id="pskpi-my-tooltip"
                  data-tooltip-content="Team/employees having tasks to be completed will be judged on these points"
                >
                  <IoIosInformationCircleOutline size={20} />
                </button>
              </div>
            </div>
            <p className="text-secondary description-text">
              The person responsible for completing the task will earn or lose
              points based on timely completion or delays.
            </p>
          </div>
          <div className=" pt-2 pb-2 mt-1">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex align-items-center">
                <h4 className="kpi-main-card-section-title2 mb-0 flex-grow-1">
                  Points <span>for Task Owner</span>
                </h4>
                <button
                  className="info-icon ms-2"
                  data-tooltip-id="pskpi-my-tooltip"
                  data-tooltip-content="Creator or owner of a task getting points if he will manage to get that task done by his team/employee"
                >
                  <IoIosInformationCircleOutline size={20} />
                </button>
              </div>
            </div>
            <p className="text-secondary description-text">
              The person responsible for completing the task will earn or lose
              points based on timely completion or delays.
            </p>
          </div>
        </div>
      </div>
      {openDeleteModal && (
        <PSKPIDeleteModal
          isShow={openDeleteModal}
          handleClose={handleClose}
          isDark={isDark}
          template={deleteTemplate}
        />
      )}
    </>
  );
}

export default PSKPIMainCard;
