import React from "react";
import LiveChat from "./components/LiveChat";

const LiveChatMain = (props) => {
  return (
    <LiveChat
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default LiveChatMain;
