// Constants for teams list actions
export const TEAMS_LIST_REQUEST = "TEAMS_LIST_REQUEST";
export const TEAMS_LIST_SUCCESS = "TEAMS_LIST_SUCCESS";
export const TEAMS_LIST_OPTIONS_SUCCESS = "TEAMS_LIST_OPTIONS_SUCCESS";
export const TEAMS_LIST_FAIL = "TEAMS_LIST_FAIL";

// Constants for team form list
export const TEAMS_FORM_LIST_REQUEST = "TEAMS_FORM_LIST_REQUEST";
export const TEAMS_FORM_LIST_SUCCESS = "TEAMS_FORM_LIST_SUCCESS";
export const TEAMS_FORM_LIST_OPTIONS_SUCCESS =
  "TEAMS_FORM_LIST_OPTIONS_SUCCESS";
export const TEAMS_FORM_LIST_FAIL = "TEAMS_FORM_LIST_FAIL";
