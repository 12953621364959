// For projects
export const isOwnerInProject = (projects, projectId, userId) => {
  // Find the project with the provided projectId
  const project = projects.find((project) => project.projectId === projectId);

  // If project is found, find the member with the provided userId and memberType "owner"
  if (project) {
    const member = project?.projectMembers?.find(
      (member) => member.userId === userId && member.memberType === "owner"
    );
    // Return true if member is found and is an owner, otherwise return false
    return !!member;
  }

  // Return false if project with the provided projectId is not found
  return false;
};

// For tasks
export const isOwnerInTasks = (tasks, taskId, userId) => {
  // Find the task with the provided taskId
  const task = tasks?.find((task) => task?.id === taskId);

  // If task is found, find the member with the provided userId and memberType "owner"
  if (task) {
    const member = task?.taskMembers?.find(
      (member) => member.userId === userId && member.memberType === "owner"
    );
    // Return true if member is found and is an owner, otherwise return false
    return !!member;
  }

  // Return false if task with the provided taskId is not found
  return false;
};

// For projects using single object
export const isOwnerInProjectOne = (project, userId) => {
  // If project is found, find the member with the provided userId and memberType "owner"
  if (project) {
    const member = project?.projectMembers?.find(
      (member) => member.userId === userId && member.memberType === "owner"
    );
    // Return true if member is found and is an owner, otherwise return false
    return !!member;
  }

  // Return false if project with the provided projectId is not found
  return false;
};

// For tasks using single object
export const isOwnerInTaskOne = (task, userId) => {
  // If task is found, find the member with the provided userId and memberType "owner"
  if (task) {
    if (task?.taskMembers) {
      const member = task.taskMembers.find(
        (member) => member.userId === userId && member.memberType === "owner"
      );
      // Return true if member is found and is an owner, otherwise return false
      return !!member;
    } else {
      const member = task.users.find(
        (member) => member.user_id === userId && member.memberType === "owner"
      );
      // Return true if member is found and is an owner, otherwise return false
      return !!member;
    }
  }

  // Return false if task with the provided taskId is not found
  return false;
};

export const isMemberOfTask = (task, userId) => {
  // If task is found, find the member with the provided userId and memberType "owner"
  if (task) {
    if (task?.taskMembers) {
      const member = task.taskMembers.find(
        (member) => member.userId === userId
      );
      // Return true if member is found and is an owner, otherwise return false
      return !!member;
    } else {
      const member = task.users.find((member) => member.user_id === userId);
      // Return true if member is found and is an owner, otherwise return false
      return !!member;
    }
  }
};

// Function to check if the user is an observer of the task
export const isObserver = (task, userId) => {
  if (task) {
    if (task?.taskMembers) {
      const member = task.taskMembers.find(
        (member) => member.userId === userId && member.memberType === "observer"
      );
      return !!member; // Returns true if observer found, else false
    } else {
      return false; // Default to false if task or member not found
    }
  }
};
