import React, { useState } from "react";
import { Modal, Spinner, Tooltip } from "react-bootstrap";
import icon from "../../../assets/perf_down_modal_icon.png";
import { useGetStaffSummaryReportQuery } from "../../../slices/projects/projectSlice";
import { downloadStaffSummaryAsExcel } from "../../../helper/downloadFileHelpers";
import { toast } from "react-toastify";

function PerformanceDownloadModal({
  isDark,
  show,
  handleCloseModal,
  typeTask,
}) {
  const [reportType, setReportType] = useState("this_month");
  const { data: fetchedData, isLoading } = useGetStaffSummaryReportQuery(
    {
      filterType: reportType,
      sendEmail: false,
    },
    { skip: !show, keepUnusedDataFor: 0 }
  );
  const [excelLoad, setExcelLoad] = useState(false);

  const handleDownloadExcel = async () => {
    try {
      setExcelLoad(true);
      const tasks = fetchedData?.data || [];

      if (tasks?.length === 0) {
        toast.warn("No members available to download.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }

      downloadStaffSummaryAsExcel(tasks, typeTask);
      handleCloseModal();
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to download tasks.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      setExcelLoad(false);
    }
  };
  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="performance-down-modal"
    >
      <Tooltip id="my-tooltip" />

      <Modal.Body>
        <div className="text-center">
          <img
            src={icon}
            alt="Download Icon"
            className="mb-4"
            width={70}
            height={70}
          />
          <h5
            style={{
              color: "var(--font-color)",
            }}
            className="ps-4 pe-4"
          >
            Which performance report would you like to download?
          </h5>
        </div>
        <div className="d-flex justify-content-around mt-4 mb-2">
          <button
            className={
              reportType === "this_week"
                ? "performance-down-modal-report-btn-active"
                : "performance-down-modal-report-btn"
            }
            onClick={() => {
              setReportType("this_week");
            }}
          >
            Weekly Report
          </button>
          <button
            className={
              reportType === "this_month"
                ? "performance-down-modal-report-btn-active"
                : "performance-down-modal-report-btn"
            }
            onClick={() => {
              setReportType("this_month");
            }}
          >
            Monthly Report
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer className="edit-profile-modal-footer">
        <button
          className="edit-profile-modal-footer-button-2"
          onClick={handleCloseModal}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="edit-profile-modal-footer-button-1"
          onClick={handleDownloadExcel}
          disabled={isLoading || excelLoad || fetchedData?.data?.length === 0}
        >
          Download {excelLoad && <Spinner size="sm" className="ms-1" />}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PerformanceDownloadModal;
