import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../assets/infoMain.svg";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const ConfirmAddonModal = (props) => {
  const navigate = useNavigate();
  return props?.planData?.currentStatus === "Trial" ||
    (props?.planData?.subscriptionStatus !== "Subscribed" &&
      props?.planData?.currentStatus !== "Trial") ? (
    <Modal
      show={props.showAddModal}
      onHide={() => props.setShowAddModal(false)}
      centered
      size="md"
      data-theme={props?.isDark}
      backdrop="static"
      keyboard={false}
      className="main-delete-modal"
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}
        {/* <button className="custom-modal-close-btn" onClick={() => props.setShowAddModal(false)}>
          <Icon icon="maki:cross" />
        </button> */}

      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        {/* <h5>Verify Email</h5>
          <p>To access the system, please verify your email</p> */}
        <div className="del-modal-img-container">
          <img src={DeleteIcon} />
        </div>
        <h5>Subscription required</h5>
        <p>Please subscribe a package to perform this action</p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={() => {
              navigate(
                `/${localStorage.getItem("company")}/company-subscriptions`
              );
              props.setShowAddModal(false);
            }}
          >
            Ok
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={() => props.setShowAddModal(false)}
          >
            Close
          </button>
        </div>
        {/* <button
            className="cancel-email-btn"
            onClick={() => setShowVerifyEmailModal(false)}
          >
            Cancel
          </button> */}
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={props.showAddModal}
      onHide={() => props.setShowAddModal(false)}
      centered
      size="md"
      data-theme={props?.isDark}
      backdrop="static"
      keyboard={false}
      className="main-delete-modal"
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}

      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        {/* <h5>Verify Email</h5>
          <p>To access the system, please verify your email</p> */}
        <div className="del-modal-img-container">
          <img src={DeleteIcon} />
        </div>
        <h5>Seat limit reached</h5>
        <p>
          You have reached the seat limit. Please add more seats to invite more
          members.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={() => {
              props.handleAddonShow();
              props.setShowAddModal(false);
            }}
          >
            Ok
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={() => props.setShowAddModal(false)}
          >
            Close
          </button>
        </div>
        {/* <button
            className="cancel-email-btn"
            onClick={() => setShowVerifyEmailModal(false)}
          >
            Cancel
          </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAddonModal;
