export const COMPANY_LISTING_REQUEST = "COMPANY_LISTING_REQUEST";
export const COMPANY_LISTING_SUCCESS = "COMPANY_LISTING_SUCCESS";
export const COMPANY_LISTING_FAIL = "COMPANY_LISTING_FAIL";

export const INVITEE_LISTING_REQUEST = "INVITEE_LISTING_REQUEST";
export const INVITEE_LISTING_SUCCESS = "INVITEE_LISTING_SUCCESS";
export const INVITEE_LISTING_FAIL = "INVITEE_LISTING_FAIL";

export const COMPANY_NAME_REQUEST = "COMPANY_NAME_REQUEST";
export const COMPANY_NAME_SUCCESS = "COMPANY_NAME_SUCCESS";
export const COMPANY_NAME_FAIL = "COMPANY_NAME_FAIL";

export const COMPANY_MODAL_DATA_REQUEST = "COMPANY_MODAL_DATA_REQUEST";
export const COMPANY_MODAL_DATA_SUCCESS = "COMPANY_MODAL_DATA_SUCCESS";
export const COMPANY_MODAL_DATA_FAIL = "COMPANY_MODAL_DATA_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
