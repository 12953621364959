import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoMdArrowRoundBack } from "react-icons/io";
import clockreport from "../../../assets/clock-report.svg";
import performanceimageicon from "../../../assets/performance-image-1.svg";
import performanceimageicon1 from "../../../assets/performance-image-2.svg";
import {
  dateFormatter,
  formatDateToYYYYMMDD,
} from "../../../helper/dateFormatter";
import userProfileBlank from "../../../assets/blank_profile.svg";
import { formatLabel } from "../../../helper/taskHelpers";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import upIcon from "../../../assets/performance_up_icon.png";
import downIcon from "../../../assets/performance_down_icon.png";
import HowPerformanceWorksModal from "../modals/HowPerformanceWorksModal";
import { useLocation, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { useIsMobile } from "../../../helper/windowHelper";
import { BottomSheet } from "react-spring-bottom-sheet";
import seetaskicon from "../../../assets/see-task-list.svg";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const ReportProfile = ({
  props,
  selectedWeek,
  setSelectedWeek,
  userProfile,
  loggedInUser,
  dailyReportData,
  filterType,
  setFilterType,
  selectedDateFilter,
  setSelectedDateFilter,
  isLoading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  bottomSheetOpen,
  setBottomSheetOpen,
  handleStatNavigation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shareReportLoading, setShareReportLoading] = useState(false);
  const [localFilterType, setLocalFilterType] = useState(filterType);
  const [showDropdown, setShowDropdown] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const allowedRoutes = ["performance-report", "team-performance"];
  return (
    <div className="">
      <div className="calendar-date-wrap d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center flex-wrap">
          {allowedRoutes.some((route) =>
            location.state?.from.endsWith(route)
          ) && (
            <button
              className="ms-0 me-2 p-0 bg-transparent border-0 mb-1" // Add a class for styling
              onClick={() => navigate(-1)} // Go back to the previous page
            >
              <IoMdArrowRoundBack size={23} color="#999999" />
            </button>
          )}
          <div className="dropdown-date">
            <DatePickerSection
              bottomSheetOpen={bottomSheetOpen}
              setBottomSheetOpen={setBottomSheetOpen}
              setLocalFilterType={setLocalFilterType}
              setFilterType={setFilterType}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setSelectedDateFilter={setSelectedDateFilter}
              localFilterType={localFilterType}
              selectedDateFilter={selectedDateFilter}
              isDark={props.isDark}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              filterType={filterType}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 flex-wrap">
          <button
            disabled={isLoading || shareReportLoading}
            onClick={async () => {
              setOpenModal(true);
            }}
            className="download-excel-btn d-flex align-items-center"
          >
            <i
              className="fas fa-circle-info"
              style={{ marginRight: "8px" }}
            ></i>
            How it works?
          </button>
          <button
            disabled={isLoading || shareReportLoading}
            onClick={async () => {
              try {
                setShareReportLoading(true);
                const res = await ProjectAPI.get(
                  `/task/daily-summary?filterType=${filterType}${
                    filterType === "date"
                      ? `&selectedDate=${formatDateToYYYYMMDD(
                          selectedDateFilter
                        )}`
                      : ""
                  }&sendEmail=true`
                );

                toast.success(res?.data?.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 300,
                  hideProgressBar: true,
                  closeButton: false,
                });
              } catch (error) {
                toast.error(error?.response?.data?.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 300,
                  hideProgressBar: true,
                  closeButton: false,
                });
              } finally {
                setShareReportLoading(false);
              }
            }}
            className="download-excel-btn d-flex align-items-center"
          >
            <i className="fas fa-share" style={{ marginRight: "8px" }}></i>
            Share Report
            {shareReportLoading ? (
              <Spinner size="sm" className="ms-1 global-spinner" />
            ) : (
              ""
            )}{" "}
          </button>
        </div>
      </div>

      <div className="profile-daily-report-wrap">
        <div className="daily-profile-main-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="profile-image-report-wrap">
                <div className="profile-image-report">
                  <img
                    src={
                      userProfile?.profileImage
                        ? userProfile?.profileImage
                        : userProfileBlank
                    }
                    alt="no-data"
                    style={{
                      objectFit: "cover",
                      height: "100%",
                    }}
                  />
                </div>
                <div className="profile-image-text">
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (
                        loggedInUser?.roles?.some(
                          (role) => role.roleType === "admin"
                        )
                      ) {
                        // navigate(
                        //   `/${localStorage.getItem(
                        //     "company"
                        //   )}/user-profile?id=${userProfile?.userId}`
                        // );
                        saveLocalStorageToCookies(
                          `/user-profile?id=${userProfile?.userId}`
                        );
                      }
                    }}
                  >
                    {userProfile?.userName}
                  </h3>
                  <span>{userProfile?.designation}</span>
                  <ul className="mb-3">
                    {/* {userProfile?.dayStartTime?.length > 0 && (
                      <li>
                        {" "}
                        <img src={clockreport} alt="no-data" /> Sign In:{" "}
                        <strong>
                          {dateFormatter(
                            new Date(userProfile?.dayStartTime),
                            3
                          )}
                        </strong>
                      </li>
                    )}
                    {userProfile?.dayEndTime?.length > 0 && (
                      <li>
                        {" "}
                        <img src={clockreport} alt="no-data" /> Sign out:{" "}
                        <strong>
                          {dateFormatter(new Date(userProfile?.dayEndTime), 3)}
                        </strong>
                      </li>
                    )} */}
                    {userProfile?.lastActiveDate && (
                      <div className="last-report-profile mt-0">
                        Last Active:{" "}
                        <strong>
                          {dateFormatter(userProfile?.lastActiveDate)}
                        </strong>
                      </div>
                    )}
                  </ul>
                  <a
                    onClick={handleStatNavigation}
                    style={{ cursor: "pointer" }}
                    href=""
                    className="see-task-list-btn"
                  >
                    {" "}
                    <img src={seetaskicon} alt="no-data" /> See Task List
                  </a>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="performance-total-main-wrap">
                <ul>
                  <li className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="performance-total-list-wrap">
                      {" "}
                      <span>
                        {" "}
                        <img src={performanceimageicon} alt="no-data" />{" "}
                      </span>{" "}
                      Performance{" "}
                    </div>
                    <div className="me-lg-4 me-0 performance-total-list-wrap">
                      <strong
                        style={{
                          color:
                            dailyReportData?.performanceStatus === 1
                              ? "#2098D1"
                              : "#FF6363",
                        }}
                      >
                        {dailyReportData?.totalTask &&
                        dailyReportData?.totalTask > 0
                          ? `${dailyReportData?.performance}%`
                          : "---"}{" "}
                        {dailyReportData?.totalTask &&
                        dailyReportData?.totalTask > 0 ? (
                          <img
                            height={20}
                            width={20}
                            src={
                              dailyReportData?.performanceStatus === 1
                                ? upIcon
                                : downIcon
                            }
                            alt="no-data"
                          />
                        ) : (
                          ""
                        )}{" "}
                      </strong>
                    </div>
                  </li>
                  <li className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="performance-total-list-wrap">
                      {" "}
                      <span>
                        {" "}
                        <img src={performanceimageicon1} alt="no-data" />{" "}
                      </span>{" "}
                      Total Points{" "}
                    </div>
                    <div className="me-lg-4 me-0 performance-total-list-wrap">
                      <strong>
                        {" "}
                        {dailyReportData?.totalTask &&
                        dailyReportData?.totalTask > 0
                          ? dailyReportData?.assigneeTotalObtainedScore +
                            dailyReportData?.ownerTotalObtainedScore
                          : "---"}{" "}
                      </strong>{" "}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HowPerformanceWorksModal
        show={openModal}
        handleCloseModal={() => setOpenModal(false)}
        isDark={props.isDark}
      />
    </div>
  );
};

const DatePickerSection = ({
  bottomSheetOpen,
  setBottomSheetOpen,
  setLocalFilterType,
  setFilterType,
  setStartDate,
  setEndDate,
  setSelectedDateFilter,
  localFilterType,
  selectedDateFilter,
  isDark,
  setShowDropdown,
  showDropdown,
  filterType,
}) => {
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const { isMobile } = useIsMobile();
  // Date Selection
  const fullDateToString = (inputDateString) => {
    if (!inputDateString) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }

    const inputDate = new Date(inputDateString);

    // Check if inputDate is valid
    if (isNaN(inputDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based
    const year = inputDate.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  const handleFilterChange = (type) => {
    if (type === "date_range") {
      setLocalFilterType(type);
      if (isMobile) {
        setBottomSheetOpen(true);
      }
    } else {
      setLocalFilterType(type);
      setFilterType(type);
      setStartDate(null);
      setEndDate(null);
      setSelectedDateFilter(type === "today" ? new Date() : null); // Reset selected date for other filters
      setShowDropdown(false); // Close dropdown
      setBottomSheetOpen(false);
    }
  };

  const shortcutsItems = [
    {
      label: "Tasks of This Week",
      getValue: () => handleFilterChange("this_week"),
      value: "this_week",
    },
    {
      label: "Tasks of This Month",
      getValue: () => handleFilterChange("this_month"),
      value: "this_month",
    },
    {
      label: "Tasks of Last Month",
      getValue: () => handleFilterChange("last_month"),
      value: "last_month",
    },
    {
      label: "Date Range",
      getValue: () => handleFilterChange("date_range"),
      value: "date_range",
    },
  ];

  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
    e.preventDefault();
    if (!isMobile) {
      setShowDropdown(!showDropdown);
    } else {
      setBottomSheetOpen(true);
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      data-theme={isDark}
      className="project-details-main-date-filter d-flex align-items-center justify-content-between position-relative mb-2 mt-1"
      onClick={handleIconClick}
    >
      {(localFilterType !== "all" || selectedDateFilter) && (
        <div className="selected-filter-text-container-date">
          <Icon
            icon="mdi:cross-circle"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDateFilter(null);
              setStartDate(null);
              setEndDate(null);
              setFilterType("all");
              setLocalFilterType("all");
            }}
          />
        </div>
      )}

      {/* Icon */}
      <div
        className={`d-flex align-items-center ps-2 select-item-date   ${
          filterType === "all" ? "" : ""
        }`}
        style={{
          cursor: "pointer",
        }}
      >
        <span className="me-2">
          <Icon icon="lets-icons:date-range" ref={iconRef} />
        </span>
        <p className="p-0 m-0">
          {localFilterType === "all"
            ? "Due date"
            : localFilterType === "date"
            ? dateFormatter(selectedDateFilter, 1)
            : formatLabel(localFilterType) === "Today"
            ? `${formatLabel(localFilterType)} - ${dateFormatter(
                selectedDateFilter,
                1
              )}`
            : formatLabel(localFilterType)}
        </p>
        <div className="select-icon me-1 ms-4 select-icon-date">
          <IoIosArrowDown />
        </div>
      </div>

      {/* Dropdown */}
      {isMobile ? (
        <BottomSheet
          open={bottomSheetOpen}
          blocking={true}
          onDismiss={() => setBottomSheetOpen(false)}
        >
          <div className="bottom-sheet-content" data-theme={isDark}>
            {/* Shortcuts Column */}
            <div className="custom-date-picker-dropdown-shortcuts-mobile">
              {shortcutsItems.map((shortcut) => (
                <button
                  key={shortcut.label}
                  className={`${
                    localFilterType === shortcut.value
                      ? "selected-shortcut-mobile"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent closing the BottomSheet
                    shortcut.getValue();
                  }}
                >
                  {shortcut.label}
                </button>
              ))}
            </div>

            {/* Date Picker Column */}
            <Calendar
              onChange={(date) => {
                if (localFilterType === "date_range" && date[0] && date[1]) {
                  setSelectedDateFilter(date);
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                  setFilterType("date_range");
                  setLocalFilterType("date_range");
                  setBottomSheetOpen(false);
                  console.log("====================================");
                  console.log("bottomSheetOpen", "date is picked");
                  console.log("====================================");
                } else if (localFilterType !== "date_range") {
                  setSelectedDateFilter(date);
                  setFilterType("date");
                  setLocalFilterType("date");
                  setBottomSheetOpen(false);
                  console.log("====================================");
                  console.log("bottomSheetOpen", "date is picked");
                  console.log("====================================");
                }
              }}
              selectRange={localFilterType === "date_range"}
              value={selectedDateFilter}
              className="react-calendar react-calender-mobile"
            />
          </div>
        </BottomSheet>
      ) : (
        showDropdown && (
          <div
            ref={dropdownRef}
            className="dropdown-menu py-0 show d-flex custom-date-picker-dropdown"
            // Prevent event bubbling up from inside dropdown
            onClick={(e) => e.stopPropagation()}
          >
            {/* Shortcuts Column */}
            <div className="custom-date-picker-dropdown-shortcuts">
              {shortcutsItems.map((shortcut) => (
                <button
                  key={shortcut.label}
                  className={`btn btn-link text-start position-relative p-0 ${
                    localFilterType === shortcut.value
                      ? "selected-shortcut"
                      : ""
                  }`}
                  onClick={() => shortcut.getValue()}
                >
                  {shortcut.label}
                  {localFilterType === shortcut.value &&
                    localFilterType === "date_range" && (
                      <div
                        style={{
                          top: "30%",
                        }}
                        className="selected-filter-text-container-date"
                      >
                        <Icon
                          icon="mdi:cross-circle"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedDateFilter(new Date());
                            setStartDate(null);
                            setEndDate(null);
                            setFilterType("this_month");
                            setLocalFilterType("this_month");
                          }}
                        />
                      </div>
                    )}
                </button>
              ))}
            </div>

            {/* Date Picker Column */}

            <Flatpickr
              data-theme={isDark}
              className="project-details-task-table-flatpickr-container"
              placeholder="MMM DD, YYYY"
              options={{
                allowInput: false, // Disable manual input
                altInput: false, // Prevent rendering of alternative input
                inline: true, // Keep calendar always visible
                altFormat: "M d, Y",
                dateFormat: "Y-m-d",
                static: true,
                disableMobile: true,
                clickOpens: false, // Optional: Disable opening on input click
                mode: localFilterType === "date_range" ? "range" : "single",
                maxDate: new Date(),
              }}
              value={selectedDateFilter}
              onChange={(date) => {
                if (localFilterType === "date_range" && date[0] && date[1]) {
                  setSelectedDateFilter(date);
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                  setFilterType("date_range");
                  setLocalFilterType("date_range");
                } else if (localFilterType !== "date_range") {
                  setSelectedDateFilter(date[0]);
                  setFilterType("date");
                  setLocalFilterType("date");
                }
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

export default ReportProfile;
