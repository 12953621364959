// Login request
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Register request
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

// Load user
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

// Logout user
export const LOGOUT_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOAD_USER_FAIL";

// Auth Screen content
export const LOAD_AUTH_ONBOARDING_CONTENT_REQUEST = "LOAD_AUTH_ONBOARDING_CONTENT_REQUEST";
export const LOAD_AUTH_ONBOARDING_CONTENT_SUCCESS = "LOAD_AUTH_ONBOARDING_CONTENT_SUCCESS";
export const LOAD_AUTH_ONBOARDING_CONTENT_FAIL = "LOAD_AUTH_ONBOARDING_CONTENT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
