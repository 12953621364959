import React, { createContext, useContext, useState } from "react";
import RetryModal from "./RetryModal"; // Use your existing RetryModal component

let retryModalFn;

export const RetryProvider = ({ children }) => {
  const [retryConfig, setRetryConfig] = useState({
    show: false,
    callback: null,
  });

  retryModalFn = (callback) => {
    setRetryConfig({ show: true, callback });
  };

  const [loading, setLoading] = useState(false);

  const showRetryModal = (callback) => {
    setRetryConfig({ show: true, callback });
  };

  const retry = async () => {
    if (retryConfig.callback) {
      setLoading(true); // Start loading
      try {
        const res = await retryConfig.callback(); // Attempt the retry
        if (res) {
          setRetryConfig({ show: false, callback: null }); // Close the modal on success
        }
      } catch (error) {
        console.log("Retry failed:", error);
        setRetryConfig({ show: true, callback: retryConfig.callback }); // Keep modal open if retry fails
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const close = () => {
    setRetryConfig({ show: false, callback: null });
    setLoading(false);
  };

  return (
    <>
      <RetryModal
        show={retryConfig.show}
        onRetry={retry}
        onClose={close}
        loading={loading}
      />
      {children}
    </>
  );
};

export const getRetryModal = () => retryModalFn;
