import React from "react";
import { useGetCompanyProfileQuery } from "../../../slices/user/userSlice";

function useCompanyProfileMain() {
  const { data: companyProfile, isLoading: isCompanyProfileLoading } = useGetCompanyProfileQuery();

  return { companyProfile: companyProfile?.data,isCompanyProfileLoading };
}

export default useCompanyProfileMain;
