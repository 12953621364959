import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { calculateDiscountedPrice } from "../../../helper/getDiscountedPrice";
import Icon1 from "../../../assets/icon1.svg";
import Icon2 from "../../../assets/icon2.svg";
import Icon3 from "../../../assets/icon3.svg";
import Icon4 from "../../../assets/icon4.svg";
import DownArrow from "../../../assets/arrow-down.svg";
import BackIcon from "../../../assets/back.svg";
import { dateFormatter } from "../../../helper/dateFormatter";
import SelectUserModal from "../../../components/SelectUserModal/SelectUserModal";
import PackageCard from "./PackageCard";

const OverviewSubscriptionModal = ({
  showPackageModal,
  handleClosePackageModal,
  summery,
  userModal,
  isDark,
  selectedUser,
  setUserModal,
  setUserPro,
  setSummery,
  userpro,
  applicationName,
  setSeat,
  seat,
  seats,
  minSeats,
  selectedPlan,
  packageData,
  fullPackage,
  packageName,
  selectedPackage,
  setPackageName,
  setPackageDataOverview,
  packageDataOverview,
  handleReSubscribe,
  openModal,
  load,
  getPrice,
  inter,
  billingDetail,
  setSelectedPackage,
  setSelectedName,
  setMinSeats,
  setOpenContact,
  userCount,
  selectedPackageDisplayOrder,
  setNewDisplayOrder,
  setFullPackage,
  show,
  handleShowTab,
  setSelectedPlan,
  setInter,
  loadbtn,
  selectedName,
  sumLoad,
  setSelectedUser,
  selectedUserIds,
  setSelectedUserIds,
  removedUserIds,
  setRemovedUserIds,
  setUsers,
  limit,
  setLimit,
  users,
  planData,
  amt,
  setAmt,
}) => {
  const currentDate = new Date();
  const nextYearDate = new Date(currentDate);
  nextYearDate.setFullYear(currentDate.getFullYear() + 1);

  const nextMonthDate = new Date(currentDate);
  nextMonthDate.setMonth(currentDate.getMonth() + 1);

  return (
    <Modal
      show={showPackageModal}
      onHide={handleClosePackageModal}
      className={`package-modal ${
        summery ? "update-package-summery-modal add-sum-modal" : ""
      } ${userModal ? "user-select-modal" : ""}`}
      data-theme={isDark}
      animation={false}
      centered
    >
      {/* Your Modal content here */}
      <Modal.Header closeButton className="update-modal-header">
        {!summery && !userModal ? (
          <div className="modal-heading-wrap">
            <h3>
              {" "}
              {/* <span>Choose</span> Your Plan{" "} */}
              <span>Select A Plan</span> For Your Team{" "}
            </h3>
          </div>
        ) : (
          <div className="d-flex align-items-center add-on-popup-wraper">
            {summery && (
              <>
                {planData?.currentStatus !== "Trial" && (
                  <div
                    className="go-back"
                    onClick={() => {
                      if (selectedUser?.length > 0) {
                        setUserModal(true);
                        setUserPro(true);
                        setSummery(false);
                      } else {
                        setSummery(false);
                      }
                    }}
                  >
                    <img src={BackIcon} alt="back-icon" />
                  </div>
                )}
                <h2>
                  {" "}
                  Plan <span>Summary</span>{" "}
                </h2>
              </>
            )}
            {userModal && (
              <>
                <div
                  className="go-back"
                  onClick={() => {
                    if (userpro === true) {
                      setUserPro(false);
                    } else {
                      setUserModal(false);
                    }
                  }}
                >
                  <img src={BackIcon} alt="back-icon" />
                </div>
                {userpro ? (
                  <h2>
                    {" "}
                    Selected <span>Team</span>{" "}
                  </h2>
                ) : (
                  <h2>
                    {" "}
                    Downgrade <span>Package</span>{" "}
                  </h2>
                )}
              </>
            )}
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="update-modal-body">
        {/* Your Modal body content here */}
        {/* For example, the content you provided */}
        {summery && <hr style={{ marginTop: "5px" }} />}
        {!summery && !userModal ? (
          <div className="package-listing-new-wraper modal-package-list">
            {/* <h2> Select the best plan for your team </h2> */}
            <p>
              {" "}
              Thank you for choosing {applicationName}! You have 7 day left on
              your free trial.
            </p>
            <div className="form-group">
              <label for="inputEmail3">Your team size:</label>
              <div className="seats-form-wrap">
                <select
                  onChange={(e) => setSeat(e.target.value)}
                  className="form-control"
                  value={seat}
                >
                  {seats
                    ?.filter((s) => (isNaN(minSeats) ? true : s >= minSeats))
                    .map((s, id) => (
                      <option key={id} value={s}>
                        {s < 50 ? s : s + "+"} seats
                      </option>
                    ))}
                </select>
              </div>

              <div className="plan-seelct">
                {" "}
                <a
                  className={`${selectedPlan === 2 ? "selected-plan" : ""} `}
                  onClick={() => {
                    setSelectedPlan(2);
                    setInter("Year");
                  }}
                >
                  {" "}
                  SAVE 18% Yearly{" "}
                </a>{" "}
                <a
                  className={`${selectedPlan === 1 ? "selected-plan" : ""} `}
                  onClick={() => {
                    setSelectedPlan(1);
                    setInter("Month");
                  }}
                >
                  {" "}
                  Monthly{" "}
                </a>{" "}
              </div>
            </div>
            <div className="new-package-radio-btn">
              {" "}
              <div className="pacakage-listing-main-wraper package-listing-modal-wrap">
                <ul className="row">
                  {packageData?.map(
                    (res, id) =>
                      res?.packageName !== "Free" && (
                        <div className="col-lg-3 col-md-6 package-wrap-modal">
                          <PackageCard
                            res={res}
                            id={id}
                            fullPackage={fullPackage}
                            packageName={packageName}
                            selectedPackage={selectedPackage}
                            setPackageName={setPackageName}
                            setPackageDataOverview={setPackageDataOverview}
                            packageDataOverview={packageDataOverview}
                            handleReSubscribe={handleReSubscribe}
                            handleClosePackageModal={handleClosePackageModal}
                            openModal={openModal}
                            load={load}
                            getPrice={getPrice}
                            inter={inter}
                            seat={seat}
                            billing={billingDetail}
                            setSelectedPackage={setSelectedPackage}
                            setSummery={setSummery}
                            setSelectedName={setSelectedName}
                            setMinSeats={setMinSeats}
                            setSeat={setSeat}
                            setOpenContact={setOpenContact}
                            userCount={userCount}
                            setUserModal={setUserModal}
                            selectedPackageDisplayOrder={
                              selectedPackageDisplayOrder
                            }
                            setNewDisplayOrder={setNewDisplayOrder}
                            setFullPackage={setFullPackage}
                            amt={amt}
                            setAmt={setAmt}
                          />
                          {res?.packageName !== "Enterprice" && (
                            <label className="customradio-role sub-radio">
                              <input
                                type="radio"
                                className="modal-radio"
                                checked={selectedPackage === res?.id}
                                onClick={() => {
                                  setAmt(
                                    inter === "Month"
                                      ? Math.floor(res?.packagePrice * seat)
                                      : Math.floor(
                                          calculateDiscountedPrice(
                                            res?.packagePrice,
                                            res?.yearlyDiscountedPercentage
                                          ) *
                                            seat *
                                            12
                                        )
                                  );
                                  setPackageDataOverview({
                                    ...packageDataOverview,
                                    packageId: res?.id,
                                    stripeProductId: res?.stripeProductId,
                                    totalAmount:
                                      inter === "Month"
                                        ? Math.floor(res?.packagePrice * seat)
                                        : Math.floor(
                                            calculateDiscountedPrice(
                                              res?.packagePrice,
                                              res?.yearlyDiscountedPercentage
                                            ) *
                                              seat *
                                              12
                                          ),

                                    interval: inter.toLowerCase(),
                                    seatCount: parseInt(seat),
                                  });
                                  setFullPackage(res);
                                  setSelectedPackage(res.id);
                                  setSelectedName(res?.packageName);
                                  setNewDisplayOrder(res?.displayOrder);
                                  setMinSeats(parseInt(res?.minSeats));
                                  if (
                                    parseInt(seat) < parseInt(res?.minSeats)
                                  ) {
                                    setSeat(parseInt(res?.minSeats));
                                  }
                                }}
                              />
                              <span className="radio-mark" />
                            </label>
                          )}
                        </div>
                      )
                  )}
                </ul>
              </div>
            </div>
            <div className="toogle-main-wraper">
              <a onClick={handleShowTab} style={{ cursor: "pointer" }}>
                {" "}
                Compare plans and features{" "}
                <img
                  onClick={handleShowTab}
                  src={DownArrow}
                  className={show ? "flipped" : ""}
                  alt="down-arrow"
                />{" "}
              </a>
            </div>
            {show && (
              <>
                <div className="table-pacakge-wrap">
                  <div className="table-responsive">
                    <table
                      className="table table-wrap-package"
                      width="100%"
                      border="1"
                    >
                      <tr className="heading-pacakge-table">
                        <td>Essentials</td>
                        <td>Basic</td>
                        <td>Standard</td>
                        <td>Pro</td>
                        <td>Enterprise</td>
                      </tr>
                      <tr>
                        <td>Seats</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>Items</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>Unlimited boards</td>
                        <td>
                          <img src={Icon1} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon2} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon3} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon4} alt="package-info-icons" />
                        </td>
                      </tr>
                      <tr>
                        <td>File storage</td>
                        <td>
                          <img src={Icon1} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon2} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon3} alt="package-info-icons" />
                        </td>
                        <td>
                          <img src={Icon4} alt="package-info-icons" />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="choose-continue-btn choose-continue-btn-margin">
                  <div className="next-button-wraper module-btn-wrapper">
                    <button
                      disabled={
                        fullPackage?.packageName === "Enterprice" ||
                        selectedName === "Free"
                      }
                      onClick={() => {
                        setAmt(
                          inter === "Month"
                            ? Math.floor(fullPackage?.packagePrice * seat)
                            : Math.floor(
                                calculateDiscountedPrice(
                                  fullPackage?.packagePrice,
                                  fullPackage?.yearlyDiscountedPercentage
                                ) *
                                  seat *
                                  12
                              )
                        );
                        setPackageDataOverview({
                          ...packageDataOverview,
                          totalAmount:
                            inter === "Month"
                              ? Math.floor(fullPackage?.packagePrice * seat)
                              : Math.floor(
                                  calculateDiscountedPrice(
                                    fullPackage?.packagePrice,
                                    fullPackage?.yearlyDiscountedPercentage
                                  ) *
                                    seat *
                                    12
                                ),
                          interval: inter.toLowerCase(),
                        });
                        if (
                          userCount >
                          (parseInt(seat) < parseInt(fullPackage?.minSeats)
                            ? parseInt(fullPackage?.minSeats)
                            : parseInt(seat))
                        ) {
                          setUserModal(true);
                        } else {
                          setSummery(true);
                        }
                      }}
                      className="module-modal-btn module-main-btn remove-package-modal-btn"
                    >
                      {loadbtn ? <Spinner animation="border" /> : "Continue"}{" "}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            {summery && (
              <div className="add-on-popup-wraper">
                <div className="w-100 d-flex justify-content-between align-items-center summery-wrapper">
                  <div className="w-100 package-info-data">
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Package</p>
                      <p>{selectedName}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Seats</p>
                      <p>{seat}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Duration</p>
                      <p>{packageDataOverview.interval}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Activation Date</p>
                      <p>{dateFormatter(new Date())}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Expiary Date</p>
                      <p>
                        {dateFormatter(
                          packageDataOverview?.interval === "year"
                            ? nextYearDate
                            : nextMonthDate
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="w-100 d-flex justify-content-between align-items-center package-info-data-main">
                  <div className="package-info-data">
                    <p>Total</p>
                  </div>
                  <p>
                    {packageDataOverview.interval === "year" ? (
                      <span
                        style={{ fontSize: "15px" }}
                      >{`(12 months X $${Math.round(amt / 12)})`}</span>
                    ) : (
                      ""
                    )}{" "}
                    ${amt}
                  </p>
                </div>
                <div
                  className={`${
                    planData?.currentStatus === "Trial"
                      ? "case-trial-btn-wrap"
                      : ""
                  } choose-continue-btn choose-continue-btn-margin`}
                >
                  {planData?.currentStatus === "Trial" && (
                    <div className="back-button-wraper module-btn-wrapper">
                      <button onClick={() => setSummery(false)}>
                        Choose Plan
                      </button>
                    </div>
                  )}
                  <div className="next-button-wraper module-btn-wrapper">
                    <button
                      onClick={async () => {
                        if (billingDetail?.length > 0) {
                          await handleReSubscribe(
                            packageDataOverview?.packageId,
                            packageDataOverview?.stripeProductId,
                            packageDataOverview?.totalAmount,
                            packageDataOverview?.interval,
                            seat,
                            selectedUser
                          );
                          handleClosePackageModal();
                        } else {
                          handleClosePackageModal();
                          openModal();
                        }
                      }}
                      disabled={sumLoad}
                      className="module-modal-btn module-main-btn remove-package-modal-btn"
                    >
                      {/* {loadbtn ? <Spinner animation="border" /> : "Continue"}{" "} */}
                      {sumLoad ? (
                        <Spinner animation="border" />
                      ) : billingDetail?.length > 0 ? (
                        "Buy Now"
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {userModal && (
              <SelectUserModal
                seat={seat}
                userpro={userpro}
                setUserPro={setUserPro}
                setSummery={setSummery}
                setUserModal={setUserModal}
                setSelectedUser={setSelectedUser}
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
                removedUserIds={removedUserIds}
                setRemovedUserIds={setRemovedUserIds}
                setUsers={setUsers}
                limit={limit}
                setLimit={setLimit}
                users={users}
              />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OverviewSubscriptionModal;
