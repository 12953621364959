import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";

const ConfirmDeleteProjectModal = (props) => {
  // Delete project
  const deleteProject = async () => {
    props.handleConfirmClose();
    props.handleCloseDeleteModal();
  };

  return (
    <Modal
      show={props.showDeleteModal}
      // onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>{props?.modalContent?.short_description}</h5>
        <h5>{props?.modalContent?.long_description}</h5>

        <p className="del-modal-name">
          {props.getProjectName(props.projectId)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteProject}>
            Remove
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteProjectModal;
