import React from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import TaskAssigneeCardMain from "../components/TaskAssigneeCardMain";
import TaskOwnerCardMain from "../components/TaskOwnerCardMain";
import CloseBtn from "../../../assets/icon-close.svg";
import { Icon } from "@iconify/react/dist/iconify.js";
import { IoIosInformationCircleOutline } from "react-icons/io";
import useAddApiOffCanvas from "../services/useAddApiOffCanvas";
import ConfirmUpdatePSModal from "../modals/ConfirmUpdatePSModal";
import { Tooltip } from "react-tooltip";

function AddKPIOffCanvas({
  show,
  handleCloseModal,
  isDark,
  MotionWrapper,
  animationVariants,
  updateKPIId,
}) {
  const {
    formik,
    isAssigneeCardExpanded,
    isOwnerCardExpanded,
    toggleAssigneeCard,
    toggleOwnerCard,
    isCPTSubmitLoading,
    isShowUpdateModal,
    handleUpdateIsShowUpdateModal,
    handleOnProceed,
    removeError,
  } = useAddApiOffCanvas({ show, handleCloseModal, updateKPIId });

  return (
    <Offcanvas
      className={`${"off-concave-proj-modal"}`}
      show={show}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body className="pt-0">
        <Tooltip id="pskpi-canvas-my-tooltip" />
        {/* my code will start from here */}
        <form onSubmit={formik.handleSubmit}>
          {/* for task assigne card and input fields */}
          <div className="kpi-container ps-3 pt-4 pe-3  ">
            <label htmlFor="kpiTitle" className="form-label">
              KPI Title
              <IoIosInformationCircleOutline
                size={20}
                className="info-icon ms-2"
                data-tooltip-id="pskpi-canvas-my-tooltip"
                data-tooltip-content="KPI Title"
              />
            </label>
            <div className="kpi-header align-items-center justify-content-between">
              <div className="title-section ">
                <input
                  type="text"
                  className="form-control kpi-title-input-field"
                  placeholder=""
                  id="kpiTitle"
                  // {...formik.getFieldProps("kpiTitle")}
                  value={formik?.values?.kpiTitle}
                  onChange={(e) => {
                    formik.setFieldValue("kpiTitle", e.target.value);
                    removeError("kpiTitle");
                  }}
                />
                {formik.touched.kpiTitle && formik.errors.kpiTitle ? (
                  <div className="text-danger mt-1 text-error text-start ms-0 ">
                    {formik.errors.kpiTitle}
                  </div>
                ) : null}
              </div>
              <div className=" gap-2 align-items-center d-flex flex-row flex-shrink-0  projects-detail-heading-wrap">
                <button
                  disabled={isCPTSubmitLoading}
                  type="submit"
                  className="create-btn"
                >
                  Update {isCPTSubmitLoading && <Spinner size="sm" />}
                </button>
              </div>
            </div>
          </div>

          {/* checkbox and label  */}
          <div className="mb-1 ms-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input md-3"
                style={{
                  width: "19px",
                  height: "19px",
                }}
                id="allowPoints"
                checked={formik?.values?.allowPoints}
                {...formik.getFieldProps("allowPoints")}
              />
              <label
                className="form-check-label text-secondary ms-1"
                htmlFor="allowPoints"
              >
                Allow points for tasks with the same owner and member.{" "}
                <IoIosInformationCircleOutline
                  size={20}
                  data-tooltip-id="pskpi-canvas-my-tooltip"
                  data-tooltip-content="This will allow user to get points even when he is creating and self assigning a task"
                />
              </label>
            </div>
          </div>

          <TaskAssigneeCardMain
            formik={formik}
            isExpanded={isAssigneeCardExpanded}
            toggleExpanded={toggleAssigneeCard}
            MotionWrapper={MotionWrapper}
            animationVariants={animationVariants}
            removeError={removeError}
          />
          {/* for task owner card and input fields */}
          <div className="kpi-card-divider ms-3 me-3"></div>
          <TaskOwnerCardMain
            formik={formik}
            isExpanded={isOwnerCardExpanded}
            toggleExpanded={toggleOwnerCard}
            MotionWrapper={MotionWrapper}
            animationVariants={animationVariants}
            removeError={removeError}
          />
        </form>
        {/* close icon */}
        <button
          className="btn-role-concave"
          onClick={() => {
            handleCloseModal();
          }}
        >
          <img src={CloseBtn} alt="Close" />
        </button>

        {/* confirm update modal */}
        <ConfirmUpdatePSModal
          isShow={isShowUpdateModal}
          isDark={isDark}
          isLoading={isCPTSubmitLoading}
          handleOnClose={() => handleUpdateIsShowUpdateModal(false)}
          handleOnProceed={handleOnProceed}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default AddKPIOffCanvas;
