import React from "react";
import Deals from "./components/Deals";

const DealsMain = (props) => {
  return (
    <Deals
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default DealsMain;
