import React from "react";
import Projects from "./components/GeneralLedger";

const GeneralLedgerMain = (props) => {
  return (
    <Projects
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default GeneralLedgerMain;
