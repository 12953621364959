import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { dateFormatter } from "../../../helper/dateFormatter";

const ConfirmCardDeleteModal = (props) => {
  const [selected, setSelected] = useState(false);
  const [err, setErr] = useState(false);

  return (
    <Modal
      show={props.showConfirmDeleteModal}
      onHide={props.handleCloseConfirmDeleteModal}
      className="main-delete-modal delete-confirm-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="" closeButton>
        <div className="payment-method-wrap module-modal-wrap">
          <h3 className="module-select-heading">
            {" "}
            <span>Remove</span> Credit Card{" "}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div className="popup-delete-card-wraper">
          <h3> Removing your credit card puts into an unverified state </h3>
          <p> If you continue you will no longer be able to: </p>
          <ul>
            <li>Auto renew subscription</li>
            <li> Upgrade or Add-on to your package </li>
          </ul>
          <p>
            You can read more about <a href="#">credit card removal</a> in the
            Dev center{" "}
          </p>
          <div className="credit-card-danger-wrap">
            <h4>
              {" "}
              By removing your card, you wont be able to attach another card
              before the end of payment cycle 
              {dateFormatter(props?.planData?.trialEndDate, 2)}{" "}
            </h4>
            <p>
              {" "}
              If you want to change your card information, you can use{" "}
              <a
                href="#"
                onClick={() => {
                  props.handleCloseConfirmDeleteModal();
                  props.setIsOpen(true);
                }}
              >
                change credit card
              </a>{" "}
              option.
            </p>
            <div className="form-check form-package-delete-modal-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={selected}
                onClick={() => {
                  setSelected(!selected);
                  setErr(false);
                }}
                checked={selected}
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                {" "}
                I understand and would like to proceed with removing my credit
                card.{" "}
              </label>
            </div>
              {" "}
          </div>
          {err && (
            <div className="w-100 d-flex justify-content-end">
              <p style={{ marginBottom: "0px !important", color: "#ff0000" }}>
                Please first accept agreement to proceed with this action.
              </p>
            </div>
          )}
          <div className="choose-continue-btn choose-continue-btn-margin">
            <div className="next-button-wraper module-btn-wrapper">
              <button
                onClick={() => {
                  if (selected) {
                    props.handleDeleteCardInfo(props.billingInfoItem);
                    setErr(false);
                  } else {
                    setErr(true);
                  }
                }}
                disabled={props.loadbtn}
                className="module-modal-btn module-main-btn remove-package-modal-btn"
              >
                {props.loadbtn ? <Spinner animation="border" /> : "Continue"}{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCardDeleteModal;
