import React from "react";
import { calculateDiscountedPrice } from "../../../helper/getDiscountedPrice";
import EntIcon from "../../../assets/enterprise.svg";

const PackageCard = ({
  res,
  id,
  packageName,
  selectedPackage,
  setPackageName,
  setPackageDataOverview,
  packageDataOverview,
  inter,
  seat,
  setSelectedPackage,
  setSummery,
  setSelectedName,
  setMinSeats,
  setSeat,
  setOpenContact,
  setUserModal,
  userCount,
  setNewDisplayOrder,
  setFullPackage,
  setAmt,
}) => {
  return (
    <li
      className="col-lg-12"
      key={id}
      onClick={() => {
        setPackageDataOverview({
          ...packageDataOverview,
          packageId: res?.id,
          stripeProductId: res?.stripeProductId,
          totalAmount:
            inter === "Month"
              ? Math.floor(res?.packagePrice * seat)
              : Math.floor(
                  calculateDiscountedPrice(
                    res?.packagePrice,
                    res?.yearlyDiscountedPercentage
                  ) *
                    seat *
                    12
                ),

          interval: inter.toLowerCase(),
          seatCount: parseInt(seat),
        });
        setFullPackage(res);
        setSelectedPackage(res.id);
        setSelectedName(res?.packageName);
        setNewDisplayOrder(res?.displayOrder);
        setMinSeats(parseInt(res?.minSeats));
        if (parseInt(seat) < parseInt(res?.minSeats)) {
          setSeat(parseInt(res?.minSeats));
        }
      }}
    >
      <div
        className={`listing-pacakge-wraper ${
          packageName === res?.packageName && packageName !== "Enterprice"
            ? "selected-package-wrapper"
            : ""
        } ${res.packageName === "Enterprice" ? "ent-package" : ""} ${
          selectedPackage === res?.id ? "selected-package-main" : ""
        }`}
        onMouseEnter={() => setPackageName("")}
      >
        {" "}
        <a>
          {res?.packageName !== "Enterprice" ? (
            <>
              <h4> {res.packageName} </h4>
              <div className="price-listing">
                {" "}
                <span>$</span>{" "}
                {inter === "Month"
                  ? Math.floor(res?.packagePrice)
                  : Math.floor(
                      calculateDiscountedPrice(
                        res?.packagePrice,
                        res?.yearlyDiscountedPercentage
                      )
                    )}
              </div>
              <div className="pacakge-tip-info"> Seat / month </div>
              <div className="package-trails-wrap">
                {" "}
                Total $
                {inter === "Month"
                  ? Math.floor(res?.packagePrice * seat)
                  : Math.floor(
                      calculateDiscountedPrice(
                        res?.packagePrice,
                        res?.yearlyDiscountedPercentage,
                        seat
                      ) * seat
                    )}
                /month
              </div>
              <div className="package-trails-wrap"> 7 Days Free Trail </div>
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center price-listing ent-name ent-name-2">
              {" "}
              <img src={EntIcon} alt="enterprice-icon" />
              <h5>Enterprise</h5>
              <p>
                For teams, business and organization, waiting to maximize
                growth.
              </p>
            </div>
          )}
          <div
            className={`pacakge-info-list-wrap ${
              res?.packageName === "Enterprice" ? "ent-li" : ""
            }`}
          >
            {res?.packageName === "Enterprice" ? (
              <>
                <h5> Contact Sales </h5>
                <ul>
                  <li>
                    {" "}
                    Contact our sales team to get enterprise package which suits
                    your organization.{" "}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h5> {res.packageName} includes: </h5>
                <ul>
                  <li> Unlimited customizable pipelines </li>
                  <li> Unlimited contacts </li>
                  <li> Unlimited boards </li>
                  <li> IOS and Android apps </li>
                  <li> Unlimited free viewers </li>
                </ul>
              </>
            )}
          </div>
          {res?.packageName !== "Enterprice" ? (
            <div
              className="btn-update-package"
              onClick={async () => {
                const newSeat =
                  parseInt(seat) < parseInt(res?.minSeats)
                    ? parseInt(res?.minSeats)
                    : parseInt(seat);
                setFullPackage(res);

                setAmt(
                  inter === "Month"
                    ? Math.floor(res?.packagePrice * newSeat)
                    : Math.floor(
                        calculateDiscountedPrice(
                          res?.packagePrice,
                          res?.yearlyDiscountedPercentage
                        ) *
                          newSeat *
                          12
                      )
                );
                setPackageDataOverview({
                  ...packageDataOverview,
                  packageId: res?.id,
                  stripeProductId: res?.stripeProductId,
                  totalAmount:
                    inter === "Month"
                      ? Math.floor(res?.packagePrice * newSeat)
                      : Math.floor(
                          calculateDiscountedPrice(
                            res?.packagePrice,
                            res?.yearlyDiscountedPercentage
                          ) *
                            newSeat *
                            12
                        ),

                  interval: inter.toLowerCase(),
                  seatCount:
                    parseInt(seat) < parseInt(res?.minSeats)
                      ? parseInt(res?.minSeats)
                      : parseInt(seat),
                });
                setSelectedName(res?.packageName);
                setNewDisplayOrder(res?.displayOrder);
                if (
                  userCount >
                  (parseInt(seat) < parseInt(res?.minSeats)
                    ? parseInt(res?.minSeats)
                    : parseInt(seat))
                ) {
                  setUserModal(true);
                } else {
                  setSummery(true);
                }
              }}
            >
              SELECT PLAN
            </div>
          ) : (
            <div
              className="btn-update-package ent-btn-main"
              onClick={async () => {
                setOpenContact(true);
              }}
            >
              CONTACT US
            </div>
          )}
        </a>{" "}
      </div>
    </li>
  );
};

export default PackageCard;
