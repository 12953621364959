import React from "react";
import Lightbox from "react-image-video-lightbox";
import { Icon } from "@iconify/react";
import { getFileType } from "../../helper/getFileType";
import "../styles/lightbox.css";
import {
  handleDownloadDocFiles,
  handleFileDownload,
} from "../../helper/downloadFile";

const LightboxMain = ({ selectedFile, handleCloseFileViewer }) => {
  const fileType = getFileType(selectedFile?.attachmentName);
  return (
    <div className="lightbox-main-wrap">
      {fileType === "photo" || fileType === "video" ? (
        <div className="lightbox-wrap">
          <Lightbox
            data={[
              {
                url: selectedFile?.attachmentPath,
                type: getFileType(selectedFile?.attachmentName),
              },
            ]}
            startIndex={0}
            onCloseCallback={handleCloseFileViewer}
            showResourceCount={false}
            showThumbnails={false}
            showPlayButton={false}
            showZoom={false}
            showFullscreenButton={false}
            // Disable the slider
            sliderDisabled={true}
          />
          <div
            className="download-lightbox-icon"
            onClick={() =>
              handleDownloadDocFiles(selectedFile?.attachmentPath, fileType)
            }
          >
            <Icon icon="ic:round-download" />
            {/* <span className="ms-1">Download</span> */}
          </div>
          <div
            className="download-lightbox-icon iframe-view-icon"
            onClick={handleCloseFileViewer}
          >
            <Icon icon="ic:close" />
            {/* <span className="ms-1">Download</span> */}
          </div>
        </div>
      ) : fileType === "doc" ||
        fileType === "xls" ||
        fileType === "ppt" ||
        fileType === "pptx" ||
        fileType === "docx" ||
        fileType === "xlsx" ||
        fileType === "rtf" ? (
        <div className="pdf-viewer-wrap">
          <div className="pdf-wrap">
            <iframe
              src={
                "https://docs.google.com/viewer?url=" +
                selectedFile?.attachmentPath +
                "&embedded=true"
              }
              target="_blank"
              title="file"
              width="100%"
              // height="600"
            ></iframe>
          </div>

          <div
            className="download-lightbox-icon iframe-google-view-icon-download"
            onClick={() =>
              handleDownloadDocFiles(selectedFile?.attachmentPath, fileType)
            }
          >
            <Icon icon="ic:round-download" />
            {/* <span className="ms-1">Download</span> */}
          </div>
          <div
            className={`download-lightbox-icon ${
              fileType === "pdf"
                ? "iframe-view-icon-pdf"
                : "iframe-google-view-icon"
            }`}
            onClick={handleCloseFileViewer}
          >
            <Icon icon="ic:close" />
            {/* <span className="ms-1">Download</span> */}
          </div>
        </div>
      ) : (
        <div className="pdf-viewer-wrap">
          <div className="pdf-wrap">
            <iframe src={selectedFile?.attachmentPath} title="pdf" target="_blank" />
            <Icon icon="gg:close" onClick={handleCloseFileViewer} />
          </div>
          {/* <Viewer
            fileUrl={selectedFile?.attachmentPath}
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]}
          /> */}
          {fileType !== "pdf" && (
            <div
              className="download-lightbox-icon"
              onClick={() =>
                handleDownloadDocFiles(selectedFile?.attachmentPath, fileType)
              }
            >
              <Icon icon="ic:round-download" />
              {/* <span className="ms-1">Download</span> */}
            </div>
          )}
          <div
            className={`download-lightbox-icon ${
              fileType === "pdf" ? "iframe-view-icon-pdf" : "iframe-view-icon"
            }`}
            onClick={handleCloseFileViewer}
          >
            <Icon icon="ic:close" />
            {/* <span className="ms-1">Download</span> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default LightboxMain;
