import React from "react";
import usePSMainService from "../services/usePSMainService";
import AddKPIOffCanvas from "../canvas/AddKPIOffCanvas";

function PSMainHeader({ isDark, handleUpdateIsShowAddNewKPI }) {
  return (
    <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap gap-2 projects-detail-heading-wrap">
      <h4 className="projects-detail-heading-wrap mb-0">
        Performance <span>System</span>
      </h4>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <button
          className="ms-0 top-0 create-btn-2"
          onClick={() => handleUpdateIsShowAddNewKPI(true)}
        >
          Add Another KPI
        </button>
      </div>{" "}
    </div>
  );
}

export default PSMainHeader;
