import { ADMBaseApi } from "../../services/ADMBaseAPi";

export const userSlice = ADMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // endpoint for update daily logs
    postUpdateDailyLogs: builder.mutation({
      query: (data) => ({
        url: `/users/update-daily-logs`,
        method: "POST",
        body: data,
      }),
    }),

    // endpoint for getting permissions urls
    getPermissionsUrls: builder.query({
      query: () => ({
        url: `/users/permissions-urls`,
        method: "GET",
      }),
    }),

    // endpoint for getting company roles
    getCompanyRoles: builder.query({
      query: () => ({
        url: `/company/roles`,
        method: "GET",
      }),
    }),

    // endpoint to get user permissions
    getUserPermissions: builder.mutation({
      query: (data) => ({
        url: `/users/permissions`,
        method: "POST",
        body: data,
      }),
    }),

    // endpoint to get user roles
    getUserRoles: builder.mutation({
      query: (data) => ({
        url: `/users/roles`,
        method: "POST",
        body: data,
      }),
    }),

    // Company profile
    getCompanyProfile: builder.query({
      query: () => ({
        url: `/company/profile`,
        method: "GET",
      }),
      providesTags: ["companyProfile"],
    }),

    // endpoint to update company profile image
    postUpdateCompanyImage: builder.mutation({
      query: (data) => ({
        url: `/company/update-logo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["companyProfile"],
    }),

    // endpoint to update company profile image
    postUpdateCompanyProfile: builder.mutation({
      query: (data) => ({
        url: `/company/profile-update`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["companyProfile"],
    }),
    // endpoint to update company profile image
    postAddCompanyWorkingHours: builder.mutation({
      query: (data) => ({
        url: `/company/add-working-hours`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["companyProfile"],
    }),

    // endpoint to update company profile image
    updateCompanyWorkingHours: builder.mutation({
      query: (data) => ({
        url: `/company/update-working-hours`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["companyProfile"],
    }),

    // endpoint to update company profile image
    updateCompanyWorkingHoursStatus: builder.mutation({
      query: (data) => ({
        url: `/company/status-update-working-hours`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["companyProfile"],
    }),

    // endpoint to delete company working hour
    deleteCompanyWorkingHours: builder.mutation({
      query: (workingHourId) => ({
        url: `/company/delete-working-hours`,
        method: "DELETE",
        body: { workingHourId: workingHourId },
      }),
      invalidatesTags: ["companyProfile"],
    }),

    // Endpoint to get industry list
    getIndustries: builder.query({
      query: () => ({
        url: `/auth/industries`,
        method: "GET",
      }),
    }),

    // endpoint to get company working shifts
    getCompanyWorkingShifts: builder.query({
      query: () => ({
        url: `/company/working-shifts`,
        method: "Get",
      }),
    }),
  }),
});
export const {
  usePostUpdateDailyLogsMutation,
  useGetPermissionsUrlsQuery,
  useGetUserPermissionsMutation,
  useGetCompanyRolesQuery,
  useGetUserRolesMutation,
  useGetCompanyProfileQuery,
  usePostUpdateCompanyImageMutation,
  usePostUpdateCompanyProfileMutation,
  usePostAddCompanyWorkingHoursMutation,
  useDeleteCompanyWorkingHoursMutation,
  useGetIndustriesQuery,
  useUpdateCompanyWorkingHoursMutation,
  useUpdateCompanyWorkingHoursStatusMutation,
  useGetCompanyWorkingShiftsQuery,
} = userSlice;
