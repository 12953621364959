import React from "react";
import Booking from "./components/Booking";

const BookingMain = (props) => {
  return (
    <Booking
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default BookingMain;
