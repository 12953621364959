// Import action type constants from constants.js
import { ProjectAPI } from "../services/ClientApi";
import {
  FETCH_PROJECT_DETAIL_TASKS_REQUEST,
  FETCH_PROJECT_DETAIL_TASKS_SUCCESS,
  FETCH_PROJECT_DETAIL_TASKS_FAILURE,
  UPDATE_PROJECT_DETAIL_TASK_SUCCESS,
  DELETE_PROJECT_DETAIL_TASK_SUCCESS,
  REMOVE_TASK_AND_PHASES,
} from "../constants/projectDetailTaskConstants";

// Action creator for initiating the task fetch process
export const fetchProjectDetailTasksRequest = () => ({
  type: FETCH_PROJECT_DETAIL_TASKS_REQUEST,
});

// Action creator for handling successful task fetch
export const fetchProjectDetailTasksSuccess = (
  tasks,
  limit,
  page,
  phaseId,
  projectId
) => ({
  type: FETCH_PROJECT_DETAIL_TASKS_SUCCESS,
  payload: { tasks, limit, page, phaseId, projectId },
});

// Action creator for handling task fetch failure
export const fetchProjectDetailTasksFailure = (error) => ({
  type: FETCH_PROJECT_DETAIL_TASKS_FAILURE,
  payload: error,
});

// Thunk action to fetch tasks based on the provided limit, page, phaseId, and projectId
export const fetchProjectDetailTasks = (limit, page, phaseId, projectId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const cachedTasks =
      state?.projectDetailTaskList?.taskPhaseList[projectId]?.[phaseId]?.[
        `${limit}_${page}`
      ];

    if (cachedTasks) {
      return;
    }

    dispatch(fetchProjectDetailTasksRequest());

    try {
      let data;

      if (phaseId === "All") {
        const response = await ProjectAPI.post(
          `project/tasks?page=${page}&limit=${limit}`,
          {
            projectId: projectId,
          }
        );
        data = response.data?.data;
      } else {
        const response = await ProjectAPI.post(
          `project/phase-tasks?page=${page}&limit=${limit}`,
          {
            projectId: projectId,
            projectPhaseId: phaseId,
          }
        );
        data = response.data?.data;
      }

      dispatch(
        fetchProjectDetailTasksSuccess(data, limit, page, phaseId, projectId)
      );
    } catch (error) {
      // dispatch(fetchProjectDetailTasksFailure(error.message));
      dispatch(fetchProjectDetailTasksFailure({
        type: FETCH_PROJECT_DETAIL_TASKS_FAILURE,
        payload: error.message,
        meta: {
          retryAction: fetchProjectDetailTasks(limit, page, phaseId, projectId), // Retry action
        },
      }));
    }
  };
};

// Action creator for updating a task
export const updateCachedProjectDetailTask =
  (taskId, updatedTask) => async (dispatch) => {
    dispatch({
      type: UPDATE_PROJECT_DETAIL_TASK_SUCCESS,
      payload: { taskId, updatedTask },
    });
  };

// Action creator for deleting tasks
export const deleteCachedProjectDetailTasks = (taskIds) => ({
  type: DELETE_PROJECT_DETAIL_TASK_SUCCESS,
  payload: { taskIds },
});

// Remove task from phase
export const removeTaskAndPhases = (projectId, phaseId, taskId) => ({
  type: REMOVE_TASK_AND_PHASES,
  payload: { projectId, phaseId, taskId },
});

