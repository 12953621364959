import {
  TASK_PRIORITIES_LIST_SUCCESS,
  TASK_PRIORITIES_LIST_REQUEST,
  TASK_PRIORITIES_LIST_FAIL,
  TASK_TYPES_LIST_REQUEST,
  TASK_TYPES_LIST_SUCCESS,
  TASK_TYPES_LIST_FAIL,
  TASK_LIST_FAIL,
  TASK_LIST_REQUEST,
  TASK_LIST_SUCCESS,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_FAIL,
  ADD_TASK_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/tasksConstants"; // Update the path if necessary
import { ProjectAPI } from "../services/ClientApi";

// Action to get task priorities list
export const taskPrioritiesListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TASK_PRIORITIES_LIST_REQUEST });
    const { data } = await ProjectAPI.get("general/pm-priorities"); // Update the API endpoint if necessary
    dispatch({
      type: TASK_PRIORITIES_LIST_SUCCESS,
      payload: data?.data,
    });
    return data?.data;
  } catch (error) {
    dispatch({
      type: TASK_PRIORITIES_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: taskPrioritiesListAction(), // Retry action
      },
    });
  }
};

// Action to empty task priorities list
export const emptyTaskPrioritiesListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TASK_PRIORITIES_LIST_REQUEST });
    dispatch({
      type: TASK_PRIORITIES_LIST_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: TASK_PRIORITIES_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: emptyTaskPrioritiesListAction(), // Retry action
      },
    });
  }
};

// action to get all task types
export const taskTypesListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TASK_TYPES_LIST_REQUEST });
    const { data } = await ProjectAPI.get("general/pm-tasks-types"); // Update the API endpoint if necessary
    dispatch({
      type: TASK_TYPES_LIST_SUCCESS,
      payload: data?.data,
    });
    return data?.data;
  } catch (error) {
    dispatch({
      type: TASK_TYPES_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: taskTypesListAction(), // Retry action
      },
    });
  }
};

// action to get all task types
export const emptyTaskTypesListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TASK_TYPES_LIST_REQUEST });
    dispatch({
      type: TASK_TYPES_LIST_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: TASK_TYPES_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: emptyTaskTypesListAction(), // Retry action
      },
    });
  }
};

// action to get all tasks
export const taskListAction = (pageSize, currentPage) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LIST_REQUEST });
    const { data } = await ProjectAPI.post(
      `task/listing?page=${currentPage}&limit=${pageSize}`
    ); // Update the API endpoint if necessary
    dispatch({
      type: TASK_LIST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: TASK_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: taskListAction(pageSize, currentPage), // Retry action
      },
    });
  }
};

// action to get all completed tasks
export const taskCompletedListAction =
  (pageSize, currentPage) => async (dispatch) => {
    try {
      dispatch({ type: TASK_LIST_REQUEST });
      const { data } = await ProjectAPI.post(
        `task/completed-listing?page=${currentPage}&limit=${pageSize}`
      ); // Update the API endpoint if necessary
      dispatch({
        type: TASK_LIST_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: TASK_LIST_FAIL,
        payload: error,
        meta: {
          retryAction: taskCompletedListAction(pageSize, currentPage), // Retry action
        },
      });
    }
  };

// action to update a task
export const updateTaskAction = (updatedTask) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_UPDATE_REQUEST });

    // Assuming updatedTask contains the new task data
    // const { data } = await ProjectAPI.put(`task/update/${updatedTask.id}`, updatedTask);

    // Simulating the updated task response from the API
    const data = updatedTask;

    dispatch({
      type: TASK_UPDATE_SUCCESS,
      payload: data,
    });

    // Update the task list in the state
    const { taskList } = getState().taskListData;
    const updatedTaskList = taskList.map((task) =>
      task.id === data.id ? data : task
    );

    dispatch({
      type: TASK_LIST_SUCCESS,
      payload: updatedTaskList,
    });

    return data;
  } catch (error) {
    dispatch({
      type: TASK_UPDATE_FAIL,
      payload: error,
      meta: {
        retryAction: taskCompletedListAction(updatedTask), // Retry action
      },
    });
  }
};

// action to delete a task by ID
export const deleteTaskAction = (taskIds) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_LIST_REQUEST }); // Start loading state

    // Assuming the API call is successful, update the local state
    const state = getState(); // Get the entire Redux state
    // Check if taskList is defined in the state
    if (state && state.taskListData && state.taskListData.taskList) {
      const updatedTaskList = state.taskListData.taskList.filter(
        (task) => !taskIds.includes(task.id)
      );

      dispatch({
        type: TASK_LIST_SUCCESS,
        payload: updatedTaskList,
      });
    } else {
      // Handle the case where taskList is undefined
      dispatch({
        type: TASK_LIST_FAIL,
        payload: "Task list is undefined",
      });
    }
  } catch (error) {
    // Handle errors, potentially dispatch an error action
    dispatch({
      type: TASK_LIST_FAIL,
      payload: error.message,
      meta: {
        retryAction: deleteTaskAction(taskIds), // Retry action
      },
    });
  }
};

// Add task to list
export const addTaskAction = (taskData) => (dispatch) => {
  dispatch({
    type: ADD_TASK_SUCCESS,
    payload: taskData,
  });
};

// action to clear the entire task list
export const clearTaskListAction = () => (dispatch) => {
  dispatch({
    type: TASK_LIST_SUCCESS,
    payload: null, // Set the taskList to an empty array
  });
};

//Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
    payload: null,
  });
};
