import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import LeadsMain from "./pages/Leads/LeadsMain.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import InviteTeamMember from "./pages/InviteTeam/InviteTeamMember.jsx";
import ChooseDepartmentMain from "./pages/ChooseDepartment/ChooseDepartmentMain.jsx";
import PackagesMain from "./pages/Packages/PackagesMain.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Agtable from "./pages/Agtable/Agtable.jsx";
import ProtectedRouteAuth from "./components/protectedRouteAuth.jsx";
import Module from "./pages/Module/Module.jsx";
import Profile from "./pages/Profile/Profile.jsx";
import LeadsNew from "./pages/LeadsNew/LeadsNew.jsx";
import EmailVerify from "./pages/EmailVerify/EmailVerify.jsx";
import VerifyInviteEmail from "./pages/VerifyInviteEmail/VerifyInviteEmail.jsx";
import Payment from "./pages/Payment/Payment.jsx";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.jsx";
import ChangePassword from "./pages/ChangePassword/ChangePassword.jsx";
import UserProfile from "./pages/UserProfile/UserProfile.jsx";
import Roles from "./pages/Roles/Roles.jsx";
// import Subscriptions from "./pages/Subscriptions/Subscriptions";
import SubscriptionsUpdated from "./pages/SubscriptionsUpdated/Subscriptions.jsx";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
import ContactsMain from "./pages/Contacts/ContactsMain.jsx";
import DealsMain from "./pages/Deals/DealsMain.jsx";
import AccountOrCustomersMain from "./pages/AccountsOrCustomers/AccountOrCustomersMain.jsx";
import LiveChatMain from "./pages/LiveChat/LiveChatMain.jsx";
import MarketingMain from "./pages/Marketing/MarketingMain.jsx";
import ProjectsMain from "./pages/Projects/ProjectsMain.jsx";
import TasksMain from "./pages/Tasks/TasksMain.jsx";
import BookingMain from "./pages/Booking/BookingMain.jsx";
import ExpensesMain from "./pages/Expenses/ExpensesMain.jsx";
import InvoicesMain from "./pages/Invoices/InvoicesMain.jsx";
import ChartsOfAccountsMain from "./pages/ChartsOfAccounts/ChartsOfAccountsMain.jsx";
import GeneralLedgerMain from "./pages/GeneralLedger/GeneralLedgerMain.jsx";
import BalanceSheetMain from "./pages/BalanceSheet/BalanceSheetMain.jsx";
import PAndLMain from "./pages/P_L/PAndLMain.jsx";
import MyGoalMain from "./pages/MyGoal/MyGoalMain.jsx";
import SettingMain from "./pages/Setting/SettingMain.jsx";
import InventoryMain from "./pages/Inventory/InventoryMain.jsx";
import RecruiterMain from "./pages/Recruiter/RecruiterMain.jsx";
import ModulePage from "./pages/ModulePage/ModulePage.jsx";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail.jsx";
import Team from "./pages/Team/Team.jsx";
import TeamMemberDetails from "./pages/TeamMemberDetails/TeamMemberDetails.jsx";
import Scrum from "./pages/Scrum/Scrum.jsx";
import Dailyreports from "./pages/Dailyreport/Dailyreports.jsx";
import StaffSummary from "./pages/staff-daily-summary/StaffSummary.jsx";
import DayTimeStartModal from "./components/DayTimeStartModal/DayTimeStartModal.jsx";
import DayTimeEndModal from "./components/DayTimeEndModal/DayTimeEndModal.jsx";
import CompanyProfileMain from "./pages/CompanyProfile/CompanyProfileMain.jsx";
// import NotificationPermissionPopup from "./components/NotificationPermissionPopup.jsx";
import InternetConnectionCheck from "./components/InternetConnectionCheck.jsx";
import TimeZoneAuthMain from "./pages/TimeZoneAuth/TimeZoneAuthMain.jsx";
import PerformanceSystem from "./pages/PerformanceSystem/PerformanceSystem.jsx";
import Cookies from "js-cookie";

function App() {
  const noop = () => {};

  // // Disable specific console methods
  [
    "log",
    "warn",
    "error",
    "info",
    "debug",
    "table",
    "group",
    "groupEnd",
    "time",
    "timeEnd",
    "trace",
    "assert",
    "clear",
    "count",
    "countReset",
    "dir",
    "dirxml",
    "profile",
    "profileEnd",
  ].forEach((method) => {
    console[method] = noop;
  });

  const [companySlug, setCompanySlug] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {
          full_name: "",
          email_address: "",
          password: "",
          passwordConfirm: "",
          company_name: "",
          industry_id: 0,
          package_id: "",
          modules_category_ids: [],
          login_token: "",
          login_type: "",
          seatCount: 3,
          interval: "",
        }
  );

  // This section handles redirection
  const token = Cookies.get("access_token");
  const permissions = Cookies.get("permissions");
  const company = Cookies.get("company");
  const enableSwitches = Cookies.get("enableSwitch");
  const notify = Cookies.get("notify");
  const tid = Cookies.get("tid");

  // Ensure enableSwitches is explicitly true
  if (enableSwitches || notify) {
    if (token) localStorage.setItem("access_token", token);
    if (permissions) localStorage.setItem("permissions", permissions);
    if (company) localStorage.setItem("company", company);
    if (tid) localStorage.setItem("tid", tid);
    // Now remove cookies after setting localStorage
    Cookies.remove("access_token");
    Cookies.remove("permissions");
    Cookies.remove("company");
    Cookies.remove("enableSwitch");
    Cookies.remove("notify");
    Cookies.remove("tid");
  } else {
    if (token) localStorage.setItem("access_token", token);
    if (permissions) localStorage.setItem("permissions", permissions);
    if (company) localStorage.setItem("company", company);
    if (tid) localStorage.setItem("tid", tid);
    // Now remove cookies after setting localStorage
    Cookies.remove("access_token");
    Cookies.remove("permissions");
    Cookies.remove("company");
    Cookies.remove("enableSwitch");
  }

  const [isDark, setIsDark] = useState(
    // localStorage.getItem("isDark")
    //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
    //     ? "default"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
    //     ? "color1"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
    //     ? "color2"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
    //     ? "color3"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
    //     ? "color4"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
    //     ? "color5"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
    //     ? "color6"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
    //     ? "color7"
    //     : "default"
    //   : "default"
    "default"
  );
  useEffect(() => {
    const handleStorageChange = async () => {
      // setIsDark(
      // localStorage.getItem("isDark")
      //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
      //     ? "default"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
      //     ? "color1"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
      //     ? "color2"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
      //     ? "color3"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
      //     ? "color4"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
      //     ? "color5"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
      //     ? "color6"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
      //     ? "color7"
      //     : "default"
      //   : "default"
      // );
      setIsDark("default");
      // Add your logic to handle the change here
    };

    // Add storage event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {}, [localStorage.getItem("company")]);

  // Initialize tinymce
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/tinymce/tinymce.min.js"; // Make sure this path is correct
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      const authUrl = `${process.env.REACT_APP_AUTH_URL}/login`;

      // Try opening in the same tab first
      const newWindow = window.open(authUrl, "_self");

      // If pop-up blocked, fall back to same-page redirect
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        window.location.href = authUrl;
      }
    }
  }, [localStorage.getItem("access_token")]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <InternetConnectionCheck isDark={isDark} />
      <DayTimeStartModal isDark={isDark} />
      <DayTimeEndModal isDark={isDark} />
      {/* <NotificationPermissionPopup isDark={isDark} /> */}

      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={`/${
                companySlug || localStorage.getItem("company")
              }/dashboard`}
              replace
            />
          }
        />
        {/* <Route
          path="/"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Login
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        /> */}
        {/* <Route
          path="/login"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Login
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRouteAuth isDark={isDark} children={<ForgotPassword />} />
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRouteAuth isDark={isDark} children={<ChangePassword />} />
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Signup
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/choose-industry"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <ChooseCompany
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/create-account"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={<ChooseAccount user={user} setUser={setUser} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/working-schedule`}
          element={
            <div data-theme={"default"}>
              <TimeZoneAuthMain
                setUser={setUser}
                setCompanySlug={setCompanySlug}
                companySlug={companySlug}
              />
            </div>
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/invite-team`}
          element={
            <div data-theme={"default"}>
              <InviteTeam user={user} setUser={setUser} />
            </div>
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/invite-team-member`}
          element={
            <div data-theme={"default"}>
              <InviteTeamMember user={user} setUser={setUser} />
            </div>
          }
        />
        <Route
          path="/packages"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={<PackagesMain user={user} setUser={setUser} />}
            />
          }
        />
        <Route
          path="/modules"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Module
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        /> */}
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              children={<CompanyProfileMain isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/dashboard`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              children={<Dashboard isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/crm-leads`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<LeadsMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/crm-contacts`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ContactsMain />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/crm-deals`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<DealsMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/performance-report`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Dailyreports isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/team-performance`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<StaffSummary isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/performance-system`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<PerformanceSystem isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/crm-accounts`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<AccountOrCustomersMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/crm-live-chat`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<LiveChatMain isDark={isDark} collapse={collapse} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/crm-marketing`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<MarketingMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/task-management-porojects`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ProjectsMain isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/task-management-scrum`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Scrum isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/project`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ProjectDetail isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/team-member`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<TeamMemberDetails isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/task-management-tasks`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<TasksMain isDark={isDark} />}
            />
          }
        />
        {/* <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/task-management-timeline`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Timeline isDark={isDark} />}
            />
          }
        /> */}
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/task-management-teams`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Team isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/booking`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<BookingMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-expenses`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ExpensesMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-invoices`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<InvoicesMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-chart-of-accounts`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ChartsOfAccountsMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-general-ledger`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<GeneralLedgerMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-balance-sheet`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<BalanceSheetMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/accounts-pl`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<PAndLMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/goals-my-goal`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<MyGoalMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/goals-setting`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<SettingMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/supply-chain-inventory`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<InventoryMain />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/hr-recruiter`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<RecruiterMain />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-employees`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<LeadsNew isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/agtable`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Agtable />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Profile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/user-profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<UserProfile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-roles`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Roles isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-subscriptions`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<SubscriptionsUpdated isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/plugins`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ModulePage isDark={isDark} />}
            />
          }
        />

        <Route path="/choose-department" element={<ChooseDepartmentMain />} />
        <Route path="/payment" element={<Payment isDark={isDark} />} />
        <Route path="/verify-email" element={<EmailVerify isDark={isDark} />} />
        <Route
          path="/accept-invitation"
          element={
            <VerifyInviteEmail
              isDark={isDark}
              setCompanySlug={setCompanySlug}
              companySlug={companySlug}
            />
          }
        />
        <Route path={`*`} element={<PageNotFound isDark={isDark} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
