import React, { useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

const CustomPagination = ({ totalRows, pageSize, onPageChange, currentPage, setCurrentPage }) => {
  const numPages = Math.ceil(totalRows / pageSize);

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.textContent);
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  return (
    <ResponsivePagination
      current={currentPage}
      total={10}
      onPageChange={setCurrentPage}
    />
  );
};

export default CustomPagination;