import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import {
  useSendAttachmentMessageMutation,
  useSendMessageMutation,
} from "../../../slices/chat/chatSlice";
import socket from "../../../services/socket";
import { defaultStyles, FileIcon } from "react-file-icon";
import { formatFileSize } from "../../../helper/taskHelpers";
import { toast } from "react-toastify";
import { createMessageObject } from "../../../helper/chatHelper";

function UserChatMessageInput({
  replyMessage,
  setReplyMessage,
  selectedChat,
  user,
  selectedFiles,
  setSelectedFiles,
  onFileDrop,
  onDragOver,
  setMessages,
}) {
  const [message, setMessage] = useState("");
  const inputRef = useRef(null); // Reference for the input field

  // Using the sendMessage mutation
  const [sendMessage, { isLoading: sendLoading }] = useSendMessageMutation();
  const [sendAttachmentMessage, { isLoading }] =
    useSendAttachmentMessageMutation();

  const handleFileSelect = (event) => {
    const maxFileSize = 8 * 1024 * 1024; // 8MB in bytes
    const allowedExtensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "doc",
      "docx",
      "txt",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "gif",
      "bmp",
      "svg",
      "zip",
      "rar",
      "mp3",
      "mp4",
    ];

    const filesArray = Array.from(event.target.files); // Convert FileList to array
    const validFiles = [];
    const excludedFiles = []; // Collect excluded files with reasons

    filesArray.forEach((file) => {
      const extension = getFileType(file.name);
      if (!allowedExtensions.includes(extension)) {
        excludedFiles.push({
          name: file.name,
          reason: "Invalid extension",
        });
      } else if (file.size > maxFileSize) {
        excludedFiles.push({
          name: file.name,
          reason: "Exceeds maximum size of 8MB",
        });
      } else {
        validFiles.push(file);
      }
    });

    if (excludedFiles.length > 0) {
      const errorMessage = excludedFiles
        .map(
          (file) =>
            `File "${file.name}" was not selected because: ${file.reason}.`
        )
        .join("\n");
      // Display the message as a toast or alert
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      }); // Replace this with toast notification if required
    }

    // Add valid files to state
    setSelectedFiles((prev) => [...prev, ...validFiles]);

    // Reset the file input value to allow re-selection of the same file
    event.target.value = null;
  };

  const handleSendMessage = async () => {
    handleStopTyping();
    // if (!isLoading && !sendLoading) {
    if (!message && selectedFiles.length === 0) return; // Avoid sending empty messages

    try {
      if (selectedFiles.length > 0) {
        const placeholderAttachments = selectedFiles.map((file, index) => ({
          id: `placeholder-${index}`, // Temporary ID
          fileExtension: getFileType(file.name),
          fileName: file.name,
          fileSize: `${file.size} bytes`,
          fileType: file.type || null,
          fileUrl: URL.createObjectURL(file), // Local URL for preview
        }));
        // Map through placeholder attachments to create message objects
        const attachmentMessages = placeholderAttachments.map((file, index) => {
          const body = {
            content: index === 0 ? message : "", // Add message only for the last attachment
            message_type: "file",
            ...(replyMessage
              ? { replyToMessageId: replyMessage?.messageId }
              : {}),
            noLoad: true,
          };

          return createMessageObject(
            {
              ...body,
              replyToMessage: replyMessage ? replyMessage : null,
            },
            selectedChat,
            user,
            file
          );
        });

        // setMessages((prevMessages) => [...prevMessages, ...attachmentMessages]); // Append to the existing array
        setMessages((prevMessages) => {
          const todayKey = "Today";

          // Ensure messageObject is an array
          const newMessages = Array.isArray(attachmentMessages)
            ? attachmentMessages
            : [attachmentMessages];

          return {
            ...prevMessages,
            [todayKey]: prevMessages[todayKey]
              ? [...prevMessages[todayKey], ...newMessages]
              : newMessages,
          };
        });
        // If there are attachments, use the sendAttachmentMessage mutation with FormData
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("attachments", file); // Add files as attachments
        });
        formData.append("message_type", "file");
        formData.append("content", message || ""); // Include the text content if provided
        if (replyMessage) {
          formData.append("replyToMessageId", replyMessage?.messageId); // Add reply message ID if present
        }
        setSelectedFiles([]); // Clear file selection
        setMessage(""); // Clear the input field
        setReplyMessage(null);
        const response = await sendAttachmentMessage({
          chatId: selectedChat?.room_id,
          body: formData,
        }).unwrap();
        if (response?.status === "success") {
          socket.emit("sendMessage", {
            roomId: selectedChat?.room_id,
            messageId: response?.data?.messageId,
          });
          // socket.emit("chatClosedNotification");
        }
      } else {
        // If no attachments, use the sendMessage mutation
        const body = {
          content: message,
          message_type: "text",
          ...(replyMessage
            ? { replyToMessageId: replyMessage?.messageId }
            : {}),
          noLoad: false,
        };
        const sender = selectedChat.otherParticipants.find(
          (participant) => participant.user_id === user.userId
        );
        const messageObject = createMessageObject(
          {
            ...body,
            replyToMessage: replyMessage ? replyMessage : null,
          },
          selectedChat,
          user
        );
        // setMessages((prevMessages) => [...prevMessages, messageObject]); // Append to the existing array
        setMessages((prevMessages) => {
          const todayKey = "Today";

          // Ensure messageObject is an array
          const newMessages = Array.isArray(messageObject)
            ? messageObject
            : [messageObject];

          return {
            ...prevMessages,
            [todayKey]: prevMessages[todayKey]
              ? [...prevMessages[todayKey], ...newMessages]
              : newMessages,
          };
        });
        setMessage(""); // Clear the input field
        setReplyMessage(null);
        // Iterate through the FormData object and log each key-value pair
        // const response = await sendMessage({
        //   chatId: selectedChat?.room_id,
        //   body,
        // }).unwrap();

        // if (response?.status === "success") {
        // console.log("Id : ", selectedChat?.user_id);
        console.log("User : ", user.userId);
        socket.emit("sendTextMessage", {
          roomId: selectedChat?.room_id,
          senderId: user.userId,
          companyId: user?.companyId,
          content: message,
          replyToMessageId: replyMessage ? replyMessage?.messageId : null,
          // receiverId: selectedChat?.user_id,
        });
        // socket.emit("chatClosedNotification", selectedChat?.user_id);
        // }
      }
    } catch (error) {
      // console.error("Error sending message:", error);
      toast.error(
        "Error uploading files. Max file sizes should not be more then 8MB.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    // }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleTyping = () => {
    socket.emit("typing", selectedChat?.room_id);
  };
  const handleStopTyping = () => {
    socket.emit("stopTyping", selectedChat?.room_id);
  };

  const getFileType = (attachmentName) => {
    const extension = attachmentName?.split(".")?.pop()?.toLowerCase();
    return extension;
  };

  // Focus the input field when message or selectedChat changes
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current.focus();
    }
  }, [message, selectedChat, replyMessage]);

  // Handle paste event
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          // Validate and add the file
          if (validateFile(file)) {
            setSelectedFiles((prev) => [...prev, file]);
          }
        }
      }
    }
  };

  // Validate file (check extension and size)
  const validateFile = (file) => {
    const allowedExtensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "doc",
      "docx",
      "txt",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "gif",
      "bmp",
      "svg",
      "zip",
      "rar",
      "mp3",
      "mp4",
    ];
    const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(
        `Invalid file type: .${fileExtension}. Only the following are allowed: ${allowedExtensions.join(
          ", "
        )}.`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return false;
    }
    if (file.size > maxFileSize) {
      toast.error(`File size exceeds the limit of 8 MB.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      console.log("====================================");
      console.log("pasteFile");
      console.log("====================================");
      inputElement.addEventListener("paste", handlePaste);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  return (
    <div
      className="user-chat-message-input-wrapper"
      onDrop={onFileDrop}
      onDragOver={onDragOver}
    >
      <div className="user-chat-message-input-container">
        <div className="d-flex flex-column">
          {replyMessage && (
            <div className="w-100 user-chat-message-input-reply-message text-size-weight d-flex align-items-start gap-2">
              <div
                style={{ flex: 1 }}
                className="d-flex flex-1  align-items-start"
              >
                <div className="d-flex align-items-start flex-1 ">
                  <Icon className="icons" icon={"mdi:comma"} />
                  <Icon className="icons" icon={"mdi:comma"} />
                  <Icon
                    className="icons"
                    height={20}
                    icon={"pepicons-pop:line-y"}
                  />
                  <div className="d-flex flex-column">
                    {replyMessage?.attachments?.length > 0 && (
                      <div className="message-file-attachment-section">
                        <div className="message-file-attachment-grid">
                          {replyMessage?.attachments?.map((file, index) => (
                            <div
                              key={index}
                              className="message-file-attachment-item"
                              style={{ backgroundColor: "#fff" }}
                              // onClick={() => handleFileClick(file)}
                            >
                              <div className="message-file-attachment-info">
                                <div className="d-flex align-items-center gap-2 message-file-attachment-name">
                                  {getFileType(file.fileName) === "photo" && (
                                    <img
                                      key={file?.id}
                                      className="thumbnail"
                                      src={file?.fileUrl}
                                      alt={file?.fileName}
                                      // onClick={() =>
                                      //   handleThumbn(file)
                                      // }
                                    />
                                  )}
                                  {getFileType(file.fileName) !== "photo" && (
                                    <FileIcon
                                      extension={getFileType(file.fileName)}
                                      {...defaultStyles[
                                        getFileType(file.fileName)
                                      ]}
                                    />
                                  )}

                                  {file?.fileName}
                                </div>

                                {/* <div className="d-flex align-items-center gap-2">
                          <div
                            className="message-file-attachment-size"
                            onClick={(e) => {
                              if (!message?.noLoad) {
                                e.stopPropagation();
                                handleDownload(file?.fileUrl, true);
                              }
                            }}
                          >
                            <Icon icon="mage:download" fontSize={24} />
                          </div>
                          <div
                            className="message-file-attachment-size"
                            onClick={(e) => {
                              if (!message?.noLoad) {
                                e.stopPropagation();
                                handleFileClick(file);
                              }
                            }}
                          >
                            <Icon icon="ion:open-outline" fontSize={24} />
                          </div>
                        </div> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <p className="text-size-weight">{replyMessage?.content}</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setReplyMessage(null)}
                className="user-chat-message-input-reply-message-close"
              >
                <Icon icon={"ic:round-close"} />
              </div>
            </div>
          )}
          {selectedFiles && selectedFiles?.length > 0 && (
            <div className="d-flex flex-column gap-1 my-2 pb-2 chat-selected-file-attachment-container">
              {selectedFiles?.map((file, id) => (
                <div
                  key={id}
                  className="w-100 d-flex align-items-center gap-2 chat-selected-file-attachment p-2 position-relative"
                >
                  {/* File Icon */}
                  <FileIcon
                    extension={getFileType(file?.name)}
                    {...defaultStyles[getFileType(file?.name)]}
                  />

                  {/* File Name */}
                  <p className="mb-0 flex-grow-1">
                    {file?.name} <span>({formatFileSize(file.size)})</span>
                  </p>

                  {/* Close Icon */}
                  <div
                    onClick={() => {
                      // Remove the file from selectedFiles array
                      const updatedFiles = selectedFiles.filter(
                        (_, index) => index !== id
                      );
                      setSelectedFiles(updatedFiles);
                    }}
                    className="user-chat-message-input-reply-message-close position-absolute"
                    style={{ right: "10px", cursor: "pointer" }} // Adjust right positioning and pointer for clickability
                  >
                    <Icon icon={"ic:round-close"} />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="d-flex align-items-center gap-3">
            <label htmlFor="file-input" className="message-link">
              <Icon width={20} height={20} icon="ant-design:link-outlined" />
            </label>
            <input
              id="file-input"
              type="file"
              className="text-size-weight visually-hidden"
              onChange={handleFileSelect}
              multiple
              accept="
    .pdf,
    .jpeg,
    .jpg,
    .png,
    .doc,
    .docx,
    .txt,
    .xls,
    .xlsx,
    .ppt,
    .pptx,
    .gif,
    .bmp,
    .svg,
    .zip,
    .rar,
    .mp3,
    .mp4
  "
            />
            <input
              ref={inputRef} // Attach the ref to the input field
              className="text-size-weight"
              type="text"
              placeholder="Type a message"
              value={message}
              onBlur={() => handleStopTyping()}
              // disabled={isLoading || sendLoading}
              onChange={(e) => {
                handleTyping();
                setMessage(e.target.value);
              }}
              autoComplete="off"
              onKeyPress={handleKeyPress}
            />
            {/* {(isLoading || sendLoading) && (
              <Spinner className="global-spinner" />
            )} */}
            <Icon
              width={20}
              height={20}
              className="message-send"
              icon="iconoir:send-solid"
              onClick={handleSendMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserChatMessageInput;
