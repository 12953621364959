import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ChangeUserRole = (props) => {
  const { userProfile } = useSelector((state) => state?.profile);
  const [selectedRole, setSelectedRole] = useState(
    props?.roles?.find(
      (item) =>
        item.roleName?.toString().toLowerCase() ===
        props?.user?.roles[0]?.toString().toLowerCase()
    )?.roleId
  );
  const [selectedRoleName, setSelectedRoleName] = useState(
    props?.roles?.find(
      (item) =>
        item.roleName?.toString().toLowerCase() ===
        props?.user?.roles[0]?.toString().toLowerCase()
    )?.roleName
  );
  const [selectedModule, setSelectedModule] = useState(0);
  const [modules, setModules] = useState([]);
  const [rload, setRLoad] = useState(false);
  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };
  const handleSelectModule = (module) => {
    setSelectedModule(module);
  };

  useEffect(() => {
    setSelectedRole(
      props?.roles?.find(
        (item) =>
          item.roleName?.toString().toLowerCase() ===
          props?.user?.roles[0]?.toString().toLowerCase()
      )?.roleId
    );
    setSelectedRoleName(
      props?.roles?.find(
        (item) =>
          item.roleName?.toString().toLowerCase() ===
          props?.user?.roles[0]?.toString().toLowerCase()
      )?.roleName
    );
  }, [props.showAddRoleModal]);

  const handleSubmit = async () => {
    try {
      setRLoad(true);
      const response = await API.post("users/role-assign", {
        roleId: selectedRole,
        userId: props?.user?.userId,
      });
      if (response?.data?.status === "success") {
        props?.getUser();
        props.handleCloseAddRoleModal();
        toast.success("Role updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        if (props.user?.userId === userProfile?.userId) {
          const company = localStorage.getItem("company");
          window.location.replace(`/${company}/dashboard`);
        }
      }
    } catch (error) {
    } finally {
      setRLoad(false);
    }
  };

  return (
    <Modal
      show={props.showAddRoleModal}
      onHide={props.handleCloseAddRoleModal}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="w-100 modal-role-wrapper">
          <h6>Change Role</h6>
          <div className="w-100 roles-opt-wrapper">
            {props.roles?.map((rol, id) => (
              <div
                className={`${
                  selectedRole == rol?.roleId ? "role-selected-modal" : ""
                }`}
                key={id}
                onClick={() => {
                  handleSelectRole(rol?.roleId);
                  setSelectedRoleName(rol?.roleName);
                }}
              >
                {rol?.roleName.charAt(0).toUpperCase() + rol?.roleName.slice(1)}
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 mt-4 mb-4">
          {selectedRoleName?.toString()?.toLowerCase() == "admin" && (
            <p className="text-danger text-xs change-role-note m-0 p-0">
              <strong>Note:</strong> You set your role as admin which results in
              full access over all modules with rights and permissions.
            </p>
          )}
          {selectedRoleName?.toString()?.toLowerCase() !== "admin" && (
            <p className="text-danger text-xs change-role-note m-0 p-0">
              <strong>Note:</strong> Applying new role to all modules overwrite
              old permissions.
            </p>
          )}
        </div>
        <div className="w-100 modal-btn-wrapper d-flex mt-4 justify-content-end">
          <button onClick={handleSubmit}>
            {rload ? (
              <Spinner className="spinner-role" border="animation" />
            ) : (
              "Change Role"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUserRole;
