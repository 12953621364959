import { teamsListAction } from "../../actions/teamsActions";
import { PMBaseApi } from "../../services/PMBaseApi";

export const teamSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting team listings
    getTeamListing: builder.query({
      query: () => ({
        url: `teams/listing`, // API endpoint
        method: "POST",
      }),
      // Transform the response to modify the data structure as needed
      transformResponse: (response) => {
        return (
          response?.data
            ?.map((team) => ({
              ...team.teamInfo, // Extract teamInfo into the main object
              recentTaskCount: team?.recentTaskCount,
              projectCompleteCount: team?.projectCompleteCount,
              projectNotStartedCount: team?.projectNotStartedCount,
              projectInProgressCount: team?.projectInProgressCount,
              teamProgress: team?.teamProgress,
              projectsCount: team?.projectsCount,
            }))
            ?.reverse() || []
        ); // Reverse the array to get the latest data first, and handle potential undefined data
      },
      providesTags: ["teamListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // Cache data for 12 hours
    }),

    // Mutation to create a new team using FormData
    createTeam: builder.mutation({
      queryFn: async (formData, _queryApi, _extraOptions, baseQuery) => {
        try {
          const response = await baseQuery({
            url: `teams/create`, // API endpoint for team creation
            method: "POST",
            body: formData, // Using formData for the request body
          });
          if (response?.data?.status === "success") {
            // Return the new team data
            return response;
          } else {
            return { error: response.error || "An unknown error occurred" };
          }
        } catch (error) {
          return { error: "Failed to create team: " + error.message };
        }
      },
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          const { data: newTeam } = await queryFulfilled;
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                draft.unshift({
                  ...newTeam.teamInfo,
                  recentTaskCount: newTeam?.recentTaskCount,
                  projectCompleteCount: newTeam?.projectCompleteCount,
                  projectNotStartedCount: newTeam?.projectNotStartedCount,
                  projectInProgressCount: newTeam?.projectInProgressCount,
                  teamProgress: newTeam?.teamProgress,
                  projectsCount: newTeam?.projectsCount,
                });
              }
            )
          );
        } catch (error) {
          console.error("Error adding new team:", error);
        }
      },
      invalidatesTags: ["teamListing"], // Invalidate cache tags to refresh team listing if needed
    }),

    updateTeamData: builder.mutation({
      query: (data) => ({
        url: "teams/update", // API endpoint for updating team data
        method: "POST",
        body: data,
      }),
      // Optimistically update the cached data for the team
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const updatedTeam = data?.data[0];
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (team) => team.id === updatedTeam.teamInfo.id
                );

                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...updatedTeam.teamInfo,
                    ...updatedTeam,
                    teamMembers: updatedTeam.teamMembersDetails.users,
                  };
                }
              }
            )
          );
          dispatch(teamsListAction());
        } catch (error) {
          console.error("Failed to update team data:", error);
        }
      },
    }),

    // Mutation to update team icon
    updateTeamIcon: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("logo", data.logo);
        formData.append("teamId", data.teamId);
        return {
          url: "teams/update-team-icon", // API endpoint for updating team icon
          method: "POST",
          body: formData,
        };
      },
      // Optimistically update the cached data for the team icon
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const updatedTeam = data?.data[0];
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (team) => team.id === updatedTeam.teamInfo.id
                );
                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...updatedTeam.teamInfo,
                    ...updatedTeam,
                    teamMembers: updatedTeam.teamMembersDetails.users,
                  };
                }
              }
            )
          );
        } catch (error) {
          console.error("Failed to update team icon:", error);
        }
      },
    }),

    // Mutation to add a team member
    addTeamMember: builder.mutation({
      query: ({ teamId, teamMember }) => ({
        url: "teams/add-member", // API endpoint for adding a team member
        method: "POST",
        body: { teamId, teamMember },
      }),
      // Optimistically add the new member to the cached data
      onQueryStarted: async (
        { teamId, teamMember },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data } = await queryFulfilled;
          const updatedTeam = data?.data;
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (team) => team.id === updatedTeam.teamInfo.id
                );
                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...updatedTeam.teamInfo,
                    ...updatedTeam,
                    teamMembers: updatedTeam.teamMembersDetails.users,
                  };
                }
              }
            )
          );
        } catch (error) {
          console.error("Failed to add team member:", error);
        }
      },
    }),

    // Mutation to remove a team member
    removeTeamMember: builder.mutation({
      query: ({ teamId, teamMember }) => ({
        url: "teams/delete-member", // API endpoint for removing a team member
        method: "POST",
        body: { teamId, teamMember },
      }),
      // Optimistically remove the member from the cached data
      onQueryStarted: async (
        { teamId, teamMember },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data } = await queryFulfilled;
          const updatedTeam = data?.data;
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (team) => team.id === updatedTeam.teamInfo.id
                );
                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...updatedTeam.teamInfo,
                    ...updatedTeam,
                    teamMembers: updatedTeam.teamMembersDetails.users,
                  };
                }
              }
            )
          );
        } catch (error) {
          console.error("Failed to remove team member:", error);
        }
      },
    }),

    // Mutation to delete a team
    deleteTeam: builder.mutation({
      query: (teamId) => ({
        url: "teams/delete", // API endpoint for deleting a team
        method: "POST",
        body: { teamId }, // Send teamId in the request body
      }),
      onQueryStarted: async (teamId, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Wait for the delete request to be fulfilled

          // Update the cached data to remove the deleted team
          dispatch(
            teamSlice.util.updateQueryData(
              "getTeamListing",
              undefined,
              (draft) => {
                // Find and remove the team from the draft
                const index = draft.findIndex((team) => team.id === teamId);
                if (index !== -1) {
                  draft.splice(index, 1); // Remove the team from the array
                }
              }
            )
          );
          dispatch(teamsListAction());
        } catch (error) {
          console.error("Failed to delete team:", error);
        }
      },
      // invalidatesTags: ["teamListing"], // Optional: If you want to ensure the data is fresh
    }),

    // Mutation to clear all cached data
    clearAllCache: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call, just return an empty object
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant cache tags
          dispatch(teamSlice.util.resetApiState());
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),
  }),
});

// Export the hooks generated by `teamSlice`
export const {
  useGetTeamListingQuery,
  useCreateTeamMutation,
  useClearAllCacheMutation,
  useUpdateTeamDataMutation,
  useUpdateTeamIconMutation,
  useAddTeamMemberMutation,
  useRemoveTeamMemberMutation,
  useDeleteTeamMutation,
} = teamSlice;
