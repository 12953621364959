import {
  CREATE_PROJECT_PHASE_FAIL,
  CREATE_PROJECT_PHASE_REQUEST,
  CREATE_PROJECT_PHASE_SUCCESS,
  GET_PROJECTS_PHASES_FAIL,
  GET_PROJECTS_PHASES_REQUEST,
  GET_PROJECTS_PHASES_SUCCESS,
  UPDATE_PROJECT_PHASE_FAIL,
  UPDATE_PROJECT_PHASE_REQUEST,
  UPDATE_PROJECT_PHASE_SUCCESS,
} from "../constants/projectPhaseContants";
import { ProjectAPI } from "../services/ClientApi";

// Action for creating project phase
export const createProjectPhaseAction = (phaseData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT_PHASE_REQUEST });

    // Assuming ProjectAPI.createPhase is a function to create a project phase
    const { data } = await ProjectAPI.post(
      "project/pm-create-project-phase",
      phaseData
    );
    dispatch({
      type: CREATE_PROJECT_PHASE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_PHASE_FAIL,
      payload: error,
      meta: {
        retryAction: createProjectPhaseAction(phaseData), // Retry action
      },
    });
  }
};

// Action for getting project phases
export const getProjectsPhasesAction = (phaseData) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROJECTS_PHASES_REQUEST });

    // Assuming ProjectAPI.getPhases is a function to fetch project phases
    const { data } = await ProjectAPI.post(
      "project/pm-project-phases",
      phaseData
    );
    dispatch({
      type: GET_PROJECTS_PHASES_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: GET_PROJECTS_PHASES_FAIL,
      payload: error,
      meta: {
        retryAction: getProjectsPhasesAction(phaseData), // Retry action
      },
    });
  }
};

// Action for updating project phase
export const updateProjectPhaseAction = (phaseData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROJECT_PHASE_REQUEST });

    // Assuming ProjectAPI.updatePhase is a function to update a project phase
    const { data } = await ProjectAPI.post(
      "project/pm-update-project-phase",
      phaseData
    );
    dispatch({
      type: UPDATE_PROJECT_PHASE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: UPDATE_PROJECT_PHASE_FAIL,
      payload: error,
      meta: {
        retryAction: updateProjectPhaseAction(phaseData), // Retry action
      },
    });
  }
};
