import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ListofTaskMemberCellRenderer,
  ProjectDashboardInfoRenderer,
  ProjectNameTooltip,
  SituationCellRenderer,
  TaskStatusInfoRenderer,
} from "../../Tasks/components/utils/data";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import RepeatIcon from "../../../assets/repeat-task-icon.png";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import progressImage from "../../../assets/in-progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import criticalImage from "../../../assets/critical.svg";
import deleteIcon from "../../../assets/circle-delete.png";
import NotStarted from "../../../assets/not-started.svg";
import { IoIosArrowDown } from "react-icons/io";
import { createPortal } from "react-dom";
import { useMemo } from "react";
import TaskDetailOffCanvas from "../../Tasks/components/TaskDetailOffCanvas";
import { Tooltip } from "react-tooltip";
import {
  convertToISO,
  dateFormatter,
  formatCustomDate,
} from "../../../helper/dateFormatter";
import { useSelector } from "react-redux";
import TasksListingMainMembersEditor from "../../Tasks/components/TaskListingMainMemberEditor";
import bugIcon from "../../../assets/bug-icon.png";
import newIcon from "../../../assets/new-task.png";
import errorIcon from "../../../assets/issue.png";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInTaskOne, isOwnerInTasks } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { useSearchParams } from "react-router-dom";
import TimerIcon from "../../../assets/overdue-timer.png";
import {
  useClearAllTasksCacheMutation,
  useUpdateTaskDataMutation,
} from "../../../slices/tasks/tasksSlice";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};
function DashboardTableListing({
  props,
  rowData,
  setRowData,
  setSelectedTask,
  selectedTask,
  filters,
  setFilters,
  taskList,
  setTaskList,
  getStatusCounts,
  pageParams,
  listingType,
  userList,
  updateTaskMemberData,
  disableAction,
  showTaskDetails,
  setShowTaskDetails,
  setNewId,
  isDark,
}) {
  const [updateTaskData, { isLoading: isUpdating, isSuccess: updateSuccess }] =
    useUpdateTaskDataMutation();
  const [wasUnread, setWasUnread] = useState(false);
  const [updateTaskId, setUpdateTaskId] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const gridRef = useRef();
  const [edit, setEdit] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const optionsDropdownRef = useRef(null);
  const [readLoad, setReadLoad] = useState(false);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [taskProgressOptionsList] = useState(() => {
    const baseOptions = [
      "Not Started",
      "In Progress",
      "On Hold",
      "Completed",
      "Submit For Review",
    ];
    // Conditionally add "Deleted" if listingType is COMPLETED_TASKS
    // return baseOptions;
    return listingType === "COMPLETED_TASKS"
      ? [...baseOptions, "Deleted"]
      : baseOptions;
  });
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  // const { projectAllStatusList } = useSelector(
  //   (state) => state.projectAllStatusListMain
  // );
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );

  const { userProfile } = useSelector((state) => state?.profile);

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { projectFormList } = useSelector((state) => state.projectFormListData);

  const [projectOptions, setProjectOptions] = useState([]);

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  // Update pin
  // Update name
  const updateProject = async (data) => {
    try {
      let response;

      response = await updateTaskData({
        ...pageParams,
        ...data,
      }).unwrap();

      if (response?.status === "success") {
        if (!readLoad) {
          toast.success("Task updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 300,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }
    } catch (error) {
      console.log("==========error==========================");
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setReadLoad(false);
    }
  };

  // Date formating
  class DatePicker {
    constructor() {
      // Initialize necessary variables
      this.eInput = null;
      this.params = null;
      this.data = null;
    }

    init(params, data) {
      this.params = params;
      this.data = data; // Assuming data is directly accessible from params

      this.eInput = document.createElement("input");
      this.eInput.value = params.value || "";
      this.eInput.classList.add("ag-input");
      this.eInput.style.height = "var(--ag-row-height)";
      this.eInput.style.fontSize = "calc(var(--ag-font-size) + 1px)";

      $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: this.onSelect.bind(this),
      });
    }

    onSelect(dateText) {
      const { data } = this.params;
      if (data) {
        const updatedData = { ...data, due_date: dateText };
        this.params.api.applyTransaction({ update: [updatedData] });
      }
    }

    getGui() {
      return this.eInput;
    }

    afterGuiAttached() {
      this.eInput.focus();
      this.eInput.select();
    }

    getValue() {
      return this.eInput.value;
    }

    destroy() {}

    isPopup() {
      return false;
    }
  }

  // Date formater
  // Date formatter
  const formatDate = (dateStr) => {
    if (!dateStr) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }
    const dateString = convertToISO(dateStr);
    const [day, month, year] = dateString?.split("/").map(Number);
    const formattedDate = new Date(year, month - 1, day); // Month is 0-indexed

    const monthNames = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    // Check if formattedDate is valid
    if (isNaN(formattedDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const dateParts = formattedDate.toLocaleDateString("en-US").split("/");
    return `${dateParts[1]} ${monthNames[formattedDate.getMonth() + 1]}, ${
      dateParts[2]
    }`;
  };

  const fullDateToString = (inputDateString) => {
    if (!inputDateString) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }

    const inputDate = new Date(inputDateString);

    // Check if inputDate is valid
    if (isNaN(inputDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based
    const year = inputDate.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // Column definitions for task listing
  const columnData = [
    {
      field: "taskName",
      headerName: "Tasks",
      // pinned: "left",
      editable: false,
      suppressMenu: true,
      width: 405,
      minWidth: 405,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            Tasks
            <span className="ms-1 task-count-text">{rowData?.length || 0}</span>
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        return (
          <TaskNameCellRenderer
            data={params.data}
            api={params.api}
            handleEdit={handleEdit}
            row={params.rowIndex}
            showPin={hoveredRow === params.data.id}
            setEdit={setEdit}
            params={params}
            canEdit={false}
            noPin={false}
            updateProject={updateProject}
            setReadLoad={setReadLoad}
          />
        );
      },
    },
    {
      field: "taskPriority",
      headerName: "Priority",
      maxWidth: 120,
      minWidth: 120,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,

      cellEditorSelector: (params) => {
        return {
          component: TasksSelectPriorityEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: SituationCellRenderer,
    },
    {
      field: "taskMembers",
      headerName: "Assigned to",
      minWidth: 250,
      maxWidth: 250,
      suppressMenu: true,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,
      width: 145,
      cellEditorParams: {
        popupParent: document.querySelector("body"),
      },
      cellEditorSelector: (params) => {
        return {
          component: () => {
            return (
              <TasksListingMainMembersEditor
                params={params}
                isDark={isDark}
                userList={userList}
                projectTypes={projectTypeList}
                listingType={listingType}
                pageParams={pageParams}
                updateTaskMemberData={updateTaskMemberData}
                isOwner={isOwnerInTasks(
                  rowData,
                  hoveredRow,
                  userProfile?.userId
                )}
              />
            );
          },
          popup: true,
          popupPosition: "under",
        };
      },
      cellRenderer: ListofTaskMemberCellRenderer,
      onCellValueChanged: (params) => {
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
          suppressFlash: true,
        });
      },
    },
    {
      field: "taskDeadline",
      headerName: "Due Date",
      minWidth: 190,
      maxWidth: 190,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,
      cellRenderer: (params) => {
        const taskDeadline = params.data.taskDeadline
          ? new Date(params.data.taskDeadline)
          : ""; // Parse taskDeadline into a Date object
        const currentDate = new Date();
        return (
          <div
            className="project-date"
            style={{
              color:
                taskDeadline < currentDate &&
                params.data.taskDeadline &&
                params?.data?.taskStatus?.defaultMarkAs !== "completed"
                  ? "#ff0000"
                  : "",
            }}
          >
            {" "}
            {/* <img src={CheckIcon} alt="check-icon" />{" "}
            {params?.data?.taskDeadline ? "Due: " : ""} */}
            {params?.data?.taskDeadline
              ? formatCustomDate(
                  params?.data?.taskDeadline?.split("T")[0],
                  params?.data?.taskDeadline?.split("T")[1]
                )
              : "No deadline"}{" "}
          </div>
        );
      },
      cellEditorSelector: (params) => {
        return {
          component: (params) => {
            let startDate = new Date();
            let endDate = new Date(parseDate(params.data.taskDeadline));
            return (
              <div
                data-theme={isDark}
                className="project-details-task-table-flatpickr-container task-list-date-container"
                // style={{width: "180px"}}
              >
                <Flatpickr
                  options={{
                    altFormat: "M d, Y H:i",
                    dateFormat: "Y-m-d H:i",
                    // enableTime: true,
                    altInput: true,
                    minDate: startDate,
                    theme: "light",
                    onReady: function (selectedDates, dateStr, instance) {
                      instance.open();
                    },
                    disableMobile: true,
                    static: true,
                    position: "over",
                  }}
                  value={params.data.taskDeadline}
                  placeholder={"Select Due Date"}
                  onChange={async ([date]) => {
                    let currentDate = new Date();
                    let newEnd = new Date(fullDateToString(date));
                    // Create new Date objects from the year, month, and day components
                    currentDate = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      currentDate.getDate()
                    );
                    endDate = new Date(
                      endDate.getFullYear(),
                      endDate.getMonth(),
                      endDate.getDate()
                    );
                    startDate = new Date(
                      startDate.getFullYear(),
                      startDate.getMonth(),
                      startDate.getDate()
                    );
                    newEnd = new Date(
                      newEnd.getFullYear(),
                      newEnd.getMonth(),
                      newEnd.getDate()
                    );
                    // Purpose of this functionality is to update overdue count statuses in filter section in project main page
                    if (
                      endDate < currentDate &&
                      newEnd >= currentDate &&
                      params?.data?.taskStatus?.statusName?.toLowerCase() !==
                        "completed"
                    ) {
                      await getStatusCounts("DECREMENT_OVERDUE");
                    }
                    if (
                      endDate >= currentDate &&
                      newEnd < currentDate &&
                      params?.data?.taskStatus?.statusName?.toLowerCase() !==
                        "completed"
                    ) {
                      await getStatusCounts("INCREMENT_OVERDUE");
                    }
                    const formattedDate =
                      date.getFullYear() +
                      "-" +
                      ("0" + (date.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + date.getDate()).slice(-2);
                    const updatedProjectData = {
                      ...params.data,
                      taskDeadline: formattedDate,
                    };
                    // Find the index of the object with the matching projectId
                    const index = taskList?.findIndex(
                      (project) => project.id === updatedProjectData.id
                    );

                    // If the projectId is found, update the object at that index
                    if (index !== -1) {
                      // Create a new array by spreading the original listToFilter array
                      const updatedListToFilter = [...taskList];
                      // Replace the object at the found index with the updatedProjectData
                      updatedListToFilter[index] = updatedProjectData;
                      // Set the state with the updated array
                      setTaskList(updatedListToFilter);
                    }

                    params.api.applyTransaction({
                      update: [updatedProjectData],
                    });

                    const fieldEndData = {
                      taskId: params?.data?.id,
                      fieldName: "taskDeadline",
                      fieldValues: fullDateToString(date),
                    };
                    params.api.stopEditing();
                    await updateProject(fieldEndData);
                    // setShowDueDateModal(true);
                  }}
                />
              </div>
            );
          },
          popup: true,
          popupPosition: "over",
        };
      },
      cellEditor: DatePicker,
      valueFormatter: (params) => formatDate(params.data.taskDeadline),
    },
    {
      field: "taskStatus",
      headerName: "Status",
      maxWidth: 170,
      minWidth: 170,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProgressEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: (params) => {
        return TaskStatusInfoRenderer(params);
      },
    },
    {
      field: "",
      headerName: "Project",
      width: 190,
      minWidth: 190,
      editable: false,
      cellClass: (params) => {
        let classes = "task-project-col";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += " selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: ProjectDashboardInfoRenderer,
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProjectEditor,
          popup: true,
          popupPosition: "over",
        };
      },
    },

    {
      field: "MaxTaskScore",
      headerName: "Task's Points",
      editable: false,
      minWidth: 150,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {String(params?.data?.MaxTaskScore || 0).padStart(2, "0")}
            </p>
          </div>
        );
      },
    },
    {
      field: "obtainedScore",
      headerName: "Obtained Points",
      editable: false,
      minWidth: 150,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p className="obtained-points-text-300">
              {String(params?.data?.obtainedScore || 0).padStart(2, "0")}
            </p>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created",
      editable: false,
      minWidth: 130,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.createdAt, 3)}
            </p>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      editable: false,
      minWidth: 130,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.updatedAt, 3)}
            </p>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllProjects().catch((error) => {
      console.error("Error fetching projects:", error);
    });
  }, [projectFormList]);

  useEffect(() => {
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const handleMouseOut = (event) => {
    // Get the bounding box of the grid and options dropdown
    const gridRect = gridRef?.current?.getBoundingClientRect();
    const optionsDropdownRect =
      optionsDropdownRef?.current?.getBoundingClientRect();

    // Check if the mouse has left the grid area
    if (
      event.clientX > gridRect.right ||
      event.clientY < gridRect.top ||
      event.clientY > gridRect.bottom + 30
    ) {
      if (!showMore) {
        setHoveredMenu(null);
        setHoveredRow(null);
        setShowMore(false);
      }
      // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

      if (
        showMenu === false ||
        (optionsDropdownRect &&
          (event.clientX < optionsDropdownRect.left ||
            event.clientX > optionsDropdownRect.right ||
            event.clientY < optionsDropdownRect.top ||
            event.clientY > optionsDropdownRect.bottom))
      ) {
        setHoveredMenu(null);
        setShowMenu(false);
      }
    }
  };

  const gridReady = (params) => {
    setGridApi(params.api);
    var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected ? "#deebff" : "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      minWidth: "120px", // Set the desired width
    }),
  };
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("-");
    return new Date(`${month}/${year}/${day}`);
  };

  const handleTaskDetailsCloseModal = () => {
    setSelectedTask(null);
    setNewId(null);
    setShowTaskDetails(false);
  };

  const handleTaskDetailsShowModal = () => {
    setShowTaskDetails(true);
  };

  const OpenProjectCellRenderer = ({ showModal, id, setEdit, handleOpen }) => {
    return (
      <div
        className="open-target-link d-flex align-items-center justify-content-between "
        onClick={(e) => {
          if (!disableAction) {
            e.stopPropagation();
            setSelectedTask(id);
            showModal();
            handleOpen();
          }
        }}
        onMouseOver={() => setEdit(false)}
      >
        <div>
          {" "}
          <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
        </div>
        {/* <img src={openTask} alt="Open Task" width={100} height={100} /> */}
      </div>
    );
  };

  const CustomTasksPriorityEditorControl = ({ children, menuIsOpen, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorities.map((res, index) => {
              if (
                res?.priorityName?.toLowerCase() ===
                props.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.priorityName?.toLowerCase() === "critical"
                        ? criticalImage
                        : res?.priorityName?.toLowerCase() === "highest"
                        ? highestImage
                        : res?.priorityName?.toLowerCase() === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div className="select-icon me-1"  style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectPriorityEditor = (params) => {
    const [selected] = useState(params?.data?.taskPriority?.priorityName);
    return (
      <div style={{ height: "100%", width: "120px" }}>
        <Select
          className={`basic-single tasks-select-status-editor task-situation-filter-top-wrap-select-div task-${selected?.toLowerCase()}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          menuPlacement="auto"
          name="color"
          options={taskPriorities.map((res, index) => ({
            label: (
              <div className="d-flex align-items-center gap-1">
                {" "}
                {index === 0 && (
                  <img
                    src={criticalImage}
                    alt="Critical"
                    width={20}
                    height={20}
                  />
                )}
                {index === 1 && (
                  <img
                    src={highestImage}
                    alt="Critical"
                    width={20}
                    height={20}
                  />
                )}
                {index === 2 && (
                  <img
                    src={mediumImage}
                    alt="Critical"
                    width={20}
                    height={20}
                  />
                )}
                {index === 3 && (
                  <img src={lowImage} alt="Critical" width={20} height={20} />
                )}
                {res.priorityName}
              </div>
            ),
            value: res,
          }))}
          components={{ Control: CustomTasksPriorityEditorControl }}
          onChange={async (e) => {
            params.data.taskPriority = e.value;
            params.api.stopEditing();
            // setSelected(e.value);
            const fieldStartData = {
              taskId: params?.data?.id,
              fieldName: "taskPriorityId",
              fieldValues: e.value.id,
            };
            await updateProject(fieldStartData);
          }}
        />
      </div>
    );
  };

  const CustomTasksProgressEditorControl = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskProgressOptionsList?.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "deleted"
                        ? deleteIcon
                        : res?.toLowerCase() === "in progress"
                        ? progressImage
                        : res?.toLowerCase() === "not started"
                        ? NotStarted
                        : res?.toLowerCase() === "completed"
                        ? doneImage
                        : res?.toLowerCase() === "on hold"
                        ? reviewImage
                        : res?.toLowerCase() === "submit for review" &&
                          reviewIcon
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div className="select-icon me-1">
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomTasksProjectEditorControl = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectOptions?.map((res, index) => {
              if (
                res?.label.toLowerCase() ===
                props?.getValue()[0]?.label?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={res?.icon?.props?.src}
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div className="select-icon me-1">
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectProgressEditor = (params) => {
    const [selected, setSelected] = useState(
      params.data?.taskStatus?.statusName
    );
    return (
      <div style={{ height: "100%", minWidth: "170px" }}>
        <Select
          className={`basic-single tasks-select-status-editor task-listing-situation-filter-top-wrap-select-div task-${selected
            ?.toLowerCase()
            .split(" ")
            .join("-")}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          menuPlacement="auto"
          name="color"
          options={projectStatusList?.map((res, index) => ({
            label: (
              <div className="d-flex align-items-center gap-1">
                {" "}
                {res?.statusName?.toLowerCase() === "not started" && (
                  <img src={NotStarted} alt="Critical" width={20} height={20} />
                )}
                {res?.statusName?.toLowerCase() === "in progress" && (
                  <img
                    src={progressImage}
                    alt="Progress"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "on hold" && (
                  <img
                    src={reviewImage}
                    alt="Submit For Review"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "submit for review" && (
                  <img
                    src={reviewIcon}
                    alt="Submit For Review"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "completed" && (
                  <img src={doneImage} alt="Done" width={20} height={20} />
                )}
                {res?.statusName?.toLowerCase() === "deleted" && (
                  <img src={deleteIcon} alt="Done" width={20} height={20} />
                )}
                {res?.statusName}
              </div>
            ),
            value: res,
          }))}
          components={{ Control: CustomTasksProgressEditorControl }}
          onChange={async (e) => {
            if (
              params?.data?.hasApproval &&
              !isOwnerInTaskOne(params?.data, userProfile?.userId) &&
              e?.value?.statusName?.toLowerCase() === "completed"
            ) {
              // Assuming index 4 is the restricted one
              setShowStatusModal(true);
              return; // Prevent the Select component's value from updating
            } else {
              let endDate = new Date(parseDate(params.data.taskDeadline));
              let currentDate = new Date();
              // Create new Date objects from the year, month, and day components
              currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              );
              endDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate()
              );
              const currentStatus =
                params?.data?.taskStatus?.statusName?.toLowerCase() ||
                params?.data?.taskStatus?.name?.toLowerCase();
              const newStatus = e.value.statusName.toLowerCase();
              if (currentStatus !== "completed" && newStatus === "completed") {
                getStatusCounts("INCREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("DECREMENT_OVERDUE");
                }
              } else if (
                currentStatus === "completed" &&
                newStatus !== "completed"
              ) {
                getStatusCounts("DECREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("INCREMENT_OVERDUE");
                }
              }
              params.data.taskStatus = {
                ...e.value,
                name: e.value.statusName,
              };
              setSelected(e.value.statusName);
              params.api.stopEditing();
              const fieldStartData = {
                taskId: params?.data?.id,
                fieldName: "taskStatusId",
                fieldValues: e.value.id,
              };
              await updateProject(fieldStartData);
            }
          }}
        />
      </div>
    );
  };

  // Project helper
  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.id,
        }))
      );
    }
  };

  const getProjectOptionById = (value) => {
    return projectFormList?.find((option) => option.id === value);
  };

  // Main Project Selector Component
  // Project selector
  const TasksSelectProjectEditor = (params) => {
    const [selected, setSelected] = useState(params.data?.project);
    return (
      <div
        style={{ height: "100%", minWidth: "195px" }}
        className="project-tasklist-select"
        data-theme={isDark}
      >
        <Select
          menuPlacement="auto"
          classNamePrefix="select"
          placeholder="Project"
          data-theme={isDark}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          value={{
            label: selected?.projectName,
            value: selected?.id,
          }}
          options={projectOptions?.map((res, index) => ({
            label: (
              <div className="d-flex align-items-center gap-1" key={index}>
                {res?.icon}
                {res?.label}
              </div>
            ),
            value: res.value,
          }))}
          components={{ Control: CustomTasksProjectEditorControl }}
          onChange={async (e) => {
            const selectedProject = getProjectOptionById(e.value);

            params.data.project = selectedProject;
            params.api.stopEditing();
            // // setSelected(e.value);
            const fieldStartData = {
              taskId: params?.data?.id,
              fieldName: "projectId",
              fieldValues: e.value,
            };
            await updateProject(fieldStartData);
          }}
        />
      </div>
    );
  };
  const TaskNameCellRenderer = (props) => {
    const canEdit = props.canEdit && isPermitted("task/update");
    const [icon, setIcon] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const { taskName } = props.data;

    const currentDate = new Date();
    const taskDeadline = new Date(props.data.taskDeadline); // Parse taskDeadline into a Date object

    return (
      <div
        className="d-flex align-items-center justify-content-between custom-task-name-renderer"
        onMouseOver={() => setIcon(true)}
        onMouseLeave={() => setIcon(false)}
        onClick={async (e) => {
          if (!disableAction) {
            e.stopPropagation();
            setSelectedTask(props.params.data.id);
            handleTaskDetailsShowModal();
            if (!props.params?.data?.isRead) {
              // Create a copy of the data object and update the isRead property
              const updatedData = { ...props.params.data, isRead: true };
              props.params.node.setData(updatedData); // Refresh the grid cells to reflect the changes
              props.params.api.refreshCells({
                rowNodes: [props.params.node],
                force: true,
                suppressFlash: true,
              });
              // Delay clearing cache by 2 seconds
              setWasUnread(true);
            }
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="d-flex align-items-center task-title m-0">
            <div
              className="module-task-image position-relative"
              onMouseEnter={(event) => {
                setShowTooltip(true);
                const rect = event.target.getBoundingClientRect();
                setTooltipPosition({ x: rect.x, y: rect.y });
              }}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <img
                src={
                  props?.data?.taskType?.typeName?.toLowerCase() === "bug"
                    ? bugIcon
                    : props?.data?.taskType?.typeName?.toLowerCase() === "error"
                    ? criticalImage
                    : props?.data?.taskType?.typeName?.toLowerCase() ===
                      "new task"
                    ? newIcon
                    : props?.data?.taskType?.typeName?.toLowerCase() === "issue"
                    ? errorIcon
                    : newIcon
                }
                alt={props?.data?.taskType?.typeName}
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                }}
              />

              {showTooltip && (
                <Portal>
                  <ProjectNameTooltip
                    projectName={
                      props?.data?.taskType?.typeName
                        ? props?.data?.taskType?.typeName
                        : "New Task"
                    }
                    position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
                  />
                </Portal>
              )}
            </div>
            <p
              className="mb-0"
              onMouseOver={() => {
                if (canEdit) {
                  props.setEdit(true);
                }
              }}
              onMouseLeave={() => props.setEdit(false)}
              onClick={() => {
                if (canEdit) {
                  props.handleEdit(props.api, props.row);
                }
              }}
              style={{ cursor: canEdit ? "pointer" : "" }}
            >
              {" "}
              {taskName?.length > 30
                ? taskName?.substring(0, 30) + "..."
                : taskName}
              {taskDeadline < currentDate &&
                props.data.taskDeadline &&
                props?.data?.taskStatus?.defaultMarkAs !== "completed" && (
                  <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                    {/* <Icon
                    icon="f7:exclamationmark-circle"
                    className="overdue-task-icon"
                  /> */}
                    <img src={TimerIcon} alt="timer" width={14} height={14} />
                    <div
                      className="custom-task-tooltip"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents click event from bubbling to parent
                      }}
                    >
                      Due date is exceeded
                    </div>
                  </div>
                )}
              {props.data.isRecurred === 1 && (
                <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                  {/* <Icon
                    icon="f7:exclamationmark-circle"
                    className="overdue-task-icon"
                  /> */}
                  <img src={RepeatIcon} alt="timer" width={14} height={14} />
                  <div
                    className="custom-task-tooltip"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents click event from bubbling to parent
                    }}
                  >
                    Repeated Task
                  </div>
                </div>
              )}
            </p>
          </h5>
        </div>

        <div className="d-flex align-items-center gap-2">
          <OpenProjectCellRenderer
            showModal={handleTaskDetailsShowModal}
            id={props.params.data.id}
            setEdit={props.setEdit}
            handleOpen={(e) => {
              if (!props.params?.data?.isRead) {
                // Create a copy of the data object and update the isRead property
                const updatedData = { ...props.params.data, isRead: true };
                props.params.node.setData(updatedData); // Refresh the grid cells to reflect the changes
                props.params.api.refreshCells({
                  rowNodes: [props.params.node],
                  force: true,
                  suppressFlash: true,
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const handleEdit = async (api, row) => {
    setEdit(true);

    api.startEditingCell({
      rowIndex: row,
      colKey: "taskName",
    });
    if (!edit) {
      setEdit(true);
      api.startEditingCell({
        rowIndex: row,
        colKey: "taskName",
      });
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: false,
      filter: false,
      flex: 1,
      minWidth: 100,
      width: 1300,
    };
  }, []);
  const onBtPinnedOn = useCallback(() => {
    gridRef?.current?.api?.applyColumnState({
      state: [{ colId: "taskName", pinned: "left" }],
    });
  }, []);
  useEffect(() => {
    gridRef && onBtPinnedOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search param from email redirect
  // Access URL search parameters
  const [searchParams] = useSearchParams(); // React Router v6 hook

  useEffect(() => {
    // Get taskId from the URL query parameters
    const taskId = searchParams.get("taskId");

    // If taskId exists, set it as the selected task and show task details
    if (taskId) {
      setSelectedTask(taskId);
      setShowTaskDetails(true);
    }
  }, [searchParams]); // Re-run if searchParams changes

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rowData]);

  useEffect(() => {
    if (rowData?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [rowData]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  const taskOptionPermission =
    (isPermitted("task/delete") &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    (isPermitted("task/update") &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    isAdmin(userProfile);

  return (
    <div
      className="position-relative project-timeline mb-0"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      <div className="position-relative">{gridApi && taskOptionPermission}</div>
      <div
        className="table-responsive scroll-smooth custom-horizental-scrollbar dashboard-table"
        ref={tableRef}
      >
        <Tooltip id="my-tooltip" />

        <div
          className="ag-theme-alpine "
          style={{
            width: "100%",
            minWidth: "2040px",
          }}
          ref={gridRef}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                rowData?.length === 1 ? "left-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={rowData}
            columnDefs={columnData}
            defaultColDef={defaultColDef}
            getRowId={(params) => {
              return params.data.id;
            }}
            headerHeight={41}
            rowDragManaged={false}
            rowDragEntireRow={false}
            animateRows={true}
            domLayout={"autoHeight"}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            suppressRowHoverHighlight={true}
            popupParent={popupParent}
            onCellEditingStopped={(params) => {
              // Check if editing stopped due to clicking outside of the cell
              setEdit(false); // Set edit to false when editing is stopped
            }}
            onGridReady={gridReady}
            onCellMouseOver={(params) => {
              if (params?.data?.id !== hoveredRow) {
                setShowMore(false);
                setHoveredRow(params?.data?.id);
                // changeTheOptionPosition(params?.data?.id);
                !updateTaskCanvas && setUpdateTaskId(params?.data?.id);
              }
              if (params?.rowIndex !== hoveredMenu) {
                setShowMenu(false);
                setHoveredMenu(params?.rowIndex);
              }
            }}
          ></AgGridReact>
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                rowData?.length === 1 ? "right-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
      {showTaskDetails && (
        <TaskDetailOffCanvas
          show={showTaskDetails}
          handleCloseModal={handleTaskDetailsCloseModal}
          isDark={isDark}
          selectedTask={selectedTask}
          rowData={[]}
          setRowData={() => []}
          taskList={[]}
          setTaskList={() => []}
          getStatusCounts={() => {}}
          // updateTaskData={updateTask}
          listingType={listingType}
          pageParams={null}
          wasUnread={wasUnread}
          setWasUnread={setWasUnread}
        />
      )}
    </div>
  );
}

export default DashboardTableListing;
