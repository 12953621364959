/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import MemberUserListComponent from "./MemberUserListComponent";

const MobileTaskDetailMemberFilter = ({
  currentTab,
  setCurrentTab,
  isClicked,
  setIsClicked,
  focusedIndex,
  setFocusedIndex,
  userListRef,
  inputRef,
  isDark,
  setData,
  data,
  userSearchList,
  search,
  handleSearch,
  clearSearch,
  autoFocus,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  showFilterDropDown,
  getTypeID,
}) => {
  useEffect(() => {
    const adjustDropdownHeight = () => {
      const mainContainer = document.querySelector(
        ".task-user-filter-dropdown"
      );
      const mainDropdown = document.querySelector(".user-list-main-container");

      if (mainContainer && mainDropdown) {
        // Get the bounding rectangle of the dropdown
        const dropdownRect = mainDropdown.getBoundingClientRect();

        // Calculate the available height based on viewport and dropdown's position
        const availableHeight = window.innerHeight - dropdownRect.top - 20; // Adjust 20px for spacing

        // Calculate the maximum height, ensuring it doesn't exceed the available space
        const maxHeight = Math.min(availableHeight, mainDropdown.scrollHeight);

        // Set the max-height on the dropdown
        mainDropdown.style.maxHeight = `${maxHeight}px`;
      }
    };

    // Delay the execution by 2 seconds
    const timeoutId = setTimeout(() => {
      requestAnimationFrame(adjustDropdownHeight);
    }, 1000);

    // Clean-up function
    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts
      const mainDropdown = document.querySelector(".user-list-main-container");
      if (mainDropdown) {
        mainDropdown.style.maxHeight = ""; // Reset the max-height on unmount
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column task-user-filter-dropdown-menu custom-vertical-scrollbar">
      <div className="task-user-filter-dropdown-tab-bar-mobile pb-0">
        <h3 className="mb-3">Member</h3>
        <div className="d-flex align-items-center justify-content-between gap-2 mobile-filter-user-type-button-wrap">
          <button
            className={`w-100 custom-user-type-text-anchor ${
              currentTab === 3 ? "active-user-type-mobile" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentTab(3);
            }}
          >
            Assigned to
          </button>
          <div></div>

          <button
            className={`w-100 custom-user-type-text-anchor ${
              currentTab === 2 ? "active-user-type-mobile" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentTab(2);
            }}
          >
            Task Owner
          </button>
          <div></div>

          <div className="w-100 "></div>
        </div>
      </div>

      <MemberUserListComponent
        isDark={isDark}
        projectUserList={userSearchList}
        setData={setData}
        data={data}
        getTypeID={getTypeID}
        currentTab={currentTab}
        updateRowParameterById={updateRowParameterById}
        kanban={kanban}
        getTaskDataAfterUpdate={getTaskDataAfterUpdate}
        updateTaskData={updateTaskData}
        pageParams={pageParams}
        listingType={listingType}
        setUpdateLoad={setUpdateLoad}
        updateTaskMemberData={updateTaskMemberData}
        updatePhaseTaskMemberData={updatePhaseTaskMemberData}
        isCompletedTaskChanged={isCompletedTaskChanged}
        setIsCompletedTaskChanged={setIsCompletedTaskChanged}
        showFilterDropDown={showFilterDropDown}
        userListRef={userListRef}
        focusedIndex={focusedIndex}
        setFocusedIndex={setFocusedIndex}
        userSearchList={userSearchList}
        search={search}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        autoFocus={autoFocus}
        inputRef={inputRef}
        setIsClicked={setIsClicked}
        isClicked={isClicked}
      />
    </div>
  );
};

export default MobileTaskDetailMemberFilter;
