import React, { useEffect, useRef, useState } from "react";
import "./styles/chatSidebar.css";
import ChatMain from "../pages/Chat/ChatMain";
import socket from "../services/socket";
import notificationSound from "../assets/message-notification-new.mp3";
import { toast } from "react-toastify";
import { onMessageListener, requestForToken } from "../firebase-config";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import saveLocalStorageToCookies from "../helper/saveLocalStorageToCookies";

function ChatSidebarCollapse({
  users,
  isDark,
  setAllUsers,
  allUsers,
  roomData,
  setRoomData,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state?.profile);
  const ulRef = useRef(null);
  const [scrollable, setScrollable] = useState({ top: false, bottom: false });
  const [showChatModal, setShowChatModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [listEnter, setListEnter] = useState(false);
  const [notificationMain, setNotification] = useState({
    title: "",
    body: "",
    company: "",
  });
  const [isMessageNotification, setIsMessageNotification] = useState(false);

  const getQueryParams = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const chats = getQueryParams("chats");

  const navigateByType = (data) => {
    if (!data?.url) return;

    if (data.url.includes("subscriptions")) {
      saveLocalStorageToCookies(`/company-subscriptions`);
    } else {
      navigate(`/${data?.company}${data?.url}`);
    }
  };

  useEffect(() => {
    // Check if the URL ends with ?chat
    if (chats) {
      localStorage.removeItem("rid");
      setShowChatModal(true); // Open the chat modal
    }
  }, [chats]);
  // Notifications;
  // requestForToken();
  onMessageListener()
    .then((payload) => {
      console.log("payload", "chatSideBarCollapse", payload);

      if (
        payload?.data?.companySlug === userProfile?.companySlug &&
        payload?.data?.type !== "new-message"
      ) {
        setNotification({
          title: payload?.data?.title,
          body: payload?.data?.body,
          url: payload?.data?.url,
          company: payload?.data?.companySlug,
        });
      } else if (
        payload?.data?.companySlug === userProfile?.companySlug &&
        payload?.data?.type === "new-message"
      ) {
        if (!showChatModal) {
          setIsMessageNotification(true);
          setNotification({
            title: payload?.data?.title,
            body: payload?.data?.body,
            url: payload?.data?.url,
            company: payload?.data?.companySlug,
          });
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  function Message() {
    return notificationMain?.url ? (
      <div
        onClick={async () => {
          if (isMessageNotification) {
            setIsMessageNotification(false);
            localStorage.removeItem("rid");
            setShowChatModal(true);
          } else {
            navigateByType(notificationMain);
          }
        }}
        className="notifiy-snackbar"
      >
        <div>
          <p className="cloud-notification-text cloud-txt-1">
            <b>{notificationMain?.title}</b>
          </p>
          <p className="cloud-notification-text cloud-txt-2">
            {notificationMain?.body}
          </p>
        </div>
      </div>
    ) : (
      <div
        onClick={() => {
          if (isMessageNotification) {
            setShowChatModal(true);
          } else {
            navigateByType(notificationMain);
          }
        }}
      >
        <div>
          <p className="cloud-notification-text cloud-txt-1">
            <b>{notificationMain?.title}</b>
          </p>
          <p className="cloud-notification-text cloud-txt-2">
            {notificationMain?.body}
          </p>
        </div>
      </div>
    );
  }

  // Define a function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const msg = () => {
    playNotificationSound();
    toast(<Message />, {
      position: "bottom-right", // Position the toast
      type: "info", // Set the toast type
      hideProgressBar: true,
      autoClose: 5000, // 5 seconds in milliseconds
    });
  };

  useEffect(() => {
    if (notificationMain?.title) {
      // getNotificationUnreadCount();
      msg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMain]);

  // const filteredUsers = users?.filter((user) =>
  //   user?.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    if (roomData) {
      setShowChatModal(true);
    }
  }, [roomData]);

  useEffect(() => {
    // Listen for the availability status change event
    socket.on("availabilityStatusChange", (data) => {
      socket.emit("fetchAllUsers", {
        userId: userProfile.userId,
        companyId: userProfile.companyId,
      });
    }); // Cleanup on component unmount
    return () => {
      socket.off("availabilityStatusChange");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const adjustScrollbarHeight = () => {
      const sidebar = document.querySelector(".left-nav-wrap "); // Replace with your sidebar class
      const chatScrollbar = document.querySelector(".custom-chat-scrollbar");

      if (!sidebar || !chatScrollbar) return;

      // Check if the window height is 832px or smaller
      if (window.innerHeight <= 832) {
        const sidebarHeight = sidebar.offsetHeight;
        const scrollbarOffsetTop = chatScrollbar.offsetTop;

        // Calculate the remaining height
        const remainingHeight = sidebarHeight - scrollbarOffsetTop;

        // Apply the height dynamically
        chatScrollbar.style.height = `${remainingHeight - 80}px`;
      } else {
        // Reset the height for larger screens
        chatScrollbar.style.height = "495px"; // Default height
      }
    };

    // Run on initial load
    adjustScrollbarHeight();

    // Attach event listener for window resize
    window.addEventListener("resize", adjustScrollbarHeight);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", adjustScrollbarHeight);
  }, []); // Empty dependency array to run only on mount and unmount

  useEffect(() => {
    if (users) {
      const sorted = users.sort((a, b) => {
        // Prioritize users with 'unseenMessageCount > 0'
        if (a.unseenMessageCount > 0 && b.unseenMessageCount === 0) {
          return -1; // 'a' comes before 'b'
        }
        if (a.unseenMessageCount === 0 && b.unseenMessageCount > 0) {
          return 1; // 'b' comes before 'a'
        }

        // Further prioritize users based on chat status: "online", "away", others
        const statusOrder = ["online", "away"];
        const aStatus = a.user_chat_status?.toLowerCase() || "";
        const bStatus = b.user_chat_status?.toLowerCase() || "";
        const aStatusIndex = statusOrder.indexOf(aStatus);
        const bStatusIndex = statusOrder.indexOf(bStatus);

        if (aStatusIndex !== -1 && bStatusIndex === -1) {
          return -1; // 'a' comes before 'b'
        }
        if (aStatusIndex === -1 && bStatusIndex !== -1) {
          return 1; // 'b' comes before 'a'
        }
        if (aStatusIndex !== -1 && bStatusIndex !== -1) {
          return aStatusIndex - bStatusIndex; // Sort by order in 'statusOrder'
        }

        return 0; // No change in order
      });

      setFilteredUsers(sorted);
    } else if (users) {
      const sorted = [...users].sort((a, b) => {
        // Prioritize users with 'unseenMessageCount > 0'
        if (a.unseenMessageCount > 0 && b.unseenMessageCount === 0) {
          return -1; // 'a' comes before 'b'
        }
        if (a.unseenMessageCount === 0 && b.unseenMessageCount > 0) {
          return 1; // 'b' comes before 'a'
        }

        // Further prioritize users based on chat status: "online", "away", others
        const statusOrder = ["online", "away"];
        const aStatus = a.user_chat_status?.toLowerCase() || "";
        const bStatus = b.user_chat_status?.toLowerCase() || "";
        const aStatusIndex = statusOrder.indexOf(aStatus);
        const bStatusIndex = statusOrder.indexOf(bStatus);

        if (aStatusIndex !== -1 && bStatusIndex === -1) {
          return -1; // 'a' comes before 'b'
        }
        if (aStatusIndex === -1 && bStatusIndex !== -1) {
          return 1; // 'b' comes before 'a'
        }
        if (aStatusIndex !== -1 && bStatusIndex !== -1) {
          return aStatusIndex - bStatusIndex; // Sort by order in 'statusOrder'
        }

        return 0; // No change in order
      });

      setFilteredUsers(sorted);
    } else {
      setFilteredUsers([]);
    }
  }, [users]);

  // Scrollable functionality
  const checkScrollability = () => {
    if (ulRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = ulRef.current;
      setScrollable({
        top: scrollTop > 0,
        bottom: scrollTop + clientHeight < scrollHeight,
      });
    }
  };

  useEffect(() => {
    checkScrollability();
    // Attach scroll event to dynamically update button visibility
    const ulElement = ulRef.current;
    ulElement?.addEventListener("scroll", checkScrollability);
    return () => ulElement?.removeEventListener("scroll", checkScrollability);
  }, [filteredUsers]);

  const scrollTo = (direction) => {
    if (ulRef.current) {
      const scrollAmount = 100; // Adjust the scroll step as needed
      ulRef.current.scrollBy({
        top: direction === "down" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  // Function to handle scrolling
  const handleMouseScroll = (event) => {
    if (listEnter && ulRef.current) {
      ulRef.current.scrollBy({
        top: event.deltaY, // Use `deltaY` to scroll up or down
        behavior: "smooth",
      });
    }
  };

  // Add event listener for wheel when `listenEnter` is true
  useEffect(() => {
    if (listEnter) {
      window.addEventListener("wheel", handleMouseScroll);
    } else {
      window.removeEventListener("wheel", handleMouseScroll);
    }

    // Cleanup listener on component unmount or `listenEnter` change
    return () => {
      window.removeEventListener("wheel", handleMouseScroll);
    };
  }, [listEnter]);

  return (
    <div className="chat-sidebar d-flex flex-column align-items-center justify-content-center ">
      {/* {scrollable.top && ( */}
      <Icon
        className={`${
          scrollable.top
            ? "chat-collapse-list-arrow-icon"
            : "chat-collapse-list-arrow-icon-hide"
        }`}
        icon="solar:alt-arrow-up-bold"
        onClick={() => scrollTo("up")}
        fontSize={20}
      />
      {/* )} */}
      <ul
        className="user-list user-chat-collapse custom-chat-scrollbar"
        ref={ulRef}
        onMouseEnter={() => setListEnter(true)}
        onMouseLeave={() => setListEnter(false)}
      >
        {filteredUsers?.map((user) => (
          <li
            key={user.user_id}
            className="chat-user-wrap d-flex align-items-center justify-content-center"
            onClick={() => {
              localStorage.removeItem("rid");
              setRoomData(user);
              // setShowChatModal(true);
            }}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="button-tooltip">{user?.user_name}</Tooltip>}
            >
              <div className="position-relative">
                {user?.user_profile_image ? (
                  <img
                    src={
                      user.user_profile_image ||
                      "https://via.placeholder.com/40"
                    }
                    alt={user.user_name}
                    className="user-img m-0"
                  />
                ) : (
                  <div
                    className={"user-list-card-user-image-name m-0"}
                    style={{ width: "35px", height: "35px" }}
                  >
                    {user?.user_name[0]?.toUpperCase()}
                    {user?.user_name?.split(" ")[1]
                      ? user?.user_name?.split(" ")[1][0]?.toUpperCase()
                      : user?.user_name[1]?.toUpperCase()}
                  </div>
                )}
                {!user?.unseenMessageCount || user?.unseenMessageCount <= 0 ? (
                  <span
                    className={`${
                      user?.user_chat_status === "online"
                        ? "online-status"
                        : user?.user_chat_status === "away"
                        ? "away-status"
                        : "user-offline"
                    }`}
                  ></span>
                ) : (
                  <span className="unseen-message-count ps-0">
                    {user?.unseenMessageCount}
                  </span>
                )}
              </div>
            </OverlayTrigger>
          </li>
        ))}
      </ul>
      {scrollable.bottom && (
        <Icon
          className="chat-collapse-list-arrow-icon"
          icon="solar:alt-arrow-down-bold"
          onClick={() => scrollTo("down")}
          fontSize={20}
        />
      )}
      {showChatModal && (
        <ChatMain
          isDark={isDark}
          show={showChatModal}
          handleCloseModal={async () => {
            setShowChatModal(false);
          }}
          roomData={roomData}
          setRoomData={setRoomData}
          setAllUsers={setAllUsers}
          allUsers={allUsers}
          initialLoad={initialLoad}
          setInitialLoad={setInitialLoad}
        />
      )}
    </div>
  );
}

export default ChatSidebarCollapse;
