import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";

function UsersListSearch({ setSearchData, chats }) {
  const [username, setUserName] = useState("");

  const updateSearchedData = () => {
    if (username.length > 0) {
      let filteredData = chats?.filter((item) => {
        return item?.user_name?.toLowerCase().includes(username.toLowerCase());
      });
      setSearchData(filteredData);
    } else {
      setSearchData(chats);
    }
  };

  useEffect(() => {
    updateSearchedData(username);
  }, [username]);

  return (
    <div className="users-list-search-main">
      {/* input */}
      <div className="users-list-search-input-wrapper">
        <input
          value={username}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          className="users-list-search-input"
          type="text"
          placeholder="Find Employee or Chat"
          autoComplete="off"
        />
        <Icon className="user-list-search-icon" width={18} height={18} icon={"majesticons:search-line"} />
      </div>
    </div>
  );
}

export default UsersListSearch;
