import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { toast } from "react-toastify";
import { useDeleteTaskDataMutation } from "../../../slices/tasks/tasksSlice";
import { useDeleteCompletedTaskDataMutation } from "../../../slices/tasks/completedTasks.Slice";

const ConfirmDeleteTaskModal = ({
  showDeleteModal = false,
  handleCloseDeleteModal = () => {},
  listingType = "TASKS",
  taskId = null,
  getAllTasksByPhaseId = () => {},
  removeTask = () => {},
  getTaskName = () => "",
  isDark = false,
  getTaskList = () => {},
}) => {
  const [dload, setDLoad] = useState(false);
  const [deleteTaskData] = useDeleteTaskDataMutation(); // Use mutation hook
  const [deleteCompletedTaskData] = useDeleteCompletedTaskDataMutation();

  // Delete task
  const deleteTask = async () => {
    try {
      setDLoad(true);
      if (listingType === "COMPLETED_TASKS") {
        await deleteCompletedTaskData([taskId]).unwrap();
      } else {
        await deleteTaskData([taskId]).unwrap(); // Use the mutation and unwrap the response
      }
      // After successful deletion, close the modal and update the state
      handleCloseDeleteModal();
      await getTaskList();
      await getAllTasksByPhaseId();
      toast.success("Task deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });

      // Specific for kanban
      if (removeTask) {
        removeTask();
      }
    } catch (error) {
      toast.error("Failed to delete task. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>
        <p className="del-modal-name">{getTaskName()}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteTask}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteTaskModal;
