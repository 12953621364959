import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ProgressIcon from "../../../assets/in-progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import { dateFormatter } from "../../../helper/dateFormatter";
import { Link, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import DropButton from "../../Team/components/DropButton";
import Delete from "../../../assets/delete.svg";
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from "react-select";
import TasksListingMembersEditor from "../../Tasks/components/TasksListingMembersEditor";
import { ListofTeamMemberCellRenderer } from "../../Tasks/components/utils/data";
import { Icon } from "@iconify/react";
import ProgressDropdownMain from "./ProgressDropdownMain";
import ConfirmDeleteProjectModal from "./ConfirmDeleteProjectModal";
import ConfirmDueDateModal from "./ConfirmDueDateModal";
import EditIcon from "../../../assets/edit-project.svg";
import { toast } from "react-toastify";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import ProjectEditModal from "./ProjectEditModal";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInProject } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import SortableHeaderComponent from "../../../components/SortableHeaderComponent/SortableHeaderComponent";
import {
  usePostUpdateProjectMembersMutation,
  usePostUpdateProjectMutation,
} from "../../../slices/projects/projectSlice";
import deleteIcon from "../../../assets/circle-delete.png";
import { ProgressBar } from "react-bootstrap";
import ConfirmSureDeleteProjectModal from "./ConfirmSureDeleteProjectModal";
import CircularProgressTasks from "./CircularProgressTasks";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { useIsMobile } from "../../../helper/windowHelper";
import "../../ProjectDetail/styles/projectDetail.scss";
const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const ProjectListing = (props) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateTaskId, setUpdateTaskId] = useState(null);
  const [showDueDateModal, setShowDueDateModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [updateLoad, setUpdateLoad] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });
  const [checked, setChecked] = useState(false);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);

  const optionsDropdownRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const { modalContents } = useSelector((state) => state?.modalData);
  const projectsDeleteModalContent = findObjectByContentTitle(
    "projects-delete-model-v3",
    modalContents?.data
  );

  const handleCloseDueDateModal = () => {
    setShowDueDateModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onRowClicked = (event) => {
    const index = props.selectedProjects.indexOf(event?.data?.projectId);
    if (index === -1) {
      // Project ID not found, add it to the array
      props.setSelectedProjects([
        ...props.selectedProjects,
        event?.data?.projectId,
      ]);
    } else {
      // Project ID found, remove it from the array
      const updatedProjects = [...props.selectedProjects];
      updatedProjects.splice(index, 1);
      props.setSelectedProjects(updatedProjects);
    }
  };

  // Get project name by id
  const getProjectName = (id) => {
    const project = props.listToFilter.find(
      (project) => project.projectId === id
    );
    return project ? project.projectName : null;
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("-");
    return new Date(`${month}/${year}/${day}`);
  };

  const OpenProjectCellRenderer = ({ pid, pname }) => {
    return (
      <Link
        to={
          "/" +
          localStorage.getItem("company") +
          "/project?pid=" +
          pid +
          "&pname=" +
          pname
        }
        className="open-target-link"
      >
        <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
      </Link>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [taskProgressOptionsList] = useState(() => {
    const baseOptions = [
      "Not Started",
      "In Progress",
      "On Hold",
      "Completed",
      "Submit For Review",
      "Deleted",
    ];

    // Conditionally add "Deleted" if listingType is COMPLETED_TASKS
    return props.listingType === "COMPLETED_PROJECTS"
      ? [...baseOptions, "Deleted"]
      : baseOptions;
  });

  const CustomTasksProgressEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskProgressOptionsList?.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "deleted"
                        ? deleteIcon
                        : res?.toLowerCase() === "in progress"
                        ? ProgressIcon
                        : res?.toLowerCase() === "not started"
                        ? NotStarted
                        : res?.toLowerCase() === "completed"
                        ? doneImage
                        : res?.toLowerCase() === "on hold"
                        ? reviewImage
                        : res?.toLowerCase() === "submit for review" &&
                          reviewIcon
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state?.data?.value?.toString() === "critical"
          ? "#FB3F3F"
          : state?.data?.value?.toString().toLowerCase() === "highest"
          ? "#56BAEC"
          : state?.data?.value?.toString().toLowerCase() === "medium"
          ? "#394B84"
          : state?.data?.value?.toString().toLowerCase() === "low"
          ? "#2C2D2F"
          : state?.data?.value?.toString().toLowerCase() === "in progress"
          ? "#2098D1"
          : state?.data?.value?.toString().toLowerCase() === "not started"
          ? "#fd1b1b"
          : state?.data?.value?.toString().toLowerCase() === "completed"
          ? "#2DBF64"
          : state?.data?.value?.toString().toLowerCase() === "on hold"
          ? "#E8910D"
          : state?.data?.value?.toString().toLowerCase() === "submit for review"
          ? "#EBA900"
          : state.data.value.toString().toLowerCase() === "deleted"
          ? "#ff0000"
          : " black",
      fontSize: "14px",
      fontWeight: 300,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
  };

  const TasksSelectProgressEditor = (params) => {
    const projectStatusColumn = params.columnApi
      .getColumnState()
      .find((col) => col.colId === "projectStatus");

    const [selected, setSelected] = useState(
      params?.data?.projectStatus
        ? params?.data?.projectStatus.name
        : params.data.status
    );

    // Filter out the selected status from the options list
    const filteredOptions = props.projectStatusList?.filter(
      (res) => res?.statusName?.toLowerCase() !== selected?.toLowerCase()
    );

    // Prevent AG Grid from intercepting Enter key
    const stopEditingOnEnter = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Stop AG Grid from handling Enter
        event.stopPropagation();
        // Find the currently focused option in react-select
        const focusedOption = document.querySelector(
          ".select__menu-list .select__option--is-focused"
        );

        if (focusedOption) {
          focusedOption.click(); // Simulate a click on the focused option
        }
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", stopEditingOnEnter, true);
      return () =>
        document.removeEventListener("keydown", stopEditingOnEnter, true);
    }, []);

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", minWidth: `${projectStatusColumn.width}px` }}
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          className={`basic-single project-tasks-select-status-editor task-listing-situation-filter-top-wrap-select-div select-item-status task-${selected
            ?.toLowerCase()
            .split(" ")
            .join("-")}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          autoFocus={true}
          menuPlacement="auto"
          name="color"
          options={filteredOptions?.map((res, index) => ({
            label: (
              <div className="d-flex align-items-center gap-1" key={index}>
                {res?.statusName?.toLowerCase() === "not started" && (
                  <img
                    src={NotStarted}
                    alt="Not Started"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "in progress" && (
                  <img
                    src={ProgressIcon}
                    alt="In Progress"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "on hold" && (
                  <img src={reviewImage} alt="On Hold" width={20} height={20} />
                )}
                {res?.statusName?.toLowerCase() === "submit for review" && (
                  <img
                    src={reviewIcon}
                    alt="Submit For Review"
                    width={20}
                    height={20}
                  />
                )}
                {res?.statusName?.toLowerCase() === "completed" && (
                  <img src={doneImage} alt="Completed" width={20} height={20} />
                )}
                {res?.statusName?.toLowerCase() === "deleted" && (
                  <img src={deleteIcon} alt="Deleted" width={20} height={20} />
                )}
                {res?.statusName}
              </div>
            ),
            value: res,
          }))}
          components={{ Control: CustomTasksProgressEditorControl }}
          onChange={async (e) => {
            let endDate = new Date(parseDate(params.data.projectEndDate));
            let currentDate = new Date();
            // Create new Date objects from the year, month, and day components
            currentDate = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            );
            endDate = new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate()
            );
            const currentStatus =
              params?.data?.projectStatus?.statusName?.toLowerCase() ||
              params?.data?.projectStatus?.name?.toLowerCase();
            const newStatus = e.value.statusName?.toLowerCase();
            if (currentStatus !== "completed" && newStatus === "completed") {
              props.getStatusCounts("INCREMENT_COMPLETED");
              if (endDate < currentDate) {
                props.getStatusCounts("DECREMENT_OVERDUE");
              }
            } else if (
              currentStatus === "completed" &&
              newStatus !== "completed"
            ) {
              props.getStatusCounts("DECREMENT_COMPLETED");
              if (endDate < currentDate) {
                props.getStatusCounts("INCREMENT_OVERDUE");
              }
            }
            params.data.projectStatus = {
              ...e.value,
              name: e.value.statusName,
            };
            setSelected(e.value.statusName);
            params.api.stopEditing();
            const fieldStartData = {
              projectId: params?.data?.projectId,
              fieldName: "projectStatus",
              fieldValues: e.value.id,
            };
            await updateProject(fieldStartData);
          }}
        />
      </div>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  // Task Renderer
  const TaskDropdownRenderer = (props) => {
    return (
      <div className="task-drop-renderer">
        <div className="mb-2 mt-2 task-timeline-progress review-task-timeline-progress">
          {" "}
          <img src={reviewImage} alt="Submit For Review" /> Total Tasks (
          {props?.totalTasks}){" "}
        </div>
        <div className="mb-2 task-timeline-progress done-task-timeline-progress">
          <img src={doneImage} alt="Done" /> Completed Tasks (
          {props?.completedTasks})
        </div>
        <div className="mb-2 task-timeline-progress delete-task-timeline-progress">
          {" "}
          <img src={NotStarted} alt="Submit For Review" /> Overdue Tasks (
          {props?.overDueTasks}){" "}
        </div>
        <div className="mb-2 task-timeline-progress progress-task-timeline-progress">
          {" "}
          <img src={ProgressIcon} alt="Progress" className="me-1" />
          In Progress Tasks ({props?.pendingTasks}){" "}
        </div>
      </div>
    );
  };

  const handleEdit = async (api, row) => {
    if (isPermitted("project/pm-update-project")) {
      setEdit(true);
      api.startEditingCell({
        rowIndex: row,
        colKey: "project",
      });
    }
  };

  const [postUpdateProject, { isLoading: isUpdating, isSuccess }] =
    usePostUpdateProjectMutation();
  const [postUpdateProjectMembers, { isLoading: isMemberLoading }] =
    usePostUpdateProjectMembersMutation();

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Project updated successfully.", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 3000,
  //     });
  //   }
  // }, [isSuccess]);

  // Update name
  const updateProject = async (data) => {
    try {
      // const response = await ProjectAPI.post(`project/pm-update-project`, data);
      const response = await postUpdateProject({
        ...data,
        ...props.pageParams,
        isCompletedProjectListing: props.isCompletedProjectListing,
      });
      if (response) {
        editingCellsRef.current.clear();
        const { id, ...rest } = response?.data?.project;
        const updatedProjectsDetail = {
          projectId: id,
          ...rest,
        };
        // toast.success("Project updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
        props.updateProjectData(updatedProjectsDetail);
      }
    } catch (error) {
      toast.error(error, { position: toast.POSITION.TOP_CENTER });
    }
  };

  // Custom Toast Component
  useEffect(() => {
    if (isUpdating || isMemberLoading) {
      // Show the updating toast when isUpdating becomes true
      setUpdateLoad(true);
    } else {
      // Dismiss the toast when the update completes
      setUpdateLoad(false);
    }
  }, [isUpdating, isMemberLoading]);

  useEffect(() => {
    if (updateLoad) {
      setIsToastVisible(true);
    } else {
      // Dismiss the toast when the update completes
    }
  }, [updateLoad]);

  // Function to check if the task can be selected
  const canBeSelected = (project) => {
    return (
      (isOwnerInProject(
        props.projectList,
        project.projectId,
        userProfile?.userId
      ) ||
        isAdmin(userProfile)) &&
      isPermitted("project/pm-project-delete")
    );
  };

  const handleHeaderCheckboxChange = () => {
    const newSelectedProjects = [];
    if (!checked) {
      // Iterate through each task in the array
      props.listToFilter.forEach((project) => {
        // Check if the task can be selected
        if (canBeSelected(project)) {
          // If yes, add its ID to the selectable tasks array
          const index = props.selectedProjects.indexOf(project.projectId);
          if (index === -1) {
            newSelectedProjects.push(project.projectId);
          }
        }
      });
      // Merge newSelectedTasks with existing selectedTasks
      props.setSelectedProjects((prevSelectedProjects) => [
        ...prevSelectedProjects,
        ...newSelectedProjects,
      ]);
    } else {
      // Clear selected tasks
      props.setSelectedProjects([]);
    }
    // Toggle the checked state
    setChecked((prevChecked) => !prevChecked);
  };

  // Custom header component for checkboxes
  const SelectAllHeaderComponent = (props) => {
    return (
      <label className="control-task control-task-checkbox control-select-checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleHeaderCheckboxChange}
        />
        <div className="control_task_indicator control-select-indicator"></div>
      </label>
    );
  };

  const editingCellsRef = useRef(new Set());

  const columns = [
    {
      field: "select", // Field name for selection
      headerName: "",
      maxWidth: 33, // Adjust width as needed
      editable: false,
      headerComponent: SelectAllHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <label className="control control--checkbox control-select-checkbox">
            <input
              type="checkbox"
              checked={props.selectedProjects.includes(params.data.projectId)} // Optional: for pre-selection
              onChange={() => onRowClicked(params)}
              disabled={
                !(
                  (isOwnerInProject(
                    props.projectList,
                    params.data.projectId,
                    userProfile?.userId
                  ) ||
                    isAdmin(userProfile)) &&
                  isPermitted("project/pm-project-delete")
                )
              }
            />
            <div className="control__indicator control-select-indicator control-indicator-right"></div>
          </label>
        );
      },
    },
    {
      field: "projectName",
      headerName: "Project Name",
      minWidth: window.innerWidth > 1600 ? 480 : 380,
      editable: false,
      // checkboxSelection: true,
      // headerComponent: (params) =>
      //   SortableHeaderComponent({
      //     ...params,
      //     updateOrder: props.updateOrder, // Pass updateOrder from props
      //     order: props.order,
      //     sortOrder: props.sortOrder,
      //     setSortOrder: props.setSortOrder,
      //     list: "PROJECT",
      //   }),
      onCellValueChanged: async (e, params) => {
        const newValue = e?.newValue;
        if (newValue) {
          const fieldData = {
            projectId: e?.data?.projectId,
            fieldName: "projectName",
            fieldValues: newValue,
          };
          await updateProject(fieldData);
        } else {
          e.data.projectName = e?.oldValue;
        }
      },
      cellClass: (params) => {
        let classes = "custom-task-name-renderer";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += " selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      onCellClicked: (params) => {
        if (params.event.target.closest(".tasks-listing-pin-icon")) {
          return; // Prevent execution if the clicked element is the pinned icon
        }
        navigate(
          "/" +
            localStorage.getItem("company") +
            "/project?pid=" +
            params.data.projectId +
            "&pname=" +
            params.data.projectName
        );
      },
      cellRenderer: (params) => {
        return (
          <div
            className="w-100 d-flex align-items-center justify-content-between"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                "/" +
                  localStorage.getItem("company") +
                  "/project?pid=" +
                  params.data.projectId +
                  "&pname=" +
                  params.data.projectName
              );
            }}
          >
            <div className="project-name-progress-section">
              <ProgressDropdownMain
                isDark={props?.isDark}
                data={params.data}
                dropdownRenderer={TaskDropdownRenderer}
                api={params.api}
                handleEdit={handleEdit}
                row={params.rowIndex}
                setEdit={setEdit}
                canEdit={
                  isOwnerInProject(
                    props.projectList,
                    hoveredRow,
                    userProfile?.userId
                  ) || isAdmin(userProfile)
                }
              />
            </div>
            <div className="d-flex align-items-center gap-2">
              {hoveredRow === params.data.projectId &&
                !params.data.projectIsPinned && (
                  <Icon
                    className="tasks-listing-pin-icon"
                    icon="icon-park-solid:pin"
                    onClick={async (e) => {
                      // e.preventDefault();
                      e.stopPropagation();
                      params.data.projectIsPinned = true;
                      // Refresh the grid to reflect the changes
                      params.api.refreshCells({
                        rowNodes: [params.node],
                        force: true,
                        suppressFlash: true,
                      });
                      const fieldEndData = {
                        projectId: params?.data?.projectId,
                        fieldName: "isPined",
                        fieldValues: true,
                      };
                      // Refresh the entire grid
                      props.setFilters({
                        ...props.filters,
                        pinnedState: !props.filters.pinnedState,
                      });
                      await updateProject(fieldEndData);
                    }}
                  />
                )}
              {params.data.projectIsPinned && (
                <Icon
                  className="tasks-listing-pin-icon pined-icon"
                  icon="icon-park-solid:pin"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    params.data.projectIsPinned = false;
                    // Refresh the grid to reflect the changes
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      force: true,
                      suppressFlash: true,
                    });
                    const fieldEndData = {
                      projectId: params?.data?.projectId,
                      fieldName: "isPined",
                      fieldValues: false,
                    };
                    // Refresh the entire grid
                    props.setFilters({
                      ...props.filters,
                      pinnedState: !props.filters.pinnedState,
                    });
                    await updateProject(fieldEndData);
                  }}
                />
              )}
              <OpenProjectCellRenderer
                pid={params.data.projectId}
                pname={params.data.projectName}
              />
            </div>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Timeline",
      minWidth: 200,
      // maxWidth: 200,
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,
      cellRenderer: (params) => {
        return (
          <div className="d-flex">
            {/* {renderTimeline(timeline)
              ? renderTimeline(timeline)
              : "No timeline selected"} */}
            <CircularProgressTasks
              isDark={props.isDark}
              total={params?.data?.totalTasks || 0}
              completed={params?.data?.completedTasks || 0}
            />
          </div>
        );
      },

      onCellValueChanged: (params) => {
        const newValue = params.newValue.timeline;
        if (newValue?.start_time && newValue?.end_time) {
          params.data.timeline = newValue;
          // Refresh the grid to reflect the changes
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
            suppressFlash: true,
          });
        }
      },
    },
    {
      field: "users",
      headerName: "Members",
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      // maxWidth: window.innerWidth > 1600 ? 250 : 250,
      minWidth: 120,
      // maxWidth: 120,
      editable:
        !isMobile &&
        ((isPermitted("project/pm-update-project") &&
          isOwnerInProject(
            props.projectList,
            hoveredRow,
            userProfile?.userId
          )) ||
          isAdmin(userProfile)),
      cellEditorParams: {
        popupParent: document.querySelector("body"),
      },
      cellEditorSelector: (params) => {
        const gridElement = document.querySelector(".dashboard-main-wraper"); // Replace with your grid's root element if different
        const cellRect = params.eGridCell.getBoundingClientRect();
        const gridRect = gridElement.getBoundingClientRect();
        const spaceBelow = gridRect.bottom - cellRect.bottom;

        return {
          component: () => {
            return (
              <TasksListingMembersEditor
                params={params}
                isDark={props.isDark}
                userList={props.projectUserList}
                projectTypes={props.projectTypes}
                isOwner={isOwnerInProject(
                  props.projectList,
                  hoveredRow,
                  userProfile?.userId
                )}
                pageParams={props.pageParams}
                isCompletedProjectListing={props.isCompletedProjectListing}
                postUpdateProjectMembers={postUpdateProjectMembers}
                getProjectList={props.getProjectList()}
              />
            );
          },
          popup: true,
          popupPosition: spaceBelow > 314 ? "under" : "over",
        };
      },
      cellRenderer: ListofTeamMemberCellRenderer,
      onCellValueChanged: (params) => {
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
          suppressFlash: true,
        });
      },
    },
    {
      field: "projectStatus",
      headerName: "Status",
      cellClass: (params) => {
        let classes = "";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable:
        (!isMobile &&
          isPermitted("project/pm-update-project") &&
          isOwnerInProject(
            props.projectList,
            hoveredRow,
            userProfile?.userId
          )) ||
        isAdmin(userProfile),
      cellEditor: "agSelectCellEditor",
      //   cellRenderer: statusRender,
      // maxWidth: 170,
      minWidth: 170,
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProgressEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: (params) => {
        const { projectStatus } = params.data;
        if (projectStatus?.name?.toLowerCase() === "in progress") {
          return (
            <div className="task-timeline-progress progress-task-timeline-progress">
              {" "}
              <img src={ProgressIcon} alt="Progress" /> {projectStatus?.name}{" "}
            </div>
          );
        } else if (projectStatus?.name?.toLowerCase() === "completed") {
          return (
            <div className="task-timeline-progress done-task-timeline-progress">
              {" "}
              <img src={doneImage} alt="Done" /> {projectStatus?.name}{" "}
            </div>
          );
        } else if (projectStatus?.name?.toLowerCase() === "on hold") {
          return (
            <div className="task-timeline-progress review-task-timeline-progress">
              {" "}
              <img src={reviewImage} alt="Submit For Review" />{" "}
              {projectStatus?.name}{" "}
            </div>
          );
        } else if (projectStatus?.name?.toLowerCase() === "submit for review") {
          return (
            <div className="task-timeline-progress review-icon-timeline-progress">
              {" "}
              <img src={reviewIcon} alt="Submit For Review" />{" "}
              {projectStatus?.name}{" "}
            </div>
          );
        } else if (projectStatus?.name?.toLowerCase() === "not started") {
          return (
            <div className="task-timeline-progress critical-task-timeline-progress">
              {" "}
              <img src={NotStarted} alt="Submit For Review" />{" "}
              {projectStatus?.name}{" "}
            </div>
          );
        } else if (projectStatus?.name?.toLowerCase() === "deleted") {
          return (
            <div className="task-timeline-progress delete-task-timeline-progress">
              <img src={deleteIcon} alt="Submit For Review" />{" "}
              {projectStatus?.name}
            </div>
          );
        } else
          return (
            <div className="task-timeline-progress progress-task-timeline-progress">
              {" "}
              <img src={ProgressIcon} alt="Progress" /> {projectStatus?.name}{" "}
            </div>
          );
      },
    },
    {
      field: "projectUpdatedDate",
      headerName: "Updated Date",
      // headerComponent: SortableHeaderComponent,
      cellClass: (params) => {
        let classes = "";
        if (
          hoveredRow === params.data.projectId ||
          props.selectedProjects.includes(params.data.projectId)
        ) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.projectId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: false,
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.projectUpdatedDate, 3)}
            </p>
          </div>
        );
      },
    },
  ];

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const gridRef = useRef(null);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Get the bounding box of the grid and options dropdown
      const gridRect = gridRef?.current?.getBoundingClientRect();
      const optionsDropdownRect =
        optionsDropdownRef?.current?.getBoundingClientRect();

      //Check if the mouse has left the grid area
      if (
        event.clientX > gridRect.right ||
        event.clientY < gridRect.top ||
        event.clientY > gridRect.bottom + 30
      ) {
        if (!showMore) {
          setHoveredMenu(null);
          setHoveredRow(null);
          setShowMore(false);
        }
        //Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

        if (
          showMenu === false ||
          (optionsDropdownRect &&
            (event.clientX < optionsDropdownRect.left ||
              event.clientX > optionsDropdownRect.right ||
              event.clientY < optionsDropdownRect.top ||
              event.clientY > optionsDropdownRect.bottom))
        ) {
          setHoveredMenu(null);
          setShowMenu(false);
        }
      }
    };

    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null) {
      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top - 12,
            left: optionsColumnPosition.left,
            // zIndex: 9999,
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}
          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div data-theme={props?.isDark} className="position-relative mt-2">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
            {showMenu && (
              <Portal>
                <div
                  ref={optionsDropdownRef}
                  data-theme={props?.isDark}
                  style={{
                    position: "absolute",
                    left: `${xValue - 20}px`,
                    top: `${yValue}px`,
                    listStyle: "none",

                    border: "1px solid var(--divider-color)",
                    fontSize: "14px",
                    fontWeight: 300,
                    minWidth: "150px",
                  }}
                  onMouseOver={(e) => setShowMenu(true)}
                  // onMouseLeave={() => setShowMore(false)}
                  className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start rounded-2 "
                >
                  {((isPermitted("project/pm-update-project") &&
                    isOwnerInProject(
                      props.projectList,
                      hoveredRow,
                      userProfile?.userId
                    )) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setUpdateTaskId(hoveredRow);
                        setUpdateTaskCanvas(true);
                      }}
                    >
                      <img
                        src={EditIcon}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="edit-task-icon"
                      />
                      Edit Project
                    </div>
                  )}
                  {((isPermitted("project/pm-project-delete") &&
                    isOwnerInProject(
                      props.projectList,
                      hoveredRow,
                      userProfile?.userId
                    )) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="delete-task-icon"
                      />
                      Delete Project
                    </div>
                  )}
                </div>
              </Portal>
            )}
          </div>
        </div>
      );
    }
    return null;
  };
  const changeTheOptionPosition = async (r, i) => {
    const hoveredRowElement = document?.querySelector(`.ag-row[row-id="${r}"]`);
    if (hoveredRowElement) {
      setOptionsColumnPosition({
        top: 66 + i * 55, // Adjust top dynamically,
        left: -20, // Adjust the left position as needed
      });
    }
  };

  // Grid associated ref
  const apiRef = useRef(null); // Create a ref to hold the Ag-Grid API reference

  const gridReady = (params) => {
    apiRef.current = params.api;
    setGridApi(params.api);
  };

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.projectList]);

  useEffect(() => {
    if (props.projectList?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  const partialPermission =
    (isPermitted("project/pm-project-delete") &&
      isOwnerInProject(props.projectList, hoveredRow, userProfile?.userId)) ||
    (isPermitted("project/pm-update-project") &&
      isOwnerInProject(props.projectList, hoveredRow, userProfile?.userId)) ||
    isAdmin(userProfile);

  // Set all checked status based on selected projects
  useEffect(() => {
    if (props.selectedProjects?.length === 0) {
      setChecked(false);
    }
    if (props.selectedProjects?.length === props.projectList?.length) {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedProjects]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!gridRef.current?.contains(event.target)) {
        editingCellsRef.current.clear(); // Clear editing state
        // gridRef.current?.api.stopEditing();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="position-relative project-timeline mt-3"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      {isToastVisible && <div className="update-load-case"></div>}
      <CustomToast
        isVisible={isToastVisible}
        onClose={() => setIsToastVisible(false)}
        updateLoad={updateLoad}
      />
      <div className="position-relative">
        {gridApi && partialPermission && renderCustomOptionsColumn()}
      </div>
      <div
        className="table-responsive ms-4 scroll-smooth custom-horizental-scrollbar"
        ref={tableRef}
      >
        <div
          className="ag-theme-alpine project-main-grid"
          style={{
            width: "100%",
            minWidth: "1200px",
            // maxWidth: "1200px"
            // position: "relative"
          }}
          ref={gridRef}
          data-theme={props.isDark}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                props.projectList?.length === 1
                  ? "left-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={props.projectList}
            defaultColDef={defaultColDef}
            domLayout={"autoHeight"}
            columnDefs={columns}
            singleClickEdit={true}
            rowHeight={55}
            animateRows={true}
            // getRowStyle={getRowStyle}
            getRowId={(params) => {
              return params.data.projectId;
            }}
            rowDragManaged={false}
            rowDragEntireRow={false}
            popupParent={popupParent}
            onGridReady={gridReady}
            suppressRowHoverHighlight={true}
            onCellEditingStarted={(params) => {
              const cellKey = `${params.node.id}-${params.column.colId}`;
              // If the same cell is already editing, stop it and remove from ref
              if (editingCellsRef.current.has(cellKey)) {
                params.api.stopEditing(); // 🚫 Stop editing
                editingCellsRef.current.delete(cellKey); // ❌ Remove from ref
                return; // Exit early
              }
              // If another cell is being edited, remove it first
              if (editingCellsRef.current.size > 0) {
                const [existingKey] = editingCellsRef.current;

                editingCellsRef.current.delete(existingKey); // ❌ Remove previous key
              }
              // Add the new cellKey to ref
              editingCellsRef.current.add(cellKey);
            }}
            onCellEditingStopped={(params) => {
              // Check if editing stopped due to clicking outside of the cell
              setEdit(false); // Set edit to false when editing is stopped
            }}
            stopEditingWhenCellsLoseFocus={true}
            onCellMouseOver={(params) => {
              if (params.data.projectId !== hoveredRow) {
                setShowMore(false);
                setHoveredRow(params?.data?.projectId);
                setDeleteProjectId(params?.data?.projectId);
                !updateTaskCanvas && setUpdateTaskId(params?.data?.projectId);
              }
              if (params?.rowIndex !== hoveredMenu) {
                setShowMenu(false);
                setHoveredMenu(params?.rowIndex);
                changeTheOptionPosition(
                  params?.data?.projectId,
                  params?.rowIndex
                );
              }
            }}
          />
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                props.projectList?.length === 1
                  ? "right-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmDeleteProjectModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDark={props.isDark}
          projectId={deleteProjectId}
          getProjectList={props.getProjectList}
          listToFilter={props.listToFilter}
          setListToFilter={props.setListToFilter}
          getProjectName={getProjectName}
          type="project"
          modalContent={projectsDeleteModalContent}
          handleConfirmClose={() => setShowConfirmDelete(true)}
        />
      )}
      {showConfirmDelete && (
        <ConfirmSureDeleteProjectModal
          showDeleteModal={showConfirmDelete}
          handleCloseDeleteModal={() => setShowConfirmDelete(false)}
          isDark={props.isDark}
          projectId={deleteProjectId}
          getProjectList={props.getProjectList}
          listToFilter={props.listToFilter}
          setListToFilter={props.setListToFilter}
          getProjectName={getProjectName}
          type="project"
          modalContent={projectsDeleteModalContent}
        />
      )}
      {showDueDateModal && (
        <ConfirmDueDateModal
          showDueDateModal={showDueDateModal}
          handleCloseDueDateModal={handleCloseDueDateModal}
          isDark={props.isDark}
        />
      )}
      {updateTaskCanvas && (
        <ProjectEditModal
          id={updateTaskId}
          isDark={props.isDark}
          show={updateTaskCanvas}
          listToFilter={props.listToFilter}
          setListToFilter={props.setListToFilter}
          handleCloseModal={() => {
            setUpdateTaskId(null);
            setUpdateTaskCanvas(false);
          }}
          getProjectList={props.getProjectList}
          pageParams={props.pageParams}
          isCompletedProjectListing={props.isCompletedProjectListing}
        />
      )}
    </div>
  );
};

export default ProjectListing;
