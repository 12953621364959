import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionData } from "../../../actions/paymentActions";

const useCompanyMembers = (user) => {
  const dispatch = useDispatch();
  const { subscriptionDetail } = useSelector((state) => state.subscription);
  const { billingDetail } = useSelector((state) => state.billing);

  const [showModal, setShowModal] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [planData, setPlanData] = useState(null);

  const handleCloseModal = () => setShowModal(false);
  const handleAddonClose = () => setAddonModal(false);
  const handleAddonShow = () => setAddonModal(true);

  const getPlanData = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        await dispatch(subscriptionData());
      } catch (error) {
        console.error("Failed to fetch subscription data", error);
      }
    }
  };

  useEffect(() => {
    if (!subscriptionDetail && user && user?.canInvite) {
      getPlanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (subscriptionDetail) {
      setPlanData(subscriptionDetail?.packageDetails);
    }
  }, [subscriptionDetail]);

  return {
    showModal,
    setShowModal,
    addonModal,
    setAddonModal,
    showAddModal,
    setShowAddModal,
    planData,
    billingDetail,
    handleCloseModal,
    handleAddonClose,
    handleAddonShow,
    getPlanData,
    isAdmin: user?.canInvite === 1 && user?.roles?.some((role) => role.roleType === "admin"),
  };
};

export default useCompanyMembers;
