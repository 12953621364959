import React, { useEffect, useState } from "react";
import InfoIcon from "../../../assets/info.svg";
import { Icon } from "@iconify/react";
import { Tooltip } from "react-tooltip";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ProjectAPI } from "../../../services/ClientApi";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useDispatch, useSelector } from "react-redux";
import { isPermitted } from "../../../helper/isPermitted";
import { timelineStatsAction } from "../../../actions/timelineActions";

const TimelineGrids = ({ memberId = null, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State to manage dropdown visibility for each list item
  const [showDropdownIndex, setShowDropdownIndex] = useState(null);
  const { userProfile } = useSelector((state) => state?.profile);

  // Function to toggle the selected property of an item in type_count
  const toggleSelected = async (parentId, childId, status) => {
    props.setTimelineGrid((prevState) =>
      prevState.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            type_count: item.type_count.map((subItem) => {
              if (subItem.id === childId) {
                return {
                  ...subItem,
                  selected: !subItem.selected,
                };
              }
              return subItem;
            }),
          };
        }
        return item;
      })
    );
    await updateProject({ filterOptionId: childId, status: status });
  };

  // Update name
  const updateProject = async (data) => {
    try {
      await ProjectAPI.post(`timeline/filters-status-update`, data);
    } catch (error) {
      // toast.error(error, { position: toast.POSITION.TOP_CENTER });
    } finally {
      let load = true;
      await dispatch(timelineStatsAction(load));
    }
  };

  return props?.load ? (
    <div className="ms-4 dashboard-listing-title-wraper">
      <ul className="row">
        <li className="col-md-4 position-relative">
          <ShimmerPostItem
            title
            cta
            imageType="thumbnail"
            imageWidth={50}
            imageHeight={50}
            contentCenter
          />
        </li>
        <li className="col-md-4 position-relative">
          <ShimmerPostItem
            title
            cta
            imageType="thumbnail"
            imageWidth={50}
            imageHeight={50}
            contentCenter
          />
        </li>
        <li className="col-md-4 position-relative">
          <ShimmerPostItem
            title
            cta
            imageType="thumbnail"
            imageWidth={50}
            imageHeight={50}
            contentCenter
          />
        </li>
      </ul>
    </div>
  ) : (
    <div className="ms-4 dashboard-listing-title-wraper">
      <Tooltip id="my-tooltip" style={{ zIndex: 2 }} />
      <ul className="row">
        {props?.timelineGrid?.map((res, id) => (
          <li
            className="col-md-4 position-relative"
            key={id}
            onMouseEnter={() => setShowDropdownIndex(id)} // Show dropdown on mouse enter
            onMouseLeave={() => setShowDropdownIndex(null)} // Hide dropdown on mouse leave
          >
            <div className="listing-tile-dash">
              <div className="d-flex align-items-center justify-content-between heading-tile-dash">
                <div className="d-flex align-items-center timeline-heading-title-wrap">
                  <div className="timeline-heading-title-img">
                    {" "}
                    <Icon icon={res?.icon} />
                  </div>
                  <strong className="position-relative">
                    {" "}
                    {res?.name}{" "}
                    <span>
                      {showDropdownIndex === id && ( // Render dropdown only when showDropdownIndex matches the current index
                        <Dropdown className="roles-drop-down timeline-dropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="menu-btn">
                              <Icon icon="mi:options-horizontal" />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ width: "210px" }}>
                            {res?.type_count?.map((r, id) => (
                              <div className="py-1 ps-1 w-100" key={id}>
                                <div
                                  className="checkbox create-task-checkbox create-timeline-grid"
                                  onClick={() =>
                                    toggleSelected(
                                      res.id,
                                      r.id,
                                      r.selected === true ? false : true
                                    )
                                  } // Toggle selected property
                                >
                                  <input
                                    type="checkbox"
                                    checked={r?.selected}
                                    readOnly
                                  />
                                  <label className="timeline-drop-text">
                                    <span className="ps-1">
                                      {r?.name
                                        ?.split(" ")
                                        .map(
                                          (word) =>
                                            word[0].toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </span>
                  </strong>
                </div>
                <span>
                  {" "}
                  {id !== props.timelineGrid.length - 1 && res?.count}{" "}
                </span>{" "}
              </div>
              <div className="info-title-wrap">
                {res?.type_count?.map(
                  (data, id) =>
                    data?.selected && (
                      <span key={id} className="timeline-per-grid-options">
                        <span className="d-flex">
                          <p
                            onClick={() => {
                              if (
                                data?.type !== "clickDisable" &&
                                data?.count !== 0
                              ) {
                                if (memberId) {
                                  sessionStorage.setItem(
                                    "filtered_user",
                                    memberId
                                  );
                                }
                                if (res?.name === "Tasks") {
                                  sessionStorage.setItem(
                                    "filterType",
                                    data?.type
                                  );
                                }
                                if (res?.name === "Projects") {
                                  sessionStorage.setItem(
                                    "filterTypeProject",
                                    data?.type
                                  );
                                }
                                if (res?.name === "Productivity") {
                                  if (
                                    data?.type?.toLowerCase() === "teams" &&
                                    isPermitted("teams/members-listing")
                                  ) {
                                    localStorage.setItem(
                                      "task-management-timeline-handle-type",
                                      0
                                    );
                                  }
                                  if (
                                    data?.type?.toLowerCase() === "members" &&
                                    isPermitted("teams/members-listing")
                                  ) {
                                    localStorage.setItem(
                                      "task-management-timeline-handle-type",
                                      1
                                    );
                                  }
                                  if (
                                    data?.type?.toLowerCase() !== "members" &&
                                    data?.type?.toLowerCase() !== "teams" &&
                                    isPermitted("teams/members-listing")
                                  ) {
                                    localStorage.setItem(
                                      "task-management-timeline-handle-type",
                                      1
                                    );
                                    sessionStorage.setItem(
                                      "filterTypeMember",
                                      data?.type
                                    );
                                  }
                                }
                                // if (
                                //   data?.name?.toLowerCase() ===
                                //   "coming soon projects"
                                // ) {
                                //   sessionStorage.setItem("showClosingSoon", true);
                                // }
                                // if (res?.name === "Projects") {
                                //   if (data?.type !== 2) {
                                //     localStorage.setItem(
                                //       `isShowOnlyCompletedProjects`,
                                //       false
                                //     );
                                //     localStorage.setItem(
                                //       "projectFilter",
                                //       data?.type
                                //     );
                                //   } else {
                                //     localStorage.removeItem("projectFilter");
                                //     localStorage.setItem(
                                //       `isShowOnlyCompletedProjects`,
                                //       true
                                //     );
                                //   }
                                // }
                                // if (res?.name === "Tasks") {
                                //   localStorage.setItem("taskFilter", data?.type);
                                //   if (
                                //     data?.name?.toLowerCase() === "today tasks"
                                //   ) {
                                //     sessionStorage.setItem(
                                //       "showTodayTasks",
                                //       true
                                //     );
                                //   }
                                //   if (
                                //     data?.name?.toLowerCase() === "weekly task"
                                //   ) {
                                //     sessionStorage.setItem(
                                //       "showWeeklyTasks",
                                //       true
                                //     );
                                //   }
                                // }
                                // if (res?.name === "Teams") {
                                //   if (
                                //     data?.type?.toLowerCase() === "teams" &&
                                //     isPermitted("teams/members-listing")
                                //   ) {
                                //     localStorage.setItem(
                                //       "task-management-timeline-handle-type",
                                //       0
                                //     );
                                //   }
                                //   if (
                                //     data?.type?.toLowerCase() === "members" &&
                                //     isPermitted("teams/members-listing")
                                //   ) {
                                //     localStorage.setItem(
                                //       "task-management-timeline-handle-type",
                                //       1
                                //     );
                                //   }
                                //   if (
                                //     data?.type === "assignedMembers" &&
                                //     isPermitted("teams/members-listing")
                                //   ) {
                                //     localStorage.setItem(
                                //       "task-management-timeline-handle-type",
                                //       1
                                //     );
                                //     localStorage.setItem(
                                //       "task-management-timeline-handle-type-assigned-members",
                                //       1
                                //     );
                                //   }
                                // }
                                navigate(
                                  `/${localStorage.getItem("company")}/${
                                    res?.url
                                  }`
                                );
                              }
                            }}
                          >
                            {data?.name
                              .split(" ")
                              .map(
                                (word) => word[0].toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </p>
                          <a>
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={data?.tooltip}
                            />
                          </a>
                        </span>
                        <strong>({data?.count}) </strong>
                      </span>
                    )
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimelineGrids;
