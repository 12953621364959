import React from "react";
import Board from "./dnd/board/Board.jsx";

const KanbanView = (props) => {
  return (
    <Board
      tasksData={props.tasksData}
      setTasksData={props.setTasksData}
      isDark={props.isDark}
      projectId={props.projectId}
      getAllTasks={props.getAllTasks}
      onKanban={props.onKanban}
      loadKanban={props.loadKanban}
      setLoadKanban={props.setLoadKanban}
      projectStatusList={props.projectStatusList}
      setKanban={props.setKanban}
      setAllLoad={props.setAllLoad}
    />
  );
};

export default KanbanView;
