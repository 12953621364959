import {
  CLEAR_ERROR,
  COMPANY_LISTING_FAIL,
  COMPANY_LISTING_REQUEST,
  COMPANY_LISTING_SUCCESS,
  COMPANY_MODAL_DATA_FAIL,
  COMPANY_MODAL_DATA_REQUEST,
  COMPANY_MODAL_DATA_SUCCESS,
  COMPANY_NAME_FAIL,
  COMPANY_NAME_REQUEST,
  COMPANY_NAME_SUCCESS,
  INVITEE_LISTING_FAIL,
  INVITEE_LISTING_REQUEST,
  INVITEE_LISTING_SUCCESS,
} from "../constants/companyOverviewConstatnt";

export const userListingReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
      };

    case COMPANY_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const userInviteeListingReducer = (state = {}, action) => {
  switch (action.type) {
    case INVITEE_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVITEE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        userInviteeList: action.payload,
      };

    case INVITEE_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const companyNameReducer = (state = null, action) => {
  switch (action.type) {
    case COMPANY_NAME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        companyName: action.payload,
      };

    case COMPANY_NAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const companyModalDataReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_MODAL_DATA_REQUEST:
      return {
        ...state,
        mdloading: true,
      };

    case COMPANY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        mdloading: false,
        modalContents: action.payload,
      };

    case COMPANY_MODAL_DATA_FAIL:
      return {
        ...state,
        mdloading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
