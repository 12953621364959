import React, { useState } from "react";
import "../styles/contactModal.css";
import BackIcon from "../../assets/back.svg";
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import API from "../../services/ClientApi";
import { toast } from "react-toastify";

const ContactModal = (props) => {
  const [load, setLoad] = useState(false);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: null,
    company_size: "",
    job_title: "",
    contactType: "Enterprize Packge",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9+ ]+$/, "Invalid phone number"),
    company_size: Yup.string().required("Company size is required"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <Modal
      show={props.modal}
      onHide={() => props.handleClose()}
      className={`main-update-modal contact-modal`}
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="" closeButton>
        <div className="d-flex align-items-center add-on-popup-wraper">
          {/* <div className="go-back">
            <img src={BackIcon} alt="Go Back" />
          </div> */}
          <h2>
            {" "}
            Contact <span>Us</span>{" "}
          </h2>
        </div>
      </Modal.Header>

      <Modal.Body className="update-modal-body">
        <div className="contact-body-wrapper">
          <div className="w-100 d-flex text-center contact-para">
            <p>
              Contact our consulting team today for hands-on guidance tailored
              to your team's specific needs
            </p>
          </div>
          <div className="contact-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                // Handle your form submission logic here
                try {
                  setLoad(true);
                  const response = await API.post(
                    "auth/contact-request",
                    values
                  );
                  if (response?.data?.status === "success") {
                    toast.success(
                      "Contact information submitted successfully",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  }
                  props.handleClose();
                } catch (error) {
                } finally {
                  setLoad(false);
                }
                // Reset the form after submission
                resetForm();
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="first_name">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          isInvalid={touched.first_name && !!errors.first_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="last_name">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          isInvalid={touched.last_name && !!errors.last_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.last_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="email">
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="phone_number">
                        <Form.Label>Phone Number*</Form.Label>
                        <PhoneInput
                          international
                          defaultCountry="US"
                          value={values.phone_number}
                          className={`${
                            touched.phone_number && errors.phone_number
                              ? "invalid-phone"
                              : ""
                          }`}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "phone_number", value },
                            })
                          }
                          isInvalid={
                            touched.phone_number && !!errors.phone_number
                          }
                        />
                        {touched.phone_number && errors.phone_number && (
                          <div className="w-100 err-pn">
                            {errors.phone_number}
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.phone_number}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="job_title">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="job_title"
                          placeholder="Job Title"
                          value={values.job_title}
                          onChange={handleChange}
                          isInvalid={touched.job_title && !!errors.job_title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.job_title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="company_size">
                        <Form.Label>Company Size*</Form.Label>
                        <Form.Control
                          as="select"
                          name="company_size"
                          placeholder="Select"
                          value={values.company_size}
                          onChange={handleChange}
                          isInvalid={
                            touched.company_size && !!errors.company_size
                          }
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.company_size}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="message">
                        <Form.Label>How can our team help you?*</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="message"
                          placeholder="Your message"
                          value={values.message}
                          onChange={handleChange}
                          isInvalid={touched.message && !!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="choose-continue-btn choose-continue-btn-margin">
                    <div className="next-button-wraper module-btn-wrapper">
                      <button
                        type="submit"
                        disabled={load}
                        className="module-modal-btn module-main-btn remove-package-modal-btn"
                      >
                        {load ? <Spinner animation="border" /> : "Submit"}{" "}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
