import React, { useEffect, useMemo, useRef, useState } from "react";
import TaskIcon from "../../../assets/task-board.svg";
import ProjectIcon from "../../../assets/project-graph.svg";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { dateFormatter } from "../../../helper/dateFormatter";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import ConfirmDeleteProjectModal from "../../Projects/components/ConfirmDeleteProjectModal";
import { Tooltip } from "react-tooltip";
import { TasksPerformanceDropdownRenderer } from "../../Tasks/components/utils/data";
import doneImage from "../../../assets/completed-icon.png";
import { MemberListingTablePerformanceCellRenderer } from "../utils/data";
import { useNavigate } from "react-router-dom";
import { isPermitted } from "../../../helper/isPermitted";

const MemberList = React.memo((props) => {
  const navigate = useNavigate();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const optionsDropdownRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const OpenProjectCellRenderer = ({ pid }) => {
    return (
      <Link
        className="open-target-link"
        to={"/" + localStorage.getItem("company") + "/team-member?pid=" + pid}
      >
        <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
        {/* <img src={openTask} alt="Open Task" width={100} height={100} /> */}
      </Link>
    );
  };

  const columns = [
    {
      field: "userName",
      headerName: "Name",
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2">
            {params.data.profileImage ? (
              <img
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                }}
                src={`${params.data.profileImage}`}
                alt="user-avatar"
              />
            ) : (
              <div className="nama-table-wrap d-flex align-items-center">
                {" "}
                <span>
                  {params.data?.userName[0]?.toUpperCase()}
                  {params.data?.userName?.split(" ")[1]
                    ? params.data?.userName?.split(" ")[1][0]?.toUpperCase()
                    : params.data?.userName[1]?.toUpperCase()}
                </span>
              </div>
            )}
            <p
              className="mb-0"
              style={{
                cursor: isPermitted("teams/members-detail") ? "pointer" : "",
              }}
              onClick={(e) => {
                if (isPermitted("teams/members-detail")) {
                  e.stopPropagation();
                  e.preventDefault();
                  const companyId = localStorage.getItem("company");
                  const userId = params?.data?.userId;
                  if (companyId && userId) {
                    navigate(`/${companyId}/team-member?pid=${userId}`);
                  } else {
                    console.error("Company ID or User ID is missing");
                  }
                }
              }}
            >
              {params.data.userName}
            </p>
          </div>
        );
      },
    },

    {
      field: "",
      headerName: "",
      maxWidth: 100,
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      hide: !isPermitted("teams/members-detail"),
      cellRenderer: (params) => {
        return <OpenProjectCellRenderer pid={params.data.userId} />;
      },
    },
    {
      field: "taskCount",
      headerName: "Task",
      editable: false,
      maxWidth: 200,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2 team-list-img-text-wrap">
            <img src={TaskIcon} alt="user-avatar" />
            <p className="mb-0"> Tasks {params.data.taskCount}</p>
          </div>
        );
      },
    },
    {
      field: "projectCount",
      headerName: "Project",
      editable: false,
      maxWidth: 200,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2 team-list-img-text-wrap">
            <img src={ProjectIcon} alt="user-avatar" />
            <p className="mb-0"> Projects {params.data.projectCount}</p>
          </div>
        );
      },
    },
    {
      field: "progressbarValue",
      headerName: "",
      minWidth: 300,
      suppressMenu: true,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellEditorSelector: (params) => {
        return {
          component: (params) => {
            return (
              <div
                style={{
                  // position: "absolute",
                  // left: `${xValue}px`,
                  // top: `${yValue + 20}px`,
                  listStyle: "none",
                  background: "#fff", // Set background color
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  fontWeight: 300,
                }}
                className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
              >
                <div className="task-drop-renderer">
                  <div className="mb-2 mt-2 task-timeline-progress review-task-timeline-progress">
                    {" "}
                    <img src={doneImage} alt="Done" /> Not Started{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress review-task-timeline-progress ">
                    <img src={doneImage} alt="Done" /> Started{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress review-task-timeline-progress text-danger">
                    {" "}
                    <Icon
                      icon="mdi:close-circle"
                      className="cross-icon-width-height"
                    />{" "}
                    In Progress{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress done-task-timeline-progress">
                    {" "}
                    <img src={doneImage} alt="Done" /> Completed{" "}
                  </div>
                </div>
              </div>
            );
          },
          popup: true,
          popupPosition: "under",
        };
      },

      cellRenderer: (params) => {
        return (
          <>
            <MemberListingTablePerformanceCellRenderer
              data={params.data}
              api={params.api}
              params={params}
              dropdownRenderer={TasksPerformanceDropdownRenderer}
            />
          </>
        );
      },
    },
    {
      field: "recentTaskCount",
      editable: false,
      headerName: "",
      maxWidth: 180,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2 team-list-img-text-wrap">
            <p className="mb-0"> Recent Tasks {params.data.recentTaskCount}</p>
          </div>
        );
      },
    },
    {
      field: "last_active",
      headerName: "",
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.userId ? "selected-project-row" : "",
      cellRenderer: (params) => {
        // buttonRefs[params.rowIndex] = buttonRef.current;
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              Last active: {dateFormatter(params?.data?.updatedAt)}
            </p>
          </div>
        );
      },
    },
  ];

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const gridRef = useRef(null);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Get the bounding box of the grid and options dropdown
      const gridRect = gridRef?.current?.getBoundingClientRect();
      const optionsDropdownRect =
        optionsDropdownRef?.current?.getBoundingClientRect();

      // Check if the mouse has left the grid area
      if (
        event.clientX > gridRect.right ||
        event.clientY < gridRect.top ||
        event.clientY > gridRect.bottom
      ) {
        if (!showMore) {
          setHoveredRow(null);
          setShowMore(false);
        }
        // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

        if (
          showMenu === false ||
          (optionsDropdownRect &&
            (event.clientX < optionsDropdownRect.left ||
              event.clientX > optionsDropdownRect.right ||
              event.clientY < optionsDropdownRect.top ||
              event.clientY > optionsDropdownRect.bottom))
        ) {
          setHoveredMenu(null);
          setShowMenu(false);
        }
      }
    };

    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.memberList]);

  useEffect(() => {
    if (props.memberList?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [props.memberList]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  return (
    <div
      className="position-relative project-timeline mt-3 active-project-no-header member-list-border-right"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      {/* <div className="position-relative">
        {gridApi && renderCustomOptionsColumn()}
      </div> */}
      <div
        className="table-responsive ms-4 scroll-smooth custom-horizental-scrollbar"
        ref={tableRef}
      >
        <Tooltip id="my-tooltip" />

        <div
          className="ag-theme-alpine project-main-grid"
          style={{
            width: "98%",
            minWidth: "1550px",
          }}
          ref={gridRef}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                props.memberList?.length === 1
                  ? "left-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={props.memberList}
            defaultColDef={defaultColDef}
            domLayout={"autoHeight"}
            columnDefs={columns}
            singleClickEdit={true}
            rowHeight={55}
            animateRows={true}
            rowDragManaged={true}
            rowDragEntireRow={true}
            popupParent={popupParent}
            stopEditingWhenCellsLoseFocus={true}
            suppressRowHoverHighlight={true}
            onCellMouseOver={(params) => {
              if (params.data.userId !== hoveredRow) {
                setShowMore(false);
                setHoveredRow(params?.data?.userId);
              }
              if (params?.rowIndex !== hoveredMenu) {
                setShowMenu(false);
                setHoveredMenu(params?.rowIndex);
              }
            }}
          />
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                props.memberList?.length === 1
                  ? "right-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmDeleteProjectModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDark={props.isDark}
          getProjectName={() => {
            return true;
          }}
          type="Bilal arshad"
        />
      )}
    </div>
  );
});

export default MemberList;
