import React, { useState } from "react";
import plusImage from "../../../assets/plus.svg";
import { Icon } from "@iconify/react";
import userMemberImage from "../../../assets/user-member.svg";
import NewTeam from "../../Team/components/NewTeam";
import { useSelector } from "react-redux";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { dateFormatter } from "../../../helper/dateFormatter";
import doneImage from "../../../assets/completed-icon.png";
import reviewImage from "../../../assets/on-hold.png";
import criticalImage from "../../../assets/critical.svg";
import ProgressIcon from "../../../assets/progress.svg";
import { isPermitted } from "../../../helper/isPermitted";

function TeamMemberWorkedOnGrid(props) {
  const [show, setShow] = useState(false);
  const [create, setCreate] = useState(0);

  // creating dummy stats to show create task
  const [teamToFilterList, setTeamToFilterList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  //
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectFormList } = useSelector((state) => state.projectFormListData);

  const handleClose = () => {
    setShow(false);
    setCreate(0);
  };
  const handleCreate = (value) => {
    setCreate(value);
  };

  // Function to convert projectMembers array
  const convertProjectArray = (projectArray) => {
    return projectArray?.map((project) => ({
      id: project.projectId,
      value: project.projectName,
      label: project.projectName,
    }));
  };

  // Function to convert projectUserListing array
  const convertProjectUserListingArray = (projectUserListingArray) => {
    return projectUserListingArray?.map((user) => ({
      id: user.userId,
      value: user.userName,
      label: user.userName,
    }));
  };

  // Modified project and user listing data structure required for team/member creation form
  const newProjectList = convertProjectArray(projectFormList);
  const newUsersList = convertProjectUserListingArray(projectUserList?.data);

  const getStatusIcon = (statusName) => {
    if (statusName?.toLowerCase() === "in progress") {
      return (
        <div className="task-timeline-progress progress-task-timeline-progress">
          {" "}
          <img src={ProgressIcon} alt="Progress" /> {statusName}{" "}
        </div>
      );
    } else if (statusName?.toLowerCase() === "completed") {
      return (
        <div className="task-timeline-progress done-task-timeline-progress">
          {" "}
          <img src={doneImage} alt="Done" /> {statusName}{" "}
        </div>
      );
    } else if (statusName?.toLowerCase() === "on hold") {
      return (
        <div className="task-timeline-progress review-task-timeline-progress">
          {" "}
          <img src={reviewImage} alt="Review" /> {statusName}{" "}
        </div>
      );
    } else if (statusName?.toLowerCase() === "submit for review") {
      return (
        <div className="task-timeline-progress review-task-timeline-progress">
          {" "}
          <img src={reviewImage} alt="Review" /> {statusName}{" "}
        </div>
      );
    } else if (statusName?.toLowerCase() === "not started") {
      return (
        <div className="task-timeline-progress review-task-timeline-progress">
          {" "}
          <img src={criticalImage} alt="Review" /> {statusName}{" "}
        </div>
      );
    } else
      return (
        <div className="task-timeline-progress progress-task-timeline-progress">
          {" "}
          {statusName}{" "}
        </div>
      );
  };

  return (
    <div className="team-left-right-wraper">
      <div className="row">
        <div className="col-lg-4 col-md-5 col-12">
          <div className="w-100 team-left-info-wrap">
            <h5>
              {" "}
              Teams{" "}
              {isPermitted("teams/create") && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
                  {" "}
                  <img src={plusImage} alt="Plus" width={20} height={20} />{" "}
                  Create Team{" "}
                </a>
              )}
            </h5>
            <div className="list-team-user-wrap ">
              {props?.memberDetailsData?.memberTeams?.length > 0 ? (
                <ul>
                  {props.memberDetailsData?.memberTeams?.map((member) => {
                    return (
                      <li>
                        {" "}
                        <img
                          src={
                            getProjectIconById(member?.icon)
                              ? getProjectIconById(member?.icon)
                              : member?.icon
                          }
                          alt="User Team"
                        />{" "}
                        {member?.teamName}{" "}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="mb-0 no-associated-proj-txt">
                  User is not associated with any team yet.
                </p>
              )}
            </div>
            <div className="contact-info-wraper">
              <h4> Contact Info </h4>
              <ul>
                <li>
                  {" "}
                  <Icon icon="lets-icons:message" />{" "}
                  {props.memberDetailsData?.userInfo?.userEmail
                    ? props.memberDetailsData?.userInfo?.userEmail
                    : "No email provided by the user"}{" "}
                </li>
                <li>
                  {" "}
                  <Icon icon="iconamoon:phone-light" /> 45 645 79848{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-7 col-12 mt-md-0 mt-3">
          <div className="w-100 user-profile-worked-wrap">
            <h5> Worked On</h5>
            {props.memberDetailsData?.projects?.length > 0 ? (
              <div className="table-responsive scroll-smooth custom-horizental-scrollbar">
                <table
                  className="table"
                  border="1"
                  style={{
                    width: "100%",
                    minWidth: "850px",
                  }}
                >
                  {props.memberDetailsData?.projects?.map((project) => {
                    return (
                      <tr>
                        <td width="32%">
                          <div className="table-heading">
                            {" "}
                            <img
                              src={
                                getProjectIconById(project?.projectIcon)
                                  ? getProjectIconById(project?.projectIcon)
                                  : project?.projectIcon
                              }
                              alt="modules-name"
                            />{" "}
                            {project?.projectName}{" "}
                          </div>
                        </td>
                        <td width="25%">
                          <div className="user-member-wrap">
                            {" "}
                            <img src={userMemberImage} alt="User Member" />{" "}
                            Member {project?.projectMembers?.length}{" "}
                          </div>
                        </td>
                        <td width="22%">
                          <div className="status-icon-wrap  status-in-progress">
                            {" "}
                            {getStatusIcon(
                              project?.projectStatus?.statusName
                            )}{" "}
                          </div>
                        </td>
                        <td width="21%" className="user-member-last-active">
                          Last updated : {dateFormatter(project?.updatedAt)}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            ) : (
              <p className="mb-0 no-associated-proj-txt">
                User is not associated with any project yet.
              </p>
            )}
          </div>
        </div>
      </div>

      <NewTeam
        create={create}
        show={show}
        handleClose={handleClose}
        isDark={props.isDark}
        handleCreate={handleCreate}
        projectList={newProjectList}
        projectUserList={newUsersList}
        teamToFilterList={teamToFilterList}
        setTeamToFilterList={setTeamToFilterList}
        teamList={teamList}
        setTeamList={setTeamList}
        isMemDetail={true}
        teamDataAfterUpdate={() => {}}
        getMemberDetails={props.getMemberDetails}
      />
    </div>
  );
}

export default TeamMemberWorkedOnGrid;
