// Constants for create project phase
export const CREATE_PROJECT_PHASE_REQUEST = "CREATE_PROJECT_PHASE_REQUEST";
export const CREATE_PROJECT_PHASE_SUCCESS = "CREATE_PROJECT_PHASE_SUCCESS";
export const CREATE_PROJECT_PHASE_FAIL = "CREATE_PROJECT_PHASE_FAIL";

// Constants for get projects phases
export const GET_PROJECTS_PHASES_REQUEST = "GET_PROJECTS_PHASES_REQUEST";
export const GET_PROJECTS_PHASES_SUCCESS = "GET_PROJECTS_PHASES_SUCCESS";
export const GET_PROJECTS_PHASES_FAIL = "GET_PROJECTS_PHASES_FAIL";

// Constants for update project phase
export const UPDATE_PROJECT_PHASE_REQUEST = "UPDATE_PROJECT_PHASE_REQUEST";
export const UPDATE_PROJECT_PHASE_SUCCESS = "UPDATE_PROJECT_PHASE_SUCCESS";
export const UPDATE_PROJECT_PHASE_FAIL = "UPDATE_PROJECT_PHASE_FAIL";

// Constants for project phase error
export const PROJECT_PHASE_ERROR = "PROJECT_PHASE_ERROR";
