import React, { useEffect, useState } from "react";
import UserChatProfile from "./UserChatProfile";
import UserChatMessages from "./UserChatMessages";
import UserChatMessageInput from "./UserChatMessageInput";
import { toast } from "react-toastify";
import { calculateTotalSize } from "../../Tasks/components/CreateTaskModal";

function WrapperUserChat({
  selectedChat,
  user,
  roomChats,
  socket,
  setMessages,
  handleByWeekLoad,
  prevDays,
  days,
  setPrevDays,
  chats,
  isDark,
  maxMessages,
  lastSeen,
  initialLoad,
  roomChangeLoad,
  setRoomChangeLoad,
  online,
  away,
}) {
  const [replyMessage, setReplyMessage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Define allowed file extensions and max file size
  const allowedExtensions = [
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "doc",
    "docx",
    "txt",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "gif",
    "bmp",
    "svg",
    "zip",
    "rar",
    "mp3",
    "mp4",
  ];
  const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB

  // Validate file (check extension and size)
  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(
        `Invalid file type: .${fileExtension}. Only the following are allowed: ${allowedExtensions.join(
          ", "
        )}.`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return false;
    }
    if (file.size > maxFileSize) {
      toast.error(`File size exceeds the limit of 8 MB.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  // Select files on drag
  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const totalSize = calculateTotalSize(files);

    if (totalSize > 8 * 1024 * 1024) {
      // 8MB in bytes
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return; // Prevent further execution if validation fails
    }

    // Filter valid files only
    const validFiles = files.filter((file) => validateFile(file));
    if (validFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...validFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Remove files if chat room changes
  useEffect(() => {
    setSelectedFiles([]);
  }, [selectedChat]);
  // console.log("Messages : ", roomChats)
  return (
    <div data-theme={isDark} className="user-chat-main-wrapper">
      {/* user chat profile */}
      <UserChatProfile
        selectedChat={selectedChat}
        socket={socket}
        lastSeen={lastSeen}
        online={online}
        away={away}
      />

      {/* user messages */}
      <UserChatMessages
        selectedChat={selectedChat}
        setReplyMessage={setReplyMessage}
        roomChats={roomChats}
        user={user}
        socket={socket}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        onFileDrop={handleFileDrop}
        onDragOver={handleDragOver}
        setMessages={setMessages}
        handleByWeekLoad={handleByWeekLoad}
        prevDays={prevDays}
        days={days}
        setPrevDays={setPrevDays}
        chats={chats}
        isDark={isDark}
        maxMessages={maxMessages}
        initialLoad={initialLoad}
        roomChangeLoad={roomChangeLoad}
      />

      {/* message input and button */}
      <UserChatMessageInput
        replyMessage={replyMessage}
        setReplyMessage={setReplyMessage}
        selectedChat={selectedChat}
        socket={socket}
        user={user}
        selectedFiles={selectedFiles}
        setMessages={setMessages}
        // roomChats={roomChats}
        setSelectedFiles={setSelectedFiles}
        onFileDrop={handleFileDrop}
        onDragOver={handleDragOver}
      />
    </div>
  );
}

export default WrapperUserChat;
