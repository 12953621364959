// Import constants
import {
  PROJECT_LISTING_SUCCESS,
  PROJECT_LISTING_REQUEST,
  PROJECT_LISTING_NOLOAD_REQUEST,
  PROJECT_LISTING_FAIL,
  PROJECT_DETAIL_SUCCESS,
  PROJECT_DETAIL_REQUEST,
  PROJECT_DETAIL_FAIL,
  CREATE_PROJECT_FAIL,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_FAIL,
  SHARE_PROJECT_SUCCESS,
  SHARE_PROJECT_REQUEST,
  SHARE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_FAIL,
  CREATE_PROJECT_TYPES_SUCCESS,
  CREATE_PROJECT_TYPES_REQUEST,
  CREATE_PROJECT_TYPES_FAIL,
  CREATE_PROJECT_USERS_SUCCESS,
  CREATE_PROJECT_USERS_REQUEST,
  CREATE_PROJECT_USERS_FAIL,
  PROJECT_LISTING_STATUS_REQUEST,
  PROJECT_LISTING_STATUS_SUCCESS,
  PROJECT_LISTING_STATUS_FAIL,
  PROJECT_FORM_LISTING_FAIL,
  PROJECT_FORM_LISTING_REQUEST,
  PROJECT_FORM_LISTING_SUCCESS,
  CLEAR_PROJECT_ERROR,
  PROJECT_UPDATE_REQUEST,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAIL,
  ADD_PROJECT_SUCCESS,
  PROJECT_LISTING_ALL_STATUS_FAIL,
  PROJECT_LISTING_ALL_STATUS_REQUEST,
  PROJECT_LISTING_ALL_STATUS_SUCCESS,
} from "../constants/projectConstants";

// Project listing reducer
export const projectListingReducer = (state = { projectList: [] }, action) => {
  switch (action.type) {
    case PROJECT_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_LISTING_NOLOAD_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        projectList: action.payload,
      };
    case PROJECT_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PROJECT_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        projectList: action.payload,
      };
    case PROJECT_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: [action.payload, ...state.projectList],
      };

    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project form listing reducer
export const projectFormListingReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_FORM_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_FORM_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        projectFormList: action.payload,
      };
    case PROJECT_FORM_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project detail reducer
export const projectDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        projectData: action.payload,
      };
    case PROJECT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create project reducer
export const createProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload,
      };
    case CREATE_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Edit project reducer
export const editProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projectEdit: action.payload,
      };
    case EDIT_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Share project reducer
export const shareProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case SHARE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHARE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload,
      };
    case SHARE_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete project reducer
export const deleteProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project user list reducers
export const projectUserListingReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_USERS_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case CREATE_PROJECT_USERS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        projectUserList: action.payload,
      };
    case CREATE_PROJECT_USERS_FAIL:
      return {
        ...state,
        userLoading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project type list reducers
export const projectTypeListingReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_TYPES_REQUEST:
      return {
        ...state,
        typeLoading: true,
      };
    case CREATE_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        typeLoading: false,
        projectTypeList: action.payload,
      };
    case CREATE_PROJECT_TYPES_FAIL:
      return {
        ...state,
        typeLoading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project status reducer
export const projectListingStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_LISTING_STATUS_REQUEST:
      return {
        ...state,
        sloading: true,
      };
    case PROJECT_LISTING_STATUS_SUCCESS:
      return {
        ...state,
        sloading: false,
        projectStatusList: action.payload,
      };
    case PROJECT_LISTING_STATUS_FAIL:
      return {
        ...state,
        sloading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Project status reducer
export const projectListingAllStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_LISTING_ALL_STATUS_REQUEST:
      return {
        ...state,
        sloading: true,
      };
    case PROJECT_LISTING_ALL_STATUS_SUCCESS:
      return {
        ...state,
        sloading: false,
        projectAllStatusList: action.payload,
      };
    case PROJECT_LISTING_ALL_STATUS_FAIL:
      return {
        ...state,
        sloading: false,
        error: action.payload,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
