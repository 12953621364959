import React, { useEffect, useState } from "react";
import { isPermitted } from "../../../helper/isPermitted";
import avatar from "../../../assets/avatar.svg";
import SearchIcon from "../../../assets/search-icon.png";
import { toast } from "react-toastify";
import { useUpdateCompletedTaskMemberDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import { useClearAllMemberTaskCacheMutation } from "../../../slices/tasks/tasksSlice";
import { sortCheckedMembersOnTop } from "../../../helper/sortCheckedMembersOnTop";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const MemberUserListComponent = ({
  projectUserList,
  setData,
  data,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  showFilterDropDown,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  isDark,
  userSearchList,
  search,
  handleSearch,
  clearSearch,
  autoFocus,
  inputRef,
  setIsClicked,
  isClicked,
}) => {
  return (
    <div className="w-100 d-flex flex-column custom-user-selection-main-container-mobile">
      <div className="listing-team-modal-search-input-wrap position-relative">
        <input
          ref={inputRef}
          type="text"
          name="search"
          value={search}
          onChange={handleSearch}
          placeholder="Search members"
          autoFocus={autoFocus}
          autoComplete="off"
        />
        {search ? (
          <span className="custom-user-search-icon" onClick={clearSearch}>
            &#x2715;
          </span>
        ) : (
          <img
            src={SearchIcon}
            className="custom-user-search-icon"
            alt="search-icon"
            width={20}
            height={20}
          />
        )}
      </div>
      <TaskUsersListForFilter
        isDark={isDark}
        projectUserList={userSearchList}
        setData={setData}
        data={data}
        getTypeID={getTypeID}
        currentTab={
          currentTab === 1 ? "observer" : currentTab === 2 ? "owner" : "member"
        }
        updateRowParameterById={updateRowParameterById}
        kanban={kanban}
        getTaskDataAfterUpdate={getTaskDataAfterUpdate}
        updateTaskData={updateTaskData}
        pageParams={pageParams}
        listingType={listingType}
        setUpdateLoad={setUpdateLoad}
        updateTaskMemberData={updateTaskMemberData}
        updatePhaseTaskMemberData={updatePhaseTaskMemberData}
        isCompletedTaskChanged={isCompletedTaskChanged}
        setIsCompletedTaskChanged={setIsCompletedTaskChanged}
        showFilterDropDown={showFilterDropDown}
        userListRef={userListRef}
        focusedIndex={focusedIndex}
        setFocusedIndex={setFocusedIndex}
        setIsClicked={setIsClicked}
        isClicked={isClicked}
      />
    </div>
  );
};

const TaskUsersListForFilter = ({
  projectUserList,
  setData,
  data,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  showFilterDropDown,
  userListRef,

  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const ownerCount = data?.taskMembers?.filter(
    (member) => member.memberType === "owner"
  ).length;

  const newMembers = sortCheckedMembersOnTop(
    projectUserList,
    data?.taskMembers,
    currentTab === "observer" ? 1 : currentTab === "owner" ? 2 : 3
  );

  return (
    <div
      ref={userListRef}
      className="h-100 d-flex flex-column gap-2 user-list-main-container custom-vertical-scrollbar"
    >
      {newMembers?.map((res, index) => (
        <TaskUsersListItemForFilter
          setData={setData}
          data={data}
          selectedUsers={data?.taskMembers}
          res={res}
          currentTab={currentTab}
          getTypeID={getTypeID}
          updateRowParameterById={updateRowParameterById}
          kanban={kanban}
          getTaskDataAfterUpdate={getTaskDataAfterUpdate}
          updateTaskData={updateTaskData}
          pageParams={pageParams}
          listingType={listingType}
          setUpdateLoad={setUpdateLoad}
          ownerCount={ownerCount}
          updateTaskMemberData={updateTaskMemberData}
          updatePhaseTaskMemberData={updatePhaseTaskMemberData}
          isCompletedTaskChanged={isCompletedTaskChanged}
          setIsCompletedTaskChanged={setIsCompletedTaskChanged}
          index={index}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          setIsClicked={setIsClicked}
          isClicked={isClicked}
        />
      ))}
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  setData,
  data,
  res,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  selectedUsers,
  setUpdateLoad,
  ownerCount,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [updateCompletedTaskMemberData, { isLoading: completeMemberLoad }] =
    useUpdateCompletedTaskMemberDataMutation();
  const [clearAllMemberTaskCache] = useClearAllMemberTaskCacheMutation();

  const [isChecked, setIsChecked] = useState(false);
  const userId = res?.userId; // Initialize userId here

  useEffect(() => {
    // Check if the user is selected and update isChecked state
    const isUserSelected = selectedUsers?.some(
      (member) => member?.userId === userId && member?.memberType === currentTab
    );
    setIsChecked(isUserSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, currentTab, res]);

  const handleCheckboxClick = async () => {
    if (data?.taskStatus?.statusName !== "Completed") {
      if (ownerCount < 2 && currentTab === "owner" && isChecked) {
        toast.info(
          "There will always be atleast one owner associated with tasks.",
          { position: toast.POSITION.TOP_CENTER }
        );
      } else {
        try {
          let updatedTeamMembers;
          setIsChecked(!isChecked);

          // Toggle user selection
          updatedTeamMembers = isChecked
            ? data?.taskMembers.filter(
                (member) =>
                  !(
                    member?.userId === userId &&
                    member?.memberType === currentTab
                  )
              )
            : [
                ...data?.taskMembers,
                {
                  userId: userId,
                  memberType: currentTab,
                  userName: res?.userName,
                  profileImage: res?.profileImage,
                },
              ];

          if (currentTab === "owner" || currentTab === "member") {
            const seenUserIds = new Set();

            updatedTeamMembers = updatedTeamMembers?.filter((member) => {
              // Only consider members of the current memberType
              if (member.memberType === currentTab) {
                // Check if the userId matches the main userId
                if (member.userId === userId) {
                  // If this userId has not been seen, keep this member
                  if (!seenUserIds.has(member.userId)) {
                    seenUserIds.add(member.userId);
                    return true; // Keep this member
                  }
                  return false; // Filter out duplicates for the same memberType
                }
                return false; // Filter out if userId does not match the main userId
              }
              return true; // Keep members of other memberTypes
            });
          }
          setData({
            ...data,
            taskMembers: updatedTeamMembers,
          });
          updateRowParameterById(data?.id, "taskMembers", updatedTeamMembers);
          const updatedData = {
            taskId: data?.id,
            taskMembers: convertToObject(updatedTeamMembers),
          };
          let response;
          if (listingType === "COMPLETED_TASKS") {
            response = await updateCompletedTaskMemberData({
              ...pageParams,
              ...updatedData,
            });
          } else if (listingType === "ALL_TASKS") {
            response = await updateTaskMemberData({
              ...pageParams,
              ...updatedData,
            });
          } else if (listingType === "PHASE_TASKS") {
            response = await updatePhaseTaskMemberData(updatedData);
          } else if (listingType === "NOTIFICATION_LIST") {
            await clearAllMemberTaskCache(updatedData);
          }

          if (response?.data?.status === "success") {
            getTaskDataAfterUpdate();

            // toast.success("Task updated successfully", {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 300,
            //   hideProgressBar: true,
            //   closeButton: false,
            // });
          }
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } finally {
        }
      }
    } else {
      setIsCompletedTaskChanged(true);
    }
  };

  const convertToObject = (data) => {
    return data?.map((item) => {
      return {
        [getTypeID(item.memberType)]: item.userId,
      };
    });
  };

  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      const isUserSelected = selectedUsers?.some(
        (member) =>
          member?.userId === userId && member?.memberType === currentTab
      );
      setIsChecked(isUserSelected);
      handleCheckboxClick();
      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      className={`w-100 border-bottom-1 task-user-filter-dropdown-menu-item task-user-filter-dropdown-menu-item-mobile ${
        isChecked ? "task-user-filter-dropdown-menu-item-mobile-select" : ""
      }`}
    >
      <div
        className="checkbox create-task-checkbox-mobile"
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span
            className="ps-1"
            onClick={(e) => {
              if (isPermitted("user-profile")) {
                e.stopPropagation();
                // window.open(
                //   `/${localStorage.getItem("company")}/user-profile?id=${
                //     res?.userId
                //   }`,
                //   "_blank" // Opens in a new tab or window
                // );
                saveLocalStorageToCookies(
                  `/user-profile?id=${res?.userId}`
                );
              }
            }}
          >
            {res?.userName}
          </span>
        </label>
      </div>
    </div>
  );
};

export default MemberUserListComponent;
