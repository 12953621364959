/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import API from "../../../services/ClientApi";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { paymentListing } from "../../../actions/paymentActions";
import { dateFormatter } from "../../../helper/dateFormatter";
import Caret from "../../../assets/caret-table-icon.svg";
import { generateHTML } from "../../../helper/generateHtml";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { ShimmerTable } from "react-shimmer-effects";

const PaymentsHistory = (props) => {
  const dispatch = useDispatch();
  const { transactionHistory, payloading } = useSelector(
    (state) => state.paymentHistory
  );
  const { applicationName } = useSelector((state) => state.text);
  const { modalContents } = useSelector((state) => state?.modalData);
  const [seeMore, setSeeMore] = useState(false);

  const verifyModalContent = findObjectByContentTitle(
    "billing",
    modalContents?.data
  );

  const getHtml = async (id) => {
    try {
      const response = await API.post("company/payment-receipt", {
        transaction: id,
      });
      if (response?.data?.status === "success") {
        generateHTML(response?.data?.data, applicationName);
      }
      // GenerateHTML();
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    dispatch(paymentListing());
  }, []);

  return (
    <div className="content-payment-info-wraper">
      <h3>
        <span>Billing</span>
      </h3>
      <div className="col-md-12 mb-3">
        <p className="role-page-description">
          {verifyModalContent?.short_description}{" "}
          <span className="see-more-txt" onClick={() => setSeeMore(true)}>
            See More
          </span>
        </p>
      </div>
      {payloading || transactionHistory === undefined ? (
        <div className="position-relative">
          <div className="billing-shimmer-table">
            <ShimmerTable row={5} />
          </div>
        </div>
      ) : transactionHistory?.length > 0 ? (
        <>
          <div className="table-module-wrap table-payment-history-wrap custom-horizental-scrollbar">
            <div className="table-responsive table-history">
              <table className="table overview-modules-table " width="100%">
                <thead>
                  <th width="25%">Current Plan</th>
                  <th width="25%">Payment Date</th>
                  <th width="25%">Payment Method</th>
                  <th width="25%">Amount</th>
                </thead>
                <tbody>
                  {transactionHistory?.map((res, id) => (
                    <tr key={id}>
                      <td>
                        <div className="text-table-wrap">
                          {res?.packageName}
                        </div>
                      </td>
                      <td>
                        <div className="text-table-wrap">
                          {dateFormatter(res?.transactionDate, 2)}
                        </div>
                      </td>
                      <td>
                        <div className="text-table-wrap">
                          {res?.paymentMethod}
                        </div>
                      </td>
                      <td>
                        <div className="text-table-wrap d-flex align-items-center justify-content-between">
                          <div>
                            {res?.currency.toString().toUpperCase()}
                            {"/- "}
                            {res?.transactionAmount
                              ? res?.transactionAmount
                              : "null"}
                          </div>

                          <div>
                            <Dropdown className="roles-drop-down roles-drop-role">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                <button className="action-btn">
                                  <img src={Caret} alt="cart-icon" />
                                </button>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => getHtml(res?.transaction)}
                                >
                                  Receipt
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex mt-2 align-items-center rounded-3 ">
            <p className="no-billing-txt">
              All of your transactions details list here to track payment
              record. For any inquiry contact our accounts department.
            </p>
          </div>
        </>
      )}
      <SeeMoreModal
        seeMore={seeMore}
        setSeeMore={setSeeMore}
        headTextOne="Billing"
        headTextTwo=""
        isDark={props.isDark}
        modalContent={verifyModalContent}
      />
    </div>
  );
};

export default PaymentsHistory;
