import React from "react";
import progressImage from "../../../assets/in-progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import criticalImage from "../../../assets/critical.svg";
import DueIcon from "../../../assets/due-task-icon.png";

const AsAssigneePoints = ({data, handleCardClick}) => {
  return (
    <div className="today-task-inner-wrap pb-3">
      <h5>
        <strong>Assigned</strong> to me <span className="ms-0">{data?.statsCount?.assigneeTotalObtainedScore}</span> points 
      </h5>
      <div className="d-flex flex-column pr-task-status-info-wrap">
        <div onClick={()=>handleCardClick("completed","Completed","assignee")} className="cursor-pointer user-select-none d-flex align-items-center justify-content-between gap-2 fex-wrap pr-completed-tasks">
          <div className="d-flex align-items-center gap-2">
            <img src={doneImage} alt="completed-icon" width={24} height={24} />
            <p className="py-0">Completed Tasks</p>
          </div>
          <h3 className="pr-completed-count">{data?.statsCount?.assigneeCompletedTasks}</h3>
        </div>
        <div className="pr-due-tasks-section">
          <div onClick={()=>handleCardClick("not-completed","Due","assignee")}  className="pr-due-tasks cursor-pointer user-select-none">
            <div className="d-flex align-items-center gap-2">
              <img src={DueIcon} alt="completed-icon" width={24} height={24} />
              <p className="py-0">Due Tasks</p>
            </div>
            <h3 className="pr-due-count">{data?.statsCount?.assigneeDueTask}</h3>
          </div>
          <div onClick={()=>handleCardClick("in-progress","In Progress","assignee")} className="cursor-pointer user-select-none pr-inprogress-tasks">
            <div className="d-flex align-items-center gap-2">
              <img
                src={progressImage}
                alt="completed-icon"
                width={24}
                height={24}
              />
              <p className="py-0">In Progress</p>
            </div>
            <h3 className="pr-inprogress-count">{data?.statsCount?.assigneeInProgress}</h3>
          </div>
          <div onClick={()=>handleCardClick("on-hold","on Hold","assignee")} className="cursor-pointer user-select-none pr-onhold-tasks">
            <div className="d-flex align-items-center gap-2">
              <img
                src={reviewImage}
                alt="completed-icon"
                width={24}
                height={24}
              />
              <p className="py-0">On Hold</p>
            </div>
            <h3 className="pr-onhold-count">{data?.statsCount?.assigneeOnHold}</h3>
          </div>
          <div onClick={()=>handleCardClick("review","Review","assignee")} className="cursor-pointer user-select-none pr-review-tasks">
            <div className="d-flex align-items-center gap-2">
              <img
                src={reviewIcon}
                alt="completed-icon"
                width={24}
                height={24}
              />
              <p className="py-0">In Review</p>
            </div>
            <h3 className="pr-review-count">{data?.statsCount?.assigneeInReview}</h3>
          </div>
          <div onClick={()=>handleCardClick("overdue","Overdue","assignee")}  className="cursor-pointer user-select-none pr-overdue-tasks">
            <div className="d-flex align-items-center gap-2">
              <img
                src={criticalImage}
                alt="completed-icon"
                width={24}
                height={24}
              />
              <p className="py-0">Overdue Tasks</p>
            </div>
            <h3 className="pr-overdue-count">{data?.statsCount?.assigneeOverDueTask}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsAssigneePoints;
