import React, { useState } from "react";
import { motion } from "framer-motion";
import { useGetCompanyPerformanceTemplateQuery } from "../../../slices/performance/performanceSlice";

export const handlePointChange = (prevPoints, field, operation) => {
  const current = Number.parseInt(prevPoints[field]) || 0;
  const newValue = operation === "add" ? current + 1 : Math.max(0, current - 1);
  return { ...prevPoints, [field]: newValue.toString().padStart(2, "0") };
};

function usePSMainService() {
  const { data: companyPerformanceTemplates, isLoading } =
    useGetCompanyPerformanceTemplateQuery();
  const [isShowAddNewKPI, setIsShowAddNewKPI] = useState(false);
  const [updateKPIId, setUpdateKPIId] = useState(null);

  const handleUpdateIsShowAddNewKPI = (value) => {
    setIsShowAddNewKPI(value);
  };

  const handleSetUpdateKPIId = (value) => {
    setUpdateKPIId(value);
  };

  return {
    companyPerformanceTemplates,
    isLoading,
    MotionWrapper: motion.div,
    animationVariants: {
      hidden: { opacity: 0, height: 0, transition: { duration: 0.3 } },
      visible: { opacity: 1, height: "auto", transition: { duration: 0.3 } },
    },
    isShowAddNewKPI,
    handleUpdateIsShowAddNewKPI,
    updateKPIId,
    handleSetUpdateKPIId,
  };
}

export default usePSMainService;
