// Constants for timeline stats
export const TIMELINE_STATS_REQUEST = "TIMELINE_STATS_REQUEST";
export const TIMELINE_STATS_SUCCESS = "TIMELINE_STATS_SUCCESS";
export const TIMELINE_STATS_FAIL = "TIMELINE_STATS_FAIL";

// Timeline active projects
export const TIMELINE_ACTIVE_PROJECTS_REQUEST =
  "TIMELINE_ACTIVE_PROJECTS_REQUEST";
export const TIMELINE_ACTIVE_PROJECTS_SUCCESS =
  "TIMELINE_ACTIVE_PROJECTS_SUCCESS";
export const TIMELINE_ACTIVE_PROJECTS_FAIL = "TIMELINE_ACTIVE_PROJECTS_FAIL";

// Timeline ongoing tasks
export const TIMELINE_ONGOING_TASK_REQUEST = "TIMELINE_ONGOING_TASK_REQUEST";
export const TIMELINE_ONGOING_TASK_SUCCESS = "TIMELINE_ONGOING_TASK_SUCCESS";
export const TIMELINE_ONGOING_TASK_FAIL = "TIMELINE_ONGOING_TASK_FAIL";

// Update active project
export const TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST =
  "TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST";
export const TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS =
  "TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS";
export const TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL =
  "TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL";

// update ongoing tasks
export const TIMELINE_ONGOING_TASK_UPDATE_REQUEST =
  "TIMELINE_ONGOING_TASK_UPDATE_REQUEST";
export const TIMELINE_ONGOING_TASK_UPDATE_SUCCESS =
  "TIMELINE_ONGOING_TASK_UPDATE_SUCCESS";
export const TIMELINE_ONGOING_TASK_UPDATE_FAIL =
  "TIMELINE_ONGOING_TASK_UPDATE_FAIL";
