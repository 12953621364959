import React from "react";
import "./seeMoreModal.css";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";

const SeeMoreModal = (props) => {
  return (
    <Modal
      show={props.seeMore}
      onHide={() => props.setSeeMore(false)}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className=" position-relative update-modal-header see-modal-header" >
        <h6>
          {props?.headTextOne}{" "}
          <span className="see-more-header-span">{props?.headTextTwo}</span>
        </h6>
        <button
          className="custom-modal-close-btn position-absolute top-0 end-0"
          onClick={() => props.setSeeMore(false)}
        >
          <Icon icon="maki:cross" />
        </button>
      </Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="see-more-body-wrap">
          <p>{props?.modalContent?.short_description}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SeeMoreModal;
