import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const DropdownProgress = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const buttonRef = useRef();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    const parentRect = buttonRef.current.getBoundingClientRect();
    setXValue(parentRect.x);
    setYValue(parentRect.y);
    setShowProgress(true);
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      // Click occurred outside the DropButton component
      setShowProgress(false);
    }
  };

  const handleWindowResize = () => {
    if (props.showMore && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setXValue(parentRect.x);
      setYValue(parentRect.y);
    }
  };

  const handleScroll = () => {
    if (showProgress && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setYValue(parentRect.y + window.scrollY);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleScroll); // Remove scroll event listener on cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Re-run effect when showMore changes

  const getPerformanceNumber = () => {
    if (props?.data?.projectStatus?.name?.toLowerCase() === "completed") {
      return 100;
    }
    if (props?.data?.projectStatus?.name?.toLowerCase() === "on hold") {
      return 66;
    }
    if (
      props?.data?.projectStatus?.name?.toLowerCase() === "submit for review"
    ) {
      return 66;
    }
    if (props?.data?.projectStatus?.name?.toLowerCase() === "in progress") {
      return 33;
    }
    if (props?.data?.projectStatus?.name?.toLowerCase() === "not started") {
      return 0;
    }
    if (props?.data?.projectStatus?.name?.toLowerCase() === "deleted") {
      return 0;
    }
    if (props?.data?.projectStatus?.length === 0) {
      return 0;
    }
  };

  const getStatusColor = () => {
    const status = props?.data?.projectStatus?.name?.toLowerCase();

    switch (status) {
      case "completed":
        return "progresscolor-2dbf64"; // Green or any code representing completion
      case "on hold":
        return "progresscolor-e8910d"; // Yellow or similar
      case "submit for review":
        return "progresscolor-eba900"; // Yellow
      case "in progress":
        return "progresscolor-2098d1"; // Blue or in-progress style
      case "not started":
        return "progresscolor-ff0000"; // Grey or neutral
      default:
        // Handles null, undefined, or unexpected status values
        return "progresscolor-2098d1";
    }
  };

  return (
    <>
      <div
        className="progress-timeline"
        onMouseEnter={(e) => buttonClickHandler(e)}
        ref={buttonRef}
      >
        <div
          className={`progress-bar-timeline ${getStatusColor()}`}
          role="progressbar"
          aria-valuenow="70"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${getPerformanceNumber()}%` }}
        >
          <span className="sr-only">
            {props.data.projectProgress}% Complete
          </span>{" "}
        </div>
      </div>
      {showProgress && (
        <Portal>
          <div
            style={{
              position: "absolute",
              left: `${xValue}px`,
              top: `${yValue + 20}px`,
              listStyle: "none",
              background: "#fff", // Set background color
              border: "1px solid var(--divider-color",
              fontSize: "14px",
              fontWeight: 300,
            }}
            data-theme={props?.isDark}
            className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
          >
            {props.dropdownRenderer(props?.data)}
          </div>
        </Portal>
      )}
    </>
  );
};

export default DropdownProgress;
