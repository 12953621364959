import criticalImage from "../../../../assets/critical.svg";
import moduleNameImage from "../../../../assets/modules-name.svg";
import avatar from "../../../../assets/avatar.svg";
import { MultiSelect } from "react-multi-select-component";
import React, { useEffect, useRef, useState } from "react";
import openTask from "../../../../assets/open-task.svg";
import progressImage from "../../../../assets/progress.svg";
import doneImage from "../../../../assets/completed-icon.png";
import reviewImage from "../../../../assets/on-hold.png";
import reviewIcon from "../../../../assets/submit-for-review-icon.png";
import highestImage from "../../../../assets/highest.svg";
import mediumImage from "../../../../assets/medium.svg";
import lowImage from "../../../../assets/low.svg";

import { Icon } from "@iconify/react";
import { v4 as uuidv4 } from "uuid";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { createPortal } from "react-dom";
import getProjectIconById from "../../../../helper/projectIconGenerator";
import { Link } from "react-router-dom";
import { isPermitted } from "../../../../helper/isPermitted";
const Portal = (props) => {
  return createPortal(props.children, document.body);
};
export const color = [
  "#ece1d3",
  "#fffff0",
  "#def2ef",
  "#fff7c2",
  "#ede7de",
  "#fffce6",
  "#fcebeb",
  "#d8def0",
];
export const taskData = [
  {
    id: 1,
    taskName: "Task 1",
    situation: "Critical",
    progressbarValue: 80,
    taskModule: "Module 1",
    listofTeamMember: [
      "John Doe",
      "Jane Smith",
      "Alice Johnson",
      "Adam Lee",
      "Sarah Brown",
    ],
    status: "In Progress",
    updatedDate: "2024-02-19",
  },
  {
    id: 2,
    taskName: "Task 2",
    situation: "Highest",
    progressbarValue: 80,
    taskModule: "Module 1",
    listofTeamMember: ["John Doe"],
    status: "Completed",
    updatedDate: "2024-02-19",
  },
  {
    id: 3,
    taskName: "Task 1",
    situation: "Medium",
    progressbarValue: 80,
    taskModule: "Module 1",
    listofTeamMember: ["John Doe", "Jane Smith", "Alice Johnson"],
    status: "On Hold",
    updatedDate: "2024-02-19",
  },
  {
    id: 4,
    taskName: "Task 4",
    situation: "Low",
    progressbarValue: 80,
    taskModule: "Module 1",
    listofTeamMember: ["John Doe"],
    status: "In Progress",
    updatedDate: "2024-02-19",
  },
  {
    id: 5,
    taskName: "Task 5",
    situation: "Low",
    progressbarValue: 80,
    taskModule: "Module 1",
    listofTeamMember: ["John Doe"],
    status: "In Progress",
    updatedDate: "2024-02-19",
  },
  // Add more tasks as needed
];

export const PerformanceCellRenderer = (props) => {
  const progressbarValue = props?.data?.taskProgress;
  const progressPercentage = `${progressbarValue}%`;
  const [showProgress, setShowProgress] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const buttonRef = useRef();

  const progressBarStyle = { width: progressPercentage };
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const uniqueId = uuidv4();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    const parentRect = buttonRef.current.getBoundingClientRect();
    setXValue(parentRect.x);
    setYValue(parentRect.y);
    setShowProgress(true);
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      // Click occurred outside the DropButton component
      setShowProgress(false);
    }
  };

  const handleWindowResize = () => {
    if (props.showMore && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setXValue(parentRect.x);
      setYValue(parentRect.y);
    }
  };

  const handleScroll = () => {
    if (showProgress && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setYValue(parentRect.y + window.scrollY);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleScroll); // Remove scroll event listener on cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Re-run effect when showMore changes

  const getPerformanceNumber = () => {
    if (props?.data?.taskStatus?.statusName?.toLowerCase() === "completed") {
      return 100;
    }
    if (props?.data?.taskStatus?.statusName?.toLowerCase() === "on hold") {
      return 66;
    }
    if (
      props?.data?.taskStatus?.statusName?.toLowerCase() === "submit for review"
    ) {
      return 66;
    }
    if (props?.data?.taskStatus?.statusName?.toLowerCase() === "in progress") {
      return 33;
    }
    if (props?.data?.taskStatus?.statusName?.toLowerCase() === "not started") {
      return 0;
    }
    if (
      props?.data?.taskStatus === null ||
      props?.data?.taskStatus?.length === 0
    ) {
      return 0;
    }
  };

  const getStatusColor = () => {
    const status = props?.data?.taskStatus?.statusName?.toLowerCase();

    switch (status) {
      case "completed":
        return "progresscolor-2dbf64"; // Green or any code representing completion
      case "on hold":
        return "progresscolor-e8910d"; // Yellow or similar
      case "submit for review":
        return "progresscolor-eba900"; // Yellow
      case "in progress":
        return "progresscolor-2098d1"; // Blue or in-progress style
      case "not started":
        return "progresscolor-ff0000"; // Grey or neutral
      default:
        // Handles null, undefined, or unexpected status values
        return "progresscolor-2098d1";
    }
  };

  // See if task is overdue. If so, then give progress bar red color
  const isTaskOverdue = () => {
    let currentDate = new Date();
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    );
    let endDate = new Date(props?.data?.taskDeadline);
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      endDate.getHours(),
      endDate.getMinutes()
    );
    if (
      endDate < currentDate &&
      props?.data?.taskStatus?.statusName?.toLowerCase() !== "completed" &&
      props?.data?.taskDeadline
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="position-relative d-flex align-items-center justify-content-start gap-3">
      <div
        className="progress-timeline"
        onMouseEnter={(e) => buttonClickHandler(e)}
        ref={buttonRef}
        style={{
          maxWidth: "170px",
        }}
      >
        <div
          className={`progress-bar-timeline ${
            isTaskOverdue() ? "progress-bar-timeline-overdue" : getStatusColor()
          }`}
          role="progressbar"
          aria-valuenow="70"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${getPerformanceNumber()}%` }}
        >
          <span className="sr-only">{progressPercentage}% Complete</span>{" "}
        </div>
      </div>
      {showProgress &&
        props?.data?.taskStatus !== null &&
        !Array.isArray(props?.data?.taskStatus) && (
          <Portal>
            <div
              style={{
                position: "absolute",
                left: `${xValue}px`,
                top: `${yValue + 20}px`,
                listStyle: "none",
                background: "#fff", // Set background color
                border: "1px solid #ccc",
                fontSize: "14px",
                fontWeight: 300,
              }}
              className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
            >
              {props.dropdownRenderer(props?.data)}
            </div>
          </Portal>
        )}
      {/* {!props.noName && (
        <>
          <div
            class="module-task-image position-relative d-flex align-items-center justify-content-start gap-3"
            onMouseEnter={(event) => {
              setShowTooltip(true);
              const rect = event.target.getBoundingClientRect();
              setTooltipPosition({ x: rect.x, y: rect.y });
            }}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {" "}
            <Link
              to={`/${localStorage.getItem("company")}/project?pid=${
                props?.data?.project?.id
              }&pname=${props?.data?.project?.projectName}`}
            >
              <img
                src={
                  getProjectIconById(props?.data?.project?.projectIcon)
                    ? getProjectIconById(props?.data?.project?.projectIcon)
                    : props?.data?.project?.projectIcon
                }
                className={`${
                  !getProjectIconById(props?.data?.project?.projectIcon) &&
                  "project-icon-circle"
                }`}
                alt="Module Name"
              />{" "}
            </Link>
            {props?.data?.project?.projectName && (
              <p className="m-0 p-0">
                {props?.data?.project?.projectName?.length >= 13
                  ? props?.data?.project?.projectName?.slice(0, 13) + "..."
                  : props?.data?.project?.projectName}
              </p>
            )}
            {showTooltip && (
              <Portal>
                <ProjectNameTooltip
                  projectName={props?.data?.project?.projectName}
                  position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
                />
              </Portal>
            )}
          </div>
          {!props?.data?.project?.projectName && (
            <p className="m-0 p-0">Select Project</p>
          )}
        </>
      )} */}
    </div>
  );
};

export const ProjectNameTooltip = ({ projectName, position }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: position.y - 40,
        left: position.x - 50,
      }}
      className="custom-tooltip"
    >
      <p className="custom-tooltip-rolename">{projectName}</p>
    </div>
  );
};

export const TasksPerformanceDropdownRenderer = (params) => {
  return (
    <div className="task-drop-renderer">
      {params?.taskStatus?.statusName?.toLowerCase() === "completed" && (
        <div class="mb-0 task-timeline-progress done-task-timeline-progress">
          {" "}
          <img src={doneImage} alt="Progress" /> Completed
        </div>
      )}
      {params?.taskStatus?.statusName?.toLowerCase() === "not started" && (
        <div class="mb-0 task-timeline-progress critical-task-timeline-progress">
          {" "}
          <img src={criticalImage} alt="Progress" /> Not Started
        </div>
      )}
      {params?.taskStatus?.statusName?.toLowerCase() === "in progress" && (
        <div class="mb-0 task-timeline-progress progress-task-timeline-progress">
          {" "}
          <img src={progressImage} alt="Submit For Review" /> In Progress
        </div>
      )}
      {params?.taskStatus?.statusName?.toLowerCase() === "on hold" && (
        <div class="mb-0 task-timeline-progress review-task-timeline-progress">
          {" "}
          <img src={reviewImage} alt="Submit For Review" /> On Hold
        </div>
      )}
      {params?.taskStatus?.statusName?.toLowerCase() ===
        "submit for review" && (
        <div class="mb-0 task-timeline-progress review-icon-timeline-progress">
          {" "}
          <img src={reviewIcon} alt="Submit For Review" /> Submit For Review
        </div>
      )}
    </div>
  );
};
// You can use the same approach for defining columns and rendering cells as in your existing code

export const OpenTaskCellRenderer = ({ showModal }) => {
  return (
    <div
      onClick={() => {
        showModal();
      }}
    >
      <img src={openTask} alt="Open Task" width={100} height={100} />
    </div>
  );
};

export const SituationCellRenderer = (params) => {
  const isTaskUpdatePermitted =
    isPermitted("task/update") ||
    isPermitted("project/pm-update-project-phase");

  const situation = params?.data?.taskPriority;

  if (situation?.priorityName?.toLowerCase() == "critical") {
    return (
      <div class="task-project-status critical-task-project-status">
        {" "}
        <img src={criticalImage} alt="Critical" /> {situation?.priorityName}
      </div>
    );
  }
  if (situation?.priorityName?.toLowerCase() == "highest") {
    return (
      <div class="task-project-status highest-task-project-status">
        {" "}
        <img src={highestImage} alt="Highest" /> {situation?.priorityName}
      </div>
    );
  }
  if (situation?.priorityName?.toLowerCase() == "medium") {
    return (
      <div class="task-project-status medium-task-project-status">
        {" "}
        <img src={mediumImage} alt="Medium" /> {situation?.priorityName}
      </div>
    );
  }
  if (situation?.priorityName?.toLowerCase() == "low") {
    return (
      <div class="task-project-status low-task-project-status">
        {" "}
        <img src={lowImage} alt="Highest" /> {situation?.priorityName}
      </div>
    );
  } else
    return (
      <div class="project-status" style={{ color: "#333" }}>
        {" "}
        {isTaskUpdatePermitted ? "Set Priority" : "No Priority Set"}
      </div>
    );
};

export const TaskModuleCellRenderer = () => {
  return (
    <div class="module-task-image">
      {" "}
      <img
        data-tooltip-id="my-tooltip"
        data-tooltip-content={"Project Name"}
        src={moduleNameImage}
        alt="Module Name"
      />{" "}
    </div>
  );
};

export const ListofTaskMemberCellRenderer = (params) => {
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];

  // Filter members with memberType 'member' and select the first one
  const validMembers =
    params?.data?.taskMembers?.filter((user) => user.memberType === "member") ||
    [];

  const selectedMember = validMembers[0]; // Pick the first valid member

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();

    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    return moduleCategory?.profileImage ? (
      <li
        key={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        <img
          className="rounded-circle w-100 h-100"
          src={moduleCategory?.profileImage}
          alt="profile-img"
        />
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </li>
    ) : (
      <li
        key={id}
        style={{
          border: "1px solid " + colors[id % colors.length],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        {moduleCategory?.userName?.charAt(0).toUpperCase() +
          moduleCategory?.userName?.charAt(1).toUpperCase()}
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </li>
    );
  };

  return (
    <div
      className={`teams-member-listing-wap teams-member-listing-wap-profile ${
        params.isTaskDetail ? "no-task-detail-user-list-margin" : ""
      }`}
    >
      <ul>
        {selectedMember ? (
          <div className="d-flex align-items-center gap-1">
            <UserImageCircle
              colors={colors}
              moduleCategory={selectedMember}
              id={0}
            />
            <span>{selectedMember?.userName}</span>
          </div>
        ) : (
          <p className="text-center">Select Team Members</p>
        )}
      </ul>
    </div>
  );
};

export const ListofTeamMemberCellRenderer = (params) => {
  const listofTeamMember = !params?.data?.projectMembers
    ? ["John Doe", "Jane Smith", "Alice Johnson", "Adam Lee", "Sarah Brown"]
    : Array.from(
        new Set(params.data.projectMembers.map((user) => user.userName))
      );
  const [showMore, setShowMore] = useState(false);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];

  const firstFourCategories = listofTeamMember?.slice(0, 2);

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return (
      <li
        key={id}
        style={{
          border: "1px solid " + colors[id % colors.length],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        {moduleCategory?.charAt(0).toUpperCase() +
          moduleCategory?.charAt(1).toUpperCase()}
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </li>
    );
  };

  return (
    <div
      class={`teams-member-listing-wap teams-member-listing-wap-profile ${
        params.isTaskDetail ? "no-task-detail-user-list-margin" : ""
      }`}
    >
      <ul>
        {showMore
          ? listofTeamMember?.map((moduleCategory, id) => {
              return (
                <UserImageCircle
                  colors={colors}
                  moduleCategory={moduleCategory}
                  id={id}
                />
              );
            })
          : firstFourCategories?.map((moduleCategory, id) => {
              return (
                <UserImageCircle
                  colors={colors}
                  moduleCategory={moduleCategory}
                  id={id}
                />
              );
            })}
        {listofTeamMember?.length > 2 && (
          <li className="teams-member-listing-wap-more-btn">
            {`+${listofTeamMember?.length - 2}`}
          </li>
        )}
      </ul>
    </div>
  );
};

export const ListOfTeamMemberTooltip = ({ memberName, position }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: position.y - 40,
        left: position.x - 50,
      }}
      className="custom-tooltip"
    >
      <p className="custom-tooltip-rolename">
        {memberName?.userName || memberName}
      </p>
    </div>
  );
};

export const StatusCellRenderer = (params) => {
  const { status } = params.data;
  if (status?.toLowerCase() == "in progress") {
    return (
      <div class="task-timeline-progress progress-task-timeline-progress">
        {" "}
        <img src={progressImage} alt="Progress" /> {status}{" "}
      </div>
    );
  } else if (status?.toLowerCase() == "completed") {
    return (
      <div class="task-timeline-progress done-task-timeline-progress">
        {" "}
        <img src={doneImage} alt="Done" /> {status}{" "}
      </div>
    );
  } else if (status?.toLowerCase() == "on hold") {
    return (
      <div class="task-timeline-progress review-task-timeline-progress">
        {" "}
        <img src={reviewImage} alt="Submit For Review" /> {status}{" "}
      </div>
    );
  } else if (status?.toLowerCase() == "not started") {
    return (
      <div class="task-timeline-progress review-task-timeline-progress">
        {" "}
        <img src={criticalImage} alt="Submit For Review" /> {status}{" "}
      </div>
    );
  } else
    return (
      <div class="task-timeline-progress progress-task-timeline-progress">
        {" "}
        <img src={progressImage} alt="Progress" /> In progress{" "}
      </div>
    );
};

export const UpdatedDateCellRenderer = (params) => {
  const { updatedDate } = params.data;
  return <div className="task-listing-data">Updated : Dec 28 2023</div>;
};

export const mockTaskData = () => {
  return taskData.map((task) => ({
    id: task.id,
    taskName: task.taskName,
    situation: task.situation,
    progressbarValue: task.progressbarValue,
    listofTeamMember: task.listofTeamMember,
    status: task.status,
    updatedDate: task.updatedDate,
  }));
};

// Import MultiSelect from the appropriate library

export const ListofTeamMemberEditor = (props) => {
  const { value, data, api } = props;
  const [selectedOptions, setSelectedOptions] = useState(value);

  const handleMultiSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleSave = () => {
    api.stopEditing(); // Notify ag-grid that editing has finished
    // Update the data in the grid
    const updatedData = { ...data, listofTeamMember: selectedOptions };
    api.applyTransaction({ update: [updatedData] });
  };

  return (
    <MultiSelect
      options={data?.listofTeamMember?.map((option, index) => ({
        label: option,
        value: option,
      }))}
      value={selectedOptions || []}
      onChange={handleMultiSelectChange}
      valueRenderer={(selected) => (
        <>
          {selected.length > 0 ? (
            <div class="ms-2 avatar-image-wrap">
              {selected?.map((item, index) => (
                <span key={index}>
                  <img src={avatar} alt="Avatar" />{" "}
                </span>
              ))}
              <button> +1 </button>
            </div>
          ) : (
            <p className="multiselect-placeholder">Select Team Members</p>
          )}
        </>
      )}
      labelledBy="Select Team Members"
      className="multi-select tasks-team-listing-multiselect"
    />
  );
};
