import React from "react";
import {
  calculateDurationAgo,
  formatTimeAgo,
} from "../../../helper/timeAgoFormatter";
import { calculateWeeksAgo } from "../../../helper/chatHelper";

function UsersListCard({
  key,
  setSelectedChat,
  selectedChat,
  data,
  isSelected,
  typing,
  roomChangeLoad,
  setRoomChangeLoad,
  setRoomId,
  setDays,
  setOnline,
  setAway,
  setDayState,
  setUnreadCount,
}) {
  return (
    <div
      onClick={() => {
        if (data?.unseenMessageCount > 0) {
          localStorage?.setItem("uc", true);
        }
        setDayState(
          data?.lastMessage_sent_at &&
            calculateWeeksAgo(data.lastMessage_sent_at) > 7
            ? calculateWeeksAgo(data.lastMessage_sent_at)
            : 0
        );
        setSelectedChat(data);
        setRoomId(data?.room_id);
        setRoomChangeLoad(true);
        localStorage?.setItem("rid", data?.room_id);
        // Handle user chat status
        if (data?.user_chat_status === "online") {
          setOnline(true);
          setAway(false);
        } else if (data?.user_chat_status === "away") {
          setOnline(false);
          setAway(true);
        } else {
          setOnline(false);
          setAway(false);
        }
      }}
      className={
        isSelected ? "user-list-card-main-selected" : "user-list-card-main"
      }
    >
      {/* image */}
      {data?.user_profile_image ? (
        <img
          className="user-list-card-user-image"
          src={data?.user_profile_image}
          alt="User Profile"
        />
      ) : (
        <div
          className={
            isSelected
              ? "user-list-card-user-image-name-selected"
              : "user-list-card-user-image-name"
          }
        >
          {data?.user_name[0]?.toUpperCase()}
          {data?.user_name?.split(" ")[1]
            ? data?.user_name?.split(" ")[1][0]?.toUpperCase()
            : data?.user_name[1]?.toUpperCase()}
        </div>
      )}

      {/* name and last message */}
      <div
        style={{ flex: 1 }}
        className="d-flex align-items-lg-end flex-lg-row flex-column justify-content-between"
      >
        <div className="user-list-card-user-name">
          <h3 className={isSelected ? "card-text-white" : ""}>
            {data?.user_name}
          </h3>
          {typing ? (
            <p className={isSelected ? "card-text-white" : ""}>
              {`${data?.user_name} is typing...`}
            </p>
          ) : data.user_chat_status === "away" ? (
            <p
              className={`mt-1 d-flex align-items-center ${
                isSelected ? "card-text-white" : ""
              }`}
            >
              <span className="me-1 away-status-chat-profile"></span>Away
            </p>
          ) : data.user_chat_status === "online" ? (
            <p
              className={`mt-1 d-flex align-items-center ${
                isSelected ? "card-text-white" : ""
              }`}
            >
              <span className="me-1 online-status-chat-profile"></span>Online
            </p>
          ) : data?.user_lastSeen ? (
            <p className={`mt-1 ${isSelected ? "card-text-white" : ""}`}>
              Last seen {calculateDurationAgo(data.user_lastSeen)}
            </p>
          ) : null}
        </div>
        {/* last message time */}
        <div className="d-flex flex-column align-items-lg-center align-items-end ">
          {data?.unseenMessageCount > 0 && !isSelected && (
            <p className="unseen-message-count-modal mb-1 user-list-card-user-name-p">
              {data?.unseenMessageCount}
            </p>
          )}
          {data?.lastMessage_sent_at && (
            <p
              className={`chat-seen-at user-list-card-user-name-p ${
                isSelected
                  ? "d-flex align-items-center  gap-1 card-text-white text-size-weight"
                  : "d-flex align-items-center  gap-1 text-size-weight"
              }`}
            >
              {formatTimeAgo(data?.lastMessage_sent_at)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UsersListCard;
