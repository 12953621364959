import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import "./style.css";
import TimePicker from "../components/TimePicker";
import useEditWorkingHoursModal from "../services/useEditWorkingHoursModal";

const EditWorkingHoursModal = ({ data, show, isDark, handleCloseModal }) => {
  const {
    validationSchema,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    setEndTimeValue,
    handleOnSubmit,
    initialValues,
    daysList,
    isEditWorkingHoursLoading,
  } = useEditWorkingHoursModal({ handleCloseModal, data });

  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="create-task-modal-main"
    >
      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          Working <span>Hours</span>
        </p>
        <button className="custom-close-button" onClick={handleCloseModal}>
          <Icon icon="maki:cross" />
        </button>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleOnSubmit(values);
        }}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <Modal.Body>
              <div className="d-flex flex-column gap-4">
                {/* Shift Title */}
                <div className="set-hour-title-section">
                  <label htmlFor="shiftTitle" className="set-hour-label">
                    Shift Title
                  </label>
                  <Field
                    type="text"
                    id="shiftTitle"
                    name="shiftTitle"
                    className={`set-hour-input form-control ${
                      touched.shiftTitle && errors.shiftTitle
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter shift title"
                  />
                  {touched.shiftTitle && errors.shiftTitle && (
                    <div className="invalid-feedback">{errors.shiftTitle}</div>
                  )}
                </div>

                {/* Select Days */}
                <div className="set-hour-days-section">
                  <label className="set-hour-label">Select Days</label>
                  <div className="set-hour-days-container">
                    {daysList.map((day, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => {
                          const newDays = Array.isArray(values.days)
                            ? values.days.includes(day)
                              ? values.days.filter((d) => d !== day)
                              : [...values.days, day]
                            : [day]; // Ensure it's an array
                          setFieldValue("days", newDays);
                        }}
                        className={`set-hour-day-button ${
                          values.days && values.days.includes(day)
                            ? "set-hour-day-selected"
                            : ""
                        }`}
                      >
                        {day}
                      </button>
                    ))}
                  </div>
                  {touched.days && errors.days && (
                    <div className="invalid-feedback-days">{errors.days}</div>
                  )}
                </div>

                {/* Timing Selection */}
                <div className="set-hour-timing-section">
                  <label className="set-hour-label">Select Timing</label>
                  <div className="set-hour-timing-container">
                    {/* Start Time */}
                    <div className="set-hour-time">
                      <label
                        htmlFor="startTime"
                        className="set-hour-time-label"
                      >
                        Start Time
                      </label>
                      <TimePicker
                        isDark={isDark}
                        time={startTimeValue} // Pass initial time
                        setTime={(time) => {
                          setStartTimeValue(time);
                          setFieldValue("startTime", time);
                        }}
                        placeholder="Start time"
                        timeDifference={15}
                        maxValue={endTimeValue}
                        isInvalid={touched.startTime && errors.startTime}
                      />
                      {touched.startTime && errors.startTime && (
                        <div className="invalid-field">{errors.startTime}</div>
                      )}
                    </div>
                    {/* End Time */}
                    <div className="set-hour-time">
                      <label htmlFor="endTime" className="set-hour-time-label">
                        End Time
                      </label>
                      <TimePicker
                        isDark={isDark}
                        time={endTimeValue}
                        setTime={(time) => {
                          setEndTimeValue(time);
                          setFieldValue("endTime", time);
                        }}
                        placeholder="End time"
                        timeDifference={15}
                        minValue={startTimeValue}
                        isInvalid={touched.endTime && errors.endTime}
                      />
                      {touched.endTime && errors.endTime && (
                        <div className="invalid-field">{errors.endTime}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="edit-profile-modal-footer">
              <button
                className="edit-profile-modal-footer-button-2"
                type="button"
                onClick={handleCloseModal}
                disabled={isEditWorkingHoursLoading}
              >
                Cancel
              </button>
              <button
                className="edit-profile-modal-footer-button-1"
                type="submit"
                disabled={isEditWorkingHoursLoading}
              >
                Update{" "}
                {isEditWorkingHoursLoading && (
                  <Spinner size="sm" className="ms-2" />
                )}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditWorkingHoursModal;
