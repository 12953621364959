import React from "react";
import usePSMainService from "../services/usePSMainService";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import PointInput from "./PointInput";
import { Tooltip } from "react-tooltip";

function TaskOwnerCardMain({
  formik,
  isExpanded,
  toggleExpanded,
  MotionWrapper,
  animationVariants,
  removeError,
}) {
  return (
    <div className="card main-card mt-1 border-0">
      <Tooltip id="pskpi-canvas-owner-card-my-tooltip" />
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h2 className="section-title mb-0">Points for Task Owner</h2>
            <button className="info-icon ms-2">
              <IoIosInformationCircleOutline
                size={20}
                data-tooltip-id="pskpi-canvas-owner-card-my-tooltip"
                data-tooltip-content="Creator or owner of a task getting points if he will manage to get that task done by his team/employee"
              />
            </button>
          </div>
          <button
            className="btn btn-link p-0 chevron-btn"
            onClick={toggleExpanded}
          >
            {isExpanded ? (
              <IoIosArrowUp size={22} color="var(--font-color)" />
            ) : (
              <IoIosArrowDown size={22} color="var(--font-color)" />
            )}
          </button>
        </div>
        <p className="text-secondary description-text">
          The person responsible for completing the task will earn or lose
          points based on timely completion or delays.
        </p>
        {isExpanded && (
          <MotionWrapper
            initial="hidden"
            animate={isExpanded ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <div className="row g-4 pt-3">
              <div className="col-md-4">
                <PointInput
                  label="Task Points (Maximum points for a task)"
                  value={formik.values.taskOwnerPoints.maxPoints}
                  onChange={(value) => {
                    formik.setFieldValue("taskOwnerPoints.maxPoints", value);
                    removeError("taskOwnerPoints.maxPoints");
                  }}
                  error={formik.errors.taskOwnerPoints?.maxPoints}
                  isZeroValue={false}
                  className="total-point-unique-layout"
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Task completion on time/before given time"
                  value={formik.values.taskOwnerPoints.onTimeCompletion}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskOwnerPoints.onTimeCompletion",
                      value
                    );
                    removeError("taskOwnerPoints.onTimeCompletion");
                  }}
                  error={formik.errors.taskOwnerPoints?.onTimeCompletion}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Task completion after due date"
                  value={formik.values.taskOwnerPoints.lateCompletion}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskOwnerPoints.lateCompletion",
                      value
                    );
                    removeError("taskOwnerPoints.lateCompletion");
                  }}
                  error={formik.errors.taskOwnerPoints?.lateCompletion}
                />
              </div>
            </div>

            <h2 className="section-title-second mt-4 mb-4">
              Points for Attendance
            </h2>
            <div className="row g-4">
              <div className="col-md-4">
                <PointInput
                  label="Daily Attandance Points"
                  value={formik.values.taskOwnerPoints.dailyAttendancePoints}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskOwnerPoints.dailyAttendancePoints",
                      value
                    );
                    removeError("taskOwnerPoints.dailyAttendancePoints");
                  }}
                  error={formik.errors.taskOwnerPoints?.dailyAttendancePoints}
                  isZeroValue={false}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="On time (check-in & check-out)"
                  value={formik.values.taskOwnerPoints.onTimeAttendance}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskOwnerPoints.onTimeAttendance",
                      value
                    );
                    removeError("taskOwnerPoints.onTimeAttendance");
                  }}
                  error={formik.errors.taskOwnerPoints?.onTimeAttendance}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Late check in"
                  value={formik.values.taskOwnerPoints.lateCheckIn}
                  onChange={(value) => {
                    formik.setFieldValue("taskOwnerPoints.lateCheckIn", value);
                    removeError("taskOwnerPoints.lateCheckIn");
                  }}
                  error={formik.errors.taskOwnerPoints?.lateCheckIn}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Early check out"
                  value={formik.values.taskOwnerPoints.earlyCheckOut}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskOwnerPoints.earlyCheckOut",
                      value
                    );
                    removeError("taskOwnerPoints.earlyCheckOut");
                  }}
                  error={formik.errors.taskOwnerPoints?.earlyCheckOut}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Leave"
                  value={formik.values.taskOwnerPoints.leave}
                  onChange={(value) => {
                    formik.setFieldValue("taskOwnerPoints.leave", value);
                    removeError("taskOwnerPoints.leave");
                  }}
                  error={formik.errors.taskOwnerPoints?.leave}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Absent"
                  value={formik.values.taskOwnerPoints.absent}
                  onChange={(value) => {
                    formik.setFieldValue("taskOwnerPoints.absent", value);
                    removeError("taskOwnerPoints.absent");
                  }}
                  error={formik.errors.taskOwnerPoints?.absent}
                />
              </div>
            </div>
          </MotionWrapper>
        )}
      </div>
    </div>
  );
}

export default TaskOwnerCardMain;
