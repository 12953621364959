/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { ListOfTeamMemberTooltip } from "./utils/data";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInTaskOne } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import { sortCheckedMembersOnTop } from "../../../helper/sortCheckedMembersOnTop";
import { useClearAllMemberTaskCacheMutation } from "../../../slices/tasks/tasksSlice";
import { toast } from "react-toastify";
import { useUpdateCompletedTaskMemberDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SearchIcon from "../../../assets/search-icon.png";
import { IoIosArrowDown } from "react-icons/io";
import { useIsMobile } from "../../../helper/windowHelper";
import MobileTaskDetailMemberFilter from "./MobileTaskDetailMemberFilter";
import { BottomSheet } from "react-spring-bottom-sheet";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

function TaskUserFilter({
  data,
  setData,
  props,
  projectUserList,
  taskListToFilters,
  projectTypes,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  userId,
  userProfile,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  allowAssigntoText,
}) {
  // Type id based on stypeName
  const getTypeID = (typeName) => {
    const typeObj = projectTypes?.find((type) => type.typeName === typeName);
    return typeObj ? typeObj.id : null;
  };
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const [showFilterDropDown, setshowFilterDropDown] = useState(false);
  const [currentTab, setCurrentTab] = useState(3);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const [autoFocus, setAutoFocus] = useState(true);
  const { isMobile } = useIsMobile();

  const userListRef = useRef(null);
  const sectionRef = useRef();

  useEffect(() => {
    if (showFilterDropDown) {
      const rect = sectionRef?.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
  }, [showFilterDropDown]);

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return moduleCategory?.profileImage ? (
      <div
        key={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative filter-top-user-info-wrap"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        <img
          className="rounded-circle w-100 h-100"
          src={moduleCategory?.profileImage}
          alt="profile-img"
        />
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </div>
    ) : (
      <div
        key={id}
        style={{
          border: "1px solid " + colors[id % colors.length],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative filter-top-user-info-wrap"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        {moduleCategory?.userName?.charAt(0).toUpperCase() +
          moduleCategory?.userName?.charAt(1).toUpperCase()}
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory?.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </div>
    );
  };

  const AdditionalUserCircle = ({ colors, moduleCategory }) => {
    return (
      <div
        style={{
          border: "1px solid " + colors[0],
        }}
        className="position-relative filter-top-user-info-wrap"
      >
        {moduleCategory?.charAt(0).toUpperCase() +
          moduleCategory?.charAt(1).toUpperCase()}
      </div>
    );
  };

  // Modal search functionality
  const [search, setSearch] = useState("");
  const [userSearchList, setUserSearchList] = useState(projectUserList);
  const dropdownRef = useRef();
  const inputRef = useRef(null);

  const handleSearch = (event) => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  // Handle clearing the search input
  const clearSearch = () => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes("".toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch("");
  };

  const handleKeyDown = (event) => {
    if (!showFilterDropDown) return;

    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < userSearchList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : userSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item
      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown
      setshowFilterDropDown(false);
      event.preventDefault();
    }
  };
  const userSectionRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowFilterDropDown(false); // Close the dropdown
        clearSearch();
      } else if (
        userSectionRef.current &&
        userSectionRef.current.contains(event.target)
      ) {
        if (
          (isPermitted("task/update") && isOwnerInTaskOne(data, userId)) ||
          isAdmin(userProfile)
        ) {
          setshowFilterDropDown((prev) => !prev);
          clearSearch();
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Scroll the focused item into view
  useEffect(() => {
    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  useEffect(() => {
    setAutoFocus(true);
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the component mounts or when the tab changes
    }
  }, [currentTab]);

  return (
    <section
      className={`h-100 position-relative ${
        data?.taskMembers?.length > 0
          ? `filter-top-user-section ${
              (isPermitted("task/update") && isOwnerInTaskOne(data, userId)) ||
              isAdmin(userProfile)
                ? "filter-user-section-hover"
                : ""
            }`
          : ""
      } ${
        data?.taskMembers?.filter((member) => member?.memberType === "member")
          ?.length < 1
          ? "d-flex task-detail-header-user-section me-0 px-1"
          : ""
      }`}
      ref={sectionRef}
      onKeyDown={handleKeyDown}
      // aria-haspopup="listbox"
      // aria-expanded={isDropdownOpen}
    >
      <div className="d-flex" ref={userSectionRef}>
        {data && data?.taskMembers?.length > 0 ? (
          <div
            data-tooltip-id="filter-members-lap-perm-tlt"
            className="teams-member-listing-wap"
            // onClick={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   if (
            //     (isPermitted("task/update") &&
            //       isOwnerInTaskOne(data, userId)) ||
            //     isAdmin(userProfile)
            //   ) {
            //     setshowFilterDropDown(!showFilterDropDown);
            //   }
            // }}
          >
            <ul>
              {data?.taskMembers?.some(
                (member) => member.memberType === "member"
              ) ? (
                <>
                  {[
                    ...new Set(
                      data?.taskMembers
                        ?.filter((member) => member?.memberType === "member") // Filter for "member" type
                        ?.slice(0, 3)
                        ?.map((res) => res)
                    ),
                  ].map((user, id) => (
                    <div className="d-flex align-items-center" key={id}>
                      <UserImageCircle
                        colors={colors}
                        moduleCategory={user}
                        id={id}
                      />
                      <p className="mb-0 ms-1 task-detail-header-section-user-name">
                        {allowAssigntoText && (
                          <p className="task-detail-assigned-to-text">
                            Assigned to
                          </p>
                        )}
                        {user?.userName?.length > 14
                          ? user.userName.slice(0, 14) + "..."
                          : user?.userName}
                      </p>
                      {((isPermitted("task/update") &&
                        isOwnerInTaskOne(data, userId)) ||
                        isAdmin(userProfile)) && (
                        <div
                          className="select-icon ms-2"
                          style={{
                            transform: showFilterDropDown
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        >
                          <IoIosArrowDown />
                        </div>
                      )}
                    </div>
                  ))}
                  {[
                    ...new Set(
                      data?.taskMembers
                        ?.filter((member) => member.memberType === "member") // Filter for "member" type
                        ?.map((res) => res.userId)
                    ),
                  ].length > 3 && (
                    <AdditionalUserCircle
                      colors={colors}
                      moduleCategory={`+${
                        [
                          ...new Set(
                            data?.taskMembers
                              ?.filter(
                                (member) => member.memberType === "member"
                              ) // Filter for "member" type
                              ?.map((res) => res.userId)
                          ),
                        ].length - 3
                      }`}
                    />
                  )}
                </>
              ) : (
                // Fallback text if no "member" type exists
                <div className="d-flex align-items-center assign-to-placeholder mb-0">
                  <img
                    src={avatar}
                    alt="assign-placeholder"
                    className="mt-1 me-1"
                  />
                  Assign to
                  <span className="select-icon-user ms-2">
                    <div
                      className="select-icon"
                      style={{
                        transform: showFilterDropDown
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <IoIosArrowDown />
                    </div>
                  </span>
                </div>
              )}
            </ul>
            {!(
              (isPermitted("task/update") && isOwnerInTaskOne(data, userId)) ||
              isAdmin(userProfile)
            ) && (
              <ReactTooltip
                id={`filter-members-lap-perm-tlt`}
                place="top"
                content={() => (
                  <div>You don't have permission to update this field.</div>
                )}
              />
            )}
          </div>
        ) : (
          <div
            data-tooltip-id="filter-members-select-lap-perm-tlt"
            className="tasks-main-info-list-font-weight me-3 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (
                (isPermitted("task/update") &&
                  isOwnerInTaskOne(data, userId)) ||
                isAdmin(userProfile)
              ) {
                setshowFilterDropDown(!showFilterDropDown);
              }
            }}
          >
            Assign To
            {!(
              (isPermitted("task/update") && isOwnerInTaskOne(data, userId)) ||
              isAdmin(userProfile)
            ) && (
              <ReactTooltip
                id={`filter-members-select-lap-perm-tlt`}
                place="top"
                content={() => (
                  <div>You don't have permission to update this field.</div>
                )}
              />
            )}
          </div>
        )}
      </div>
      {showFilterDropDown && isMobile && (
        <BottomSheet
          open={showFilterDropDown}
          blocking={false}
          onDismiss={() => setshowFilterDropDown(false)}
          className="member-bottom-sheet"
        >
          <div
            className="rounded-3 shadow-lg task-user-filter-dropdown task-user-filter-dropdown-mobile"
            ref={dropdownRef}
            data-theme={props.isDark}
          >
            <MobileTaskDetailMemberFilter
              isDark={props.isDark}
              projectUserList={projectUserList}
              setData={setData}
              data={data}
              userSearchList={userSearchList}
              setUserSearchList={setUserSearchList}
              inputRef={inputRef}
              userListRef={userListRef}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              search={search}
              handleSearch={handleSearch}
              clearSearch={clearSearch}
              autoFocus={autoFocus}
              updateRowParameterById={updateRowParameterById}
              kanban={kanban}
              getTaskDataAfterUpdate={getTaskDataAfterUpdate}
              updateTaskData={updateTaskData}
              pageParams={pageParams}
              listingType={listingType}
              setUpdateLoad={setUpdateLoad}
              updateTaskMemberData={updateTaskMemberData}
              updatePhaseTaskMemberData={updatePhaseTaskMemberData}
              isCompletedTaskChanged={isCompletedTaskChanged}
              setIsCompletedTaskChanged={setIsCompletedTaskChanged}
              showFilterDropDown={showFilterDropDown}
              getTypeID={getTypeID}
            />
          </div>
        </BottomSheet>
      )}
      {showFilterDropDown && dropdownPosition && !isMobile && (
        <Portal>
          <div
            className="position-absolute rounded-3 shadow-lg task-table-user-filter task-user-filter-dropdown"
            ref={dropdownRef}
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: 1050,
              top: dropdownPosition.top + 8 + "px",
              left: dropdownPosition.left + "px",
            }}
            data-theme={props.isDark}
          >
            <div className="p-0 w-100 h-100 d-flex custom-user-selection-main-container">
              <div className="task-user-filter-dropdown-tab-bar">
                <ul className="d-flex flex-column align-items-start custom-horizental-scrollbar p-0 m-0 custom-user-type-list">
                  <li>
                    <a
                      className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 pt-0 ${
                        currentTab === 3 ? "active-user-type" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCurrentTab(3);
                      }}
                    >
                      Assigned to
                    </a>
                    <div></div>
                  </li>
                  <li>
                    <a
                      className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                        currentTab === 2 ? "active-user-type" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCurrentTab(2);
                      }}
                    >
                      Task Owner
                    </a>
                    <div></div>
                  </li>
                  <div className="w-100 "></div>
                </ul>
              </div>

              <TaskUserListComponent
                isDark={props.isDark}
                projectUserList={userSearchList}
                setData={setData}
                data={data}
                getTypeID={getTypeID}
                currentTab={currentTab}
                updateRowParameterById={updateRowParameterById}
                kanban={kanban}
                getTaskDataAfterUpdate={getTaskDataAfterUpdate}
                updateTaskData={updateTaskData}
                pageParams={pageParams}
                listingType={listingType}
                setUpdateLoad={setUpdateLoad}
                updateTaskMemberData={updateTaskMemberData}
                updatePhaseTaskMemberData={updatePhaseTaskMemberData}
                isCompletedTaskChanged={isCompletedTaskChanged}
                setIsCompletedTaskChanged={setIsCompletedTaskChanged}
                showFilterDropDown={showFilterDropDown}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                userSearchList={userSearchList}
                search={search}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                autoFocus={autoFocus}
                inputRef={inputRef}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
                setshowFilterDropDown={setshowFilterDropDown}
              />
            </div>
          </div>
        </Portal>
      )}
    </section>
  );
}

const TaskUserListComponent = ({
  projectUserList,
  setData,
  data,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  showFilterDropDown,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  isDark,
  userSearchList,
  search,
  handleSearch,
  clearSearch,
  autoFocus,
  inputRef,
  setIsClicked,
  isClicked,
  setshowFilterDropDown,
}) => {
  return (
    <div className="d-flex flex-column custom-user-selection-main-container">
      <div className="active listing-team-modal-search-input-wrap position-relative">
        <input
          ref={inputRef}
          type="text"
          name="search"
          value={search}
          onChange={handleSearch}
          placeholder="Search members"
          autoFocus={autoFocus}
          onBlur={() => inputRef.current.focus()}
          autoComplete="off"
        />
        {search ? (
          <span className="custom-user-search-icon" onClick={clearSearch}>
            &#x2715;
          </span>
        ) : (
          <img
            src={SearchIcon}
            className="custom-user-search-icon"
            alt="search-icon"
            width={20}
            height={20}
          />
        )}
      </div>
      <TaskUsersListForFilter
        isDark={isDark}
        projectUserList={userSearchList}
        setData={setData}
        data={data}
        getTypeID={getTypeID}
        currentTab={
          currentTab === 1 ? "observer" : currentTab === 2 ? "owner" : "member"
        }
        updateRowParameterById={updateRowParameterById}
        kanban={kanban}
        getTaskDataAfterUpdate={getTaskDataAfterUpdate}
        updateTaskData={updateTaskData}
        pageParams={pageParams}
        listingType={listingType}
        setUpdateLoad={setUpdateLoad}
        updateTaskMemberData={updateTaskMemberData}
        updatePhaseTaskMemberData={updatePhaseTaskMemberData}
        isCompletedTaskChanged={isCompletedTaskChanged}
        setIsCompletedTaskChanged={setIsCompletedTaskChanged}
        showFilterDropDown={showFilterDropDown}
        userListRef={userListRef}
        focusedIndex={focusedIndex}
        setFocusedIndex={setFocusedIndex}
        setIsClicked={setIsClicked}
        isClicked={isClicked}
        setshowFilterDropDown={setshowFilterDropDown}
      />
    </div>
  );
};

const TaskUsersListForFilter = ({
  projectUserList,
  setData,
  data,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  setUpdateLoad,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  showFilterDropDown,
  userListRef,
  setshowFilterDropDown,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const ownerCount = data?.taskMembers?.filter(
    (member) => member.memberType === "owner"
  ).length;

  const newMembers = sortCheckedMembersOnTop(
    projectUserList,
    data?.taskMembers,
    currentTab === "observer" ? 1 : currentTab === "owner" ? 2 : 3
  );

  return (
    <div
      ref={userListRef}
      className="task-user-filter-dropdown-menu task-detail-user-dropdown custom-vertical-scrollbar my-2"
    >
      {newMembers?.map((res, index) => (
        <TaskUsersListItemForFilter
          setData={setData}
          data={data}
          selectedUsers={data?.taskMembers}
          res={res}
          currentTab={currentTab}
          getTypeID={getTypeID}
          updateRowParameterById={updateRowParameterById}
          kanban={kanban}
          getTaskDataAfterUpdate={getTaskDataAfterUpdate}
          updateTaskData={updateTaskData}
          pageParams={pageParams}
          listingType={listingType}
          setUpdateLoad={setUpdateLoad}
          ownerCount={ownerCount}
          updateTaskMemberData={updateTaskMemberData}
          updatePhaseTaskMemberData={updatePhaseTaskMemberData}
          isCompletedTaskChanged={isCompletedTaskChanged}
          setIsCompletedTaskChanged={setIsCompletedTaskChanged}
          index={index}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          setIsClicked={setIsClicked}
          isClicked={isClicked}
          setshowFilterDropDown={setshowFilterDropDown}
        />
      ))}
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  setData,
  data,
  res,
  currentTab,
  getTypeID,
  updateRowParameterById,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskData,
  pageParams,
  listingType,
  selectedUsers,
  setUpdateLoad,
  ownerCount,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  isCompletedTaskChanged,
  setIsCompletedTaskChanged,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
  setshowFilterDropDown,
}) => {
  const [updateCompletedTaskMemberData, { isLoading: completeMemberLoad }] =
    useUpdateCompletedTaskMemberDataMutation();
  const [clearAllMemberTaskCache] = useClearAllMemberTaskCacheMutation();

  const [isChecked, setIsChecked] = useState(false);
  const userId = res?.userId; // Initialize userId here

  useEffect(() => {
    // Check if the user is selected and update isChecked state
    const isUserSelected = selectedUsers?.some(
      (member) => member?.userId === userId && member?.memberType === currentTab
    );
    setIsChecked(isUserSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, currentTab, res]);

  const handleCheckboxClick = async () => {
    if (ownerCount < 2 && currentTab === "owner" && isChecked) {
      toast.info(
        "There will always be atleast one owner associated with tasks.",
        { position: toast.POSITION.TOP_CENTER }
      );
    } else {
      try {
        setshowFilterDropDown(false);
        if (data?.taskStatus?.statusName === "Completed") {
          setIsCompletedTaskChanged(true);
        }
        let updatedTeamMembers;
        setIsChecked(!isChecked);

        // Toggle user selection
        updatedTeamMembers = isChecked
          ? data?.taskMembers.filter(
              (member) =>
                !(
                  member?.userId === userId && member?.memberType === currentTab
                )
            )
          : [
              ...data?.taskMembers,
              {
                userId: userId,
                memberType: currentTab,
                userName: res?.userName,
                profileImage: res?.profileImage,
              },
            ];

        if (currentTab === "owner" || currentTab === "member") {
          const seenUserIds = new Set();

          updatedTeamMembers = updatedTeamMembers?.filter((member) => {
            // Only consider members of the current memberType
            if (member.memberType === currentTab) {
              // Check if the userId matches the main userId
              if (member.userId === userId) {
                // If this userId has not been seen, keep this member
                if (!seenUserIds.has(member.userId)) {
                  seenUserIds.add(member.userId);
                  return true; // Keep this member
                }
                return false; // Filter out duplicates for the same memberType
              }
              return false; // Filter out if userId does not match the main userId
            }
            return true; // Keep members of other memberTypes
          });
        }
        setData({
          ...data,
          taskMembers: updatedTeamMembers,
        });
        updateRowParameterById(data?.id, "taskMembers", updatedTeamMembers);
        const updatedData = {
          taskId: data?.id,
          taskMembers: convertToObject(updatedTeamMembers),
        };
        let response;
        if (listingType === "COMPLETED_TASKS") {
          response = await updateCompletedTaskMemberData({
            ...pageParams,
            ...updatedData,
          });
        } else if (listingType === "ALL_TASKS") {
          response = await updateTaskMemberData({
            ...pageParams,
            ...updatedData,
          });
        } else if (listingType === "PHASE_TASKS") {
          response = await updatePhaseTaskMemberData(updatedData);
        } else if (listingType === "NOTIFICATION_LIST") {
          await clearAllMemberTaskCache(updatedData);
        }

        if (response?.data?.status === "success") {
          getTaskDataAfterUpdate();

          // toast.success("Task updated successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 300,
          //   hideProgressBar: true,
          //   closeButton: false,
          // });
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
      }
    }
  };

  const convertToObject = (data) => {
    return data?.map((item) => {
      return {
        [getTypeID(item.memberType)]: item.userId,
      };
    });
  };

  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      const isUserSelected = selectedUsers?.some(
        (member) =>
          member?.userId === userId && member?.memberType === currentTab
      );
      setIsChecked(isUserSelected);
      handleCheckboxClick();
      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className="py-1  w-100 border-bottom-1 task-user-filter-dropdown-menu-item"
    >
      <div
        className="checkbox create-task-checkbox"
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span className="ps-1">{res?.userName}</span>
        </label>
      </div>
    </div>
  );
};

export default TaskUserFilter;
