import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { formatTime } from "../../../helper/timeAgoFormatter";
import { formatDeadlineDate } from "../../../helper/dateFormatter";
import { Icon } from "@iconify/react/dist/iconify.js";
import upIcon from "../../../assets/performance_up_icon.png";
import downIcon from "../../../assets/performance_down_icon.png";
import userProfileBlank from "../../../assets/blank_profile.svg";
import PerformanceIcon from "../../../assets/summary-list-up-trend.png";
import TotalPointsIcon from "../../../assets/summary-list-total-points.png";
import ObtainedPointsIcon from "../../../assets/total-points-green.svg";
import SigninIcon from "../../../assets/summary-list-signin.png";
import SignoutIcon from "../../../assets/summary-list-signout.png";
import { useLocation, useNavigate } from "react-router-dom";

const StaffSummaryListView = ({
  rowData,
  handleStatNavigation,
  totalTaskHeaderText,
  startDate,
  endDate,
  filterType,
  selectedDateFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);
  //   const [gridApi, setGridApi] = useState(null);

  // Table definitions
  const gridRef = useRef();
  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: false,
      filter: false,
      flex: 1,
      minWidth: 100,
      width: 1320,
    };
  }, []);

  //   const gridReady = (params) => {
  //     setGridApi(params.api);
  //     var columnState = JSON.parse(localStorage.getItem("myColumnState"));
  //     if (columnState) {
  //       params.columnApi.applyColumnState({
  //         state: columnState,
  //         applyOrder: true,
  //       });
  //     }
  //   };

  // Column definitions for task listing
  const columnData = [
    {
      field: "userName",
      headerName: "Member",
      // pinned: "left",
      editable: false,
      suppressMenu: true,
      width: 405,
      minWidth: 405,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            Members
            <span className="ms-1 task-count-text">{rowData?.length || 0}</span>
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.userId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        return (
          <div
            className="d-flex align-items-center justify-content-between summary-list-username-cell"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (filterType === "date_range" && startDate && endDate) {
                sessionStorage?.setItem("dailyReportStartDate", startDate);
                sessionStorage?.setItem("dailyReportEndDate", endDate);
              }
              sessionStorage?.setItem(
                "dailyReportDate",
                filterType === "today"
                  ? new Date()
                  : filterType === "date_range"
                  ? JSON.stringify([
                      new Date(startDate).toISOString(),
                      new Date(endDate).toISOString(),
                    ])
                  : selectedDateFilter
              );

              sessionStorage?.setItem("dailyReportFilterType", filterType);
              navigate(
                `/${localStorage.getItem(
                  "company"
                )}/performance-report?userId=${params?.data?.userId}`,
                {
                  state: { from: location.pathname },
                }
              );
            }}
          >
            <div className="nama-table-wrap d-flex gap-2 align-items-center">
              <img
                src={
                  params?.data?.profileImage
                    ? params?.data?.profileImage
                    : userProfileBlank
                }
                alt=""
              />
              <p>{params?.data?.userName}</p>
            </div>
            <div className="open-target-link">
              {" "}
              <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
            </div>
          </div>
        );
      },
    },
    {
      field: "totalTask",
      headerName: "Date",
      editable: false,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            {totalTaskHeaderText === "last_month"
              ? "Last Month"
              : totalTaskHeaderText === "this_week"
              ? "This Week"
              : totalTaskHeaderText === "this_month"
              ? "This Month"
              : totalTaskHeaderText === "all"
              ? "All Tasks"
              : totalTaskHeaderText}
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.userId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        const totalTasks = params?.data?.totalTask || 0; // Ensure it's a number
        return <p>{totalTasks === 1 ? "1 task" : `${totalTasks} tasks`}</p>;
      },
    },

    {
      field: "taskScore",
      headerName: "Total Points",
      editable: false,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            <span>
              <img
                src={TotalPointsIcon}
                alt="performance-icon"
                width={14}
                height={14}
                className="me-2"
              />
            </span>
            Total Points
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "staff-summery-score-cell";
        if (hoveredRow === params.data.userId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        return params?.data?.totalTask && params?.data?.totalTask > 0 ? (
          <p className="total-points-text">{params?.data?.totalMaxScore}</p>
        ) : (
          <p className="total-points-text">---</p>
        );
      },
    },
    {
      field: "obtainedScore",
      headerName: "Points Obtained",
      editable: false,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            <span>
              <img
                src={ObtainedPointsIcon}
                alt="performance-icon"
                width={14}
                height={14}
                className="me-2"
              />
            </span>
            Obtained Points
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "staff-summery-obtained-score-cell";
        if (hoveredRow === params.data.userId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        return params?.data?.totalTask && params?.data?.totalTask > 0 ? (
          <p className="obtained-points-text">{params?.data?.obtainedScore}</p>
        ) : (
          <p className="obtained-points-text">---</p>
        );
      },
    },
    // {
    //   field: "dayStartAt",
    //   headerName: "Sign In",
    //   editable: false,
    //   headerComponent: (params) => (
    //     <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
    //       {" "}
    //       <label>
    //         <span>
    //           <img
    //             src={SigninIcon}
    //             alt="performance-icon"
    //             width={14}
    //             height={14}
    //             className="me-2"
    //           />
    //         </span>
    //         Sign In
    //       </label>
    //     </div>
    //   ),
    //   cellClass: (params) => {
    //     let classes = "";
    //     if (hoveredRow === params.data.userId) {
    //       classes += " selected-hovered-row";
    //     }
    //     return classes.trim();
    //   },
    //   suppressClickEdit: true, // Add this line
    //   cellRenderer: (params) => {
    //     return (
    //       <p>
    //         {params?.data?.dayStartAt
    //           ? formatTime(params?.data?.dayStartAt)
    //           : "---"}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   field: "dayEndAt",
    //   headerName: "Sign Out",
    //   editable: false,
    //   headerComponent: (params) => (
    //     <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
    //       {" "}
    //       <label>
    //         <span>
    //           <img
    //             src={SignoutIcon}
    //             alt="performance-icon"
    //             width={14}
    //             height={14}
    //             className="me-2"
    //           />
    //         </span>
    //         Sign Out
    //       </label>
    //     </div>
    //   ),
    //   cellClass: (params) => {
    //     let classes = "";
    //     if (hoveredRow === params.data.userId) {
    //       classes += " selected-hovered-row";
    //     }
    //     return classes.trim();
    //   },
    //   suppressClickEdit: true, // Add this line
    //   cellRenderer: (params) => {
    //     return (
    //       <p>
    //         {params?.data?.dayEndAt
    //           ? formatTime(params?.data?.dayEndAt)
    //           : "---"}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   field: "lastActiveAt",
    //   headerName: "Last Active",
    //   editable: false,
    //   cellClass: (params) => {
    //     let classes = "";
    //     if (hoveredRow === params.data.userId) {
    //       classes += " selected-hovered-row";
    //     }
    //     return classes.trim();
    //   },
    //   suppressClickEdit: true, // Add this line
    //   cellRenderer: (params) => {
    //     return (
    //       <p>
    //         {params?.data?.lastActiveAt
    //           ? formatDeadlineDate(params?.data?.lastActiveAt)
    //           : "---"}
    //       </p>
    //     );
    //   },
    // },
    {
      field: "performance",
      headerName: "Performance",
      editable: false,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            <span>
              <img
                src={PerformanceIcon}
                alt="performance-icon"
                width={14}
                height={14}
                className="me-2"
              />
            </span>
            Performance
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = `${
          params?.data?.performanceStatus === 0
            ? "staff-summery-performance-cell-down"
            : "staff-summery-performance-cell"
        }`;
        if (hoveredRow === params.data.userId) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      suppressClickEdit: true, // Add this line
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2">
            {params?.data?.totalTask && params?.data?.totalTask > 0 ? (
              <p
                className={`${
                  params?.data?.performanceStatus === 1
                    ? "up-trend-text"
                    : "down-trend-text"
                }`}
              >{`${params?.data?.performance}%`}</p>
            ) : (
              <p className="up-trend-text">---</p>
            )}
            {params?.data?.totalTask && params?.data?.totalTask > 0 ? (
              <img
                height={20}
                width={20}
                src={params?.data?.performanceStatus === 1 ? upIcon : downIcon}
                alt="no-data"
              />
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  // Scrolling events
  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rowData]);

  const handleMouseOut = (event) => {
    // Get the bounding box of the grid and options dropdown
    const gridRect = gridRef?.current?.getBoundingClientRect();

    // Check if the mouse has left the grid area
    if (
      event.clientX > gridRect.right ||
      event.clientY < gridRect.top ||
      event.clientY > gridRect.bottom + 30
    ) {
      setHoveredRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rowData?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [rowData]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  return (
    <div
      className="position-relative project-timeline mb-0"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      {/* <div className="position-relative">{gridApi}</div> */}
      <div
        className="table-responsive scroll-smooth custom-horizental-scrollbar"
        ref={tableRef}
      >
        {/* <Tooltip id="my-tooltip" /> */}

        <div
          className="ag-theme-alpine "
          style={{
            width: "100%",
            minWidth: "1320px",
          }}
          ref={gridRef}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                rowData?.length === 1 ? "left-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={rowData}
            columnDefs={columnData}
            defaultColDef={defaultColDef}
            getRowId={(params) => {
              return params.data.userId;
            }}
            headerHeight={41}
            rowHeight={55}
            rowDragManaged={false}
            rowDragEntireRow={false}
            animateRows={true}
            domLayout={"autoHeight"}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            suppressRowHoverHighlight={true}
            popupParent={popupParent}
            // onGridReady={gridReady}
            onCellMouseOver={(params) => {
              if (params?.data?.userId !== hoveredRow) {
                setHoveredRow(params?.data?.userId);
                // changeTheOptionPosition(params?.data?.id);
              }
            }}
          ></AgGridReact>
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                rowData?.length === 1 ? "right-scroll-button-height-one" : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffSummaryListView;
