/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import "../styles/tasks.scss";
import TaskFilterSection from "./TaskFilterSection";
import TasksListingTable from "./TasksListingTable";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import {
  projectFormListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import Select, { components } from "react-select";
import { IoIosArrowDown, IoMdArrowRoundBack } from "react-icons/io";
import TasksAdvanceSearchModalMain from "./TasksAdvanceSearchModal/TasksAdvanceSearchModalMain";
import {
  clearErrors,
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";
import ProjectNewModal from "../../Projects/components/ProjectNewModal";

import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { isPermitted } from "../../../helper/isPermitted";
import { ShimmerButton, ShimmerTable } from "react-shimmer-effects";
import DeleteSelectedTasksModal from "./DeleteSelectedTasksModal";
import { toast } from "react-toastify";
import {
  useGetTaskListQuery,
  useUpdateTaskDataMutation,
  useUpdateTaskMemberDataMutation,
} from "../../../slices/tasks/tasksSlice";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import API, { ProjectAPI } from "../../../services/ClientApi";
import { downloadTaskDataAsExcel } from "../../../helper/downloadFileHelpers";
import TasksSelectedSection from "./TasksSelectedSection";
import CreateIcon from "../../../assets/create-task-icon.png";
import CreateTaskModal from "./CreateTaskModal";
import TaskDetailOffCanvas from "./TaskDetailOffCanvas";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { userProfilePage } from "../../../actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import { useGetUserListingQuery } from "../../../slices/members/membersSlice";

const Tasks = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const [userList, setUserList] = useState([]);
  const [updateTaskData, { isLoading: isUpdating }] =
    useUpdateTaskDataMutation();
  const [updateTaskMemberData, { isLoading: isMemberUpdating }] =
    useUpdateTaskMemberDataMutation();
  const [wasUnread, setWasUnread] = useState(false);

  const [showTaskDetails, setShowTaskDetails] = useState(false);

  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { data: userTaskListing } = useGetUserListingQuery();
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const [excelLoad, setExcelLoad] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { modalContents } = useSelector((state) => state?.modalData);
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  const [selectedTask, setSelectedTask] = useState(null);
  const [boardData] = useState(null);
  const [boardColumnData] = useState([]);
  const [load, setLoad] = useState(true);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [updateLoad, setUpdateLoad] = useState(false);
  const [taskListMain, setTaskList] = useState([]);
  const [taskListToFilter, setTaskListToFilter] = useState([]);
  const [newId, setNewId] = useState(null);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showProject, setShowProject] = useState(false);
  const [pageSize, setPageSize] = useState(() => {
    const savedPageSize = sessionStorage.getItem("task_page_limit");
    return savedPageSize ? parseInt(savedPageSize) : 50; // default is 50
  }); // Number of items per page
  const [mainLoad, setMainLoad] = useState(true);
  const [readLoad, setReadLoad] = useState(false);

  // Advanced filter states
  const initialFormData = {
    multiStatus: [],
    multiPriority: [],
    teamsMembers:
      typeof userProfile?.userFilters?.task?.[0]?.filterOptions
        ?.teamsMembers === "string" &&
      userProfile?.userFilters?.task?.[0]?.filterOptions?.teamsMembers
        ? userProfile?.userFilters?.task?.[0]?.filterOptions?.teamsMembers?.split(
            ","
          )
        : [],
    teamsMembersTypes: [],
    taskType: [],
    taskStartDate: userProfile?.userFilters?.task?.[0]?.filterOptions
      ?.taskStartDate
      ? new Date(
          userProfile?.userFilters?.task?.[0]?.filterOptions?.taskStartDate
        )
      : "",
    taskEndDate: userProfile?.userFilters?.task?.[0]?.filterOptions?.taskEndDate
      ? new Date(
          userProfile?.userFilters?.task?.[0]?.filterOptions?.taskEndDate
        )
      : "",
    selectedDate: userProfile?.userFilters?.task?.[0]?.filterOptions
      ?.selectedDate
      ? new Date(
          userProfile?.userFilters?.task?.[0]?.filterOptions?.selectedDate
        )
      : "",
    createdAt: userProfile?.userFilters?.task?.[0]?.filterOptions?.createdAt
      ? new Date(userProfile?.userFilters?.task?.[0]?.filterOptions?.createdAt)
      : "",
    updatedAt: userProfile?.userFilters?.task?.[0]?.filterOptions?.updatedAt
      ? new Date(userProfile?.userFilters?.task?.[0]?.filterOptions?.updatedAt)
      : "",
    projectsIds: [],
    searchText:
      userProfile?.userFilters?.task?.[0]?.filterOptions?.searchText || "",
    taskName:
      userProfile?.userFilters?.task?.[0]?.filterOptions?.taskName || "",

    isDeleted:
      userProfile?.userFilters?.task?.[0]?.filterOptions?.isDeleted || false,
    hasApproval:
      userProfile?.userFilters?.task?.[0]?.filterOptions?.hasApproval || false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const initialFilterType =
    sessionStorage.getItem("filterType") &&
    sessionStorage.getItem("filterType") !== "date_range"
      ? sessionStorage.getItem("filterType")
      : userProfile?.userFilters?.task?.[0]?.filterOptions?.filterType
      ? userProfile?.userFilters?.task?.[0]?.filterOptions?.filterType
      : "all";

  const [filterType, setFilterType] = useState(initialFilterType);

  const defaultFilters = {
    status:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "In Progress"
        ? "in-progress"
        : "",
    priority:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "Critical"
        ? "critical"
        : "",
    overdue: false,
    memberFilter: "member",
    memberIds: [],
    observerIds: [],
    ownerIds: [],
    multiStatus: [],
    multiPriority: [],
    teamsMembers: [],
    teamsMembersTypes: [],
    taskType: [],
    taskStartDate: "",
    taskEndDate: "",
    selectedDate: "",
    searchText: "",
    taskName: "",
    createdAt: "",
    updatedAt: "",
    projectsIds: [],
    isDeleted: false,
    hasApproval: false,
  };
  const [serverFilters, setServerFilters] = useState(() => {
    // Retrieve and parse session storage filters if they exist
    const sessionFilters =
      JSON.parse(sessionStorage?.getItem("statFilters")) || {};

    const filterSession =
      sessionStorage.getItem("filterType") &&
      sessionStorage.getItem("filterType") !== "date_range"
        ? sessionStorage.getItem("filterType")
        : null;
    if (filterSession) {
      setFilterType(filterSession);
      if (filterSession === "date") {
        setSelectedDateFilter(new Date(sessionFilters?.selectedDate));
      }
    } else {
      if (userProfile?.userFilters?.task?.[0]?.filterOptions?.selectedDate) {
        // Fall back to userProfile if statFilters is not available
        setSelectedDateFilter(
          new Date(
            userProfile?.userFilters?.task[0]?.filterOptions?.selectedDate
          )
        );
        setFilterType("date");
      } else {
        // Ensure task is an array and has at least one item before accessing [0]
        if (
          Array.isArray(userProfile?.userFilters?.task) &&
          userProfile?.userFilters?.task?.length > 0
        ) {
          setFilterType(
            userProfile.userFilters.task[0]?.filterOptions?.filterType || "all"
          );
        } else {
          setFilterType("all"); // Default fallback
        }
        setSelectedDateFilter(null);
      }
    }

    // Check if sessionFilters has any keys before setting filter type
    if (
      Object.keys(sessionFilters).length > 0 &&
      !sessionStorage.getItem("filterType")
    ) {
      setFilterType("all");
      setSelectedDateFilter(null);
    }

    // Remove session storage key once retrieved
    if (Object.keys(sessionFilters).length > 0) {
      sessionStorage.removeItem("statFilters");
    }

    // Check if userProfile exists and has task filterOptions
    const userFilters =
      userProfile?.userFilters?.task?.[0]?.filterOptions || {};

    // Merge filters with priority: sessionFilters > userFilters > defaultFilters
    return { ...defaultFilters, ...userFilters, ...sessionFilters };
  });

  const [filters, setFilters] = useState({
    pinnedState: false,
    status:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "In Progress"
        ? "in-progress"
        : null,
    priority:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "Critical"
        ? "Critical"
        : null,
    listType:
      localStorage.getItem("taskFilter") &&
      parseInt(localStorage.getItem("taskFilter")) === 1
        ? 1
        : 0,

    showTodayTasks: sessionStorage.getItem("showTodayTasks") || false,
    showWeeklyTasks: sessionStorage.getItem("showWeeklyTasks") || false,
  });

  const [taskCount, setTaskCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [isShowRefresh, setIsShowRefresh] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);
  const projectsListingSeeMoreModalContent = findObjectByContentTitle(
    "tasks-list-see-more",
    modalContents?.data
  );
  const [order, setOrder] = useState({ orderBy: "", orderDirection: "" });
  // const [totalPages, setTotalPages] = useState(1);
  // state for showing and hiding the only completed tasks table
  const [showOnlyCompletedTasks, setShowOnlyCompletedTasks] = useState(false);
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const allowedRoutes = ["performance-report", "team-performance"];

  // Tour steps
  const steps = [
    {
      element: ".create-btn",
      intro: "Quickly create tasks, set deadlines and add notes.",
      position: "right",
    },
    {
      element: ".download-excel-btn",
      intro:
        "Quickly export your task lists for easy sharing and record-keeping.",
      position: "left",
    },
    {
      element: ".task-list-filters-btn-container",
      intro:
        "Use the advanced search option to quickly filter and find specific tasks.",
      position: "bottom",
    },
  ];

  // State to hold the sort order object
  const [sortOrder, setSortOrder] = useState({
    Status: "",
    "Task Name": "",
    "Project Name": "",
    Deadline: "",
    Priority: "",
  });

  const {
    data: taskList,
    isLoading,
    error,
    isFetching,
    isUninitialized,
  } = useGetTaskListQuery(
    {
      page: currentPage,
      limit: pageSize,
      orderBy: order.orderBy,
      orderDirection: order.orderDirection,
      type: filterType,
      timeZone: userProfile?.timeZone,
      ...(() => {
        const {
          memberIds,
          ownerIds,
          observerIds,
          memberFilter,
          filterType,
          ...rest
        } = serverFilters;
        return rest; // Return the remaining properties
      })(),
      membersFilter: serverFilters.memberIds?.length
        ? serverFilters.memberIds.join(",")
        : "",
      ownersFilter: serverFilters.ownerIds?.length
        ? serverFilters.ownerIds.join(",")
        : "",
      observersFilter: serverFilters.observerIds?.length
        ? serverFilters.observerIds.join(",")
        : "",
    },
    { skip: !localStorage.getItem("access_token") }
  );

  // Update `teamsMembersTypes` when `projectTypeList` changes
  useEffect(() => {
    if (projectTypeList?.length > 0) {
      const teamsMembersTypes =
        typeof userProfile?.userFilters?.task?.[0]?.filterOptions
          ?.teamsMembersTypes === "string"
          ? projectTypeList
              .filter((item) =>
                userProfile?.userFilters?.task?.[0]?.filterOptions?.teamsMembersTypes
                  ?.split(",")
                  ?.includes(item.typeName)
              )
              .map((item) => ({
                label:
                  item.typeName.charAt(0).toUpperCase() +
                  item.typeName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, teamsMembersTypes }));
    }
  }, [projectTypeList, userProfile]);

  useEffect(() => {
    if (projectStatusList?.length > 0) {
      const multiStatus =
        typeof userProfile?.userFilters?.task?.[0]?.filterOptions
          ?.multiStatus === "string"
          ? projectStatusList
              .filter((item) =>
                userProfile?.userFilters?.task?.[0]?.filterOptions?.multiStatus
                  ?.split(",")
                  ?.includes(item.defaultMarkAs)
              )
              .map((item) => ({
                label:
                  item.statusName.charAt(0).toUpperCase() +
                  item.statusName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, multiStatus }));
    }
  }, [projectStatusList, userProfile]);

  useEffect(() => {
    if (taskPriorities?.length > 0) {
      const multiPriority =
        typeof userProfile?.userFilters?.task?.[0]?.filterOptions
          ?.multiPriority === "string"
          ? taskPriorities
              .filter((item) =>
                userProfile?.userFilters?.task?.[0]?.filterOptions?.multiPriority
                  ?.split(",")
                  ?.includes(item.defaultMarkAs)
              )
              .map((item) => ({
                label:
                  item.priorityName.charAt(0).toUpperCase() +
                  item.priorityName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, multiPriority }));
    }
  }, [taskPriorities, userProfile]);

  useEffect(() => {
    if (taskTypes?.length > 0) {
      const taskType =
        typeof userProfile?.userFilters?.task?.[0]?.filterOptions?.taskType ===
        "string"
          ? taskTypes
              .filter((item) =>
                userProfile?.userFilters?.task?.[0]?.filterOptions?.taskType

                  ?.split(",")
                  ?.includes(item.typeName)
              )
              .map((item) => ({
                label: item.typeName,
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, taskType }));
    }
  }, [taskTypes, userProfile]);

  useEffect(() => {
    if (projectFormList?.length > 0) {
      const projectsIds =
        typeof userProfile?.userFilters?.task?.[0]?.filterOptions
          ?.projectsIds === "string"
          ? projectFormList
              .filter((item) =>
                userProfile?.userFilters?.task?.[0]?.filterOptions?.projectsIds

                  ?.split(",")
                  ?.includes(item.id)
              )
              .map((item) => ({
                label: item.projectName,
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, projectsIds }));
    }
  }, [projectFormList, userProfile]);

  // const tasks = taskList[`${pageSize}_${currentPage}`] || [];
  const handleCloseDeleteSelected = () => {
    setDeleteSelected(false);
  };

  // Function to update both orderBy and orderDirection
  const updateOrder = (newOrderBy, newOrderDirection) => {
    setOrder({ orderBy: newOrderBy, orderDirection: newOrderDirection });
  };

  // Download Excel
  const handleDownloadExcel = async () => {
    try {
      setExcelLoad(true);
      const response = await ProjectAPI.post(`task/list`, {
        page: currentPage,
        limit: pageSize,
        orderBy: order.orderBy,
        orderDirection: order.orderDirection,
        pagination: false,
        type: filterType,
        timeZone: userProfile?.timeZone,
        ...(() => {
          const {
            memberIds,
            ownerIds,
            observerIds,
            memberFilter,
            filterType,
            ...rest
          } = serverFilters;
          return rest; // Return the remaining properties
        })(),
        membersFilter: serverFilters.memberIds?.length
          ? serverFilters.memberIds.join(",")
          : "",
        ownersFilter: serverFilters.ownerIds?.length
          ? serverFilters.ownerIds.join(",")
          : "",
        observersFilter: serverFilters.observerIds?.length
          ? serverFilters.observerIds.join(",")
          : "",
      });

      const tasks = response?.data?.data || [];

      if (tasks.length === 0) {
        toast.warn("No tasks available to download.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }

      downloadTaskDataAsExcel(tasks);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to download tasks.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      setExcelLoad(false);
    }
  };

  // Refresh filters
  const refreshFilters = async () => {
    // Reset serverFilters state
    setServerFilters({
      status: "",
      priority: "",
      overdue: false,
      memberFilter: "member",
      memberIds: [],
      observerIds: [],
      ownerIds: [],
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate: "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    });
    setFormData({
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate: "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    });
    setSelectedDateFilter(null);
    setIsShowRefresh(false);
    localStorage.removeItem("taskFilter");
    sessionStorage.removeItem("filterType");
    setFilterType("all");
    setFilters({
      pinnedState: false,
      listType: 0,
      status: null,
      priority: null,
      showTodayTasks: false,
      showWeeklyTasks: false,
    });
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  // Manage filter count section (tasks, completed, overdue)
  const getStatusCounts = async (type) => {
    switch (type) {
      case "INCREMENT_TASK":
        setTaskCount((prev) => prev + 1);
        break;
      case "DECREMENT_TASK":
        setTaskCount((prev) => prev - 1);
        break;
      case "INCREMENT_COMPLETED":
        setCompletedCount((prev) => prev + 1);
        break;
      case "DECREMENT_COMPLETED":
        setCompletedCount((prev) => prev - 1);
        break;
      case "INCREMENT_OVERDUE":
        setOverdueCount((prev) => prev + 1);
        break;
      case "DECREMENT_OVERDUE":
        setOverdueCount((prev) => prev - 1);
        break;
      default:
        break;
    }
  };

  const { applicationName } = useSelector((state) => state.text);
  // Get board data
  const getBoardData = async () => {
    try {
      // Necessary to make a copy of original data rather then using directly since redux toolkit query data is immutable so we make copy of it to change it
      const data = taskList?.data?.map((task) => ({ ...task })) || [];
      // setTaskList(reversedTaskList);
      setTaskListToFilter(data);
      setTaskCount(data?.length);
      let currentDate = new Date();
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes()
      );
      const overdueCount = data?.filter((project) => {
        let endDate = new Date(project.taskDeadline);
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endDate.getHours(),
          endDate.getMinutes()
        );
        return (
          endDate < currentDate &&
          project?.taskStatus?.statusName?.toLowerCase() !== "completed" &&
          project?.taskDeadline
        );
      }).length;
      setOverdueCount(overdueCount);
      const completedCount = data?.filter(
        (task) => task?.taskStatus?.statusName === "Completed"
      ).length;
      setCompletedCount(completedCount);
      if (taskList?.data?.length === 0) {
        setCurrentPage(1);
      }
      // }
    } catch (error) {
    } finally {
    }
  };

  const getBoardingData = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        if (!projectUserList) {
          dispatch(projectUserListingAction());
        }
        if (!taskPriorities) {
          dispatch(taskPrioritiesListAction());
        }
        if (!taskTypes) {
          dispatch(taskTypesListAction());
        }
        if (!projectTypeList) {
          dispatch(projectTypeListingAction());
        }
        if (!projectFormList) {
          dispatch(projectFormListingAction());
        }
        if (!projectStatusList) {
          dispatch(projectListingStatusAction());
        }
      } catch (error) {
      } finally {
        // setLoad(false);
        setMainLoad(false);
      }
    }
  };

  useEffect(() => {
    getBoardingData();
  }, []);

  // Default task and detail modal states when redirected from other pages
  useEffect(() => {
    const tid = localStorage.getItem("tid");
    const oid = localStorage.getItem("oid");
    if (tid) {
      setNewId(tid);
      localStorage.removeItem("tid");
    }
    if (oid) {
      setShowTaskDetails(oid);
      localStorage.removeItem("oid");
    }
  }, []);

  // Clear selected tasks on filter change
  useEffect(() => {
    setSelectedTasks([]);
  }, [serverFilters]);

  // useEffect(() => {
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order]);

  useEffect(() => {
    setUserList(projectUserList?.data?.map((user) => ({ ...user })) || []);
  }, [projectUserList]);

  useEffect(() => {
    // Check for filtered_user in sessionStorage

    const filteredUser = sessionStorage.getItem("filtered_user");

    // If `filtered_user` exists, add it to the teamMembers array and then remove from sessionStorage
    if (filteredUser) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        teamMembers: [...prevFilters.teamMembers, filteredUser],
      }));

      // Remove `filtered_user` from sessionStorage
      sessionStorage.removeItem("filtered_user");
    }
  }, []); // Only run when `pid` changes or on initial load

  // For further filteration processes and form list associated datas
  useEffect(() => {
    getBoardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskList, currentPage, pageSize]);

  useEffect(() => {
    if (isFetching) {
      setShouldShowLoading(true);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (isFetching && serverFilters) {
      setShouldShowLoading(true);
    }
  }, [serverFilters]);

  // Hide the spinner after fetching completes
  useEffect(() => {
    if (!isLoading && !isUninitialized && !isFetching) {
      setShouldShowLoading(false);
    }
  }, [isLoading, isUninitialized, isFetching]);

  // function for update count in filters
  const updateCountFilters = () => {
    setTaskCount(taskListToFilter.length);
    let currentDate = new Date();
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    );
    const overdueCount = taskListToFilter.filter((project) => {
      if (!project.taskDeadline) {
        return false; // Exclude projects with null taskDeadline
      }
      let endDate = new Date(project?.taskDeadline);
      endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes()
      );
      return (
        endDate < currentDate &&
        project?.taskStatus?.statusName?.toLowerCase() !== "completed"
      );
    }).length;
    setOverdueCount(overdueCount);
    const completedCount = taskListToFilter.filter(
      (task) => task?.taskStatus?.statusName?.toLowerCase() === "completed"
    ).length;
    setCompletedCount(completedCount);

    // Calculate todayCount
    const todayCount = taskListToFilter.filter((project) => {
      if (!project.taskDeadline) {
        return false; // Exclude projects with null taskDeadline
      }
      let taskDeadline = new Date(project.taskDeadline);
      taskDeadline = new Date(
        taskDeadline.getFullYear(),
        taskDeadline.getMonth(),
        taskDeadline.getDate()
      );
      const todayDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );

      return taskDeadline.getTime() === todayDate.getTime();
    }).length;

    setTodayCount(todayCount); // Assuming setTodayCount is your state setter for todayCount
  };

  useEffect(() => {
    updateCountFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskListToFilter]);

  const filteredListing = async () => {
    // Check if the refresh icon should be shown

    const noRefresh =
      filters.listType === 0 &&
      serverFilters.status === "" &&
      serverFilters.priority === "" &&
      filters.showTodayTasks === false &&
      filters.showWeeklyTasks === false &&
      serverFilters.searchText === "" &&
      serverFilters.overdue === false &&
      serverFilters.isDeleted === false &&
      serverFilters.hasApproval === false &&
      serverFilters.selectedDate === "" &&
      serverFilters.memberIds.length === 0 &&
      serverFilters.ownerIds.length === 0 &&
      serverFilters.observerIds.length === 0 &&
      serverFilters.taskName === "" &&
      serverFilters.projectsIds.length === 0 &&
      serverFilters.taskStartDate === "" &&
      serverFilters.taskEndDate === "" &&
      serverFilters.createdAt === "" &&
      serverFilters.updatedAt === "" &&
      serverFilters.taskType.length === 0 &&
      serverFilters.teamsMembers.length === 0 &&
      serverFilters.multiPriority.length === 0 &&
      // serverFilters.dueDate === null &&
      serverFilters.multiStatus.length === 0 &&
      serverFilters.teamsMembersTypes.length === 0 &&
      filterType === "all";

    setIsShowRefresh(!noRefresh);

    // Handle loading state
    if (projectStatusList && !mainLoad) {
      setLoad(false);
    }
    if (taskList) {
      // Clear session storage
      sessionStorage.removeItem("showTodayTasks");
      sessionStorage.removeItem("showWeeklyTasks");
      // Remove the session storage key after assigning its value
      if (sessionStorage.getItem("filterType")) {
        sessionStorage.removeItem("filterType");
      }
    }
  };

  useEffect(() => {
    filteredListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    currentPage,
    pageSize,
    taskCount,
    overdueCount,
    completedCount,
    taskListToFilter,
    projectStatusList,
    taskList,
  ]);
  // Calculate total number of pages
  // const totalPages = Math.ceil(taskListMain?.length / pageSize);

  const totalPages = Math.ceil(taskList?.meta?.total / pageSize);
  // Calculate index of the first and last item of the current page
  // const indexOfLastItem = currentPage * pageSize;
  // const indexOfFirstItem = indexOfLastItem - pageSize;
  // const currentItems = taskListMain?.slice(indexOfFirstItem, indexOfLastItem);
  // Page size list
  const pageSizeList = [20, 50, 100];

  // Give error if list does not show
  useEffect(() => {
    if (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      setLoad(false);
      dispatch(clearErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Updating task toaster
  // Custom Toast Component
  useEffect(() => {
    if (isUpdating || isMemberUpdating) {
      // Show the updating toast when isUpdating becomes true
      setUpdateLoad(true);
    } else {
      // Dismiss the toast when the update completes
      setUpdateLoad(false);
    }
  }, [isUpdating, isMemberUpdating]);

  useEffect(() => {
    if (updateLoad) {
      setIsToastVisible(true);
    } else {
      // Dismiss the toast when the update completes
    }
  }, [updateLoad]);

  useEffect(() => {
    // Extract IDs from taskListMain
    const taskIds = taskListMain?.map((task) => task.id);

    // Filter selectedTasks to keep only those that exist in taskIds
    const updatedSelectedTasks = selectedTasks?.filter((taskId) =>
      taskIds.includes(taskId)
    );

    // Update the state with the filtered tasks
    setSelectedTasks(updatedSelectedTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskListMain]); // Run this effect whenever taskListMain changes

  const handleTaskDetailsCloseModal = () => {
    setSelectedTask(null);
    setNewId(null);
    setShowTaskDetails(false);
  };

  // Run tour journey
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    if (
      location.pathname ===
        `/${localStorage.getItem("company")}/task-management-tasks` && // Ensure URL is /dashboard
      userProfile?.taskListTour &&
      !load
    ) {
      setRunTour(true);
    }
  }, [userProfile, location, load]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;
    try {
      setRunTour(false); // Close the tour
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "taskListTour", // Add the field name for the specific input field
        fieldValues: false, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  });

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (event.target.classList.contains("introjs-prevbutton")) {
        await updateProfile();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tasks-{applicationName}</title>
      </Helmet>
      <div className="project-plan-main-wraper">
        <Tooltip id="my-tooltip-tasks" place="right" className="h-0" />
        <div className="time-line-field-wrap project-main-wrap">
          {isToastVisible && !readLoad && (
            <div className="update-load-case"></div>
          )}
          <CustomToast
            isVisible={isToastVisible && !readLoad}
            onClose={() => setIsToastVisible(false)}
            updateLoad={updateLoad}
          />
          {runTour && (
            <Steps
              enabled={runTour}
              steps={steps.filter((step) =>
                document.querySelector(step.element)
              )}
              initialStep={0}
              options={{
                showProgress: false,
                showStepNumbers: false,
                exitOnOverlayClick: false,
                scrollToElement: false,
                exitOnEsc: true,
                nextLabel: "Next",
                prevLabel: "Skip Tour",
                tooltipClass: "tour-custom-tooltip", // Optional custom styling
                disableInteraction: true,
              }}
              onExit={async () => {
                if (localStorage.getItem("access_token")) {
                  await updateProfile();
                }
              }} // Reset the tour state when exited
            />
          )}
          <div className="row">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h4 className="ms-4 projects-heading_wrap projects-detail-heading-wrap">
                {allowedRoutes.some((route) =>
                  location.state?.from.endsWith(route)
                ) && (
                  <button
                    className="ms-0 me-2 p-0 bg-transparent border-0 mb-1" // Add a class for styling
                    onClick={() => navigate(-1)} // Go back to the previous page
                  >
                    <IoMdArrowRoundBack size={23} color="#999999" />
                  </button>
                )}
                {boardData?.boardName ? boardData?.boardName : "Tasks"}
                {isPermitted("task/create-new") && (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="button-tooltip">Create task</Tooltip>}
                  >
                    <button
                      className="create-btn"
                      // data-tooltip-id="create-task-btn"
                      onClick={(e) => {
                        if (!isTrialActive(userProfile)) {
                          props.setOpenSubscriptionsModal(true);
                        } else {
                          setShow(true);
                        }
                      }}
                      disabled={load || runTour}
                    >
                      {" "}
                      <img src={CreateIcon} alt="create-icon" /> Create{" "}
                    </button>
                  </OverlayTrigger>
                )}
              </h4>
            </div>
          </div>
        </div>

        <>
          {load && !shouldShowLoading ? (
            <div className="ms-4 d-flex gap-2">
              {Array.from({ length: 7 }, (_, index) => (
                <ShimmerButton key={index} size="sm" />
              ))}
            </div>
          ) : (
            <div className="ms-4 d-flex align-items-center justify-content-between flex-wrap gap-2 project-filter-action-container">
              <TaskFilterSection
                props={props}
                filters={filters}
                setFilters={setFilters}
                taskListToFilter={taskListToFilter}
                projectUserList={userTaskListing?.data || []}
                projectStatusList={projectStatusList}
                taskPriorities={taskPriorities}
                taskCount={taskCount}
                completedCount={completedCount}
                overdueCount={overdueCount}
                todayCount={todayCount}
                refreshFilters={refreshFilters}
                showRefresh={isShowRefresh}
                setCurrentPage={setCurrentPage}
                selectedTasks={selectedTasks}
                setDeleteSelected={setDeleteSelected}
                setShowOnlyCompletedTasks={setShowOnlyCompletedTasks}
                showOnlyCompletedTasks={showOnlyCompletedTasks}
                projectTypeList={projectTypeList}
                setShowAdvanceFilterModal={setShowAdvanceFilterModal}
                setServerFilters={setServerFilters}
                serverFilters={serverFilters}
                setFilterType={setFilterType}
                filterType={filterType}
                selectedDateFilter={selectedDateFilter}
                setSelectedDateFilter={setSelectedDateFilter}
                userProfile={userProfile}
                runTour={runTour}
              />
              <button
                className="download-excel-btn d-flex align-items-center"
                onClick={handleDownloadExcel}
                disabled={excelLoad || runTour}
              >
                <i className="fa fa-download m-0" aria-hidden="true"></i>
                {excelLoad ? (
                  <Spinner size="sm" className="ms-1 global-spinner" />
                ) : (
                  ""
                )}
              </button>
            </div>
          )}
          {selectedTasks.length > 0 && (
            <TasksSelectedSection
              selectedTasks={selectedTasks}
              taskListToFilter={taskListToFilter}
              setDeleteSelected={setDeleteSelected}
              setSelectedTasks={setSelectedTasks}
              projectUserList={userList}
              projectStatusList={projectStatusList}
              taskPriorities={taskPriorities}
              isDark={props.isDark}
              getTaskList={() => {}}
              userProfile={userProfile}
            />
          )}
          {load || shouldShowLoading ? (
            <div className="position-relative">
              <div className="ms-4">
                <ShimmerTable row={5} />
              </div>
            </div>
          ) : taskList?.data?.length > 0 ? (
            <>
              <TasksListingTable
                props={props}
                rowData={taskListToFilter}
                totalTasks={taskList?.meta?.total}
                boardColumnData={boardColumnData}
                setSelectedTask={setSelectedTask}
                selectedTask={selectedTask}
                filters={filters}
                setFilters={setFilters}
                setListToFilter={setTaskList}
                taskList={taskListToFilter}
                setTaskList={setTaskListToFilter}
                getStatusCounts={getStatusCounts}
                updateCountFilters={updateCountFilters}
                selectedTasks={selectedTasks}
                setSelectedTasks={setSelectedTasks}
                listingType="ALL_TASKS"
                pageParams={{ page: currentPage, limit: pageSize }}
                userList={userList}
                order={order}
                updateOrder={updateOrder}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                updateTaskData={updateTaskData}
                updateTaskMemberData={updateTaskMemberData}
                disableAction={isUpdating || isMemberUpdating}
                showTaskDetails={showTaskDetails}
                setShowTaskDetails={setShowTaskDetails}
                newId={newId}
                setNewId={setNewId}
                getTaskList={() => {}}
                setReadLoad={setReadLoad}
                setWasUnread={setWasUnread}
              />
            </>
          ) : (
            <p className="mt-2 ms-4 project-listing-no-data-text">
              {taskList?.data?.length < 1 && isPermitted("task/create-new") && (
                <>
                  Start your first{" "}
                  <span onClick={() => setShow(true)}>task</span> and assign it
                  to member/team.
                </>
              )}
              {taskList?.data?.length < 1 && <>No task found.</>}
            </p>
          )}
          {/* Pagination */}
          <div className="d-flex pagination-section">
            {taskList?.data?.length > 0 && (
              <div className="ms-4 d-flex records-per-page">
                <div className="d-flex align-items-center record-project-wrap">
                  Records Per Page{" "}
                  <div className="form-group">
                    <Select
                      className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div`}
                      classNamePrefix="select"
                      value={{
                        label: pageSize,
                        value: pageSize,
                      }}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,

                          backgroundColor: state.isSelected
                            ? "#deebff"
                            : "#fff",
                        }),
                      }}
                      options={pageSizeList
                        ?.filter((item) => item !== pageSize)
                        .map((res, index) => ({
                          label: res,
                          value: res,
                        }))}
                      menuPlacement="auto"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageSize(e.value);
                        // Store the page size in session storage
                        sessionStorage.setItem("task_page_limit", e.value);
                      }}
                      components={{
                        Control: ({ children, menuIsOpen, ...props }) => {
                          return (
                            <components.Control {...props}>
                              {props.hasValue ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ color: "blue !important" }}>
                                    {children}
                                  </div>
                                </div>
                              ) : (
                                children
                              )}
                              <div
                                className="select-icon me-1"
                                style={{
                                  transform: menuIsOpen
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                  transition: "transform 0.3s ease",
                                }}
                              >
                                <IoIosArrowDown />
                              </div>
                            </components.Control>
                          );
                        },
                      }}
                    ></Select>
                  </div>
                </div>
              </div>
            )}
            {totalPages > 1 && (
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
        </>

        {show && (
          <CreateTaskModal
            show={show}
            handleCloseModal={handleCloseModal}
            isDark={props.isDark}
            getBoardData={getBoardData}
            getTaskList={() => {}}
            pageSize={pageSize}
            currentPage={currentPage}
            setShowTaskDetails={setShowTaskDetails}
            setNewId={setNewId}
            listing="TASK"
          />
        )}
        {showProject && (
          <ProjectNewModal
            show={showProject}
            handleCloseModal={() => setShowProject(false)}
            isDark={props.isDark}
            getProjectList={() => {}}
            creation={true}
          />
        )}
        {showAdvanceFilterModal && (
          <TasksAdvanceSearchModalMain
            showModal={showAdvanceFilterModal}
            setShowModal={setShowAdvanceFilterModal}
            data={taskListToFilter}
            isDark={props.isDark}
            filters={serverFilters}
            setFilters={setServerFilters}
            projectUserList={userList}
            projectStatusList={projectStatusList}
            projectTypeList={projectTypeList}
            taskPriorities={taskPriorities}
            taskTypes={taskTypes}
            showRefresh={isShowRefresh}
            refreshFilters={refreshFilters}
            showOnlyCompletedTasks={showOnlyCompletedTasks}
            initialFormData={initialFormData}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {seeMore && (
          <SeeMoreModal
            seeMore={seeMore}
            setSeeMore={setSeeMore}
            headTextOne="Tasks"
            headTextTwo=" Management"
            isDark={props.isDark}
            modalContent={projectsListingSeeMoreModalContent}
          />
        )}
        {deleteSelected && (
          <DeleteSelectedTasksModal
            showDeleteModal={deleteSelected}
            handleCloseDeleteModal={handleCloseDeleteSelected}
            isDark={props.isDark}
            setListToFilter={setTaskList}
            setTaskList={setTaskListToFilter}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
            listingType="ALL_TASKS"
            getTaskList={() => {}}
          />
        )}
        {showTaskDetails && (
          <TaskDetailOffCanvas
            show={showTaskDetails}
            handleCloseModal={handleTaskDetailsCloseModal}
            isDark={props.isDark}
            selectedTask={selectedTask || newId}
            rowData={taskListMain}
            setRowData={() => {}}
            taskList={taskList}
            setTaskList={setTaskList}
            getStatusCounts={getStatusCounts}
            // updateTaskData={updateTask}
            listingType="ALL_TASKS"
            pageParams={{ page: currentPage, limit: pageSize }}
            getTaskList={() => {}}
            wasUnread={wasUnread}
            setWasUnread={setWasUnread}
          />
        )}
      </div>
    </>
  );
};

export default Tasks;
