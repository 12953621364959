/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, Pagination } from "react-bootstrap";
import Button from "../../../components/Button";
import Caret from "../../../assets/caret-table-icon.svg";
import SearchNew from "../../../assets/search-new-icon.svg";
import SearchCross from "../../../assets/cross-search.png";
import TeamList from "./TeamList";
import NewTeam from "./NewTeam";
import MemberList from "./MemberList";
import { useDispatch, useSelector } from "react-redux";
import {
  projectFormListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import { ProjectAPI } from "../../../services/ClientApi";
import ProjectNewModal from "../../Projects/components/ProjectNewModal";
import CreateTaskOffcanvas from "../../Tasks/components/CreateTaskOffcanvas";
import { Tooltip } from "react-tooltip";
import { isPermitted } from "../../../helper/isPermitted";
import { ShimmerTable } from "react-shimmer-effects";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import { useGetMemberListingQuery } from "../../../slices/members/membersSlice";
import { useGetTeamListingQuery } from "../../../slices/teams/teamsSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import CreateIcon from "../../../assets/create-task-icon.png";
import CreateTaskModal from "../../Tasks/components/CreateTaskModal";

const TeamMain = (props) => {
  const dispatch = useDispatch();
  const [filterType, setFilterType] = useState(
    sessionStorage.getItem("filterTypeMember") || "all"
  );
  const { data: membersList, isLoading: load } = useGetMemberListingQuery({
    type: filterType,
  });
  const { data: teamsList } = useGetTeamListingQuery();
  // const [load, setLoad] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [showProj, setShowProj] = useState(false);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const [teamList, setTeamList] = useState([]);
  const [teamToFilterList, setTeamToFilterList] = useState([]);
  const [show, setShow] = useState(false);
  const [create, setCreate] = useState(0);
  const [filters, setFilters] = useState({
    searchQuery: "",
    pinnedState: false,
  });
  const [filtersMembers, setFiltersMembers] = useState({
    searchQuery: "",
  });

  const [pageSize, setPageSize] = useState(50); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // member original list
  const [memberToFilterList, setMemberToFilterList] = useState([]);
  // copy of member original list
  const [memberList, setMemberList] = useState([]);

  const [showRefresh, setShowRefresh] = useState(
    sessionStorage.getItem("filterTypeMember") ? true : false
  );

  useEffect(() => {
    if (
      localStorage.getItem(
        "task-management-timeline-handle-type-assigned-members"
      ) &&
      JSON.parse(
        localStorage.getItem(
          "task-management-timeline-handle-type-assigned-members"
        )
      ) === 1 &&
      JSON.parse(
        localStorage.getItem("task-management-timeline-handle-type")
      ) === 1
    ) {
      setMemberList(
        // eslint-disable-next-line array-callback-return
        memberToFilterList.filter((member) => {
          if (member?.projectCount > 0 || member?.taskCount > 0) {
            return member;
          }
        })
      );
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem(
      "task-management-timeline-handle-type-assigned-members"
    ),
    memberToFilterList,
  ]);

  const handleClose = () => {
    setShow(false);
    setCreate(0);
  };

  const handleCloseTaskModal = () => {
    setShowTask(false);
  };

  const handleCreate = (value) => {
    setCreate(value);
  };

  const [type, setType] = useState(
    isPermitted("teams/members-listing")
      ? localStorage.getItem("task-management-timeline-handle-type")
        ? JSON.parse(
            localStorage.getItem("task-management-timeline-handle-type")
          )
        : 1
      : 0
  );

  const handleCloseModal = () => {
    setShowProj(false);
  };

  const handleType = (value) => {
    setType(value);
    setCurrentPage(1);
    localStorage.removeItem("task-management-timeline-handle-type");
    localStorage.removeItem(
      "task-management-timeline-handle-type-assigned-members"
    );
    setShowRefresh(false);
    setMemberList(memberToFilterList);
    setFilters({ ...filters, searchQuery: "" });
    setFiltersMembers({
      ...filtersMembers,
      searchQuery: "",
    });
  };

  // Handler to update data based on searched query
  const handleSearchChange = async (event) => {
    setFilters({ ...filters, searchQuery: event.target.value });
    setFiltersMembers({ ...filtersMembers, searchQuery: event.target.value });
  };

  const filteredListing = async () => {
    let filteredList = [...teamToFilterList]; // Copy the original list

    // Apply search query filter
    filteredList = filteredList.filter((team) =>
      team?.teamName?.toLowerCase().includes(filters.searchQuery.toLowerCase())
    );

    // Sort by projectIsPined
    filteredList.sort((a, b) => {
      if (a.isPinned && !b.isPinned) {
        return -1;
      }
      if (!a.isPinned && b.isPinned) {
        return 1;
      }
      return 0;
    });
    setTeamList(filteredList);
  };

  const filteredListingMember = async () => {
    let filteredList = [...memberToFilterList]; // Copy the original list

    // Apply search query filter
    filteredList = filteredList.filter((team) =>
      team?.userName?.toLowerCase().includes(filters.searchQuery.toLowerCase())
    );
    setMemberList(filteredList);
  };

  // Function to convert projectMembers array
  const convertProjectArray = (projectArray) => {
    return projectArray?.map((project) => ({
      id: project.id,
      value: project.projectName,
      label: project.projectName,
    }));
  };

  // Function to convert projectUserListing array
  const convertProjectUserListingArray = (projectUserListingArray) => {
    return projectUserListingArray?.map((user) => ({
      id: user.userId,
      value: user.userName,
      label: user.userName,
    }));
  };

  const teamDataAfterUpdate = async () => {
    try {
      const response = await ProjectAPI.post("teams/listing");
      if (response?.status === 201) {
        // Extracting teamInfo, teamMembersDetails, and projects from each team object
        const modifiedData = response?.data?.teams
          .map((team) => ({
            ...team.teamInfo,
            teamMembers: team.teamMembersDetails.users.map((user) => ({
              ...user,
            })),
            projects: team.projects.map((project) => ({ ...project })),
            recentTaskCount: team?.recentTaskCount,
            projectCompleteCount: team?.projectCompleteCount,
            projectNotStartedCount: team?.projectNotStartedCount,
            projectInProgressCount: team?.projectInProgressCount,
            teamProgress: team?.teamProgress,
            projectsCount: team?.projectsCount,
          }))
          .reverse();
        setTeamToFilterList(modifiedData);
        setTeamList(modifiedData);
      }
    } catch (error) {
    } finally {
    }
  };

  // Get member list data
  const getMemberData = async () => {
    try {
      const data = membersList?.map((member) => ({ ...member })) || [];
      setMemberToFilterList(data);
      setMemberList(data);
    } catch (error) {
    } finally {
      dispatch(projectUserListingAction());
      // dispatch(projectListingAction());
      dispatch(projectFormListingAction());
    }
  };

  // Get member list data
  const getTeamData = async () => {
    try {
      const data = teamsList?.map((member) => ({ ...member })) || [];

      setTeamToFilterList(data);
      setTeamList(data);
    } catch (error) {
    } finally {
      // dispatch(projectUserListingAction());
      // // dispatch(projectListingAction());
      // dispatch(projectFormListingAction());
    }
  };

  useEffect(() => {
    getMemberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersList]);

  useEffect(() => {
    filteredListingMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberToFilterList, filtersMembers, currentPage]);

  useEffect(() => {
    getTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsList]);

  useEffect(() => {
    filteredListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, teamToFilterList, currentPage]);

  // Modified project and user listing data structure required for team/member creation form
  const newProjectList = convertProjectArray(projectFormList);
  const newUsersList = convertProjectUserListingArray(projectUserList?.data);

  // Calculate total number of pages
  const totalPages =
    type === 0
      ? Math.ceil(teamList.length / pageSize)
      : Math.ceil(memberList.length / pageSize);

  // Handle pagination click
  const handlePageChange = (pageNumber) => {
    if (pageNumber === "first") {
      setCurrentPage(1);
    } else if (pageNumber === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (pageNumber === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else if (pageNumber === "last") {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems =
    memberList?.length > 0 &&
    memberList?.slice(indexOfFirstItem, indexOfLastItem);
  const currentTeamItems =
    teamList?.length > 0 && teamList?.slice(indexOfFirstItem, indexOfLastItem);

  // Page size list
  const pageSizeList = [5, 10, 20, 50, 100];

  return (
    <div className="project-plan-main-wraper">
      <Tooltip id="my-tooltip-team" place="right" />
      <div className="project-detail-top-heading-wrap">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h3 className="ms-4 projects-detail-heading-wrap mb-0">
              {" "}
              {type === 0 ? "Teams" : "Members"}
              {isPermitted("task/create-new") && (
                <button
                  className="create-btn"
                  data-tooltip-id="my-tooltip-team"
                  data-tooltip-content="Create task"
                  onClick={(e) => {
                    setShowTask(true);
                  }}
                >
                  {" "}
                  <img src={CreateIcon} alt="create-icon" /> Create{" "}
                </button>
              )}
            </h3>

            <div className="timeline-listing-search">
              <ul>
                <li className="position-relative">
                  <div className="form-group">
                    {filters?.searchQuery ? (
                      <button
                        onClick={() => {
                          setFilters({ ...filters, searchQuery: "" });
                          setFiltersMembers({
                            ...filtersMembers,
                            searchQuery: "",
                          });
                        }}
                      >
                        <img src={SearchCross} alt="search-icon" />
                      </button>
                    ) : (
                      <button>
                        <img src={SearchNew} alt="search-icon" />
                      </button>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={filters?.searchQuery}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    />
                  </div>
                  {filters?.searchQuery ? (
                    <p className="mb-0 record-count-under-search">
                      {type === 0
                        ? currentTeamItems?.length
                        : currentItems?.length}{" "}
                      {type === 0
                        ? currentTeamItems?.length === 1
                          ? "record"
                          : "records"
                        : currentItems?.length === 1
                        ? "record"
                        : "records"}
                    </p>
                  ) : (
                    ""
                  )}
                </li>
                {/* <li>
                  <Button
                    icon="fa-solid fa-filter"
                    text="Filters"
                    handleClick={() => {}}
                    margin="0px 5px 0px 0px"
                  />
                </li> */}
                {/* {isPermitted("project/pm-create-project") && (
                  <li>
                    <Button
                      icon="fa-solid fa-add"
                      text={"Add Project"}
                      handleClick={() => {
                        setShowProj(true);
                      }}
                      margin="0px 5px 0px 0px"
                    />
                  </li>
                )} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="ms-4 task-main-info-list d-sm-flex d-none">
        <ul>
          {isPermitted("teams/members-listing") && (
            <li>
              {" "}
              <a
                className={`team-filter-option ${
                  type === 1 ? "team-selected-filter-options" : ""
                }`}
                onClick={() => handleType(1)}
              >
                {" "}
                Members ({memberToFilterList.length})
              </a>{" "}
            </li>
          )}
          <li>
            {" "}
            <a
              className={`team-filter-option ${
                type === 0 ? "team-selected-filter-options" : ""
              }`}
              onClick={() => handleType(0)}
            >
              {" "}
              Teams ({teamToFilterList.length})
            </a>
          </li>
          {isPermitted("teams/create") && (
            <li>
              <Button
                icon="fa-solid fa-users"
                text="Create Team"
                handleClick={() => {
                  setShow(true);
                }}
                margin="0px 5px 0px 0px"
              />
            </li>
          )}
          {showRefresh && (
            <li
              className="filter-refresh-icon ms-3 ps-3 cursor-pointer"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Reset filters"
              onClick={() => {
                localStorage.removeItem(
                  "task-management-timeline-handle-type-assigned-members"
                );
                localStorage.removeItem("task-management-timeline-handle-type");
                setShowRefresh(false);
                setMemberList(memberToFilterList);
              }}
            >
              <Icon
                icon="bx:refresh"
                onClick={() => {
                  localStorage.removeItem(
                    "task-management-timeline-handle-type-assigned-members"
                  );
                  localStorage.removeItem(
                    "task-management-timeline-handle-type"
                  );
                  setShowRefresh(false);
                  setMemberList(memberToFilterList);
                }}
              />{" "}
              <span
                className="refresh-text"
                onClick={() => {
                  localStorage.removeItem(
                    "task-management-timeline-handle-type-assigned-members"
                  );
                  localStorage.removeItem(
                    "task-management-timeline-handle-type"
                  );
                  setFilterType("all");
                  sessionStorage.removeItem("filterTypeMember");
                  setShowRefresh(false);
                  setMemberList(memberToFilterList);
                }}
              >
                Clear
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className="d-sm-none d-flex">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            variant="secondary"
            id="color-dropdown"
            className="no-arrow-dd "
          >
            <img
              className=""
              src={Caret}
              width={40}
              height={40}
              alt="user-listing-icons"
            />
          </Dropdown.Toggle>{" "}
          <Dropdown.Menu>
            <Dropdown.Item>
              <div className="d-flex align-items-center gap-2 tasks-main-info-list-font-weight ">
                Team (10)
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Groups (15)
            </Dropdown.Item>
            <Dropdown.Item>
              <Button
                icon="fa-solid fa-filter"
                text="Create Group"
                handleClick={() => {}}
                margin="0px 5px 0px 0px"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {load ? (
        <div className="position-relative">
          <div className="ms-4">
            <ShimmerTable row={5} />
          </div>
        </div>
      ) : type === 0 ? (
        currentTeamItems?.length > 0 ? (
          <TeamList
            handleCreate={handleCreate}
            setShow={setShow}
            isDark={props.isDark}
            teamList={currentTeamItems}
            setTeamList={setTeamList}
            setTeamToFilterList={setTeamToFilterList}
            teamToFilterList={teamToFilterList}
            filters={filters}
            setFilters={setFilters}
            projectList={newProjectList}
            projectUserList={newUsersList}
          />
        ) : (
          <p className="ms-4 no-team-data">
            No team data found.{" "}
            {isPermitted("teams/create") && (
              <>
                Create team from{" "}
                <span
                  onClick={() => {
                    if (type === 1) {
                      setCreate(1);
                    }
                    setShow(true);
                  }}
                >
                  here
                </span>
              </>
            )}
          </p>
        )
      ) : (
        <>
          {memberList.length > 0 ? (
            <MemberList
              isDark={props.isDark}
              memberList={currentItems}
              setMemberList={setMemberList}
              memberToFilterList={memberToFilterList}
            />
          ) : (
            <p className="ms-4 no-team-data">
              Add new members and invite them in your team.
            </p>
          )}
        </>
      )}
      <div className="d-flex pagination-section">
        {type !== 0 && memberToFilterList?.length > 0 && (
          <div className="ms-4 d-flex records-per-page">
            <div className="d-flex align-items-center record-project-wrap">
              Records Per Page{" "}
              <div className="form-group">
                <Select
                  className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div`}
                  classNamePrefix="select"
                  value={{
                    label: pageSize,
                    value: pageSize,
                  }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={false}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,

                      backgroundColor: state.isSelected ? "#deebff" : "#fff",
                    }),
                  }}
                  options={pageSizeList?.filter((item) => item !== pageSize).map((res, index) => ({
                    label: res,
                    value: res,
                  }))}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setPageSize(e.value);
                  }}
                  components={{
                    Control: ({ children,menuIsOpen, ...props }) => {
                      return (
                        <components.Control {...props}>
                          {props.hasValue ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ color: "blue !important" }}>
                                {children}
                              </div>
                            </div>
                          ) : (
                            children
                          )}
                          <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                            <IoIosArrowDown />
                          </div>
                        </components.Control>
                      );
                    },
                  }}
                ></Select>
              </div>
            </div>
          </div>
        )}
        {type === 0 && teamToFilterList?.length > 0 && (
          <div className="ms-4 d-flex records-per-page">
            <div className="d-flex align-items-center record-project-wrap">
              Records Per Page{" "}
              <div className="form-group">
                <Select
                  className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div`}
                  classNamePrefix="select"
                  value={{
                    label: pageSize,
                    value: pageSize,
                  }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={false}
                  options={pageSizeList?.filter((item) => item !== pageSize).map((res, index) => ({
                    label: res,
                    value: res,
                  }))}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setPageSize(e.value);
                  }}
                  components={{
                    Control: ({ children,menuIsOpen, ...props }) => {
                      return (
                        <components.Control {...props}>
                          {props.hasValue ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ color: "blue !important" }}>
                                {children}
                              </div>
                            </div>
                          ) : (
                            children
                          )}
                          <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                            <IoIosArrowDown />
                          </div>
                        </components.Control>
                      );
                    },
                  }}
                ></Select>
              </div>
            </div>
          </div>
        )}
        {type !== 0 && memberList?.length > pageSize && (
          <Pagination className="ms-4 align-items-center records-per-page">
            <Pagination.First onClick={() => handlePageChange("first")} />
            <Pagination.Prev onClick={() => handlePageChange("prev")} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange("next")} />
            <Pagination.Last onClick={() => handlePageChange("last")} />
          </Pagination>
        )}
        {type === 0 && teamList?.length > pageSize && (
          <Pagination className="ms-4 align-items-center records-per-page">
            <Pagination.First onClick={() => handlePageChange("first")} />
            <Pagination.Prev onClick={() => handlePageChange("prev")} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange("next")} />
            <Pagination.Last onClick={() => handlePageChange("last")} />
          </Pagination>
        )}
      </div>
      {show && (
        <NewTeam
          create={create}
          show={show}
          handleClose={handleClose}
          isDark={props.isDark}
          handleCreate={handleCreate}
          projectList={newProjectList}
          projectUserList={newUsersList}
          teamToFilterList={teamToFilterList}
          setTeamToFilterList={setTeamToFilterList}
          teamList={teamList}
          setTeamList={setTeamList}
          teamDataAfterUpdate={teamDataAfterUpdate}
        />
      )}
      {showProj && (
        <ProjectNewModal
          show={showProj}
          handleCloseModal={handleCloseModal}
          isDark={props.isDark}
          getProjectList={() => {}}
          creation={true}
        />
      )}
      {showTask && (
        <CreateTaskModal
          show={showTask}
          handleCloseModal={handleCloseTaskModal}
          isDark={props.isDark}
          getBoardData={() => {}}
          setShowTaskDetails={() => {}}
          setNewId={() => {}}
          listing="TEAM"
        />
      )}
    </div>
  );
};

export default TeamMain;
