import React, { useEffect, useState } from "react";
import PlanProjectPhaseDetailSectionTable from "./PlanProjectPhaseDetailSectionTable";
import { dateFormatter } from "../../../../helper/dateFormatter";
import Button from "../../../../components/Button";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createProjectPhaseAction } from "../../../../actions/projectPhaseActions";
import { Spinner } from "react-bootstrap";
import {
  getTasksAllPhases,
  updateProject,
} from "../../services/ProjectDetailsMainServices";
import { Link } from "react-router-dom";
import getProjectIconById from "../../../../helper/projectIconGenerator";
import { isPermitted } from "../../../../helper/isPermitted";
import { isOwnerInProjectOne } from "../../../../helper/projectOwner";
import { isAdmin } from "../../../../helper/isUserAdmin";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useGetProjectPhaseTaskListingMutation } from "../../../../slices/tasks/projectDetailSectionSlice";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import API from "../../../../services/ClientApi";
import { userProfilePage } from "../../../../actions/userActions";
import { calculateAverageProgress } from "../../../../helper/taskHelpers";
import { useIsMobile } from "../../../../helper/windowHelper";
import Calendar from "react-calendar";
import { BottomSheet } from "react-spring-bottom-sheet";

const PlanProjectPhaseDetailSection = (props) => {
  const dispatch = useDispatch();
  const [getProjectPhaseTaskListing] = useGetProjectPhaseTaskListingMutation();
  const [edit, setEdit] = useState(null);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState([
    props?.projectData?.projectStartDate,
    props.projectData?.projectEndDate,
  ]);
  const { isMobile } = useIsMobile();
  const { loading: createProjectPhaseLoading } = useSelector(
    (state) => state.createProjectPhaseData
  );
  const [projectAllPhasesTasks, setProjectAllPhasesTasks] = useState([]);

  const [taskAllPhasesLoading, setTaskAllPhasesLoading] = useState(false);
  const [showTour, setShowTour] = useState(false);

  const { userProfile } = useSelector((state) => state?.profile);
  const [overAllProgress, setOverAllProgress] = useState(0);

  // in this state i have all project phases data
  const [data, setData] = useState([]);
  const [runTour, setRunTour] = useState(false);

  // Tour steps
  const steps = [
    {
      element: ".project-details-main-timeline-date-filter",
      intro: "Set project complete duration.",
      position: "left",
    },

    {
      element: ".add-new-phase-container",
      intro: "Quickly add task in phase.",
      position: "right",
    },
  ];

  if (
    (isPermitted("project/pm-update-project-phase") &&
      isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
    isAdmin(userProfile)
  ) {
    steps.push({
      element: ".phase-edit-btn-step",
      intro: "Edit name of project phase.",
      position: "right",
    });
  }

  // Conditionally add the last step
  if (
    (isPermitted("project/pm-update-project") &&
      isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
    isAdmin(userProfile)
  ) {
    steps.push({
      element: ".project-plan-main-wraper .custom-button-main",
      intro: "Add new phases as per your requirements.",
      position: "right",
    });
  }

  const getAllProjectPhases = async (load = false) => {
    try {
      if (props.projectId) {
        try {
          if (load) {
            setTaskAllPhasesLoading(true);
          }
          const response = await getProjectPhaseTaskListing({
            projectId: props.projectId,
          });
          if (response?.data?.status === "success") {
            setProjectAllPhasesTasks(response?.data?.data);
          }
        } catch (error) {
        } finally {
          setTaskAllPhasesLoading(false);
        }
      }
    } catch (error) {}
  };

  // update the data based on projectsPhasesList
  useEffect(() => {
    if (projectAllPhasesTasks) {
      setData(projectAllPhasesTasks);
    }
  }, [projectAllPhasesTasks]);

  useEffect(() => {
    let loadState = props?.memberSuccess ? false : true;
    getAllProjectPhases(loadState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberSuccess]);

  const addAnotherPhase = async () => {
    if (data.length === 0 || data === null) {
      // If no phases exist, create a new project with an "Untitled" phase
      const response = await dispatch(
        createProjectPhaseAction({
          projectId: props.projectId,
          phaseName: "title-1",
          phaseDisplayOrder: 1,
        })
      );
      if (response) {
        setData([
          {
            phaseName: response?.data?.phaseName,
            id: response?.data?.phaseID,
            phaseDisplayOrder: response?.data?.phaseDisplayOrder,
          },
        ]);
        setEdit(response.phaseID);
      }
    } else {
      const lastPhase = data[data?.length - 1];
      const lastTaskGroupName = lastPhase.phaseName;
      const lastUntitledIndex = lastTaskGroupName.lastIndexOf("title-");
      let lastUntitledNumber = 0;
      if (lastUntitledIndex !== -1) {
        lastUntitledNumber = parseInt(
          lastTaskGroupName.substring(lastUntitledIndex + 6)
        );
      }

      const newTaskGroupName =
        lastUntitledIndex === -1
          ? "title-1"
          : `title-${lastUntitledNumber + 1}`;

      const response = await dispatch(
        createProjectPhaseAction({
          projectId: props.projectId,
          phaseName: newTaskGroupName,
          phaseDisplayOrder: projectAllPhasesTasks?.length,
        })
      );

      if (response) {
        setData([
          ...data,
          {
            phaseName: response?.data?.phaseName,
            id: response?.data?.phaseID,
            phaseDisplayOrder: projectAllPhasesTasks?.phaseDisplayOrder,
          },
        ]);
        setEdit(response?.data?.phaseID);
      }
    }
    // await getAllProjectPhases(false);
  };

  const handleTimelineChange = async (
    projectId,
    [newStartDate, newEndDate]
  ) => {
    const fieldStartData = {
      projectId: projectId,
      fieldName: "projectStartDate",
      fieldValues: fullDateToString(newStartDate),
    };
    const fieldEndData = {
      projectId: projectId,
      fieldName: "projectEndDate",
      fieldValues: fullDateToString(newEndDate),
    };

    newStartDate && (await updateProject(fieldStartData, false));
    newEndDate && (await updateProject(fieldEndData, true));
  };

  const fullDateToString = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based
    const year = inputDate.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // Run tour journey
  useEffect(() => {
    if (userProfile?.phaseListingTour && showTour) {
      setRunTour(true);
    }
  }, [userProfile, showTour]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton")
        // ||
        // event.target.classList.contains("introjs-donebutton")
      ) {
        try {
          setRunTour(false); // Close the tour
          const response = await API.post("users/update-profile", {
            userId: userProfile?.userId,
            fieldName: "phaseListingTour", // Add the field name for the specific input field
            fieldValues: false, // Add the corresponding state variable here
          });
          if (response?.status === 200) {
            await dispatch(userProfilePage());
          }
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
  }, []);

  // Calculate overall progress
  useEffect(() => {
    if (data) {
      const allTasks = data?.flatMap((phase) => phase.tasks);
      const progress = calculateAverageProgress(allTasks);
      setOverAllProgress(isNaN(progress) ? 0 : progress);
    }
  }, [data]); // Dependency array includes `data`

  return (
    <div className="project-plan-main-wraper project-offcanvas-main-wraper">
      {(props.isUpdating || props.isMemberLoading) && (
        <div className="create-task-is-loading"></div>
      )}
      <div className="project-detail-top-heading-wrap">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
            <div className="proj-detail-header-wrap proj-off-detail-header-wrap gap-2 flex-wrap">
              <div className="d-flex align-items-center">
                <img
                  className="proj-detail-header-img"
                  src={
                    getProjectIconById(props?.projectData?.projectIcon)
                      ? getProjectIconById(props?.projectData?.projectIcon)
                      : props?.projectData?.projectIcon
                  }
                  alt="projectIcon"
                />
                <h4 className="ms-2 pt-0">
                  {" "}
                  {props?.projectName}{" "}
                  <span>
                    {" "}
                    | Project <span>Plan</span>{" "}
                  </span>{" "}
                </h4>
              </div>
              <div className="progress-detail">
                <div
                  className="progress-bar-timeline progress-bar-timeline-color"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${overAllProgress}%` }}
                >
                  {" "}
                  <span className="sr-only">
                    {overAllProgress}% Complete
                  </span>{" "}
                </div>
              </div>
            </div>
            {/* timeline date picker */}
            <div
              data-theme={props.isDark}
              className="project-details-main-timeline-date-filter d-flex align-items-center justify-content-between create-task-deadline"
            >
              {(isPermitted("project/pm-update-project") &&
                isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
              isAdmin(userProfile) ? (
                <>
                  {isMobile ? (
                    <div
                      className="d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        setBottomSheetOpen(true);
                      }}
                    >
                      <span>
                        {" "}
                        <Icon icon="uis:calender" className="pe-auto" />
                      </span>

                      <p className="ms-1 mb-0">{`${dateFormatter(
                        selectedDateFilter[0]
                      )} to ${dateFormatter(selectedDateFilter[1])}`}</p>
                    </div>
                  ) : (
                    <span>
                      <Icon icon="uis:calender" className="pe-auto" />
                    </span>
                  )}
                  {isMobile ? (
                    <BottomSheet
                      open={bottomSheetOpen}
                      blocking={false}
                      onDismiss={() => setBottomSheetOpen(false)}
                    >
                      <div
                        className="react-calendar-container"
                        data-theme={props.isDark}
                      >
                        <Calendar
                          className="w-100 project-details-task-table-calendar"
                          selectRange={true}
                          onChange={(e) => {
                            const [startDate, endDate] = Array.isArray(e)
                              ? e
                              : [e, e];
                            if (startDate && endDate) {
                              setSelectedDateFilter([startDate, endDate]);
                              handleTimelineChange(props.projectId, [
                                startDate,
                                endDate,
                              ]);
                            }
                          }}
                          value={selectedDateFilter}
                        />
                      </div>
                    </BottomSheet>
                  ) : (
                    <Flatpickr
                      data-theme={props.isDark}
                      className="project-details-task-table-flatpickr-container"
                      placeholder="Select a timeline range"
                      defaultDate={selectedDateFilter}
                      options={{
                        altInput: true,
                        altFormat: "M d, Y",
                        dateFormat: "Y-m-d",
                        static: true,
                        disableMobile: true,
                        mode: "range",
                      }}
                      value={selectedDateFilter}
                      shouldCloseOnSelect={false}
                      onChange={([startDate, endDate]) => {
                        if (startDate && endDate) {
                          setSelectedDateFilter([startDate, endDate]);
                          handleTimelineChange(props.projectId, [
                            startDate,
                            endDate,
                          ]);
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <span>
                    {" "}
                    <Icon icon="uis:calender" className="pe-auto" />
                  </span>
                  <p className="ms-1 mb-0">{`${dateFormatter(
                    selectedDateFilter[0]
                  )} to ${dateFormatter(selectedDateFilter[1])}`}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <p>
          Draw outline of the project; splitting it into milestones/phases and
          manage tasks accordingly. It provides you complete project cycle
        </p>
      </div>
      {taskAllPhasesLoading ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
            <ShimmerThumbnail height={50} />
          </div>
          <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
            <ShimmerThumbnail height={50} />
          </div>
          <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
            <ShimmerThumbnail height={50} />
          </div>
        </div>
      ) : data.length > 0 ? (
        <div className="project-plan-table-wrap">
          {runTour && (
            <Steps
              enabled={runTour}
              steps={steps}
              initialStep={0}
              options={{
                showProgress: false,
                showStepNumbers: false,
                exitOnOverlayClick: false,
                scrollToElement: false,
                exitOnEsc: true,
                nextLabel: "Next",
                prevLabel: "Skip Tour",
                tooltipClass: "tour-custom-tooltip", // Optional custom styling
                disableInteraction: true,
              }}
              onExit={async () => {
                try {
                  setRunTour(false); // Close the tour
                  const response = await API.post("users/update-profile", {
                    userId: userProfile?.userId,
                    fieldName: "phaseListingTour", // Add the field name for the specific input field
                    fieldValues: false, // Add the corresponding state variable here
                  });
                  if (response?.status === 200) {
                    await dispatch(userProfilePage());
                  }
                } catch (error) {
                  console.error("Error updating profile:", error);
                }
              }} // Reset the tour state when exited
            />
          )}
          <ul className="project-listing-detail-section-list">
            {data?.map((res, id) => (
              <li
                key={id}
                className={`${id === 0 ? "phase-first-section" : ""}`}
              >
                <PlanProjectPhaseDetailSectionTable
                  data={res}
                  phaseId={res?.id}
                  getAllProjectPhases={async () => {
                    await getAllProjectPhases(false);
                  }}
                  projectData={props.projectData}
                  projectAllPhasesTasks={projectAllPhasesTasks}
                  setProjectAllPhasesTasks={setProjectAllPhasesTasks}
                  projectId={props?.projectId}
                  isDark={props.isDark}
                  edit={edit}
                  setEdit={setEdit}
                  index={id}
                  updatePhaseTaskData={props.updatePhaseTaskData}
                  updatePhaseTaskMemberData={props.updatePhaseTaskMemberData}
                  setSelectedPhaseIdChange={props.setSelectedPhaseIdChange}
                  getTaskList={() => props.getTaskList(false)}
                  setReadLoad={props.setReadLoad}
                  setShowTour={setShowTour}
                  setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <p>No Project Phases Found.</p>
        </div>
      )}

      <div className="project-main-button-wrap">
        <div className="d-flex justify-content-between proj-detail-skip-section-wrap">
          {((isPermitted("project/pm-update-project") &&
            isOwnerInProjectOne(props.projectData, userProfile?.userId)) ||
            isAdmin(userProfile)) && (
            <div className="d-flex align-items-center">
              <Button
                icon="fa-solid fa-add"
                text="Add another phase"
                width="200px"
                handleClick={() => {
                  if (!createProjectPhaseLoading) {
                    addAnotherPhase();
                  }
                }}
                margin="0px 5px 0px 0px"
              />
              {createProjectPhaseLoading && (
                <Spinner
                  className="global-spinner"
                  isDark={props.isDark}
                  size="small"
                />
              )}
            </div>
          )}
          {props.isHideCreateAndSkip ? null : (
            <div className="">
              <div className="project-phase-right-btn">
                <ul className="mb-0">
                  <li>
                    {" "}
                    <Link
                      to={`/${localStorage.getItem(
                        "company"
                      )}/task-management-porojects`}
                    >
                      <button
                        className="skip-button-wrap"
                        onClick={() => {
                          props.handleCloseModal();
                          props.setModal(false);
                        }}
                      >
                        {" "}
                        Skip{" "}
                      </button>{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      to={`/${localStorage.getItem(
                        "company"
                      )}/task-management-porojects`}
                    >
                      <button
                        className="create-project-btn"
                        onClick={() => {
                          props.handleCloseModal();
                          props.setModal(false);
                        }}
                      >
                        {" "}
                        Create Project{" "}
                      </button>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanProjectPhaseDetailSection;
