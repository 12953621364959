import ExcelJS from "exceljs";
import { dateFormatter } from "./dateFormatter";

export const downloadProjectDataAsExcel = async (projects) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Project Tasks");

  // Define headers for the worksheet
  worksheet.addRow([
    "Project Name",
    "Project Privacy",
    "Start Date",
    "End Date",
    "Status",
    "Total Tasks",
    "Completed Tasks",
    "Pending Tasks",
    "Overdue Tasks",
    // "Project Progress",
    "Project Members",
  ]);

  // Adjust column widths dynamically
  worksheet.columns.forEach((column, index) => {
    const maxLength = Math.max(
      ...projects.map((project) => {
        const value = getCellValue(project, index);
        return value ? value.toString().length : 10;
      })
    );
    column.width = maxLength < 20 ? 20 : maxLength;
  });

  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFE9EEFF" },
  };

  // Add project data rows
  projects.forEach((project) => {
    const membersString =
      project.projectMembers
        ?.map((member) => `${member.userName} (${member.memberType})`)
        .join(", ") || "-";

    worksheet.addRow([
      project.projectName || "-",
      project.projectPrivacy || "-",
      project.projectStartDate
        ? dateFormatter(project.projectStartDate, 1)
        : "No Start Date",
      project.projectEndDate
        ? dateFormatter(project.projectEndDate, 1)
        : "No End Date",
      project.projectStatus?.name || "-",
      project.totalTasks ?? 0,
      project.completedTasks ?? 0,
      project.pendingTasks ?? 0,
      project.overDueTasks ?? 0,
      // `${project.projectProgress ?? 0}%`,
      membersString,
    ]);
  });

  // Generate Excel file and download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "project_list.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
};

// Staff summary download excel
export const downloadStaffSummaryAsExcel = async (
  staffData,
  totalTaskHeaderText
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Staff Summary");

  // Define headers for the worksheet
  const headers = [
    "Members",
    `${
      totalTaskHeaderText === "last_month"
        ? "Last Month"
        : totalTaskHeaderText === "this_week"
        ? "This Week"
        : totalTaskHeaderText === "this_month"
        ? "This Month"
        : totalTaskHeaderText === "all"
        ? "All Tasks"
        : totalTaskHeaderText
    }`,
    "Total Points",
    "Points Obtained",
    "Performance",
  ];

  // Add header row
  const headerRow = worksheet.addRow(headers);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE9EEFF" },
    };
    cell.alignment = { horizontal: "left" }; // Left align header
  });

  // Add staff data rows
  staffData.forEach((staff) => {
    const row = worksheet.addRow([
      staff.userName,
      staff.totalTask === 1 ? "1 task" : `${staff.totalTask} tasks`,
      staff.totalTask && staff.totalTask > 0 ? staff.totalMaxScore : "--",
      staff.totalTask && staff.totalTask > 0 ? staff.obtainedScore : "--",
      staff.totalTask && staff.totalTask > 0 ? `${staff.performance}%` : "--",
    ]);

    // Left align all values
    row.eachCell((cell) => {
      cell.alignment = { horizontal: "left" };
    });
  });

  // Adjust column widths dynamically
  worksheet.columns.forEach((column, index) => {
    const maxLength = Math.max(
      headers[index].length, // Length of header text
      ...worksheet
        .getColumn(index + 1)
        .values.slice(1)
        .map((value) => (value ? value.toString().length : 0))
    );
    column.width = Math.min(Math.max(maxLength + 2, 10), 30); // Ensure minimum width of 10 and cap at 30
  });

  // Generate Excel file and trigger download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "all_staff_summary_report.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
};

// Helper function to get cell value based on index
const getCellValue = (project, index) => {
  switch (index) {
    case 0:
      return project.projectName || "-";
    case 1:
      return project.projectPrivacy || "-";
    case 2:
      return project.projectStartDate
        ? dateFormatter(project.projectStartDate, 1)
        : "No Start Date";
    case 3:
      return project.projectEndDate
        ? dateFormatter(project.projectEndDate, 1)
        : "No End Date";
    case 4:
      return project.projectStatus?.name || "-";
    case 5:
      return project.totalTasks ?? 0;
    case 6:
      return project.completedTasks ?? 0;
    case 7:
      return project.pendingTasks ?? 0;
    case 8:
      return project.overDueTasks ?? 0;
    // case 9:
    //   return `${project.projectProgress ?? 0}%`;
    case 9:
      return (
        project.projectMembers
          ?.map((member) => `${member.userName} (${member.memberType})`)
          .join(", ") || "-"
      );
    default:
      return "N/A";
  }
};

// task
export const downloadTaskDataAsExcel = async (projects) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Tasks");

  // Define headers for the worksheet
  worksheet.addRow([
    "Task Name",
    "Priority",
    "Assigned to",
    "Team Members",
    "Due date",
    "Status",
    "Project",
    "Created",
    "Last updated",
  ]);

  // Adjust column widths dynamically
  worksheet.columns.forEach((column, index) => {
    const maxLength = Math.max(
      ...projects.map((project) => {
        const value = getCellTaskValue(project, index);
        return value ? value.toString().length : 10;
      })
    );
    column.width = maxLength < 20 ? 20 : maxLength;
  });

  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFE9EEFF" },
  };
  // Add project data rows
  projects.forEach((project) => {
    const membersString =
      safeGet(project.taskMembers, [])
        .map((member) => `${member.userName} (${member.memberType})`)
        .join(", ") || "-";

    const validMembers =
      project.taskMembers?.filter((user) => user.memberType === "member") || [];

    const selectedMember = validMembers[0]; // Pick the first valid member

    worksheet.addRow([
      safeGet(project.taskName, "-"), // Task Name
      safeGet(project.taskPriority?.priorityName, "-"), // Priority
      selectedMember?.userName,
      membersString, // Team Members
      dateFormatter(project.taskDeadline, 3), // Deadline
      safeGet(project.taskStatus?.statusName, "-"), // Status
      safeGet(project.project?.projectName, "-"), // Project
      dateFormatter(project.createdAt, 3), // created
      dateFormatter(project.updatedAt, 3), // last updated
    ]);
  });

  // Generate Excel file and download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "task_list.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
};

// Utility function to safely get values with a default fallback
const safeGet = (value, fallback = "") => (value != null ? value : fallback);

// Helper function to get cell value based on index
const getCellTaskValue = (project, index) => {
  const validMembers =
    project.taskMembers?.filter((user) => user.memberType === "member") || [];

  const selectedMember = validMembers[0]; // Pick the first valid member
  switch (index) {
    case 0:
      return safeGet(project.taskName, "-");
    case 1:
      return safeGet(project.taskPriority?.priorityName, "-");
    case 2:
      return safeGet(selectedMember?.userName, "-");
    case 3:
      return (
        safeGet(project.taskMembers, [])
          .map((member) => `${member.userName} (${member.memberType})`)
          .join(", ") || "-"
      );
    case 4:
      return dateFormatter(project.taskDeadline, 3);
    case 5:
      return safeGet(project.taskStatus?.statusName, "-");
    case 6:
      return safeGet(project.project?.projectName, "-");
    case 7:
      return dateFormatter(project.createdAt, 3);
    case 8:
      return dateFormatter(project.updatedAt, 3);
    default:
      return "-";
  }
};
