import React, { memo, useEffect, useRef, useState } from "react";
import "../styles/create-task-modal.css";
import { Modal, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import infoIcon from "../../../assets/Info_alt_light.png";
import { Tooltip } from "react-tooltip";
import { Editor } from "@tinymce/tinymce-react";
import {
  formatFileSize,
  getIdByDefaultMarkAs,
  getPriorityNameById,
  getStatusNameById,
  truncateText,
} from "../../../helper/taskHelpers";
import Select, { components } from "react-select";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { useCreateTaskDataMutation } from "../../../slices/tasks/tasksSlice";
import Avatar from "../../../assets/avater-user-default.png";
import moment from "moment-timezone";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import { formatDeadlineDate } from "../../../helper/dateFormatter";
import { formatTimeToAMPM } from "../../../helper/timeAgoFormatter";
import { v4 as uuidv4 } from "uuid";
import plusIcon from "../../../assets/plus.svg";
import { ShimmerThumbnail } from "react-shimmer-effects";

// Define allowed file extensions and max file size
const allowedExtensions = [
  "pdf",
  "jpeg",
  "jpg",
  "png",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "gif",
  "bmp",
  "svg",
  "zip",
  "rar",
  "mp3",
  "mp4",
];
const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB

// Validate file (check extension and size)
export const validateFile = (file) => {
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    toast.error(
      `Invalid file type: .${fileExtension}. Only the following are allowed: ${allowedExtensions.join(
        ", "
      )}.`,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  }
  if (file.size > maxFileSize) {
    toast.error(`File size exceeds the limit of 8 MB.`, {
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }
  return true;
};

// Helper function to calculate the total size of files
export const calculateTotalSize = (files) => {
  return files.reduce((total, file) => total + file.size, 0);
};

const CreateTaskModal = ({
  show,
  handleCloseModal,
  isDark,
  setShowTaskDetails,
  setNewId,
  listing = "TASK",
  projectId = null,
  getGrid = () => {},
  getTaskList = () => {},
}) => {
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const selectRef1 = useRef(null);
  const selectRef2 = useRef(null);
  const selectRef3 = useRef(null);
  const textRef = useRef();
  const [bulkTodoOpen, setBulkTodoOpen] = useState(false);
  // State to track which button was clicked
  const [isAddMoreInfo, setIsAddMoreInfo] = useState(false);
  const [addLoad, setAddLoad] = useState(false);
  const [createLoad, setCreateLoad] = useState(false);
  const [observerOptions, setObserverOptions] = useState([]);
  const [openDescription, setOpenDescription] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const [createTaskData] = useCreateTaskDataMutation();
  const [approval, setApproval] = useState(false);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const [files, setFiles] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState("19:00");
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  // Getting users
  const updateState = (typeName, users) => {
    // Ensure users is always an array, even for single user updates
    const userList = Array.isArray(users) ? users : [users];

    setObserverOptions(
      userList?.map((user) => ({
        id: user.userId,
        value: user.userName,
        label: user.userName,
      }))
    );
  };

  const getUsers = async () => {
    if (projectUserList?.data?.length > 0) {
      updateState("observer", projectUserList.data);
    }
  };

  // custom styles for priority
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.data.value.toString().toLowerCase() === "critical"
          ? "#FB3F3F"
          : state.data.value.toString().toLowerCase() === "highest"
          ? "#56BAEC"
          : state.data.value.toString().toLowerCase() === "medium"
          ? "#394B84"
          : state.data.value.toString().toLowerCase() === "low"
          ? "#2C2D2F"
          : state.data.value.toString().toLowerCase() === "in progress"
          ? "#2098D1"
          : state.data.value.toString().toLowerCase() === "not started"
          ? "#fd1b1b"
          : state.data.value.toString().toLowerCase() === "completed"
          ? "#2DBF64"
          : state.data.value.toString().toLowerCase() === "on hold"
          ? "#E8910D"
          : state.data.value.toString().toLowerCase() === "submit for review"
          ? "#EBA900"
          : " black",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "#e9eeff" : "transparent",
      "&:hover": {
        backgroundColor: "#e9eeff", // Set background color when hovering over the option
      },
      fontWeight: 300,
      zIndex: 99999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      // Set the desired width
    }),
  };

  // Status selection
  const CustomTasksStatusEditorControl = memo(
    ({ children, menuIsOpen, ...props }) => {
      return (
        <components.Control {...props}>
          {!props.hasValue && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 11 10"
              fill="none"
              className="me-1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.45145 3.08552H9.68506C9.85643 3.08552 9.9954 2.94657 9.9954 2.77518C9.9954 2.60379 9.85643 2.46484 9.68506 2.46484H7.22813C7.26549 2.68764 7.34246 2.89702 7.45145 3.08552ZM6.1031 3.08552C6.04059 2.88652 5.99758 2.67888 5.97635 2.46484H0.995639C0.824247 2.46484 0.685303 2.60379 0.685303 2.77518C0.685303 2.94657 0.824247 3.08552 0.995639 3.08552H6.1031ZM0.995639 5.56821C0.824247 5.56821 0.685303 5.70718 0.685303 5.87854C0.685303 6.04991 0.824247 6.18888 0.995639 6.18888H9.68506C9.85643 6.18888 9.9954 6.04991 9.9954 5.87854C9.9954 5.70718 9.85643 5.56821 9.68506 5.56821H0.995639ZM0.995639 8.67157C0.824247 8.67157 0.685303 8.81054 0.685303 8.98191C0.685303 9.15328 0.824247 9.29225 0.995639 9.29225H9.68506C9.85643 9.29225 9.9954 9.15328 9.9954 8.98191C9.9954 8.81054 9.85643 8.67157 9.68506 8.67157H0.995639Z"
                fill="#94ABFF"
              />
              <path
                d="M9.06429 4.01701C10.0927 4.01701 10.9263 3.18335 10.9263 2.15499C10.9263 1.12662 10.0927 0.292969 9.06429 0.292969C8.03592 0.292969 7.20227 1.12662 7.20227 2.15499C7.20227 3.18335 8.03592 4.01701 9.06429 4.01701Z"
                fill="#94ABFF"
              />
            </svg>
          )}
          {props.hasValue ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {projectStatusList?.map((res, index) => {
                if (res?.id === props?.getValue()[0]?.value) {
                  return (
                    <img
                      key={index}
                      src={
                        res?.defaultMarkAs === "not-started"
                          ? NotStarted
                          : res?.defaultMarkAs === "in-progress"
                          ? progressImage
                          : res?.defaultMarkAs === "on-hold"
                          ? reviewImage
                          : res?.defaultMarkAs === "review"
                          ? reviewIcon
                          : CompletedIcon
                      }
                      alt={res?.statusName}
                      style={{
                        width: 20,
                        marginLeft: 5,
                        marginRight: 4,
                        // marginTop: 1,
                      }}
                    />
                  );
                }
                return null;
              })}
              <div>{children}</div>
            </div>
          ) : (
            children
          )}
          <div
            className="select-icon me-1"
            style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <IoIosArrowDown />
          </div>
        </components.Control>
      );
    }
  );

  // Priority selection
  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {!props.hasValue && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 13 11"
            fill="none"
            className="me-1"
          >
            <path
              d="M1.12585 3.69297L3.52585 1.29297L5.92585 3.69297"
              stroke="#94ABFF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.52588 1.29297V10.293M11.9259 3.09297H8.92588M11.9259 6.09297H7.72588M11.9259 9.09297H5.92588"
              stroke="#94ABFF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorities?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "critical"
                        ? criticalImage
                        : res?.defaultMarkAs === "highest"
                        ? highestImage
                        : res?.defaultMarkAs === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 4,
                      // marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            {children}
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomSingleValue = ({ data }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginTop: "-30px",
          userSelect: "none",
        }}
      >
        {/* User Image */}
        <img
          src={getUserIcon(data.value) || Avatar} // Use the icon function or fallback to the default avatar
          alt={data.label}
          width={32}
          height={32}
          style={{ borderRadius: "50%" }}
        />

        {/* Text Stack */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "12px", color: "#888" }}>Assigned to</span>
          <span>{data.label}</span>
        </div>
      </div>
    );
  };

  // User Selection
  const CustomUserEditorControl = ({ children, menuIsOpen, ...props }) => {
    const { hasValue, getValue } = props;
    const selectedValue = getValue()?.[0];

    return (
      <components.Control {...props}>
        {children}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  // Create task function
  const createTaskSubmit = async (data) => {
    try {
      let formData = new FormData();

      formData.append("taskName", data.taskName);
      data.taskPriorityId &&
        formData.append("taskPriorityId", data.taskPriorityId);
      data.taskStatusId
        ? formData.append("taskStatusId", data.taskStatusId)
        : formData.append(
            "taskStatusId",
            getIdByDefaultMarkAs(projectStatusList, "not-started")
          );
      data.taskDescription &&
        formData.append("taskDescription", data.taskDescription);
      projectId && formData.append("projectId", projectId);
      formData.append("hasApproval", approval);
      const taskDeadline =
        selectedDateFilter !== null ? data?.taskDeadline : null;
      taskDeadline && formData.append("taskDeadline", taskDeadline);
      // For taskCheckList
      data.taskCheckList.forEach((checkListItem, index) => {
        formData.append(`taskCheckList[]`, checkListItem);
      });
      // For files
      data.files.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      // For taskMembers
      data.taskMembers.forEach((memberId, index) => {
        formData.append(`taskMembers[]`, memberId);
      });

      const response = await createTaskData(formData).unwrap();
      if (response.status === "success") {
        setNewId(response?.data);
        if (
          listing !== "TASK" &&
          listing !== "PROJECT_DETAIL" &&
          isAddMoreInfo
        ) {
          localStorage.setItem("tid", response?.data?.id);
          localStorage.setItem("oid", true);
        }
        toast.success("Task Created Successfully.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        });
        getTaskList();
      }
      // Now formData contains all the data in a format suitable for sending in a form request
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      // setSelectedDateFilter(null);
      // setCreateTaskLoading(false);
      // getGrid();
      // if (!teamsOptionsList) {
      //   dispatch(teamsListAction());
      // }
    }
  };

  // Return user info from id
  const getUserLabel = (id) => {
    const status = observerOptions?.find((status) => status.id === id);
    return status?.label;
  };

  const getUserIcon = (id) => {
    const status = projectUserList?.data?.find(
      (status) => status.userId === id
    );
    return status?.profileImage;
  };

  const formik = useFormik({
    initialValues: {
      taskName: "",
      deadline: "",
      assignedTo: null,
      priority: null,
      status: "",
      taskDescription: "",
      attachment: null,
    },
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission here
      // validateForm();
      try {
        if (isAddMoreInfo) {
          setAddLoad(true);
        } else {
          setCreateLoad(true);
        }
        // Combine date and time
        const combinedDateTime = `${moment(selectedDateFilter).format(
          "YYYY-MM-DD"
        )}T${selectedTime}:00.000Z`;

        // Convert to moment object
        const convertedDate = moment(combinedDateTime, "YYYY-MM-DD HH:mm")
          .clone() // Clone to avoid mutation of the original moment object
          .tz(userProfile?.timeZone, true) // true ensures we keep the exact same time, not adjusting it to the timezone offset
          .utc() // Convert to UTC
          .toISOString();

        let data = {
          taskName: values.taskName,
          taskPriorityId: values?.priority,
          taskStatusId: values?.status,
          taskDescription: values?.taskDescription,
          taskDeadline: convertedDate,
          files: files,
          taskMembers: [],
          taskCheckList: [],
        };

        let taskObserver = [];
        let taskAssignTo = [];
        if (values.observer && values.observer.length > 0) {
          const newTaskMembers = values.observer.map((item) => {
            return `${getTypeIDByName("observer")}:${item.id}`;
          });
          taskObserver = newTaskMembers;
        }

        if (values.assignedTo) {
          const newTaskMember = `${getTypeIDByName("member")}:${
            values.assignedTo
          }`;
          taskAssignTo = [newTaskMember];
        }

        if (taskObserver.length > 0 || taskAssignTo.length > 0) {
          data.taskMembers = [
            ...taskAssignTo,
            `${getTypeIDByName("owner")}:${userProfile?.userId}`,
          ];
        }

        // if (list && list.length > 0) {
        //   data.taskCheckList = list.map((item) => ({
        //     description: item.text,
        //     isCompleted: item.checked,
        //   }));
        // }
        if (list && list.length > 0) {
          // eslint-disable-next-line array-callback-return
          list.map((item) => {
            item.text &&
              item.text.length > 0 &&
              data.taskCheckList.push(item.text);
          });
        }
        // console.log("Checklist : ", data);
        await createTaskSubmit(data);
        resetForm();
        setFiles([]);
        setSelectedDateFilter(null);
        if (isAddMoreInfo) {
          if (listing === "PROJECT_DETAIL") {
            navigate(
              `/${localStorage.getItem("company")}/project?pid=${projectId}`
            );
          } else {
            navigate(
              `/${localStorage.getItem("company")}/task-management-tasks`
            );
          }
          setShowTaskDetails(true);
        } else {
          if (listing === "PROJECT_DETAIL") {
            navigate(
              `/${localStorage.getItem("company")}/project?pid=${projectId}`
            );
          } else {
            navigate(
              `/${localStorage.getItem("company")}/task-management-tasks`
            );
          }
        }
        handleCloseModal();
      } catch (error) {
      } finally {
        setCreateLoad(false);
        setAddLoad(false);
        setIsAddMoreInfo(false);
        getGrid();
      }
      // navigate(`/${localStorage.getItem("company")}/task-management-tasks`);
    },
    validate: (values) => {
      const errors = {};

      // Regex pattern to allow only letters, numbers, and '@' symbol
      // const taskNamePattern = /^[A-Za-z0-9& ]+$/;

      // Validate task name
      if (!values.taskName) {
        errors.taskName = "Task name is required.";
      } else if (values.taskName.length > 255) {
        errors.taskName = "Task name cannot exceed 255 characters.";
      }
      if (
        !values.assignedTo &&
        (!values?.taskTeamId || values?.taskTeamId?.length === 0)
      ) {
        errors.assignedTo = "Assigned to is required.";
      }
      return errors;
    },
  });

  // Upload file functionality
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalSize = calculateTotalSize(selectedFiles);

    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        { position: toast.POSITION.TOP_CENTER }
      );
      e.target.value = ""; // Reset input to allow re-selecting the same file
      return;
    }

    const validFiles = selectedFiles.filter((file) => validateFile(file));

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);

    // Reset the input field to allow selecting the same file again
    e.target.value = "";
  };

  const handleDeleteFile = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  // useEffects
  useEffect(() => {
    getUsers();
  }, [projectUserList]);

  // useEffect(() => {
  //   import("tinymce/tinymce").then(() => setIsEditorReady(true));
  // }, []);

  // Focus the editor when openDescription becomes true
  useEffect(() => {
    if (openDescription && editorRef.current) {
      editorRef.current.focus();
    }
  }, [openDescription]);

  // If datepickr is opened, clicking on select component while date pickr is open should focus properly on Select component
  const handleClick = (selectRef) => {
    if (selectRef.current) {

      // Close DateTimePicker if open
      if (isTimeModalOpen) {
        setIsTimeModalOpen(false);
      }

      // Ensure focus happens after closing DateTimePicker
      setTimeout(() => {
        selectRef.current.focus();
      }, 100);
    }
  };

  useEffect(() => {
    if (bulkTodoOpen) {
      textRef?.current?.focus();
    }
  }, [bulkTodoOpen]);

  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="create-task-modal-main create-task-modal-main-width"
    >
      {(addLoad || createLoad) && (
        <div className="create-task-is-loading"></div>
      )}
      <Tooltip id="create-my-tooltip" />

      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          Create <span>Task</span>
        </p>
        <button className="custom-close-button" onClick={handleCloseModal}>
          <Icon icon="maki:cross" />
        </button>
      </div>

      <Modal.Body>
        <form
          noValidate
          className="create-new-task-form"
          onSubmit={formik.handleSubmit}
        >
          <div
            className={`create-task-top-fields ${
              formik.values.assignedTo
                ? "create-task-top-fields-has-assignedto-value"
                : ""
            }`}
          >
            <div className="form-group">
              <input
                type="text"
                name="taskName"
                placeholder="Task Name"
                isInvalid={
                  formik?.touched.taskName && !!formik?.errors.taskName
                }
                className={`form-control ${
                  formik?.touched.taskName && formik?.errors.taskName
                    ? "is-invalid"
                    : ""
                } ${formik.values.taskName ? "form-control-value" : ""}`}
                value={formik.values.taskName}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                autoComplete="off"
              />
              {formik.touched.taskName && formik.errors.taskName ? (
                <div className="invalid-feedback">{formik.errors.taskName}</div>
              ) : null}
            </div>
            <div
              className={`form-group assignee-group ${
                formik.values.assignedTo ? "selected-create-task-field" : ""
              }`}
            >
              <Select
                ref={selectRef1}
                getOptionValue={(option) => option.value}
                classNamePrefix="select"
                options={observerOptions
                  ?.filter((res) => res.id !== formik.values.assignedTo)
                  ?.map((res) => ({
                    label: res.label, // Make sure this is a string for searching
                    value: res.id,
                    icon: getUserIcon(res.id), // Save icon separately
                  }))}
                formatOptionLabel={(option) => (
                  <div className="d-flex align-items-center gap-1 custom-project-select-item">
                    <img
                      src={getUserIcon(option.value) || Avatar}
                      alt="Critical"
                      width={20}
                      height={20}
                      // className="me-1"
                    />
                    {option.label}
                  </div>
                )}
                isSelectAll={false}
                isRtl={false}
                placeholder="Assignee *"
                value={
                  formik.values.assignedTo
                    ? {
                        label: truncateText(
                          getUserLabel(formik.values.assignedTo),
                          16
                        ),
                        value: formik.values.assignedTo,
                      }
                    : null
                }
                isInvalid={
                  formik.touched.assignedTo && !!formik.errors.assignedTo
                }
                className={`task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status filter-item-status ${
                  formik.values.assignedTo ? "select-item-assigned-to" : ""
                } ${
                  formik.touched.assignedTo && formik.errors.assignedTo
                    ? "is-invalid invalid-error-border"
                    : ""
                }`}
                name="assignedTo"
                menuPlacement="auto"
                components={{
                  Control: CustomUserEditorControl,
                  SingleValue: CustomSingleValue,
                }}
                onChange={(e) => {
                  formik.setFieldValue("assignedTo", e.value);
                }}
                onMenuOpen={(e) => handleClick(selectRef1)}
              />

              {formik.touched.assignedTo && formik.errors.assignedTo ? (
                <div className="invalid-feedback">
                  {formik.errors.assignedTo}
                </div>
              ) : null}
            </div>
          </div>
          <div className="selector-group">
            <div
              className={`form-group create-new-task-deadline ${
                selectedDateFilter ? "create-task-modal-selected-date" : ""
              }`}
            >
              <div className="position-relative">
                <div
                  style={{
                    border: "1px solid var(--divider-color)",
                    height: "40px",
                    width: "100%",
                    position: "static",
                    borderRadius: "6px",
                    fontSize: "13px",
                    fontWeight: "300",
                    left: "14px",
                    top: "0",
                    color: "var(--font-color)",
                    paddingLeft: "9px",
                    transform: "translateY(0%)",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                  }}
                  className="due-date-icon"
                  // onClick={() => flatpickrRef.current.flatpickr.open()}
                  // onClick={() => setShowDateTimePicker(!showDateTimePicker)}
                  onClick={() => setIsTimeModalOpen(!isTimeModalOpen)}
                >
                  <span>
                    <Icon icon="lets-icons:date-range" />{" "}
                    <span
                      style={{
                        height: "max-content",
                      }}
                    >
                      {selectedDateFilter
                        ? `Due on ${formatDeadlineDate(
                            selectedDateFilter
                          )}-${formatTimeToAMPM(selectedTime)}`
                        : "Due Date"}
                    </span>
                  </span>
                  {selectedDateFilter && (
                    <button
                      style={{
                        border: "1px solid var(--divider-color)",
                        borderRadius: "4px",
                        padding: "1px",
                        background: "var(--search-bar-bg)",
                        margin: "0px !important",
                        height: "max-content",
                      }}
                      className="select-icon me-1 select-icon-date"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedDateFilter(null);
                      }}
                    >
                      <IoMdClose
                        style={{
                          cursor: "pointer",
                          color: "var(--font-color)",
                        }}
                      />
                    </button>
                  )}
                </div>
                <DateTimePicker
                  isOpen={isTimeModalOpen}
                  setIsOpen={setIsTimeModalOpen}
                  time={selectedTime}
                  setTime={setSelectedTime}
                  date={selectedDateFilter}
                  setDate={setSelectedDateFilter}
                  isCloseIcon={true}
                  isDateSelectOnSlotChange={true}
                />
              </div>
            </div>
            <div
              className={`form-group ${
                formik.values.priority ? "selected-create-task-field" : ""
              }`}
            >
              <Select
                ref={selectRef2}
                className={`task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status create-item-status filter-item-status`}
                classNamePrefix="select"
                value={
                  formik.values.priority
                    ? {
                        label: getPriorityNameById(
                          taskPriorities,
                          formik.values.priority
                        ),
                        value: formik.values.priority,
                      }
                    : null
                }
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                styles={customStyles}
                placeholder="Select Priority"
                name="color"
                menuPlacement="auto"
                getOptionValue={(option) => option.value}
                options={taskPriorities
                  ?.filter((res) => res.id !== formik.values.priority) // Exclude selected value
                  .map((res) => ({
                    label: (
                      <div>
                        {res?.defaultMarkAs === "critical" && (
                          <img
                            src={criticalImage}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "highest" && (
                          <img
                            src={highestImage}
                            alt="Highest"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "medium" && (
                          <img
                            src={mediumImage}
                            alt="Medium"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "low" && (
                          <img
                            src={lowImage}
                            alt="Low"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.priorityName}
                      </div>
                    ),
                    value: res.id,
                  }))}
                components={{
                  Control: CustomTasksPriorityEditorControl,
                }}
                onChange={(e) => {
                  formik.setFieldValue("priority", e.value);
                }}
                onMenuOpen={(e) => handleClick(selectRef2)}
              />
            </div>
            <div
              className={`form-group ${
                formik.values.status ? "selected-create-task-field" : ""
              }`}
            >
              <Select
                ref={selectRef3}
                className={`task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status filter-item-status`}
                classNamePrefix="select"
                value={
                  formik.values.status
                    ? {
                        label: getStatusNameById(
                          projectStatusList,
                          formik.values.status
                        ),
                        value: formik.values.status,
                      }
                    : null
                }
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                styles={customStyles}
                placeholder="Select Status"
                name="color"
                menuPlacement="auto"
                getOptionValue={(option) => option.value}
                options={projectStatusList
                  ?.filter((res) => res.id !== formik.values.status)
                  ?.map((res, index) => ({
                    label: (
                      <div>
                        {" "}
                        {res?.defaultMarkAs === "not-started" && (
                          <img
                            src={NotStarted}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "in-progress" && (
                          <img
                            src={progressImage}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "on-hold" && (
                          <img
                            src={reviewImage}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "review" && (
                          <img
                            src={reviewIcon}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.defaultMarkAs === "completed" && (
                          <img
                            src={CompletedIcon}
                            alt="Critical"
                            width={20}
                            height={20}
                            className="me-1"
                          />
                        )}
                        {res?.statusName}
                      </div>
                    ),
                    value: res?.id,
                  }))}
                components={{
                  Control: CustomTasksStatusEditorControl,
                }}
                onChange={(e) => {
                  formik.setFieldValue("status", e.value);
                }}
                onMenuOpen={(e) => handleClick(selectRef3)}
              />
            </div>
          </div>
          <div
            className="form-group"
            style={{ display: openDescription ? "block" : "none" }}
          >
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              apiKey="gpl"
              value={formik.values.taskDescription}
              init={{
                height: 150,
                menubar: false,
                plugins: "lists textcolor colorpicker",
                toolbar: "bold italic underline | checklist numlist bullist",
                content_style:
                  "body { font-family: 'Poppins', sans-serif; font-size: 14px }",
              }}
              onInit={(evt, editor) => {
                editorRef.current = editor;
              }}
              onEditorChange={(newContent) => {
                formik.setFieldValue("taskDescription", newContent);
              }}
            />
          </div>

          {!openDescription && (
            <div
              className="create-task-open-description"
              onClick={() => setOpenDescription(true)}
            >
              Click to add a description
            </div>
          )}

          {/* Checklist section */}
          <div className="create-task-modal-checklist">
            {list?.length > 0 && (
              <div className="accordion" id="todoAccordion">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className={`w-100 create-task-accordion-button p-0 ${
                        isOpen ? "" : "collapsed"
                      } d-flex justify-content-between align-items-center`}
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {/* Left Section: Arrow + Title */}
                      <div className="d-flex align-items-center">
                        <label className="create-task-label">
                          <span className={`me-1`}>
                            {isOpen ? (
                              <Icon icon="ep:arrow-up" />
                            ) : (
                              <Icon icon="ep:arrow-down" />
                            )}
                          </span>
                          Checklist /Subtask
                        </label>
                      </div>
                      {/* Right Section: Count */}
                      <div className="create-task-checklist-count">
                        {list?.filter((item) => item.checked).length}/
                        {list?.length}
                      </div>
                    </button>
                  </h2>
                  <div
                    id="todoListCollapse"
                    className={`accordion-collapse collapse ${
                      isOpen ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body px-0">
                      <TaskDetailsTodoList
                        list={list}
                        setList={setList}
                        isDark={isDark}
                        addItemInList={() => {
                          setList([
                            ...list,
                            { id: uuidv4(), checked: false, text: null },
                          ]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between gap-2">
              <h5>
                <span
                  className="todo-list-add-item ms-0 create-task-label"
                  onClick={async () => {
                    if (!isOpen) {
                      setIsOpen(true);
                    }
                    setList([
                      ...list,
                      { id: uuidv4(), checked: false, text: null },
                    ]);
                  }}
                >
                  {" "}
                  <img src={plusIcon} alt="Plus" />{" "}
                  {list?.length > 0 ? "Add item" : "Add Checklist/Subtask"}
                </span>{" "}
              </h5>
              <div className="d-flex gap-4 task-detail-export-task-list mb-md-0 mb-2">
                <p
                  onClick={() => setBulkTodoOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <Icon
                      icon="clarity:export-line"
                      className="me-2"
                      fontSize={18}
                    />
                  </span>
                  Import task list
                </p>
              </div>
            </div>
          </div>
          {/* Bulk import UI */}
          {bulkTodoOpen && (
            <div className="d-flex flex-column gap-3 mb-2">
              <textarea
                ref={textRef}
                placeholder="Copy paste task list to add all your sub tasks"
                className="bulk-checklist-text-area"
                rows={4}
              />
              <div className="w-100 d-flex align-items-center justify-content-end gap-2">
                <button
                  type="button"
                  onClick={async () => {
                    const textValue = textRef.current.value; // Get the value of the textarea
                    if (!textValue.trim()) {
                      // Focus textarea if no text is entered
                      textRef?.current?.focus();
                      return;
                    } else {
                      try {
                        // Split text into an array of strings
                        const taskArray = textValue
                          .split(/\r?\n|\•/)
                          .map((item) => item.trim())
                          .filter((item) => item !== "");
                        // Map through taskArray to create new checklist items
                        const newTasks = taskArray.map((text) => ({
                          id: uuidv4(),
                          checked: false,
                          text, // set the text field to the string from taskArray
                        }));

                        // Add new tasks to the existing list
                        setList([...list, ...newTasks]);
                      } catch (err) {
                      } finally {
                        setBulkTodoOpen(false);
                      }
                    }
                  }}
                  className="comment-field-submit-btn comment-field-post-button"
                >
                  Create
                </button>
                <button
                  className="comment-field-cancel-btn comment-field-submit-btn"
                  onClick={() => setBulkTodoOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {/* File Upload Field */}
          <div className="form-group form-group-attachment">
            <div className="d-flex align-items-center mb-2">
              <label className="mb-0 create-task-label" htmlFor="file-upload">
                <span className="me-1">
                  <Icon icon="ph:upload" />
                </span>
                Upload attachment{" "}
              </label>

              <input
                type="file"
                accept={allowedExtensions.map((ext) => `.${ext}`).join(",")}
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-upload"
              />
            </div>

            {/* Display Uploaded Files */}
            <div className="create-task-modal-uploaded-files">
              {files.map((file, index) => (
                <div key={index} className="file-item-one">
                  <span>
                    <Icon
                      icon="mingcute:check-fill"
                      className="me-1"
                      width={16}
                      height={16}
                    />{" "}
                    {file.name} ({formatFileSize(file.size)})
                  </span>

                  <button
                    className="remove-uploaded-file-button"
                    onClick={() => handleDeleteFile(file)}
                  >
                    <Icon icon="maki:cross" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Review for approval */}
          <div className="d-flex align-items-center gap-4 flex-wrap">
            {/* <div
              className={`review-for-approval-task-wrap`}
              onClick={() => setIsVisible(!isVisible)}
            >
              <p className="mb-0">
                Mark as{" "}
                <span>
                  <Icon icon="ep:arrow-right" className="ms-2" />
                </span>
              </p>
            </div> */}
            <p className={`mb-0 create-task-check-txt`}>
              <span className="me-1 d-flex align-items-center">
                <label className="d-flex align-items-center mb-0 control-task control-task-checkbox control-select-checkbox advanced-check-options">
                  <input
                    type="checkbox"
                    checked={approval} // Checked state based on `approval`
                    onChange={(e) => {
                      setApproval(!approval);

                      // Remove focus after clicking
                    }}
                  />
                  <div className="control_task_indicator control-select-indicator"></div>
                  <p className="mb-0">
                    Task owner approval required{" "}
                    <span className="ms-1">
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="create-my-tooltip"
                        data-tooltip-content="If marked, then assignee can not complete the task rather just submit to review."
                        width={20}
                        height={20}
                      />{" "}
                    </span>
                  </p>
                </label>
              </span>
            </p>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer className="create-task-modal-footer">
        <button
          className="create-task-modal-footer-button-2"
          onClick={() => {
            setIsAddMoreInfo(true);
            formik.handleSubmit();
          }}
          disabled={addLoad || createLoad}
        >
          <Icon icon="fluent:add-12-regular" className="me-2" /> Add more info{" "}
          {addLoad && <Spinner size="sm" className="ms-1" />}
        </button>
        <button
          className="create-task-modal-footer-button-1"
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={addLoad || createLoad}
        >
          Create Task{createLoad && <Spinner size="sm" className="ms-1" />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

// Task checklist
const TaskDetailsTodoList = ({ list, setList, isDark, addItemInList }) => {
  const onCheckedChange = (id, checked) => {
    setList(
      list.map((res, index) =>
        res.id === id ? { ...res, checked: checked } : res
      )
    );
  };
  const onTextChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, text: text } : res))
    );
  };

  const onItemDelete = (id) => {
    setList(list.filter((res, index) => res.id !== id));
  };
  return (
    <div className="d-flex flex-column gap-2">
      {list.map((res, index) => {
        return (
          <TaskDetailsTodoListItem
            text={res.text}
            checked={res.checked}
            id={res.id}
            key={index}
            order={index}
            isDark={isDark}
            addItemInList={addItemInList}
            onCheckedChange={onCheckedChange}
            onTextChange={onTextChange}
            onItemDelete={onItemDelete}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsTodoListItem = ({
  text,
  checked,
  id,
  key,
  isDark,
  onCheckedChange,
  onTextChange,
  onItemDelete,
  addItemInList,
  order,
}) => {
  const [isEditable, setIsEditable] = useState(text ? false : true);
  const [inputValue, setInputValue] = useState(text);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      inputValue && inputValue.length > 0 && setIsEditable(false);
      onTextChange(id, inputValue);
      if (inputValue && inputValue.length > 0) {
        addItemInList();
      } else {
        onItemDelete(id);
      }
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
    onTextChange(id, event.target.value);
  };
  const handleBlur = (e) => {
    e.stopPropagation();
    e.preventDefault();
    inputValue && inputValue.length > 0 && setIsEditable(false);
    onTextChange(id, inputValue);
    if (inputValue && inputValue.length > 0) {
    } else {
      onItemDelete(id);
    }
  };

  // useEffect(() => {},[list])
  return (
    <div
      data-theme={isDark}
      key={key}
      className="position-relative d-flex align-item-center gap-2 create-task-todo-list-item"
    >
      {/* <div
        className={`${
          !isEditable
            ? "task-details-check-list-radio-button"
            : "task-details-check-list-radio-button-input"
        }`}
        onClick={() => {
          setIsChecked(!isChecked);
          // if (!isChecked) {
          onCheckedChange(id, !isChecked);
          // }
        }}
      >
        <input
          type="checkbox"
          name="optionsRadios"
          id="optionsRadios2"
          value="option2"
          checked={isChecked}
        />
        <label></label>
      </div> */}
      <div
        className={`create-task-subtask-count ${
          checked ? "create-task-subtask-count-checked" : ""
        }`}
        onClick={() => {
          // setIsChecked(!checked);
          // if (!isChecked) {
          onCheckedChange(id, !checked);
          // }
        }}
      >
        {order + 1}
      </div>
      {!isEditable ? (
        <div className="d-flex align-items-center w-100 task-details-todo-list-item-text-wrap">
          <p
            onClick={() => setIsEditable(true)}
            className={`mb-0 pb-0 task-details-todo-list-item-text w-100 ${
              checked ? "text-decoration-line-through" : ""
            }`}
          >
            {text}
          </p>

          {/* <img
            onClick={() => onItemDelete(id)}
            src={Delete}
            alt="Delete"
            className="task-details-todo-list-item-delete-icon"
          /> */}
          <Icon icon="maki:cross" onClick={() => onItemDelete(id)} />
        </div>
      ) : (
        <div className="task-details-todo-list-item-input-container w-100">
          <input
            type="text"
            placeholder="Add a task"
            className="form-control w-100 subtask-input ps-0"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={inputValue}
            onBlur={handleBlur}
            autoFocus
            autoComplete="off"
          />
        </div>
      )}
    </div>
  );
};

export default CreateTaskModal;
