import { ChatBaseApi } from "../../services/ChatBaseApi";

export const chatApi = ChatBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Start a new chat
    startChat: builder.mutation({
      query: ({ chatId, body }) => ({
        url: `/v1/chat/${chatId}/start`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ChatRoom", "AllChatUsers"],
    }),

    // Send a message in a specific chat room
    sendMessage: builder.mutation({
      query: ({ chatId, body }) => ({
        url: `/v1/chat/${chatId}/send`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ChatMessages"],
    }),

    // Send a message attachment in a specific chat room
    sendAttachmentMessage: builder.mutation({
      query: ({ chatId, body }) => ({
        url: `/v1/chat/${chatId}/send-message`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ChatMessages"],
    }),

    // Send a message in a specific chat room
    forwardMessage: builder.mutation({
      query: (body) => ({
        url: `/v1/chat/forward-message`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ChatMessages"],
    }),

    // Update read count messages
    updateReadCount: builder.mutation({
      query: (body) => ({
        url: `/v1/chat/last-seen`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ChatMessages"],
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(chatApi.util.invalidateTags(["AllChatUsers"]));
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
    }),

    // Delete a specific message
    deleteMessage: builder.mutation({
      query: (body) => ({
        url: `/v1/chat/remove-message`,
        method: "POST",
        body,
      }),
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(chatApi.util.invalidateTags(["ChatMessages"]));
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
      // invalidatesTags: ["ChatMessages"],
    }),

    // Delete a specific message
    userStatusUpdate: builder.mutation({
      query: (body) => ({
        url: `/v1/chat/status-update`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ChatMessages"],
    }),

    // Get all messages in a specific chat room
    getMessages: builder.query({
      query: ({ chatId, days }) => `/v1/chat/${chatId}/messages?days=${days}`,
      providesTags: ["ChatMessages"],
    }),

    // Get all chat rooms for the user
    getUserRooms: builder.query({
      query: () => `/v1/chat/user/rooms`,
      providesTags: ["ChatRoom"],
    }),

    // Get users without a chat
    getUsersWithoutChat: builder.query({
      query: () => `/v1/chat/users/without-chat`,
      providesTags: ["UsersWithoutChat"],
    }),

    // Get users without a chat
    getAllChatUsers: builder.query({
      query: () => `/v1/chat/users`,
      providesTags: ["AllChatUsers"],
    }),

    // Clear messages in a specific chat room
    // Clear messages in a specific chat room
    clearMessages: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(chatApi.util.invalidateTags(["ChatMessages"]));
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),

    clearChatUsers: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(chatApi.util.invalidateTags(["AllChatUsers"]));
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),
  }),
});

export const {
  useStartChatMutation,
  useSendMessageMutation,
  useGetMessagesQuery,
  useGetUserRoomsQuery,
  useGetUsersWithoutChatQuery,
  useGetAllChatUsersQuery,
  useClearMessagesMutation,
  useDeleteMessageMutation,
  useForwardMessageMutation,
  useUpdateReadCountMutation,
  useSendAttachmentMessageMutation,
  useUserStatusUpdateMutation,
  useClearChatUsersMutation
} = chatApi;

export default chatApi;
