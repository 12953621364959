import React from "react";
import Cookies from "js-cookie";
function saveLocalStorageToCookies(link) {
  const cookieOptions = {
    path: "/",
  };
  // Only set domain for production
  if (window.location.hostname !== "localhost") {
    cookieOptions.domain = process.env.REACT_APP_DOMAIN; // Works on real domains
    cookieOptions.secure = true; // Ensures security on HTTPS
    cookieOptions.sameSite = "None"; // Required for cross-domain cookies
  }

  Cookies.set(
    "access_token",
    localStorage.getItem("access_token"),
    cookieOptions
  );
  Cookies.set(
    "permissions",
    localStorage.getItem("permissions"),
    cookieOptions
  );
  Cookies.set("company", localStorage.getItem("company"), cookieOptions);

  console.log(
    "Saved Cookie:",
    Cookies.get("access_token"),
    Cookies.get("permissions"),
    Cookies.get("company")
  );

  const manageDomain = process.env.REACT_APP_MANAGE_URL;

  window.open(
    `${manageDomain}/${localStorage.getItem("company")}${link}`,
    "_blank"
  );
}

export default saveLocalStorageToCookies;
