import React from "react";
import { Accordion } from "react-bootstrap";
import SprintTask from "./SprintTask";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "@iconify/react";
import { Tooltip } from "react-tooltip";

const SprintBoard = ({ data, index }) => {
  return (
    <Accordion className="sprint-board">
      {" "}
      <Accordion.Item eventKey={data.boardId}>
        <Accordion.Header>
          <Tooltip id="my-tooltip" />
          <div className="d-flex justify-content-between align-items-center w-100 sprint-board-header">
            <div className="sprint-board-header-left-wrap">
              <p className="sprint-header-heading">
                {data.boardName}{" "}
                <span>
                  <Icon icon="ri:pencil-fill" />
                </span>
              </p>
              <p className="expiary-sprint-board-header-text">
                Expired 27 February
              </p>
            </div>
            <div className="d-flex align-items-center sprint-board-header-right-wrap">
              <div className="sprint-task-count">
                <p>Tasks {data?.tasks?.length}</p>
              </div>
              <div className="ms-2 d-flex justify-content-between sprint-task-status-count">
                <div
                  className="ms-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Not Started"
                >
                  <p>
                    {
                      data?.tasks?.filter(
                        (task) =>
                          task.taskStatus.statusName.toLowerCase() ===
                          "not started"
                      ).length
                    }
                  </p>
                </div>
                <div
                  className="ms-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="In Progress"
                >
                  <p>
                    {
                      data?.tasks?.filter(
                        (task) =>
                          task.taskStatus.statusName.toLowerCase() ===
                          "in progress"
                      ).length
                    }
                  </p>
                </div>
                <div
                  className="ms-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="On Hold"
                >
                  <p>
                    {
                      data?.tasks?.filter(
                        (task) =>
                          task.taskStatus.statusName.toLowerCase() === "on hold"
                      ).length
                    }
                  </p>
                </div>
                <div
                  className="ms-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Completed"
                >
                  <p>
                    {
                      data?.tasks?.filter(
                        (task) =>
                          task.taskStatus.statusName.toLowerCase() ===
                          "completed"
                      ).length
                    }
                  </p>
                </div>
                <div className="ms-1">
                  <i className={`fa-solid fa-add`} aria-hidden="true"></i>
                </div>
              </div>
              <div className="ms-2 sprint-board-state-btn-wrap">
                <button
                  className={`${
                    data?.isStarted ? "sprint-finish-btn" : "sprint-start-btn"
                  }`}
                >
                  {data?.isStarted ? "Finish" : "Start"}
                </button>
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Droppable droppableId={index.toString()} type="TASK">
            {(provided) => (
              <div
                className="sprint-tasks-wrap"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data?.tasks?.length === 0 ? (
                  <div className="d-flex flex-column text-center justify-content-center align-items-center no-sprint-tasks">
                    <h4>Start a new sprint with your team</h4>
                    <p>
                      <span>Create a task </span>or drop here the backlog tasks
                      to start sprinting plan
                    </p>
                  </div>
                ) : (
                  data?.tasks?.map((res, id) => (
                    <SprintTask task={res} taskIndex={id} />
                  ))
                )}
              </div>
            )}
          </Droppable>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SprintBoard;
