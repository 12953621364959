import React, { useCallback, useEffect, useRef, useState } from "react";
import "../styles/projects.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import ProjectListing from "./ProjectListing";
import ProjectNewModal from "./ProjectNewModal";
import ProjectFilterSection from "./ProjectFilterSection";
import ResponsivePagination from "react-responsive-pagination";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import {
  projectFormListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
  updateProjectAction,
} from "../../../actions/projectActions";
import ProjectAdvanceSearchModalMain from "./ProjectAdvanceSearchModal/ProjectAdvanceSearchModalMain";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import {
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { isPermitted } from "../../../helper/isPermitted";
import { ShimmerButton, ShimmerTable } from "react-shimmer-effects";
import DeleteSelectedProjectsModal from "./DeleteSelectedProjectsModal";
import { updateActiveProjectAction } from "../../../actions/timelineActions";
import { useGetProjectListingQuery } from "../../../slices/projects/projectSlice";
import CreateIcon from "../../../assets/create-task-icon.png";
import { Spinner } from "react-bootstrap";
import API, { ProjectAPI } from "../../../services/ClientApi";
import { downloadProjectDataAsExcel } from "../../../helper/downloadFileHelpers";
import CreateTaskModal from "../../Tasks/components/CreateTaskModal";
import ProjectSelectedSection from "./ProjectSelectedSection";
import DeleteConfirmSelectedProjectsModal from "./DeleteConfirmProjectModal";
import "../../Timeline/styles/timeline.css";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { userProfilePage } from "../../../actions/userActions";
import { useLocation } from "react-router-dom";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import { useGetUserListingQuery } from "../../../slices/members/membersSlice";

const Projects = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [load, setLoad] = useState(true);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);

  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { projectFormList } = useSelector((state) => state.projectFormListData);

  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  // Create api calls and states for listing here right now since there is an issue calling redux action on ag grid value change
  const [projectListMain, setProjectList] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [show, setShow] = useState(false);
  const [taskCount, setTaskCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [showTask, setShowTask] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [order, setOrder] = useState({ orderBy: "", orderDirection: "" });
  const [userList, setUserList] = useState([]);
  const [filterType, setFilterType] = useState(
    sessionStorage.getItem("filterTypeProject") || "all"
  );
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const [excelLoad, setExcelLoad] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const { data: userTaskListing } = useGetUserListingQuery();

  const [runTour, setRunTour] = useState(false);

  // Tour steps
  const steps = [
    {
      element: ".create-btn",
      intro: "Quickly create projects, set deadlines and add notes.",
      position: "right",
    },
    {
      element: ".download-excel-btn",
      intro:
        "Quickly export your project lists for easy sharing and record-keeping.",
      position: "left",
    },
    {
      element: ".task-list-filters-btn-container",
      intro:
        "Use the advanced search option to quickly filter and find specific projects.",
      position: "bottom",
    },
  ];

  // Advanced filter states
  const initialFormData = {
    multiStatus: [],
    teamsMembers:
      typeof userProfile?.userFilters?.project?.[0]?.filterOptions
        ?.teamsMembers === "string" &&
      userProfile?.userFilters?.project?.[0]?.filterOptions?.teamsMembers
        ? userProfile?.userFilters?.project?.[0]?.filterOptions?.teamsMembers?.split(
            ","
          )
        : [],
    teamsMembersTypes: [],
    projectStartDate: userProfile?.userFilters?.project?.[0]?.filterOptions
      ?.projectStartDate
      ? new Date(
          userProfile?.userFilters?.project?.[0]?.filterOptions?.projectStartDate
        )
      : "",
    projectEndDate: userProfile?.userFilters?.project?.[0]?.filterOptions
      ?.projectEndDate
      ? new Date(
          userProfile?.userFilters?.project?.[0]?.filterOptions?.projectEndDate
        )
      : "",
    selectedDate: userProfile?.userFilters?.project?.[0]?.filterOptions
      ?.selectedDate
      ? new Date(
          userProfile?.userFilters?.project?.[0]?.filterOptions?.selectedDate
        )
      : "",
    searchText:
      userProfile?.userFilters?.project?.[0]?.filterOptions?.searchText || "",
    projectName:
      userProfile?.userFilters?.project?.[0]?.filterOptions?.projectName || "",
    createdAt: userProfile?.userFilters?.project?.[0]?.filterOptions?.createdAt
      ? new Date(
          userProfile?.userFilters?.project?.[0]?.filterOptions?.createdAt
        )
      : "",
    updatedAt: userProfile?.userFilters?.project?.[0]?.filterOptions?.updatedAt
      ? new Date(
          userProfile?.userFilters?.project?.[0]?.filterOptions?.updatedAt
        )
      : "",
    isDeleted:
      userProfile?.userFilters?.project?.[0]?.filterOptions?.isDeleted || false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const defaultFilters = {
    status:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "In Progress"
        ? "in-progress"
        : "",
    overdue: false,
    memberFilter: "member",
    privacy: "",
    memberIds: [],
    observerIds: [],
    ownerIds: [],
    multiStatus: [],
    teamsMembers: [],
    teamsMembersTypes: [],
    projectStartDate: "",
    projectEndDate: "",
    selectedDate: "",
    searchText: "",
    projectName: "",
    createdAt: "",
    updatedAt: "",
    isDeleted: false,
  };

  const [serverFilters, setServerFilters] = useState(() => {
    // Check if userProfile exists and has task filterOptions
    const userFilters =
      userProfile?.userFilters?.project?.[0]?.filterOptions || null;

    // Merge user filters with default filters
    return userFilters ? { ...defaultFilters, ...userFilters } : defaultFilters;
  });

  const [filters, setFilters] = useState({
    pinnedState: false,
  });

  const [listToFilter, setListToFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    sessionStorage.getItem("projectsListingPageSize")
      ? parseInt(sessionStorage.getItem("projectsListingPageSize"))
      : 50
  ); // Number of items per page
  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);
  const projectsListingSeeMoreModalContent = findObjectByContentTitle(
    "projects-list-see-more",
    modalContents?.data
  );

  const {
    data: projectListData,
    isLoading: loading,
    error,
    isUninitialized,
    isFetching,
  } = useGetProjectListingQuery({
    page: currentPage,
    limit: pageSize,
    orderBy: order.orderBy,
    orderDirection: order.orderDirection,
    type: filterType,
    timeZone: userProfile?.timeZone,
    ...(() => {
      const { memberIds, ownerIds, observerIds, memberFilter, ...rest } =
        serverFilters;
      return rest; // Return the remaining properties
    })(),
    membersFilter: serverFilters.memberIds?.length
      ? serverFilters.memberIds.join(",")
      : "",
    ownersFilter: serverFilters.ownerIds?.length
      ? serverFilters.ownerIds.join(",")
      : "",
    observersFilter: serverFilters.observerIds?.length
      ? serverFilters.observerIds.join(",")
      : "",
  });

  // Get initial project list
  const getProjectList = async (load) => {
    try {
      const data =
        projectListData?.data?.map((project) => ({ ...project })) || [];
      setProjectList(
        projectListData?.data?.map((project) => ({ ...project })) || []
      );
      if (error) {
        toast.error(error?.data?.message + " " + error?.data?.error, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setProjectList([]);
        setListToFilter([]);
      } else {
        setProjectList(data);
        setListToFilter(data);
      }
      if (projectListData?.data?.length === 0) {
        setCurrentPage(1);
      }

      setTaskCount(data?.length);
      let currentDate = new Date();
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );

      const overdueCount = data?.filter((project) => {
        let endDate = new Date(project.projectEndDate);
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );
        return (
          endDate < currentDate &&
          project.projectStatus?.name?.toLowerCase() !== "completed"
        );
      }).length;
      setOverdueCount(overdueCount);
      const completedCount = data?.filter(
        (project) => project.projectStatus.name === "Completed"
      ).length;
      setCompletedCount(completedCount);
      // Calculate todayCount
      const todayCount = data?.filter((project) => {
        if (!project.projectEndDate) {
          return false; // Exclude projects with null taskDeadline
        }
        let taskDeadline = new Date(project.projectEndDate);
        taskDeadline = new Date(
          taskDeadline.getFullYear(),
          taskDeadline.getMonth(),
          taskDeadline.getDate()
        );
        const todayDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );

        return taskDeadline.getTime() === todayDate.getTime();
      }).length;

      setTodayCount(todayCount); // Assuming setTodayCount is your state setter for todayCount
    } catch (error) {
    } finally {
    }
  };

  // General data from lists
  const getGeneralData = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        if (!projectUserList) {
          dispatch(projectUserListingAction());
        }
        if (!projectStatusList) {
          dispatch(projectListingStatusAction());
        }
        if (!taskPriorities) {
          dispatch(taskPrioritiesListAction());
        }
        if (!taskTypes) {
          dispatch(taskTypesListAction());
        }
        if (!projectTypeList) {
          dispatch(projectTypeListingAction());
          // setProjectTypes(response?.data?.data);
        }
        if (!projectFormList) {
          dispatch(projectFormListingAction());
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectListData]);

  useEffect(() => {
    getGeneralData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectTypeList) {
      setProjectTypes(projectTypeList);
    }
  }, [projectTypeList]);

  // Update `teamsMembersTypes` when `projectTypeList` changes
  useEffect(() => {
    if (projectTypeList?.length > 0) {
      const teamsMembersTypes =
        typeof userProfile?.userFilters?.project?.[0]?.filterOptions
          ?.teamsMembersTypes === "string"
          ? projectTypeList
              .filter((item) =>
                userProfile?.userFilters?.project?.[0]?.filterOptions?.teamsMembersTypes
                  ?.split(",")
                  ?.includes(item.typeName)
              )
              .map((item) => ({
                label:
                  item.typeName.charAt(0).toUpperCase() +
                  item.typeName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, teamsMembersTypes }));
    }
  }, [projectTypeList, userProfile]);

  useEffect(() => {
    if (projectStatusList?.length > 0) {
      const multiStatus =
        typeof userProfile?.userFilters?.project?.[0]?.filterOptions
          ?.multiStatus === "string"
          ? projectStatusList
              .filter((item) =>
                userProfile?.userFilters?.project?.[0]?.filterOptions?.multiStatus
                  ?.split(",")
                  ?.includes(item.defaultMarkAs)
              )
              .map((item) => ({
                label:
                  item.statusName.charAt(0).toUpperCase() +
                  item.statusName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, multiStatus }));
    }
  }, [projectStatusList, userProfile]);

  // State to hold the sort order object
  const [sortOrder, setSortOrder] = useState({
    Status: "",
    "Task Name": "",
    "Project Name": "",
    Deadline: "",
    Priority: "",
  });

  // state for showing  and hiding the only completed projects
  const [isShowOnlyCompletedProjects, setIsOnlyCompletedProjects] = useState(
    localStorage.getItem(`isShowOnlyCompletedProjects`)
      ? JSON.parse(localStorage.getItem(`isShowOnlyCompletedProjects`))
      : false
  );

  const handleCloseDeleteSelected = () => {
    setDeleteSelected(false);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleCloseTaskModal = () => {
    setShowTask(false);
  };

  // Function to update both orderBy and orderDirection
  const updateOrder = (newOrderBy, newOrderDirection) => {
    setOrder({ orderBy: newOrderBy, orderDirection: newOrderDirection });
  };

  // Refresh filters
  const refreshFilters = async () => {
    setServerFilters({
      status: "",
      overdue: false,
      memberFilter: "member",
      privacy: "",
      memberIds: [],
      observerIds: [],
      ownerIds: [],
      multiStatus: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      projectStartDate: "",
      projectEndDate: "",
      selectedDate: "",
      searchText: "",
      projectName: "",
      createdAt: "",
      updatedAt: "",
      isDeleted: false,
    });
    setFormData({
      multiStatus: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      projectStartDate: "",
      projectEndDate: "",
      selectedDate: "",
      searchText: "",
      projectName: "",
      createdAt: "",
      updatedAt: "",
      // projectsIds: [],
      isDeleted: false,
    });
    sessionStorage.removeItem("filterTypeProject");
    setFilterType("all");
    localStorage.removeItem("projectFilter");
    setFilters({
      pinnedState: false,
    });
  };

  const showRefresh = () => {
    if (
      filterType === "all" &&
      serverFilters.overdue === false &&
      serverFilters.status === "" &&
      (serverFilters.memberFilter === "member" ||
        serverFilters.memberFilter === "observer" ||
        serverFilters.memberFilter === "owner") &&
      serverFilters.memberIds.length === 0 &&
      serverFilters.ownerIds.length === 0 &&
      serverFilters.observerIds.length === 0 &&
      serverFilters.privacy === "" &&
      serverFilters.searchText === "" &&
      serverFilters.overdue === false &&
      serverFilters.isDeleted === false &&
      serverFilters.selectedDate === "" &&
      serverFilters.projectName === "" &&
      serverFilters.projectStartDate === "" &&
      serverFilters.projectEndDate === "" &&
      serverFilters.createdAt === "" &&
      serverFilters.updatedAt === "" &&
      serverFilters.teamsMembers.length === 0 &&
      serverFilters.multiStatus.length === 0 &&
      serverFilters.teamsMembersTypes.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Handler to update data based on searched query

  const { applicationName } = useSelector((state) => state.text);

  // Manage filter count section (tasks, completed, overdue)
  const getStatusCounts = async (type) => {
    switch (type) {
      case "INCREMENT_TASK":
        setTaskCount((prev) => prev + 1);
        break;
      case "DECREMENT_TASK":
        setTaskCount((prev) => prev - 1);
        break;
      case "INCREMENT_COMPLETED":
        setCompletedCount((prev) => prev + 1);
        break;
      case "DECREMENT_COMPLETED":
        setCompletedCount((prev) => prev - 1);
        break;
      case "INCREMENT_OVERDUE":
        setOverdueCount((prev) => prev + 1);
        break;
      case "DECREMENT_OVERDUE":
        setOverdueCount((prev) => prev - 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isFetching) {
      setShouldShowLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  // Clear selected projects on filter change
  useEffect(() => {
    setSelectedProjects([]);
    if (isFetching && serverFilters) setShouldShowLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverFilters]);

  // Hide the spinner after fetching completes
  useEffect(() => {
    if (!loading && !isUninitialized && !isFetching) {
      setShouldShowLoading(false);
    }
  }, [loading, isUninitialized, isFetching]);

  useEffect(() => {
    setUserList(projectUserList?.data?.map((user) => ({ ...user })) || []);
  }, [projectUserList]);

  const filteredListing = async () => {
    if (projectStatusList) {
      setLoad(false);
    }
  };

  useEffect(() => {
    filteredListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    taskCount,
    overdueCount,
    completedCount,
    currentPage,
    pageSize,
    listToFilter,
    projectStatusList,
  ]);

  // Calculate total number of pages
  const totalPages = Math.ceil(projectListData?.meta?.total / pageSize);

  // Page size list
  const pageSizeList = [20, 50, 100];

  const updateProjectData = async (project) => {
    setProjectList((prevList) =>
      prevList.map((item) =>
        item.id === project.id ? { ...item, ...project } : item
      )
    );

    await dispatch(updateProjectAction(project));
    await dispatch(updateActiveProjectAction(project));
  };

  // Excel download
  const handleDownloadExcel = async () => {
    try {
      setExcelLoad(true);
      const response = await ProjectAPI.post(`project/listing`, {
        page: currentPage,
        limit: pageSize,
        orderBy: order.orderBy,
        orderDirection: order.orderDirection,
        type: filterType,
        timeZone: userProfile?.timeZone,
        pagination: false,
        ...(() => {
          const { memberIds, ownerIds, observerIds, memberFilter, ...rest } =
            serverFilters;
          return rest; // Return the remaining properties
        })(),
        membersFilter: serverFilters.memberIds?.length
          ? serverFilters.memberIds.join(",")
          : "",
        ownersFilter: serverFilters.ownerIds?.length
          ? serverFilters.ownerIds.join(",")
          : "",
        observersFilter: serverFilters.observerIds?.length
          ? serverFilters.observerIds.join(",")
          : "",
      });

      const projects = response?.data?.data || [];

      if (projects.length === 0) {
        toast.warn("No projects available to download.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }

      downloadProjectDataAsExcel(projects);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || "Failed to download tasks.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      setExcelLoad(false);
    }
  };

  // Run tour journey
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    if (
      location.pathname ===
        `/${localStorage.getItem("company")}/task-management-porojects` && // Ensure URL is /dashboard
      userProfile?.projectListTour &&
      !load &&
      !shouldShowLoading
    ) {
      setRunTour(true);
    }
  }, [userProfile, location, load, shouldShowLoading]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;
    try {
      setRunTour(false); // Close the tour
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "projectListTour", // Add the field name for the specific input field
        fieldValues: false, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [dispatch, userProfile?.userId]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton")
        // ||
        // event.target.classList.contains("introjs-donebutton")
      ) {
        await updateProfile();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects-{applicationName}</title>
      </Helmet>
      <div className="timeline-dashboard-wraper">
        <Tooltip id="my-tooltip-project" place="right" />
        {runTour && (
          <Steps
            enabled={runTour}
            steps={steps.filter((step) => document.querySelector(step.element))}
            initialStep={0}
            options={{
              showProgress: false,
              showStepNumbers: false,
              exitOnOverlayClick: false,
              scrollToElement: false,
              exitOnEsc: true,
              nextLabel: "Next",
              prevLabel: "Skip Tour",
              tooltipClass: "tour-custom-tooltip", // Optional custom styling
              disableInteraction: true,
            }}
            onExit={async () => {
              if (localStorage.getItem("access_token")) {
                await updateProfile();
              }
            }} // Reset the tour state when exited
          />
        )}
        <div
          data-theme={props?.isDark}
          className="time-line-field-wrap project-main-wrap"
        >
          <div className="row">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h4 className="ms-4 projects-heading_wrap projects-detail-heading-wrap">
                {" "}
                Projects{" "}
                {isPermitted("project/pm-create-project") && (
                  <button
                    className="create-btn"
                    data-tooltip-id="my-tooltip-project"
                    data-tooltip-content="Create project"
                    onClick={(e) => {
                      if (!isTrialActive(userProfile)) {
                        props.setOpenSubscriptionsModal(true);
                      } else {
                        setShow(true);
                      }
                    }}
                    disabled={loading}
                  >
                    {" "}
                    <img src={CreateIcon} alt="create-icon" /> Create{" "}
                  </button>
                )}
              </h4>
            </div>
          </div>
        </div>

        <>
          {loading ? (
            <div data-theme={props?.isDark} className="ms-4 d-flex gap-2">
              {Array.from({ length: 7 }, (_, index) => (
                <ShimmerButton key={index} size="sm" />
              ))}
            </div>
          ) : (
            <div className="ms-4 d-flex align-items-center justify-content-between flex-wrap gap-2 project-filter-action-container">
              <ProjectFilterSection
                props={props}
                taskCount={taskCount}
                overdueCount={overdueCount}
                completedCount={completedCount}
                todayCount={todayCount}
                setCurrentPage={setCurrentPage}
                projectUserList={
                  userTaskListing?.data?.map((user) => ({ ...user })) || []
                }
                allProjects={listToFilter}
                refreshFilters={refreshFilters}
                showRefresh={showRefresh}
                selectedProjects={selectedProjects}
                setDeleteSelected={setDeleteSelected}
                projectStatusList={projectStatusList}
                isShowOnlyCompletedProjects={isShowOnlyCompletedProjects}
                setIsOnlyCompletedProjects={setIsOnlyCompletedProjects}
                projectTypeList={projectTypeList}
                setFilterType={setFilterType}
                filterType={filterType}
                setServerFilters={setServerFilters}
                serverFilters={serverFilters}
                setShowAdvanceFilterModal={setShowAdvanceFilterModal}
                userProfile={userProfile}
              />
              <div className="project-header-search-section">
                <ul>
                  {isPermitted("task/create-new") && (
                    <li className="ms-0">
                      <Button
                        text="Add Task"
                        handleClick={() => {
                          if (!isTrialActive(userProfile)) {
                            props.setOpenSubscriptionsModal(true);
                          } else {
                            setShowTask(true);
                          }
                        }}
                        disabled={loading}
                        margin="0px 5px 0px 0px"
                        width="auto"
                        iconify="line-md:file-document-plus"
                      />
                    </li>
                  )}
                  <li>
                    <button
                      className="download-excel-btn d-flex align-items-center"
                      onClick={handleDownloadExcel}
                      disabled={excelLoad}
                    >
                      <i className="fa fa-download m-0" aria-hidden="true"></i>
                      {excelLoad ? (
                        <Spinner size="sm" className="ms-1 global-spinner" />
                      ) : (
                        ""
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {selectedProjects.length > 0 && (
            <ProjectSelectedSection
              selectedProjects={selectedProjects}
              taskListToFilter={listToFilter}
              setDeleteSelected={setDeleteSelected}
              setSelectedProjects={setSelectedProjects}
              projectUserList={userList}
              projectStatusList={projectStatusList}
              taskPriorities={taskPriorities}
              getProjectList={() => {}}
              isDark={props.isDark}
              userProfile={userProfile}
            />
          )}
          {load || shouldShowLoading ? (
            <div className="position-relative">
              <div className="ms-4">
                <ShimmerTable row={5} />
              </div>
            </div>
          ) : projectListMain?.length > 0 ? (
            <ProjectListing
              isDark={props.isDark}
              projectList={projectListMain}
              setProjectList={setProjectList}
              getStatusCounts={getStatusCounts}
              projectUserList={
                projectUserList?.data?.map((user) => ({ ...user })) || []
              }
              getProjectList={() => {}}
              projectTypes={projectTypes}
              listToFilter={listToFilter}
              setListToFilter={setListToFilter}
              projectStatusList={projectStatusList}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              updateProjectData={updateProjectData}
              pageParams={{ page: currentPage, limit: pageSize }}
              isCompletedProjectListing={false}
              updateOrder={updateOrder}
              order={order}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            <p className="mt-2 ms-4 project-listing-no-data-text">
              {projectListMain?.length < 1 &&
                listToFilter?.length < 1 &&
                isPermitted("project/pm-create-project") && (
                  <>
                    Create your first{" "}
                    <span onClick={() => setShow(true)}>project</span> and
                    manage tasks in most efficient way.
                  </>
                )}
              {projectListMain?.length < 1 && listToFilter?.length > 0 && (
                <>No project found.</>
              )}
            </p>
          )}

          {/* Pagination */}
          <div className="d-flex pagination-section">
            {listToFilter?.length > 0 && (
              <div className="ms-4 d-flex records-per-page">
                <div className="d-flex align-items-center record-project-wrap">
                  Records Per Page{" "}
                  <div className="form-group">
                    <Select
                      className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div`}
                      classNamePrefix="select"
                      value={{
                        label: pageSize,
                        value: pageSize,
                      }}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,

                          backgroundColor: state.isSelected
                            ? "#deebff"
                            : "#fff",
                        }),
                      }}
                      menuPlacement="auto"
                      options={pageSizeList
                        ?.filter((item) => item !== pageSize)
                        .map((res, index) => ({
                          label: res,
                          value: res,
                        }))}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageSize(e.value);
                        sessionStorage.setItem(
                          "projectsListingPageSize",
                          e.value
                        );
                      }}
                      components={{
                        Control: ({ children, menuIsOpen, ...props }) => {
                          return (
                            <components.Control {...props}>
                              {props.hasValue ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ color: "blue !important" }}>
                                    {children}
                                  </div>
                                </div>
                              ) : (
                                children
                              )}
                              <div
                                className="select-icon me-1"
                                style={{
                                  transform: menuIsOpen
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                  transition: "transform 0.3s ease",
                                }}
                              >
                                <IoIosArrowDown />
                              </div>
                            </components.Control>
                          );
                        },
                      }}
                    ></Select>
                  </div>
                </div>
              </div>
            )}
            {totalPages > 1 && (
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
        </>

        {show && (
          <ProjectNewModal
            show={show}
            handleCloseModal={handleCloseModal}
            isDark={props.isDark}
            getProjectList={() => {}}
            creation={true}
          />
        )}
        {showAdvanceFilterModal && (
          <ProjectAdvanceSearchModalMain
            showModal={showAdvanceFilterModal}
            setShowModal={setShowAdvanceFilterModal}
            data={listToFilter}
            isDark={props.isDark}
            filters={serverFilters}
            setFilters={setServerFilters}
            projectUserList={
              projectUserList?.data?.map((user) => ({ ...user })) || []
            }
            projectStatusList={projectStatusList}
            projectTypeList={projectTypeList}
            showRefresh={showRefresh}
            isShowOnlyCompletedProjects={isShowOnlyCompletedProjects}
            initialFormData={initialFormData}
            formData={formData}
            setFormData={setFormData}
            refreshFilters={refreshFilters}
          />
        )}
        <SeeMoreModal
          seeMore={seeMore}
          setSeeMore={setSeeMore}
          headTextOne="Projects"
          headTextTwo=" Management"
          isDark={props.isDark}
          modalContent={projectsListingSeeMoreModalContent}
        />
        {showTask && (
          <CreateTaskModal
            show={showTask}
            handleCloseModal={handleCloseTaskModal}
            isDark={props.isDark}
            getBoardData={() => {}}
            setShowTaskDetails={() => {}}
            setNewId={() => {}}
            listing="PROJECT"
          />
        )}
        {deleteSelected && (
          <DeleteSelectedProjectsModal
            showDeleteModal={deleteSelected}
            handleCloseDeleteModal={handleCloseDeleteSelected}
            isDark={props.isDark}
            getProjectList={() => {}}
            listToFilter={listToFilter}
            setListToFilter={setListToFilter}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            handleConfirmDelete={() => setConfirmDelete(true)}
          />
        )}
        {confirmDelete && (
          <DeleteConfirmSelectedProjectsModal
            showDeleteModal={confirmDelete}
            handleCloseDeleteModal={() => setConfirmDelete(false)}
            isDark={props.isDark}
            getProjectList={() => {}}
            listToFilter={listToFilter}
            setListToFilter={setListToFilter}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
          />
        )}
      </div>
    </>
  );
};

export default Projects;
