import React, { useState } from "react";
import "./style.css";
import ReportProfile from "./components/ReportProfile";
import DailyTask from "./components/DailyTask";
import DueTask from "./components/DueTask";
import DueTaskList from "./components/DueTaskList";
import { useGetDailyTaskReportQuery } from "../../slices/projects/projectSlice";
import {
  dateFormatter,
  formatDateToYYYYMMDD,
} from "../../helper/dateFormatter";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useEffect } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../services/ClientApi";
import { isTrialActive } from "../../helper/subscriptionHelpers";
import DueTaskOffCanvas from "./components/DueTaskOffCanvas";
import AsAssigneePoints from "./components/AsAssigneePoints";
import CreatedByMePoints from "./components/CreatedByMePoints";

const Dailyreports = (props) => {
  const navigate = useNavigate();
  const [selectedWeek, setSelectedWeek] = useState({
    label: dateFormatter(new Date()),
    value: "this_week",
  });
  const { userProfile } = useSelector((state) => state?.profile);
  const [filterType, setFilterType] = useState(
    sessionStorage?.getItem("dailyReportFilterType")
      ? sessionStorage?.getItem("dailyReportFilterType")
      : "this_month"
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    sessionStorage?.getItem("dailyReportDate")
      ? sessionStorage?.getItem("dailyReportFilterType") &&
        sessionStorage?.getItem("dailyReportFilterType") === "date_range"
        ? JSON.parse(sessionStorage?.getItem("dailyReportDate"))
        : sessionStorage?.getItem("dailyReportDate")
      : new Date()
  );
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    sessionStorage?.getItem("dailyReportStartDate")
      ? sessionStorage?.getItem("dailyReportStartDate")
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    sessionStorage?.getItem("dailyReportEndDate")
      ? sessionStorage?.getItem("dailyReportEndDate")
      : new Date()
  );
  const [allTasks, setAllTasks] = useState(null);
  const location = useLocation();
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);
  // Helper function to get query parameters
  const getQueryParams = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const userId = getQueryParams("userId");

  const {
    data: dailyReportData,
    isLoading,
    isSuccess,
  } = useGetDailyTaskReportQuery(
    {
      filterType: filterType,
      selectedDateFilter:
        filterType !== "date_range"
          ? formatDateToYYYYMMDD(selectedDateFilter)
          : null,
      startDate: formatDateToYYYYMMDD(startDate),
      endDate: formatDateToYYYYMMDD(endDate),
      userId: userId,
    },
    {
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }
  );
  const [showTasksType, setShowTasksType] = useState(null);
  const [showTasksTypeName, setShowTasksTypeName] = useState(null);
  const [showTaskUserType, setShowTaskUserType] = useState(null);
  const handleSetShowTaskType = (type) => {
    setShowTasksType(type);
  };

  useEffect(() => {
    if (showTasksType && showTaskUserType) {
      const currentDate = new Date(); // Get the current date for overdue checks
      const taskList =
        showTaskUserType === "assignee"
          ? dailyReportData?.data?.AssigneeFormattedTasks
          : showTaskUserType === "me"
          ? dailyReportData?.data?.OwnershipFormattedTasks
          : [];
      console.log(taskList);
      if (taskList?.length > 0) {
        const filteredTasks = taskList?.filter((task) => {
          console.log("Task : ", [
            task?.taskStatus?.defaultMarkAs !== "completed",
            new Date(task.taskDeadline).getTime(),
            currentDate.getTime(),
            new Date(task.taskDeadline).getTime() < currentDate.getTime(),
          ]);

          switch (showTasksType) {
            case "completed":
              return task?.taskStatus?.defaultMarkAs === "completed";
            case "not-completed":
              return task?.taskStatus?.defaultMarkAs !== "completed";
            case "in-progress":
              return task?.taskStatus?.defaultMarkAs === "in-progress";
            case "on-hold":
              return task?.taskStatus?.defaultMarkAs === "on-hold";
            case "review":
              return task?.taskStatus?.defaultMarkAs === "review";
            case "overdue":
              if (!task?.taskDeadline) return false;

              const taskDeadline = new Date(task.taskDeadline); // Convert string to Date
              return (
                taskDeadline.getTime() < currentDate.getTime() &&
                task?.taskStatus?.defaultMarkAs !== "completed"
              );
            default:
              return true;
          }
        });
        console.log("filteredTasks", filteredTasks);
        setAllTasks(filteredTasks);
      } else {
        setAllTasks([]);
      }
    }
  }, [showTasksType, dailyReportData, showTaskUserType]);

  const handleStatNavigation = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const statFilters = {
      memberIds: [userId],
      status: "",
      memberFilter: "member",
      priority: "",
      overdue: false,
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate: selectedDateFilter
        ? formatDateToYYYYMMDD(selectedDateFilter)
        : "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    };

    // Stringify the object before saving it to sessionStorage
    sessionStorage?.setItem("statFilters", JSON.stringify(statFilters));
    sessionStorage?.setItem("filterType", filterType);

    navigate(`/${localStorage.getItem("company")}/task-management-tasks`, {
      state: { from: location.pathname },
    });
  };

  const getUser = async () => {
    setLoad(true);
    try {
      const user = await API.post("users/user-profile", { userId: userId });

      if (user) {
        setUser(user?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const handleCardClick = (type, name, user) => {
    handleSetShowTaskType(type);
    setShowTasksTypeName(name);
    setShowTaskUserType(user);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isTrialActive(userProfile) && !props.openSubscriptionModal) {
      props.setOpenSubscriptionsModal(true);
    }
  }, [userProfile, props.openSubscriptionModal]);

  // write a function
    

  return (
    <div className="project-plan-main-wraper p-0" data-theme={props.isDark}>
      <div className="report-profile-main-wrap">
        {(isLoading || load) && (
          <div className="position-relative">
            <div className="ms-4">
              <ShimmerTable row={5} />
            </div>
          </div>
        )}
        {!isLoading && !load && (
          <>
            {" "}
            <ReportProfile
              props={props}
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              userProfile={user?.userInfo}
              dailyReportData={dailyReportData?.data?.statsCount}
              loggedInUser={userProfile}
              filterType={filterType}
              setFilterType={setFilterType}
              selectedDateFilter={selectedDateFilter}
              setSelectedDateFilter={setSelectedDateFilter}
              isLoading={isLoading || load}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              bottomSheetOpen={bottomSheetOpen}
              setBottomSheetOpen={setBottomSheetOpen}
              handleStatNavigation={handleStatNavigation}
            />
            <div className="row today-task-main-wrap">
              <div className="col-md-6">
                <AsAssigneePoints
                  data={dailyReportData?.data}
                  handleCardClick={handleCardClick}
                />
              </div>
              <div className="col-md-6">
                <CreatedByMePoints
                  data={dailyReportData?.data}
                  handleCardClick={handleCardClick}
                />
              </div>
            </div>
            {/* <DailyTask
              dailyReportData={dailyReportData?.data?.statsCount}
              handleSetShowTaskType={handleSetShowTaskType}
              handleStatNavigation={handleStatNavigation}
              setShowTasksTypeName={setShowTasksTypeName}
              showTasksType={showTasksType}
            />
            <DueTask
              dailyReportData={dailyReportData?.data?.statsCount}
              handleSetShowTaskType={handleSetShowTaskType}
              handleStatNavigation={handleStatNavigation}
              setShowTasksTypeName={setShowTasksTypeName}
              showTasksType={showTasksType}
            />*/}
            <DueTaskOffCanvas
              show={showTasksType !== null && allTasks?.length > 0}
              isDark={props?.isDark}
              handleCloseModal={() => setShowTasksType(null)}
              props={props}
              dailyReportData={dailyReportData?.data}
              allTasks={allTasks}
              showTasksTypeName={showTasksTypeName}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Dailyreports;
