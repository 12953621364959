export const getFileType = (attachmentName) => {
  const extension = attachmentName?.split(".").pop().toLowerCase();

  // Image extensions
  const imageExtensions = ["jpg", "jpeg", "png"];

  // Video extensions
  const videoExtensions = ["mp4", "mov", "avi", "mkv", "webm"];

  if (imageExtensions.includes(extension)) {
    return "photo";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return extension;
  }
};

export const getIndividualFileType = (filename) => {
  const extensionIndex = filename.lastIndexOf(".");
  if (extensionIndex !== -1) {
    return filename.substring(extensionIndex + 1);
  } else {
    return ""; // Return empty string if no extension found
  }
};
