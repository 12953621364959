import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ConfirmDeleteProjectDocument = (props) => {
  const [dload, setDLoad] = useState(false);
  const navigate = useNavigate();

  // Delete project
  const deleteProjectDocument = async () => {
    try {
      setDLoad(true);
      if (props.showDeleteModal?.status && props.showDeleteModal?.id) {
        await props.handleDeleteFile(props.showDeleteModal?.id);
      }
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={props.showDeleteModal?.status}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        {/*  <h5>{props?.modalContent?.short_description}</h5>
        <h5>{props?.modalContent?.long_description}</h5> */}
        <h5>Are you sure you</h5>
        <h5>want to delete this document</h5>

        <p className="del-modal-name">
          {/**{props.getProjectName(props.projectId)} */}
          <p className="del-modal-name">
            {props.documentName?.length > 20
              ? `${props.documentName.slice(0, 20)}...`
              : props.documentName}
          </p>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteProjectDocument}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteProjectDocument;
