import React, { useState, useRef, useEffect } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import "../../Tasks/styles/create-task-modal.css";
import { Modal, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useFormik } from "formik";
import { Tooltip } from "react-tooltip";
import EditWhite from "../../../assets/edit-white.svg";
import userProfileBlank from "../../../assets/blank_profile.svg";
import API from "../../../services/ClientApi";
import { userProfilePage } from "../../../actions/userActions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const ProfileEditModal = ({
  show,
  handleCloseModal,
  isDark,
  userProfile,
  getUser,
}) => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = useRef();

  const formik = useFormik({
    initialValues: {
      profileImage: userProfile?.userInfo?.profileImage || userProfileBlank,
      fullName: userProfile?.userInfo?.userName || "",
      phoneNumber: userProfile?.updatedProfileDetail?.phone || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoad(true);
      const formData = new FormData();

      // Check if user has cropped a new image
      let profileImageFile;

      // Append only modified fields to formData
      if (
        profileImageFile ||
        values.profileImage !== formik.initialValues.profileImage
      ) {
        formData.append("picture", imageFile || values.profileImage);
      }
      if (values.fullName !== formik.initialValues.fullName) {
        formData.append("fullName", values.fullName);
      }
      if (values.phoneNumber !== formik.initialValues.phoneNumber) {
        formData.append("phone", values.phoneNumber);
      }

      // Check if formData is empty
      if (
        !formData.has("picture") &&
        !formData.has("fullName") &&
        !formData.has("phone")
      ) {
        toast.info("No changes detected.");
        setLoad(false);
        return;
      }

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${
              localStorage.getItem("access_token") || ""
            }`,
          },
        };
        const response = await API.post(
          `users/update-my-profile`,
          formData,
          config
        );
        if (response?.status === 200) {
          let load = false;
          getUser(load);
          await dispatch(userProfilePage());
          toast.success("Profile updated successfully.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          handleCloseModal();
        }
      } catch (error) {
        toast.error("Error updating profile.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } finally {
        setLoad(false);
        setImageFile(null);
      }
    },
    validate: (values) => {
      const errors = {};

      // Full Name Validation
      if (!values.fullName) {
        errors.fullName = "Full Name is required.";
      } else if (!/^[a-zA-Z\s]+$/.test(values.fullName)) {
        errors.fullName = "Name can only contain alphabetic characters.";
      }

      // Phone Number Validation
      if (values.phoneNumber) {
        if (!/^\d{10,15}$/.test(values.phoneNumber)) {
          errors.phoneNumber = "Phone number must be between 10 to 15 digits.";
        }
      }

      return errors;
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setShowCropper(true);
    }

    e.target.value = null;
  };

  const handleCrop = () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const file = new File([blob], `${selectedImage?.name}`, {
          type: blob.type,
        });
        setImageFile(file);
        const croppedImageUrl = URL.createObjectURL(blob);
        formik.setFieldValue("profileImage", croppedImageUrl);
        setShowCropper(false);
      }, "image/jpeg");
    }
  };

  useEffect(() => {
    if (showCropper && selectedImage && imageRef.current) {
      const image = imageRef.current;
      const imageUrl = URL.createObjectURL(selectedImage);
      image.src = imageUrl;

      setCropper(
        new Cropper(image, {
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [showCropper, selectedImage]);

  return (
    <>
      <Modal
        show={show}
        data-theme={isDark}
        centered
        dialogClassName="create-task-modal-main"
      >
        {load && <div className="create-task-is-loading"></div>}
        <Tooltip id="my-tooltip" />

        {/* Header */}
        <div className="create-task-modal-header">
          <p className="mb-0">
            Edit <span>Profile</span>
          </p>
          <button className="custom-close-button" onClick={handleCloseModal}>
            <Icon icon="maki:cross" />
          </button>
        </div>

        <Modal.Body>
          <form
            noValidate
            className="create-new-task-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-group text-center mb-4 profile-image-name-wrap-edit">
              <div className="profile-edit-btn-profile d-flex align-items-center justify-content-center">
                <div className="position-relative w-auto">
                  <a
                    onClick={() =>
                      document.getElementById("profileImageModal").click()
                    }
                  >
                    <img src={EditWhite} alt="edit-icon" />
                  </a>
                  <img
                    className="profile-dash-image"
                    src={formik.values.profileImage}
                    alt="User Profile"
                    onClick={() =>
                      document.getElementById("profileImageModal").click()
                    }
                    onError={(e) => {
                      e.target.src = userProfileBlank;
                    }}
                  />
                </div>
              </div>
              <input
                type="file"
                id="profileImageModal"
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
              />
              {formik.touched.profileImage && formik.errors.profileImage ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.profileImage}
                </div>
              ) : null}
              {/* Full Name and Phone Number Row */}
              <div className="form-row d-flex justify-content-between mt-4">
                <div className="form-group col-6 pe-2">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    className={`form-control ${
                      formik.touched.fullName && formik.errors.fullName
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="invalid-feedback">
                      {formik.errors.fullName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-6 ps-2">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    className={`form-control ${
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="invalid-feedback">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="edit-profile-modal-footer">
          <button
            className="edit-profile-modal-footer-button-2"
            onClick={handleCloseModal}
            disabled={load}
          >
            Cancel
          </button>
          <button
            className="edit-profile-modal-footer-button-1"
            onClick={formik.handleSubmit}
            disabled={load}
          >
            Edit Profile {load && <Spinner size="sm" className="ms-1" />}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Cropper Modal */}
      <Modal
        show={showCropper}
        onHide={() => setShowCropper(false)}
        centered
        size="lg"
        data-theme={isDark}
      >
        <Modal.Header closeButton>
          <h3>
            <span>Crop</span> Image
          </h3>
        </Modal.Header>
        <Modal.Body style={{ height: "400px" }}>
          <div className="w-100 h-100">
            {selectedImage && (
              <img
                ref={imageRef}
                alt="Crop"
                className="w-100 h-100 object-fit-contain"
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="crop-btn"
            onClick={handleCrop}
            disabled={isUploading}
          >
            {isUploading ? <Spinner animation="border" size="sm" /> : "Crop"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileEditModal;
