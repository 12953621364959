import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import LogoDash from "../../../assets/logo-dash.svg";
import "../styles/verify.css";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const EmailVerifyMain = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [verified, setVerified] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token");

  useEffect(() => {
    const handleVerify = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        setLoad(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}users/email-verification`,
          {
            token: token,
          },
          config
        );
        if (response.data.status === "success") {
          setVerified(true);
          navigate("/login");
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } finally {
        setLoad(false);
      }
    };

    handleVerify();
  }, []);

  // Now you have the token and can use it as needed
  return (
    <div className="verify-email-main" data-theme={props.isDark}>
      <Card style={{ width: "36rem" }}>
        <Card.Img variant="top" src={LogoDash} />
        <Card.Body>
          <Card.Title>Verifying your email address</Card.Title>
          <Card.Text>
            You are almost ready to get started. Please wait while we verify
            your email
          </Card.Text>
          {!verified ? (
            <div className="w-100 align-items-center justify-content-center text-center">
              <Spinner animation="border" className="global-spinner" />
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
      {/* Other components or logic related to the token */}
    </div>
  );
};

export default EmailVerifyMain;
