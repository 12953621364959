// Create a dummy message object
export function createMessageObject(
  body,
  selectedChat,
  user,
  placeholderAttachments
) {
  const { content, message_type, replyToMessageId, noLoad, replyToMessage } =
    body;

  // Extract sender and receiver
  const sender = selectedChat.otherParticipants.find(
    (participant) => participant.user_id === user.userId
  );
  const receiver = selectedChat.otherParticipants.find(
    (participant) => participant.user_id !== user.userId
  );

  // Construct the message object
  const messageObject = {
    attachments: placeholderAttachments ? [placeholderAttachments] : [], // Assuming no attachments in this case
    content: content,
    isForwarded: null, // Assuming no forwarded messages here
    messageId: generateUUID(), // Generate a temporary unique ID
    messageType: message_type,
    noLoad: noLoad,
    receiver: {
      chatStatus: receiver.user_chat_status,
      fullName: receiver.user_name,
      id: receiver.user_id,
    },
    sender: {
      chatStatus: sender.user_chat_status,
      fullName: sender.user_name,
      id: sender.user_id,
    },
    room: {
      id: selectedChat.room_id,
      roomName: selectedChat.room_name,
      type: selectedChat.room_type,
      updatedAt: selectedChat.room_updated_at,
    },
    replyToMessage: replyToMessageId ? replyToMessage : null, // Add reply message details if available
    sentAt: new Date().toISOString(), // Current timestamp
  };

  return messageObject;
}

// Utility function to generate UUID
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Calculate weeks ago by date
export function calculateWeeksAgo(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  // Normalize input date and current date to the start of their respective weeks
  const normalizeToStartOfWeek = (date) => {
    const normalized = new Date(date);
    const day = normalized.getDay(); // 0 is Sunday
    const diffToMonday = day === 0 ? 6 : day - 1; // Adjust for Monday start
    normalized.setDate(normalized.getDate() - diffToMonday);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  const inputStartOfWeek = normalizeToStartOfWeek(inputDate);
  const currentStartOfWeek = normalizeToStartOfWeek(currentDate);

  // Calculate the difference in weeks
  const timeDifference = currentStartOfWeek - inputStartOfWeek;
  const weeksDifference = Math.floor(
    timeDifference / (7 * 24 * 60 * 60 * 1000)
  );
  // console.log("TW : ", [weeksDifference, timeDifference]);
  // If it's within the current week, return 7
  if (timeDifference <= 0) return 7;

  // Otherwise, calculate the multiplier (14, 21, ...)
  return (weeksDifference + 1) * 7;
}

// Group messages by date
export const groupMessagesByDate = (messages = []) => {
  if (!Array.isArray(messages)) {
    console.error("Invalid messages input:", messages);
    return {}; // Return an empty object if messages is not an array
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const options = { weekday: "long" }; // e.g., "Monday", "Tuesday"

  const formatDate = (date) => {
    if (date.toDateString() === today.toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
    if (
      date >
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
    ) {
      return new Intl.DateTimeFormat("en-US", options).format(date); // e.g., "Monday"
    }
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date); // e.g., "12 January 2025"
  };
  console.log("====================================");
  console.log("messages", messages);
  console.log("====================================");
  return messages?.length > 0
    ? messages?.reduce((acc, message) => {
        if (!message || !message.sentAt) return acc; // Skip invalid messages

        const messageDate = new Date(message.sentAt);
        const groupName = formatDate(messageDate);

        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(message);

        return acc;
      }, {})
    : {};
};

// Return days since oldest message
export const getDaysSinceOldestMessage = (groupedMessages) => {
  // Extract all message dates from grouped messages
  const messageDates = Object.values(groupedMessages)
    .flat() // Flatten the arrays to get all messages
    .map((msg) => new Date(msg.sentAt)) // Convert sentAt timestamps to Date objects
    .filter((date) => !isNaN(date)); // Remove invalid dates

  if (!messageDates.length) return 0;

  // Find the oldest message date
  const oldestDate = new Date(Math.min(...messageDates));
  const currentDate = new Date();

  // Calculate the difference in days
  const diffTime = Math.abs(currentDate - oldestDate);
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

// export const getDaysSinceOldestMessage = (messages) => {
//   if (!messages.length) return 0;

//   // Extract oldest message based on sentAt date
//   const oldestMessage = messages.reduce((oldest, current) =>
//     new Date(current.sentAt) < new Date(oldest.sentAt) ? current : oldest
//   );

//   // Calculate difference in days
//   const oldestDate = new Date(oldestMessage.sentAt);
//   const currentDate = new Date();
//   const diffTime = Math.abs(currentDate - oldestDate);
//   return Math.floor(diffTime / (1000 * 60 * 60 * 24));
// };

// Messages length
export const getTotalMessagesLength = (obj) => {
  return Object.values(obj).reduce(
    (total, arr) => total + (Array.isArray(arr) ? arr.length : 0),
    0
  );
};
