import React from "react";
import Setting from "./components/Setting";

const SettingMain = (props) => {
  return (
    <Setting
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default SettingMain;
