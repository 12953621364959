/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Overview from "./Overview";
import PaymentsHistory from "./PaymentsHistory";
import BillingInfo from "./BillingInfo";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const SubscriptionsMain = (props) => {
  const [state, setState] = useState(
    sessionStorage.getItem("substate")
      ? parseInt(sessionStorage.getItem("substate"))
      : 0
  );

  const { applicationName } = useSelector((state) => state.text);
  // New state

  const handleState = (value) => {
    sessionStorage.setItem("substate", value);
    setState(value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Subscriptions-{applicationName}</title>
      </Helmet>
      <div className="subscription-main-wraper">
        <div
          className={`col-xxl-10 col-12 ${
            state === 1 ? "billing-sub-wrap" : ""
          } ${state === 0 ? "overview-sub-wrap" : ""}`}
        >
          <div className="nav-subscription-wrap">
            <ul className="custom-horizental-scrollbar">
              <li>
                <a
                  className={`${state === 0 ? "active-overview" : ""}`}
                  onClick={() => handleState(0)}
                >
                  {" "}
                  Overview{" "}
                </a>
                <div
                  className={`${state === 0 ? "active-overview" : ""}`}
                ></div>
              </li>
              <li>
                <a
                  className={`${state === 2 ? "active-overview" : ""}`}
                  onClick={() => handleState(2)}
                >
                  {" "}
                  Billing{" "}
                </a>
                <div></div>
              </li>
              <li>
                <a
                  className={`${state === 1 ? "active-overview" : ""}`}
                  onClick={() => handleState(1)}
                >
                  Card Details
                </a>
                <div></div>
              </li>
              <div className="w-100 "></div>
            </ul>
          </div>
          {state === 0 && (
            <Overview isDark={props.isDark} getOptions={props.getOptions} />
          )}

          {state === 1 && (
            <BillingInfo isDark={props.isDark} handleState={handleState} />
          )}
          {state === 2 && <PaymentsHistory isDark={props.isDark} />}
        </div>
      </div>
    </>
  );
};

export default SubscriptionsMain;
