import React, { useState } from "react";
import DropdownProgress from "./DropdownProgress";
import { Icon } from "@iconify/react";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { Link } from "react-router-dom";
import { isPermitted } from "../../../helper/isPermitted";

const ProgressDropdownMain = (props) => {
  const [icon, setIcon] = useState(false);
  const canEdit = props.canEdit && isPermitted("project/pm-update-project");
  return props.case ? (
    <DropdownProgress
      data={props?.data}
      dropdownRenderer={props.dropdownRenderer}
      isDark={props?.isDark}
    />
  ) : (
    <div className="project-list-active">
      <Link
        to={`/${localStorage.getItem("company")}/project?pid=${
          props.data?.projectId
        }&pname=${props?.data?.projectName}`}
      >
        <img
          className="project-listing-icon-img"
          src={
            getProjectIconById(props?.data?.projectIcon)
              ? getProjectIconById(props?.data?.projectIcon)
              : props?.data?.projectIcon
          }
          alt="project-icon"
        />
      </Link>
      <div
        className={`project-heading-info ${canEdit ? "project-cursor" : ""}`}
        onMouseOver={() => {
          setIcon(true);
        }}
        onMouseLeave={() => {
          setIcon(false);
        }}
      >
        <h5
          onMouseOver={() => {
            if (canEdit) {
              props.setEdit(true);
            }
          }}
          onMouseLeave={() => {
            props.setEdit(false);
          }}
          onClick={() => {
            props.handleEdit(props.api, props.row);
          }}
        >
          {props?.data?.projectName?.length > 50
            ? props?.data?.projectName?.substring(0, 50) + "..."
            : props?.data?.projectName}
          {/* {icon && canEdit ? (
            <Icon
              icon="ri:pencil-fill"
              className="ms-2"
              onMouseOver={() => props.setEdit(true)}
              onMouseLeave={() => props.setEdit(false)}
              onClick={() => {
                props.handleEdit(props.api, props.row);
              }}
            />
          ) : (
            ""
          )} */}
        </h5>
        <div className="position-relative">
          <DropdownProgress
            data={props?.data}
            isDark={props?.isDark}
            dropdownRenderer={props.dropdownRenderer}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressDropdownMain;
