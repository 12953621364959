import React, { useEffect, useState } from "react";
import StripeLogo from "../../../assets/stripe-logo.png";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteUnSubscribeModel from "./DeleteUnSubscribeModel";
import DeleteReSubscribeModel from "./DeleteReSubscribeModel";
import { useDispatch, useSelector } from "react-redux";
import { billingData } from "../../../actions/paymentActions";
import { dateFormatter } from "../../../helper/dateFormatter";
import ConfirmCardDeleteModal from "./ConfirmCardDeleteModal";
import UpdateCreditCardModal from "./UpdateCreditCardModal";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { ShimmerTable } from "react-shimmer-effects";

const BillingInfo = (props) => {
  const dispatch = useDispatch();
  const { billingDetail, bloading } = useSelector((state) => state.billing);
  const [isOpen, setIsOpen] = useState(false);
  const [loadbtn, setLoadBtn] = useState(false);
  const [planData, setPlanData] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);

  const verifyModalContent = findObjectByContentTitle(
    "card-data",
    modalContents?.data
  );

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(billingData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnSubscribe = async (id) => {
    try {
      const response = await API.post(
        "company/stripe-unsubscribe-subscription",
        { subscriptionId: id }
      );
      if (response?.data?.status === "success") {
        await dispatch(billingData());
        toast.success(
          response?.data?.message ||
            "Subscription canceled at the end of the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      } else {
        toast.error(
          response.response.data.message ||
            "Failed to canceled the subscription at the end of the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      toast.error(
        "Failed to canceled the subscription at the end of the current billing period.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    }
  };

  const handleReSubscribe = async (
    packageId,
    stripeProductId,
    totalAmount,
    setCount,
    interval
  ) => {
    try {
      const response = await API.post(
        "company/stripe-resubscribe-subscription",
        {
          packageId: packageId,
          stripeProductId: stripeProductId,
          totalAmount: parseInt(totalAmount),
          seatCount: parseInt(setCount),
          interval: interval,
        }
      );
      if (response?.data?.status === "success") {
        await dispatch(billingData());
        toast.success(
          response?.data?.message || "Pacakage ReSubscribed Successfully.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      } else {
        toast.error(
          response?.data?.message ||
            "Failed to resubscribe at the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      toast.error("Failed to resubscribe at the current billing period.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteCardInfo = async (billingInfoItem) => {
    try {
      setLoadBtn(true);
      const response = await API.post(
        "company/stripe-remove-card-subscription",
        { subscriptionId: billingInfoItem?.id }
      );
      if (response?.data?.status === "success") {
        await dispatch(billingData());
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
    } finally {
      setLoadBtn(false);
    }
  };

  const getPlanData = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPlanData(response?.data?.data?.packageDetails);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getPlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-billing-detail-wraper">
        <h3>
          {" "}
          <span>Card</span> Details{" "}
        </h3>
        <div className="col-md-12 mb-3">
          <p className="role-page-description">
            {verifyModalContent?.short_description}{" "}
            <span className="see-more-txt" onClick={() => setSeeMore(true)}>
              See More
            </span>
          </p>
        </div>
        {bloading ? (
          <div className="position-relative">
            <div className="w-75">
              <ShimmerTable row={1} />
            </div>
          </div>
        ) : (
          <>
            {billingDetail?.length > 0 ? (
              <div className="table-billing-info-wrap">
                <div className="table-responsive custom-horizental-scrollbar">
                  <table
                    className="table table-billing table-billing-main"
                    width="100%"
                  >
                    <tbody>
                      {billingDetail &&
                        billingDetail?.map((billingInfoItem, index) => {
                          return (
                            <BillingInfoTableRow
                              billingInfoItem={billingInfoItem}
                              index={index}
                              handleUnSubscribe={handleUnSubscribe}
                              isDark={props.isDark}
                              handleReSubscribe={handleReSubscribe}
                              setIsOpen={setIsOpen}
                              handleDeleteCardInfo={handleDeleteCardInfo}
                              loadbtn={loadbtn}
                              planData={planData}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="d-flex mt-2 align-items-center rounded-3 ">
                <p
                  className="no-billing-txt"
                  onClick={() => props.handleState(0)}
                >
                  You don't have any payment attached with the system,{" "}
                  <span>subscribe </span>
                  the package that meets your requirements
                </p>
              </div>
            )}
          </>
        )}
        <UpdateCreditCardModal
          isDark={props.isDark}
          isOpen={isOpen}
          onClose={onClose}
        />
        <SeeMoreModal
          seeMore={seeMore}
          setSeeMore={setSeeMore}
          headTextOne="Card"
          headTextTwo="Details"
          isDark={props.isDark}
          modalContent={verifyModalContent}
        />
      </div>
    </>
  );
};

export default BillingInfo;

function BillingInfoTableRow({
  billingInfoItem,
  index,
  handleUnSubscribe,
  isDark,
  handleReSubscribe,
  setIsOpen,
  handleDeleteCardInfo,
  loadbtn,
  setLoadBtn,
  planData,
}) {
  const [showDeleteUnsubModel, setShowDeleteUnsubModel] = useState(false);
  const [showDeleteSubModel, setShowDeleteSubModel] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { applicationName } = useSelector((state) => state.text);

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };

  const handleCloseDeleteUnsubModal = () => {
    setShowDeleteUnsubModel(false);
  };
  const handleCloseDeleteSubModal = () => {
    setShowDeleteSubModel(false);
  };

  return (
    <>
      <tr key={index}>
        <td width="15%">
          {billingInfoItem?.paymentMethod === "Stripe" && (
            <img
              className="stripe-img"
              src={StripeLogo}
              alt={`stripe-${applicationName}`}
            />
          )}
        </td>
        <td width="20%">*************{billingInfoItem.cardNumber}</td>
        <td width="20%">
          Expiry Date: {dateFormatter(billingInfoItem.cardExpiry, 2)}
        </td>
        <td width="45%">
          <div className="w-100 billing-options">
            {/* <button className="add-ons-btn">Add-ons</button> */}
            <button
              className="remove-card-btn"
              onClick={() => setShowConfirmDeleteModal(true)}
            >
              Remove card
            </button>
            <button className="modify-package" onClick={() => setIsOpen(true)}>
              Change credit card
            </button>
          </div>
        </td>
      </tr>
      <DeleteUnSubscribeModel
        showDeleteModal={showDeleteUnsubModel}
        handleCloseDeleteModal={handleCloseDeleteUnsubModal}
        handleSubmit={async (id) => await handleUnSubscribe(id)}
        billingId={billingInfoItem?.id}
        isDark={isDark}
        title={"Are You Sure You Want To Unsubscribe?"}
        buttonTitle={"Unsubscribe"}
      />
      <DeleteReSubscribeModel
        showDeleteModal={showDeleteSubModel}
        handleCloseDeleteModal={handleCloseDeleteSubModal}
        handleSubmit={async (
          packageId,
          stripeProductId,
          totalAmount,
          setCount,
          interval
        ) =>
          await handleReSubscribe(
            packageId,
            stripeProductId,
            totalAmount,
            setCount,
            interval
          )
        }
        packageId={billingInfoItem?.packageId}
        stripeProductId={billingInfoItem?.stripeProductId}
        seatCount={billingInfoItem?.seatCount}
        interval={billingInfoItem?.interval}
        totalAmount={billingInfoItem?.totalAmount}
        isDark={isDark}
        title={"Are You Sure You Want To Subscribe?"}
        buttonTitle={"Subscribe"}
      />
      <ConfirmCardDeleteModal
        showConfirmDeleteModal={showConfirmDeleteModal}
        handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
        loadbtn={loadbtn}
        isDark={isDark}
        billingInfoItem={billingInfoItem}
        handleDeleteCardInfo={handleDeleteCardInfo}
        setIsOpen={setIsOpen}
        planData={planData}
      />
    </>
  );
}
