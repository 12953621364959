import React, { useEffect, useState } from "react";
import "./styles/chatSidebar.css";
import { FaSearch } from "react-icons/fa";
import ChatMain from "../pages/Chat/ChatMain";
import { calculateShortDurationAgo } from "../helper/timeAgoFormatter";
import socket from "../services/socket";
import notificationSound from "../assets/message-notification-new.mp3";
import { toast } from "react-toastify";
import { onMessageListener, requestForToken } from "../firebase-config";
import chatApi, {
  useClearChatUsersMutation,
  useClearMessagesMutation,
} from "../slices/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import saveLocalStorageToCookies from "../helper/saveLocalStorageToCookies";

function ChatSidebar({
  users,
  isDark,
  setAllUsers,
  allUsers,
  roomData,
  setRoomData,
}) {
  const [clearMessages] = useClearMessagesMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { userProfile } = useSelector((state) => state?.profile);
  const [searchTerm, setSearchTerm] = useState("");
  const [showChatModal, setShowChatModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [notificationMain, setNotification] = useState({
    title: "",
    body: "",
    company: "",
  });

  const [isMessageNotification, setIsMessageNotification] = useState(false);

  const getQueryParams = (param, search) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(param);
  };

  const navigateByType = (data) => {
    if (!data?.url) return;

    if (data.url.includes("subscriptions")) {
      saveLocalStorageToCookies(`/company-subscriptions`);
    } else {
      navigate(`/${data?.company}${data?.url}`);
    }
  };

  useEffect(() => {
    const chats = getQueryParams("chats", location.search);
    if (chats === "true") {
      localStorage.removeItem("rid");

      setShowChatModal(true);
      const url = new URL(window.location.href);
      if (url.searchParams.has("chats")) {
        url.searchParams.delete("chats");
        window.history.replaceState(null, "", url.toString());
      }
      // Small delay ensures state change triggers
    } else {
      setShowChatModal(false); // Close when "chats" is removed
    }
  }, [window.location.href]);
  // Notifications;
  // requestForToken();
  onMessageListener()
    .then((payload) => {
      console.log("payload", "chatSideBar", payload);

      if (
        payload?.data?.companySlug === userProfile?.companySlug &&
        payload?.data?.type !== "new-message"
      ) {
        setNotification({
          title: payload?.data?.title,
          body: payload?.data?.body,
          url: payload?.data?.url,
          company: payload?.data?.companySlug,
        });
      } else if (
        payload?.data?.companySlug === userProfile?.companySlug &&
        payload?.data?.type === "new-message"
      ) {
        if (!showChatModal) {
          setIsMessageNotification(true);
          setNotification({
            title: payload?.data?.title,
            body: payload?.data?.body,
            url: payload?.data?.url,
            company: payload?.data?.companySlug,
          });
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  function Message() {
    return notificationMain?.url ? (
      <div
        onClick={async () => {
          if (isMessageNotification) {
            setIsMessageNotification(false);
            localStorage.removeItem("rid");
            setShowChatModal(true);
          } else {
            navigateByType(notificationMain);
          }
        }}
        className="notifiy-snackbar"
      >
        <div>
          <p className="cloud-notification-text cloud-txt-1">
            <b>{notificationMain?.title}</b>
          </p>
          <p className="cloud-notification-text cloud-txt-2">
            {notificationMain?.body}
          </p>
        </div>
      </div>
    ) : (
      <div
        onClick={() => {
          if (isMessageNotification) {
            setShowChatModal(true);
          } else {
            navigateByType(notificationMain);
          }
        }}
      >
        <div>
          <p className="cloud-notification-text cloud-txt-1">
            <b>{notificationMain?.title}</b>
          </p>
          <p className="cloud-notification-text cloud-txt-2">
            {notificationMain?.body}
          </p>
        </div>
      </div>
    );
  }

  // Define a function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const msg = () => {
    playNotificationSound();
    toast(<Message />, {
      position: "bottom-right", // Position the toast
      type: "info", // Set the toast type
      hideProgressBar: true,
      autoClose: 5000, // 5 seconds in milliseconds
    });
  };

  useEffect(() => {
    if (notificationMain?.title) {
      // getNotificationUnreadCount();
      msg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMain]);

  // const filteredUsers = users?.filter((user) =>
  //   user?.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    if (roomData) {
      setShowChatModal(true);
    }
  }, [roomData]);

  useEffect(() => {
    // Listen for the availability status change event
    socket.on("availabilityStatusChange", (data) => {
      socket.emit("fetchAllUsers", {
        userId: userProfile.userId,
        companyId: userProfile.companyId,
      });
    }); // Cleanup on component unmount
    return () => {
      socket.off("availabilityStatusChange");
    };
  }, []);

  useEffect(() => {
    const adjustScrollbarHeight = () => {
      const sidebar = document.querySelector(".left-nav-wrap "); // Replace with your sidebar class
      const chatScrollbar = document.querySelector(".custom-chat-scrollbar");

      if (!sidebar || !chatScrollbar) return;

      // Check if the window height is 832px or smaller
      if (window.innerHeight <= 832) {
        const sidebarHeight = sidebar.offsetHeight;
        const scrollbarOffsetTop = chatScrollbar.offsetTop;

        // Calculate the remaining height
        const remainingHeight = sidebarHeight - scrollbarOffsetTop;

        // Apply the height dynamically
        chatScrollbar.style.height = `${remainingHeight - 80}px`;
      } else {
        // Reset the height for larger screens
        chatScrollbar.style.height = "495px"; // Default height
      }
    };

    // Run on initial load
    adjustScrollbarHeight();

    // // Attach event listener for window resize
    // window.addEventListener("resize", adjustScrollbarHeight);

    // // Cleanup event listener on unmount
    // return () => window.removeEventListener("resize", adjustScrollbarHeight);
    // Attach ResizeObserver to watch for changes in the sidebar size
    const sidebar = document.querySelector(".left-nav-wrap");
    if (sidebar) {
      const resizeObserver = new ResizeObserver(() => {
        adjustScrollbarHeight();
      });
      resizeObserver.observe(sidebar);

      // Cleanup observer on unmount
      return () => resizeObserver.disconnect();
    }
  }, []); // Empty dependency array to run only on mount and unmount

  useEffect(() => {
    if (users && searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filtered = users.filter((user) =>
        user?.user_name?.toLowerCase().includes(lowerCaseSearchTerm)
      );

      const sorted = filtered.sort((a, b) => {
        // Prioritize users with 'unseenMessageCount > 0'
        if (a.unseenMessageCount > 0 && b.unseenMessageCount === 0) {
          return -1; // 'a' comes before 'b'
        }
        if (a.unseenMessageCount === 0 && b.unseenMessageCount > 0) {
          return 1; // 'b' comes before 'a'
        }

        // Further prioritize users based on chat status: "online", "away", others
        const statusOrder = ["online", "away"];
        const aStatus = a.user_chat_status?.toLowerCase() || "";
        const bStatus = b.user_chat_status?.toLowerCase() || "";
        const aStatusIndex = statusOrder.indexOf(aStatus);
        const bStatusIndex = statusOrder.indexOf(bStatus);

        if (aStatusIndex !== -1 && bStatusIndex === -1) {
          return -1; // 'a' comes before 'b'
        }
        if (aStatusIndex === -1 && bStatusIndex !== -1) {
          return 1; // 'b' comes before 'a'
        }
        if (aStatusIndex !== -1 && bStatusIndex !== -1) {
          return aStatusIndex - bStatusIndex; // Sort by order in 'statusOrder'
        }

        return 0; // No change in order
      });

      setFilteredUsers(sorted);
    } else if (users) {
      const sorted = [...users].sort((a, b) => {
        // Prioritize users with 'unseenMessageCount > 0'
        if (a.unseenMessageCount > 0 && b.unseenMessageCount === 0) {
          return -1; // 'a' comes before 'b'
        }
        if (a.unseenMessageCount === 0 && b.unseenMessageCount > 0) {
          return 1; // 'b' comes before 'a'
        }

        // Further prioritize users based on chat status: "online", "away", others
        const statusOrder = ["online", "away"];
        const aStatus = a.user_chat_status?.toLowerCase() || "";
        const bStatus = b.user_chat_status?.toLowerCase() || "";
        const aStatusIndex = statusOrder.indexOf(aStatus);
        const bStatusIndex = statusOrder.indexOf(bStatus);

        if (aStatusIndex !== -1 && bStatusIndex === -1) {
          return -1; // 'a' comes before 'b'
        }
        if (aStatusIndex === -1 && bStatusIndex !== -1) {
          return 1; // 'b' comes before 'a'
        }
        if (aStatusIndex !== -1 && bStatusIndex !== -1) {
          return aStatusIndex - bStatusIndex; // Sort by order in 'statusOrder'
        }

        return 0; // No change in order
      });

      setFilteredUsers(sorted);
    } else {
      setFilteredUsers([]);
    }
  }, [users, searchTerm]);

  return (
    <div className="chat-sidebar">
      <h2 className="mt-3 mb-2">CHAT</h2>
      <form autoFocus={false} autoComplete="off" className="search-bar">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoComplete="off"
        />
        <input type="hidden" value="something" />
        <FaSearch className="search-icon" />
      </form>
      <ul className="user-list custom-chat-scrollbar">
        {filteredUsers?.map((user) => (
          <li
            key={user.user_id}
            className="chat-user-wrap"
            onClick={() => {
              localStorage.removeItem("rid");
              setRoomData(user);
              // setShowChatModal(true);
            }}
          >
            <div className="position-relative">
              {user?.user_profile_image ? (
                <img
                  src={
                    user.user_profile_image || "https://via.placeholder.com/40"
                  }
                  alt={user.user_name}
                  className="user-img me-1"
                />
              ) : (
                <div
                  className={"user-list-card-user-image-name me-1"}
                  style={{ width: "35px", height: "35px" }}
                >
                  {user?.user_name[0]?.toUpperCase()}
                  {user?.user_name?.split(" ")[1]
                    ? user?.user_name?.split(" ")[1][0]?.toUpperCase()
                    : user?.user_name[1]?.toUpperCase()}
                </div>
              )}
              {!user?.unseenMessageCount || user?.unseenMessageCount <= 0 ? (
                <span
                  className={`${
                    user?.user_chat_status === "online"
                      ? "online-status"
                      : user?.user_chat_status === "away"
                      ? "away-status"
                      : "user-offline"
                  }`}
                ></span>
              ) : (
                <span className="unseen-message-count ps-0">
                  {user?.unseenMessageCount}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between user-info gap-2">
              <p className="user-name">{user.user_name}</p>
              {user.lastMessage_sent_at && (
                <p className="user-time mb-0 me-1">
                  {calculateShortDurationAgo(user.lastMessage_sent_at)}
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>
      {showChatModal && (
        <ChatMain
          isDark={isDark}
          show={showChatModal}
          handleCloseModal={async () => {
            setShowChatModal(false);
          }}
          setShowChatModal={setShowChatModal}
          roomData={roomData}
          setRoomData={setRoomData}
          setAllUsers={setAllUsers}
          allUsers={allUsers}
          initialLoad={initialLoad}
          setInitialLoad={setInitialLoad}
        />
      )}
    </div>
  );
}

export default ChatSidebar;
