/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../styles/board.css";
import { Icon } from "@iconify/react";
import { Dropdown, Spinner } from "react-bootstrap";
import EditIcon from "../../../../../assets/edit-project.svg";
import Eyes from "../../../../../assets/eyes.svg";
import Delete from "../../../../../assets/delete.svg";
import { v4 as uuidv4 } from "uuid";
import TaskDetailOffCanvas from "../../../../Tasks/components/TaskDetailOffCanvas";
import {
  ProjectAPI,
  ProjectFormDataAPI,
} from "../../../../../services/ClientApi";
import ConfirmDeleteTaskModal from "../../../../Tasks/components/ConfirmDeleteTaskModal";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import UpdateTaskOffcanvas from "../../../../Tasks/components/UpdateTaskOffcanvas";
import {
  isMemberOfTask,
  isOwnerInTaskOne,
} from "../../../../../helper/projectOwner";
import { isPermitted } from "../../../../../helper/isPermitted";
import { isAdmin } from "../../../../../helper/isUserAdmin";
import NoStatusAllowedModal from "../../../../../components/NoStatusAllowedModal/NoStatusAllowedModal";
import {
  ShimmerBadge,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import { useUpdateKanbanAllTaskListingsMutation } from "../../../../../slices/tasks/kanbanSlice";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};
const Board = ({
  isDark,
  projectId,
  getAllTasks,
  onKanban,
  loadKanban,
  setLoadKanban,
  projectStatusList,
  setKanban,
  setAllLoad,
}) => {
  const [updateKanbanAllTaskListings] =
    useUpdateKanbanAllTaskListingsMutation();
  const [hoveredTask, setHoveredTask] = useState(null);
  const [load, setLoad] = useState(false);
  const [addLoad, setAddLoad] = useState(false);
  const [, setHoverGroup] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [tasksData, setTasksData] = useState([]);
  const [show, setShow] = useState(false);
  const [, setNewTaskInput] = useState({ columnIndex: null });
  const [id, setId] = useState(null);
  // State to hold the mapping of status names to their IDs
  const [statusIdMap, setStatusIdMap] = useState({});
  const handleCloseModal = () => {
    setShow(false);
  };
  const [typ, setTyp] = useState({ typeId: "", taskIndex: "" });
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const handleClose = () => {
    setShowStatusModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // Function to create the mapping of status names to their IDs
  const createStatusIdMap = (originalObject) => {
    const map = {};
    for (const statusName in originalObject) {
      map[originalObject[statusName].StatusName] =
        originalObject[statusName].StatusId;
    }
    return map;
  };

  // Function to replace object keys with numeric indexes
  const replaceObjectKeys = (originalObject) => {
    const newObject = {};
    let index = 0;
    for (const statusName in originalObject) {
      newObject[index++] = originalObject[statusName];
    }
    return newObject;
  };

  const removeTaskById = (taskId) => {
    // Iterate through tasksData to find the column containing the task with the given taskId
    const updatedTasksData = tasksData.map((column) => {
      // Filter out the task with the given taskId if it exists in the current column
      const updatedTasks = column.tasks.filter(
        (task) => task.task_id !== taskId
      );
      // Return the column with the updated tasks
      return {
        ...column,
        tasks: updatedTasks,
      };
    });

    // Update the tasksData state with the modified array
    setTasksData(updatedTasksData);
  };

  const getTaskData = async () => {
    if (!loadKanban) {
      setLoad(true);
    }
    try {
      const response = await ProjectAPI.post("task/kb-listing", {
        projectId: projectId,
      });
      const originalData = response?.data?.data;
      let map = createStatusIdMap(originalData);
      map = replaceObjectKeys(map);
      setStatusIdMap(map);
      const transformedData = Object.keys(originalData).map((statusName) => {
        const statusData = originalData[statusName];
        const isCompleted = statusName === "Completed"; // Check if the status name is "Completed"
        return {
          type_id: statusData.StatusId,
          type: statusData.StatusName,
          type_count: statusData.Tasks.length,
          is_completed: isCompleted, // Set is_completed based on the status name
          tasks: statusData.Tasks.map((task) => ({
            task_id: task.id,
            task_description: task.taskName,
            users: task.taskMembers.map((member) => ({
              user_id: member.userId,
              user_name: member.userName,
              avatar: member.profileImage,
              memberType: member.memberType,
            })),
            order: task.taskDisplayOrder,
            approval: task.hasApproval,
          })).sort((a, b) => a.order - b.order),
        };
      });
      setTasksData(transformedData);
    } catch (error) {
    } finally {
      setLoad(false);
      setLoadKanban(false);
    }
  };

  useEffect(() => {
    getTaskData();
  }, [onKanban]);

  // Update name
  const updateProject = async (data) => {
    try {
      await ProjectAPI.post(`task/update`, data);
    } catch (error) {
      // toast.error(error, { position: toast.POSITION.TOP_CENTER });
    }
  };

  const isStatusCompleted = (id) => {
    // Find the object with the given id in the dataArray
    const statusObject = projectStatusList?.find((item) => item.id === id);

    // Check if the statusObject exists and its statusName is "Completed"
    return statusObject && statusObject.statusName === "Completed";
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
    // If dropped outside of droppable area or on the same position, do nothing
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    if (type === "COLUMN") {
      // Reordering columns
      const updatedTasksData = Array.from(tasksData);
      const [removedColumn] = updatedTasksData.splice(source.index, 1);
      updatedTasksData.splice(destination.index, 0, removedColumn);
      setTasksData(updatedTasksData);
    } else {
      // If dragging tasks
      const sourceTypeIndex = parseInt(source.droppableId);
      const destinationTypeIndex = parseInt(destination.droppableId);
      const updatedTasksData = [...tasksData];

      // If dropped outside any droppable area
      if (destination.droppableId === "board") {
        const sourceTasks = [...updatedTasksData[sourceTypeIndex].tasks];
        const [draggedTask] = sourceTasks.splice(source.index, 1);
        const destinationTasks = [
          ...updatedTasksData[destination.index].tasks,
          draggedTask,
        ];
        updatedTasksData[sourceTypeIndex] = {
          ...updatedTasksData[sourceTypeIndex],
          tasks: sourceTasks,
        };
        updatedTasksData[destination.index] = {
          ...updatedTasksData[destination.index],
          tasks: destinationTasks,
        };
        setTasksData(updatedTasksData);
      } else {
        // If dragging within the same board
        if (sourceTypeIndex === destinationTypeIndex) {
          const tasks = [...updatedTasksData[sourceTypeIndex].tasks];
          const [removedTask] = tasks.splice(source.index, 1);
          tasks.splice(destination.index, 0, removedTask);
          updatedTasksData[sourceTypeIndex] = {
            ...updatedTasksData[sourceTypeIndex],
            tasks,
          };
          setTasksData(updatedTasksData);
          tasks.map(async (n, i) => {
            await updateProject({
              taskId: n.task_id,
              fieldName: "taskDisplayOrder",
              fieldValues: i,
            });
          });
        } else {
          const sourceTasks = [...updatedTasksData[sourceTypeIndex].tasks];
          const destinationTasks = [
            ...updatedTasksData[destinationTypeIndex].tasks,
          ];
          const [draggedTask] = sourceTasks.splice(source.index, 1);
          if (
            draggedTask?.approval &&
            isStatusCompleted(statusIdMap[parseInt(destination.droppableId)]) &&
            !isOwnerInTaskOne(draggedTask, userProfile?.userId) &&
            !isAdmin(userProfile)
          ) {
            setShowStatusModal(true);
            return;
          } else {
            destinationTasks.splice(destination.index, 0, draggedTask);
            updatedTasksData[sourceTypeIndex] = {
              ...updatedTasksData[sourceTypeIndex],
              tasks: sourceTasks,
            };
            updatedTasksData[destinationTypeIndex] = {
              ...updatedTasksData[destinationTypeIndex],
              tasks: destinationTasks,
            };
            const taskId = draggableId; // Assuming draggableId is taskId
            const statusId = statusIdMap[parseInt(destination.droppableId)]; // Assuming droppableId is statusId
            setTasksData(updatedTasksData);
            const data = {
              taskId: taskId,
              fieldName: "taskStatusId",
              fieldValues: statusId,
            };
            await updateProject(data);
            // Update the display order for tasks in the destination column
            destinationTasks.map(async (task, index) => {
              await updateProject({
                taskId: task.task_id,
                fieldName: "taskDisplayOrder",
                fieldValues: index,
              });
            });
          }
        }
      }
    }
  };

  const addNewTask = (typeId) => {
    const newTask = {
      task_id: uuidv4(),
      task_description: "", // Empty task description initially
      users: [],
      order: 1,
      approval: false,
    };

    setTasksData((prevState) => {
      const updatedData = [...prevState];
      const columnIndex = updatedData.findIndex(
        (column) => column.type_id === typeId
      );
      if (columnIndex !== -1) {
        updatedData[columnIndex].tasks.unshift(newTask); // Add new task to the top
      }
      return updatedData;
    });

    setNewTaskInput({ typeId });
  };

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const handleDescriptionChange = async (e, typeId, taskIndex) => {
    let formData = new FormData();
    formData.append("taskName", e.target.value);
    formData.append("projectId", projectId);
    // formData.append("projectPhaseId", "6687acd6-31c5-42dc-a07e-dfd469f11c8f");
    formData.append("taskStatusId", typeId);
    formData.append("taskDisplayOrder", 0);
    formData.append("hasApproval", false);
    let taskObserver = "";
    let taskMembers = [];
    if (userProfile?.userId) {
      const newTaskMembers = `${getTypeIDByName("owner")}:${
        userProfile?.userId
      }`;
      taskObserver = newTaskMembers;
    }

    if (taskObserver.length > 0) {
      taskMembers = [taskObserver];
    }

    taskMembers.forEach((memberId, index) => {
      formData.append(`taskMembers[]`, memberId);
    });

    try {
      setAddLoad(true);
      const response = await ProjectFormDataAPI.post(
        "task/create-new",
        formData
      );
      if (response.status === 201) {
        const updatedDescription = response?.data?.data?.taskName;
        const updatedUsers = response?.data?.data?.taskMembers?.map(
          (member) => ({
            user_id: member.userId,
            user_name: member.userName,
            avatar: member.profileImage,
            memberType: member.memberType,
          })
        );
        setTasksData((prevState) => {
          const updatedData = [...prevState];
          const columnIndex = updatedData.findIndex(
            (column) => column.type_id === typeId
          );
          if (columnIndex !== -1) {
            updatedData[columnIndex].tasks[taskIndex].task_description =
              updatedDescription;
            updatedData[columnIndex].tasks[taskIndex].task_id =
              response?.data?.data?.id;
            updatedData[columnIndex].tasks[taskIndex].users = updatedUsers;
          }
          updatedData[[columnIndex]]?.tasks.map(async (task, index) => {
            await updateProject({
              taskId: task.task_id,
              fieldName: "taskDisplayOrder",
              fieldValues: index,
            });
          });
          return updatedData;
        });
      }
    } catch (error) {
    } finally {
      setInputValue("");
      setAddLoad(false);
      await updateKanbanAllTaskListings();
    }
  };

  const removeEmptyTask = (typeId, taskIndex) => {
    setTasksData((prevState) => {
      const updatedData = [...prevState];
      const columnIndex = updatedData.findIndex(
        (column) => column.type_id === typeId
      );
      if (columnIndex !== -1) {
        updatedData[columnIndex].tasks.splice(taskIndex, 1);
      }
      return updatedData;
    });
    setNewTaskInput(null);
    setInputValue("");
  };

  // // Update canvas
  const updateTask = async (response) => {
    const statusId = response?.data?.taskStatus?.id;
    const updatedDescription = response?.data?.taskName;
    const updatedUsers = response?.data?.taskMembers?.map((member) => ({
      user_id: member.userId,
      user_name: member.userName,
      avatar: member.profileImage,
      memberType: member.memberType,
    }));
    setTasksData((prevState) => {
      const updatedData = [...prevState];
      const columnIndex = updatedData.findIndex(
        (column) => column.type_id === statusId
      );
      if (
        columnIndex !== -1 &&
        updatedData[columnIndex]?.tasks[typ.taskIndex]?.task_description
      ) {
        updatedData[columnIndex].tasks[typ.taskIndex].task_description =
          updatedDescription;
        // updatedData[columnIndex].tasks[typ.taskIndex].task_id =
        //   response?.data?.task?.task?.id;
        updatedData[columnIndex].tasks[typ.taskIndex].users = updatedUsers;
      }
      return updatedData;
    });
  };

  const getTaskDataAfterUpdate = async () => {
    try {
      const response = await ProjectAPI.post("task/kb-listing", {
        projectId: projectId,
      });
      const originalData = response?.data?.data;

      let map = createStatusIdMap(originalData);
      map = replaceObjectKeys(map);
      setStatusIdMap(map);

      const transformedData = Object.keys(originalData).map((statusName) => {
        const statusData = originalData[statusName];
        const isCompleted = statusName === "Completed"; // Check if the status name is "Completed"
        return {
          type_id: statusData.StatusId,
          type: statusData.StatusName,
          type_count: statusData.Tasks.length,
          is_completed: isCompleted, // Set is_completed based on the status name
          tasks: statusData.Tasks.map((task) => ({
            task_id: task.id,
            task_description: task.taskName,
            users: task.taskMembers.map((member) => ({
              user_id: member.userId,
              user_name: member.userName,
              avatar: member.profileImage,
              memberType: member.memberType,
            })),
            order: task.taskDisplayOrder,
            approval: task.hasApproval,
          })).sort((a, b) => a.order - b.order),
        };
      });
      setTasksData(transformedData);
    } catch (error) {
    } finally {
    }
  };

  const boardRef = useRef(null); // Ref for the drag-and-drop library

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = boardRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tasksData]);

  useEffect(() => {
    const handleScroll = () => {
      const board = boardRef?.current;
      setIsLeftScrollable(board?.scrollLeft > 0);
      setIsRightScrollable(
        board?.scrollLeft + board?.clientWidth < board?.scrollWidth
      );
    };

    const board = boardRef?.current;
    board?.addEventListener("scroll", handleScroll);

    return () => {
      board?.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollLeft = () => {
    const board = boardRef?.current;
    scrollInterval.current = setInterval(() => {
      board.scrollLeft -= 5;
      if (board?.scrollLeft <= 0) {
        clearInterval(scrollInterval?.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const board = boardRef?.current;
    scrollInterval.current = setInterval(() => {
      board.scrollLeft += 5;
      if (board?.scrollLeft + board?.clientWidth >= board?.scrollWidth) {
        clearInterval(scrollInterval?.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval?.current);
  };

  return (
    <>
      <div className="w-100 ms-4 d-flex align-items-center justify-content-between mb-3">
        <a
          onClick={() => {
            setAllLoad(true);
            getAllTasks();
            setKanban(false);
          }}
          className="d-flex align-items-center anchor-list-view"
        >
          <Icon icon="gg:list" />
          <p className="ms-2 mb-0">List View</p>
        </a>{" "}
        {isTableScrollable && (
          <div className="d-flex align-items-center gap-2 board-scroll-btn">
            {/* Left scroll button */}
            <div
              className=""
              onMouseEnter={() => {
                if (isTableScrollable && isLeftScrollable) {
                  handleScrollLeft();
                }
              }}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>

            {/* Right scroll button */}
            <div
              className=""
              onMouseEnter={() => {
                if (isTableScrollable && isRightScrollable) {
                  handleScrollRight();
                }
              }}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        )}
      </div>
      {load ? (
        <div className="ms-4 w-100 d-flex align-items-center gap-4 shimmer-card-kanban-main">
          <div className="shimmer-card-kanban">
            <ShimmerBadge width={200} />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
          </div>
          <div className="shimmer-card-kanban">
            <ShimmerBadge width={200} />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
          </div>
          <div className="shimmer-card-kanban">
            <ShimmerBadge width={200} />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
          </div>
          <div className="shimmer-card-kanban">
            <ShimmerBadge width={200} />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
            <ShimmerThumbnail height={150} className="m-0" rounded />
          </div>
        </div>
      ) : (
        <div
          className="ms-4 board-container-main scroll-smooth custom-horizental-scrollbar"
          ref={boardRef}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board" direction="horizontal" type="COLUMN">
              {(provided) => (
                <div
                  className="board-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tasksData.map((type, index) => (
                    <Draggable
                      key={type.type_id}
                      draggableId={type.type_id.toString()}
                      index={index}
                      isDragDisabled
                      type="COLUMN"
                    >
                      {(provided) => (
                        <div
                          className="type-container"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div
                            className="w-100 type-container-header "
                            {...provided.dragHandleProps}
                            onMouseEnter={() => {
                              setHoverGroup(type.type_id);
                            }}
                            onMouseLeave={() => setHoverGroup(null)}
                          >
                            <h3 className="d-flex">
                              {type.type}{" "}
                              <span className="ms-2">
                                ({type.tasks.length})
                              </span>
                            </h3>
                            {isPermitted("task/create-new") && (
                              <Icon
                                onClick={() => {
                                  if (!addLoad) {
                                    addNewTask(type.type_id);
                                  }
                                }}
                                className="icon-head-dnd"
                                icon="carbon:add-filled"
                              />
                            )}
                          </div>
                          <Droppable droppableId={index.toString()} type="TASK">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="task-item-container"
                              >
                                {type.tasks.map((task, taskIndex) => (
                                  <Draggable
                                    key={task?.task_id}
                                    draggableId={task?.task_id?.toString()}
                                    index={taskIndex}
                                    isDragDisabled={
                                      !(
                                        isMemberOfTask(
                                          task,
                                          userProfile?.userId
                                        ) && isPermitted("task/update")
                                      ) && !isAdmin(userProfile)
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        className="task-item"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        onMouseEnter={() => {
                                          setHoveredTask(task?.task_id);
                                        }}
                                        onMouseLeave={() =>
                                          setHoveredTask(null)
                                        }
                                        onClick={() => {
                                          if (task.task_description !== "") {
                                            setId(task);
                                            setShow(true);
                                          }
                                        }}
                                      >
                                        {type?.is_completed &&
                                          task.task_description !== "" && (
                                            <div className="w-100 task-item-head">
                                              <p>
                                                <span>
                                                  <Icon icon="mdi:tick-circle-outline" />
                                                </span>
                                                Completed
                                              </p>
                                            </div>
                                          )}
                                        <div
                                          className={`w-100 position-relative task-item-iternal ${
                                            type?.is_completed
                                              ? "task-item-iternal-completed"
                                              : ""
                                          }`}
                                        >
                                          {task.task_description === "" ? (
                                            <>
                                              <input
                                                className="form-control w-100"
                                                autoFocus
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "300",
                                                }}
                                                disabled={addLoad}
                                                placeholder="Enter task name"
                                                value={inputValue}
                                                onChange={(e) =>
                                                  setInputValue(e.target.value)
                                                }
                                                autoComplete="off"
                                                onBlur={(e) => {
                                                  if (e.target.value === "") {
                                                    removeEmptyTask(
                                                      type.type_id,
                                                      taskIndex
                                                    );
                                                  } else {
                                                    handleDescriptionChange(
                                                      e,
                                                      type.type_id,
                                                      taskIndex
                                                    );
                                                  }
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    if (e.target.value === "") {
                                                      removeEmptyTask(
                                                        type.type_id,
                                                        taskIndex
                                                      );
                                                    } else {
                                                      handleDescriptionChange(
                                                        e,
                                                        type.type_id,
                                                        taskIndex
                                                      );
                                                    }
                                                  }
                                                }}
                                              />
                                              {addLoad && (
                                                <span className="mt-2 w-100 d-flex justify-content-center">
                                                  <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    className="global-spinner"
                                                  />
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <p>{task.task_description}</p>
                                              {hoveredTask === task.task_id && (
                                                <Dropdown
                                                  className="roles-drop-down timeline-dropdown dnd-item-dropdown"
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <div className="menu-btn">
                                                      <Icon icon="mi:options-horizontal" />
                                                    </div>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                      <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setTyp({
                                                            ...typ,
                                                            typeId:
                                                              type.type_id,
                                                            taskIndex:
                                                              taskIndex,
                                                          });
                                                          setId(task);
                                                          setShow(true);
                                                        }}
                                                      >
                                                        <img
                                                          src={Eyes}
                                                          style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                          alt="eye-icon"
                                                        />
                                                        View task
                                                      </div>
                                                    </Dropdown.Item>
                                                    {((isOwnerInTaskOne(
                                                      task,
                                                      userProfile?.userId
                                                    ) &&
                                                      isPermitted(
                                                        "task/update"
                                                      )) ||
                                                      isAdmin(userProfile)) && (
                                                      <Dropdown.Item>
                                                        <div
                                                          className="d-flex align-items-center"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setTyp({
                                                              ...typ,
                                                              typeId:
                                                                type.type_id,
                                                              taskIndex:
                                                                taskIndex,
                                                            });
                                                            setId(task);
                                                            setUpdateTaskCanvas(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={EditIcon}
                                                            style={{
                                                              width: "15px",
                                                              height: "15px",
                                                              marginRight:
                                                                "5px",
                                                            }}
                                                            alt="edit-icon"
                                                          />
                                                          Edit task
                                                        </div>
                                                      </Dropdown.Item>
                                                    )}
                                                    {((isOwnerInTaskOne(
                                                      task,
                                                      userProfile?.userId
                                                    ) &&
                                                      isPermitted(
                                                        "task/delete"
                                                      )) ||
                                                      isAdmin(userProfile)) && (
                                                      <Dropdown.Item>
                                                        <div
                                                          className="d-flex align-items-center"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setId(task);
                                                            setShowDeleteModal(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={Delete}
                                                            style={{
                                                              width: "15px",
                                                              height: "15px",
                                                              marginRight:
                                                                "5px",
                                                            }}
                                                            alt="delete-icon"
                                                          />
                                                          Delete task
                                                        </div>
                                                      </Dropdown.Item>
                                                    )}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              )}
                                              {ListofTaskMemberCellRenderer(
                                                task?.users
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      {show && (
        <TaskDetailOffCanvas
          show={show}
          handleCloseModal={handleCloseModal}
          isDark={isDark}
          selectedTask={id?.task_id}
          getTaskDataAfterUpdate={getTaskDataAfterUpdate}
          kanban={true}
          rowData={[]}
          setRowData={() => {}}
          taskList={[]}
          setTaskList={() => {}}
          getStatusCounts={() => {}}
          listingType={"PHASE_TASKS"}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteTaskModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDark={isDark}
          getTaskName={() => {
            return id?.task_description;
          }}
          taskId={id?.task_id}
          setListToFilter={() => {}}
          remove={true}
          removeTask={() => removeTaskById(id?.task_id)}
          setTaskList={[]}
          type="task"
          // updateCountFilters={updateCountFilters}
        />
      )}
      {updateTaskCanvas && (
        <UpdateTaskOffcanvas
          show={updateTaskCanvas}
          handleCloseModal={() => setUpdateTaskCanvas(false)}
          isDark={isDark}
          getBoardData={() => {}}
          taskId={id?.task_id}
          kanban={true}
          setTasksData={updateTask}
          getTaskDataAfterUpdate={getTaskDataAfterUpdate}
          taskList={[]}
          setTaskList={() => {}}
          getStatusCounts={() => {}}
          listingType={"PHASE_TASKS"}
        />
      )}
      {showStatusModal && (
        <NoStatusAllowedModal
          show={showStatusModal}
          handleClose={handleClose}
          isDark={isDark}
        />
      )}
    </>
  );
};

export default Board;

const ListofTaskMemberCellRenderer = (users) => {
  const uniqueUsers = (users) => {
    // Create a Set to store unique user_ids
    const uniqueIds = new Set();
    // Filter out duplicates by comparing user_ids
    const uniqueUsers = users.filter((user) => {
      if (!uniqueIds.has(user.user_id)) {
        uniqueIds.add(user.user_id);
        return true;
      }
      return false;
    });
    return uniqueUsers;
  };

  const listofTeamMember = !users
    ? ["John Doe", "Jane Smith", "Alice Johnson", "Adam Lee", "Sarah Brown"]
    : uniqueUsers(users);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    return moduleCategory?.avatar ? (
      <li
        key={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        <img
          className="rounded-circle w-100 h-100"
          src={moduleCategory?.avatar}
          alt="profile-img"
        />
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </li>
    ) : (
      <li
        key={id}
        style={{
          border: "1px solid " + colors[id % colors.length],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        {moduleCategory?.user_name?.charAt(0).toUpperCase() +
          moduleCategory?.user_name?.charAt(1).toUpperCase()}
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </li>
    );
  };

  return (
    <div className={`teams-member-listing-wap`}>
      <ul>
        {listofTeamMember?.map((moduleCategory, id) => {
          return (
            <UserImageCircle
              colors={colors}
              moduleCategory={moduleCategory}
              id={id}
            />
          );
        })}
        {/* {listofTeamMember?.length > 2 && (
          <li className="teams-member-listing-wap-more-btn">
            {`+${listofTeamMember?.length - 2}`}
          </li>
        )} */}
      </ul>
    </div>
  );
};

const ListOfTeamMemberTooltip = ({ memberName, position }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: position.y - 40,
        left: position.x - 50,
      }}
      className="custom-tooltip"
    >
      <p className="custom-tooltip-rolename">{memberName?.user_name}</p>
    </div>
  );
};
