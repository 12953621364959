import {
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  PAYMENT_HISTORY_FAIL,
  BILLING_FAIL,
  BILLING_REQUEST,
  BILLING_SUCCESS,
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAIL,
} from "../constants/paymentConstants";
import API from "../services/ClientApi";

export const paymentListing = () => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_HISTORY_REQUEST });
    const data = await API.get("company/transactions");
    if (data?.data?.status === "success") {
      dispatch({
        type: PAYMENT_HISTORY_SUCCESS,
        payload: data?.data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PAYMENT_HISTORY_FAIL,
      payload: error,
      meta: {
        retryAction: paymentListing(), // Retry action
      },
    });
  }
};

export const billingData = () => async (dispatch) => {
  try {
    dispatch({ type: BILLING_REQUEST });
    const data = await API.get("company/billing-information");
    if (data?.data?.status === "success") {
      dispatch({
        type: BILLING_SUCCESS,
        payload: data?.data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_FAIL,
      payload: error,
      meta: {
        retryAction: billingData(), // Retry action
      },
    });
  }
};

export const subscriptionData = () => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_REQUEST });
    const data = await API.get("company/subscription");
    if (data?.data?.status === "success") {
      dispatch({
        type: SUBSCRIPTION_SUCCESS,
        payload: data?.data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_FAIL,
      payload: error,
      meta: {
        retryAction: subscriptionData(), // Retry action
      },
    });
  }
};

export const clearSubscriptionData = () => async (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_SUCCESS,
    payload: null,
  });
  dispatch({
    type: BILLING_SUCCESS,
    payload: null,
  });
  dispatch({
    type: PAYMENT_HISTORY_SUCCESS,
    payload: null,
  });
};
