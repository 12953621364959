/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import SearchNew from "../../../assets/search-new-icon.svg";
import CustomPagination from "../../../components/CustomPagination";
import { useDispatch } from "react-redux";
import "../styles/leadsNew.css";
import API from "../../../services/ClientApi";
import Eyes from "../../../assets/eyes.svg";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import DeleteUserModal from "./DeleteUserModal";
import InviteModal from "../../../components/InviteModal/InviteModal";
import ReinviteModal from "./ReinviteModal";
import { userListingPage } from "../../../actions/companyOverviewActions";
import TeamInvitations, { UserTooltip } from "./TeamInvitations";
import { Helmet } from "react-helmet";
import Button from "../../../components/Button";
import ExcelJS from "exceljs";
import AddOnModal from "../../SubscriptionsUpdated/components/AddOnModal";
import ConfirmAddonModal from "../../../components/ConfirmAddonModal";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import ChangeUserRole from "./ChangeUserRole";
import AdvanceSearchModalMain from "./AdvanceSearchModal/AdvanceSearchModalMain";
import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { dateFormatter } from "../../../helper/dateFormatter";
import DropdownRoles from "./DropdownRoles";
import DropButton from "../../Team/components/DropButton";
import { createPortal } from "react-dom";
import { Spinner } from "react-bootstrap";
import { ShimmerTable } from "react-shimmer-effects";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const LeadsNewMain = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const optionsDropdownRef = useRef(null);
  const { billingDetail } = useSelector((state) => state.billing);

  const { userList, loading: userListingLoading } = useSelector(
    (state) => state.userListing
  );
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [users, setUsers] = useState(userList);
  const [user, setUser] = useState(null);
  const [hoveredUser, setHoveredUser] = useState(null);
  const [load, setLoad] = useState(true);

  // State to track checked IDs
  const [roles, setRoles] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [, setSelectedRole] = useState(roles[0]?.roleId);
  const [, setSelectedModule] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [email] = useState("");
  const [search, setSearch] = useState("");
  const [id, setId] = useState(0);
  const [addonModal, setAddonModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [planData, setPlanData] = useState(null);
  const { applicationName } = useSelector((state) => state.text);
  const [showAdvanceFilterSearchModal, setShowAdvanceFilterModal] =
    useState(false);
  const [filteredData, setFiltersData] = useState(null);
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem("userManagementFilters"))
      ? JSON.parse(localStorage.getItem("userManagementFilters"))
      : null
  );
  const [seeMore, setSeeMore] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });
  const [gridApi, setGridApi] = useState(null);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);

  const gridReady = (params) => {
    setGridApi(params.api);
  };

  const userListingModalContent = findObjectByContentTitle(
    "User Management Listing",
    modalContents?.data
  );

  useEffect(() => {
    localStorage.setItem("userManagementFilters", JSON.stringify({}));
  }, []);

  useEffect(() => {
    handleFilterUpdated();
    localStorage.setItem("userManagementFilters", JSON.stringify(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    handleFilterUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userManagementFilters")]);

  useEffect(() => {
    users && setFiltersData(users);
    handleFilterUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleFilterUpdated = () => {
    if (
      filters &&
      users &&
      users.length > 0 &&
      !Object.values(filters).every(
        (value) => value === "" || (Array.isArray(value) && value.length === 0)
      )
    ) {
      const filtered = users.filter((item) => {
        // Apply filter conditions based on the filters object
        if (filters.userName && filters.userName.length > 0) {
          const matchingUserNames = filters.userName.some((filter) => {
            return new RegExp(filter.label, "i").test(item.userName);
          });
          if (!matchingUserNames) {
            return false;
          }
        }

        if (filters.userEmail && filters.userEmail.length > 0) {
          const matchingEmails = filters.userEmail.some((filter) => {
            return new RegExp(filter.label, "i").test(item.userEmail);
          });
          if (!matchingEmails) {
            return false;
          }
        }

        if (
          filters.userStatus &&
          item.userStatus?.toLowerCase() !== filters.userStatus?.toLowerCase()
        ) {
          return false;
        }

        if (
          filters.joinedDate &&
          !item.joinedDate.includes(filters.joinedDate)
        ) {
          return false;
        }

        if (filters.roles && filters.roles.length > 0) {
          const matchingRoles = filters.roles.some((role) => {
            return item.roles.some(
              (itemRole) =>
                itemRole?.toLowerCase() === role.label?.toLowerCase()
            );
          });

          if (!matchingRoles) {
            return false;
          }
        }

        if (filters.departments && filters.departments.length > 0) {
          const matchingDepartments = filters.departments.some((department) => {
            return item.departments.some((dep) =>
              new RegExp(department.label, "i").test(dep.moduleName)
            );
          });

          if (!matchingDepartments) {
            return false;
          }
        }

        return true; // Include item if it passed all filters
      });
      setFiltersData(filtered);
    } else {
      // If no filters are set, reset filteredData to original data
      setFiltersData(users);
    }
  };

  const handleRemoveFilter = (keyToRemove, itemToRemove) => {
    // Create a new object excluding the key or item to remove
    const updatedFilters = { ...filters };
    if (Array.isArray(updatedFilters[keyToRemove])) {
      updatedFilters[keyToRemove] = updatedFilters[keyToRemove].filter(
        (item) => item !== itemToRemove
      );
    } else {
      delete updatedFilters[keyToRemove];
    }
    setFilters(updatedFilters);
  };
  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleAddonShow = () => {
    setAddonModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setModal(false);
  };

  // Departments renderer
  const UserListingDepartmentList = ({ departments, colors }) => {
    const [showMore, setShowMore] = useState(false);
    const [data, setData] = useState([]);

    const toggleShowAll = () => {
      setShowMore(!showMore);
    };

    useMemo(() => {
      if (departments) {
        setData(departments);
      }
    }, [departments]);

    const firstFourItems = data.length > 4 ? data.slice(0, 4) : data;

    const expandCollapseButton = (
      <button
        onClick={toggleShowAll}
        className="border-0 rounded-circle p-0 m-0 user-profile-modules-categories-expand"
      >
        {showMore ? `-${data.length - 4}` : `+${data.length - 4}`}
      </button>
    );

    const UserImageCircle = ({ colors, item, id }) => {
      const [showTooltip, setShowTooltip] = useState(false);
      const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
      const uniqueId = uuidv4();
      return (
        <React.Fragment key={id}>
          <li
            onMouseEnter={(event) => {
              setShowTooltip(true);
              const rect = event.target.getBoundingClientRect();
              setTooltipPosition({ x: rect.x, y: rect.y });
            }}
            onMouseLeave={() => setShowTooltip(false)}
            style={{
              backgroundColor: colors[id % colors.length],
            }}
            className="position-relative"
            data-tooltip-id={`my-tooltip-${uniqueId}`}
          >
            {item.abbreviatedName}
            {showTooltip && (
              <UserTooltip
                userName={item.abbreviatedName}
                roleName={
                  item.roleName.charAt(0).toUpperCase() + item.roleName.slice(1)
                }
                position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
              />
            )}
          </li>
        </React.Fragment>
      );
    };

    return (
      <ul>
        {showMore
          ? data.map((item, id) => (
              <UserImageCircle item={item} id={id} colors={colors} />
            ))
          : firstFourItems.map((item, id) => (
              <UserImageCircle item={item} id={id} colors={colors} />
            ))}
        {data.length > 4 && (
          <li style={{ zIndex: 10 }}>{expandCollapseButton}</li>
        )}
      </ul>
    );
  };

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Add your data to the worksheet
    worksheet.addRow([
      "User Name",
      "User Email",
      "Roles",
      "Departments",
      "User Status",
      "Joined Date",
      "Invited By",
    ]);
    worksheet.columns.forEach((column, i) => {
      const maxLength = Math.max(
        ...users.map((user) =>
          i === 3 // Check if it's the 'Departments' column
            ? (
                user.departments
                  .map(
                    (department) =>
                      department.roleName + "-" + department.moduleName
                  )
                  .join(", ") ?? ""
              ).length
            : (user[worksheet.getColumn(i + 1).key] ?? "").toString().length
        )
      );
      column.width = maxLength < 20 ? 20 : maxLength; // Set a minimum width
    });
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE9EEFF" }, // Set fgColor instead of bgColor
    };
    users.forEach((user) => {
      // Convert the 'departments' array to a comma-separated string
      const departmentsString = user.departments
        .map((department, index) => {
          if (index === 0) {
            return `\n${department.roleName}: ` + department.moduleName;
          } else return department.moduleName;
        })
        .join(", ");

      const roleString = user.roles.map((role) => role).join(", ");

      worksheet.addRow([
        user.userName,
        user.userEmail,
        roleString,
        departmentsString, // Add the string representation of departments
        user.userStatus,
        newFormattedDate(user.joinedDate),
        user.invitedBy,
      ]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "user_manangement_listing.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  const validateInput = (event) => {
    const value = parseInt(event.target.value);

    // Check if the value is not a number or is less than or equal to 0
    if (isNaN(value) || value <= 0) {
      event.preventDefault(); // Prevent invalid input from being set
      return;
    }
  };

  const formattedDate = (originalDate) => {
    // Convert the string to a Date object
    const date = new Date(originalDate);

    // Check if the date is valid before formatting
    if (isNaN(date)) {
      return "Invalid Date";
    }

    // Format the date to "dd MMM, yyyy"
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return formattedDate;
  };
  const newFormattedDate = (originalDate) => {
    // Convert the string to a Date object
    const date = new Date(originalDate);

    // Check if the date is valid before formatting
    if (isNaN(date)) {
      return "Invalid Date";
    }

    // Format the date to "dd mmm yyyy"
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;

    return formattedDate;
  };

  const handleAddRole = (user) => {
    setSelectedUser(user);
    handleSelectModule(user?.departments[0]?.moduleId);
    handleSelectRole(
      roles?.find((role) => role.roleName === user?.departments[0]?.roleName)
        ?.roleId
    );
    // Logic to handle adding a role
    setShowAddRoleModal(true);
  };

  const handleCloseAddRoleModal = () => {
    setShowAddRoleModal(false);
  };

  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };
  const handleSelectModule = (module) => {
    setSelectedModule(module);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleOpenDeleteModal = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const getAllUsersAfterUpdate = async () => {
    try {
      const user = await API.get("users/basic-info");
      if (user) {
        setUser(user?.data?.data);
        setUserId(user?.data?.data?.userId);
      }
      dispatch(userListingPage(search));
      const roleData = await API.get("company/roles");
      if (roleData?.data?.status === "success") {
        setRoles(roleData?.data?.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const getPlanData = async () => {
    const response = await API.get("company/subscription");
    if (response?.data?.status === "success") {
      setPlanData(response?.data?.data?.packageDetails);
    }
  };

  useEffect(() => {
    getPlanData();
  }, [billingDetail]);

  useEffect(() => {
    const getAllUsers = async () => {
      setLoad(true);
      try {
        const user = await API.get("users/basic-info");
        if (user) {
          setUser(user?.data?.data);
          setUserId(user?.data?.data?.userId);
        }
        dispatch(userListingPage(search));
        const roleData = await API.get("company/roles");
        if (roleData?.data?.status === "success") {
          setRoles(roleData?.data?.data);
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  function isAdminRoleMoreThanTwo(users) {
    // Flatten the roles array from all users
    const allRoles = users.reduce((roles, user) => {
      return roles.concat(user.roles);
    }, []);

    // Count the occurrences of "admin" role
    const adminRoleCount = allRoles.filter((role) => role === "admin").length;

    // Check if the count is greater than two
    return adminRoleCount >= 2;
  }

  // AGGird definitions
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const columns = [
    {
      field: "userName",
      headerName: "Name",
      width: 372,
      minWidth: 298,
      editable: false,
      cellRenderer: (params) => {
        return (
          <div
            className="nama-table-wrap d-flex align-items-center"
            onClick={() => {
              if (
                params?.data?.userName !== "" &&
                params?.data?.userName !== null
              ) {
                // navigate(
                //   `/${localStorage.getItem("company")}/user-profile?id=${
                //     params?.data?.userId
                //   }`
                // );
                saveLocalStorageToCookies(
                  `/user-profile?id=${params?.data?.userId}`
                );
              }
            }}
          >
            {" "}
            {!params?.data?.profileImage ? (
              <span>
                {params?.data.userName.length >= 2
                  ? params?.data.userName.substring(0, 2).toUpperCase()
                  : params?.data.userName.toUpperCase()}
              </span>
            ) : (
              <img src={params?.data?.profileImage} alt="user-profile" />
            )}
            {params?.data?.userName ? params?.data?.userName : "....."}
          </div>
        );
      },
    },
    {
      field: "userEmail",
      headerName: "Email",
      editable: false,
      cellRenderer: (params) => {
        const email = params?.data?.userEmail || ".....";
        const displayEmail =
          email.length > 22 ? email.substring(0, 22) + "..." : email;

        return <div>{displayEmail}</div>;
      },
    },
    {
      field: "roles",
      headerName: "User Role",
      editable: false,
      cellRenderer: (params) => {
        return params?.data?.roles?.length < 2 ? (
          <>{capitalizeFirstLetter(params?.data?.roles[0])}</>
        ) : (
          <DropdownRoles roles={params.data.roles} />
        );
      },
    },
    {
      field: "departments",
      headerName: "Department",
      editable: false,
      cellRenderer: (params) => {
        return (
          <div className="teams-listing-wap">
            <UserListingDepartmentList
              departments={params.data?.departments}
              colors={colors}
            />
          </div>
        );
      },
    },
    {
      field: "userStatus",
      headerName: "Status",
      cellEditor: "agSelectCellEditor",
      maxWidth: 170,
      editable: (params) => !params.data.roles.includes("admin"),
      cellEditorParams: {
        values: ["Active", "InActive"],
      },
      cellStyle: (params) => ({
        backgroundColor:
          params?.data?.userStatus === "Active"
            ? "#74cc5233"
            : params?.data?.userStatus === "InActive"
            ? "#fcebeb"
            : "",
        textAlign: "center", // Center align cell text
      }),
      onCellValueChanged: async (params) => {
        try {
          await API.post("users/status-update", {
            userId: params?.data?.userId,
          });
          dispatch(userListingPage());
        } catch (error) {
        } finally {
        }
      },
    },
    {
      field: "joinedDate",
      headerName: "Joined",
      editable: false,
      cellRenderer: (params) => {
        return formattedDate(params?.data?.joinedDate);
      },
    },
    {
      field: "invitedBy",
      headerName: "Invited By",
      editable: false,
    },
  ];

  // const popupParent = useMemo(() => {
  //   return document.querySelector(".ag-center-cols-container");
  // }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Get the bounding box of the grid and options dropdown
      const gridRect = gridRef?.current?.getBoundingClientRect();
      const optionsDropdownRect =
        optionsDropdownRef?.current?.getBoundingClientRect();

      // Check if the mouse has left the grid area
      if (
        event.clientX > gridRect?.right ||
        event.clientY < gridRect?.top ||
        event.clientY > gridRect?.bottom
      ) {
        // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

        if (
          showMenu === false ||
          (optionsDropdownRect &&
            (event.clientX < optionsDropdownRect?.left ||
              event.clientX > optionsDropdownRect?.right ||
              event.clientY < optionsDropdownRect?.top ||
              event.clientY > optionsDropdownRect?.bottom))
        ) {
          setHoveredMenu(null);
          setShowMenu(false);
        }
      }
    };

    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null) {
      const dropdownHeight = 97; // Adjust this value based on your dropdown height
      const availableSpaceBelow =
        window.innerHeight - (yValue + dropdownHeight);

      const dropdownPosition =
        availableSpaceBelow < 0
          ? {
              top: `${yValue - dropdownHeight - 26}px`,
              left: `${xValue - 20}px`,
            }
          : { top: `${yValue}px`, left: `${xValue - 20}px` };

      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top,
            left: optionsColumnPosition.left,
            // zIndex: 9999,
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}
          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div className="position-relative">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
            {showMenu && (
              <Portal>
                <div
                  ref={optionsDropdownRef}
                  data-theme={props.isDark}
                  style={{
                    position: "absolute",
                    left: dropdownPosition.left,
                    top: dropdownPosition.top,
                    listStyle: "none",
                    background: "#fff", // Set background color
                    // border: "1px solid #ccc",
                    fontSize: "14px",
                    fontWeight: 300,
                    minWidth: "150px",
                  }}
                  onMouseOver={(e) => setShowMenu(true)}
                  // onMouseLeave={() => setShowMore(false)}
                  className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1"
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      // navigate(
                      //   `/${localStorage.getItem("company")}/user-profile?id=${
                      //     hoveredUser?.userId
                      //   }`
                      // )
                      saveLocalStorageToCookies(
                        `/user-profile?id=${hoveredUser?.userId}`
                      )
                    }
                  >
                    <img
                      src={Eyes}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      alt="edit-task-icon"
                    />
                    View
                  </div>
                  {userId !== hoveredUser?.userId &&
                  hoveredUser?.roleName !== "Admin" ? (
                    <>
                      <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleAddRole(hoveredUser);
                        }}
                      >
                        <img
                          src={Edit}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "5px",
                          }}
                          alt="share-task-icon"
                        />
                        Edit role
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setId(hoveredUser?.userId);
                          handleOpenDeleteModal(hoveredUser);
                        }}
                      >
                        <img
                          src={Delete}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "5px",
                          }}
                          alt="share-task-icon"
                        />
                        Delete
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      {isAdminRoleMoreThanTwo(users) && (
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleAddRole(hoveredUser);
                          }}
                        >
                          <img
                            src={Edit}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "5px",
                            }}
                            alt="share-task-icon"
                          />
                          Edit role
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Portal>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const changeTheOptionPosition = async (r) => {
    const hoveredRowElement = document?.querySelector(`.ag-row[row-id="${r}"]`);
    if (hoveredRowElement) {
      setOptionsColumnPosition({
        top: 71 + r * 55, // Adjust top dynamically,
        left: -20, // Adjust the left position as needed
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Management-{applicationName}</title>
      </Helmet>
      <div className="user-dashboard-main-wrap user-management-main-page-height">
        <div className="heading-user-mange-wrap">
          <div className="row">
            <div className="col-md-8 leads-new-header-cols">
              <div className="heading-search-wraper">
                <ul>
                  <li className="ms-4 d-flex user-listing-search-section-li">
                    <h3>
                      {" "}
                      User <span>Management</span>{" "}
                    </h3>
                    <div className="d-flex flex-column gap-2 justify-content-start user-listing-search-section">
                      <div className="col-md-12 d-flex flex-md-row gap-2 justify-content-center user-listing-search-section-wrap">
                        <div className="search-group-wraper">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            autoComplete="off"
                          />
                          <button>
                            {" "}
                            <img src={SearchNew} alt="search-icon" />{" "}
                          </button>
                        </div>
                        <Button
                          icon="fa-solid fa-filter"
                          text="Advance Search"
                          handleClick={() => {
                            setShowAdvanceFilterModal(true);
                          }}
                          margin="0px 5px 0px 0px"
                        />
                      </div>
                      <div className="col-12 user-listing-quick-search-list-wrap">
                        <ul className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                          {filters &&
                            Object.entries(filters).map(([key, value]) => {
                              if (
                                value !== "" &&
                                !(Array.isArray(value) && value.length === 0)
                              ) {
                                return (
                                  <div
                                    className="d-flex align-items-center flex-wrap gap-2"
                                    key={key}
                                  >
                                    {Array.isArray(value) ? (
                                      value.map((item, index) => (
                                        <a
                                          key={index}
                                          className="position-relative"
                                        >
                                          {item.label || item}
                                          <IoMdClose
                                            onClick={() =>
                                              handleRemoveFilter(key, item)
                                            }
                                            className="advance-search-tag-close-icon"
                                          />
                                        </a>
                                      ))
                                    ) : (
                                      <a className="position-relative">
                                        {key === "joinedDate"
                                          ? dateFormatter(value.label || value)
                                          : value.label || value}

                                        <IoMdClose
                                          onClick={() =>
                                            handleRemoveFilter(key)
                                          }
                                          className="advance-search-tag-close-icon"
                                        />
                                      </a>
                                    )}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          {Object.values(filters || {}).some(
                            (value) =>
                              value !== "" &&
                              !(Array.isArray(value) && value.length === 0)
                          ) && (
                            <div style={{ width: "max-content !important" }}>
                              {" "}
                              <a
                                style={{
                                  width: "max-content !important",
                                  position: "relative",
                                }}
                                onClick={() => setFilters(null)}
                              >
                                {" "}
                                <IoMdClose className="advance-search-tag-close-icon" />
                                Clear{" "}
                              </a>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 leads-new-header-cols cols-second-margin">
              <div className="button-top-right-wrap">
                <Button
                  icon="fa fa-user"
                  text="Invite Members"
                  handleClick={() => {
                    if (!isTrialActive(props.user)) {
                      props.setOpenSubscriptionsModal(true);
                    } else {
                      setShowModal(true);
                    }
                  }}
                  margin="0px 5px 0px 0px"
                  width="178px"
                />
                <Button
                  icon="fa fa-download"
                  text="Download Excel"
                  handleClick={downloadExcel}
                  width="178px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ms-4 col-md-12 mt-3">
          <p className="role-page-description">
            {userListingModalContent?.short_description}{" "}
            <span className="see-more-txt" onClick={() => setSeeMore(true)}>
              See More
            </span>
          </p>
        </div>
        {load ? (
          <div className="position-relative">
            <div className="ms-4">
              <ShimmerTable row={5} />
            </div>
          </div>
        ) : (
          <div className="project-timeline mt-3">
            <div className="position-relative">
              {gridApi && renderCustomOptionsColumn()}
            </div>
            <div className="table-responsive ms-4 scroll-smooth custom-horizental-scrollbar">
              <div
                className={`ag-theme-alpine leads-new-aggrid ${
                  !(!filteredData && (load || userListingLoading))
                    ? "show-table"
                    : ""
                }`}
                style={{
                  width: "100%",
                  minWidth:
                    !filteredData && (load || userListingLoading)
                      ? "100%"
                      : "1500px",
                  overflow:
                    !filteredData && (load || userListingLoading)
                      ? "hidden"
                      : "1500px",
                }}
                ref={gridRef}
                data-theme={props.isDark}
              >
                <AgGridReact
                  rowData={filteredData}
                  columnDefs={columns}
                  domLayout={"autoHeight"}
                  defaultColDef={defaultColDef}
                  rowHeight={55}
                  headerHeight={54}
                  singleClickEdit={true}
                  // popupParent={popupParent}
                  onGridReady={gridReady}
                  deltaRowDataMode={true}
                  rowDragManaged={false}
                  rowDragEntireRow={false}
                  animateRows={true}
                  suppressRowTransform={true}
                  suppressRowHoverHighlight={true}
                  stopEditingWhenCellsLoseFocus={true}
                  onCellMouseOver={(params) => {
                    if (params?.rowIndex !== hoveredMenu) {
                      setShowMenu(false);
                      setHoveredMenu(params?.rowIndex);
                      changeTheOptionPosition(params?.rowIndex);
                      setHoveredUser(params?.data);
                    }
                  }}
                  overlayLoadingTemplate={"<object></object>"}
                  overlayNoRowsTemplate={
                    "<span >This is a custom 'no rows' overlay</span>"
                  }
                />
                {!filteredData && (load || userListingLoading) && (
                  <div className="my-3 w-100 d-flex justify-content-center align-items-center global-spinner">
                    <Spinner animation="border" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!load && (
          <TeamInvitations
            props={props}
            isDark={props.isDark}
            filters={filters}
          />
        )}
        {/* Add Role Modal */}

        <ChangeUserRole
          isDark={props.isDark}
          showAddRoleModal={showAddRoleModal}
          handleCloseAddRoleModal={handleCloseAddRoleModal}
          roles={roles}
          user={selectedUser}
          getUser={getAllUsersAfterUpdate}
        />
        <DeleteUserModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          user={selectedUser}
          isDark={props.isDark}
          userId={id}
          getAllUsersAfterUpdate={getAllUsersAfterUpdate}
        />
        {showModal && (
          <InviteModal
            isDark={props?.isDark}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            addonModal={addonModal}
            handleAddonClose={handleAddonClose}
            handleAddonShow={handleAddonShow}
            setShowAddModal={setShowAddModal}
            isAdmin={
              user?.canInvite === 1 &&
              user?.roles?.some((role) => role.roleType === "admin")
                ? true
                : false
            }
          />
        )}
        <AddOnModal
          modal={addonModal}
          handleClose={handleAddonClose}
          isDark={props.isDark}
          planData={planData}
          getPlanData={() => {}}
        />
        <ConfirmAddonModal
          isDark={props.isDark}
          handleAddonShow={handleAddonShow}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          billingDetail={billingDetail}
          planData={planData}
        />
        <ReinviteModal
          modal={modal}
          handleClose={handleClose}
          isDark={props?.isDark}
          email={email}
          userId={id}
          getAllUsersAfterUpdate={getAllUsersAfterUpdate}
        />
      </div>

      <AdvanceSearchModalMain
        showModal={showAdvanceFilterSearchModal}
        setShowModal={setShowAdvanceFilterModal}
        isDark={props?.isDark}
        data={users}
        filters={filters}
        setFilters={setFilters}
      />
      <SeeMoreModal
        seeMore={seeMore}
        setSeeMore={setSeeMore}
        headTextOne="User"
        headTextTwo=" Management"
        isDark={props.isDark}
        modalContent={userListingModalContent}
      />
    </>
  );
};

export default LeadsNewMain;
