/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CloseBtn from "../../../assets/icon-close.svg";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
// import TextareaAutosize from "react-textarea-autosize";
import Select, { components } from "react-select";
import Delete from "../../../assets/delete-icon.png";
import deleteIcon from "../../../assets/circle-delete.png";
import { Modal, Spinner } from "react-bootstrap";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { Icon } from "@iconify/react";
import TaskDetailFilterSection from "./TaskDetailFilterSection";
import TaskDetailCommentSection from "./TaskDetailCommentSection";
import API, {
  ProjectAPI,
  ProjectFormDataAPI,
} from "../../../services/ClientApi";
import { FileIcon, defaultStyles } from "react-file-icon";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import UpdateTaskOffcanvas from "./UpdateTaskOffcanvas";
import { isPermitted } from "../../../helper/isPermitted";
import {
  isOwnerInTaskOne,
  isMemberOfTask,
  isObserver,
} from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import NoStatusAllowedModal from "../../../components/NoStatusAllowedModal/NoStatusAllowedModal";
import {
  ShimmerButton,
  ShimmerCategoryList,
  ShimmerThumbnail,
} from "react-shimmer-effects";

import LightboxMain from "../../../components/Lightbox/LightboxMain";
import { handleDownloadDocFiles } from "../../../helper/downloadFile";
import OwnerIcon from "../../../assets/owner.png";

// import tinymce from 'tinymce'; // Import tinymce
import {
  useClearAllTaskCacheMutation,
  useClearAllTasksCacheMutation,
  useDownloadZipAllFilesMutation,
  useUpdateTaskDataMutation,
  useUpdateTaskMemberDataMutation,
} from "../../../slices/tasks/tasksSlice";
import { useUpdateCompletedTaskDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import {
  useUpdatePhaseTaskDataMutation,
  useUpdatePhaseTaskMemberDataMutation,
} from "../../../slices/tasks/projectDetailSectionSlice";
import { Tooltip } from "react-tooltip";
import {
  getDefaultMarkAsById,
  getIdByDefaultMarkAs,
  getStatusNameById,
  truncateText,
} from "../../../helper/taskHelpers";
import getProjectIconById from "../../../helper/projectIconGenerator";
import TaskSelectedObserverSection from "./TaskSelectedObserverSection";
import { dateFormatter } from "../../../helper/dateFormatter";
import { calculateTotalSize, validateFile } from "./CreateTaskModal";
import CustomToast from "../../../components/CustomToast/CustomToast";
import TimerIcon from "../../../assets/overdue-green.png";
import TimelyIcon from "../../../assets/timely-completed.png";
import AttachmentsModal from "./AttachmentsModal";
import ConfirmWithoutSaveCloseTaskModal from "./ConfirmWithoutSaveCloseTaskModal";
import ConfirmChangeCompletedTaskModal from "./ConfirmChangeCompletedTaskModal";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { userProfilePage } from "../../../actions/userActions";
import TaskSelectedOwnerSection from "./TaskSelectedOwnerSection";
import {
  projectFormListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import {
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";

import CalenderIcon from "../../../assets/td-calender-icon.svg";
import CheckIcon from "../../../assets/td-check-icon.svg";
import PhaseIcon from "../../../assets/td-phase-icon.svg";
import ProjectIcon from "../../../assets/td-project-icon.svg";
import RepeatIcon from "../../../assets/td-repeat-icon.svg";
import ObserverIcon from "../../../assets/td-observer-icon.svg";
import OwnerTdIcon from "../../../assets/td-owner-icon.svg";
import { useIsMobile } from "../../../helper/windowHelper";

function TaskDetailOffCanvas({
  show,
  handleCloseModal,
  isDark,
  isInModal,
  selectedTask,
  taskList,
  setTaskList,
  getStatusCounts,
  isMoreWidth = false,
  kanban,
  getTaskDataAfterUpdate,
  pageParams,
  listingType,
  getAllTasksByPhaseId = () => {},
  getTaskList = () => {},
  wasUnread,
  setWasUnread,
}) {
  const mobile = useIsMobile();
  const dispatch = useDispatch();
  const [updateTaskData, { isLoading: isUpdating }] =
    useUpdateTaskDataMutation();
  const [clearAllTasksCache] = useClearAllTasksCacheMutation();
  const [updateTaskMemberData, { isLoading: memberLoad }] =
    useUpdateTaskMemberDataMutation();
  const [updatePhaseTaskMemberData, { isLoading: phaseMemberLoad }] =
    useUpdatePhaseTaskMemberDataMutation();
  const [updateCompletedTaskData, { isLoading: completeLoading }] =
    useUpdateCompletedTaskDataMutation();
  const [updatePhaseTaskData, { isLoading: phaseLoading }] =
    useUpdatePhaseTaskDataMutation();
  const [downloadZipAllFiles, { isLoading: zipLoading }] =
    useDownloadZipAllFilesMutation();
  const [clearAllTaskCache] = useClearAllTaskCacheMutation();
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updateLoad, setUpdateLoad] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const [taskLoad, setTaskLoad] = useState(false);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [projectTypes, setProjectTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [types, setTypes] = useState([]);
  const [taskData, setTaskData] = useState(null);
  const [status, setStatus] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [addCheckListLoad, setAddCheckListLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [repeat, setRepeat] = useState(null);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState(null);
  const [initialDescription, setInitialDescription] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [prevName, setPrevName] = useState("");
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [bulkTodoOpen, setBulkTodoOpen] = useState(false);
  const [projectPhasesOptions, setProjectPhasesOptions] = useState({
    data: [],
    isLoading: false,
  });
  const [projectPhase, setProjectPhase] = useState(null);
  const [selected, setSelected] = useState(null);
  const [todoCount, setTodoCount] = useState({ total: 0, completed: 0 });
  const [originalHeight, setOriginalHeight] = useState(0);
  const [approval, setApproval] = useState(false);
  const [
    showCanvasCloseConfirmationModal,
    setShowCanvasCloseConfirmationModal,
  ] = useState(false);
  // all apis listing data
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  // eslint-disable-next-line no-unused-vars
  const [visibleFilesCount, setVisibleFilesCount] = useState(9); // Show max 9 initially
  const [isCompletedTaskChanged, setIsCompletedTaskChanged] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [journeyLoad, setJourneyLoad] = useState(false);
  // Tour steps
  const steps = [
    {
      element: ".task-detail-status-journey",
      intro: "Start working on your task by changing the status.",
      position: "left",
    },
    {
      element: ".selected-item-date-detail",
      intro: "Set task due date.",
      position: "bottom",
    },
    {
      element: ".selected-item-date-detail-no-edit",
      intro: "Set task due date.",
      position: "bottom",
    },
    {
      element: ".task-observer-row",
      intro: "Add members involved in this task.",
      position: "right",
    },
    {
      element: ".task-review-row",
      intro: "Set a check to review first before completion.",
      position: "right",
    },
    {
      element: ".todo-listn-wrap",
      intro: "Create sub tasks or todo items.",
      position: "bottom",
    },
    {
      element: ".task-detail-export-task-list",
      intro: "Add sub task list in one go - copy/paste.",
      position: "bottom",
    },
    {
      element: ".comment-field-text input",
      intro: "Add comments to this task for members.",
      position: "top",
    },
  ];

  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);

  // Task name update related functions

  const handleDoubleClick = () => {
    setEditMode(true);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // const taskNamePattern = /^[A-Za-z\s&0-9]+$/; // Allows letters, spaces, &, and numbers

    // Limit input to 255 characters
    if (value.length <= 255) {
      setTaskName(value);
    }
  };

  const handleBlurOrSubmit = () => {
    if (!taskName.trim()) {
      // If taskName is empty, revert to previous name
      setTaskName(prevName);
    } else if (taskName !== prevName) {
      const data = {
        taskId: taskData?.id,
        fieldName: "taskName",
        fieldValues: taskName,
      };
      updateTask(data);
      setPrevName(taskName);
    }
    setEditMode(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlurOrSubmit();
    }
  };
  //-----------------------------------

  const fileViewers = {
    image: (url) => url,
    video: (url) => url,
    pdf: (url) => url,
    doc: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    docx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xls: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xlsx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    ppt: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    pptx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    default: (url) => url, // Fallback for other file types
  };

  // Repeate options
  const repeatOptions = [
    {
      label: "No Repeat",
      value: "no-repeat",
    },
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
  ];

  // Boarding data
  const getBoardingData = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        if (!projectUserList) {
          dispatch(projectUserListingAction());
        }
        if (!taskPriorities) {
          dispatch(taskPrioritiesListAction());
        }
        if (!taskTypes) {
          dispatch(taskTypesListAction());
        }
        if (!projectTypeList) {
          dispatch(projectTypeListingAction());
        }
        if (!projectFormList) {
          dispatch(projectFormListingAction());
        }
        if (!projectStatusList) {
          dispatch(projectListingStatusAction());
        }
      } catch (error) {
      } finally {
        // setLoad(false);
      }
    }
  };

  useEffect(() => {
    getBoardingData();
  }, []);

  // Project and phase options
  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.projectName,
        }))
      );
    }
  };

  const getProjectPhasesAPI = async (projectId) => {
    try {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
      const response = await ProjectAPI.post("project/pm-project-phases", {
        projectId: projectId,
      });

      if (response.status === 201) {
        setProjectPhasesOptions({
          data: response?.data?.data?.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.phaseName,
          })),
          isLoading: false,
        });
      }
    } catch (error) {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
    }
  };

  const getFileViewerUrl = (fileType, fileUrl) => {
    return fileViewers[fileType]
      ? fileViewers[fileType](fileUrl)
      : fileViewers.default(fileUrl);
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    const fileType = getFileType(file?.attachmentName);
    const fileViewerUrl = getFileViewerUrl(fileType, file?.attachmentPath);

    // Open the file in a new tab
    window.open(fileViewerUrl, "_blank", "noopener,noreferrer");
  };

  const handleCloseFileViewer = () => {
    setShowFileViewer(false);
  };

  const handleClose = () => {
    setShowStatusModal(false);
  };

  const canEdit =
    (isPermitted("task/update") &&
      isOwnerInTaskOne(taskData, userProfile?.userId)) ||
    isAdmin(userProfile);

  const canEditObserver =
    (isPermitted("task/update") && isObserver(taskData, userProfile?.userId)) ||
    isAdmin(userProfile);

  const canEditChecklist =
    (isPermitted("task/update") &&
      (isMemberOfTask(taskData, userProfile?.userId) ||
        isObserver(taskData, userProfile?.userId))) ||
    isAdmin(userProfile);

  const canEdiStatus =
    (isPermitted("task/update") &&
      (isMemberOfTask(taskData, userProfile?.userId) ||
        isObserver(taskData, userProfile?.userId))) ||
    isAdmin(userProfile);

  const taskDeadline = taskData?.taskDeadline
    ? new Date(taskData?.taskDeadline)
    : ""; // Parse taskDeadline into a Date object
  const taskCompletedDate = taskData?.taskCompletedDate
    ? new Date(taskData?.taskCompletedDate)
    : new Date();

  const handleCloseFileModal = () => setShowModal(false);

  const handleThumbnailClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowModal(true);
  };
  const statusList = [
    "Not Started",
    "In Progress",
    "On Hold",
    "Submit For Review",
    "Completed",
    "Deleted",
  ];
  const [list, setList] = useState([]);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#e9eeff" : "inherit",
      color:
        state.data.value.toString().toLowerCase() === "critical"
          ? "#FB3F3F"
          : state.data.value.toString().toLowerCase() === "highest"
          ? "#56BAEC"
          : state.data.value.toString().toLowerCase() === "medium"
          ? "#394B84"
          : state.data.value.toString().toLowerCase() === "low"
          ? "#2C2D2F"
          : state.data.value.toString().toLowerCase() === "in progress"
          ? "#2098D1"
          : state.data.value.toString().toLowerCase() === "not started"
          ? "#fd1b1b"
          : state.data.value.toString().toLowerCase() === "completed"
          ? "#2DBF64"
          : state.data.value.toString().toLowerCase() === "on hold"
          ? "#E8910D"
          : state.data.value.toString().toLowerCase() ===
            "submit for submit for review"
          ? "#E8910D"
          : " black",
      fontSize: "14px",
      fontWeight: 300,
      textAlign: "left",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      width: "140px", // Set the desired width
      textWrap: "wrap",
    }),
  };

  const CustomTasksStatusEditorControl = memo(
    ({ children, menuIsOpen, ...props }) => {
      return (
        <components.Control {...props}>
          {props.hasValue ? (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="ms-1"
            >
              {props?.getValue()[0]?.value ===
                "9faa1123-d3f6-45a0-9ea3-a238dba20307" && (
                <img
                  src={deleteIcon}
                  alt="delete-icon"
                  style={{
                    width: 20,
                    marginLeft: 5,
                    marginRight: 4,
                    marginTop: 1,
                  }}
                />
              )}
              {projectStatusList?.map((res, index) => {
                if (res?.id === props?.getValue()[0]?.value) {
                  return (
                    <img
                      key={index}
                      src={
                        res?.defaultMarkAs === "not-started"
                          ? NotStarted
                          : res?.defaultMarkAs === "in-progress"
                          ? progressImage
                          : res?.defaultMarkAs === "on-hold"
                          ? reviewImage
                          : res?.defaultMarkAs === "review"
                          ? reviewIcon
                          : res?.defaultMarkAs === "deleted"
                          ? deleteIcon
                          : taskDeadline
                          ? taskDeadline < taskCompletedDate
                            ? TimerIcon
                            : TimelyIcon
                          : CompletedIcon
                      }
                      alt={res?.statusName}
                      style={{
                        width: 20,
                        marginLeft: 5,
                        marginRight: 4,
                        marginTop: 1,
                      }}
                    />
                  );
                }
                return null;
              })}
              <div className="task-detail-status">{children}</div>
            </div>
          ) : (
            children
          )}
          <div
            className="select-icon me-2"
            style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <IoIosArrowDown />
          </div>
        </components.Control>
      );
    }
  );

  // Get task detail
  const getTaskData = async () => {
    try {
      setTaskLoad(true);
      const response = await ProjectAPI.post(
        `task/detail?timeZone=${userProfile?.timeZone}`,
        {
          taskId: selectedTask,
        }
      );
      setList(response?.data?.data?.taskCheckLists);
      setTodoCount({
        ...todoCount,
        total: response?.data?.data?.taskCheckLists?.length,
        completed: response?.data?.data?.taskCheckLists?.filter(
          (item) => item.checklistStatus === 1
        ).length,
      });
      if (response?.data?.data?.recurringType) {
        setRepeat({
          label: repeatOptions.find(
            (option) => option.value === response?.data?.data?.recurringType
          )?.label,
          value: response?.data?.data?.recurringType,
        });
      }
      if (response?.data?.data?.projectPhase) {
        setProjectPhase({
          label: truncateText(
            response?.data?.data?.projectPhase?.phaseName,
            26
          ),
          value: response?.data?.data?.projectPhase?.value,
        });
      }
      setApproval(response?.data?.data?.hasApproval);
      setStatus(response?.data?.data?.taskStatus?.id);
      setSelected(response?.data?.data?.project?.id || null);
      setTaskData(response?.data?.data);
      setDescription(response?.data?.data?.taskDescription);
      setInitialDescription(response?.data?.data?.taskDescription);
      setTaskName(response?.data?.data?.taskName);
      setPrevName(response?.data?.data?.taskName);
      if (response?.data?.data?.project?.id) {
        getProjectPhasesAPI(response?.data?.data?.project?.id);
      }
      if (response?.data?.data?.taskAttachments) {
        setFiles(response?.data?.data?.taskAttachments);
      }
    } catch (error) {
    } finally {
      setTaskLoad(false);

      setProjectTypes(projectTypeList);
      setPriorities(taskPriorities);
      setTypes(taskTypes);
      setJourneyLoad(true);
      if (wasUnread) {
        clearAllTasksCache();
        setWasUnread(false);
      }
    }
  };

  useEffect(() => {
    getTaskData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canApiHit = (data) => {
    return true;
  };

  // Task update function
  const updateTask = async (data) => {
    let response;
    try {
      if (
        getStatusNameById(projectStatusList, status)?.toLowerCase() ===
          "completed" &&
        data?.fieldName !== "taskStatusId"
      ) {
        setIsCompletedTaskChanged(true);
      }
      if (canApiHit(data)) {
        if (listingType === "COMPLETED_TASKS") {
          response = await updateCompletedTaskData({
            ...pageParams,
            ...data,
          }).unwrap();
        } else if (listingType === "ALL_TASKS") {
          response = await updateTaskData({
            ...pageParams,
            ...data,
          }).unwrap();
        } else if (listingType === "PHASE_TASKS") {
          response = await updatePhaseTaskData({
            ...pageParams,
            ...data,
          }).unwrap();
        } else if (listingType === "NOTIFICATION_LIST") {
          await clearAllTaskCache(data);
        }
        if (response?.status === "success") {
          await getTaskList();
          await getAllTasksByPhaseId();
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
    }
  };

  const updateRowParameterById = (id, parameterName, parameterValue) => {
    setTaskList((prevRowData) => {
      // Find the index of the object with the given id
      const rowIndex = prevRowData.findIndex((row) => row.id === id);

      // If the object with the given id is found
      if (rowIndex !== -1) {
        // Create a new array to avoid mutating the state directly
        const newRowData = [...prevRowData];
        // Update the parameter of the object at the found index
        newRowData[rowIndex] = {
          ...newRowData[rowIndex],
          [parameterName]: parameterValue,
        };
        return newRowData;
      }

      // If the object with the given id is not found, return the previous state
      return prevRowData;
    });
  };

  const getFileType = (attachmentName) => {
    const extension = attachmentName?.split(".")?.pop()?.toLowerCase();
    return extension;
  };

  const updateTaskCheckList = async (data) => {
    try {
      // if (taskData?.taskStatus?.statusName !== "Completed") {
      // } else {
      //   setIsCompletedTaskChanged(true);
      // }
      await ProjectAPI.post("task/update-checklist", data);
      if (data?.description) {
        // setList(
        //   // eslint-disable-next-line array-callback-return
        //   list.filter((listItem) => {
        //     if (listItem?.taskDescription?.length > 0) {
        //       return listItem;
        //     }
        //   })
        // );
      } else {
        setList(list.filter((res, index) => res.id !== data?.checklist));
        decrementTotal();
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const updateTaskCheckListAcknowledgement = async (data) => {
    try {
      await ProjectAPI.post("task/sub-task-acknowledgement", data);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteTaskCheckList = async (data) => {
    try {
      // if (taskData?.taskStatus?.statusName !== "Completed") {
      // } else {
      //   setIsCompletedTaskChanged(true);
      // }
      // Count how many items in the list have checklistStatus as true
      const completedCount = list.filter(
        (item) =>
          (item.checklistStatus === true || item.checklistStatus === 1) &&
          item?.id === data?.checklist
      ).length;

      // Decrement the total for each true checklistStatus
      for (let i = 0; i < completedCount; i++) {
        decrementCompleted();
      }

      // Call the API to delete the checklist
      decrementTotal(); // Decrement the total count once for the deleted checklist
      await ProjectAPI.post("task/delete-checklist", data);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const addTaskCheckList = async (data, showToast = false) => {
    try {
      setAddCheckListLoad(true);

      const response = await ProjectAPI.post("task/add-new-checklist", data);

      if (response.status === 200) {
        const newChecklistItems = response?.data?.data || []; // Safely access array of objects

        // Map the response data and format each item
        const formattedItems = newChecklistItems.map((item) => ({
          id: item.id,
          taskDescription: item.taskDescription,
          checklistStatus: item.checklistStatus === 1 ? true : false,
        }));

        // Update the list state by appending the new items
        setList((prevList) => [...prevList, ...formattedItems]);

        // Increment the total count for each item in the array
        newChecklistItems.forEach(() => {
          incrementTotal();
        });

        if (showToast) {
          toast.success("Todo list updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 300,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }
    } catch (error) {
      // Show error toast on failure
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setAddCheckListLoad(false); // Stop loading state
      setBulkTodoOpen(false);
      if (!isChecklistOpen) {
        setIsChecklistOpen(true);
      }
    }
  };

  // Increase Functions
  const incrementCompleted = () => {
    setTodoCount((prevCount) => ({
      ...prevCount,
      completed: prevCount.completed + 1,
    }));
  };

  const incrementTotal = () => {
    setTodoCount((prevCount) => ({
      ...prevCount,
      total: prevCount.total + 1,
    }));
  };

  // Decrease Functions
  const decrementCompleted = () => {
    setTodoCount((prevCount) => ({
      ...prevCount,
      completed: Math.max(0, prevCount.completed - 1), // Ensure completed doesn't go below 0
    }));
  };

  const decrementTotal = () => {
    setTodoCount((prevCount) => ({
      ...prevCount,
      total: Math.max(0, prevCount.total - 1), // Ensure total doesn't go below 0
    }));
  };

  // Project selector
  const getProjectNameById = (value) => {
    const project = projectFormList?.find((option) => option.id === value);
    return project?.projectName;
  };

  const ProjectSelectSection = () => {
    return (
      <div className={`task-situation-filter-top-wrap-select`}>
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status select-item-project me-2 task-detail-select-min-width ${
              selected
                ? "filter-dropdown-selected-value"
                : "filter-dropdown-not-selected-value"
            }`}
            classNamePrefix="select"
            placeholder="Project Assignment"
            value={
              selected
                ? {
                    label: truncateText(getProjectNameById(selected), 25),
                    value: selected,
                  }
                : null
            }
            isDisabled={!canEdit}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            styles={customStyles}
            name="color"
            options={projectOptions
              ?.filter((res) => res.id !== selected) // Exclude the selected project
              .map((res) => ({
                label: res.label, // Ensure this is a string for searching
                value: res.id,
                icon: res.icon, // Save icon separately
              }))}
            formatOptionLabel={(option) => (
              <div className="d-flex align-items-center gap-1 custom-project-select-item">
                {option.icon}
                {option.label}
              </div>
            )}
            components={{ Control: CustomTasksProjectEditorControl }}
            onChange={async (e) => {
              // Check if `e` is null, which indicates the selection has been cleared
              if (e) {
                setSelected(e.value);
                getProjectPhasesAPI(e.value);
                const data = {
                  taskId: taskData?.id,
                  fieldName: "projectId",
                  fieldValues: e.value,
                };
                updateTask(data);
              } else {
                // Handle the case when selection is cleared
                setSelected(null);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const CustomTasksProjectEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="ms-1"
          >
            {projectOptions?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={res?.icon?.props?.src}
                    alt={res}
                    style={{
                      width: 25,
                      marginLeft: 5,
                      borderRadius: "50%",
                      marginRight: 5,
                      // marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            {children}
          </div>
        ) : (
          children
        )}
        <div className="d-flex align-items-center">
          {selected && (
            <div
              className="select-icon me-1 select-icon-date"
              style={{
                cursor: "pointer",
                border: "1px solid lightgray",
                borderRadius: "4px",
                padding: "0px 3px",
              }}
              onMouseDown={(e) => {
                e.stopPropagation(); // ✅ Prevents dropdown from opening
                e.preventDefault(); // ✅ Stops default behavior
                handleClearProjectSelection(); // ✅ Clears value & hits API
              }}
            >
              <IoMdClose style={{ cursor: "pointer", color: "#343131" }} />
            </div>
          )}
          <div
            className="select-icon me-2"
            style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <IoIosArrowDown />
          </div>
        </div>
      </components.Control>
    );
  };

  /* ✅ Function to clear the value & hit the API */
  const handleClearProjectSelection = async () => {
    setSelected(null);
    const data = {
      taskId: taskData?.id,
      fieldName: "projectId",
      fieldValues: null, // ✅ Set to null in the API
    };
    await updateTask(data); // ✅ Hits API to set value to null
    await handleClearProjectPhaseSelection();
  };

  /* ✅ Function to clear the value & hit the API */
  const handleClearProjectPhaseSelection = async () => {
    setProjectPhase(null);
    const data = {
      taskId: taskData?.id,
      fieldName: "projectPhaseId",
      fieldValues: null, // ✅ Set to null in the API
    };
    await updateTask(data); // ✅ Hits API to set value to null
  };

  // Phase selection
  const ProjectPhaseSelectSection = () => {
    return (
      <div className={`task-situation-filter-top-wrap-select`}>
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status select-item-phase me-2 task-detail-select-min-width ${
              projectPhase
                ? "filter-dropdown-selected-detail-value"
                : "filter-dropdown-not-selected-value"
            }`}
            classNamePrefix="select"
            placeholder="Select Project Phase"
            value={projectPhase}
            isDisabled={!canEdit}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            name="color"
            getOptionValue={(option) => option.value}
            options={
              projectPhasesOptions.data
                ?.filter((option) => option.value !== projectPhase?.value) // Exclude selected project phase
                .map((option) => ({
                  label: option.label,
                  value: option.value,
                })) || []
            }
            components={{
              Control: ({ children, menuIsOpen, ...props }) => {
                return (
                  <components.Control {...props}>
                    {children}
                    <div className="d-flex align-items-center">
                      {" "}
                      {projectPhase && (
                        <div
                          className="select-icon me-1 select-icon-date"
                          style={{
                            cursor: "pointer",
                            border: "1px solid lightgray",
                            borderRadius: "4px",
                            padding: "0px 3px",
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation(); // ✅ Prevents dropdown from opening
                            e.preventDefault(); // ✅ Stops default behavior
                            handleClearProjectPhaseSelection(); // ✅ Clears value & hits API
                          }}
                        >
                          <IoMdClose
                            style={{ cursor: "pointer", color: "#343131" }}
                          />
                        </div>
                      )}
                      <div
                        className="select-icon me-2"
                        style={{
                          transform: menuIsOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      >
                        <IoIosArrowDown />
                      </div>
                    </div>
                  </components.Control>
                );
              },
            }}
            onChange={async (e) => {
              // Check if `e` is null, which indicates the selection has been cleared
              if (e) {
                setProjectPhase({
                  label: truncateText(e.label, 26),
                  value: e.value,
                });
                const data = {
                  taskId: taskData?.id,
                  fieldName: "projectPhaseId",
                  fieldValues: e.value,
                };
                updateTask(data);
              } else {
                // Handle the case when selection is cleared
                setProjectPhase(null);
              }
            }}
          />
        </div>
      </div>
    );
  };

  // Daily selection
  const DailySelectSection = () => {
    return (
      <div className={`task-situation-filter-top-wrap-select`}>
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status select-item-phase me-2 task-detail-select-min-width ${
              repeat
                ? "filter-dropdown-selected-detail-value"
                : "filter-dropdown-not-selected-detail-value"
            }`}
            classNamePrefix="select"
            placeholder="Select Repeat Option"
            value={repeat}
            isDisabled={!canEdit}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            name="color"
            getOptionValue={(option) => option.value}
            options={
              repeatOptions
                ?.filter((option) => option.value !== repeat?.value) // Exclude selected repeat option
                .map((option) => ({
                  label: option.label,
                  value: option.value,
                })) || []
            }
            components={{
              Control: ({ children, menuIsOpen, ...props }) => {
                return (
                  <components.Control {...props}>
                    {children}
                    <div
                      className="select-icon me-2"
                      style={{
                        transform: menuIsOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <IoIosArrowDown />
                    </div>
                  </components.Control>
                );
              },
            }}
            onChange={async (e) => {
              // Check if `e` is null, which indicates the selection has been cleared
              if (e) {
                setRepeat(e);
                const data = {
                  taskId: taskData?.id,
                  fieldName: "recurringType",
                  fieldValues: e.value,
                };
                updateTask(data);
              } else {
                // Handle the case when selection is cleared
                setRepeat(null);
              }
            }}
          />
        </div>
      </div>
    );
  };

  // const [editorSize, setEditorSize] = useState({ width: "100%", height: 60 });
  const editorRef = useRef(null);
  const handleEditorInit = (evt, editor) => {
    const currentHeight = editor.getContentAreaContainer().clientHeight;
    setOriginalHeight(currentHeight);
    editorRef.current = editor;

    if (!isEditing || !canEdit) {
      editor.getBody().setAttribute("contenteditable", "false");
    }

    // Delay focus call to ensure editor is initialized
    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    }, 100); // Adjust the delay as needed
  };

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setShowMore(true);
    // Focus editor when user clicks to edit
    // Delay focus call to ensure editor is initialized
    // setTimeout(() => {
    //   if (editorRef.current) {
    //     editorRef.current.focus();
    //   }
    // }, 100);
  };

  const handleEditorBlur = async () => {
    if (description !== initialDescription) {
      const data = {
        taskId: taskData?.id,
        fieldName: "taskDescription",
        fieldValues: description,
      };
      setInitialDescription(description);
      setTaskData({ ...taskData, taskDescription: description });
      await updateTask(data);

      // initialDescription.current = newDescription;
    }
    setIsEditing(false);
  };

  // File attachments
  const [filesUploadLoader, setFilesUploadLoader] = useState(false);

  const allowedExtensions = [
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "doc",
    "docx",
    "txt",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "gif",
    "bmp",
    "svg",
    "zip",
    "rar",
    "mp3",
    "mp4",
  ];

  const fileInputRef = useRef(null);

  const handleUploadIconClick = () => {
    if (canEdit) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  const isImageFile = (fileName) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|webp|gif)$/i;
    return imageExtensionRegex.test(fileName);
  };

  const handleFileChange = async (event) => {
    if (taskData?.taskStatus?.statusName !== "Completed") {
    } else {
      setIsCompletedTaskChanged(true);
    }
    if (filesUploadLoader) return;

    const acceptedFiles = Array.from(event.target.files);

    const totalSize = calculateTotalSize(acceptedFiles);

    if (totalSize > 8 * 1024 * 1024) {
      // 8MB in bytes
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return; // Prevent further execution if validation fails
    }

    const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

    // if (filteredFiles.length === 0) {
    //   toast.error("Please enter a valid file.", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }

    try {
      setFilesUploadLoader(true);

      let formData = new FormData();
      filteredFiles.forEach((file) => {
        formData.append("attachments", file);
      });
      formData.append("taskId", taskData?.id);

      const response = await ProjectFormDataAPI.post(
        "task/upload-attachments",
        formData
      );

      if (response.status === 201) {
        const newFiles = response.data?.data?.map((file) => ({
          id: file.id,
          attachmentPath: file.attachmentPath,
          attachmentName: file.attachmentName,
          fileName: isImageFile(file.attachmentName),
        }));

        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        toast.success("Task updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    } finally {
      setFilesUploadLoader(false);
    }
  };

  // Delete file
  const handleDeleteFile = async (fileToDelete) => {
    try {
      setFilesUploadLoader(true);

      // eslint-disable-next-line no-unused-vars
      const response = await ProjectAPI.post("task/delete-attachments", {
        attachmentId: fileToDelete.id,
        taskId: taskData.id,
      });
      toast.success("Task updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
      const updatedFiles = files?.filter((file) => file.id !== fileToDelete.id);
      setFiles(updatedFiles);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setFilesUploadLoader(false);
    }
  };

  // Updating task toaster
  // Custom Toast Component

  useEffect(() => {
    if (
      isUpdating ||
      completeLoading ||
      phaseLoading ||
      filesUploadLoader ||
      memberLoad ||
      phaseMemberLoad
    ) {
      // Show the updating toast when isUpdating becomes true
      setUpdateLoad(true);
    } else {
      setUpdateLoad(false);
      // toast.dismiss();
    }
  }, [
    isUpdating,
    completeLoading,
    phaseLoading,
    filesUploadLoader,
    memberLoad,
    phaseMemberLoad,
  ]);

  useEffect(() => {
    if (updateLoad) {
      setIsToastVisible(true);
    } else {
      // Dismiss the toast when the update completes
    }
  }, [updateLoad]);

  // Run tour journey
  useEffect(() => {
    if (userProfile?.taskDetailTour && journeyLoad && show) {
      setRunTour(true);
    }
  }, [userProfile, journeyLoad, show]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton")
        // ||
        // event.target.classList.contains("introjs-donebutton")
      ) {
        try {
          setRunTour(false); // Close the tour
          const response = await API.post("users/update-profile", {
            userId: userProfile?.userId,
            fieldName: "taskDetailTour", // Add the field name for the specific input field
            fieldValues: false, // Add the corresponding state variable here
          });
          if (response?.status === 200) {
            await dispatch(userProfilePage());
          }
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 120); // Check if text exceeds 5 rows
    }
  }, [description]);

  useEffect(() => {
    // Delay focus call to ensure editor is initialized
    setTimeout(() => {
      if (isEditing && editorRef.current) {
        editorRef.current.focus();
        editorRef.current.selection.select(editorRef.current.getBody(), true);
        editorRef.current.selection.collapse(false); // Moves cursor to end
      }
    }, 100);
  }, [isEditing]);

  return (
    <Offcanvas
      className={`${
        openMoreInfo
          ? "off-concave-task-detail-modal-expand"
          : "off-concave-task-detail-modal"
      }`}
      show={show}
      // onHide={handleCloseModal}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body className="pt-0">
        <div className="task-detal-main-wraper pt-0">
          {taskLoad ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center pt-4">
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 d-flex gap-2">
                {Array.from({ length: 4 }, (_, index) => (
                  <ShimmerButton key={index} size="sm" />
                ))}
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                <ShimmerThumbnail height={220} />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 d-flex gap-2">
                {Array.from({ length: 3 }, (_, index) => (
                  <ShimmerButton key={index} size="sm" />
                ))}
              </div>
              <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100">
                <ShimmerCategoryList
                  title
                  items={6}
                  categoryStyle="STYLE_SEVEN"
                />
              </div>
            </div>
          ) : taskData ? (
            <>
              {isToastVisible && <div className="update-load-case"></div>}
              {/* {isToastVisible && ( */}
              <CustomToast
                isVisible={isToastVisible}
                onClose={() => setIsToastVisible(false)}
                updateLoad={updateLoad}
              />
              {runTour && (
                <Steps
                  enabled={runTour}
                  steps={steps.filter((step) =>
                    document.querySelector(step.element)
                  )}
                  initialStep={0}
                  options={{
                    showProgress: false,
                    showStepNumbers: false,
                    exitOnOverlayClick: false,
                    scrollToElement: true, // Enable scroll for the last step
                    exitOnEsc: true,
                    nextLabel: "Next",
                    prevLabel: "Skip Tour",
                    tooltipClass: "tour-custom-tooltip", // Optional custom styling
                    disableInteraction: true,
                  }}
                  // onBeforeChange={handleBeforeChange}
                  onExit={async () => {
                    try {
                      setRunTour(false); // Close the tour
                      const response = await API.post("users/update-profile", {
                        userId: userProfile?.userId,
                        fieldName: "taskDetailTour", // Add the field name for the specific input field
                        fieldValues: false, // Add the corresponding state variable here
                      });
                      if (response?.status === 200) {
                        await dispatch(userProfilePage());
                      }
                    } catch (error) {
                      console.error("Error updating profile:", error);
                    }
                  }} // Reset the tour state when exited
                />
              )}
              {/* )} */}
              <div
                className="top-task-detail-wraper"
                style={{
                  position: userProfile?.taskDetailTour ? "relative" : "sticky",
                }}
              >
                <div className="d-flex align-items-center justify-content-between gap-4  mb-3 mt-md-0 mt-4 td-header-container">
                  {canEdit ? (
                    <div
                      className={`d-flex align-items-center task-detail-header-section ${
                        editMode ? "task-header-edit" : ""
                      }`}
                      onClick={handleDoubleClick}
                    >
                      {editMode ? (
                        <input
                          type="text"
                          value={taskName}
                          onChange={handleChange}
                          onBlur={handleBlurOrSubmit}
                          onKeyDown={handleKeyDown}
                          className="px-2 py-1"
                          autoFocus
                        />
                      ) : (
                        <h4 className="px-2 py-1">{taskName}</h4>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <h4>{taskName}</h4>
                    </div>
                  )}

                  {canEdiStatus ? (
                    <div className="ms-md-2 task-situation-filter-top-wrap-select task-detail-status-container task-detail-status-journey">
                      {getDefaultMarkAsById(projectStatusList, status) ===
                      "not-started" ? (
                        <button
                          className="start-task-button"
                          onClick={() => {
                            setStatus(
                              getIdByDefaultMarkAs(
                                projectStatusList,
                                "in-progress"
                              )
                            );
                            const data = {
                              taskId: taskData?.id,
                              fieldName: "taskStatusId",
                              fieldValues: getIdByDefaultMarkAs(
                                projectStatusList,
                                "in-progress"
                              ),
                            };
                            updateTask(data);
                            updateRowParameterById(taskData?.id, "taskStatus", {
                              id: getIdByDefaultMarkAs(
                                projectStatusList,
                                "in-progress"
                              ),
                              statusName: getStatusNameById(
                                projectStatusList,
                                getIdByDefaultMarkAs(
                                  projectStatusList,
                                  "in-progress"
                                )
                              ),
                            });
                          }}
                        >
                          <Icon icon="gravity-ui:play" /> Start Task
                        </button>
                      ) : (
                        <div className="form-group">
                          <Select
                            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status filter-item-status td-status-select ${
                              status
                                ? `filter-dropdown-selected-value-${getDefaultMarkAsById(
                                    projectStatusList,
                                    status
                                  )}`
                                : "filter-dropdown-not-selected-value"
                            }`}
                            classNamePrefix="select"
                            value={{
                              label: status
                                ? getStatusNameById(projectStatusList, status)
                                : "Select Status",
                              value: status,
                            }}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            styles={{
                              ...customStyles,
                              menu: (base) => ({
                                ...base,
                                width: "140px !important", // Override width only for this select
                              }),
                            }}
                            placeholder="Select Status"
                            name="color"
                            menuPlacement="auto"
                            options={projectStatusList
                              ?.filter((res) => res.id !== status) // Exclude the currently selected status
                              .map((res) => ({
                                label: (
                                  <div>
                                    {res?.defaultMarkAs === "not-started" && (
                                      <img
                                        src={NotStarted}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                        className="me-1"
                                      />
                                    )}
                                    {res?.defaultMarkAs === "in-progress" && (
                                      <img
                                        src={progressImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                        className="me-1"
                                      />
                                    )}
                                    {res?.defaultMarkAs === "on-hold" && (
                                      <img
                                        src={reviewImage}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                        className="me-1"
                                      />
                                    )}
                                    {res?.defaultMarkAs === "review" && (
                                      <img
                                        src={reviewIcon}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                        className="me-1"
                                      />
                                    )}
                                    {res?.defaultMarkAs === "completed" && (
                                      <img
                                        src={CompletedIcon}
                                        alt="Critical"
                                        width={20}
                                        height={20}
                                        className="me-1"
                                      />
                                    )}
                                    {res?.statusName}
                                  </div>
                                ),
                                value: res?.id,
                              }))}
                            components={{
                              Control: CustomTasksStatusEditorControl,
                            }}
                            onChange={(e) => {
                              if (
                                taskData?.hasApproval &&
                                !isOwnerInTaskOne(
                                  taskData,
                                  userProfile?.userId
                                ) &&
                                getDefaultMarkAsById(
                                  projectStatusList,
                                  e.value
                                ) === "completed"
                              ) {
                                // Assuming index 4 is the restricted one
                                setShowStatusModal(true);
                                return; // Prevent the Select component's value from updating
                              } else {
                                setStatus(e.value);
                                const data = {
                                  taskId: taskData?.id,
                                  fieldName: "taskStatusId",
                                  fieldValues: e.value,
                                };
                                updateTask(data);
                                updateRowParameterById(
                                  taskData?.id,
                                  "taskStatus",
                                  {
                                    id: e.value,
                                    statusName: getStatusNameById(
                                      projectStatusList,
                                      e.value
                                    ),
                                  }
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        id="status-prem-tooltip"
                        style={{ display: "flex", alignItems: "center" }}
                        className="task-detail-status-journey"
                      >
                        {statusList?.map((res, index) => {
                          if (
                            res?.toLowerCase() ===
                            taskData?.taskStatus?.statusName?.toLowerCase()
                          ) {
                            return (
                              <img
                                key={index}
                                src={
                                  res?.toLowerCase() === "not started"
                                    ? NotStarted
                                    : res?.toLowerCase() === "in progress"
                                    ? progressImage
                                    : res?.toLowerCase() === "on hold"
                                    ? reviewImage
                                    : res?.toLowerCase() === "submit for review"
                                    ? reviewIcon
                                    : res?.toLowerCase() === "deleted"
                                    ? deleteIcon
                                    : CompletedIcon
                                }
                                alt={res}
                                style={{
                                  width: 20,
                                  marginLeft: 5,
                                  marginRight: 0,
                                  marginTop: 1,
                                }}
                              />
                            );
                          }
                          return null;
                        })}
                        <div
                          className="ms-1"
                          style={{
                            color: "blue !important",
                            fontSize: "14px",
                            fontWeight: "300",
                          }}
                        >
                          {taskData?.taskStatus?.statusName ||
                            "No status assigned"}
                        </div>
                      </div>
                      <Tooltip id="status-prem-tooltip">
                        You don't have permission.
                      </Tooltip>
                    </>
                  )}
                </div>
                {!openMoreInfo && (
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <TaskDetailFilterSection
                      props={{ isDark: isDark }}
                      task={taskData}
                      setTask={setTaskData}
                      taskList={taskList}
                      setTaskList={setTaskList}
                      isDark={isDark}
                      getStatusCounts={getStatusCounts}
                      projectTypes={projectTypes}
                      priorities={priorities}
                      types={types}
                      kanban={kanban}
                      getTaskDataAfterUpdate={async () => {
                        await getTaskList();
                        await getAllTasksByPhaseId();
                      }}
                      canEdit={canEdit}
                      userProfile={userProfile}
                      updateTaskData={updateTask}
                      pageParams={pageParams}
                      listingType={listingType}
                      setUpdateLoad={setUpdateLoad}
                      updateLoad={updateLoad}
                      updateTaskMemberData={updateTaskMemberData}
                      updatePhaseTaskMemberData={updatePhaseTaskMemberData}
                      isCompletedTaskChanged={isCompletedTaskChanged}
                      setIsCompletedTaskChanged={setIsCompletedTaskChanged}
                    />
                    {/* {!openMoreInfo && ( */}
                    <div className="task-detail-more-info-wrapper mb-2">
                      <div
                        className="d-flex align-items-center justify-content-center task-detail-more-info"
                        onClick={() => setOpenMoreInfo(!openMoreInfo)}
                      >
                        <button
                          className="task-detail-more-info-btn"
                          onClick={() => setOpenMoreInfo(!openMoreInfo)}
                        >
                          <Icon icon="ep:arrow-left" />
                        </button>
                        More Info
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                )}
              </div>
              <div
                className={`${
                  openMoreInfo
                    ? "d-flex align-items-start justify-content-between task-detail-more-info-container"
                    : ""
                }`}
              >
                <div
                  className={`project-info-main-wraper
                }`}
                >
                  {openMoreInfo && (
                    <div className="d-flex align-items-center justify-content-between gap-2">
                      <TaskDetailFilterSection
                        props={{ isDark: isDark }}
                        task={taskData}
                        setTask={setTaskData}
                        taskList={taskList}
                        setTaskList={setTaskList}
                        isDark={isDark}
                        getStatusCounts={getStatusCounts}
                        projectTypes={projectTypes}
                        priorities={priorities}
                        types={types}
                        kanban={kanban}
                        getTaskDataAfterUpdate={async () => {
                          await getTaskList();
                          await getAllTasksByPhaseId();
                        }}
                        canEdit={canEdit}
                        userProfile={userProfile}
                        updateTaskData={updateTask}
                        pageParams={pageParams}
                        listingType={listingType}
                        setUpdateLoad={setUpdateLoad}
                        updateLoad={updateLoad}
                        updateTaskMemberData={updateTaskMemberData}
                        updatePhaseTaskMemberData={updatePhaseTaskMemberData}
                        isCompletedTaskChanged={isCompletedTaskChanged}
                        setIsCompletedTaskChanged={setIsCompletedTaskChanged}
                      />
                    </div>
                  )}
                  <div
                    className={`w-100 position-relative  ${
                      !isEditing ? "task-detail-tinymc" : ""
                    }`}
                  >
                    <h6>
                      {" "}
                      Description{" "}
                      {/* {!canEdit ? (
                      <span style={{ fontWeight: "300", fontSize: "11px" }}>
                        (You are not allowed to change task description)
                      </span>
                    ) : (
                      ""
                    )} */}
                      {canEdit && (
                        <span className="edit-description-icon">
                          <Icon
                            icon="lets-icons:edit-light"
                            fontSize={20}
                            onClick={handleEditClick}
                          />
                        </span>
                      )}
                    </h6>
                    {!description && !isEditing ? (
                      canEdit ? (
                        <div
                          className="no-description-data"
                          onClick={handleEditClick}
                        >
                          <p className="mb-0 ms-4">Add details of task</p>
                        </div>
                      ) : (
                        <div
                          className="no-description-data"
                          // onClick={handleEditClick}
                        >
                          <p className="mb-0 ms-4">No description available</p>
                        </div>
                      )
                    ) : (
                      <>
                        <div
                          className={`task-detail-editor-wraper ${
                            !isEditing || !canEdit
                              ? "task-detail-editor-wraper-no-edit"
                              : ""
                          } ${
                            showMore ? "task-detail-editor-wraper-open" : ""
                          }`}
                          style={{ display: isEditing ? "block" : "none" }}
                        >
                          <Editor
                            onInit={handleEditorInit}
                            ref={editorRef}
                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                            apiKey="gpl"
                            value={description}
                            // onClick={() => {
                            //   if (!isEditing && canEdit) {
                            //     setIsEditing(!isEditing);
                            //     setShowMore(true);
                            //     // Focus editor when user clicks to edit
                            //     setTimeout(() => {
                            //       if (editorRef.current) {
                            //         editorRef.current.focus();
                            //       }
                            //     }, 100);
                            //   }
                            // }}
                            init={{
                              // menubar: false,
                              // readonly: !isEditing || !canEdit, // Switch to editable only in editing mode
                              plugins: "autoresize lists textcolor colorpicker",
                              toolbar:
                                "bold italic underline | checklist numlist bullist",
                              setup: (editor) => {
                                editor.on("init", () => {
                                  editor
                                    .getBody()
                                    .setAttribute("contenteditable", "true");
                                });
                              },
                              content_style:
                                "html, body { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; overflow: hidden; margin:0px; padding:0px } p {margin-top:0 !important},body:focus {outline: none !important;} .tox-tinymce {outline: none !important;}",
                            }}
                            // disabled={!isEditing || !canEdit}
                            onEditorChange={(newContent, editor) => {
                              // Update your formik state if needed:
                              setDescription(newContent);
                            }}
                          />
                        </div>
                        {!isEditing && (
                          <div
                            className="task-detail-container"
                            // style={{ display: !isEditing ? "block" : "none" }}
                          >
                            <div
                              ref={contentRef}
                              className={`task-detail-text ${
                                isOverflowing && !showMore
                                  ? "description-overflow-collapsed"
                                  : ""
                              } ${showMore ? "expanded" : "collapsed"}`}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: description
                                    ? description.replace(
                                        /^(<p>\s*&nbsp;\s*<\/p>\s*)+/i,
                                        ""
                                      )
                                    : "Description not set",
                                }}
                              />
                            </div>

                            {/* Show "More" button if text exceeds 5 rows */}
                            {isOverflowing && (
                              <div className="w-100 d-flex justify-content-end">
                                <button
                                  className="view-more-btn"
                                  onClick={() => setShowMore(!showMore)}
                                >
                                  {showMore ? "Show less" : "More"}
                                  <Icon
                                    icon={
                                      showMore
                                        ? "lsicon:double-arrow-up-filled"
                                        : "lsicon:double-arrow-down-filled"
                                    }
                                    fontSize={20}
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {/* {!isEditing && description && originalHeight > 120 && (
                    <div className="w-100 d-flex justify-content-end tinymce-editor-show-button">
                      <button
                        className="position-absolute me-1"
                        // onClick={handleShowMoreClick}
                        onClick={handleShowMoreClick}
                      >
                        {!showMore ? "More" : "Show less"}
                        {!showMore ? (
                          <Icon
                            icon="lsicon:double-arrow-down-filled"
                            fontSize={20}
                          />
                        ) : (
                          <Icon
                            icon="lsicon:double-arrow-up-filled"
                            fontSize={20}
                          />
                        )}
                      </button>
                    </div>
                  )} */}
                    {isEditing && (
                      <div className="w-100 d-flex align-items-center justify-content-end gap-2 mt-2">
                        <button
                          className="task-detail-description-edit-btn-check"
                          onClick={handleEditorBlur}
                        >
                          <Icon icon="tabler:check" />
                        </button>
                        <button
                          className="task-detail-description-edit-btn-cross"
                          onClick={async (e) => {
                            e.stopPropagation();
                            setDescription(initialDescription);
                            setIsEditing(false);
                          }}
                        >
                          <Icon icon="gridicons:cross" />
                        </button>
                      </div>
                    )}
                  </div>
                  {!isAdmin(userProfile) &&
                  !isOwnerInTaskOne(taskData, userProfile?.userId) &&
                  list.length === 0 ? (
                    <div className="my-1"></div>
                  ) : (
                    <div className="todo-listn-wrap col-md-12">
                      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mb-3">
                        <h5 className="d-flex align-items-center mb-0">
                          <span
                            className={`todo-list-toggle ${
                              isChecklistOpen ? "open" : ""
                            }`}
                            onClick={() => setIsChecklistOpen(!isChecklistOpen)}
                            style={{
                              cursor: "pointer",
                              marginRight: "8px",
                              marginBottom: "3px",
                            }}
                          >
                            <Icon
                              icon={
                                isChecklistOpen
                                  ? "akar-icons:chevron-up"
                                  : "akar-icons:chevron-down"
                              }
                              fontSize={18}
                            />
                          </span>{" "}
                          Check List{" "}
                          <span className="ms-2 todo-list-count-span">
                            {todoCount?.completed} / {todoCount?.total}
                          </span>{" "}
                          {canEditChecklist && canEdit && (
                            <span
                              className="todo-list-add-item"
                              onClick={async () => {
                                await addTaskCheckList({
                                  taskId: taskData?.id,
                                  description: "",
                                });
                              }}
                              disabled={addCheckListLoad}
                            >
                              + Sub tasks
                            </span>
                          )}
                          {addCheckListLoad && (
                            <span className="">
                              <Spinner
                                size="sm"
                                animation="border"
                                className="global-spinner ms-2"
                              ></Spinner>
                            </span>
                          )}
                          {/* {list.length > 0 && (
                        <p className="ms-3 mb-0">
                          {todoCount?.completed} / {todoCount?.total}
                          <span className="ms-2">Completed</span>
                        </p>
                      )} */}
                        </h5>
                        {canEditChecklist && canEdit && (
                          <div className="d-flex gap-4 task-detail-export-task-list mb-md-0 mb-2">
                            <p
                              onClick={() => {
                                if (!isChecklistOpen) {
                                  setIsChecklistOpen(true);
                                }
                                setBulkTodoOpen(true);
                              }}
                            >
                              <span>
                                <Icon
                                  icon="clarity:export-line"
                                  className="me-2"
                                  fontSize={18}
                                />
                              </span>
                              Import task list
                            </p>
                          </div>
                        )}
                      </div>
                      <div
                        className={`task-detail-todo-list-container ${
                          isChecklistOpen ? "open" : ""
                        }`}
                      >
                        {list?.length > 0 || bulkTodoOpen ? (
                          <>
                            <TaskDetailsTodoList
                              list={list}
                              setList={setList}
                              isDark={isDark}
                              updateTaskCheckList={updateTaskCheckList}
                              deleteTaskCheckList={deleteTaskCheckList}
                              addTaskCheckList={async () => {
                                await addTaskCheckList({
                                  taskId: taskData?.id,
                                  description: [""],
                                });
                              }}
                              addCheckListBulk={addTaskCheckList}
                              taskDetails={taskData}
                              canEdit={canEditChecklist}
                              canEditGeneral={canEdit}
                              canEditObserver={canEditObserver}
                              incrementCompleted={incrementCompleted}
                              decrementCompleted={decrementCompleted}
                              userProfile={userProfile}
                              updateTaskCheckListAcknowledgement={
                                updateTaskCheckListAcknowledgement
                              }
                              bulkTodoOpen={bulkTodoOpen}
                              setBulkTodoOpen={setBulkTodoOpen}
                              addCheckListLoad={addCheckListLoad}
                            />
                            {canEditChecklist && canEdit && (
                              <h5 style={{ marginTop: "10px" }}>
                                <span
                                  className="todo-list-add-item"
                                  onClick={async () => {
                                    await addTaskCheckList({
                                      taskId: taskData?.id,
                                      description: "",
                                    });
                                  }}
                                >
                                  + Sub tasks
                                </span>
                              </h5>
                            )}
                          </>
                        ) : canEdit ? (
                          <div
                            className="no-todo-data"
                            onClick={async () => {
                              await addTaskCheckList({
                                taskId: taskData?.id,
                                description: "",
                              });
                            }}
                          >
                            <p className="mb-0">
                              <span className="mx-2">
                                <Icon icon="mingcute:add-line" fontSize={24} />
                              </span>
                              Add Todo List
                            </p>
                          </div>
                        ) : (
                          <div className="no-todo-data">
                            <p className="mb-0 ms-2">No todo list available</p>
                          </div>
                        )}
                      </div>
                      {/* {isChecklistOpen && (
                        <>
                          {list?.length > 0 || bulkTodoOpen ? (
                            <>
                              <TaskDetailsTodoList
                                list={list}
                                setList={setList}
                                isDark={isDark}
                                updateTaskCheckList={updateTaskCheckList}
                                deleteTaskCheckList={deleteTaskCheckList}
                                addTaskCheckList={async () => {
                                  await addTaskCheckList({
                                    taskId: taskData?.id,
                                    description: [""],
                                  });
                                }}
                                addCheckListBulk={addTaskCheckList}
                                taskDetails={taskData}
                                canEdit={canEditChecklist}
                                canEditGeneral={canEdit}
                                canEditObserver={canEditObserver}
                                incrementCompleted={incrementCompleted}
                                decrementCompleted={decrementCompleted}
                                userProfile={userProfile}
                                updateTaskCheckListAcknowledgement={
                                  updateTaskCheckListAcknowledgement
                                }
                                bulkTodoOpen={bulkTodoOpen}
                                setBulkTodoOpen={setBulkTodoOpen}
                                addCheckListLoad={addCheckListLoad}
                              />
                              {canEditChecklist && canEdit && (
                                <h5 style={{ marginTop: "10px" }}>
                                  <span
                                    className="todo-list-add-item"
                                    onClick={async () => {
                                      await addTaskCheckList({
                                        taskId: taskData?.id,
                                        description: "",
                                      });
                                    }}
                                  >
                                    + Sub tasks
                                  </span>
                                </h5>
                              )}
                            </>
                          ) : canEdit ? (
                            <div
                              className="no-todo-data"
                              onClick={async () => {
                                await addTaskCheckList({
                                  taskId: taskData?.id,
                                  description: "",
                                });
                              }}
                            >
                              <p className="mb-0">
                                <span className="mx-2">
                                  <Icon
                                    icon="mingcute:add-line"
                                    fontSize={24}
                                  />
                                </span>
                                Add Todo List
                              </p>
                            </div>
                          ) : (
                            <div className="no-todo-data">
                              <p className="mb-0 ms-2">
                                No todo list available
                              </p>
                            </div>
                          )}
                        </>
                      )} */}
                    </div>
                  )}
                  {!isAdmin(userProfile) &&
                  !isOwnerInTaskOne(taskData, userProfile?.userId) &&
                  files.length === 0 ? (
                    <div className="my-1"></div>
                  ) : (
                    <div className="attachment-files-main-wrap">
                      <div className="d-flex align-items-center gap-4 justify-content-between flex-wrap">
                        <h4>
                          {" "}
                          Attachments ({files?.length}){" "}
                          {files?.length > 0 && canEditChecklist && canEdit && (
                            <span className="ms-2">
                              <span
                                className="ms-2"
                                onClick={handleUploadIconClick}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon size={24} icon="ph:upload" />
                              </span>
                            </span>
                          )}
                          {filesUploadLoader && (
                            <span className="ms-2">
                              <Spinner className="global-spinner" size="sm" />
                            </span>
                          )}
                        </h4>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept={allowedExtensions
                            .map((ext) => `.${ext}`)
                            .join(",")}
                          multiple
                          onChange={handleFileChange}
                        />
                        {files?.length > 0 && (
                          <p
                            className="download-all-files"
                            onClick={async () => {
                              const response = await downloadZipAllFiles({
                                taskId: taskData?.id,
                              });
                              if (
                                response?.data?.status === "success" &&
                                response?.data?.zipFilePath
                              ) {
                                window.location.href =
                                  response.data.zipFilePath;
                              } else {
                                toast.error("Failed to generate ZIP file.", {
                                  position: toast.POSITION.TOP_CENTER,
                                  autoClose: 300,
                                  hideProgressBar: true,
                                  closeButton: false,
                                });
                              }
                            }}
                          >
                            {zipLoading && (
                              <span>
                                <Spinner size="sm" className="me-2" />
                              </span>
                            )}
                            <span>
                              <Icon icon="hugeicons:download-03" />
                            </span>{" "}
                            Download all
                          </p>
                        )}
                      </div>
                      {files?.length === 0 ? (
                        <div
                          className="no-attachment-data"
                          onClick={handleUploadIconClick}
                          style={{
                            cursor: canEdit ? "pointer" : "default", // Set cursor based on canEdit
                          }}
                        >
                          <p className="mb-0">
                            {canEdit && (
                              <span className="me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15.75 9H13.8204C13.2964 9 13.0344 9 12.8133 9.11833C12.5922 9.23665 12.4469 9.45463 12.1563 9.8906L11.8437 10.3594C11.5531 10.7954 11.4078 11.0133 11.1867 11.1317C10.9656 11.25 10.7036 11.25 10.1796 11.25H7.82037C7.2964 11.25 7.03442 11.25 6.81333 11.1317C6.59223 11.0133 6.44691 10.7954 6.15627 10.3594L5.84373 9.8906C5.55309 9.45463 5.40777 9.23665 5.18667 9.11833C4.96558 9 4.7036 9 4.17963 9H2.25"
                                    stroke="#aeb6d1"
                                  />
                                  <path
                                    d="M6.375 6L9 3.75M9 3.75L11.625 6M9 3.75L9 9"
                                    stroke="#aeb6d1"
                                  />
                                  <path
                                    d="M3.75 6.75L2.54289 7.95711C2.35536 8.14464 2.25 8.399 2.25 8.66421V12.25C2.25 13.3546 3.14543 14.25 4.25 14.25H13.75C14.8546 14.25 15.75 13.3546 15.75 12.25V8.66421C15.75 8.399 15.6446 8.14464 15.4571 7.95711L14.25 6.75"
                                    stroke="#aeb6d1"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                            )}
                            {canEdit
                              ? "Upload files"
                              : "No attachments available"}
                          </p>
                        </div>
                      ) : (
                        <div className="attachment-files-main-wrap-list">
                          {files
                            .slice(0, visibleFilesCount)
                            ?.map((attachment, index) => (
                              <div
                                className="file-attachment-wrap"
                                // onClick={() => handleThumbnailClick(attachment)}
                              >
                                <div className="file-attachment-icon">
                                  {attachment &&
                                    getFileType(attachment.attachmentName) ===
                                      "image" && (
                                      <img
                                        key={attachment?.id}
                                        className="thumbnail"
                                        src={attachment?.attachmentPath}
                                        alt={attachment?.attachmentName}
                                        onClick={() =>
                                          handleThumbnailClick(attachment)
                                        }
                                      />
                                    )}{" "}
                                  {attachment &&
                                    getFileType(attachment?.attachmentName) !==
                                      "image" && (
                                      <FileIcon
                                        extension={getFileType(
                                          attachment?.attachmentName
                                        )}
                                        {...defaultStyles[
                                          getFileType(
                                            attachment?.attachmentName
                                          )
                                        ]}
                                      />
                                    )}
                                </div>
                                {canEdit && (
                                  <button className="delete-file-button">
                                    <Icon
                                      icon="basil:cross-outline"
                                      size={24}
                                      onClick={() =>
                                        handleDeleteFile(attachment)
                                      }
                                    />
                                  </button>
                                )}
                                <div className="d-flex flex-column justify-content-center">
                                  <h6>
                                    {attachment?.attachmentName &&
                                    attachment.attachmentName.length > 15
                                      ? attachment.attachmentName.substring(
                                          0,
                                          15
                                        ) + "..."
                                      : attachment.attachmentName}
                                  </h6>
                                  <div className="d-flex align-items-center gap-2 view-download-container">
                                    <p
                                      onClick={() =>
                                        handleFileClick(attachment)
                                      }
                                    >
                                      View
                                    </p>
                                    <span></span>
                                    <p
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDownloadDocFiles(
                                          attachment?.attachmentPath,
                                          getFileType(
                                            attachment?.attachmentPath
                                          )
                                        );
                                      }}
                                    >
                                      Download
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                      {files?.length > 9 && (
                        <div className="mt-4 w-100 d-flex align-items-center justify-content-center show-more-container">
                          <button
                            onClick={() => setShowAttachmentModal(true)}
                            className="show-more-attachment-btn"
                          >
                            Show More
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <TaskDetailCommentSection
                    selectedTask={selectedTask}
                    isDark={isDark}
                    userProfile={userProfile}
                    taskData={taskData}
                  />
                </div>
                {openMoreInfo && !mobile?.isMobile && (
                  <div className="position-relative task-detail-right-section">
                    <button
                      className="task-detail-more-info-btn task-detail-more-info-btn-top"
                      onClick={() => setOpenMoreInfo(!openMoreInfo)}
                    >
                      <Icon icon="ep:arrow-right" />
                    </button>
                    <div className="w-100 more-option-row-task-detail">
                      <div className="w-100">
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={ObserverIcon}
                                    alt="check-icon"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                Task Observer
                              </p>
                            </div>
                            <div className="">
                              {/* <ObserverList taskData={taskData} /> */}
                              <TaskSelectedObserverSection
                                taskData={taskData}
                                projectUserList={
                                  projectUserList?.data?.map((user) => ({
                                    ...user,
                                  })) || []
                                }
                                projectTypes={projectTypes}
                                userId={userProfile?.userId}
                                userProfile={userProfile}
                                isDark={isDark}
                                setTaskData={setTaskData}
                                setUpdateLoad={setUpdateLoad}
                                pageParams={pageParams}
                                listingType={listingType}
                                kanban={kanban}
                                getTaskDataAfterUpdate={async () => {
                                  await getTaskList();
                                  await getAllTasksByPhaseId();
                                }}
                                updateTaskMemberData={updateTaskMemberData}
                                updatePhaseTaskMemberData={
                                  updatePhaseTaskMemberData
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={OwnerTdIcon}
                                    alt="owner-icon"
                                    width={20}
                                    height={20}
                                    className="task-detail-owner-label-icon"
                                  />
                                </span>
                                Task Owner
                              </p>
                            </div>
                            <div className="">
                              {/* <ObserverList taskData={taskData} /> */}
                              <TaskSelectedOwnerSection
                                taskData={taskData}
                                projectUserList={
                                  projectUserList?.data?.map((user) => ({
                                    ...user,
                                  })) || []
                                }
                                projectTypes={projectTypes}
                                userId={userProfile?.userId}
                                userProfile={userProfile}
                                isDark={isDark}
                                setTaskData={setTaskData}
                                setUpdateLoad={setUpdateLoad}
                                pageParams={pageParams}
                                listingType={listingType}
                                kanban={kanban}
                                getTaskDataAfterUpdate={async () => {
                                  await getTaskData();
                                  await getTaskList();
                                  await getAllTasksByPhaseId();
                                }}
                                updateTaskMemberData={updateTaskMemberData}
                                updatePhaseTaskMemberData={
                                  updatePhaseTaskMemberData
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={ProjectIcon}
                                    alt="check-icon"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                Project
                              </p>
                            </div>
                            <div className="">
                              {canEdit ? (
                                <ProjectSelectSection />
                              ) : (
                                <>
                                  <div
                                    id="status-prem-tooltip"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {taskData?.project ? (
                                      <>
                                        <img
                                          src={
                                            getProjectIconById(
                                              taskData?.project?.projectIcon
                                            )
                                              ? getProjectIconById(
                                                  taskData?.project?.projectIcon
                                                )
                                              : taskData?.project?.projectIcon
                                          }
                                          alt={taskData?.project?.projectName}
                                          style={{
                                            width: 35,
                                            marginLeft: 5,
                                            marginRight: 0,
                                            marginTop: 1,
                                            objectFit: "contain",
                                            borderRadius: "50%",
                                          }}
                                        />
                                        <div
                                          className="ms-1"
                                          style={{
                                            color: "blue !important",
                                            fontSize: "14px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          {taskData?.project?.projectName}
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="ms-1"
                                        style={{
                                          color: "blue !important",
                                          fontSize: "14px",
                                          fontWeight: "300",
                                        }}
                                      >
                                        No project assigned
                                      </div>
                                    )}
                                  </div>
                                  <Tooltip id="status-prem-tooltip">
                                    You don't have permission.
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={PhaseIcon}
                                    alt="check-icon"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                Project Phase
                              </p>
                            </div>
                            <div className="">
                              {canEdit ? (
                                <ProjectPhaseSelectSection />
                              ) : (
                                <>
                                  <div
                                    id="status-prem-tooltip"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {taskData?.projectPhase ? (
                                      <>
                                        <div
                                          className="ms-1"
                                          style={{
                                            color: "blue !important",
                                            fontSize: "14px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          {taskData?.projectPhase?.phaseName}
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="ms-1"
                                        style={{
                                          color: "blue !important",
                                          fontSize: "14px",
                                          fontWeight: "300",
                                        }}
                                      >
                                        No project phase assigned
                                      </div>
                                    )}
                                  </div>
                                  <Tooltip id="status-prem-tooltip">
                                    You don't have permission.
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={RepeatIcon}
                                    alt="check-icon"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                Repeat
                              </p>
                            </div>
                            <div className="">
                              {canEdit ? (
                                <DailySelectSection />
                              ) : (
                                <>
                                  <div
                                    id="status-prem-tooltip"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {taskData?.recurringType ? (
                                      <>
                                        <div
                                          className="ms-1"
                                          style={{
                                            color: "blue !important",
                                            fontSize: "14px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          {taskData?.recurringType
                                            .charAt(0)
                                            .toUpperCase() +
                                            taskData.recurringType.slice(1)}
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="ms-1"
                                        style={{
                                          color: "blue !important",
                                          fontSize: "14px",
                                          fontWeight: "300",
                                        }}
                                      >
                                        No recurring type assigned
                                      </div>
                                    )}
                                  </div>
                                  <Tooltip id="status-prem-tooltip">
                                    You don't have permission.
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-review-row task-detail-right-individual-section">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={CheckIcon}
                                    alt="check-icon"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                Check me out
                              </p>
                            </div>
                            <div className="">
                              {canEdit ? (
                                <div
                                  className={`review-for-approval-wrap ${
                                    approval ? "review-approval" : ""
                                  }`}
                                >
                                  <label className="d-flex align-items-center mb-0 control-task control-task-checkbox control-select-checkbox advanced-check-options">
                                    <input
                                      type="checkbox"
                                      checked={approval} // Checked state based on `approval`
                                      onChange={(e) => {
                                        setApproval(!approval);
                                        const data = {
                                          taskId: taskData?.id,
                                          fieldName: "hasApproval",
                                          fieldValues: !approval,
                                        };
                                        updateTask(data);
                                        e.target.blur();
                                        // Remove focus after clicking
                                      }}
                                    />
                                    <div className="control_task_indicator control-select-indicator"></div>
                                    <p className="mb-0">Review for approval</p>
                                  </label>
                                </div>
                              ) : (
                                <div
                                  className={`no-approval-edit ${
                                    !approval ? "ps-1" : ""
                                  }`}
                                >
                                  <label
                                    className={`d-flex align-items-center mb-0 ${
                                      !approval ? "ps-0" : ""
                                    } control-task control-task-checkbox control-select-checkbox advanced-check-options`}
                                    style={{
                                      cursor: "default", // Conditional cursor style
                                    }}
                                  >
                                    {approval && (
                                      <>
                                        <input
                                          type="checkbox"
                                          checked={approval} // Checked state based on `approval`
                                          onChange={(e) => {
                                            return;
                                          }}
                                        />
                                        <div className="control_task_indicator control-select-indicator"></div>
                                      </>
                                    )}
                                    <p className="mb-0">
                                      {approval
                                        ? "Review for approval"
                                        : "Review approval option not available"}
                                    </p>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date pb-0">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={CalenderIcon}
                                    alt="owner-icon"
                                    width={20}
                                    height={20}
                                    className="task-detail-owner-label-icon"
                                  />
                                </span>
                                Completion date
                              </p>
                            </div>
                            <div className="more-options-label-date">
                              {/* <ObserverList taskData={taskData} /> */}
                              <p>
                                {taskData?.taskCompletedDate
                                  ? dateFormatter(
                                      taskData?.taskCompletedDate,
                                      3
                                    )
                                  : "Not completed"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date pb-0">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={CalenderIcon}
                                    alt="owner-icon"
                                    width={20}
                                    height={20}
                                    className="task-detail-owner-label-icon"
                                  />
                                </span>
                                Created date
                              </p>
                            </div>
                            <div className="more-options-label-date">
                              {/* <ObserverList taskData={taskData} /> */}
                              <p>
                                {taskData?.createdAt
                                  ? dateFormatter(taskData?.createdAt, 3)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date">
                            <div className="d-flex align-items-center">
                              <p className="more-options-label">
                                <span>
                                  <img
                                    src={CalenderIcon}
                                    alt="owner-icon"
                                    width={20}
                                    height={20}
                                    className="task-detail-owner-label-icon"
                                  />
                                </span>
                                Updated date
                              </p>
                            </div>
                            <div className="more-options-label-date">
                              {/* <ObserverList taskData={taskData} /> */}
                              <p>
                                {taskData?.updatedAt
                                  ? dateFormatter(taskData?.updatedAt, 3)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6"></div> */}
                    </div>
                  </div>
                )}
                {/* Mobile View with Offcanvas Modal */}
                {openMoreInfo && mobile?.isMobile && (
                  <Offcanvas
                    show={openMoreInfo}
                    onHide={() => setOpenMoreInfo(false)}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>More Information</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div className="position-relative task-detail-right-section">
                        <button
                          className="task-detail-more-info-btn task-detail-more-info-btn-top"
                          onClick={() => setOpenMoreInfo(!openMoreInfo)}
                        >
                          <Icon icon="ep:arrow-right" />
                        </button>
                        <div className="w-100 more-option-row-task-detail">
                          <div className="w-100">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={ObserverIcon}
                                        alt="check-icon"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    Task Observer
                                  </p>
                                </div>
                                <div className="">
                                  {/* <ObserverList taskData={taskData} /> */}
                                  <TaskSelectedObserverSection
                                    taskData={taskData}
                                    projectUserList={
                                      projectUserList?.data?.map((user) => ({
                                        ...user,
                                      })) || []
                                    }
                                    projectTypes={projectTypes}
                                    userId={userProfile?.userId}
                                    userProfile={userProfile}
                                    isDark={isDark}
                                    setTaskData={setTaskData}
                                    setUpdateLoad={setUpdateLoad}
                                    pageParams={pageParams}
                                    listingType={listingType}
                                    kanban={kanban}
                                    getTaskDataAfterUpdate={async () => {
                                      await getTaskList();
                                      await getAllTasksByPhaseId();
                                    }}
                                    updateTaskMemberData={updateTaskMemberData}
                                    updatePhaseTaskMemberData={
                                      updatePhaseTaskMemberData
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={OwnerTdIcon}
                                        alt="owner-icon"
                                        width={20}
                                        height={20}
                                        className="task-detail-owner-label-icon"
                                      />
                                    </span>
                                    Task Owner
                                  </p>
                                </div>
                                <div className="">
                                  {/* <ObserverList taskData={taskData} /> */}
                                  <TaskSelectedOwnerSection
                                    taskData={taskData}
                                    projectUserList={
                                      projectUserList?.data?.map((user) => ({
                                        ...user,
                                      })) || []
                                    }
                                    projectTypes={projectTypes}
                                    userId={userProfile?.userId}
                                    userProfile={userProfile}
                                    isDark={isDark}
                                    setTaskData={setTaskData}
                                    setUpdateLoad={setUpdateLoad}
                                    pageParams={pageParams}
                                    listingType={listingType}
                                    kanban={kanban}
                                    getTaskDataAfterUpdate={async () => {
                                      await getTaskData();
                                      await getTaskList();
                                      await getAllTasksByPhaseId();
                                    }}
                                    updateTaskMemberData={updateTaskMemberData}
                                    updatePhaseTaskMemberData={
                                      updatePhaseTaskMemberData
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={ProjectIcon}
                                        alt="check-icon"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    Project
                                  </p>
                                </div>
                                <div className="">
                                  {canEdit ? (
                                    <ProjectSelectSection />
                                  ) : (
                                    <>
                                      <div
                                        id="status-prem-tooltip"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {taskData?.project ? (
                                          <>
                                            <img
                                              src={
                                                getProjectIconById(
                                                  taskData?.project?.projectIcon
                                                )
                                                  ? getProjectIconById(
                                                      taskData?.project
                                                        ?.projectIcon
                                                    )
                                                  : taskData?.project
                                                      ?.projectIcon
                                              }
                                              alt={
                                                taskData?.project?.projectName
                                              }
                                              style={{
                                                width: 35,
                                                marginLeft: 5,
                                                marginRight: 0,
                                                marginTop: 1,
                                                objectFit: "contain",
                                                borderRadius: "50%",
                                              }}
                                            />
                                            <div
                                              className="ms-1"
                                              style={{
                                                color: "blue !important",
                                                fontSize: "14px",
                                                fontWeight: "300",
                                              }}
                                            >
                                              {taskData?.project?.projectName}
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="ms-1"
                                            style={{
                                              color: "blue !important",
                                              fontSize: "14px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            No project assigned
                                          </div>
                                        )}
                                      </div>
                                      <Tooltip id="status-prem-tooltip">
                                        You don't have permission.
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={PhaseIcon}
                                        alt="check-icon"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    Project Phase
                                  </p>
                                </div>
                                <div className="">
                                  {canEdit ? (
                                    <ProjectPhaseSelectSection />
                                  ) : (
                                    <>
                                      <div
                                        id="status-prem-tooltip"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {taskData?.projectPhase ? (
                                          <>
                                            <div
                                              className="ms-1"
                                              style={{
                                                color: "blue !important",
                                                fontSize: "14px",
                                                fontWeight: "300",
                                              }}
                                            >
                                              {
                                                taskData?.projectPhase
                                                  ?.phaseName
                                              }
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="ms-1"
                                            style={{
                                              color: "blue !important",
                                              fontSize: "14px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            No project phase assigned
                                          </div>
                                        )}
                                      </div>
                                      <Tooltip id="status-prem-tooltip">
                                        You don't have permission.
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={RepeatIcon}
                                        alt="check-icon"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    Repeat
                                  </p>
                                </div>
                                <div className="">
                                  {canEdit ? (
                                    <DailySelectSection />
                                  ) : (
                                    <>
                                      <div
                                        id="status-prem-tooltip"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {taskData?.recurringType ? (
                                          <>
                                            <div
                                              className="ms-1"
                                              style={{
                                                color: "blue !important",
                                                fontSize: "14px",
                                                fontWeight: "300",
                                              }}
                                            >
                                              {taskData?.recurringType
                                                .charAt(0)
                                                .toUpperCase() +
                                                taskData.recurringType.slice(1)}
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="ms-1"
                                            style={{
                                              color: "blue !important",
                                              fontSize: "14px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            No recurring type assigned
                                          </div>
                                        )}
                                      </div>
                                      <Tooltip id="status-prem-tooltip">
                                        You don't have permission.
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-review-row task-detail-right-individual-section">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={CheckIcon}
                                        alt="check-icon"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    Check me out
                                  </p>
                                </div>
                                <div className="">
                                  {canEdit ? (
                                    <div
                                      className={`review-for-approval-wrap ${
                                        approval ? "review-approval" : ""
                                      }`}
                                    >
                                      <label className="d-flex align-items-center mb-0 control-task control-task-checkbox control-select-checkbox advanced-check-options">
                                        <input
                                          type="checkbox"
                                          checked={approval} // Checked state based on approval
                                          onChange={(e) => {
                                            setApproval(!approval);
                                            const data = {
                                              taskId: taskData?.id,
                                              fieldName: "hasApproval",
                                              fieldValues: !approval,
                                            };
                                            updateTask(data);
                                            e.target.blur();
                                            // Remove focus after clicking
                                          }}
                                        />
                                        <div className="control_task_indicator control-select-indicator"></div>
                                        <p className="mb-0">
                                          Review for approval
                                        </p>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className={`no-approval-edit ${
                                        !approval ? "ps-1" : ""
                                      }`}
                                    >
                                      <label
                                        className={`d-flex align-items-center mb-0 ${
                                          !approval ? "ps-0" : ""
                                        } control-task control-task-checkbox control-select-checkbox advanced-check-options}
                                    style={{
                                      cursor: "default", // Conditional cursor style
                                    }`}
                                      >
                                        {approval && (
                                          <>
                                            <input
                                              type="checkbox"
                                              checked={approval} // Checked state based on approval
                                              onChange={(e) => {
                                                return;
                                              }}
                                            />
                                            <div className="control_task_indicator control-select-indicator"></div>
                                          </>
                                        )}
                                        <p className="mb-0">
                                          {approval
                                            ? "Review for approval"
                                            : "Review approval option not available"}
                                        </p>
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date pb-0">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={CalenderIcon}
                                        alt="owner-icon"
                                        width={20}
                                        height={20}
                                        className="task-detail-owner-label-icon"
                                      />
                                    </span>
                                    Completion date
                                  </p>
                                </div>
                                <div className="more-options-label-date">
                                  {/* <ObserverList taskData={taskData} /> */}
                                  <p>
                                    {taskData?.taskCompletedDate
                                      ? dateFormatter(
                                          taskData?.taskCompletedDate,
                                          3
                                        )
                                      : "Not completed"}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date pb-0">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={CalenderIcon}
                                        alt="owner-icon"
                                        width={20}
                                        height={20}
                                        className="task-detail-owner-label-icon"
                                      />
                                    </span>
                                    Created date
                                  </p>
                                </div>
                                <div className="more-options-label-date">
                                  {/* <ObserverList taskData={taskData} /> */}
                                  <p>
                                    {taskData?.createdAt
                                      ? dateFormatter(taskData?.createdAt, 3)
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2 task-observer-row task-detail-right-individual-section task-detail-right-individual-section-date">
                                <div className="d-flex align-items-center">
                                  <p className="more-options-label">
                                    <span>
                                      <img
                                        src={CalenderIcon}
                                        alt="owner-icon"
                                        width={20}
                                        height={20}
                                        className="task-detail-owner-label-icon"
                                      />
                                    </span>
                                    Updated date
                                  </p>
                                </div>
                                <div className="more-options-label-date">
                                  {/* <ObserverList taskData={taskData} /> */}
                                  <p>
                                    {taskData?.updatedAt
                                      ? dateFormatter(taskData?.updatedAt, 3)
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6"></div> */}
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                )}
              </div>
            </>
          ) : (
            <p className="mt-4 mb-0">No task data available.</p>
          )}
        </div>
        {/* close icon */}
        <button
          className="btn-task-detail-concave"
          onClick={() => {
            if (!updateLoad) {
              if (description !== taskData?.taskDescription) {
                setShowCanvasCloseConfirmationModal(true);
              } else {
                handleCloseModal();
              }
            }
          }}
        >
          <img src={CloseBtn} alt="Close" />
        </button>
        <Modal
          className="file-viewer-modal"
          show={showModal}
          onHide={handleCloseFileModal}
          dialogClassName="modal-90w"
          data-theme={isDark}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedAttachment?.attachmentName &&
              selectedAttachment?.attachmentName.length > 15
                ? selectedAttachment?.attachmentName.substring(0, 15) + "..."
                : selectedAttachment?.attachmentName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedAttachment &&
            getFileType(selectedAttachment?.attachmentName) === "image" ? (
              <img
                src={selectedAttachment?.attachmentPath}
                alt="Attachment"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
              />
            ) : (
              <iframe
                src={selectedAttachment?.attachmentPath}
                title="Attachment Viewer"
                width="100%"
                height="500px"
              ></iframe>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="file-viewer-btn" onClick={handleCloseFileModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {showAttachmentModal && (
          <AttachmentsModal
            show={showAttachmentModal}
            handleCloseModal={() => setShowAttachmentModal(false)}
            files={files}
            isDark={isDark}
            canEdit={canEdit}
            handleThumbnailClick={handleThumbnailClick}
            handleDeleteFile={handleDeleteFile}
            handleDownloadDocFiles={handleDownloadDocFiles}
            handleFileClick={handleFileClick}
          />
        )}
        {updateTaskCanvas && (
          <UpdateTaskOffcanvas
            show={updateTaskCanvas}
            handleCloseModal={() => setUpdateTaskCanvas(false)}
            isDark={isDark}
            getBoardData={() => {}}
            taskId={selectedTask}
            taskList={[]}
            setTaskList={() => {}}
            getStatusCounts={getStatusCounts}
            updateFromTaskList={true}
            updateTaskData={updateTask}
            getTaskDetailAgain={true}
            getTaskData={getTaskData}
            listingType={listingType}
            pageParams={pageParams}
          />
        )}
        {showStatusModal && (
          <NoStatusAllowedModal
            show={showStatusModal}
            handleClose={handleClose}
            isDark={isDark}
          />
        )}
        {showFileViewer && (
          <LightboxMain
            selectedFile={selectedFile}
            handleCloseFileViewer={handleCloseFileViewer}
          />
        )}
        {/** close canvas confirmation modal */}
        {
          <ConfirmWithoutSaveCloseTaskModal
            isDark={isDark}
            isShow={showCanvasCloseConfirmationModal && !taskLoad && taskData}
            handleOnYes={() => {
              handleEditorBlur();
              setShowCanvasCloseConfirmationModal(false);
              handleCloseModal();
            }}
            handleOnNo={() => {
              setShowCanvasCloseConfirmationModal(false);
              handleCloseModal();
            }}
            taskData={taskData}
          />
        }
        {/** change completed task confirmation task modal */}
        <ConfirmChangeCompletedTaskModal
          isDark={isDark}
          taskData={taskData}
          isShow={isCompletedTaskChanged}
          handleOnYes={() => {
            setIsCompletedTaskChanged(false);
          }}
          handleOnNo={() => {
            setIsCompletedTaskChanged(false);
            setShowCanvasCloseConfirmationModal(false);
            handleCloseModal();
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const TaskDetailsTodoList = ({
  list,
  setList,
  isDark,
  updateTaskCheckList,
  deleteTaskCheckList,
  taskDetails,
  addTaskCheckList,
  canEdit,
  incrementCompleted,
  decrementCompleted,
  canEditGeneral,
  userProfile,
  canEditObserver,
  updateTaskCheckListAcknowledgement,
  setBulkTodoOpen,
  bulkTodoOpen,
  addCheckListBulk,
  addCheckListLoad,
}) => {
  const textRef = useRef();
  const onCheckedChange = async (id, checked, text, isAcknowledged, index) => {
    setList(
      list?.map((res, index) =>
        res.id === id
          ? {
              ...res,
              checklistStatus: checked ? 1 : 0,
              updatedBy: {
                userId: userProfile?.userId,
                userName: userProfile?.userName,
                userEmail: userProfile?.emailAddress,
                profileImage: userProfile?.profileImage,
                companySlug: userProfile?.companySlug,
                emailNotification: userProfile?.emailNotification,
                userTimeZone: userProfile?.timeZone,
              },
              updatedAt: new Date(),
            }
          : res
      )
    );
    if (taskDetails && taskDetails.id) {
      updateTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
        status: checked ? 1 : 0,
        description: text,
      });
    }
    if (checked) {
      incrementCompleted();
    } else {
      decrementCompleted();
      if (isAcknowledged) {
        await onItemAcknowledge(id, false, index);
      }
    }
  };
  const onTextChange = (id, text, ischecked, isBlur) => {
    if (text?.length > 0) {
      setList(
        list.map((res, index) =>
          res.id === id ? { ...res, taskDescription: text } : res
        )
      );
    }
    if (taskDetails && taskDetails.id && isBlur) {
      updateTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
        status: ischecked ? 1 : 0,
        description: text,
      });
    }
  };

  const onItemDelete = (id) => {
    setList(list.filter((res, index) => res.id !== id));
    if (taskDetails && taskDetails.id) {
      deleteTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
      });
    }
  };

  const onItemAcknowledge = async (id, status, index) => {
    setList(
      list.map((res, index) =>
        res.id === id
          ? {
              ...res,

              isAcknowledged: status,
            }
          : res
      )
    );
    if (taskDetails && taskDetails.id) {
      await updateTaskCheckListAcknowledgement({
        taskId: taskDetails.id,
        subTaskId: id,
        taskNumber: index + 1,
      });
    }
  };

  useEffect(() => {
    if (bulkTodoOpen) {
      textRef?.current?.focus();
    }
  }, [bulkTodoOpen]);

  return (
    <div className="d-flex flex-column task-checklist-container">
      {bulkTodoOpen ? (
        <div className="d-flex flex-column gap-3">
          <textarea
            ref={textRef}
            placeholder="Copy paste task list to add all your sub tasks"
            className="bulk-checklist-text-area"
            disabled={addCheckListLoad}
            rows={4}
          />
          <div className="w-100 d-flex align-items-center justify-content-end gap-2">
            <button
              onClick={async () => {
                const textValue = textRef.current.value; // Get the value of the textarea

                if (!textValue.trim()) {
                  // Show an alert or handle the error as needed
                  textRef?.current?.focus();
                  return;
                } else {
                  // Split text into an array of strings
                  const taskArray = textValue
                    .split(/\r?\n|\•/) // Split by line breaks (\n) or bullet points (•)
                    .map((item) => item.trim()) // Remove extra whitespace
                    .filter((item) => item !== ""); // Remove empty strings

                  await addCheckListBulk(
                    {
                      taskId: taskDetails?.id,
                      description: taskArray,
                    },
                    true
                  );
                }
              }}
              className="comment-field-submit-btn comment-field-post-button"
              disabled={addCheckListLoad}
            >
              Create{" "}
            </button>
            <button
              className="comment-field-cancel-btn comment-field-submit-btn"
              onClick={() => {
                setBulkTodoOpen(false);
              }}
              disabled={addCheckListLoad}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {list.map((res, index) => {
        return (
          <TaskDetailsTodoListItem
            index={index}
            text={res.taskDescription}
            checked={res.checklistStatus === 1 ? true : false}
            id={res.id}
            key={index}
            isDark={isDark}
            onCheckedChange={onCheckedChange}
            onTextChange={onTextChange}
            onItemDelete={onItemDelete}
            onItemAcknowledge={onItemAcknowledge}
            isAcknowledged={res?.isAcknowledged}
            addTaskCheckList={addTaskCheckList}
            canEdit={canEdit}
            updatedData={res?.updatedAt || new Date()}
            updateBy={res?.updatedBy?.userName}
            canEditGeneral={canEditGeneral}
            userProfile={userProfile}
            canEditObserver={canEditObserver}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsTodoListItem = ({
  index,
  text,
  checked,
  id,
  key,
  isDark,
  onCheckedChange,
  onTextChange,
  onItemDelete,
  addTaskCheckList,
  canEdit,
  updatedData,
  updateBy,
  canEditGeneral,
  userProfile,
  canEditObserver,
  onItemAcknowledge,
  isAcknowledged,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isEditable, setIsEditable] = useState(text ? false : true);
  const [inputValue, setInputValue] = useState(text);
  const [isNewlyChecked, setIsNewelyChecked] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      inputValue && inputValue.length > 0 && setIsEditable(false);
      onTextChange(id, inputValue, isChecked, true);
      if (inputValue && inputValue.length > 0) {
        addTaskCheckList();
      }
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
    onTextChange(id, event.target.value, isChecked, false);
  };
  const handleBlur = () => {
    inputValue && inputValue.length > 0 && setIsEditable(false);
    onTextChange(id, inputValue, isChecked, true);
    if (inputValue && inputValue.length > 0) {
    }
  };

  return (
    <div
      data-theme={isDark}
      key={key}
      className={`position-relative d-flex align-item-center gap-2 task-details-todo-list-item`}
    >
      <div
        className={`${
          isChecked
            ? "task-details-todo-list-item-index-checked"
            : "task-details-todo-list-item-index"
        }`}
      >
        {index + 1}
      </div>
      <div
        className={`task-details-check-list-radio-button`}
        onClick={(e) => {
          e.stopPropagation(); // Prevents the click event from bubbling up
          if (canEdit) {
            setIsChecked(!isChecked);
            setIsNewelyChecked(true);
            onCheckedChange(id, !isChecked, text, isAcknowledged, index);
          }
        }}
        style={{ cursor: canEdit ? "pointer" : "default" }}
      >
        <input
          type="checkbox"
          name="optionsRadios"
          id="optionsRadios2"
          value="option2"
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
        />
        <label style={{ cursor: canEdit ? "pointer" : "default" }}></label>
      </div>
      {!isEditable ? (
        <div className="d-flex align-items-center w-100 task-details-todo-list-item-text-wrap">
          <p
            onClick={() => {
              if (canEditGeneral) {
                setIsEditable(true);
              } else {
                if (canEdit) {
                  setIsChecked(!isChecked);
                  onCheckedChange(id, !isChecked, text, isAcknowledged);
                }
              }
            }}
            className={`ps-4 mb-0 pb-0 task-details-todo-list-item-text w-100 ${
              isChecked ? "text-decoration-line-through" : ""
            }`}
          >
            {text}
          </p>
          {canEditGeneral && (
            <img
              onClick={() => onItemDelete(id)}
              src={Delete}
              alt="Delete"
              className={`task-details-todo-list-item-delete-icon ${
                (canEditObserver || canEditGeneral) && isChecked ? "me-3" : ""
              }`}
            />
          )}
          <div
            className={`acknowledged-icon ${
              (canEditObserver || canEditGeneral) && isChecked
                ? "acknowledged-editable-icon"
                : ""
            } ${
              isAcknowledged
                ? "acknowledged-is-icon"
                : (canEditObserver || canEditGeneral) && isChecked
                ? "acknowledged-is-not-icon"
                : "acknowledged-is-not-icon-assignee"
            }`}
            onClick={() => {
              if ((canEditObserver || canEditGeneral) && isChecked) {
                onItemAcknowledge(id, !isAcknowledged, index);
              }
            }}
            data-tooltip-content={
              isAcknowledged ? "Acknowledged task" : "Acknowledge task"
            } // Tooltip content
            data-tooltip-id={`acknowledge-tooltip-${id}`}
          >
            <Icon icon="solar:check-read-outline" />
          </div>
          <Tooltip
            id={`acknowledge-tooltip-${id}`}
            place="top"
            effect="solid"
          />
        </div>
      ) : (
        <div className="ps-3 ms-2 task-details-todo-list-item-input-container w-100">
          <input
            type="text"
            placeholder="Add a task"
            className="form-control w-100 p-0"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={inputValue}
            onBlur={handleBlur}
            autoFocus
            autoComplete="off"
          />
        </div>
      )}
      {isChecked && (
        <div className="d-flex align-items-center position-absolute checked-user-info">
          <p className="m-0">
            {isNewlyChecked ? userProfile?.userName : updateBy} -{" "}
            {isNewlyChecked
              ? dateFormatter(new Date(), 4)
              : dateFormatter(updatedData, 4)}
          </p>
        </div>
      )}
    </div>
  );
};

export default TaskDetailOffCanvas;
