import React from "react";
import MyGoal from "./components/MyGoal";

const MyGoalMain = (props) => {
  return (
    <MyGoal
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default MyGoalMain;
