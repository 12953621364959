import React from "react";
import Recruiter from "./components/Recruiter";

const RecruiterMain = (props) => {
  return (
    <Recruiter
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default RecruiterMain;
