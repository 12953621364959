import React from "react";
import Projects from "./components/Projects";

const ProjectsMain = (props) => {
  return (
    <Projects
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
      setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
    />
  );
};

export default ProjectsMain;
