import React from "react";
import { Offcanvas } from "react-bootstrap";
import AddBtn from "../../assets/icon-close.svg";
import FAQMain from "./FAQMain";
import "../styles/faqModal.scss";

function FAQModalOffset({ props, show, setShow }) {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Offcanvas
        className="off-concave-one notification-canvas"
        show={show}
        // onHide={handleClose}
        placement="end"
        data-theme={props.isDark}
      >
        <Offcanvas.Body className="">
          {/* heading */}
          <h2 className="faq-model-faq-title">
            <strong>Frequently</strong> Asked Question
          </h2>
          {/* Main Notification part  */}
          <FAQMain props={props} />
          {/* close btn */}
          <button className="btn-role-concave" onClick={handleClose}>
            <img src={AddBtn} alt="close-button" />
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default FAQModalOffset;
