import React from "react";
import UserProfileMain from "./components/UserProfileMain";

const UserProfile = (props) => {
  return (
    <UserProfileMain isDark={props.isDark} getOptions={props?.getOptions} />
  );
};

export default UserProfile;
