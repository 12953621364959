/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/search-icon.png";
import MobileUsersItemsForFilter from "./MobileUsersItemsForFilter";

const MobileMemberFilter = ({
  projectUserList,
  setData,
  data,
  projectTypeList,
  setServerFilters,
  serverFilters,
  userSearchList,
  setUserSearchList,
  inputRef,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setUserSearchList(projectUserList);
    setSearch("");
  };

  const handleTabClick = (tabName) => {
    const foundType = projectTypeList?.find(
      (item) => item.typeName === tabName
    );
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the component mounts or when the tab changes
    }
    // Check if the tab already exists in the currentTabs array
    setServerFilters((prev) => ({
      ...prev,
      memberFilter: foundType?.typeName.toLowerCase(),
    }));
  };

  useEffect(() => {
    const adjustDropdownHeight = () => {
      const mainContainer = document.querySelector(".task-user-filter-dropdown");
      const mainDropdown = document.querySelector(".user-list-main-container");

      if (mainContainer && mainDropdown) {
        // Get the bounding rectangle of the dropdown
        const dropdownRect = mainDropdown.getBoundingClientRect();

        // Calculate the available height based on viewport and dropdown's position
        const availableHeight = window.innerHeight - dropdownRect.top - 20; // Adjust 20px for spacing

        // Calculate the maximum height, ensuring it doesn't exceed the available space
        const maxHeight = Math.min(availableHeight, mainDropdown.scrollHeight);

        // Set the max-height on the dropdown
        mainDropdown.style.maxHeight = `${maxHeight}px`;
      }
    };

    // Delay the execution by 2 seconds
    const timeoutId = setTimeout(() => {
      requestAnimationFrame(adjustDropdownHeight);
    }, 1000);

    // Clean-up function
    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts
      const mainDropdown = document.querySelector(".user-list-main-container");
      if (mainDropdown) {
        mainDropdown.style.maxHeight = ""; // Reset the max-height on unmount
      }
    };
  }, []);



  return (
    <div className="d-flex flex-column task-user-filter-dropdown-menu custom-vertical-scrollbar">
      <div className="task-user-filter-dropdown-tab-bar-mobile pb-0">
        <h3 className="mb-3">Member</h3>
        <div className="d-flex align-items-center justify-content-between gap-2 mobile-filter-user-type-button-wrap">
          <button
            className={`w-100 custom-user-type-text-anchor ${serverFilters?.memberFilter === "member"
                ? "active-user-type-mobile"
                : ""
              }`}
            onClick={() => handleTabClick("member")}
          >
            Assigned To
          </button>

          <button
            className={`w-100 custom-user-type-text-anchor ${serverFilters?.memberFilter === "owner"
                ? "active-user-type-mobile"
                : ""
              }`}
            onClick={() => handleTabClick("owner")}
          >
            Task Owner
          </button>

          <button
            className={`w-100 custom-user-type-text-anchor ${serverFilters?.memberFilter === "observer"
                ? "active-user-type-mobile"
                : ""
              }`}
            onClick={() => handleTabClick("observer")}
          >
            Task Observer
          </button>
        </div>
      </div>
      <div className="w-100 d-flex flex-column custom-user-selection-main-container-mobile">
        <div className="listing-team-modal-search-input-wrap position-relative">
          <input
            ref={inputRef}
            type="text"
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            autoFocus={true}
            autoComplete="off"
            className="custom-user-search"
          />
          {search ? (
            <span className="custom-user-search-icon" onClick={clearSearch}>
              &#x2715;
            </span>
          ) : (
            <img
              src={SearchIcon}
              className="custom-user-search-icon"
              alt="search-icon"
            />
          )}
        </div>
        <div
          ref={userListRef}
          className="h-100 d-flex flex-column gap-2 user-list-main-container custom-vertical-scrollbar"
        >
          {userSearchList?.length === 0 && (
            <p className="p-0">No member found</p>
          )}
          {userSearchList?.map((res, index) => (
            <MobileUsersItemsForFilter
              setData={setData}
              data={data}
              res={res}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
              index={index}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMemberFilter;
