import React from "react";
import "./styles/subscriptions.css";
import SubscriptionsMain from "./components/SubscriptionsMain";

const Subscriptions = (props) => {
  return (
    <SubscriptionsMain isDark={props.isDark} getOptions={props.getOptions} />
  );
};

export default Subscriptions;
