/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import ClickAwayListener from "react-click-away-listener";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { ListOfTeamMemberTooltip } from "./utils/data";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInTaskOne } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import { sortCheckedMembersOnTop } from "../../../helper/sortCheckedMembersOnTop";
import { useClearAllMemberTaskCacheMutation } from "../../../slices/tasks/tasksSlice";
import { toast } from "react-toastify";
import { useUpdateCompletedTaskMemberDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SearchIcon from "../../../assets/search-icon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TaskSelectedOwnerSection({
  isDark,
  projectUserList,
  projectTypes,
  userId,
  userProfile,
  taskData,
  setTaskData,
  setUpdateLoad,
  pageParams,
  listingType,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
}) {
  // Type id based on stypeName
  const getTypeID = (typeName) => {
    const typeObj = projectTypes?.find((type) => type.typeName === typeName);
    return typeObj ? typeObj.id : null;
  };
  const [showFilterDropDown, setshowFilterDropDown] = useState(false);
  const [currentTab, setCurrentTab] = useState(2);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const [autoFocus, setAutoFocus] = useState(true);

  const userListRef = useRef(null);

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return moduleCategory?.profileImage ? (
      // <OverlayTrigger
      //   placement="top"
      //   overlay={
      //     <Tooltip id="button-tooltip">{moduleCategory.userName}</Tooltip>
      //   }
      // >
      <div
        key={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative filter-top-user-info-wrap"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        <img
          className="rounded-circle w-100 h-100"
          src={moduleCategory?.profileImage}
          alt="profile-img"
        />
        {showTooltip && (
          <ListOfTeamMemberTooltip
            memberName={moduleCategory.userName}
            position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
          />
        )}
      </div>
    ) : (
      // </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="button-tooltip">{moduleCategory.userName}</Tooltip>
        }
      >
        <div
          key={id}
          style={{
            border: "1px solid " + colors[id % colors.length],
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="position-relative filter-top-user-info-wrap"
          data-tooltip-id={`my-tooltip-${uniqueId}`}
        >
          {moduleCategory?.userName?.charAt(0).toUpperCase() +
            moduleCategory?.userName?.charAt(1).toUpperCase()}
          {/* {showTooltip && (
            <ListOfTeamMemberTooltip
              memberName={moduleCategory?.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          )} */}
        </div>
      </OverlayTrigger>
    );
  };

  const AdditionalUserCircle = ({ colors, moduleCategory }) => {
    return (
      <div
        style={{
          border: "1px solid " + colors[0],
        }}
        className="position-relative filter-top-user-info-wrap"
      >
        {moduleCategory?.charAt(0).toUpperCase() +
          moduleCategory?.charAt(1).toUpperCase()}
      </div>
    );
  };

  // Helper function to get userName based on userId
  const getUserNameById = (userId) => {
    const user = projectUserList?.find((user) => user.userId === userId);
    return user ? user : ""; // Return userName if found, otherwise return an empty string
  };

  // Modal search functionality
  const [search, setSearch] = useState("");
  const [userSearchList, setUserSearchList] = useState(projectUserList);
  const dropdownRef = useRef();

  const handleSearch = (event) => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  // Handle clearing the search input
  const clearSearch = () => {
    const filteredList = projectUserList?.filter((user) =>
      user.userName.toLowerCase().includes("".toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch("");
  };

  const handleKeyDown = (event) => {
    console.log("handleKeyDown", "function executed");

    if (!showFilterDropDown) return;

    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < userSearchList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : userSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item
      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown
      setshowFilterDropDown(false);
      event.preventDefault();
    }
  };

  useEffect(() => {
    console.log("useEffect", "focusedIndex", focusedIndex);
    console.log("userListRef", userListRef.current);

    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  const sectionRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowFilterDropDown(false); // Close the dropdown
        clearSearch();
      } else if (
        sectionRef.current &&
        sectionRef.current.contains(event.target)
      ) {
        if (
          (isPermitted("task/update") && isOwnerInTaskOne(taskData, userId)) ||
          isAdmin(userProfile)
        ) {
          setshowFilterDropDown((prev) => !prev);

          clearSearch();
        }
      }
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Focus the input after delay
        }
      }, 100); // 1-second delay
      return () => clearTimeout(timer);
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setshowFilterDropDown]);

  return (
    <section
      onKeyDown={handleKeyDown}
      className={`position-relative ${
        taskData?.taskMembers?.filter((res) => res.memberType === "owner")
          ?.length > 0
          ? "filter-top-user-section"
          : ""
      }`}
    >
      <div ref={sectionRef}>
        {taskData &&
        taskData?.taskMembers?.filter((res) => res.memberType === "owner")
          ?.length > 0 ? (
          <div
            data-tooltip-id="members-lap-perm-tlt"
            className="teams-member-listing-wap task-detail-member-listing-wrap"
            // onClick={() => {
            //   if (
            //     (isPermitted("task/update") &&
            //       isOwnerInTaskOne(taskData, userId)) ||
            //     isAdmin(userProfile)
            //   ) {
            //     setshowFilterDropDown(!showFilterDropDown);
            //   }
            // }}
          >
            <ul>
              {[
                ...new Set(
                  taskData?.taskMembers
                    ?.filter((res) => res.memberType === "owner")
                    ?.map((res) => res)
                ),
              ].map((user, id) => {
                const profile = user;
                return (
                  <li
                    key={id}
                    className="d-flex align-items-center gap-2 w-100 justify-content-start"
                  >
                    <UserImageCircle
                      colors={colors}
                      moduleCategory={profile}
                      id={id}
                    />
                    <span className="task-detail-owner-name">
                      {profile?.userName}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div
            data-tooltip-id="members-select-lap-perm-tlt"
            className="tasks-main-info-list-font-weight me-3 cursor-pointer"
            // onClick={() => {
            //   if (
            //     (isPermitted("task/update") &&
            //       isOwnerInTaskOne(taskData, userId)) ||
            //     isAdmin(userProfile)
            //   ) {
            //     setshowFilterDropDown(!showFilterDropDown);
            //   }
            // }}
          >
            {(isPermitted("task/update") &&
              isOwnerInTaskOne(taskData, userId)) ||
            isAdmin(userProfile) ? (
              <div className="add-more-observer-btn">
                <Icon icon="ri:user-add-line" fontSize={18} />
              </div>
            ) : (
              <p className="ms-1 mb-0" style={{ fontSize: "14px" }}>
                Observer not assigned
              </p>
            )}
            {!(
              (isPermitted("task/update") &&
                isOwnerInTaskOne(taskData, userId)) ||
              isAdmin(userProfile)
            ) && (
              <ReactTooltip
                id={`members-select-lap-perm-tlt`}
                place="top"
                content={() => (
                  <div>You don't have permission to update this field.</div>
                )}
              />
            )}
          </div>
        )}
      </div>
      {showFilterDropDown && (
        <div
          className="position-absolute rounded-3 shadow-lg task-table-user-filter task-user-filter-dropdown"
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-0 w-100 h-100 d-flex custom-user-selection-main-container">
            <div className="w-100 d-flex flex-column custom-user-selection-main-container">
              <div className="listing-team-modal-search-input-wrap position-relative">
                <input
                  ref={inputRef}
                  type="text"
                  name="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search members"
                  autoComplete="off"
                  autoFocus={true}
                />
                {search ? (
                  <span
                    className="custom-user-search-icon"
                    onClick={clearSearch}
                  >
                    &#x2715;
                  </span>
                ) : (
                  <img
                    src={SearchIcon}
                    className="custom-user-search-icon"
                    alt="search-icon"
                    width={20}
                    height={20}
                  />
                )}
              </div>
              <TaskUsersListForFilter
                isDark={isDark}
                projectUserList={userSearchList}
                setData={setTaskData}
                data={taskData}
                getTypeID={getTypeID}
                setUpdateLoad={setUpdateLoad}
                currentTab={
                  currentTab === 1
                    ? "observer"
                    : currentTab === 2
                    ? "owner"
                    : "member"
                }
                pageParams={pageParams}
                listingType={listingType}
                kanban={kanban}
                getTaskDataAfterUpdate={getTaskDataAfterUpdate}
                updateTaskMemberData={updateTaskMemberData}
                updatePhaseTaskMemberData={updatePhaseTaskMemberData}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setshowFilterDropDown={setshowFilterDropDown}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

const TaskUsersListForFilter = ({
  projectUserList,
  setData,
  data,
  currentTab,
  getTypeID,
  setUpdateLoad,
  pageParams,
  listingType,
  kanban,
  getTaskDataAfterUpdate,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  setIsClicked,
  isClicked,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  setshowFilterDropDown
}) => {
  const ownerCount = data?.taskMembers?.filter(
    (member) => member.memberType === "owner"
  ).length;
  const [newMembers, setNewMembers] = useState(
    sortCheckedMembersOnTop(
      projectUserList,
      data?.taskMembers,
      currentTab === "observer" ? 1 : currentTab === "owner" ? 2 : 3
    )
  );

  useEffect(() => {
    setNewMembers(
      sortCheckedMembersOnTop(
        projectUserList,
        data?.taskMembers,
        currentTab === "observer" ? 1 : currentTab === "owner" ? 2 : 3
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUserList]);

  return (
    <div
      ref={userListRef}
      className="task-user-filter-dropdown-menu task-detail-user-dropdown custom-vertical-scrollbar my-2"
    >
      {newMembers?.map((res, index) => (
        <TaskUsersListItemForFilter
          setData={setData}
          data={data}
          selectedUsers={data?.taskMembers}
          res={res}
          currentTab={currentTab}
          getTypeID={getTypeID}
          ownerCount={ownerCount}
          setUpdateLoad={setUpdateLoad}
          pageParams={pageParams}
          listingType={listingType}
          kanban={kanban}
          getTaskDataAfterUpdate={getTaskDataAfterUpdate}
          updateTaskMemberData={updateTaskMemberData}
          updatePhaseTaskMemberData={updatePhaseTaskMemberData}
          index={index}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          setIsClicked={setIsClicked}
          isClicked={isClicked}
          setshowFilterDropDown={setshowFilterDropDown}
        />
      ))}
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  setData,
  data,
  res,
  currentTab,
  getTypeID,
  ownerCount,
  selectedUsers,
  listingType,
  pageParams,
  getTaskDataAfterUpdate,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
  setshowFilterDropDown
}) => {
  const [updateCompletedTaskMemberData] =
    useUpdateCompletedTaskMemberDataMutation();
  const [clearAllMemberTaskCache] = useClearAllMemberTaskCacheMutation();

  const [isChecked, setIsChecked] = useState(false);
  let userId = res?.userId;

  useEffect(() => {
    const isUserSelected = selectedUsers?.some(
      (member) => member?.userId === userId && member?.memberType === currentTab
    );
    setIsChecked(isUserSelected);
  }, [selectedUsers, currentTab, userId, res]);

  const handleCheckboxClick = async () => {
    // e.preventDefault();
    // e.stopPropagation();

    if (ownerCount < 2 && currentTab === "owner" && isChecked) {
      toast.info(
        "There will always be at least one owner associated with tasks.",
        { position: toast.POSITION.TOP_CENTER }
      );
    } else {
      try {
        setshowFilterDropDown(false);
        let updatedTeamMembers;
        setIsChecked(!isChecked);

        updatedTeamMembers = isChecked
          ? data?.taskMembers.filter(
              (member) =>
                !(
                  member?.userId === userId && member?.memberType === currentTab
                )
            )
          : [
              ...data?.taskMembers,
              {
                userId: userId,
                memberType: currentTab,
                userName: res?.userName,
                profileImage: res?.profileImage,
              },
            ];
        if (currentTab === "owner" || currentTab === "member") {
          const seenUserIds = new Set();

          updatedTeamMembers = updatedTeamMembers?.filter((member) => {
            // Only consider members of the current memberType
            if (member.memberType === currentTab) {
              // Check if the userId matches the main userId
              if (member.userId === userId) {
                // If this userId has not been seen, keep this member
                if (!seenUserIds.has(member.userId)) {
                  seenUserIds.add(member.userId);
                  return true; // Keep this member
                }
                return false; // Filter out duplicates for the same memberType
              }
              return false; // Filter out if userId does not match the main userId
            }
            return true; // Keep members of other memberTypes
          });
        }
        setData({
          ...data,
          taskMembers: updatedTeamMembers,
        });
        // updateRowParameterById(data?.id, "taskMembers", updatedTeamMembers);
        const updatedData = {
          taskId: data?.id,
          taskMembers: convertToObject(updatedTeamMembers),
        };

        let response;
        if (listingType === "COMPLETED_TASKS") {
          response = await updateCompletedTaskMemberData({
            ...pageParams,
            ...updatedData,
          });
        } else if (listingType === "ALL_TASKS") {
          response = await updateTaskMemberData({
            ...pageParams,
            ...updatedData,
          });
        } else if (listingType === "PHASE_TASKS") {
          response = await updatePhaseTaskMemberData(updatedData);
        } else if (listingType === "NOTIFICATION_LIST") {
          await clearAllMemberTaskCache(updatedData);
        }

        if (response?.data?.status === "success") {
          getTaskDataAfterUpdate();

          // toast.success("Task updated successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 300,
          //   hideProgressBar: true,
          //   closeButton: false,
          // });
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
      }
    }
  };

  const convertToObject = (data) => {
    return data?.map((item) => {
      return {
        [getTypeID(item.memberType)]: item.userId,
      };
    });
  };

  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      const isUserSelected = selectedUsers?.some(
        (member) =>
          member?.userId === userId && member?.memberType === currentTab
      );
      setIsChecked(isUserSelected);
      handleCheckboxClick();
      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className="py-1 w-100 border-bottom-1 task-user-filter-dropdown-menu-item"
    >
      <div
        className="checkbox create-task-checkbox"
        onClick={(e) => handleCheckboxClick(e)}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCheckboxClick(e);
          }}
        />
        <label
          className="task-user-filter-dropdown-menu-item-label"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCheckboxClick(e);
          }}
        >
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span className="ps-1">{res?.userName}</span>
        </label>
      </div>
    </div>
  );
};

export default TaskSelectedOwnerSection;
