import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";

const DeleteSelectedProjectsModal = (props) => {
  // Delete projects
  const deleteProject = async () => {
    props.handleConfirmDelete();
    props.handleCloseDeleteModal();
  };

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>

        <p className="del-modal-name">
          selected{" "}
          {props.selectedProjects?.length === 1 ? "Project" : "projects"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={deleteProject}>
            Remove
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSelectedProjectsModal;
