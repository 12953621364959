import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import API from "./../../../services/ClientApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { findObjectByContentTitle } from "../../../helper/getContentObject";

const DeleteRoleModal = (props) => {
  const [dload, setDLoad] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);

  const verifyModalContent = findObjectByContentTitle(
    "delete-role",
    modalContents?.data
  );

  const handleSubmit = async () => {
    try {
      setDLoad(true);
      const response = await API.post("company/role-delete", {
        roleId: props.roleName.id,
      });
      if (response?.data?.status === "success") {
        props.getRolesAfterUpdate();
        toast.success("Role deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        props.handleCloseDeleteModal();

        props.setIsDeletedRow(true);
      }
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };
  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} />
        </div>
        {props?.roleName?.members > 0 ? (
          <>
            <h5>
              There {props?.roleName?.members > 1 ? "are" : "is"}{" "}
              {props?.roleName?.members}{" "}
              {props?.roleName?.members > 1 ? "members" : "member"} Associated
              with this role. Are you sure you want to remove
            </h5>
          </>
        ) : (
          <>
            {" "}
            <h5>{verifyModalContent?.short_description}</h5>
            <h5>{verifyModalContent?.long_description}</h5>
          </>
        )}

        <p className="del-modal-name">{props.roleName.name}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={handleSubmit}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteRoleModal;
