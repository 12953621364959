/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useRef } from "react";
import API from "../../../services/ClientApi";
import Caret from "../../../assets/caret-table-icon.svg";
import Role from "../../../assets/role-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import { Tooltip as ReactTooltip } from "react-tooltip";
import DeleteUserRoleModal from "./DeleteUserRoleModal";
import Dropdown from "react-bootstrap/Dropdown";
import Delete from "../../../assets/delete.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import AddBtn from "../../../assets/icon-close.svg";
import { dateFormatter } from "../../../helper/dateFormatter";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import { AgGridReact } from "ag-grid-react";
import { createPortal } from "react-dom";
import { ShimmerCategoryItem, ShimmerTable } from "react-shimmer-effects";
import {
  useGetCompanyRolesQuery,
  useGetUserRolesMutation,
} from "../../../slices/user/userSlice";
import { useSelector } from "react-redux";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};
const UserPermissions = (props) => {
  console.log("Props : ", props)
  const [roles, setRoles] = useState([]);
  const { data: companyRoles } = useGetCompanyRolesQuery();
  const { userProfile } = useSelector((state) => state?.profile);
  const [getUserRoles, { data: userRoles }] = useGetUserRolesMutation();

  const [load, setLoad] = useState(false);
  const [idR, setIdR] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleName, setRoleName] = useState({ name: "", id: "" });
  const [roleId, setRoleId] = useState(0);
  const [pload, setPLoad] = useState(false);
  const [checkedModules, setCheckedModules] = useState([]);
  const [privateId, setPrivate] = useState([]);
  const [publicId, setPublic] = useState([]);
  const [roleCaetgories, setRoleCategories] = useState([]);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [permissionRoles, setPermissionRoles] = useState([]);
  const [roleType, setRoleType] = useState(null);

  // AG-Grid library
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const columns = [
    {
      field: "roleName",
      headerName: "Role",
      maxWidth: 180,
      cellStyle: (params) => {
        return {
          cursor: "pointer",
        };
      },
      cellRenderer: (params) => {
        return capitalizeFirstLetter(params?.data?.roleName);
      },
    },
    {
      field: "createdDate",
      headerName: "Created On",
      maxWidth: 150,
      editable: false,
      cellRenderer: (params) => {
        return dateFormatter(params?.data?.createdDate);
      },
    },
    {
      field: "createdDate",
      headerName: "Last Updated",
      editable: false,
      maxWidth: 150,
      cellClassName: "ellipsis-cell",
      cellRenderer: (params) => {
        return dateFormatter(params?.data?.createdDate);
      },
    },
    {
      field: "moduleCategories",
      headerName: "Modules",
      editable: false,
      flex: 2,
      cellEditorPopup: true,
      cellRenderer: (params) => {
        return (
          <div className="permission-module-listing-wap w-100 d-flex align-items-center justify-content-between">
            <ModulesCategoryList params={params} colors={colors} />
            <div className="d-flex align-items-center justify-content-between role-type-table-wrap">
              {params?.data?.roleType !== "admin" && (
                <Dropdown className="roles-drop-down roles-drop-role">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <button className="action-btn">
                      <img src={Caret} alt="caret-icon" />
                    </button>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation();
                        props.setShow(false);
                        setRoleId(params?.data?.roleId);
                        setShowDeleteModal(true);
                        props.setShow(false);
                        handleCloseModal();
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="delete-icon"
                      />
                      Delete role
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const handleCloseModal = () => {
    props.setShow(false);
  };
  const handleShowModal = () => {
    props.setShow(true);
  };

  const handleArrowClick = (roleName) => {
    setIdR((prevRoleNames) => {
      if (prevRoleNames?.includes(roleName)) {
        // RoleName exists, remove it
        return prevRoleNames?.filter((name) => name !== roleName);
      } else {
        // RoleName does not exist, add it
        return [...prevRoleNames, roleName];
      }
    });
  };

  const handlePrivatePublic = async (rId, mId, action, iid, accType) => {
    if (accType === "PUBLIC") {
      const updatedIds = privateId.includes(iid)
        ? privateId.filter((id) => id !== iid) // Remove iid if it exists
        : [...privateId, iid];
      setPrivate(updatedIds);
      const updatedPrivate = [...publicId, iid];
      setPublic(updatedPrivate);
    } else {
      const updatedIds = publicId.includes(iid)
        ? publicId.filter((id) => id !== iid) // Remove iid if it exists
        : [...publicId, iid];
      setPublic(updatedIds);
      const updatedPrivate = [...privateId, iid];
      setPrivate(updatedPrivate);
    }

    const updatedRoleCategories = roleCaetgories.map((roleCategory) => {
      // Find the target module in the modules array
      const updatedModules = roleCategory.modules.map((module) => {
        if (module.moduleId === mId) {
          // Update the isChecked property based on the action
          module.accessType = accType;
        }
        return module;
      });

      // Return the updated roleCategory object
      return {
        ...roleCategory,
        modules: updatedModules,
      };
    });

    // Update the state with the modified roleCategories array
    setRoleCategories(updatedRoleCategories);

    try {
      const responseU = await API.post("users/update-user-permissions", {
        roleId: rId,
        moduleId: mId,
        actionType: action,
        userId: props?.user?.userInfo?.userId,
        accessType: accType,
      });
      props?.getOptions();
      const permissions = await API.get("users/permissions-urls");
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions?.data?.data)
      );
      // getPermissions(roleId, false);
    } catch (error) {
    } finally {
    }
  };

  const handleUpdateRole = async (rId, mId, action, iid, accType) => {
    const updatedIds = checkedModules.includes(iid)
      ? checkedModules.filter((id) => id !== iid) // Remove iid if it exists
      : [...checkedModules, iid];
    setCheckedModules(updatedIds);
    if (action === "add") {
      const newPublic = [...publicId, iid];
      setPublic(newPublic);
    } else {
      const updatedIds = publicId.includes(iid)
        ? publicId.filter((id) => id !== iid) // Remove iid if it exists
        : [...publicId, iid];
      setPublic(updatedIds);
    }
    // Clone the roleCategories array to avoid direct mutation
    const updatedRoleCategories = roleCaetgories.map((roleCategory) => {
      // Find the target module in the modules array
      const updatedModules = roleCategory.modules.map((module) => {
        if (module.moduleId === mId) {
          // Update the isChecked property based on the action
          module.isChecked = action === "add";
        }
        return module;
      });

      // Return the updated roleCategory object
      return {
        ...roleCategory,
        modules: updatedModules,
      };
    });

    // Update the state with the modified roleCategories array
    setRoleCategories(updatedRoleCategories);
    try {
      const responseU = await API.post("users/update-user-permissions", {
        roleId: rId,
        moduleId: mId,
        actionType: action,
        userId: props?.user?.userInfo?.userId,
        accessType: accType ? accType : "PUBLIC",
      });
      props?.getOptions();
      const permissions = await API.get("users/permissions-urls");
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions?.data?.data)
      );
      // getPermissions(roleId, false);
    } catch (error) {
    } finally {
    }
  };

  const getPermissionsAfterDelete = async () => {
    try {
      const response = await API.post("users/permissions", {
        userId: props.userId,
      });
      if (response?.data?.status === "success") {
        const modifiedData = response?.data?.data.map((role) => ({
          ...role,
          rolesCategories: role?.rolesCategories.map((category) => ({
            ...category,
            modules: category?.modules.map((module) => {
              const uid = uuidv4();
              if (module?.isChecked) {
                // If isChecked is true, store the uid in the state
                props.setCheckedModules((prevCheckedModules) => [
                  ...prevCheckedModules,
                  uid,
                ]);
              }
              return {
                ...module,
                uid: uid,
              };
            }),
          })),
        }));
      }
    } catch (error) {
    } finally {
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const getPermissions = async (id, load) => {
    try {
      if (load) {
        setPLoad(true);
      }
      const response = await API.post("users/permissions", {
        userId: props?.user?.userInfo?.userId,
        roleId: id,
      });

      setRoleType(response?.data?.data?.roleType);
      const rolesCategoriesNew = response?.data?.data?.rolesCategories?.map(
        (category) => ({
          ...category,
          modules: category?.modules.map((module) => {
            const uid = uuidv4();
            if (module?.isChecked) {
              // If isChecked is true, store the uid in the state
              setCheckedModules((prevCheckedModules) => [
                ...prevCheckedModules,
                uid,
              ]);
            }
            if (module?.isChecked && module?.accessType === "PUBLIC") {
              setPublic((prevCheckedModules) => [...prevCheckedModules, uid]);
            } else if (module?.isChecked && module?.accessType === "PRIVATE") {
              setPrivate((prevCheckedModules) => [...prevCheckedModules, uid]);
            }
            return {
              ...module,
              uid: uid,
            };
          }),
        })
      );
      setRoleCategories(rolesCategoriesNew);
    } catch (error) {
    } finally {
      setPLoad(false);
    }
  };

  const handleRowClick = async (params) => {
    handleShowModal();
    setRoleId(params?.data?.roleId);
    getPermissions(params?.data?.roleId, true);
  };

  let clickTimer;

  const handleCellClick = (params) => {
    const target = params.event.target;
    // Check if the clicked element is part of the dropdown button
    const isDropdownButton = target.closest(".roles-drop-down");
    // If it's a dropdown button click, return without further action
    if (isDropdownButton) {
      return;
    }
    // Check if the clicked cell is part of the "roleName" column
    if (params.colDef.field === "roleName") {
      // Use a timer to differentiate between single click and double click
      if (!clickTimer) {
        // Single click
        clickTimer = setTimeout(() => {
          clearTimeout(clickTimer);
          clickTimer = null;

          // Execute single-click action (if needed)
          handleRowClick(params);
          // For example: handleRowClick(params);
        }, 200); // Adjust the time interval as needed
      } else {
        // Double click
        clearTimeout(clickTimer);
        clickTimer = null;
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.colDef.field,
        });
        // Execute double-click action
      }
    } else if (params.colDef.field !== "updatedDate") {
      handleRowClick(params);
    }
  };

  useEffect(() => {
    const getAllRoles = async () => {
      setLoad(true);
      try {
        if (props.user) {
          await getUserRoles({
            userId: props.user?.userInfo?.userId,
          });
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getAllRoles();
  }, [props.user]);

  useEffect(() => {
    if (companyRoles) {
      setRoles(companyRoles?.data);
    }
  }, [companyRoles]);

  useEffect(() => {
    if (userRoles) {
      setPermissionRoles(userRoles?.data);
      setRoleId(userRoles?.data[0]?.roleId);
      getPermissions(userRoles?.data[0]?.roleId, true);
    }
  }, [userRoles]);

  const gridOptions = {
    domLayout: "autoHeight", // Avoid clipping if overflow is used
  };

  return (
    <div className="listing-info-profile-wrap listing-permissions-wrap">
      <div className="w-100">
        <div className="w-100 d-flex justify-content-between user-permission-wrap">
          <h4> Roles </h4>
        </div>
        <div className="table-role-permission-wraper">
          <div className="table-responsive">
            {load ? (
              <div className="ms-1 w-75 position-relative">
                <ShimmerTable row={3} />
              </div>
            ) : (
              <div
                className="ag-theme-alpine custom-horizental-scrollbar"
                style={{
                  width: "100%",
                  minWidth: "1350px",
                  maxWidth: "1000px",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <AgGridReact
                  rowData={props?.user?.userRolesAndModules}
                  columnDefs={columns}
                  defaultColDef={defaultColDef}
                  rowDragManaged={false}
                  rowDragEntireRow={false}
                  animateRows={true}
                  suppressRowTransform={true}
                  gridOptions={gridOptions}
                  domLayout={"autoHeight"}
                  stopEditingWhenCellsLoseFocus={true}
                  overlayLoadingTemplate={
                    '<object style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) scale(2)" type="image/svg+xml" data="https://ag-grid.com/images/ag-grid-loading-spinner.svg" aria-label="loading"></object>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  getRowNodeId={(data) => data.id}
                  deltaRowDataMode={true}
                  onCellClicked={(params) => handleCellClick(params)}
                ></AgGridReact>
              </div>
            )}
          </div>
        </div>
      </div>

      <Offcanvas
        className="off-concave-role"
        show={props.show}
        // onHide={handleCloseModal}
        placement="end"
        data-theme={props.isDark}
      >
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          <div className="subscription-main-new-wraper">
            <div className="subrcip-top-heading-field-wrap">
              <div className="d-flex justify-content-between role-offconcave-header-wrap">
                <div className="heading-permission-wrap">
                  {" "}
                  Set <span>Permissions</span>{" "}
                </div>

                {!(pload || load) ? (
                  <div className="form-group form-role">
                    <select
                      className="form-control"
                      value={roleId}
                      onChange={(e) => {
                        setRoleId(e.target.value);
                        getPermissions(e.target.value, true);
                      }}
                    >
                      {permissionRoles?.map((r, id) => (
                        <option key={id} value={r?.roleId}>
                          {capitalizeFirstLetter(r?.roleName)}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            {pload || load ? (
              <div className="position-relative">
                <div className="ms-1">
                  <ShimmerTable row={1} />
                  <ShimmerCategoryItem
                    hasImage
                    imageType="thumbnail"
                    imageWidth={200}
                    imageHeight={200}
                    title
                  />
                  <ShimmerCategoryItem
                    hasImage
                    imageType="thumbnail"
                    imageWidth={200}
                    imageHeight={200}
                    title
                  />
                  <ShimmerCategoryItem
                    hasImage
                    imageType="thumbnail"
                    imageWidth={200}
                    imageHeight={200}
                    title
                  />
                </div>
              </div>
            ) : (
              <div className="table-main-wraper-permission">
                <div className="table-responsive">
                  <table
                    width="100%"
                    border="1"
                    className="table table-role-permission"
                  >
                    <tr className="heading-permission">
                      <td>Modules</td>
                      <td className="d-flex align-items-center px-4 w-full justify-content-between flex-row">
                        Details{" "}
                        <span w className="pull-right ">
                          {" "}
                          Permissions/Access{" "}
                        </span>
                      </td>
                    </tr>
                    {roleCaetgories?.map(
                      (res, id) =>
                        res?.modules?.length > 0 && (
                          <tr key={id}>
                            <td className="d-flex align-items-center table-permission-modules-td pt-4">
                              {res?.categoryName}{" "}
                              <a
                                className={`arrow-btn-role ${
                                  idR.includes(res?.categoryName)
                                    ? "flip-arrow"
                                    : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleArrowClick(res?.categoryName)
                                }
                              >
                                <img src={Role} alt="role-icon" />
                              </a>
                            </td>

                            <td>
                              <div className="modules-permission-listing">
                                {/* <div className="modules-permission-heading">
                              {mod?.permissionName}
                            </div> */}

                                <div className="listing-edit-permission">
                                  <ul>
                                    {res?.modules
                                      ?.slice(
                                        0,
                                        idR.includes(res?.categoryName)
                                          ? res?.modules?.length
                                          : 2
                                      )
                                      .map((mod, id) => (
                                        <li key={mod?.uid}>
                                          <div className="list-edit-module-wrap">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <span className="heading-lead">
                                                  {" "}
                                                  {mod?.permissionName}{" "}
                                                </span>
                                              </div>

                                              <div className="col-md-8">
                                                <div className="lising-radio-permission-wrap">
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      <div className="onoffswitch2">
                                                        <input
                                                          type="checkbox"
                                                          name={`myonoffswitch${mod?.uid}`}
                                                          className="onoffswitch2-checkbox"
                                                          id={`myonoffswitch${mod?.uid}`}
                                                          checked={checkedModules.includes(
                                                            mod.uid
                                                          )}
                                                          disabled={
                                                            roleType === "admin"
                                                          }
                                                          onClick={() => {
                                                            handleUpdateRole(
                                                              roleId,
                                                              mod?.moduleId,
                                                              mod?.isChecked
                                                                ? "del"
                                                                : "add",
                                                              mod?.uid,
                                                              mod?.accessType
                                                            );
                                                          }}
                                                        />
                                                        <label
                                                          className="onoffswitch2-label"
                                                          for={`myonoffswitch${mod?.uid}`}
                                                        >
                                                          <span className="onoffswitch2-inner"></span>
                                                          <span className="onoffswitch2-switch"></span>
                                                        </label>
                                                      </div>{" "}
                                                    </li>
                                                    <li>
                                                      {" "}
                                                      <div className="d-flex form-check-inline">
                                                        <label className="customradio">
                                                          <label className="customradio-role">
                                                            <input
                                                              type="radio"
                                                              name={`radio${mod.uid}`}
                                                              className="role-permission-radio"
                                                              checked={publicId.includes(
                                                                mod.uid
                                                              )}
                                                              disabled={
                                                                !checkedModules.includes(
                                                                  mod.uid
                                                                ) ||
                                                                roleType ===
                                                                  "admin"
                                                              }
                                                              onClick={() =>
                                                                handlePrivatePublic(
                                                                  roleId,
                                                                  mod?.moduleId,
                                                                  "add",
                                                                  mod?.uid,
                                                                  publicId.includes(
                                                                    mod.uid
                                                                  )
                                                                    ? "PRIVATE"
                                                                    : "PUBLIC"
                                                                )
                                                              }
                                                            />
                                                            <span className="radio-mark" />
                                                          </label>
                                                          <span className="radiotextsty">
                                                            Public
                                                          </span>
                                                        </label>
                                                        <label className="customradio">
                                                          <label className="customradio-role">
                                                            <input
                                                              type="radio"
                                                              name={`radio${mod.uid}`}
                                                              className="role-permission-radio"
                                                              checked={privateId.includes(
                                                                mod.uid
                                                              )}
                                                              disabled={
                                                                !checkedModules.includes(
                                                                  mod.uid
                                                                ) ||
                                                                roleType ===
                                                                  "admin"
                                                              }
                                                              onClick={() =>
                                                                handlePrivatePublic(
                                                                  roleId,
                                                                  mod?.moduleId,
                                                                  "add",
                                                                  mod?.uid,
                                                                  privateId.includes(
                                                                    mod.uid
                                                                  )
                                                                    ? "PUBLIC"
                                                                    : "PRIVATE"
                                                                )
                                                              }
                                                            />
                                                            <span className="radio-mark" />
                                                          </label>
                                                          <span className="radiotextsty">
                                                            Private
                                                          </span>
                                                        </label>
                                                      </div>{" "}
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                </div>
              </div>
            )}
            <button className="btn-role-concave" onClick={handleCloseModal}>
              <img src={AddBtn} alt="add-btn" />
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <DeleteUserRoleModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        userId={props.userId}
        getUser={props.getUser}
        isDark={props.isDark}
        roleName={roleName}
        roleId={roleId}
        getPermissionsAfterDelete={getPermissionsAfterDelete}
      />
    </div>
  );
};

function ModulesCategoryList({ params, colors }) {
  const [showMore, setShowMore] = useState(false);
  const itemsToShow = 5;
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const buttonRef = useRef();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    if (!showMore) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      setXValue(parentRect.x + 20);
      setYValue(parentRect.y + 25);
    }
    setShowMore(true);
  };
  return (
    <ul className="d-flex flex-row align-items-center gap-1">
      {params?.data?.moduleCategories
        ?.slice(0, itemsToShow)
        .map((moduleCategory, id) => {
          const uniqueId = uuidv4();
          return (
            <React.Fragment key={uniqueId}>
              <li
                style={{
                  backgroundColor: colors[id % colors.length],
                }}
                data-tooltip-id={`my-tooltip-${uniqueId}`}
              >
                {moduleCategory?.moduleName}
              </li>
              <ReactTooltip
                id={`my-tooltip-${uniqueId}`}
                place="top"
                getContent={() => (
                  <div>
                    {moduleCategory?.moduleName} <br />
                    {moduleCategory?.moduleName.charAt(0).toUpperCase() +
                      moduleCategory?.moduleName.slice(1)}
                  </div>
                )}
              />
            </React.Fragment>
          );
        })}
      {params?.data?.moduleCategories?.length > itemsToShow && (
        <div className="position-relative">
          <button
            ref={buttonRef}
            className="btn border border-1 ms-2 more-modules-btn-userinfo"
            onMouseEnter={buttonClickHandler}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowMore(false);
            }}
            onClick={buttonClickHandler}
          >
            +{params?.data?.moduleCategories.length - itemsToShow}
          </button>

          {showMore && (
            <Portal>
              <div
                onMouseEnter={buttonClickHandler}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  setShowMore(false);
                }}
                style={{
                  position: "absolute",
                  left: `${xValue}px`,
                  top: `${yValue}px`,

                  listStyle: "none",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add shadow for better visibility
                  background: "#fff", // Set background color
                  border: "1px solid #ccc",
                }}
                className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1 border"
              >
                {params?.data?.moduleCategories
                  ?.slice(itemsToShow)
                  .map((moduleCategory, id) => {
                    const uniqueId = uuidv4();
                    return (
                      <React.Fragment key={uniqueId}>
                        <li
                          style={{
                            backgroundColor: colors[id % colors.length],
                          }}
                          data-tooltip-id={`my-tooltip-${uniqueId}`}
                        >
                          {moduleCategory?.moduleName}
                        </li>
                        <ReactTooltip
                          id={`my-tooltip-${uniqueId}`}
                          place="top"
                          getContent={() => (
                            <div>
                              {moduleCategory?.moduleName} <br />
                              {moduleCategory?.moduleName
                                .charAt(0)
                                .toUpperCase() +
                                moduleCategory?.moduleName.slice(1)}
                            </div>
                          )}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </Portal>
          )}
        </div>
      )}
    </ul>
  );
}

const UserRolePermissionList = ({ role }) => {
  const [showAll, setShowAll] = useState(false);
  const itemsToShow =
    role?.moduleCategories.length > 2
      ? showAll
        ? role?.moduleCategories
        : role.moduleCategories.slice(0, 2)
      : role?.moduleCategories;

  const handleShowMore = (e) => {
    e.stopPropagation();
    setShowAll(!showAll);
  };

  return (
    <>
      <ul className="d-flex align-items-center font-bold flex-wrap user-role-permission-list  flex-shrink-0 gap-2 text-sm">
        {itemsToShow?.map((moduleCategory, id) => (
          <li
            key={id}
            className={`user-role-permission-name ${
              itemsToShow.length < 2 && "no-line"
            }`}
          >
            {moduleCategory?.moduleName}
          </li>
        ))}
        {role?.moduleCategories.length > 2 && (
          <span onClick={handleShowMore} className="user-role-show-more-button">
            {showAll ? "Hide..." : "Show more..."}
          </span>
        )}
      </ul>
    </>
  );
};

export default UserPermissions;
