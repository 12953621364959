// Month difference
export const calculateMonthDifference = (endDateStr) => {
  // Get the current date
  const currentDate = new Date();

  // Parse the trial end date string into a Date object
  const endDate = new Date(endDateStr);

  // Calculate the difference in months
  const monthsDifference =
    (endDate.getFullYear() - currentDate.getFullYear()) * 12 +
    (endDate.getMonth() - currentDate.getMonth());
  return monthsDifference;
};
