import {
  CREATE_PROJECT_PHASE_REQUEST,
  CREATE_PROJECT_PHASE_SUCCESS,
  CREATE_PROJECT_PHASE_FAIL,
  GET_PROJECTS_PHASES_REQUEST,
  GET_PROJECTS_PHASES_SUCCESS,
  GET_PROJECTS_PHASES_FAIL,
  UPDATE_PROJECT_PHASE_REQUEST,
  UPDATE_PROJECT_PHASE_SUCCESS,
  UPDATE_PROJECT_PHASE_FAIL,
  PROJECT_PHASE_ERROR,
} from "../constants/projectPhaseContants";

export const createProjectPhaseReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_PHASE_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };
    case CREATE_PROJECT_PHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        phase: action.payload,
        isError: false,
        error: null,
      };
    case CREATE_PROJECT_PHASE_FAIL:
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.payload,
      };
    case PROJECT_PHASE_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getProjectsPhasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECTS_PHASES_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };
    case GET_PROJECTS_PHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        projectsPhasesList: action.payload,
        error: null,
      };
    case GET_PROJECTS_PHASES_FAIL:
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.payload,
      };
    case PROJECT_PHASE_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateProjectPhaseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_PHASE_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };
    case UPDATE_PROJECT_PHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        phase: action.payload,
        isError: false,
        error: null,
      };
    case UPDATE_PROJECT_PHASE_FAIL:
      return {
        ...state,
        loading: false,
        isError: true,

        error: action.payload,
      };
    case PROJECT_PHASE_ERROR:
      return {
        ...state,
        isError: true,

        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
