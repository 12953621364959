import React from "react";
import Projects from "./components/ChartsOfAccounts";

const ChartsOfAccountsMain = (props) => {
  return (
    <Projects
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default ChartsOfAccountsMain;
