/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { ListOfTeamMemberTooltip } from "./utils/data";
import SearchIcon from "../../../assets/search-icon.png";
import { isPermitted } from "../../../helper/isPermitted";
import { useIsMobile } from "../../../helper/windowHelper";
import { BottomSheet } from "react-spring-bottom-sheet";
import MobileMemberFilter from "./MobileMemberFilter";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

function TaskMainListFilter({
  data,
  setData,
  props,
  projectUserList,
  taskListToFilters,
  projectTypeList,
  setServerFilters,
  serverFilters,
}) {
  const [showFilterDropDown, setshowFilterDropDown] = useState(false);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const { isMobile } = useIsMobile();
  const inputRef = useRef(null);
  const userListRef = useRef(null);

  const [userSearchList, setUserSearchList] = useState(projectUserList);

  useEffect(() => {
    setUserSearchList(projectUserList);
  }, [projectUserList, showFilterDropDown]);

  const UserImageCircle = ({ colors, moduleCategory, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    const handleMouseEnter = (event) => {
      setShowTooltip(true);
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ x: rect.x, y: rect.y });
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    return moduleCategory?.profileImage ? (
      <div
        key={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative filter-top-user-info-wrap"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        <img
          className="rounded-circle w-100 h-100"
          src={moduleCategory?.profileImage}
          alt="profile-img"
        />
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </div>
    ) : (
      <div
        key={id}
        style={{
          border: "1px solid " + colors[id % colors.length],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative filter-top-user-info-wrap"
        data-tooltip-id={`my-tooltip-${uniqueId}`}
      >
        {moduleCategory?.userName?.charAt(0).toUpperCase() +
          moduleCategory?.userName?.charAt(1).toUpperCase()}
        {showTooltip && (
          <Portal>
            <ListOfTeamMemberTooltip
              memberName={moduleCategory.userName}
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          </Portal>
        )}
      </div>
    );
  };

  const AdditionalUserCircle = ({ colors, moduleCategory }) => {
    return (
      <div
        style={{
          border: "1px solid " + colors[0],
        }}
        className="position-relative filter-top-user-info-wrap"
      >
        {moduleCategory?.charAt(0).toUpperCase() +
          moduleCategory?.charAt(1).toUpperCase()}
      </div>
    );
  };

  // Helper function to get userName based on userId
  const getUserNameById = (userId) => {
    const user = projectUserList?.find((user) => user.userId === userId);
    return user ? user : ""; // Return userName if found, otherwise return an empty string
  };

  const combinedIds = [
    ...(serverFilters?.memberIds || []),
    ...(serverFilters?.observerIds || []),
    ...(serverFilters?.ownerIds || []),
  ];

  // Remove duplicates by converting the array to a Set and back to an array
  const uniqueIds = Array.from(new Set(combinedIds));

  const handleKeyDown = (event) => {
    if (!showFilterDropDown) return;

    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < userSearchList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : userSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item
      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown
      setshowFilterDropDown(false);
      event.preventDefault();
    }
  };

  // Scroll the focused item into view
  useEffect(() => {
    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  // Drop down using portal
  const sectionRef = useRef();
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);

  useEffect(() => {
    if (showFilterDropDown) {
      const rect = sectionRef?.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
  }, [showFilterDropDown]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        console.log("clicked", 186);

        setshowFilterDropDown(false);
      } else if (
        sectionRef.current &&
        sectionRef.current.contains(event.target)
      ) {
        setshowFilterDropDown((prev) => !prev);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section
      onKeyDown={handleKeyDown}
      className={`position-relative ${
        uniqueIds?.length > 0 ? "filter-top-user-section me-0" : ""
      }`}
    >
      <div ref={sectionRef}>
        {uniqueIds?.length > 0 ? (
          <div
            className="teams-member-listing-wap"
            // onClick={() => setshowFilterDropDown(!showFilterDropDown)}
          >
            <ul>
              {uniqueIds?.slice(0, 3)?.map((res, id) => {
                return (
                  <UserImageCircle
                    colors={colors}
                    moduleCategory={getUserNameById(res)}
                    id={id}
                  />
                );
              })}
              {uniqueIds?.length > 3 && (
                <AdditionalUserCircle
                  colors={colors}
                  moduleCategory={`+${uniqueIds.length - 3}`}
                />
              )}
            </ul>
          </div>
        ) : (
          <div
            className={`d-flex align-items-center task-selector-filter task-filter-specific tasks-main-info-list-font-weight cursor-pointer ${
              serverFilters.memberFilter ? "selected-filter-member" : ""
            }`}
            // onClick={() => setshowFilterDropDown(!showFilterDropDown)}
          >
            <img src={avatar} alt="avatar-icon" className="mt-1" /> Members
            {/* {serverFilters.memberFilter === "member"
            ? "Assigned to"
            : serverFilters.memberFilter
            ? formatLabel(serverFilters.memberFilter)
            : "Members"} */}
            <div
              className="select-icon"
              style={{
                transform: showFilterDropDown
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
              </svg>
            </div>
          </div>
        )}
      </div>
      {showFilterDropDown && isMobile && (
        <BottomSheet
          open={showFilterDropDown}
          blocking={false}
          onDismiss={() => setshowFilterDropDown(false)}
          className="member-bottom-sheet"
        >
          <div
            className="rounded-3 shadow-lg task-user-filter-dropdown task-user-filter-dropdown-mobile"
            ref={dropdownRef}
            data-theme={props.isDark}
          >
            <MobileMemberFilter
              isDark={props.isDark}
              projectUserList={projectUserList}
              setData={setData}
              data={data}
              projectTypeList={projectTypeList}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
              userSearchList={userSearchList}
              setUserSearchList={setUserSearchList}
              inputRef={inputRef}
              userListRef={userListRef}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
            />
          </div>
        </BottomSheet>
      )}
      {showFilterDropDown && dropdownPosition && !isMobile && (
        <Portal>
          <div
            className="position-absolute rounded-3 shadow-lg task-user-filter-dropdown"
            ref={dropdownRef}
            style={{
              zIndex: 1050,
              top: dropdownPosition.top + 8 + "px",
              left: dropdownPosition.left + "px",
            }}
            data-theme={props.isDark}
          >
            <TaskUsersListForFilter
              isDark={props.isDark}
              projectUserList={projectUserList}
              setData={setData}
              data={data}
              projectTypeList={projectTypeList}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
              userSearchList={userSearchList}
              setUserSearchList={setUserSearchList}
              inputRef={inputRef}
              userListRef={userListRef}
              focusedIndex={focusedIndex}
              setFocusedIndex={setFocusedIndex}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
            />
          </div>
        </Portal>
      )}
    </section>
  );
}

const TaskUsersListForFilter = ({
  projectUserList,
  setData,
  data,
  projectTypeList,
  setServerFilters,
  serverFilters,
  userSearchList,
  setUserSearchList,
  inputRef,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    setUserSearchList(
      projectUserList?.filter((user) =>
        user.userName.toLowerCase().includes(value)
      )
    );
  };

  const clearSearch = () => {
    setSearch("");
    setUserSearchList(projectUserList);
  };

  const handleTabClick = (tabName, index) => {
    const foundType = projectTypeList?.find(
      (item) => item.typeName === tabName
    );
    setServerFilters((prev) => ({
      ...prev,
      memberFilter: foundType?.typeName.toLowerCase(),
    }));
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div
      // onKeyDown={handleKeyDown}
      className="d-flex task-user-filter-dropdown-menu custom-vertical-scrollbar"
    >
      <div className="task-user-filter-dropdown-tab-bar">
        <ul className="d-flex flex-column align-items-start custom-horizental-scrollbar p-0 m-0 custom-user-type-list">
          {["member", "owner", "observer"].map((type, index) => (
            <li className="w-100" key={type}>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                  serverFilters?.memberFilter === type ? "active-user-type" : ""
                }`}
                onClick={() => handleTabClick(type, index)}
              >
                {type === "member"
                  ? "Assigned To"
                  : type === "owner"
                  ? "Task Owner"
                  : "Task Observer"}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex flex-column custom-user-selection-main-container">
        <div className="listing-team-modal-search-input-wrap position-relative">
          <input
            ref={inputRef}
            type="text"
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            autoFocus
            autoComplete="off"
            className="custom-user-search"
          />
          {search ? (
            <span className="custom-user-search-icon" onClick={clearSearch}>
              &#x2715;
            </span>
          ) : (
            <img
              src={SearchIcon}
              className="custom-user-search-icon"
              alt="search-icon"
            />
          )}
        </div>
        <div
          ref={userListRef}
          className="user-list-main-container pe-2 custom-vertical-scrollbar"
        >
          {userSearchList?.length === 0 && (
            <p className="p-0">No member found</p>
          )}
          {[...userSearchList]
            .sort((a, b) => {
              const filterKey =
                serverFilters.memberFilter === "member"
                  ? "memberIds"
                  : serverFilters.memberFilter === "observer"
                  ? "observerIds"
                  : "ownerIds";

              const isCheckedA = serverFilters[filterKey]?.includes(a?.userId);
              const isCheckedB = serverFilters[filterKey]?.includes(b?.userId);
              return isCheckedB - isCheckedA;
            })
            .map((res, index) => (
              <TaskUsersListItemForFilter
                setData={setData}
                data={data}
                res={res}
                setServerFilters={setServerFilters}
                serverFilters={serverFilters}
                index={index}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
                key={res.userId}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  setData,
  data,
  res,
  serverFilters,
  setServerFilters,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      setIsChecked(!isChecked);

      // Determine which ID array to update based on the memberFilter
      const filterKey =
        serverFilters.memberFilter === "member"
          ? "memberIds"
          : serverFilters.memberFilter === "observer"
          ? "observerIds"
          : "ownerIds";

      const currentIds = serverFilters[filterKey];

      // Check if the userId is already in the selected filter array
      if (currentIds.includes(res?.userId)) {
        // Remove the userId if it exists in the array
        const updatedIds = currentIds.filter(
          (userId) => userId !== res?.userId
        );
        setServerFilters({
          ...serverFilters,
          [filterKey]: updatedIds,
        });
      } else {
        // Add the userId if it doesn't exist in the array
        setServerFilters({
          ...serverFilters,
          [filterKey]: [...currentIds, res?.userId],
        });
      }

      setIsClicked(false);
    }
  }, [isClicked]);
  return (
    <div
      role="option"
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className="w-100 border-bottom-1 task-user-filter-dropdown-menu-item"
    >
      <div
        className="checkbox create-task-checkbox"
        onClick={() => {
          setIsChecked(!isChecked);

          // Determine which ID array to update based on the memberFilter
          const filterKey =
            serverFilters.memberFilter === "member"
              ? "memberIds"
              : serverFilters.memberFilter === "observer"
              ? "observerIds"
              : "ownerIds";

          const currentIds = serverFilters[filterKey];

          // Check if the userId is already in the selected filter array
          if (currentIds.includes(res?.userId)) {
            // Remove the userId if it exists in the array
            const updatedIds = currentIds.filter(
              (userId) => userId !== res?.userId
            );
            setServerFilters({
              ...serverFilters,
              [filterKey]: updatedIds,
            });
          } else {
            // Add the userId if it doesn't exist in the array
            setServerFilters({
              ...serverFilters,
              [filterKey]: [...currentIds, res?.userId],
            });
          }
        }}
      >
        <input
          type="checkbox"
          checked={serverFilters[
            serverFilters.memberFilter === "member"
              ? "memberIds"
              : serverFilters.memberFilter === "observer"
              ? "observerIds"
              : "ownerIds"
          ]?.includes(res?.userId)}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={res?.profileImage ? res?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          {/* <span className="user-live-status"></span> */}
          <span
            className="ps-1"
            // onClick={(e) => {
            //   if (isPermitted("user-profile")) {
            //     e.stopPropagation();
            //     // window.open(
            //     //   `/${localStorage.getItem("company")}/user-profile?id=${
            //     //     res?.userId
            //     //   }`,
            //     //   "_blank" // Opens in a new tab or window
            //     // );
            //     saveLocalStorageToCookies(`/user-profile?id=${res?.userId}`);
            //   }
            // }}
          >
            {res?.userName}
          </span>
        </label>
      </div>
    </div>
  );
};

export default TaskMainListFilter;
