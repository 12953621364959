/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/ClientApi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../assets/infoMain.svg";
import { Icon } from "@iconify/react";
import { getIconCode } from "../helper/iconGeneratorSidebar";
import { useSelector } from "react-redux";
import { findObjectByContentTitle } from "../helper/getContentObject";
import ChatSidebar from "./ChatSidebar";
import socket from "../services/socket";
import ChatSidebarCollapse from "./ChatSidebarCollapse";

const SideBar = (props) => {
  const navigate = useNavigate();
  // const { data: chatUsers, isLoading: chatLoading } = useGetAllChatUsersQuery();
  const [allUsers, setAllUsers] = useState([]);
  const [currentRoute, setCurrentRoute] = useState("");
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [load, setLoad] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);
  const { userProfile } = useSelector((state) => state?.profile);
  const [roomData, setRoomData] = useState(null);

  const verifyModalContent = findObjectByContentTitle(
    "Verify Email",
    modalContents?.data
  );

  const resendEmail = async () => {
    try {
      setLoad(true);
      const response = await API.post("users/resend-email-verification");
      if (response?.data?.status === "success") {
        toast.success("Verification mail sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setShowVerifyEmailModal(false);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const handleVerifyEmailModalClose = () => {
    setShowVerifyEmailModal(false);
  };

  const handleOutsideClick = (e) => {
    // Check if the click is outside of the sidebar and the sidebar is currently open
    const isMenuIconClick = e.target.closest(".menu-icon");
    const isMenuIconClick2 = e.target.closest(".menu-icon-2");
    if (
      props.sidebar &&
      !e.target.closest(".vertical-navbar") &&
      !isMenuIconClick &&
      !isMenuIconClick2
    ) {
      props.setSideBar(false);
    }
  };
  useEffect(() => {
    // Add event listener to the document body
    document.body.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sidebar]);

  useEffect(() => {
    const currentRoute = window.location.pathname;
    setCurrentRoute(currentRoute.substring(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (userProfile) {
      socket.emit("fetchAllUsers", {
        userId: userProfile?.userId,
        companyId: userProfile?.companyId,
      });
    }
  }, [userProfile]);

  useEffect(() => {
    // Listen for user list updates
    socket.on("allUsers", (data) => {
      setAllUsers(data?.users || []); // Ensure setAllUsers gets a valid array
    });

    // Listen for error messages
    socket.on("errorMessage", (error) => {
      console.error("Error message from server:", error.message);
    });

    return () => {
      // Clean up listeners
      socket.off("allUsers");
      socket.off("errorMessage");
    };
  }, []);

  useEffect(() => {
    socket.on("errorMessage", (data) => {
      console.log("ErrD : ", data);
    });

    return () => {
      socket.off("errorMessage");
    };
  }, []);

  return (
    <>
      <div className={`vertical-navbar ${props.sidebar ? "open" : ""}`}>
        {/* Navbar content goes here */}
        <ul>
          <li>
            {" "}
            <a
              onClick={() => {
                if (props?.user?.emailVerified !== 0) {
                  navigate(`/${localStorage.getItem("company")}/dashboard`);
                  sessionStorage.removeItem("tab");
                  sessionStorage.removeItem("userTab");
                  sessionStorage.removeItem("substate");
                  props.setSideBar(false);
                } else {
                  setShowVerifyEmailModal(true);
                }
              }}
              className={`sidebar-icon-wrap ${
                currentRoute === `${localStorage.getItem("company")}/dashboard`
                  ? "selected-route"
                  : ""
              }`}
            >
              {" "}
              <Icon icon="material-symbols-light:tv-outline-rounded"></Icon>{" "}
              Dashboard{" "}
            </a>{" "}
          </li>
          {props?.options?.map((res, id) => (
            <>
              {res?.modules?.length > 0 && (
                <li className="category-li" key={id}>
                  <span>{res.categoryName}</span>
                  {res?.modules?.map((mod, moduleId) => (
                    <a
                      key={moduleId}
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          if (mod?.moduleSlug !== "crm-live-chat") {
                            navigate(
                              `/${localStorage.getItem("company")}/${
                                mod?.moduleSlug
                              }`
                            );
                          }
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/${mod?.moduleSlug}`
                          ? "selected-route"
                          : ""
                      }`}
                      alt="icon"
                      // className="category-sub-a"
                    >
                      <Icon icon={`${getIconCode(mod?.moduleSlug)}`} />{" "}
                      {mod?.moduleName}
                    </a>
                  ))}
                </li>
              )}
            </>
          ))}
        </ul>
      </div>
      <div className={`left-nav-wrap ${props.collapse ? "collapse-nav" : ""}`}>
        {!props.collapse ? (
          <div
            className={`left-nav-main ${
              props?.bannerCount === 1 ? "left-nav-main-one" : ""
            } ${props?.bannerCount === 2 ? "left-nav-main-two" : ""}`}
          >
            <ul>
              <li>
                {" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(`/${localStorage.getItem("company")}/dashboard`);
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/dashboard`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="material-symbols-light:tv-outline-rounded"></Icon>{" "}
                  Dashboard{" "}
                </a>{" "}
              </li>
              {props?.options?.map((res, id) => (
                <>
                  {res?.modules?.length > 0 && (
                    <li
                      key={id}
                      className="category-li d-flex flex-column gap-1"
                    >
                      <span>{res.categoryName}</span>
                      {res?.modules?.map((mod, moduleId) => (
                        <a
                          key={moduleId}
                          onClick={() => {
                            if (props?.user?.emailVerified !== 0) {
                              if (mod?.moduleSlug !== "crm-live-chat") {
                                if (mod?.moduleSlug === "team-performance") {
                                  if (
                                    props?.user?.roles?.some(
                                      (item) => item.roleType === "admin"
                                    )
                                  ) {
                                    navigate(
                                      `/${localStorage.getItem("company")}/${
                                        mod?.moduleSlug
                                      }`
                                    );
                                  } else {
                                    navigate(
                                      `/${localStorage.getItem(
                                        "company"
                                      )}/performance-report?userId=${
                                        props?.user?.userId
                                      }`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `/${localStorage.getItem("company")}/${
                                      mod?.moduleSlug
                                    }`
                                  );
                                }
                              }

                              sessionStorage.removeItem("tab");
                              sessionStorage.removeItem("userTab");
                              sessionStorage.removeItem("substate");
                            } else {
                              setShowVerifyEmailModal(true);
                            }
                          }}
                          className={`sidebar-icon-wrap ${
                            currentRoute ===
                            `${localStorage.getItem("company")}/${
                              mod?.moduleSlug
                            }`
                              ? "selected-route"
                              : ""
                          } `}
                        >
                          <Icon icon={`${getIconCode(mod?.moduleSlug)}`} />{" "}
                          {mod?.moduleName}
                        </a>
                      ))}
                    </li>
                  )}
                </>
              ))}
            </ul>
            {allUsers?.length > 0 && (
              <ChatSidebar
                users={allUsers}
                isDark={props?.isDark}
                setAllUsers={setAllUsers}
                allUsers={allUsers}
                roomData={roomData}
                setRoomData={setRoomData}
              />
            )}
          </div>
        ) : (
          <div
            className={`left-nav-main left-nav-main-collapse ${
              props?.bannerCount === 1 ? "left-nav-main-one" : ""
            } ${props?.bannerCount === 2 ? "left-nav-main-two" : ""}`}
          >
            <ul className="ul-collapsed">
              <li>
                {" "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip">Dashboard</Tooltip>}
                >
                  <a
                    onClick={() => {
                      if (props?.user?.emailVerified !== 0) {
                        navigate(
                          `/${localStorage.getItem("company")}/dashboard`
                        );
                        sessionStorage.removeItem("tab");
                        sessionStorage.removeItem("userTab");
                        sessionStorage.removeItem("substate");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                    className={`sidebar-icon-wrap ${
                      currentRoute ===
                      `${localStorage.getItem("company")}/dashboard`
                        ? "selected-route"
                        : ""
                    }`}
                  >
                    <Icon icon="material-symbols-light:tv-outline-rounded"></Icon>
                  </a>
                </OverlayTrigger>
              </li>
              {props?.options?.map(
                (res, id) =>
                  res?.modules?.length > 0 && (
                    <li key={id} className="mt-1">
                      {res?.modules?.map((mod, moduleId) => (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="button-tooltip">
                              {mod?.moduleName}
                            </Tooltip>
                          }
                        >
                          <a
                            onClick={() => {
                              if (props?.user?.emailVerified !== 0) {
                                if (mod?.moduleSlug !== "crm-live-chat") {
                                  navigate(
                                    `/${localStorage.getItem("company")}/${
                                      mod?.moduleSlug
                                    }`
                                  );
                                }

                                sessionStorage.removeItem("tab");
                                sessionStorage.removeItem("userTab");
                                sessionStorage.removeItem("substate");
                              } else {
                                setShowVerifyEmailModal(true);
                              }
                            }}
                            className={`sidebar-icon-wrap ${
                              currentRoute ===
                              `${localStorage.getItem("company")}/${
                                mod?.moduleSlug
                              }`
                                ? "selected-route"
                                : ""
                            }`}
                          >
                            <Icon icon={`${getIconCode(mod?.moduleSlug)}`} />
                          </a>
                        </OverlayTrigger>
                      ))}
                    </li>
                  )
              )}
            </ul>
            {allUsers?.length > 0 && (
              <ChatSidebarCollapse
                users={allUsers}
                isDark={props?.isDark}
                setAllUsers={setAllUsers}
                allUsers={allUsers}
                roomData={roomData}
                setRoomData={setRoomData}
              />
            )}
          </div>
        )}
      </div>
      {showVerifyEmailModal && (
        <Modal
          show={showVerifyEmailModal}
          onHide={handleVerifyEmailModalClose}
          centered
          size="md"
          data-theme={props?.isDark}
          backdrop="static"
          keyboard={false}
          className="main-delete-modal"
        >
          <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
            <div className="del-modal-img-container">
              <img src={DeleteIcon} alt="verify-icon" />
            </div>
            <h5>{verifyModalContent?.model_title}</h5>
            <p>
              {verifyModalContent?.short_description}{" "}
              <a onClick={resendEmail} className="email-verify-anchor">
                {load ? (
                  <Spinner
                    border="animation"
                    style={{ width: "1rem", height: "1rem" }}
                  />
                ) : (
                  "Resend"
                )}
              </a>{" "}
              {verifyModalContent?.long_description}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="w-100 delete-modal-btn-wrapper">
              <button
                className="w-100 delete-btn-1"
                onClick={handleVerifyEmailModalClose}
                disabled={load}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SideBar;
