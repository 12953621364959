/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../styles/modulePage.css";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import m1 from "../../../assets/modules-icon1.png";
import m2 from "../../../assets/modules-icon2.png";
import m3 from "../../../assets/modules-icon3.png";
import m4 from "../../../assets/modules-icon4.png";
import m5 from "../../../assets/modules-icon5.png";
import m6 from "../../../assets/modules-icon6.png";
import m7 from "../../../assets/modules-icon7.png";
import InstallModuleModal from "./InstallModuleModal";
import UninstallModuleModal from "./UninstallModuleModal";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import { useSelector } from "react-redux";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { Helmet } from "react-helmet";
import { ShimmerCategoryList } from "react-shimmer-effects";

const ModulePageMain = (props) => {
  const [load, setLoad] = useState(false);
  const [installModal, setInstallModal] = useState(false);
  const [uninstallModal, setUninstallModal] = useState(false);
  const [loadbtn, setLoadBtn] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [alreadySelected, setAlreadySelected] = useState([]);
  const [noModuleErr, setNoModuleErr] = useState(false);
  const [modal, setModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [notInstalledModules, setNotInstalledModules] = useState([]);
  const [moduleName, setModuleName] = useState("");
  const { applicationName } = useSelector((state) => state.text);
  const [seeMore, setSeeMore] = useState(false);
  const [seeMoreOthers, setSeeMoreOthers] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);

  const activeModuleModalContent = findObjectByContentTitle(
    "plugins-active-modules",
    modalContents?.data
  );

  const otherModuleModalContent = findObjectByContentTitle(
    "plugins-other-modules",
    modalContents?.data
  );

  const handleClose = () => {
    setModal(false);
  };

  const getModules = async () => {
    try {
      setLoad(true);
      const response = await API.get("company/new-modules");
      setModules(response.data.data);
      await getCategories();
      // Check if any module in modulesData has isSelected set to true
      const selectedIds = response.data.data
        .filter((module) => module.isSelected)
        .map((selectedModule) => selectedModule.id);
      const notSelectedIds = response.data.data
        .filter((module) => !module.isSelected)
        .map((selectedModule) => selectedModule);
      setNotInstalledModules(notSelectedIds);
      // Add the selectedIds to the existing selectedModules
      setAlreadySelected((prevSelectedModules) => [
        ...prevSelectedModules,
        ...selectedIds,
      ]);
      await props.geOptions();
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPermissions = async () => {
    try {
      const permissions = await API.get("users/permissions-urls");
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions?.data?.data)
      );
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setLoadBtn(true);
    if (selectedModules.length > 0) {
      try {
        const response = await API.post("company/add-new-modules", {
          moduleCategoriesIds: selectedModules,
        });
        if (response?.response?.data?.status === "error") {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        if (response?.data?.status === "success") {
          props.getOptions();
          toast.success("Module added successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setInstallModal(false);
          getModules();
          getPermissions();
          setSelectedModules([]);
        }
      } catch (error) {
      } finally {
        setLoadBtn(false);
      }
    } else {
      setNoModuleErr(true);
      setLoadBtn(false);
    }
  };

  const handleUninstallSubmit = async () => {
    setLoadBtn(true);
    if (selectedModules.length > 0) {
      try {
        const response = await API.post("company/inactive-plugin", {
          moduleCategoriesId: selectedModules[0],
        });
        if (response?.response?.data?.status === "error") {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        if (response?.data?.status === "success") {
          props.getOptions();
          toast.success("Module uninstalled successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setUninstallModal(false);
          getModules();
          getPermissions();
          setSelectedModules([]);
        }
      } catch (error) {
      } finally {
        setLoadBtn(false);
      }
    } else {
      setNoModuleErr(true);
      setLoadBtn(false);
    }
  };

  const getCategories = async () => {
    const response = await API.get("company/subscription");
    if (response?.data?.status === "success") {
      setCategories(response?.data?.data?.accessedCategories);
    }
  };

  const getIcon = (name) => {
    if (name === "CRM") {
      return m1;
    }
    if (name === "Task Management") {
      return m2;
    }
    if (name === "Booking System") {
      return m3;
    }
    if (name === "Accounts") {
      return m4;
    }
    if (name === "Goals") {
      return m5;
    }
    if (name === "Supply Chain") {
      return m6;
    }
    if (name === "Human Resources") {
      return m7;
    }
  };

  const getDescription = (name) => {
    if (name === "CRM") {
      return "Empowering businesses to build stronger customer relationships and drive growth.";
    }
    if (name === "Task Management") {
      return "Orchestrating tasks, resources, and timelines to achieve successful outcomes.";
    }
    if (name === "Booking System") {
      return "Streamlining reservations and appointments for seamless scheduling and organization.";
    }
    if (name === "Accounts") {
      return "Ensuring accurate tracking and management of transactions.";
    }
    if (name === "Goals") {
      return "Guiding beacons that fuel ambition and drive progress toward desired outcomes.";
    }
    if (name === "Supply Chain") {
      return "From raw materials to consumers, optimizing efficiency and delivery.";
    }
    if (name === "Human Resources") {
      return "Driving organizational success through people-centric strategies.";
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plugins-{applicationName}</title>
      </Helmet>
      <div className="modules-new-wraper">
        <>
          <div className="w-100 d-flex justify-content-between align-items-center module-section-header-wrap">
            <h2>
              {" "}
              <span>
                <strong>Active</strong> Modules{" "}
              </span>{" "}
            </h2>
          </div>
          <div className="col-md-12 mt-3">
            <p className="role-page-description">
              {activeModuleModalContent?.short_description}{" "}
              <span className="see-more-txt" onClick={() => setSeeMore(true)}>
                See More
              </span>
            </p>
          </div>
          {load && (
            <ul className="row d-flex module-row-wrap">
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
            </ul>
          )}
          {!load && categories?.length > 2 ? (
            <ul className="row d-flex module-row-wrap">
              {categories
                .filter(
                  (res) =>
                    res.moduleName !== "Data Center" &&
                    res.moduleName !== "Company Overview"
                )
                .map((res, id) => (
                  <li className="col-md-3" key={id}>
                    <div className="listing-modules-wrap">
                      <a className="h-100">
                        <div className="listing-modules-icon">
                          <img
                            src={getIcon(res?.moduleName)}
                            alt="module-icons"
                          />
                        </div>
                        <div className="listing-text-wraper">
                          <h5>{res?.moduleName}</h5>
                          <p>{getDescription(res?.moduleName)}</p>
                        </div>
                        <button
                          className="crefix-btn"
                          onClick={() => {
                            setSelectedModules([res?.mainCategoryId]);
                            setModuleName(res?.moduleName);
                            setUninstallModal(true);
                          }}
                        >
                          Uninstall
                        </button>
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <>
              {!load && (
                <div className="d-flex mt-2 align-items-center rounded-3 ">
                  <p className="no-billing-txt">No active modules found.</p>
                </div>
              )}
            </>
          )}
          <div className="w-100 d-flex justify-content-between align-items-center module-section-header-wrap">
            <h2>
              {" "}
              <span>
                <strong>Other</strong> Modules{" "}
              </span>{" "}
            </h2>
          </div>
          <div className="col-md-12 mt-3">
            <p className="role-page-description">
              {otherModuleModalContent?.short_description}{" "}
              <span
                className="see-more-txt"
                onClick={() => setSeeMoreOthers(true)}
              >
                See More
              </span>
            </p>
          </div>
          {load && (
            <ul className="row d-flex module-row-wrap">
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
              <li className="col-md-3 mb-2">
                <ShimmerCategoryList items={1} categoryStyle="STYLE_SEVEN" />
              </li>
            </ul>
          )}
          {!load && notInstalledModules?.length > 0 ? (
            <ul className="row d-flex module-row-wrap">
              {notInstalledModules
                .filter(
                  (res) =>
                    res.name !== "Data Center" &&
                    res.name !== "Company Overview"
                )
                .map((res, id) => (
                  <li className="col-md-3" key={id}>
                    <div className="listing-modules-wrap">
                      <a className="h-100">
                        <div className="listing-modules-icon">
                          <img src={getIcon(res?.name)} alt="module-icons" />
                        </div>
                        <div className="listing-text-wraper">
                          <h5>{res?.name}</h5>
                          <p>{getDescription(res?.name)}</p>
                        </div>
                        <button
                          className="crefix-btn"
                          onClick={() => {
                            setSelectedModules([res?.id]);
                            setModuleName(res?.name);
                            setInstallModal(true);
                          }}
                        >
                          Install
                        </button>
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <div className="d-flex mt-2 align-items-center rounded-3 ">
              <p className="no-billing-txt">All modules already installed</p>
            </div>
          )}
        </>
        <InstallModuleModal
          installModal={installModal}
          setInstallModal={setInstallModal}
          isDark={props.isDark}
          handleSubmit={handleSubmit}
          loadbtn={loadbtn}
          moduleName={moduleName}
        />
        <UninstallModuleModal
          installModal={uninstallModal}
          setInstallModal={setUninstallModal}
          isDark={props.isDark}
          handleSubmit={handleUninstallSubmit}
          loadbtn={loadbtn}
          moduleName={moduleName}
        />
        <SeeMoreModal
          seeMore={seeMore}
          setSeeMore={setSeeMore}
          headTextOne="Active"
          headTextTwo=" Modules"
          isDark={props.isDark}
          modalContent={activeModuleModalContent}
        />
        <SeeMoreModal
          seeMore={seeMoreOthers}
          setSeeMore={setSeeMoreOthers}
          headTextOne="Others"
          headTextTwo=" Modules"
          isDark={props.isDark}
          modalContent={otherModuleModalContent}
        />
      </div>
    </>
  );
};

export default ModulePageMain;
