import React, { useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Mention from "@tiptap/extension-mention";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useSelector } from "react-redux";

const TiptapEditor = ({
  onChange,
  disabled,
  value,
  onKeyDown,
  setMentionedUserIds,
  isFileUpload,
  onFileChange,
  isSearchButton = false,
  isSearchButtonActive = false,
  handleSearch = () => {},
}) => {
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const users = projectUserList?.data.map((user) => ({
    id: user.userId,
    name: user.userName,
    username: user.userName,
    email: user.userEmail,
    profileImage: user.profileImage,
  }));

  const updateMentionedUserIdsFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const mentionElements = doc.querySelectorAll(".mention");
    const ids = Array.from(mentionElements).map((element) =>
      element.getAttribute("data-id")
    );
    setMentionedUserIds(ids);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      BulletList,
      OrderedList,
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: {
          items: ({ query }) => {
            return users.filter((user) =>
              user.username.toLowerCase().includes(query.toLowerCase())
            );
          },
          char: "@",
          command: ({ editor, range, props }) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "mention",
                  attrs: props,
                },
              ])
              .run();
          },
          render: () => {
            let popup;
            let component;

            const scrollSelectedIntoView = (menu, selectedIndex) => {
              requestAnimationFrame(() => {
                const selectedItem = menu.children[selectedIndex];
                if (selectedItem) {
                  const menuRect = menu.getBoundingClientRect();
                  const itemRect = selectedItem.getBoundingClientRect();

                  const overScroll = itemRect.bottom - menuRect.bottom;
                  const underScroll = itemRect.top - menuRect.top;

                  if (overScroll > 0) {
                    menu.scrollTop += overScroll;
                  } else if (underScroll < 0) {
                    menu.scrollTop += underScroll;
                  }
                }
              });
            };

            const renderMenu = (props) => {
              const menu = document.createElement("div");
              menu.className = "mention-dropdown";

              props.items.forEach((item, index) => {
                const div = document.createElement("div");
                div.className = `mention-item ${
                  props.selectedIndex === index ? "selected" : ""
                }`;
                div.textContent = item.username;
                div.onclick = () => {
                  props.command({ id: item.id, label: item.username });
                  popup?.hide();
                };
                menu.appendChild(div);
              });

              scrollSelectedIntoView(menu, props.selectedIndex);

              return menu;
            };

            return {
              onStart: (props) => {
                component = {
                  props: {
                    ...props,
                    selectedIndex: 0,
                  },
                  onKeyDown: ({ event }) => {
                    if (event.key === "ArrowUp") {
                      event.preventDefault();
                      component.props.selectedIndex =
                        (component.props.selectedIndex -
                          1 +
                          component.props.items.length) %
                        component.props.items.length;
                      const menu = renderMenu(component.props);
                      popup?.setContent(menu);
                      return true;
                    }

                    if (event.key === "ArrowDown") {
                      event.preventDefault();
                      component.props.selectedIndex =
                        (component.props.selectedIndex + 1) %
                        component.props.items.length;
                      const menu = renderMenu(component.props);
                      popup?.setContent(menu);
                      return true;
                    }

                    if (event.key === "Enter") {
                      event.preventDefault();
                      const selectedItem =
                        component.props.items[component.props.selectedIndex];
                      if (selectedItem) {
                        component.props.command({
                          id: selectedItem.id,
                          label: selectedItem.username,
                        });
                        popup?.hide();
                      }
                      return true;
                    }

                    if (event.key === "Escape") {
                      popup?.hide();
                      event.preventDefault();
                      return true;
                    }

                    return false;
                  },
                };

                const menu = renderMenu(component.props);
                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: menu,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                  maxWidth: "none",
                })[0];
              },

              onUpdate: (props) => {
                component.props = {
                  ...props,
                  selectedIndex: component.props?.selectedIndex || 0,
                };
                const menu = renderMenu(component.props);
                popup?.setContent(menu);
              },

              onKeyDown: ({ event }) => {
                return component?.onKeyDown({ event });
              },

              onExit: () => {
                popup?.destroy();
                popup = null;
              },
            };
          },
        },
      }),
    ],
    content: value || "",
    editable: !disabled,
    onCreate: ({ editor }) => {
      editor.chain().focus().run();
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
      updateMentionedUserIdsFromHTML(html);
    },
    editorProps: {
      handleKeyDown: (view, event) => {
        if (event.ctrlKey && event.key === "Enter") {
          event.preventDefault();
          onKeyDown();
        }
      },
    },
  });

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    const imageFiles = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        imageFiles.push(file);
      }
    }

    if (imageFiles.length > 0) {
      onFileChange(imageFiles);
    }
  };

  useEffect(() => {
    const editorElement = document.querySelector(".ProseMirror");
    if (editorElement) {
      editorElement.addEventListener("paste", handlePaste);
    }
    return () => {
      if (editorElement) {
        editorElement.removeEventListener("paste", handlePaste);
      }
    };
  }, [editor]);

  return (
    <div
      className={`w-100 comment-editor-tiptap ${disabled ? "disabled" : ""}`}
    >
      <div className="tiptap-editor-toolbar">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "tip-tap-is-active" : ""}
          disabled={disabled}
        >
          <Icon icon="codicon:bold" fontSize={24} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "tip-tap-is-active" : ""}
          disabled={disabled}
        >
          <Icon icon="fluent:text-bullet-list-20-filled" fontSize={24} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "tip-tap-is-active" : ""}
          disabled={disabled}
        >
          <Icon icon="octicon:list-ordered-24" fontSize={24} />
        </button>
        {isFileUpload && (
          <div
            style={{
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={disabled}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor="file-upload-editor"
              className="file-upload-label cursor-pointer"
            >
              <Icon
                width={20}
                height={20}
                icon="teenyicons:attachment-outline"
              />
            </label>
            <input
              id="file-upload-editor"
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                onFileChange(e.target.files);
              }}
            />
          </div>
        )}
        {isSearchButton && (
          <button
            onClick={handleSearch}
            className={isSearchButtonActive ? "tip-tap-is-active" : ""}
            disabled={disabled}
          >
            <Icon icon="bitcoin-icons:search-filled" fontSize={24} />
          </button>
        )}
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TiptapEditor;
