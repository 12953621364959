import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import API from "./../../../services/ClientApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userInviteeListingPage } from "../../../actions/companyOverviewActions";
import { findObjectByContentTitle } from "../../../helper/getContentObject";

const DeleteUserModal = (props) => {
  const { modalContents } = useSelector((state) => state?.modalData);

  const verifyModalContent = findObjectByContentTitle(
    "remove-user",
    modalContents?.data
  );

  const [dload, setDLoad] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      setDLoad(true);
      const response = await API.post("users/delete", { userId: props.userId });
      if (response?.data?.status === "success") {
        props.getAllUsersAfterUpdate();
        dispatch(userInviteeListingPage());
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        props.handleCloseDeleteModal();
      }
    } catch (error) {
    } finally {
      setDLoad(false);
    }
  };

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={props.handleCloseDeleteModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} />
        </div>
        <h5>{verifyModalContent?.short_description}</h5>
        <h5>{verifyModalContent?.long_description}</h5>
        <p className="del-modal-name">
          {props?.user?.userName ? props?.user?.userName : "this user"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-50 delete-btn-1" onClick={handleSubmit}>
            {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDeleteModal}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserModal;
