import React, { useEffect, useRef, useState } from "react";
import CloseBtn from "../../../assets/icon-close.svg";
import { Modal, Offcanvas, Spinner } from "react-bootstrap";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import MultiSelect from "../../../components/MultiSelect";
import API, { ProjectFormDataAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import P1Icon from "../../../assets/project1.svg";
import P2Icon from "../../../assets/project2.svg";
import P3Icon from "../../../assets/project3.svg";
import P4Icon from "../../../assets/project4.svg";
import P5Icon from "../../../assets/project5.svg";
import InfoIcon from "../../../assets/info.svg";
import PhotoIcon from "../../../assets/photo.svg";
import { v4 as uuidv4 } from "uuid";
import InviteModal from "../../../components/InviteModal/InviteModal";
import ConfirmAddonModal from "../../../components/ConfirmAddonModal";
import { useSelector } from "react-redux";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { useCreateTeamMutation } from "../../../slices/teams/teamsSlice";
import { teamsListAction } from "../../../actions/teamsActions";
import { useDispatch } from "react-redux";

const NewTeam = (props) => {
  const dispatch = useDispatch();
  const [createTeam] = useCreateTeamMutation();
  const [check, setCheck] = useState(false);
  const [load, setLoad] = useState(false);
  const [projProfile, setProjProfile] = useState(0);
  const [projProfileImg, setProjProfileImg] = useState(null);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const { billingDetail } = useSelector((state) => state.billing);
  const [planData, setPlanData] = useState(null);
  const [user, setUser] = useState(null);
  const [cropper, setCropper] = useState(null);
  const imageRef = useRef(null);
  const [showCropModal, setShowCropModal] = useState(false);

  const handleModalClose = () => {
    setShowCropModal(false);
    setCropper(null); // Clean up Cropper instance
  };

  const imageOptions = [
    {
      id: 0,
      url: P1Icon,
    },
    {
      id: 1,
      url: P2Icon,
    },
    {
      id: 2,
      url: P3Icon,
    },
    {
      id: 3,
      url: P4Icon,
    },
    {
      id: 4,
      url: P5Icon,
    },
  ];

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleAddonShow = () => {
    setAddonModal(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file

    // Check if a file is selected
    if (file) {
      // Check if the file type is an image
      if (!file.type.startsWith("image/")) {
        // Display an error message
        toast.error("You can only select image files.", {
          position: toast.POSITION.TOP_CENTER,
        });

        // Optionally clear the selected file input
        event.target.value = null;
        return;
      }

      // Set the selected file
      setFile(file);

      // Assuming file is an image
      setShowCropModal(true);
      const reader = new FileReader();
      reader.onload = () => {
        setProjProfileImg(reader.result); // Set the selected image as projProfile
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Initialize Cropper when the modal opens and an image is selected
    if (showCropModal && file && imageRef.current) {
      const image = imageRef.current;
      image.src = URL.createObjectURL(file);
      setCropper(
        new Cropper(image, {
          // Optional cropping options
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    // Cleanup Cropper when the modal closes or component unmounts
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCropModal, file, imageRef, setCropper]);

  const handleImageUpload = async (image) => {
    const croppedCanvas = cropper.getCroppedCanvas();
    await croppedCanvas.toBlob(async (blob) => {
      const fileNew = new File([blob], `team-icon-${uuidv4()}.jpg`, {
        type: blob.type,
      });
      // Use the blob here, e.g., for upload, display, or download
      if (fileNew) {
        // Assuming file is an image
        setFile(fileNew);
        const reader = new FileReader();
        reader.onload = () => {
          setProjProfileImg(reader.result); // Set the selected image as projProfile
          setShowCropModal(false);
        };
        reader.readAsDataURL(fileNew);
      }
      // Handle API response (e.g., update profile image URL)
    });
  };

  const initialValues = {
    teamName: "",
    teamLink: "",
    teamMembers: [],
    projects: [],
  };

  const validationSchema = Yup.object().shape({
    teamName: Yup.string().required("Team name is required"),
    teamLink: Yup.string()
      .nullable() // Allow null value
      .test("is-url", "Team link must be a valid URL", function (value) {
        if (
          value &&
          value.trim() !== "" &&
          !Yup.string().url().isValidSync(value)
        ) {
          return false;
        }
        return true;
      }),
    teamMembers: Yup.array()
      .required("At least one team member is required")
      .min(1, "At least one person must be invited"),
    // projects: Yup.array()
    //   .required("At least one project is required")
    //   .min(1, "At least one project must be selected"),
  });

  const getPlanData = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPlanData(response?.data?.data?.packageDetails);
      }
      const data = await API.get("users/basic-info");
      setUser(data?.data?.data);
      // dispatch(billingData());
    } catch (error) {}
  };

  useEffect(() => {
    getPlanData();
  }, [props.show]);

  return (
    <Offcanvas
      className={`off-concave-role`}
      show={props.show}
      placement="end"
      data-theme={props.isDark}
    >
      {/* <Offcanvas.Header closeButton>
    <Offcanvas.Title>Notifications</Offcanvas.Title>
  </Offcanvas.Header> */}
      <Offcanvas.Body>
        {props.create === 0 ? (
          <div className="add-team-member-wraper">
            <div className="d-flex justify-content-between mb-3 create-team-modal-header-wrap">
              {" "}
              <h3>
                Create <span>teams</span>
              </h3>{" "}
              {!props.fromProjectForm && (
                <Button
                  icon="fa-solid fa-add"
                  text="Invite Members"
                  width="180px"
                  handleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowModal(true);
                  }}
                  margin="0px 5px 0px 0px"
                />
              )}
            </div>
            <p>
              Make teams to work on specific project or task or even assign them
              a stage of a project.
            </p>

            <div className="listing-form-team-member">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  // Handle your form submission logic here

                  let formData = new FormData();
                  formData.append("teamName", values.teamName);
                  formData.append(
                    "teamLink",
                    `https://${values.teamName}-${uuidv4()}.com`
                  );

                  // Append teamMembers as multiple entries
                  const teamString = values.teamMembers
                    .map((member) => member.id)
                    .join(",");
                  formData.append("teamMembers", teamString);

                  // Append projects as multiple entries
                  const projectIdsString = values.projects
                    .map((project) => project.id)
                    .join(",");
                  formData.append("projects", projectIdsString);

                  formData.append("joinPrivacy", check ? 1 : 0);
                  formData.append("logo", file);
                  formData.append("teamIcon", projProfile);

                  try {
                    setLoad(true);

                    const response = await createTeam(formData);

                    if (response?.data?.status === "success") {
                      dispatch(teamsListAction());

                      toast.success("Team created successfully", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                      });
                      if (props.isMemDetail) {
                        props.getMemberDetails(false);
                      }
                      if (props.fromProjectForm) {
                        props.getTeamList();
                      }
                      props.teamDataAfterUpdate();
                    }
                  } catch (error) {
                    toast.error(error, {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 3000,
                    });
                  } finally {
                    // Reset the form after submission

                    setLoad(false);
                    setCheck(false);
                    setProjProfileImg(null);
                    resetForm();
                    props.handleClose();
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Team name{" "}
                          <span className="required-fields-span">*</span>
                        </label>
                        <input
                          type="text"
                          name="teamName"
                          placeholder="Team name"
                          autoComplete="off"
                          value={values.teamName}
                          onChange={handleChange}
                          isInvalid={touched.teamName && !!errors.teamName}
                          className={`form-control ${
                            touched.teamName && errors.teamName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="teamName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Add people to your team{" "}
                          <span className="required-fields-span">*</span>
                        </label>
                        <div className="team-offconcave-select-wrapper">
                          <MultiSelect
                            options={props?.projectUserList || []}
                            isSelectAll={true}
                            placeholder="Add people"
                            value={values?.teamMembers}
                            isInvalid={
                              touched.teamMembers && !!errors.teamMembers
                            }
                            className={`${
                              touched.teamMembers && errors.teamMembers
                                ? "dept-err"
                                : ""
                            }`}
                            name="teamMembers"
                            menuPlacement="bottom"
                            onChange={(e) => {
                              setFieldValue("teamMembers", e);
                            }}
                          />

                          {touched.teamMembers && errors.teamMembers && (
                            <div className="text-error-department">
                              Please select atleast one team member
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Team link to share </label>
                        <input
                          type="url"
                          name="teamLink"
                          autoComplete="off"
                          value={`https://${values.teamName}-${uuidv4()}.com`}
                          disabled
                          onChange={handleChange}
                          isInvalid={touched.teamLink && !!errors.teamLink}
                          className={`form-control ${
                            touched.teamLink && errors.teamLink
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="https://www.figma.com/file/7SV7vkt3LZ9ujGZovePKVW/Free"
                        />
                        <ErrorMessage
                          name="teamLink"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-center">
                      <div className="form-group">
                        <div className="rounded-2 ">
                          <div
                            className="checkbox create-task-checkbox"
                            onClick={() => setCheck(!check)}
                          >
                            <input type="checkbox" checked={check} />
                            <label className="d-flex align-items-center">
                              <span className="ps-2 create-team-modal-check-text">
                                Anyone can join the team
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Assign Project </label>
                        <div className="team-offconcave-select-wrapper">
                          <MultiSelect
                            options={props?.projectList || []}
                            isSelectAll={true}
                            placehold="Assign project"
                            value={values?.projects}
                            isInvalid={touched.projects && !!errors.projects}
                            className={`${
                              touched.projects && errors.projects
                                ? "dept-err"
                                : ""
                            }`}
                            name="projects"
                            menuPlacement="bottom"
                            onChange={(e) => {
                              setFieldValue("projects", e);
                            }}
                          />

                          {touched.projects && errors.projects && (
                            <div className="text-error-department">
                              Please select atleast one project
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-icon-wrap">
                        <label>
                          {" "}
                          Team Icon{" "}
                          <img
                            src={InfoIcon}
                            alt="info-icon"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Team Icon"
                          />{" "}
                        </label>
                        <ul>
                          {imageOptions?.map((res, id) => (
                            <li>
                              {" "}
                              <label
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setProjProfile(res?.id);
                                }}
                              >
                                {" "}
                                <img
                                  className={`${
                                    projProfile === res?.id
                                      ? "selected-project-profile-img"
                                      : "unselected-project-profile-img"
                                  }`}
                                  src={res?.url}
                                  alt="project-icon"
                                />{" "}
                              </label>
                            </li>
                          ))}

                          <li>
                            {" "}
                            <label htmlFor="file-upload">
                              {" "}
                              <img
                                src={
                                  projProfileImg ? projProfileImg : PhotoIcon
                                }
                                alt="avatar-icon"
                              />{" "}
                            </label>
                            <input
                              type="file"
                              id="file-upload"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-btn-create">
                        <button type="submit">
                          {" "}
                          {load ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Create"
                          )}{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <div className="team-view-wraper">
            <div className="d-flex justify-content-between mb-3 create-team-modal-header-wrap team-view-wraper-head">
              <h3>Team 1</h3>
              <Button
                icon="fa-solid fa-add"
                text="Create Team"
                width="180px"
                handleClick={() => {
                  props.handleCreate(0);
                }}
                margin="0px 5px 0px 0px"
              />
            </div>
            <p>
              {" "}
              Nulla nec sem quis est porta fermentum nulla nunc dui mollis a
              odio eu auctor faucibus lacus donec viverra quam quis vehicula
              hendrerit{" "}
            </p>
            <div className="team-view-left-right-wrap">
              <div className="row">
                <div className="col-md-4">
                  <div className="left-team-main-wrap">
                    <div className="left-team-main-button">
                      {" "}
                      <button className="add-people-btn">
                        {" "}
                        Add People{" "}
                      </button>{" "}
                      <button className="caret-btn-image">
                        <Icon icon="solar:menu-dots-bold" />
                      </button>
                      <div className="clearfix"></div>
                    </div>
                    <div className="member-team-info-wrap">
                      <div className="member-team-wrap">
                        <h5> Members </h5>
                        <span> 5 Members </span>{" "}
                      </div>
                      <div className="team-listing-main-wrap">
                        <ul>
                          <li>
                            <div className="listing-team-wrap">
                              <div className="listing-team-image"></div>
                              <div className="listing-team-text">
                                <h6> John Smith </h6>
                                <span> Admin hr </span>{" "}
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </li>
                          <li>
                            <div className="listing-team-wrap">
                              <div className="listing-team-image"></div>
                              <div className="listing-team-text">
                                <h6> John Smith </h6>
                                <span> Admin hr </span>{" "}
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </li>
                          <li>
                            <div className="listing-team-wrap">
                              <div className="listing-team-image"></div>
                              <div className="listing-team-text">
                                <h6> John Smith </h6>
                                <span> Admin hr </span>{" "}
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </li>
                          <li>
                            <div className="listing-team-wrap">
                              <div className="listing-team-image"></div>
                              <div className="listing-team-text">
                                <h6> John Smith </h6>
                                <span> Admin hr </span>{" "}
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="team-activity-wraper">
                    <h4> Team Activity </h4>
                    <ul>
                      <li>
                        <div className="listing-activity-team">
                          <div className="listing-activity-team-icon">
                            <Icon icon="fluent-mdl2:settings" />
                          </div>
                          <div className="listing-text-activity">
                            <h4> Development </h4>
                            <p>
                              {" "}
                              My Scrum project you created this on Dec 15,2024{" "}
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li>
                        <div className="listing-activity-team">
                          <div className="listing-activity-team-icon">
                            <Icon icon="fluent-mdl2:settings" />
                          </div>
                          <div className="listing-text-activity">
                            <h4> Development </h4>
                            <p>
                              {" "}
                              My Scrum project you created this on Dec 15,2024{" "}
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li>
                        <div className="listing-activity-team">
                          <div className="listing-activity-team-icon">
                            <Icon icon="fluent-mdl2:settings" />
                          </div>
                          <div className="listing-text-activity">
                            <h4> Development </h4>
                            <p>
                              {" "}
                              My Scrum project you created this on Dec 15,2024{" "}
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                      <li>
                        <div className="listing-activity-team">
                          <div className="listing-activity-team-icon">
                            <Icon icon="fluent-mdl2:settings" />
                          </div>
                          <div className="listing-text-activity">
                            <h4> Development </h4>
                            <p>
                              {" "}
                              My Scrum project you created this on Dec 15,2024{" "}
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          className="btn-role-concave"
          onClick={() => {
            props.handleClose();
          }}
        >
          <img src={CloseBtn} alt="close-btn" />
        </button>
        {showModal && (
          <InviteModal
            isDark={props?.isDark}
            showModal={showModal}
            handleCloseModal={() => setShowModal(false)}
            addonModal={addonModal}
            handleAddonClose={handleAddonClose}
            handleAddonShow={handleAddonShow}
            setShowAddModal={setShowAddModal}
            isAdmin={
              user?.canInvite === 1 &&
              user?.roles?.some((role) => role.roleType === "admin")
                ? true
                : false
            }
          />
        )}
        {showAddModal && (
          <ConfirmAddonModal
            isDark={props.isDark}
            handleAddonShow={handleAddonShow}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            billingDetail={billingDetail}
            planData={planData}
          />
        )}
        {showCropModal && (
          <Modal
            show={showCropModal}
            onHide={handleModalClose}
            centered
            size="lg"
            data-theme={props?.isDark}
          >
            <Modal.Header closeButton>
              <div className="modal-heading-wrap">
                <h3>
                  {" "}
                  {/* <span>Choose</span> Your Plan{" "} */}
                  <span>Crop</span> Image{" "}
                </h3>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: "400px" }}>
              <div className="w-100 h-100">
                <img ref={imageRef} alt="crop-item" />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button className="crop-btn" onClick={handleImageUpload}>
                Select
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewTeam;
