// actions/projectCompletedListingActions.js

import {
  PROJECT_COMPLETED_LISTING_REQUEST,
  PROJECT_COMPLETED_LISTING_SUCCESS,
  PROJECT_COMPLETED_LISTING_FAIL,
  PROJECT_COMPLETED_EDIT_SUCCESS,
  PROJECT_COMPLETED_DELETE_SUCCESS,
  PROJECT_COMPLETED_CLEAR_STATE,
} from "../constants/projectCompletedListingConstants";

import { ProjectAPI } from "../services/ClientApi";

// Fetch Completed Projects
export const fetchProjectCompletedListing =
  (pageSize, currentPage) => async (dispatch) => {
    try {
      dispatch({ type: PROJECT_COMPLETED_LISTING_REQUEST });

      const { data } = await ProjectAPI.get(
        `project/completed-list?limit=${pageSize}&page=${currentPage}`
      );

      dispatch({
        type: PROJECT_COMPLETED_LISTING_SUCCESS,
        payload: { data, limit: pageSize, page: currentPage },
      });
    } catch (error) {
      dispatch({
        type: PROJECT_COMPLETED_LISTING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        meta: {
          retryAction: fetchProjectCompletedListing(pageSize, currentPage), // Retry action
        },
      });
    }
  };

// Edit Completed Project
export const editProjectCompleted =
  (projectId, updatedData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROJECT_COMPLETED_EDIT_SUCCESS,
        payload: updatedData,
      });

      // Optionally refetch or update cache
    } catch (error) {}
  };

// Delete Completed Project
export const deleteProjectCompleted =
  (projectId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROJECT_COMPLETED_DELETE_SUCCESS,
        payload: projectId,
      });

      // Optionally refetch or update cache
    } catch (error) {}
  };

// Delete Completed Project
export const projectCompletedClearState = () => async (dispatch) => {
  try {
    dispatch({
      type: PROJECT_COMPLETED_CLEAR_STATE,
    });

    // Optionally refetch or update cache
  } catch (error) {}
};
