import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup"; // To create validation schema
import { useUpdateCompanyWorkingHoursMutation } from "../../../slices/user/userSlice";

function useEditWorkingHoursModal({
  handleCloseModal = () => {},
  data = null,
  status = null,
}) {
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);

  const [updateCompanyWorkingHours, { isLoading: isEditWorkingHoursLoading }] =
    useUpdateCompanyWorkingHoursMutation();

  const initialValues = {
    shiftTitle: data?.shiftTitle || "",
    startTime: data?.startTime ? data?.startTime.slice(0, -3) : "",
    endTime: data?.endTime ? data?.endTime.slice(0, -3) : "",
    days: data?.dayOfWeek ? data?.dayOfWeek.split(",") : [], // Default as empty array
  };

  const daysList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const validationSchema = Yup.object({
    shiftTitle: Yup.string().required("Shift title is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    days: Yup.array()
      .min(1, "At least one day must be selected")
      .required("Select at least one day"),
  });

  // Set initial time values
  useEffect(() => {
    if (data) {
      setStartTimeValue(data?.startTime.slice(0, -3));
      setEndTimeValue(data?.endTime.slice(0, -3));
    }
  }, [data]);

  const handleOnSubmit = async (values) => {
    // Handle form submission
    try {
      // Create payload
      const payload = {
        workingHourId: data?.id,
        shiftTitle: values.shiftTitle,
        dayOfWeek: values.days.join(","), // Assuming days need to be a comma-separated string
        startTime: values.startTime,
        endTime: values.endTime,
        status: values?.status,
      };

      const response = await updateCompanyWorkingHours(payload).unwrap();
      if (response?.status === "success") {
        toast.success(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
        });
      }
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
      });
    } finally {
      handleCloseModal();
    }
  };

  return {
    validationSchema,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    initialValues,
    setEndTimeValue,
    handleOnSubmit,
    daysList,
    isEditWorkingHoursLoading,
  };
}

export default useEditWorkingHoursModal;
