import {
  COMPANY_LISTING_FAIL,
  COMPANY_LISTING_REQUEST,
  COMPANY_LISTING_SUCCESS,
  COMPANY_MODAL_DATA_FAIL,
  COMPANY_MODAL_DATA_REQUEST,
  COMPANY_MODAL_DATA_SUCCESS,
  INVITEE_LISTING_FAIL,
  INVITEE_LISTING_REQUEST,
  INVITEE_LISTING_SUCCESS,
} from "../constants/companyOverviewConstatnt.js";
import API from "../services/ClientApi.js";
import { v4 as uuidv4 } from "uuid";

export const userListingPage = (search) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_LISTING_REQUEST });
    const { data } = await API.post("users/user-listing", { search: search });
    if (data?.data) {
      const modifiedData = data?.data?.map((user) => ({
        ...user,
        departments: user?.departments?.map((dep) => ({
          ...dep,
          uid: uuidv4(), // Generate a unique ID for each department item
        })),
      }));
      dispatch({
        type: COMPANY_LISTING_SUCCESS,
        payload: modifiedData,
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_LISTING_FAIL,
      payload: error.response.data.message,
      meta: {
        retryAction: userListingPage(search), // Retry action
      },
    });
  }
};

export const userInviteeListingPage = () => async (dispatch) => {
  try {
    dispatch({ type: INVITEE_LISTING_REQUEST });
    const { data } = await API.post("users/invited-user-listing", {});
    if (data?.data) {
      const modifiedData = data?.data?.map((user) => ({
        ...user,
        departments: user?.departments?.map((dep) => ({
          ...dep,
          uid: uuidv4(), // Generate a unique ID for each department item
        })),
      }));
      dispatch({
        type: INVITEE_LISTING_SUCCESS,
        payload: modifiedData,
      });
    }
  } catch (error) {
    dispatch({
      type: INVITEE_LISTING_FAIL,
      payload: error.response.data.message,
      meta: {
        retryAction: userInviteeListingPage(), // Retry action
      },
    });
  }
};

export const companyModalDataListing = () => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_MODAL_DATA_REQUEST });
    const { data } = await API.get("users/models/content");
    dispatch({
      type: COMPANY_MODAL_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_MODAL_DATA_FAIL,
      payload: error,
      meta: {
        retryAction: companyModalDataListing(), // Retry action
      },
    });
  }
};
