import React, { useEffect, useRef, useState } from "react";
import AvatarImg from "../../../assets/avatar.svg";
import ProjectDetailSectionTable from "./ProjectDetailSectionTable";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { dateFormatter } from "../../../helper/dateFormatter";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createProjectPhaseAction,
  getProjectsPhasesAction,
} from "../../../actions/projectPhaseActions";
import { Spinner } from "react-bootstrap";
import PlanProjectPhaseDetailSectionTable from "../../ProjectDetail/components/PlanProjectPhases/PlanProjectPhaseDetailSectionTable";
import { Link } from "react-router-dom";
import getProjectIconById from "../../../helper/projectIconGenerator";

const ProjectDetailSection = (props) => {
  const dispatch = useDispatch();
  const flatpickrRef = useRef(null);
  const [edit, setEdit] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());
  const [date, setDate] = useState(null);
  const {
    projectsPhasesList,
    loading: projectsPhasesLoading,
    isError: projectsPhasesIsError,
    error: projectsPhasesError,
  } = useSelector((state) => state.projectPhasesListingData);

  const { loading: createProjectPhaseLoading } = useSelector(
    (state) => state.createProjectPhaseData
  );

  // in this state i have all project phases data
  const [data, setData] = useState([]);

  const getAllProjectPhases = async () => {
    try {
      if (props.projectId) {
        await dispatch(getProjectsPhasesAction({ projectId: props.projectId }));
      }
    } catch (error) {}
  };

  // update the data based on projectsPhasesList
  useEffect(() => {
    if (projectsPhasesList) {
      setData(projectsPhasesList);
    }
  }, [projectsPhasesList]);

  useEffect(() => {
    setData([]);
    getAllProjectPhases();
  }, []);

  const CustomInput = ({ value, onClick }) => (
    <div
      className="custom-input-calender d-flex align-items-center "
      onClick={onClick}
    >
      <FaCalendar className="custom-input-calendar-icon" />
      <span>{value}</span>
    </div>
  );

  const addAnotherPhase = async () => {
    if (data.length === 0 || data === null) {
      // If no phases exist, create a new project with an "Untitled" phase
      const response = await dispatch(
        createProjectPhaseAction({
          projectId: props.projectId,
          phaseName: "title-1",
          phaseDisplayOrder: 1,
        })
      );

      if (response) {
        setData([
          {
            phaseName: response.phaseName,
            id: response.phaseID,
            phaseDisplayOrder: response.phaseDisplayOrder,
          },
        ]);
        setEdit(response.phaseID);
      }
    } else {
      const lastPhase = data[data?.length - 1];
      const lastTaskGroupName = lastPhase.phaseName;
      const lastUntitledIndex = lastTaskGroupName.lastIndexOf("title-");
      let lastUntitledNumber = 0;
      if (lastUntitledIndex !== -1) {
        lastUntitledNumber = parseInt(
          lastTaskGroupName.substring(lastUntitledIndex + 6)
        );
      }

      const newTaskGroupName =
        lastUntitledIndex === -1
          ? "title-1"
          : `title-${lastUntitledNumber + 1}`;

      const response = await dispatch(
        createProjectPhaseAction({
          projectId: props.projectId,
          phaseName: newTaskGroupName,
          phaseDisplayOrder: projectsPhasesList.length,
        })
      );

      if (response) {
        setData([
          ...data,
          {
            phaseName: response.phaseName,
            id: response.phaseID,
            phaseDisplayOrder: projectsPhasesList.phaseDisplayOrder,
          },
        ]);
        setEdit(response.phaseID);
      }
    }
  };
  return (
    <div className="project-plan-main-wraper project-offcanvas-main-wraper">
      <div className="project-detail-top-heading-wrap">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="proj-detail-header-wrap proj-off-detail-header-wrap">
              <img
                className="proj-detail-header-img"
                src={
                  getProjectIconById(props?.project?.projectIcon)
                    ? getProjectIconById(props?.project?.projectIcon)
                    : props?.project?.projectIcon
                }
                alt="projectIcon"
              />
              <h4>
                {" "}
                {props?.project?.projectName}{" "}
                <span>
                  {" "}
                  | Project <span>Plan</span>{" "}
                </span>
              </h4>
              <div className="progress-detail">
                <div
                  className="progress-bar-timeline"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "1%" }}
                >
                  {" "}
                  <span className="sr-only">1% Complete</span>{" "}
                </div>
              </div>
            </div>
            <div
              data-theme={props.isDark}
              className="project-details-main-date-filter project-detail-offcanvas-date d-flex align-items-center justify-content-between"
            >
              <span>
                <Icon
                  onClick={() => flatpickrRef.current.flatpickr.open()}
                  icon="uis:calender"
                />
              </span>
              <Flatpickr
                data-theme={props.isDark}
                className="project-details-task-table-flatpickr-container"
                options={{
                  altInput: true,
                  altFormat: "M d, Y",
                  dateFormat: "Y-m-d",
                  static: true,
                  disableMobile: true,
                }}
                ref={flatpickrRef}
                value={selectedDateFilter}
                onChange={([date]) => {
                  const formattedDate =
                    date.getFullYear() +
                    "-" +
                    ("0" + (date.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + date.getDate()).slice(-2);
                  setSelectedDateFilter(formattedDate);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {data.length > 0 ? (
        <div className="project-plan-table-wrap">
          <ul className="project-listing-detail-section-list">
            {data?.map((res, id) => (
              <li key={id}>
                <PlanProjectPhaseDetailSectionTable
                  data={res}
                  phaseId={res?.id}
                  projectId={props?.projectId}
                  isDark={props.isDark}
                  edit={edit}
                  setEdit={setEdit}
                  index={id}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <p>No Project Phases Found.</p>
        </div>
      )}

      <div className="project-main-button-wrap">
        <div className="d-flex justify-content-between proj-detail-skip-section-wrap">
          <div className="d-flex align-items-center">
            <Button
              icon="fa-solid fa-add"
              text="Add another phase"
              width="200px"
              handleClick={() => {
                if (!createProjectPhaseLoading) {
                  addAnotherPhase();
                }
              }}
              margin="0px 5px 0px 0px"
            />
            {createProjectPhaseLoading && (
              <Spinner
                className="global-spinner"
                isDark={props.isDark}
                size="small"
              />
            )}
          </div>
          <div className="">
            <div className="project-phase-right-btn">
              <ul className="mb-0">
                <li>
                  {" "}
                  <Link
                    to={`/${localStorage.getItem(
                      "company"
                    )}/task-management-porojects`}
                  >
                    <button className="skip-button-wrap"> Skip </button>{" "}
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to={`/${localStorage.getItem(
                      "company"
                    )}/task-management-porojects`}
                  >
                    <button className="create-project-btn">
                      Create Project
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailSection;
