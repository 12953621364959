import React from "react";
import Cookies from "js-cookie";

const clearAllCookies = () => {
  const cookies = document.cookie.split("; "); // Get all cookies

  cookies.forEach((cookie) => {
    const [name] = cookie.split("=");
    Cookies.remove(name, { path: "/", domain: process.env.REACT_APP_DOMAIN });
  });
};

function saveSwitchCompanyCookies(link) {
  clearAllCookies();
  const cookieOptions = {
    path: "/",
  };
  // Only set domain for production
  if (window.location.hostname !== "localhost") {
    cookieOptions.domain = process.env.REACT_APP_DOMAIN; // Works on real domains
    cookieOptions.secure = true; // Ensures security on HTTPS
    cookieOptions.sameSite = "None"; // Required for cross-domain cookies
  }

  Cookies.set(
    "access_token",
    localStorage.getItem("access_token"),
    cookieOptions
  );
  Cookies.set(
    "permissions",
    localStorage.getItem("permissions"),
    cookieOptions
  );
  Cookies.set("company", localStorage.getItem("company"), cookieOptions);
  Cookies.set("enableSwitch", true, cookieOptions);
}

export default saveSwitchCompanyCookies;
