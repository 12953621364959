import React, { useEffect, useState } from "react";
import WrapperUsersList from "./WrapperUsersList";
import WrapperUserChat from "./WrapperUserChat";

function LiveChatWrapper({
  chats,
  room,
  setRoom,
  user,
  roomChats,
  socket,
  setMessages,
  handleByWeekLoad,
  prevDays,
  days,
  setPrevDays,
  isDark,
  maxMessages,
  lastSeen,
  initialLoad,
  roomChangeLoad,
  setRoomChangeLoad,
  setRoomId,
  setDays,
  online,
  setOnline,
  away,
  setAway,
  setDayState,
  setUnreadCount,
}) {
  // data list after searching name
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSearchData(chats);
  }, [chats]);

  return (
    <div data-theme={isDark} className="live-chat-wrapper">
      <WrapperUsersList
        selectedChat={room}
        setSelectedChat={setRoom}
        setSearchData={setSearchData}
        searchData={searchData}
        chats={chats}
        socket={socket}
        roomChangeLoad={roomChangeLoad}
        setRoomChangeLoad={setRoomChangeLoad}
        setRoomId={setRoomId}
        setDays={setDays}
        setOnline={setOnline}
        setAway={setAway}
        setDayState={setDayState}
        setUnreadCount={setUnreadCount}
      />
      <WrapperUserChat
        selectedChat={room}
        user={user}
        roomChats={roomChats}
        socket={socket}
        setMessages={setMessages}
        handleByWeekLoad={handleByWeekLoad}
        prevDays={prevDays}
        days={days}
        setPrevDays={setPrevDays}
        chats={chats}
        isDark={isDark}
        maxMessages={maxMessages}
        lastSeen={lastSeen}
        initialLoad={initialLoad}
        roomChangeLoad={roomChangeLoad}
        online={online}
        away={away}
      />
    </div>
  );
}

export default LiveChatWrapper;
