import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CloseBtn from "../../../assets/icon-close.svg";
import { ProjectAPI, ProjectFormDataAPI } from "../../../services/ClientApi";
import { FileIcon, defaultStyles } from "react-file-icon";
import SearchNew from "../../../assets/search-new-icon.svg";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ConfirmDeleteProjectDocument from "./ConfirmDeleteProjectDocument";
import { getFileType } from "../../../helper/getFileType";
import {
  calculateTotalSize,
  validateFile,
} from "../../Tasks/components/CreateTaskModal";

const ProjectDrive = (props) => {
  const [load, setLoad] = useState(false);
  const [original, setOriginal] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [search, setSearch] = useState("");
  const [
    isShowConfirmDeleteProjectDocument,
    setIsShowConfirmDeleteProjectDocument,
  ] = useState({ status: false, id: null, name: null });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  // Allowed extensions
  const allowedExtensions = [
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "doc",
    "docx",
    "txt",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "gif",
    "bmp",
    "svg",
    "zip",
    "rar",
    "mp3",
    "mp4",
  ];

  const handleUpload = async () => {
    const totalSize = calculateTotalSize(selectedFiles);

    if (totalSize > 8 * 1024 * 1024) {
      // 8MB in bytes
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return; // Prevent further execution if validation fails
    }

    // Filter files to ensure only valid extensions are uploaded
    const filteredFiles = selectedFiles.filter((file) => validateFile(file));

    // if (filteredFiles.length === 0) {
    //   // Handle case where no valid files were selected
    //   toast.error("Please enter a valid file.", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }

    try {
      setLoad(true);

      let formData = new FormData();
      filteredFiles.forEach((file) => {
        formData.append("attachments", file);
      });
      formData.append("projectId", props.projectId);

      const response = await ProjectFormDataAPI.post(
        "project/pm-add-attachment",
        formData
      );

      if (response.status === 200) {
        toast.success("Task updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        await getAttachments(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoad(false);
      setSelectedFiles([]);
    }
  };

  const getAttachments = async (load = true) => {
    try {
      load && setLoad(true);
      const response = await ProjectAPI.post("general/pm-drive", {
        type: "projects", //all,project,task
        projectId: props.projectId,
      });

      if (response?.status === 201) {
        setOriginal(response?.data?.data);
        setAttachments(response?.data?.data);
      }
    } catch (error) {
    } finally {
      load && setLoad(false);
    }
  };

  const filterList = async () => {
    let filteredFiles = [...original]; // Copy the original list

    // Apply search query filter
    filteredFiles = filteredFiles.filter((file) =>
      file.attachmentName?.toLowerCase().includes(search?.toLowerCase())
    );

    setAttachments(filteredFiles);
  };

  useEffect(() => {
    getAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const fileViewers = {
    image: (url) => url,
    video: (url) => url,
    pdf: (url) => url,
    doc: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    docx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xls: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xlsx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    ppt: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    pptx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    default: (url) => url, // Fallback for other file types
  };

  const getFileViewerUrl = (fileType, fileUrl) => {
    return fileViewers[fileType]
      ? fileViewers[fileType](fileUrl)
      : fileViewers.default(fileUrl);
  };

  const handleFileClick = (file) => {
    const fileType = getFileType(file?.attachmentName);
    const fileViewerUrl = getFileViewerUrl(fileType, file?.attachmentPath);

    // Open the file in a new tab
    window.open(fileViewerUrl, "_blank", "noopener,noreferrer");
  };
  // const handleCloseFileViewer = () => {
  //   setShowFileViewer(false);
  // };

  const handleDeleteFile = async (id) => {
    try {
      const updatedFiles = attachments.filter((file) => file.id !== id);
      const updatedFilesOriginal = original.filter((file) => file.id !== id);
      setAttachments(updatedFiles);
      setOriginal(updatedFilesOriginal);
      const response = await ProjectAPI.post("project/delete-attachment", {
        attachmentId: id,
        projectId: props.projectId,
      });
      if (response.status === 200) {
        setIsShowConfirmDeleteProjectDocument({
          status: false,
          id: null,
          name: null,
        });
        toast.success("Attachment deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDownload = (url, isPdf) => {
    const link = document.createElement("a");
    link.href = url + `?t=${new Date().getTime()}`;
    if (isPdf) {
      link.target = "_blank";
    }
    link.download = url.split("/").pop(); // Extract the file name from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Hide loader after download
    link.onload = () => {};
  };

  return (
    <Offcanvas
      className={`off-concave-role`}
      show={props.show}
      // onHide={() => {
      //   props.handleClose();
      // }}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        <div className="w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
              <h4 className="projects-heading_wrap projects-detail-heading-wrap">
                Drive
              </h4>
              <div className="timeline-listing-search">
                <ul>
                  <li>
                    <div className="form-group">
                      <button>
                        <img src={SearchNew} alt="search-icon" />
                      </button>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept={allowedExtensions
                          .map((ext) => `.${ext}`)
                          .join(",")} // Accept all file types except .exe
                        multiple // Allow multiple file selection
                      />
                      <Button
                        icon="fa-solid fa-add"
                        text="Upload"
                        width="220px"
                        handleClick={(e) => {
                          handleButtonClick();
                        }}
                        margin="0px 5px 0px 0px"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {load ? (
          <div className="w-100 d-flex align-items-center justify-content-center drive-shimmer-wrap">
            <ShimmerSimpleGallery imageHeight={150} />
          </div>
        ) : (
          <div className="row">
            {attachments && attachments.length > 0
              ? attachments.map((file, id) => (
                  <div className="col-md-3 file-container" key={id}>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center file-wrap-container position-relative"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileClick(file);
                      }}
                    >
                      {/* {getFileType(file?.attachmentName) !== "image" && ( */}
                      <FileIcon
                        extension={getFileType(file?.attachmentName)}
                        // {...defaultStyles.getFileType(file?.attachmentName)}
                        {...defaultStyles[getFileType(file?.attachmentName)]}
                      />
                      {/* )} */}
                      <p>
                        {file?.attachmentName.length > 20
                          ? file?.attachmentName.substring(0, 15) + "..."
                          : file?.attachmentName}
                      </p>
                      <span
                        className="drive-delete-button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setIsShowConfirmDeleteProjectDocument({
                            status: true,
                            id: file?.id,
                            name: file?.attachmentName,
                          });
                        }}
                      >
                        <Icon icon="mingcute:delete-2-fill" size={36} />
                      </span>
                      <span
                        className="drive-download-button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDownload(
                            file?.attachmentPath,
                            true
                            // getFileType(file?.attachmentPath) === "pdf"
                            //   ? true
                            //   : false
                          );
                        }}
                      >
                        <Icon icon="tabler:download" size={36} />
                      </span>
                    </div>
                  </div>
                ))
              : !load && (
                  <p className="no-attachment-txt">No documents found.</p>
                )}
          </div>
        )}

        <ConfirmDeleteProjectDocument
          showDeleteModal={isShowConfirmDeleteProjectDocument}
          handleCloseDeleteModal={() => {
            setIsShowConfirmDeleteProjectDocument({
              status: false,
              id: null,
              name: null,
            });
          }}
          documentName={isShowConfirmDeleteProjectDocument?.name}
          handleDeleteFile={handleDeleteFile}
          isDark={props.isDark}
          type="deleteProjectDocument"
        />

        <button
          className="btn-role-concave"
          onClick={() => {
            props.handleClose();
          }}
        >
          <img src={CloseBtn} alt="close-btn" />
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProjectDrive;
