import React, { useEffect, useState } from "react";
import InfoIcon from "../../../assets/info.svg";
import { Icon } from "@iconify/react";
import { Tooltip } from "react-tooltip";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ProjectAPI } from "../../../services/ClientApi";

const TeamDetailTimelineGrids = (props) => {
  const navigate = useNavigate();
  // State to manage dropdown visibility for each list item
  const [showDropdownIndex, setShowDropdownIndex] = useState(null);
  const [data, setData] = useState(props?.timelineGrid);

  // Function to toggle the selected property of an item in type_count
  const toggleSelected = async (parentId, childId, status) => {
    props.setTimelineGrid((prevState) =>
      prevState.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            type_count: item.type_count.map((subItem) => {
              if (subItem.id === childId) {
                return {
                  ...subItem,
                  selected: !subItem.selected,
                };
              }
              return subItem;
            }),
          };
        }
        return item;
      })
    );
    await updateProject({ filterOptionId: childId, status: status });
  };

  // Update name
  const updateProject = async (data) => {
    try {
      await ProjectAPI.post(`timeline/filters-status-update`, data);
    } catch (error) {
      // toast.error(error, { position: toast.POSITION.TOP_CENTER });
    }
  };

  return (
    <div className="dashboard-listing-title-wraper">
      <Tooltip id="my-tooltip" style={{ zIndex: 2 }} />
      <ul className="row">
        {props?.timelineGrid?.map((res, id) => (
          <li
            className="col-md-4 position-relative"
            key={id}
            onMouseEnter={() => setShowDropdownIndex(id)} // Show dropdown on mouse enter
            onMouseLeave={() => setShowDropdownIndex(null)} // Hide dropdown on mouse leave
          >
            <div className="listing-tile-dash">
              <div className="d-flex align-items-center justify-content-between heading-tile-dash">
                <div className="d-flex align-items-center timeline-heading-title-wrap">
                  <div className="timeline-heading-title-img">
                    {" "}
                    <Icon icon={res?.icon} />
                  </div>
                  <strong className="position-relative">
                    {" "}
                    {res?.name}{" "}
                    <span>
                      {showDropdownIndex === id && ( // Render dropdown only when showDropdownIndex matches the current index
                        <Dropdown className="roles-drop-down timeline-dropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="menu-btn">
                              <Icon icon="mi:options-horizontal" />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {res?.type_count?.map((r, id) => (
                              <div className="py-1 ps-1 w-100" key={id}>
                                <div
                                  className="checkbox create-task-checkbox create-timeline-grid"
                                  onClick={() =>
                                    toggleSelected(
                                      res.id,
                                      r.id,
                                      r.selected === true ? false : true
                                    )
                                  } // Toggle selected property
                                >
                                  <input
                                    type="checkbox"
                                    checked={r?.selected}
                                    readOnly
                                  />
                                  <label className="timeline-drop-text">
                                    <span className="ps-1">{r?.name}</span>
                                  </label>
                                </div>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </span>
                  </strong>
                </div>
                <span> {res?.count} </span>{" "}
              </div>
              <div className="info-title-wrap">
                {res?.type_count?.map(
                  (data, id) =>
                    data?.selected && (
                      <span key={id} className="timeline-per-grid-options">
                        <span className="d-flex">
                          <p
                            onClick={() => {
                              if (res?.name === "Projects") {
                                localStorage.setItem(
                                  "projectFilter",
                                  data?.type
                                );
                              }
                              if (res?.name === "Tasks") {
                                localStorage.setItem("taskFilter", data?.type);
                              }
                              navigate(
                                `/${localStorage.getItem("company")}/${
                                  res?.url
                                }`
                              );
                            }}
                          >
                            {data?.name.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}
                          </p>
                          <a>
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={data?.tooltip}
                            />
                          </a>
                        </span>
                        <strong>({data?.count}) </strong>
                      </span>
                    )
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeamDetailTimelineGrids;
