import React from "react";
import TeamMemberDetailsMain from "./components/TeamMemberDetailsMain";
import "./styles/teamMemberDetails.scss"
function TeamMemberDetails(props) {
  return (
    <TeamMemberDetailsMain
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
}

export default TeamMemberDetails;
