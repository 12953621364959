import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CloseBtn from "../../../assets/icon-close.svg";
import TeamDetailTimelineGrid from "./TeamDetailTimelineGrid";
import TeamDetailProjectGrid from "./TeamDetailProjectGrid";
import { ProjectAPI } from "../../../services/ClientApi";
import getProjectIconById from "../../../helper/projectIconGenerator";
import {
  ShimmerBadge,
  ShimmerPostItem,
  ShimmerTable,
} from "react-shimmer-effects";

const TeamDetailModal = (props) => {
  const [load, setLoad] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [grid, setGrid] = useState(null);

  const getTeamData = async () => {
    try {
      setLoad(true);
      const response = await ProjectAPI.post("teams/detail", {
        teamId: props.id,
      });
      const response1 = await ProjectAPI.post("teams/stats", {
        teamId: props.id,
      });
      setGrid(convertObject(response1?.data?.data, iconMapping, urlMapping));
      if (response?.status === 201) {
        setTeamData(response?.data?.data[0]);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  function convertObject(data, iconMapping = {}, urlMapping = {}) {
    const result = [];
    let totalByType = 0; // Track total count across types (optional)

    for (const category in data) {
      const typeCounts = [];
      for (const type in data[category]) {
        // Calculate total count if needed
        totalByType += data[category][type].count;

        let typeValue;
        switch (category.toLowerCase()) {
          case "projects":
            typeValue =
              type === "overDueProject"
                ? 1
                : type === "completedProject"
                ? 2
                : 0; // Map tasks based on type names
            break;
          case "tasks":
            typeValue =
              type === "overDueTasks"
                ? 1
                : type === "criticalTasks"
                ? "Critical"
                : "In Progress"; // Map tasks based on type names
            break;
          default:
            typeValue = type; // Use type name as default
        }
        // Include properties you need in the type_count object
        typeCounts.push({
          name: type.replace(/([A-Z])/g, " $1").trim(), // Convert camelCase to human-readable names (optional)
          count: data[category][type].count,
          tooltip: type.replace(/([A-Z])/g, " $1").trim(),
          type: typeValue, // Add type property with specific ordering
          selected: data[category][type].isSelected,
          id: data[category][type].id,
        });
      }

      const icon = iconMapping[category.toLowerCase()] || "default-icon.svg"; // Use provided icon mapping or default
      const url = urlMapping[category.toLowerCase()] || "url"; // Use provided url mapping or default
      result.push({
        id: result.length, // Assign unique but sequential IDs
        name: category,
        // Include only properties you need in the top-level object
        count: totalByType, // Include total count if needed
        icon: icon,
        url: url,
        type_count: typeCounts,
      });

      totalByType = 0; // Reset total count for the next category
    }

    return result;
  }

  // usage with icon mapping
  const iconMapping = {
    projects: "octicon:project-24",
    tasks: "fluent:tasks-app-28-regular",
    teams: "teenyicons:users-outline",
  };

  // usage with url mapping
  const urlMapping = {
    projects: "task-management-porojects",
    tasks: "task-management-tasks",
    teams: "task-management-teams",
  };

  useEffect(() => {
    getTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Offcanvas
      className="off-concave-team"
      show={props.show}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        <div className="w-100">
          {load ? (
            <div className="w-100 d-flex flex-column align-items-center">
              <div className="w-100 text-start">
                <ShimmerBadge width={200} />
              </div>
              <div className="w-100 dashboard-listing-title-wraper">
                <ul className="row">
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                </ul>
              </div>
              <div className="w-100">
                <ShimmerTable row={5} />
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-3 team-detail-section-header">
                <img
                  className="team-detail-icon-img"
                  src={
                    getProjectIconById(teamData?.teamInfo?.teamIcon)
                      ? getProjectIconById(teamData?.teamInfo?.teamIcon)
                      : teamData?.teamInfo?.teamIcon
                  }
                  alt="team-icon"
                />
                <span className="ms-2">{teamData?.teamInfo?.teamName}</span>
              </div>
              <TeamDetailTimelineGrid
                isDark={props.isDark}
                timelineGrid={grid}
                setTimelineGrid={setGrid}
              />
              {teamData?.projects?.length > 0 && (
                <TeamDetailProjectGrid
                  isDark={props.isDark}
                  projects={teamData?.projects}
                />
              )}
            </>
          )}
        </div>
        {/** close icon */}
        <button className="btn-role-concave" onClick={props.handleCloseModal}>
          <img src={CloseBtn} alt="Close" />
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TeamDetailModal;
