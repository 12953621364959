import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import EditCompanyInfoModal from "../modals/EditCompanyInfoModal";
import userProfileBlank from "../../../assets/blank_profile.svg";

const CompanyInfo = ({ isDark, companyProfile, placement }) => {
  const [openEditModal, setOpenEditModal] = useState();
  // console.log("CompanyProfile : ", companyProfile);

  return (
    <>
      {placement === "profile" && (
        <div className="company-info-header mb-3">
          <p>
            Company's <span>Info</span>
          </p>
          {/* <button
            className="company-profile-edit-button"
            onClick={() => setOpenEditModal(true)}
          >
            <Icon icon="basil:edit-outline" fontSize={22} /> Edit
          </button> */}
        </div>
      )}
      <div className="company-info-wrapper">
        {placement !== "profile" && (
          <div className="company-info-header">
            <p>
              Company's <span>Info</span>
            </p>
            <button
              className="company-profile-edit-button"
              onClick={() => setOpenEditModal(true)}
            >
              <Icon icon="basil:edit-outline" fontSize={22} /> Edit
            </button>
          </div>
        )}
        {placement === "profile" && (
          <div className="company-info-header justify-content-end">
            <button
              className="company-profile-edit-button"
              onClick={() => setOpenEditModal(true)}
            >
              <Icon icon="basil:edit-outline" fontSize={22} /> Edit
            </button>
          </div>
        )}
        <div className="company-info-section">
          <div className="company-info-image-section">
            <img
              src={companyProfile?.companyLogo || userProfileBlank}
              alt="company-icon"
            />
          </div>
          <div className="company-info-basic mt-1">
            <h3 className="p-0 border-0">{companyProfile?.companyName}</h3>
            {companyProfile?.companyEmail && (
              <p>
                <span>
                  <Icon icon="ic:outline-email" fontSize={16} />
                </span>
                {companyProfile?.companyEmail}
              </p>
            )}
            {companyProfile?.companyPhone && (
              <p>
                {" "}
                <span>
                  <Icon icon="solar:phone-linear" fontSize={16} />
                </span>
                {companyProfile?.companyPhone}
              </p>
            )}
            {companyProfile?.companyIndustry &&
              companyProfile?.companyIndustry?.industryName && (
                <p>
                  {" "}
                  <span>
                    <Icon icon="lineicons:briefcase-2" fontSize={16} />
                  </span>
                  {companyProfile?.companyIndustry?.industryName}
                </p>
              )}
          </div>
        </div>
        {companyProfile?.companyDescription && (
          <div className="company-info-about">
            <h3 className="p-0 border-0">About</h3>
            <p>{companyProfile?.companyDescription}</p>
          </div>
        )}
        {openEditModal && (
          <EditCompanyInfoModal
            show={openEditModal}
            isDark={isDark}
            handleCloseModal={() => setOpenEditModal(false)}
            companyProfile={companyProfile}
          />
        )}
      </div>
    </>
  );
};

export default CompanyInfo;
