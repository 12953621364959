import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import EditWorkingHoursModal from "../modals/EditWorkingHourModal";
import DeleteScheduleModal from "../modals/DeleteScheduleModal";
import { convertDaysToRange } from "../../../helper/companyHelper";
import { Spinner } from "react-bootstrap";
import useEditWorkingHoursStatus from "../services/useEditeWorkingHoursStatus";

const CompanyHourCard = ({ data, isDark }) => {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { handleOnSubmit, isEditWorkingHoursLoading } =
    useEditWorkingHoursStatus();

  return (
    <div className="company-work-hour-item-wrapper">
      <div className="company-work-hour-item">
        <div className="company-work-hour-item-section-1">
          <div className="company-work-hour-item-days">
            <div className="company-work-hour-item-days-icon">
              <Icon icon="lets-icons:date-range" fontSize={25} />
            </div>
            <div className="company-work-hour-item-days-text">
              <p>{data?.shiftTitle}</p>
              <h3 className="p-0 border-0">
                {convertDaysToRange(data?.dayOfWeek)}
              </h3>
            </div>
          </div>
          <div class="vr company-work-hour-item-vr"></div>
          <div className="company-work-hour-item-days company-work-hour-item-time">
            <div className="company-work-hour-item-days-icon">
              <Icon icon="ion:time-outline" fontSize={25} />
            </div>
            <div className="company-work-hour-item-days-text">
              <p>Working Time</p>
              <h3 className="p-0 border-0">
                {data?.startTime.slice(0, -3)}-{data?.endTime.slice(0, -3)}
              </h3>
            </div>
          </div>
        </div>
        <div className="company-work-hour-item-active">
          <button
            className={`${
              data?.status
                ? "company-work-hour-item-active-btn"
                : "company-work-hour-item-inactive-btn"
            }`}
            disabled={isEditWorkingHoursLoading}
            onClick={() => handleOnSubmit(data)}
          >
            {isEditWorkingHoursLoading && <Spinner size="sm" />}{" "}
            {data?.status ? "Active" : "In Active"}
          </button>
        </div>
      </div>
      <div className="company-work-hour-item-actions">
        <button
          className="company-profile-edit-button"
          onClick={() => setShow(true)}
        >
          <Icon icon="basil:edit-outline" fontSize={22} /> Edit
        </button>
        <button
          className="company-work-delete-button"
          onClick={() => setShowDelete(true)}
        >
          <Icon icon="fluent:delete-24-regular" fontSize={22} /> Delete
        </button>
      </div>
      {show && (
        <EditWorkingHoursModal
          show={show}
          handleCloseModal={() => setShow(false)}
          isDark={isDark}
          data={data}
        />
      )}
      {showDelete && (
        <DeleteScheduleModal
          data={data}
          show={showDelete}
          handleCloseModal={() => setShowDelete(false)}
          isDark={isDark}
        />
      )}
    </div>
  );
};

export default CompanyHourCard;
