/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/avatar.svg";
import { sortCheckedMembersOnTop } from "../../../helper/sortCheckedMembersOnTop";
import { toast } from "react-toastify";
import { useUpdateCompletedTaskMemberDataMutation } from "../../../slices/tasks/completedTasks.Slice";
import { useDispatch, useSelector } from "react-redux";
import { teamsListAction } from "../../../actions/teamsActions";
import SearchIcon from "../../../assets/search-icon.png";
import { isPermitted } from "../../../helper/isPermitted";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

function TasksListingMainMembersEditor(props) {
  const dispatch = useDispatch();
  const { teamsOptionsList } = useSelector((state) => state.teamsListData);
  const [currentTab, setCurrentTab] = useState(3);
  const inputRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const [autoFocus, setAutoFocus] = useState(true);

  const userListRef = useRef(null);

  useEffect(() => {
    // Function to handle tab clicks
    const handleTabClick = (event) => {
      const target = event.target;
      const tabIndex = parseInt(target.getAttribute("data-tab-index"), 10);
      if (!isNaN(tabIndex)) {
        setCurrentTab(tabIndex);
      }
    };

    // Get the dropdown tab bar element
    const tabBar = document.querySelector(".task-user-filter-dropdown-tab-bar");

    // Check if the element exists before adding event listener
    if (tabBar) {
      tabBar.addEventListener("click", handleTabClick);
    }

    // Fetch teams list if not available
    if (!teamsOptionsList) {
      dispatch(teamsListAction());
    }

    // Cleanup function to remove the event listener
    return () => {
      if (tabBar) {
        tabBar.removeEventListener("click", handleTabClick);
      }
    };
  }, [teamsOptionsList, dispatch]); // Removed `document` from dependencies

  // Prevent AG Grid from intercepting Enter key
  const stopEditingOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Stop AG Grid from handling Enter
      event.stopPropagation();
      // Find the currently focused option in react-select
      const focusedOption = document.querySelector(
        ".task-user-filter-dropdown-menu-item-focused"
      );

      if (focusedOption) {
        focusedOption.click(); // Simulate a click on the focused option
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", stopEditingOnEnter, true);
    return () =>
      document.removeEventListener("keydown", stopEditingOnEnter, true);
  }, [props.api]);
  // Type id based on stypeName
  const getTypeID = (typeName) => {
    const typeObj =
      props?.projectTypes?.length > 0 &&
      props?.projectTypes?.find((type) => type?.typeName === typeName);
    return typeObj ? typeObj.id : null;
  };

  // Modal search functionality
  const [search, setSearch] = useState("");
  const [userSearchList, setUserSearchList] = useState(props?.userList);

  useEffect(() => {
    setUserSearchList(props.userList);
  }, [props.userList]);

  const handleSearch = (event) => {
    const filteredList = props.userList.filter((user) =>
      user.userName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch(event.target.value);
  };

  // Handle clearing the search input
  const clearSearch = () => {
    const filteredList = props.userList.filter((user) =>
      user.userName.toLowerCase().includes("".toLowerCase())
    );
    setUserSearchList(filteredList);
    setSearch("");
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "ArrowDown") {
      // Move focus down
      setFocusedIndex((prevIndex) =>
        prevIndex < userSearchList.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      // Move focus up
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : userSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter") {
      // Select the focused item

      setIsClicked(true);
      event.preventDefault();
    } else if (event.key === "Escape") {
      // Close the dropdown

      event.preventDefault();
    }
  };

  // Scroll the focused item into view
  useEffect(() => {
    console.log("useEffect", "focusedIndex", focusedIndex);
    console.log("userListRef", userListRef.current);

    if (userListRef.current) {
      const focusedElement = userListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  return (
    <div
      // ref={editorRef}
      onKeyDown={handleKeyDown}
      data-theme={props.isDark}
      className="rounded-3 top-0 shadow-lg task-table-user-filter task-user-filter-dropdown"
    >
      <div className="p-0 w-100 h-100 d-flex custom-user-selection-main-container">
        <div className="task-user-filter-dropdown-tab-bar">
          <ul className="d-flex flex-column align-items-start custom-horizental-scrollbar p-0 m-0 custom-user-type-list">
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 pt-0 ${
                  currentTab === 3 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(3)}
              >
                Assigned to
              </a>
              <div></div>
            </li>
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                  currentTab === 2 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(2)}
              >
                {" "}
                Task Owner
              </a>
              <div></div>
            </li>
            <li>
              <a
                className={`d-flex justify-content-between w-100 custom-user-type-text-anchor px-0 ${
                  currentTab === 1 ? "active-user-type" : ""
                }`}
                onClick={() => setCurrentTab(1)}
              >
                Task Observer
              </a>
              <div></div>
            </li>
            {/* <li>
              <a
                className={`${currentTab === 4 ? "active-overview" : ""}`}
                onClick={() => setCurrentTab(4)}
              >
                {" "}
                Teams{" "}
              </a>
              <div></div>
            </li> */}
            <div className="w-100 "></div>
          </ul>
        </div>
        <div className="d-flex flex-column custom-user-selection-main-container">
          <div className="listing-team-modal-search-input-wrap position-relative">
            <input
              ref={inputRef}
              type="text"
              name="search"
              value={search}
              onChange={handleSearch}
              placeholder="Search"
              autoComplete="off"
              autoFocus // Optionally ensure the input regains focus
              // onFocus={() => console.log("Input focused")}
              onBlur={() => inputRef.current.focus()}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                inputRef.current.focus();
              }}
            />
            {search ? (
              <span className="custom-user-search-icon" onClick={clearSearch}>
                &#x2715;
              </span>
            ) : (
              <img
                src={SearchIcon}
                className="custom-user-search-icon"
                alt="search-icon"
                width={20}
                height={20}
              />
            )}
          </div>
          <div className="user-list-main-container custom-vertical-scrollbar">
            {currentTab === 1 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                setCurrentTab={setCurrentTab}
                members={userSearchList}
                selectedUsers={props?.params?.data?.taskMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                listingType={props.listingType}
                pageParams={props.pageParams ? props.pageParams : {}}
                type={"MEMBER_LIST"}
                updateTaskMemberData={props.updateTaskMemberData}
                updatePhaseTaskMemberData={props.updatePhaseTaskMemberData}
                getTaskList={props.getTaskList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
            {currentTab === 2 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                members={userSearchList}
                selectedUsers={props?.params?.data?.taskMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                listingType={props.listingType}
                pageParams={props.pageParams ? props.pageParams : {}}
                type={"MEMBER_LIST"}
                updateTaskMemberData={props.updateTaskMemberData}
                updatePhaseTaskMemberData={props.updatePhaseTaskMemberData}
                getTaskList={props.getTaskList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
            {currentTab === 3 && (
              <TaskUsersListForFilter
                currentTab={currentTab}
                isDark={props.isDark}
                members={userSearchList}
                selectedUsers={props?.params?.data?.taskMembers}
                params={props.params}
                getTypeID={getTypeID}
                isOwner={props.isOwner}
                listingType={props.listingType}
                pageParams={props.pageParams ? props.pageParams : {}}
                type={"MEMBER_LIST"}
                updateTaskMemberData={props.updateTaskMemberData}
                updatePhaseTaskMemberData={props.updatePhaseTaskMemberData}
                getTaskList={props.getTaskList}
                userListRef={userListRef}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const TaskUsersListForFilter = ({
  currentTab,
  isDark,
  members,
  selectedUsers,
  params,
  getTypeID,
  isOwner,
  listingType,
  pageParams,
  teams,
  type,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  getTaskList,
  userListRef,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const ownerCount = selectedUsers?.filter(
    (member) => member.memberType === "owner"
  ).length;

  // if (type === "MEMBER_LIST") {
  const newMembers = sortCheckedMembersOnTop(
    members,
    selectedUsers,
    currentTab
  );

  return newMembers?.length > 0 ? (
    <div
      ref={userListRef}
      className="pe-2 task-user-filter-dropdown-menu custom-vertical-scrollbar my-2"
    >
      {newMembers.map((res, id) => (
        <TaskUsersListItemForFilter
          key={id}
          member={res}
          selectedUsers={selectedUsers}
          params={params}
          getTypeID={getTypeID}
          isOwner={isOwner}
          ownerCount={ownerCount}
          listingType={listingType}
          pageParams={pageParams}
          updateTaskMemberData={updateTaskMemberData}
          updatePhaseTaskMemberData={updatePhaseTaskMemberData}
          type={
            currentTab === 1
              ? "observer"
              : currentTab === 2
              ? "owner"
              : "member"
          }
          getTaskList={getTaskList}
          index={id}
          userListRef={userListRef}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          setIsClicked={setIsClicked}
          isClicked={isClicked}
        />
      ))}
    </div>
  ) : (
    <div className="task-user-filter-dropdown-menu custom-vertical-scrollbar my-2">
      <p>No members found</p>
    </div>
  );
};

const TaskUsersListItemForFilter = ({
  member,
  selectedUsers,
  type,
  params,
  getTypeID,
  isOwner,
  ownerCount,
  typePage,
  pageParams,
  listingType,
  updateTaskMemberData,
  updatePhaseTaskMemberData,
  getTaskList,
  index,
  focusedIndex,
  setFocusedIndex,
  setIsClicked,
  isClicked,
}) => {
  const [updateCompletedTaskMemberData] =
    useUpdateCompletedTaskMemberDataMutation();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(
      selectedUsers?.some(
        (user) => user.userId === member?.userId && user.memberType === type
      )
    );
  }, [selectedUsers, member, type]);

  const convertToObject = (data) => {
    return data.map((item) => {
      return {
        [getTypeID(item.memberType)]: item.userId,
      };
    });
  };

  // Based on which page listing is present and member is updated of a task, update that listing only
  const listingTypeHandlers = {
    COMPLETED_TASKS: updateCompletedTaskMemberData,
    ALL_TASKS: updateTaskMemberData,
    PHASE_TASKS: updatePhaseTaskMemberData,
  };

  const updateMembers = async (data) => {
    try {
      // Convert projectMembersData into an array of strings
      let combinedArray = data.map((member) => {
        const [key, value] = Object.entries(member)[0];
        return `${key} : ${value}`;
      });
      const formData = new FormData(); // Create a new FormData object

      // Append each key-value pair from data to the FormData object
      formData.append("-", params.data.projectId);

      // For members
      combinedArray.forEach((memberItem, index) => {
        formData.append(`projectMembers[]`, memberItem);
      });
      const paramData = {
        taskId: params?.data?.id,
        taskMembers: data,
      };
      let response;
      const handler = listingTypeHandlers[listingType];

      if (handler) {
        response = await handler({
          ...pageParams,
          ...paramData,
        });
      }
      if (response) {
        await getTaskList();
        // toast.success("Task updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
    }
  };
  const handleCheckboxClick = async () => {
    if (ownerCount < 2 && type === "owner" && isChecked) {
      toast.info(
        "There will always be atleast one owner associated with tasks.",
        { position: toast.POSITION.TOP_CENTER }
      );
    } else {
      setIsChecked(!isChecked);
      if (!isChecked) {
        // Clone the original projectMembers array
        const updatedProjectMembers = [...params.data.taskMembers];

        // Add the new member with the memberType
        updatedProjectMembers.push({ ...member, memberType: type });
        // // Update the grid data with the updated projectMembers array
        // params.data.projectMembers = updatedProjectMembers;
        // params.api.setRowData([data]);
        // // Refresh the grid to reflect the changes
        params.api.applyTransaction({
          update: [{ ...params.data, taskMembers: updatedProjectMembers }],
        });
        // // Refresh the grid to reflect the changes
        // params.api.refreshCells({
        //   rowNodes: [params.node],
        //   force: true,
        //   suppressFlash: true,
        // });

        let updatedMembers = convertToObject(params.data.taskMembers);
        if (type === "owner") {
          const typeId = getTypeID(type);
          const existingIndex = updatedMembers.findIndex(
            (entry) => entry[typeId] !== undefined
          );
          if (existingIndex !== -1) {
            // If it exists, update the `userId` for that typeId
            updatedMembers[existingIndex][typeId] = member?.userId;
            // Filter out the user who has memberType 'owner' and the specific id

            const updatedPrevMembers = updatedProjectMembers;
            // Find the owner member in updatedProjectMembers
            const ownerMember = updatedPrevMembers.find(
              (user) => user.memberType === "owner"
            );

            if (ownerMember) {
              // If the owner member is found, update the userId for that typeId
              const updatedObject = { ...updatedPrevMembers[existingIndex] }; // Clone the object
              updatedObject[typeId] = ownerMember.userId; // Modify the clone
              updatedPrevMembers[existingIndex] = updatedObject; // Replace the original object with the modified clone
            }
            params.node.setDataValue("taskMembers", updatedPrevMembers);
          } else {
            // If it doesn't exist, add a new entry
            updatedMembers = [...updatedMembers, { [typeId]: member?.userId }];
            params.node.setDataValue("taskMembers", updatedProjectMembers);
          }
        } else if (type === "member") {
          const typeId = getTypeID(type);

          // Remove any existing entries with the same typeId
          updatedMembers = updatedMembers.filter(
            (entry) => entry[typeId] === undefined
          );

          // Add the new entry with the specified typeId and userId
          updatedMembers = [...updatedMembers, { [typeId]: member?.userId }];

          // Ensure taskMembers is updated to reflect these changes
          params.node.setDataValue("taskMembers", updatedMembers);

          // Update the grid data with the updated projectMembers array
          params.api.applyTransaction({
            update: [{ ...params.data, taskMembers: updatedProjectMembers }],
          });
          // Refresh the grid to reflect the changes
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
            suppressFlash: true,
          });
        } else {
          updatedMembers = [
            ...updatedMembers,
            { [getTypeID(type)]: member?.userId },
          ];
          params.node.setDataValue("taskMembers", updatedProjectMembers);
        }
        // params.api.stopEditing();
        params.api.stopEditing();
        await updateMembers(updatedMembers);
        // params.data.taskMembers = data;
      } else {
        // Clone the original projectMembers array
        let updatedProjectMembers = [...params.data.taskMembers];

        // Remove the member from the projectMembers array
        updatedProjectMembers = updatedProjectMembers.filter(
          (m) => !(m.userId === member.userId && m.memberType === type)
        );
        let updatedMembers = convertToObject(updatedProjectMembers);

        // Update the grid data with the updated projectMembers array
        params.api.applyTransaction({
          update: [{ ...params.data, taskMembers: updatedProjectMembers }],
        });
        // Refresh the grid to reflect the changes
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
          suppressFlash: true,
        });
        //   params.api.stopEditing();
        // start editing
        params.api.stopEditing();
        await updateMembers(updatedMembers);
        // params.data = {
        //   ...params.data,
        //   taskMembers: data,
        // };
      }
    }
  };

  useEffect(() => {
    if (focusedIndex === index && isClicked) {
      console.log("checked clicked");

      setIsChecked(!isChecked);
      handleCheckboxClick();

      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <div
      role="option"
      aria-selected={focusedIndex === index}
      tabIndex={focusedIndex === index ? 0 : -1}
      onMouseEnter={() => setFocusedIndex(index)}
      style={{
        paddingLeft: "10px !important",
        backgroundColor: focusedIndex === index ? "#f0f0f0" : "white",
        cursor: "pointer",
      }}
      className={`w-100 border-bottom-1 task-user-filter-dropdown-menu-item `}
    >
      <div
        className={`checkbox create-task-checkbox ${
          focusedIndex === index
            ? "task-user-filter-dropdown-menu-item-focused"
            : ""
        }`}
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={member?.profileImage ? member?.profileImage : avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span className="ps-1">{member?.userName}</span>
        </label>
      </div>
    </div>
  );
};

export default TasksListingMainMembersEditor;
