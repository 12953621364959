/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ListofTaskMemberCellRenderer,
  ProjectNameTooltip,
  SituationCellRenderer,
} from "../utils/data";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import highestImage from "../../../../assets/highest.svg";
import mediumImage from "../../../../assets/medium.svg";
import lowImage from "../../../../assets/low.svg";
import progressImage from "../../../../assets/in-progress.svg";
import doneImage from "../../../../assets/completed-icon.png";
import reviewIcon from "../../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../../assets/on-hold.svg";
import criticalImage from "../../../../assets/critical.svg";
import NotStarted from "../../../../assets/not-started.svg";
import { IoIosArrowDown } from "react-icons/io";
import DropButton from "../../../Team/components/DropButton";
import { createPortal } from "react-dom";
import Delete from "../../../../assets/delete.svg";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import EditIcon from "../../../../assets/edit-project.svg";
// import TaskListingAddColumn from "./TaskListingAddColumn";
import {
  convertToISO,
  dateFormatter,
  formatCustomDate,
  formatDateToYYYYMMDD,
} from "../../../../helper/dateFormatter";
import UpdateTaskOffcanvas from "../../../Tasks/components/UpdateTaskOffcanvas";
import ConfirmDeleteTaskModal from "../../../Tasks/components/ConfirmDeleteTaskModal";
import TaskDetailOffCanvas from "../../../Tasks/components/TaskDetailOffCanvas";
import { useSelector } from "react-redux";
import TasksListingMainMembersEditor from "../../../Tasks/components/TaskListingMainMemberEditor";
import bugIcon from "../../../../assets/bug-icon.png";
import newIcon from "../../../../assets/new-task.png";
import errorIcon from "../../../../assets/issue.png";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { isPermitted } from "../../../../helper/isPermitted";
import {
  isMemberOfTask,
  isObserver,
  isOwnerInTaskOne,
  isOwnerInTasks,
} from "../../../../helper/projectOwner";
import { isAdmin } from "../../../../helper/isUserAdmin";
import NoStatusAllowedModal from "../../../../components/NoStatusAllowedModal/NoStatusAllowedModal";
import {
  useClearAllTasksCacheMutation,
  useCreateTaskDataMutation,
} from "../../../../slices/tasks/tasksSlice";
import getProjectIconById from "../../../../helper/projectIconGenerator";
import {
  ProjectInfoRenderer,
  TaskStatusInfoRenderer,
} from "../../../Tasks/components/utils/data";
import $ from "jquery";
import {
  getDefaultMarkAsById,
  getIdByDefaultMarkAs,
} from "../../../../helper/taskHelpers";
import TimerIcon from "../../../../assets/overdue-timer.png";
import TimelyIcon from "../../../../assets/timely-completed.png";
import TimerGreenIcon from "../../../../assets/overdue-green.png";
import DateTimePicker from "../../../../components/DateTimePicker/DateTimePicker";
import moment from "moment-timezone";
import RepeatIcon from "../../../../assets/repeat-task-icon.png";
import { useIsMobile } from "../../../../helper/windowHelper";
import { isTrialActive } from "../../../../helper/subscriptionHelpers";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};
function PlanProjectPhaseSectionIndividualTable({
  props,
  boardColumnData,
  rowData,
  setRowData,
  setSelectedTask,
  selectedTask,
  filters,
  setFilters,
  setListToFilter,
  taskList,
  setTaskList,
  getStatusCounts,
  updateCountFilters,
  projectId,
  phaseId,
  getAllTasksByPhaseId,
  projectAllPhasesTasks,
  updateTaskData,
  updateTaskMemberData,
  getTaskList,
  setReadLoad,
}) {
  const { isMobile } = useIsMobile();
  const [createTaskData, { isLoading }] = useCreateTaskDataMutation();
  const [clearAllTasksCache] = useClearAllTasksCacheMutation();
  const { userProfile } = useSelector((state) => state?.profile);
  const [updateTaskId, setUpdateTaskId] = useState(null);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const gridRef = useRef();
  const [edit, setEdit] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const optionsDropdownRef = useRef(null);
  const [isDateChanged, setIsDateChanged] = useState(false);

  const [paramApi, setParamApi] = useState(null);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [taskPriorityList] = useState(["Critical", "Highest", "Medium", "Low"]);
  const [taskProgressOptionsList] = useState([
    "Not Started",
    "In Progress",
    "On Hold",
    "Submit For Review",
    "Completed",
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const [projectOptions, setProjectOptions] = useState([]);

  const [newTaskName, setNewTaskName] = useState("");
  const [createTaskLoading, setCreateTaskLoading] = useState(false);

  const isTaskCreationPermitted = isPermitted("task/create-new");

  const isTaskUpdatePermitted = isPermitted("task/update");

  const isTaskDeletePermitted = isPermitted("task/delete");

  const isTaskOptionPermitted =
    (isTaskUpdatePermitted &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    (isTaskDeletePermitted &&
      isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
    isAdmin(userProfile);

  const [showStatusModal, setShowStatusModal] = useState(false);

  const handleClose = () => {
    setShowStatusModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });

  // Update pin
  // Update name
  const updateProject = async (data) => {
    try {
      // await ProjectAPI.post(`task/update`, data);
      const pageParams = {};
      const response = await updateTaskData({
        ...pageParams,
        ...data,
      }).unwrap();
      if (response?.status === "success") {
        setReadLoad(false);
        editingCellsRef.current.clear();
        // toast.success("Task updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
        await getAllTasksByPhaseId();
        await getTaskList();
      }
    } catch (error) {
      // toast.error(error, { position: toast.POSITION.TOP_CENTER });
    }
  };

  // Date formating
  class DatePicker {
    constructor() {
      // Initialize necessary variables
      this.eInput = null;
      this.params = null;
      this.data = null;
    }

    init(params, data) {
      this.params = params;
      this.data = data; // Assuming data is directly accessible from params

      this.eInput = document.createElement("input");
      this.eInput.value = params.value || "";
      this.eInput.classList.add("ag-input");
      this.eInput.style.height = "var(--ag-row-height)";
      this.eInput.style.fontSize = "calc(var(--ag-font-size) + 1px)";

      $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: this.onSelect.bind(this),
      });
    }

    onSelect(dateText) {
      const { data } = this.params;
      if (data) {
        const updatedData = { ...data, due_date: dateText };
        this.params.api.applyTransaction({ update: [updatedData] });
      }
    }

    getGui() {
      return this.eInput;
    }

    afterGuiAttached() {
      this.eInput.focus();
      this.eInput.select();
    }

    getValue() {
      return this.eInput.value;
    }

    destroy() {}

    isPopup() {
      return false;
    }
  }

  // Date formater
  // Date formatter
  const formatDate = (dateStr) => {
    if (!dateStr) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }
    const dateString = convertToISO(dateStr);
    const [day, month, year] = dateString?.split("/").map(Number);
    const formattedDate = new Date(year, month - 1, day); // Month is 0-indexed

    const monthNames = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    // Check if formattedDate is valid
    if (isNaN(formattedDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const dateParts = formattedDate.toLocaleDateString("en-US").split("/");
    return `${dateParts[1]} ${monthNames[formattedDate.getMonth() + 1]}, ${
      dateParts[2]
    }`;
  };

  const TaskListingDueDateCellEditorHandler = (params) => {
    const datePickerRef = useRef(null);

    const [selectedDateFilter, setSelectedDateFilter] = useState(
      params?.data?.taskDeadline
        ? new Date(params?.data?.taskDeadline?.split("T")[0])
        : null
    );
    const formatDateTime = (date, time) => {
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      return `${formattedDate} - ${time}`;
    };
    const [selectedTime, setSelectedTime] = useState(() => {
      if (params?.data?.taskDeadline) {
        // Format the time as HH:mm
        return `${params?.data?.taskDeadline?.split("T")[1]?.split(":")[0]}:${
          params?.data?.taskDeadline?.split("T")[1]?.split(":")[1]
        }`;
      }
      return "19:00"; // Default time
    });
    let isTimeModalOpen = true;

    const handleDateTimeChange = () => {
      setIsDateChanged(true);
    };
    useEffect(() => {
      if (selectedTime && selectedDateFilter) {
        const formattedDate = `${formatDateToYYYYMMDD(
          selectedDateFilter
        )}T${selectedTime}:00.000Z`;

        const updatedProjectData = {
          ...params.data,
          taskDeadline: formattedDate,
        };

        const index = taskList?.findIndex(
          (project) => project.id === updatedProjectData.id
        );

        if (index !== -1) {
          const updatedListToFilter = [...taskList];
          updatedListToFilter[index] = updatedProjectData;
          setTaskList(updatedListToFilter);
        }

        params.api.applyTransaction({
          update: [updatedProjectData],
        });
      }
    }, [selectedTime, selectedDateFilter]);

    // Clear due date from item
    const handleClearDate = async () => {
      setIsDateChanged(true);
      let updatedDate = null;

      const updatedProjectData = {
        ...params.data,
        taskDeadline: updatedDate,
      };

      const index = taskList?.findIndex(
        (project) => project.id === updatedProjectData.id
      );

      if (index !== -1) {
        const updatedListToFilter = [...taskList];
        updatedListToFilter[index] = updatedProjectData;
        setTaskList(updatedListToFilter);
      }

      params.api.applyTransaction({
        update: [updatedProjectData],
      });
    };

    return (
      <div
        data-theme={props.isDark}
        className="project-details-task-table-flatpickr-container task-list-date-container position-relative"
        style={{ width: "180px", background: "red", zIndex: 9999 }}
        onClick={(e) => e.stopPropagation()} // Prevents the grid from losing focus
      >
        <input
          value={
            selectedDateFilter
              ? formatDateTime(selectedDateFilter, selectedTime)
              : null
          }
          altFormat="M d, Y H:i"
          dateFormat="Y-m-d H:i"
          class="form-control input active task-table-deadline-date-input"
          placeholder="Select Due Date"
          tabindex="0"
          autoFocus
          type="text"
          readonly="readonly"
        ></input>
        <DateTimePicker
          isOpen={isTimeModalOpen}
          setIsOpen={(open) => {
            isTimeModalOpen = open;
          }}
          time={selectedTime}
          setTime={setSelectedTime}
          date={selectedDateFilter}
          setDate={setSelectedDateFilter}
          ref={datePickerRef}
          handleDateTimeChange={handleDateTimeChange}
          enableClear={params?.data?.taskDeadline}
          clearDateHandler={handleClearDate}
        />
      </div>
    );
  };

  const editingCellsRef = useRef(new Set());

  // Column definitions for task listing
  const columnData = [
    {
      field: "taskName",
      headerName: "Tasks",
      // pinned: "left",
      editable: edit,
      suppressMenu: true,
      width: 425,
      minWidth: 425,
      headerComponent: (params) => (
        <div className="w-100 d-flex justify-content-between align-items-center custom-sort-header">
          {" "}
          <label>
            Tasks
            <span className="ms-1 task-count-text">{rowData?.length || 0}</span>
          </label>
        </div>
      ),
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      onCellValueChanged: async (params) => {
        const newValue = params?.newValue;
        if (newValue) {
          const data = {
            taskId: params?.data?.id,
            fieldName: "taskName",
            fieldValues: newValue,
          };
          await updateProject(data);
        } else {
          params.data.taskName = params?.oldValue;
        }
      },
      cellRenderer: (params) => {
        console.log(params?.data);
        return (
          <div
            className="d-flex align-items-center justify-content-between custom-task-name-renderer"
            onClick={async (e) => {
              e.stopPropagation();
              setSelectedTask(params.data.id);
              handleTaskDetailsShowModal();
              if (!params?.data?.isRead) {
                params.data.isRead = true;
                // Refresh the grid to reflect the changes
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                  suppressFlash: true,
                });
                // Delay clearing cache by 2 seconds
                setTimeout(() => {
                  clearAllTasksCache();
                }, 2000);
              }
            }}
          >
            <TaskNameCellRenderer
              data={params.data}
              api={params.api}
              handleEdit={handleEdit}
              row={params.rowIndex}
              showPin={hoveredRow === params.data.id}
              setEdit={setEdit}
              params={params}
              canEdit={false}
            />
            <div className="d-flex align-items-center gap-2">
              {hoveredRow === params.data.id && !params.data.isPinned && (
                <Icon
                  className="tasks-listing-pin-icon"
                  icon="icon-park-solid:pin"
                  onClick={async (e) => {
                    e.stopPropagation();
                    // params.data.isPinned = true;
                    // Refresh the grid to reflect the changes
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      force: true,
                      suppressFlash: true,
                    });
                    const fieldEndData = {
                      taskId: params?.data?.id,
                      fieldName: "isPined",
                      fieldValues: true,
                    };
                    // Refresh the entire grid
                    setFilters({
                      ...filters,
                      pinnedState: !filters.pinnedState,
                    });
                    await updateProject(fieldEndData);
                  }}
                />
              )}
              {params.data.isPinned && (
                <Icon
                  className="tasks-listing-pin-icon pined-icon"
                  icon="icon-park-solid:pin"
                  onClick={async (e) => {
                    e.stopPropagation();

                    // Create a new object instead of mutating
                    const newData = {
                      ...params.data,
                      isPinned: false,
                    };

                    // Update through grid API
                    params.api.applyTransaction({
                      update: [newData],
                    });

                    const fieldEndData = {
                      taskId: params.data.id,
                      fieldName: "isPined",
                      fieldValues: false,
                    };

                    setFilters({
                      ...filters,
                      pinnedState: !filters.pinnedState,
                    });
                    await updateProject(fieldEndData);
                  }}
                />
              )}
              <OpenProjectCellRenderer
                showModal={handleTaskDetailsShowModal}
                id={params.data.id}
                handleOpen={(e) => {
                  if (!params?.data?.isRead) {
                    params.data.isRead = true;
                    // Refresh the grid to reflect the changes
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      force: true,
                      suppressFlash: true,
                    });
                  }
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      field: "taskPriority",
      headerName: "Priority",
      maxWidth: 120,
      minWidth: 120,
      suppressMenu: true,
      filter: false,
      sortable: false,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable:
        (!isMobile &&
          isTaskUpdatePermitted &&
          isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
        isAdmin(userProfile),
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectPriorityEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellEditorPopup: true,
      getContextMenuPopupParent: function () {
        return document.body; // Render dropdown menu outside the table
      },
      cellRenderer: SituationCellRenderer,
    },
    {
      field: "taskMembers",
      headerName: "Assigned to",
      minWidth: 250,
      maxWidth: 250,
      suppressMenu: true,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable:
        (!isMobile &&
          isTaskUpdatePermitted &&
          isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
        isAdmin(userProfile),
      width: 145,
      cellEditorParams: {
        popupParent: document.querySelector("body"),
      },
      cellEditorSelector: (params) => {
        const gridElement = document.querySelector(".dashboard-main-wraper"); // Replace with your grid's root element if different
        const cellRect = params.eGridCell.getBoundingClientRect();
        const gridRect = gridElement.getBoundingClientRect();
        const spaceBelow = gridRect.bottom - cellRect.bottom;

        return {
          component: () => {
            return (
              <TasksListingMainMembersEditor
                params={params}
                isDark={props.isDark}
                userList={userList}
                projectTypes={projectTypeList}
                isOwner={isOwnerInTasks(
                  rowData,
                  hoveredRow,
                  userProfile?.userId
                )}
                listingType={"ALL_TASKS"}
                updateTaskMemberData={updateTaskMemberData}
                getTaskList={() => getTaskList(false)}
              />
            );
          },
          popup: true,
          popupPosition: spaceBelow > 314 ? "under" : "over",
        };
      },
      cellRenderer: (params) =>
        ListofTaskMemberCellRenderer(
          params,
          isTaskUpdatePermitted &&
            isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)
        ),
      onCellValueChanged: (params) => {
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
          suppressFlash: true,
        });
      },
    },
    {
      field: "taskDeadline",
      headerName: "Due Date",
      minWidth: 190,
      maxWidth: 190,
      // headerComponent: (params) =>
      //   SortableHeaderComponent({
      //     ...params,
      //     updateOrder: updateOrder, // Pass updateOrder from props
      //     order: order,
      //     sortOrder: sortOrder,
      //     setSortOrder: setSortOrder,
      //     list: "TASK",
      //   }),
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable:
        (!isMobile &&
          isPermitted("task/update") &&
          isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
        isAdmin(userProfile),
      cellRenderer: (params) => {
        const taskDeadline = params.data.taskDeadline
          ? new Date(params.data.taskDeadline)
          : ""; // Parse taskDeadline into a Date object
        const currentDate = new Date();
        return (
          <div
            className="project-date"
            style={{
              color:
                taskDeadline < currentDate &&
                params.data.taskDeadline &&
                params?.data?.taskStatus?.defaultMarkAs !== "completed"
                  ? "#ff0000"
                  : "",
            }}
          >
            {" "}
            {/* <img src={CheckIcon} alt="check-icon" />{" "}
            {params?.data?.taskDeadline ? "Due: " : ""} */}
            {params?.data?.taskDeadline
              ? formatCustomDate(
                  new Date(params?.data?.taskDeadline),
                  `${
                    params?.data?.taskDeadline?.split("T")[1]?.split(":")[0]
                  }:${params?.data?.taskDeadline?.split("T")[1]?.split(":")[1]}`
                )
              : "No deadline"}{" "}
          </div>
        );
      },
      cellEditorSelector: (params) => {
        return {
          component: (params) => {
            return TaskListingDueDateCellEditorHandler(params);
          },
          popup: true,
          popupPosition: "over",
        };
      },
      cellEditor: DatePicker,
      valueFormatter: (params) => formatDate(params.data.taskDeadline),
    },
    {
      field: "taskStatus",
      headerName: "Status",
      maxWidth: 170,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      editable: (params) =>
        (!isMobile &&
          isPermitted("task/update") &&
          (isMemberOfTask(params?.data, userProfile?.userId) ||
            isObserver(params?.data, userProfile?.userId))) ||
        isAdmin(userProfile),
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProgressEditor,
          popup: true,
          popupPosition: "over",
        };
      },
      cellRenderer: (params) => {
        return TaskStatusInfoRenderer(params);
      },
    },
    {
      field: "",
      headerName: "Project",
      width: 190,
      minWidth: 190,
      editable: (params) =>
        (!isMobile &&
          isTaskUpdatePermitted &&
          isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
        isAdmin(userProfile),
      cellClass: (params) => {
        let classes = "task-project-col";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += " selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: ProjectInfoRenderer,
      cellEditorSelector: (params) => {
        return {
          component: TasksSelectProjectEditor,
          popup: true,
          popupPosition: "over",
        };
      },
    },

    {
      field: "createdAt",
      headerName: "Created",
      editable: false,
      width: 170,
      minWidth: 170,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.createdAt, 3)}
            </p>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      editable: false,
      width: 170,
      minWidth: 170,
      cellClass: (params) => {
        let classes = "";
        if (hoveredRow === params.data.id || !params?.data?.isRead) {
          classes += "selected-project-row";
        }
        if (hoveredRow === params.data.id) {
          classes += " selected-hovered-row";
        }
        return classes.trim();
      },
      cellRenderer: (params) => {
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              {dateFormatter(params?.data?.updatedAt, 3)}
            </p>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllProjects().catch((error) => {
      console.error("Error fetching projects:", error);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, [showMenu]);

  const handleMouseOut = (event) => {
    // Get the bounding box of the grid and options dropdown
    const gridRect = gridRef?.current?.getBoundingClientRect();
    const optionsDropdownRect =
      optionsDropdownRef?.current?.getBoundingClientRect();

    // Check if the mouse has left the grid area
    if (
      event.clientX > gridRect?.right ||
      event.clientY < gridRect?.top ||
      event.clientY > gridRect?.bottom + 30
    ) {
      if (!showMore) {
        setHoveredMenu(null);
        setHoveredRow(null);
        setShowMore(false);
      }
      // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

      if (
        showMenu === false ||
        (optionsDropdownRect &&
          (event.clientX < optionsDropdownRect.left ||
            event.clientX > optionsDropdownRect.right ||
            event.clientY < optionsDropdownRect.top ||
            event.clientY > optionsDropdownRect.bottom))
      ) {
        setHoveredMenu(null);
        setShowMenu(false);
      }
    }
  };

  const gridReady = (params) => {
    setGridApi(params.api);
    var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color:
      //   state.data?.value?.toString()?.toLowerCase() === "critical"
      //     ? "#FB3F3F"
      //     : state.data?.value?.toString()?.toLowerCase() === "highest"
      //     ? "#56BAEC"
      //     : state.data?.value?.toString()?.toLowerCase() === "medium"
      //     ? "#394B84"
      //     : state.data?.value?.toString()?.toLowerCase() === "low"
      //     ? "#2C2D2F"
      //     : state.data?.value?.toString()?.toLowerCase() === "in progress"
      //     ? "#2098D1"
      //     : state.data?.value?.toString()?.toLowerCase() === "not started"
      //     ? "#fd1b1b"
      //     : state.data?.value?.toString()?.toLowerCase() === "completed"
      //     ? "#2DBF64"
      //     : state.data?.value?.toString()?.toLowerCase() === "on hold"
      //     ? "#E8910D"
      //     : state.data.value.toString().toLowerCase() === "submit for review"
      //     ? "#EDA900"
      //     : " black",
      fontSize: "14px",
      fontWeight: 300,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      minWidth: "120px", // Set the desired width
    }),
  };
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("-");
    return new Date(`${month}/${year}/${day}`);
  };

  const handleTaskDetailsCloseModal = () => {
    setShowTaskDetails(false);
  };

  const handleTaskDetailsShowModal = () => {
    setShowTaskDetails(true);
  };

  const OpenProjectCellRenderer = ({ showModal, id, handleOpen }) => {
    return (
      <div
        className="open-target-link d-flex align-items-center justify-content-between "
        onClick={() => {
          setSelectedTask(id);
          showModal();
          handleOpen();
        }}
      >
        <div>
          {" "}
          <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
        </div>
        {/* <img src={openTask} alt="Open Task" width={100} height={100} /> */}
      </div>
    );
  };

  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorityList.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "critical"
                        ? criticalImage
                        : res?.toLowerCase() === "highest"
                        ? highestImage
                        : res?.toLowerCase() === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectPriorityEditor = (params) => {
    const [selected] = useState(params?.data?.taskPriority?.priorityName);
    const selectRef = useRef(null);
    // Icons mapping based on `defaultMarkAs`
    const priorityIcons = {
      critical: criticalImage,
      highest: highestImage,
      medium: mediumImage,
      low: lowImage,
    };

    // Filter out the selected option from the options list
    const filteredOptions = taskPriorities?.filter(
      (res) => res.priorityName !== selected // Exclude the selected option
    );

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", width: "120px" }}
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          ref={selectRef}
          className={`basic-single tasks-select-status-editor task-situation-filter-top-wrap-select-div task-${selected?.toLowerCase()}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          styles={customStyles}
          tabIndex={0}
          menuPlacement="auto"
          autoFocus={true}
          name="color"
          options={filteredOptions?.map((res) => ({
            label: (
              <div className="d-flex align-items-center">
                {/* Dynamically add icons based on defaultMarkAs */}
                <img
                  src={priorityIcons[res.defaultMarkAs]} // Get the correct icon using `defaultMarkAs`
                  alt={res.priorityName}
                  width={20}
                  height={20}
                  className="me-1"
                />
                {res.priorityName}
              </div>
            ),
            value: res,
          }))}
          components={{ Control: CustomTasksPriorityEditorControl }}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={async (e) => {
            // Clone and update the data object
            const updatedData = { ...params.data, taskPriority: e.value };
            // Notify the grid that editing is complete
            params.api.applyTransaction({ update: [updatedData] });
            params.api.stopEditing();
            // setSelected(e.value);
            const fieldStartData = {
              taskId: params?.data?.id,
              fieldName: "taskPriorityId",
              fieldValues: e.value.id,
            };
            await updateProject(fieldStartData);
          }}
        />
      </div>
    );
  };

  const CustomTasksProjectEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectOptions?.map((res, index) => {
              if (
                res?.label.toLowerCase() ===
                props?.getValue()[0]?.label?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={res?.icon?.props?.src}
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomTasksProgressEditorControl = ({
    children,
    taskDeadline,
    taskCompletedDate,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskProgressOptionsList.map((res, index) => {
              if (
                res?.toLowerCase() ===
                props?.getValue()[0]?.value?.toLowerCase()
              ) {
                return (
                  <img
                    key={index}
                    src={
                      res?.toLowerCase() === "in progress"
                        ? progressImage
                        : res?.toLowerCase() === "not started"
                        ? NotStarted
                        : res?.toLowerCase() === "completed"
                        ? taskDeadline
                          ? taskDeadline < taskCompletedDate
                            ? TimerGreenIcon
                            : TimelyIcon
                          : doneImage
                        : res?.toLowerCase() === "on hold"
                        ? reviewImage
                        : res?.toLowerCase() === "submit for review" &&
                          reviewIcon
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: -5,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const TasksSelectProgressEditor = (params) => {
    const [selected, setSelected] = useState(
      params.data?.taskStatus?.statusName
    );

    const taskDeadline = params.data.taskDeadline
      ? new Date(params.data.taskDeadline)
      : ""; // Parse taskDeadline into a Date object
    const taskCompletedDate = params.data.taskCompletedDate
      ? new Date(params.data.taskCompletedDate)
      : new Date();

    // Icons mapping based on statusName
    const statusIcons = {
      "not started": NotStarted,
      "in progress": progressImage,
      "on hold": reviewImage,
      "submit for review": reviewIcon,
      completed: doneImage,
    };

    // Filter out the selected option from the options list
    const filteredOptions = projectStatusList?.filter(
      (res) => res?.statusName !== selected
    );

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", minWidth: "170px" }}
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          className={`basic-single tasks-select-status-editor task-listing-situation-filter-top-wrap-select-div select-item-status task-${selected
            ?.toLowerCase()
            .split(" ")
            .join("-")}-select`}
          classNamePrefix="select"
          value={{
            label: selected,
            value: selected,
          }}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          styles={customStyles}
          name="color"
          menuPlacement="auto"
          autoFocus={true}
          options={filteredOptions?.map((res) => ({
            label: (
              <div className="d-flex align-items-center">
                {/* Dynamically add icons based on statusName */}
                <img
                  src={statusIcons[res.statusName?.toLowerCase()]} // Get the correct icon using statusName
                  alt={res.statusName}
                  width={20}
                  height={20}
                  className="me-1"
                />
                {res.statusName}
              </div>
            ),
            value: res,
          }))}
          components={{
            Control: (props) => (
              <CustomTasksProgressEditorControl
                {...props}
                taskDeadline={taskDeadline}
                taskCompletedDate={taskCompletedDate}
              />
            ),
          }}
          onChange={async (e) => {
            if (
              params?.data?.hasApproval &&
              !isOwnerInTaskOne(params?.data, userProfile?.userId) &&
              getDefaultMarkAsById(projectStatusList, e.value.id) ===
                "completed"
            ) {
              // Assuming index 4 is the restricted one
              setShowStatusModal(true);
              return; // Prevent the Select component's value from updating
            } else {
              let endDate = new Date(parseDate(params.data.taskDeadline));
              let currentDate = new Date();
              // Create new Date objects from the year, month, and day components
              currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              );
              endDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate()
              );
              const currentStatus =
                params?.data?.taskStatus?.statusName?.toLowerCase() ||
                params?.data?.taskStatus?.name?.toLowerCase();
              const newStatus = e.value.statusName?.toLowerCase();
              if (currentStatus !== "completed" && newStatus === "completed") {
                getStatusCounts("INCREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("DECREMENT_OVERDUE");
                }
              } else if (
                currentStatus === "completed" &&
                newStatus !== "completed"
              ) {
                getStatusCounts("DECREMENT_COMPLETED");
                if (endDate < currentDate) {
                  getStatusCounts("INCREMENT_OVERDUE");
                }
              }

              // Clone and update the data object
              const updatedData = { ...params.data, taskStatus: e.value };
              setSelected(e.value.statusName);

              // Notify the grid that editing is complete
              params.api.applyTransaction({ update: [updatedData] });
              params.api.stopEditing();
              const fieldStartData = {
                taskId: params?.data?.id,
                fieldName: "taskStatusId",
                fieldValues: e.value.id,
              };
              await updateProject(fieldStartData);
            }
          }}
        />
      </div>
    );
  };

  const TaskNameCellRenderer = (props) => {
    const canEdit = props.canEdit && isPermitted("task/update");
    const { taskName } = props.data;
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const currentDate = new Date();
    const taskDeadline = new Date(props.data.taskDeadline); // Parse taskDeadline into a Date object
    const taskCompletedDate = new Date(props.data.taskCompletedDate);

    return (
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="d-flex align-items-center task-title m-0">
          <div
            className="module-task-image position-relative"
            onMouseEnter={(event) => {
              setShowTooltip(true);
              const rect = event.target.getBoundingClientRect();
              setTooltipPosition({ x: rect.x, y: rect.y });
            }}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img
              src={
                props?.data?.taskType?.typeName?.toLowerCase() === "bug"
                  ? bugIcon
                  : props?.data?.taskType?.typeName?.toLowerCase() === "error"
                  ? criticalImage
                  : props?.data?.taskType?.typeName?.toLowerCase() ===
                    "new task"
                  ? newIcon
                  : props?.data?.taskType?.typeName?.toLowerCase() === "issue"
                  ? errorIcon
                  : newIcon
              }
              alt={props?.data?.taskType?.typeName}
              style={{
                width: 20,
                marginRight: 10,
                height: 20,
              }}
            />

            {showTooltip && (
              <Portal>
                <ProjectNameTooltip
                  projectName={
                    props?.data?.taskType?.typeName
                      ? props?.data?.taskType?.typeName
                      : "New Task"
                  }
                  position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
                />
              </Portal>
            )}
          </div>
          <p
            className="mb-0"
            onMouseOver={() => {
              if (canEdit) {
                props.setEdit(true);
              }
            }}
            onMouseLeave={() => props.setEdit(false)}
            onClick={() => {
              if (canEdit) {
                props.handleEdit(props.api, props.row);
              }
            }}
            // style={{ cursor: canEdit ? "pointer" : "" }}
          >
            {taskName?.length > 30
              ? taskName?.substring(0, 30) + "..."
              : taskName}
            {taskDeadline < currentDate &&
              props.data.taskDeadline &&
              props?.data?.taskStatus?.defaultMarkAs !== "completed" && (
                <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                  {/* <Icon
                  icon="f7:exclamationmark-circle"
                  className="overdue-task-icon"
                /> */}
                  <img src={TimerIcon} alt="timer" width={14} height={14} />
                  <div
                    className="custom-task-phase-tooltip"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents click event from bubbling to parent
                    }}
                  >
                    Due date is exceeded
                  </div>
                </div>
              )}
            {props.data.isRecurred === 1 && (
              <div className="position-relative d-inline-block ms-1 task-deadline-icon">
                {/* <Icon
                    icon="f7:exclamationmark-circle"
                    className="overdue-task-icon"
                  /> */}
                <img src={RepeatIcon} alt="timer" width={14} height={14} />
                <div
                  className="custom-task-tooltip"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents click event from bubbling to parent
                  }}
                >
                  Repeated Task
                </div>
              </div>
            )}
          </p>
          {/* {props.showPin && canEdit ? (
            <Icon
              icon="ri:pencil-fill"
              onMouseOver={() => props.setEdit(true)}
              onMouseLeave={() => props.setEdit(false)}
              onClick={() => {
                props.handleEdit(props.api, props.row);
              }}
              className="ms-1"
            />
          ) : (
            ""
          )} */}
        </h5>
      </div>
    );
  };

  // Project helper
  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.id,
        }))
      );
    }
  };
  const getProjectOptionById = (value) => {
    return projectFormList?.find((option) => option.id === value);
  };
  // Project selector
  const TasksSelectProjectEditor = (params) => {
    const [selected, setSelected] = useState(params.data?.project);

    // Custom filter function for searching
    const filterOption = (option, inputValue) => {
      const label = option.label.props.children[1].toLowerCase(); // Assuming the second child is the label text
      return label.includes(inputValue.toLowerCase());
    };

    // Filter out the selected project from the options list
    const filteredOptions = projectOptions?.filter(
      (res) => res?.value !== selected?.id
    );

    const handleClick = (event) => {
      const popupEditor = document.querySelector(".ag-popup-editor");

      if (popupEditor) {
        // If editor is open, close it
        params.api.stopEditing();
      } else {
        // Otherwise, start editing
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.column.colId,
        });
      }
      event.stopPropagation();
    };

    return (
      <div
        style={{ height: "100%", minWidth: "199px" }}
        className="project-tasklist-select"
        data-theme={props.isDark}
        onClick={handleClick}
      >
        <Select
          menuPlacement="auto"
          classNamePrefix="select"
          className="select-item-project"
          placeholder="Select project"
          data-theme={props.isDark}
          menuIsOpen={true}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          autoFocus={true}
          value={{
            label: selected?.projectName,
            value: selected?.id,
          }}
          options={filteredOptions?.map((res, index) => ({
            label: (
              <div
                className="d-flex align-items-center gap-1 custom-project-select-item"
                key={index}
              >
                {res?.icon}
                {res?.label}
              </div>
            ),
            value: res.value,
          }))}
          components={{ Control: CustomTasksProjectEditorControl }}
          onChange={async (e) => {
            const selectedProject = getProjectOptionById(e.value);
            // params.data.project = selectedProject;
            const updatedData = { ...params.data, project: selectedProject };
            // Notify the grid that editing is complete
            params.api.applyTransaction({ update: [updatedData] });
            params.api.stopEditing();
            // // setSelected(e.value);
            const fieldStartData = {
              taskId: params?.data?.id,
              fieldName: "projectId",
              fieldValues: e.value,
            };
            await updateProject(fieldStartData);
          }}
          filterOption={filterOption} // Add the custom filter function
        />
      </div>
    );
  };

  const handleEdit = async (api, row) => {
    setEdit(true);

    api.startEditingCell({
      rowIndex: row,
      colKey: "project",
    });
    if (!edit) {
      setEdit(true);
      api.startEditingCell({
        rowIndex: row,
        colKey: "project",
      });
    }
  };

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null) {
      const dropdownHeight = 70; // Adjust this value based on your dropdown height
      const availableSpaceBelow =
        window.innerHeight - (yValue + dropdownHeight);
      const dropdownPosition =
        availableSpaceBelow < 0
          ? {
              top: `${yValue - dropdownHeight - 26}px`,
              left: `${xValue - 20}px`,
            }
          : { top: `${yValue}px`, left: `${xValue - 20}px` };
      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top,
            left: optionsColumnPosition.left,
            zIndex: "99999 !important",
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}

          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div className="position-relative">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
            {showMenu && (
              <Portal>
                <div
                  ref={optionsDropdownRef}
                  style={{
                    position: "absolute",
                    left: dropdownPosition.left,
                    top: dropdownPosition.top,
                    listStyle: "none",
                    background: "#fff", // Set background color
                    // border: "1px solid #ccc",
                    fontSize: "14px",
                    fontWeight: 300,
                    minWidth: "150px",
                    zIndex: "9999 !important",
                    overflow: "auto",
                  }}
                  data-theme={props.isDark}
                  onMouseEnter={(e) => setShowMenu(true)}
                  onMouseLeave={() => setShowMore(false)}
                  className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1"
                >
                  {((isTaskUpdatePermitted &&
                    isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        setSelectedTask(hoveredRow);
                        handleTaskDetailsShowModal();
                        if (!paramApi?.data?.isRead) {
                          paramApi.data.isRead = true;
                          // Refresh the grid to reflect the changes
                          paramApi.api.refreshCells({
                            rowNodes: [paramApi.node],
                            force: true,
                            suppressFlash: true,
                          });
                        }
                      }}
                    >
                      <img
                        src={EditIcon}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="edit-icon"
                      />
                      Edit Task
                    </div>
                  )}
                  {((isTaskDeletePermitted &&
                    isOwnerInTasks(rowData, hoveredRow, userProfile?.userId)) ||
                    isAdmin(userProfile)) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <img
                        src={Delete}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        alt="delete-icon"
                      />
                      Delete Task
                    </div>
                  )}
                </div>
              </Portal>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100,
      width: 1300,
    };
  }, []);
  const onBtPinnedOn = useCallback(() => {
    gridRef?.current?.api?.applyColumnState({
      state: [{ colId: "taskName", pinned: "left" }],
    });
  }, []);

  useEffect(() => {
    const users = projectUserList?.data?.map((user) => ({ ...user })) || [];
    setUserList(users);
  }, [projectUserList]);

  useEffect(() => {
    gridRef && onBtPinnedOn();
  }, []);

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const changeTheOptionPosition = async (r, i) => {
    const hoveredRowElement = document?.querySelector(`.ag-row[row-id="${r}"]`);
    if (hoveredRowElement) {
      setOptionsColumnPosition({
        top: i * 41 + 47,
        left: -42, // Adjust the left position as needed
      });
    }
  };

  const createTask = async () => {
    try {
      setCreateTaskLoading(true);
      let formData = new FormData();
      formData.append("taskName", newTaskName);
      formData.append("projectId", projectId);
      formData.append("projectPhaseId", phaseId);
      formData.append(
        "taskStatusId",
        getIdByDefaultMarkAs(projectStatusList, "not-started")
      );
      formData.append(
        "taskMembers[]",
        `${getTypeIDByName("owner")}:${userProfile?.userId}`
      );

      const response = await createTaskData(formData).unwrap();
      if (response.status === "success") {
        setNewTaskName("");
        toast.success("Task Created Successfully.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        getAllTasksByPhaseId();
      }
      // Now formData contains all the data in a format suitable for sending in a form request
    } catch (error) {
      toast.error("Failed to create task.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setNewTaskName("");
      setCreateTaskLoading(false);
    }
  };

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rowData]);

  useEffect(() => {
    // if (rowData?.length !== 0) {
    const handleScroll = () => {
      const table = tableRef?.current;
      setIsLeftScrollable(table?.scrollLeft > 0);
      setIsRightScrollable(
        table?.scrollLeft + table?.clientWidth < table?.scrollWidth
      );
    };

    const table = tableRef?.current;
    table.addEventListener("scroll", handleScroll);

    return () => {
      table?.removeEventListener("scroll", handleScroll);
    };
    // }
  }, [rowData]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  const handleCellEditingStopped = async (event) => {
    if (event.colDef.field === "taskDeadline" && isDateChanged) {
      const updatedTaskDeadline = event.data.taskDeadline;
      const taskId = event.data.id;

      // Call the API to update the task deadline
      const fieldEndData = {
        taskId,
        fieldName: "taskDeadline",
        fieldValues: moment(updatedTaskDeadline, "YYYY-MM-DD HH:mm")
          .clone() // Clone to avoid mutation of the original moment object
          .tz(userProfile?.timeZone, true) // true ensures we keep the exact same time, not adjusting it to the timezone offset
          .utc()
          .toISOString(),
      };

      await updateProject(fieldEndData); // Replace with your actual API call
      setIsDateChanged(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!gridRef.current?.contains(event.target)) {
        editingCellsRef.current.clear(); // Clear editing state
        // gridRef.current?.api.stopEditing();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="position-relative tasks-table-top-wrap"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      <>
        <div className="position-relative">
          {gridApi && isTaskOptionPermitted && renderCustomOptionsColumn()}
        </div>
        <div
          className={`table-responsive task-responsive-${props.index}  scroll-smooth custom-horizental-scrollbar`}
          ref={tableRef}
        >
          <Tooltip id="my-tooltip" />

          <div
            className={`ag-theme-alpine   task-modal-table-${props.index}`}
            style={{
              width: "100%",
              minWidth: "1690px",
            }}
            ref={gridRef}
          >
            {/* Left scroll button */}
            {isTableScrollable && isLeftScrollable && (
              <div
                className={`left-scroll-button ${
                  rowData?.length === 1 ? "left-scroll-button-height-one" : ""
                }`}
                onMouseEnter={handleScrollLeft}
                onMouseLeave={stopScrolling}
              >
                <i className="fa fa-chevron-left"></i>
              </div>
            )}
            <AgGridReact
              rowData={rowData}
              columnDefs={columnData}
              defaultColDef={defaultColDef}
              getRowId={(params) => {
                return params.data.id;
              }}
              // onColumnMoved={swapColumnState}
              // pagination={true}
              rowDragManaged={false}
              rowDragEntireRow={false}
              animateRows={true}
              domLayout={"autoHeight"}
              headerHeight={41}
              // onRowDragEnd={onRowDragEnd}
              singleClickEdit={true}
              suppressHorizontalScroll={true}
              suppressRowHoverHighlight={true}
              stopEditingWhenCellsLoseFocus={true}
              popupParent={popupParent}
              onCellEditingStarted={(params) => {
                const cellKey = `${params.node.id}-${params.column.colId}`;
                // If the same cell is already editing, stop it and remove from ref
                if (editingCellsRef.current.has(cellKey)) {
                  params.api.stopEditing(); // 🚫 Stop editing
                  editingCellsRef.current.delete(cellKey); // ❌ Remove from ref
                  return; // Exit early
                }
                // If another cell is being edited, remove it first
                if (editingCellsRef.current.size > 0) {
                  const [existingKey] = editingCellsRef.current;

                  editingCellsRef.current.delete(existingKey); // ❌ Remove previous key
                }
                // Add the new cellKey to ref
                editingCellsRef.current.add(cellKey);
              }}
              onCellEditingStopped={async (event) => {
                // Check if editing stopped due to clicking outside of the cell
                await handleCellEditingStopped(event);
                setEdit(false); // Set edit to false when editing is stopped
              }}
              onGridReady={gridReady}
              onCellMouseOver={(params) => {
                if (params?.data?.id !== hoveredRow) {
                  setShowMore(false);
                  setHoveredRow(params?.data?.id);
                  setParamApi(params);
                  !updateTaskCanvas && setUpdateTaskId(params?.data?.id);
                }
                if (params?.rowIndex !== hoveredMenu) {
                  setShowMenu(false);
                  setHoveredMenu(params?.rowIndex);
                  changeTheOptionPosition(params?.data?.id, params?.rowIndex);
                }
              }}
            />
            {/* Right scroll button */}
            {isTableScrollable && isRightScrollable && (
              <div
                className={`right-scroll-button ${
                  rowData?.length === 1 ? "right-scroll-button-height-one" : ""
                }`}
                onMouseEnter={handleScrollRight}
                onMouseLeave={stopScrolling}
              >
                <i className="fa fa-chevron-right"></i>
              </div>
            )}
            {rowData.length === 0 && (
              <div className="d-flex align-items-start justify-content-center w-100 add-lead-new">
                <p>No data found</p>
              </div>
            )}
            {isTaskCreationPermitted && (
              <div className="w-100 add-lead-new form-group d-flex align-items-center ">
                <div className="d-flex align-items-center add-new-phase-container">
                  <input
                    type="text"
                    style={{ width: "190px !important" }}
                    value={newTaskName}
                    onChange={(e) => setNewTaskName(e.target.value)}
                    placeholder="Enter Task Name"
                    autoComplete="off"
                    className="form-control "
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (newTaskName !== "") {
                          if (!createTaskLoading) {
                            if (!isTrialActive(userProfile)) {
                              props.setOpenSubscriptionsModal(true);
                            } else {
                              createTask();
                            }
                          }
                        }
                      }
                    }}
                    onBlur={(e) => {
                      e.stopPropagation();
                      if (newTaskName !== "") {
                        if (!createTaskLoading) {
                          if (!isTrialActive(userProfile)) {
                            props.setOpenSubscriptionsModal(true);
                          } else {
                            createTask();
                          }
                        }
                      }
                    }}
                  />
                  <span className="ms-2 enter-span">
                    <Icon icon="fluent:arrow-enter-left-20-filled" /> Press
                    Enter
                  </span>
                </div>
                {createTaskLoading && (
                  <span className="ms-2">
                    <Spinner
                      size="sm"
                      animation="border"
                      className="global-spinner"
                    ></Spinner>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </>
      {showTaskDetails && (
        <TaskDetailOffCanvas
          show={showTaskDetails}
          handleCloseModal={handleTaskDetailsCloseModal}
          isDark={props.isDark}
          selectedTask={selectedTask}
          rowData={rowData}
          setRowData={setRowData}
          taskList={taskList}
          setTaskList={setTaskList}
          getStatusCounts={getStatusCounts}
          isMoreWidth={true}
          listingType={"ALL_TASKS"}
          getAllTasksByPhaseId={getAllTasksByPhaseId}
        />
      )}
      <ConfirmDeleteTaskModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDark={props.isDark}
        getTaskName={() => {
          // eslint-disable-next-line array-callback-return
          const task = taskList?.find((task) => {
            if (updateTaskId === task.id) {
              return task;
            }
          });
          return task?.taskName;
        }}
        phaseId={phaseId}
        taskId={updateTaskId}
        setListToFilter={setListToFilter}
        setTaskList={setTaskList}
        type="task"
        updateCountFilters={updateCountFilters}
        getAllTasksByPhaseId={getAllTasksByPhaseId}
      />
      {
        <UpdateTaskOffcanvas
          show={updateTaskCanvas}
          handleCloseModal={() => {
            setUpdateTaskCanvas(false);
            getAllTasksByPhaseId();
          }}
          isDark={props.isDark}
          getBoardData={() => {}}
          taskId={updateTaskId}
          taskList={taskList}
          setTaskList={setTaskList}
          getStatusCounts={getStatusCounts}
          isMoreWidth={true}
        />
      }
      {showStatusModal && (
        <NoStatusAllowedModal
          show={showStatusModal}
          handleClose={handleClose}
          isDark={props.isDark}
        />
      )}
    </div>
  );
}

export default PlanProjectPhaseSectionIndividualTable;
