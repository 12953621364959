import React, { useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import "../../../components/styles/menuButton.css";

const DropButton = (props) => {
  const buttonRef = useRef();

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    if (!props.showMore) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      props.setXValue(parentRect.x + 20);
      props.setYValue(parentRect.y + 25);
    }
    props.setShowMore(true);
  };

  const buttonMouseEnter = (e) => {
    e.stopPropagation();
    // if (!props.showMore) {
    const parentRect = buttonRef.current.getBoundingClientRect();
    props.setXValue(parentRect.x + 20);
    props.setYValue(parentRect.y + 25);
    // }
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      // Click occurred outside the DropButton component
      props.setShowMore(false);
    }
  };

  const handleWindowResize = () => {
    if (props.showMore && buttonRef.current) {
      const parentRect = buttonRef.current.getBoundingClientRect();
      props.setXValue(parentRect.x);
      props.setYValue(parentRect.y + 25);
    }
  };

  const handleScroll = () => {
    if (buttonRef?.current) {
      const parentRect = buttonRef?.current?.getBoundingClientRect();
      props.setXValue(parentRect.x);
      props.setYValue(parentRect.y);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("scroll", handleScroll, { capture: true });
    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", handleScroll, { capture: true });
    };
  }, [props.showMore]); // Re-run effect when showMore changes

  return (
    <div
      className="menu-btn"
      key={props.id}
      ref={buttonRef}
      //  className="action-btn" // Corrected className attribute
      onClick={(e) => buttonClickHandler(e)}
      onMouseEnter={(e) => buttonMouseEnter(e)}
    >
      <Icon icon="mi:options-horizontal" />
    </div>
    // <button
    //   key={props.id}
    //   ref={buttonRef}
    //   className="action-btn" // Corrected className attribute
    //   onClick={(e) => buttonClickHandler(e)}
    // >
    //   <img src={Caret} alt="Caret" />
    // </button>
  );
};

export default DropButton;
