import {
  TEAMS_LIST_FAIL,
  TEAMS_LIST_OPTIONS_SUCCESS,
  TEAMS_LIST_REQUEST,
  TEAMS_LIST_SUCCESS,
} from "../constants/teamConstant";
import { ProjectAPI } from "../services/ClientApi";

// Action to get teams list
export const teamsListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TEAMS_LIST_REQUEST });
    const { data } = await ProjectAPI.post("teams/assignment"); // Update the API endpoint if necessary
    if (data) {
      dispatch({
        type: TEAMS_LIST_SUCCESS,
        payload: data?.data,
      });
      const flattenedTeams = data?.data?.flatMap((team) => ({
        id: team.id,
        label: team.teamName,
        value: team.id,
      }));
      dispatch({
        type: TEAMS_LIST_OPTIONS_SUCCESS,
        payload: flattenedTeams,
      });
    }

    return data;
  } catch (error) {
    dispatch({
      type: TEAMS_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: teamsListAction(), // Retry action
      },
    });
  }
};

// Action to get teams list
export const emptyTeamsListAction = () => async (dispatch) => {
  try {
    dispatch({ type: TEAMS_LIST_REQUEST });
    dispatch({
      type: TEAMS_LIST_SUCCESS,
      payload: null,
    });

    dispatch({
      type: TEAMS_LIST_OPTIONS_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: TEAMS_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: emptyTeamsListAction(), // Retry action
      },
    });
  }
};
