import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../assets/infoMain.svg";

const NoStatusAllowedModal = ({ show, handleClose, isDark }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      data-theme={isDark}
      backdrop="static"
      keyboard={false}
      className="main-delete-modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Status Update</h5>
        <p>On successful review it will be marked as complete by owner only.</p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button className="w-100 delete-btn-1" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NoStatusAllowedModal;
