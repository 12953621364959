import React from "react";
import DashboardMain from "./components/DashboardMain";

const Dashboard = (props) => {
  return (
    <DashboardMain
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
      runTour={props.runTour}
      setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
    />
  );
};

export default Dashboard;
