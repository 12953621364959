// reducer.js
import {
  FETCH_COMPLETED_TASKS_REQUEST,
  FETCH_COMPLETED_TASKS_SUCCESS,
  FETCH_COMPLETED_TASKS_FAILURE,
  UPDATE_COMPLETED_TASK,
  DELETE_COMPLETED_TASK,
  CLEAR_COMPLETED_TASKS,
} from "../constants/completedTasksConstants";

// Initial state for the tasks cache reducer
const initialState = {
  loading: false, // Indicates whether data is being fetched
  taskCompletedList: {}, // Stores cached tasks, keyed by limit and page number
  error: null, // Holds any error message if data fetching fails
};

// Reducer function for managing tasks cache state
const tasksCachedCompletedReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handles the start of a fetch request
    case FETCH_COMPLETED_TASKS_REQUEST:
      return {
        ...state,
        loading: true, // Set loading to true when fetching starts
      };

    // Handles successful data fetching
    case FETCH_COMPLETED_TASKS_SUCCESS:
      const { tasks, limit, page } = action.payload; // Extract tasks, limit, and page from action payload
      return {
        ...state,
        loading: false, // Set loading to false when fetching completes
        taskCompletedList: {
          ...state.taskCompletedList,
          [`${limit}_${page}`]: tasks, // Cache tasks with a key based on limit and page number
        },
        error: null, // Clear any previous errors
      };

    // Handles failed data fetching
    case FETCH_COMPLETED_TASKS_FAILURE:
      return {
        ...state,
        loading: false, // Set loading to false when fetching fails
        error: action.payload, // Store the error message from action payload
      };

    // Handles task update
    case UPDATE_COMPLETED_TASK: {
      const { taskId, updatedTask } = action.payload; // Extract task ID and updated task from action payload
      const updatedData = { ...state.taskCompletedList }; // Create a copy of current data

      // Iterate over all cached data to update the specific task
      for (let key in updatedData) {
        updatedData[key] = updatedData[key].map(
          (task) => (task.id === taskId ? updatedTask : task) // Replace old task with updated task
        );
      }

      return {
        ...state,
        data: updatedData, // Update the data in the state with the updated task
      };
    }

    // Handles task deletion
    case DELETE_COMPLETED_TASK: {
      const { taskId, limit, page } = action.payload; // Extract task ID, limit, and page from action payload
      const key = `${limit}_${page}`; // Generate the cache key based on limit and page number
      const updatedTasks = state.taskCompletedList[key].filter(
        (task) => task.id !== taskId
      ); // Remove the task with the specified ID

      return {
        ...state,
        data: {
          ...state.taskCompletedList,
          [key]: updatedTasks, // Update the data in the state with the remaining tasks
        },
      };
    }

    case CLEAR_COMPLETED_TASKS:
      return {
        ...state,
        taskCompletedList: {}, // Cached data will be stored as {`${limit}_${page}`: data}
        loading: false,
      };
    // Default case for unrecognized actions
    default:
      return state;
  }
};

export default tasksCachedCompletedReducer;
