/* eslint-disable no-unused-vars */
// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseUrl = `${process.env.REACT_APP_CHAT_API}`;

// Define a service using a base URL and expected endpoints
export const ChatBaseApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,

    prepareHeaders: (headers, { getState }) => {
      // Get the token from the URL
      const token = localStorage.getItem("access_token");

      // If we have a token from the URL, set it in the headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  // define all tags that's goona be revalidate and other things
  tagTypes: ["ChatRoom", "ChatRoom", "UsersWithoutChat", "AllChatUsers", "ChatMessages"],

  endpoints: (builder) => ({}),
});
