/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import TaskMainListFilter from "./TaskMainListFilter";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import {
  areObjectsEqual,
  formatLabel,
  getDefaultMarkAsById,
  getIdByDefaultMarkAs,
  getPriorityNameByDefaultMarkAs,
  getStatusNameById,
} from "../../../helper/taskHelpers";
import { dateFormatter } from "../../../helper/dateFormatter";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { userProfilePage } from "../../../actions/userActions";
import { useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useIsMobile } from "../../../helper/windowHelper";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

function TaskFilterSection({
  props,
  filters,
  setFilters,
  taskListToFilter,
  overdueCount,
  projectUserList,
  taskPriorities,
  projectStatusList,
  refreshFilters,
  showRefresh,
  showOnlyCompletedTasks,
  projectTypeList,
  setShowAdvanceFilterModal,
  setServerFilters,
  serverFilters,
  setFilterType,
  filterType,
  selectedDateFilter,
  setSelectedDateFilter,
  userProfile,
  runTour,
}) {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const [saveLoad, setSaveLoad] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [selectedTaskPriority, setSelectedTaskPriority] = useState(
    serverFilters?.priority
      ? getPriorityNameByDefaultMarkAs(taskPriorities, serverFilters?.priority)
      : null
  );
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(
    serverFilters?.status
      ? getIdByDefaultMarkAs(projectStatusList, serverFilters?.status)
      : null
  );

  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {taskPriorities.map((res, index) => {
              if (res?.priorityName === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "critical"
                        ? criticalImage
                        : res?.defaultMarkAs === "highest"
                        ? highestImage
                        : res?.defaultMarkAs === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res?.priorityName}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 4,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div className="">{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const CustomTasksStatusEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectStatusList?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "not-started"
                        ? NotStarted
                        : res?.defaultMarkAs === "in-progress"
                        ? progressImage
                        : res?.defaultMarkAs === "on-hold"
                        ? reviewImage
                        : res?.defaultMarkAs === "review"
                        ? reviewIcon
                        : CompletedIcon
                    }
                    alt={res?.statusName}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      marginRight: 4,
                      marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            <div className="">{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected ? "#deebff" : "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      minWidth: "max-content", // Set the desired width
    }),
  };

  const handleType = (value) => {
    setServerFilters((prev) => ({
      ...prev,
      overdue: true,
    }));
    localStorage.removeItem("taskFilter");
  };

  // useMemo(() => {
  //   setSelectedDateFilter(serverFilters.selectedDate);
  // }, [serverFilters]);

  // Daet selection filter
  const DatePickerSection = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
    const dropdownRef = useRef(null);
    const iconRef = useRef(null);

    // Date Selection
    const fullDateToString = (inputDateString) => {
      if (!inputDateString) {
        return ""; // Return an empty string or handle accordingly if no date is provided
      }

      const inputDate = new Date(inputDateString);

      // Check if inputDate is valid
      if (isNaN(inputDate.getTime())) {
        return ""; // Return empty if date is invalid
      }

      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1; // Months are zero-based
      const year = inputDate.getFullYear();

      // Pad the day and month with leading zeros if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => setFilterType("today"),
        value: "today",
      },
      {
        label: "Tasks of This Week",
        getValue: () => setFilterType("this_week"),
        value: "this_week",
      },
      {
        label: "Tasks of This Month",
        getValue: () => setFilterType("this_month"),
        value: "this_month",
      },
      {
        label: "Tasks of Last Month",
        getValue: () => setFilterType("last_month"),
        value: "last_month",
      },
    ];

    const handleIconClick = (e) => {
      e.stopPropagation(); // Prevent dropdown from closing
      e.preventDefault();

      if (isMobile) {
        setBottomSheetOpen((prev) => !prev);
      }
    };

    // Close dropdown on outside click
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        } else if (
          sectionRef.current &&
          sectionRef.current.contains(event.target)
        ) {
          setShowDropdown((prev) => !prev);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    // Status from server
    useEffect(() => {
      if (serverFilters?.status && projectStatusList) {
        setSelectedTaskStatus(
          getIdByDefaultMarkAs(projectStatusList, serverFilters?.status)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectStatusList]);

    // Priority from server
    useEffect(() => {
      if (serverFilters?.priority && taskPriorities) {
        setSelectedTaskPriority(
          serverFilters?.priority
            ? getPriorityNameByDefaultMarkAs(
                taskPriorities,
                serverFilters?.priority
              )
            : null
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskPriorities]);

    // Drop down using portal
    const sectionRef = useRef();

    const [dropdownPosition, setDropdownPosition] = useState(null);

    useEffect(() => {
      if (showDropdown) {
        const rect = sectionRef?.current.getBoundingClientRect();
        setDropdownPosition({ top: rect.bottom, left: rect.left });
      }
    }, [showDropdown]);

    return (
      <div
        data-theme={props.isDark}
        className="project-details-main-date-filter d-flex align-items-center justify-content-between position-relative"
        onClick={handleIconClick}
      >
        {(filterType !== "all" || selectedDateFilter) && (
          <div className="selected-filter-text-container-date">
            <Icon
              icon="mdi:cross-circle"
              ref={iconRef}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedDateFilter(null);
                setServerFilters((prev) => ({
                  ...prev,
                  selectedDate: "",
                }));
                setFilterType("all");
              }}
            />
          </div>
        )}
        {/* Icon */}
        <div
          className={`d-flex align-items-center ps-2 select-item-date ${
            filterType === "all" ? "no-selected-item-date" : ""
          }`}
          ref={sectionRef}
        >
          <span>
            <Icon icon="lets-icons:date-range" />
          </span>
          <p className="p-0 m-0">
            {filterType === "all"
              ? "Due date"
              : filterType === "date"
              ? dateFormatter(selectedDateFilter, 1)
              : formatLabel(filterType)}
          </p>
          <div
            style={{
              transform:
                showDropdown || bottomSheetOpen
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            className="select-icon me-1 select-icon-date"
          >
            <IoIosArrowDown />
          </div>
        </div>

        {/* Dropdown */}
        {isMobile ? (
          <BottomSheet
            open={bottomSheetOpen}
            blocking={false}
            onDismiss={() => setBottomSheetOpen(false)}
          >
            <div className="bottom-sheet-content" data-theme={props.isDark}>
              {/* Shortcuts Column */}
              <div className="custom-date-picker-dropdown-shortcuts-mobile">
                {shortcutsItems.map((shortcut) => (
                  <button
                    key={shortcut.label}
                    className={`${
                      filterType === shortcut.value
                        ? "selected-shortcut-mobile"
                        : ""
                    }`}
                    onClick={() => shortcut.getValue()}
                  >
                    {shortcut.label}
                  </button>
                ))}
              </div>

              {/* Date Picker Column */}
              <Calendar
                onChange={(date) => {
                  setSelectedDateFilter(date);
                  setServerFilters((prev) => ({
                    ...prev,
                    selectedDate: fullDateToString([date]),
                  }));
                  setFilterType("date");
                }}
                value={selectedDateFilter}
                className="react-calendar react-calender-mobile"
              />
            </div>
          </BottomSheet>
        ) : (
          showDropdown &&
          dropdownPosition && (
            <Portal>
              <div
                ref={dropdownRef}
                className="dropdown-menu py-0 show d-flex custom-date-picker-dropdown"
                // Prevent event bubbling up from inside dropdown
                onClick={(e) => e.stopPropagation()}
                data-theme={props.isDark}
                style={{
                  zIndex: 1050,
                  top: dropdownPosition.top + 8 + "px",
                  left: dropdownPosition.left + "px",
                }}
              >
                {/* Shortcuts Column */}
                <div className="custom-date-picker-dropdown-shortcuts">
                  {shortcutsItems.map((shortcut) => (
                    <button
                      key={shortcut.label}
                      className={`btn btn-link text-start p-0 ${
                        filterType === shortcut.value ? "selected-shortcut" : ""
                      }`}
                      onClick={() => shortcut.getValue()}
                    >
                      {shortcut.label}
                    </button>
                  ))}
                </div>

                {/* Date Picker Column */}
                {/* <div
              data-theme={props.isDark}
              className="project-details-main-date-filter d-flex align-items-center justify-content-between"
            > */}
                <Flatpickr
                  data-theme={props.isDark}
                  className="project-details-task-table-flatpickr-container"
                  placeholder="MMM DD, YYYY"
                  options={{
                    allowInput: false, // Disable manual input
                    altInput: false, // Prevent rendering of alternative input
                    inline: true, // Keep calendar always visible
                    altFormat: "M d, Y",
                    dateFormat: "Y-m-d",
                    static: true,
                    disableMobile: true,
                    clickOpens: false, // Optional: Disable opening on input click
                  }}
                  value={selectedDateFilter}
                  onChange={([date]) => {
                    setSelectedDateFilter(date);
                    setServerFilters((prev) => ({
                      ...prev,
                      selectedDate: fullDateToString([date]),
                    }));
                    setFilterType("date");
                  }}
                />
              </div>
            </Portal>
            // </div>
          )
        )}
      </div>
    );
  };

  const PrioritySelectSection = () => {
    const taskPriorityList = ["Critical", "Highest", "Medium", "Low"];

    // Filter out the selected task priority from the options list
    const filteredPriorityList = taskPriorityList.filter(
      (priority) => priority !== selectedTaskPriority
    );

    // Function to get the appropriate icon for the priority
    const getPriorityIcon = (priority) => {
      switch (priority.toLowerCase()) {
        case "critical":
          return criticalImage;
        case "highest":
          return highestImage;
        case "medium":
          return mediumImage;
        case "low":
          return lowImage;
        default:
          return null;
      }
    };

    return (
      <li className={`task-situation-filter-top-wrap-select`}>
        {serverFilters?.priority && (
          <div className="selected-filter-text-container">
            <Icon
              icon="mdi:cross-circle"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTaskPriority(null);
                setServerFilters((prev) => ({
                  ...prev,
                  priority: "",
                }));
                setFilters({ ...filters, priority: null });
              }}
            />
          </div>
        )}

        <div className="form-group" data-theme={props.isDark}>
          <Select
            className={`basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div filter-item-status filter-item-status me-2 ${
              selectedTaskPriority
                ? "filter-dropdown-selected-value"
                : "filter-dropdown-not-selected-value"
            }`}
            classNamePrefix="select"
            placeholder="Priority"
            value={
              selectedTaskPriority
                ? {
                    label: selectedTaskPriority,
                    value: selectedTaskPriority,
                  }
                : null
            }
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            menuPlacement="auto" // Adjust placement based on available space
            menuPosition="fixed" // Use fixed positioning to ensure visibility
            // menuPortalTarget={document.body}
            name="color"
            options={filteredPriorityList.map((res) => ({
              label: (
                <div>
                  <img
                    src={getPriorityIcon(res)}
                    alt={res}
                    width={20}
                    height={20}
                    className="me-1"
                  />
                  {res}
                </div>
              ),
              value: res,
            }))}
            components={{ Control: CustomTasksPriorityEditorControl }}
            onChange={(e) => {
              setSelectedTaskPriority(e.value);
              setServerFilters((prev) => ({
                ...prev,
                priority: e.value.toLowerCase(),
              }));
              setFilters({ ...filters, priority: e.value.toLowerCase() });
              localStorage.removeItem("taskFilter");
            }}
          />
        </div>
      </li>
    );
  };

  useEffect(() => {
    if (!serverFilters?.status) {
      setSelectedTaskStatus(null);
    }
  }, [serverFilters]);

  const StatusSelectSection = () => {
    // Filter out the selected task status from the options list
    const filteredStatusList = projectStatusList?.filter(
      (status) => status?.id !== selectedTaskStatus
    );
    return (
      <li className={`task-situation-filter-top-wrap-select`}>
        {serverFilters?.status && (
          <div className="selected-filter-text-container selected-filter-text-status">
            <Icon
              icon="mdi:cross-circle"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTaskStatus(null);
                setServerFilters((prev) => ({
                  ...prev,
                  status: "",
                }));
                setFilters({ ...filters, status: null });
              }}
            />
          </div>
        )}
        <div className="form-group" data-theme={props.isDark}>
          <Select
            className={`filter-item-status-responsive basic-single task-filter-select task-filter-select__filter task-situation-filter-top-wrap-select-div select-item-status filter-item-status ${
              selectedTaskStatus
                ? "filter-dropdown-selected-value"
                : "filter-dropdown-not-selected-value"
            }`}
            classNamePrefix="select"
            placeholder="Status"
            value={
              selectedTaskStatus
                ? {
                    label: getStatusNameById(
                      projectStatusList,
                      selectedTaskStatus
                    ),
                    value: selectedTaskStatus,
                  }
                : null
            }
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            menuPlacement="auto" // Adjust placement based on available space
            menuPosition="fixed" // Use fixed positioning to ensure visibility
            // menuPortalTarget={document.body}
            name="color"
            options={filteredStatusList?.map((res, index) => ({
              label: (
                <div>
                  {res?.defaultMarkAs === "not-started" && (
                    <img
                      src={NotStarted}
                      alt="Not Started"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "in-progress" && (
                    <img
                      src={progressImage}
                      alt="In Progress"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "on-hold" && (
                    <img
                      src={reviewImage}
                      alt="On Hold"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "review" && (
                    <img
                      src={reviewIcon}
                      alt="Review"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "completed" && (
                    <img
                      src={CompletedIcon}
                      alt="Completed"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.statusName}
                </div>
              ),
              value: res?.id,
            }))}
            components={{ Control: CustomTasksStatusEditorControl }}
            onChange={(e) => {
              setSelectedTaskStatus(e.value);
              setServerFilters((prev) => ({
                ...prev,
                status: getDefaultMarkAsById(projectStatusList, e.value),
              }));
              localStorage.removeItem("taskFilter");
            }}
          />
        </div>
      </li>
    );
  };

  const OverdueCountSection = () => {
    return (
      <div
        className={`team-filter-option-overdue ${
          serverFilters?.overdue === true ? "team-selected-filter-options" : ""
        }`}
      >
        <a className={`team-filter-option `} onClick={() => handleType(1)}>
          {" "}
          Overdue <span>({overdueCount})</span>
        </a>
      </div>
    );
  };

  // Save filter
  const handleFilterSave = async () => {
    try {
      const data =
        areObjectsEqual(
          serverFilters,
          userProfile?.userFilters?.task?.[0]?.filterOptions
        ) &&
        filterType ===
          userProfile?.userFilters?.task?.[0]?.filterOptions?.filterType
          ? {
              status: "",
              priority: "",
              overdue: false,
              memberFilter: "member",
              memberIds: [],
              observerIds: [],
              ownerIds: [],
              multiStatus: [],
              multiPriority: [],
              teamsMembers: [],
              teamsMembersTypes: [],
              taskType: [],
              taskStartDate: "",
              taskEndDate: "",
              selectedDate: "",
              searchText: "",
              taskName: "",
              createdAt: "",
              updatedAt: "",
              projectsIds: [],
              isDeleted: false,
              hasApproval: false,
              filterType: "all",
            }
          : { ...serverFilters, filterType: filterType };
      setSaveLoad(true);
      const response = await API.post("users/filters", {
        filterName: "Task List Filter",
        filterType: "task",
        filterOptions: data,
      });
      if (response?.data?.status === "success") {
        // setIsSaved(true);
        await dispatch(userProfilePage());
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (error) {
    } finally {
      setSaveLoad(false);
      if (
        areObjectsEqual(
          serverFilters,
          userProfile?.userFilters?.task?.[0]?.filterOptions
        ) &&
        filterType ===
          userProfile?.userFilters?.task?.[0]?.filterOptions?.filterType
      ) {
        refreshFilters();
        setSelectedTaskPriority(null);
        setSelectedTaskStatus(null);
      }
    }
  };

  // Detect if filter combination is already saved
  useEffect(() => {
    if (
      areObjectsEqual(
        serverFilters,
        userProfile?.userFilters?.task?.[0]?.filterOptions
      ) &&
      filterType ===
        userProfile?.userFilters?.task?.[0]?.filterOptions?.filterType
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverFilters, userProfile, filterType]);

  return (
    <>
      {/* filters for laptop screen */}
      <div className="task-main-info-list project-main-info-laptop justify-content-between">
        <ul className="project-list-filter-container">
          <li>
            <DatePickerSection />
          </li>
          <li>
            <TaskMainListFilter
              data={filters}
              setData={setFilters}
              projectUserList={projectUserList}
              taskListToFilter={taskListToFilter}
              props={props}
              projectTypeList={projectTypeList}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
            />
          </li>
          <PrioritySelectSection />
          <StatusSelectSection />
          {!showOnlyCompletedTasks && (
            <li>
              <OverdueCountSection />{" "}
            </li>
          )}
          <li className="task-list-filters-btn-container">
            <div
              className="d-flex align-items-center task-filter-container"
              onClick={() => {
                if (!runTour) {
                  setShowAdvanceFilterModal(true);
                }
              }}
            >
              <Icon icon="solar:filter-outline" />
              <span>Filters</span>
            </div>
          </li>

          {showRefresh && (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">{`${
                    isSaved ? "Clear your filter settings" : "Save filters"
                  }`}</Tooltip>
                }
              >
                <li
                  className={`filter-save-icon ${
                    isSaved ? "saved-filter" : ""
                  }`}
                  onClick={handleFilterSave}
                >
                  {saveLoad ? (
                    <Spinner size="sm" className="me-1 global-spinner" />
                  ) : (
                    ""
                  )}
                  <Icon icon="fluent:save-20-regular" />{" "}
                  <span className="refresh-text">
                    {isSaved ? "Saved" : "Save"}
                  </span>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Reset filters</Tooltip>}
              >
                <li className="filter-refresh-icon">
                  <Icon
                    icon="humbleicons:refresh"
                    onClick={() => {
                      refreshFilters();
                      setSelectedTaskPriority(null);
                    }}
                  />{" "}
                  <span
                    className="refresh-text"
                    onClick={() => {
                      refreshFilters();
                      setSelectedTaskPriority(null);
                      setSelectedTaskStatus(null);
                    }}
                  >
                    Clear
                  </span>
                </li>
              </OverlayTrigger>
            </>
          )}
        </ul>
      </div>
      {/* filters for tablet screen */}
      <div className="ms-4 task-main-info-list project-main-info-tablet">
        <ul>
          <li>
            <DatePickerSection />
          </li>
          <li>
            <TaskMainListFilter
              data={filters}
              setData={setFilters}
              projectUserList={projectUserList}
              taskListToFilter={taskListToFilter}
              props={props}
              projectTypeList={projectTypeList}
              setServerFilters={setServerFilters}
              serverFilters={serverFilters}
            />
          </li>
          <PrioritySelectSection />
          <StatusSelectSection />
          <div className="project-main-info-tablet">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="secondary"
                id="color-dropdown"
                className="no-arrow-dd "
              >
                <div className="menu-btn">
                  <Icon icon="mi:options-horizontal" />
                </div>
              </Dropdown.Toggle>{" "}
              <Dropdown.Menu>
                {!showOnlyCompletedTasks && (
                  <Dropdown.Item
                    className="project-filter-dropdown-list"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <OverdueCountSection />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <li className="task-list-filters-btn-container">
            <div
              className="d-flex align-items-center task-filter-container"
              onClick={() => {
                if (!runTour) {
                  setShowAdvanceFilterModal(true);
                }
              }}
            >
              <Icon icon="solar:filter-outline" />
              <span>Filters</span>
            </div>
          </li>

          {showRefresh && (
            <>
              <li
                className={`filter-save-icon ${isSaved ? "saved-filter" : ""}`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${
                  isSaved ? "Clear your filter settings" : "Save filters"
                }`}
                onClick={handleFilterSave}
              >
                {saveLoad ? (
                  <Spinner size="sm" className="me-1 global-spinner" />
                ) : (
                  ""
                )}
                <Icon icon="fluent:save-20-regular" />{" "}
                <span className="refresh-text">
                  {isSaved ? "Saved" : "Save"}
                </span>
              </li>
              <li
                className="filter-refresh-icon"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Reset filters"
              >
                <Icon
                  icon="humbleicons:refresh"
                  onClick={() => {
                    refreshFilters();
                    setSelectedTaskPriority(null);
                  }}
                />{" "}
                <span
                  className="refresh-text"
                  onClick={() => {
                    refreshFilters();
                    setSelectedTaskPriority(null);
                    setSelectedTaskStatus(null);
                  }}
                >
                  Clear
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* filters for mobile screen */}
      <div className="project-main-info-mobile ms-4">
        <div className="d-flex align-items-center gap-2">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              variant="secondary"
              id="color-dropdown"
              className="no-arrow-dd "
            >
              <div className="menu-btn">
                <Icon icon="mi:options-horizontal" />
              </div>
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="project-filter-dropdown-list"
              >
                <DatePickerSection />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <TaskMainListFilter
                  data={filters}
                  setData={setFilters}
                  projectUserList={projectUserList}
                  taskListToFilter={taskListToFilter}
                  props={props}
                  projectTypeList={projectTypeList}
                  setServerFilters={setServerFilters}
                  serverFilters={serverFilters}
                />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PrioritySelectSection />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <StatusSelectSection />
              </Dropdown.Item>

              {!showOnlyCompletedTasks && (
                <Dropdown.Item
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="project-filter-dropdown-list"
                >
                  <OverdueCountSection />
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="project-filter-dropdown-list"
              >
                <li className="task-list-filters-btn-container">
                  <div
                    className="d-flex align-items-center task-filter-container"
                    onClick={() => {
                      if (!runTour) {
                        setShowAdvanceFilterModal(true);
                      }
                    }}
                  >
                    <Icon icon="solar:filter-outline" />
                    <span>Filters</span>
                  </div>
                </li>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ul className="mb-0 mobile-view-filter-list">
            {showRefresh && (
              <>
                <li
                  className={`filter-save-icon ${
                    isSaved ? "saved-filter" : ""
                  }`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`${
                    isSaved ? "Clear your filter settings" : "Save filters"
                  }`}
                  onClick={handleFilterSave}
                >
                  {saveLoad ? (
                    <Spinner size="sm" className="me-1 global-spinner" />
                  ) : (
                    ""
                  )}
                  <Icon icon="fluent:save-20-regular" />{" "}
                  <span className="refresh-text">
                    {isSaved ? "Saved" : "Save"}
                  </span>
                </li>
                <li
                  className="filter-refresh-icon"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Reset filters"
                >
                  <Icon
                    icon="humbleicons:refresh"
                    onClick={() => {
                      refreshFilters();
                      setSelectedTaskPriority(null);
                    }}
                  />{" "}
                  <span
                    className="refresh-text"
                    onClick={() => {
                      refreshFilters();
                      setSelectedTaskPriority(null);
                      setSelectedTaskStatus(null);
                    }}
                  >
                    Clear
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default TaskFilterSection;
