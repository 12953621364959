import React, { useEffect, useState } from "react";
import avatar from "../../../assets/avatar.svg";

function ProjectDetailTaskListingMemberEditor(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabClick = (tabIndex) => {
    setCurrentTab(tabIndex);
  };
  useEffect(() => {
    // Registering event listeners
    const handleTabClick = (tabIndex) => {
      setCurrentTab(tabIndex);
    };

    document
      .querySelector(".task-user-filter-dropdown-tab-bar")
      .addEventListener("click", (event) => {
        const target = event.target;
        const tabIndex = parseInt(target.getAttribute("data-tab-index"), 10);
        if (!isNaN(tabIndex)) {
          handleTabClick(tabIndex);
        }
      });
  }, [document]);

  return (
    <div
      data-theme={props.isDark}
      className="position-absolute rounded-3 shadow-lg task-user-filter-dropdown"
    >
      <div className="task-user-filter-dropdown-tab-bar">
        <ul className="custom-horizental-scrollbar p-0 m-0">
          <li>
            <a
              className={`${
                currentTab === 0
                  ? "active-overview border-1 border-secondary"
                  : ""
              }`}
              onClick={() => setCurrentTab(0)}
            >
              {" "}
              All{" "}
            </a>
            <div className={`${currentTab === 0 ? "active-overview" : ""}`}></div>
          </li>
          <li>
            <a
              className={`${currentTab === 2 ? "active-overview" : ""}`}
              onClick={() => setCurrentTab(2)}
            >
              {" "}
              Admin{" "}
            </a>
            <div></div>
          </li>
          <li>
            <a
              className={`${currentTab === 1 ? "active-overview" : ""}`}
              onClick={() => setCurrentTab(1)}
            >
              Members
            </a>
            <div></div>
          </li>
          <div className="w-100 "></div>
        </ul>
      </div>
      {currentTab === 0 && (
        <TaskUsersListForFilter
          isDark={props.isDark}
          getOptions={props.getOptions}
        />
      )}

      {currentTab === 1 && (
        <TaskUsersListForFilter
          isDark={props.isDark}
          setCurrentTab={setCurrentTab}
        />
      )}
      {currentTab === 2 && <TaskUsersListForFilter isDark={props.isDark} />}
    </div>
  );
}

const TaskUsersListForFilter = ({ isDark, title }) => {
  return (
    <div className="task-user-filter-dropdown-menu custom-vertical-scrollbar my-2">
      {title}
      <TaskUsersListItemForFilter />
      <TaskUsersListItemForFilter />
      <TaskUsersListItemForFilter />
      <TaskUsersListItemForFilter />
      <TaskUsersListItemForFilter />
      <TaskUsersListItemForFilter />
    </div>
  );
};

const TaskUsersListItemForFilter = () => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="py-1 ps-1 w-100 border-bottom-1 task-user-filter-dropdown-menu-item">
      <div className="checkbox create-task-checkbox" onClick={handleCheckboxClick}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <label className="task-user-filter-dropdown-menu-item-label">
          <img
            src={avatar}
            alt="Avatar"
            className="task-user-filter-dropdown-menu-item-label-img"
          />
          <span className="ps-1">Lorem Ipsem{isChecked ? 1 : 0}</span>
        </label>
      </div>
    </div>
  );
};

export default ProjectDetailTaskListingMemberEditor;
