import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDeletePerformanceTemplateMutation } from "../../../slices/performance/performanceSlice";
import DeleteIcon from "../../../assets/delete-circle.svg";
import { toast } from "react-toastify";

const PSKPIDeleteModal = ({ isShow, handleClose, isDark, template }) => {
  const [deletePerformanceTemplate, { isLoading }] =
    useDeletePerformanceTemplateMutation();

  // Delete template functionality
  const handleDelete = async () => {
    try {
      await deletePerformanceTemplate(template.id).unwrap();
      handleClose(); // Close modal after successful deletion
      toast.success("Performance template deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (err) {
      toast.error("Failed to delete performance template. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} width={100} height={100} alt="delete-icon" />
        </div>
        <h5>Are you sure</h5>
        <h5>you want to delete</h5>
        <p className="del-modal-name">{template?.templateName} ?</p>
        <p className="point-label">
          Deleting this performance template will automatically reassign all
          associated users to the default performance template.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            disabled={isLoading}
            className="w-50 delete-btn-1"
            onClick={handleDelete}
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button className="w-50 delete-btn-2" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PSKPIDeleteModal;
