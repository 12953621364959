// Checked members should always be on top
export const sortCheckedMembersOnTop = (members, selectedUsers, currentTab) => {
  return members?.sort((a, b) => {
    const aIsChecked = selectedUsers?.some(
      (user) =>
        user.userId === a.userId &&
        user.memberType ===
          (currentTab === 1
            ? "observer"
            : currentTab === 2
            ? "owner"
            : "member")
    );

    const bIsChecked = selectedUsers?.some(
      (user) =>
        user.userId === b.userId &&
        user.memberType ===
          (currentTab === 1
            ? "observer"
            : currentTab === 2
            ? "owner"
            : "member")
    );

    // Priority for checked users of the current tab
    if (aIsChecked && !bIsChecked) {
      return -1;
    } else if (!aIsChecked && bIsChecked) {
      return 1;
    }

    // Secondary sorting (you can customize this)
    if (a.userName < b.userName) {
      return -1;
    } else if (a.userName > b.userName) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const sortMembersByTab = (members, currentTab) => {
  const tabOrder = {
      owner: 1,
      observer: 2,
      member: 3
  };

  return members.sort((a, b) => {
      const aOrder = tabOrder[a.memberType];
      const bOrder = tabOrder[b.memberType];

      // If memberType is the same as currentTab, it appears first
      if (a.memberType === currentTab && b.memberType !== currentTab) {
          return -1;
      } else if (a.memberType !== currentTab && b.memberType === currentTab) {
          return 1;
      }

      // Sort by tabOrder if memberType is different from currentTab
      return aOrder - bOrder;
  });
};
