import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import StatusIcon from "../../../assets/status-modal-icon.png";
import PriorityIcon from "../../../assets/priority-modal-icon.png";
import UserIcon from "../../../assets/user-modal-icon.png";
import DateIcon from "../../../assets/date-modal-icon.png";
import avatar from "../../../assets/avatar.svg";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import {
  dateFormatter,
  formatDeadlineDate,
} from "../../../helper/dateFormatter";
import { Icon } from "@iconify/react/dist/iconify.js";

/**
 * Helper function to retrieve the appropriate status icon
 * based on a given status `defaultMarkAs` value.
 */
export const getStatusIcon = (defaultMarkAs) => {
  switch (defaultMarkAs) {
    case "not-started":
      return NotStarted;
    case "in-progress":
      return progressImage;
    case "on-hold":
      return reviewImage;
    case "review":
      return reviewIcon;
    case "completed":
      return CompletedIcon;
    default:
      return NotStarted; // Fallback icon if status is unrecognized
  }
};

/**
 * Helper function to retrieve the priority icon based on
 * a given priority `defaultMarkAs` value.
 */
export const getPriorityIcon = (defaultMarkAs) => {
  switch (defaultMarkAs) {
    case "critical":
      return criticalImage;
    case "highest":
      return highestImage;
    case "medium":
      return mediumImage;
    case "low":
      return lowImage;
    default:
      return mediumImage; // Fallback icon if priority is unrecognized
  }
};

/**
 * ConfirmBulkAction Modal Component
 * Displays a confirmation modal for various bulk actions.
 */
const ConfirmBulkAction = ({
  showConfirmModal, // Boolean for modal visibility
  handleCloseConfirmModal, // Function to close the modal
  isDark, // Boolean for theme (light/dark)
  handleSubmit, // Function for modal "Proceed" button
  isLoading, // Boolean for loading state
  formData, // Object containing form data
  projectOptions, // Array of project options
  projectUserList, // Array of project users
  projectStatusList, // Array of status options
  taskPriorities, // Array of priority levels
}) => {
  /**
   * Renders the status icon and label based on selected status in formData.
   */
  const renderStatus = () => {
    const status = projectStatusList.find(
      (status) => status.id === formData.actionValue
    );
    if (status) {
      return (
        <div className="d-flex align-items-center">
          <img
            src={getStatusIcon(status.defaultMarkAs)}
            alt={`${status.statusName} icon`}
            className="bulk-modal-name-img"
            style={{ width: "24px", height: "24px" }}
          />
          <p className="bulk-modal-name mb-0">{status.statusName}</p>
        </div>
      );
    }
    return <p className="bulk-modal-name mb-0">Unknown Status</p>; // Fallback text if status not found
  };

  /**
   * Renders privacy setting with capitalized first letter of action value.
   */
  const renderPrivacy = () => {
    return (
      <div className="d-flex align-items-center">
        <p className="bulk-modal-name mb-0">
          {formData.actionValue.charAt(0).toUpperCase() +
            formData.actionValue.slice(1)}
        </p>
      </div>
    );
  };

  /**
   * Renders the priority icon and label based on selected priority in formData.
   */
  const renderPriority = () => {
    const priority = taskPriorities?.find(
      (priority) => priority.id === formData.actionValue
    );
    if (priority) {
      return (
        <div className="d-flex align-items-center">
          <img
            src={getPriorityIcon(priority.defaultMarkAs)}
            alt={`${priority.priorityName} icon`}
            className="bulk-modal-name-img"
            style={{ width: "24px", height: "24px" }}
          />
          <p className="bulk-modal-name mb-0">{priority.priorityName}</p>
        </div>
      );
    }
    return <p className="bulk-modal-name mb-0">Unknown Priority</p>; // Fallback if priority not found
  };

  /**
   * Renders the formatted deadline date.
   */
  const renderDeadline = () => {
    const formattedDate = dateFormatter(formData.actionValue, 3);
    return (
      <p className="bulk-modal-name mb-0">
        <span>
          <Icon icon="lets-icons:date-range" />
        </span>
        {formattedDate}
      </p>
    );
  };

  /**
   * Returns the icon based on the action type in formData.
   */
  const getIconForActionType = (actionType) => {
    switch (actionType) {
      case "statusChange":
        return StatusIcon;
      case "priorityChange":
      case "projectAssignment":
        return PriorityIcon;
      case "assignedTo":
        return UserIcon;
      case "deadlinesChange":
        return DateIcon;
      default:
        return StatusIcon; // Fallback icon if actionType not recognized
    }
  };

  // Retrieve the correct icon for the action type
  const icon = getIconForActionType(formData?.actionType);

  return (
    <Modal
      show={showConfirmModal}
      onHide={() => !isLoading && handleCloseConfirmModal()}
      className="main-confirm-bulk-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        {/* Modal Icon */}
        <div className="bulk-modal-img-container">
          <img src={icon} width={83} height={83} alt="bulk-modal-icon" />
        </div>
        {/* Modal Headings */}
        <h5>
          {formData?.actionType === "assignedTo"
            ? "Do you confirm assigning"
            : formData?.actionType === "deadlinesChange"
            ? "Do you want to change the"
            : "Do you confirm changing"}
        </h5>
        <h5>
          {formData?.actionType === "statusChange"
            ? "status to"
            : formData?.actionType === "priorityChange"
            ? "priority to"
            : formData?.actionType === "deadlinesChange"
            ? "due date to"
            : formData?.actionType === "assignedTo"
            ? "this to"
            : formData?.actionType === "projectAssignment"
            ? "project to"
            : formData?.actionType === "projectPrivacyChange"
            ? "privacy to"
            : ""}
        </h5>
        {/* Render the appropriate action element */}
        {formData?.actionType === "projectAssignment" &&
          (() => {
            const project = projectOptions?.find(
              (proj) => proj.value === formData.actionValue
            );
            return project ? (
              <div className="d-flex align-items-center">
                <p className="bulk-modal-name mb-0">
                  {project.icon} {project.label}
                </p>
              </div>
            ) : (
              <p className="bulk-modal-name mb-0">Unknown Project</p>
            );
          })()}
        {formData?.actionType === "assignedTo" &&
          (() => {
            const user = projectUserList?.find(
              (user) => user.userId === formData.actionValue
            );
            return user ? (
              <div className="d-flex align-items-center">
                <img
                  src={user.profileImage || avatar}
                  alt={`${user.userName} profile`}
                  className="rounded-circle bulk-modal-name-img mb"
                  style={{ width: "24px", height: "24px" }}
                />
                <p className="bulk-modal-name mb-0">{user.userName}</p>
              </div>
            ) : (
              <p className="bulk-modal-name mb-0">Unknown User</p>
            );
          })()}
        {formData?.actionType === "statusChange" && renderStatus()}
        {formData?.actionType === "priorityChange" && renderPriority()}
        {formData?.actionType === "deadlinesChange" && renderDeadline()}
        {formData?.actionType === "projectPrivacyChange" && renderPrivacy()}
      </Modal.Body>
      {/* Footer Buttons */}
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Proceed"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={handleCloseConfirmModal}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBulkAction;
