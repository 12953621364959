import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const RoleNameModal = (props) => {
  const [rload, setRLoad] = useState(false);
  const [err, setErr] = useState("");
  const initialValues = {
    role: props.role.name,
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Role name is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    // You can perform any additional submission logic here
    // If there are no validation errors

    if (values.role) {
      try {
        setRLoad(true);
        const response = await API.post("company/role-update", {
          roleId: props?.role?.id,
          roleName: values.role,
        });
        if (response?.data?.status === "success") {
          props.handleClose();
          toast.success("Role updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          props.getRolesAfterUpdate();
        }
        if (response === undefined) {
          setErr("Role name already exists");
        }
      } catch (error) {
      } finally {
        setRLoad(false);
      }
    }

    // Set submitting to false to allow the form to be submitted again
    setSubmitting(false);
  };

  return (
    <Modal
      show={props.modal}
      onHide={props.handleClose}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="col-md-12 choose-industry-columns ">
          <div>
            <div className="form-section-wrap form-modal-wrap role-modal">
              <h4>
                {" "}
                Edit <span>role name</span>{" "}
              </h4>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="form-group">
                      <label>Role*</label>
                      <Field
                        type="role"
                        name="role"
                        className={`form-control ${
                          (touched.role && errors.role) || err
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Add new role"
                        onChange={(e) => {
                          setFieldValue("role", e.target.value); // This will update the form value
                          setErr(""); // Reset the error
                        }}
                      />
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="invalid-feedback"
                      />
                      {err && (
                        <p
                          className="text-end"
                          style={{ fontSize: "14px", color: "#e93131" }}
                        >
                          {err}
                        </p>
                      )}
                    </div>

                    <div className="btn-group-signup">
                      <button
                        type="submit"
                        className="d-flex align-items-center justify-content-center"
                      >
                        {rload ? <Spinner animation="border" /> : "Update"}
                      </button>
                    </div>

                    {/* <div className="or-text-signup">
                        <span>OR</span>
                      </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RoleNameModal;
