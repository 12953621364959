import ProgressIcon from "../assets/in-progress.svg";
import DoneIcon from "../assets/completed-icon.png";
import OnHoldIcon from "../assets/on-hold.svg"; // Assuming this is for "On Hold"
import ReviewIcon from "../assets/submit-for-review-icon.png";
import criticalImage from "../assets/not-started.svg"; // Not used in current logic

export const getIconByStatusId = (statusList, statusId) => {
  const status = statusList.find((s) => s.id === statusId);

  // Switch or if/else for clarity
  switch (
    status?.value.toLowerCase() // Make case-insensitive
  ) {
    case "in progress":
      return ProgressIcon;
    case "completed":
      return DoneIcon;
    case "on hold":
      return OnHoldIcon;
    case "submit for review":
      return ReviewIcon;
    case "not started": // Add icon for "Not Started" if needed
      return criticalImage; // or return a placeholder icon
    default:
      return null; // Handle unknown statuses gracefully
  }
};
