import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  CLEAR_ERROR,
} from "../constants/companiesConstants";

export const companiesListingReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesList: action.payload,
      };

    case COMPANY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
