import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import API from "./services/ClientApi";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4Arg0Z3YzsVS39xCiKseF2vUkQDcZYb4",
  authDomain: "iffrataskpro.firebaseapp.com",
  projectId: "iffrataskpro",
  storageBucket: "iffrataskpro.firebasestorage.app",
  messagingSenderId: "40149599278",
  appId: "1:40149599278:web:36c9c0f01d26a3e66a8bbf",
  measurementId: "G-0ZM9LRHTBC",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  return getToken(messaging, {
    vapidKey:
      "BD2rour1EuHRP2ZlqzjEPTcIklpekE9ZzyeYqZyKK3h5GKF6anjwpqGvZVWXuI3vyZyYFecWuu2Luwcxeov_Y6M",
  })
    .then(async (currentToken) => {
      if (currentToken) {
        await API.post("users/device-token/update", {
          deviceToken: currentToken,
        });
      } else {
        // Show permission request UI
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
    });
};

// When app is in foreground, handle messages in such cases
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
