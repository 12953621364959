import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Offcanvas, ProgressBar, Spinner } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import CloseBtn from "../../../assets/icon-close.svg";
import Button from "../../../components/Button";
import InfoIcon from "../../../assets/info.svg";
import P1Icon from "../../../assets/project1.svg";
import P2Icon from "../../../assets/project2.svg";
import P3Icon from "../../../assets/project3.svg";
import P4Icon from "../../../assets/project4.svg";
import P5Icon from "../../../assets/project5.svg";
import PhotoIcon from "../../../assets/photo.svg";
import MultiSelect from "../../../components/MultiSelect";
import "react-tooltip/dist/react-tooltip.css"; // Import CSS
import {
  projectFormListingAction,
  projectTypeListingAction,
} from "../../../actions/projectActions";
import { toast } from "react-toastify";
import InviteModal from "../../../components/InviteModal/InviteModal";
import AddOnModal from "../../SubscriptionsUpdated/components/AddOnModal";
import ConfirmAddonModal from "../../../components/ConfirmAddonModal";
import API from "../../../services/ClientApi";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import PlanProjectPhaseDetailSection from "../../ProjectDetail/components/PlanProjectPhases/PlanProjectPhaseDetailSection";
import { FileIcon, defaultStyles } from "react-file-icon";
import { teamsListAction } from "../../../actions/teamsActions";
import NewTeam from "../../Team/components/NewTeam";
import { isPermitted } from "../../../helper/isPermitted";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { v4 as uuidv4 } from "uuid";
import { useCreateProjectDataMutation } from "../../../slices/projects/projectSlice";
import {
  useUpdatePhaseTaskDataMutation,
  useUpdatePhaseTaskMemberDataMutation,
} from "../../../slices/tasks/projectDetailSectionSlice";
import { formatFileSize } from "../../../helper/taskHelpers";
import {
  calculateTotalSize,
  validateFile,
} from "../../Tasks/components/CreateTaskModal";

const ProjectNewModal = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updatePhaseTaskData, { isLoading: isUpdating }] =
    useUpdatePhaseTaskDataMutation();
  const [
    updatePhaseTaskMemberData,
    { isLoading: isMemberLoading, isSuccess: memberSuccess },
  ] = useUpdatePhaseTaskMemberDataMutation();
  // const { loading } = useSelector((state) => state.createProjectData);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { billingDetail } = useSelector((state) => state.billing);
  const [planData, setPlanData] = useState(null);
  const [user, setUser] = useState(null);
  const { userProfile } = useSelector((state) => state?.profile);
  const [privacy, setPrivacy] = useState(0);
  const [modal, setModal] = useState(props.modal ? props.modal : false);
  const [projProfile, setProjProfile] = useState(0);
  const [projProfileImg, setProjProfileImg] = useState(null);
  const [observerOptions, setObserverOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [newProjectId, setNewProjectId] = useState(null);
  // create state for newly created project with name newlyCreatedProject
  const [newlyCreatedProject, setNewlyCreatedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [projectProfileBinaryImage, setProjectProfileBinaryImage] =
    useState(null);
  const { teamsOptionsList } = useSelector((state) => state.teamsListData);
  const [show, setShow] = useState(false);
  const [cropper, setCropper] = useState(null);
  const imageRef = useRef(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { registeredUserCount } = useSelector(
    (state) => state?.registeredUserCount
  );

  const handleModalClose = () => {
    setShowCropModal(false);
    setCropper(null); // Clean up Cropper instance
  };

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleAddonShow = () => {
    setAddonModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to convert projectMembers array
  const convertProjectArray = (projectArray) => {
    return projectArray?.map((project) => ({
      id: project.id,
      value: project.projectName,
      label: project.projectName,
    }));
  };

  const { userLoading: projectUserListLoading, projectUserList } = useSelector(
    (state) => state.createProjectUsers
  );

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const getFileType = (attachmentName) => {
    const extension = attachmentName?.split(".").pop().toLowerCase();
    return extension;
  };

  const handleClose = () => {
    props.handleCloseModal();
    setObserverOptions([]);
    setTeamOptions([]);
    setOwnerOptions([]);
  };

  const updateState = (typeName, users) => {
    // Ensure users is always an array, even for single user updates

    const userList = Array.isArray(users) ? users : [users];

    // Find the corresponding state setter based on typeName
    const stateSetter = {
      owner: setOwnerOptions,
      observer: setObserverOptions,
      member: setTeamOptions,
    }[typeName];

    if (stateSetter && userList.length > 0) {
      const type = getTypeIDByName(typeName);
      if (type) {
        // Efficiently update state using spread syntax and object destructuring
        stateSetter((prevOptions) => [
          ...userList?.map((user) => ({
            id: user?.userId,
            value: user?.userId,
            label: user?.userName,
            type_id: type,
          })),
        ]);
      }
    } else {
      // console.warn(`Invalid typeName: ${typeName} or empty users array`);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    // Reset input value to allow the same file to trigger onChange
    event.target.value = null;
    setFile(file);
    setProjectProfileBinaryImage(file);
    if (file) {
      // Assuming file is an image
      setShowCropModal(true);
      const reader = new FileReader();
      reader.onload = () => {
        setProjProfileImg(reader.result); // Set the selected image as projProfile
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const initialValues = {
    project_name: "",
    team_members: [],
    owner_of_project: ownerOptions.find(
      (option) => option.id === userProfile?.userId
    ),
    observer: [],
    project_description: "",
    projectTeamId: null,
  };

  const validationSchema = Yup.object().shape({
    project_name: Yup.string()
      .max(255, "Project name cannot exceed 255 characters") // Limit to 255 characters
      .required("Project name is required"),
    owner_of_project: Yup.object().required("Owner of project is required"),
    team_members: Yup.array()
      .required("At least one team member is required")
      .min(1, "At least one team member is required"),
  });

  const handlePrivacy = (value) => {
    setPrivacy(value);
  };

  const imageOptions = [
    {
      id: 0,
      url: P1Icon,
    },
    {
      id: 1,
      url: P2Icon,
    },
    {
      id: 2,
      url: P3Icon,
    },
    {
      id: 3,
      url: P4Icon,
    },
    {
      id: 4,
      url: P5Icon,
    },
  ];

  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    // Get year, month, and day from the date object
    const year = date.getFullYear();
    // Month is zero-indexed, so add 1 to get the correct month
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  };

  // Helper function to format date as "YYYY-MM-DD"
  const formatDateAfterWeek = (date) => {
    // Get the current date
    const currentDate = date;

    // Add 7 days to the current date
    const oneWeekLater = new Date(
      currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );

    // Get year, month, and day from the new date object
    const year = oneWeekLater.getFullYear();
    // Month is zero-indexed, so add 1 to get the correct month
    const month = String(oneWeekLater.getMonth() + 1).padStart(2, "0");
    const day = String(oneWeekLater.getDate()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  };

  const getUsersAndTypes = async () => {
    try {
      const response = await dispatch(projectTypeListingAction());
      if (response?.data?.status === "success") {
        updateState("owner", projectUserList?.data);
        updateState("observer", projectUserList?.data);
        updateState("member", projectUserList?.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const getPlanData = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPlanData(response?.data?.data?.packageDetails);
      }
      const data = await API.get("users/basic-info");
      setUser(data?.data?.data);
      // dispatch(billingData());
    } catch (error) {}
  };

  useEffect(() => {
    getUsersAndTypes();
    getPlanData();
    if (!teamsOptionsList) {
      dispatch(teamsListAction());
    }
    if (!projectFormList) {
      dispatch(projectFormListingAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  // Get Team List
  const getTeamList = async () => {
    dispatch(teamsListAction());
  };

  // File upload
  const allowedExtensions = [
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "doc",
    "docx",
    "txt",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "gif",
    "bmp",
    "svg",
    "zip",
    "rar",
    "mp3",
    "mp4",
  ];

  // Map extensions to MIME types
  const extensionToMime = {
    pdf: "application/pdf",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    txt: "text/plain",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    gif: "image/gif",
    bmp: "image/bmp",
    svg: "image/svg+xml",
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    mp3: "audio/mpeg",
    mp4: "video/mp4",
  };

  // Generate the structured `accept` object
  const acceptObject = Object.entries(extensionToMime).reduce(
    (acc, [extension, mimeType]) => {
      acc[mimeType] = [`.${extension}`];
      return acc;
    },
    {}
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: acceptObject, // Define accepted file types
    onDrop: (acceptedFiles, rejectedFiles) => {
      // Handle rejected files
      // if (rejectedFiles.length > 0) {
      //   const rejectedFileNames = rejectedFiles.map((file) => file.name);
      //   alert(
      //     `The following files are not allowed: ${rejectedFileNames.join(", ")}`
      //   );
      // }
      const totalSize = calculateTotalSize(acceptedFiles);

      if (totalSize > 8 * 1024 * 1024) {
        // 8MB in bytes
        toast.error(
          "The total size of all files exceeds 8MB. Please remove some files.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        return; // Prevent further execution if validation fails
      }

      const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

      // if (filteredFiles.length === 0) {
      //   // Handle case where no valid files were dropped
      //   toast.error("Please enter a valid file.", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      //   return;
      // }
      setFiles((prevFiles) => [
        ...prevFiles,
        ...filteredFiles.map((file) =>
          Object.assign(file, {
            preview:
              file.type.startsWith("image/") &&
              /\.(jpg|jpeg|png|webp|gif)$/i.test(file.name)
                ? URL.createObjectURL(file)
                : null, // Generate preview URL for images
          })
        ),
      ]);
    },
  });

  const handleDeleteFile = (fileToDelete) => {
    const updatedFiles = files.filter((file) => file !== fileToDelete);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    // Initialize Cropper when the modal opens and an image is selected
    if (showCropModal && file && imageRef.current) {
      const image = imageRef.current;
      image.src = URL.createObjectURL(file);
      setCropper(
        new Cropper(image, {
          // Optional cropping options
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    // Cleanup Cropper when the modal closes or component unmounts
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCropModal, file, imageRef, setCropper]);

  const handleImageUpload = async (image) => {
    const croppedCanvas = cropper.getCroppedCanvas();
    await croppedCanvas.toBlob(async (blob) => {
      const fileNew = new File([blob], `project-icon-${uuidv4()}.jpg`, {
        type: blob.type,
      });
      // Use the blob here, e.g., for upload, display, or download
      if (fileNew) {
        // Assuming file is an image
        setProjectProfileBinaryImage(fileNew);
        const reader = new FileReader();
        reader.onload = () => {
          setProjProfileImg(reader.result); // Set the selected image as projProfile
          setShowCropModal(false);
        };
        reader.readAsDataURL(fileNew);
      }
      // Handle API response (e.g., update profile image URL)
    });
  };

  // const handleImageError = (event) => {
  //   // Handle broken image scenario
  //   event.target.src = ProfileNew; // Use the imported ProfileNew image
  // };

  const [createProjectData] = useCreateProjectDataMutation();

  // Custom Toast Component
  const UpdatingTaskToast = ({ onClose }) => {
    const [progress, setProgress] = useState(25); // Start at 25%
    const [statusText, setStatusText] = useState("Updating Task...");

    useEffect(() => {
      let interval;

      // Gradually increase the progress to 75%
      interval = setInterval(() => {
        setProgress((prev) => (prev < 75 ? prev + 10 : prev));
      }, 1000);

      return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
      <div className="d-flex flex-column align-items-start on-update-loader">
        <span className="">{statusText}</span>
        <ProgressBar
          now={progress}
          variant="info"
          className="w-100 mt-2"
          style={{ height: "2px", borderRadius: "4px" }}
        />
      </div>
    );
  };

  // Function to trigger the toast
  const showUpdatingToast = () => {
    toast.info(<UpdatingTaskToast />, {
      position: "top-right",
      autoClose: false, // Prevent auto-close until manually dismissed
      closeButton: false,
      hideProgressBar: true,
      icon: false,
      className: "custom-toast-updating", // Custom class for the toast
    });
  };

  useEffect(() => {
    if (isUpdating || isMemberLoading) {
      // Show the updating toast when isUpdating becomes true
      showUpdatingToast();
    } else {
      // Dismiss the toast when the update completes
      toast.dismiss();
    }
  }, [isUpdating, isMemberLoading]);

  return (
    <Offcanvas
      className={`off-concave-role ${modal ? "off-concave-proj-modal" : ""}`}
      show={props.show}
      // onHide={() => {
      //   if (props.creation) {
      //     setModal(false);
      //   }
      //   handleClose();
      // }}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        {!modal ? (
          <div className="create-project-new-wrap">
            <h3>
              {" "}
              Create <span>Project</span>{" "}
            </h3>
            <p>
              Manage your project with IFFRA-Task Pro that saves more than 60%
              of your operational time and increase efficiency. Start it by
              providing project details with targets to achieve.
            </p>
            {/* {projectUserListLoading ? (
              <div className="w-100 d-flex align-items-center justify-content-center">
                <Spinner
                  animation="border"
                  className="global-spinner"
                ></Spinner>
              </div>
            ) : ( */}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                // Combine team_members and observers into a single array of objects
                const combinedArray = [
                  ...values?.team_members.map((member) => ({
                    [member.type_id]: member.id,
                  })),
                  ...values?.observer.map((observer) => ({
                    [observer.type_id]: observer.id,
                  })),
                ];

                // Handle your form submission logic here
                const finalProjectData = {
                  projectName: values.project_name,
                  projectIcon: projProfile.toString(),
                  projectType: "regular",
                  projectPrivacy: privacy === 0 ? "public" : "private",
                  projectStartDate: formatDate(new Date()),
                  projectEndDate: formatDateAfterWeek(new Date()),
                  projectDescription: values.project_description,
                  projectTeamId:
                    values?.projectTeamId && values?.projectTeamId?.length > 0
                      ? values.projectTeamId
                          ?.flatMap((item) => item?.id)
                          .join(",")
                          .toString()
                      : "",
                  projectMembers: [
                    ...combinedArray,
                    {
                      [values?.owner_of_project?.type_id]:
                        values?.owner_of_project?.id,
                    },
                  ],
                };

                const formData = new FormData(); // Create a new FormData object

                // Append project data to FormData
                for (const key in finalProjectData) {
                  if (key === "projectMembers") {
                    // Convert projectMembers array into string representation
                    finalProjectData.projectMembers.forEach((member, index) => {
                      const [memberKey, memberValue] =
                        Object.entries(member)[0];
                      formData.append(
                        `projectMembers[]`,
                        `${memberKey} : ${memberValue}`
                      );
                    });
                  } else {
                    finalProjectData[key] &&
                      formData.append(key, finalProjectData[key]);
                  }
                }
                // Assuming 'files' is an array of File objects
                files.forEach((file) => {
                  formData.append("attachments", file);
                });

                projectProfileBinaryImage &&
                  formData.append("logo", projectProfileBinaryImage);

                try {
                  const response = await createProjectData(formData);
                  if (response) {
                    setNewProjectId(response?.data?.data?.project?.id);
                    setNewlyCreatedProject(response?.data?.data?.project);
                    const { id, ...rest } = response?.data?.data?.project;

                    // await dispatch(addProjectAction(updatedProjectsDetail));
                    props.getProjectList();

                    toast.success("Project created successfully", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    dispatch(projectFormListingAction());
                  }
                  setModal(true);
                } catch (error) {
                  toast.error("An unexpected error occured", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                } finally {
                  setFiles([]);
                  setLoading(false);
                }
                // setModal(true);

                //Reset the form after submission
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form className="row" onSubmit={handleSubmit}>
                  <Tooltip id="my-tooltip" />
                  <div className="col-md-8">
                    <div className="form-group">
                      <label>
                        {" "}
                        Project name{" "}
                        <span className="required-fields-span">*</span>{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Project Name"
                        />{" "}
                      </label>
                      <input
                        type="text"
                        name="project_name"
                        placeholder="Project Name"
                        autoComplete="off"
                        value={values.project_name}
                        onChange={handleChange}
                        isInvalid={
                          touched.project_name && !!errors.project_name
                        }
                        className={`form-control ${
                          touched.project_name && errors.project_name
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="project_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="radio-button-wrap form-group">
                      <label>
                        {" "}
                        Privacy{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Privacy"
                        />{" "}
                      </label>

                      <div className="form-check-inline">
                        <label className="customradio">
                          <span className="radiotextsty">Public</span>
                          <input
                            type="radio"
                            checked={privacy === 0}
                            name="radio"
                            onClick={() => handlePrivacy(0)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="customradio">
                          <span className="radiotextsty">Private</span>
                          <input
                            type="radio"
                            name="radio"
                            checked={privacy === 1}
                            onClick={() => handlePrivacy(1)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group">
                      <label>
                        {" "}
                        Owner of Project{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Owner of project"
                        />{" "}
                      </label>
                      <select
                        name="owner_of_project"
                        placeholder="Owner Of Project"
                        value={values?.owner_of_project?.value}
                        isInvalid={
                          touched.owner_of_project && !!errors.owner_of_project
                        }
                        className={`form-control ${
                          touched.owner_of_project && errors.owner_of_project
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={(e) => {
                          const selectedOwner = ownerOptions.find(
                            (opt) => opt.value === e.target.value
                          );
                          handleChange(e);
                          setFieldValue("owner_of_project", selectedOwner); // Update initialValues dynamically
                        }}
                      >
                        {ownerOptions?.map((opt, id) => (
                          <option key={id} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name="owner_of_project"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="project-icon-wrap form-group">
                      <label>
                        {" "}
                        Project Icon{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Project Icon"
                        />{" "}
                      </label>
                      <ul>
                        {imageOptions?.map((res, id) => (
                          <li>
                            {" "}
                            <label
                              onClick={(e) => {
                                e.stopPropagation();
                                setProjProfile(res?.id);
                              }}
                            >
                              {" "}
                              <img
                                className={`${
                                  projProfile === res?.id
                                    ? "selected-project-profile-img"
                                    : "unselected-project-profile-img"
                                }`}
                                src={res?.url}
                                alt="project-icon"
                              />{" "}
                            </label>
                          </li>
                        ))}

                        <li>
                          {" "}
                          <label htmlFor="file-upload">
                            {" "}
                            <img
                              src={projProfileImg ? projProfileImg : PhotoIcon}
                              alt="avatar-icon"
                            />{" "}
                          </label>
                          <input
                            type="file"
                            id="file-upload"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group multi-select-wrapper">
                      <label>
                        {" "}
                        Team Members{" "}
                        <span className="required-fields-span">*</span>{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Participants of the project"
                        />{" "}
                      </label>
                      <div className="team-offconcave-select-wrapper">
                        <MultiSelect
                          options={teamOptions ? teamOptions : []}
                          isSelectAll={true}
                          placehold="Select team members"
                          value={values.team_members}
                          isInvalid={
                            touched.team_members && !!errors.team_members
                          }
                          className={`${
                            touched.team_members && errors.team_members
                              ? "dept-err"
                              : ""
                          }`}
                          name="team_members"
                          menuPlacement="bottom"
                          onChange={(e) => {
                            setFieldValue("team_members", e);
                          }}
                        />

                        {touched.team_members && errors.team_members && (
                          <div className="text-error-department">
                            Please select atleast one team member
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="button-invite-from">
                      {userProfile?.canInvite === 1 && (
                        <>
                          {userProfile?.roles[0]?.roleType
                            ?.toString()
                            .toLowerCase() === "admin" ? (
                            <>
                              <Button
                                icon="fa-solid fa-add"
                                text="Invite Members"
                                handleClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowModal(true);
                                }}
                                margin="0px 5px 0px 0px"
                                width="100%"
                              />
                            </>
                          ) : (
                            <>
                              {registeredUserCount &&
                                userProfile?.packageDetails?.seatCount -
                                  (registeredUserCount || 0) >
                                  0 && (
                                  <Button
                                    icon="fa-solid fa-add"
                                    text="Invite Members"
                                    handleClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setShowModal(true);
                                    }}
                                    margin="0px 5px 0px 0px"
                                    width="100%"
                                  />
                                )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group multi-select-wrapper">
                      <label>
                        {" "}
                        Observer{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Observer"
                        />{" "}
                      </label>
                      <div className="team-offconcave-select-wrapper">
                        <MultiSelect
                          options={observerOptions ? observerOptions : []}
                          isSelectAll={true}
                          placehold="Select observer"
                          value={values.observer}
                          name="observer"
                          menuPlacement="bottom"
                          onChange={(e) => {
                            setFieldValue("observer", e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="textarea-group-wrap mb-3">
                      <label>
                        {" "}
                        Project Description{" "}
                        <img
                          src={InfoIcon}
                          alt="info-icon"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Project Description"
                        />{" "}
                      </label>
                      <textarea
                        rows="6"
                        name="project_description"
                        placeholder="Project Description"
                        value={values.project_description}
                        onChange={handleChange}
                        isInvalid={
                          touched.project_description &&
                          !!errors.project_description
                        }
                        className={`form-control ${
                          touched.project_description &&
                          errors.project_description
                            ? "is-invalid"
                            : ""
                        }`}
                      ></textarea>
                      <ErrorMessage
                        name="project_description"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="drag-drop-task-file">
                      <label>Attachment</label>
                      <div
                        {...getRootProps()}
                        className="drage-file-upload dropzone"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3 flex-wrap d-flex w-100 align-items-center justify-content-center gap-2">
                          {files.map((file) => (
                            <div
                              key={file.name}
                              className={`file-preview-container`}
                              onClick={(e) => e.stopPropagation()}
                              style={{
                                userSelect: "none",
                              }}
                            >
                              <div className="file-preview">
                                {file.preview ? (
                                  <img
                                    src={file.preview}
                                    alt={file.name}
                                    className="file-image"
                                  />
                                ) : (
                                  <div>
                                    <FileIcon
                                      extension={getFileType(file?.name)}
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                      {...defaultStyles[
                                        getFileType(file?.name)
                                      ]}
                                    />
                                  </div>
                                )}
                                <div className="file-info">
                                  <span className="file-size">
                                    {formatFileSize(file.size)}
                                  </span>
                                  <span className="file-name">{file.name}</span>
                                </div>
                                <span
                                  className="delete-button"
                                  onClick={() => handleDeleteFile(file)}
                                >
                                  <Icon icon="fluent:delete-12-filled" />
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                        Drag and drop or browse
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="main-form-button-wrap">
                      <button type="submit" className="create-project-button">
                        {" "}
                        {loading ? (
                          <Spinner animation="border" />
                        ) : (
                          "Continue"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            {/* )} */}
            <button
              className="btn-role-concave"
              onClick={() => {
                setModal(false);
                handleClose();
              }}
            >
              <img src={CloseBtn} alt="close-btn" />
            </button>
          </div>
        ) : (
          <div>
            <PlanProjectPhaseDetailSection
              projectId={newProjectId}
              project={newlyCreatedProject}
              projectData={newlyCreatedProject}
              projectName={newlyCreatedProject?.projectName}
              isDark={props.isDark}
              setModal={setModal}
              handleCloseModal={handleClose}
              updatePhaseTaskData={updatePhaseTaskData}
              updatePhaseTaskMemberData={updatePhaseTaskMemberData}
              setSelectedPhaseIdChange={() => {}}
              isUpdating={isUpdating}
              isMemberLoading={isMemberLoading}
              memberSuccess={memberSuccess}
              getTaskList={() => {}}
            />
            <button
              className="btn-role-concave"
              onClick={() => {
                if (props.creation) {
                  setModal(false);
                }
                handleClose();
              }}
            >
              <img src={CloseBtn} alt="close-btn" />
            </button>
          </div>
        )}
        {showModal && (
          <InviteModal
            isDark={props?.isDark}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            addonModal={addonModal}
            handleAddonClose={handleAddonClose}
            handleAddonShow={handleAddonShow}
            setShowAddModal={setShowAddModal}
            isAdmin={
              user?.canInvite === 1 &&
              user?.roles?.some((role) => role.roleType === "admin")
                ? true
                : false
            }
          />
        )}
        {addonModal && (
          <AddOnModal
            modal={addonModal}
            handleClose={handleAddonClose}
            isDark={props.isDark}
            planData={planData}
            getPlanData={getPlanData}
          />
        )}
        {showAddModal && (
          <ConfirmAddonModal
            isDark={props.isDark}
            handleAddonShow={handleAddonShow}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            billingDetail={billingDetail}
            planData={planData}
          />
        )}
        {show && (
          <NewTeam
            create={0}
            show={show}
            handleClose={() => setShow(false)}
            isDark={props.isDark}
            handleCreate={() => {}}
            projectList={convertProjectArray(projectFormList)}
            projectUserList={teamOptions ? teamOptions : []}
            teamToFilterList={[]}
            setTeamToFilterList={() => {}}
            teamList={[]}
            setTeamList={() => {}}
            teamDataAfterUpdate={() => {}}
            fromProjectForm={true}
            getTeamList={getTeamList}
          />
        )}
        {showCropModal && (
          <Modal
            show={showCropModal}
            onHide={handleModalClose}
            centered
            size="lg"
            data-theme={props?.isDark}
          >
            <Modal.Header closeButton>
              <div className="modal-heading-wrap">
                <h3>
                  {" "}
                  {/* <span>Choose</span> Your Plan{" "} */}
                  <span>Crop</span> Image{" "}
                </h3>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: "400px" }}>
              <div className="w-100 h-100">
                <img ref={imageRef} alt="crop-item" />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <p>Ctrl & +/- to zoom in and out</p>
              <button className="crop-btn" onClick={handleImageUpload}>
                Select
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProjectNewModal;

// custom styles for priority
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state?.data?.value?.toString()?.toLowerCase() === "critical"
        ? "#FB3F3F"
        : state?.data?.value?.toString()?.toLowerCase() === "highest"
        ? "#56BAEC"
        : state?.data?.value?.toString()?.toLowerCase() === "medium"
        ? "#394B84"
        : state?.data?.value?.toString()?.toLowerCase() === "low"
        ? "#2C2D2F"
        : state?.data?.value?.toString()?.toLowerCase() === "in progress"
        ? "#2098D1"
        : state?.data?.value?.toString()?.toLowerCase() === "not started"
        ? "#fd1b1b"
        : state?.data?.value?.toString()?.toLowerCase() === "completed"
        ? "#2DBF64"
        : state?.data?.value?.toString()?.toLowerCase() === "on hold"
        ? "#E8910D"
        : " black",
    fontSize: "13px",
    backgroundColor: state.isSelected ? "#e9eeff" : "transparent",
    "&:hover": {
      backgroundColor: "#e9eeff", // Set background color when hovering over the option
    },
    fontWeight: 300,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 300,
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 300,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 300,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Ensure dropdown appears above other elements
  }),
  menu: (base) => ({
    ...base,
    // Set the desired width
  }),
};
