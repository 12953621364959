import React from "react";
import AccountsOrCustomers from "./components/AccountsOrCustomers";

const AccountOrCustomersMain = (props) => {
  return (
    <AccountsOrCustomers
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
    />
  );
};

export default AccountOrCustomersMain;
