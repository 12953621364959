// constants for cached tasks
export const FETCH_PROJECT_DETAIL_TASKS_REQUEST =
  "FETCH_PROJECT_DETAIL_TASKS_REQUEST";
export const FETCH_PROJECT_DETAIL_TASKS_SUCCESS =
  "FETCH_PROJECT_DETAIL_TASKS_SUCCESS";
export const FETCH_PROJECT_DETAIL_TASKS_FAILURE =
  "FETCH_PROJECT_DETAIL_TASKS_FAILURE";
export const UPDATE_PROJECT_DETAIL_TASK_SUCCESS =
  "UPDATE_PROJECT_DETAIL_TASK_SUCCESS";
export const DELETE_PROJECT_DETAIL_TASK_SUCCESS =
  "DELETE_PROJECT_DETAIL_TASK_SUCCESS";
export const REMOVE_TASK_AND_PHASES = "REMOVE_TASK_AND_PHASES";
