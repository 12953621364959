import logoOne from "../assets/logoOne.png";
import logoTwo from "../assets/logoTwo.png";
import logoThree from "../assets/logoThree.png";
import logoFour from "../assets/logoFour.png";
import logoFive from "../assets/logoFive.png";
import logoSix from "../assets/logoSix.png";
import logoSeven from "../assets/logoSeven.png";
import LogoAnim from "../assets/iffra-logo.png";

// Define a mapping of palette names to logo names
const paletteToLogoMap = {
  default: logoOne,
  color1: logoTwo,
  color3: logoThree,
  color4: logoFour,
  color5: logoFive,
  color6: logoSix,
  color7: logoSeven,
  // Add more mappings as needed
};

// Helper function to get the logo based on the provided palette
export const getLogoFromPalette = (palette) => {
  // Check if the palette exists in the mapping, otherwise default to logoOne
  // return LogoAnim;
  // return paletteToLogoMap[palette] || "logoOne";
  return palette==="color7"?paletteToLogoMap[palette]:LogoAnim;
};
