import P1Icon from "../assets/project1.svg";
import P2Icon from "../assets/project2.svg";
import P3Icon from "../assets/project3.svg";
import P4Icon from "../assets/project4.svg";
import P5Icon from "../assets/project5.svg";

const getProjectIconById = (projectId) => {
  switch (projectId) {
    case "0":
      return P1Icon;
    case "1":
      return P2Icon;
    case "2":
      return P3Icon;
    case "3":
      return P4Icon;
    case "4":
      return P5Icon;
    default:
      // Return a default icon or handle the case when the project ID doesn't match any icon
      return null;
  }
};

export default getProjectIconById;
