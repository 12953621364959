import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import TeamMemberWorkedOnGrid from "./TeamMemberWorkedOnGrid";
import { Link } from "react-router-dom";
import { getQueryVariable } from "../../../components/GetQueriesFromUrl";
import { ProjectAPI } from "../../../services/ClientApi";
import TimelineGrids from "../../Timeline/components/TimelineGrids";
import ProjectNewModal from "../../Projects/components/ProjectNewModal";
import CreateTaskOffcanvas from "../../Tasks/components/CreateTaskOffcanvas";
import {
  projectFormListingAction,
  projectListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import { useDispatch } from "react-redux";
import { isPermitted } from "../../../helper/isPermitted";
import {
  ShimmerBadge,
  ShimmerButton,
  ShimmerCircularImage,
  ShimmerPostItem,
  ShimmerTable,
  ShimmerTitle,
} from "react-shimmer-effects";
import {
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";
import CreateTaskModal from "../../Tasks/components/CreateTaskModal";

function TeamMemberDetailsMain(props) {
  const dispatch = useDispatch();
  // stats for show all modals
  const [showProject, setShowProject] = useState(false);
  const [showTask, setShowTask] = useState(false);
  // use state for member details
  const [memberDetailsData, setMemberDetailsData] = useState({});
  const [memberStatData, setMemberStatData] = useState([]);
  const [memberDetailsLoading, setMemberDetailsLoading] = useState(true);
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  const { projectList } = useSelector((state) => state.projectListingData);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );

  // get the member id from params
  const memberId = getQueryVariable("pid");

  // use effect will run when ever memberId changed
  useEffect(() => {
    getMemberDetails(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  // usage with icon mapping
  const iconMapping = {
    projects: "octicon:project-24",
    tasks: "fluent:tasks-app-28-regular",
    performance: "eos-icons:performance",
  };

  // usage with url mapping
  const urlMapping = {
    projects: "task-management-porojects",
    tasks: "task-management-tasks",
    performance: "task-management-teams",
  };

  //  get the members details based on member id
  const getMemberDetails = async (isLoad) => {
    try {
      if (isLoad) {
        setMemberDetailsLoading(true);
      }
      const memberDetailsResponse = await ProjectAPI.post(
        "teams/members-detail",
        {
          memberId: memberId,
        }
      );

      if (memberDetailsResponse.status === 200) {
        setMemberDetailsData(memberDetailsResponse?.data?.data);
      }
      const memberStatResponse = await ProjectAPI.post(
        "teams/members-detail-stats",
        {
          memberId: memberId,
        }
      );
      if (memberStatResponse.status === 201) {
        setMemberStatData(
          convertObject(memberStatResponse.data.data, iconMapping, urlMapping)
        );
      }
    } catch (error) {
    } finally {
      setMemberDetailsLoading(false);
      if (!projectList) {
        dispatch(projectListingAction());
      }
      if (!projectUserList) {
        dispatch(projectUserListingAction());
      }
      if (!taskPriorities) {
        dispatch(taskPrioritiesListAction());
      }
      if (!taskTypes) {
        dispatch(taskTypesListAction());
      }
      if (!projectTypeList) {
        dispatch(projectTypeListingAction());
      }
      if (!projectFormList) {
        dispatch(projectFormListingAction());
      }
      if (!projectStatusList) {
        dispatch(projectListingStatusAction());
      }
    }
  };

  function convertObject(data, iconMapping = {}, urlMapping = {}) {
    const result = [];
    let totalByType = 0; // Track total count across types (optional)

    for (const category in data) {
      const typeCounts = [];
      for (const type in data[category]) {
        // Calculate total count if needed
        totalByType += data[category][type].count;

        let typeValue;
        switch (category.toLowerCase()) {
          case "projects":
            typeValue =
              type === "overDueProject"
                ? 1
                : type === "completedProject"
                ? 2
                : 0; // Map tasks based on type names
            break;
          case "tasks":
            typeValue =
              type === "overDueTasks"
                ? 1
                : type === "criticalTasks"
                ? "Critical"
                : "In Progress"; // Map tasks based on type names
            break;
          default:
            typeValue = type; // Use type name as default
        }
        // Include properties you need in the type_count object
        typeCounts.push({
          name: type.replace(/([A-Z])/g, " $1").trim(), // Convert camelCase to human-readable names (optional)
          count: data[category][type].count,
          tooltip: type.replace(/([A-Z])/g, " $1").trim(),
          type: typeValue, // Add type property with specific ordering
          selected: data[category][type].isSelected,
          id: data[category][type].id,
        });
      }

      const icon = iconMapping[category.toLowerCase()] || "default-icon.svg"; // Use provided icon mapping or default
      const url = urlMapping[category.toLowerCase()] || "url"; // Use provided url mapping or default
      result.push({
        id: result.length, // Assign unique but sequential IDs
        name: category,
        // Include only properties you need in the top-level object
        count: totalByType, // Include total count if needed
        icon: icon,
        url: url,
        type_count: typeCounts,
      });

      totalByType = 0; // Reset total count for the next category
    }

    return result;
  }

  const { applicationName } = useSelector((state) => state.text);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team Member Details-{applicationName}</title>
      </Helmet>
      <section className=" ">
        <div className="member-detail-main-wraper">
          {memberDetailsLoading ? (
            <div className="w-100 d-flex flex-column justify-content-center">
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div className="ms-4 d-flex align-items-center">
                  <ShimmerCircularImage size={80} />
                  <div className="ms-2 d-flex flex-column">
                    <ShimmerBadge width={200} />
                    <ShimmerTitle line={1} variant="secondary" />
                  </div>
                </div>
                <ShimmerButton size="sm" />
              </div>
              <div className="ms-4 dashboard-listing-title-wraper">
                <ul className="row">
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                </ul>
              </div>
              <div className="ms-4 dashboard-listing-title-wraper">
                <ul className="row">
                  <li className="col-md-4 position-relative">
                    <ShimmerPostItem
                      title
                      cta
                      imageType="thumbnail"
                      imageWidth={50}
                      imageHeight={50}
                      contentCenter
                    />
                  </li>
                  <li className="col-md-8 position-relative">
                    <ShimmerTable row={2} />
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div className="members-top-section-wraper ms-4">
                <div className="row d-flex align-items-center flex-wrap">
                  <div className="col-md-5">
                    <div className="member-info-wraper d-flex align-items-center flex-sm-row flex-column justify-content-sm-start justify-content-center">
                      <div className="team-details-name-wrap d-flex align-items-center">
                        {memberDetailsData?.userInfo?.profileImage ? (
                          <img
                            // className="member-image-wraper"
                            src={memberDetailsData?.userInfo?.profileImage}
                            alt="Profile"
                          ></img>
                        ) : (
                          <span>
                            {memberDetailsData?.userInfo?.userName[0]}
                            {memberDetailsData?.userInfo?.userName[1]}
                          </span>
                        )}
                      </div>

                      <div className="member-text-wraper">
                        <h4>
                          {" "}
                          {memberDetailsData?.userInfo?.userName}{" "}
                          <span>
                            <Link
                              to={`/${localStorage.getItem(
                                "company"
                              )}/task-management-teams`}
                              className="team-project-heading-a"
                            >
                              Teams
                            </Link>
                            <span style={{ color: "#7E7E7E" }}>/ </span>
                            <span className="team-project-heading-a team-project-heading-a-last">
                              Member Detail
                            </span>
                            {/* <Link to="" className="team-project-heading-a">
                              Member Detail
                            </Link> */}
                          </span>{" "}
                          {isPermitted("task/create-new") && (
                            <button
                              onClick={() => setShowTask(true)}
                              className="create-btn"
                            >
                              {" "}
                              Create{" "}
                            </button>
                          )}
                        </h4>
                        <div className="quick-info-wrap">
                          <ul>
                            <li> Last active Dec 10, 2024 </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="timeline-listing-search">
                      <ul>
                        <li></li>
                        <li></li>
                        {/* {isPermitted("project/pm-create-project") && (
                          <li>
                            <Button
                              icon="fa-solid fa-add"
                              text="Add Project"
                              handleClick={() => {
                                setShowProject(true);
                              }}
                              margin="0px 5px 0px 0px"
                            />
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <TimelineGrids
                timelineGrid={memberStatData}
                setTimelineGrid={setMemberStatData}
                memberId={memberId}
              />
              <div className="ms-4">
                <TeamMemberWorkedOnGrid
                  memberDetailsData={memberDetailsData}
                  isDark={props.isDark}
                  getMemberDetails={getMemberDetails}
                />
              </div>
            </>
          )}
        </div>
        {showProject && (
          <ProjectNewModal
            show={showProject}
            handleCloseModal={() => setShowProject(false)}
            isDark={props.isDark}
            getProjectList={() => {}}
            creation={true}
          />
        )}

        {showTask && (
          <CreateTaskModal
            show={showTask}
            handleCloseModal={() => setShowTask(false)}
            isDark={props.isDark}
            getBoardData={() => {}}
            setShowTaskDetails={() => {}}
            setNewId={() => {}}
            listing="TEAM_MEMBER"
          />
        )}
      </section>
    </>
  );
}

export default TeamMemberDetailsMain;
