import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Offcanvas, ProgressBar, Spinner } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import CloseBtn from "../../../assets/icon-close.svg";
import Button from "../../../components/Button";
import InfoIcon from "../../../assets/info.svg";
import P1Icon from "../../../assets/project1.svg";
import P2Icon from "../../../assets/project2.svg";
import P3Icon from "../../../assets/project3.svg";
import P4Icon from "../../../assets/project4.svg";
import P5Icon from "../../../assets/project5.svg";
import PhotoIcon from "../../../assets/photo.svg";
import ProjectDetailSection from "./ProjectDetailSection";
import MultiSelect from "../../../components/MultiSelect";
import "react-tooltip/dist/react-tooltip.css"; // Import CSS
import { toast } from "react-toastify";
import { ProjectAPI, ProjectFormDataAPI } from "../../../services/ClientApi";
import InviteModal from "../../../components/InviteModal/InviteModal";
import AddOnModal from "../../SubscriptionsUpdated/components/AddOnModal";
import ConfirmAddonModal from "../../../components/ConfirmAddonModal";
import API from "../../../services/ClientApi";
import { billingData } from "../../../actions/paymentActions";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { FileIcon, defaultStyles } from "react-file-icon";
import NewTeam from "../../Team/components/NewTeam";
import { teamsListAction } from "../../../actions/teamsActions";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { v4 as uuidv4 } from "uuid";
import { updateProjectAction } from "../../../actions/projectActions";
import { updateActiveProjectAction } from "../../../actions/timelineActions";
import {
  usePostProjectDetailsMutation,
  usePostUpdateProjectMembersMutation,
  usePostUpdateProjectMutation,
} from "../../../slices/projects/projectSlice";
import {
  calculateTotalSize,
  validateFile,
} from "../../Tasks/components/CreateTaskModal";
import CustomToast from "../../../components/CustomToast/CustomToast";

const ProjectEditModal = (props) => {
  const dispatch = useDispatch();
  const { registeredUserCount } = useSelector(
    (state) => state?.registeredUserCount
  );
  const [initLoad, setInitLoad] = useState(false);
  const [updateLoad, setUpdateLoad] = useState(false);
  const [projectData, setProjectData] = useState(null);
  // const { loading } = useSelector((state) => state.createProjectData);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const [privacy, setPrivacy] = useState(0);
  const [modal, setModal] = useState(props.modal ? props.modal : false);
  const [projProfile, setProjProfile] = useState(0);
  const [projProfileImg, setProjProfileImg] = useState(null);
  const [observerOptions, setObserverOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [newProjectId] = useState(null);
  // create state for newly created project with name newlyCreatedProject
  const [newlyCreatedProject] = useState(null);
  const { billingDetail } = useSelector((state) => state.billing);
  const [planData, setPlanData] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesUploadLoader, setFilesUploadLoader] = useState(false);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { teamsOptionsList } = useSelector((state) => state.teamsListData);
  const { userProfile } = useSelector((state) => state?.profile);
  const [show, setShow] = useState(false);
  const [cropper, setCropper] = useState(null);
  const imageRef = useRef(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileLoad, setFileLoad] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [isAttachmentDeleteLoading, setIsAttachmentDeleteLoading] =
    useState(false);

  const handleModalClose = () => {
    setShowCropModal(false);
    setCropper(null); // Clean up Cropper instance
  };

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleAddonShow = () => {
    setAddonModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { userLoading: projectUserListLoading } = useSelector(
    (state) => state.createProjectUsers
  );
  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const handleClose = () => {
    props.handleCloseModal();
    setObserverOptions([]);
    setTeamOptions([]);
    setOwnerOptions([]);
  };

  const getFileType = (attachmentName) => {
    const extension = attachmentName?.split(".").pop().toLowerCase();
    return extension;
  };

  const updateState = (typeName, users) => {
    // Ensure users is always an array, even for single user updates
    const userList = Array.isArray(users) ? users : [users];

    // Find the corresponding state setter based on typeName
    const stateSetter = {
      owner: setOwnerOptions,
      observer: setObserverOptions,
      member: setTeamOptions,
    }[typeName];

    if (stateSetter && userList.length > 0) {
      const type = getTypeIDByName(typeName);
      if (type) {
        // Efficiently update state using spread syntax and object destructuring
        stateSetter((prevOptions) => [
          ...userList.map((user) => ({
            id: user.userId,
            value: user.userId,
            label: user.userName,
            type_id: type,
          })),
        ]);
      }
    } else {
      // console.warn(`Invalid typeName: ${typeName} or empty users array`);
    }
  };

  const updateProjectIcon = async (data) => {
    try {
      const response = await ProjectFormDataAPI.post(
        "project/update-project-icon",
        data
      );
      if (response?.status === 201) {
        const updatedProject = response?.data?.data;
        const updatedProjectWithID = {
          ...updatedProject,
          projectId: updatedProject?.id,
        };
        delete updatedProjectWithID.id;

        const updatedListToFilter = props.listToFilter.map((item) =>
          item.projectId === updatedProjectWithID.projectId
            ? updatedProjectWithID
            : item
        );
        const { id, ...rest } = response?.data?.data;
        const updatedProjectsDetail = {
          projectId: id,
          ...rest,
        };
        await dispatch(updateProjectAction(updatedProjectsDetail));
        await dispatch(updateActiveProjectAction(updatedProjectsDetail));

        props.setListToFilter(updatedListToFilter);
        setFileLoad(false);
        setShowCropModal(false);
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      // Any cleanup code can go here
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    event.target.value = null;
    if (!file) {
      return;
    }

    if (file) {
      // Assuming file is an image
      setFile(file);
      setShowCropModal(true);
      const reader = new FileReader();
      reader.onload = () => {
        setProjProfileImg(reader.result); // Set the selected image as projProfile
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Initialize Cropper when the modal opens and an image is selected
    if (showCropModal && file && imageRef.current) {
      const image = imageRef.current;
      image.src = URL.createObjectURL(file);
      setCropper(
        new Cropper(image, {
          // Optional cropping options
          aspectRatio: 1, // Set aspect ratio if needed
          viewMode: 1, // Set initial view mode
          zoomable: true,
          minCropBoxHeight: 10,
          minCropBoxWidth: 10,
          background: false,
          responsive: true,
          autoCropArea: 1,
          checkOrientation: false, // https://github.com/fengyuanchen/cropperjs/issues/671
          guides: true,
          data: {
            width: 700, // Set desired cropping area width
            height: 700, // Set desired cropping area height
          },
        })
      );
    }

    // Cleanup Cropper when the modal closes or component unmounts
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCropModal, file, imageRef, setCropper]);

  const handleImageUpload = async (image) => {
    setFileLoad(true);
    const croppedCanvas = cropper.getCroppedCanvas();
    await croppedCanvas.toBlob(async (blob) => {
      const fileNew = new File([blob], `project-icon-${uuidv4()}.jpg`, {
        type: blob.type,
      });
      // Use the blob here, e.g., for upload, display, or download
      if (fileNew) {
        // Assuming file is an image
        const formData = new FormData();
        formData.append("projectId", props.id);
        formData.append("logo", fileNew);

        await updateProjectIcon(formData);
        const reader = new FileReader();
        reader.onload = () => {
          setProjProfileImg(reader.result); // Set the selected image as projProfile
        };
        reader.readAsDataURL(fileNew);
      }
      // Handle API response (e.g., update profile image URL)
    });
  };

  const teamMembers =
    projectData &&
    projectData?.projectMembers
      ?.filter((member) => member.memberType === "member")
      .map((member) => ({
        id: member.userId,
        value: member.userId,
        label: member.userName,
        type_id: getTypeIDByName(member.memberType),
      }));

  const ownerOfProject =
    projectData &&
    projectData?.projectMembers
      ?.filter((member) => member.memberType === "owner")
      .map((member) => ({
        id: member.userId,
        value: member.userId,
        label: member.userName,
        type_id: getTypeIDByName(member.memberType),
      }))[0]; // Assuming there's only one owner, so we take the first element

  const observer =
    projectData &&
    projectData?.projectMembers
      ?.filter((member) => member.memberType === "observer")
      .map((member) => ({
        id: member.userId,
        value: member.userId,
        label: member.userName,
        type_id: getTypeIDByName(member.memberType),
      }));

  const initialValues = {
    project_name: projectData ? projectData.projectName : "",
    team_members: teamMembers,
    owner_of_project: ownerOfProject,
    observer: observer,
    project_description: projectData ? projectData.projectDescription : "",
    projectTeamId:
      projectData &&
      projectData.ProjectTeams &&
      projectData.ProjectTeams?.length > 0
        ? projectData.ProjectTeams?.map((item) => {
            return {
              id: item.id,
              value: item.id,
              label: item.teamName,
            };
          })
        : [],
  };

  const validationSchema = Yup.object().shape({
    project_name: Yup.string()
      .max(255, "Project name cannot exceed 255 characters") // Limit to 255 characters
      .required("Project name is required"),
    owner_of_project: Yup.object().required("Owner of project is required"),
    team_members: Yup.array()
      .required("At least one team member is required")
      .min(1, "At least one team member is required"),
  });

  const handlePrivacy = (value, isUpdate = false) => {
    setPrivacy(value);
    if (value === 0) {
      const data = {
        fieldName: "projectPrivacy",
        fieldValues: "public",
        projectId: props.id,
      };
      isUpdate && updateData(data);
    } else {
      const data = {
        fieldName: "projectPrivacy",
        fieldValues: "private",
        projectId: props.id,
      };
      isUpdate && updateData(data);
    }
  };

  const imageOptions = [
    {
      id: 0,
      url: P1Icon,
    },
    {
      id: 1,
      url: P2Icon,
    },
    {
      id: 2,
      url: P3Icon,
    },
    {
      id: 3,
      url: P4Icon,
    },
    {
      id: 4,
      url: P5Icon,
    },
  ];

  const isImageFile = (fileName) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|webp|gif)$/i;
    return imageExtensionRegex.test(fileName);
  };

  const [postProjectDetails] = usePostProjectDetailsMutation();

  const getUsersAndTypes = async () => {
    try {
      setInitLoad(true);
      const projectDataResponse = await postProjectDetails({
        projectId: props.id,
        timeZone: userProfile?.timeZone,
      });

      if (projectDataResponse) {
        setProjectData(projectDataResponse?.data?.data);
        handlePrivacy(
          projectDataResponse?.data?.data?.projectPrivacy === "public" ? 0 : 1
        );
        projectDataResponse?.data?.data?.ProjectAttachments &&
          projectDataResponse?.data?.data?.ProjectAttachments.length > 0 &&
          setFiles(
            projectDataResponse?.data?.data?.ProjectAttachments.map((file) => {
              return {
                id: file.id,
                preview: file.attachmentPath,
                name: file.attachmentName,
                isImage: isImageFile(file.attachmentName),
              };
            })
          );
        if (
          !["0", "1", "2", "3", "4"].includes(
            projectDataResponse?.data?.data?.projectIcon
          )
        ) {
          setProjProfileImg(projectDataResponse?.data?.data?.projectIcon);
          setProjProfile(5);
        } else {
          setProjProfile(
            parseInt(projectDataResponse?.data?.data?.projectIcon)
          );
        }
      }
      setInitLoad(false);

      if (projectUserList?.data?.length > 0) {
        updateState("owner", projectUserList.data);
        updateState("observer", projectUserList.data);
        updateState("member", projectUserList.data);
      }
    } catch (error) {
    } finally {
      setInitLoad(false);
    }
  };

  const getPlanData = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPlanData(response?.data?.data?.packageDetails);
      }
      const data = await API.get("users/basic-info");
      setUser(data?.data?.data);
      dispatch(billingData());
    } catch (error) {}
  };

  // Get Team List
  const getTeamList = async () => {
    dispatch(teamsListAction());
  };

  useEffect(() => {
    getUsersAndTypes();
    getPlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const [postUpdateProject, { isLoading }] = usePostUpdateProjectMutation();

  // Update data other then members
  const updateData = async (data) => {
    try {
      const response = await postUpdateProject({
        ...data,
        ...props.pageParams,
        isCompletedProjectListing: props.isCompletedProjectListing,
      });
      if (response?.data?.status === "success") {
        props.getProjectList();
        // toast.success("Project updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
        const updatedProject = response?.data?.data;
        // Replace "id" with "projectId" in the updatedProject object
        updatedProject.projectId = updatedProject.id;
        delete updatedProject.id; // Remove the "id" property
        const updatedListToFilter = props.listToFilter.map((item) =>
          item.projectId === updatedProject.projectId ? updatedProject : item
        );
        await dispatch(updateProjectAction(response?.data?.data));
        await dispatch(updateActiveProjectAction(response?.data?.data));
        if (props.projectDetailRoute) {
          props.setProjName(updatedProject?.projectName);
        }
        props.setListToFilter(updatedListToFilter);
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      // Any cleanup code can go here
    }
  };

  const [postUpdateProjectMembers, { isLoading: memberLoading }] =
    usePostUpdateProjectMembersMutation();

  // Update project members
  const updateMembers = async (data) => {
    try {
      const formData = new FormData(); // Create a new FormData object

      // Append each key-value pair from data to the FormData object
      formData.append("projectId", props.id);

      let combinedArray = {
        projectId: props.id,
        projectMembers: data.projectMembers || [],
      };

      // For members
      data.projectMembers.forEach((memberItem, index) => {
        formData.append(`projectMembers[]`, memberItem);
      });

      const response = await postUpdateProjectMembers({
        ...props.pageParams,
        ...combinedArray,
        isCompletedProjectListing: props.isCompletedProjectListing,
      });

      if (response?.data?.status === "success") {
        props.getProjectList();
        // toast.success("Project updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
        const updatedProject = response?.data?.data;
        // Replace "id" with "projectId" in the updatedProject object
        updatedProject.projectId = updatedProject.id;
        delete updatedProject.id; // Remove the "id" property
        const updatedListToFilter = props.listToFilter.map((item) =>
          item.projectId === updatedProject.projectId ? updatedProject : item
        );
        await dispatch(updateProjectAction(response?.data?.data));
        await dispatch(updateActiveProjectAction(response?.data?.data));
        props.setListToFilter(updatedListToFilter);
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      // Any cleanup code can go here
    }
  };

  // Map extensions to MIME types
  const extensionToMime = {
    pdf: "application/pdf",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    txt: "text/plain",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    gif: "image/gif",
    bmp: "image/bmp",
    svg: "image/svg+xml",
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    mp3: "audio/mpeg",
    mp4: "video/mp4",
  };

  // Generate the structured `accept` object
  const acceptObject = Object.entries(extensionToMime).reduce(
    (acc, [extension, mimeType]) => {
      acc[mimeType] = [`.${extension}`];
      return acc;
    },
    {}
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptObject, // Define accepted file types
    multiple: true,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const totalSize = calculateTotalSize(acceptedFiles);

      if (totalSize > 8 * 1024 * 1024) {
        // 8MB in bytes
        toast.error(
          "The total size of all files exceeds 8MB. Please remove some files.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        return; // Prevent further execution if validation fails
      }

      const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

      // if (filteredFiles.length === 0) {
      //   // Handle case where no valid files were dropped
      //   toast.error("Please enter a valid file.", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      //   return;
      // }
      try {
        setFilesUploadLoader(true);

        let formData = new FormData();
        filteredFiles.forEach((file, index) => {
          formData.append(`attachments`, file);
        });
        formData.append(`projectId`, projectData?.id);
        const response = await ProjectFormDataAPI.post(
          "project/pm-add-attachment",
          formData
        );
        if (response.status === 200) {
          toast.success("File uploaded successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 300,
            hideProgressBar: true,
            closeButton: false,
          });
          // await getTaskDetails(taskDetails?.id);

          const newFiles = response?.data?.data?.map((file) => {
            return {
              id: file.id,
              preview: file.attachmentPath,
              name: file.attachmentName,
              isImage: isImageFile(file.attachmentName),
            };
          });
          setFiles([...files, ...newFiles]);
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setFilesUploadLoader(false);
      }
    },
  });

  const handleDeleteFile = async (fileToDelete) => {
    try {
      setIsAttachmentDeleteLoading(true);
      const response = await ProjectAPI.post("project/delete-attachment", {
        attachmentId: fileToDelete,
        projectId: projectData.id,
      });
      if (response.status === 200) {
        toast.success("File deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        const updatedFiles = files.filter((file) => file.id !== fileToDelete);
        setFiles(updatedFiles);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsAttachmentDeleteLoading(false);
    }
  };

  // Custom Toast Component
  useEffect(() => {
    if (isLoading || memberLoading) {
      // Show the updating toast when isUpdating becomes true
      setUpdateLoad(true);
    } else {
      setUpdateLoad(false);
      // toast.dismiss();
    }
  }, [isLoading, memberLoading]);

  useEffect(() => {
    if (updateLoad) {
      setIsToastVisible(true);
    } else {
      // Dismiss the toast when the update completes
    }
  }, [updateLoad]);

  return (
    <Offcanvas
      className={`off-concave-role ${modal ? "off-concave-proj-modal" : ""}`}
      show={props.show}
      // onHide={() => {
      //   if (props.creation) {
      //     setModal(false);
      //   }
      //   handleClose();
      // }}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        {!modal ? (
          <div className="create-project-new-wrap">
            <h3>
              {" "}
              Edit <span>Project</span>{" "}
            </h3>
            <p>
              Update project details that includes project members, timeline,
              project status or details of the project.
            </p>
            {initLoad || projectUserListLoading ? (
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
              </div>
            ) : (
              <>
                {isToastVisible && <div className="update-load-case"></div>}
                <CustomToast
                  isVisible={isToastVisible}
                  onClose={() => setIsToastVisible(false)}
                  updateLoad={updateLoad}
                />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form className="row" onSubmit={handleSubmit}>
                      <Tooltip id="my-tooltip" />
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>
                            {" "}
                            Project name{" "}
                            <span className="required-fields-span">*</span>{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Project Name"
                            />{" "}
                          </label>
                          <input
                            type="text"
                            name="project_name"
                            placeholder="Project Name"
                            autoComplete="off"
                            value={values.project_name}
                            onChange={handleChange}
                            onKeyDown={async (e) => {
                              if (e.key === "Enter") {
                                if (e.target.value.trim() === "") {
                                  // If the text is an empty string, revert to previous text
                                  setFieldValue(
                                    "project_name",
                                    projectData?.projectName
                                  );
                                }
                                e.target.blur(); // Blur the input field
                              }
                            }}
                            onBlur={async (e) => {
                              const trimmedValue = e.target.value.trim();

                              if (!trimmedValue) {
                                setFieldValue(
                                  "project_name",
                                  projectData?.projectName
                                );
                                return;
                              }

                              try {
                                // Validate project_name manually
                                await validationSchema.validateAt(
                                  "project_name",
                                  { project_name: trimmedValue }
                                );

                                // If valid, update data
                                const data = {
                                  fieldName: "projectName",
                                  fieldValues: trimmedValue,
                                  projectId: props.id,
                                };
                                updateData(data);
                              } catch (error) {
                                // If invalid, revert to previous value
                                setFieldValue(
                                  "project_name",
                                  projectData?.projectName
                                );
                              }
                            }}
                            isInvalid={
                              touched.project_name && !!errors.project_name
                            }
                            className={`form-control ${
                              touched.project_name && errors.project_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="project_name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="radio-button-wrap form-group">
                          <label>
                            {" "}
                            Privacy{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Privacy"
                            />{" "}
                          </label>

                          <div className="form-check-inline">
                            <label className="customradio">
                              <span className="radiotextsty">Public</span>
                              <input
                                type="radio"
                                checked={privacy === 0}
                                name="radio"
                                onClick={() => {
                                  handlePrivacy(0, true);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="customradio">
                              <span className="radiotextsty">Private</span>
                              <input
                                type="radio"
                                name="radio"
                                checked={privacy === 1}
                                onClick={() => {
                                  handlePrivacy(1, true);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>
                            {" "}
                            Owner of Project{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Owner of project"
                            />{" "}
                          </label>
                          <select
                            name="owner_of_project"
                            placeholder="Owner Of Project"
                            value={values?.owner_of_project?.value}
                            isInvalid={
                              touched.owner_of_project &&
                              !!errors.owner_of_project
                            }
                            className={`form-control ${
                              touched.owner_of_project &&
                              errors.owner_of_project
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={async (e) => {
                              const selectedOwner = ownerOptions.find(
                                (opt) => opt.value === e.target.value
                              );
                              handleChange(e);
                              setFieldValue("owner_of_project", selectedOwner); // Update initialValues dynamically
                              // Convert selectedOwner into an array of objects
                              const selectedOwnerArray = selectedOwner
                                ? [
                                    {
                                      [selectedOwner.type_id]: selectedOwner.id,
                                    },
                                  ]
                                : [];

                              let combinedArray = [
                                ...values?.team_members.map((member) => ({
                                  [member.type_id]: member.id,
                                })),
                                ...values?.observer.map((observer) => ({
                                  [observer.type_id]: observer.id,
                                })),
                                ...selectedOwnerArray, // Concatenate selectedOwnerArray
                              ];

                              // Convert projectMembersData into an array of strings
                              combinedArray = combinedArray.map((member) => {
                                const [key, value] = Object.entries(member)[0];
                                return `${key} : ${value}`;
                              });

                              const data = {
                                projectId: props.id,
                                projectMembers: combinedArray,
                              };
                              updateMembers(data);
                            }}
                          >
                            {ownerOptions?.map((opt, id) => (
                              <option key={id} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name="owner_of_project"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="project-icon-wrap form-group">
                          <label>
                            {" "}
                            Project Icon{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Project Icon"
                            />{" "}
                          </label>
                          <ul>
                            {imageOptions?.map((res, id) => (
                              <li>
                                {" "}
                                <label
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    setProjProfile(res?.id);
                                    const data = {
                                      fieldName: "projectIcon",
                                      fieldValues: res?.id.toString(),
                                      projectId: props.id,
                                    };
                                    updateData(data);
                                  }}
                                >
                                  {" "}
                                  <img
                                    className={`${
                                      projProfile === res?.id
                                        ? "selected-project-profile-img"
                                        : "unselected-project-profile-img"
                                    }`}
                                    src={res?.url}
                                    alt="project-icon"
                                  />{" "}
                                </label>
                              </li>
                            ))}

                            <li>
                              {" "}
                              <label htmlFor="file-upload">
                                {" "}
                                <img
                                  src={
                                    projProfileImg ? projProfileImg : PhotoIcon
                                  }
                                  alt="avatar-icon"
                                />{" "}
                              </label>
                              <input
                                type="file"
                                id="file-upload"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group multi-select-wrapper">
                          <label>
                            {" "}
                            Team Members{" "}
                            <span className="required-fields-span">*</span>{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Team Members"
                            />{" "}
                          </label>
                          <div className="team-offconcave-select-wrapper">
                            <MultiSelect
                              options={teamOptions ? teamOptions : []}
                              isSelectAll={true}
                              placehold="Select team members"
                              value={values.team_members}
                              isInvalid={
                                touched.team_members && !!errors.team_members
                              }
                              className={`${
                                touched.team_members && errors.team_members
                                  ? "dept-err"
                                  : ""
                              }`}
                              name="team_members"
                              menuPlacement="bottom"
                              onChange={(e) => {
                                setFieldValue("team_members", e);
                                // Convert selectedOwner into an array of objects
                                const selectedOwnerArray =
                                  values.owner_of_project
                                    ? [
                                        {
                                          [values.owner_of_project.type_id]:
                                            values.owner_of_project.id,
                                        },
                                      ]
                                    : [];
                                let combinedArray = [
                                  ...e.map((member) => ({
                                    [member.type_id]: member.id,
                                  })),
                                  ...values?.observer.map((observer) => ({
                                    [observer.type_id]: observer.id,
                                  })),
                                  ...selectedOwnerArray, // Concatenate selectedOwnerArray
                                ];

                                // Convert projectMembersData into an array of strings
                                combinedArray = combinedArray.map((member) => {
                                  const [key, value] =
                                    Object.entries(member)[0];
                                  return `${key} : ${value}`;
                                });

                                const data = {
                                  projectId: props.id,
                                  projectMembers: combinedArray,
                                };
                                updateMembers(data);
                              }}
                            />

                            {touched.team_members && errors.team_members && (
                              <div className="text-error-department">
                                Please select atleast one team member
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="button-invite-from form-group">
                          {userProfile?.canInvite === 1 && (
                            <>
                              {userProfile?.roles[0]?.roleType
                                ?.toString()
                                .toLowerCase() === "admin" ? (
                                <>
                                  <Button
                                    icon="fa-solid fa-add"
                                    text="Invite Members"
                                    handleClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setShowModal(true);
                                    }}
                                    margin="0px 5px 0px 0px"
                                    width="100%"
                                  />
                                </>
                              ) : (
                                <>
                                  {registeredUserCount &&
                                    userProfile?.packageDetails?.seatCount -
                                      (registeredUserCount || 0) >
                                      0 && (
                                      <Button
                                        icon="fa-solid fa-add"
                                        text="Invite Members"
                                        handleClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShowModal(true);
                                        }}
                                        margin="0px 5px 0px 0px"
                                        width="100%"
                                      />
                                    )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group multi-select-wrapper">
                          <label>
                            {" "}
                            Observer{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Observer"
                            />{" "}
                          </label>
                          <div className="team-offconcave-select-wrapper">
                            <MultiSelect
                              options={observerOptions ? observerOptions : []}
                              isSelectAll={true}
                              placehold="Select observer"
                              value={values.observer}
                              name="observer"
                              menuPlacement="bottom"
                              onChange={(e) => {
                                setFieldValue("observer", e);
                                // Convert selectedOwner into an array of objects
                                const selectedOwnerArray =
                                  values.owner_of_project
                                    ? [
                                        {
                                          [values.owner_of_project.type_id]:
                                            values.owner_of_project.id,
                                        },
                                      ]
                                    : [];
                                let combinedArray = [
                                  ...values.team_members.map((member) => ({
                                    [member.type_id]: member.id,
                                  })),
                                  ...e.map((observer) => ({
                                    [observer.type_id]: observer.id,
                                  })),
                                  ...selectedOwnerArray, // Concatenate selectedOwnerArray
                                ];

                                // Convert projectMembersData into an array of strings
                                combinedArray = combinedArray.map((member) => {
                                  const [key, value] =
                                    Object.entries(member)[0];
                                  return `${key} : ${value}`;
                                });

                                const data = {
                                  projectId: props.id,
                                  projectMembers: combinedArray,
                                };
                                updateMembers(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="textarea-group-wrap mb-3">
                          <label>
                            {" "}
                            Project Description{" "}
                            <img
                              src={InfoIcon}
                              alt="info-icon"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Project Description"
                            />{" "}
                          </label>
                          <textarea
                            rows="6"
                            name="project_description"
                            placeholder="Project Description"
                            value={values.project_description}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (
                                  projectData?.projectDescription !==
                                  e.target.value
                                ) {
                                  const data = {
                                    fieldName: "projectDescription",
                                    fieldValues: e.target.value,
                                    projectId: props.id,
                                  };
                                  updateData(data);
                                  e.target.blur(); // Blur the input field
                                }
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                projectData?.projectDescription !==
                                e.target.value
                              ) {
                                const data = {
                                  fieldName: "projectDescription",
                                  fieldValues: e.target.value,
                                  projectId: props.id,
                                };
                                updateData(data);
                              }
                            }}
                            isInvalid={
                              touched.project_description &&
                              !!errors.project_description
                            }
                            className={`form-control ${
                              touched.project_description &&
                              errors.project_description
                                ? "is-invalid"
                                : ""
                            }`}
                          ></textarea>
                          <ErrorMessage
                            name="project_description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="drag-drop-task-file">
                          <label>Attachment</label>
                          <div
                            {...getRootProps()}
                            className="drage-file-upload dropzone"
                            style={{
                              userSelect: "none",
                            }}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3 flex-wrap d-flex w-100 align-items-center justify-content-center gap-2">
                              {files.map((file) => (
                                <div
                                  key={file.name}
                                  className={`file-preview-container`}
                                  onClick={(e) => e.stopPropagation()}
                                  style={{
                                    userSelect: "none",
                                  }}
                                >
                                  <div className="file-preview">
                                    <div>
                                      <FileIcon
                                        extension={getFileType(file?.name)}
                                        style={{
                                          width: "120px",
                                          height: "120px",
                                        }}
                                        {...defaultStyles[
                                          getFileType(file?.name)
                                        ]}
                                      />
                                    </div>

                                    <div className="file-info">
                                      {/* <span className="file-size">
                                        {formatFileSize(file.size)}
                                      </span> */}
                                      <span className="file-name">
                                        {file.name}
                                      </span>
                                    </div>
                                    <span
                                      className="delete-button"
                                      disabled={isAttachmentDeleteLoading}
                                      onClick={(e) => {
                                        if (!isAttachmentDeleteLoading) {
                                          e.stopPropagation();
                                          handleDeleteFile(file.id);
                                        }
                                      }}
                                    >
                                      <Icon icon="fluent:delete-12-filled" />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                            Drag and drop or browse
                            {filesUploadLoader && (
                              <div className="w-100 mt-3 d-flex align-items-center justify-content-center">
                                <Spinner
                                  animation="border"
                                  className="global-spinner"
                                ></Spinner>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
            <button
              className="btn-role-concave"
              onClick={() => {
                setModal(false);
                handleClose();
              }}
              disabled={isLoading || memberLoading}
            >
              <img src={CloseBtn} alt="close-btn" />
            </button>
          </div>
        ) : (
          <div>
            <ProjectDetailSection
              projectId={newProjectId}
              project={newlyCreatedProject}
              isDark={props.isDark}
              setModal={setModal}
              handleCloseModal={handleClose}
            />
            <button
              className="btn-role-concave"
              onClick={() => {
                if (props.creation) {
                  setModal(false);
                }
                handleClose();
              }}
            >
              <img src={CloseBtn} alt="close-btn" />
            </button>
          </div>
        )}
        {showModal && (
          <InviteModal
            isDark={props?.isDark}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            addonModal={addonModal}
            handleAddonClose={handleAddonClose}
            handleAddonShow={handleAddonShow}
            setShowAddModal={setShowAddModal}
            isAdmin={
              user?.canInvite === 1 &&
              user?.roles?.some((role) => role.roleType === "admin")
                ? true
                : false
            }
          />
        )}
        {addonModal && (
          <AddOnModal
            modal={addonModal}
            handleClose={handleAddonClose}
            isDark={props.isDark}
            planData={planData}
            getPlanData={getPlanData}
          />
        )}
        {showAddModal && (
          <ConfirmAddonModal
            isDark={props.isDark}
            handleAddonShow={handleAddonShow}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            billingDetail={billingDetail}
            planData={planData}
          />
        )}
        {show && (
          <NewTeam
            create={0}
            show={show}
            handleClose={() => setShow(false)}
            isDark={props.isDark}
            handleCreate={() => {}}
            projectList={[]}
            projectUserList={teamOptions ? teamOptions : []}
            teamToFilterList={[]}
            setTeamToFilterList={() => {}}
            teamList={[]}
            setTeamList={() => {}}
            teamDataAfterUpdate={() => {}}
            fromProjectForm={true}
            getTeamList={getTeamList}
          />
        )}
        {showCropModal && (
          <Modal
            show={showCropModal}
            onHide={handleModalClose}
            centered
            size="lg"
            data-theme={props?.isDark}
          >
            <Modal.Header closeButton>
              <div className="modal-heading-wrap">
                <h3>
                  {" "}
                  {/* <span>Choose</span> Your Plan{" "} */}
                  <span>Crop</span> Image{" "}
                </h3>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: "400px" }}>
              <div className="w-100 h-100">
                <img ref={imageRef} alt="crop-item" />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button
                className="crop-btn"
                onClick={handleImageUpload}
                disabled={fileLoad}
              >
                {fileLoad ? (
                  <Spinner border="animation" size="1rem" />
                ) : (
                  "Select"
                )}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProjectEditModal;
