import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
} from "../constants/companiesConstants";
import API from "../services/ClientApi";

export const companiesListingAction = (userEmail) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_LIST_REQUEST });
    const { data } = await API.post("auth/user-companies-by-email", {
      email_address: userEmail,
    });

    const transformedData = Object.values(data?.data);

    if (data) {
      dispatch({
        type: COMPANY_LIST_SUCCESS,
        payload: transformedData,
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_LIST_FAIL,
      payload: error,
      meta: {
        retryAction: companiesListingAction(userEmail), // Retry action
      },
    });
  }
};
