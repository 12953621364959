import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import {
  calculateDurationAgo,
  formatTime,
} from "../../../helper/timeAgoFormatter";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import MessageForwardModal from "./MessageFarwardModal";
import { toast } from "react-toastify";
import ConfirmDeleteMessage from "./ConfirmDeleteMessage";
import { defaultStyles, FileIcon } from "react-file-icon";
import { getFileType } from "../../../helper/getFileType";
import { formatFileSize } from "../../../helper/taskHelpers";
import { getTotalMessagesLength } from "../../../helper/chatHelper";

function UserChatMessages({
  selectedChat,
  setReplyMessage,
  roomChats,
  user,
  onFileDrop,
  onDragOver,
  setMessages,
  handleByWeekLoad,
  prevDays,
  days,
  setPrevDays,
  chats,
  isDark,
  maxMessages,
  socket,
  initialLoad,
  roomChangeLoad,
}) {
  const messagesEndRef = useRef(null);
  const [load, setLoad] = useState(true);
  const [moreLoad, setMoreLoad] = useState(false);
  const [isMessageDeleted, setIsMessageDeleted] = useState(false);

  // Scroll to bottom function
  const scrollToBottom = (scroll = false) => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: scroll ? "smooth" : "auto",
    });
  };

  // useEffect(() => {
  //   setLoad(true);
  // }, [selectedChat]);
  useEffect(() => {
    if (roomChangeLoad) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [roomChangeLoad]);

  useEffect(() => {
    // Scroll to bottom when roomChats changes
    if (prevDays !== days) {
      setPrevDays(days);
      setLoad(false);
    } else {
      scrollToBottom(true);
      setLoad(false);
    }
    setMoreLoad(false);
  }, [roomChats]);

  useEffect(() => {
    if (!isMessageDeleted) {
      scrollToBottom();
    } else {
      setIsMessageDeleted(false);
    }
  }, [load]);

  useEffect(() => {
    setMoreLoad(true);
  }, [days]);

  const loadShow = getTotalMessagesLength(roomChats) < maxMessages;

  const chatRef = useRef(null);
  let scrollInterval = null;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!chatRef.current) return;

      const scrollAmount = 40; // Scroll speed
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault(); // Prevent default behavior

        if (!scrollInterval) {
          scrollInterval = requestAnimationFrame(() => {
            if (e.key === "ArrowDown") {
              chatRef.current.scrollBy({ top: scrollAmount });
            } else if (e.key === "ArrowUp") {
              chatRef.current.scrollBy({ top: -scrollAmount });
            }
            scrollInterval = null;
          });
        }
      }
    };

    const handleKeyUp = () => {
      if (scrollInterval) {
        cancelAnimationFrame(scrollInterval);
        scrollInterval = null;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div
      className="user-chat-messages-wrapper custom-horizental-scrollbar"
      ref={chatRef}
      onDrop={onFileDrop}
      onDragOver={onDragOver}
    >
      {!load && loadShow && (
        <div className="w-100 d-flex align-items-center justify-content-center py-2">
          <p
            className="mb-0 load-more-chat"
            onClick={() => {
              if (!moreLoad) {
                handleByWeekLoad();
              }
            }}
          >
            Load more {moreLoad ? <Spinner size="sm" /> : ""}
          </p>
        </div>
      )}
      {!load && !initialLoad ? (
        <div className="d-flex flex-column gap-3 w-100">
          {Object.entries(roomChats).map(([date, messages]) => (
            <div key={date} className="d-flex flex-column gap-3">
              {/* Date header */}
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="chat-message-date-group-header">{date}</div>
              </div>

              {/* Messages for the date */}
              {messages?.map((message) => (
                <div key={message.messageId}>
                  <MessageType
                    message={message}
                    setReplyMessage={setReplyMessage}
                    user={user}
                    setMessages={setMessages}
                    chats={chats}
                    isDark={isDark}
                    socket={socket}
                    setIsMessageDeleted={setIsMessageDeleted}
                  />
                </div>
              ))}
            </div>
          ))}

          {/* Dummy element to act as the scroll target */}
          <div ref={messagesEndRef}></div>
        </div>
      ) : (
        <div className="w-100">
          <ShimmerCategoryItem />
        </div>
      )}
    </div>
  );
}

export default UserChatMessages;

// Messages section
function MessageType({
  message,
  setReplyMessage,
  user,
  setMessages,
  chats,
  isDark,
  socket,
  setIsMessageDeleted,
}) {
  const [show, setShow] = React.useState(false);
  const [showFarward, setShowFarward] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);

  // Handle delete message
  const handleDeleteMessage = async () => {
    setOpenDelModal(true);
  };

  // Handle farword message
  const handleForwardMessage = async (userIds, close) => {
    try {
      socket.emit("forwardMessage", {
        senderId: user?.userId,
        recipients: userIds,
        messageId: message?.messageId,
        content: message?.content,
        companyId: user?.companyId,
      });
      // console.log("Tec : ", {
      //   senderId: user?.userId,
      //   recipients: userIds,
      //   messageId: message?.messageId,
      //   content: message?.content,
      // })
      // socket.emit("chatClosedNotification");
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      toast.success("Message forwarded successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
      close();
    }
  };

  // File viewer
  const fileViewers = {
    image: (url) => url,
    video: (url) => url,
    pdf: (url) => url,
    doc: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    docx: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    xls: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    xlsx: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    // xlsx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    ppt: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    pptx: (url) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`,
    default: (url) => url, // Fallback for other file types
  };

  const getFileViewerUrl = (fileType, fileUrl) => {
    return fileViewers[fileType]
      ? fileViewers[fileType](fileUrl)
      : fileViewers.default(fileUrl);
  };

  const handleFileClick = async (file) => {
    if (!message?.noLoad) {
      const fileType = getFileType(file?.fileName);
      const fileViewerUrl = getFileViewerUrl(fileType, file?.fileUrl);

      // Open the file in a new tab
      if (fileViewerUrl) {
        window.open(fileViewerUrl, "_blank", "noopener,noreferrer");
      }
    }
  };

  // Download file
  const handleDownload = (url, isPdf) => {
    const link = document.createElement("a");
    link.href = url + `?t=${new Date().getTime()}`;
    if (isPdf) {
      link.target = "_blank";
    }
    link.download = url.split("/").pop(); // Extract the file name from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Hide loader after download
    link.onload = () => {};
  };

  const renderMessageContent = (content) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g; // Regex to match all URLs

    // Split the content by URLs and map over the parts
    const parts = content.split(urlPattern).map((part, index) => {
      if (urlPattern.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });

    return parts;
  };

  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={`position-relative w-100 d-flex gap-2 ${
        message?.sender?.id === user?.userId && "justify-content-end"
      }`}
    >
      {message?.sender?.id !== user?.userId && (
        <div className="d-flex flex-column align-items-start chat-message-container">
          <div className="d-flex flex-column user-chat-message-type-1">
            {message?.replyToMessage && (
              <div className="w-100 user-chat-message-input-reply-message text-size-weight d-flex align-items-start gap-2 mb-2">
                <div
                  style={{ flex: 1 }}
                  className="d-flex flex-1 align-items-start"
                >
                  <div className="d-flex align-items-start flex-1">
                    <Icon className="icons" icon={"mdi:comma"} />
                    <Icon className="icons" icon={"mdi:comma"} />
                    <Icon className="icons" icon={"pepicons-pop:line-y"} />
                    <div className="d-flex flex-column">
                      {message?.replyToMessage?.attachments?.length > 0 && (
                        <div className="message-file-attachment-section">
                          <div className="message-file-attachment-grid">
                            {message?.replyToMessage?.attachments?.map(
                              (file, index) => (
                                <div
                                  key={index}
                                  className="message-file-attachment-item"
                                  style={{
                                    background: "#fff",
                                    border: "1px solid #e5ebff",
                                  }}
                                  onClick={() => handleFileClick(file)}
                                >
                                  <div className="message-file-attachment-info">
                                    <div className="d-flex align-items-center gap-3 message-file-attachment-name">
                                      {getFileType(file.fileUrl) ===
                                        "photo" && (
                                        <img
                                          key={file?.id}
                                          className="thumbnail"
                                          src={file?.fileUrl}
                                          alt={file?.fileName}
                                          // onClick={() =>
                                          //   handleThumbn(file)
                                          // }
                                        />
                                      )}
                                      {getFileType(file.fileUrl) !==
                                        "photo" && (
                                        <FileIcon
                                          extension={getFileType(file?.fileUrl)}
                                          {...defaultStyles[
                                            getFileType(file?.fileUrl)
                                          ]}
                                        />
                                      )}

                                      <div className="d-flex flex-column gap-2">
                                        <p className="mb-0 chat-attachment-file-name">
                                          {file?.fileName}
                                        </p>
                                        <p className="mb-0">
                                          {formatFileSize(
                                            file?.fileSize?.replace(
                                              /\s*bytes\s*/i,
                                              ""
                                            )
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <div
                                        className="message-file-attachment-size"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDownload(file?.fileUrl, true);
                                        }}
                                      >
                                        <Icon
                                          icon="mage:download"
                                          fontSize={18}
                                        />
                                      </div>
                                      <div
                                        className="message-file-attachment-size"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFileClick(file);
                                        }}
                                      >
                                        <Icon
                                          icon="ion:open-outline"
                                          fontSize={18}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <p className="text-size-weight">
                        {renderMessageContent(message?.replyToMessage?.content)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="">
              {message?.isForwarded && (
                <p className="mb-0 forwarded-text">
                  <span>
                    <Icon icon="basil:forward-solid" />
                  </span>
                  Forwarded
                </p>
              )}
              {message?.attachments?.length > 0 && (
                <div className="message-file-attachment-section">
                  <div className="message-file-attachment-grid">
                    {message?.attachments?.map((file, index) => (
                      <div
                        key={index}
                        className="message-file-attachment-item"
                        style={{
                          background: "#fff",
                          border: "1px solid #e5ebff",
                        }}
                        onClick={() => handleFileClick(file)}
                      >
                        <div className="message-file-attachment-info">
                          <div className="d-flex align-items-center gap-3 message-file-attachment-name">
                            {getFileType(file.fileUrl) === "photo" && (
                              <img
                                key={file?.id}
                                className="thumbnail"
                                src={file?.fileUrl}
                                alt={file?.fileName}
                                // onClick={() =>
                                //   handleThumbn(file)
                                // }
                              />
                            )}
                            {getFileType(file.fileUrl) !== "photo" && (
                              <FileIcon
                                extension={getFileType(file?.fileUrl)}
                                {...defaultStyles[getFileType(file?.fileUrl)]}
                              />
                            )}

                            <div className="d-flex flex-column gap-2">
                              <p className="mb-0 chat-attachment-file-name-receiver">
                                {file?.fileName}
                              </p>
                              <p className="mb-0 chat-attachment-file-size-receiver">
                                {formatFileSize(
                                  file?.fileSize?.replace(/\s*bytes\s*/i, "")
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="message-file-attachment-size"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(file?.fileUrl, true);
                              }}
                            >
                              <Icon icon="mage:download" fontSize={18} />
                            </div>
                            <div
                              className="message-file-attachment-size"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileClick(file);
                              }}
                            >
                              <Icon icon="ion:open-outline" fontSize={18} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <p className="text-size-weight">
                {" "}
                {renderMessageContent(message?.content)}
              </p>
            </div>
          </div>
          <p
            className="text-size-weight mt-2 chat-time-text"
            style={{ color: "#898989" }}
          >
            {formatTime(message?.sentAt)}
          </p>
        </div>
      )}

      <div className="user-chat-message-options">
        {show && !message.noLoad && (
          <Dropdown>
            <Dropdown.Toggle>
              <Icon width={15} height={15} icon={"mingcute:more-2-fill"} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setReplyMessage(message)}
                className="text-size-weight ps-3 py-1"
              >
                Reply
              </Dropdown.Item>
              <Dropdown.Item
                className="text-size-weight ps-3 py-1"
                onClick={() => setShowFarward(true)}
              >
                Forward
              </Dropdown.Item>
              {message?.sender?.id === user?.userId && (
                <Dropdown.Item
                  className="text-size-weight ps-3 py-1"
                  onClick={handleDeleteMessage}
                >
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {message?.sender?.id === user?.userId && (
        <div className="d-flex flex-column align-items-end chat-message-container">
          <div className="d-flex flex-column user-chat-message-type-2">
            {message?.replyToMessage && (
              <div className="w-100 user-chat-message-input-reply-message sender-reply text-size-weight d-flex align-items-start gap-2 mb-2">
                <div
                  style={{ flex: 1 }}
                  className="d-flex flex-1 align-items-start"
                >
                  <div className="d-flex align-items-start flex-1">
                    <Icon className="icons" icon={"mdi:comma"} />
                    <Icon className="icons" icon={"mdi:comma"} />
                    <Icon className="icons" icon={"pepicons-pop:line-y"} />
                    <div className="d-flex flex-column">
                      {message?.replyToMessage?.attachments?.length > 0 && (
                        <div className="message-file-attachment-section">
                          <div className="message-file-attachment-grid">
                            {message?.replyToMessage?.attachments?.map(
                              (file, index) => (
                                <div
                                  key={index}
                                  className="message-file-attachment-item"
                                  onClick={() => handleFileClick(file)}
                                >
                                  <div className="message-file-attachment-info">
                                    <div className="d-flex align-items-center gap-3 message-file-attachment-name">
                                      {getFileType(file.fileUrl) ===
                                        "photo" && (
                                        <img
                                          key={file?.id}
                                          className="thumbnail"
                                          src={file?.fileUrl}
                                          alt={file?.fileName}
                                          // onClick={() =>
                                          //   handleThumbn(file)
                                          // }
                                        />
                                      )}
                                      {getFileType(file.fileUrl) !==
                                        "photo" && (
                                        <FileIcon
                                          extension={getFileType(file?.fileUrl)}
                                          {...defaultStyles[
                                            getFileType(file?.fileUrl)
                                          ]}
                                        />
                                      )}

                                      <div className="d-flex flex-column gap-2">
                                        <p className="mb-0 chat-attachment-file-name">
                                          {file?.fileName}
                                        </p>
                                        <p className="mb-0 chat-attachment-file-size">
                                          {formatFileSize(
                                            file?.fileSize?.replace(
                                              /\s*bytes\s*/i,
                                              ""
                                            )
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <div
                                        className="message-file-attachment-size"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDownload(file?.fileUrl, true);
                                        }}
                                      >
                                        <Icon
                                          icon="mage:download"
                                          fontSize={18}
                                        />
                                      </div>
                                      <div
                                        className="message-file-attachment-size"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFileClick(file);
                                        }}
                                      >
                                        <Icon
                                          icon="ion:open-outline"
                                          fontSize={18}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <p className="text-size-weight">
                        {renderMessageContent(message?.replyToMessage?.content)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="">
              {message?.isForwarded && (
                <p className="mb-0 forward-text-user">
                  <span>
                    <Icon icon="basil:forward-solid" />
                  </span>
                  Forwarded
                </p>
              )}
              {message?.attachments?.length > 0 && (
                <div className="message-file-attachment-section">
                  <div className="message-file-attachment-grid">
                    {message?.attachments?.map((file, index) => (
                      <div
                        key={index}
                        className="message-file-attachment-item"
                        onClick={() => handleFileClick(file)}
                      >
                        <div className="message-file-attachment-info">
                          <div className="d-flex align-items-center gap-3 message-file-attachment-name">
                            {message?.noLoad && (
                              <div className="position-absolute ms-3">
                                <Spinner size="sm" className="" />
                              </div>
                            )}
                            {getFileType(file.fileName) === "photo" && (
                              <img
                                key={file?.id}
                                className="thumbnail"
                                src={file?.fileUrl}
                                alt={file?.fileName}
                                // onClick={() =>
                                //   handleThumbn(file)
                                // }
                              />
                            )}
                            {getFileType(file.fileName) !== "photo" && (
                              <FileIcon
                                extension={getFileType(file.fileName)}
                                {...defaultStyles[getFileType(file.fileName)]}
                              />
                            )}
                            <div className="d-flex flex-column gap-2">
                              <p className="mb-0 chat-attachment-file-name">
                                {file?.fileName}
                              </p>
                              <p className="mb-0 chat-attachment-file-size">
                                {formatFileSize(
                                  file?.fileSize?.replace(/\s*bytes\s*/i, "")
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="message-file-attachment-size-sender"
                              onClick={(e) => {
                                if (!message?.noLoad) {
                                  e.stopPropagation();
                                  handleDownload(file?.fileUrl, true);
                                }
                              }}
                            >
                              <Icon icon="mage:download" fontSize={18} />
                            </div>
                            <div
                              className="message-file-attachment-size-sender"
                              onClick={(e) => {
                                if (!message?.noLoad) {
                                  e.stopPropagation();
                                  handleFileClick(file);
                                }
                              }}
                            >
                              <Icon icon="ion:open-outline" fontSize={18} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <p className="text-size-weight card-text-white">
                {renderMessageContent(message?.content)}
              </p>
            </div>
          </div>
          <p className="text-size-weight mt-2 chat-time-text">
            {formatTime(message?.sentAt)}
          </p>
        </div>
      )}
      {showFarward && (
        <MessageForwardModal
          show={showFarward}
          onClose={() => setShowFarward(false)}
          userList={chats}
          isDark={isDark}
          handleForwardMessage={handleForwardMessage}
          forwardLoading={false}
        />
      )}
      {openDelModal && (
        <ConfirmDeleteMessage
          showDeleteModal={openDelModal}
          handleCloseDeleteModal={() => setOpenDelModal(false)}
          setMessages={setMessages}
          isDark={isDark}
          message={message}
          setIsMessageDeleted={setIsMessageDeleted}
        />
      )}
    </div>
  );
}
