/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./styles/banner.css";
import API from "../services/ClientApi";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Banner = (props) => {
  const [load, setLoad] = useState(false);

  const resendEmail = async () => {
    try {
      setLoad(true);
      const response = await API.post("users/resend-email-verification");
      if (response?.data?.status === "success") {
        toast.success("Verification mail sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  return (
    <div
      className={`w-100 banner ${
        props.message === "sub" ? "banner-2" : "banner-3"
      }`}
    >
      {props.message === "sub" ? (
        <p style={{ marginBottom: "0px", color: "#fff" }}>
          {props?.inActive
            ? "Your trial period is over"
            : `You are in ${props?.user?.packageDetails?.trialLeftDays}-day trial
          period`}
          . Please{" "}
          <a
            onClick={() => props.setOpenSubscriptionsModal(true)}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            upgrade
          </a>{" "}
          your package.
        </p>
      ) : (
        <p style={{ marginBottom: "0px", color: "#fff" }}>
          Email is not verified.{" "}
          <a
            onClick={resendEmail}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            {load ? (
              <Spinner
                border="animation"
                style={{ width: "1rem", height: "1rem" }}
              />
            ) : (
              "Resend"
            )}
          </a>{" "}
          if not recieved yet.
        </p>
      )}
    </div>
  );
};

export default Banner;
