import React, { useState } from "react";
import Button from "../../../components/Button";
import BacklogTask from "./BacklogTask";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid"; // Import from uuid
import { Icon } from "@iconify/react";

const BacklogBoard = ({ data, taskId }) => {
  return (
    <div className="backlog-board-main">
      <div className="d-flex align-items-center justify-content-between backlog-board-head">
        <div className="d-flex flex-column backlog-head-txt">
          <h4>{data?.boardName}</h4>
          <p>Task 1</p>
        </div>
        <div className="d-flex backlog-btn-group sprint-board-state-btn-wrap">
          <button className="sprint-finish-btn">
            <span>
              <i className={`fa-solid fa-add`} aria-hidden="true"></i>
            </span>
            Epic
          </button>
          <button className="ms-2 sprint-start-btn">
            <span>
              <i className={`fa-solid fa-add`} aria-hidden="true"></i>
            </span>
            Task
          </button>
          {/* <Button
            icon="fa-solid fa-add"
            text="Epic"
            handleClick={() => {
              //   setShowStage(true);
            }}
            margin="0px 5px 0px 0px"
          />
          <Button
            icon="fa-solid fa-add"
            text="Task"
            handleClick={() => {
              //   setShowStage(true);
            }}
            margin="0px 5px 0px 0px"
          /> */}
        </div>
      </div>
      <Droppable droppableId={taskId.toString()} type="TASK">
        {(provided) => (
          <div
            className="backlog-board-task-list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {data?.tasks?.map((res, id) => (
              <BacklogTask task={res} taskIndex={id} />
            ))}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default BacklogBoard;
