/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import CloseBtn from "../assets/icon-close.svg";
import "./styles/notificationSection.css";
import API from "../services/ClientApi";
import { notificationUnReadCount } from "../actions/notificationsActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { dateFormatter } from "../helper/dateFormatter";
import Icon1 from "../assets/notify-icons/comment-task.png";
import Icon2 from "../assets/notify-icons/complete-task.png";
import Icon3 from "../assets/notify-icons/due-task.png";
import Icon4 from "../assets/notify-icons/new-project.png";
import Icon5 from "../assets/notify-icons/new-task.png";
import Icon6 from "../assets/notify-icons/status-task.png";
import Icon7 from "../assets/notify-icons/update-project.png";
import ResponsivePagination from "react-responsive-pagination";
import { toast } from "react-toastify";
import saveLocalStorageToCookies from "../helper/saveLocalStorageToCookies";

const NotificationSection = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allLoad, setAllLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [load, setLoad] = useState(false);
  const [readCount, setReadCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [page, setPage] = useState(1);
  const [state, setState] = useState(
    sessionStorage.getItem("notificationSubState")
      ? parseInt(sessionStorage.getItem("notificationSubState"))
      : 1
  );

  const handleState = (value) => {
    setPage(1);
    sessionStorage.setItem("notificationSubState", value);
    setState(value);
  };

  const { applicationName } = useSelector((state) => state.text);
  const getNotifications = async () => {
    setLoad(true);
    const response = await API.get(
      `users/notification?page=${page}&type=${
        state === 0
          ? "all"
          : state === 1
          ? "unread"
          : state === 2
          ? "read"
          : "all"
      }`
    );

    if (response?.data?.status === "success") {
      setNotifications(response?.data?.data);
      setTotalCount(
        state === 0
          ? response?.data?.totalCount
          : state === 1
          ? response?.data?.unreadCount
          : state === 2
          ? response?.data?.readCount
          : response?.data?.totalCount
      );
      setLimit(response?.data?.limit);

      setReadCount(response?.data?.readCount);
      setUnreadCount(response?.data?.unreadCount);
      setAllCount(response?.data?.readCount + response?.data?.unreadCount);
      // const notificationKeysArray = response?.data?.notification.map(
      //   (notification) => notification.notificationKey
      // );
    }
    setLoad(false);
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, page]);

  const handleNotificationClick = async (notificationKey, data) => {
    try {
      navigateByType(data);
      if (!data?.readStatus) {
        await API.post("users/notification/update-status", {
          notificationKey: notificationKey,
        });
        await getNotifications();
        await dispatch(notificationUnReadCount());
      }
    } catch (error) {
    } finally {
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      setAllLoad(true);
      const response = await API.get("users/notification/mark-read-all");
      if (response?.status === 200) {
        toast.success("All notifications marked as read.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        await getNotifications();
        await dispatch(notificationUnReadCount());
      }
    } catch (error) {
      toast.error("An unexpected error occured", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setAllLoad(false);
    }
  };

  const navigateByType = (data) => {
    if (data?.notificationType === "subscription") {
      props?.handleClose();
      saveLocalStorageToCookies(`/company-subscriptions`);
    }
    if (data?.notificationType?.includes("project-")) {
      props?.handleClose();
      navigate(
        `/${localStorage.getItem("company")}/project?pid=${data?.target_id}`
      );
    }
    if (data?.notificationType?.includes("task-")) {
      props.setShowTaskDetail(true);
      props.setSelectedTask(data?.target_id);
      // navigate(`/${localStorage.getItem("company")}/task-management-tasks`);
    }
    // props?.handleClose();
  };

  const filteredNotifications = notifications;

  function getNotificationMainText(notification) {
    const { notificationType } = notification;

    switch (notificationType) {
      case "task-created":
        return "New Task Assigned";
      case "task-deadline":
        return "Task Deadline Change";
      case "task-status":
        return "Task Status Change";
      case "task-comment":
        return "Task Comment Alert";
      case "task-completed":
        return "Task Completion Acknowledgment";
      case "project-crated": // Assuming this is a typo and should be 'project-created'
        return "New Project Created";
      case "project-deadline":
        return "Project Deadline Reminder";
      case "project-update":
        return "Project Update Notification";
      default:
        return "Notification";
    }
  }

  function getNotificationIcon(notificationType) {
    switch (notificationType) {
      case "task-created":
        return Icon5; // New Task Assigned
      case "task-deadline":
        return Icon3; // Task Due Soon
      case "task-status":
        return Icon6; // Task Status Change
      case "task-comment":
        return Icon1; // Task Comment Alert
      case "task-completed":
        return Icon2; // Task Completion Acknowledgment
      case "project-crated": // Assuming typo, should be 'project-created'
        return Icon4; // New Project Created
      case "project-deadline":
        return Icon3; // Project Deadline Reminder
      case "project-update":
        return Icon7; // Project Update Notification
      default:
        return Icon5; // Fallback to no icon
    }
  }

  const totalPages = Math.ceil(totalCount / limit);

  return (
    <Offcanvas
      className="off-concave-role notification-offcanvas-main"
      show={props.show}
      // onHide={props.handleClose}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        <section className="notification-main-wraper">
          <div className="d-flex flex-column flex-wrap notification-header-wrap">
            <div className="w-100 d-flex justify-content-between align-items-center flex-wrap gap-2 notification-section-header-text-container">
              <h3> Notifications</h3>
              <p
                className="mark-all-read-text mb-0"
                onClick={handleMarkAllAsRead}
              >
                {allLoad ? <Spinner size="sm" className="me-2" /> : ""} Mark all
                as read
              </p>
            </div>
            <div className="notification-list-container">
              <ul
                className="nav nav-tabs notification-type-tabs"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`d-flex nav-link ${state === 0 ? "active" : ""}`}
                    // id="home-tab"
                    type="button"
                    role="tab"
                    onClick={() => handleState(0)}
                  >
                    All
                    <span className="ms-2 ">({allCount})</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`d-flex nav-link ${state === 1 ? "active" : ""}`}
                    // id="profile-tab"
                    type="button"
                    role="tab"
                    onClick={() => handleState(1)}
                  >
                    Unread
                    <span className="ms-2">({unreadCount})</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`d-flex nav-link ${state === 2 ? "active" : ""}`}
                    // id="contact-tab"
                    type="button"
                    role="tab"
                    onClick={() => handleState(2)}
                  >
                    Read
                    <span className="ms-2">({readCount})</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            {state === 0 && (
              <div className="tab-pane fade show active" id="home">
                <div className="search-notify-main-tab-wrap">
                  <div className="listing-notification-wraper">
                    <ul>
                      {!load && filteredNotifications.length > 0
                        ? filteredNotifications?.map((n, id) => (
                            <li
                              key={id}
                              onClick={() =>
                                handleNotificationClick(n?.notificationKey, n)
                              }
                              className={`${
                                n.readStatus ? "read-notification" : ""
                              }`}
                            >
                              <div className="lisitng-noftt-wrap">
                                <div className="w-100 d-flex align-items-center">
                                  <div className="lisitng-noftt-icon">
                                    <img
                                      src={getNotificationIcon(
                                        n?.notificationType
                                      )}
                                      alt={`${applicationName} notification icon`}
                                    />
                                  </div>
                                  <div
                                    className={`w-100 listing-notify-wrap ${
                                      n?.readStatus === false
                                        ? "unread-notification-container"
                                        : ""
                                    }`}
                                  >
                                    <div className="w-100 d-flex align-items-center justify-content-between gap-1 flex-wrap-reverse">
                                      <h5> {getNotificationMainText(n)} </h5>
                                      <div className="notification-date">
                                        {dateFormatter(n?.createdAt, 4)}
                                      </div>
                                    </div>
                                    <p>{n?.content}</p>
                                  </div>
                                </div>
                                {/* <div className="notification-date">
                                  {dateFormatter(n?.createdAt, 3)}
                                </div> */}
                              </div>
                            </li>
                          ))
                        : !load && (
                            <div className="ms-1 d-flex mt-2 align-items-center rounded-3 ">
                              <p className="ms-4 no-billing-txt">
                                No notifications found.
                              </p>
                            </div>
                          )}

                      {load && (
                        <div className="mt-3 w-100 d-flex justify-content-center global-spinner">
                          <ShimmerCategoryList
                            title
                            items={6}
                            categoryStyle="STYLE_SEVEN"
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {state === 1 && (
              <div className="tab-pane fade show active" id="home">
                <div className="search-notify-main-tab-wrap">
                  <div className="listing-notification-wraper">
                    <ul>
                      {!load &&
                      filteredNotifications?.filter(
                        (n) => n.readStatus === false
                      ).length > 0
                        ? filteredNotifications
                            ?.filter((n) => n.readStatus === false)
                            .map((n, id) => (
                              <li
                                key={id}
                                onClick={() =>
                                  handleNotificationClick(n?.notificationKey, n)
                                }
                                className={`${
                                  n.readStatus ? "read-notification" : ""
                                }`}
                              >
                                <div className="lisitng-noftt-wrap">
                                  <div className="w-100 d-flex align-items-center">
                                    <div className="lisitng-noftt-icon">
                                      <img
                                        src={getNotificationIcon(
                                          n?.notificationType
                                        )}
                                        alt={`${applicationName} notification icon`}
                                      />
                                    </div>
                                    <div
                                      className={`w-100 listing-notify-wrap ${
                                        n?.readStatus === false
                                          ? "unread-notification-container"
                                          : ""
                                      }`}
                                    >
                                      <div className="w-100 d-flex align-items-center justify-content-between gap-1 flex-wrap-reverse">
                                        <h5> {getNotificationMainText(n)} </h5>
                                        <div className="notification-date">
                                          {dateFormatter(n?.createdAt, 3)}
                                        </div>
                                      </div>
                                      <p>{n?.content}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                        : !load && (
                            <div className="ms-1 d-flex mt-2 align-items-center rounded-3 ">
                              <p className="ms-4 no-billing-txt">
                                No notifications found.
                              </p>
                            </div>
                          )}

                      {load && (
                        <div className="mt-3 w-100 d-flex justify-content-center global-spinner">
                          <ShimmerCategoryList
                            title
                            items={6}
                            categoryStyle="STYLE_SEVEN"
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {state === 2 && (
              <div className="tab-pane fade show active" id="home">
                <div className="search-notify-main-tab-wrap">
                  <div className="listing-notification-wraper">
                    <ul>
                      {!load &&
                      filteredNotifications?.filter(
                        (n) => n.readStatus === true
                      ).length > 0
                        ? filteredNotifications
                            ?.filter((n) => n.readStatus === true)
                            .map((n, id) => (
                              <li
                                key={id}
                                onClick={() =>
                                  handleNotificationClick(n?.notificationKey, n)
                                }
                                className={`${
                                  n.readStatus ? "read-notification" : ""
                                }`}
                              >
                                <div className="lisitng-noftt-wrap">
                                  <div className="w-100 d-flex align-items-center">
                                    <div className="lisitng-noftt-icon">
                                      <img
                                        src={getNotificationIcon(
                                          n?.notificationType
                                        )}
                                        alt={`${applicationName} notification icon`}
                                      />
                                    </div>
                                    <div className="w-100 listing-notify-wrap">
                                      <div className="w-100 d-flex align-items-center justify-content-between gap-1 flex-wrap-reverse">
                                        <h5> {getNotificationMainText(n)} </h5>
                                        <div className="notification-date">
                                          {dateFormatter(n?.createdAt, 3)}
                                        </div>
                                      </div>
                                      <p>{n?.content}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                        : !load && (
                            <div className="ms-1 d-flex mt-2 align-items-center rounded-3 ">
                              <p className="ms-4 no-billing-txt">
                                No notifications found.
                              </p>
                            </div>
                          )}

                      {load && (
                        <div className="mt-3 w-100 d-flex justify-content-center global-spinner">
                          <ShimmerCategoryList
                            title
                            items={6}
                            categoryStyle="STYLE_SEVEN"
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          <button className="btn-role-concave" onClick={props.handleClose}>
            <img src={CloseBtn} alt="close-btn" />
          </button>
        </section>
      </Offcanvas.Body>
      {totalPages > 1 && !load && (
        <div className="w-100 d-flex align-items-center justify-content-end py-4 px-2 notification-pagination-container">
          <ResponsivePagination
            current={page}
            total={totalPages}
            onPageChange={setPage}
          />
        </div>
      )}
    </Offcanvas>
  );
};

export default NotificationSection;
