// Check if package is expired
export const isTrialActive = (userProfile) => {
  if (!userProfile?.packageDetails?.trialEndDate) return false;

  // Get current date in UTC
  const today = new Date();
  const currentDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

  // Compare trialEndDate with current date
  const trialEndDate = userProfile?.packageDetails?.trialEndDate;

  return trialEndDate >= currentDate;
  // Test case below
  // return trialEndDate < currentDate;
};
