/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import API from "../../../services/ClientApi";
import PaymentModal from "../../../components/PaymentModal/PaymentModal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../helper/dateFormatter";
import { calculateDiscountedPrice } from "../../../helper/getDiscountedPrice";
import { billingData } from "../../../actions/paymentActions";
import ContactModal from "../../../components/ContactModal/ContactModal";
import AddOnSummaryModal from "./AddOnSummaryModal";
import Warning from "../../../assets/warning.svg";
import DeleteReSubscribeModel from "./DeleteReSubscribeModel";
import DeleteUnSubscribeModel from "./DeleteUnSubscribeModel";
import OverviewSubscriptionModal from "./OverviewSubscriptionModal";
import { Icon } from "@iconify/react";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";

const Overview = (props) => {
  const dispatch = useDispatch();
  const [planData, setPlanData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [load, setLoad] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null); // New state
  const [modal, setModal] = useState(false);
  const { billingDetail, bloading } = useSelector((state) => state.billing);
  const [addonModal, setAddonModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(2);
  const [summery, setSummery] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [sumLoad, setSumLoad] = useState(false);
  const [minSeats, setMinSeats] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [userCount, setUserCount] = useState(false);
  const [fullPackage, setFullPackage] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [removedUserIds, setRemovedUserIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [packageDataOverview, setPackageDataOverview] = useState({
    packageId: "",
    stripeProductId: "",
    totalAmount: 0,
    interval: "",
    seatCount: 0,
  });
  const [inter, setInter] = useState("Year");
  const [seat, setSeat] = useState(3);
  const seats = [3, 5, 10, 15, 20, 25, 30, 40, 50];
  const [userModal, setUserModal] = useState(false);
  const [userpro, setUserPro] = useState(false);
  const [selectedPackageDisplayOrder, setSelectedPackageDisplayOrder] =
    useState(null);
  const [newDisplayOrder, setNewDisplayOrder] = useState(null);
  const [addonSeat, setAddOnSeat] = useState(1);
  const [showDeleteSubModel, setShowDeleteSubModel] = useState(false);
  const [showDeleteUnsubModel, setShowDeleteUnsubModel] = useState(false);
  const { applicationName } = useSelector((state) => state.text);
  const { modalContents } = useSelector((state) => state?.modalData);
  const [seeMore, setSeeMore] = useState(false);
  const [amt, setAmt] = useState(0);

  const unsub = findObjectByContentTitle(
    "unsubscribe_subscription-two",
    modalContents?.data
  );

  const unsubOne = findObjectByContentTitle(
    "unsubscribe_subscription-one",
    modalContents?.data
  );

  const resub = findObjectByContentTitle("Resubscribe", modalContents?.data);

  const planText = findObjectByContentTitle("plan-data", modalContents?.data);

  const handleCloseDeleteUnsubModal = () => {
    setShowDeleteUnsubModel(false);
  };

  const handleCloseDeleteSubModal = () => {
    setShowDeleteSubModel(false);
  };

  const getPrice = (price) => {
    return seat * price;
  };

  const handleShowTab = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleShowPackageModal = async () => {
    getPackageName();
    if (planData?.currentStatus === "Trial") {
      const selectedPackage = packageData.find(
        (item) => item.packageName === planData.packageName
      );
      setPackageDataOverview({
        ...packageDataOverview,
        packageId: selectedPackage.id,
        stripeProductId: selectedPackage.stripeProductId,
        interval: planData?.subscriptionPeriod?.toLowerCase(),
        totalAmount:
          planData?.subscriptionPeriod === "year"
            ? Math.floor(
                calculateDiscountedPrice(
                  planData?.perSeatCharged,
                  planData?.yearlyDiscountedPercentage
                ) *
                  planData?.seatCount *
                  12
              )
            : Math.floor(planData?.perSeatCharged * planData?.seatCount),
      });
      setAmt(
        planData?.subscriptionPeriod === "year"
          ? Math.floor(
              calculateDiscountedPrice(
                planData?.perSeatCharged,
                planData?.yearlyDiscountedPercentage
              ) *
                planData?.seatCount *
                12
            )
          : Math.floor(planData?.perSeatCharged * planData?.seatCount)
      );
      setSelectedName(planData?.packageName);
      setSelectedPackage(selectedPackage.id);
      setSeat(planData?.seatCount);
      if (planData?.packageName !== "Free") {
        setSummery(true);
      }
      setShowPackageModal(true);
    } else {
      setShowPackageModal(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClosePackageModal = () => {
    setShowPackageModal(false);
    setUserModal(false);
    setUserPro(false);
    getUsers();
    setSelectedUserIds([]);
    setRemovedUserIds([]);
    setSummery(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const responseP = await API.get("auth/packages");
        await dispatch(billingData());
        const allPackages = responseP.data.data.allPackages;
        setPackageData(allPackages);
        checkSeats();
      } catch (error) {
      } finally {
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    try {
      const response = await API.post("company/registered-users", {
        searchTerm: "",
      });
      if (response?.data?.status === "success") {
        const fetchedUsers = response?.data?.data || [];
        setUsers(fetchedUsers);
        // Populate selectedUserIds with userIds from fetchedUsers
        const userIds = fetchedUsers.map((user) => user.userId);
        setSelectedUserIds(userIds);

        setLimit(userIds.length);
      }
    } catch (error) {
    } finally {
    }
  };

  const getPackageName = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPackageName(response?.data?.data?.packageDetails?.packageName);
      }
    } catch (error) {}
  };

  const getPlanData = async () => {
    try {
      const response = await API.get("company/subscription");
      if (response?.data?.status === "success") {
        setPlanData(response?.data?.data?.packageDetails);
        setCategories(response?.data?.data?.accessedCategories);
        const matchingPackage = packageData.find(
          (packageItem) =>
            packageItem.packageName ===
            response?.data?.data?.packageDetails.packageName
        );
        setSelectedPackage(matchingPackage.id);
        setMinSeats(matchingPackage.minSeats);
        setSeat(matchingPackage?.minSeats);
        // Check if a matching package is found
        if (matchingPackage) {
          // Set the displayOrder from the matching package to the state
          setSelectedPackageDisplayOrder(matchingPackage.displayOrder);
        }
        setPackageDataOverview({
          ...packageDataOverview,
          packageId: matchingPackage?.id,
          stripeProductId: matchingPackage?.stripeProductId,
          totalAmount:
            inter === "Month"
              ? Math.floor(
                  matchingPackage?.packagePrice *
                    response?.data?.data?.packageDetails?.seatCount
                )
              : Math.floor(
                  calculateDiscountedPrice(
                    matchingPackage?.packagePrice,
                    matchingPackage?.yearlyDiscountedPercentage
                  ) *
                    response?.data?.data?.packageDetails?.seatCount *
                    12
                ),

          interval: inter.toLowerCase(),
          seatCount:
            parseInt(response?.data?.data?.packageDetails?.seatCount) <
            parseInt(matchingPackage?.minSeats)
              ? parseInt(matchingPackage?.minSeats)
              : parseInt(response?.data?.data?.packageDetails?.seatCount),
        });
        setSelectedName(matchingPackage?.packageName);
        setFullPackage(matchingPackage);
        getUsers();
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (packageData) {
      getPlanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDetail, packageData]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const checkSeats = async () => {
    try {
      const response = await API.post("company/registered-user-count");
      setUserCount(response?.data?.registeredUserCount);
    } catch (error) {
    } finally {
    }
  };

  const handleUnSubscribe = async (id) => {
    try {
      const response = await API.post(
        "company/stripe-unsubscribe-subscription",
        { subscriptionId: id }
      );
      if (response?.data?.status === "success") {
        setLoad(true);
        handleCloseDeleteUnsubModal();
        toast.success(
          response?.data?.message ||
            "Subscription cancelled at the end of the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
        await dispatch(billingData());
      } else {
        toast.error(
          response.response.data.message ||
            "Failed to cancelled the subscription at the end of the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      toast.error(
        "Failed to cancelled the subscription at the end of the current billing period.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      handleCloseDeleteUnsubModal();
    }
  };

  const handleReSubscribeSamePackage = async (
    packageId,
    stripeProductId,
    totalAmount,
    setCount,
    interval
  ) => {
    try {
      const response = await API.post(
        "company/stripe-resubscribe-subscription",
        {
          packageId: packageId,
          stripeProductId: stripeProductId,
          totalAmount: parseInt(totalAmount),
          seatCount: parseInt(setCount),
          interval: interval,
        }
      );
      if (response?.data?.status === "success") {
        setLoad(true);
        handleCloseDeleteSubModal();
        toast.success(
          response?.data?.message || "Pacakage ReSubscribed Successfully.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
        await dispatch(billingData());
      } else {
        toast.error(
          response?.data?.message ||
            "Failed to resubscribe at the current billing period.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      toast.error("Failed to resubscribe at the current billing period.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      handleCloseDeleteSubModal();
    }
  };

  const handleReSubscribe = async (
    packageId,
    stripeProductId,
    totalAmount,
    interval,
    seatCount,
    selectedUser
  ) => {
    try {
      setSumLoad(true);
      if (selectedUser?.length > 0) {
        const response = await API.post("company/bulk-users-deleted", {
          userIds: selectedUser,
        });
        if (response?.data?.status === "success") {
          const response = await API.post(
            "company/stripe-resubscribe-subscription",
            {
              packageId: packageId,
              stripeProductId: stripeProductId,
              totalAmount: parseInt(totalAmount),
              seatCount: parseInt(seatCount),
              interval: interval,
              subscriptionType:
                newDisplayOrder > selectedPackageDisplayOrder
                  ? "upgrade"
                  : newDisplayOrder === selectedPackageDisplayOrder
                  ? "same"
                  : newDisplayOrder < selectedPackageDisplayOrder
                  ? "downgrade"
                  : "unknown",
            }
          );
          if (response?.data?.status === "success") {
            await getPlanData();
            dispatch(billingData());
            toast.success("Pacakage Payment Successfully.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            toast.error(
              response?.data?.message ||
                "Failed to subscribe at the current billing period.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              }
            );
          }
        }
      } else {
        const response = await API.post(
          "company/stripe-resubscribe-subscription",
          {
            packageId: packageId,
            stripeProductId: stripeProductId,
            totalAmount: totalAmount,
            interval: interval,
            seatCount: parseInt(seatCount),
            subscriptionType:
              newDisplayOrder > selectedPackageDisplayOrder
                ? "upgrade"
                : newDisplayOrder === selectedPackageDisplayOrder
                ? "same"
                : newDisplayOrder < selectedPackageDisplayOrder
                ? "downgrade"
                : "unknown",
          }
        );
        if (response?.data?.status === "success") {
          await getPlanData();
          toast.success("Pacakage Payment Successfully.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          dispatch(billingData());
        } else {
          toast.error(
            response?.data?.message ||
              "Failed to subscribe at the current billing period.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            }
          );
        }
      }
      checkSeats();
    } catch (error) {
      toast.error("Failed to subscribe at the current billing period.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setSumLoad(false);
    }
  };

  return (
    <div className="content-subscription-wrap">
      <div className="payment-method-wrap">
        <h3>
          {" "}
          <span>Plan</span> Details{" "}
        </h3>
        <div className="col-md-12 mb-3">
          <p className="role-page-description">
            {planText?.short_description}{" "}
            <span className="see-more-txt" onClick={() => setSeeMore(true)}>
              See More
            </span>
          </p>
        </div>
        {load ? (
          <div className="w-100 gap-3 d-flex align-items-center plan-package-shimmer-wrap">
            <div className="p-3 d-flex flex-column plan-package-shimmer">
              <div className="w-100 mb-1 mt-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                <ShimmerButton size="sm" />
              </div>
              <div className="w-100 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
            </div>
            <div className="p-3 d-flex flex-column plan-package-shimmer">
              <div className="w-100 mb-1 mt-3 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
              <div className="d-flex gap-4 justify-content-between">
                <div className="w-50">
                  <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                    <ShimmerButton size="sm" />
                  </div>
                  <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                    <ShimmerButton size="sm" />
                  </div>
                  <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                    <ShimmerButton size="sm" />
                  </div>
                  <div className="w-100 mb-1 d-flex justify-content-between single-row-shimmer-in-form-full">
                    <ShimmerButton size="sm" />
                  </div>
                </div>
                <div className="w-50">
                  <ShimmerThumbnail className="m-0" height={196} rounded />
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between dual-row-shimmer-in-form-half">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="new-subscibe-addon-wraper">
              {billingDetail &&
                billingDetail[0]?.subscriptionStatus === "unSubscribed" &&
                planData?.currentStatus !== "Trial" &&
                planData?.currentStatus !== "Expired" && (
                  <div className="w-100 sub-warning-wrap">
                    <img src={Warning} alt="warning-subscription" />
                    <span>
                      Subscription cannot be updated as it is scheduled for
                      cancellation at the end of current term.
                    </span>
                  </div>
                )}
              {billingDetail &&
                billingDetail?.length < 1 &&
                planData?.currentStatus !== "Trial" &&
                planData?.currentStatus !== "Expired" && (
                  <div className="w-100 sub-warning-wrap">
                    <img src={Warning} alt="warning-subscription" />
                    <span>
                      Subscription cannot be updated as it is scheduled for
                      cancellation at the end of current term.
                    </span>
                  </div>
                )}
              {planData?.currentStatus === "Expired" && (
                <div className="w-100 sub-warning-wrap">
                  <img src={Warning} alt="warning-subscription" />
                  <span>
                    You subscription has expired. Please renew your
                    subscription.
                  </span>
                </div>
              )}
              <ul className="w-100 row">
                <li className="col-md-6">
                  <div className="addon-subcribe-main-wrap">
                    <div className="ov-head-wrap">
                      <h5>
                        {" "}
                        {planData?.packageName}{" "}
                        <span style={{ fontWeight: 300 }}>Package</span>{" "}
                      </h5>
                      <strong
                        className={`${
                          planData?.subscriptionStatus !== "Subscribed"
                            ? "unsub-txt"
                            : ""
                        }`}
                      >
                        {planData?.subscriptionStatus === "Subscribed" &&
                        planData?.currentStatus !== "Trial" ? (
                          <span className="sub-icon">
                            <Icon icon="icon-park-solid:check-one" />
                          </span>
                        ) : planData?.currentStatus === "Trial" ? (
                          <span className="sub-icon warning-icon">
                            <Icon icon="openmoji:warning" />
                          </span>
                        ) : (
                          <span></span>
                        )}{" "}
                        {planData?.currentStatus === "Trial"
                          ? "Trial Period"
                          : planData?.subscriptionStatus}
                      </strong>
                    </div>
                    <div className="addon-subscribe-listing-wrap">
                      <span>
                        {" "}
                        Seat Count{" "}
                        <strong>
                          {planData?.seatCount ? planData.seatCount : "Null"}
                        </strong>
                      </span>
                      <span>
                        {" "}
                        Status <strong>{planData?.currentStatus}</strong>
                      </span>
                      <span>
                        {" "}
                        Activation date{" "}
                        <strong>
                          {dateFormatter(planData?.activationDate, 2)}
                        </strong>
                      </span>
                      <span>
                        {" "}
                        Expiry date{" "}
                        <strong>
                          {" "}
                          {dateFormatter(planData?.trialEndDate, 2)}{" "}
                        </strong>
                      </span>
                      {planData?.subscriptionStatus === "Subscribed" &&
                        planData?.currentStatus !== "Trial" && (
                          <span>
                            {" "}
                            Next auto renewal amount{" "}
                            <strong>
                              {planData?.nextChargedAmount
                                ? `$${planData?.nextChargedAmount}`
                                : "N/A"}
                            </strong>
                          </span>
                        )}
                    </div>
                    {billingDetail &&
                    billingDetail?.length > 0 &&
                    planData?.currentStatus !== "Trial" ? (
                      <div className="d-flex justify-content-between btn-subscription-wrap">
                        {billingDetail &&
                        billingDetail[0]?.subscriptionStatus ===
                          "unSubscribed" ? (
                          <button
                            className="unsub-sub-btn"
                            onClick={() => setShowDeleteSubModel(true)}
                          >
                            {" "}
                            <span></span> Subscribe
                          </button>
                        ) : (
                          <button
                            className="unsub-sub-btn"
                            onClick={() => setShowDeleteUnsubModel(true)}
                          >
                            {" "}
                            <span></span> Unsubscribe
                          </button>
                        )}
                        <button
                          onClick={handleShowPackageModal}
                          className="update-plan-btn"
                        >
                          {" "}
                          Upgrade Plan{" "}
                        </button>{" "}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end btn-subscription-wrap">
                        <button
                          onClick={handleShowPackageModal}
                          className="update-plan-btn"
                        >
                          {" "}
                          Upgrade Plan{" "}
                        </button>{" "}
                      </div>
                    )}
                    {billingDetail &&
                      billingDetail[0]?.subscriptionStatus !== "unSubscribed" &&
                      billingDetail?.length > 0 &&
                      planData?.currentStatus !== "Trial" &&
                      planData?.currentStatus !== "Expired" && (
                        <div
                          className="w-100 sub-warning-wrap-1"
                          style={{ paddingLeft: "10px" }}
                        >
                          {/* <img src={Warning} alt="warning-subscription" /> */}

                          <p>
                            Note:
                            <span>{` Your package will be auto renewed on ${dateFormatter(
                              planData?.trialEndDate,
                              2
                            )}.`}</span>
                          </p>
                        </div>
                      )}
                    {planData?.currentStatus === "Trial" && (
                      <div
                        className="w-100 sub-warning-wrap-1"
                        style={{ paddingLeft: "10px" }}
                      >
                        {/* <img src={Warning} alt="warning-subscription" /> */}

                        <p>
                          Note:
                          <span>{` Upgrade your plan for full access to all plugins.`}</span>
                        </p>
                      </div>
                    )}
                    {billingDetail &&
                      billingDetail[0]?.subscriptionStatus === "unSubscribed" &&
                      billingDetail?.length > 0 &&
                      planData?.currentStatus !== "Trial" &&
                      planData?.currentStatus !== "Expired" && (
                        <div
                          className="w-100 sub-warning-wrap"
                          style={{ paddingLeft: "10px" }}
                        >
                          {/* <img src={Warning} alt="warning-subscription" /> */}

                          <p>
                            Note:
                            <span>{` You have cancelled your subscription but can still access your subscription until ${dateFormatter(
                              planData?.trialEndDate,
                              2
                            )}.`}</span>
                          </p>
                        </div>
                      )}
                    {billingDetail &&
                      billingDetail?.length < 1 &&
                      planData?.currentStatus !== "Trial" &&
                      planData?.currentStatus !== "Expired" && (
                        <div
                          className="w-100 sub-warning-wrap"
                          style={{ paddingLeft: "10px" }}
                        >
                          {/* <img src={Warning} alt="warning-subscription" /> */}

                          <p>
                            Note:
                            <span>{` You have cancelled your subscription but can still access your subscription until ${dateFormatter(
                              planData?.trialEndDate,
                              2
                            )}.`}</span>
                          </p>
                        </div>
                      )}
                    {planData?.currentStatus === "Expired" && (
                      <div
                        className="w-100 sub-warning-wrap"
                        style={{ paddingLeft: "10px" }}
                      >
                        {/* <img src={Warning} alt="warning-subscription" /> */}

                        <p>
                          Note:
                          <span>{`Your subscription has expired.`}</span>
                        </p>
                      </div>
                    )}
                  </div>
                </li>

                <li className="col-md-6">
                  <div
                    className={`addon-package-wraper ${
                      (billingDetail &&
                        billingDetail[0]?.subscriptionStatus ===
                          "unSubscribed") ||
                      (billingDetail && billingDetail?.length < 1)
                        ? "disabled-addon"
                        : ""
                    }`}
                  >
                    <h3>
                      {" "}
                      <span>Add</span>-On
                      <div className="form-group">
                        <div className="select-addon-wrap">
                          <select
                            className="form-control"
                            placeholder="Select Seats"
                            value={addonSeat}
                            disabled={
                              (billingDetail &&
                                billingDetail[0]?.subscriptionStatus ===
                                  "unSubscribed") ||
                              (billingDetail && billingDetail?.length < 1)
                            }
                            onChange={(e) => setAddOnSeat(e.target.value)}
                          >
                            <option value={1}>1 seat</option>
                            <option value={2}>2 seats</option>
                            <option value={4}>4 seats</option>
                            <option value={6}>6 seats</option>
                            <option value={8}>8 seats</option>
                          </select>
                        </div>
                      </div>{" "}
                    </h3>

                    <div className="addon-package-list-wrap">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="basic-package-listing-wrap add-on-wrap">
                            <strong>
                              {" "}
                              Looking for add more team members?{" "}
                            </strong>
                            <ul>
                              <li> Simple, easy and hassle free. </li>
                              <li> Same package will continue. </li>
                              <li>
                                {" "}
                                There will ne no change in existing team.{" "}
                              </li>
                              {planData?.subscriptionPeriod === "year" && (
                                <li>
                                  You will be charged only for remaining
                                  subscription period.
                                </li>
                              )}
                            </ul>

                            <div className="button-package-wrap add-on-btn-wrap">
                              <button
                                onClick={() => setAddonModal(true)}
                                className="add-on-btn add-on-new-btn"
                                disabled={
                                  billingDetail?.length < 1 ||
                                  (billingDetail &&
                                    billingDetail[0]?.subscriptionStatus ===
                                      "unSubscribed")
                                }
                              >
                                {" "}
                                Add On{" "}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="plan-listing-amount-wraper">
                            <span className="plan-info-wrap">
                              {" "}
                              {planData?.packageName}{" "}
                            </span>
                            {planData?.subscriptionPeriod === "year" ? (
                              <strong className="price-amount">
                                {" "}
                                <strong>$</strong>
                                {Math.floor(
                                  calculateDiscountedPrice(
                                    planData?.perSeatCharged,
                                    planData?.yearlyDiscountedPercentage
                                  ) * addonSeat
                                )}
                              </strong>
                            ) : (
                              <strong className="price-amount">
                                {" "}
                                <strong>$</strong>
                                {Math.floor(
                                  planData?.perSeatCharged * addonSeat
                                )}{" "}
                              </strong>
                            )}

                            <span className="seat-package-wrap">
                              {" "}
                              Seat / month{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <OverviewSubscriptionModal
        showPackageModal={showPackageModal}
        handleClosePackageModal={handleClosePackageModal}
        summery={summery}
        userModal={userModal}
        isDark={props.isDark}
        selectedUser={selectedUser}
        setUserModal={setUserModal}
        setUserPro={setUserPro}
        setSummery={setSummery}
        userpro={userpro}
        applicationName={applicationName}
        setSeat={setSeat}
        seat={seat}
        seats={seats}
        minSeats={minSeats}
        selectedPlan={selectedPlan}
        packageData={packageData}
        fullPackage={fullPackage}
        packageName={packageName}
        selectedPackage={selectedPackage}
        setPackageName={setPackageName}
        setPackageDataOverview={setPackageDataOverview}
        packageDataOverview={packageDataOverview}
        handleReSubscribe={handleReSubscribe}
        openModal={openModal}
        load={load}
        getPrice={getPrice}
        inter={inter}
        billingDetail={billingDetail}
        setSelectedPackage={setSelectedPackage}
        setSelectedName={setSelectedName}
        setMinSeats={setMinSeats}
        setOpenContact={setOpenContact}
        userCount={userCount}
        selectedPackageDisplayOrder={selectedPackageDisplayOrder}
        setNewDisplayOrder={setNewDisplayOrder}
        setFullPackage={setFullPackage}
        show={show}
        handleShowTab={handleShowTab}
        setSelectedPlan={setSelectedPlan}
        setInter={setInter}
        loadbtn={props.loadbtn}
        selectedName={selectedName}
        sumLoad={sumLoad}
        setSelectedUser={setSelectedUser}
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}
        removedUserIds={removedUserIds}
        setRemovedUserIds={setRemovedUserIds}
        setUsers={setUsers}
        limit={limit}
        setLimit={setLimit}
        users={users}
        planData={planData}
        amt={amt}
        setAmt={setAmt}
      />
      <PaymentModal
        isOpen={isModalOpen}
        getPackageName={getPlanData}
        onClose={closeModal}
        packageData={packageDataOverview}
        selectedUser={selectedUser}
        checkSeats={checkSeats}
        packageName={selectedName}
        seat={seat}
        amt={amt}
      />
      <ContactModal
        modal={openContact}
        handleClose={() => setOpenContact(false)}
        isDark={props.isDark}
      />
      <AddOnSummaryModal
        modal={addonModal}
        handleClose={handleAddonClose}
        addonSeat={addonSeat}
        setAddOnSeat={setAddOnSeat}
        isDark={props.isDark}
        planData={planData}
        getPlanData={getPlanData}
      />
      <DeleteReSubscribeModel
        showDeleteModal={showDeleteSubModel}
        handleCloseDeleteModal={handleCloseDeleteSubModal}
        handleSubmit={async (
          packageId,
          stripeProductId,
          totalAmount,
          setCount,
          interval
        ) =>
          await handleReSubscribeSamePackage(
            packageId,
            stripeProductId,
            totalAmount,
            setCount,
            interval
          )
        }
        packageId={billingDetail && billingDetail[0]?.packageId}
        stripeProductId={billingDetail && billingDetail[0]?.stripeProductId}
        seatCount={billingDetail && billingDetail[0]?.seatCount}
        interval={billingDetail && billingDetail[0]?.interval}
        totalAmount={billingDetail && billingDetail[0]?.totalAmount}
        isDark={props.isDark}
        title={resub?.long_description}
        buttonTitle={"Subscribe"}
        setLoad={setLoad}
      />
      <DeleteUnSubscribeModel
        showDeleteModal={showDeleteUnsubModel}
        handleCloseDeleteModal={handleCloseDeleteUnsubModal}
        handleSubmit={async (id) => await handleUnSubscribe(id)}
        billingId={billingDetail && billingDetail[0]?.id}
        isDark={props.isDark}
        title={"Are You Sure You Want To Unsubscribe?"}
        buttonTitle={"Unsubscribe"}
        setLoad={setLoad}
        unsub={unsub}
        unsubOne={unsubOne}
      />
      <SeeMoreModal
        seeMore={seeMore}
        setSeeMore={setSeeMore}
        headTextOne="Plan"
        headTextTwo="Details"
        isDark={props.isDark}
        modalContent={planText}
      />
    </div>
  );
};

export default Overview;
