import React from "react";

const LogBox = ({ log, isDark }) => {
  const getInitials = (name) => {
    const words = name?.split(" ");
    const initials = words
      ?.map((word) => word?.charAt(0)?.toUpperCase())
      ?.join("");
    return initials?.substring(0, 2);
  };

  // Function to calculate duration ago
  function calculateDurationAgo(dateString) {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - pastDate;

    // Convert milliseconds to seconds, minutes, hours, days, weeks, months, and years
    const seconds = Math.floor(differenceMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    // Determine which unit to display
    if (years > 0) {
      return years + " years ago";
    } else if (months > 0) {
      return months + " months ago";
    } else if (weeks > 0) {
      return weeks + " weeks ago";
    } else if (days > 0) {
      return days + " days ago";
    } else if (hours > 0) {
      return hours + " hours ago";
    } else if (minutes > 0) {
      return minutes + " minutes ago";
    } else if (seconds >= 1) {
      return seconds + " seconds ago";
    } else {
      return "Just now";
    }
  }

  return (
    <div className="d-flex gap-2 mt-4 comment-box">
      <div className="comment-user-image-container">
        {log?.userDetails?.profileImage ? (
          <img
            src={log?.userDetails?.profileImage}
            alt="comment-user-profile-name"
          />
        ) : (
          <div className="user-comment-name">
            {getInitials(log?.userDetails?.userName)}
          </div>
        )}
      </div>
      <div className="w-100 d-flex flex-column comment-box-text">
        <p className="comment-user-name">
          {log?.userDetails?.userName}
          <span className="ms-2 comment-date">
            {calculateDurationAgo(log?.createdAt)}
          </span>
        </p>
        <p className="comment-user-comment">{log.logDescription}</p>
      </div>
    </div>
  );
};

export default LogBox;
