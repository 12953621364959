import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import CompanyMemberCard from "./CompanyMemberCard";
import InviteModal from "../../../components/InviteModal/InviteModal";
import AddOnModal from "../../SubscriptionsUpdated/components/AddOnModal";
import ConfirmAddonModal from "../../../components/ConfirmAddonModal";
import useCompanyMembers from "../services/useCompanyMembers";

const CompanyMembers = ({ isDark, members, user }) => {
  const {
    showModal,
    setShowModal,
    addonModal,
    showAddModal,
    setShowAddModal,
    planData,
    billingDetail,
    handleCloseModal,
    handleAddonClose,
    handleAddonShow,
    isAdmin,
  } = useCompanyMembers(user);

  return (
    <div className="company-members">
      <div
        className={`company-members-header ${
          members?.length === 0 ? "mb-0" : ""
        }`}
      >
        <p>
          Company <span>Members</span> ({members?.length})
        </p>
        <button
          className="company-invite-member-button"
          onClick={() => setShowModal(true)}
        >
          <Icon icon="icons8:add-user" fontSize={20} /> Invite Member
        </button>
      </div>
      <div
        className={`company-members-list ${
          members?.length > 3 ? "company-members-more-then-one" : ""
        }`}
      >
        {members?.map((member, index) => (
          <CompanyMemberCard key={index} member={member} />
        ))}
      </div>
      {showModal && (
        <InviteModal
          isDark={isDark}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          addonModal={addonModal}
          handleAddonClose={handleAddonClose}
          handleAddonShow={handleAddonShow}
          setShowAddModal={setShowAddModal}
          isAdmin={isAdmin}
        />
      )}
      {addonModal && (
        <AddOnModal
          modal={addonModal}
          handleClose={handleAddonClose}
          isDark={isDark}
          planData={planData}
          getPlanData={handleAddonShow}
        />
      )}
      {showAddModal && (
        <ConfirmAddonModal
          isDark={isDark}
          handleAddonShow={handleAddonShow}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          billingDetail={billingDetail}
          planData={planData}
        />
      )}
    </div>
  );
};

export default CompanyMembers;
