import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/Button";
import SearchNew from "../../../assets/search-new-icon.svg";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import BacklogBoard from "./BacklogBoard";
import SprintList from "./SprintList";
import { v4 as uuidv4 } from "uuid"; // Import from uuid
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ScrumMain = () => {
  const { applicationName } = useSelector((state) => state.text);
  const [epicValue, setEpicValue] = useState("Epic");

  // Epic list
  const epicList = ["Epic", "test", "test"];

  // Task and boardData
  const [data, setData] = useState([
    {
      boardId: uuidv4(),
      boardName: "Backlog",
      boardType: "epic",
      isStarted: false,
      tasks: [
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: null,
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: {
            id: "2eb3816c-c11c-447c-a64c-d86570372fed",
            typeName: "Bug",
          },
        },
      ],
    },
    {
      boardId: uuidv4(),
      boardName: "Backlog2",
      boardType: "epic",
      isStarted: false,
      tasks: [
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: null,
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: null,
        },
      ],
    },
    {
      boardId: uuidv4(),
      boardName: "Sprint1",
      boardType: "sprint",
      isStarted: true,
      tasks: [
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: null,
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "In Progress" },
          taskType: null,
        },
      ],
    },
    {
      boardId: uuidv4(),
      boardName: "Sprint2",
      boardType: "sprint",
      isStarted: false,
      tasks: [
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Not Started" },
          taskType: {
            id: "2eb3816c-c11c-447c-a64c-d86570372fed",
            typeName: "Issue",
          },
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Completed" },
          taskType: {
            id: "2eb3816c-c11c-447c-a64c-d86570372fed",
            typeName: "Issue",
          },
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "On Hold" },
          taskType: {
            id: "2eb3816c-c11c-447c-a64c-d86570372fed",
            typeName: "Issue",
          },
        },
        {
          taskId: uuidv4(),
          taskName: `Task ${uuidv4()}`,
          taskIcon: "",
          taskStatus: { statusName: "Completed" },
          taskType: {
            id: "2eb3816c-c11c-447c-a64c-d86570372fed",
            typeName: "Issue",
          },
        },
      ],
    },
    {
      boardId: uuidv4(),
      boardName: "Sprint3",
      boardType: "sprint",
      isStarted: false,
      tasks: [],
    },
  ]);

  const handleDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!result.destination) return;
    // If dragging tasks
    const sourceTypeIndex = parseInt(source.droppableId);
    const destinationTypeIndex = parseInt(destination.droppableId);
    const updatedTasksData = [...data];

    // If source and destination is same
    if (sourceTypeIndex === destinationTypeIndex) {
      const tasks = [...updatedTasksData[sourceTypeIndex].tasks];
      const [removedTask] = tasks.splice(source.index, 1);
      tasks.splice(destination.index, 0, removedTask);
      updatedTasksData[sourceTypeIndex] = {
        ...updatedTasksData[sourceTypeIndex],
        tasks,
      };
      setData(updatedTasksData);
    } else {
      // If dragging between different boards
      const sourceTasks = [...updatedTasksData[sourceTypeIndex].tasks];
      const destinationTasks = [
        ...updatedTasksData[destinationTypeIndex].tasks,
      ];
      const [draggedTask] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, draggedTask);
      updatedTasksData[sourceTypeIndex] = {
        ...updatedTasksData[sourceTypeIndex],
        tasks: sourceTasks,
      };
      updatedTasksData[destinationTypeIndex] = {
        ...updatedTasksData[destinationTypeIndex],
        tasks: destinationTasks,
      };
      //  const taskId = draggableId; // Assuming draggableId is taskId
      //  const statusId = statusIdMap[parseInt(destination.droppableId)]; // Assuming droppableId is statusId
      setData(updatedTasksData);
    }
    // const updatedData = updateState(data, result);
    // setData(updatedData);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Scrum-{applicationName}</title>
      </Helmet>
      <div className="timeline-dashboard-wraper">
        <Tooltip id="my-tooltip-project" place="right" />
        <div className="time-line-field-wrap project-main-wrap">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h4 className="d-flex align-items-center projects-heading_wrap projects-detail-heading-wrap scrum-header">
                {" "}
                Project Name{" "}
                <div className="ms-2 d-flex user-list-scrum">
                  <div>
                    <p>MA</p>
                  </div>
                  <div>
                    <p>TJ</p>
                  </div>
                  <div>
                    <p>WA</p>
                  </div>
                  <div className="ms-2">
                    <i className={`fa-solid fa-add`} aria-hidden="true"></i>
                  </div>
                </div>
                <div className="form-group scrum-header-epic-form-control">
                  <Select
                    className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div record-project-wrap`}
                    classNamePrefix="select"
                    value={{
                      label: epicValue,
                      value: epicValue,
                    }}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    options={epicList.map((res, index) => ({
                      label: res,
                      value: res,
                    }))}
                    onChange={(e) => {
                      //   setCurrentPage(1);
                      //   setPageSize(e.value);
                    }}
                    components={{
                      Control: ({ children,menuIsOpen, ...props }) => {
                        return (
                          <components.Control {...props}>
                            {props.hasValue ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ color: "blue !important" }}>
                                  {children}
                                </div>
                              </div>
                            ) : (
                              children
                            )}
                            <div className="select-icon me-1" style={{
                              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}>
                              <IoIosArrowDown />
                            </div>
                          </components.Control>
                        );
                      },
                    }}
                  ></Select>
                </div>
                <button
                  className="create-btn"
                  data-tooltip-id="my-tooltip-project"
                  data-tooltip-content="Create task"
                  onClick={(e) => {
                    // setShowTask(true);
                  }}
                >
                  {" "}
                  Create{" "}
                </button>
              </h4>
              <div className="project-header-search-section">
                <ul>
                  <li>
                    <div className="form-group">
                      <button>
                        <img src={SearchNew} alt="search-icon" />
                      </button>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        // value={filters?.searchQuery}
                        // onChange={handleSearchChange}
                      />
                    </div>
                  </li>

                  <li>
                    <Button
                      icon="fa-solid fa-add"
                      text="Create Sprint"
                      handleClick={() => {
                        //   setShowStage(true);
                      }}
                      margin="0px 5px 0px 0px"
                    />
                  </li>

                  <li>
                    <Button
                      icon="fa-solid fa-add"
                      text="Add Project"
                      handleClick={() => {
                        //   setShow(true);
                      }}
                      margin="0px 5px 0px 0px"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="d-flex justify-content-between">
            <div className="backlog-wrap">
              {data &&
                data?.map(
                  (res, id) =>
                    res?.boardType === "epic" && (
                      <BacklogBoard data={res} taskId={id} />
                    )
                )}
            </div>
            <div className="sprint-wrap">
              <SprintList data={data} />
            </div>
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default ScrumMain;
