// reducers/projectCompletedListingReducer.js

import {
  PROJECT_COMPLETED_LISTING_REQUEST,
  PROJECT_COMPLETED_LISTING_SUCCESS,
  PROJECT_COMPLETED_LISTING_FAIL,
  PROJECT_COMPLETED_EDIT_SUCCESS,
  PROJECT_COMPLETED_EDIT_FAIL,
  PROJECT_COMPLETED_DELETE_SUCCESS,
  PROJECT_COMPLETED_DELETE_FAIL,
  PROJECT_COMPLETED_CLEAR_STATE,
} from "../constants/projectCompletedListingConstants";

const initialState = {
  loading: false,
  projectCompletedCacheData: {},
  error: null,
};

export const projectCompletedListingReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PROJECT_COMPLETED_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_COMPLETED_LISTING_SUCCESS: {
      const { data, limit, page } = action.payload;

      return {
        ...state,
        loading: false,
        projectCompletedCacheData: {
          ...state.projectCompletedCacheData,
          [`${limit}_${page}`]: data,
        },
      };
    }

    case PROJECT_COMPLETED_EDIT_SUCCESS: {
      // Assuming that the payload contains the updated project data.
      const updatedProject = action.payload;
      const updatedCache = { ...state.projectCompletedCacheData };
      Object.keys(updatedCache).forEach((key) => {
        updatedCache[key] = updatedCache[key]?.map((project) =>
          project?.id === updatedProject?.id ? updatedProject : project
        );
      });
      return {
        ...state,
        loading: false,
        projectCompletedCacheData: updatedCache,
      };
    }
    case PROJECT_COMPLETED_DELETE_SUCCESS: {
      // Assuming that the payload contains the projectId that was deleted.
      const deletedProjectId = action.payload;
      const cacheAfterDeletion = { ...state.projectCompletedCacheData };
      Object.keys(cacheAfterDeletion).forEach((key) => {
        cacheAfterDeletion[key] = cacheAfterDeletion[key].filter(
          (project) => project.id !== deletedProjectId
        );
      });
      return {
        ...state,
        loading: false,
        projectCompletedCacheData: cacheAfterDeletion,
      };
    }

    case PROJECT_COMPLETED_LISTING_FAIL:
    case PROJECT_COMPLETED_EDIT_FAIL:
    case PROJECT_COMPLETED_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case PROJECT_COMPLETED_CLEAR_STATE: {
      return {
        ...state,
        loading: false,
        projectCompletedCacheData: {},
        error: null,
      };
    }
    default:
      return state;
  }
};
