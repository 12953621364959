import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { ShimmerButton } from "react-shimmer-effects";

const AddUserRole = (props) => {
  const [modLoad, setModLoad] = useState(true);
  const [selectedRole, setSelectedRole] = useState(props?.roles[0]?.roleId);
  const [selectedModule, setSelectedModule] = useState(0);
  const [modules, setModules] = useState([]);
  const [rload, setRLoad] = useState(false);

  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };
  const handleSelectModule = (module) => {
    setSelectedModule(module);
  };

  const handleSubmit = async () => {
    try {
      setRLoad(true);
      const response = await API.post("users/update-roles-modules", {
        roleId: selectedRole,
        modulesCategoriesId: selectedModule,
        userId: props?.user?.userInfo?.userId,
      });
      if (response?.data?.status === "success") {
        props?.getUser();
        props.handleCloseAddRoleModal();
        toast.success("Role updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      // console.log("Err : ", error);
    } finally {
      setRLoad(false);
    }
  };

  useEffect(() => {
    const getModule = async () => {
      try {
        setModLoad(true);
        const responseDept = await API.get("company/modules-categories");
        if (responseDept?.data?.status === "success") {
          const companyModules = responseDept?.data?.data?.companyModules;

          // Check if props.user is available and has roles
          if (props.user) {
            // Iterate over companyModules
            const updatedArray2 = companyModules.map((category) => {
              const matchingModule = props?.user?.userRolesAndModules.find(
                (module) => {
                  return module.moduleCategories.some(
                    (cat) => cat.moduleId === category.moduleCategoryId
                  );
                }
              );

              if (matchingModule) {
                category.roleId = matchingModule.roleId;
              }

              return category; // Make sure to return the modified category
            });
            setModules(updatedArray2);
            setSelectedModule(updatedArray2[0]?.moduleCategoryId);
            handleSelectRole(updatedArray2[0]?.roleId);
          }
        }
      } catch (error) {
      } finally {
        setModLoad(false);
      }
    };
    getModule();
  }, [props.user]);

  return (
    <Modal
      show={props.showAddRoleModal}
      onHide={props.handleCloseAddRoleModal}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        {modLoad ? (
          <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
            <ShimmerButton size="sm" />
            <ShimmerButton size="sm" />
            <ShimmerButton size="sm" />
            <ShimmerButton size="sm" />
          </div>
        ) : (
          <>
            <div className="w-100 modal-module-wrapper">
              <h6>Select Modules</h6>
              <div className="w-100 modules-opt-wrapper">
                {modules &&
                  modules?.map((res, id) => (
                    <div
                      key={id}
                      className={`${
                        selectedModule === res?.moduleCategoryId
                          ? "role-selected-modal"
                          : ""
                      }`}
                      onClick={() => {
                        handleSelectModule(res?.moduleCategoryId);
                        handleSelectRole(res?.roleId);
                      }}
                    >
                      {res?.categoryName}
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-100 modal-role-wrapper">
              <h6>Assign Role</h6>
              <div className="w-100 roles-opt-wrapper">
                {props.roles?.map(
                  (rol, id) =>
                    rol.roleName?.toString()?.toLowerCase() !== "admin" && (
                      <div
                        className={`${
                          selectedRole === rol?.roleId
                            ? "role-selected-modal"
                            : ""
                        }`}
                        key={id}
                        onClick={() => {
                          handleSelectRole(rol?.roleId);
                        }}
                      >
                        {rol?.roleName.charAt(0).toUpperCase() +
                          rol?.roleName.slice(1)}
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="w-100 modal-btn-wrapper d-flex mt-4 justify-content-end">
              <button onClick={handleSubmit}>
                {rload ? (
                  <Spinner className="spinner-role" border="animation" />
                ) : (
                  "Update Role"
                )}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddUserRole;
