import {
  REGISTERED_USER_COUNT_FAIL,
  REGISTERED_USER_COUNT_REQUEST,
  REGISTERED_USER_COUNT_SUCCESS,
} from "../constants/registeredUserCountConstants.js";
import API from "../services/ClientApi.js";
import { v4 as uuidv4 } from "uuid";

export const registeredUserCountAction = () => async (dispatch) => {
  try {
    dispatch({ type: REGISTERED_USER_COUNT_REQUEST });
    const { data } = await API.post("company/registered-user-count", {});
    if (data?.status === "success") {
      dispatch({
        type: REGISTERED_USER_COUNT_SUCCESS,
        payload: data?.registeredUserCount,
      });
    }
  } catch (error) {
    dispatch({
      type: REGISTERED_USER_COUNT_FAIL,
      payload: error,
      meta: {
        retryAction: registeredUserCountAction(), // Retry action
      },
    });
  }
};
