import React, { useState } from "react";
import "../styles/live_chat.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ChatMain from "../../Chat/ChatMain";

const LiveChat = (props) => {
  const [showChat, setShowChat] = useState(true);
  return (
    <>
      {/* Main Wrapper */}
      <ChatMain
        show={showChat}
        setShowChat={setShowChat}
        handleCloseModal={() => setShowChat(false)}
        isDark={props.isDark}
      />
    </>
  );
};

export default LiveChat;
