/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import API from "../../../services/ClientApi";
import { ShimmerCategoryList } from "react-shimmer-effects";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { userProfilePage } from "../../../actions/userActions";
import { useDispatch } from "react-redux";

const Notifications = ({ user }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [isChecked, setIsChecked] = useState(
    user?.userInfo?.emailNotification || false
  );
  const [load, setLoad] = useState(false);

  const handleChange = async () => {
    const newValue = !isChecked;
    setIsChecked(newValue); // Update the local state immediately

    try {
      const response = await API.post("users/update-profile", {
        userId: user?.userInfo?.userId,
        fieldName: "emailNotification", // Field name for the update
        fieldValues: newValue.toString(), // Convert boolean to string ("true"/"false")
      });

      if (response.status === 200) {
        toast.success("Profile updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        dispatch(userProfilePage());
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    setIsChecked(user?.userInfo?.emailNotification);
  }, [user]);

  useEffect(() => {
    const getNotifications = async () => {
      setLoad(true);
      try {
        const response = await API.get("users/notification");
        if (response?.data?.status === "success") {
          setNotifications(response?.data?.data);
          // eslint-disable-next-line no-unused-vars
          const notificationKeysArray = response?.data?.data.map(
            (notification) => notification.notificationKey
          );
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoad(false);
      }
    };

    getNotifications();
  }, []);

  return (
    <div className="listing-info-profile-wrap">
      <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
        <h4>Notifications</h4>
        <div className="d-flex align-items-center gap-2 email-notify-text-container">
          <h4>Email Notification</h4>
          <Switch
            onChange={handleChange}
            checked={isChecked}
            checkedIcon={null}
            uncheckedIcon={null}
            className={`${isChecked ? "custom-switch" : ""}`}
            height={20}
            width={40}
            handleDiameter={20}
          />
        </div>
      </div>
      <ul className="row">
        {load ? (
          <div className="w-100 d-flex justify-content-center global-spinner ">
            <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />
          </div>
        ) : notifications.length > 0 ? (
          notifications.map((n, id) => (
            <li className="col-md-12" key={id}>
              <div className="overview-profile">
                <a>
                  <div className="overview-profile-image">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 11 11"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.97965 3.19698C3.1091 1.90248 4.19839 0.916687 5.49935 0.916687C6.8003 0.916687 7.8896 1.90248 8.01904 3.19698L8.15041 4.51063C8.21538 5.16029 8.43874 5.7841 8.8009 6.32735L9.19559 6.91938C9.57478 7.48816 9.16704 8.25002 8.48345 8.25002H2.51525C1.83166 8.25002 1.42392 7.48816 1.80311 6.91938L2.1978 6.32735C2.55996 5.7841 2.78332 5.16029 2.84829 4.51063L2.97965 3.19698Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.61392 9.45515C4.66614 9.50387 4.78123 9.54693 4.94132 9.57764C5.10141 9.60835 5.29756 9.625 5.49935 9.625C5.70114 9.625 5.89729 9.60835 6.05738 9.57764C6.21747 9.54693 6.33255 9.50387 6.38478 9.45515"
                        stroke="currentColor"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div className="number-overview-wrap">
                    <p>{n?.content}</p>
                  </div>
                  <div className="clearfix"></div>
                </a>
              </div>
            </li>
          ))
        ) : (
          <div className="d-flex mt-2 align-items-center rounded-3 ">
            <p className="no-billing-txt">No notifications found.</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Notifications;
