import React, { useEffect, useMemo, useRef, useState } from "react";
import TaskIcon from "../../../assets/task-board.svg";
import ProjectIcon from "../../../assets/project-graph.svg";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { dateFormatter } from "../../../helper/dateFormatter";
import Delete from "../../../assets/delete.svg";
import { createPortal } from "react-dom";
import DropButton from "./DropButton";
import { Icon } from "@iconify/react";
import EditIcon from "../../../assets/edit-project.svg";
import {
  PerformanceTeamCellRenderer,
  TeamPerformanceDropdownRenderer,
} from "../../Tasks/components/utils/data";
import doneImage from "../../../assets/completed-icon.png";
import ConfirmDeleteTeamModal from "./ConfirmDeleteTeamModal";
import { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import getProjectIconById from "../../../helper/projectIconGenerator";
import EditTeam from "./EditTeam";
import TeamDetailModal from "./TeamDetailModal";
import { isPermitted } from "../../../helper/isPermitted";
import { useSelector } from "react-redux";
import { isAdmin } from "../../../helper/isUserAdmin";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const TeamList = React.memo((props) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [teamCreator, setTeamCreator] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });
  const { userProfile } = useSelector((state) => state?.profile);

  const handleDetailClose = () => {
    setShowDetail(false);
  };

  const optionsDropdownRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);

  const gridReady = (params) => {
    setGridApi(params.api);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // Get project name by id
  const getTeamName = (id) => {
    const team = props.teamToFilterList.find((team) => team.id === id);
    return team ? team.teamName : null;
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const OpenProjectCellRenderer = ({ pid }) => {
    return (
      <p className="open-target-link-p" onClick={() => setShowDetail(true)}>
        <Icon icon="material-symbols-light:open-in-full-rounded" /> Open
        {/* <img src={openTask} alt="Open Task" width={100} height={100} /> */}
      </p>
    );
  };

  const TeamNameCellRenderer = (props) => {
    const { teamName, teamIcon } = props.data;
    return (
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="task-title m-0 d-flex align-items-center gap-2">
          <img
            className="project-listing-icon-img"
            src={
              getProjectIconById(teamIcon)
                ? getProjectIconById(teamIcon)
                : props?.data?.teamIcon
            }
            alt="project-icon"
          />
          {teamName.length > 15 ? teamName.substring(0, 15) + "..." : teamName}
        </h5>
      </div>
    );
  };

  // Update name
  const updateTeam = async (data) => {
    try {
      await ProjectAPI.post(`teams/update`, data);
    } catch (error) {
      toast.error(error, { position: toast.POSITION.TOP_CENTER });
    }
  };

  const columns = [
    {
      field: "teamName",
      headerName: "Team Name",
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      // width: 250,
      // minWidth: 250,
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <TeamNameCellRenderer
              data={params.data}
              api={params.api}
              // handleEdit={handleEdit}
              row={params.rowIndex}
              showPin={hoveredRow === params.data.id}
              // setEdit={setEdit}
            />
            <div className="d-flex align-items-center gap-2">
              {hoveredRow === params.data.id && !params.data.isPinned && (
                <Icon
                  className="tasks-listing-pin-icon"
                  icon="icon-park-solid:pin"
                  onClick={async () => {
                    params.data.isPinned = true;
                    // Refresh the grid to reflect the changes
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      force: true,
                      suppressFlash: true,
                    });
                    const fieldEndData = {
                      teamId: params?.data?.id,
                      fieldName: "isPinned",
                      fieldValues: true,
                    };
                    // Refresh the entire grid
                    props.setFilters({
                      ...props.filters,
                      pinnedState: !props.filters.pinnedState,
                    });
                    await updateTeam(fieldEndData);
                  }}
                />
              )}
              {params.data.isPinned && (
                <Icon
                  className="tasks-listing-pin-icon pined-icon"
                  icon="icon-park-solid:pin"
                  onClick={async () => {
                    params.data.isPinned = false;
                    // Refresh the grid to reflect the changes
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      force: true,
                      suppressFlash: true,
                    });
                    const fieldEndData = {
                      teamId: params?.data?.id,
                      fieldName: "isPinned",
                      fieldValues: false,
                    };
                    // Refresh the entire grid
                    props.setFilters({
                      ...props.filters,
                      pinnedState: !props.filters.pinnedState,
                    });
                    await updateTeam(fieldEndData);
                  }}
                />
              )}
              {isPermitted("teams/detail") && (
                <OpenProjectCellRenderer
                  // showModal={handleTaskDetailsShowModal}
                  id={params.data.id}
                />
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "task",
      headerName: "Task",
      maxWidth: 200,
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2 team-list-img-text-wrap">
            <img src={TaskIcon} alt="user-avatar" />
            <p className="mb-0"> Tasks {params.data.recentTaskCount}</p>
          </div>
        );
      },
    },
    {
      field: "projects",
      headerName: "Projects",
      maxWidth: 200,
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center gap-2 team-list-img-text-wrap">
            <img src={ProjectIcon} alt="user-avatar" />
            <p className="mb-0"> Projects {params.data.projectsCount}</p>
          </div>
        );
      },
    },
    {
      field: "progressbarValue",
      headerName: "",
      minWidth: 300,
      suppressMenu: true,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      cellEditorSelector: (params) => {
        return {
          component: (params) => {
            return (
              <div
                style={{
                  // position: "absolute",
                  // left: `${xValue}px`,
                  // top: `${yValue + 20}px`,
                  listStyle: "none",
                  background: "#fff", // Set background color
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  fontWeight: 300,
                }}
                className="d-flex flex-column user-permission-modules-dropdown px-2 py-2 d-flex align-items-start justify-content-start bg-white rounded-2 border-1 border"
              >
                <div className="task-drop-renderer">
                  <div className="mb-2 mt-2 task-timeline-progress review-task-timeline-progress">
                    {" "}
                    <img src={doneImage} alt="Done" /> Not Started{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress review-task-timeline-progress ">
                    <img src={doneImage} alt="Done" /> Started{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress review-task-timeline-progress text-danger">
                    {" "}
                    <Icon
                      icon="mdi:close-circle"
                      className="cross-icon-width-height"
                    />{" "}
                    In Progress{" "}
                  </div>
                  <div className="mb-2 task-timeline-progress done-task-timeline-progress">
                    {" "}
                    <img src={doneImage} alt="Done" /> Completed{" "}
                  </div>
                </div>
              </div>
            );
          },
          popup: true,
          popupPosition: "under",
        };
      },

      cellRenderer: (params) => {
        return (
          <>
            <PerformanceTeamCellRenderer
              data={params.data}
              api={params.api}
              params={params}
              dropdownRenderer={TeamPerformanceDropdownRenderer}
              noName={true}
            />
          </>
        );
      },
    },

    {
      field: "recent_task",
      editable: false,
      headerName: "",
      maxWidth: 180,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      cellRenderer: (params) => {
        // buttonRefs[params.rowIndex] = buttonRef.current;
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              Recent tasks {params.data.recentTaskCount}
            </p>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "",
      editable: false,
      cellClass: (params) =>
        hoveredRow === params.data.id ? "selected-project-row" : "",
      cellRenderer: (params) => {
        // buttonRefs[params.rowIndex] = buttonRef.current;
        return (
          <div className="w-100 d-flex align-items-center justify-content-between role-type-table-wrap">
            <p onClick={(params) => {}}>
              Last active: {dateFormatter(params?.data?.updatedAt)}
            </p>
          </div>
        );
      },
    },
  ];

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const gridRef = useRef(null);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Get the bounding box of the grid and options dropdown
      const gridRect = gridRef?.current?.getBoundingClientRect();
      const optionsDropdownRect =
        optionsDropdownRef?.current?.getBoundingClientRect();

      // Check if the mouse has left the grid area
      if (
        event.clientX > gridRect.right ||
        event.clientY < gridRect.top ||
        event.clientY > gridRect.bottom
      ) {
        if (!showMore) {
          setHoveredRow(null);
          setShowMore(false);
        }
        // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

        if (
          showMenu === false ||
          (optionsDropdownRect &&
            (event.clientX < optionsDropdownRect.left ||
              event.clientX > optionsDropdownRect.right ||
              event.clientY < optionsDropdownRect.top ||
              event.clientY > optionsDropdownRect.bottom))
        ) {
          setHoveredMenu(null);
          setShowMenu(false);
        }
      }
    };

    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null) {
      const dropdownHeight = 70; // Adjust this value based on your dropdown height
      const availableSpaceBelow =
        window.innerHeight - (yValue + dropdownHeight);

      const dropdownPosition =
        availableSpaceBelow < 0
          ? {
              top: `${yValue - dropdownHeight - 26}px`,
              left: `${xValue - 20}px`,
            }
          : { top: `${yValue}px`, left: `${xValue - 20}px` };

      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top,
            left: optionsColumnPosition.left,
            zIndex: 9999,
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}
          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div className="position-relative mt-2">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
          </div>
          {showMenu && (
            <Portal>
              <div
                ref={optionsDropdownRef}
                style={{
                  position: "absolute",
                  left: dropdownPosition.left,
                  top: dropdownPosition.top,
                  listStyle: "none",
                  background: "#fff", // Set background color
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  fontWeight: 300,
                  minWidth: "150px",
                }}
                onMouseOver={(e) => setShowMenu(true)}
                // onMouseLeave={() => setShowMore(false)}
                className="dropdown-list user-permission-modules-dropdown px-2 py-2 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1 border"
              >
                {((isPermitted("teams/update") &&
                  userProfile?.userId === teamCreator) ||
                  isAdmin(userProfile)) && (
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setUpdateTaskCanvas(true)}
                  >
                    <img
                      src={EditIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      alt="edit-icon"
                    />
                    Edit Team
                  </div>
                )}
                {((isPermitted("teams/delete") &&
                  userProfile?.userId === teamCreator) ||
                  isAdmin(userProfile)) && (
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <img
                      src={Delete}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      alt="delete-icon"
                    />
                    Delete Team
                  </div>
                )}
              </div>
            </Portal>
          )}
        </div>
      );
    }
    return null;
  };

  const teamOptionsPermission =
    (isAdmin(userProfile) &&
      (isPermitted("teams/delete") || isPermitted("teams/update"))) ||
    (userProfile?.userId === teamCreator &&
      (isPermitted("teams/delete") || isPermitted("teams/update")));

  const changeTheOptionPosition = async (r, i) => {
    const hoveredRowElement = document?.querySelector(`.ag-row[row-id="${r}"]`);
    if (hoveredRowElement) {
      setOptionsColumnPosition({
        top: i * 55 + 10,
        left: -20, // Adjust the left position as needed
      });
    }
  };

  // Scrolling events
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(true);
  const scrollInterval = useRef(null);
  const tableRef = useRef(null); // Ref for the .table-responsive div
  const [isTableScrollable, setIsTableScrollable] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const timer = setTimeout(() => {
        const table = tableRef?.current;
        const needsScrolling = table?.scrollWidth > table?.clientWidth;
        setIsTableScrollable(needsScrolling);
      }, 100); // Adjust the delay time as needed

      return () => clearTimeout(timer);
    };

    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener and clear timer when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.teamList]);

  useEffect(() => {
    if (props.teamList?.length !== 0) {
      const handleScroll = () => {
        const table = tableRef.current;
        setIsLeftScrollable(table.scrollLeft > 0);
        setIsRightScrollable(
          table.scrollLeft + table.clientWidth < table.scrollWidth
        );
      };

      const table = tableRef.current;
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [props.teamList]);

  const handleScrollLeft = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft -= 5;
      if (table.scrollLeft <= 0) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const handleScrollRight = () => {
    const table = tableRef.current;
    scrollInterval.current = setInterval(() => {
      table.scrollLeft += 5;
      if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        clearInterval(scrollInterval.current);
      }
    }, 1);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval.current);
  };

  return (
    <div
      className="position-relative project-timeline mt-3 pb-2 active-project-no-header"
      onMouseEnter={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "auto";
        }
      }}
      onMouseLeave={() => {
        if (tableRef.current !== null) {
          tableRef.current.style.overflowX = "hidden";
        }
      }}
    >
      <div className="position-relative">
        {gridApi && teamOptionsPermission && renderCustomOptionsColumn()}
      </div>
      <div
        className="table-responsive ms-4 scroll-smooth custom-horizental-scrollbar"
        ref={tableRef}
      >
        <div
          className="ag-theme-alpine project-main-grid team-data-grid"
          style={{
            width: "98%",
            minWidth: "1550px",
          }}
          ref={gridRef}
        >
          {/* Left scroll button */}
          {isTableScrollable && isLeftScrollable && (
            <div
              className={`left-scroll-button ${
                props.teamList?.length === 1
                  ? "left-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollLeft}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <AgGridReact
            rowData={props.teamList}
            defaultColDef={defaultColDef}
            domLayout={"autoHeight"}
            columnDefs={columns}
            singleClickEdit={true}
            getRowId={(params) => {
              return params.data.id;
            }}
            rowHeight={55}
            animateRows={true}
            rowDragManaged={true}
            rowDragEntireRow={true}
            popupParent={popupParent}
            onGridReady={gridReady}
            stopEditingWhenCellsLoseFocus={true}
            suppressRowHoverHighlight={true}
            onCellMouseOver={(params) => {
              if (params.data.id !== hoveredRow) {
                setShowMore(false);
                setHoveredRow(params?.data?.id);
                setTeamCreator(params?.data?.createdBy);
                !updateTaskCanvas && setTeamId(params?.data?.id);
              }
              if (params?.rowIndex !== hoveredMenu) {
                setShowMenu(false);
                setHoveredMenu(params?.rowIndex);
                changeTheOptionPosition(params?.data?.id, params?.rowIndex);
              }
            }}
          />
          {/* Right scroll button */}
          {isTableScrollable && isRightScrollable && (
            <div
              className={`right-scroll-button ${
                props.teamList?.length === 1
                  ? "right-scroll-button-height-one"
                  : ""
              }`}
              onMouseEnter={handleScrollRight}
              onMouseLeave={stopScrolling}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
      <ConfirmDeleteTeamModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDark={props.isDark}
        getTeamName={getTeamName}
        id={teamId}
        setListToFilter={props.setTeamToFilterList}
      />
      {updateTaskCanvas && (
        <EditTeam
          id={teamId}
          show={updateTaskCanvas}
          handleClose={() => setUpdateTaskCanvas(false)}
          isDark={props.isDark}
          projectList={props.projectList}
          projectUserList={props.projectUserList}
          teamToFilterList={props.teamToFilterList}
          setTeamToFilterList={props.setTeamToFilterList}
          teamList={props.teamList}
          setTeamList={props.setTeamList}
        />
      )}
      {showDetail && (
        <TeamDetailModal
          id={teamId}
          show={showDetail}
          handleCloseModal={handleDetailClose}
          isDark={props.isDark}
        />
      )}
    </div>
  );
});

export default TeamList;
