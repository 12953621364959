import React from "react";

function PointInput({
  label,
  value,
  onChange,
  error,
  isZeroValue = true,
  className = "",
}) {
  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue === "" ? "" : Number(newValue), e);
  };

  const handleIncrement = () => {
    onChange(Number(value) + 1);
  };

  const handleDecrement = () => {
    if (Number(value) > 0) onChange(Number(value) - 1);
  };
  return (
    <div className={`point-input-container ${className}`}>
      <p className="point-label mb-2">{label}</p>
      <small className="text-secondary mb-2">No. of Points</small>

      <div className="d-flex align-items-center justify-content-between point-input-container-btn-section">
        <button
          type="button"
          className="btn btn-outline-secondary minus-btn"
          onClick={handleDecrement}
        >
          -
        </button>
        <input
          type="number"
          value={value}
          onChange={handleChange}
          className="form-control text-center border-0 p-0 m-0"
          style={{ width: "max-content" }}
        />

        <button
          type="button"
          className="btn btn-outline-secondary plus-btn"
          onClick={handleIncrement}
        >
          +
        </button>
      </div>
      {error && (
        <div className="text-danger mt-1 text-error text-start ms-0 ">
          {error}
        </div>
      )}
    </div>
  );
}

export default PointInput;
