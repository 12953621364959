import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import CompanyHourCard from "./CompanyHourCard";
import SetWorkingHoursModal from "../modals/SetWorkingHoursModal";

const CompanyHours = ({ isDark, companyProfile }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="company-work-wrapper">
      <div
        className={`company-work-header ${
          companyProfile?.workingHours?.length === 0
            ? "mb-0 justify-content-between"
            : ""
        }`}
      >
        <p>
          Working <span>Hours</span>
        </p>
        <button className="company-work-button" onClick={() => setShow(true)}>
          <Icon icon="gala:add" />
          Add Another
        </button>
      </div>
      <div className="company-work-hours-list-wrapper">
        {companyProfile?.workingHours?.map((res, id) => (
          <CompanyHourCard data={res} isDark={isDark} />
        ))}
      </div>
      {show && (
        <SetWorkingHoursModal
          show={show}
          handleCloseModal={() => setShow(false)}
          isDark={isDark}
        />
      )}
    </div>
  );
};

export default CompanyHours;
