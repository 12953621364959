import { PMBaseApi } from "../../services/PMBaseApi";

export const memberSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting member listings
    getMemberListing: builder.query({
      query: ({ type }) => ({
        url: `teams/members-listing?type=${type}`, // API endpoint
        method: "POST",
      }),
      transformResponse: (response) =>
        response?.data
          ?.map((memberInfo) => {
            const userInfo = memberInfo?.userInfo;
            return {
              userId: userInfo?.userId,
              userName: userInfo?.userName,
              userEmail: userInfo?.userEmail,
              profileImage: userInfo?.profileImage,
              projectCount: memberInfo?.projectCount,
              taskCount: memberInfo?.taskCount,
              memberProgress: memberInfo?.memberProgress,
              recentTaskCount: memberInfo?.recentTaskCount,
              updatedAt: memberInfo?.updatedAt,
            };
          })
          .filter((member) => member.userId), // Filter out members without a userId
      providesTags: ["memberListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Define the query for getting member listings
    getUserListing: builder.query({
      query: () => ({
        url: `teams/pm-users-listing`, // API endpoint
        method: "GET",
      }),
      providesTags: ["userTaskListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to clear all cached data
    clearAllCache: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(memberSlice.util.resetApiState());
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),
  }),
});

// Export the hooks generated by `memberSlice`
export const {
  useGetMemberListingQuery,
  useGetUserListingQuery,
  useClearAllCacheMutation,
} = memberSlice;
