import React, { useEffect, useRef, useState } from "react";
import {
  dateFormatter,
  formatDateToYYYYMMDD,
} from "../../../helper/dateFormatter";
import { formatLabel } from "../../../helper/taskHelpers";
import { IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import { Spinner, ButtonGroup, ToggleButton } from "react-bootstrap";
import API, { ProjectAPI } from "../../../services/ClientApi";
import { toast } from "react-toastify";
import upIcon from "../../../assets/performance_up_icon.png";
import downIcon from "../../../assets/performance_down_icon.png";
import { useIsMobile } from "../../../helper/windowHelper";
import { BottomSheet } from "react-spring-bottom-sheet";
import Calendar from "react-calendar";

function StaffSummaryFilter({
  props,
  filterType,
  setFilterType,
  selectedDateFilter,
  setSelectedDateFilter,
  refreshFilters,
  searchText,
  setSearchText,
  handleDownloadExcel,
  excelLoad,
  staffSummaryDataRefetch,
  isFetching,
  sortOrder,
  setSortOrder,
  setShowPerformanceDownloadModal,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  bottomSheetOpen,
  setBottomSheetOpen,
  view,
  setView,
}) {
  const [shareReportLoading, setShareReportLoading] = useState(false);
  const [localFilterType, setLocalFilterType] = useState(filterType);
  const toggleOptions = [
    { name: "List View", value: 1, icon: "prime:list" },
    { name: "Grid View", value: 2, icon: "mingcute:grid-fill" },
  ];

  return (
    <div className="d-flex align-items-md-center justify-content-between mb-3 flex-md-row flex-column ">
      <div className="d-flex align-items-center flex-1 flex-wrap justify-content-start mb-md-0 mb-4 gap-md-0 gap-2">
        {/* filters for laptop screen */}
        <div className="col-md-3 col-12 mb-md-0 mb-3">
          <div className="form-group">
            <div className="advanced-input-search position-relative">
              <input
                placeholder="Search here"
                className="w-100"
                type="text"
                value={searchText}
                name={"search"}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                autoComplete="off"
              />
              <div className="position-absolute advanced-input-search-icon">
                <Icon icon="wpf:search" />
              </div>
            </div>
          </div>
        </div>

        {/** performance filter button  */}
        <button
          style={{
            border: "1px solid var(--divider-color)",

            height: "45px",
            borderColor:
              sortOrder === "ascending" || sortOrder === "descending"
                ? "var(--icon-color2)"
                : "var(--divider-color)",
            backgroundColor: "var(--card-color)",
          }}
          className="d-flex p-2 rounded-1 align-items-center  ms-md-3 h-100 gap-2"
          onClick={() => {
            if (sortOrder === "normal") {
              setSortOrder("ascending");
            } else if (sortOrder === "ascending") {
              setSortOrder("descending");
            } else {
              setSortOrder("normal");
            }
          }}
        >
          <img width={24} height={24} src={downIcon} alt="Performance Down" />
          <img width={24} height={24} src={upIcon} alt="Performance Up" />
        </button>

        <ButtonGroup className="ms-md-3 team-summery-list-type-switch">
          {toggleOptions?.map((option, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={view === option.value ? "primary" : "light"}
              name="view"
              value={option.value}
              checked={view === option.value}
              onChange={(e) => {
                setView(Number(e.currentTarget.value));
              }}
              className="d-flex align-items-center px-2"
            >
              <Icon icon={option.icon} width={24} />
            </ToggleButton>
          ))}
        </ButtonGroup>

        <div className="ms-md-4 ms-1 task-main-info-list justify-content-between">
          <ul>
            <li>
              <DatePickerSection
                bottomSheetOpen={bottomSheetOpen}
                setBottomSheetOpen={setBottomSheetOpen}
                setLocalFilterType={setLocalFilterType}
                setFilterType={setFilterType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setSelectedDateFilter={setSelectedDateFilter}
                localFilterType={localFilterType}
                selectedDateFilter={selectedDateFilter}
                isDark={props?.isDark}
              />
            </li>
            <li
              className="filter-refresh-icon d-flex flex-nowrap"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Reset filters"
            >
              <Icon
                icon="humbleicons:refresh"
                onClick={() => {
                  refreshFilters();
                  setLocalFilterType("all");
                }}
              />{" "}
              <span
                className="refresh-text"
                onClick={() => {
                  refreshFilters();
                  setLocalFilterType("all");
                }}
              >
                Clear
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex align-items-md-center gap-3 justify-content-md-center justify-content-md-end">
        <button
          disabled={isFetching || shareReportLoading}
          onClick={async () => {
            try {
              setShareReportLoading(true);
              const res = await ProjectAPI.get(
                `/task/daily-staff-summary?filterType=${filterType}${
                  filterType === "date"
                    ? `&selectedDate=${formatDateToYYYYMMDD(
                        selectedDateFilter
                      )}`
                    : ""
                }&sendEmail=true`
              );

              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 300,
                hideProgressBar: true,
                closeButton: false,
              });
            } catch (error) {
              toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 300,
                hideProgressBar: true,
                closeButton: false,
              });
            } finally {
              setShareReportLoading(false);
            }
          }}
          className="download-excel-btn d-flex align-items-center"
        >
          <i className="fas fa-share" style={{ marginRight: "8px" }}></i>
          Share Report
          {shareReportLoading ? (
            <Spinner size="sm" className="ms-1 global-spinner" />
          ) : (
            ""
          )}{" "}
        </button>
        <button
          className="download-excel-btn d-flex align-items-center"
          onClick={() => setShowPerformanceDownloadModal(true)}
          disabled={excelLoad}
        >
          <i className="fa fa-download m-0" aria-hidden="true"></i>
          {excelLoad ? (
            <Spinner size="sm" className="ms-1 global-spinner" />
          ) : (
            ""
          )}
        </button>
      </div>
    </div>
  );
}

const DatePickerSection = ({
  bottomSheetOpen,
  setBottomSheetOpen,
  setLocalFilterType,
  setFilterType,
  setStartDate,
  setEndDate,
  setSelectedDateFilter,
  localFilterType,
  selectedDateFilter,
  isDark,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const { isMobile } = useIsMobile();

  const shortcutsItems = [
    {
      label: "Tasks of This Week",
      getValue: () => handleFilterChange("this_week"),
      value: "this_week",
    },
    {
      label: "Tasks of This Month",
      getValue: () => handleFilterChange("this_month"),
      value: "this_month",
    },
    {
      label: "Tasks of Last Month",
      getValue: () => handleFilterChange("last_month"),
      value: "last_month",
    },
    {
      label: "Date Range",
      getValue: () => handleFilterChange("date_range"),
      value: "date_range",
    },
  ];

  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
    e.preventDefault();
    if (!isMobile) {
      setShowDropdown(!showDropdown);
    } else {
      setBottomSheetOpen(true);
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (type) => {
    if (type === "date_range") {
      setLocalFilterType(type);
      if (isMobile) {
        setBottomSheetOpen(true);
      }
    } else {
      setLocalFilterType(type);
      setFilterType(type);
      setStartDate(null);
      setEndDate(null);
      setSelectedDateFilter(null); // Reset selected date for other filters
      setShowDropdown(false); // Close dropdown

      setBottomSheetOpen(false);
    }
  };

  return (
    <div
      data-theme={isDark}
      className="project-details-main-date-filter d-flex align-items-center justify-content-between position-relative"
      onClick={handleIconClick}
    >
      {(localFilterType !== "all" || selectedDateFilter) && (
        <div className="selected-filter-text-container-date">
          <Icon
            icon="mdi:cross-circle"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDateFilter(null);
              setStartDate(null);
              setEndDate(null);
              setFilterType("all");
              setLocalFilterType("all");
            }}
          />
        </div>
      )}
      {/* Icon */}
      <div
        className={`d-flex align-items-center ps-2 select-item-date ${
          localFilterType === "all" ? "no-selected-item-date" : ""
        }`}
      >
        <span>
          <Icon icon="lets-icons:date-range" ref={iconRef} />
        </span>
        <p className="p-0 m-0">
          {localFilterType === "all"
            ? "Due date"
            : localFilterType === "date"
            ? dateFormatter(selectedDateFilter, 1)
            : formatLabel(localFilterType)}
        </p>
        <div className="select-icon me-1 select-icon-date">
          <IoIosArrowDown />
        </div>
      </div>

      {/* Dropdown */}
      {isMobile ? (
        <BottomSheet
          open={bottomSheetOpen}
          blocking={true}
          onDismiss={() => setBottomSheetOpen(false)}
        >
          <div className="bottom-sheet-content" data-theme={isDark}>
            {/* Shortcuts Column */}
            <div className="custom-date-picker-dropdown-shortcuts-mobile">
              {shortcutsItems.map((shortcut) => (
                <button
                  key={shortcut.label}
                  className={`${
                    localFilterType === shortcut.value
                      ? "selected-shortcut-mobile"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent closing the BottomSheet
                    shortcut.getValue();
                  }}
                >
                  {shortcut.label}
                </button>
              ))}
            </div>

            {/* Date Picker Column */}
            <Calendar
              onChange={(date) => {
                if (localFilterType === "date_range" && date[0] && date[1]) {
                  setSelectedDateFilter(date);
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                  setFilterType("date_range");
                  setLocalFilterType("date_range");
                  setBottomSheetOpen(false);
                  console.log("====================================");
                  console.log("bottomSheetOpen", "date is picked");
                  console.log("====================================");
                } else if (localFilterType !== "date_range") {
                  setSelectedDateFilter(date);
                  setFilterType("date");
                  setLocalFilterType("date");
                  setBottomSheetOpen(false);
                  console.log("====================================");
                  console.log("bottomSheetOpen", "date is picked");
                  console.log("====================================");
                }
              }}
              selectRange={localFilterType === "date_range"}
              value={selectedDateFilter}
              className="react-calendar react-calender-mobile"
            />
          </div>
        </BottomSheet>
      ) : (
        showDropdown && (
          <div
            ref={dropdownRef}
            className="dropdown-menu py-0 show d-flex custom-date-picker-dropdown"
            // Prevent event bubbling up from inside dropdown
            onClick={(e) => e.stopPropagation()}
          >
            {/* Shortcuts Column */}
            {shortcutsItems?.length > 0 && (
              <div className="custom-date-picker-dropdown-shortcuts">
                {shortcutsItems.map((shortcut) => (
                  <button
                    key={shortcut.label}
                    className={`btn btn-link text-start position-relative p-0 ${
                      localFilterType === shortcut.value
                        ? "selected-shortcut"
                        : ""
                    }`}
                    onClick={() => {
                      shortcut.getValue();
                    }}
                  >
                    {shortcut.label}
                    {localFilterType === shortcut.value &&
                      localFilterType === "date_range" && (
                        <div
                          style={{
                            top: "30%",
                          }}
                          className="selected-filter-text-container-date"
                        >
                          <Icon
                            icon="mdi:cross-circle"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedDateFilter(null);
                              setStartDate(null);
                              setEndDate(null);
                              setFilterType("all");
                              setLocalFilterType("all");
                            }}
                          />
                        </div>
                      )}
                  </button>
                ))}
              </div>
            )}
            {/* Date Picker Column */}
            {/* <div
                  data-theme={props.isDark}
                  className="project-details-main-date-filter d-flex align-items-center justify-content-between"
                > */}
            <Flatpickr
              data-theme={isDark}
              className="project-details-task-table-flatpickr-container"
              placeholder="MMM DD, YYYY"
              options={{
                allowInput: false, // Disable manual input
                altInput: false, // Prevent rendering of alternative input
                inline: true, // Keep calendar always visible
                altFormat: "M d, Y",
                dateFormat: "Y-m-d",
                static: true,
                disableMobile: true,
                clickOpens: false, // Optional: Disable opening on input click
                mode: localFilterType === "date_range" ? "range" : "single",
                maxDate: new Date(),
              }}
              value={selectedDateFilter}
              onChange={(date) => {
                if (localFilterType === "date_range" && date[0] && date[1]) {
                  setSelectedDateFilter(date);
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                  setFilterType("date_range");
                  setLocalFilterType("date_range");
                } else if (localFilterType !== "date_range") {
                  setSelectedDateFilter(date[0]);
                  setFilterType("date");
                  setLocalFilterType("date");
                }
              }}
            />
          </div>
          // </div>
        )
      )}
    </div>
  );
};

export default StaffSummaryFilter;
