import {
  TIMELINE_STATS_REQUEST,
  TIMELINE_STATS_SUCCESS,
  TIMELINE_STATS_FAIL,
  TIMELINE_ACTIVE_PROJECTS_FAIL,
  TIMELINE_ACTIVE_PROJECTS_REQUEST,
  TIMELINE_ACTIVE_PROJECTS_SUCCESS,
  TIMELINE_ONGOING_TASK_REQUEST,
  TIMELINE_ONGOING_TASK_SUCCESS,
  TIMELINE_ONGOING_TASK_FAIL,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS,
  TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL,
  TIMELINE_ONGOING_TASK_UPDATE_FAIL,
  TIMELINE_ONGOING_TASK_UPDATE_REQUEST,
  TIMELINE_ONGOING_TASK_UPDATE_SUCCESS,
} from "../constants/timelineConstants";

// Timeline stats
export const timelineStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMELINE_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TIMELINE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        timelineStats: action.payload,
      };
    case TIMELINE_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Timeline active projects
export const timeActiveProjectsReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMELINE_ACTIVE_PROJECTS_REQUEST:
      return {
        ...state,
        aploading: true,
      };
    case TIMELINE_ACTIVE_PROJECTS_SUCCESS:
      return {
        ...state,
        aploading: false,
        timelineActiveProjects: action.payload,
      };
    case TIMELINE_ACTIVE_PROJECTS_FAIL:
      return {
        ...state,
        aploading: false,
        error: action.payload,
      };
    case TIMELINE_ACTIVE_PROJECTS_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TIMELINE_ACTIVE_PROJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        timelineActiveProjects: state.timelineActiveProjects.map((project) =>
          project.projectId === action.payload.projectId
            ? action.payload
            : project
        ),
      };
    case TIMELINE_ACTIVE_PROJECTS_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

// Timeline ongoing tasks
export const timelineOngoingTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMELINE_ONGOING_TASK_REQUEST:
      return {
        ...state,
        otloading: true,
      };
    case TIMELINE_ONGOING_TASK_SUCCESS:
      return {
        ...state,
        otloading: false,
        timelineOngoingTasks: action.payload,
      };
    case TIMELINE_ONGOING_TASK_FAIL:
      return {
        ...state,
        otloading: false,
        error: action.payload,
      };
    case TIMELINE_ONGOING_TASK_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TIMELINE_ONGOING_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        timelineOngoingTasks: state?.timelineOngoingTasks?.map((task) =>
          task.id === action.payload.id ? action.payload : task
        ),
      };
    case TIMELINE_ONGOING_TASK_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};
