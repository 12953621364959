/* eslint-disable no-unused-vars */
// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getRetryModal } from "../components/RetryModal/RetryContext";

export const baseUrl = process.env.REACT_APP_BACKEND_API;

// Define a service using a base URL and expected endpoints
export const ADMBaseApi = createApi({
  reducerPath: "ADMapi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("access_token");
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["companyProfile"],
  endpoints: (builder) => ({}),
});
