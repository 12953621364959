/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import CommentBox from "./CommentBox";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { Spinner } from "react-bootstrap";
import LogBox from "./LogBox";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInTaskOne } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import { ProjectAPI, ProjectFormDataAPI } from "../../../services/ClientApi";
import CommentMainIcon from "../../../assets/comment-icons/comment-main.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import BulbIcon from "../../../assets/bulb.png";
import TiptapEditor from "./TiptapEditor";
import { calculateTotalSize } from "../../Tasks/components/CreateTaskModal";
import { dateFormatter } from "../../../helper/dateFormatter";

const TaskDetailCommentSection = ({
  selectedTask,
  taskData,
  userProfile,
  isDark,
}) => {
  const [commentType, setCommentType] = useState(0);
  const [visibleComments, setVisibleComments] = useState(10); // Number of comments to show initially
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [commentLoad, setCommentLoad] = useState(false);
  const [logs, setLogs] = useState([]);
  const [comLoad, setComLoad] = useState(false);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);

  // state for showing the comment editor
  const [showEditor, setShowEditor] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const searchRef = useRef();
  // states for comments search
  const [filteredComments, setFilteredComments] = useState([]);
  const [isSearchButtonActive, setIsSearchButtonActive] = useState(false);

  // File validation logic
  const allowedExtensions = [
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "doc",
    "docx",
    "txt",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "gif",
    "bmp",
    "svg",
    "zip",
    "rar",
    "mp3",
    "mp4",
  ];
  const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(
        `Invalid file type: .${fileExtension}. Only the following are allowed: ${allowedExtensions.join(
          ", "
        )}.`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return false;
    }
    if (file.size > maxFileSize) {
      toast.error(`File size exceeds the limit of 8 MB.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const totalSize = calculateTotalSize(files);

    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const validFiles = files.filter((file) => validateFile(file));
    if (validFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...validFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCommentType = (value) => {
    setCommentType(value);
  };

  const handleLoadMore = () => {
    setVisibleComments(comments.length); // Show all comments
  };

  // const handleShowLess = () => {
  //   setVisibleComments(4); // Reset to show only 4 comments
  // };

  const flattenReplies = (comments) => {
    const flattenCommentReplies = (comment) => {
      const allReplies = [];

      // Recursive function to collect replies from all levels
      const gatherReplies = (replies) => {
        replies.forEach((reply) => {
          // Add the reply to the allReplies array
          allReplies.push({
            comId: reply.comId,
            userId: reply.userId,
            fullName: reply.fullName,
            avatarUrl: reply.avatarUrl,
            text: reply.text,
            timestamp: reply.timestamp,
            isEdited: reply?.isEdited,
            attachments: reply?.attachments,
          });

          // If this reply has nested replies, gather them recursively
          if (reply.replies && reply.replies.length > 0) {
            gatherReplies(reply.replies);
          }
        });
      };

      // Start gathering replies from the comment's own replies
      gatherReplies(comment.replies);

      // Return a new comment object with flattened replies array
      return {
        ...comment,
        replies: allReplies,
      };
    };

    // Process each comment at the top level
    return comments.map(flattenCommentReplies);
  };
  const transformCommentsData = (comments) => {
    const newComments = comments.map((comment) => ({
      comId: comment.id,
      userId: comment.userDetails.userId,
      fullName: comment.userDetails.userName,
      avatarUrl: comment.userDetails.profileImage,
      text: comment.commentText,
      createdAt: comment.createdDate,
      timestamp: comment.createdDate,
      isEdited: comment?.isEdited,
      attachments: comment?.attachments,
      replies: comment.children ? transformCommentsData(comment.children) : [],
    }));
    return flattenReplies(newComments);
  };

  const getComments = async () => {
    setComLoad(true);
    try {
      const commentsResponse = await ProjectAPI.post("task/comments", {
        taskId: selectedTask,
      });
      const logResponse = await ProjectAPI.post("task/history-listing", {
        taskId: selectedTask,
      });

      const modifiedLogs = logResponse?.data?.data?.map((obj) => ({
        ...obj,
        type: "log",
      }));
      setLogs(modifiedLogs);

      const transformedComments = transformCommentsData(
        commentsResponse?.data?.data || []
      );
      setComments(transformedComments);
    } catch (error) {
      console.error("Failed to fetch comments or logs", error);
    } finally {
      setComLoad(false);
    }
  };

  const handleFileChange = (files) => {
    const acceptedFiles = Array.from(files);

    const totalSize = calculateTotalSize(acceptedFiles);
    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

    if (filteredFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...filteredFiles]);
    }
  };

  // Add comment for task
  const handleComment = async (e) => {
    try {
      setCommentLoad(true);

      // Create a FormData object
      const formData = new FormData();
      formData.append("taskId", selectedTask);
      formData.append("comments", e);
      formData.append("mentionedUsersIds", mentionedUserIds?.join(","));

      // Append each file to the FormData object
      selectedFiles.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      const response = await ProjectFormDataAPI.post(
        "task/add-comment",
        formData
      );

      const newComment = {
        comId: response?.data?.data?.id,
        userId: response.data.data.userDetails.userId,
        fullName: response.data.data.userDetails.userName,
        avatarUrl: response.data.data.userDetails.profileImage,
        text: response.data.data.commentText,
        createdAt: response.data.data.createdDate,
        timestamp: response.data.data.createdDate,
        isEdited: response.data.data?.isEdited,
        replies: [],
        attachments: response?.data?.data?.attachments || [], // Attach files to the comment
      };

      setComments([newComment, ...comments]);
      toast.success("Comment added successfully.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.error("Comment not added.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        // hideProgressBar: true,
        // closeButton: false,
      });
    } finally {
      setCommentLoad(false);
      setShowEditor(false);
      setMentionedUserIds([]);
      setSelectedFiles([]);
      setIsSearchButtonActive(false);
      setCommentContent("");
    }
  };

  const getInitials = (name) => {
    const words = name?.split(" ");
    let initials;
    if (words?.length === 1) {
      initials = name?.substring(0, 2)?.toUpperCase();
    } else {
      initials = words?.map((word) => word?.charAt(0)?.toUpperCase())?.join("");
      initials = initials?.substring(0, 2);
    }
    return initials;
  };

  useEffect(() => {
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const highlightText = (htmlText, searchText) => {
    if (!searchText) {
      return <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
    }

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlText; // Convert the HTML string into DOM elements

    const regex = new RegExp(`(${searchText})`, "gi"); // Case-insensitive search

    const walkNodes = (node) => {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
        // Only modify pure text nodes
        const parent = node.parentNode;
        const newText = node.nodeValue.replace(
          regex,
          (match) => `<span style="background-color: yellow; color: black
          ">${match}</span>`
        );
        const span = document.createElement("span");
        span.innerHTML = newText;
        parent.replaceChild(span, node);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Process all elements, including mentions, but modify only their text
        Array.from(node.childNodes).forEach(walkNodes);
      }
    };

    walkNodes(tempDiv);

    return <span dangerouslySetInnerHTML={{ __html: tempDiv.innerHTML }} />;
  };

  useEffect(() => {
    if (isSearchButtonActive && commentContent !== "") {
      const searchText = commentContent;
      console.log("comments", comments);
      const filtered = comments.filter((comment) => {
        // Strip HTML tags from the main comment text

        const mainCommentText = comment.text
          .replace(/<[^>]+>/g, "")
          .toLowerCase();
        const mainCommentMatch = mainCommentText.includes(
          searchText.toLowerCase()
        );

        // Check if any child comment contains the search content
        const childCommentMatch = comment.replies.some((child) => {
          const childCommentText = child.text
            .replace(/<[^>]+>/g, "")
            .toLowerCase();
          return childCommentText.includes(searchText.toLowerCase());
        });

        return mainCommentMatch || childCommentMatch;
      });

      setFilteredComments(filtered);
    } else {
      setFilteredComments(comments);
    }
  }, [isSearchButtonActive, commentContent, comments]);

  useEffect(() => {
    if (isSearchButtonActive) {
      setCommentContent("");
      searchRef && searchRef?.current && searchRef.current.focus();
    }
  }, [isSearchButtonActive]);

  return (
    <div className="comment-project-list-wrap">
      <div className="d-flex align-items-center gap-4 flex-wrap justify-content-between">
        <ul className="mb-0 ps-0">
          <li>
            {" "}
            <p
              className={`comment-head-txt ms-0 mb-0`}
              onClick={() => handleCommentType(0)}
            >
              {" "}
              Comments ({comments?.length}){" "}
            </p>{" "}
          </li>
          {(isPermitted("task/history-listing") || isAdmin(userProfile)) && (
            <li>
              {" "}
              <Icon
                icon="octicon:history-24"
                onClick={() => handleCommentType(1)}
                className="ms-2 history-icon mb-1"
                fontSize={20}
              />{" "}
            </li>
          )}
        </ul>
      </div>
      {commentType !== 2 && (
        <div
          className={`comment-field-wrap d-flex ${
            userProfile?.profileImage ? "no-bg-comment" : ""
          }`}
        >
          {userProfile?.profileImage ? (
            <div className="comment-field-icon p-0">
              <img src={userProfile?.profileImage} alt="profile-icon"></img>
            </div>
          ) : (
            <div className="comment-field-icon">
              {getInitials(userProfile?.userName)}
            </div>
          )}

          <div className="comment-field-text">
            {!showEditor && (
              <input
                type="text"
                value={comment}
                autoComplete="off"
                className="form-control"
                placeholder={
                  !(isPermitted("task/add-comment") || isAdmin(userProfile))
                    ? "You do not have the permission to comment on tasks."
                    : "Add a comment"
                }
                disabled={
                  commentLoad ||
                  commentType === 2 ||
                  !(isPermitted("task/add-comment") || isAdmin(userProfile))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value?.length > 0) {
                      handleComment(e.target.value);
                    }
                  }
                }}
                onChange={(e) => setComment(e.target.value)}
                onClick={() => {
                  if (isPermitted("task/add-comment") || isAdmin(userProfile)) {
                    setShowEditor(true);
                  }
                }}
              />
            )}
            {showEditor &&
              (!isSearchButtonActive ? (
                <div
                  onDrop={handleFileDrop}
                  onDragOver={handleDragOver}
                  className="w-100 comment-editor-mce"
                >
                  <TiptapEditor
                    onChange={(content) => setCommentContent(content)}
                    disabled={commentLoad}
                    onKeyDown={() => {
                      handleComment(commentContent);
                    }}
                    setMentionedUserIds={setMentionedUserIds}
                    onFileChange={handleFileChange}
                    isFileUpload={true}
                    isSearchButton={true} // to show the button or not
                    isSearchButtonActive={isSearchButtonActive} // when user click on the button then it will be active
                    handleSearch={() => {
                      setCommentType(0);
                      setIsSearchButtonActive(!isSearchButtonActive);
                    }}
                  />
                  <div className="w-100 flex-md-row flex-column d-flex align-items-md-center align-items-end justify-content-between py-2">
                    <p className="align-self-start mb-0 ctrl-enter-text">
                      <img
                        src={BulbIcon}
                        alt="bulb-icon"
                        width={24}
                        height={24}
                      />{" "}
                      Ctrl+Enter to "add comment"
                    </p>

                    <div className="d-flex align-items-center gap-2">
                      <button
                        onClick={async () => {
                          if (
                            commentContent?.trim() !== "" ||
                            selectedFiles?.length !== 0
                          ) {
                            handleComment(commentContent);
                          }
                        }}
                        className="comment-field-submit-btn comment-field-post-button"
                        disabled={
                          commentLoad ||
                          commentType === 2 ||
                          !(
                            isPermitted("task/add-comment") ||
                            isAdmin(userProfile)
                          )
                            ? true
                            : false
                        }
                      >
                        Post{" "}
                        {(commentLoad || commentType === 2) && (
                          <span>
                            {" "}
                            <Spinner size="sm" className="ms-2" />
                          </span>
                        )}
                      </button>
                      <button
                        className="comment-field-cancel-btn comment-field-submit-btn"
                        onClick={() => {
                          setShowEditor(false);
                          setMentionedUserIds([]);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-100 position-relative">
                  <input
                    type="text"
                    // value={comment}
                    ref={searchRef}
                    autoComplete="off"
                    className="form-control"
                    placeholder="Search Comments"
                    value={commentContent}
                    onChange={(e) => setCommentContent(e.target.value)}
                  />
                  <span
                    className="custom-user-search-icon-comment"
                    onClick={() => {
                      setIsSearchButtonActive(false);
                      setCommentContent("");
                    }}
                  >
                    &#x2715;
                  </span>
                </div>
              ))}
          </div>
          <div className="clearfix"></div>
        </div>
      )}
      {selectedFiles.length > 0 && (
        <div className="create-task-modal-uploaded-files">
          <h6>Attached Files with comments</h6>
          {selectedFiles.map((file, index) => (
            <div key={index} className="file-item-one">
              <span>
                <Icon
                  icon="mingcute:check-fill"
                  className="me-1"
                  width={16}
                  height={16}
                />{" "}
                {file.name} ({(file.size / 1024).toFixed(2)} KB)
              </span>

              <button
                className="remove-uploaded-file-button"
                onClick={() => {
                  setSelectedFiles((prev) =>
                    prev.filter((_, i) => i !== index)
                  );
                }}
              >
                <Icon icon="maki:cross" />
              </button>
            </div>
          ))}
        </div>
      )}
      {comLoad ? (
        <div className="w-100 mt-4">
          <ShimmerCategoryList title items={1} categoryStyle="STYLE_SEVEN" />
        </div>
      ) : (
        <>
          {commentType === 0 && (
            <div className="d-flex flex-column w-100 log-main-container custom-horizontal-scrollbar">
              {/* <div className="d-flex align-items-center gap-2 log-main-container-header">
                <img
                  src={CommentMainIcon}
                  alt="log-icon"
                  width={40}
                  height={40}
                ></img>
                <p className="mb-0">Comments</p>
              </div> */}
              {filteredComments?.length === 0 ? (
                <p className="mb-0" style={{ marginLeft: "3.5rem" }}>
                  No comments posted yet.
                </p>
              ) : (
                <>
                  {/* Slice the comments array to only show the number of visible comments */}
                  {filteredComments
                    .slice(0, visibleComments)
                    .map((comment, id) => (
                      <CommentBox
                        key={id}
                        comment={comment}
                        taskId={selectedTask}
                        comments={filteredComments}
                        setComments={setComments}
                        isDark={isDark}
                        islog={commentType === 0}
                        logs={logs}
                        userId={userProfile?.userId}
                        isOwner={isOwnerInTaskOne(
                          taskData,
                          userProfile?.userId
                        )}
                        isAdmin={isAdmin(userProfile)}
                        selectedTask={selectedTask}
                        commId={comment?.comId}
                        setMentionedUserIds={setMentionedUserIds}
                        mentionedUserIds={mentionedUserIds}
                        highlightText={highlightText}
                        commentContent={commentContent}
                      />
                    ))}
                  {/* Show "Load More" button only if there are more comments to load */}
                  {visibleComments < filteredComments.length &&
                    filteredComments.length > 4 && (
                      <p
                        className="load-more-comment mt-2"
                        style={{ marginLeft: "3.3rem" }}
                        onClick={handleLoadMore}
                      >
                        Load More Comments{" "}
                        <Icon icon="lsicon:down-outline" fontSize={16} />
                      </p>
                    )}
                  {visibleComments === filteredComments.length &&
                    filteredComments.length > 4 && (
                      <p
                        className="load-more-comment mt-2"
                        style={{ marginLeft: "3.3rem" }}
                        onClick={() => {
                          setVisibleComments(4);
                        }}
                      >
                        Load Less Comments{" "}
                        <Icon icon="lsicon:up-outline" fontSize={16} />
                      </p>
                    )}
                </>
              )}
            </div>
          )}
          {commentType === 1 && (
            <div className="d-flex flex-column w-100 log-main-container">
              <div className="d-flex align-items-center gap-2 log-main-container-header">
                <img
                  src={CommentMainIcon}
                  alt="log-icon"
                  width={40}
                  height={40}
                ></img>
                <p className="mb-0">Task Activity</p>
              </div>
              {logs?.length === 0 ? (
                <p className="mt-2" style={{ marginLeft: "3.5rem" }}>
                  No logs found.
                </p>
              ) : (
                logs?.map((log, id) => (
                  <LogBox key={id} log={log} isDark={isDark} />
                ))
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TaskDetailCommentSection;
