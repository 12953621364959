import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddStageModal from "../../Projects/components/AddStageModal";
import KanbanView from "../../Projects/components/KanbanView";
import ProjectNewModal from "../../Projects/components/ProjectNewModal";
import { dndData } from "../../Projects/components/dnd/mockData";
import ProjectDetailFilterSection from "./ProjectDetailFilterSection";
import ProjectDetailTaskTable from "./ProjectDetailTaskTable";
import { Dropdown, Spinner } from "react-bootstrap";
import Delete from "../../../assets/delete.svg";
import EditIcon from "../../../assets/edit-project.svg";
import {
  useGetPhaseTaskListingQuery,
  useUpdatePhaseTaskDataMutation,
  useUpdatePhaseTaskMemberDataMutation,
} from "../../../slices/tasks/projectDetailSectionSlice";
import {
  getQueryVariable,
  getTasksAllPhases,
  toCamelCase,
} from "../services/ProjectDetailsMainServices";
import PlanProjectPhaseMain from "./PlanProjectPhases/PlanProjectPhaseMain";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Pagination } from "react-bootstrap";
import Select, { components } from "react-select";
import { IoIosArrowDown, IoMdArrowRoundBack } from "react-icons/io";
import {
  projectFormListingAction,
  projectListingAction,
  projectListingStatusAction,
  projectTypeListingAction,
  projectUserListingAction,
} from "../../../actions/projectActions";
import {
  taskPrioritiesListAction,
  taskTypesListAction,
} from "../../../actions/tasksActions";
import TasksAdvanceSearchModalMain from "../../Tasks/components/TasksAdvanceSearchModal/TasksAdvanceSearchModalMain";
import { dateFormatter } from "../../../helper/dateFormatter";
import { Icon } from "@iconify/react";
import ProjectDrive from "./ProjectDrive";
import { Tooltip } from "react-tooltip";
import ConfirmDeleteProjectModal from "../../Projects/components/ConfirmDeleteProjectModal";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import API, { ProjectAPI } from "../../../services/ClientApi";
import ProjectEditModal from "../../Projects/components/ProjectEditModal";
import { isPermitted } from "../../../helper/isPermitted";
import { isOwnerInProjectOne } from "../../../helper/projectOwner";
import { isAdmin } from "../../../helper/isUserAdmin";
import { ShimmerButton, ShimmerTable } from "react-shimmer-effects";
import getProjectIconById from "../../../helper/projectIconGenerator";
import CreateIcon from "../../../assets/create-task-icon.png";
import CreateTaskModal from "../../Tasks/components/CreateTaskModal";
import ProjectDetailSelectedSection from "./ProjectDetailSelectedSection";
import DeleteSelectedTasksModal from "../../Tasks/components/DeleteSelectedTasksModal";
import { toast } from "react-toastify";
import { downloadTaskDataAsExcel } from "../../../helper/downloadFileHelpers";
import TaskDetailOffCanvas from "../../Tasks/components/TaskDetailOffCanvas";
import ConfirmSureDeleteProjectModal from "../../Projects/components/ConfirmSureDeleteProjectModal";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { userProfilePage } from "../../../actions/userActions";
import { calculateAverageProgress } from "../../../helper/taskHelpers";
import { isTrialActive } from "../../../helper/subscriptionHelpers";
import PlanIcon from "../../../assets/project-plan-icon.png";
import { useGetUserListingQuery } from "../../../slices/members/membersSlice";

const ProjectDetailMain = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [wasUnread, setWasUnread] = useState(false);
  const [updatePhaseTaskData, { isLoading: isUpdating }] =
    useUpdatePhaseTaskDataMutation();
  const [
    updatePhaseTaskMemberData,
    { isLoading: isMemberLoading, isSuccess: memberSuccess },
  ] = useUpdatePhaseTaskMemberDataMutation();
  const [newId, setNewId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [updateTaskCanvas, setUpdateTaskCanvas] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [showStage, setShowStage] = useState(false);
  const [projName, setProjName] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [updateLoad, setUpdateLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [kanban, setKanban] = useState(false);
  const [onKanban, setOnKanBan] = useState(false);
  const [loadKanban, setLoadKanban] = useState(false);
  const [overAllProgress, setOverAllProgress] = useState(0);
  const [newProject, setNewProject] = useState(false);
  const [tasksData, setTasksData] = useState(dndData);
  const [taskCount, setTaskCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  // const [taskList, setTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(() => {
    const savedPageSize = sessionStorage.getItem("project_detail_list_limit");
    return savedPageSize ? parseInt(savedPageSize) : 50;
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [totalTasks, setTotalTasks] = useState(0);
  const [isShowRefresh, setIsShowRefresh] = useState(false);
  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const { data: userTaskListing } = useGetUserListingQuery();

  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { projectFormList } = useSelector((state) => state.projectFormListData);

  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { userProfile } = useSelector((state) => state?.profile);
  const [filterType, setFilterType] = useState(
    userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.filterType
      ? userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.filterType
      : sessionStorage.getItem("filterType") || "today"
  );
  const { modalContents } = useSelector((state) => state?.modalData);
  const projectsDeleteModalContent = findObjectByContentTitle(
    "projects-delete-model-v3",
    modalContents?.data
  );
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const [excelLoad, setExcelLoad] = useState(false);
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);
  const { projectTypeList } = useSelector((state) => state.createProjectTypes);
  const [readLoad, setReadLoad] = useState(false);
  const [runTour, setRunTour] = useState(false);

  const [openDrive, setOpenDrive] = useState(false);

  // project phases data state
  const [projectAllPhasesTasks, setProjectAllPhasesTasks] = useState([]);

  const [loadJourney, setLoadJourney] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState("All");

  // get the pid from params
  const pid = getQueryVariable("pid");

  // Tour steps

  const steps = [
    {
      element: ".proj-detail-menu",
      intro: "Manage project details.",
      position: "bottom",
    },
    {
      element: ".create-btn",
      intro: "Create task in this project.",
      position: "right",
    },
    {
      element: ".project-main-info-laptop",
      intro: "Apply filters to find relevant records.",
      position: "right",
    },
    {
      element: ".project-drive-btn",
      intro: "Save project documents.",
      position: "left",
    },
    {
      element: ".phase-select-dropdown-journey",
      intro: "See tasks phase wise in list.",
      position: "bottom",
    },
    {
      element: ".progress-timeline",
      intro: "Project progress bar based on its tasks.",
      position: "right",
    },
    {
      element: ".plan-project-btn-icon",
      intro: "Plan your project phase wise with all its tasks.",
      position: "left",
    },
    {
      element: ".ag-row-first .ag-column-first",
      intro: "Select task to perform actions like status, priority etc.",
      position: "right",
    },
  ];

  // Advanced filter states
  const initialFormData = {
    multiStatus: [],
    multiPriority: [],
    teamsMembers:
      typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
        ?.teamsMembers === "string" &&
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.teamsMembers
        ? userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.teamsMembers?.split(
            ","
          )
        : [],
    teamsMembersTypes: [],
    taskType: [],
    taskStartDate: userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
      ?.taskStartDate
      ? new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.taskStartDate
        )
      : "",
    taskEndDate: userProfile?.userFilProjectDetail?.task?.[0]?.filterOptions
      ?.taskEndDate
      ? new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.taskEndDate
        )
      : "",
    selectedDate: userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
      ?.selectedDate
      ? new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.selectedDate
        )
      : "",
    createdAt: userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
      ?.createdAt
      ? new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.createdAt
        )
      : "",
    updatedAt: userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
      ?.updatedAt
      ? new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.updatedAt
        )
      : "",
    projectsIds: [],
    searchText:
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.searchText ||
      "",
    taskName:
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.taskName ||
      "",

    isDeleted:
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.isDeleted ||
      false,
    hasApproval:
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
        ?.hasApproval || false,
  };

  const defaultFilters = {
    status:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "In Progress"
        ? "in-progress"
        : "",
    priority:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "Critical"
        ? "critical"
        : "",
    overdue: false,
    memberFilter: "member",
    memberIds: [],
    observerIds: [],
    ownerIds: [],
    multiStatus: [],
    multiPriority: [],
    teamsMembers: [],
    teamsMembersTypes: [],
    taskType: [],
    taskStartDate: "",
    taskEndDate: "",
    selectedDate: "",
    searchText: "",
    taskName: "",
    createdAt: "",
    updatedAt: "",
    projectsIds: [],
    isDeleted: false,
    hasApproval: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [serverFilters, setServerFilters] = useState(() => {
    // Check if userProfile exists and has task filterOptions
    const userFilters =
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions || null;

    // Merge user filters with default filters
    return userFilters ? { ...defaultFilters, ...userFilters } : defaultFilters;
  });
  const [filters, setFilters] = useState({
    pinnedState: false,
    status:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "In Progress"
        ? "in progress"
        : null,
    priority:
      localStorage.getItem("taskFilter") &&
      localStorage.getItem("taskFilter") === "Citical"
        ? "critical"
        : null,
    listType:
      localStorage.getItem("taskFilter") &&
      parseInt(localStorage.getItem("taskFilter")) === 1
        ? 1
        : 0,
  });

  const {
    data: taskPhaseList,
    isLoading,
    error,
    isUninitialized,
    isFetching,
    isSuccess,
  } = useGetPhaseTaskListingQuery({
    projectId: pid,
    projectPhaseId: selectedPhaseId !== "All" ? selectedPhaseId : "all",
    page: currentPage,
    limit: pageSize,
    type: filterType,
    timeZone: userProfile?.timeZone,
    ...(() => {
      const { memberIds, ownerIds, observerIds, memberFilter, ...rest } =
        serverFilters;
      return rest; // Return the remaining properties
    })(),
    membersFilter: serverFilters.memberIds?.length
      ? serverFilters.memberIds.join(",")
      : "",
    ownersFilter: serverFilters.ownerIds?.length
      ? serverFilters.ownerIds.join(",")
      : "",
    observersFilter: serverFilters.observerIds?.length
      ? serverFilters.observerIds.join(",")
      : "",
  });
  const [taskListToFilter, setTaskListToFilter] = useState(
    taskPhaseList?.data?.map((task) => ({ ...task })) || []
  );

  useEffect(() => {
    if (isSuccess && taskPhaseList) {
      setTaskListToFilter(
        taskPhaseList?.data?.map((task) => ({ ...task })) || []
      );
    }
  }, [taskPhaseList, isSuccess]);

  // Update `teamsMembersTypes` when `projectTypeList` changes
  useEffect(() => {
    if (projectTypeList?.length > 0) {
      const teamsMembersTypes =
        typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
          ?.teamsMembersTypes === "string"
          ? projectTypeList
              .filter((item) =>
                userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.teamsMembersTypes
                  ?.split(",")
                  ?.includes(item.typeName)
              )
              .map((item) => ({
                label:
                  item.typeName.charAt(0).toUpperCase() +
                  item.typeName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, teamsMembersTypes }));
    }
  }, [projectTypeList, userProfile]);

  useEffect(() => {
    if (projectStatusList?.length > 0) {
      const multiStatus =
        typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
          ?.multiStatus === "string"
          ? projectStatusList
              .filter((item) =>
                userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.multiStatus
                  ?.split(",")
                  ?.includes(item.defaultMarkAs)
              )
              .map((item) => ({
                label:
                  item.statusName.charAt(0).toUpperCase() +
                  item.statusName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, multiStatus }));
    }
  }, [projectStatusList, userProfile]);

  useEffect(() => {
    if (taskPriorities?.length > 0) {
      const multiPriority =
        typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
          ?.multiPriority === "string"
          ? taskPriorities
              .filter((item) =>
                userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.multiPriority
                  ?.split(",")
                  ?.includes(item.defaultMarkAs)
              )
              .map((item) => ({
                label:
                  item.priorityName.charAt(0).toUpperCase() +
                  item.priorityName.slice(1),
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, multiPriority }));
    }
  }, [taskPriorities, userProfile]);

  useEffect(() => {
    if (taskTypes?.length > 0) {
      const taskType =
        typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
          ?.taskType === "string"
          ? taskTypes
              .filter((item) =>
                userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.taskType

                  ?.split(",")
                  ?.includes(item.typeName)
              )
              .map((item) => ({
                label: item.typeName,
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, taskType }));
    }
  }, [taskTypes, userProfile]);

  useEffect(() => {
    if (projectFormList?.length > 0) {
      const projectsIds =
        typeof userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
          ?.projectsIds === "string"
          ? projectFormList
              .filter((item) =>
                userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.projectsIds

                  ?.split(",")
                  ?.includes(item.id)
              )
              .map((item) => ({
                label: item.projectName,
                value: item.id,
              }))
          : [];
      setFormData((prev) => ({ ...prev, projectsIds }));
    }
  }, [projectFormList, userProfile]);

  useEffect(() => {
    if (
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
        ?.selectedDate &&
      userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions
        ?.filterType === "date"
    ) {
      setSelectedDateFilter(
        new Date(
          userProfile?.userFilters?.ProjectDetail?.[0]?.filterOptions?.selectedDate
        )
      );
      setFilterType("date");
    }
  }, [userProfile]);

  const handleDriveClose = () => {
    setOpenDrive(false);
  };

  const handleNewProjectClose = () => {
    setNewProject(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseDeleteSelected = () => {
    setDeleteSelected(false);
  };

  // Refresh filters
  const refreshFilters = async () => {
    setServerFilters({
      status: "",
      priority: "",
      overdue: false,
      memberFilter: "member",
      memberIds: [],
      observerIds: [],
      ownerIds: [],
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate: "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    });
    setFormData({
      multiStatus: [],
      multiPriority: [],
      teamsMembers: [],
      teamsMembersTypes: [],
      taskType: [],
      taskStartDate: "",
      taskEndDate: "",
      selectedDate: "",
      searchText: "",
      taskName: "",
      createdAt: "",
      updatedAt: "",
      projectsIds: [],
      isDeleted: false,
      hasApproval: false,
    });
    setSelectedDateFilter(null);
    setIsShowRefresh(false);
    localStorage.removeItem("taskFilter");
    sessionStorage.removeItem("filterType");
    setFilterType("all");
    setFilters({
      pinnedState: false,
      listType: 0,
      status: null,
      priority: null,
    });
  };

  // Manage filter count section (tasks, completed, overdue)
  const getStatusCounts = async (type) => {
    switch (type) {
      case "INCREMENT_TASK":
        setTaskCount((prev) => prev + 1);
        break;
      case "DECREMENT_TASK":
        setTaskCount((prev) => prev - 1);
        break;
      case "INCREMENT_COMPLETED":
        setCompletedCount((prev) => prev + 1);
        break;
      case "DECREMENT_COMPLETED":
        setCompletedCount((prev) => prev - 1);
        break;
      case "INCREMENT_OVERDUE":
        setOverdueCount((prev) => prev + 1);
        break;
      case "DECREMENT_OVERDUE":
        setOverdueCount((prev) => prev - 1);
        break;
      default:
        break;
    }
  };

  //all use Effects

  // Default task and detail modal states when redirected from other pages
  useEffect(() => {
    const tid = localStorage.getItem("tid");
    const oid = localStorage.getItem("oid");
    if (tid) {
      setNewId(tid);
      localStorage.removeItem("tid");
    }
    if (oid) {
      setShowTaskDetails(oid);
      localStorage.removeItem("oid");
    }
  }, []);

  // Track if filters changed to conditionally show the spinner
  // Track if filters changed to conditionally show the spinner
  const getTaskList = async (load = true) => {
    try {
      // load && setShouldShowLoading(true);
      const body = {
        projectId: pid,
        projectPhaseId: selectedPhaseId !== "All" ? selectedPhaseId : "all",
        page: currentPage,
        limit: pageSize,
        type: filterType,
        timeZone: userProfile?.timeZone,
        ...serverFilters,
      };

      // Convert specific array fields to comma-separated strings
      const updatedBody = {
        ...body,
        membersFilter: body.memberIds?.length ? body.memberIds.join(",") : "",
        ownersFilter: body.ownerIds?.length ? body.ownerIds.join(",") : "",
        observersFilter: body.observerIds?.length
          ? body.observerIds.join(",")
          : "",
      };

      // Remove original fields after conversion
      delete updatedBody.memberIds;
      delete updatedBody.ownerIds;
      delete updatedBody.observerIds;
      delete updatedBody.memberFilter;

      // Remove keys with empty string or null values
      Object.keys(updatedBody).forEach((key) => {
        if (
          updatedBody[key] === "" ||
          updatedBody[key] === null ||
          (Array.isArray(updatedBody[key]) && updatedBody[key].length === 0)
        ) {
          delete updatedBody[key];
        }
      });

      // getTaskListing(updatedBody);
    } catch (e) {
      // Handle errors if needed
      console.error("Error fetching task list:", e);
    } finally {
      // setShouldShowLoading(false);
    }
  };

  useEffect(() => {
    if (isFetching) {
      setShouldShowLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  // useEffect(() => {
  //   getTaskList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [serverFilters, filterType, currentPage, pageSize, selectedPhaseId]); // Only trigger when filters change

  // Hide the spinner after fetching completes
  useEffect(() => {
    if (!isLoading && !isUninitialized && !isFetching) {
      setShouldShowLoading(false);
    }
  }, [isLoading, isUninitialized, isFetching]);

  // Clear selected tasks on filter change
  useEffect(() => {
    setSelectedTasks([]);
  }, [serverFilters]);

  useEffect(() => {
    getAllProjectPhases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  useEffect(() => {
    getAllTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhaseId, taskPhaseList, currentPage, pageSize]);

  useEffect(() => {
    updateCountFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskListToFilter]);

  useEffect(() => {
    const users = projectUserList?.data?.map((user) => ({ ...user })) || [];
    setUserList(users);
  }, [projectUserList]);

  useEffect(() => {
    filteredListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    currentPage,
    pageSize,
    taskCount,
    overdueCount,
    completedCount,
    taskPhaseList,
  ]);

  // all handles functions
  const handleStageClose = () => {
    setShowStage(false);
  };

  // Download Excel
  const handleDownloadExcel = async () => {
    try {
      setExcelLoad(true);
      const response = await ProjectAPI.post(`project/phases-tasks`, {
        projectId: pid,
        projectPhaseId: selectedPhaseId !== "All" ? selectedPhaseId : "all",
        page: currentPage,
        limit: pageSize,
        type: filterType,
        pagination: false,
        timeZone: userProfile?.timeZone,
        ...(() => {
          const { memberIds, ownerIds, observerIds, memberFilter, ...rest } =
            serverFilters;
          return rest; // Return the remaining properties
        })(),
        membersFilter: serverFilters.memberIds?.length
          ? serverFilters.memberIds.join(",")
          : "",
        ownersFilter: serverFilters.ownerIds?.length
          ? serverFilters.ownerIds.join(",")
          : "",
        observersFilter: serverFilters.observerIds?.length
          ? serverFilters.observerIds.join(",")
          : "",
      });
      const tasks = response?.data?.data || [];

      if (tasks.length === 0) {
        toast.warn("No tasks available to download.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }

      downloadTaskDataAsExcel(tasks);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || "Failed to download tasks.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } finally {
      setExcelLoad(false);
    }
  };

  const handleCloseModal = () => {
    setShow(false);
    // phaseTaskListRefetch();
    getAllProjectPhases(false);
  };
  const handlePhaseChange = (e) => {
    if (e.target.value !== "All") {
      setSelectedPhaseId(e.target.value);
    } else {
      setSelectedPhaseId("All");
    }
    setSelectedTasks([]);
  };

  // all apis functions
  const getAllProjectPhases = async (load = true) => {
    try {
      if (pid) {
        const response = await getTasksAllPhases(pid);
        // await getTaskList();
        const presponse = await ProjectAPI.post("project/detail", {
          projectId: pid,
        });
        setProjName(presponse?.data?.data?.projectName);

        setProjectData(presponse?.data?.data);
        if (response) {
          setProjectAllPhasesTasks(response?.data?.data);
        }
      }
      if (!projectStatusList) {
        dispatch(projectListingStatusAction());
      }
    } catch (error) {
    } finally {
      if (load) {
        if (!projectUserList) {
          dispatch(projectUserListingAction());
        }
        if (!taskPriorities) {
          dispatch(taskPrioritiesListAction());
        }
        if (!taskTypes) {
          dispatch(taskTypesListAction());
        }
        if (!projectTypeList) {
          dispatch(projectTypeListingAction());
        }
        if (!projectFormList) {
          dispatch(projectFormListingAction());
        }

        dispatch(
          projectListingAction({ noLoad: false, pageSize: 100, currentPage: 1 })
        );
      }
      setLoadJourney(true);
    }
  };

  // All task in phases
  const getAllTasks = async () => {
    if (taskPhaseList?.data && taskPhaseList?.data?.length > 0) {
      const tasksArray =
        taskPhaseList?.data?.map((task) => ({ ...task })) || [];
      // setTaskList(tasksArray);
      setTaskListToFilter(tasksArray);
      setTaskCount(tasksArray?.length);
      setTotalTasks(taskPhaseList?.meta?.total);
      let currentDate = new Date();
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes()
      );
      const overdueCount = tasksArray.filter((project) => {
        let endDate = new Date(project?.taskDeadline);
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endDate.getHours(),
          endDate.getMinutes()
        );
        return (
          endDate < currentDate &&
          project?.taskStatus?.statusName?.toLowerCase() !== "completed"
        );
      })?.length;
      if (taskPhaseList?.data?.length === 0) {
        setCurrentPage(1);
      }
      setOverdueCount(overdueCount);
      const completedCount = tasksArray.filter(
        (task) => task?.taskStatus?.statusName === "Completed"
      )?.length;
      setCompletedCount(completedCount);
    }
  };

  const filteredListing = async () => {
    let filteredProjects =
      taskPhaseList?.data?.map((task) => ({ ...task })) || []; // Copy the original list

    const progress = calculateAverageProgress(filteredProjects);
    setOverAllProgress(isNaN(progress) ? 0 : progress);

    // updating show refresh icon
    if (
      filters.listType === 0 &&
      serverFilters.status === "" &&
      filters.pinnedState === false &&
      serverFilters.priority === "" &&
      serverFilters.searchText === "" &&
      serverFilters.overdue === false &&
      serverFilters.isDeleted === false &&
      serverFilters.hasApproval === false &&
      serverFilters.selectedDate === "" &&
      serverFilters.memberIds.length === 0 &&
      serverFilters.ownerIds.length === 0 &&
      serverFilters.observerIds.length === 0 &&
      serverFilters.taskName === "" &&
      serverFilters.projectsIds.length === 0 &&
      serverFilters.taskStartDate === "" &&
      serverFilters.taskEndDate === "" &&
      serverFilters.createdAt === "" &&
      serverFilters.updatedAt === "" &&
      serverFilters.taskType.length === 0 &&
      serverFilters.teamsMembers.length === 0 &&
      serverFilters.multiPriority.length === 0 &&
      // serverFilters.dueDate === null &&
      serverFilters.multiStatus.length === 0 &&
      serverFilters.teamsMembersTypes.length === 0 &&
      filterType === "all"
    ) {
      setIsShowRefresh(false);
    } else {
      setIsShowRefresh(true);
    }
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(taskPhaseList?.meta?.total / pageSize);

  // Handle pagination click
  const handlePageChange = (pageNumber) => {
    if (pageNumber === "first") {
      setCurrentPage(1);
    } else if (pageNumber === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (pageNumber === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else if (pageNumber === "last") {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  // function for update count in filters
  const updateCountFilters = () => {
    setTaskCount(taskListToFilter?.length);
    let currentDate = new Date();
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    );
    const overdueCount = taskListToFilter?.filter((project) => {
      let endDate = new Date(project?.taskDeadline);
      endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes()
      );
      return (
        endDate < currentDate &&
        project?.taskStatus?.statusName?.toLowerCase() !== "completed" &&
        project?.taskDeadline
      );
    })?.length;
    setOverdueCount(overdueCount);
    const completedCount = taskListToFilter.filter(
      (task) => task?.taskStatus?.statusName?.toLowerCase() === "completed"
    )?.length;
    setCompletedCount(completedCount);
  };

  // Page size list
  const pageSizeList = [20, 50, 100];

  const canProjectEdit =
    isOwnerInProjectOne(projectData, userProfile?.userId) &&
    isPermitted("project/pm-update-project");

  const canProjectDelete =
    isPermitted("project/pm-project-delete") &&
    isOwnerInProjectOne(projectData, userProfile?.userId);

  const canOptionsExist = canProjectEdit || canProjectDelete;

  const isCustomIcon = (project) => {
    if (
      project?.projectIcon !== "0" &&
      project?.projectIcon !== "1" &&
      project?.projectIcon !== "2" &&
      project?.projectIcon !== "3" &&
      project?.projectIcon !== "4"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const mainIcon = isCustomIcon(projectData)
    ? projectData?.projectIcon
    : getProjectIconById(projectData?.projectIcon);

  // Updating task toaster
  // Custom Toast Component

  // Function to trigger the toast

  useEffect(() => {
    if (isUpdating || isMemberLoading) {
      // Show the updating toast when isUpdating becomes true
      setUpdateLoad(true);
    } else {
      // Dismiss the toast when the update completes
      setUpdateLoad(false);
    }
  }, [isUpdating, isMemberLoading]);

  useEffect(() => {
    if (updateLoad) {
      setIsToastVisible(true);
    } else {
      // Dismiss the toast when the update completes
    }
  }, [updateLoad]);

  useEffect(() => {
    // Extract IDs from taskListMain
    const taskIds = taskListToFilter?.map((task) => task.id);

    // Filter selectedTasks to keep only those that exist in taskIds
    const updatedSelectedTasks = selectedTasks?.filter((taskId) =>
      taskIds.includes(taskId)
    );

    // Update the state with the filtered tasks
    setSelectedTasks(updatedSelectedTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskListToFilter]);

  const handleTaskDetailsCloseModal = () => {
    setNewId(null);
    setSelectedTask(null);
    setShowTaskDetails(false);
  };

  // Run tour journey
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const pidExists = searchParams.has("pid"); // Check if 'pid' query param exists

    if (
      currentPath.startsWith(`/${localStorage.getItem("company")}/project`) && // Ensure the path starts with /project
      pidExists && // Optionally ensure 'pid' exists in the query string
      userProfile?.projectDetailTour &&
      loadJourney &&
      !shouldShowLoading
    ) {
      setRunTour(true);
    }
  }, [userProfile, location, loadJourney, shouldShowLoading]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;
    try {
      setRunTour(false); // Close the tour
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "projectDetailTour", // Add the field name for the specific input field
        fieldValues: false, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [dispatch, userProfile?.userId]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton")
        // ||
        // event.target.classList.contains("introjs-donebutton")
      ) {
        await updateProfile();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-plan-main-wraper">
      {isToastVisible && !readLoad && <div className="update-load-case"></div>}
      <CustomToast
        isVisible={isToastVisible && !readLoad}
        onClose={() => setIsToastVisible(false)}
        updateLoad={updateLoad}
      />
      {runTour && (
        <Steps
          enabled={runTour}
          steps={steps.filter((step) => document.querySelector(step.element))}
          initialStep={0}
          options={{
            showProgress: false,
            showStepNumbers: false,
            exitOnOverlayClick: false,
            scrollToElement: false,
            exitOnEsc: true,
            nextLabel: "Next",
            prevLabel: "Skip Tour",
            tooltipClass: "tour-custom-tooltip", // Optional custom styling
            disableInteraction: true,
          }}
          onExit={async () => {
            if (localStorage.getItem("access_token")) {
              await updateProfile();
            }
          }} // Reset the tour state when exited
        />
      )}
      <div className="project-detail-top-heading-wrap">
        <Tooltip id="my-tooltip-proj" place="right" />
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap project-main-head-wrap">
            <h3 className="ms-4 projects-detail-heading-wrap mb-0 d-flex flex-wrap ">
              {projectData && (
                <img
                  className="proj-detail-header-icon"
                  src={mainIcon}
                  alt="project-icon"
                />
              )}
              {projName || "Project"}{" "}
              {(canOptionsExist || isAdmin(userProfile)) && (
                <div className="ms-2">
                  <Dropdown className="roles-drop-down timeline-dropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div className="proj-detail-menu">
                        <Icon icon="mi:options-vertical" />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {(canProjectEdit || isAdmin(userProfile)) && (
                        <Dropdown.Item
                          onClick={() => setUpdateTaskCanvas(true)}
                        >
                          <img
                            src={EditIcon}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "5px",
                            }}
                            alt="edit-task-icon"
                          />
                          Edit project
                        </Dropdown.Item>
                      )}
                      {(canProjectDelete || isAdmin(userProfile)) && (
                        <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                          <img
                            src={Delete}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "5px",
                            }}
                            alt="delete-task-icon"
                          />
                          Delete project
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              <span className="ms-2">
                <Link
                  to={`/${localStorage.getItem(
                    "company"
                  )}/task-management-porojects`}
                  className="team-project-heading-a"
                >
                  Projects
                </Link>
                <span
                  style={{ color: "#7E7E7E" }}
                  className="team-project-heading-a"
                >
                  /
                </span>
                <Link
                  to=""
                  className="team-project-heading-a"
                  onClick={(e) => e.preventDefault()}
                >
                  Project Detail
                </Link>
              </span>{" "}
              {isPermitted("task/create-new") && (
                <button
                  className="create-btn"
                  data-tooltip-id="my-tooltip-proj"
                  data-tooltip-content="Create task"
                  onClick={(e) => {
                    if (!isTrialActive(userProfile)) {
                      props.setOpenSubscriptionsModal(true);
                    } else {
                      setShowCreateTask(true);
                    }
                  }}
                >
                  {" "}
                  <img src={CreateIcon} alt="create-icon" /> Create{" "}
                </button>
              )}
            </h3>
          </div>
        </div>
      </div>
      {isPermitted("project/pm-project-phases") ? (
        <>
          {/* {taskListToFilter && taskListToFilter.length > 0 && ( */}
          {isLoading ? (
            <div className="ms-4 d-flex gap-2">
              {Array.from({ length: 7 }, (_, index) => (
                <ShimmerButton key={index} size="sm" />
              ))}
            </div>
          ) : (
            !kanban && (
              <div className="ms-4 d-flex align-items-center flex-wrap justify-content-between gap-2 project-filter-action-container">
                <ProjectDetailFilterSection
                  props={props}
                  filters={filters}
                  setFilters={setFilters}
                  taskListToFilter={taskListToFilter}
                  projectUserList={userTaskListing?.data || []}
                  projectStatusList={projectStatusList}
                  taskPriorities={taskPriorities}
                  taskCount={taskCount}
                  completedCount={completedCount}
                  overdueCount={overdueCount}
                  todayCount={0}
                  refreshFilters={refreshFilters}
                  showRefresh={isShowRefresh}
                  setCurrentPage={setCurrentPage}
                  selectedTasks={selectedTasks}
                  setDeleteSelected={setDeleteSelected}
                  setShowOnlyCompletedTasks={() => {}}
                  showOnlyCompletedTasks={false}
                  projectTypeList={projectTypeList}
                  setShowAdvanceFilterModal={setShowAdvanceFilterModal}
                  setServerFilters={setServerFilters}
                  serverFilters={serverFilters}
                  setFilterType={setFilterType}
                  filterType={filterType}
                  selectedDateFilter={selectedDateFilter}
                  setSelectedDateFilter={setSelectedDateFilter}
                  userProfile={userProfile}
                />
                <div className="project-header-search-section">
                  <ul>
                    <li
                      style={{ width: "auto" }}
                      className="project-drive-btn ms-0"
                    >
                      <div
                        onClick={() => setOpenDrive(true)}
                        className="d-flex align-items-center drive-icon gap-2 me-2"
                      >
                        <div>
                          <Icon icon="entypo:drive" />
                        </div>{" "}
                        <p className="p-0">Drive</p>
                      </div>
                    </li>

                    <li>
                      <button
                        className="download-excel-btn d-flex align-items-center"
                        onClick={handleDownloadExcel}
                        disabled={excelLoad}
                      >
                        <i
                          className="fa fa-download m-0"
                          aria-hidden="true"
                        ></i>
                        {excelLoad ? (
                          <Spinner size="sm" className="ms-1 global-spinner" />
                        ) : (
                          ""
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )
          )}
          {/* )} */}

          {!kanban ? (
            <div>
              {isLoading ? (
                <div className="position-relative">
                  <div className="ms-4">
                    <ShimmerTable row={5} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="ms-4 project-info-heading-wrap mt-3">
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                      <div className="">
                        <ul className="left-listing-wrap mt-0">
                          <li className="phase-select-dropdown">
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                              className="form-group"
                            >
                              <select
                                className="form-control phase-select-dropdown-journey"
                                value={selectedPhaseId}
                                onChange={handlePhaseChange}
                                // onFocus={() => setIsOpen(true)}
                                onBlur={() => setIsOpen(false)}
                                onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown state
                                style={{
                                  appearance: "none", // Hide default arrow
                                  WebkitAppearance: "none",
                                  MozAppearance: "none",
                                  paddingRight: "30px", // Make space for custom icon
                                  cursor: "pointer",
                                }}
                              >
                                <option value="All" key="All">
                                  All Phases
                                </option>
                                {projectAllPhasesTasks?.map((phase) => (
                                  <option key={phase.id} value={phase.id}>
                                    {toCamelCase(phase.phaseName)}
                                  </option>
                                ))}
                              </select>
                              {/* Dropdown Icon with Rotation */}
                              <div
                                className="select-icon"
                                style={{
                                  position: "absolute",
                                  top: "22%",
                                  right: "3px",
                                  transform: isOpen
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                  transition: "transform 0.3s ease",
                                  pointerEvents: "none", // Prevents interaction with the icon
                                  transformOrigin: "center",
                                }}
                              >
                                <IoIosArrowDown />
                              </div>
                            </div>
                          </li>
                          {/* <li>
                              <h4>
                                {" "}
                                {all ? "All" : selectedPhaseName}{" "}
                                <span>{all ? "Phases" : "Phase"}</span>{" "}
                              </h4>
                            </li> */}
                          <li>
                            <button>
                              {" "}
                              Tasks ({taskListToFilter?.length}){" "}
                            </button>
                          </li>
                          <li>
                            <button>
                              {" "}
                              {dateFormatter(projectData?.projectEndDate)}{" "}
                            </button>
                          </li>
                          <li>
                            <div className="progress-timeline progress-bar-top">
                              <div
                                className="progress-bar-timeline progress-bar-timeline-color"
                                role="progressbar"
                                aria-valuenow="70"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{
                                  width: `${overAllProgress}%`,
                                }}
                              >
                                {" "}
                                <span className="sr-only">
                                  {overAllProgress}% Complete
                                </span>{" "}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="">
                        <ul className="d-flex align-items-center flex-wrap right-listi-wrap">
                          <li>
                            <button
                              onClick={() => setShow(true)}
                              className="plan-project-btn-icon"
                            >
                              {" "}
                              {/* <Icon icon="fluent:clipboard-task-list-ltr-20-filled" /> */}
                              <img
                                src={PlanIcon}
                                alt="plan-project-icon"
                                className="me-2"
                                width={20}
                                height={20}
                              />
                              Your Project Plan
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {selectedTasks.length > 0 && (
                    <ProjectDetailSelectedSection
                      selectedTasks={selectedTasks}
                      taskListToFilter={taskListToFilter}
                      setDeleteSelected={setDeleteSelected}
                      setSelectedTasks={setSelectedTasks}
                      projectUserList={userList}
                      projectStatusList={projectStatusList}
                      taskPriorities={taskPriorities}
                      isDark={props.isDark}
                      getTaskList={() => getTaskList(false)}
                      userProfile={userProfile}
                    />
                  )}
                  {/* {projectAllPhasesTasks &&
                    projectAllPhasesTasks?.length > 0 && ( */}
                  <div className="project-plan-table-wrap">
                    <ul>
                      {shouldShowLoading || isLoading || isFetching ? (
                        <div className="position-relative">
                          <div className="ms-4">
                            <ShimmerTable row={5} />
                          </div>
                        </div>
                      ) : (
                        <li>
                          {taskListToFilter?.length > 0 ? (
                            <>
                              <ProjectDetailTaskTable
                                props={props}
                                rowData={taskListToFilter}
                                totalTasks={taskPhaseList?.meta?.total}
                                boardColumnData={[]}
                                setSelectedTask={setSelectedTask}
                                selectedTask={selectedTask}
                                filters={filters}
                                setFilters={setFilters}
                                setListToFilter={setTaskListToFilter}
                                taskList={taskListToFilter}
                                setTaskList={setTaskListToFilter}
                                getStatusCounts={getStatusCounts}
                                updateCountFilters={updateCountFilters}
                                projectId={pid}
                                phaseId={selectedPhaseId}
                                // getAllTasksByPhaseId={getAllTasksByPhaseId}
                                projectAllPhasesTasks={projectAllPhasesTasks}
                                disableAction={isUpdating || isMemberLoading}
                                selectedTasks={selectedTasks}
                                setSelectedTasks={setSelectedTasks}
                                userList={userList}
                                getAllProjectPhases={getAllProjectPhases}
                                listingType="PHASE_TASKS"
                                newId={newId}
                                setNewId={setNewId}
                                getTaskList={() => getTaskList(false)}
                                showTaskDetails={showTaskDetails}
                                setShowTaskDetails={setShowTaskDetails}
                                pageParams={{
                                  projectId: pid,
                                  projectPhaseId:
                                    selectedPhaseId === "All"
                                      ? "all"
                                      : selectedPhaseId,
                                  page: currentPage,
                                  limit: pageSize,
                                }}
                                updatePhaseTaskData={updatePhaseTaskData}
                                updatePhaseTaskMemberData={
                                  updatePhaseTaskMemberData
                                }
                                setReadLoad={setReadLoad}
                                setWasUnread={setWasUnread}
                              />
                              {/* Pagination */}

                              <div className="d-flex justify-content-between">
                                {taskListToFilter?.length > 0 && (
                                  <div className="ms-4 d-flex lead-pagi-col">
                                    <div className="d-flex align-items-center record-project-wrap">
                                      Records Per Page{" "}
                                      <div className="form-group">
                                        <Select
                                          className={`basic-single  project-filter-select task-situation-filter-top-wrap-select-div`}
                                          classNamePrefix="select"
                                          value={{
                                            label: pageSize,
                                            value: pageSize,
                                          }}
                                          isDisabled={false}
                                          isLoading={false}
                                          isClearable={false}
                                          isRtl={false}
                                          isSearchable={false}
                                          styles={{
                                            option: (provided, state) => ({
                                              ...provided,

                                              backgroundColor: state.isSelected
                                                ? "#deebff"
                                                : "#fff",
                                            }),
                                          }}
                                          menuPlacement="auto"
                                          options={pageSizeList
                                            ?.filter(
                                              (item) => item !== pageSize
                                            )
                                            .map((res, index) => ({
                                              label: res,
                                              value: res,
                                            }))}
                                          onChange={(e) => {
                                            setCurrentPage(1);
                                            setPageSize(e.value);
                                            sessionStorage.setItem(
                                              "project_detail_list_limit",
                                              e.value
                                            );
                                          }}
                                          components={{
                                            Control: ({
                                              children,
                                              menuIsOpen,
                                              ...props
                                            }) => {
                                              return (
                                                <components.Control {...props}>
                                                  {props.hasValue ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          color:
                                                            "blue !important",
                                                        }}
                                                      >
                                                        {children}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    children
                                                  )}
                                                  <div
                                                    className="select-icon me-1"
                                                    style={{
                                                      transform: menuIsOpen
                                                        ? "rotate(180deg)"
                                                        : "rotate(0deg)",
                                                      transition:
                                                        "transform 0.3s ease",
                                                    }}
                                                  >
                                                    <IoIosArrowDown />
                                                  </div>
                                                </components.Control>
                                              );
                                            },
                                          }}
                                        ></Select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {totalTasks > pageSize && (
                                  <Pagination className="align-items-center">
                                    <Pagination.First
                                      onClick={() => handlePageChange("first")}
                                    />
                                    <Pagination.Prev
                                      onClick={() => handlePageChange("prev")}
                                    />
                                    {Array.from(
                                      { length: totalPages },
                                      (_, index) => (
                                        <Pagination.Item
                                          key={index}
                                          active={index + 1 === currentPage}
                                          onClick={() =>
                                            handlePageChange(index + 1)
                                          }
                                        >
                                          {index + 1}
                                        </Pagination.Item>
                                      )
                                    )}
                                    <Pagination.Next
                                      onClick={() => handlePageChange("next")}
                                    />
                                    <Pagination.Last
                                      onClick={() => handlePageChange("last")}
                                    />
                                  </Pagination>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <p className="mt-2 ms-4 project-listing-no-data-text">
                                <>
                                  Start your first{" "}
                                  <span
                                    onClick={() => setShowCreateTask(true)}
                                    className="here-text-project"
                                  >
                                    task
                                  </span>{" "}
                                  and assign it to member/team.
                                </>
                              </p>
                            </>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* )} */}
                </>
              )}
            </div>
          ) : (
            <KanbanView
              tasksData={tasksData}
              setTasksData={setTasksData}
              getAllTasks={getAllTasks}
              isDark={props.isDark}
              projectId={pid}
              onKanban={onKanban}
              loadKanban={loadKanban}
              setLoadKanban={setLoadKanban}
              projectStatusList={projectStatusList}
              setKanban={setKanban}
              setAllLoad={() => {}}
            />
          )}
        </>
      ) : (
        <div className="ms-4 w-100">
          <p>
            Unfortunately, you do not have necessary permissions to view project
            phases. Please contact your admin for access.
          </p>
        </div>
      )}
      {showStage && (
        <AddStageModal
          show={showStage}
          handleClose={handleStageClose}
          isDark={props.isDark}
          setTasksData={setTasksData}
          tasksData={tasksData}
        />
      )}
      {show && (
        <PlanProjectPhaseMain
          show={show}
          handleCloseModal={handleCloseModal}
          isDark={props.isDark}
          modal={true}
          projectId={pid}
          projectName={projName}
          project={projectData}
          overAllProgress={overAllProgress}
          setSelectedPhaseIdChange={() => {
            setSelectedPhaseId("All");
          }}
          updatePhaseTaskData={updatePhaseTaskData}
          updatePhaseTaskMemberData={updatePhaseTaskMemberData}
          isUpdating={isUpdating}
          isMemberLoading={isMemberLoading}
          memberSuccess={memberSuccess}
          getTaskList={() => getTaskList(false)}
          setReadLoad={setReadLoad}
          setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
        />
      )}
      {showCreateTask && (
        <CreateTaskModal
          show={showCreateTask}
          handleCloseModal={() => setShowCreateTask(false)}
          isDark={props.isDark}
          getBoardData={() => getAllProjectPhases()}
          projectId={pid}
          projectPhaseId={null}
          projectAllPhasesTasks={projectAllPhasesTasks}
          isPhasesSelectShow={true}
          kanban={true}
          setKanban={() => setOnKanBan(!onKanban)}
          setLoadKanban={setLoadKanban}
          setShowTaskDetails={setShowTaskDetails}
          listing="PROJECT_DETAIL"
          setNewId={setNewId}
          getTaskList={() => getTaskList(false)}
        />
      )}

      {newProject && (
        <ProjectNewModal
          show={newProject}
          handleCloseModal={handleNewProjectClose}
          isDark={props.isDark}
          getProjectList={() => {}}
          creation={true}
        />
      )}
      {showAdvanceFilterModal && (
        <TasksAdvanceSearchModalMain
          showModal={showAdvanceFilterModal}
          setShowModal={setShowAdvanceFilterModal}
          data={taskListToFilter}
          isDark={props.isDark}
          filters={serverFilters}
          setFilters={setServerFilters}
          projectUserList={userList}
          projectStatusList={projectStatusList}
          projectTypeList={projectTypeList}
          taskPriorities={taskPriorities}
          taskTypes={taskTypes}
          showRefresh={isShowRefresh}
          refreshFilters={refreshFilters}
          initialFormData={initialFormData}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {openDrive && (
        <ProjectDrive
          show={openDrive}
          handleClose={handleDriveClose}
          isDark={props.isDark}
          projectId={pid}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteProjectModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDark={props.isDark}
          projectId={pid}
          getProjectList={() => {}}
          listToFilter={[]}
          setListToFilter={() => {}}
          getProjectName={() => {
            return projName;
          }}
          projectDetailRoute={true}
          type="project"
          modalContent={projectsDeleteModalContent}
          handleConfirmClose={() => setShowConfirmDelete(true)}
        />
      )}
      {showConfirmDelete && (
        <ConfirmSureDeleteProjectModal
          showDeleteModal={showConfirmDelete}
          handleCloseDeleteModal={() => setShowConfirmDelete(false)}
          isDark={props.isDark}
          projectId={pid}
          getProjectList={props.getProjectList}
          listToFilter={props.listToFilter}
          setListToFilter={props.setListToFilter}
          getProjectName={() => {
            return projName;
          }}
          projectDetailRoute={true}
          type="project"
          modalContent={projectsDeleteModalContent}
        />
      )}
      {updateTaskCanvas && (
        <ProjectEditModal
          id={pid}
          isDark={props.isDark}
          show={updateTaskCanvas}
          listToFilter={[]}
          setListToFilter={() => {}}
          handleCloseModal={() => {
            setUpdateTaskCanvas(false);
          }}
          projectDetailRoute={true}
          setProjName={setProjName}
        />
      )}
      {deleteSelected && (
        <DeleteSelectedTasksModal
          showDeleteModal={deleteSelected}
          handleCloseDeleteModal={handleCloseDeleteSelected}
          isDark={props.isDark}
          setListToFilter={setTaskListToFilter}
          setTaskList={setTaskListToFilter}
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
          getTaskList={() => getTaskList(false)}
          listingType="ALL_TASKS"
        />
      )}
      {showTaskDetails && (
        <TaskDetailOffCanvas
          show={showTaskDetails}
          handleCloseModal={handleTaskDetailsCloseModal}
          isDark={props.isDark}
          selectedTask={selectedTask || newId}
          rowData={taskListToFilter}
          setRowData={() => {}}
          taskList={taskListToFilter}
          setTaskList={setTaskListToFilter}
          getStatusCounts={getStatusCounts}
          listingType="PHASE_TASKS"
          pageParams={{
            projectId: pid,
            projectPhaseId: selectedPhaseId === "All" ? "all" : selectedPhaseId,
            page: currentPage,
            limit: pageSize,
          }}
          getTaskList={() => getTaskList(false)}
          wasUnread={wasUnread}
          setWasUnread={setWasUnread}
        />
      )}
    </div>
  );
};

export default ProjectDetailMain;
