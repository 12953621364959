import React, { useEffect, useRef, useState } from "react";
import API from "../../../services/ClientApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { useGetCompanyWorkingShiftsQuery } from "../../../slices/user/userSlice";

const formattedInitialDate = (dateString) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear().toString();
  return `${year}-${month}-${day}`;
};

const PersonalInfo = (props) => {
  const currentDate = new Date();
  const [designation, setDesignation] = useState(null);
  const [initialDesignation, setInitialDesignation] = useState(null);
  const [phone, setPhone] = useState(null);
  const [initialPhone, setInitialPhone] = useState(null);
  const [skype, setSkype] = useState(null);
  const [initialSkype, setInitialSkype] = useState(null);
  const [location, setLocation] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [workAnniversary, setWorkAnniversary] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);
  const inputFourRef = useRef(null);
  const [shift, setShift] = useState(
    props?.user?.userInfo?.shiftTitle ? props?.user?.userInfo?.shiftTitle : null
  );
  const [shiftOptions, setShiftOptions] = useState([]);
  const { data: companyWorkingShiftData } = useGetCompanyWorkingShiftsQuery();
  const { userProfile } = useSelector((state) => state?.profile);

  useEffect(() => {
    if (props?.user?.updatedProfileDetail) {
      const updatedDetails = props.user.updatedProfileDetail;

      setDesignation(updatedDetails.designation || null);
      setInitialDesignation(updatedDetails.designation || null);
      setPhone(updatedDetails.phone || null);
      setSkype(updatedDetails.skypeId || null);
      setLocation(updatedDetails.location || null);
      setInitialPhone(updatedDetails.phone || null);
      setInitialSkype(updatedDetails.skypeId || null);
      setInitialLocation(updatedDetails.location || null);

      setBirthday(
        updatedDetails.birthDate
          ? new Date(formattedInitialDate(updatedDetails.birthDate))
          : null
      );
      setWorkAnniversary(
        updatedDetails.workAnniversary
          ? new Date(formattedInitialDate(updatedDetails.workAnniversary))
          : null
      );
    }
  }, [props.user]);

  useEffect(() => {
    if (props?.user?.userInfo?.shiftTitle) {
      setShift(props?.user?.userInfo?.shiftTitle);
    }
  }, [props?.user]);

  useEffect(() => {
    if (companyWorkingShiftData && companyWorkingShiftData?.data?.length > 0) {
      setShiftOptions(
        companyWorkingShiftData?.data?.map((item) => {
          return {
            label: item?.shift_title,
            value: item?.id,
          };
        })
      );
    }
  }, [companyWorkingShiftData]);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleChange = async (value, field) => {
    try {
      const response = await API.post("users/update-profile", {
        userId: props?.user?.userInfo?.userId,
        fieldName: field, // Add the field name for the specific input field
        fieldValues: value, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        await props.getMainProfileData();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleLanguageChange = async (value, label) => {
    setShift(label);
    try {
      await API.post("users/update-profile", {
        userId: props?.user?.userInfo?.userId,
        fieldName: "workingShift", // Add the field name for the specific input field
        fieldValues: value, // Add the corresponding state variable here
      });
      await props?.getUser(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const validatePhoneNumber = (number) => {
    if (number === "") {
      return true; // Allow empty string as valid
    }

    const phonePattern = /^\d{10,15}$/; // Pattern for a 10 to 15 digit phone number
    return phonePattern.test(number); // Only validate if number exists
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      if (!validatePhoneNumber(input)) {
        setPhoneError("Phone number must be between 10 to 15 digits.");
      } else {
        setPhoneError("");
        handleChange(input, "phone");
      }
    }
  };

  return (
    <div className="listing-info-profile-wrap">
      <h4> Overview </h4>
      <ul className="row profile-info-row-main">
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 11.3198C11.7867 11.2526 11.9787 10.9152 11.8185 10.6274C11.4652 9.9929 10.9087 9.43538 10.1968 9.01049C9.27991 8.46329 8.1565 8.16669 7.0008 8.16669C5.84509 8.16669 4.72168 8.46329 3.8048 9.01049C3.09288 9.43537 2.53637 9.9929 2.18311 10.6274C2.02287 10.9152 2.21492 11.2526 2.5374 11.3198C5.48137 11.9334 8.52022 11.9334 11.4642 11.3198Z"
                    fill="currentColor"
                  />
                  <circle
                    cx="7.00065"
                    cy="4.66667"
                    r="2.91667"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong>Designation: </strong>{" "}
                <input
                  type="text"
                  ref={inputOneRef}
                  placeholder="Enter Your Designation"
                  value={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                  onBlur={(e) => {
                    // Only run handleChange if the value has changed
                    if (e.target.value !== initialDesignation) {
                      handleChange(e.target.value, "designation");
                      setInitialDesignation(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Trigger onBlur manually when Enter is pressed
                      inputOneRef.current.blur(); // Optionally blur the input
                    }
                  }}
                  autoComplete="off"
                />{" "}
              </div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.33398"
                    y="3.5"
                    width="9.33333"
                    height="7"
                    rx="2"
                    stroke="currentColor"
                  />
                  <path
                    d="M6.10622 7.13612L2.33398 5.25V8.5C2.33398 9.60457 3.22941 10.5 4.33398 10.5H9.66732C10.7719 10.5 11.6673 9.60457 11.6673 8.5V5.25L7.89508 7.13612C7.33202 7.41765 6.66928 7.41765 6.10622 7.13612Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong className="user-personal-email">
                  Email Address:
                </strong>{" "}
                <p> {props?.user?.userInfo?.emailAddress} </p>
              </div>
              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 8 8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.83316 4.72789L7.66065 5.55538C7.84807 5.7428 7.84807 6.04667 7.66065 6.23409C6.64738 7.24736 5.04322 7.36136 3.89684 6.50157L3.83001 6.45145C2.96527 5.8029 2.1971 5.03473 1.54855 4.16999L1.49843 4.10316C0.638636 2.95678 0.752639 1.35262 1.76591 0.339352C1.95333 0.151933 2.2572 0.151933 2.44462 0.339352L3.27211 1.16685C3.55784 1.45258 3.55784 1.91584 3.27211 2.20158L2.73705 2.73664C2.6112 2.86248 2.58 3.05474 2.65959 3.21392C3.1197 4.13414 3.86586 4.8803 4.78608 5.34041C4.94526 5.42 5.13752 5.3888 5.26336 5.26295L5.79842 4.72789C6.08416 4.44216 6.54742 4.44216 6.83316 4.72789Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="w-100">
                <div className="number-overview-wrap profile-info-num-wrap pb-1 w-100">
                  {" "}
                  <strong>Phone No:</strong>{" "}
                  <input
                    type="tel"
                    ref={inputTwoRef}
                    placeholder="Enter Your Number"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    onBlur={(e) => {
                      // Only run handleChange if the value has changed
                      if (e.target.value !== initialPhone) {
                        handlePhoneChange(e);
                        setInitialPhone(e.target.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        // Trigger onBlur manually when Enter is pressed
                        inputTwoRef.current.blur(); // Optionally blur the input
                      }
                    }}
                    autoComplete="off"
                  />
                </div>
                {phoneError && (
                  <p
                    style={{ fontSize: "12px" }}
                    className="p-0 m-0 text-danger "
                  >
                    {phoneError}
                  </p>
                )}
              </div>

              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 7 8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.64014 5.14014C4.64014 4.98779 4.60205 4.85026 4.52588 4.72754C4.44971 4.60059 4.30371 4.48633 4.08789 4.38477C3.8763 4.2832 3.56315 4.1901 3.14844 4.10547C2.78451 4.02507 2.4502 3.92985 2.14551 3.81982C1.84505 3.70557 1.58691 3.56803 1.37109 3.40723C1.15527 3.24642 0.988118 3.05599 0.869629 2.83594C0.751139 2.61589 0.691895 2.36198 0.691895 2.07422C0.691895 1.79492 0.753255 1.53044 0.875977 1.28076C0.998698 1.03109 1.17432 0.811035 1.40283 0.620605C1.63135 0.430176 1.90853 0.279948 2.23438 0.169922C2.56445 0.0598958 2.93262 0.00488281 3.33887 0.00488281C3.91439 0.00488281 4.40739 0.102214 4.81787 0.296875C5.23258 0.487305 5.54997 0.747559 5.77002 1.07764C5.99007 1.40348 6.1001 1.77165 6.1001 2.18213H4.57031C4.57031 2.00016 4.52376 1.83089 4.43066 1.67432C4.3418 1.51351 4.20638 1.38444 4.02441 1.28711C3.84245 1.18555 3.61393 1.13477 3.33887 1.13477C3.0765 1.13477 2.85856 1.17708 2.68506 1.26172C2.51579 1.34212 2.38883 1.44792 2.3042 1.5791C2.2238 1.71029 2.18359 1.85417 2.18359 2.01074C2.18359 2.125 2.20475 2.22868 2.24707 2.32178C2.29362 2.41064 2.36979 2.49316 2.47559 2.56934C2.58138 2.64128 2.72526 2.70898 2.90723 2.77246C3.09342 2.83594 3.32617 2.8973 3.60547 2.95654C4.13021 3.06657 4.58089 3.20833 4.95752 3.38184C5.33838 3.55111 5.63037 3.77116 5.8335 4.04199C6.03662 4.30859 6.13818 4.64714 6.13818 5.05762C6.13818 5.3623 6.07259 5.6416 5.94141 5.89551C5.81445 6.14518 5.62826 6.36312 5.38281 6.54932C5.13737 6.73128 4.84326 6.87305 4.50049 6.97461C4.16195 7.07617 3.78109 7.12695 3.35791 7.12695C2.73584 7.12695 2.20898 7.01693 1.77734 6.79688C1.3457 6.57259 1.01774 6.28695 0.793457 5.93994C0.573405 5.5887 0.463379 5.22477 0.463379 4.84814H1.94238C1.95931 5.13167 2.0376 5.35807 2.17725 5.52734C2.32113 5.69238 2.49886 5.81299 2.71045 5.88916C2.92627 5.9611 3.14844 5.99707 3.37695 5.99707C3.65202 5.99707 3.88265 5.9611 4.06885 5.88916C4.25505 5.81299 4.39681 5.71143 4.49414 5.58447C4.59147 5.45329 4.64014 5.30518 4.64014 5.14014Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong>Skype: </strong>
                <input
                  type="text"
                  ref={inputThreeRef}
                  placeholder="Enter Your SkypeID"
                  value={skype}
                  onChange={(e) => {
                    setSkype(e.target.value);
                  }}
                  onBlur={(e) => {
                    // Only run handleChange if the value has changed
                    if (e.target.value !== initialSkype) {
                      handleChange(e.target.value, "skypeId");
                      setInitialSkype(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Trigger onBlur manually when Enter is pressed
                      inputThreeRef.current.blur(); // Optionally blur the input
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 13 13"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.89726 10.6301C7.86943 10.0926 10.2923 8.4983 10.2923 5.95835C10.2923 3.86427 8.59473 2.16669 6.50065 2.16669C4.40657 2.16669 2.70898 3.86427 2.70898 5.95835C2.70898 8.4983 5.13187 10.0926 6.10404 10.6301C6.35333 10.768 6.64797 10.768 6.89726 10.6301ZM6.50065 7.58335C7.39811 7.58335 8.12565 6.85582 8.12565 5.95835C8.12565 5.06089 7.39811 4.33335 6.50065 4.33335C5.60319 4.33335 4.87565 5.06089 4.87565 5.95835C4.87565 6.85582 5.60319 7.58335 6.50065 7.58335Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong>Address: </strong>{" "}
                <input
                  type="text"
                  ref={inputFourRef}
                  value={location}
                  placeholder="Enter Your Address"
                  className="profile-info-email-address"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  onBlur={(e) => {
                    // Only run handleChange if the value has changed
                    if (e.target.value !== initialLocation) {
                      handleChange(e.target.value, "location");
                      setInitialLocation(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Trigger onBlur manually when Enter is pressed
                      inputFourRef.current.blur(); // Optionally blur the input
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 5.33331H3.66667C3.04359 5.33331 2.73205 5.33331 2.5 5.46729C2.34798 5.55506 2.22174 5.68129 2.13397 5.83331C2 6.06536 2 6.3769 2 6.99998C2 7.62306 2 7.9346 2.13397 8.16665C2.1685 8.22645 2.20898 8.28226 2.25464 8.33331H7V5.33331ZM4 10.3333V12C4 12.9428 4 13.4142 4.29289 13.7071C4.58579 14 5.05719 14 6 14H7V10.3333H4ZM9 14H10C10.9428 14 11.4142 14 11.7071 13.7071C12 13.4142 12 12.9428 12 12V10.3333H9V14ZM13.7454 8.33331C13.791 8.28226 13.8315 8.22645 13.866 8.16665C14 7.9346 14 7.62306 14 6.99998C14 6.3769 14 6.06536 13.866 5.83331C13.7783 5.68129 13.652 5.55506 13.5 5.46729C13.268 5.33331 12.9564 5.33331 12.3333 5.33331H9V8.33331H13.7454Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.666 3.08828V2.96688C12.666 2.16323 11.8787 1.59576 11.1163 1.84989C10.1625 2.1678 9.29592 2.7034 8.58505 3.41428L7.99935 3.99998V4.66665H10.3415C10.5564 4.66665 10.77 4.63199 10.9739 4.56401L11.8011 4.28828C12.3176 4.11611 12.666 3.63274 12.666 3.08828Z"
                    fill="currentColor"
                  />
                  <path
                    d="M3.33398 3.08828V2.96688C3.33398 2.16323 4.12131 1.59576 4.88372 1.84989C5.83746 2.1678 6.70408 2.7034 7.41495 3.41428L8.00065 3.99998V4.66665H5.65854C5.44358 4.66665 5.23002 4.63199 5.02608 4.56401L4.1989 4.28828C3.68238 4.11611 3.33398 3.63274 3.33398 3.08828Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong>Birthday: </strong>{" "}
                <DatePicker
                  selected={birthday}
                  placeholderText="Enter Your Date of birth"
                  onChange={(date) => {
                    setBirthday(date);
                    handleChange(formatDate(date), "birthDate");
                  }}
                  dateFormat="MMM d, yyyy" // Display format
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={currentDate}
                />
              </div>
              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>
        <li className="col-md-6">
          <div className="overview-profile">
            {" "}
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 5.33331H3.66667C3.04359 5.33331 2.73205 5.33331 2.5 5.46729C2.34798 5.55506 2.22174 5.68129 2.13397 5.83331C2 6.06536 2 6.3769 2 6.99998C2 7.62306 2 7.9346 2.13397 8.16665C2.1685 8.22645 2.20898 8.28226 2.25464 8.33331H7V5.33331ZM4 10.3333V12C4 12.9428 4 13.4142 4.29289 13.7071C4.58579 14 5.05719 14 6 14H7V10.3333H4ZM9 14H10C10.9428 14 11.4142 14 11.7071 13.7071C12 13.4142 12 12.9428 12 12V10.3333H9V14ZM13.7454 8.33331C13.791 8.28226 13.8315 8.22645 13.866 8.16665C14 7.9346 14 7.62306 14 6.99998C14 6.3769 14 6.06536 13.866 5.83331C13.7783 5.68129 13.652 5.55506 13.5 5.46729C13.268 5.33331 12.9564 5.33331 12.3333 5.33331H9V8.33331H13.7454Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.666 3.08828V2.96688C12.666 2.16323 11.8787 1.59576 11.1163 1.84989C10.1625 2.1678 9.29592 2.7034 8.58505 3.41428L7.99935 3.99998V4.66665H10.3415C10.5564 4.66665 10.77 4.63199 10.9739 4.56401L11.8011 4.28828C12.3176 4.11611 12.666 3.63274 12.666 3.08828Z"
                    fill="currentColor"
                  />
                  <path
                    d="M3.33398 3.08828V2.96688C3.33398 2.16323 4.12131 1.59576 4.88372 1.84989C5.83746 2.1678 6.70408 2.7034 7.41495 3.41428L8.00065 3.99998V4.66665H5.65854C5.44358 4.66665 5.23002 4.63199 5.02608 4.56401L4.1989 4.28828C3.68238 4.11611 3.33398 3.63274 3.33398 3.08828Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap profile-info-num-wrap">
                {" "}
                <strong>Work Anniversary:</strong>{" "}
                <DatePicker
                  placeholderText="Enter Your Anniversary Date"
                  selected={workAnniversary}
                  onChange={(date) => {
                    setWorkAnniversary(date);
                    handleChange(formatDate(date), "workAnniversary");
                  }}
                  dateFormat="MMM d, yyyy" // Display format
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={currentDate}
                />
              </div>
              <div className="clearfix"></div>
            </a>{" "}
          </div>
        </li>

        <li className="col-md-6">
          <div
            style={{ width: "100%" }}
            className="overview-profile overview-language"
          >
            <a>
              <div style={{ marginRight: "15px", color: "var(--app-color-1)" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 26 27"
                  fill="currentColor"
                >
                  <circle cx="13" cy="13.4785" r="13" fill="#F1F4FF" />
                  <path
                    d="M19.6199 13.4794C19.6199 17.1355 16.656 20.0994 12.9999 20.0994C9.34376 20.0994 6.37988 17.1355 6.37988 13.4794C6.37988 9.82325 9.34376 6.85938 12.9999 6.85938C16.656 6.85938 19.6199 9.82325 19.6199 13.4794ZM7.58763 13.4794C7.58763 16.4685 10.0108 18.8916 12.9999 18.8916C15.989 18.8916 18.4122 16.4685 18.4122 13.4794C18.4122 10.4903 15.989 8.06712 12.9999 8.06712C10.0108 8.06712 7.58763 10.4903 7.58763 13.4794Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.9993 9.26562C12.6669 9.26562 12.3975 9.53507 12.3975 9.86744V13.7592C12.3975 13.7592 12.3975 13.9161 12.4737 14.0341C12.5247 14.1342 12.6043 14.2212 12.7088 14.2815L15.4891 15.8868C15.777 16.0529 16.145 15.9543 16.3112 15.6664C16.4774 15.3786 16.3788 15.0105 16.0909 14.8444L13.6011 13.4069V9.86744C13.6011 9.53507 13.3317 9.26562 12.9993 9.26562Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div
                style={{
                  width: "87%",
                }}
                className="number-overview-wrap lang-info-num-wrap"
              >
                <strong>Time Slot:</strong>
                {userProfile?.roles?.some(
                  (role) => role.roleType === "admin"
                ) ? (
                  <Select
                    styles={{ marginRight: "10px" }}
                    className="basic-single profile-single-select"
                    classNamePrefix="select"
                    placeholder="Select User Time Slot"
                    value={shift ? { label: shift, value: shift } : null}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    menuPlacement="auto"
                    name="color"
                    options={shiftOptions}
                    components={{ Control: CustomControl }}
                    onChange={(e) => {
                      handleLanguageChange(e.value, e.label);
                    }}
                  />
                ) : (
                  <p>{props?.user?.userInfo?.shiftTitle ? props?.user?.userInfo?.shiftTitle : "No Time Slot Assigned"}</p>
                )}
              </div>
              <div className="clearfix"></div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

const CustomControl = ({ children, ...props }) => (
  <components.Control {...props}>
    {children}
    <div className="select-icon me-1">
      <IoIosArrowDown />
    </div>
  </components.Control>
);

export default PersonalInfo;
