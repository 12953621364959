import { PMBaseApi } from "../../services/PMBaseApi";

export const dashboardSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting team listings
    getDashboardData: builder.query({
      query: () => ({
        url: `timeline/dashboard`, // API endpoint
        method: "POST",
      }),
      transformResponse: (response) => response?.data, // Transform the response to return only the data property
      providesTags: ["dashboardStats"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // Cache data for 12 hours
    }),
  }),
});

// Export the hooks generated by `teamSlice`
export const { useGetDashboardDataQuery } = dashboardSlice;
