// reducer.js
import {
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  UPDATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  CLEAR_CACHED_TASKS,
} from "../constants/taskCachedConstants";

// Initial state for the tasks cache reducer
const initialState = {
  loading: false, // Indicates whether data is being fetched
  taskList: {}, // Stores cached tasks, keyed by limit and page number
  error: null, // Holds any error message if data fetching fails
};

// Reducer function for managing tasks cache state
const tasksCachedReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handles the start of a fetch request
    case FETCH_TASKS_REQUEST:
      return {
        ...state,
        loading: true, // Set loading to true when fetching starts
      };

    // Handles successful data fetching
    case FETCH_TASKS_SUCCESS:
      const { tasks, limit, page } = action.payload; // Extract tasks, limit, and page from action payload
      return {
        ...state,
        loading: false, // Set loading to false when fetching completes
        taskList: {
          ...state.taskList,
          [`${limit}_${page}`]: tasks, // Cache tasks with a key based on limit and page number
        },
        error: null, // Clear any previous errors
      };

    // Handles failed data fetching
    case FETCH_TASKS_FAILURE:
      return {
        ...state,
        loading: false, // Set loading to false when fetching fails
        error: action.payload, // Store the error message from action payload
      };

    // Handles task update
    case UPDATE_TASK_SUCCESS: {
      const { taskId, updatedTask } = action.payload;

      const updatedTaskList = Object.keys(state.taskList).reduce((acc, key) => {
        const updatedTasks = state.taskList[key]?.data?.map((task) =>
          task.id === taskId ? { ...updatedTask } : { ...task }
        );

        return {
          ...acc,
          [key]: {
            ...state.taskList[key],
            data: updatedTasks,
          },
        };
      }, {});

      return {
        ...state,
        taskList: { ...updatedTaskList }, // Ensure taskList is a new object
      };
    }

    // Handles task deletion
    case DELETE_TASK_SUCCESS: {
      const { taskIds } = action.payload; // Extract the array of task IDs from the action payload
      // Iterate through all keys in the taskList and update each one
      const updatedTaskList = Object.keys(state.taskList).reduce((acc, key) => {
        // Filter out tasks that have an ID matching one of the taskIds to be deleted
        const updatedTasks = state.taskList[key]?.data?.filter(
          (task) => !taskIds.includes(task.id)
        );
        // Return the updated taskList object with the current key updated
        return {
          ...acc,
          [key]: {
            ...state.taskList[key], // Spread the existing object to retain other properties
            data: updatedTasks, // Update only the data property with filtered tasks
          },
        };
      }, {});

      return {
        ...state,
        taskList: { ...updatedTaskList }, // Ensure taskList is a new object
      };
    }

    case CLEAR_CACHED_TASKS:
      return {
        ...state,
        loading: false,
        taskList: {},
      };

    // Default case for unrecognized actions
    default:
      return state;
  }
};

export default tasksCachedReducer;
