import { ProjectFormDataAPI } from "../../services/ClientApi";
import { PMBaseApi } from "../../services/PMBaseApi";
import chatApi from "../chat/chatSlice";
import { dashboardSlice } from "../dashboard/dashboardSlice";
import { memberSlice } from "../members/membersSlice";
import { performanceSlice } from "../performance/performanceSlice";
import { projectSlice } from "../projects/projectSlice";
import { timelineSlice } from "../timeline/timelineSilce";
import { userSlice } from "../user/userSlice";
import { completedTasksSlice } from "./completedTasks.Slice";
import { phaseTasksSlice } from "./projectDetailSectionSlice";

export const taskSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the query for getting task listings with pagination
    getTaskListing: builder.mutation({
      query: (body) => ({
        url: `task/list`, // API endpoint
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["taskListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    getTaskList: builder.query({
      query: (body) => ({
        url: `task/list`, // API endpoint
        method: "POST",
        body: body,
      }),
      providesTags: ["taskListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Mutation to create a new task using form data
    createTaskData: builder.mutation({
      // Custom query function to handle the API request
      queryFn: async (formData) => {
        try {
          // Use your original API call to post form data
          const response = await ProjectFormDataAPI.post(
            "task/create-new",
            formData
          );
          return { data: response.data };
        } catch (error) {
          return {
            error: { status: error.response?.status, data: error.message },
          };
        }
      },
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          // Otherwise, invalidate the regular task listing cache
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(projectSlice.util.invalidateTags(["projectListing"]));
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to upgrade task data
    upgradeTaskData: builder.mutation({
      // Custom query function to handle the API request
      queryFn: async (formData) => {
        try {
          // Use your original API call to post form data
          const response = await ProjectFormDataAPI.post(
            "task/upgrade",
            formData
          );
          return { data: response.data };
        } catch (error) {
          return {
            error: { status: error.response?.status, data: error.message },
          };
        }
      },
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        await queryFulfilled; // Await the response after mutation
        dispatch(taskSlice.util.invalidateTags(["taskListing"]));
        dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
        dispatch(
          completedTasksSlice.util.invalidateTags(["completedTaskListing"])
        );
        dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
        dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
        dispatch(
          phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
        );
      },
    }),

    // Mutation to update task data
    updateTaskData: builder.mutation({
      query: (data) => ({
        url: `task/update`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation
          if (
            params?.fieldName === "taskStatusId" ||
            params?.fieldName === "taskTypeId" ||
            params?.fieldName === "taskPriorityId" ||
            params?.fieldName === "taskName" ||
            params?.fieldName === "projectId" ||
            params?.fieldName === "isPined" ||
            params?.fieldName === "taskDeadline"
          ) {
            dispatch(taskSlice.util.invalidateTags(["taskListing"]));
            dispatch(
              phaseTasksSlice.util.invalidateTags(["phaseTasksListing"])
            );
            dispatch(
              completedTasksSlice.util.invalidateTags(["completedTaskListing"])
            );
            dispatch(
              timelineSlice.util.invalidateTags(["timelineOngoingTasks"])
            );
            dispatch(
              phaseTasksSlice.util.invalidateTags(["phaseTasksListing"])
            );
            dispatch(
              phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
            );
            dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
            if (params.fieldName === "taskStatusId") {
              dispatch(projectSlice.util.invalidateTags(["projectListing"]));
            }
          }
        } catch (error) {
          console.error("Error updating task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update bulk task data
    updateBulkTaskData: builder.mutation({
      query: (data) => ({
        url: `task/bulk-actions`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
          if (params.fieldName === "taskStatusId") {
            dispatch(projectSlice.util.invalidateTags(["projectListing"]));
          }
        } catch (error) {
          console.error("Error updating task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update task members
    updateTaskMemberData: builder.mutation({
      query: (data) => ({
        url: `task/member-update`, // API endpoint to update task members
        method: "POST",
        body: data, // Data to be sent in the request body
      }),

      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
        } catch (error) {
          console.error("Error updating task member data:", error); // Log error if mutation fails
        }
      },
    }),

    // Define the delete mutation
    deleteTaskData: builder.mutation({
      query: (taskIds) => ({
        url: `task/bulk-delete`, // API endpoint for bulk delete
        method: "POST",
        body: { taskIds }, // Send the array of IDs in the request body
      }),

      // Hook to handle cache updates and re-fetching after the mutation is successful
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation
          // Invalidate the taskListing query to trigger re-fetching
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(projectSlice.util.invalidateTags(["projectListing"]));
          dispatch(timelineSlice.util.invalidateTags(["timelineOngoingTasks"]));
          dispatch(
            completedTasksSlice.util.invalidateTags(["completedTaskListing"])
          );
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
        } catch (error) {
          console.error("Error deleting task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to clear all cached data
    clearAllCache: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(taskSlice.util.resetApiState());
          dispatch(memberSlice.util.resetApiState());
          dispatch(projectSlice.util.resetApiState());
          dispatch(phaseTasksSlice.util.resetApiState());
          dispatch(chatApi.util.resetApiState());
          dispatch(dashboardSlice.util.resetApiState());
          dispatch(userSlice.util.resetApiState());
          dispatch(performanceSlice.util.resetApiState());
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),

    // Mutation to clear all cached data
    clearAllTasksCache: builder.mutation({
      queryFn: async () => {
        return { data: {} }; // No actual API call
      },
      onQueryStarted: async (_, { dispatch }) => {
        try {
          // Invalidate all relevant tags
          dispatch(taskSlice.util.invalidateTags(["taskListing"]));
          dispatch(phaseTasksSlice.util.invalidateTags(["phaseTasksListing"]));
          dispatch(
            phaseTasksSlice.util.invalidateTags(["projectPhaseTasksListing"])
          );
          dispatch(dashboardSlice.util.invalidateTags(["dashboardStats"]));
        } catch (error) {
          console.error("Error clearing all cache:", error);
        }
      },
    }),

    // Mutation to clear all cached task data
    clearAllTaskCache: builder.mutation({
      query: (data) => ({
        url: `task/update`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      invalidatesTags: [
        "taskListing",
        "phaseTasksListing",
        "timelineOngoingTasks",
      ],
    }),

    // Mutation to clear all cached task data
    clearAllMemberTaskCache: builder.mutation({
      query: (data) => ({
        url: `task/member-update`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      invalidatesTags: [
        "taskListing",
        "phaseTasksListing",
        "timelineOngoingTasks",
        "completedTaskListing",
      ],
    }),

    // Download zip file
    downloadZipAllFiles: builder.mutation({
      query: (body) => ({
        url: `task/attachment-zip`, // API endpoint
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the hooks generated by `taskSlice`
export const {
  useGetTaskListingMutation,
  // useGetTaskListingQuery,
  useUpgradeTaskDataMutation,
  useUpdateTaskDataMutation,
  useUpdateBulkTaskDataMutation,
  useUpdateTaskMemberDataMutation,
  useDeleteTaskDataMutation,
  useCreateTaskDataMutation,
  useClearAllCacheMutation,
  useClearAllTaskCacheMutation,
  useClearAllMemberTaskCacheMutation,
  useDownloadZipAllFilesMutation,
  useGetTaskListQuery,
  useClearAllTasksCacheMutation,
} = taskSlice;
