import { NOTIFICATION_UNREAD_COUNT_FAIL, NOTIFICATION_UNREAD_COUNT_REQUEST, NOTIFICATION_UNREAD_COUNT_SUCCESS } from "../constants/notificationConstants.js";
import API from "../services/ClientApi.js";

export const notificationUnReadCount = () => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_UNREAD_COUNT_REQUEST });
    const { data } = await API.get("users/notification/un-read-count");
    if (data?.status === "success") {
      dispatch({
        type: NOTIFICATION_UNREAD_COUNT_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: NOTIFICATION_UNREAD_COUNT_FAIL,
      payload: error.response.data.message,
      meta: {
        retryAction: notificationUnReadCount(), // Retry action
      },
    });
  }
};