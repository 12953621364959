import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../../assets/infoMain.svg";

const ConfirmDueDateModal = (props) => {
  return (
    <Modal
      show={props.showDueDateModal}
      onHide={props.handleCloseDueDateModal}
      className="main-delete-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} />
        </div>
        <h5>Are you sure you want</h5>
        <h5>to update due date to</h5>

        <p className="del-modal-name">28 Feb, 2028</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={props.handleCloseDueDateModal}
          >
            {/* {dload ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : ( */}
            Update
            {/* )} */}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={props.handleCloseDueDateModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDueDateModal;
