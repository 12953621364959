// src/redux/actions.js
import {
  PROJECT_LISTING_CACHE_FETCH_REQUEST,
  PROJECT_LISTING_CACHE_FETCH_SUCCESS,
  PROJECT_LISTING_CACHE_FETCH_FAILURE,
  PROJECT_LISTING_CACHE_UPDATE_RECORD,
  PROJECT_LISTING_CACHE_DELETE_RECORD,
  PROJECT_LISTING_CACHE_ADD_RECORD,
  CLEAR_PROJECT_LISTING_CACHE,
} from "../constants/ProjectListingCacheConstants";

// Action to start data fetching
export const projectListingCacheFetchRequest = () => ({
  type: PROJECT_LISTING_CACHE_FETCH_REQUEST,
});

// Action for successful data fetch
export const projectListingCacheFetchSuccess = (data, limit, page) => ({
  type: PROJECT_LISTING_CACHE_FETCH_SUCCESS,
  payload: { data, limit, page },
});

// Action for failed data fetch
export const projectListingCacheFetchFailure = (error) => ({
  type: PROJECT_LISTING_CACHE_FETCH_FAILURE,
  payload: error,
});

// Action to update a record in the cache
export const projectListingCacheUpdateRecord = (id, newData) => ({
  type: PROJECT_LISTING_CACHE_UPDATE_RECORD,
  payload: { id, newData },
});

// Action to delete a record from the cache
export const projectListingCacheDeleteRecord = (id) => ({
  type: PROJECT_LISTING_CACHE_DELETE_RECORD,
  payload: id,
});

// Action to add a new record to the cache
export const projectListingCacheAddRecord = (data, limit, page) => ({
  type: PROJECT_LISTING_CACHE_ADD_RECORD,
  payload: { data, limit, page },
});

// Action to clear project cache
export const clearProjectCache = () => ({
  type: CLEAR_PROJECT_LISTING_CACHE
})
