import React, { useCallback, useEffect, useRef, useState } from "react";
import Delete from "../../../assets/delete-icon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import Select, { components } from "react-select";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import CompletedIcon from "../../../assets/completed-icon.png";
import progressImage from "../../../assets/in-progress.svg";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import reviewImage from "../../../assets/on-hold.svg";
import NotStarted from "../../../assets/not-started.svg";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { useUpdateBulkTaskDataMutation } from "../../../slices/tasks/tasksSlice.js";
import {
  getPriorityNameById,
  getStatusNameById,
} from "../../../helper/taskHelpers.js";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "../../../helper/dateFormatter.js";
import ProjectSelectedUserSection from "./ProjectSelectedUserSection.jsx";
import ConfirmBulkAction from "../../Tasks/components/ConfirmBulkAction.jsx";
import { useUpdateBulkProjectDataMutation } from "../../../slices/projects/projectSlice.js";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import API from "../../../services/ClientApi.js";
import { userProfilePage } from "../../../actions/userActions.js";

const ProjectSelectedSection = ({
  selectedProjects,
  taskListToFilter,
  setDeleteSelected,
  setSelectedProjects,
  projectUserList,
  projectStatusList,
  isDark,
  taskPriorities,
  getProjectList,
  userProfile,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [userData, setUserData] = useState(null);
  const [selectedPrivacy, setSelectedPrivacy] = useState(null);
  const projectPublicityList = [
    { label: "Public", value: "public" },
    { label: "Private", value: "private" },
  ];
  const [runTour, setRunTour] = useState(false);

  // Tour steps
  const steps = [
    {
      element: ".selected-items",
      intro: "Total number of selected projects for bulk actions.",
      position: "top",
    },
    {
      element: ".bulk-action-options",
      intro: "Apply any action to the selected project.",
      position: "right",
    },
  ];

  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const [updateBulkProjectData, { isLoading }] =
    useUpdateBulkProjectDataMutation();
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    // Check formData for action types and reset corresponding states
    if (formData.actionType.includes("projectPrivacyChange")) {
      setSelectedPrivacy(null);
    }
    if (formData.actionType.includes("statusChange")) {
      setSelectedTaskStatus(null);
    }
    if (formData.actionType.includes("projectAssignment")) {
      setSelected(null);
    }
    if (formData.actionType.includes("deadlinesChange")) {
      setSelectedDate(null);
    }
    if (formData.actionType.includes("assignedTo")) {
      setUserData(null);
    }
  };

  const handleOpenConfirmModal = () => {
    setShowConfirmModal(true);
  };

  // Bulk actions
  const handleChange = async (data) => {
    setFormData(data);
    handleOpenConfirmModal();
  };

  const handleSubmit = async () => {
    try {
      const response = await updateBulkProjectData(formData);
      if (response?.data?.status === "success") {
        await getProjectList();
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        handleCloseConfirmModal();
      }
    } catch (error) {
      toast.error("An unexpected error occured", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
    }
  };

  const getTaskLabel = (count) => {
    return count === 1 ? "project" : "projects";
  };

  // Custom styles
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected ? "#deebff" : "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      width: "120px", // Set the desired width
    }),
  };

  // Priority selection
  const CustomTasksPriorityEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {taskPriorities?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "critical"
                        ? criticalImage
                        : res?.defaultMarkAs === "highest"
                        ? highestImage
                        : res?.defaultMarkAs === "medium"
                        ? mediumImage
                        : lowImage
                    }
                    alt={res}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      // marginRight: 4,
                      // marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            {children}
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const PrioritySelectSection = () => {
    return (
      <div
        className={`task-situation-filter-top-wrap-select selected-items-priority-section`}
      >
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-situation-filter-top-wrap-select-div`}
            classNamePrefix="select"
            placeholder="Privacy"
            value={selectedPrivacy}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            name="color"
            options={projectPublicityList?.map((res, index) => ({
              label: <div>{res?.label}</div>,
              value: res.value,
            }))}
            components={{ Control: CustomTasksPriorityEditorControl }}
            onChange={(e) => {
              setSelectedPrivacy(e);
              const data = {
                projectsIds: selectedProjects,
                actionType: "projectPrivacyChange",
                actionValue: e.value,
              };
              handleChange(data);
            }}
          />
        </div>
      </div>
    );
  };

  // Status selection
  const CustomTasksStatusEditorControl = ({
    children,
    menuIsOpen,
    ...props
  }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {projectStatusList?.map((res, index) => {
              if (res?.id === props?.getValue()[0]?.value) {
                return (
                  <img
                    key={index}
                    src={
                      res?.defaultMarkAs === "not-started"
                        ? NotStarted
                        : res?.defaultMarkAs === "in-progress"
                        ? progressImage
                        : res?.defaultMarkAs === "on-hold"
                        ? reviewImage
                        : res?.defaultMarkAs === "review"
                        ? reviewIcon
                        : CompletedIcon
                    }
                    alt={res?.statusName}
                    style={{
                      width: 20,
                      marginLeft: 5,
                      // marginRight: 4,
                      // marginTop: 1,
                    }}
                  />
                );
              }
              return null;
            })}
            {children}
            {/* <p className="mb-0 selected-item-text py-0 ps-1 pe-2">
              {getStatusNameById(
                projectStatusList,
                props?.getValue()[0]?.value
              )}
            </p> */}
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  const StatusSelectSection = () => {
    return (
      <div
        className={`d-flex align-items-center task-situation-filter-top-wrap-select selected-items-priority-section`}
      >
        {/* {!selectedTaskStatus && <Icon icon="mdi:circle-double" />} */}
        <div className="form-group">
          <Select
            className={`basic-single task-filter-select task-situation-filter-top-wrap-select-div task-${selectedTaskStatus?.toLowerCase()}-select select-item-status`}
            classNamePrefix="select"
            placeholder="Status"
            value={
              selectedTaskStatus
                ? {
                    label: getStatusNameById(
                      projectStatusList,
                      selectedTaskStatus
                    ),
                    value: selectedTaskStatus,
                  }
                : null
            }
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            styles={customStyles}
            name="color"
            options={projectStatusList?.map((res, index) => ({
              label: (
                <div className="">
                  {" "}
                  {res?.defaultMarkAs === "not-started" && (
                    <img
                      src={NotStarted}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "in-progress" && (
                    <img
                      src={progressImage}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "on-hold" && (
                    <img
                      src={reviewImage}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "review" && (
                    <img
                      src={reviewIcon}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.defaultMarkAs === "completed" && (
                    <img
                      src={CompletedIcon}
                      alt="Critical"
                      width={20}
                      height={20}
                      className="me-1"
                    />
                  )}
                  {res?.statusName}
                </div>
              ),
              value: res?.id,
            }))}
            components={{ Control: CustomTasksStatusEditorControl }}
            onChange={(e) => {
              setSelectedTaskStatus(e.value);
              const data = {
                projectsIds: selectedProjects,
                actionType: "statusChange",
                actionValue: e.value,
              };
              handleChange(data);
            }}
          />
        </div>
      </div>
    );
  };

  // Delete section
  const DeleteSection = () => {
    return (
      <div
        className="d-flex align-items-center selected-items-delete-section"
        onClick={() => setDeleteSelected(true)}
      >
        <img
          src={Delete}
          style={{
            width: "20px",
            height: "20px",
            marginRight: "3px",
          }}
          alt="delete-task-icon"
        />{" "}
        <p className="mb-0">Delete</p>
      </div>
    );
  };

  // Project selection
  const getAllProjects = async () => {
    if (projectFormList?.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.id,
        }))
      );
    }
  };

  // Date Selection
  const fullDateToString = (inputDateString) => {
    if (!inputDateString) {
      return ""; // Return an empty string or handle accordingly if no date is provided
    }

    const inputDate = new Date(inputDateString);

    // Check if inputDate is valid
    if (isNaN(inputDate.getTime())) {
      return ""; // Return empty if date is invalid
    }

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based
    const year = inputDate.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const DatePickerSection = () => {
    const datePickerRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const iconRef = useRef(null);

    const handleIconClick = () => {
      setShowDropdown(!showDropdown);
    };

    const handleDateChange = async (date) => {
      setSelectedDate(date);
      setTimeout(() => {
        const data = {
          projectsIds: selectedProjects,
          actionType: "deadlinesChange",
          actionValue: fullDateToString(date),
        };
        handleChange(data);
      }, 0); // Execute after React's state updates
    };

    // Close dropdown on outside click
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        } else if (
          datePickerRef.current &&
          datePickerRef.current.contains(event.target)
        ) {
          setShowDropdown((prev) => !prev);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div
        data-theme={isDark}
        className="project-details-main-date-filter d-flex align-items-center justify-content-between position-relative"
        // onClick={handleIconClick}
      >
        {/* Icon */}
        <div
          ref={datePickerRef}
          className="d-flex align-items-center select-item-date-bulk"
        >
          <span>
            <Icon icon="lets-icons:date-range" ref={iconRef} />
          </span>
          <p className="p-0 m-0" style={{ fontWeight: 300 }}>
            {selectedDate ? dateFormatter(selectedDate[0], 1) : "Due Date"}
          </p>
          <div
            className="select-icon me-1 select-icon-date"
            style={{
              transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <IoIosArrowDown />
          </div>
        </div>
        {/* Dropdown */}
        {showDropdown && (
          <div
            ref={dropdownRef}
            className="custom-date-input-select"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "46px",
              left: 0,
            }}
            // Prevent event bubbling up from inside dropdown
            onClick={(e) => e.stopPropagation()}
          >
            <Flatpickr
              value={selectedDate}
              onChange={handleDateChange}
              options={{
                minDate: new Date(),
                altFormat: "M d, Y", // Optional: Alternative display format
                dateFormat: "Y-m-d", // Actual value format
                defaultDate: new Date(), // Set default date to today
                allowInput: false, // Disable manual input
                altInput: false, // Prevent rendering of alternative input
                inline: true, // Keep calendar always visible
                disableMobile: true, // Ensure desktop-like experience on mobile
              }}
            />
          </div>
        )}
      </div>
    );
  };

  // Use effects
  useEffect(() => {
    getAllProjects().catch((error) => {
      console.error("Error fetching projects:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFormList]);

  // Run tour journey
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    if (selectedProjects?.length > 0 && userProfile?.projectsSelectedTour) {
      // Scroll `.dashboard-main-wraper` to the top
      const dashboardWrap = document.querySelector(".dashboard-main-wraper");
      if (dashboardWrap) {
        dashboardWrap.scrollTo({ top: 0, behavior: "smooth" });

        // Monitor if the scroll position reaches the top
        const interval = setInterval(() => {
          if (dashboardWrap.scrollTop === 0) {
            setRunTour(true);
            clearInterval(interval);
          }
        }, 50); // Check every 50ms
      }
    }
  }, [userProfile]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;
    try {
      setRunTour(false); // Close the tour
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "projectsSelectedTour", // Add the field name for the specific input field
        fieldValues: false, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [dispatch, userProfile?.userId]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton")
        // ||
        // event.target.classList.contains("introjs-donebutton")
      ) {
        await updateProfile();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
  }, []);

  return (
    <div className="ms-4 d-flex align-items-center flex-wrap selected-tasks-wrap">
      {runTour && (
        <Steps
          enabled={runTour}
          steps={steps.filter((step) => document.querySelector(step.element))}
          initialStep={0}
          options={{
            showProgress: false,
            showStepNumbers: false,
            exitOnOverlayClick: false,
            scrollToElement: false,
            exitOnEsc: true,
            nextLabel: "Next",
            prevLabel: "Skip Tour",
            tooltipClass: "tour-custom-tooltip", // Optional custom styling
            disableInteraction: true,
          }}
          onExit={async () => {
            if (localStorage.getItem("access_token")) {
              await updateProfile();
            }
          }} // Reset the tour state when exited
        />
      )}
      <div className="d-flex align-items-center selected-items">
        <p className="m-0">
          {`${
            selectedProjects.length < 10
              ? `0${selectedProjects.length}`
              : selectedProjects.length
          } ${getTaskLabel(selectedProjects.length)}`}{" "}
          selected
        </p>
        <Icon
          icon="akar-icons:cross"
          width={12}
          height={12}
          onClick={() => setSelectedProjects([])}
        />
      </div>
      <div className="bulk-action-options d-flex flex-wrap align-items-center selected-bulk-options">
        <StatusSelectSection />
        <PrioritySelectSection />
        <ProjectSelectedUserSection
          isDark={isDark}
          projectUserList={projectUserList}
          handleBulkChange={handleChange}
          selectedProjects={selectedProjects}
          data={userData}
          setData={setUserData}
        />
        <DatePickerSection />
        <DeleteSection />
        {/* {isLoading && <Spinner size="sm" className="global-spinner" />} */}
      </div>
      <ConfirmBulkAction
        showConfirmModal={showConfirmModal}
        handleCloseConfirmModal={handleCloseConfirmModal}
        isDark={isDark}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        formData={formData}
        projectOptions={projectOptions}
        projectUserList={projectUserList}
        projectStatusList={projectStatusList}
        taskPriorities={taskPriorities}
      />
    </div>
  );
};

export default ProjectSelectedSection;
