import React from "react";
import Modal from "react-bootstrap/Modal";
import "../styles/inviteModal.css";
import InviteModalAdmin from "./InviteModalAdmin";
import InviteModalMember from "./InviteModalMember";
import { Icon } from "@iconify/react/dist/iconify.js";

const InviteModal = (props) => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.handleCloseModal}
      className="main-update-modal"
      centered
      data-theme={props.isDark}
    >
      {/* <Modal.Header className="update-modal-header"></Modal.Header>
       */}{" "}
      {/* <div> */}
        <button
          className="custom-modal-close-btn align-self-end"
          onClick={props.handleCloseModal}
        >
          <Icon icon="maki:cross" />
        </button>
      {/* </div> */}
      <Modal.Body className="update-modal-body">
        {props?.isAdmin ? (
          <InviteModalAdmin
            addonModal={props.addonModal}
            handleAddonShow={props.handleAddonShow}
            handleAddonClose={props.handleAddonClose}
            close={props.handleCloseModal}
            setShowAddModal={props.setShowAddModal}
          />
        ) : (
          <InviteModalMember
            addonModal={props.addonModal}
            handleAddonShow={props.handleAddonShow}
            handleAddonClose={props.handleAddonClose}
            close={props.handleCloseModal}
            setShowAddModal={props.setShowAddModal}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InviteModal;
