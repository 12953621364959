import React, { useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import CloseBtn from "../../../../assets/icon-close.svg";
import PlanProjectPhaseDetailSection from "./PlanProjectPhaseDetailSection";

const PlanProjectPhaseMain = (props) => {
  const [initLoad] = useState(false);
  const [modal, setModal] = useState(props.modal ? props.modal : false);
  // create state for newly created project with name newlyCreatedProject
  const [newlyCreatedProject] = useState({
    projectId: props.projectId,
    projectName: props.projectName,
  });

  const handleClose = () => {
    props.handleCloseModal();
  };

  return (
    <Offcanvas
      className={`off-concave-role ${
        modal ? "off-concave-proj-modal" : ""
      } offconcave-phase-detail`}
      show={props.show}
      // onHide={() => {
      //   if (props.creation) {
      //     setModal(false);
      //   }
      //   handleClose();
      // }}
      placement="end"
      data-theme={props.isDark}
    >
      <Offcanvas.Body>
        {initLoad ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner animation="border" className="global-spinner"></Spinner>
          </div>
        ) : (
          <div>
            <PlanProjectPhaseDetailSection
              projectId={props.projectId}
              project={newlyCreatedProject}
              projectData={props.project}
              projectName={props.projectName}
              isDark={props.isDark}
              setModal={setModal}
              handleCloseModal={handleClose}
              isHideCreateAndSkip={true}
              overAllProgress={props.overAllProgress}
              updatePhaseTaskData={props.updatePhaseTaskData}
              updatePhaseTaskMemberData={props.updatePhaseTaskMemberData}
              setSelectedPhaseIdChange={props.setSelectedPhaseIdChange}
              isUpdating={props.isUpdating}
              isMemberLoading={props.isMemberLoading}
              memberSuccess={props.memberSuccess}
              getTaskList={() => props.getTaskList(false)}
              setReadLoad={props.setReadLoad}
              setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
            />
            <button
              className="btn-role-concave"
              onClick={() => {
                if (props.creation) {
                  setModal(false);
                }
                handleClose();
              }}
            >
              <img src={CloseBtn} alt="close-btn" />
            </button>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PlanProjectPhaseMain;
