import React, { memo } from "react";
import "./style.css";
import StaffSummaryMain from "./components/StaffSummaryMain";

const StaffSummary = memo((props) => {
  return (
    <StaffSummaryMain
      props={props}
      setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
      openSubscriptionModal={props.openSubscriptionModal}
    />
  );
});
export default StaffSummary;
