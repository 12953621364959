import React, { useState, useRef } from "react";
import { ProjectAPI, ProjectFormDataAPI } from "../../../services/ClientApi";
import DeleteCommentModal from "./DeleteCommentModal";
import { isPermitted } from "../../../helper/isPermitted";
import BulbIcon from "../../../assets/bulb.png";
import { Modal, Spinner } from "react-bootstrap";
import CommentRepliesSection from "./CommentRepliesSection";
import TiptapEditor from "./TiptapEditor";
import { Icon } from "@iconify/react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { getFileType } from "../../../helper/getFileType";
import { handleDownloadDocFiles } from "../../../helper/downloadFile";
import { toast } from "react-toastify";
import { calculateTotalSize } from "./CreateTaskModal";

const CommentBox = ({
  comment,
  taskId,
  comments,
  setComments,
  isDark,
  userId,
  selectedTask,
  commId,
  setMentionedUserIds,
  mentionedUserIds,
  highlightText,
  commentContent,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updatedText, setUpdatedText] = useState(comment.text);
  const [load, setLoad] = useState(false);
  const [replyMode, setReplyMode] = useState(false); // Track reply editor state
  const [replyText, setReplyText] = useState(""); // Track reply content
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [repliesSelectedFiles, setRepliesSelectedFiles] = useState([]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleReply = () => {
    setReplyMode(true);
  };

  const handleCancelReply = () => {
    setReplyText("");
    setReplyMode(false);
    setMentionedUserIds([]);
    setRepliesSelectedFiles([]);
  };

  // Add reply to a comment
  const handleCommentReply = async (e, parentCommentId, type = null) => {
    setLoad(true);
    try {
      // setCommentLoad(true);
      const formData = new FormData();
      formData.append("taskId", selectedTask);
      formData.append("comments", e.text);
      formData.append("parentCommentId", e.repliedToCommentId);
      formData.append("mentionedUsersIds", mentionedUserIds?.join(","));

      // Append each file in the attachments array to the formData
      e?.attachments?.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      const response = await ProjectAPI.post("task/add-comment", formData);

      const newReply = {
        comId: response.data.data.id,
        userId: response.data.data.userDetails.userId,
        fullName: response.data.data.userDetails.userName,
        avatarUrl: response.data.data.userDetails.profileImage,
        text: response.data.data.commentText,
        createdAt: response.data.data.createdDate,
        isEdited: response?.data.data?.isEdited,
        attachments: response?.data.data?.attachments,
        replies: [],
      };

      // Update the specific comment's replies
      const updateReplies = (commentsList) =>
        commentsList.map((comment) => {
          if (comment.comId === parentCommentId) {
            // If parentCommentId matches, check for repliedToCommentId
            if (!comment.replies) {
              // If no replies, initialize and add newReply
              return {
                ...comment,
                replies: [newReply],
              };
            }

            const updatedReplies = [];
            let isReplyAdded = false;

            comment.replies.forEach((reply) => {
              updatedReplies.push(reply);

              // Check if this reply matches repliedToCommentId
              if (reply.comId === e.repliedToCommentId && !isReplyAdded) {
                updatedReplies.push(newReply); // Add newReply just below
                isReplyAdded = true;
              }
            });

            // If repliedToCommentId wasn't found, add newReply at the beginning
            if (!isReplyAdded) {
              updatedReplies.unshift(newReply);
            }

            return {
              ...comment,
              replies: updatedReplies,
            };
          } else if (comment.replies) {
            // Recurse through nested replies
            return {
              ...comment,
              replies: updateReplies(comment.replies),
            };
          }
          return comment;
        });

      setComments(updateReplies(comments));
    } catch (error) {
      console.error("Failed to add reply", error);
    } finally {
      setLoad(false);
      handleCancelReply();
      setMentionedUserIds([]);
    }
  };

  const getInitials = (name) => {
    const words = name?.split(" ");
    let initials;
    if (words?.length === 1) {
      initials = name?.substring(0, 2)?.toUpperCase();
    } else {
      initials = words?.map((word) => word?.charAt(0)?.toUpperCase())?.join("");
      initials = initials?.substring(0, 2);
    }
    return initials;
  };

  const handleEditTask = async (id, text) => {
    try {
      setLoad(true);
      let formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("commentId", id);
      formData.append("comments", text);

      // Transform mentionedUserIds array into a comma-separated string
      const mentionIdsString = mentionedUserIds.join(",");
      formData.append("mentionedUsersIds", mentionIdsString);

      // eslint-disable-next-line no-unused-vars
      const resp = await ProjectFormDataAPI.post(
        "task/update-comment",
        formData
      );

      // Helper function to recursively find and update a reply
      const updateCommentOrReply = (commentsList) =>
        commentsList.map((comment) => {
          if (comment.comId === id) {
            // If the comment is found, update its text
            return {
              ...comment,
              text,
              isEdited: resp?.data?.data?.isEdited,
            };
          } else if (comment.replies) {
            // If not found, check replies recursively
            return {
              ...comment,
              replies: updateCommentOrReply(comment.replies),
            };
          }
          return comment;
        });

      // Update the comments array using the helper function
      const updatedComments = updateCommentOrReply(comments);

      // Update the state with the updated comments array
      setComments(updatedComments);
    } catch (error) {
      console.error("Error updating comment:", error);
    } finally {
      setLoad(false);
      setEditMode(false);
      setMentionedUserIds([]);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setUpdatedText(comment.text);
    setEditMode(false);
    setMentionedUserIds([]);
  };

  const handleSaveEdit = async () => {
    if (updatedText.trim() === "" && !load) {
      // Prevent saving empty comment
      setUpdatedText(comment.text);
      setEditMode(false);
    } else {
      handleEditTask(comment.comId, updatedText);
    }
  };

  // Function to calculate duration ago
  function calculateDurationAgo(dateString) {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - pastDate;

    // Convert milliseconds to seconds, minutes, hours, days, weeks, months, and years
    const seconds = Math.floor(differenceMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    // Determine which unit to display
    if (years > 0) {
      return years + " years ago";
    } else if (months > 0) {
      return months + " months ago";
    } else if (weeks > 0) {
      return weeks + " weeks ago";
    } else if (days > 0) {
      return days + " days ago";
    } else if (hours > 0) {
      return hours + " hours ago";
    } else if (minutes > 0) {
      return minutes + " minutes ago";
    } else if (seconds >= 1) {
      return seconds + " seconds ago";
    } else {
      return "Just now";
    }
  }

  const sanitizeHtml = (html) => {
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Select all <p> tags and remove those that only contain &nbsp;
    const emptyParagraphs = doc.querySelectorAll("p");
    emptyParagraphs.forEach((p) => {
      if (p.innerHTML.trim() === "&nbsp;" || p.innerHTML.trim() === "") {
        p.remove(); // Remove the empty <p> tag
      }
    });

    // Return the sanitized HTML string
    return doc.body.innerHTML;
  };

  const sanitizedCommentText = sanitizeHtml(comment.text);

  const getFileType = (attachmentName) => {
    const extension = attachmentName?.split(".")?.pop()?.toLowerCase();
    return extension;
  };

  const handleFileClick = (attachment) => {
    const fileType = getFileType(attachment.attachmentName);
    const fileViewerUrl = getFileViewerUrl(fileType, attachment.attachmentPath);
    window.open(fileViewerUrl, "_blank", "noopener,noreferrer");
  };

  const getFileViewerUrl = (fileType, fileUrl) => {
    return fileViewers[fileType]
      ? fileViewers[fileType](fileUrl)
      : fileViewers.default(fileUrl);
  };

  const handleThumbnailClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowModal(true);
  };

  const handleCloseFileModal = () => setShowModal(false);

  const fileViewers = {
    image: (url) => url,
    video: (url) => url,
    pdf: (url) => url,
    doc: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    docx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xls: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    xlsx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    ppt: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    pptx: (url) => `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
    default: (url) => url, // Fallback for other file types
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const totalSize = calculateTotalSize(files);

    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const validFiles = files.filter((file) => validateFile(file));
    if (validFiles.length > 0) {
      setRepliesSelectedFiles((prev) => [...prev, ...validFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const validateFile = (file) => {
    const allowedExtensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "doc",
      "docx",
      "txt",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "gif",
      "bmp",
      "svg",
      "zip",
      "rar",
      "mp3",
      "mp4",
    ];
    const maxFileSize = 8 * 1024 * 1024; // Max size: 8 MB
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(`Invalid file type: .${fileExtension}.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    if (file.size > maxFileSize) {
      toast.error("File size exceeds the limit of 8 MB.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  const handleFileChange = (files) => {
    const acceptedFiles = Array.from(files);

    const totalSize = calculateTotalSize(acceptedFiles);
    if (totalSize > 8 * 1024 * 1024) {
      toast.error(
        "The total size of all files exceeds 8MB. Please remove some files.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const filteredFiles = acceptedFiles.filter((file) => validateFile(file));

    if (filteredFiles.length > 0) {
      setRepliesSelectedFiles((prev) => [...prev, ...filteredFiles]);
    }
  };

  return (
    <div className="d-flex gap-2 mt-2 comment-box">
      <div className="comment-user-image-container">
        {comment?.avatarUrl ? (
          <img src={comment?.avatarUrl} alt="comment-user-profile-name" />
        ) : (
          <div className="user-comment-name">
            {getInitials(comment?.fullName)}
          </div>
        )}
      </div>
      <div className="w-100 d-flex flex-column comment-box-text">
        <p className="comment-user-name">
          {comment?.fullName}
          {comment?.isEdited && (
            <span className="ms-2 is-comment-edited-text">Edited</span>
          )}
          <span className="ms-2 comment-date">
            {calculateDurationAgo(comment.createdAt)}
          </span>
        </p>
        {editMode ? (
          <div className="comment-editor-mce">
            <TiptapEditor
              value={comment?.text}
              disabled={load}
              onChange={(content) => setUpdatedText(content)}
              onKeyDown={handleSaveEdit}
              setMentionedUserIds={setMentionedUserIds}
              onFileChange={handleFileChange}
              isFileUpload={true}
            />
          </div>
        ) : (
          <div
            className="comment-user-comment"
            // dangerouslySetInnerHTML={{ __html: sanitizedCommentText }}
          >
            {highlightText(sanitizedCommentText, commentContent)}
          </div>
        )}

        {/* Attachments Section */}
        {comment?.attachments && comment.attachments.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "4px",
            }}
            className="attachment-files-main-wrap"
          >
            {comment.attachments.map((attachment, index) => (
              <div
                style={{ width: "max-content", cursor: "pointer" }}
                key={index}
                className="file-attachment-wrap bg-white"
                onClick={() => handleFileClick(attachment)}
              >
                <div className="file-attachment-icon">
                  {getFileType(attachment.attachmentName) === "image" ? (
                    <img
                      className="thumbnail"
                      src={attachment.attachmentPath}
                      alt={attachment.attachmentName}
                      onClick={() => handleThumbnailClick(attachment)}
                    />
                  ) : (
                    <FileIcon
                      extension={getFileType(attachment.attachmentName)}
                      {...defaultStyles[getFileType(attachment.attachmentName)]}
                    />
                  )}
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h6>
                    {attachment.attachmentName.length > 15
                      ? attachment.attachmentName.substring(0, 15) + "..."
                      : attachment.attachmentName}
                  </h6>
                  <div className="d-flex align-items-center gap-2 view-download-container">
                    <p onClick={() => handleFileClick(attachment)}>View</p>
                    <span></span>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDownloadDocFiles(
                          attachment.attachmentPath,
                          getFileType(attachment.attachmentPath)
                        );
                      }}
                    >
                      Download
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {!replyMode && (
          <div
            className={`d-flex align-items-center gap-2 w-100 edit-comment-section mt-2 ${
              editMode ? "mt-2" : ""
            }`}
          >
            {!editMode && (
              <span onClick={handleReply} className="reply-comment-btn">
                Reply
              </span>
            )}
            {!editMode &&
              isPermitted("task/update-comment") &&
              userId === comment?.userId && (
                <>
                  <span>.</span>
                  <span onClick={handleEdit}>Edit</span>
                </>
              )}
            {!editMode && (
              <>
                {isPermitted("task/update-comment") &&
                  isPermitted("task/delete-comment") &&
                  userId === comment?.userId && <span>.</span>}
                {isPermitted("task/delete-comment") &&
                  userId === comment?.userId && (
                    <span
                      className="delete-comment-btn"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </span>
                  )}
              </>
            )}
            {editMode && (
              <div className="w-100 d-flex flex-md-row flex-column align-items-md-center align-items-end justify-content-between py-2">
                <p className="align-self-start mb-0 ctrl-enter-text">
                  <img src={BulbIcon} alt="bulb-icon" width={24} height={24} />{" "}
                  Ctrl+Enter to "add comment"
                </p>
                <div className="d-flex align-items-center gap-2">
                  <button
                    onClick={handleSaveEdit}
                    className="comment-field-submit-btn comment-field-post-button"
                  >
                    Save{" "}
                    {load && (
                      <span>
                        <Spinner size="sm" className="ms-2" />
                      </span>
                    )}
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="comment-field-cancel-btn comment-field-submit-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {replyMode && (
          <div
            className="reply-editor-mce mt-2"
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
          >
            <TiptapEditor
              value={replyText}
              disabled={load}
              onChange={(content) => setReplyText(content)}
              onKeyDown={() => {
                handleCommentReply(
                  {
                    text: replyText,
                    repliedToCommentId: comment.comId,
                    attachments: repliesSelectedFiles,
                  },
                  comment.comId
                );
              }}
              setMentionedUserIds={setMentionedUserIds}
              onFileChange={handleFileChange}
              isFileUpload={true}
            />
            <div className="w-100 d-flex flex-md-row flex-column align-items-md-center align-items-end justify-content-between py-2">
              <p className="align-self-start mb-0 ctrl-enter-text">
                <img src={BulbIcon} alt="bulb-icon" width={24} height={24} />{" "}
                Ctrl+Enter to "add comment"
              </p>
              <div className="d-flex align-items-center gap-2">
                <button
                  onClick={() =>
                    handleCommentReply(
                      {
                        text: replyText,
                        repliedToCommentId: comment.comId,
                        attachments: repliesSelectedFiles,
                      },
                      comment.comId
                    )
                  }
                  className="comment-field-submit-btn comment-field-post-button"
                >
                  Post {load && <Spinner size="sm" className="ms-2" />}
                </button>
                <button
                  onClick={handleCancelReply}
                  className="comment-field-cancel-btn comment-field-submit-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
            {repliesSelectedFiles.length > 0 && (
              <div className="create-task-modal-uploaded-files">
                <h6>Attached Files with replies</h6>
                {repliesSelectedFiles.map((file, index) => (
                  <div key={index} className="file-item-one">
                    <span>
                      <Icon
                        icon="mingcute:check-fill"
                        className="me-1"
                        width={16}
                        height={16}
                      />{" "}
                      {file.name} ({(file.size / 1024).toFixed(2)} KB)
                    </span>
                    <button
                      className="remove-uploaded-file-button"
                      onClick={() => {
                        setRepliesSelectedFiles((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <Icon icon="maki:cross" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {comment?.replies && comment?.replies.length > 0 && (
          <div className="reply-comment-box ps-3">
            {comment?.replies?.map((reply, id) => (
              <CommentRepliesSection
                key={id}
                comment={reply}
                getInitials={getInitials}
                calculateDurationAgo={calculateDurationAgo}
                comments={comments}
                setComments={setComments}
                taskId={taskId}
                isDark={isDark}
                userId={userId}
                sanitizedCommentText={sanitizeHtml(reply.text)}
                handleEditTask={handleEditTask}
                handleReplyTask={handleCommentReply}
                parentId={commId}
                setMentionedUserIds={setMentionedUserIds}
                mentionedUserIds={mentionedUserIds}
                highlightText={highlightText}
                commentContent={commentContent}
              />
            ))}
          </div>
        )}
      </div>
      <Modal
        className="file-viewer-modal"
        show={showModal}
        onHide={handleCloseFileModal}
        dialogClassName="modal-90w"
        data-theme={isDark}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAttachment?.attachmentName &&
            selectedAttachment?.attachmentName.length > 15
              ? selectedAttachment?.attachmentName.substring(0, 15) + "..."
              : selectedAttachment?.attachmentName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAttachment &&
          getFileType(selectedAttachment?.attachmentName) === "image" ? (
            <img
              src={selectedAttachment?.attachmentPath}
              alt="Attachment"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          ) : (
            <iframe
              src={selectedAttachment?.attachmentPath}
              title="Attachment Viewer"
              width="100%"
              height="500px"
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="file-viewer-btn" onClick={handleCloseFileModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {showDeleteModal && (
        <DeleteCommentModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          comments={comments}
          setComments={setComments}
          taskId={taskId}
          commentId={comment.comId}
          isDark={isDark}
        />
      )}
    </div>
  );
};

export default CommentBox;
