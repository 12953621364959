import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeroIcon from "../../../assets/hero-image.png";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";

const ChooseDepartment = (props) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const handleDepartmentChange = (industry) => {
    setSelectedDepartment(industry);
  };

  const handleSubmit = async () => {
    props.setUser({
      ...props.user,
      industry: selectedDepartment,
    });
    sessionStorage.setItem(
      "user",
      JSON.stringify({
        ...props.user,
        industry: selectedDepartment,
      })
    );
    navigate(`/${localStorage.getItem("company")}/invite-team`);
  };

  return (
    <section className="signup-main-wraper dep-main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    <img src={HeroIcon} alt="hero-icon" />{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>Your online business partners</h2>
                          <p>All of your operations on just a click away.</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="choose-industry-wraper">
              <div className="funkyradio">
                <h3> Choose department </h3>
                <p>Your department is</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio1"
                        checked={selectedDepartment === 0}
                        onChange={() => handleDepartmentChange(0)}
                      />
                      <label for="radio1">Task Management</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio8"
                        checked={selectedDepartment === 1}
                        onChange={() => handleDepartmentChange(1)}
                      />
                      <label for="radio8">CRM</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio3"
                        checked={selectedDepartment === 2}
                        onChange={() => handleDepartmentChange(2)}
                      />
                      <label for="radio3">HR</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio4"
                        checked={selectedDepartment === 3}
                        onChange={() => handleDepartmentChange(3)}
                      />
                      <label for="radio4">Booking System</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio5"
                        checked={selectedDepartment === 4}
                        onChange={() => handleDepartmentChange(4)}
                      />
                      <label for="radio5">Accounts</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default">
                      <input
                        type="radio"
                        name="radio"
                        id="radio6"
                        checked={selectedDepartment === 5}
                        onChange={() => handleDepartmentChange(5)}
                      />
                      <label for="radio6">Goals</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default radio-continue">
                      <input
                        type="radio"
                        name="radio"
                        id="radio7"
                        checked={selectedDepartment === 6}
                        onChange={() => handleDepartmentChange(6)}
                      />
                      <label for="radio7">Marketing</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="funkyradio-default radio-continue">
                      <input
                        type="radio"
                        name="radio"
                        id="radio9"
                        checked={selectedDepartment === 7}
                        onChange={() => handleDepartmentChange(7)}
                      />
                      <label for="radio9">Supply Chain</label>
                    </div>
                  </div>
                </div>
                <div className="choose-continue-btn choose-continue-btn-margin">
                  <div className="next-button-wraper">
                    <button onClick={handleSubmit}> Continue </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseDepartment;
