import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";
import { ShimmerButton } from "react-shimmer-effects";
import { useSelector } from "react-redux";

const ChangeUserRole = (props) => {
  const { userProfile } = useSelector((state) => state?.profile);
  const [modLoad, setModLoad] = useState(true);
  const [selectedRole, setSelectedRole] = useState(props?.roles[0]?.roleId);
  const [selectedRoleName, setSelectedRoleName] = useState(
    props?.roles[0]?.roleName
  );
  const [rload, setRLoad] = useState(false);
  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };

  const handleSubmit = async () => {
    try {
      setRLoad(true);
      const response = await API.post("users/role-assign", {
        roleId: selectedRole,
        userId: props?.user?.userInfo?.userId,
      });
      if (response?.data?.status === "success") {
        props?.getUser();
        props.handleCloseAddRoleModal();
        toast.success("Role updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        if (props?.user?.userInfo?.userId === userProfile?.userId) {
          const company = localStorage.getItem("company");
          window.location.replace(`/${company}/dashboard`);
        }
      }
    } catch (error) {
      // console.log("Err : ", error);
    } finally {
      setRLoad(false);
    }
  };

  useEffect(() => {
    const getModule = async () => {
      try {
        const responseDept = await API.get("company/modules-categories");
        if (responseDept?.data?.status === "success") {
          const companyModules = responseDept?.data?.data?.companyModules;

          // Check if props.user is available and has roles
          if (props.user) {
            // Iterate over companyModules
            const updatedArray2 = companyModules.map((category) => {
              const matchingModule = props?.user?.userRolesAndModules.find(
                (module) => {
                  return module.moduleCategories.some(
                    (cat) => cat.moduleId === category.moduleCategoryId
                  );
                }
              );

              if (matchingModule) {
                category.roleId = matchingModule.roleId;
              }

              return category; // Make sure to return the modified category
            });
            handleSelectRole(updatedArray2[0]?.roleId);
            const newRoleName = props?.roles?.find((item, index) => {
              if (item.roleId === updatedArray2[0]?.roleId) {
                return item;
              }
            });
            setSelectedRoleName(newRoleName?.roleName);
          }
        }
      } catch (error) {
      } finally {
        setModLoad(false);
      }
    };
    getModule();
  }, [props.user]);

  return (
    <Modal
      show={props.showAddRoleModal}
      onHide={props.handleCloseAddRoleModal}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="w-100 modal-role-wrapper">
          <h6>Change Role</h6>
          {modLoad ? (
            <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
            </div>
          ) : (
            <div className="w-100 roles-opt-wrapper mt-2">
              {props.roles?.map((rol, id) => (
                <div
                  className={`${
                    selectedRole === rol?.roleId ? "role-selected-modal" : ""
                  }`}
                  key={id}
                  onClick={() => {
                    handleSelectRole(rol?.roleId);
                    setSelectedRoleName(rol?.roleName);
                  }}
                >
                  {rol?.roleName.charAt(0).toUpperCase() +
                    rol?.roleName.slice(1)}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-100 mt-4 mb-4">
          {selectedRoleName?.toString()?.toLowerCase() === "admin" && (
            <p className="text-danger text-xs change-role-note m-0 p-0">
              <strong>Note:</strong> You set your role as admin which results in
              full access over all modules with rights and permissions.
            </p>
          )}
          {selectedRoleName?.toString()?.toLowerCase() !== "admin" && (
            <p className="text-danger text-xs change-role-note m-0 p-0">
              <strong>Note:</strong> Applying new role to all modules overwrite
              old permissions.
            </p>
          )}
        </div>
        <div className="w-100 modal-btn-wrapper d-flex mt-4 justify-content-end">
          <button onClick={handleSubmit} disabled={modLoad}>
            {rload ? (
              <Spinner className="spinner-role" border="animation" />
            ) : (
              "Change Role"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUserRole;
