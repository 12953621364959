import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import CloseBtn from "../../../assets/icon-close.svg";
import "../styles/tasks.scss";
import { Tooltip } from "react-tooltip";
import infoIcon from "../../../assets/info.svg";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import plusIcon from "../../../assets/plus.svg";
import Delete from "../../../assets/delete.svg";
import MultiSelect from "../../../components/MultiSelect";
import InfoIcon from "../../../assets/info.svg";
import { useDispatch } from "react-redux";
import criticalImage from "../../../assets/critical.svg";
import highestImage from "../../../assets/highest.svg";
import mediumImage from "../../../assets/medium.svg";
import lowImage from "../../../assets/low.svg";
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from "react-select";
import { ProjectAPI, ProjectFormDataAPI } from "../../../services/ClientApi";
import ProgressIcon from "../../../assets/progress.svg";
import doneImage from "../../../assets/completed-icon.png";
import reviewImage from "../../../assets/on-hold.png";
import reviewIcon from "../../../assets/submit-for-review-icon.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import bugIcon from "../../../assets/bug.svg";
import getProjectIconById from "../../../helper/projectIconGenerator";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getFileType } from "../../../components/getFIleType";
import { Editor } from "@tinymce/tinymce-react";
import NewTeam from "../../Team/components/NewTeam";
import { teamsListAction } from "../../../actions/teamsActions";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import { getIconByStatusId } from "../../../helper/getIconByStatusId";
import {
  useUpdateTaskDataMutation,
  useUpgradeTaskDataMutation,
} from "../../../slices/tasks/tasksSlice";
import { useUpdateTaskMemberDataMutation } from "../../../slices/tasks/tasksSlice";
import {
  useUpdateCompletedTaskDataMutation,
  useUpdateCompletedTaskMemberDataMutation,
} from "../../../slices/tasks/completedTasks.Slice";
import {
  useUpdatePhaseTaskDataMutation,
  useUpdatePhaseTaskMemberDataMutation,
} from "../../../slices/tasks/projectDetailSectionSlice";
import { isPermitted } from "../../../helper/isPermitted";
import { convertToUTC } from "./CreateTaskOffcanvas";
import { v4 as uuidv4 } from "uuid";

function UpdateTaskOffcanvas({
  show,
  handleCloseModal,
  isDark,
  getBoardData,
  taskId,
  taskList,
  setTaskList,
  getStatusCounts,
  isOutgoing,
  getGrid,
  isMoreWidth = false,
  kanban,
  setTasksData,
  getTaskDataAfterUpdate,
  // updateTaskData,
  updateFromTaskList,
  getTaskDetailAgain,
  getTaskData,
  pageParams,
  listingType,
}) {
  const dispatch = useDispatch();
  const [initLoad, setInitLoad] = useState(false);
  const [taskDetails, setTaskDetails] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    taskName: "",
    deadline: "",
    assignedTo: null,
    priority: null,
    taskType: null,
    project: null,
    observer: null,
    status: "",
    reviewForApproval: false,
    taskDescription: "",
    attachment: null,
    taskTeamId: null,
    projectPhases: null,
  });
  const [updateCompletedTaskData] = useUpdateCompletedTaskDataMutation();
  const [updateCompletedTaskMemberData] =
    useUpdateCompletedTaskMemberDataMutation();
  const [updatePhaseTaskData] = useUpdatePhaseTaskDataMutation();
  const [updatePhaseTaskMemberData] = useUpdatePhaseTaskMemberDataMutation();
  const [upgradeTaskData] = useUpgradeTaskDataMutation();
  const [showNew, setShowNew] = useState(false);
  const [reviewForApproval, setReviewForApproval] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [typeOfTasksOptions, setTypeOfTasksOptions] = useState([]);

  const [projectOptions, setProjectOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [list, setList] = useState([]);
  const flatpickrRef = useRef(null);
  const [files, setFiles] = useState([]);

  const [addCheckListLoad, setAddCheckListLoad] = useState(false);
  const [filesUploadLoader, setFilesUploadLoader] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [addCustomFieldListLoad, setAddCustomFieldListLoad] = useState(false);
  const [projectPhasesOptions, setProjectPhasesOptions] = useState({
    data: [],
    isLoading: false,
  });
  const [updateTaskData] = useUpdateTaskDataMutation();
  const [updateTaskMemberData] = useUpdateTaskMemberDataMutation();
  const { userProfile } = useSelector((state) => state?.profile);

  const allowedExtensions = [
    "pdf",
    "doc",
    "docx",
    "png",
    "jpg",
    "jpeg",
    "gif",
    "csv",
    "xls",
    "ppt",
    "pptx",
    "xlsx",
    "msg",
  ];
  const { getRootProps, getInputProps } = useDropzone({
    accept: "*", // Define accepted file types
    multiple: true,
    onDrop: async (acceptedFiles) => {
      if (filesUploadLoader) {
        return;
      }
      const filteredFiles = acceptedFiles.filter((file) => {
        const extension = `${file.name.split(".").pop()}`;
        return allowedExtensions.includes(extension.toLowerCase());
      });

      if (filteredFiles.length === 0) {
        // Handle case where no valid files were dropped
        toast.error("Please enter a valid file.", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      try {
        setFilesUploadLoader(true);

        let formData = new FormData();
        filteredFiles.forEach((file, index) => {
          formData.append(`attachments`, file);
        });
        formData.append(`taskId`, taskDetails?.id);
        const response = await ProjectFormDataAPI.post(
          "task/upload-attachments",
          formData
        );

        if (response.status === 201) {
          // toast.success("Task updated successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 300,
          //   hideProgressBar: true,
          //   closeButton: false,
          // });
          // await getTaskDetails(taskDetails?.id);
          const newFiles = response.data?.data?.map((file) => {
            return {
              id: file.id,
              preview: file.attachmentPath,
              name: file.attachmentName,
              isImage: isImageFile(file.attachmentName),
            };
          });

          setFiles([...files, ...newFiles]);
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setFilesUploadLoader(false);
      }
    },
  });

  const isImageFile = (fileName) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|webp|gif)$/i;
    return imageExtensionRegex.test(fileName);
  };

  const [observerOptions, setObserverOptions] = useState([]);
  const [createTaskLoading, setCreateTaskLoading] = useState(false);

  // all apis listing data
  const { projectFormList } = useSelector((state) => state.projectFormListData);
  const { projectUserList } = useSelector((state) => state.createProjectUsers);
  const { projectStatusList } = useSelector(
    (state) => state.projectStatusListMain
  );
  const { taskPriorities } = useSelector(
    (state) => state.tasksPrioritiesListData
  );
  const { taskTypes } = useSelector((state) => state.tasksTypesListData);

  const { projectTypeList } = useSelector((state) => state.createProjectTypes);

  const { teamsOptionsList } = useSelector((state) => state.teamsListData);

  const updateState = (typeName, users) => {
    // Ensure users is always an array, even for single user updates
    const userList = Array.isArray(users) ? users : [users];

    setObserverOptions(
      userList.map((user) => ({
        id: user.userId,
        value: user.userName,
        label: user.userName,
      }))
    );
  };

  const getAllProjects = async () => {
    if (projectFormList.length > 0) {
      setProjectOptions(
        projectFormList?.map((project) => ({
          id: project.id,
          icon: project?.projectIcon ? (
            <img
              src={
                getProjectIconById(project?.projectIcon)
                  ? getProjectIconById(project?.projectIcon)
                  : project?.projectIcon
              }
              alt="Project Name"
            />
          ) : null,
          label: project.projectName,
          value: project.projectName,
        }))
      );
    }
  };
  const getAllStatuses = async () => {
    if (projectStatusList) {
      setStatusOptions(
        projectStatusList?.map((status) => ({
          id: status.id,
          label: status.statusName,
          value: status.statusName,
        }))
      );
    }
  };
  const getAllTaskTypes = async () => {
    if (taskTypes) {
      setTypeOfTasksOptions(
        taskTypes?.map((task) => ({
          id: task.id,
          label: task.typeName,
          value: task.typeName,
        }))
      );
    }
  };

  const getUsersAndTypes = async () => {
    try {
      if (projectUserList?.data?.length > 0) {
        updateState("observer", projectUserList.data);
      }
      if (taskPriorities) {
        setPriorityOptions(
          taskPriorities?.map((priority) => ({
            id: priority.id,
            value: priority.priorityName,
            label: priority.priorityName,
          }))
        );
        // await getAllProjects();
        // await getAllStatuses();
        // await getAllTaskTypes();
        // Execute each asynchronous operation sequentially
        await Promise.all([
          getAllProjects().catch((error) => {
            console.error("Error fetching projects:", error);
          }),
          getAllStatuses().catch((error) => {
            console.error("Error fetching statuses:", error);
          }),
          getAllTaskTypes().catch((error) => {
            console.error("Error fetching task types:", error);
          }),
        ]);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getUsersAndTypes();
  }, [show]);

  const getTaskDetails = async (taskId) => {
    try {
      setInitLoad(true);
      setTaskDetails([]);
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
      const response = await ProjectAPI.post("task/detail", { taskId: taskId });
      if (response?.status === 201) {
        setTaskDetails(response?.data?.data);
        const fetchedTaskDetails = response?.data?.data;
        setReviewForApproval(fetchedTaskDetails?.hasApproval);
        setInitialFormValues({
          taskName: fetchedTaskDetails.taskName || "",
          deadline: new Date(fetchedTaskDetails.taskDeadline) || "",
          assignedTo:
            (fetchedTaskDetails?.taskMembers &&
              fetchedTaskDetails.taskMembers
                .filter((item) => item && item.memberType === "member")
                .map((item) => ({
                  id: item?.userId,
                  label: item?.userName,
                  value: item?.userName,
                }))[0]) ||
            null,
          priority: fetchedTaskDetails?.taskPriority !== null &&
            !Array.isArray(fetchedTaskDetails?.taskPriority) && {
              id: fetchedTaskDetails?.taskPriority.id,
              label: fetchedTaskDetails?.taskPriority.priorityName,
              value: fetchedTaskDetails?.taskPriority.priorityName,
              icon:
                fetchedTaskDetails?.taskPriority?.priorityName?.toLowerCase() ===
                "critical" ? (
                  <img
                    src={criticalImage}
                    alt="Critical"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskPriority?.priorityName?.toLowerCase() ===
                  "highest" ? (
                  <img
                    src={highestImage}
                    alt="Highest"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskPriority?.priorityName?.toLowerCase() ===
                  "medium" ? (
                  <img src={mediumImage} alt="Medium" width={20} height={20} />
                ) : fetchedTaskDetails?.taskPriority?.priorityName?.toLowerCase() ===
                  "low" ? (
                  <img src={lowImage} alt="Low" width={20} height={20} />
                ) : null,
            },
          taskType:
            fetchedTaskDetails?.taskType !== null &&
            !Array.isArray(fetchedTaskDetails?.taskType) &&
            fetchedTaskDetails.taskType
              ? {
                  id: fetchedTaskDetails.taskType.id,
                  label: fetchedTaskDetails.taskType.typeName,
                  value: fetchedTaskDetails.taskType.typeName,
                  icon:
                    fetchedTaskDetails.taskType.typeName?.toLowerCase() ===
                    "error" ? (
                      <img
                        src={criticalImage}
                        alt="Critical"
                        width={20}
                        height={20}
                      />
                    ) : fetchedTaskDetails.taskType.typeName?.toLowerCase() ===
                      "new task" ? (
                      <img
                        src={mediumImage}
                        alt="Progress"
                        width={20}
                        height={20}
                      />
                    ) : fetchedTaskDetails.taskType.typeName?.toLowerCase() ===
                      "bug" ? (
                      <img
                        src={bugIcon}
                        alt="Submit For Review"
                        width={20}
                        height={20}
                      />
                    ) : fetchedTaskDetails.taskType.typeName?.toLowerCase() ===
                      "issue" ? (
                      <Icon icon="pajamas:issue-close" width={20} height={20} />
                    ) : null,
                }
              : null,
          project: fetchedTaskDetails?.project !== null &&
            !Array.isArray(fetchedTaskDetails?.project) && {
              id: fetchedTaskDetails.project.id,
              icon: fetchedTaskDetails?.project?.projectIcon ? (
                <img
                  src={
                    getProjectIconById(fetchedTaskDetails?.project?.projectIcon)
                      ? getProjectIconById(
                          fetchedTaskDetails?.project?.projectIcon
                        )
                      : fetchedTaskDetails?.project?.projectIcon
                  }
                  alt="Project Name"
                />
              ) : null,
              label: fetchedTaskDetails.project.projectName,
              value: fetchedTaskDetails.project.projectName,
            },
          projectPhases: fetchedTaskDetails?.projectPhase !== null && {
            id: fetchedTaskDetails.projectPhase?.id,
            label: fetchedTaskDetails.projectPhase?.phaseName,
            value: fetchedTaskDetails.projectPhase?.phaseName,
          },
          observer:
            fetchedTaskDetails?.taskMembers &&
            fetchedTaskDetails.taskMembers
              .filter((item) => item && item.memberType === "observer")
              .map((item) => ({
                id: item?.userId,
                label: item?.userName,
                value: item?.userName,
              })),
          status: fetchedTaskDetails?.taskStatus !== null &&
            !Array.isArray(fetchedTaskDetails?.taskStatus) &&
            fetchedTaskDetails.taskStatus && {
              id: fetchedTaskDetails.taskStatus.id,
              label: fetchedTaskDetails.taskStatus.statusName,
              value: fetchedTaskDetails.taskStatus.statusName,
              icon:
                fetchedTaskDetails?.taskStatus?.statusName?.toLowerCase() ===
                "not started" ? (
                  <img
                    src={criticalImage}
                    alt="Critical"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskStatus?.statusName?.toLowerCase() ===
                  "in progress" ? (
                  <img
                    src={ProgressIcon}
                    alt="Progress"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskStatus?.statusName?.toLowerCase() ===
                  "on hold" ? (
                  <img
                    src={reviewImage}
                    alt="Submit For Review"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskStatus?.statusName?.toLowerCase() ===
                  "submit for review" ? (
                  <img
                    src={reviewIcon}
                    alt="Submit For Review"
                    width={20}
                    height={20}
                  />
                ) : fetchedTaskDetails?.taskStatus?.statusName?.toLowerCase() ===
                  "completed" ? (
                  <img src={doneImage} alt="Done" width={20} height={20} />
                ) : null,
            },
          reviewForApproval: fetchedTaskDetails.hasApproval || false,
          taskDescription: fetchedTaskDetails.taskDescription,
          attachment: null,
          taskTeamId:
            fetchedTaskDetails.taskTeams &&
            fetchedTaskDetails?.taskTeams?.map((taskTeam) => {
              return {
                id: taskTeam?.id,
                value: taskTeam?.id,
                label: taskTeam?.teamName,
              };
            }),
        });
        fetchedTaskDetails.taskDeadline &&
          setSelectedDateFilter(fetchedTaskDetails.taskDeadline);

        fetchedTaskDetails.taskCheckLists &&
          fetchedTaskDetails.taskCheckLists.length > 0 &&
          setList(
            fetchedTaskDetails.taskCheckLists.map((item) => {
              return {
                id: item.id,
                text: item.taskDescription,
                checked: item.checklistStatus === 1 ? true : false,
              };
            })
          );
        fetchedTaskDetails?.taskAttachments &&
          fetchedTaskDetails?.taskAttachments?.length > 0 &&
          setFiles(
            fetchedTaskDetails?.taskAttachments?.map((file) => {
              return {
                id: file.id,
                preview: file.attachmentPath,
                name: file.attachmentName,
                isImage: isImageFile(file.attachmentName),
              };
            })
          );

        fetchedTaskDetails.customFields &&
          fetchedTaskDetails.customFields.length > 0 &&
          setCustomFields(
            fetchedTaskDetails.customFields.map((item) => {
              return {
                id: item.id,
                field: item.columnName,
                value: item.columnValue,
              };
            })
          );
        fetchedTaskDetails?.project?.length !== 0 &&
          getProjectPhasesAPI(fetchedTaskDetails?.project?.id);
      }
    } catch (error) {
    } finally {
      setInitLoad(false);
      getTeamList();
    }
  };

  const getProjectPhasesAPI = async (projectId) => {
    try {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
      const response = await ProjectAPI.post("project/pm-project-phases", {
        projectId: projectId,
      });
      if (response.status === 201) {
        setProjectPhasesOptions({
          data: response?.data?.data?.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.phaseName,
          })),
          isLoading: false,
        });
      }
    } catch (error) {
      setProjectPhasesOptions({
        data: [],
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (taskId && show) {
      getTaskDetails(taskId);
    }
  }, [taskId, show]);

  // custom styles for priority
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.data.value.toString().toLowerCase() === "critical"
          ? "#FB3F3F"
          : state.data.value.toString().toLowerCase() === "highest"
          ? "#56BAEC"
          : state.data.value.toString().toLowerCase() === "medium"
          ? "#394B84"
          : state.data.value.toString().toLowerCase() === "low"
          ? "#2C2D2F"
          : state.data.value.toString().toLowerCase() === "in progress"
          ? "#2098D1"
          : state.data.value.toString().toLowerCase() === "not started"
          ? "black"
          : state.data.value.toString().toLowerCase() === "completed"
          ? "#2DBF64"
          : state.data.value.toString().toLowerCase() === "on hold"
          ? "#E8910D"
          : state.data.value.toString().toLowerCase() === "submit for review"
          ? "#EBA900"
          : " black",
      fontSize: "13px",
      backgroundColor: state.isSelected ? "#e9eeff" : "transparent",
      "&:hover": {
        backgroundColor: "#e9eeff", // Set background color when hovering over the option
      },
      fontWeight: 300,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    menu: (base) => ({
      ...base,
      // Set the desired width
    }),
  };

  const getTypeIDByName = (typeName) => {
    const type = projectTypeList?.find((item) => item.typeName === typeName);
    return type ? type.id : null;
  };

  const createTaskSubmit = async (data) => {
    try {
      setCreateTaskLoading(true);
      let formData = new FormData();
      if (taskDetails.id) formData.append("taskId", taskDetails.id);
      if (data.taskName) formData.append("taskName", data.taskName);
      if (data.taskPriorityId)
        formData.append("taskPriorityId", data.taskPriorityId);
      if (data.taskTypeId) formData.append("taskTypeId", data.taskTypeId);
      if (data.taskStatusId) formData.append("taskStatusId", data.taskStatusId);
      if (data.taskDescription)
        formData.append("taskDescription", data.taskDescription);
      if (data.hasApproval != null)
        formData.append("hasApproval", data.hasApproval); // explicitly check for null/undefined
      if (data.projectId) formData.append("projectId", data.projectId);
      if (data.taskTeamId) formData.append("taskTeamId", data.taskTeamId);
      if (data.projectPhaseId)
        formData.append("projectPhaseId", data.projectPhaseId);

      // Only append taskDeadline if it is not null
      const taskDeadline = selectedDateFilter
        ? convertToUTC(selectedDateFilter)
        : null;
      if (taskDeadline) formData.append("taskDeadline", taskDeadline);

      // For taskCheckList
      data.taskCheckList.forEach((checkListItem) => {
        if (checkListItem) formData.append("taskCheckList[]", checkListItem);
      });

      // For files
      // data.files.forEach((file) => {
      //   if (file) formData.append("attachments", file);
      // });

      // For taskMembers
      data.taskMembers.forEach((memberId) => {
        if (memberId) formData.append("taskMembers[]", memberId);
      });

      // For customFields
      data.customFields.forEach((customField) => {
        if (customField) formData.append("customFields[]", customField);
      });

      const response = await upgradeTaskData(formData).unwrap();
      if (response.status === "success") {
        toast.success("Task Updated Successfully.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      // Now formData contains all the data in a format suitable for sending in a form request
    } catch (error) {
    } finally {
      setCreateTaskLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission here
      let data = {
        taskName: values.taskName,
        taskPriorityId: values?.priority?.value?.id,
        taskTypeId: values?.taskType?.value?.id,
        taskStatusId: values?.status?.value?.id,
        taskDescription: values?.taskDescription,
        hasApproval: reviewForApproval,
        projectId: values?.project?.id,
        projectPhaseId: values?.projectPhases?.id,
        taskDeadline: convertToUTC(selectedDateFilter),
        taskCheckList: [],
        // files: files,
        taskMembers: [],
        customFields: [],
        taskTeamId: values?.taskTeamId?.flatMap((item) => item?.id).join(","),
      };

      if (list && list.length > 0) {
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
          item.text &&
            item.text.length > 0 &&
            data.taskCheckList.push(item.text);
        });
      }
      if (customFields && customFields.length > 0) {
        // eslint-disable-next-line array-callback-return
        customFields.map((item) => {
          item.field &&
            item.field.length > 0 &&
            data.customFields.push(`${item.field}: ${item.value}`);
        });
      }
      let taskObserver = [];
      let taskAssignTo = [];
      if (values.observer && values.observer.length > 0) {
        const newTaskMembers = values.observer.map((item) => {
          return `${getTypeIDByName("observer")}:${item.id}`;
        });
        taskObserver = newTaskMembers;
      }

      if (values.assignedTo) {
        const newTaskMember = `${getTypeIDByName("member")}:${
          values.assignedTo.id
        }`;
        taskAssignTo = [newTaskMember];
      }

      if (taskObserver.length > 0 || taskAssignTo.length > 0) {
        data.taskMembers = [
          ...taskObserver,
          ...taskAssignTo,
          `${getTypeIDByName("owner")}:${userProfile?.userId}`,
        ];
      }
      await createTaskSubmit(data);
    },
    validate: (values) => {
      const errors = {};

      // Validate each field here
      if (!values.taskName) {
        errors.taskName = "Task name is required.";
      }
      if (!values.assignedTo) {
        errors.assignedTo = "Assigned to is required.";
      }
      // Add validations for other fields as needed

      return errors;
    },
  });

  const handleDeleteFile = async (fileToDelete) => {
    try {
      const updatedFiles = files.filter((file) => file.id !== fileToDelete.id);
      setFiles(updatedFiles);
      const response = await ProjectAPI.post("task/delete-attachments", {
        attachmentId: fileToDelete.id,
        taskId: taskDetails.id,
      });
      toast.success("Task updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return "vscode-icons:file-type-pdf2";
      case "pdf":
        return "vscode-icons:file-type-pdf2";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "vscode-icons:file-type-word";
      case "doc":
        return "vscode-icons:file-type-word";
      case "docx":
        return "vscode-icons:file-type-word";
      case "text/plain":
        return "simple-icons:googledocs";
      case "txt":
        return "simple-icons:googledocs";
      case "zip":
        return "icon-[clarity--file-zip-solid]";
      default:
        return "icon-[il--folder]";
    }
  };

  const updateTask = async (data) => {
    try {
      let response;
      if (listingType === "COMPLETED_TASKS") {
        response = await updateCompletedTaskData({
          ...pageParams,
          ...data,
        }).unwrap();
      } else if (listingType === "ALL_TASKS") {
        response = await updateTaskData({
          ...pageParams,
          ...data,
        }).unwrap();
      } else if (listingType === "PHASE_TASKS") {
        response = await updatePhaseTaskData({
          ...pageParams,
          ...data,
        }).unwrap();
      }
      if (response?.status === "success") {
        setTaskDetails(response?.data);
        if (kanban) {
          setTasksData(response);
        }
        toast.success("Task updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      }

      // if (isOutgoing) {
      //   getGrid();
      // }
      // // Specific in case of kanban

      // if (updateFromTaskList) {
      //   updateTaskData(response?.data?.taskDetail?.task);
      // }
      // updateTaskObject(response?.data?.taskDetail);
      if (getTaskDetailAgain) {
        getTaskData();
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const updateTaskMemberAndObserver = async (data) => {
    const owner = taskDetails?.taskMembers.find(
      (member) => member.memberType === "owner"
    );

    //Define the owner key value pair before submitting data
    const memberKey = `${getTypeIDByName(owner?.memberType)}`; // Replace with the actual new key
    const memberValue = `${owner?.userId}`; // Replace with the actual new member ID
    // Create the new entry
    const newEntry = { [memberKey]: memberValue };

    // Add the owner to the taskMembers array
    data.taskMembers.push(newEntry);
    try {
      let response;
      if (listingType === "COMPLETED_TASKS") {
        response = await updateCompletedTaskMemberData({
          ...pageParams,
          ...data,
        });
      } else if (listingType === "ALL_TASKS") {
        response = await updateTaskMemberData({ ...pageParams, ...data });
      } else if (listingType === "PHASE_TASKS") {
        response = await updatePhaseTaskMemberData(data);
      }

      if (response?.data?.status === "success") {
        // Specific in case of kanban
        if (kanban) {
          const resp = { data: response?.data?.taskDetail?.task };
          setTasksData(resp);
        }
        // if (updateFromTaskList) {
        //   updateTaskData(response?.data?.taskDetail?.task);
        // }
        // updateTaskObject(response?.data?.data);
        if (getTaskDetailAgain) {
          getTaskData();
        }
        // toast.success("Task updated successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 300,
        //   hideProgressBar: true,
        //   closeButton: false,
        // });
        // setTaskList(
        //   taskList.map((task) => {
        //     if (task.id === taskDetails.id) {
        //       return response?.data?.data;
        //     } else {
        //       return task;
        //     }
        //   })
        // );
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const dateFormateForRequestBody = (taskDeadline) => {
    const date = new Date(taskDeadline);
    const formattedDeadline = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}.${date.getMilliseconds().toString().padStart(3, "0")}`;
    return formattedDeadline;
  };

  const updateTaskCheckList = async (data) => {
    if (data?.description) {
      try {
        const response = await ProjectAPI.post("task/update-checklist", data);
        toast.success("Task updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const deleteTaskCheckList = async (data) => {
    try {
      const response = await ProjectAPI.post("task/delete-checklist", data);
      toast.success("Task updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteTaskCustomFieldItem = async (data) => {
    try {
      const response = await ProjectAPI.post("task/delete-custom-filed", data);
      toast.success("Task updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const addTaskCheckList = async (data) => {
    try {
      setAddCheckListLoad(true);
      const response = await ProjectAPI.post("task/add-new-checklist", data);

      if (response.status === 200) {
        response.data.data &&
          setList([
            ...list,
            {
              id: response.data.data.id,
              text: response.data.data.taskDescription,
              checked: response.data.data.checklistStatus === 1 ? true : false,
            },
          ]);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setAddCheckListLoad(false);
    }
  };
  const addAndUpdateTaskCustomFieldListItem = async (data) => {
    try {
      setAddCustomFieldListLoad(true);
      const response = await ProjectAPI.post(
        "task/update-custom-filed-value",
        data
      );

      if (response.status === 200) {
        toast.success("Task updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        response.data.data &&
          setCustomFields(
            response.data.data.map((item) => ({
              id: item.id,
              field: item.columnName,
              value: item.columnValue,
            }))
          );
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setAddCustomFieldListLoad(false);
    }
  };

  const updateRowParameterById = (id, parameterName, parameterValue) => {
    setTaskList((prevRowData) => {
      // Find the index of the object with the given id
      const rowIndex = prevRowData.findIndex((row) => row.id === id);

      // If the object with the given id is found
      if (rowIndex !== -1) {
        // Create a new array to avoid mutating the state directly
        const newRowData = [...prevRowData];
        // Update the parameter of the object at the found index
        newRowData[rowIndex] = {
          ...newRowData[rowIndex],
          [parameterName]: parameterValue,
        };
        return newRowData;
      }

      // If the object with the given id is not found, return the previous state
      return prevRowData;
    });
  };

  // Get Team List
  const getTeamList = async () => {
    if (!teamsOptionsList) {
      dispatch(teamsListAction());
    }
  };

  return (
    <Offcanvas
      className={`off-concave-role`}
      style={{ zIndex: "9999999 !important" }}
      show={show}
      placement="end"
      data-theme={isDark}
    >
      <Offcanvas.Body>
        <div className="create-task-new-wraper">
          <div className="create-task-main-wraper">
            <h4>
              {" "}
              Update <span>Tasks</span>{" "}
            </h4>
            <p>
              {" "}
              Quickly manage task details if you want to change timeline,
              priority or assign task to new members
            </p>
            <Tooltip id="my-tooltip" />
            {initLoad ? (
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between tri-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between tri-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between dual-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-between row-shimmer-thumbnail-in-form">
                  <ShimmerThumbnail height={220} />
                </div>
                <div className="w-100 mb-3 d-flex justify-content-end single-row-shimmer-in-form">
                  <ShimmerButton size="sm" />
                </div>
              </div>
            ) : (
              <form noValidate className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-9">
                  <div className="form-group">
                    <label>
                      {" "}
                      Task name{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Name"
                      />{" "}
                    </label>
                    <input
                      type="text"
                      name="taskName"
                      placeholder="Task Name"
                      autoComplete="off"
                      isInvalid={
                        formik?.touched.taskName && !!formik?.errors.taskName
                      }
                      className={`form-control ${
                        formik?.touched.taskName && formik?.errors.taskName
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={formik.handleBlur}
                      value={formik.values.taskName}
                      onChange={formik.handleChange}
                      // onKeyDown={(e) => {
                      //   if (e.key === "Enter") {
                      //     e.target.blur();
                      //   }
                      // }}
                      // onBlur={(e) => {
                      //   formik.handleBlur(e);
                      //   taskDetails &&
                      //     e.target.value.length > 0 &&
                      //     updateTask({
                      //       taskId: taskDetails?.id,
                      //       fieldName: "taskName",
                      //       fieldValues: e.target.value,
                      //     });
                      //   updateRowParameterById(
                      //     taskDetails?.id,
                      //     "taskName",
                      //     e.target.value
                      //   );
                      // }}
                    />
                    {formik.touched.taskName && formik.errors.taskName ? (
                      <div className="invalid-feedback">
                        {formik.errors.taskName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group create-task-deadline">
                    <label>
                      Deadline{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Deadline"
                      />
                    </label>
                    <Flatpickr
                      // data-theme={props.isDark}
                      className={`project-details-task-table-flatpickr-container`}
                      placeholder="Set Date"
                      options={{
                        altInput: true,
                        altFormat: "M d, Y H:i",
                        dateFormat: "Y-m-d H:i",
                        static: true,
                        enableTime: true,
                        disableMobile: true,
                        defaultHour: 18,
                        onClose: function (selectedDates, dateStr, instance) {
                          if (taskDetails) {
                            updateTask({
                              taskId: taskDetails.id,
                              fieldName: "taskDeadline",
                              fieldValues: selectedDateFilter,
                            });
                          }
                        },
                      }}
                      ref={flatpickrRef}
                      value={selectedDateFilter}
                      onChange={([date]) => {
                        const formattedDate = date
                          ? new Date(date)
                          : new Date();
                        setSelectedDateFilter(formattedDate);
                      }}
                    />
                    <span
                      className="create-task-deadline-icon"
                      onClick={() => flatpickrRef.current.flatpickr.open()}
                    >
                      <Icon icon="lets-icons:date-range" />
                    </span>
                    {selectedDateFilter ? null : (
                      <div className="invalid-feedback">
                        Task Deadline is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Assigned To{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Assigned To"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        options={observerOptions ? observerOptions : []}
                        isSelectAll={false}
                        placehold="Select Assigned To"
                        value={formik.values.assignedTo}
                        isInvalid={
                          formik.touched.assignedTo &&
                          !!formik.errors.assignedTo
                        }
                        className={`${
                          formik.touched.assignedTo && formik.errors.assignedTo
                            ? "is-invalid invalid-error-border"
                            : ""
                        }`}
                        name="assignedTo"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("assignedTo", e);
                        }}
                      />

                      {formik.touched.assignedTo && formik.errors.assignedTo ? (
                        <div className="invalid-feedback">
                          {formik.errors.assignedTo}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Priority{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Assigned To"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={true}
                        placeholder="Set Priority"
                        value={formik.values.priority}
                        isInvalid={
                          formik.touched.priority && !!formik.errors.priority
                        }
                        className={`${
                          formik.touched.priority && formik.errors.priority
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="priority"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("priority", e);
                        }}
                        getOptionLabel={(option) => (
                          <div className="d-flex align-items-center gap-1">
                            {option.icon} {/* Render the icon */}
                            {option.label} {/* Render the typeName */}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        options={
                          priorityOptions
                            ? priorityOptions?.map((res, index) => ({
                                icon:
                                  index === 0 ? (
                                    <img
                                      src={criticalImage}
                                      alt="Critical"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 1 ? (
                                    <img
                                      src={highestImage}
                                      alt="Critical"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 2 ? (
                                    <img
                                      src={mediumImage}
                                      alt="Critical"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 3 ? (
                                    <img
                                      src={lowImage}
                                      alt="Critical"
                                      width={20}
                                      height={20}
                                    />
                                  ) : null,
                                label: res?.value,
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children,menuIsOpen, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.priority && formik.errors.priority ? (
                        <div className="invalid-feedback">
                          {formik.errors.priority}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* type of tasks */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Type of task
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Type of task"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select taskType"
                        value={formik.values.taskType}
                        isInvalid={
                          formik.touched.taskType && !!formik.errors.taskType
                        }
                        className={`${
                          formik.touched.taskType && formik.errors.taskType
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="taskType"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("taskType", e);
                        }}
                        getOptionLabel={(option) => (
                          <div className="d-flex align-items-center gap-1">
                            {option.icon} {/* Render the icon */}
                            {option.label} {/* Render the typeName */}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        options={
                          typeOfTasksOptions
                            ? typeOfTasksOptions?.map((res, index) => ({
                                icon:
                                  index === 0 ? (
                                    <img
                                      src={criticalImage}
                                      alt="Critical"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 1 ? (
                                    <img
                                      src={mediumImage}
                                      alt="Progress"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 2 ? (
                                    <img
                                      src={bugIcon}
                                      alt="Review"
                                      width={20}
                                      height={20}
                                    />
                                  ) : index === 3 ? (
                                    <Icon
                                      icon="pajamas:issue-close"
                                      width={20}
                                      height={20}
                                    />
                                  ) : null,
                                label: res?.value,
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children,menuIsOpen, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.taskType && formik.errors.taskType ? (
                        <div className="invalid-feedback">
                          {formik.errors.taskType}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* projects */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Project{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Project"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={true}
                        placeholder="Select Project"
                        value={formik.values.project}
                        isInvalid={
                          formik.touched.project && !!formik.errors.project
                        }
                        className={`${
                          formik.touched.project && formik.errors.project
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={true}
                        name="project"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("project", e);
                          formik.setFieldValue("projectPhases", null);
                          getProjectPhasesAPI(e.id);
                        }}
                        getOptionLabel={(option) => (
                          <div className="d-flex align-items-center gap-1 selection-project-img">
                            {option.icon} {/* Render the icon */}
                            {option.label} {/* Render the typeName */}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        options={projectOptions ? projectOptions : []}
                        components={{
                          Control: ({ children,menuIsOpen, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.project && formik.errors.project ? (
                        <div className="invalid-feedback">
                          {formik.errors.project}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* project phases */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Project Phases{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Selected Project Phases"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select Project Phase"
                        value={formik.values.projectPhases}
                        isInvalid={
                          formik.touched.projectPhases &&
                          !!formik.errors.projectPhases
                        }
                        className={`${
                          formik.touched.projectPhases &&
                          formik.errors.projectPhases
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isLoading={projectPhasesOptions?.isLoading}
                        isDisabled={projectPhasesOptions?.isLoading}
                        isSearchable={false}
                        name="projectPhases"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("projectPhases", e);
                        }}
                        getOptionValue={(option) => option.value}
                        options={
                          projectPhasesOptions.data
                            ? projectPhasesOptions.data
                            : []
                        }
                        components={{
                          Control: ({ children,menuIsOpen, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.status && formik.errors.status ? (
                        <div className="invalid-feedback">
                          {formik.errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* statuses */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Status{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Status"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <Select
                        isSelectAll={false}
                        placeholder="Select Status"
                        value={formik.values.status}
                        isInvalid={
                          formik.touched.status && !!formik.errors.status
                        }
                        className={`${
                          formik.touched.status && formik.errors.status
                            ? "is-invalid invalid-error-border"
                            : `create-task-priority-select`
                        }`}
                        styles={customStyles}
                        isSearchable={false}
                        name="status"
                        menuPlacement="bottom"
                        onChange={async (e) => {
                          formik.setFieldValue("status", e);
                        }}
                        getOptionLabel={(option) => (
                          <div className="d-flex align-items-center gap-1">
                            {option.icon} {/* Render the icon */}
                            {option.label} {/* Render the typeName */}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        options={
                          statusOptions
                            ? statusOptions?.map((res, index) => ({
                                icon: (
                                  <img
                                    src={getIconByStatusId(
                                      statusOptions,
                                      res?.id
                                    )}
                                    alt="status-icon"
                                    width={20}
                                    height={20}
                                  />
                                ),
                                label: res?.value,
                                value: res,
                              }))
                            : []
                        }
                        components={{
                          Control: ({ children,menuIsOpen, ...props }) => {
                            return (
                              <components.Control {...props}>
                                {children}
                                <div className="select-icon me-1" style={{
              transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}>
                                  <IoIosArrowDown />
                                </div>
                              </components.Control>
                            );
                          },
                        }}
                      />

                      {formik.touched.status && formik.errors.status ? (
                        <div className="invalid-feedback">
                          {formik.errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* task team */}
                <div className="col-md-4">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Task Teams{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Teams"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <MultiSelect
                        options={teamsOptionsList ? teamsOptionsList : []}
                        isSelectAll={true}
                        placehold="Select Task Teams"
                        value={formik.values.taskTeamId}
                        name="taskTeamId"
                        menuPlacement="bottom"
                        disabled={isPermitted("teams/listing")}
                        onChange={(e) => {
                          formik.setFieldValue("taskTeamId", e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center col-md-4 add-team-project">
                  <span onClick={() => setShowNew(true)}>+ Add Team</span>
                </div>
                {/* observer */}
                <div className="col-md-6">
                  <div className="form-group multi-select-wrapper">
                    <label>
                      {" "}
                      Observer{" "}
                      <img
                        src={InfoIcon}
                        alt="info-icon"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Task Observer"
                      />{" "}
                    </label>
                    <div className="team-offconcave-select-wrapper">
                      <MultiSelect
                        options={observerOptions ? observerOptions : []}
                        isSelectAll={true}
                        placehold="Select Observer"
                        value={formik.values.observer}
                        isInvalid={
                          formik.touched.observer && !!formik.errors.observer
                        }
                        className={`${
                          formik.touched.observer && formik.errors.observer
                            ? "is-invalid invalid-error-border"
                            : ""
                        }`}
                        name="observer"
                        menuPlacement="bottom"
                        onChange={(e) => {
                          formik.setFieldValue("observer", e);
                        }}
                      />

                      {formik.touched.observer && formik.errors.observer ? (
                        <div className="invalid-feedback">
                          {formik.errors.observer}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* review for approval */}
                <div className="col-md-6 ">
                  <div className="form-group">
                    <label>
                      Review for Approval{" "}
                      <img
                        src={infoIcon}
                        alt="Info"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Review For Approval"
                      />
                    </label>
                    <div className="mt-1 rounded-0 ">
                      <div
                        className="checkbox  create-task-checkbox"
                        onClick={() => {
                          setReviewForApproval(!reviewForApproval);
                        }}
                      >
                        <input
                          type="checkbox"
                          readOnly
                          checked={reviewForApproval}
                        />
                        <label>
                          <span className="ps-2">Check me out</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* task description */}
                <div className="col-md-12">
                  <div className="textarea-group">
                    <label> Task Description </label>
                    <Editor
                      tinymceScriptSrc="/tinymce/tinymce.min.js"
                      apiKey="gpl" // Your TinyMCE API key
                      value={formik.values.taskDescription}
                      init={{
                        height: 200,
                        menubar: false,
                        selector: "textarea", // Not required if directly replacing <textarea>
                        plugins: "lists textcolor colorpicker",
                        toolbar:
                          "undo redo | blocks | forecolor | bold italic underline | checklist numlist bullist",
                        content_style:
                          "body { font-family: 'Poppins', sans-serif; font-size: 14px }",
                      }}
                      onEditorChange={(newContent, editor) => {
                        // Update your formik state if needed:
                        formik.setFieldValue("taskDescription", newContent);
                      }}
                    />
                  </div>
                </div>
                <div className="todo-listn-wrap col-md-12 mb-0 mt-3">
                  <h5>
                    {" "}
                    Todo List{" "}
                    <span
                      className="todo-list-add-item"
                      onClick={async () => {
                        setList([
                          ...list,
                          { id: uuidv4(), checked: false, text: null },
                        ]);
                      }}
                      // disabled={addCheckListLoad}
                    >
                      {" "}
                      <img src={plusIcon} alt="Plus" /> Add Item{" "}
                    </span>{" "}
                    {addCheckListLoad && (
                      <span className="">
                        <Spinner
                          size="sm"
                          animation="border"
                          className="global-spinner"
                        ></Spinner>
                      </span>
                    )}
                  </h5>
                  <TaskDetailsTodoList
                    list={list}
                    setList={setList}
                    isDark={isDark}
                    updateTaskCheckList={() => {}}
                    deleteTaskCheckList={() => {}}
                    noRadio={true}
                    addItemInList={() => {
                      setList([
                        ...list,
                        { id: uuidv4(), checked: false, text: null },
                      ]);
                    }}
                    taskDetails={taskDetails}
                  />
                </div>

                {/* custom Fields*/}
                <div className="todo-listn-wrap col-md-12 mb-0 mt-3">
                  <h5>
                    Custom Fields
                    <span
                      className="todo-list-add-item"
                      onClick={() => {
                        setCustomFields([
                          ...customFields,
                          { id: uuidv4(), field: null, value: null },
                        ]);
                      }}
                    >
                      <img src={plusIcon} alt="Plus" /> Add Field
                    </span>
                    {addCustomFieldListLoad && (
                      <span className="ms-1">
                        <Spinner
                          size="sm"
                          animation="border"
                          className="global-spinner"
                        ></Spinner>
                      </span>
                    )}
                  </h5>
                  <TaskDetailsCustomFieldsList
                    list={customFields}
                    setList={setCustomFields}
                    isDark={isDark}
                    deleteTaskCustomFieldItem={() => {}}
                    addAndUpdateTaskCustomFieldListItem={() => {}}
                    taskDetails={taskDetails}
                  />
                </div>

                <div className="col-md-12">
                  <div className="drag-drop-task-file">
                    <label>Attachment</label>
                    <div
                      {...getRootProps()}
                      className="drage-file-upload dropzone"
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3 flex-wrap d-flex w-100 align-items-center justify-content-center gap-2">
                        {files.map((file) => (
                          <div
                            key={file.name}
                            className="file-preview-container"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="file-preview">
                              {file.isImage ? (
                                <img
                                  src={file.preview}
                                  alt={file.name}
                                  className="file-image"
                                />
                              ) : (
                                <div>
                                  <FileIcon
                                    extension={getFileType(file?.name)}
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                    {...defaultStyles[getFileType(file?.name)]}
                                  />
                                </div>
                              )}
                              <div className="file-info">
                                <span className="file-name">{file.name}</span>
                              </div>
                              <span
                                className="delete-button"
                                onClick={() => handleDeleteFile(file)}
                              >
                                <Icon icon="fluent:delete-12-filled" />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      Drag and drop or browse
                      {filesUploadLoader && (
                        <div className="w-100 mt-3 d-flex align-items-center justify-content-center">
                          <Spinner
                            animation="border"
                            className="global-spinner"
                          ></Spinner>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="create-task-button-wrap">
                    <button
                      type="submit"
                      disabled={createTaskLoading}
                      className="custom-button-main"
                      style={{ minWidth: "150px" }}
                    >
                      {createTaskLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Update"
                      )}{" "}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        {/** close icon */}
        <button className="btn-role-concave" onClick={handleCloseModal}>
          <img src={CloseBtn} alt="Close" />
        </button>
        {showNew && (
          <NewTeam
            create={0}
            show={showNew}
            handleClose={() => setShowNew(false)}
            isDark={isDark}
            handleCreate={() => {}}
            projectList={projectOptions}
            projectUserList={observerOptions ? observerOptions : []}
            teamToFilterList={[]}
            setTeamToFilterList={() => {}}
            teamList={[]}
            setTeamList={() => {}}
            teamDataAfterUpdate={() => {}}
            fromProjectForm={true}
            getTeamList={getTeamList}
          />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default UpdateTaskOffcanvas;

const CustomRadioButtonListItem = ({ title, id, onChange, value }) => {
  const [isChecked, setIsChecked] = useState(value);
  return (
    <div
      key={id}
      className="create-task-check-list-radio-button"
      onClick={() => {
        setIsChecked(!isChecked);
        if (!isChecked) {
          onChange();
        }
      }}
    >
      <input
        type="checkbox"
        name="optionsRadios"
        id="optionsRadios2"
        value="option2"
        checked={isChecked}
      />
      <label>{title}</label>
    </div>
  );
};

const TaskDetailsTodoList = ({
  list,
  setList,
  isDark,
  updateTaskCheckList,
  deleteTaskCheckList,
  addTaskCheckList,
  taskDetails,
  noRadio = false,
}) => {
  const onCheckedChange = (id, checked, text) => {
    setList(
      list.map((res, index) =>
        res.id === id ? { ...res, checked: checked } : res
      )
    );
    if (taskDetails && taskDetails.id) {
      updateTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
        status: checked ? 1 : 0,
        description: text,
      });
    }
  };
  const onTextChange = (id, text, ischecked, isBlur) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, text: text } : res))
    );

    if (taskDetails && taskDetails.id && isBlur) {
      updateTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
        status: ischecked ? 1 : 0,
        description: text,
      });
    }
  };

  const onItemDelete = (id) => {
    setList(list.filter((res, index) => res.id !== id));
    if (taskDetails && taskDetails.id) {
      deleteTaskCheckList({
        taskId: taskDetails.id,
        checklist: id,
      });
    }
  };
  return (
    <div className="d-flex flex-column gap-2">
      {list.map((res, index) => {
        return (
          <TaskDetailsTodoListItem
            text={res.text}
            checked={res.checked}
            id={res.id}
            key={index}
            isDark={isDark}
            onCheckedChange={onCheckedChange}
            onTextChange={onTextChange}
            onItemDelete={onItemDelete}
            addTaskCheckList={addTaskCheckList}
            noRadio={noRadio}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsTodoListItem = ({
  text,
  checked,
  id,
  key,
  isDark,
  onCheckedChange,
  onTextChange,
  onItemDelete,
  addTaskCheckList,
  noRadio = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isEditable, setIsEditable] = useState(text ? false : true);
  const [inputValue, setInputValue] = useState(text);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      inputValue && inputValue.length > 0 && setIsEditable(false);
      onTextChange(id, inputValue, isChecked, true);
      if (inputValue && inputValue.length > 0) {
        addTaskCheckList();
      } else {
        onItemDelete(id);
      }
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
    onTextChange(id, event.target.value, isChecked, false);
  };
  const handleBlur = () => {
    inputValue && inputValue.length > 0 && setIsEditable(false);
    onTextChange(id, inputValue, isChecked, true);
    if (inputValue && inputValue.length > 0) {
    } else {
      onItemDelete(id);
    }
  };
  return (
    <div
      data-theme={isDark}
      key={key}
      className="position-relative d-flex align-item-center gap-2 task-details-todo-list-item"
    >
      {/* <div
        className={`${
          !isEditable
            ? "task-details-check-list-radio-button"
            : "task-details-check-list-radio-button-input"
        }`}
        onClick={() => {
          setIsChecked(!isChecked);
          onCheckedChange(id, !isChecked, text);
        }}
      >
        <input
          type="checkbox"
          name="optionsRadios"
          id="optionsRadios2"
          value="option2"
          checked={isChecked}
        />
        <label></label>
      </div> */}
      {!isEditable ? (
        <div className="d-flex align-items-center w-100 task-details-todo-list-item-text-wrap">
          <p
            onClick={() => setIsEditable(true)}
            className={`mb-0 pb-0 task-details-todo-list-item-text w-100 ${
              isChecked ? "text-decoration-line-through" : ""
            }`}
          >
            {text}
          </p>

          <img
            onClick={() => onItemDelete(id)}
            src={Delete}
            alt="Delete"
            className="task-details-todo-list-item-delete-icon"
          />
        </div>
      ) : (
        <div className="task-details-todo-list-item-input-container w-100">
          <input
            type="text"
            placeholder="Add a task"
            className="form-control w-100"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={inputValue}
            onBlur={handleBlur}
            autoFocus
            autoComplete="off"
          />
        </div>
      )}
    </div>
  );
};

const TaskDetailsCustomFieldsList = ({
  list,
  setList,
  isDark,
  deleteTaskCustomFieldItem,
  taskDetails,
  addAndUpdateTaskCustomFieldListItem,
}) => {
  const onFieldChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, field: text } : res))
    );
  };
  const onValueChange = (id, text) => {
    setList(
      list.map((res, index) => (res.id === id ? { ...res, value: text } : res))
    );
  };

  const onBlurInput = async (id, field, value) => {
    if (taskDetails && taskDetails.id) {
      await addAndUpdateTaskCustomFieldListItem({
        taskId: taskDetails.id,
        filedId: id,
        fieldValues: [{ [field]: value ? value : "" }],
      });
    }
  };

  const onItemDelete = async (id) => {
    setList(list.filter((res, index) => res.id !== id));
    if (taskDetails && taskDetails.id) {
      await deleteTaskCustomFieldItem({
        fieldId: id,
        taskId: taskDetails.id,
      });
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      {list.map((res, index) => {
        return (
          <TaskDetailsCustomFieldsListItem
            field={res.field}
            value={res.value}
            id={res.id}
            key={index}
            isDark={isDark}
            onFieldChange={onFieldChange}
            onValueChange={onValueChange}
            onItemDelete={onItemDelete}
            onBlurInput={onBlurInput}
          />
        );
      })}
    </div>
  );
};

const TaskDetailsCustomFieldsListItem = ({
  field,
  value,
  id,
  key,
  isDark,
  onFieldChange,
  onValueChange,
  onItemDelete,
  error,
  validateForm,
  onBlurInput,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleFieldChange = (event) => {
    const newField = event.target.value;
    onFieldChange(id, newField);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    onValueChange(id, newValue);
  };

  return (
    <div
      className={`position-relative d-flex align-item-center gap-2 task-details-custom-fields-list-item ${
        isHovered ? "hovered" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-50 mb-0 form-group">
        <input
          type="text"
          placeholder="Field"
          className={`form-control `}
          value={field}
          onChange={handleFieldChange}
          onBlur={() => onBlurInput(id, field, value)}
          autoComplete="off"
        />
      </div>
      <div className="w-50 mb-0 form-group">
        <input
          type="text"
          placeholder="Value"
          className={`form-control `}
          value={value}
          onChange={handleValueChange}
          onBlur={() => onBlurInput(id, field, value)}
          autoComplete="off"
        />
      </div>
      {isHovered ? (
        <div className="delete-icon-container">
          <img
            onClick={() => onItemDelete(id)}
            src={Delete}
            alt="Delete"
            height={20}
            width={20}
            className="mt-2 cursor-pointer"
            style={{ cursor: "pointer" }}
          />
        </div>
      ) : (
        <div style={{ width: "20px", height: "20px" }}></div>
      )}
    </div>
  );
};
