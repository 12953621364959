import { PMBaseApi } from "../../services/PMBaseApi";

export const performanceSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Getting company performance template
    getCompanyPerformanceTemplate: builder.query({
      query: () => ({
        url: `performance/performance-templates`, // API endpoint
        method: "GET",
      }),
      providesTags: ["companyPerformanceTemplate"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    // Getting assignment performance template
    getAssignmentPerformanceTemplate: builder.query({
      query: () => ({
        url: `performance/templates-assignments`, // API endpoint
        method: "GET",
      }),
      providesTags: ["assignmentPerformanceTemplate"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),

    getPerformanceTemplateDetails: builder.query({
      query: (id) => ({
        url: `performance/templates-details?templateId=${id}`, // API endpoint
        method: "GET",
      }),
      // Tags used for cache management
      keepUnusedDataFor: 0, // 12 hours
    }),

    // Mutation to create a new performance template
    createPerformanceTemplate: builder.mutation({
      // Custom query function to handle the API request
      query: (data) => ({
        url: `performance/create-performance-template`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(
            performanceSlice.util.invalidateTags(["companyPerformanceTemplate"])
          );
          dispatch(
            performanceSlice.util.invalidateTags([
              "assignmentPerformanceTemplate",
            ])
          );
        } catch (error) {
          console.error("Error updating task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update performance template score
    updatePerformanceTemplateScore: builder.mutation({
      // Custom query function to handle the API request
      query: (data) => ({
        url: `performance/update-performance-template-score`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(
            performanceSlice.util.invalidateTags(["companyPerformanceTemplate"])
          );
          dispatch(
            performanceSlice.util.invalidateTags([
              "assignmentPerformanceTemplate",
            ])
          );
        } catch (error) {
          console.error("Error updating task data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to delete a performance template
    deletePerformanceTemplate: builder.mutation({
      query: (templateId) => ({
        url: `performance/delete-performance-template?templateId=${templateId}`, // API endpoint
        method: "DELETE",
      }),
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (templateId, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          dispatch(
            performanceSlice.util.invalidateTags(["companyPerformanceTemplate"])
          );
          dispatch(
            performanceSlice.util.invalidateTags([
              "assignmentPerformanceTemplate",
            ])
          );
        } catch (error) {
          console.error("Error deleting performance template:", error); // Log error if mutation fails
        }
      },
    }),
  }),
});

// Export the hooks generated by `performanceSlice`
export const {
  useGetCompanyPerformanceTemplateQuery,
  useGetAssignmentPerformanceTemplateQuery,
  useCreatePerformanceTemplateMutation,
  useUpdatePerformanceTemplateScoreMutation,
  useGetPerformanceTemplateDetailsQuery,
  useDeletePerformanceTemplateMutation
} = performanceSlice;
