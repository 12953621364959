/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";
import { ShimmerButton } from "react-shimmer-effects";

const formattedSubmitDate = (date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const UserSessionHistory = (props) => {
  const flatpickrRef = useRef(null);
  const [history, setHistory] = useState([]);
  const [load, setLoad] = useState(false);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const getHistory = async () => {
      try {
        setLoad(true);
        const response = await API.post("users/sessions-history", {
          userId: props?.user?.userInfo?.userId,
          filterDate: date
            ? formattedSubmitDate(date)
            : formattedSubmitDate(new Date()),
        });
        setHistory(response?.data?.userSessions);
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div className={`listing-info-profile-wrap`}>
      <div className="d-flex history-date-wrapper">
        <h4>
          Session <span>History</span>
        </h4>
        <div
          data-theme={props.isDark}
          className="project-details-main-date-filter d-flex align-items-center justify-content-between history-date"
        >
          <strong style={{ fontWeight: "500", marginRight: "10px" }}>
            Date:{" "}
          </strong>
          <Flatpickr
            data-theme={props.isDark}
            className="project-details-task-table-flatpickr-container"
            options={{
              altInput: true,
              altFormat: "M d, Y",
              dateFormat: "Y-m-d",
              static: true,
              maxDate: new Date(),
              disableMobile: true,
            }}
            ref={flatpickrRef}
            value={date}
            onChange={([date]) => {
              setDate(date);
            }}
          />
          <span>
            <Icon
              onClick={() => flatpickrRef.current.flatpickr.open()}
              icon="uis:calender"
            />
          </span>
        </div>
      </div>
      <ul className="row">
        <li className="col-md-12">
          {load ? (
            <div className="overview-profile overview-password">
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
            </div>
          ) : (
            <div className="overview-profile overview-password">
              <a className="d-flex flex-column history-a">
                {history && history.length > 0 ? (
                  history?.map((res, id) => (
                    <div className="mt-0 w-100 d-flex justify-content-between align-items-center number-overview-wrap history-p-one">
                      <strong>{res?.actionType}</strong>
                      <p className="history-p">
                        {new Date(res?.actionTime).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="d-flex mt-2 align-items-center rounded-3 ">
                    <p className="no-billing-txt">No session history found.</p>
                  </div>
                )}
              </a>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default UserSessionHistory;
