import {
  TEAMS_FORM_LIST_FAIL,
  TEAMS_FORM_LIST_REQUEST,
  TEAMS_FORM_LIST_SUCCESS,
  TEAMS_LIST_FAIL,
  TEAMS_LIST_OPTIONS_SUCCESS,
  TEAMS_LIST_REQUEST,
  TEAMS_LIST_SUCCESS,
} from "../constants/teamConstant";

// Reducer for teams list
export const teamsListReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAMS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TEAMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teamsList: action.payload,
      };
    case TEAMS_LIST_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamsOptionsList: action.payload,
      };
    case TEAMS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Reducer for teams FORM list
export const teamsFormListReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAMS_FORM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TEAMS_FORM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teamsFormList: action.payload,
      };
    case TEAMS_FORM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
