import {
  FETCH_PROJECT_DETAIL_TASKS_REQUEST,
  FETCH_PROJECT_DETAIL_TASKS_SUCCESS,
  FETCH_PROJECT_DETAIL_TASKS_FAILURE,
  UPDATE_PROJECT_DETAIL_TASK_SUCCESS,
  DELETE_PROJECT_DETAIL_TASK_SUCCESS,
  REMOVE_TASK_AND_PHASES,
} from "../constants/projectDetailTaskConstants";

// Initial state for the project detail tasks cache reducer
const initialState = {
  loading: false,
  taskPhaseList: {},
  error: null,
};

const projectDetailTasksCachedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_DETAIL_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PROJECT_DETAIL_TASKS_SUCCESS: {
      const { tasks, limit, page, phaseId, projectId } = action.payload;
      return {
        ...state,
        loading: false,
        taskPhaseList: {
          ...state.taskPhaseList,
          [projectId]: {
            ...state.taskPhaseList[projectId],
            [phaseId]: {
              ...state.taskPhaseList[projectId]?.[phaseId],
              [`${limit}_${page}`]: tasks,
            },
          },
        },
        error: null,
      };
    }

    case FETCH_PROJECT_DETAIL_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROJECT_DETAIL_TASK_SUCCESS: {
      const { taskId, updatedTask, projectId, phaseId } = action.payload;

      return {
        ...state,
        taskPhaseList: {
          ...state.taskPhaseList,
          [projectId]: {
            ...state.taskPhaseList[projectId],
            [phaseId]: state.taskPhaseList[projectId][phaseId].map((task) =>
              task.id === taskId ? { ...task, ...updatedTask } : task
            ),
          },
        },
      };
    }
    // case UPDATE_PROJECT_DETAIL_TASK_SUCCESS: {
    //   const { taskId, updatedTask } = action.payload;

    //   const updatedTaskList = Object.keys(state.taskPhaseList).reduce(
    //     (projectAcc, projectKey) => {
    //       const projectData = state.taskPhaseList[projectKey];

    //       const updatedProjectData = Object.keys(projectData).reduce(
    //         (phaseAcc, phaseKey) => {
    //           const phaseTasks = projectData[phaseKey];

    //           const updatedPhaseTasks = Object.keys(phaseTasks).reduce(
    //             (taskAcc, taskKey) => {
    //               const updatedTasks = phaseTasks[taskKey]?.map((task) =>
    //                 task.id === taskId ? { ...task, ...updatedTask } : task
    //               );

    //               return {
    //                 ...taskAcc,
    //                 [taskKey]: updatedTasks,
    //               };
    //             },
    //             {}
    //           );

    //           return {
    //             ...phaseAcc,
    //             [phaseKey]: updatedPhaseTasks,
    //           };
    //         },
    //         {}
    //       );

    //       return {
    //         ...projectAcc,
    //         [projectKey]: updatedProjectData,
    //       };
    //     },
    //     {}
    //   );

    //   return {
    //     ...state,
    //     taskPhaseList: updatedTaskList,
    //   };
    // }

    case DELETE_PROJECT_DETAIL_TASK_SUCCESS: {
      const { taskIds } = action.payload;

      const updatedTaskList = Object.keys(state.taskPhaseList).reduce(
        (projectAcc, projectKey) => {
          const projectData = state.taskPhaseList[projectKey];

          const updatedProjectData = Object.keys(projectData).reduce(
            (phaseAcc, phaseKey) => {
              const phaseTasks = projectData[phaseKey];

              const updatedPhaseTasks = Object.keys(phaseTasks).reduce(
                (taskAcc, taskKey) => {
                  const updatedTasks = phaseTasks[taskKey]?.filter(
                    (task) => !taskIds.includes(task.id)
                  );

                  return {
                    ...taskAcc,
                    [taskKey]: updatedTasks,
                  };
                },
                {}
              );

              return {
                ...phaseAcc,
                [phaseKey]: updatedPhaseTasks,
              };
            },
            {}
          );

          return {
            ...projectAcc,
            [projectKey]: updatedProjectData,
          };
        },
        {}
      );

      return {
        ...state,
        taskPhaseList: { ...updatedTaskList },
      };
    }

    case REMOVE_TASK_AND_PHASES: {
      const { projectId, phaseId, taskId } = action.payload;

      if (!state.taskPhaseList[projectId]) return state; // If the projectId doesn't exist, return the state

      // Remove the specific task from the given phaseId
      const updatedPhaseTasks = Object.keys(
        state.taskPhaseList[projectId][phaseId]
      ).reduce((taskAcc, taskKey) => {
        const remainingTasks = state.taskPhaseList[projectId][phaseId][
          taskKey
        ].filter((task) => task.id !== taskId);

        // If the task list is not empty, keep this key
        if (remainingTasks.length > 0) {
          return {
            ...taskAcc,
            [taskKey]: remainingTasks,
          };
        } else {
          return taskAcc;
        }
      }, {});

      // Remove all other phases except the one from which the task was removed
      const updatedProjectData = {
        [phaseId]: updatedPhaseTasks,
      };

      return {
        ...state,
        taskPhaseList: {
          ...state.taskPhaseList,
          [projectId]: updatedProjectData,
        },
      };
    }

    default:
      return state;
  }
};

export default projectDetailTasksCachedReducer;
