import { toast } from "react-toastify";
import { useUpdateCompanyWorkingHoursStatusMutation } from "../../../slices/user/userSlice";

function useEditWorkingHoursStatus() {
  const [
    updateCompanyWorkingHoursStatus,
    { isLoading: isEditWorkingHoursLoading },
  ] = useUpdateCompanyWorkingHoursStatusMutation();

  const handleOnSubmit = async (data) => {
    // Handle form submission
    try {
      // Create payload
      const payload = {
        workingHourId: data?.id,
      };

      const response = await updateCompanyWorkingHoursStatus(payload).unwrap();
      if (response?.status === "success") {
        toast.success(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
        });
      }
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
      });
    } finally {
    }
  };

  return {
    handleOnSubmit,
    isEditWorkingHoursLoading,
  };
}

export default useEditWorkingHoursStatus;
