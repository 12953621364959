import { CLEAR_ERROR } from "../constants/companyOverviewConstatnt.js";
import { REGISTERED_USER_COUNT_FAIL, REGISTERED_USER_COUNT_REQUEST, REGISTERED_USER_COUNT_SUCCESS } from "../constants/registeredUserCountConstants.js";

  
  export const registeredUserCountReducer = (state = 0, action) => {
    switch (action.type) {
      case REGISTERED_USER_COUNT_REQUEST:
        return {
          ...state,
          loading: true,
          registeredUserCount: 0,
        };
  
      case REGISTERED_USER_COUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          registeredUserCount: action.payload,
        };
  
      case REGISTERED_USER_COUNT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          registeredUserCount: 0,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
          registeredUserCount: 0,
        };
  
      default:
        return state;
    }
  };
  